import React, { useContext } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import CreateCustomerOrder, { CreateCustomerOrderParams } from "./CreateCustomerOrder";
import { DataContextInternal } from "../../../../context/dataContext";

interface CreateCustomerOrderWrapperProps extends RouteComponentProps<CreateCustomerOrderParams> {}

const CreateCustomerOrderWrapper: React.FunctionComponent<CreateCustomerOrderWrapperProps> = (props) => {
  const context = useContext(DataContextInternal);
  return <CreateCustomerOrder context={context} {...props} />;
};

export default withRouter(CreateCustomerOrderWrapper);
