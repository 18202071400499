import React, { PureComponent } from "react";
import { DataContextInternalType } from "../../../../../../context/dataContext";
import { CustomerOrderExtended } from "../../../../../../model/customerOrder.types";
import { formatCurrency } from "../../../../../../utils/baseUtils";
import { convertCurrency, CUSTOMER_BASE_CURRENCY } from "../../../../../../utils/currencyUtils";
import { resolveFilePath, shortenAlias } from "../../../../../../utils/fileUtils";

interface WorkflowServicesCardProps {
  order: CustomerOrderExtended;
  context: DataContextInternalType;
}

class WorkflowServicesCard extends PureComponent<WorkflowServicesCardProps> {
  render() {
    const { order, context } = this.props;
    const allDone = order.services.every((s) => s.performed);

    return (
      <div className="opacity-100-hover" style={{ opacity: allDone ? 0.3 : 1 }}>
        <div className="fw-bolder text-white fs-3 my-5">
          Services
          {order.services.length > 0 &&
            " - (" +
              order.services.reduce((d, s) => d + (s.performed ? 1 : 0), 0) +
              "/" +
              order.services.length +
              " done)"}{" "}
          {allDone ? (
            <i className="h2 fas fa-check-circle text-success" />
          ) : (
            <span className="text-warning">[Current Task]</span>
          )}
        </div>
        {order.services.map((s) => (
          <div key={s.service._id.toString()} className="row py-2">
            <div className="bg-light2 rounded p-5 mb-7" style={{ opacity: s.performed ? "0.8" : "1" }}>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 me-2">
                  <span className="fw-bolder text-gray-800 fs-6 flex-center flex-stack">
                    RBS-{s.service.serviceNo} {s.service.title.en}
                  </span>
                  <span className="text-muted fw-bold d-block">{s.service.description.en}</span>
                </div>
                <div className="text-right">
                  <div className="fw-bold fs-6 text-gray-400">{s.service.duration} day(s) duration</div>
                  <span className="text-muted mb-1">
                    {formatCurrency(
                      convertCurrency(s.service.price, CUSTOMER_BASE_CURRENCY, order.currency, context.currencies),
                      order.currency
                    )}
                  </span>
                </div>
              </div>
              <div className="mt-5">
                <div className="fw-bolder text-white fs-5 mt-4">Service Documents</div>
                <div>
                  {s.files.length > 0 ? (
                    s.files.map((d) => (
                      <a
                        key={d._id.toString()}
                        className="text-white custom-link fw-bold ml-3"
                        href={resolveFilePath(d.path)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {shortenAlias(d.path)}
                      </a>
                    ))
                  ) : (
                    <span className="text-muted fw-bold ml-3">No files uploaded</span>
                  )}
                </div>
              </div>
              <div className="float-right d-inline-block">
                {s.performed && (
                  <span className="text-white">
                    <i className="icon-lg fas fa-check-circle text-success mr-1" />
                    Done
                  </span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default WorkflowServicesCard;
