import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import {
  AirFreightCalculationValues,
  AirFreightPriceCalculation,
  isAirFreightValues,
  SeaFreightCalculationValues,
  SeaFreightPriceCalculation,
  isSeaFreightValues,
  EUStockPriceCalculation,
  EUStockCalculationValues,
  isEUStockValues,
} from "../../../utils/priceCalculationUtils";
import Tooltip from "../../common/Tooltip";
import { Input } from "../../common/Input";
import { Commodity } from "../../../model/commodity.types";
import { SelectedSupplier } from "../../../utils/supplierOrderUtils";
import AirFreightCalculationView from "./views/AirFreightCalculationView";
import SeaFreightCalculationView from "./views/SeaFreightCalculationView";
import EuStockCalculationView from "./views/EUStockCalculationView";
import { FinishedProduct } from "../../../model/finishedProduct.types";
import { formatArticleUnit } from "../../../utils/productArticleUtils";

interface CreateSupplierOrderCalculationValuesModalProps {
  article: Commodity | FinishedProduct;
  totalAmount: number;
  calculationDetails: SeaFreightPriceCalculation | AirFreightPriceCalculation | EUStockPriceCalculation | null;
  selectedSupplier: SelectedSupplier | null;
  calculationValues: SeaFreightCalculationValues | AirFreightCalculationValues | EUStockCalculationValues;
  onSetCalculationValues: (
    calculationValues: SeaFreightCalculationValues | AirFreightCalculationValues | EUStockCalculationValues
  ) => void;
  currency: string;
}

interface CreateSupplierOrderCalculationValuesModalState {
  show: boolean;
}

class CreateSupplierOrderCalculationValuesModal extends PureComponent<
  CreateSupplierOrderCalculationValuesModalProps,
  CreateSupplierOrderCalculationValuesModalState
> {
  viewRef: React.RefObject<SeaFreightCalculationView | AirFreightCalculationView | EuStockCalculationView>;
  constructor(props: CreateSupplierOrderCalculationValuesModalProps) {
    super(props);
    this.viewRef = React.createRef();
    this.state = { show: false };
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState({ show: false });

  handleSetCalculationValues = () => {
    if (!this.viewRef || !this.viewRef.current) return;
    this.props.onSetCalculationValues(this.viewRef.current.getCalculationValues());
    this.handleHide();
  };

  render() {
    const { article, totalAmount, calculationDetails, selectedSupplier, calculationValues, currency } = this.props;
    const { show } = this.state;
    return (
      <>
        <Tooltip tooltipText={"View and adjust the detailed calculation values"}>
          <button className="btn btn-text-light p-0 pb-2 ml-2 " onClick={this.handleShow}>
            <i className="fas fa-cog align-middle" style={{ fontSize: "1.1rem" }} />
          </button>
        </Tooltip>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered size="xl">
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Calculation Details</h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            {(calculationDetails || selectedSupplier) && (
              <>
                <h3 className="mb-3 mt-7">General</h3>
                <div className="row">
                  {selectedSupplier && (
                    <div className="col-xl-4">
                      <label className="fs-5 fw-bold mb-2">Commodity price</label>
                      <div className="input-group">
                        <Input
                          className="form-control custom-form-control disabled"
                          type={"number"}
                          disabled={true}
                          value={selectedSupplier.price.price.toFixed(2)}
                        />
                        <div className="input-group-append rounded-end bg-custom-light-gray" style={{ opacity: 0.7 }}>
                          <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                            {selectedSupplier.price.currency}/{formatArticleUnit(article.unit)}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {calculationDetails && (
                    <div className="col-xl-4">
                      <label className="fs-5 fw-bold mb-2">Total commodity cost</label>
                      <div className="input-group">
                        <Input
                          className="form-control custom-form-control disabled"
                          type={"number"}
                          disabled={true}
                          value={calculationDetails.totalCommodityPrice.toFixed(2)}
                        />
                        <div className="input-group-append rounded-end bg-custom-light-gray" style={{ opacity: 0.7 }}>
                          <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                            {currency}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
            {isSeaFreightValues(calculationValues) && (
              <SeaFreightCalculationView
                ref={this.viewRef as React.RefObject<SeaFreightCalculationView>}
                totalAmount={totalAmount}
                calculationValues={calculationValues}
              />
            )}
            {isAirFreightValues(calculationValues) && (
              <AirFreightCalculationView
                ref={this.viewRef as React.RefObject<AirFreightCalculationView>}
                totalAmount={totalAmount}
                calculationValues={calculationValues}
              />
            )}
            {isEUStockValues(calculationValues) && (
              <EuStockCalculationView
                ref={this.viewRef as React.RefObject<EuStockCalculationView>}
                totalAmount={totalAmount}
                calculationValues={calculationValues}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-text-secondary" onClick={this.handleHide}>
              Close
            </button>
            <button className="btn btn-sm btn-outline btn-outline-light" onClick={this.handleSetCalculationValues}>
              Save & Recalculate
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreateSupplierOrderCalculationValuesModal;
