import { SupplierOrder } from "../../model/supplierOrder.types";
import { getOrderNumber, isSupplierOrder } from "../orderUtils";
import { DataContextInternalType } from "../../context/dataContext";
import { StorageOrder } from "../../model/storageOrder.types";
import { getFormattedHTML } from "../wysiwygUtils";
import { isCommoditySnapshot } from "../productArticleUtils";
import { formatDateWithType } from "../logisticsUtils";

/**
 * Create a storage order html string for pdf generation
 * @param context the internal context
 * @param order the supplier order for the storage order
 * @param storageOrder the storage order
 * @param draft optional, indicates if pdf is a draft
 * @returns {string} a html string for pdf generation
 */
export function createStorageOrder(
  context: DataContextInternalType,
  order: SupplierOrder,
  storageOrder: StorageOrder,
  draft?: boolean
): string {
  const deliveryDate = formatDateWithType(storageOrder.deliveryDate, storageOrder.deliveryDateType);
  const { commodity } = order;
  const batches = context.batch
    .filter((b) => b.supplierOrder === order._id.toString())
    .map((b) => {
      return b.lot;
    });
  let html = `
  <head>
    <link href="https://fonts.googleapis.com/css?family=Signika&display=swap" rel="stylesheet">
      <style>tr:nth-child(even) {
          background-color: white;
      }</style>
    <meta http-equiv="content-type" content="text/html; charset=utf-8">
  </head>
  <body style="font-family: Signika,serif">
    <img src="https://rawbids.com/images/logo-dark.png" alt="Logo" width="180" style="float:right" />
    <span style="font-size: 30px; font-weight: bold"> ${draft ? "DRAFT - " : ""}Storage Order</span><br /><br /><br /> 
     <div><b>Storage order No: </b>RB-${storageOrder.storageOrderNo}</div><br />
    <div><b>Our Reference: </b>${getOrderNumber(order)}</div><br />`;
  if (storageOrder.supplierRef) {
    html += `<div><b>Supplier Reference: </b>${storageOrder.supplierRef}</div><br />`;
  }
  html += `<table style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px">
      <thead>
        <tr style="background-color:#333333; color: #ffffff">
          <th style="text-align: left"><b style="white-space:nowrap">Order number</b></th>
          <th style="text-align: left"><b>Packing</b></th>
          <th style="min-width: 150px; text-align: left"><b>Goods and Quality</b></th>
          <th style="text-align: left"><b style="white-space:nowrap;">Net (kg)</b></th>
          <th style="text-align: left"><b style="white-space:nowrap;">Gross (kg)</b></th>
        </tr>
      </thead>
      <tbody>
       <tr><td>${isSupplierOrder(order) ? "PO: " : "SO: "}${getOrderNumber(order)}</td>
          <td>${getFormattedHTML(storageOrder.orderInformation.packaging)}</td>
          <td><b>${commodity.title.en}</b><br/>Country of origin: ${commodity.country.name} <br/> ${
    isCommoditySnapshot(commodity) && commodity.particleSize ? commodity.particleSize.en + "<br/>" : ""
  } Article No.: ${commodity.articleNo}<br/>LOT: ${
    batches && batches.length > 0 ? batches.join(", ") : "see attached certificates of analysis"
  }<br/></td>
          <td>${storageOrder.orderInformation.netWeight.toLocaleString()}kg</td>
          <td>${
            storageOrder.orderInformation.grossWeight > 0
              ? `${storageOrder.orderInformation.grossWeight.toLocaleString()}kg`
              : "-"
          }</td></tr>
      </tbody>
    </table>
    <br /><br />
    <table style="font-size:16px; width: 100%; display: block; border-spacing: 0 2em;">
      <tbody>`;
  if (
    storageOrder.takeOver &&
    (storageOrder.takeOver.startIncoterm?.trim() !== "" || storageOrder.takeOver.destinationIncoterm?.trim() !== "")
  ) {
    html += `<tr><td style="vertical-align: top; width: 30%">
            <b>Take over: </b>
           </td><td>${storageOrder.takeOver.startIncoterm ? storageOrder.takeOver.startIncoterm : ""} ${
      storageOrder.takeOver.startingFrom ? storageOrder.takeOver.startingFrom : ""
    } ${
      storageOrder.takeOver && storageOrder.takeOver.startIncoterm && storageOrder.takeOver.destinationIncoterm
        ? "-"
        : ""
    } ${storageOrder.takeOver.destinationIncoterm ? storageOrder.takeOver.destinationIncoterm : ""} ${
      storageOrder.takeOver.destinationTo ? storageOrder.takeOver.destinationTo : ""
    }</td>
           </tr>`;
  }
  html += `<tr><td style="vertical-align: top; width: 30%"><b>Warehouse: </b></td><td>${getFormattedHTML(
    storageOrder.warehouse
  )}</td></tr>`;
  if (storageOrder.sender) {
    html += `<tr> 
            <td style="vertical-align: top"> <b>Sender: </b>
            </td><td><span>${getFormattedHTML(storageOrder.sender)}</span></td>
        </tr> `;
  }
  if (storageOrder.recipient) {
    html += `<tr><td style="vertical-align: top">
             <b>Recipient: </b></td><td><span>${getFormattedHTML(storageOrder.recipient)}</span></td>
        </tr>`;
  }
  html += `<tr><td style="vertical-align: top">
             <b>State: </b></td><td><span>${
               storageOrder.customsState ? "Customs cleared" : "Customs not cleared"
             }</span></td>
        </tr>
         <tr><td style="vertical-align: top">   <b>Delivery Date: </b></td><td>${deliveryDate}</td>
        </tr>
        <tr><td style="vertical-align: top">  <b>Remarks: </b>
          </td><td>${storageOrder.remarks}</td>
        </tr>
      </tbody>
    </table>
  </body>`;
  return html;
}
