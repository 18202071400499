import React, { PureComponent } from "react";
import { Nav, Tab } from "react-bootstrap";
import { TabDefinition } from "../../../common/CustomTypes";
import OverviewOffers from "./tabPanels/OverviewOffers";
import { DataContextSupplierType } from "../../../../context/dataContext";
import { Commodity } from "../../../../model/commodity.types";
import userService from "../../../../services/userService";
import { FinishedProduct } from "../../../../model/finishedProduct.types";
import { SupplierArticleExtended } from "../../../../utils/productArticleUtils";

interface OverviewTabPanelProps {
  context: DataContextSupplierType;
  onSelectArticle: (selectedArticle: SupplierArticleExtended) => void;
}

interface OverviewTabPanelState {
  currentTab: string;
}

class OverviewTabPanel extends PureComponent<OverviewTabPanelProps, OverviewTabPanelState> {
  constructor(props: OverviewTabPanelProps) {
    super(props);
    this.state = { currentTab: this.getTabDefinitions()[0].name };
  }
  /**
   * Handle tab changes
   * @param eventKey new tab
   */
  handleTab = (eventKey: string | null) => {
    if (eventKey && eventKey !== this.state.currentTab) this.setState({ currentTab: eventKey });
  };

  getTabDefinitions = (): Array<TabDefinition> => {
    const { onSelectArticle, context } = this.props;
    const { articles, articlesActive, articlesExpiring, articlesExpired, articlesWithoutPrices, articlesDisabled } =
      this.getRelevantArticles();

    return [
      {
        name: "allOffers",
        tabContent: (
          <span>
            All Offers <small className="text-muted">{articles.length}</small>
          </span>
        ),
        component: (
          <OverviewOffers type="all" onSelectArticle={onSelectArticle} articles={articles} context={context} />
        ),
      },
      {
        name: "activeOffers",
        tabContent: (
          <span>
            Active <small className="text-success">{articlesActive.length}</small>
          </span>
        ),
        component: (
          <OverviewOffers type="active" onSelectArticle={onSelectArticle} articles={articlesActive} context={context} />
        ),
      },
      {
        name: "expiringOffers",
        tabContent: (
          <span>
            Expiring <small className="text-danger">{articlesExpiring.length}</small>
          </span>
        ),
        component: (
          <OverviewOffers
            type="expiring"
            onSelectArticle={onSelectArticle}
            articles={articlesExpiring}
            context={context}
          />
        ),
      },
      {
        name: "inactiveOffers",
        tabContent: (
          <span>
            Expired <small className="text-danger">{articlesExpired.length}</small>
          </span>
        ),
        component: (
          <OverviewOffers
            type="inactive"
            onSelectArticle={onSelectArticle}
            articles={articlesExpired}
            context={context}
          />
        ),
      },
      {
        name: "withoutPricesOffers",
        tabContent: (
          <span>
            Without Prices <small className="text-danger">{articlesWithoutPrices.length}</small>
          </span>
        ),
        component: (
          <OverviewOffers
            type="inactive"
            onSelectArticle={onSelectArticle}
            articles={articlesWithoutPrices}
            context={context}
          />
        ),
      },
      {
        name: "Disabled",
        tabContent: (
          <span>
            Disabled <small className="text-danger">{articlesDisabled.length}</small>
          </span>
        ),
        component: (
          <OverviewOffers
            type="inactive"
            onSelectArticle={onSelectArticle}
            articles={articlesDisabled}
            context={context}
          />
        ),
      },
    ];
  };

  getRelevantArticles = () => {
    const { context } = this.props;
    let articles: Array<Commodity | FinishedProduct> = context.commodity.filter((c) =>
      c.suppliers.some((s) => s.supplier === userService.getCompany())
    );
    const finishedProducts: Array<FinishedProduct> = context.finishedProduct.filter((fp) =>
      fp.suppliers.some((s) => s.supplier === userService.getCompany())
    );
    articles = articles.concat(finishedProducts);

    const now = new Date();
    const expThreshold = new Date(new Date().setDate(new Date().getDate() + 7));
    const articlesActive = articles.filter((c) =>
      c.suppliers.some((s) => !s.disabled && s.prices.some((s) => s.validUntil >= now))
    );
    const articlesExpiring = articles.filter((c) =>
      c.suppliers.some(
        (s) =>
          !s.disabled && s.prices.length > 0 && s.prices.some((s) => s.validUntil >= now && s.validUntil < expThreshold)
      )
    );
    const articlesExpired = articles.filter((c) =>
      c.suppliers.some((s) => !s.disabled && s.prices.length > 0 && s.prices.every((s) => s.validUntil < now))
    );
    const articlesWithoutPrices = articles.filter((c) => c.suppliers.some((s) => !s.disabled && s.prices.length === 0));
    const articlesDisabled = articles.filter((c) => c.suppliers.some((s) => s.disabled));
    return {
      articles,
      articlesActive,
      articlesExpiring,
      articlesExpired,
      articlesWithoutPrices,
      articlesDisabled,
    };
  };

  render() {
    const { currentTab } = this.state;
    const tabs = this.getTabDefinitions();

    return (
      <Tab.Container id="supplierOverviewTabs" activeKey={currentTab} onSelect={this.handleTab}>
        <div className="flex-lg-row-fluid ms-lg-15">
          <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
            {tabs.map((t) => (
              <li key={t.name + "_link"} className="nav-item cursor-pointer">
                <Nav.Link
                  role="tab"
                  id={t.name}
                  eventKey={t.name}
                  active={currentTab === t.name}
                  className="text-active-white pb-4"
                  style={{ opacity: t.condition && !t.condition() ? 0.5 : 1 }}
                  disabled={t.condition && !t.condition()}
                >
                  {t.tabContent}
                </Nav.Link>
              </li>
            ))}
            <li className="nav-item ms-auto">
              <button className="btn btn-outline btn-outline-light disabled" disabled={true}>
                Renew All
              </button>
            </li>
          </ul>
          <Tab.Content>
            {tabs.map((t) => (
              <Tab.Pane id={t.name} key={t.name} eventKey={t.name}>
                {t.component}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </div>
      </Tab.Container>
    );
  }
}

export default OverviewTabPanel;
