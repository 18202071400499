import React, { PureComponent } from "react";

class QuickActions extends PureComponent {
  render() {
    return (
      <div className="card bg-white">
        <div className="card-header border-0">
          <div className="card-title">
            <h3 className="fw-bolder m-0">Quick Actions</h3>
          </div>
        </div>
        <div className="card-body pt-2">
          <div className="py-2 fs-6">
            <button className="btn btn-light w-100 fw-bolder disabled" disabled={true}>
              Renew all commodities
            </button>
          </div>
          <div className="py-2 fs-6">
            <button className="btn btn-light w-100 fw-bolder disabled" disabled={true}>
              Bulk-Adjust all commodities
            </button>
          </div>
          <div className="py-2 fs-6">
            <button className="btn btn-light w-100 fw-bolder disabled" disabled={true}>
              Disable all commodities
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default QuickActions;
