export const STANDARDTEXTSHORT =
  "We thank you for your order and the trust you have placed in us.<br/><br/>With kind regards and best wishes<br/>" +
  "<b>RAWBIDS GmbH</b><br/><br/>" +
  "<b><i>This document is valid without signature.</i></b>";

export const REQUESTTEXTSHORT =
  "We thank you for your request and the trust you have placed in us.<br/><br/>With kind regards and best wishes<br/>" +
  "<b>RAWBIDS GmbH</b><br/><br/>" +
  "<b><i>This document is valid without signature.</i></b>";

export const CONTRACTDELIVERY =
  "We are currently checking the possible departure. As soon as the goods are ready for you at our warehouse, we will contact you.";

// not known yet! - RB-510
export const ARBITRATIONTEXT = "";

export const ORGANICCODESHORT = "DE-ÖKO-006";
export const ORGANICCODE = `Certified Organic Code: ${ORGANICCODESHORT}`;
