import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { DataContextInternal } from "../../../../context/dataContext";
import { SupplierOrder } from "../../../../model/supplierOrder.types";
import { getOrderNumber } from "../../../../utils/orderUtils";
import { getAmountForOrderOrContract, getLinkForOrderOrContract } from "../../../../utils/contractAndOrderUtils";

interface CustomerOrderSupplierOrderProps {
  context: React.ContextType<typeof DataContextInternal>;
  supplierOrder?: SupplierOrder;
}

interface CustomerOrderSupplierOrderState {}

class CustomerOrderSupplierOrder extends PureComponent<
  CustomerOrderSupplierOrderProps,
  CustomerOrderSupplierOrderState
> {
  render() {
    const { supplierOrder } = this.props;
    return (
      <>
        {supplierOrder && (
          <div>
            <div className="card mb-5 mb-xl-8 bg-white">
              <div className="card-body">
                <div className="d-flex flex-stack fs-4 py-3">
                  <div className="fw-bolder rotate collapsible">Supplier Orders</div>
                </div>
                <div className="border-bottom-dark-gray" />
                <div className="pb-5 fs-6">
                  <div className="fw-bolder mt-5">
                    <Link className="text-white custom-link" to={getLinkForOrderOrContract(supplierOrder)}>
                      {getOrderNumber(supplierOrder)}
                    </Link>
                  </div>
                  <div className="text-muted">{getAmountForOrderOrContract(supplierOrder)}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default CustomerOrderSupplierOrder;
