import React, { useContext } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DataContextInternal } from "../../../context/dataContext";
import CreateCustomerContract, { CreateCustomerContractParams } from "./CreateCustomerContract";

interface CreateCustomerContractWrapperProps extends RouteComponentProps<CreateCustomerContractParams> {}

const CreateCustomerContractWrapper: React.FunctionComponent<CreateCustomerContractWrapperProps> = (props) => {
  const context = useContext(DataContextInternal);
  return <CreateCustomerContract context={context} {...props} />;
};

export default withRouter(CreateCustomerContractWrapper);
