import React, { useEffect, useMemo, useState } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import PropertiesWidget from "../../common/PropertiesWidget";
import { toAbsoluteUrl } from "../../../utils/baseUtils";
import { resolveFilePath } from "../../../utils/fileUtils";
import { D_MASTERSPECIFICATION } from "../../../utils/commodityUtils";
import { PropertyType, resolveProperties } from "../../../utils/propertyUtils";
import { Commodity } from "../../../model/commodity.types";
import userService from "../../../services/userService";
import { DataContextSupplierType } from "../../../context/dataContext";
import { CommodityStatistics } from "../../../model/statistics/commodityStatistics.types";
import { CORState } from "../../../model/commodityOfferRequest.types";
import { getArticleStatistics } from "../../../utils/productArticleUtils";

interface SupplierCommodityListingRowProps extends RouteComponentProps {
  commodity: Commodity;
  context: DataContextSupplierType;
}

const SupplierCommodityListingRow: React.FunctionComponent<SupplierCommodityListingRowProps> = ({
  commodity,
  context,
  history,
}) => {
  const masterSpec = commodity.documents.find((d) => d.type === D_MASTERSPECIFICATION);
  const forwardCommodity = (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.stopPropagation();
    history.push(`/commodity/${commodity._id.toString()}`);
  };
  const cor =
    context.commodityOfferRequest.find(
      (c) =>
        ![CORState.CANCELED, CORState.REJECTED].includes(c.state) &&
        c.article.id === commodity._id.toString() &&
        c.supplier === userService.getCompany()
    ) ||
    context.commodityOfferRequest.find(
      (c) => c.article.id === commodity._id.toString() && c.supplier === userService.getCompany()
    );
  const [statistics, setStatistics] = useState<Partial<CommodityStatistics> | undefined>(undefined);

  useEffect(() => {
    getArticleStatistics(commodity._id, ["generalStatistics"]).then((res) => {
      if (res && "generalStatistics" in res) {
        setStatistics(res);
      }
    });
  }, [commodity]);

  const properties = useMemo(() => {
    return resolveProperties(commodity.properties, context.property);
  }, [commodity.properties, context.property]);

  return (
    <tr onClick={forwardCommodity}>
      <td className="align-middle">
        <Link
          className="text-white fs-5 mb-1 custom-link"
          onClick={(e) => e.stopPropagation()}
          to={`/commodity/${commodity._id.toString()}`}
        >
          {commodity.title.en}
          {commodity.organic && <i title={"organic"} className="fas fa-leaf text-success ml-2" />}
        </Link>
        <div>
          <div className="text-muted">{commodity.subtitle.en}</div>
          <PropertiesWidget properties={properties} types={[PropertyType.CATEGORY, PropertyType.COMPOSITION]} />
        </div>
      </td>
      <td className="align-middle">
        <div>
          <div className="text-muted">{commodity.hsCode || "Not Available"}</div>
        </div>
      </td>
      <td className="align-middle">
        <div>
          <div className="text-muted">{commodity.casNumber.join(" / ") || "Not Available"}</div>
        </div>
      </td>
      <td className="align-middle">
        <div>
          {statistics?.generalStatistics?.priceGradient?.rating === -1 ? (
            <div className="text-success">Low Competition</div>
          ) : statistics?.generalStatistics?.priceGradient?.rating === 0 ? (
            <div className="text-muted">Medium Competition</div>
          ) : statistics?.generalStatistics?.priceGradient?.rating === 1 ? (
            <div className="text-danger">High Competition</div>
          ) : (
            <div className="text-muted">No Data</div>
          )}
        </div>
      </td>
      <td className="align-middle">
        <div>
          {statistics?.generalStatistics?.popularity.rating === -1 ? (
            <div className="text-muted">Low Demand</div>
          ) : statistics?.generalStatistics?.popularity.rating === 0 ? (
            <div className="text-muted">Medium Demand</div>
          ) : statistics?.generalStatistics?.popularity.rating === 1 ? (
            <div className="text-success">High Demand</div>
          ) : (
            <div className="text-muted">No Data</div>
          )}
        </div>
      </td>
      <td className="align-middle text-white">
        {cor ? (
          cor.state === CORState.REQUESTED || cor.state === CORState.IN_REVIEW ? (
            <span className="badge badge-warning">Request Pending</span>
          ) : cor.state === CORState.APPROVED ? (
            <span className="badge badge-success">Request Approved</span>
          ) : cor.state === CORState.REJECTED ? (
            <span className="badge badge-danger">Request Rejected</span>
          ) : (
            cor.state === CORState.CANCELED && <span className="badge badge-danger">Request Canceled</span>
          )
        ) : (
          <span className="badge badge-secondary">Not Requested</span>
        )}
      </td>
      <td className="align-middle text-right">
        {masterSpec ? (
          <a href={resolveFilePath(masterSpec.path)} target="_blank" rel="noopener noreferrer">
            <img alt="pdf" className="w-30px mr-2" src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} />
          </a>
        ) : (
          <img
            alt="pdf"
            className="w-30px mr-2 opacity-25"
            style={{ cursor: "not-allowed" }}
            src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
          />
        )}
      </td>
    </tr>
  );
};

export default withRouter(SupplierCommodityListingRow);
