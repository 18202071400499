import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Search from "../../common/Search";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import DateInput from "../../common/DateInput";
import { INBOUND, OUTBOUND } from "../../../utils/SCMUtils";
import { isCustomerOrder, isSupplierOrder, O_TRANSPORTTYPES_INTERNAL } from "../../../utils/orderUtils";
import { SupplierOrderExtended } from "../../../model/supplierOrder.types";
import { CustomerOrderExtended } from "../../../model/customerOrder.types";
import { getSupplierOrderStartString } from "../../../utils/supplierOrderUtils";
import { formatAddress, getStandardWarehouse } from "../../../utils/addressUtils";
import { DataContextInternalType } from "../../../context/dataContext";
import { ForwardingOrder } from "../../../model/forwardingOrder.types";

interface SCMDashboardFilterProps {
  context: DataContextInternalType;
  search: string;
  orderType?: SelectOption;
  transport?: SelectOption;
  startingPoint?: SelectOption;
  destination?: SelectOption;
  supplier?: SelectOption;
  forwarder?: SelectOption;
  etdFrom: Date | null;
  etdTo: Date | null;
  etaFrom: Date | null;
  etaTo: Date | null;
  selectedTab: typeof INBOUND | typeof OUTBOUND;
  availableOrders: Array<SupplierOrderExtended | CustomerOrderExtended>;
  availableForwardingOrders: Array<ForwardingOrder>;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectFilter: (fieldName: string, e: SelectOption) => void;
  onSelectDate: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onToggleFilter: () => void;
}

class SCMDashboardFilter extends PureComponent<SCMDashboardFilterProps> {
  /**
   * Returns all starting points of the displayed orders as selectOptions
   * @returns {Array<SelectOption>} all available starting points
   */
  getStartingPointSelectOptions = (): Array<SelectOption> => {
    const { availableOrders } = this.props;
    const selectOptionsMap: Map<string, SelectOption> = new Map();
    availableOrders.forEach((order) => {
      const value = isSupplierOrder(order) ? getSupplierOrderStartString(order) : "-";
      if (!selectOptionsMap.has(value)) {
        selectOptionsMap.set(value, { value, label: value });
      }
    });
    return Array.from(selectOptionsMap.values());
  };

  /**
   * Returns all destinations of the displayed orders as selectOptions
   * @returns {Array<SelectOption>} all available destinations
   */
  getDestinationSelectOptions = (): Array<SelectOption> => {
    const { availableOrders, context } = this.props;
    const glomm = getStandardWarehouse(context);
    const selectOptionsMap: Map<string, SelectOption> = new Map();
    if (glomm?.address) {
      const glommAddress = formatAddress(glomm.address);
      selectOptionsMap.set(glommAddress, {
        value: glommAddress,
        label: glommAddress,
      });
    } else {
      selectOptionsMap.set("-", {
        value: "-",
        label: "-",
      });
    }
    availableOrders.forEach((order) => {
      const value = isCustomerOrder(order)
        ? typeof order.destination === "string"
          ? order.destination
          : formatAddress(order.destination)
        : undefined;
      if (value !== undefined && !selectOptionsMap.has(value)) {
        selectOptionsMap.set(value, { value, label: value });
      }
    });
    return Array.from(selectOptionsMap.values());
  };

  /**
   * Returns all supppliers of the displayed orders as selectOptions
   * @returns {Array<SelectOption>} all available suppliers
   */
  getSupplierSelectOptions = (): Array<SelectOption> => {
    const { availableOrders } = this.props;
    const selectOptionsMap: Map<string, SelectOption> = new Map();
    availableOrders.forEach((order) => {
      const supplier = order.supplier;
      const value = supplier?.name;
      if (value && !selectOptionsMap.has(value)) {
        selectOptionsMap.set(value, { value, label: value });
      }
    });
    return Array.from(selectOptionsMap.values());
  };

  /**
   * Returns all forwarders of the displayed forwardingOrders as selectOptions
   * @returns {Array<SelectOption>} all forwarders of the displayed forwardingOrders as selectOptions
   */
  getForwarderSelectOptions = (): Array<SelectOption> => {
    const { availableForwardingOrders, context } = this.props;
    const selectOptionsMap: Map<string, SelectOption> = new Map();
    availableForwardingOrders.forEach((fwo) => {
      const value = fwo.forwarder;
      if (value && !selectOptionsMap.has(value)) {
        const forwarder = context.forwarder.find((fwo) => fwo._id.toString() === value);
        selectOptionsMap.set(value, { value, label: forwarder?.name ?? "Unknown name" });
      }
    });
    return Array.from(selectOptionsMap.values());
  };

  render() {
    const {
      search,
      orderType,
      transport,
      startingPoint,
      destination,
      supplier,
      forwarder,
      etdFrom,
      etdTo,
      etaFrom,
      etaTo,
      selectedTab,
      onSearch,
      onSelectFilter,
      onSelectDate,
      onToggleFilter,
    } = this.props;
    const startingPointOptions = this.getStartingPointSelectOptions();
    const destinationOptions = this.getDestinationSelectOptions();
    const supplierOptions = this.getSupplierSelectOptions();
    const forwarderOptions = this.getForwarderSelectOptions();

    return (
      <>
        <div className="row bg-white align-items-end pb-3">
          <div className="col-2 text-white">
            <label className="fs-6 form-label fw-bolder text-dark">Order Type</label>
            <CustomSelect
              options={[
                { value: "customer", label: "Customer Orders" },
                { value: "supplier", label: "Supplier Orders" },
              ]}
              value={orderType}
              isClearable={true}
              onChange={(selectedOption: SelectOption) => onSelectFilter("orderType", selectedOption)}
            />
          </div>
          <div className="col-2 text-white">
            <label className="fs-6 form-label fw-bolder text-dark">Transport</label>
            <CustomSelect
              options={O_TRANSPORTTYPES_INTERNAL}
              value={transport}
              isClearable={true}
              onChange={(selectedOption: SelectOption) => onSelectFilter("transport", selectedOption)}
            />
          </div>
          {selectedTab === INBOUND ? (
            <>
              <div className="col-1 text-white">
                <label className="fs-6 form-label fw-bolder text-dark">ETD from</label>
                <DateInput
                  value={etdFrom}
                  name="etdFrom"
                  classes="form-control custom-form-control bg-light2"
                  onBlur={onSelectDate}
                  allowClear={true}
                  max={etdTo ?? undefined}
                />
              </div>
              <div className="col-1 text-white">
                <label className="fs-6 form-label fw-bolder text-dark">ETD to</label>
                <DateInput
                  value={etdTo}
                  name="etdTo"
                  classes="form-control custom-form-control bg-light2"
                  onBlur={onSelectDate}
                  allowClear={true}
                  min={etdFrom ?? undefined}
                />
              </div>
            </>
          ) : (
            <>
              <div className="col-1 text-white">
                <label className="fs-6 form-label fw-bolder text-dark">ETA from</label>
                <DateInput
                  value={etaFrom}
                  name="etaFrom"
                  classes="form-control custom-form-control bg-light2"
                  onBlur={onSelectDate}
                  allowClear={true}
                  max={etaTo ?? undefined}
                />
              </div>
              <div className="col-1 text-white">
                <label className="fs-6 form-label fw-bolder text-dark">ETA to</label>
                <DateInput
                  value={etaTo}
                  name="etaTo"
                  classes="form-control custom-form-control bg-light2"
                  onBlur={onSelectDate}
                  allowClear={true}
                  min={etaFrom ?? undefined}
                />
              </div>
            </>
          )}
          <div className="col-2 text-white">
            <label className="fs-6 form-label fw-bolder text-dark">Starting Point</label>
            <CustomSelect
              options={startingPointOptions}
              value={startingPoint}
              isClearable={true}
              onChange={(selectedOption: SelectOption) => onSelectFilter("startingPoint", selectedOption)}
            />
          </div>
          <div className="col-3 ml-auto">
            <Search value={search} onSearch={onSearch} />
          </div>
        </div>
        <div className="row bg-white align-items-end pb-3">
          <div className="col-2 text-white">
            <label className="fs-6 form-label fw-bolder text-dark">Destination</label>
            <CustomSelect
              options={destinationOptions}
              value={destination}
              isClearable={true}
              onChange={(selectedOption: SelectOption) => onSelectFilter("destination", selectedOption)}
            />
          </div>
          {selectedTab == INBOUND && (
            <div className="col-2 text-white">
              <label className="fs-6 form-label fw-bolder text-dark">Supplier</label>
              <CustomSelect
                options={supplierOptions}
                value={supplier}
                isClearable={true}
                onChange={(selectedOption: SelectOption) => onSelectFilter("supplier", selectedOption)}
              />
            </div>
          )}
          <div className="col-2 text-white">
            <label className="fs-6 form-label fw-bolder text-dark">Forwarder</label>
            <CustomSelect
              options={forwarderOptions}
              value={forwarder}
              isClearable={true}
              onChange={(selectedOption: SelectOption) => onSelectFilter("forwarder", selectedOption)}
            />
          </div>
          <div className="col text-white">
            <button type="button" className="btn btn-light float-right p-3 ml-3" onClick={onToggleFilter}>
              Hide Filter Options
            </button>
            <Link className="btn btn-light float-right p-3" to="/createForwardingOrder">
              New Forwarding Order
            </Link>
          </div>
        </div>
      </>
    );
  }
}

export default SCMDashboardFilter;
