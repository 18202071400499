import React, { useContext, useMemo } from "react";
import CommodityOfferRequestList from "./commodityOfferRequests/CommodityOfferRequestList";
import { DataContextInternal } from "../../../context/dataContext";
import CustomerRequestList from "./customerRequests/CustomerRequestList";

enum Mode {
  COR,
  REQ,
}

const RequestListing: React.FC = () => {
  const context = useContext(DataContextInternal);

  const [mode, setMode] = React.useState<Mode>(Mode.COR);

  const modeLabel = useMemo(() => {
    switch (mode) {
      case Mode.COR:
        return "Article Offer Requests";
      case Mode.REQ:
        return "Customer Requests";
    }
  }, [mode]);

  return (
    <div className="content d-flex flex-column flex-column-fluid">
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl">
          <div className="card bg-white" style={{ minHeight: "100%" }}>
            <div className="card-body">
              <h3 className="card-title align-items-start flex-column mb-15">
                <span className="card-label fw-bolder mb-3 fs-3rem">{modeLabel}</span>
              </h3>
              <div className="row mb-2">
                <div className="col-12">
                  <div className="btn-group mb-4 w-100">
                    <div
                      className={"w-50 btn btn-light " + (mode === Mode.COR && "active")}
                      onClick={() => setMode(Mode.COR)}
                    >
                      Article Offer Requests
                    </div>
                    <div
                      className={"w-50 btn btn-light " + (mode === Mode.REQ && "active")}
                      onClick={() => setMode(Mode.REQ)}
                    >
                      Customer Requests
                    </div>
                  </div>
                </div>
              </div>
              {mode === Mode.COR ? <CommodityOfferRequestList context={context} /> : <CustomerRequestList />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestListing;
