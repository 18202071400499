import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import { Company, CompanyExtended } from "../../../model/company.types";
import CustomerPageTabPanel from "./CustomerPageTabPanel";
import CustomerPageSummary from "./CustomerPageSummary";
import CustomerPageStats from "./CustomerPageStats";
import { getDocFromCollection } from "../../../utils/baseUtils";
import { COMPANY, getDocumentDB, USERDATA } from "../../../services/dbService";
import { DataContextInternalType } from "../../../context/dataContext";
import { extendCompany } from "../../../utils/dataTransformationUtils";
import { UserData } from "../../../model/userData.types";

interface CompanyParams {
  id: string;
}

interface CompanyProps extends RouteComponentProps<CompanyParams> {
  context: DataContextInternalType;
}

interface CompanyState {
  company?: CompanyExtended;
}

class CustomerPage extends PureComponent<CompanyProps, CompanyState> {
  _isMounted = false;
  constructor(props: CompanyProps) {
    super(props);
    this.state = {
      company: this.getCompany(props),
    };
  }

  componentDidMount = async () => {
    const { match, history, context } = this.props;
    if (this.state.company) return;
    const id = match.params.id;
    if (!id || !BSON.ObjectId.isValid(id)) {
      history.push("/customers");
      return;
    }
    this._isMounted = true;
    const company = await getDocumentDB<Company>(COMPANY, id);
    if (!company) {
      console.error("No customer could be loaded for id", id);
      toast.error("The requested customer could not be loaded");
      history.push("/customers");
      return;
    }
    context.addDocuments(COMPANY, [company]);
    const companyExtended = extendCompany(company, context);
    // If customer was just created it might happen that the primary person is not inside the context yet. We need to
    // manually add it then.
    if (!companyExtended.primaryPerson) {
      const primaryPerson = await getDocumentDB<UserData>(USERDATA, company.primaryPerson);
      if (primaryPerson) {
        context.addDocuments(USERDATA, [primaryPerson]);
        companyExtended.internalContact = primaryPerson;
      }
    }
    if (this._isMounted) this.setState({ company: companyExtended });
  };

  componentDidUpdate(prevProps: Readonly<CompanyProps>) {
    const { match } = this.props;
    if (match.params.id || (!match.params.id && prevProps.match.params.id)) {
      const company = this.getCompany(this.props);
      if (!_.isEqual(company, this.state.company)) {
        this.setState({
          company,
        });
      }
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  /**
   * Get the initial company data
   * @param props the components properties
   * @returns {CompanyExtended | undefined} a company object or undefined
   */
  getCompany = (props: CompanyProps): CompanyExtended | undefined => {
    const company = getDocFromCollection(props.context.company, props.match.params.id);
    if (company) return extendCompany(company, props.context);
  };

  render() {
    const { context } = this.props;
    const { company } = this.state;
    if (!company) return null;
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="d-flex flex-column flex-xl-row">
              <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
                <CustomerPageSummary company={company} context={context} />
                <CustomerPageStats company={company} />
              </div>
              <CustomerPageTabPanel company={company} context={context} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerPage;
