import { Commodity, Price } from "../model/commodity.types";
import { Supplier } from "../model/supplier.types";
import { getFastestDelivery } from "./orderUtils";
import { CO_TRANSPORT, T_AIRFREIGHT, T_EUSTOCK } from "../model/customerOrder.types";
import { DataContextInternalType } from "../context/dataContext";
import { FinishedProduct } from "../model/finishedProduct.types";
import {
  AirFreightCalculationValues,
  AirFreightPriceCalculation,
  EUStockCalculationValues,
  EUStockPriceCalculation,
  isAirFreightCalculation,
  isAirFreightValues,
  isEUStockCalculation,
  isEUStockValues,
  isSeaFreightCalculation,
  isSeaFreightValues,
  LCLSeaFreightCalculationValues,
  LCLSeaFreightPriceCalculation,
  SeaFreightCalculationValues,
  SeaFreightPriceCalculation,
  WarehouseCalculationValues,
  WarehousePriceCalculation,
} from "./priceCalculationUtils";
import {
  getAirFreightSupplierOrderCalculationDetails,
  getEUStockSupplierOrderCalculationDetails,
  getSeaFreightSupplierOrderCalculationDetails,
} from "./supplierOrderUtils";
import { CalculationDetails } from "../model/supplierOrder.types";

import { extendSupplierPrices } from "./dataTransformationUtils";
import { convertCurrency, USD } from "./currencyUtils";
import { getDocFromCollection } from "./baseUtils";

export interface SupplierOrderCalculation {
  totalTurnover: number;
  commodityCost: number;
  warehouseCost?: number; // cost for commodities bought for stock
  transportCost: number;
  customsCost?: number;
  warehouseHandlingCost?: number; // cost for glomm warehouse
  totalMargin: number;
  totalPrice: number;
  totalInsuranceCost?: number;
}

/**
 * Get the best price and the associated supplier for a commodity and amount
 * @param article a commodity or finished product document
 * @param amount the total required amount
 * @param unit the unit of the amount
 * @param transport the type of transport
 * @param context internal context to find supplier
 * @param suppliers optional, list of supplier ids that should only be included
 * @returns {{ supplier: Supplier; price: Price, bestPrice?: boolean } | undefined} object with supplier and price or undefined if no prices are available
 */
export const getBestSupplierAndPrice = (
  article: Commodity | FinishedProduct,
  amount: number,
  unit: "kg" | "ltr" | "1000 pcs",
  transport: CO_TRANSPORT,
  context: DataContextInternalType,
  suppliers?: Array<string>
): { supplier: Supplier; price: Price; bestPrice?: boolean } | undefined => {
  // Collect all prices
  let allPrices: Array<{ supplier: Supplier; price: Price; bestPrice?: boolean }> = [];
  let lowestMOQ = Infinity;
  if (transport === T_EUSTOCK) {
    const sups = suppliers
      ? article.supplierEUStocks?.filter((s) => suppliers.includes(s.supplier))
      : article.supplierEUStocks;
    const cSuppliers = sups?.filter((s) => !s.disabled);
    cSuppliers?.forEach((cSupplier) => {
      cSupplier.prices.forEach((sPrice) => {
        // Ignore outdated and invalid prices
        if (sPrice.price > 0 && sPrice.validUntil >= new Date()) {
          if (sPrice.minOQ < lowestMOQ) lowestMOQ = sPrice.minOQ;
          const supplier = getDocFromCollection(context.supplier, cSupplier.supplier);
          if (supplier) {
            allPrices.push({ supplier, price: sPrice });
          }
        }
      });
    });
  } else {
    const sups = suppliers ? article.suppliers.filter((s) => suppliers.includes(s.supplier)) : article.suppliers;
    const cSuppliers = sups.filter((s) => !s.disabled);
    cSuppliers.forEach((cSupplier) => {
      cSupplier.prices.forEach((sPrice) => {
        // Ignore outdated and invalid prices
        if (sPrice.price > 0 && sPrice.validUntil >= new Date()) {
          if (sPrice.minOQ < lowestMOQ) lowestMOQ = sPrice.minOQ;
          const supplier = getDocFromCollection(context.supplier, cSupplier.supplier);
          if (supplier) {
            allPrices.push({ supplier, price: sPrice });
          }
        }
      });
    });
  }
  if (allPrices.length === 0) {
    console.error("No prices found for article", article._id.toString());
    return;
  }

  const foundMatchingMOQ = amount >= lowestMOQ;
  if (foundMatchingMOQ) {
    // If we found a matching moq remove all non-matching moqs
    allPrices = allPrices.filter((price) => price.price.minOQ <= amount);
  } else {
    // If we did not find a matching moq take the lowest moq
    allPrices = allPrices.filter((price) => price.price.minOQ === lowestMOQ);
  }
  allPrices.sort(
    (a, b) =>
      convertCurrency(a.price.price, a.price.currency, USD, context.currencies) -
      convertCurrency(b.price.price, b.price.currency, USD, context.currencies)
  );
  const finalPrice = allPrices[0];
  if (suppliers) {
    const bestPrice = getBestSupplierAndPrice(article, amount, unit, transport, context);
    if (
      bestPrice &&
      bestPrice.price._id.toString() === finalPrice.price._id.toString() &&
      bestPrice.supplier._id.toString() === finalPrice.supplier._id.toString()
    )
      finalPrice.bestPrice = true;
  } else {
    finalPrice.bestPrice = true;
  }
  return finalPrice;
};

/**
 * Get a 'better' price with higher order quantity for an article, if existing
 * @param article a commodity or finished product document
 * @param currentPrice the current best price to check against
 * @param transport the type of transport
 * @param context Data context, needed to resolve supplier
 * @returns {{ supplier: Supplier; price: Price } | null} object with supplier and price or null if no better price found
 */
export const getBetterPriceOrderQuantity = (
  article: Commodity | FinishedProduct,
  currentPrice: Price,
  transport: CO_TRANSPORT,
  context: DataContextInternalType
): { supplier: Supplier; price: Price } | null => {
  let betterPrice: { supplier: Supplier; price: Price } | null = null;
  const { price, minOQ } = currentPrice;
  let fastest = 0;
  const sups = extendSupplierPrices(article.suppliers, context);
  if (transport === T_AIRFREIGHT) fastest = getFastestDelivery(sups);
  sups
    .filter((s) =>
      s.disabled
        ? false
        : transport === T_AIRFREIGHT
        ? getFastestDelivery(sups, s.supplier._id.toString()) <= fastest
        : true
    )
    .forEach((cSupplier) => {
      cSupplier.prices.forEach((sPrice) => {
        if (
          sPrice.validUntil >= new Date() &&
          sPrice.price < price &&
          sPrice.minOQ > minOQ &&
          (!betterPrice || betterPrice.price.minOQ > sPrice.minOQ)
        ) {
          const supplier = context.supplier.find((s) => s._id.toString() === cSupplier.supplier._id.toString());
          if (supplier) {
            betterPrice = { supplier, price: sPrice };
          }
        }
      });
    });
  return betterPrice;
};

/**
 * Build the CalculationDetails object for the given calculation and calculation values.
 * @param calculation Calculation depending on transport type
 * @param calculationValues Calculation values depending on transport type
 * @returns {CalculationDetails | undefined} Correct calculation details for the given arguments or undefined
 */
export function getCalculationDetails(
  calculation:
    | LCLSeaFreightPriceCalculation
    | AirFreightPriceCalculation
    | SeaFreightPriceCalculation
    | EUStockPriceCalculation
    | WarehousePriceCalculation,
  calculationValues:
    | LCLSeaFreightCalculationValues
    | AirFreightCalculationValues
    | SeaFreightCalculationValues
    | EUStockCalculationValues
    | WarehouseCalculationValues
    | undefined
): CalculationDetails | undefined {
  return isSeaFreightCalculation(calculation) && isSeaFreightValues(calculationValues)
    ? getSeaFreightSupplierOrderCalculationDetails(calculation, calculationValues)
    : isAirFreightCalculation(calculation) && isAirFreightValues(calculationValues)
    ? getAirFreightSupplierOrderCalculationDetails(calculation, calculationValues)
    : isEUStockCalculation(calculation) && isEUStockValues(calculationValues)
    ? getEUStockSupplierOrderCalculationDetails(calculation, calculationValues)
    : undefined;
}
