import _, { isEmpty } from "lodash";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { DataContextAnonymousType, DataContextCustomerType } from "../../../context/dataContext";
import { getCW } from "../../../utils/dateUtils";
import { CustomerCustomerOrder } from "../../../model/customer/customerCustomerOrder.types";
import { formatDate, formatUnit, pluralize, truncateString } from "../../../utils/baseUtils";
import { getOrderNumber, getOrderStateDescriptions } from "../../../utils/orderUtils";
import {
  CO_ARCHIVED,
  CO_CANCELED,
  CO_HANDLEDATWAREHOUSE,
  CO_SHIPPEDTOCUSTOMER,
  CO_SHIPPEDTOWAREHOUSE,
  CO_STATES,
  CO_T_SHIPPED,
  CO_TRANSPORT,
  T_AIRFREIGHT,
  T_EUSTOCK,
  T_SEAFREIGHT,
  T_WAREHOUSE,
} from "../../../model/customerOrder.types";
import { CustomerSupplierOrder } from "../../../model/customer/customerSupplierOrder.types";
import { getPortCode } from "../../../utils/supplierOrderUtils";
import { getAddressByType } from "../../../utils/addressUtils";
import userService from "../../../services/userService";
import { AddressType } from "../../../model/commonTypes";

interface CustomerDashboardDeliveriesProps {
  context: DataContextCustomerType | DataContextAnonymousType;
}

interface CustomerDashboardDeliveriesState {
  orders: { [cw: string]: Array<CustomerCustomerOrder> };
  activeCW: string;
}

class CustomerDashboardDeliveries extends PureComponent<
  CustomerDashboardDeliveriesProps,
  CustomerDashboardDeliveriesState
> {
  validCWs: Array<{ cw: number; year: number }> = [];
  constructor(props: CustomerDashboardDeliveriesProps) {
    super(props);
    const now = new Date();
    const weekInMs = 1000 * 60 * 60 * 24 * 7;
    const week1 = new Date(now.getTime() + weekInMs);
    const week2 = new Date(now.getTime() + weekInMs * 2);
    const week3 = new Date(now.getTime() + weekInMs * 3);
    const week4 = new Date(now.getTime() + weekInMs * 4);
    const week5 = new Date(now.getTime() + weekInMs * 5);
    this.validCWs = [
      { cw: getCW(now), year: now.getFullYear() },
      { cw: getCW(week1), year: week1.getFullYear() },
      { cw: getCW(week2), year: week2.getFullYear() },
      { cw: getCW(week3), year: week3.getFullYear() },
      { cw: getCW(week4), year: week4.getFullYear() },
      { cw: getCW(week5), year: week5.getFullYear() },
    ];
    this.state = { activeCW: getCW(new Date()).toString(), orders: {} };
  }

  componentDidMount() {
    this.setState({ orders: this.prepareOrders() });
  }

  componentDidUpdate(
    prevProps: Readonly<CustomerDashboardDeliveriesProps>,
    prevState: Readonly<CustomerDashboardDeliveriesState>
  ) {
    const { orders } = this.state;
    if (!_.isEqual(prevProps.context.customerOrder, this.props.context.customerOrder)) {
      this.setState({ orders: this.prepareOrders() });
    } else if (!_.isEqual(prevState.orders, orders)) {
      const currentCW = getCW(new Date());
      const firstContent =
        orders[currentCW.toString()] && orders[currentCW.toString()].length > 0
          ? [currentCW.toString()]
          : Object.entries(orders).find((entry) => entry[1].length > 0);
      this.setState({ activeCW: firstContent ? firstContent[0] : this.state.activeCW });
    }
  }

  handleChangeCW = (activeCW: string) => this.setState({ activeCW });

  prepareOrders = () => {
    const { context } = this.props;
    const orderMap: { [cw: string]: Array<CustomerCustomerOrder> } = {};
    for (let i = 0; i < context.customerOrder.length; i++) {
      const o = context.customerOrder[i];
      if (([CO_CANCELED, CO_ARCHIVED] as Array<CO_STATES>).includes(o.state)) continue;
      const oCW = getCW(o.changedETA ?? o.targetDate);
      let key = oCW.toString();
      if (oCW < this.validCWs[0].cw && (o.changedETA ?? o.targetDate).getFullYear() <= this.validCWs[0].year) continue;
      if (
        oCW > this.validCWs[this.validCWs.length - 1].cw &&
        (o.changedETA ?? o.targetDate).getFullYear() >= this.validCWs[this.validCWs.length - 1].year
      )
        key = "future";
      if (key in orderMap) {
        orderMap[key].push(o);
      } else {
        orderMap[key] = [o];
      }
    }
    return orderMap;
  };

  render() {
    const { context } = this.props;
    const { orders, activeCW } = this.state;
    const amountOrdersSoon = !isEmpty(orders)
      ? (orders[this.validCWs[0].cw.toString()]?.length || 0) +
        (orders[this.validCWs[1].cw.toString()]?.length || 0) +
        (orders[this.validCWs[2].cw.toString()]?.length || 0) +
        (orders[this.validCWs[3].cw.toString()]?.length || 0)
      : 0;

    return (
      <div className="card h-100 bg-white">
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">Deliveries</span>
            <span className="text-muted fw-bold fs-7">
              {pluralize(amountOrdersSoon, "Order")} will be delivered in the next 4 weeks
            </span>
          </h3>
        </div>
        <div className="card-body" style={{ overflowY: "auto" }}>
          <ul
            className="delivery-nav nav-stretch nav-pills nav-pills-custom nav-pills-active-custom d-flex justify-content-between mb-3"
            role="tablist"
          >
            {this.validCWs.map(({ cw, year }) => (
              <li
                key={`${cw}-${year}`}
                className={"delivery-nav-item p-0 ms-0 bg-light mx-2 " + (cw.toString() === activeCW && "active")}
                onClick={() => this.handleChangeCW(cw.toString())}
              >
                <div className="nav-link btn d-flex flex-column flex-center py-1 px-0 ">
                  <span className="fs-5 fw-bold">CW {cw}</span>
                  <span className="fs-7 fw-semibold text-muted ">
                    {pluralize(orders ? orders[cw.toString()]?.length || 0 : 0, "order")}
                  </span>
                </div>
              </li>
            ))}
            <li
              className={"delivery-nav-item p-0 ms-0 bg-light " + (activeCW === "future" && "active")}
              onClick={() => this.handleChangeCW("future")}
            >
              <div className="nav-link btn d-flex flex-column flex-center h-100 px-0">
                <i className="fa fa-arrow-right"></i>
              </div>
            </li>
          </ul>
          {orders && orders[activeCW]?.length > 0 ? (
            orders[activeCW].map((o) => (
              <CustomerDashboardDelivery key={o._id.toString()} context={context} order={o} />
            ))
          ) : (
            <div className="p-7 text-center text-muted h6">
              No Orders that are scheduled to arrive{" "}
              {activeCW !== "future" ? `in CW-${activeCW}` : `after CW-${this.validCWs[5].cw.toString()}`}
            </div>
          )}
        </div>
        <div className="card-footer border-top-0 pt-4">
          <Link to="/orders">
            <button type="button" className="btn btn-light w-100">
              Show All Deliveries
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

interface CustomerDashboardDeliveryProps {
  context: DataContextCustomerType | DataContextAnonymousType;
  order: CustomerCustomerOrder;
}

const WAREHOUSE = "Warehouse";

class CustomerDashboardDelivery extends PureComponent<CustomerDashboardDeliveryProps> {
  resolveOrderIcon = () => {
    const { order } = this.props;
    switch (order.transport) {
      case T_AIRFREIGHT:
        return "fa-plane";
      case T_SEAFREIGHT:
        return "fa-ship";
      default:
        return "fa-shipping-fast";
    }
  };

  getRelatedSO = () => {
    const { order, context } = this.props;
    return context.supplierOrder.find((sO) => sO.customerOrders.includes(order._id.toString()));
  };

  getDeliveryData = (sO?: CustomerSupplierOrder) => {
    const { order, context } = this.props;
    if (
      ([T_WAREHOUSE, T_EUSTOCK] as Array<CO_TRANSPORT>).includes(order.transport) ||
      ([CO_HANDLEDATWAREHOUSE, CO_SHIPPEDTOCUSTOMER] as Array<CO_STATES>).includes(order.state)
    ) {
      const shipDate = order.timeline.find((t) => t.type === CO_T_SHIPPED)?.date;
      let deliveryCity = order.terms?.deliveryCity;
      if (!deliveryCity) {
        const company = context.company.find((c) => c._id.toString() === userService.getCompany());
        const delAddr = getAddressByType(company?.address, AddressType.A_SHIPPING);
        deliveryCity = delAddr?.city;
      }
      return {
        startName: WAREHOUSE,
        startDate: shipDate,
        endName: deliveryCity ?? "You",
        endDate: order.deliveryDate ?? order.targetDate,
        icon: "fa-shipping-fast",
      };
    }
    if (!sO || sO.shipment.length === 0) {
      return {
        startName: "TBD",
        startDate: undefined,
        endName: "TBD",
        endDate: undefined,
        icon: this.resolveOrderIcon(),
      };
    }
    const shipment = sO.shipment[0];
    const { startingPoint, destination, shipped, arrivedAtStartingPort, eta } = shipment.shipping;
    const data = {
      startName: getPortCode(startingPoint ?? "Various Port"),
      startDate: shipped ?? arrivedAtStartingPort,
      endName: getPortCode(destination ?? "Various Port"),
      endDate: eta,
      icon: this.resolveOrderIcon(),
    };
    if (order.state === CO_SHIPPEDTOWAREHOUSE) {
      data.startName = data.endName;
      data.startDate = data.endDate;
      data.endName = WAREHOUSE;
      data.endDate = new Date((data.endDate || new Date()).getTime() + 1000 * 60 * 60 * 3);
      data.icon = "fa-shipping-fast";
    }
    return data;
  };

  /**
   * Shortens the commodity title based upon the current innerWidth.
   * @param title Title that should be shortened
   * @returns {string} Shortened title
   */
  shortenCommodityTitle = (title: string): string => {
    const { innerWidth } = this.props.context;
    if (innerWidth > 1900) return truncateString(title, 30);
    if (innerWidth > 1800) return truncateString(title, 26);
    if (innerWidth > 1700) return truncateString(title, 23);
    return truncateString(title, 20);
  };

  render() {
    const { order, context } = this.props;
    const { innerWidth } = context;
    const relatedSO = this.getRelatedSO();
    const { startName, startDate, endName, endDate, icon } = this.getDeliveryData(relatedSO);

    return (
      <Link to={`/order/${order._id.toString()}`} className="cursor-pointer">
        <div className="d-flex align-items-center bg-light rounded p-5 mb-3">
          <div className="flex-grow-1 mr-2">
            <div className="d-inline-flex fw-bolder text-gray-800  flex-center flex-stack">
              <span className="fs-6">
                {innerWidth > 1750 && <span>Order </span>}
                {getOrderNumber(order)}
              </span>
              <span className="text-success ml-3" style={{ fontSize: ".8rem" }}>
                <div className="d-flex flex-center">
                  <div
                    style={{
                      width: "5px",
                      height: "5px",
                      borderRadius: "5px",
                      backgroundColor: "#47be7d",
                    }}
                  >
                    &nbsp;
                  </div>
                  <span className="ml-1">{getOrderStateDescriptions(order).title}</span>
                </div>
              </span>
            </div>
            <span className="text-muted d-block">
              <span className="mr-3">
                <b>{formatUnit(order.amount, order.unit)}</b> {this.shortenCommodityTitle(order.commodity.title.en)}
              </span>
            </span>
          </div>
          {innerWidth > 1850 && (
            <div className="text-center mr-2 mr-xl-4 fs-1">
              <i className={"fa fs-3 text-muted " + icon} />
            </div>
          )}
          <div className="text-center">
            <div className="fw-bold fs-6 text-gray-400">{startName}</div>
            <span className="text-muted mb-1">{startDate ? formatDate(startDate) : "Preparation"}</span>
          </div>
          <div className={"text-center " + (innerWidth > 1700 ? "mx-4" : "mx-2")}>
            <div className="fw-bold fs-6 text-gray-400">
              <i className="fa fa-arrow-right" />
            </div>
          </div>
          <div className="text-center">
            <div className="fw-bold fs-6 text-gray-400">{endName}</div>
            <span className="text-muted mb-1">{endDate ? formatDate(endDate) : "TBD"}</span>
          </div>
          {innerWidth > 1750 && (
            <div className="text-right ml-2 ml-xl-4">
              <div className="fw-bold fs-6 text-gray-400">CW {getCW(order.changedETA ?? order.targetDate)}</div>
              <span className="text-muted mb-1">ETA</span>
            </div>
          )}
        </div>
      </Link>
    );
  }
}

export default CustomerDashboardDeliveries;
