import { formatCurrency } from "../baseUtils";
import { CustomerContractExtended } from "../../model/customerContract.types";
import { getContractNumber } from "../customerContractUtils";
import { CONTRACTDELIVERY, ORGANICCODE } from "./templateUtils";
import { getCW } from "../dateUtils";
import { formatAddress } from "../addressUtils";
import { formatArticleUnit } from "../productArticleUtils";

/**
 * Creates the order confirmation HTML
 * @param contract Contract that should be confirmed
 * @param notes Notes below positions
 * @param draft optional, flag if a draft is created
 * @returns { string } a html string that can be used to create the PDF
 */
export function createContractConfirmationHTML(
  contract: CustomerContractExtended,
  notes: string,
  draft?: boolean
): string {
  const address = contract.company.address[0];
  return `<head><link href="https://fonts.googleapis.com/css?family=Signika&display=swap" rel="stylesheet"><style>tr:nth-child(even) {background-color: white;}</style><meta http-equiv="content-type" content="text/html; charset=utf-8"><title></title></head>
    <body style="font-family: Signika, sans-serif">
    <img src="https://rawbids.com/images/logo-dark.png" alt="Logo" width="180" style="float:right"/>
    <br/><br/><br/><br/>
    <span style="font-size: 30px; font-weight: bold">Contract Confirmation ${
      draft ? "Draft" : getContractNumber(contract)
    }</span>
    <br/>
    <span style="font-size: 20px; font-weight: bold">${contract.commodity.title.en}</span>
    <br/><br/><br/>
    <table cellpadding="5" cellspacing="0" style="width: 100%">
      <tbody>
        <tr style="width: 100%; vertical-align: top">
          <td style="vertical-align: top"><span style="font-size:16px;  width: 33%; vertical-align: top">Recipient:<br/><b>${
            contract.company.name
          }</b><br/>${formatAddress(address).replace(/\n/g, "<br/>")}<br/>${
    contract.company.vat ? "VAT-ID: " + contract.company.vat : ""
  }</span>
          </td>
          <td style="vertical-align: top"><span style="font-size: 16px; width: 33%; vertical-align: top">Issued by:<br/><b>RAWBIDS GmbH</b><br/>Willy-Brandt-Str. 23, 20457 Hamburg<br/>Germany<br/>VAT-ID: DE354508196</span></td>
          <td style="text-align: right; vertical-align: top"><span style="font-size:16px; width: 33%; vertical-align: top"><b>Contract number: ${
            draft ? "Draft" : getContractNumber(contract)
          }</b>
          <br/>Order date: ${new Date().toLocaleDateString()}
          ${contract.commodity.organic ? `<br/>${ORGANICCODE}` : `<br/>`}
          ${contract.customerReference && `<br/><span>Reference number: ${contract.customerReference}</span>`}
          <br/>Validity period: ${contract.validityPeriod.start.toLocaleDateString()} to ${contract.validityPeriod.end.toLocaleDateString()}
    <br/>Contact: info@rawbids.com</span></td>
        </tr>
      </tbody>
    </table>
    <br/>
    <table style="font-size:16px; width: 100%; display: block; margin-top:20px; margin-bottom:20px">
      <tbody>
        <tr>
          <td>Dear Sir or Madam, <br/><br/>We hereby confirm to you
the acceptance of contract ${draft ? "Draft" : getContractNumber(contract)} as follows:
          </td>
        </tr>
      </tbody>
    </table>
    <table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px" >
      <thead>
        <tr style="background-color:#333333; color: #ffffff">
          <th><b>Pos.</b></th>
          <th style="min-width: 250px"><b style="white-space:nowrap">Description</b></th><th><b>Amount</b></th><th><b style="white-space:nowrap;">Unit</b></th><th><b style="white-space:nowrap;">Per unit</b></th>
          <th><b style="white-space:nowrap;">Discount</b></th><th><b style="white-space:nowrap; float: right">Total</b></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>&nbsp;&nbsp;${1}</td>
          <td style="width: 45%"><b>${contract.commodity.title.en}</b><br/>${contract.commodity.subtitle.en}</td>
          <td>&nbsp;&nbsp;${contract.contractInformation.totalAmount}</td>
          <td>&nbsp;&nbsp;${contract.commodity.unit}</td>
          <td>&nbsp;&nbsp;${formatCurrency(
            contract.priceInformation.totalPrice / contract.contractInformation.totalAmount,
            contract.priceInformation.currency
          )}</td>
          <td>&nbsp;&nbsp;${contract.priceInformation.discount ? contract.priceInformation.discount + "%" : ""}</td>
          <td style="float: right; text-align: right">${formatCurrency(
            contract.priceInformation.totalPrice * (1 - (contract.priceInformation.discount || 0) / 100),
            contract.priceInformation.currency
          )}
          </td>
        </tr>
      </tbody>
    </table>
    <br/>
    <table style="width: 50%; float: right; display: block">
      <tbody>
        <tr>
          <td>Total</td>
          <td style="float: right; text-align: right"><span style="float: right; text-align: right"><b style="font-size: 20px">${formatCurrency(
            contract.priceInformation.totalPrice * (1 - (contract.priceInformation.discount || 0) / 100),
            contract.priceInformation.currency
          )}</b></span>
          </td>
        </tr>
      </tbody>
   </table>
   <br/><br/><br/><br/><br/><br/>
   <table style="font-size:16px; width: 100%; display: block">
    <tbody>
      <tr>
        <td>
        <b>Terms of delivery: </b>${contract.terms.deliveryTerms}
        <br /><br />
        <b>Terms of payment: </b>${
          contract.terms.paymentTerms
            ? contract.terms.paymentTermConditions
              ? contract.terms.paymentTerms + " " + contract.terms.paymentTermConditions
              : contract.terms.paymentTerms
            : contract.terms.paymentTermConditions
            ? contract.terms.paymentTermConditions
            : ""
        }
        <br /><br />
        ${contract.terms.note ? `<b>Additional information: </b>${contract.terms.note}<br /><br />` : ""}
        <b>Delivery: </b>Minimum quantity per call-off ${
          contract.contractInformation.minimumCallQuantity
        }${formatArticleUnit(contract.commodity.unit)} net
        <br /><br />
        <b>Delivery time: </b>${
          contract.targetDate
            ? `available around CW ${getCW(contract.targetDate)}-${contract.targetDate.getFullYear()}`
            : CONTRACTDELIVERY
        }
        <br /><br />
         </td>
        </tr>
      </tbody>
    </table>
    <br/><br/><br/>
    <table style="font-size:16px; width: 100%; display: block">
      <tbody>
        <tr>
          <td><span style="font-size:22px; font-weight: bold;">Notes:</span><br/><br/>${notes}</td>
        </tr>
      </tbody>
    </table>
  </body>`;
}
