import _ from "lodash";
import React, { PureComponent } from "react";
import { DataContextInternalType } from "../../../../context/dataContext";
import { CustomerOrder } from "../../../../model/customerOrder.types";
import Search from "../../../common/Search";
import { CompanyExtended } from "../../../../model/company.types";
import { doFuseSearch, pluralize } from "../../../../utils/baseUtils";
import OrderWidget from "../../../common/OrderWidget";
import CustomerPageUpdates from "../CustomerPageUpdates";
import { sortCustomerOrdersByState } from "../../../../utils/customerOrderUtils";
import { extendCustomerOrder } from "../../../../utils/dataTransformationUtils";

interface CustomerPageOrdersProps {
  company: CompanyExtended;
  context: DataContextInternalType;
}

interface CustomerPageOrdersState {
  activeOrders: Array<CustomerOrder>;
  search: string;
}

class CustomerPageOrders extends PureComponent<CustomerPageOrdersProps, CustomerPageOrdersState> {
  constructor(props: CustomerPageOrdersProps) {
    super(props);
    this.state = {
      search: "",
      activeOrders: this.getRelevantOrders(props),
    };
  }

  componentDidUpdate = (prevProps: Readonly<CustomerPageOrdersProps>, prevState: Readonly<CustomerPageOrdersState>) => {
    if (!_.isEqual(prevProps.company, this.props.company)) {
      this.setState({ activeOrders: this.getRelevantOrders(this.props) });
      return;
    }
    // Update if a change appeared, but it was not a state update, e.g. search. We assume it must be context update then
    if (_.isEqual(prevState, this.state)) {
      const orders = this.getRelevantOrders(this.props);
      if (!_.isEqual(orders, this.state.activeOrders)) this.setState({ activeOrders: orders });
    }
  };

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });

  getRelevantOrders = (props: CustomerPageOrdersProps) => {
    const { customerOrder } = this.props.context;
    return customerOrder.filter((cO) => cO.company === props.company._id.toString());
  };

  render() {
    const { context } = this.props;
    const { search, activeOrders } = this.state;
    const filteredOrders = search.trim()
      ? doFuseSearch(activeOrders, search, ["orderNo", "commodity.title.en", "amount"])
      : activeOrders;
    return (
      <>
        <div className="card bg-white">
          <div className="card-header border-0 mt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder fs-3 mb-1">Current Orders</span>
              <span className="text-muted fw-bold fs-7">{pluralize(activeOrders.length, "Order")}</span>
            </h3>
            <div className="card-toolbar">
              <Search onSearch={this.handleSearch} value={search} />
            </div>
          </div>
          <div className="card-body modal-content-scroll">
            {(sortCustomerOrdersByState(filteredOrders) as Array<CustomerOrder>).map((o) => (
              <OrderWidget key={o._id.toString()} type="commodity" order={extendCustomerOrder(o, context)} />
            ))}
          </div>
        </div>
        <CustomerPageUpdates orders={activeOrders} context={context} />
      </>
    );
  }
}

export default CustomerPageOrders;
