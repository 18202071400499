import { formatCurrency } from "../baseUtils";
import { getOrderNumber } from "../orderUtils";
import { SampleOrderExtended } from "../../model/sampleOrder.types";
import { ORGANICCODE } from "./templateUtils";

/**
 * Creates the sample order confirmation HTML
 * @param order Order that should be confirmed
 * @param companyName Name of the company
 * @param companyAddress Address of the company
 * @param companyVAT VAT of the company
 * @param notes Notes below positions
 * @returns { string } a html string that can be used to create the PDF
 */
export function createSampleOrderConfirmationHTML(
  order: SampleOrderExtended,
  companyName: string,
  companyAddress: string,
  companyVAT: string,
  notes: string
): string {
  return `
    <head>
      <link href='https://fonts.googleapis.com/css?family=Signika&display=swap' rel='stylesheet'>
      <style>tr:nth-child(even) {background-color: white;}</style>
      <meta http-equiv='content-type' content='text/html; charset=utf-8'>
    </head>
    <body style='font-family: Signika, sans-serif'>
      <img src='https://rawbids.com/images/logo-dark.png' alt="Rawbids logo" width='180' style='float:right'/>
      <br/><br/><br/>
        <span style='font-size: 30px; font-weight: bold'>
          Sample Order Confirmation ${getOrderNumber(order)}
        </span>
      <br>
        <span style='font-size: 20px; font-weight: bold'>
        ${order.commodity.title.en}
        </span>
        <br/><br/><br/><br/>
        <table cellpadding='5' cellspacing='0' style='width: 100%'>
          <tbody>
            <tr style='width: 100%; vertical-align: top'>
              <td style='vertical-align: top'>
                <span style='font-size:16px;  width: 33%; vertical-align: top'>Recipient:<br/>
                  <b>${companyName}</b>
                  <br/>${companyAddress.replace(/\n/g, "<br/>")}<br/>
                  ${companyVAT ? "VAT-ID: " + companyVAT : ""}
                </span>
              </td>
              <td style='vertical-align: top'>
                <span style='font-size:16px; width: 33%; vertical-align: top'>Issued by:<br/>
                <b>RAWBIDS GmbH</b>
                <br/>Willy-Brandt-Str. 23, DE - 20457 Hamburg<br/>Germany<br/>VAT-ID: DE354508196</span>
              </td>
              <td style='text-align: right; vertical-align: top'>
                <span style='font-size:16px; width: 33%; vertical-align: top'>
                  <b>Order number: ${order.orderNo}</b>
                  ${order.commodity.organic ? `<br/>${ORGANICCODE}` : ""}
                  <br/>Order date: ${new Date().toLocaleDateString()}
                  <br/>ETA: ${order.targetDate.toLocaleDateString()}<br/>
                  <br/>Contact: info@rawbids.com
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </br>
      <table style='font-size:16px; width: 100%; display: block; margin-top:20px; margin-bottom:20px'>
        <tbody>
          <tr><td>
            Dear Sir or Madam, We hereby confirm to you the acceptance of sample order ${getOrderNumber(
              order
            )} as follows:
          </td></tr>
        </tbody>
      </table>
      <table cellpadding='5' cellspacing='0' style='font-size:15px; width:100%; background-color:#fafafa; margin-top:20px' >
        <thead>
          <tr style='background-color:#333333; color: #ffffff'>
            <th><b>Pos.</b></th>
            <th style='min-width: 250px'><b style='white-space:nowrap'>Description</b></th>
            <th><b>Amount</b></th>
            <th><b style='white-space:nowrap;'>Unit</b></th>
            <th><b style='white-space:nowrap;'>Per unit</b></th>
            <th><b style='white-space:nowrap;'>VAT</b></th>
            <th><b style='white-space:nowrap;'>Discount</b></th>
            <th><b style='white-space:nowrap; float: right'>Total</b></th>
          </tr>
        </thead>
        <tbody>
          <tr><td>&nbsp;&nbsp;${1}</td>
          <td style='width: 45%'><b>Sample of </b><b>${order.commodity.title.en}</b><br/>
            ${order.commodity.subtitle.en}
          </td>
          <td>${order.amount}</td>
          <td>${order.unit}</td>
          <td>${formatCurrency(order.totalPrice / order.amount, order.currency)}</td>
          <td>19%</td>
          <td></td>
          <td style='float: right; text-align: right'>${formatCurrency(order.totalPrice, order.currency)}</td></tr>
        </tbody>
      </table>
      <br/>
      <table style='width: 50%; float: right; display: block'>
        <tbody>
          <tr>
            <td>Subtotal (net)</td>
            <td style='float: right; text-align: right'>
              <span style='float: right; text-align: right'>
                <b>${formatCurrency(order.totalPrice, order.currency)}</b>
              </span>
            </td>
          </tr>
          <tr>
            <td>VAT 19% (of ${formatCurrency(order.totalPrice, order.currency)})</td>
            <td style='float: right; text-align: right'>
              <span style='float: right; text-align: right'>
                <b>${formatCurrency(order.totalPrice * 0.19, order.currency)}</b>
              </span>
            </td>
          </tr>
          <tr>
            <td>Total</td>
            <td style='float: right; text-align: right'>
              <span style='float: right; text-align: right'>
                <b style='font-size: 20px'>${formatCurrency(order.totalPrice * 1.19, order.currency)}</b>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
        <br/><br/><br/><br/><br/><br/>
      <table style='font-size:16px; width: 100%; display: block'>
        <tbody>
          <tr><td>
            <span style='font-size:22px; font-weight: bold;'>Notes:</span><br/><br/>${notes}
          </td></tr>
        </tbody>
      </table>
    </body>
  `;
}
