import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import GraduationCard, { GraduationReference } from "../../settings/internal/graduationValues/GraduationCard";
import { isFinishedProduct } from "../../../utils/finishedProductUtils";
import { COMMODITY, FINISHEDPRODUCT, transaction, UpdateAction } from "../../../services/dbService";
import { ArticleGraduationType } from "../../../model/configuration/graduationConfiguration.types";
import { CALC_AIRFREIGHT, CALC_SEAFREIGHT } from "../../../model/supplierOrder.types";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { InternalArticleExtended } from "../../../utils/productArticleUtils";

interface ArticleGraduationProps {
  article: InternalArticleExtended;
}

interface ArticleGraduationState {
  saving: boolean;
  graduation: ArticleGraduationType;
  seaGraduationsErrors: Array<string>;
  airGraduationsErrors: Array<string>;
}

const ArticleGraduation: React.FunctionComponent<ArticleGraduationProps> = ({ article }) => {
  const { packagingSizes } = article;

  // case: For this article are no valid graduation configurable
  if (!packagingSizes || packagingSizes.length === 0) return <></>;

  const [{ saving, graduation, seaGraduationsErrors, airGraduationsErrors }, setState] =
    useState<ArticleGraduationState>({
      saving: false,
      graduation: { sea: [], air: [] },
      seaGraduationsErrors: [],
      airGraduationsErrors: [],
    });

  useEffect(() => {
    const graduation = article.graduation ?? { sea: [], air: [] };
    setState((prevState) => {
      return {
        ...prevState,
        graduation: graduation,
      };
    });
  }, [article.graduation]);

  useEffect(() => {
    validateData();
  }, [graduation]);

  const handleChangeGraduation = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, idx: number, reference?: GraduationReference) => {
      if (!e.target.value || !reference) return;
      const graduationCopy = _.clone(graduation);
      const newGraduationValue = calculateNearestValidGraduationValue(Number(e.target.value));
      if (reference.tab === CALC_SEAFREIGHT) {
        if (!graduationCopy.sea.some((value) => value === newGraduationValue))
          graduationCopy.sea[idx] = newGraduationValue;
      } else {
        if (!graduationCopy.air.some((value) => value === newGraduationValue))
          graduationCopy.air[idx] = newGraduationValue;
      }
      setState((prevState) => {
        return {
          ...prevState,
          graduation: graduationCopy,
        };
      });
    },
    [graduation]
  );

  const handleAddGraduation = useCallback(
    (reference?: GraduationReference) => {
      if (!reference) return;
      const graduationCopy = _.clone(graduation);
      if (reference.tab === CALC_SEAFREIGHT) {
        graduationCopy.sea.push(0);
      } else {
        graduationCopy.air.push(0);
      }

      setState((prevState) => {
        return {
          ...prevState,
          graduation: graduationCopy,
        };
      });
    },
    [graduation]
  );

  const handleDeleteGraduation = useCallback(
    (idx: number, reference?: GraduationReference) => {
      if (!reference) return;
      const graduationCopy = _.clone(graduation);
      if (reference.tab === CALC_SEAFREIGHT) {
        graduationCopy.sea.splice(idx, 1);
      } else {
        graduationCopy.air.splice(idx, 1);
      }
      setState((prevState) => {
        return {
          ...prevState,
          graduation: graduationCopy,
        };
      });
    },
    [graduation]
  );

  const handleSaveGraduation = async () => {
    setState((prevState) => {
      return {
        ...prevState,
        saving: true,
      };
    });

    try {
      const action: UpdateAction = {
        collection: isFinishedProduct(article) ? FINISHEDPRODUCT : COMMODITY,
        filter: { _id: article._id },
      };

      const updateObject: ArticleGraduationType = {
        sea: graduation.sea.sort((a, b) => a - b),
        air: graduation.air.sort((a, b) => a - b),
      };
      // Update both entries so that if only one is created the other is created too
      action.update = { graduation: updateObject };

      const res = await transaction([action]);
      if (res) {
        toast.success("Graduation successfully saved!");
      } else toast.error("Error saving graduation. Please try again later.");
    } catch (e) {
      console.error("Graduation could not be saved:", e);
    } finally {
      setState((prevState) => {
        return {
          ...prevState,
          saving: false,
        };
      });
    }
  };

  const validateData = useCallback(() => {
    const newSeaGraduationErrors: Array<string> = [];
    const newAirGraduationErrors: Array<string> = [];

    if (graduation.sea.some((value) => value === 0)) {
      newSeaGraduationErrors.push("0 is not a valid value.");
    }

    if (graduation.air.some((value) => value === 0)) {
      newAirGraduationErrors.push("0 is not a valid value.");
    }

    setState((prevState) => {
      return {
        ...prevState,
        seaGraduationsErrors: newSeaGraduationErrors,
        airGraduationsErrors: newAirGraduationErrors,
      };
    });
  }, []);

  const calculateNearestValidGraduationValue = useCallback(
    (graduationValue: number) => {
      let minimumUpperRemaining = Infinity;
      let minimumRest = Infinity;
      packagingSizes.forEach((packagingSize) => {
        const rest = graduationValue % packagingSize.packagingSize;
        const upperRemaining = packagingSize.packagingSize - rest;
        if (minimumUpperRemaining > upperRemaining) minimumUpperRemaining = upperRemaining;
        if (minimumRest > rest) minimumRest = rest;
      });
      if (minimumRest < minimumUpperRemaining) return graduationValue - minimumRest;
      return graduationValue + minimumUpperRemaining;
    },
    [packagingSizes]
  );

  const graduationSeaReference: GraduationReference = useMemo(() => {
    return {
      type: "article",
      id: article._id.toString(),
      tab: CALC_SEAFREIGHT,
    };
  }, []);

  const graduationAirReference: GraduationReference = useMemo(() => {
    return {
      type: "article",
      id: article._id.toString(),
      tab: CALC_AIRFREIGHT,
    };
  }, []);

  return (
    <div className="card bg-white mt-5">
      <div className="card-header border-0 mt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3 mb-1">Graduation</span>
        </h3>
      </div>
      <div className="p-6 ml-3 mr-3 mb-7 badge badge-warning fs-7 text-black text-wrap lh-base">
        <div className="row">
          <div className="col-1">
            <i className="flaticon-information text-black" style={{ fontSize: "50px", color: "#000000" }} />
          </div>
          <div className="col-11 mt-1">
            The validity of the graduation values entered depends on the sizes of the available packaging. Therefore,
            each entry is checked for validity and, if necessary, automatically adjusted to the closest possible value.
          </div>
        </div>
      </div>
      <div className="row p-4 mb-7">
        <div className="col-6 rounded">
          <GraduationCard
            title="Sea Freight"
            reference={graduationSeaReference}
            graduations={graduation.sea}
            onChangeGraduation={handleChangeGraduation}
            onAddGraduation={handleAddGraduation}
            onDeleteGraduation={handleDeleteGraduation}
          >
            <ErrorOverlayButton
              errors={seaGraduationsErrors}
              className={"btn btn-text-success btn-sm float-right"}
              buttonText="Save"
              saving={saving}
              onClick={saving ? undefined : handleSaveGraduation}
            />
          </GraduationCard>
        </div>
        <div className="col-6 rounded">
          <GraduationCard
            title="Air Freight"
            reference={graduationAirReference}
            graduations={graduation.air}
            onChangeGraduation={handleChangeGraduation}
            onAddGraduation={handleAddGraduation}
            onDeleteGraduation={handleDeleteGraduation}
            isFullyDeletable
          >
            <ErrorOverlayButton
              errors={airGraduationsErrors}
              className={"btn btn-text-success btn-sm float-right"}
              buttonText="Save"
              saving={saving}
              onClick={saving ? undefined : handleSaveGraduation}
            />
          </GraduationCard>
        </div>
      </div>
    </div>
  );
};
export default ArticleGraduation;
