import _ from "lodash";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { SupplierExtended } from "../../../model/supplier.types";
import { Textarea } from "../../common/Textarea";
import { updateSupplier } from "../../../utils/supplierUtils";

interface SupplierNotesProps {
  supplier: SupplierExtended;
}

const SupplierNotes: React.FunctionComponent<SupplierNotesProps> = ({ supplier }) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>("");

  /**
   * Update notes of a specific supplier
   */
  const handleSaveNotes = async () => {
    // create update object
    const updateObject = _.cloneDeep(supplier);
    updateObject.notes = notes;

    // set update flag
    setSaving(true);

    try {
      // update supplier notes
      const result = await updateSupplier({ notes: notes }, supplier._id);

      // check result of updating
      if (result) {
        toast.success("Notes successfully updated");
        setEdit(false);
        setNotes("");
      } else toast.error("Notes could not be updated");
    } catch (e) {
      toast.error("Note could not be updated. Reason:\n" + e);
    } finally {
      setSaving(false);
    }
  };

  const handleToggleEdit = () => {
    setNotes(edit ? "" : supplier.notes);
    setEdit(!edit);
  };

  const handleEditChanges = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (e.target.value) {
      setNotes(e.target.value);
    }
  };

  return (
    <>
      {edit && (
        <div>
          <Textarea
            className="pb-5 fs-6 card card-body bg-light mt-5 no-hover fw-bolder text-white"
            value={notes}
            onBlur={handleEditChanges}
            placeholder="Enter supplier notes"
            rows={8}
            style={{ width: "100%" }}
          />
          <button className="btn btn-light float-right ml-2 mt-2 px-3 py-1" onClick={handleSaveNotes} disabled={saving}>
            {!saving ? (
              <i className="flaticon2-check-mark pr-0" style={{ color: "white" }} />
            ) : (
              <div className="spinner-border-sm text-primary" />
            )}
          </button>
          {!saving && (
            <button className="btn btn-light float-right mt-2 px-3 py-1" onClick={handleToggleEdit}>
              <i className="flaticon2-cancel pr-0" style={{ color: "white" }} />
            </button>
          )}
        </div>
      )}
      {supplier.notes && !edit && (
        <div className="pb-5 fs-6">
          <div className="card card-body bg-light mt-5 no-hover">
            <div className="fw-bolder">
              <div className="text-white">{supplier.notes}</div>
            </div>
          </div>
        </div>
      )}
      {!edit && (
        <button className="btn btn-light float-right mt-2 px-3 py-1" onClick={handleToggleEdit}>
          {supplier.notes ? <i className="flaticon2-edit pr-0" style={{ color: "white" }} /> : "+"}
        </button>
      )}
    </>
  );
};
export default SupplierNotes;
