import _ from "lodash";
import React from "react";
import { CustomerCustomerContractExtended } from "../../../model/customer/customerCustomerContract.types";
import { getOrderNumber, getTimelineEntryText, ORDER_TYPES } from "../../../utils/orderUtils";
import {
  getContractNumber,
  isCustomerContract,
  isCustomerCustomerContract,
} from "../../../utils/customerContractUtils";
import { OrderTimeline } from "../../../model/commonTypes";
import { EXTENDED_CONTRACT_TYPES } from "../../../utils/contractUtils";
import { CustomerCustomerOrder } from "../../../model/customer/customerCustomerOrder.types";

interface CustomerContractUpdatesProps {
  contract: CustomerCustomerContractExtended;
  calls?: Array<CustomerCustomerOrder>;
}

const CustomerContractUpdates: React.FunctionComponent<CustomerContractUpdatesProps> = ({ contract, calls }) => {
  const recentlyUpdated = contract.timeline.filter(
    (t) => new Date().getTime() - t.date.getTime() < 1000 * 60 * 60 * 2
  ).length;

  /**
   * Filters the timeline by the given search query.
   * @returns { Array<{
   *       order: ORDER_TYPES | EXTENDED_CONTRACT_TYPES;
   *       timelineEntry: OrderTimeline;
   *       recent: boolean;
   *     }> } Filtered timeline entries
   */
  const getTimeline = (): Array<{
    order: ORDER_TYPES | EXTENDED_CONTRACT_TYPES;
    timelineEntry: OrderTimeline;
    recent: boolean;
  }> => {
    let timeline: Array<{
      order: ORDER_TYPES | EXTENDED_CONTRACT_TYPES;
      timelineEntry: OrderTimeline;
      recent: boolean;
    }> = contract.timeline.map((t) => {
      return {
        order: contract,
        timelineEntry: t as OrderTimeline,
        recent: new Date().getTime() - t.date.getTime() < 1000 * 60 * 60 * 2, // less than 2 hours of age,
      };
    });
    if (calls)
      calls.forEach((call) => {
        timeline = timeline.concat(
          call.timeline.map((t) => {
            return {
              order: call,
              timelineEntry: t as OrderTimeline,
              recent: new Date().getTime() - t.date.getTime() < 1000 * 60 * 60 * 2, // less than 2 hours of age,
            };
          })
        );
      });
    return _.orderBy(timeline, "timelineEntry.date", "desc");
  };

  const timeline = getTimeline();
  return (
    <div className="col-12">
      <div className="card bg-white" style={{ height: "500px" }}>
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">Updates</span>
            <span className="text-muted fw-bold fs-7">{recentlyUpdated} recent updates on this contract</span>
          </h3>
        </div>
        <div className="card-body scroll-y" style={{ maxHeight: "400px" }}>
          {timeline.slice(0, 30).map((entry) => (
            <div
              key={entry.timelineEntry._id.toString()}
              className={
                "timeline-item no-hover " +
                (new Date().getTime() - entry.timelineEntry.date.getTime() < 1000 * 60 * 60 * 2 && "new")
              }
            >
              <div className="timeline-content text-muted ps-3">
                <span className="fw-bolder text-white ml-2">
                  {isCustomerContract(entry.order) || isCustomerCustomerContract(entry.order)
                    ? `Contract ${getContractNumber(entry.order)}`
                    : `Order ${getOrderNumber(entry.order)}`}{" "}
                </span>
                {getTimelineEntryText(entry.timelineEntry)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomerContractUpdates;
