import _ from "lodash";
import { BSON } from "realm-web";
import { Commodity, CommodityExtended, CommoditySnapshot, Price, UploadedFileExtended } from "../model/commodity.types";
import { CustomerCommodity, CustomerCommodityExtended } from "../model/customer/customerCommodity.types";
import { FinishedProduct, FinishedProductExtended, FinishedProductSnapshot } from "../model/finishedProduct.types";
import {
  CustomerFinishedProduct,
  CustomerFinishedProductExtended,
} from "../model/customer/customerFinishedProduct.types";
import { isFinishedProduct } from "./finishedProductUtils";
import { INTERNAL } from "./userUtils";
import { D_SUPPLIERSPECIFICATION } from "./commodityUtils";
import { formatUnit } from "./baseUtils";
import { Property } from "../model/property.types";
import { PropertyType } from "./propertyUtils";
import { SelectOption } from "../components/common/CustomSelect";
import { CommodityStatistics } from "../model/statistics/commodityStatistics.types";
import { COMMODITYSTATISTICS, getDb } from "../services/dbService";
import { SupplierCommodityExtended } from "../model/supplier/supplierCommodity.types";
import { SupplierFinishedProductExtended } from "../model/supplier/supplierFinishedProduct.types";

export type InternalArticle = Commodity | FinishedProduct;
export type InternalArticleExtended = CommodityExtended | FinishedProductExtended;
export type CustomerArticle = CustomerCommodity | CustomerFinishedProduct;
export type CustomerArticleExtended = CustomerCommodityExtended | CustomerFinishedProductExtended;
export type SupplierArticleExtended = SupplierCommodityExtended | SupplierFinishedProductExtended;
export type Article = InternalArticle | CustomerArticle;
export type ArticleExtended = InternalArticleExtended | CustomerArticleExtended | SupplierArticleExtended;
export type ArticleSnapshot = CommoditySnapshot | FinishedProductSnapshot;

export enum ARTICLETYPES {
  FINISHEDPRODUCT = "finishedProduct",
  COMMODITY = "commodity",
}

export const GERMAN_VAT_OPTIONS: Array<SelectOption> = [
  { value: "7", label: "7%" },
  { value: "19", label: "19%" },
];

/**
 * Get a commodity or finished product snapshot
 * @param article a commodity or finished product document, support for snapshots is needed since there are placed where both can occur
 * @param supplierId optional, supplier to filter prices for
 * @param price optional, price to filter specifically for. has to match with supplier
 * @returns {CommoditySnapshot | FinishedProductSnapshot} a commodity or finished product snapshot
 */
export const getArticleSnapshot = (
  article: ArticleExtended | ArticleSnapshot,
  supplierId?: BSON.ObjectId,
  price?: Price
): CommoditySnapshot | FinishedProductSnapshot => {
  if (isSnapshot(article)) return article;
  const snapshot = isFinishedProduct(article)
    ? (_.omit(_.cloneDeep(article), ["timeline", "disabled", "approved"]) as FinishedProductSnapshot)
    : (_.omit(_.cloneDeep(article), ["timeline", "images", "disabled", "approved"]) as CommoditySnapshot);
  snapshot.snapshotDate = new Date();
  snapshot.articleType = isFinishedProduct(article) ? ARTICLETYPES.FINISHEDPRODUCT : ARTICLETYPES.COMMODITY;

  for (let i = 0; i < snapshot.documents.length; i++) {
    const s = snapshot.documents[i].supplier;
    if (s && "timeline" in s) {
      s.timeline = [];
    }
  }

  // Filter suppliers for supplier and price if given
  if (supplierId && price) {
    const suppliers = snapshot.suppliers.filter(
      (s) =>
        s.supplier.toString() === supplierId.toString() &&
        s.prices.some((p) => p._id.toString() === price._id.toString())
    );
    if (suppliers.length === 1)
      suppliers[0].prices = suppliers[0].prices.filter((p) => p._id.toString() === price._id.toString());
    else if (suppliers.length > 1) {
      for (let i = 0; i < suppliers.length; i++) {
        const sup = suppliers[i];
        sup.prices = sup.prices.filter((p) => p._id.toString() === price._id.toString());
      }
    }
    snapshot.suppliers = suppliers;
  } else if (supplierId)
    snapshot.suppliers = snapshot.suppliers.filter((s) => s.supplier.toString() === supplierId.toString());

  return snapshot;
};

/**
 * Determines if the given commodity or finished product is a snapshot or not.
 * @param article Commodity or finished product that should be checked
 * @returns { boolean } Indicating if the commodity or finished product is a snapshot or not
 */
function isSnapshot(
  article: Article | ArticleExtended | ArticleSnapshot
): article is CommoditySnapshot | FinishedProductSnapshot {
  return "snapshotDate" in article;
}

/**
 * Determines if the given article is some kind of finished product or not.
 * @param article Finished product or commodity that should be checked
 * @returns { boolean } Indicating if the article is some kind of finished product or not
 */
export function isAnyFinishedProduct(
  article: Article | ArticleExtended | FinishedProductSnapshot | CommoditySnapshot | undefined
): article is
  | FinishedProduct
  | FinishedProductExtended
  | FinishedProductSnapshot
  | CustomerFinishedProduct
  | CustomerFinishedProductExtended {
  return article !== undefined && "weightPerUnit" in article; // Explicit undefined check is needed due to the requirement of the function to return a boolean
}

/**
 * Determines if the given commodity is for internal or not.
 * @param article Commodity or finished product that should be checked
 * @param view the current view
 * @returns { boolean } Indicating if the commodity is for internal or not
 */
export function isCommodity(
  article: Article | ArticleExtended | CommoditySnapshot | FinishedProductSnapshot,
  view: string
): article is Commodity | CommodityExtended {
  return view === INTERNAL && !isAnyFinishedProduct(article);
}

/**
 * Check if an article snapshot is of type CommoditySnapshot or not
 * @param article The snapshot
 * @returns {boolean} Flag if article is a commodity snapshot or not
 */
export function isCommoditySnapshot(
  article: CommoditySnapshot | FinishedProductSnapshot
): article is CommoditySnapshot {
  return article.articleType === undefined || article.articleType === ARTICLETYPES.COMMODITY;
}

/**
 * Get the newest Supplier Specifications from a commodity or finished product
 * @param commodity a commodity document to search for supplier specs
 * @param supplierId Optional, only the supplier specifications of the given supplier will be returned (if existing)
 * @param getAllVersions Optional, will also return older versions of a file in addition to the newest one
 * @returns {Array<UploadedFileExtended>} list of found Supplier Specifications (using type UploadedFile from customerCommodity)
 */
export const getSupplierSpecs = (
  commodity: ArticleExtended | ArticleSnapshot,
  supplierId?: string,
  getAllVersions?: boolean
): Array<UploadedFileExtended> => {
  let supplierSpecs: Array<UploadedFileExtended>;
  // Sort the newest on top, so duplicate filter keeps newest and throws away older specs
  supplierSpecs = _.orderBy(
    commodity.documents.filter(
      (d) =>
        (!supplierId || (d.supplier && d.supplier._id.toString() === supplierId)) && // only filter for supplierId if given
        d.type === D_SUPPLIERSPECIFICATION &&
        (!d.validUntil || d.validUntil >= new Date())
    ),
    "date",
    "desc"
  );
  if (!getAllVersions) {
    // Remove duplicates from suppliers
    supplierSpecs = supplierSpecs.filter((specFile, idx, self) => {
      return idx === self.findIndex((sp) => sp.supplier === specFile.supplier);
    });
  }
  return supplierSpecs;
};

/**
 * Retrieve the full commodity or finished product name with title and subtitle
 * @param article Commodity or FinishedProduct whose name should be resolved
 * @returns { string } Title and (if set) subtitle of the commodity or finished product separated by a "-"
 */
export function getFullArticleName(article: Article | ArticleExtended | ArticleSnapshot): string {
  if (article.subtitle.en.trim()) return article.title.en + " - " + article.subtitle.en;
  return article.title.en;
}

/**
 * Get the unit of a commodity or finished article well formatted
 * @param unit the unit
 * @param article optional finished product or commodity snapshot
 * @returns {string} the unit formatted for the type
 */
export function formatArticleUnit(unit: string, article?: Article | ArticleExtended | ArticleSnapshot): string {
  if ((article && isAnyFinishedProduct(article)) || unit === "1000 pcs") return ` x ${unit}`;
  else return unit;
}

/**
 * Get a concatenated string for packaging sizes of a commodity or finished product
 * @param article a commodity document
 * @returns {string} string with all packaging sizes and types
 */
export function getPackagingSizeString(article: Article | ArticleExtended): string {
  if (!article.packagingSizes || article.packagingSizes.length === 0) return formatUnit(25, article.unit) + " drums";
  if (article.packagingSizes.length === 1)
    return `${formatUnit(article.packagingSizes[0].packagingSize, article.unit)} ${article.packagingSizes[0].type}`;
  const sizes = article.packagingSizes.map((pS) => `${formatUnit(pS.packagingSize, article.unit)} ${pS.type}`);
  const lastElement = sizes.pop();
  return `${sizes.join(", ")} or ${lastElement || ""}`;
}

/**
 * Retrieve the properties of an article sorted into their categories
 * @param properties Array of properties that should be split into their categories
 * @returns {{[key: string]: Array<Property>}} Object which contains the properties sorted into categories
 */
export function getArticleProperties(properties: Array<Property>): { [key: string]: Array<Property> } {
  const propertiesCollection: { [key: string]: Array<Property> } = {
    [PropertyType.TAG]: [],
    [PropertyType.COMPOSITION]: [],
    [PropertyType.SOLVENT]: [],
    [PropertyType.ALLERGEN]: [],
    [PropertyType.CATEGORY]: [],
    [PropertyType.PACKAGING]: [],
    [PropertyType.CARRIER]: [],
    [PropertyType.ANALYSISMETHOD]: [],
    [PropertyType.EXTRACTIONSOURCE]: [],
    [PropertyType.ODOR]: [],
    [PropertyType.TRANSPORTCONDITIONS]: [],
    [PropertyType.PURPOSEOFUSE]: [],
    [PropertyType.OTHER]: [],
  };
  for (let i = 0; i < properties.length; i++) {
    const p = properties[i];
    propertiesCollection[p.type].push(p);
  }
  return propertiesCollection;
}

/**
 * Get article statistics
 * @param articleId id of commodity or finished product to get statistics for
 * @param statistics optional, list of statistic names to get
 * @returns {Promise<Partial<CommodityStatistics> | undefined>} commodity statistics (article statistics) if found for commodity or finished product
 */
export async function getArticleStatistics(
  articleId: string | BSON.ObjectId,
  statistics?: Array<string>
): Promise<Partial<CommodityStatistics>> {
  const db = getDb();
  // Using collection also for finished products due to efficiency considerations.
  const collection = db?.collection(COMMODITYSTATISTICS);
  const projection: { [field: string]: number } = {};
  if (statistics) statistics.forEach((s) => (projection[s] = 1));
  return collection?.findOne({ commodity: articleId.toString() }, { projection: statistics ? projection : undefined });
}
