import Tagify, { TagifySettings } from "@yaireo/tagify";
import Tags from "@yaireo/tagify/dist/react.tagify";
import _ from "lodash";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import FileUploadDropzone from "../../../common/FileUploadDropzone";
import { Input } from "../../../common/Input";
import { Textarea } from "../../../common/Textarea";
import { Article, ArticleType } from "../../../../model/article.types";
import {
  ARTICLE_TYPEOPTIONS,
  getArticleTimelineEntry,
  getDefaultArticle,
  insertArticle,
  updateArticle,
} from "../../../../utils/articleUtils";
import { uploadFile } from "../../../../utils/fileUtils";

interface CreateArticleModalProps {
  article?: Article;
  onClose: () => void;
}

interface CreateArticleModalState {
  articleEdit: Article;
  articleType: SelectOption;
  image?: File;
  show: boolean;
  saving: boolean;
}

class CreateArticleModal extends PureComponent<CreateArticleModalProps, CreateArticleModalState> {
  articleTagSettings: TagifySettings<{ value: string; id: string }> = {
    placeholder: "Enter Tag(s), press Enter to confirm",
    enforceWhitelist: false,
    duplicates: false,
    trim: true,
    pasteAsTags: true,
    editTags: 2,
  };

  constructor(props: CreateArticleModalProps) {
    super(props);
    this.state = this.getDefaultState(props, false);
  }

  componentDidUpdate(prevProps: Readonly<CreateArticleModalProps>) {
    if (prevProps.article !== this.props.article) {
      this.setState(this.getDefaultState(this.props, Boolean(this.props.article)));
    }
  }

  handleShow = () => this.setState(this.getDefaultState(this.props, true));
  handleHide = () => this.setState({ show: false }, this.props.onClose);

  handleChangeString = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const articleEdit = _.cloneDeep(this.state.articleEdit);
    // @ts-ignore
    articleEdit[e.target.name] = e.target.value;
    this.setState({ articleEdit });
  };

  handleOnDrop = <T extends File>(acceptedFiles: T[]) => this.setState({ image: acceptedFiles[0] });

  handleTagsChange = (event: CustomEvent<Tagify.ChangeEventData<{ value: string; id: string }>>) => {
    const articleEdit = _.cloneDeep(this.state.articleEdit);
    if (!event.detail.value) {
      articleEdit.tags = [];
    } else {
      const tags = JSON.parse(event.detail.value);
      articleEdit.tags = tags.map((tag: { value: string }) => {
        return tag.value;
      });
    }
    this.setState({ articleEdit });
  };

  handleChangeArticleType = (articleType: SelectOption) => this.setState({ articleType });

  handleSaveArticle = async () => {
    const { article, onClose } = this.props;
    const { articleEdit, articleType, image } = this.state;
    this.setState({ saving: true });

    try {
      // Check if user want to upload a article image
      if (image) {
        // Try to upload article image
        articleEdit.imagePath = this.uploadNewsImage();
      }
      // Case create
      if (!article) {
        articleEdit.creationTime = new Date();
      }
      articleEdit.type = articleType.value as ArticleType;
      articleEdit.lastWrite = new Date();

      let res;
      if (article) {
        // Update existing article
        const articleUpdate = _.omit(articleEdit, "_id");
        res = await updateArticle(articleUpdate, articleEdit._id, getArticleTimelineEntry());
      } else {
        // Insert the new article
        res = await insertArticle(articleEdit);
      }

      // Handling the result
      if (res) {
        // Case success
        toast.success(`Article ${article ? "edited" : "created"} successfully`);
        this.setState({ show: false });
      } else {
        // Case failed
        toast.error(`Error ${article ? "editing" : "creating"} article`);
      }
    } catch (e) {
      toast.error(`Error ${article ? "editing" : "creating"} article: ${JSON.stringify(e) || ""}`);
    } finally {
      this.setState({ saving: false }, onClose);
    }
  };

  uploadNewsImage = (): string => {
    const { image } = this.state;
    let alias = "";
    // News entity has a corresponding image?
    if (image) {
      // Upload news image
      const result = uploadFile(image, undefined, undefined, false);
      // Check upload success
      if (!result) {
        throw new Error("Error: Can not upload news image!");
      } else {
        // save received file path
        alias = result;
      }
    }
    return alias;
  };

  getDefaultState = (props: CreateArticleModalProps, show: boolean): CreateArticleModalState => {
    return {
      show,
      articleEdit: _.cloneDeep(props.article) ?? getDefaultArticle(ArticleType.NEWS),
      articleType: ARTICLE_TYPEOPTIONS.find((t) => t.value === props.article?.type) ?? ARTICLE_TYPEOPTIONS[0],
      image: undefined,
      saving: false,
    };
  };

  validateData = () => {
    const { title, newsText } = this.state.articleEdit;
    // Error Collection
    const errors: string[] = [];
    // checking for title
    if (!title.trim()) {
      errors.push("Please insert an title");
    }
    // checking for news Text
    if (!newsText.trim()) {
      errors.push("Please insert a news text");
    }
    return errors;
  };

  render() {
    const { article } = this.props;
    const { articleEdit, image, articleType, show, saving } = this.state;
    const { title, newsText, imagePath, tags } = articleEdit;
    const errors = this.validateData();

    return (
      <>
        <button className="btn btn-outline btn-outline-light float-right" onClick={this.handleShow}>
          Create Article
        </button>
        <Modal contentClassName="bg-dark" show={Boolean(article) || show} onHide={this.handleHide} centered size="xl">
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">
                {article ? "Edit" : "New"} {articleType.value === ArticleType.NEWS ? "News" : "Market Report"}
              </h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="mb-4">
              <label className="align-items-center fs-6 fw-bold mb-2">
                <span className="required">Title</span>
              </label>
              <div className="mb-5">
                <Input
                  type="text"
                  className="form-control custom-form-control"
                  placeholder="News Title"
                  value={title}
                  name="title"
                  onChange={this.handleChangeString}
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="align-items-center fs-6 fw-bold mb-2">
                <span className="required">Type</span>
              </label>
              <div className="mb-5">
                <CustomSelect
                  options={ARTICLE_TYPEOPTIONS}
                  matchFormControl={true}
                  value={articleType}
                  onChange={(e: SelectOption) => this.handleChangeArticleType(e)}
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="align-items-center fs-6 fw-bold mb-2">
                <span className="required">Text</span>
              </label>
              <div className="mb-5">
                <Textarea
                  rows={7}
                  className="form-control custom-form-control"
                  placeholder="News Text"
                  value={newsText}
                  name="newsText"
                  onChange={this.handleChangeString}
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="align-items-center fs-6 fw-bold mb-2">
                <span>Image</span>
              </label>
              <div className="mb-5">
                <FileUploadDropzone
                  onDrop={this.handleOnDrop}
                  previewImage={image ?? imagePath}
                  acceptance={{
                    "image/png": [".png"],
                    "image/jpg": [".jpg"],
                  }}
                />
              </div>
            </div>
            <div>
              <label className="align-items-center fs-6 fw-bold mb-2">
                <span>Tags</span>
              </label>
              <div className="mb-5">
                <Tags
                  className="custom-form-control form-control-solid"
                  settings={this.articleTagSettings}
                  onChange={this.handleTagsChange}
                  value={tags}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ErrorOverlayButton
              errors={errors}
              saving={saving}
              className="btn btn-light btn-active-light-primary w-25"
              buttonText={article ? "Apply Changes" : "Create"}
              onClick={this.handleSaveArticle}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreateArticleModal;
