import React, { PureComponent } from "react";
import { T_SEAFREIGHT, T_AIRFREIGHT, CustomerOrderExtended } from "../../../../../../model/customerOrder.types";
import { DataContextInternalType } from "../../../../../../context/dataContext";
import OrderDetails from "../../../../common/OrderDetails";
import {
  getCustomerOrderCalculation,
  getEarliestDeliveryDate,
  formatDateFromType,
} from "../../../../../../utils/customerOrderUtils";
import { formatCurrency, formatDate } from "../../../../../../utils/baseUtils";
import { BASE_CURRENCY } from "../../../../../../utils/currencyUtils";
import { CustomerPriceInfo } from "../../../../../../model/commonTypes";
import Tooltip from "../../../../../common/Tooltip";

interface WorkflowRequestedProps {
  order: CustomerOrderExtended;
  context: DataContextInternalType;
}

interface WorkflowRequestedState {
  priceInfoFastest?: CustomerPriceInfo;
  priceInfoCheapest?: CustomerPriceInfo;
  fastestDelivery?: Date;
  cheapestDelivery?: Date;
}

class WorkflowRequested extends PureComponent<WorkflowRequestedProps, WorkflowRequestedState> {
  constructor(props: WorkflowRequestedProps) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const { order, context } = this.props;
    // Get fastest and cheapest price for comparison
    const priceInfoFastest = await getCustomerOrderCalculation(
      order.commodity,
      order.amount,
      T_AIRFREIGHT,
      context.currencies
    );
    const priceInfoCheapest = await getCustomerOrderCalculation(
      order.commodity,
      order.amount,
      T_SEAFREIGHT,
      context.currencies
    );
    const fastestDelivery = await getEarliestDeliveryDate(T_AIRFREIGHT);
    const cheapestDelivery = await getEarliestDeliveryDate(T_SEAFREIGHT);
    const newState: WorkflowRequestedState = {};
    if (
      priceInfoFastest &&
      priceInfoFastest.unitPrice !== Infinity &&
      priceInfoFastest.unitPrice !== 0 &&
      !isNaN(priceInfoFastest.unitPrice)
    )
      newState["priceInfoFastest"] = priceInfoFastest;
    if (
      priceInfoCheapest &&
      priceInfoCheapest.unitPrice !== Infinity &&
      priceInfoCheapest.unitPrice !== 0 &&
      !isNaN(priceInfoCheapest.unitPrice)
    )
      newState["priceInfoCheapest"] = priceInfoCheapest;
    if (fastestDelivery) newState["fastestDelivery"] = fastestDelivery;
    if (cheapestDelivery) newState["cheapestDelivery"] = cheapestDelivery;
    this.setState({ ...newState });
  }

  render() {
    const { order, context } = this.props;
    const { priceInfoFastest, priceInfoCheapest, fastestDelivery, cheapestDelivery } = this.state;
    const unitPrice = order.priceCommodities / order.amount;
    return (
      <div className="card bg-white">
        <div className="card-header border-0 mt-5">
          <div className="card-title flex-column">
            <h2 className="mb-1">Review Request</h2>
          </div>
        </div>
        <div className="card-body p-9 pt-0">
          <div className="d-flex py-3 align-items-center w-100">
            <div className="mr-3">
              <div className="text-white fs-5 fw-bolder">Request Details</div>
              <div className="text-muted">Price information as specified by customer</div>
            </div>
          </div>
          <div className="fs-6">
            <div className="d-flex flex-wrap py-5">
              <table className="table fw-bold gy-1">
                <tbody>
                  <tr>
                    <td className="text-white w-25 min-w-125px">Total commodity price</td>
                    <td className="text-muted w-25">{formatCurrency(order.priceCommodities, BASE_CURRENCY)}</td>
                    <td className="text-white w-25 min-w-125px">Price per {order.unit}</td>
                    <td className="text-muted w-25">{formatCurrency(unitPrice, BASE_CURRENCY)}</td>
                  </tr>
                  <tr>
                    <td className="text-white w-25 min-w-125px">Expiration</td>
                    <td className="text-muted w-25">
                      {order.limitTimeFrame
                        ? formatDate(
                            new Date(order.createdAt.setDate(order.createdAt.getDate() + order.limitTimeFrame))
                          )
                        : "not set"}
                    </td>
                    <td className="text-white w-25 min-w-125px">Latest Delivery</td>
                    <td className="text-muted w-25">{formatDateFromType(order.targetDate, order.targetDateType)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {(priceInfoCheapest || priceInfoFastest) && (
            <div className="d-flex py-3 align-items-center w-100">
              <div className="mr-3">
                <div className="text-white fs-5 fw-bolder">Currently Available Prices</div>
                <div className="text-muted">
                  Lowest price and price prioritizing faster delivery based on currently known price data
                </div>
              </div>
            </div>
          )}
          {priceInfoCheapest && (
            <div className="fs-6">
              <div className="d-flex flex-wrap pt-5 pb-2">
                <table className="table fw-bold gy-1">
                  <thead>
                    <tr>
                      <th colSpan={2} className="text-white border-bottom-0">
                        <h5 className="mb-0">Best Price</h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-white w-25 min-w-125px">Price per {order.unit}</td>
                      <td
                        className={
                          "w-25 " + (priceInfoCheapest.unitPrice < unitPrice ? "text-success" : "text-warning")
                        }
                      >
                        {formatCurrency(priceInfoCheapest.unitPrice, BASE_CURRENCY)}/{order.unit}
                      </td>
                      <td className="text-white w-25 min-w-125px">
                        Est. Delivery Date
                        <Tooltip
                          tooltipText={
                            <span className="text-white">
                              Estimated delivery date is based on reference values of already completed orders.
                              Depending on the selected supplier, the delivery time may be longer or shorter.
                            </span>
                          }
                        >
                          <i className="fa fa-question-circle text-muted opacity-25 ml-2" />
                        </Tooltip>
                      </td>
                      <td
                        className={
                          "w-25 " +
                          (cheapestDelivery
                            ? cheapestDelivery < order.targetDate
                              ? "text-success"
                              : "text-warning"
                            : "text-muted")
                        }
                      >
                        {cheapestDelivery ? formatDate(cheapestDelivery) : "N/A"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {priceInfoFastest && (
            <div className="fs-6">
              <div className="d-flex flex-wrap pb-5 pt-2">
                <table className="table fw-bold gy-1">
                  <thead>
                    <tr>
                      <th colSpan={2} className="text-white border-bottom-0">
                        <h5 className="mb-0">Fastest Delivery</h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-white w-25 min-w-125px">Price per {order.unit}</td>
                      <td
                        className={"w-25 " + (priceInfoFastest.unitPrice < unitPrice ? "text-success" : "text-warning")}
                      >
                        {formatCurrency(priceInfoFastest.unitPrice, BASE_CURRENCY)}/{order.unit}
                      </td>
                      <td className="text-white w-25 min-w-125px">
                        Est. Delivery Date
                        <Tooltip
                          tooltipText={
                            <span className="text-white">
                              Estimated delivery date is based on reference values of already completed orders.
                              Depending on the selected supplier, the delivery time may be longer or shorter.
                            </span>
                          }
                        >
                          <i className="fa fa-question-circle text-muted opacity-25 ml-2" />
                        </Tooltip>
                      </td>
                      <td
                        className={
                          "w-25 " +
                          (fastestDelivery
                            ? fastestDelivery < order.targetDate
                              ? "text-success"
                              : "text-warning"
                            : "text-muted")
                        }
                      >
                        {fastestDelivery ? formatDate(fastestDelivery) : "N/A"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <OrderDetails order={order} hideDocuments={true} context={context} />
        </div>
      </div>
    );
  }
}

export default WorkflowRequested;
