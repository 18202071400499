import _ from "lodash";
import { BSON } from "realm-web";

/**
 * Get difference between two list of objects
 * @param listPre list pre edit
 * @param listPost list post edit
 * @returns {[ Array<T>, Array<T>]} tuple with entries that differ
 */
export function getAllListDifferences<T extends { _id: BSON.ObjectId | string }>(
  listPre: Array<T>,
  listPost: Array<T>
): [Array<T>, Array<T>] {
  const differentPre = getListDifferences(listPre, listPost);
  const differentPost = getListDifferences(listPost, listPre);
  return [differentPre, differentPost];
}

/**
 * Get the differences between two list of objects with _id properties
 * @param list list of objects
 * @param list2 list of objects
 * @returns { Array<T>} list of items that differ between list 1 and 2
 */
export function getListDifferences<T extends { _id: BSON.ObjectId | string }>(
  list: Array<T>,
  list2: Array<T>
): Array<T> {
  const differences = [];
  for (let i = 0; i < list.length; i++) {
    const e = list[i];
    const e2 = list2.find((p2) => e._id.toString() === p2._id.toString());
    if (e2) {
      if (!_.isEqual(e, e2)) differences.push(e);
    } else differences.push(e);
  }
  return differences;
}

/**
 * Get difference between two list of objects by directly comparing objects. Do not use if only ids are important
 * @param listPre list pre edit
 * @param listPost list post edit
 * @returns {[ Array<T>, Array<T>]} tuple with entries that differ
 */
export function getAllListDifferencesGeneral<T>(listPre: Array<T>, listPost: Array<T>): [Array<T>, Array<T>] {
  const differentPre = getListDifferencesGeneral(listPre, listPost);
  const differentPost = getListDifferencesGeneral(listPost, listPre);
  return [differentPre, differentPost];
}

/**
 * Get the differences between two list of objects by directly comparing objects. Do not use if only ids are important
 * @param list list of objects
 * @param list2 list of objects
 * @returns { Array<T>} list of items that differ between list 1 and 2
 */
function getListDifferencesGeneral<T>(list: Array<T>, list2: Array<T>): Array<T> {
  const differences = [];
  for (let i = 0; i < list.length; i++) {
    const e = list[i];
    const e2 = list2.find((p2) => _.isEqual(e, p2));
    if (!e2) differences.push(e);
  }
  return differences;
}
