import React, { PureComponent } from "react";
import { Nav, Tab } from "react-bootstrap";
import { TabDefinition } from "../../../common/CustomTypes";
import OrderDocuments from "../OrderDocuments";
import OrderInvoices from "../OrderInvoices";
import OrderHistory from "../OrderHistory";
import {
  DataContextAnonymousType,
  DataContextCustomerType,
  DataContextInternalType,
  isInternalContext,
} from "../../../../context/dataContext";
import { SAMO_STATE, SampleOrderExtended } from "../../../../model/sampleOrder.types";
import SampleOrderWorkflow from "./tabPanels/SampleOrderWorkflow";
import HandleSampleRequestModal from "./tabPanels/workflowTabPanels/modals/HandleSampleRequestModal";
import { CustomerSampleOrderExtended } from "../../../../model/customer/customerSampleOrder.types";
import { INTERNAL } from "../../../../utils/userUtils";
import CancelSampleOrderModal from "../../customer/sampleOrder/modals/CancelSampleOrderModal";
import OrderNotes from "../OrderNotes";
import OrderCreditNotes from "../OrderCreditNotes";

interface SampleOrderTabPanelProps {
  order: SampleOrderExtended | CustomerSampleOrderExtended;
  context: DataContextInternalType | DataContextCustomerType | DataContextAnonymousType;
}

interface SampleOrderTabPanelState {
  currentTab: string;
}

class SampleOrderTabPanel extends PureComponent<SampleOrderTabPanelProps, SampleOrderTabPanelState> {
  constructor(props: SampleOrderTabPanelProps) {
    super(props);
    this.state = { currentTab: this.getTabDefinitions()[0].name };
  }

  componentDidUpdate(prevProps: Readonly<SampleOrderTabPanelProps>) {
    if (prevProps.order._id.toString() !== this.props.order._id.toString()) {
      this.setState({ currentTab: this.getTabDefinitions()[0].name });
    }
  }

  getTabDefinitions = (): Array<TabDefinition> => {
    const { order, context } = this.props;
    const tabDefinitions = [
      {
        name: "workflow",
        tabContent: "Workflow",
        component: <SampleOrderWorkflow order={order} context={context} />,
      },
      {
        name: "documents",
        tabContent: "Documents",
        component: <OrderDocuments order={order} context={context} />,
      },
      {
        name: "invoices",
        tabContent: "Invoices",
        component: (
          <div>
            <OrderInvoices order={order} context={context} />
            <OrderCreditNotes order={order} context={context} />
          </div>
        ),
      },
    ];
    if (context.type === INTERNAL) {
      tabDefinitions.push({
        name: "notes",
        tabContent: "Notes",
        component: <OrderNotes order={order as SampleOrderExtended} context={context as DataContextInternalType} />,
      });
    }
    tabDefinitions.push({
      name: "history",
      tabContent: "History",
      component: <OrderHistory order={order} context={context} />,
    });
    return tabDefinitions;
  };

  /**
   * Handle tab changes
   * @param eventKey new tab
   */
  handleTab = (eventKey: string | null) => {
    if (eventKey && eventKey !== this.state.currentTab) this.setState({ currentTab: eventKey });
  };

  render() {
    const { order, context } = this.props;
    const { currentTab } = this.state;
    const tabs = this.getTabDefinitions();

    return (
      <Tab.Container id="customerOrderTabs" activeKey={currentTab} onSelect={this.handleTab}>
        <div className="flex-lg-row-fluid ms-lg-15">
          <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
            {tabs.map((t) => (
              <li key={t.name + "_link"} className="nav-item cursor-pointer">
                <Nav.Link
                  role="tab"
                  id={t.name}
                  eventKey={t.name}
                  active={currentTab === t.name}
                  className="text-active-white pb-4 "
                >
                  {t.tabContent}
                </Nav.Link>
              </li>
            ))}
            {order.state === SAMO_STATE.REQUESTED && isInternalContext(context) ? (
              <div className="ms-auto">
                <li className="nav-item ms-auto">
                  <HandleSampleRequestModal order={order as SampleOrderExtended} />
                </li>
              </div>
            ) : (
              <div className="ms-auto">
                <li className="nav-item ms-auto">
                  <CancelSampleOrderModal order={order} context={context} />
                </li>
              </div>
            )}
          </ul>
          <Tab.Content>
            {tabs.map((t) => (
              <Tab.Pane id={t.name} key={t.name} eventKey={t.name}>
                {t.component}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </div>
      </Tab.Container>
    );
  }
}

export default SampleOrderTabPanel;
