import React from "react";
import { Batch, BatchExtended } from "../../../model/batch.types";
import { Textarea } from "../../common/Textarea";

interface DisableBatchModalProps {
  batch: Batch | BatchExtended;
  note: string;
  onChangeNote: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const DisableBatchDialog: React.FunctionComponent<DisableBatchModalProps> = ({ batch, note, onChangeNote }) => {
  return (
    <div>
      <p className="text-white">
        Are you sure you want to {batch.disabled ? "enable" : "disable"} batch{" "}
        <em>
          RB{batch.identifier} ({batch.lot})
        </em>
        ?
      </p>
      <div className="row mt-15">
        <div className="col-4">
          <p className="text-white">Please provide a reason:</p>
        </div>
        <div className="col-8">
          <Textarea
            className={"form-control custom-form-control"}
            rows={2}
            name="note"
            value={note}
            onChange={onChangeNote}
          />
        </div>
      </div>
    </div>
  );
};

export default DisableBatchDialog;
