import { BSON } from "realm-web";
import { callFunction } from "../services/dbService";
import { Article, ArticleTimeline, ArticleType } from "../model/article.types";
import userService from "../services/userService";
import { SelectOption } from "../components/common/CustomSelect";

export const ARTICLE_TYPES: { [key in ArticleType]: string } = {
  [ArticleType.NEWS]: "News",
  [ArticleType.MARKETREPORT]: "Market Report",
};

export const ARTICLE_TYPEOPTIONS: Array<SelectOption> = [
  { value: ArticleType.NEWS, label: "News" },
  { value: ArticleType.MARKETREPORT, label: "Market Report" },
];

// Backend functions relating to property
const UPSERTNEWS = "upsertArticle";

/**
 * Inserts a new article into the database.
 * @param article Article that should be inserted into the database
 * @returns {Promise<{ res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> } | false>} Result of the function
 */
export async function insertArticle(
  article: Article
): Promise<{ res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> } | false> {
  return (await callUpsertArticle(article, true)) as
    | { res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> }
    | false;
}

/**
 * Update a given article.
 * @param article Article that should be inserted into the database
 * @param articleId Id of article
 * @param timelineEntry Timeline object for updating article
 * @returns {Promise<{ res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> } | false>} Result of the function
 */
export async function updateArticle(
  article: Partial<Article>,
  articleId?: BSON.ObjectId,
  timelineEntry?: ArticleTimeline
): Promise<{ res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> } | false> {
  if (articleId) article._id = articleId;
  return (await callUpsertArticle(article, false, timelineEntry)) as
    | { res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> }
    | false;
}

/**
 * Calls the upsert article function in backend.
 * @param article Article that should be upsert
 * @param insert True for insert, else update
 * @param timelineEntry Timeline object for updating article
 * @returns { Promise<{ res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> } | {res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> } | false> } Result of the function
 */
async function callUpsertArticle(
  article: Partial<Article>,
  insert: boolean,
  timelineEntry?: ArticleTimeline
): Promise<
  | { res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> }
  | { res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> }
  | false
> {
  return callFunction(UPSERTNEWS, [article, insert, timelineEntry, undefined]);
}

/**
 * Get all available news
 * @param articles list of all articles
 * @returns { Array<Article> } list of all news articles
 */
export function getNews(articles: Array<Article>): Array<Article> {
  return articles.filter((art) => !art.type || art.type === ArticleType.NEWS);
}

/**
 * Get a default article of the given type
 * @param type Desired type of article
 * @returns {Article} Default article
 */
export function getDefaultArticle(type: ArticleType): Article {
  return {
    _id: new BSON.ObjectId(),
    title: "",
    newsText: "",
    type,
    creationTime: new Date(),
    createdBy: userService.getUserId(),
    imagePath: "",
    tags: [],
    lastWrite: new Date(),
    timeline: [],
  };
}

/**
 * Get an article timeline entry. Since we got no different types it always just the same for now.
 * @returns {ArticleTimeline} Timeline entry
 */
export function getArticleTimelineEntry(): ArticleTimeline {
  return { _id: new BSON.ObjectId(), date: new Date(), person: userService.getUserId() };
}
