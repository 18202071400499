import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContextAnonymous, DataContextCustomer } from "../../../context/dataContext";
import CommodityPage from "./CommodityPage";
import userService from "../../../services/userService";
import { ANONYMOUS, CUSTOMER } from "../../../utils/userUtils";

interface CommodityWrapperProps extends RouteComponentProps<CommodityParams> {}

interface CommodityParams {
  id: string;
}

const CommodityWrapper: React.FunctionComponent<CommodityWrapperProps> = (props) => {
  let context;
  if (userService.getUserType() === CUSTOMER) {
    context = useContext(DataContextCustomer);
  } else if (userService.getUserType() === ANONYMOUS) {
    context = useContext(DataContextAnonymous);
  }
  if (!context) return <></>;
  return <CommodityPage context={context} {...props} />;
};
export default CommodityWrapper;
