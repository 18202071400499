import _ from "lodash";
import React, { PureComponent } from "react";
import { DataContextInternalType } from "../../../../context/dataContext";
import Search from "../../../common/Search";
import { doFuseSearch } from "../../../../utils/baseUtils";
import OrderWidget from "../../../common/OrderWidget";
import { SupplierExtended } from "../../../../model/supplier.types";
import { SupplierOrder } from "../../../../model/supplierOrder.types";
import SupplierPageUpdates from "../SupplierPageUpdates";
import { sortSupplierOrdersByState } from "../../../../utils/supplierOrderUtils";
import { extendSupplierOrder } from "../../../../utils/dataTransformationUtils";

interface SupplierPageOrdersProps {
  supplier: SupplierExtended;
  context: DataContextInternalType;
}

interface SupplierPageOrdersState {
  activeOrders: Array<SupplierOrder>;
  search: string;
}

class SupplierPageOrders extends PureComponent<SupplierPageOrdersProps, SupplierPageOrdersState> {
  constructor(props: SupplierPageOrdersProps) {
    super(props);
    this.state = {
      search: "",
      activeOrders: this.getRelevantOrders(props),
    };
  }

  componentDidUpdate = (prevProps: Readonly<SupplierPageOrdersProps>, prevState: Readonly<SupplierPageOrdersState>) => {
    if (!_.isEqual(prevProps.supplier, this.props.supplier)) {
      this.setState({ activeOrders: this.getRelevantOrders(this.props) });
      return;
    }
    // Update if a change appeared, but it was not a state update, e.g. search. We assume it must be context update then
    if (_.isEqual(prevState, this.state)) {
      const orders = this.getRelevantOrders(this.props);
      if (!_.isEqual(orders, this.state.activeOrders)) this.setState({ activeOrders: orders });
    }
  };

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });

  getRelevantOrders = (props: SupplierPageOrdersProps) => {
    const { supplierOrder } = this.props.context;
    return supplierOrder.filter((sO) => sO.supplier === props.supplier._id.toString());
  };

  render() {
    const { context } = this.props;
    const { search, activeOrders } = this.state;
    const filteredOrders = search.trim()
      ? doFuseSearch(activeOrders, search, ["orderNo", "transport", "commodity.title.en", "amount"])
      : activeOrders;
    return (
      <>
        <div className="card bg-white">
          <div className="card-header border-0 mt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder fs-3 mb-1">Current Orders</span>
              <span className="text-muted fw-bold fs-7">Currently {activeOrders.length} orders are active</span>
            </h3>
            <div className="card-toolbar">
              <Search onSearch={this.handleSearch} value={search} />
            </div>
          </div>
          <div className="card-body modal-content-scroll">
            {sortSupplierOrdersByState(filteredOrders).map((o) => (
              <OrderWidget
                key={o._id.toString()}
                type="commodity"
                order={extendSupplierOrder(o as SupplierOrder, context)}
              />
            ))}
          </div>
        </div>
        <SupplierPageUpdates orders={activeOrders} context={context} />
      </>
    );
  }
}

export default SupplierPageOrders;
