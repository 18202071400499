import React from "react";
import { RangeCostObject } from "../../../../../model/configuration/calculationConfiguration.types";
import { Input } from "../../../../common/Input";

interface RangeCostObjectListProps {
  cost: Array<RangeCostObject>;
  objectPath: string;
  onChangeValues: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RangeCostObjectList: React.FunctionComponent<RangeCostObjectListProps> = ({
  cost,
  onChangeValues,
  objectPath,
}) => {
  return (
    <div className="row">
      <div className="col-4">
        <label className="fs-5 fw-bold mb-2">From</label>
      </div>
      <div className="col-4">
        <label className="fs-5 fw-bold mb-2">To</label>
      </div>
      <div className="col-4">
        <label className="fs-5 fw-bold mb-2">Cost</label>
      </div>
      {cost.map((costObject, index) => (
        <RangeCostObjectConfiguration
          key={index.toString()}
          costObject={costObject}
          objectPath={objectPath}
          index={index}
          onChangeValues={onChangeValues}
        />
      ))}
    </div>
  );
};

interface RangeCostObjectConfigurationProps {
  costObject: RangeCostObject;
  onChangeValues: (e: React.ChangeEvent<HTMLInputElement>) => void;
  objectPath: string;
  index: number;
}

const RangeCostObjectConfiguration: React.FunctionComponent<RangeCostObjectConfigurationProps> = ({
  costObject,
  onChangeValues,
  objectPath,
  index,
}) => {
  return (
    <>
      <div className={"col-4 " + (index > 0 && "mt-2")}>
        <div className="input-group">
          <Input
            className="form-control custom-form-control"
            type={"number"}
            min={0}
            name={`${objectPath}.${index}.from`}
            value={costObject.from}
            onChange={onChangeValues}
          />
          <div className="input-group-append rounded-end bg-custom-light-gray">
            <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
              kg
            </div>
          </div>
        </div>
      </div>
      <div className={"col-4 " + (index > 0 && "mt-2")}>
        <div className="input-group">
          {costObject.to === Infinity ? (
            <Input
              className={"form-control custom-form-control disabled"}
              type={"text"}
              disabled={true}
              min={0}
              name={`${objectPath}.${index}.cost`}
              value={"Infinity"}
            />
          ) : (
            <Input
              className="form-control custom-form-control"
              type={"number"}
              min={0}
              name={`${objectPath}.${index}.to`}
              value={costObject.to}
              onChange={onChangeValues}
            />
          )}
          <div className="input-group-append rounded-end bg-custom-light-gray">
            <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
              kg
            </div>
          </div>
        </div>
      </div>
      <div className={"col-4 " + (index > 0 && "mt-2")}>
        <div className="input-group">
          <Input
            className={"form-control custom-form-control"}
            type={"number"}
            min={0}
            name={`${objectPath}.${index}.cost`}
            value={costObject.cost}
            onChange={onChangeValues}
          />

          <div className="input-group-append rounded-end bg-custom-light-gray">
            <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
              {costObject.currency}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
