import _ from "lodash";
import React, { PureComponent } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import { SupplierOrder } from "../../../model/supplierOrder.types";
import {
  calculateArrivalInformation,
  getLastStateUpdate,
  getOrderNumber,
  getOrderProgress,
  getOrderStateDescriptions,
  getOrderType,
  INTERNAL_ORDER_TYPES,
  isCustomerOrder,
  isOrderCanceled,
  isSampleOrder,
  isSupplierOrder,
} from "../../../utils/orderUtils";
import { DataContextInternalType, isInternalContext } from "../../../context/dataContext";
import { getDaysBetween, getTimeDiffString } from "../../../utils/dateUtils";
import HoverPopover from "../../common/HoverPopover";
import { getDocFromCollection, pluralize } from "../../../utils/baseUtils";
import { formatArticleUnit } from "../../../utils/productArticleUtils";
import { CustomerOrder } from "../../../model/customerOrder.types";

interface OrderRowProps extends RouteComponentProps {
  context: DataContextInternalType;
  order: INTERNAL_ORDER_TYPES;
}

interface OrderRowState {
  supplierOrders: Array<SupplierOrder>;
}

class OrderRow extends PureComponent<OrderRowProps, OrderRowState> {
  constructor(props: OrderRowProps) {
    super(props);
    this.state = { supplierOrders: [] };
  }

  componentDidMount() {
    this.setState({ supplierOrders: this.resolveRelatedSupplierOrders() });
  }

  componentDidUpdate(prevProps: Readonly<OrderRowProps>) {
    const { context } = this.props;
    if (isInternalContext(context) && isInternalContext(prevProps.context)) {
      if (
        !_.isEqual(prevProps.context.customerOrder, context.customerOrder) ||
        !_.isEqual(prevProps.context.supplierOrder, context.supplierOrder)
      ) {
        this.setState({ supplierOrders: this.resolveRelatedSupplierOrders() });
      }
    }
  }

  /**
   * Resolves the supplier orders related to the order.
   * @returns { Array<SupplierOrder> } List of supplier orders
   */
  resolveRelatedSupplierOrders = (): Array<SupplierOrder> => {
    const { context, order } = this.props;
    return context.supplierOrder.filter((sO) => sO.customerOrders.some((cO) => cO === order._id.toString()));
  };

  /**
   * Resolve the references to other orders.
   * @returns { JSX.Element } References to other orders
   */
  getOrdersReference = (): JSX.Element => {
    const { order, context } = this.props;
    const { supplierOrders } = this.state;
    const link = isCustomerOrder(order) ? "/supplierOrder/" : "/customerOrder/";
    // There might be canceled orders that are referenced inside supplier orders. Those are not relevant, thus filtered
    const orders: Array<SupplierOrder | CustomerOrder> = isSupplierOrder(order)
      ? (order.customerOrders
          .map((cO) => getDocFromCollection(context.customerOrder, cO))
          .filter((o) => Boolean(o)) as Array<CustomerOrder>)
      : supplierOrders;
    if (orders.length === 0) return <span>-</span>;
    if (orders.length > 2)
      return (
        <HoverPopover
          popoverStyle={{ maxWidth: "95vw", border: "none" }}
          content={
            <div className="card overflow-auto" style={{ background: "#3f3f3f" }}>
              <div className="card-body">
                <div className="text-white">
                  {orders.map((o, idx) => {
                    return (
                      <div key={o._id.toString()} className="row" style={{ minWidth: "300px" }}>
                        <div className="col-7 px-2 my-auto fw-bolder fs-7 text-left">
                          <Link
                            className="custom-link text-white"
                            to={`${link}${o._id.toString()}`}
                            onClick={(e) => e.stopPropagation()}
                          >
                            Order {getOrderNumber(o)}
                          </Link>
                        </div>
                        <div className="col-5 pr-2 pl-0 my-auto fs-7 text-left">
                          {o.amount + formatArticleUnit(o.unit, o.commodity)}
                        </div>
                        {idx < orders.length - 1 && <div className="border-bottom-dark-gray my-2" />}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          }
        >
          <div>
            {orders.slice(0, 2).map((o) => (
              <Link
                key={o._id.toString()}
                className="text-white fs-5 custom-link"
                to={`${link}${o._id.toString()}`}
                onClick={(e) => e.stopPropagation()}
              >
                <span>{getOrderNumber(o)}</span>
              </Link>
            ))}
            <div className="text-muted fs-5">and {orders.length - 2} more</div>
          </div>
        </HoverPopover>
      );
    return (
      <div>
        {orders.slice(0, 2).map((o) => (
          <Link
            key={o._id.toString()}
            className="text-white fs-5 custom-link"
            to={`${link}${o._id.toString()}`}
            onClick={(e) => e.stopPropagation()}
          >
            <span>{getOrderNumber(o)}</span>
          </Link>
        ))}
      </div>
    );
  };

  forwardOrder = (e: React.MouseEvent<HTMLTableRowElement>) => {
    const { order, history } = this.props;
    e.stopPropagation();
    history.push(
      `/${
        isCustomerOrder(order) ? "customerOrder" : isSampleOrder(order) ? "sampleOrder" : "supplierOrder"
      }/${order._id.toString()}`
    );
  };

  render() {
    const { order, context } = this.props;
    const sampleOrder = isSampleOrder(order);
    const stateDescription = getOrderStateDescriptions(order);
    const arrival = calculateArrivalInformation(order);
    const limitTimeLeft =
      isCustomerOrder(order) && order.request && order.limitTimeFrame
        ? getDaysBetween(
            new Date(order.createdAt.getTime() + order.limitTimeFrame * 24 * 60 * 60 * 1000),
            new Date(),
            true
          )
        : null;
    const orderProgress = getOrderProgress(order);
    const company = isSupplierOrder(order)
      ? getDocFromCollection(context.supplier, order.supplier)
      : getDocFromCollection(context.company, order.company);

    return (
      <tr className="cursor-pointer-row" onClick={this.forwardOrder}>
        <td className="align-middle">
          <div className="text-white fs-5 text-ellipsis" style={{ maxWidth: "25rem" }}>
            {order.amount}
            {formatArticleUnit(order.unit, order.commodity)} {order.commodity.title.en}
          </div>
          <div className="text-muted fs-7">
            {(isCustomerOrder(order) || isSampleOrder(order)) && "customerReference" in order && order.customerReference
              ? order.customerReference + " - " + company?.name
              : company?.name}
          </div>
          <span className="text-white fs-7">{getOrderNumber(order)}</span>
        </td>
        <td className="text-muted align-middle">{this.getOrdersReference()}</td>
        <td className="text-light align-middle">
          <div className="text-warning">{stateDescription.title}</div>
          <div className="text-muted">{getTimeDiffString(getLastStateUpdate(order)?.date)}</div>
        </td>
        <td className="text-light align-middle">
          <ProgressBar style={{ backgroundColor: "#232323", height: 5 }}>
            <ProgressBar min={0} max={100} now={orderProgress[0]} variant={orderProgress[1]} />
          </ProgressBar>
          <div className="fs-7 text-nowrap text-center">{orderProgress[0]}%</div>
        </td>
        <td className="text-light align-middle">{sampleOrder ? "Sample" : getOrderType(order)}</td>
        <td className="align-middle">
          {!isOrderCanceled(order) &&
            (sampleOrder ? (
              <span className="text-muted">
                CW {arrival.cw}-{arrival.year}
              </span>
            ) : "request" in order && order.request && limitTimeLeft ? (
              <>
                <div className="text-muted">
                  {limitTimeLeft > 0 ? limitTimeLeft : 0} {limitTimeLeft === 1 ? "day" : "days"} left
                </div>
                <div className="text-warning">requested</div>
              </>
            ) : (
              <>
                <div className="text-muted">
                  CW {arrival.cw}-{arrival.year}
                </div>
                {arrival.target ? (
                  <span className={"text-nowrap " + (arrival.late ? "text-danger" : "text-success")}>
                    {pluralize(arrival.weekDiff, "week")} {arrival.late ? "overdue" : "remaining"}
                  </span>
                ) : (
                  <span className="text-success">delivered</span>
                )}
              </>
            ))}
        </td>
      </tr>
    );
  }
}

export default withRouter(OrderRow);
