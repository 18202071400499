import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import SupplierPage from "./SupplierPage";
import { DataContextSupplier } from "../../../context/dataContext";

interface SupplierWrapperProps extends RouteComponentProps<SupplierParams> {}

interface SupplierParams {
  id: string;
}

const SupplierWrapper: React.FunctionComponent<SupplierWrapperProps> = (props) => {
  const context = useContext(DataContextSupplier);
  return <SupplierPage context={context} {...props} />;
};
export default SupplierWrapper;
