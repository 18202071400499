import { formatCurrency } from "../baseUtils";
import { CustomerOrderExtended } from "../../model/customerOrder.types";
import { CustomerCustomerOrderExtended } from "../../model/customer/customerCustomerOrder.types";
import { convertCurrency, Currencies, EURO } from "../currencyUtils";
import { getOrderNumber } from "../orderUtils";
import { formatAddress, getAddressByType } from "../addressUtils";
import { AddressType } from "../../model/commonTypes";
import { ORGANICCODE } from "./templateUtils";
import { formatOrderDate } from "../customerOrderUtils";

/**
 * Creates the order confirmation HTML
 * @param order Order that should be confirmed
 * @param notes Notes below positions
 * @param currencies Currencies from context
 * @param deliveryTerms terms of delivery
 * @param paymentTerms terms of payment
 * @param paymentTermConditions conditions additional to payment terms
 * @param additionalInfo additional information to add
 * @param deliveryCity city of delivery
 * @returns { string } a html string that can be used to create the PDF
 */
export function createOrderConfirmationHTML(
  order: CustomerOrderExtended | CustomerCustomerOrderExtended,
  notes: string,
  currencies: Currencies,
  deliveryTerms?: string,
  paymentTerms?: string,
  paymentTermConditions?: string,
  additionalInfo?: string,
  deliveryCity?: string
): string {
  const dTerm = deliveryTerms ? deliveryTerms : order.terms?.deliveryTerms ? order.terms.deliveryTerms : "";
  const dCity = deliveryCity
    ? deliveryCity
    : order.terms?.deliveryCity ?? getAddressByType(order.company.address, AddressType.A_SHIPPING)?.city ?? "";
  const delivery = dTerm + " " + dCity;
  const payment = paymentTerms ? paymentTerms : order.terms?.paymentTerms ? order.terms.paymentTerms : "";
  const paymentConditions =
    paymentTermConditions || paymentTermConditions === ""
      ? paymentTermConditions
      : order.terms
      ? order.terms.paymentTermConditions
      : "";
  const note = additionalInfo ? additionalInfo : order.terms?.note;
  const address = order.company.address[0];
  let html =
    // Header
    `<head><link href='https://fonts.googleapis.com/css?family=Signika&display=swap' rel='stylesheet'><style>tr:nth-child(even) {background-color: white;}</style><meta http-equiv='content-type' content='text/html; charset=utf-8'></head>` +
    `<body style='font-family: Signika'><img src='https://rawbids.com/images/logo-dark.png' alt="Logo" width='180' style='float:right'/>` +
    `<br/><br/><br/><br/><span style='font-size: 30px; font-weight: bold'>Order Confirmation ${getOrderNumber(order)}` +
    `</span><br/><span style='font-size: 20px; font-weight: bold'>${order.commodity.title.en}</span><br/><br/><br/>` +
    `<table cellpadding='5' cellspacing='0' style='width: 100%'><tbody>` +
    `<tr style='width: 100%; vertical-align: top'>` +
    `<td style='vertical-align: top'><span style='font-size:16px;  width: 33%; vertical-align: top'>Recipient:<br/><b>${
      order.company.name
    }</b><br/>${formatAddress(address).replace(/\n/g, "<br/>")}<br/>${
      order.company.vat ? "VAT-ID: " + order.company.vat : ""
    }</span>
    </td>` +
    `<td style='vertical-align: top'><span style='font-size: 16px; width: 33%; vertical-align: top'>Issued by:<br/><b>RAWBIDS GmbH</b><br/>Willy-Brandt-Str. 23, 20457 Hamburg<br/>Germany<br/>VAT-ID: DE354508196</span></td>` +
    `<td style='text-align: right; vertical-align: top'><span style='font-size:16px; width: 33%; vertical-align: top'><b>Order number: ${
      order.orderNo
    }</b>
    ${order.commodity.organic ? `<br/>${ORGANICCODE}` : ""}
    <br/>Order date: ${new Date().toLocaleDateString()}` +
    `${order.customerReference && `<br/><span>Reference number: ${order.customerReference}</span>`}` +
    `<br/>Expected delivery date: ${formatOrderDate(
      order
    )}<br/><br/>Contact: info@rawbids.com</span></td></tr></tbody></table><br/>`;
  html += `<table style='font-size:16px; width: 100%; display: block; margin-top:20px; margin-bottom:20px'><tbody><tr><td>Dear Sir or Madam, <br/><br/>We hereby confirm to you
the acceptance of order ${getOrderNumber(order)} as follows:</td></tr></tbody></table>`;
  html +=
    `<table cellpadding='5' cellspacing='0' style='font-size:15px; width:100%; background-color:#fafafa; margin-top:20px' ><thead><tr style='background-color:#333333; color: #ffffff'><th><b>Pos.</b></th>` +
    `<th style='min-width: 250px'><b style='white-space:nowrap'>Description</b></th><th><b>Amount</b></th><th><b style='white-space:nowrap;'>Unit</b></th><th><b style='white-space:nowrap;'>Per unit</b></th>` +
    `<th><b style='white-space:nowrap;'>Discount</b></th><th><b style='white-space:nowrap; float: right'>Total</b></th></tr></thead><tbody>`;
  // Commodity
  html +=
    `<tr><td>&nbsp;&nbsp;${1}</td>` +
    `<td style='width: 45%'><b>${order.commodity.title.en}</b><br/>${order.commodity.subtitle.en}</td>` +
    `<td>&nbsp;&nbsp;${order.amount}</td>` +
    `<td>&nbsp;&nbsp;${order.unit}</td>` +
    `<td>&nbsp;&nbsp;${formatCurrency(order.priceCommodities / order.amount, order.currency)}</td>` +
    `<td>&nbsp;&nbsp;${order.discount ? order.discount + "%" : ""}</td>` +
    `<td style='float: right; text-align: right'>${formatCurrency(order.priceCommodities, order.currency)}</td></tr>`;
  // Services
  for (let i = 0; i < order.services.length; i++) {
    const s = order.services[i];
    html +=
      `<tr><td>&nbsp;&nbsp;${i + 2}</td>` +
      `<td style='width: 45%'><b>${s.service.title.en}</b><br/>${s.service.description.en}</td>` +
      `<td>&nbsp;&nbsp;1</td>` +
      `<td>&nbsp;&nbsp;Service</td>` +
      `<td>&nbsp;&nbsp;${formatCurrency(
        convertCurrency(s.service.price, EURO, order.currency, currencies),
        order.currency
      )}</td>` +
      `<td></td>` +
      `<td style='float: right; text-align: right'>${formatCurrency(
        convertCurrency(s.service.price, EURO, order.currency, currencies),
        order.currency
      )}</td></tr>`;
  }
  html += `</tbody></table><br/>` + `<table style='width: 50%; float: right; display: block'><tbody>`;
  if (order.discount) {
    html += `<tr><td>Total before Discount</td><td style='float: right; text-align: right'><span style='float: right; text-align: right'><b>${formatCurrency(
      order.totalPrice,
      order.currency
    )}</b></span></td></tr>`;
    html += `<tr><td>Discount</td><td style='float: right; text-align: right'><span style='float: right; text-align: right'><b>${formatCurrency(
      -(order.totalPrice * (order.discount / 100)),
      order.currency
    )}</b></span></td></tr>`;
  }
  html += `<tr><td>Total</td><td style='float: right; text-align: right'><span style='float: right; text-align: right'><b style='font-size: 20px'>${formatCurrency(
    order.totalPrice * (1 - (order.discount || 0) / 100),
    order.currency
  )}</b></span></td></tr></tbody></table><br/><br/><br/><br/><br/><br/>`;
  if ((payment || paymentTermConditions) && delivery) {
    html += `<table style="font-size:16px; width: 100%; display: block">
      <tbody>
          <tr>
              <td>`;
    html += `<b>Terms of delivery: </b>${delivery}<br /><br /><b>Terms of payment: </b>${
      payment && paymentConditions
        ? payment + " " + paymentConditions
        : payment && !paymentConditions
        ? payment
        : paymentConditions && !payment
        ? paymentConditions
        : ""
    }<br /><br />`;
    if (note) {
      html += `<b>Additional information: </b>${note}<br /><br />`;
    }
    html += `</td></tr></tbody></table><br/><br/><br/>`;
  }
  html += `<table style='font-size:16px; width: 100%; display: block'><tbody><tr><td><span style='font-size:22px; font-weight: bold;'>Notes:</span><br/><br/>${notes}</td></tr></tbody></table>`;
  html += "</body>";

  return html;
}
