import _ from "lodash";
import React, { Component } from "react";
import PriceTickerPrice from "./PriceTickerPrice";
import {
  DataContextAnonymousType,
  DataContextCustomerType,
  DataContextInternalType,
  isAnonymousContext,
  isCustomerContext,
  isInternalContext,
} from "../../../context/dataContext";
import { PriceTickerData } from "../../../model/commonTypes";
import { Commodity } from "../../../model/commodity.types";
import { getBestOverallPrice, getCommoditiesStatistics } from "../../../utils/commodityUtils";
import { CommodityStatistics } from "../../../model/statistics/commodityStatistics.types";
import { BASE_CURRENCY } from "../../../utils/currencyUtils";

interface PriceTickerProps {
  context: DataContextInternalType | DataContextCustomerType | DataContextAnonymousType;
}

interface PriceTickerState {
  loading: boolean;
  articles: Array<PriceTickerData>;
}

class PriceTicker extends Component<PriceTickerProps, PriceTickerState> {
  lastUpdate: Date;

  constructor(props: PriceTickerProps) {
    super(props);
    this.lastUpdate = new Date(0);
    this.state = { articles: [], loading: true };
  }

  async componentDidMount() {
    this.setState({ articles: await this.prepareData() }, () => this.setState({ loading: false }));
    this.lastUpdate = new Date();
  }

  shouldComponentUpdate(): boolean {
    return this.lastUpdate < new Date(new Date().getTime() - 1000 * 60 * 5);
  }

  async componentDidUpdate(prevProps: Readonly<PriceTickerProps>) {
    if (!_.isEqual(prevProps.context.commodity, this.props.context.commodity)) {
      this.setState({ articles: await this.prepareData() }, () => this.setState({ loading: false }));
      this.lastUpdate = new Date();
    }
  }

  prepareData = async () => {
    const { context } = this.props;
    const { commodity, currencies, priceGraph } = context;
    const isInternal = isInternalContext(context);
    const isCustomerOrAnonymous = isCustomerContext(context) || isAnonymousContext(context);
    const now = new Date();
    const comRelevant = commodity.filter(
      (c) =>
        !c.disabled && c.approved && c.suppliers.some((s) => !s.disabled && s.prices.some((p) => p.validUntil > now))
    );
    // TODO Extend with finished products statistics; Part of RB-785
    const priceData = (await getCommoditiesStatistics(
      comRelevant.map((c) => c._id),
      ["priceDevelopment", "commodity"]
    )) as Array<Pick<CommodityStatistics, "priceDevelopment" | "commodity">> | undefined;
    const articles: Array<PriceTickerData> = [];
    for (let i = 0; i < comRelevant.length; i++) {
      const c = comRelevant[i];
      const statistics = priceData ? priceData.find((pD) => pD.commodity === c._id.toString()) : null;
      const relatedGraph = priceGraph.find((graph) => graph.article === c._id.toString());
      // Skip articles without graph information
      if (!statistics || !relatedGraph || !statistics.priceDevelopment) continue;
      let price = -1;
      let priceCurrency = "";
      if (isInternal) {
        const avgPrice = getBestOverallPrice((c as Commodity).suppliers, currencies);
        if (avgPrice) {
          price = avgPrice;
          priceCurrency = BASE_CURRENCY;
        }
      } else if (isCustomerOrAnonymous) {
        if (c.fromPrice) {
          if (c.fromPrice.airfreight.price) {
            price = c.fromPrice.airfreight.price;
            priceCurrency = c.fromPrice.airfreight.currency;
          }
          if (c.fromPrice.seafreight.price && c.fromPrice.seafreight.price < price) {
            price = c.fromPrice.seafreight.price;
            priceCurrency = c.fromPrice.seafreight.currency;
          }
          if (c.fromPrice.warehouse.price && c.fromPrice.warehouse.price < price) {
            price = c.fromPrice.warehouse.price;
            priceCurrency = c.fromPrice.warehouse.currency;
          }
        }
      }
      if (!price || price <= 0) continue;
      articles.push({
        article: c,
        priceGraph: relatedGraph.graph,
        price,
        priceDevelopment: statistics.priceDevelopment,
        priceCurrency,
      });
    }
    return articles;
  };

  render() {
    const { articles, loading } = this.state;
    if (loading && articles.length === 0)
      return (
        <div style={{ width: "100%", maxWidth: "100%" }} className="text-white text-center mt-3">
          <div className="splash-screen-relative">
            <svg className="splash-spinner" viewBox="0 0 50 50" style={{ height: "25px", width: "25px" }}>
              <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
            </svg>
            <span className="ml-2">Loading</span>
          </div>
        </div>
      );

    let articlesRelevant = _.cloneDeep(articles);
    if (articles.length > 20) {
      articlesRelevant = articlesRelevant.filter((c) => c.priceDevelopment && c.priceDevelopment?.percentage !== 0);
    }
    while (articlesRelevant.length > 0 && articlesRelevant.length < 5) {
      articlesRelevant = articlesRelevant.concat(_.cloneDeep(articlesRelevant));
    }

    return (
      <div style={{ width: "100%", paddingBottom: 2, paddingTop: 2 }} className="marquee">
        <div
          className="header-animation"
          style={{ animation: `marquee ${articlesRelevant.length * 7}s linear infinite` }}
        >
          {articlesRelevant.map((c, idx) => (
            <PriceTickerPrice
              key={
                c.article._id.toString() + idx.toString() // Needed since we might have filled the array with the same article multiple times
              }
              tickerData={c}
            />
          ))}
        </div>
        {/* We need to repeat the data so that the user never sees that the animation ends */}
        <div
          className="header-animation-repeat"
          style={{ animation: `marquee ${articlesRelevant.length * 7}s linear infinite` }}
        >
          {articlesRelevant.map((c, idx) => (
            <PriceTickerPrice
              key={
                c.article._id.toString() + idx.toString() // Needed since we might have filled the array with the same article multiple times
              }
              tickerData={c}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default PriceTicker;
