import React, { PureComponent } from "react";
import { Accordion } from "react-bootstrap";
import Search from "../../../common/Search";
import SupplierAndCommodityDocuments from "../SupplierAndCommodityDocuments";
import { T_GENERALSUPPLIER, T_SUPPLIER } from "../../internal/modals/UploadDocumentModal";
import { getSupplierDocuments } from "../../../../utils/commodityUtils";
import { Supplier, SupplierFile } from "../../../../model/supplier.types";
import { ArticleExtended } from "../../../../utils/productArticleUtils";
import { UploadedFileExtended } from "../../../../model/commodity.types";
import { pluralize } from "../../../../utils/baseUtils";

interface CommodityPageDocumentsProps {
  article: ArticleExtended;
}

interface CommodityPageDocumentsState {
  search: string;
}

class CommodityPageDocuments extends PureComponent<CommodityPageDocumentsProps, CommodityPageDocumentsState> {
  constructor(props: CommodityPageDocumentsProps) {
    super(props);
    this.state = {
      search: "",
    };
  }

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });

  render() {
    const { article } = this.props;
    const { search } = this.state;
    const { documents, documentsMap } = getSupplierDocuments(article, search);
    return (
      <div className="card bg-white">
        <div className="card-header border-0 mt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">Documents</span>
            <span className="text-muted fw-bold fs-7">{pluralize(documents.length, "Document")}</span>
          </h3>
          <div className="card-toolbar">
            <Search onSearch={this.handleSearch} value={search} />
          </div>
        </div>
        <div className="card-body p-4 pt-4">
          <div className=" pt-0">
            <div className="card-body p-4 modal-content-scroll">
              <Accordion alwaysOpen={true} defaultActiveKey={Object.keys(documentsMap)}>
                {Object.entries(documentsMap).map(([id, docs]) => {
                  const supplier = docs[0] && docs[0].supplier;
                  return (
                    <SupplierAndCommodityDocuments
                      key={id}
                      type={id === "general" ? T_GENERALSUPPLIER : T_SUPPLIER}
                      article={article}
                      supplier={supplier as Supplier | undefined}
                      documents={docs as Array<UploadedFileExtended | SupplierFile>}
                      noSupplierSelection={id === "general"}
                    />
                  );
                })}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CommodityPageDocuments;
