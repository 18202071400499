import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContextSupplierType } from "../../../context/dataContext";
import SupplierPageTabPanel from "./SupplierPageTabPanel";
import SupplierPageSummary from "./SupplierPageSummary";
import { getDocFromCollection } from "../../../utils/baseUtils";
import { getDocumentDB, SUPPLIER } from "../../../services/dbService";
import { SupplierSupplier, SupplierSupplierExtended } from "../../../model/supplier/supplierSupplier.types";
import userService from "../../../services/userService";

import { extendSupplierSupplier } from "../../../utils/dataTransformationUtils";

interface SupplierProps extends RouteComponentProps {
  context: DataContextSupplierType;
}

interface SupplierState {
  supplier?: SupplierSupplierExtended;
}

class SupplierPage extends PureComponent<SupplierProps, SupplierState> {
  _isMounted = false;
  constructor(props: SupplierProps) {
    super(props);
    this.state = {
      supplier: this.getSupplier(props),
    };
  }

  componentDidMount = async () => {
    const { history, context } = this.props;
    if (this.state.supplier) return;
    const id = userService.getCompany();
    if (!id) {
      history.push("/");
      return;
    }
    this._isMounted = true;
    const supplier = await getDocumentDB<SupplierSupplier>(SUPPLIER, id);
    if (!supplier) {
      history.push("/");
      return;
    }
    context.addDocuments(SUPPLIER, [supplier]);
    const supplierExtended = extendSupplierSupplier(supplier, context);
    if (this._isMounted) this.setState({ supplier: supplierExtended });
  };

  componentDidUpdate() {
    const supplier = this.getSupplier(this.props);
    if (!_.isEqual(supplier, this.state.supplier)) {
      this.setState({
        supplier,
      });
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  /**
   * Get the initial supplier data
   * @param props the components properties
   * @returns {SupplierSupplierExtended | undefined} a supplier object or undefined
   */
  getSupplier = (props: SupplierProps): SupplierSupplierExtended | undefined => {
    const supplier = getDocFromCollection(props.context.supplier, userService.getCompany());
    if (supplier) return extendSupplierSupplier(supplier, props.context);
  };

  render() {
    const { context } = this.props;
    const { supplier } = this.state;
    if (!supplier) return null;
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="d-flex flex-column flex-xl-row">
              <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
                <SupplierPageSummary supplier={supplier} context={context} />
              </div>
              <SupplierPageTabPanel supplier={supplier} context={context} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SupplierPage;
