import React, { PureComponent } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface DashboardStatWidgetProps {
  title: string;
  value?: number;
}

class DashboardStatWidget extends PureComponent<DashboardStatWidgetProps> {
  getColor = () => {
    const { value } = this.props;
    if (value === undefined) return "#A1A5B7";
    if (value < 70) {
      return "#f1416c";
    }
    if (value < 90) {
      return "#ffc700";
    }
    return "#50cd89";
  };

  render() {
    const { title, value } = this.props;

    const options: ApexOptions = {
      chart: {
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#111111",
            margin: 5,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "16px",
              fontWeight: "600",
              color: "white",
              show: value !== undefined,
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        colors: [this.getColor()],
      },
      stroke: {
        dashArray: 2,
      },
    };

    return (
      <div id="chart" className={"text-center py-1 mx-2"} style={{ width: 120 }}>
        <Chart options={options} series={[value !== undefined ? value : 100]} type="radialBar" />
        <span className="text-white text-nowrap">{value !== undefined ? title : "Loading..."}</span>
      </div>
    );
  }
}

export default DashboardStatWidget;
