import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import AsideUserPicture from "./asideContent/AsideUserPicture";
import userService from "../../services/userService";
import { toAbsoluteUrl } from "../../utils/baseUtils";
import {
  DataContextAnonymous,
  DataContextCustomer,
  DataContextInternal,
  DataContextSupplier,
  DataContextType,
  isInternalContext,
  isSupplierContext,
} from "../../context/dataContext";
import { CO_ARCHIVED, CO_CANCELED, CO_STATES } from "../../model/customerOrder.types";
import { SAMO_STATE } from "../../model/sampleOrder.types";
import { CustomerRequestState } from "../../model/customerRequest.types";
import { ADMIN, CUSTOMER, INTERNAL, SCM, SUPPLIER } from "../../utils/userUtils";
import authService from "../../services/authService";

enum BadgeTypes {
  CUSTOMERORDERS = "customerOrders",
  CUSTOMERSAMPLES = "customerSamples",
  CUSTOMERREQUESTS = "customerRequests",
}

interface AsideProps {
  view: "internal" | "customer" | "supplier" | "anonymous";
}

interface EntryDefinition {
  path: string;
  additionalPaths?: Array<string>;
  title: string;
  svg: JSX.Element;
  svgIconClass?: string;
  disabled?: boolean;
  id?: string;
  badge?: string;
}

class Aside extends Component<AsideProps> {
  static contextType =
    userService.getUserType() === CUSTOMER
      ? DataContextCustomer
      : userService.getUserType() === INTERNAL
      ? DataContextInternal
      : userService.getUserType() === SUPPLIER
      ? DataContextSupplier
      : DataContextAnonymous;
  context!: DataContextType;

  getAsideDefinition = () => {
    switch (this.props.view) {
      case "internal":
        return isInternalContext(this.context) && this.context.currentView === SCM
          ? SCM_DEFINITION
          : INTERNAL_DEFINITION;
      case "customer":
        return CUSTOMER_DEFINITION;
      case "supplier":
        return SUPPLIER_DEFINITION;
      case "anonymous":
        return ANONYMOUS_DEFINITION;
    }
  };

  render() {
    const asideDefinition = this.getAsideDefinition();
    const isSC = isSupplierContext(this.context);
    return (
      <div
        id="kt_aside"
        className="aside pb-5 pt-5 pt-lg-0"
        style={{ overflowY: "auto", top: isSC ? "75px" : "115px", zIndex: 99 }}
      >
        <div className="aside-menu flex-column-fluid" id="kt_aside_menu">
          <div className="mb-2 mb-lg-5 pe-lg-n1" id="kt_aside_menu_wrapper">
            <div
              className="menu menu-column menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold"
              id="#kt_aside_menu"
            >
              <AsideUserPicture context={this.context} />
              {asideDefinition.map((ad) => (
                <AsideEntry
                  key={ad.path}
                  path={ad.path}
                  title={ad.title}
                  svg={ad.svg}
                  additionalPaths={ad.additionalPaths}
                  svgIconClass={ad.svgIconClass}
                  disabled={ad.disabled}
                  id={ad.id}
                  badge={ad.badge}
                  context={this.context}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

interface AsideEntryProps {
  path: string;
  additionalPaths?: Array<string>;
  title: string;
  svg: JSX.Element;
  svgIconClass?: string;
  disabled?: boolean;
  id?: string;
  badge?: string;
  context?: DataContextType;
}

const AsideEntry: React.FunctionComponent<AsideEntryProps> = ({
  path,
  additionalPaths,
  title,
  svg,
  svgIconClass,
  disabled,
  id,
  badge,
  context,
}) => {
  const isActive = (page: string, additionalPaths?: Array<string>) => {
    if (
      page === window.location.pathname ||
      (additionalPaths &&
        additionalPaths.some((p) => p === window.location.pathname || window.location.pathname.includes(p)))
    ) {
      return "active";
    }
  };

  const getBadgeCount = React.useCallback(() => {
    let count: number | undefined;
    if (context && !isSupplierContext(context)) {
      switch (badge) {
        case BadgeTypes.CUSTOMERORDERS:
          count = context?.customerOrder.filter(
            (cO) => !([CO_CANCELED, CO_ARCHIVED] as Array<CO_STATES>).includes(cO.state)
          ).length;
          break;
        case BadgeTypes.CUSTOMERSAMPLES:
          count = context?.sampleOrder.filter(
            (sampO) =>
              !([SAMO_STATE.REJECTED, SAMO_STATE.CANCELED, SAMO_STATE.ARCHIVED] as Array<SAMO_STATE>).includes(
                sampO.state
              )
          ).length;
          break;
        case BadgeTypes.CUSTOMERREQUESTS:
          count = context?.customerRequest.filter((cR) => cR.state === CustomerRequestState.OPEN).length;
          break;
      }
    }
    return count && count > 99 ? "99\u207A" : count;
  }, [context, badge]);

  const [badgeCount, setBadgeCount] = useState(getBadgeCount());

  useEffect(() => {
    setBadgeCount(getBadgeCount());
  }, [context, badge]);

  if (title !== "Logout" && (disabled || !userService.getUserData()?.onboardingDone)) {
    return (
      <span className="menu-item py-2 disabled" style={{ opacity: 0.3 }}>
        <span className="menu-link menu-center disabled">
          <span className="menu-icon svg-icon justify-content-center me-0 py-2">
            <span className={"svg-icon svg-icon-primary svg-icon-2x " + (svgIconClass || "")}>{svg}</span>
          </span>
          <span className="menu-text">{title}</span>
        </span>
      </span>
    );
  }
  return (
    <Link id={id ?? ""} className="menu-item py-2" to={path}>
      <span className={"menu-link menu-center " + isActive(path, additionalPaths)}>
        <span className="menu-icon svg-icon justify-content-center me-0 py-2" style={{ position: "relative" }}>
          {badgeCount !== undefined && (
            <span
              className="badge badge-circle badge-light-dark badge-sm fw-boldest"
              style={{ position: "absolute", top: -5, right: -15 }}
            >
              {badgeCount}
            </span>
          )}
          <span className={"svg-icon svg-icon-primary svg-icon-2x " + (svgIconClass || "")}>{svg}</span>
        </span>
        <span className="menu-text">{title}</span>
      </span>
    </Link>
  );
};

export default Aside;

const roles = authService.isLoggedIn() ? userService.getRoles() : [];
const scmOrAdmin = roles.includes(ADMIN) || roles.includes(SCM);

const INTERNAL_DEFINITION: Array<EntryDefinition> = [
  {
    path: "/dashboard",
    title: "Dashboard",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/dashboard.svg")} />,
  },
  {
    path: "/controlling",
    title: "Controlling",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/markets.svg")} />,
  },
  {
    path: "/orders",
    title: "Orders",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/orders.svg")} />,
    additionalPaths: [
      "/customerOrder/",
      "/supplierOrder/",
      "/sampleOrder/",
      "/canceledOrders",
      "/createStockOrder/",
      "/createCustomerOrder",
    ],
  },
  {
    path: "/contracts",
    title: "Contracts",
    svg: <SVG src={toAbsoluteUrl("/assets/media/icons/duotune/graphs/gra007.svg")} />,
    additionalPaths: ["/customerContract/", "/createCustomerContract", "/canceledContracts"],
  },
  {
    path: "/articles",
    title: "Articles",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/commodities.svg")} />,
    additionalPaths: ["/articles/", "/commodity/", "/finishedProduct/"],
  },
  {
    path: "/suppliers",
    title: "Suppliers",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/suppliers.svg")} />,
    additionalPaths: ["/supplier/"],
  },
  {
    path: "/requests",
    title: "Requests",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/requests.svg")} />,
  },
  {
    path: "/customers",
    title: "Customers",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/customers.svg")} />,
    additionalPaths: ["/customer/"],
  },
  {
    path: "/customerInvoices",
    title: "Finance",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/finance.svg")} />,
    svgIconClass: "svg-icon-2hx",
  },
  {
    path: "/logistics",
    title: "Logistics",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/delivery-truck.svg")} />,
    svgIconClass: "svg-icon-2hx",
    disabled: !scmOrAdmin,
  },
  {
    path: "/warehouse",
    title: "Warehouse",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/warehouse.svg")} />,
    svgIconClass: "svg-icon-2hx",
  },
  {
    path: "/news",
    title: "News",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/news-paper.svg")} />,
    additionalPaths: ["/article/"],
  },
  {
    path: "/tools",
    title: "Tools",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/tools.svg")} />,
    svgIconClass: "svg-icon-2hx",
  },
  {
    path: "/settings",
    additionalPaths: ["/properties", "/activeSubstances", "/profile"],
    title: "Settings",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/settings.svg")} />,
  },
  {
    path: "/logout",
    title: "Logout",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/logout.svg")} />,
    id: "aside-logout",
  },
];

const SCM_DEFINITION: Array<EntryDefinition> = [
  {
    path: "/scm",
    title: "Dashboard",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/dashboard.svg")} />,
  },
  {
    path: "/orders",
    title: "Orders",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/orders.svg")} />,
    additionalPaths: ["/customerOrder/", "/supplierOrder/", "/sampleOrder/"],
  },
  {
    path: "/logistics",
    title: "Logistics",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/delivery-truck.svg")} />,
    svgIconClass: "svg-icon-2hx",
    disabled: !scmOrAdmin,
  },
  {
    path: "/forwarders",
    title: "Forwarders",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/transport-cart.svg")} />,
    svgIconClass: "svg-icon-2hx",
    disabled: !scmOrAdmin,
  },
  {
    path: "/tools",
    title: "Tools",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/tools.svg")} />,
    svgIconClass: "svg-icon-2hx",
  },
  {
    path: "/settings",
    additionalPaths: ["/properties", "/activeSubstances", "/profile"],
    title: "Settings",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/settings.svg")} />,
  },
  {
    path: "/logout",
    title: "Logout",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/logout.svg")} />,
    id: "aside-logout",
  },
];

const CUSTOMER_DEFINITION: Array<EntryDefinition> = [
  {
    path: "/dashboard",
    title: "Dashboard",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/dashboard.svg")} />,
  },
  {
    path: "/articles",
    title: "Commodities",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/commodities.svg")} />,
    additionalPaths: ["/commodity/", "/finishedProduct/"],
  },
  {
    path: "/orders",
    title: "Orders",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/orders.svg")} />,
    additionalPaths: ["/order"],
    badge: BadgeTypes.CUSTOMERORDERS,
  },
  {
    path: "/samples",
    title: "Samples",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/samples.svg")} />,
    additionalPaths: ["/sampleOrder"],
    badge: BadgeTypes.CUSTOMERSAMPLES,
  },
  {
    path: "/requests",
    title: "Requests",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/requests.svg")} />,
    additionalPaths: ["/request"],
    badge: BadgeTypes.CUSTOMERREQUESTS,
  },
  {
    path: "/contracts",
    title: "Contracts",
    svg: <SVG src={toAbsoluteUrl("/assets/media/icons/duotune/graphs/gra007.svg")} />,
    additionalPaths: ["/contract/"],
  },
  {
    path: "/invoices",
    title: "Invoices",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/finance.svg")} />,
    svgIconClass: "svg-icon-2hx",
  },
  {
    path: "/support",
    title: "Support",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/support.svg")} />,
    disabled: true,
  },
  {
    path: "/news",
    title: "News",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/news-paper.svg")} />,
    additionalPaths: ["/article/"],
  },
  {
    path: "/settings",
    additionalPaths: ["/profile"],
    title: "Settings",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/settings.svg")} />,
  },
  {
    path: "/logout",
    title: "Logout",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/logout.svg")} />,
    id: "aside-logout",
  },
];

const ANONYMOUS_DEFINITION: Array<EntryDefinition> = [
  {
    path: "/dashboard",
    title: "Dashboard",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/dashboard.svg")} />,
  },
  {
    path: "/articles",
    title: "Commodities",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/commodities.svg")} />,
    additionalPaths: ["/commodity/", "/finishedProduct/"],
  },
  {
    path: "/orders",
    title: "Orders",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/orders.svg")} />,
    additionalPaths: ["/order"],
    badge: BadgeTypes.CUSTOMERORDERS,
  },
  {
    path: "/samples",
    title: "Samples",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/orders.svg")} />,
    additionalPaths: ["/sampleOrder"],
    badge: BadgeTypes.CUSTOMERSAMPLES,
  },
  {
    path: "/requests",
    title: "Requests",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/orders.svg")} />,
    additionalPaths: ["/request"],
    badge: BadgeTypes.CUSTOMERREQUESTS,
  },
  {
    path: "/contracts",
    title: "Contracts",
    svg: <SVG src={toAbsoluteUrl("/assets/media/icons/duotune/graphs/gra007.svg")} />,
    additionalPaths: ["/contract/"],
  },
  {
    path: "/invoices",
    title: "Invoices",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/finance.svg")} />,
    svgIconClass: "svg-icon-2hx",
  },
  {
    path: "/support",
    title: "Support",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/support.svg")} />,
    disabled: true,
  },
  {
    path: "/news",
    title: "News",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/news-paper.svg")} />,
    additionalPaths: ["/article/"],
  },
  {
    path: "/settings",
    additionalPaths: ["/profile"],
    title: "Settings",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/settings.svg")} />,
    disabled: true,
  },
  {
    path: "/logout",
    title: "Logout",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/logout.svg")} />,
    id: "aside-logout",
  },
];

const SUPPLIER_DEFINITION: Array<EntryDefinition> = [
  {
    path: "/dashboard",
    title: "Overview",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/dashboard.svg")} />,
  },
  {
    path: "/articles",
    title: "Commodities",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/commodities.svg")} />,
    additionalPaths: ["/commodity/", "/finishedProduct/"],
  },
  {
    path: "/orders",
    title: "Orders",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/orders.svg")} />,
    disabled: true,
  },
  {
    path: "/scheduling",
    title: "Scheduling",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/scheduling.svg")} />,
    disabled: true,
  },
  {
    path: "/markets",
    title: "Markets",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/markets.svg")} />,
    disabled: true,
  },
  {
    path: "/support",
    title: "Support",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/support.svg")} />,
    disabled: true,
  },
  {
    path: "/settings",
    additionalPaths: [
      "/properties",
      "/activeSubstances",
      "/profile",
      "/supplier",
      "/packagingDimensions",
      "/notificationSettings",
    ],
    title: "Settings",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/settings.svg")} />,
  },
  {
    path: "/logout",
    title: "Logout",
    svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/logout.svg")} />,
    id: "aside-logout",
  },
];
