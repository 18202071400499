import _ from "lodash";
import React, { PureComponent } from "react";
import ServiceRow from "./ServiceRow";
import BaseListing from "../../common/BaseListing";
import Search from "../../common/Search";
import { doFuseSearch, getComponentState } from "../../../utils/baseUtils";
import { paginate, PaginationState } from "../../common/Pagination";
import { DataContextInternal } from "../../../context/dataContext";

interface ServiceListingProps {
  context: React.ContextType<typeof DataContextInternal>;
}

interface ServiceListingState extends PaginationState {
  search: string;
}

const COMPONENT_NAME = "ServiceListing";

class ServiceListing extends PureComponent<ServiceListingProps, ServiceListingState> {
  headerDefinition = [
    { title: "Name", style: { width: "30%" } },
    { title: "Description", style: { width: "35%" } },
    { title: "Price", style: { width: "10%" } },
    { title: "Duration", style: { width: "10%" } },
    { title: "Action", className: "text-right", style: { width: "15%" } },
  ];

  constructor(props: ServiceListingProps) {
    super(props);
    this.state = {
      search: "",
      currentPage: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {
    const state = getComponentState(this.props.context, COMPONENT_NAME);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(COMPONENT_NAME, this.state);
  }

  handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ search: e.target.value, currentPage: 1 });
  };

  /**
   * Filters the services by name if a search query was entered.
   * @returns { Array<Service> } Filtered services
   */
  getFilteredServices = () => {
    const { service } = this.props.context;
    const { search } = this.state;
    if (!search.trim()) return _.orderBy(service, "title.en");
    return doFuseSearch(service, search, ["title.en", "description.en"]);
  };

  render() {
    const { currentPage, pageSize } = this.state;
    const filteredServices = this.getFilteredServices();
    const paginatedServices = paginate(filteredServices, currentPage, pageSize);
    return (
      <>
        <div className="d-flex align-items-center my-5">
          <div className="w-50">
            <Search
              onSearch={this.handleChangeSearch}
              placeholder="Search..."
              inputClasses="form-control custom-form-control"
            />
          </div>
        </div>
        <BaseListing
          headerDefinition={this.headerDefinition}
          documents={filteredServices}
          noHover={true}
          bodyContent={
            <>
              {paginatedServices.map((aS) => (
                <ServiceRow key={aS._id.toString()} service={aS} />
              ))}
            </>
          }
          currentPage={currentPage}
          pageSize={pageSize}
          baseSize={25}
          onPageChange={(currentPage) => this.setState({ currentPage })}
          onPageSizeChange={(pageSize) => this.setState({ pageSize, currentPage: 1 })}
        />
      </>
    );
  }
}

export default ServiceListing;
