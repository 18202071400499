import React from "react";
import { Input } from "../../../common/Input";
import { CostObject } from "../../../../model/configuration/calculationConfiguration.types";

interface CalculationCostObjectProps {
  cost: CostObject;
  sizeClassName?: string;
  onChangeCost: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CalculationCostObject: React.FunctionComponent<CalculationCostObjectProps> = ({
  cost,
  sizeClassName,
  onChangeCost,
}) => {
  return (
    <div className={sizeClassName ?? "col-xl-4 mt-2"}>
      <label className="fs-5 fw-bold mb-2">
        {cost.description}
        {cost.minimum ? ` (Minimum: ${cost.minimum}${cost.currency})` : ""}
      </label>
      <div className="input-group">
        <Input
          className="form-control custom-form-control"
          type={"number"}
          min={0}
          name={cost.description}
          value={cost.costPerUnit}
          onChange={onChangeCost}
        />
        <div className="input-group-append rounded-end bg-custom-light-gray">
          <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
            {cost.currency}/{cost.unit}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalculationCostObject;
