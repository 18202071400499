import React, { PureComponent } from "react";
import { Nav, Tab } from "react-bootstrap";
import { CO_REQUESTEDBYCUSTOMER, CustomerOrderExtended } from "../../../../model/customerOrder.types";
import { DataContextInternalType } from "../../../../context/dataContext";
import { TabDefinition } from "../../../common/CustomTypes";
import CustomerOrderWorkflow from "./tabPanels/CustomerOrderWorkflow";
import { SupplierOrder } from "../../../../model/supplierOrder.types";
import CustomerOrderServices from "./tabPanels/CustomerOrderServices";
import OrderDocuments from "../../common/OrderDocuments";
import OrderInvoices from "../../common/OrderInvoices";
import OrderHistory from "../../common/OrderHistory";
import WorkflowRequested from "./tabPanels/workflowTabPanels/WorkflowRequested";
import LimitOrderHandlingModal from "./LimitOrderHandlingModal";
import CustomerOrderCalculation from "./tabPanels/CustomerOrderCalculation";
import OrderNotes from "../../common/OrderNotes";
import CustomerOrderUsedBatches from "./tabPanels/CustomerOrderUsedBatches";
import OrderCreditNotes from "../../common/OrderCreditNotes";

interface CustomerOrderTabPanelProps {
  order: CustomerOrderExtended;
  context: DataContextInternalType;
  supplierOrder?: SupplierOrder;
}

interface CustomerOrderTabPanelState {
  currentTab: string;
}

class CustomerOrderTabPanel extends PureComponent<CustomerOrderTabPanelProps, CustomerOrderTabPanelState> {
  constructor(props: CustomerOrderTabPanelProps) {
    super(props);
    this.state = { currentTab: this.getTabDefinitions()[0].name };
  }

  componentDidUpdate(prevProps: Readonly<CustomerOrderTabPanelProps>) {
    if (
      prevProps.order._id.toString() !== this.props.order._id.toString() ||
      (prevProps.order.state === CO_REQUESTEDBYCUSTOMER && this.props.order.state !== CO_REQUESTEDBYCUSTOMER)
    ) {
      this.setState({ currentTab: this.getTabDefinitions()[0].name });
    }
  }

  getTabDefinitions = (): Array<TabDefinition> => {
    const { order, context, supplierOrder } = this.props;
    if (order.state === CO_REQUESTEDBYCUSTOMER)
      return [
        {
          name: "limit",
          tabContent: "Request",
          component: <WorkflowRequested order={order} context={context} />,
        },
        {
          name: "services",
          tabContent: "Services",
          component: <CustomerOrderServices order={order} context={context} />,
        },
        {
          name: "history",
          tabContent: "History",
          component: <OrderHistory order={order} context={context} />,
        },
      ];
    return [
      {
        name: "workflow",
        tabContent: "Workflow",
        component: <CustomerOrderWorkflow order={order} context={context} supplierOrder={supplierOrder} />,
      },
      {
        name: "services",
        tabContent: "Services",
        component: <CustomerOrderServices order={order} context={context} />,
      },
      {
        name: "calculation",
        tabContent: "Calculation",
        component: <CustomerOrderCalculation order={order} context={context} />,
      },
      {
        name: "usedBatches",
        tabContent: "Used Batches",
        component: <CustomerOrderUsedBatches order={order} context={context} />,
      },
      {
        name: "documents",
        tabContent: "Documents",
        component: <OrderDocuments order={order} context={context} />,
      },
      {
        name: "invoices",
        tabContent: "Invoices",
        component: (
          <div>
            <OrderInvoices order={order} context={context} />
            <OrderCreditNotes order={order} context={context} />
          </div>
        ),
      },
      {
        name: "notes",
        tabContent: "Notes",
        component: <OrderNotes order={order} context={context} />,
      },
      {
        name: "history",
        tabContent: "History",
        component: <OrderHistory order={order} context={context} />,
      },
    ];
  };

  /**
   * Handle tab changes
   * @param eventKey new tab
   */
  handleTab = (eventKey: string | null) => {
    if (eventKey && eventKey !== this.state.currentTab) this.setState({ currentTab: eventKey });
  };

  render() {
    const { order, context } = this.props;
    const { currentTab } = this.state;
    const tabs = this.getTabDefinitions();

    return (
      <Tab.Container id="customerOrderTabs" activeKey={currentTab} onSelect={this.handleTab}>
        <div className="flex-lg-row-fluid ms-lg-15">
          <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
            {tabs.map((t) => (
              <li key={t.name + "_link"} className="nav-item cursor-pointer">
                <Nav.Link
                  role="tab"
                  id={t.name}
                  eventKey={t.name}
                  active={currentTab === t.name}
                  className="text-active-white pb-4 "
                >
                  {t.tabContent}
                </Nav.Link>
              </li>
            ))}
            {order.state === CO_REQUESTEDBYCUSTOMER && (
              <div className="ms-auto">
                <li className="nav-item ms-auto">
                  <LimitOrderHandlingModal order={order} context={context} />
                </li>
              </div>
            )}
          </ul>
          <Tab.Content>
            {tabs.map((t) => (
              <Tab.Pane id={t.name} key={t.name} eventKey={t.name}>
                {t.component}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </div>
      </Tab.Container>
    );
  }
}

export default CustomerOrderTabPanel;
