import _ from "lodash";
import { BSON } from "realm-web";
import { PackagingDimension, T_S_ADDPACKAGINGDIMENSIONS, T_S_UPDATEPACKAGINGDIMENSIONS } from "../model/supplier.types";
import { Action, SUPPLIER, transaction } from "../services/dbService";
import { DEFAULT_PALETTE } from "./priceCalculationUtils";
import { getDocFromCollection, round } from "./baseUtils";
import { getPaletteForCommodity, getSupplierTimelineEntry } from "./supplierUtils";
import { Commodity } from "../model/commodity.types";
import { PaletteData } from "../model/configuration/calculationConfiguration.types";
import { SelectOption } from "../components/common/CustomSelect";
import { CO_TRANSPORT } from "../model/customerOrder.types";
import { FinishedProduct } from "../model/finishedProduct.types";
import { isFinishedProduct } from "./finishedProductUtils";
import { DataContextInternalType } from "../context/dataContext";

export enum PDTransportTypes {
  AIR = "air",
  SEA = "sea",
  ROAD = "road",
  RAIL = "rail",
}

export const PD_TRANSPORT_TYPES = Object.values(PDTransportTypes);

export const PD_TRANSPORT_TYPE_OPTIONS = Object.values(PDTransportTypes).map((t) => {
  return { value: t, label: _.upperFirst(t) };
});

/**
 * Calculate the CBM with the given length, width and height.
 * @param length Length of the palette
 * @param width Width of the palette
 * @param height Height of the palette
 * @returns { number } CBM
 */
export function calculateCBM(length: number, width: number, height: number): number {
  return round((length * width * height) / (1000 * 1000), 2);
}

/**
 * Return information about the given packaging dimension in a single string
 * @param pD PackagingDimension whose that should be returned
 * @returns {string} Packaging dimension information
 */
export function getPackagingDimensionString(pD: PackagingDimension): string {
  let pDString = `${pD.length}cm x ${pD.width}cm x ${pD.height}cm (${pD.cbm}m³)`;
  if (pD.stackable) pDString += " Stackable";
  if (_.isEqual(pD, getStandardPackagingDimension(pD._id))) pDString += " (Standard Palette)";
  return pDString;
}

/**
 * Transforms a packaging dimension into palette data
 * @param pD PackagingDimension that should be transformed
 * @returns {PaletteData} Transformed data
 */
export function packagingDimensionAsPaletteData(pD: PackagingDimension): PaletteData {
  const { height, length, width, cbm, netWeight, grossWeight, stackable } = pD;
  return { height, length, width, cbm, netWeight, grossWeight, stackable };
}

/**
 * Inserts a new packaging dimension for the supplier into the database
 * @param packagingDimension Packaging dimension that should be inserted
 * @param supplierId ID of the supplier
 * @returns { Promise<boolean> } Result of the insert
 */
export async function insertPackagingDimension(
  packagingDimension: PackagingDimension,
  supplierId: BSON.ObjectId | string
): Promise<boolean> {
  const timelineEntry = getSupplierTimelineEntry(T_S_ADDPACKAGINGDIMENSIONS);
  const actions: Array<Action> = [
    {
      collection: SUPPLIER,
      filter: { _id: new BSON.ObjectId(supplierId.toString()) },
      push: { packagingDimensions: packagingDimension, timeline: timelineEntry },
    },
  ];
  return transaction(actions);
}

/**
 * Updates a packaging dimension of the supplier
 * @param packagingDimension Packaging dimension that should be updated
 * @param supplierId ID of the supplier
 * @returns { Promise<boolean> } Result of the update
 */
export async function updatePackagingDimension(
  packagingDimension: PackagingDimension,
  supplierId: BSON.ObjectId | string
): Promise<boolean> {
  const timelineEntry = getSupplierTimelineEntry(T_S_UPDATEPACKAGINGDIMENSIONS);
  const actions: Array<Action> = [
    {
      collection: SUPPLIER,
      filter: { _id: new BSON.ObjectId(supplierId.toString()) },
      update: {
        "packagingDimensions.$[pd].description": packagingDimension.description,
        "packagingDimensions.$[pd].length": packagingDimension.length,
        "packagingDimensions.$[pd].width": packagingDimension.width,
        "packagingDimensions.$[pd].height": packagingDimension.height,
        "packagingDimensions.$[pd].cbm": packagingDimension.cbm,
        "packagingDimensions.$[pd].netWeight": packagingDimension.netWeight,
        "packagingDimensions.$[pd].grossWeight": packagingDimension.grossWeight,
        "packagingDimensions.$[pd].commodities": packagingDimension.commodities,
        "packagingDimensions.$[pd].finishedProducts": packagingDimension.finishedProducts,
        "packagingDimensions.$[pd].transportTypes": packagingDimension.transportTypes,
      },
      push: { timeline: timelineEntry },
      arrayFilters: [{ "pd._id": packagingDimension._id }],
    },
  ];
  return transaction(actions);
}

/**
 * Build the standard palette object and return it.
 * @param _id Optional, if set the given ID is used
 * @returns { PackagingDimension } Standard palette
 */
export function getStandardPackagingDimension(_id?: BSON.ObjectId): PackagingDimension {
  return {
    _id: _id ?? new BSON.ObjectId(),
    length: DEFAULT_PALETTE.length,
    width: DEFAULT_PALETTE.width,
    height: DEFAULT_PALETTE.height,
    cbm: DEFAULT_PALETTE.cbm,
    netWeight: DEFAULT_PALETTE.netWeight,
    grossWeight: DEFAULT_PALETTE.grossWeight,
    commodities: "all",
    description: "",
    transportTypes: [],
  };
}

/**
 * Check if a commodity can be selected or not
 * @param article a commodity or finished product document
 * @param packagingDimension the packaging dimension the commodity or finished product should be added to
 * @param articlesUsageMap a map with commodities or finished products and the transport types they are already selected for
 * @returns {string | boolean} true if the commodity or finished product cannot be selected for the packging dimension due to a conflict
 */
export function isSelectionDisabled(
  article: Commodity | FinishedProduct,
  packagingDimension: PackagingDimension,
  articlesUsageMap: { [id: string]: Array<string> }
): string | boolean {
  const usage = articlesUsageMap[article._id.toString()];
  if (!usage) return false;
  if (usage.some((t) => packagingDimension.transportTypes?.includes(t)))
    return isFinishedProduct(article)
      ? `Finished product already selected for ${Array.from(new Set(usage)).join(", ")}`
      : `Commodity already selected for ${Array.from(new Set(usage)).join(", ")}`;
  return false;
}

/**
 * Generates a list of select options that contains all valid palettes for the given commodity and transport. The
 * listing will contain no duplicates.
 * @param commodity Commodity whose palettes should be generated
 * @param transport Transport that should be checked
 * @param context Data context - needed for resolving suppliers
 * @returns {Array<SelectOption>} List of valid palettes as select option
 */
export function generateValidPalettesForCommodity(
  commodity: Commodity | undefined,
  transport: CO_TRANSPORT,
  context: DataContextInternalType
): Array<SelectOption<PackagingDimension>> {
  if (!commodity) return [];
  const suppliers = commodity.suppliers.map((s) => s.supplier);
  const palettes: Array<SelectOption<PackagingDimension>> = [];
  for (let i = 0; i < suppliers.length; i++) {
    const s = suppliers[i];
    const sup = getDocFromCollection(context.supplier, s);
    if (!sup) continue; // If supplier is not found we don't have the dimensions
    const validPD = getPaletteForCommodity(sup, commodity._id.toString(), transport);
    // Prevent listing standard palette
    if (_.isEqual(validPD, getStandardPackagingDimension(validPD._id))) continue;
    // Prevent listing palettes that are the same
    const omittedFields = ["_id", "commodities", "description", "transportTypes"];
    if (palettes.some((p) => _.isEqual(_.omit(p.object, omittedFields), _.omit(validPD, omittedFields)))) continue;
    palettes.push({ value: validPD._id.toString(), label: getPackagingDimensionString(validPD), object: validPD });
  }
  return palettes;
}
