import _ from "lodash";
import { BSON } from "realm-web";
import { callFunction } from "../services/dbService";
import {
  AdjustedCalculationEntry,
  CALC_AIRFREIGHT,
  CALC_EUSTOCK,
  CALC_LCLBASE,
  CALC_SEAFREIGHT,
  CalculationDetails,
  SO_ARCHIVED,
  SO_CANCELED,
  SO_HANDLEDATCUSTOMS,
  SO_HANDLEDATWAREHOUSE,
  SO_ORDERCONFIRMED,
  SO_REQUESTED,
  SO_SHIPPEDFROMSUPPLIER,
  SO_SHIPPEDTOWAREHOUSE,
  SO_STATES,
  SO_T_BOLUPLOADED,
  SO_T_CUSTOMSINVOICEUPLOADED,
  SO_T_PACKLISTUPLOADED,
  SO_TIMELINETYPE,
  SupplierOrder,
  SupplierOrderExtended,
  SupplierOrderShipment,
  SupplierOrderTimelineEntry,
  SupplierOrderTimelineEntryPayload,
} from "../model/supplierOrder.types";
import { SelectOption } from "../components/common/CustomSelect";
import { Address, OrderFile } from "../model/commonTypes";
import { ContractTimelineEntry } from "../model/customerContract.types";
import { CustomerOrderTimelineEntry, T_AIRFREIGHT, T_EUSTOCK, T_SEAFREIGHT } from "../model/customerOrder.types";
import userService from "../services/userService";
import { SupplierSupplierOrder } from "../model/supplier/supplierSupplierOrder.types";
import { Supplier } from "../model/supplier.types";
import { Price } from "../model/commodity.types";
import {
  AirFreightCalculationValues,
  AirFreightPriceCalculation,
  EUStockCalculationValues,
  EUStockPriceCalculation,
  LCLSeaFreightCalculationValues,
  LCLSeaFreightPriceCalculation,
  SeaFreightCalculationValues,
  SeaFreightPriceCalculation,
} from "./priceCalculationUtils";
import { Airport } from "../model/airport.types";
import { Seaport } from "../model/seaport.types";
import { getAirportName, isAirport } from "./airportUtils";
import { getSeaportName, isSeaport } from "./seaportUtils";
import { getCountryNameForCode } from "./baseUtils";
import { formatAddress } from "./addressUtils";
import { DataContextInternal } from "../context/dataContext";
import { Batch } from "../model/batch.types";
import { I_PAYMENTTARGETS } from "./invoiceUtils";
import { CO_DELIVERYTERMS } from "./customerOrderUtils";
import { Notify } from "../model/notify.types";
import { Incoterm } from "./commodityUtils";
import { SUPPLIER_ORDER_TYPES } from "./orderUtils";

export interface ExtendedSupplierOrderTimelineEntry extends SupplierOrderTimelineEntry {
  text: string | JSX.Element;
  order: SupplierOrder;
}

const UPSERTSUPPLIERORDER = "upsertSupplierOrder";
const UPDATESUPPLIERORDERSANDSWITCHCUSTOMERSTATES = "updateSupplierOrdersAndSwitchCustomerStates";
const UPDATESUPPLIERORDERSHIPMENT = "updateSupplierOrderShipment";
const CREATESUPPLIERORDER = "createSupplierOrder";

// file types
export const SO_SPECIFICATION = "specification";
export const SO_ORDERDOCUMENT = "order";
export const SO_ORDERCONFIRMATION = "orderConfirmation";
export const SO_BOL = "bol";
export const SO_COA = "coa";
export const SO_INVOICE = "invoice";
export const SO_SHIPPINGMARK = "shippingMark";
export const SO_CUSTOMSCLEARANCE = "customsClearanceInstruction";
export const SO_PACKAGINGLIST = "packagingList";
export const SO_CUSTOMSINVOICE = "customsInvoice";
export const SO_FORWARDINGORDER = "forwardingOrder";
export const SO_STORAGEORDER = "storageOrder";
export const SO_OTHER = "other";
export const SO_ADJUSTED_CALCULATION = "adjustedCalculationDocument";

export const SO_FILETYPES: Array<SelectOption> = [
  { value: SO_SPECIFICATION, label: "Specification" },
  { value: SO_ORDERDOCUMENT, label: "Order" },
  { value: SO_ORDERCONFIRMATION, label: "Order Confirmation" },
  { value: SO_BOL, label: "BOL" },
  { value: SO_COA, label: "CoA" },
  { value: SO_INVOICE, label: "Invoice" },
  { value: SO_PACKAGINGLIST, label: "Packaging List" },
  { value: SO_CUSTOMSINVOICE, label: "Customs Invoice" },
  { value: SO_SHIPPINGMARK, label: "Shipping Mark" },
  { value: SO_CUSTOMSCLEARANCE, label: "Customs Clearance Instruction" },
  { value: SO_FORWARDINGORDER, label: "Forwarding Order" },
  { value: SO_STORAGEORDER, label: "Storage Order" },
  { value: SO_ADJUSTED_CALCULATION, label: "Calculation Adjustment" },
  { value: SO_OTHER, label: "Other" },
];

export const SO_DELIVERYTERMS: Array<SelectOption> = Object.values(Incoterm).map((i) => {
  return { value: i, label: i };
});

export interface SelectedSupplier {
  supplier: Supplier;
  price: Price;
  bestPrice?: boolean;
  betterPrice: { supplier: Supplier; price: Price } | null;
}

export interface SupplierTermOptions {
  paymentTerm: SelectOption;
  customPaymentTerm: string;
  customPaymentTermCondition: string;
  notify: Notify;
  deliveryTerm: SelectOption;
}

export enum ADJUSTABLE_CALCULATION_VALUES {
  TRANSPORT = "transport",
  INSURANCE = "insurance",
  CUSTOMS = "customs",
  WAREHOUSE = "warehouse",
  FOLLOWUP = "followup",
}

/**
 * Inserts a new supplier order into the database.
 * @param supplierOrder supplier order that should be inserted into the database
 * @returns {Promise<{res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; orderNo: string}| false>} Result of the function
 */
export async function insertSupplierOrder(
  supplierOrder: SupplierOrder
): Promise<{ res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; orderNo: string } | false> {
  return (await callUpsertSupplierOrder(supplierOrder, true)) as
    | { res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; orderNo: string }
    | false;
}
/**
 * Updates an existing supplier order inside the database.
 * @param supplierOrder supplier order that should be updated inside the database
 * @param supplierOrderId Optional id of supplier order if it is not contained in supplier object
 * @param timelineEntry Optional timeline entry
 * @returns {Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false>} Result of the function
 */
export async function updateSupplierOrder(
  supplierOrder: Partial<SupplierOrder>,
  supplierOrderId?: BSON.ObjectId,
  timelineEntry?: SupplierOrderTimelineEntry
): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> {
  if (supplierOrderId) supplierOrder._id = supplierOrderId;
  return (await callUpsertSupplierOrder(supplierOrder, false, timelineEntry)) as
    | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>
    | false;
}

/**
 * Updates a shipment of a supplier order.
 * @param supplierOrder Supplier order whose shipment should be updated
 * @param shipmentId ID of the shipment
 * @param supplierOrderId Optional, ID of supplier order if it is not contained in supplier object
 * @param shipment Optional, update of the shipment
 * @param supplierOrderTimeline Optional, timeline entry that should be added to the order
 * @param shipmentTimeline Optional, timeline entry that should be added to the shipment
 * @returns { Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> } Result of the function call
 */
export async function updateSupplierOrderShipment(
  supplierOrder: Partial<SupplierOrder>,
  shipmentId: BSON.ObjectId | string,
  supplierOrderId?: BSON.ObjectId,
  shipment?: Partial<SupplierOrderShipment>,
  supplierOrderTimeline?: SupplierOrderTimelineEntry,
  shipmentTimeline?: SupplierOrderTimelineEntry
): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> {
  if (supplierOrderId) supplierOrder._id = supplierOrderId;
  return await callFunction<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false>(UPDATESUPPLIERORDERSHIPMENT, [
    supplierOrder,
    shipmentId,
    shipment,
    supplierOrderTimeline,
    shipmentTimeline,
  ]);
}

/**
 * Ships the given supplier order and updates all related customer orders.
 * @param supplierOrder Partial update of the supplier order
 * @param customerOrderIds IDs of all customer orders that need to be updated
 * @param supplierOrderTimeline Optional supplier order timeline entry
 * @param customerOrderTimeline Optional customer order timeline entry
 * @returns { Promise<boolean> } Indicating the success or failure of the function
 */
export async function shipSupplierOrder(
  supplierOrder: Partial<SupplierOrder>,
  customerOrderIds: Array<BSON.ObjectId | string>,
  supplierOrderTimeline?: SupplierOrderTimelineEntry,
  customerOrderTimeline?: CustomerOrderTimelineEntry
): Promise<boolean> {
  return updateSupplierOrderAndSwitchCustomerStates(
    supplierOrder,
    customerOrderIds,
    [],
    SO_SHIPPEDFROMSUPPLIER,
    "",
    supplierOrderTimeline,
    customerOrderTimeline
  );
}

/**
 * Updates the given supplier order and updates the state of the given customer orders.
 * @param supplierOrder Partial update of the supplier order
 * @param customerOrderIds IDs of all customer orders that need to be updated
 * @param customerContractIds IDs of all customer contracts that need to be updated
 * @param customerOrdersState State the customer orders should have after the update
 * @param customerContractState State the customer contracts should have after the update
 * @param supplierOrderTimeline Optional supplier order timeline entry
 * @param customerOrderTimeline Optional customer order timeline entry
 * @param customerContractTimeline Optional customer contract timeline entry
 * @returns { Promise<boolean> } Indicating the success or failure of the function
 */
export async function updateSupplierOrderAndSwitchCustomerStates(
  supplierOrder: Partial<SupplierOrder>,
  customerOrderIds: Array<BSON.ObjectId | string>,
  customerContractIds: Array<BSON.ObjectId | string>,
  customerOrdersState: string,
  customerContractState: string,
  supplierOrderTimeline?: SupplierOrderTimelineEntry,
  customerOrderTimeline?: CustomerOrderTimelineEntry,
  customerContractTimeline?: ContractTimelineEntry
): Promise<boolean> {
  return await callFunction<boolean>(UPDATESUPPLIERORDERSANDSWITCHCUSTOMERSTATES, [
    supplierOrder,
    customerOrderIds,
    customerContractIds,
    customerOrdersState,
    customerContractState,
    supplierOrderTimeline,
    customerOrderTimeline,
    customerContractTimeline,
  ]);
}

/**
 * Calls the upsert supplier order function in backend.
 * @param supplierOrder supplier order that should be upsert
 * @param insert True for insert, else update
 * @param timelineEntry Optional timeline entry
 * @returns {Promise<false | {res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; orderNo: string} | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>>} Result of the function
 */
async function callUpsertSupplierOrder(
  supplierOrder: Partial<SupplierOrder>,
  insert: boolean,
  timelineEntry?: SupplierOrderTimelineEntry
): Promise<
  | false
  | { res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; orderNo: string }
  | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>
> {
  return callFunction(UPSERTSUPPLIERORDER, [supplierOrder, insert, timelineEntry]);
}

/**
 * Calls the create supplier order function in backend.
 * @param supplierOrder supplier order that should be created
 * @param cOTimelineEntry timeline entry for customer orders
 * @param cCTimelineEntry timeline entry for customer contracts
 * @returns { Promise<false | {res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; orderNo: string}> } Result of the function
 */
export async function createSupplierOrder(
  supplierOrder: SupplierOrder,
  cOTimelineEntry: CustomerOrderTimelineEntry,
  cCTimelineEntry: ContractTimelineEntry
): Promise<false | { res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; orderNo: string }> {
  return await callFunction<false | { res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; orderNo: string }>(
    CREATESUPPLIERORDER,
    [supplierOrder, cOTimelineEntry, cCTimelineEntry]
  );
}

/**
 * Get a file object for supplier order files
 * @param path path to the file
 * @param type type of the file
 * @returns {OrderFile} order file
 */
export const getSupplierOrderFile = (path: string, type: typeof SO_ORDERDOCUMENT): OrderFile => {
  return {
    _id: new BSON.ObjectId(),
    date: new Date(),
    path,
    type,
  };
};

/**
 * Generates a timeline entry for a supplier order
 * @param type Type of the entry
 * @param payload Optional payload of the entry
 * @returns {SupplierOrderTimelineEntry} Timeline entry object
 */
export function getSupplierOrderTimelineEntry(
  type: SO_TIMELINETYPE,
  payload?: SupplierOrderTimelineEntryPayload
): SupplierOrderTimelineEntry {
  return {
    _id: new BSON.ObjectId(),
    date: new Date(),
    type: type,
    person: userService.getUserId(),
    payload: payload ?? null,
  } as SupplierOrderTimelineEntry;
}

/**
 * Checks all relevant files for existence and returns them.
 * @param order supplier order
 * @param shipment Shipment that is checked for files
 * @returns {{ bol?: OrderFile, coa?: OrderFile, invoice?: OrderFile, packagingList?: OrderFile, customsInvoice?: OrderFile }} Relevant files
 */
export const getShipmentFiles = (
  order: SupplierOrder | SupplierOrderExtended,
  shipment: SupplierOrderShipment
): { bol?: OrderFile; coa?: OrderFile; invoice?: OrderFile; packagingList?: OrderFile; customsInvoice?: OrderFile } => {
  let bol, packagingList, customsInvoice;
  for (let i = 0; i < shipment.timeline.length; i++) {
    const t = shipment.timeline[i];
    if (t.type === SO_T_BOLUPLOADED) bol = order.files.find((f) => f._id.toString() === t.payload?.reference);
    else if (t.type === SO_T_PACKLISTUPLOADED)
      packagingList = order.files.find((f) => f._id.toString() === t.payload?.reference);
    else if (t.type === SO_T_CUSTOMSINVOICEUPLOADED)
      customsInvoice = order.files.find((f) => f._id.toString() === t.payload?.reference);
  }
  return { bol, packagingList, customsInvoice };
};

/**
 * Get the numeric representation of the state of the referenced order
 * @param order Order whose state ranking should be resolved
 * @return {number} State ranking
 */
export const getOrderStateRanking = (order: SUPPLIER_ORDER_TYPES): number => {
  switch (order.state) {
    case SO_REQUESTED:
      return 0;
    case SO_ORDERCONFIRMED:
      return 1;
    case SO_SHIPPEDFROMSUPPLIER:
      return 2;
    case SO_HANDLEDATCUSTOMS:
      return 3;
    case SO_SHIPPEDTOWAREHOUSE:
      return 4;
    case SO_HANDLEDATWAREHOUSE:
      return 5;
    case SO_ARCHIVED:
      return 6;
    case SO_CANCELED:
      return 7;
    default:
      return -1;
  }
};

/**
 * Sort supplier orders
 * @param orders list of supplier orders
 * @returns {Array<SupplierOrder | SupplierSupplierOrder>} list of supplier orders sorted by state
 */
export const sortSupplierOrdersByState = (
  orders: Array<SupplierOrder | SupplierSupplierOrder>
): Array<SupplierOrder | SupplierSupplierOrder> => _.orderBy(orders, (o) => getOrderStateRanking(o), "asc");

/**
 * Get a supplier order calculation detail object from price calculation and calculation values
 * @param priceCalculation a price calculation object
 * @param calculationValues a calculation values object
 * @returns {CalculationDetails} object with all information about the price calculation
 */
export const getLCLSupplierOrderCalculationDetails = (
  priceCalculation: LCLSeaFreightPriceCalculation,
  calculationValues: LCLSeaFreightCalculationValues
): CalculationDetails => {
  const baseValues = { ..._.omit(calculationValues, "baseValues"), ...calculationValues.baseValues };
  return {
    type: CALC_LCLBASE,
    details: {
      finalValues: {
        totalCommodityPrice: priceCalculation.totalCommodityPrice,
        totalWarehouseCost: priceCalculation.totalWarehouseCost || 0,
        totalWarehouseHandlingCost: priceCalculation.warehouseHandlingCost,
        totalDockage: priceCalculation.dockage,
        totalB2bFollowUpCost: priceCalculation.b2bFollowUpCost,
        totalCustomsCost: priceCalculation.customsCost,
        totalSeaFreight: priceCalculation.seaFreight,
        totalLclCharges: priceCalculation.lclCharges,
        totalCost: priceCalculation.totalCost,
        totalPricePerUnit: priceCalculation.totalPricePerUnit,
      },
      baseValues,
    },
  };
};

/**
 * Get a supplier order calculation detail object from price calculation and calculation values
 * @param priceCalculation a price calculation object
 * @param calculationValues a calculation values object
 * @returns {CalculationDetails} object with all information about the price calculation
 */
export const getAirFreightSupplierOrderCalculationDetails = (
  priceCalculation: AirFreightPriceCalculation,
  calculationValues: AirFreightCalculationValues
): CalculationDetails => {
  // Minimum margin is irrelevant for supplier order
  const baseValues = {
    ..._.omit(calculationValues, ["baseValues", "minimumAbsoluteMargin"]),
    ...calculationValues.baseValues,
  };
  return {
    type: CALC_AIRFREIGHT,
    details: {
      finalValues: {
        totalCommodityPrice: priceCalculation.totalCommodityPrice,
        totalWarehouseCost: priceCalculation.totalWarehouseCost || 0,
        totalWarehouseHandlingCost: priceCalculation.warehouseHandlingCost,
        totalAirFreightCost: priceCalculation.airFreightCost,
        totalAirportCost: priceCalculation.airportCost,
        totalFollowUpCost: priceCalculation.followUpCost,
        totalB2bFollowUpCost: priceCalculation.b2bFollowUpCost,
        totalAdditionalFee: priceCalculation.additionalFee,
        totalDuty: priceCalculation.duty,
        totalCost: priceCalculation.totalCost,
        totalPricePerUnit: priceCalculation.totalPricePerUnit,
        totalInsuranceCost: priceCalculation.totalInsuranceCost || 0,
      },
      baseValues,
    },
  };
};

/**
 * Get a supplier order calculation detail object from price calculation and calculation values
 * @param priceCalculation a price calculation object
 * @param calculationValues a calculation values object
 * @returns {CalculationDetails} object with all information about the price calculation
 */
export const getSeaFreightSupplierOrderCalculationDetails = (
  priceCalculation: SeaFreightPriceCalculation,
  calculationValues: SeaFreightCalculationValues
): CalculationDetails => {
  // Minimum margin is irrelevant for supplier order
  const baseValues = {
    ..._.omit(calculationValues, ["baseValues", "minimumAbsoluteMargin"]),
    ...calculationValues.baseValues,
  };
  return {
    type: CALC_SEAFREIGHT,
    details: {
      finalValues: {
        totalCommodityPrice: priceCalculation.totalCommodityPrice,
        totalWarehouseCost: priceCalculation.totalWarehouseCost || 0,
        totalWarehouseHandlingCost: priceCalculation.warehouseHandlingCost,
        totalLCLCost: priceCalculation.lclCost,
        totalFCLCost: priceCalculation.fclCost,
        totalB2bFollowUpCost: priceCalculation.b2bFollowUpCost,
        totalCustomsCost: priceCalculation.customsCost,
        totalCost: priceCalculation.totalCost,
        totalPricePerUnit: priceCalculation.totalPricePerUnit,
        totalInsuranceCost: priceCalculation.totalInsuranceCost || 0,
      },
      baseValues,
    },
  };
};

/**
 * Get a supplier order calculation detail object from price calculation and calculation values
 * @param priceCalculation a price calculation object
 * @param calculationValues a calculation values object
 * @returns {CalculationDetails} object with all information about the price calculation
 */
export const getEUStockSupplierOrderCalculationDetails = (
  priceCalculation: EUStockPriceCalculation,
  calculationValues: EUStockCalculationValues
): CalculationDetails => {
  const baseValues = { ..._.omit(calculationValues, "baseValues"), ...calculationValues.baseValues };
  return {
    type: CALC_EUSTOCK,
    details: {
      finalValues: {
        totalCommodityPrice: priceCalculation.totalCommodityPrice,
        totalWarehouseCost: priceCalculation.totalWarehouseCost || 0,
        totalWarehouseHandlingCost: priceCalculation.warehouseHandlingCost || 0,
        totalFollowUpCost: priceCalculation.followUpCost,
        totalB2bFollowUpCost: priceCalculation.b2bFollowUpCost,
        totalCost: priceCalculation.totalCost,
        totalPricePerUnit: priceCalculation.totalPricePerUnit,
      },
      baseValues,
    },
  };
};

/**
 * Get the name for an airport, seaport or a formatted address
 * @param portOrAddress the air or seaport or address
 * @param withCountry Optional, if set the country is also returned
 * @returns {string} name of the port
 */
export function getPortNameOrAddress(
  portOrAddress: Airport | Seaport | Address | string,
  withCountry?: boolean
): string {
  if (typeof portOrAddress === "string") return portOrAddress;
  const country = withCountry ? getCountryNameForCode(portOrAddress.country) : undefined;
  if (isAirport(portOrAddress)) return getAirportName(portOrAddress) + (country ? `, ${country}` : "");
  else if (isSeaport(portOrAddress)) return getSeaportName(portOrAddress) + (country ? `, ${country}` : "");
  else if (!(isAirport(portOrAddress) || isSeaport(portOrAddress)))
    return formatAddress(portOrAddress) + (withCountry ? `, ${portOrAddress.country}` : "");
  return "Various";
}

/**
 * Get the code (iata+icao or locode) for an airport or seaport
 * @param port the air or seaport, for address give empty string
 * @returns {string} code of the port
 */
export function getPortCode(port: Airport | Seaport | Address | string): string {
  if (typeof port === "string") return port;
  else if (isAirport(port)) return `${port.iata}-${port.icao}`;
  else if (isSeaport(port)) return port.locode;
  else if (!(isAirport(port) || isSeaport(port))) return "";
  return "Various";
}

/**
 * Collect all batches related to the supplier order.
 * @param order the supplier order to check for batches
 * @param context data context with batches
 * @returns { Array<Batch> } List of all related batches
 */
export function getRelatedBatches(
  order: SupplierOrder | undefined,
  context: React.ContextType<typeof DataContextInternal>
): Batch[] {
  return context.batch.filter((b) => order && b.supplierOrder === order._id.toString());
}

/**
 * Gives terms for a given order
 * @param order SupplierOrder, the given order
 * @param notifies all given notifies
 * @returns {SupplierTermOptions} for an order or contract
 */
export function getSupplierTermOptionFromSupplierOrder(
  order: SupplierOrderExtended,
  notifies: Array<Notify>
): SupplierTermOptions {
  let paymentTerm = I_PAYMENTTARGETS[4];
  let customPaymentTerm = "";
  let customPaymentTermCondition = "";
  let deliveryTerm = CO_DELIVERYTERMS[0];
  let notify = notifies[0];
  const supplier = order.supplier;
  // try to preload data from order if given
  if (order.terms) {
    const isCustom = !I_PAYMENTTARGETS.some((pt) => pt.label === order.terms?.paymentTerms);
    if (isCustom) {
      paymentTerm = { value: "custom", label: "Custom" };
      customPaymentTerm = order.terms.paymentTerms.replace(" days", "").trim();
    } else {
      paymentTerm = I_PAYMENTTARGETS.find((pt) => pt.label === order.terms?.paymentTerms) || I_PAYMENTTARGETS[4];
    }
    customPaymentTermCondition = order.terms.paymentTermConditions || "";
    deliveryTerm = { value: order.terms.deliveryTerms, label: order.terms.deliveryTerms };
    notify = notifies.find((n) => n._id.toString() === order.terms?.notify) || notifies[0];
  } else if (supplier?.paymentTerms) {
    // try to preload data from customer paymentTerms if given
    return getSupplierTermOptionFromSupplier(supplier, notifies);
  }
  return {
    paymentTerm: paymentTerm,
    customPaymentTerm: customPaymentTerm,
    customPaymentTermCondition: customPaymentTermCondition,
    notify: notify,
    deliveryTerm: deliveryTerm,
  };
}

/**
 * Gives terms for a given order by using the company
 * @param supplier Company, the given customer
 * @param notifies all given notifies
 * @returns {SupplierTermOptions} for an order or contract
 */
export function getSupplierTermOptionFromSupplier(supplier: Supplier, notifies: Array<Notify>): SupplierTermOptions {
  let paymentTerm = I_PAYMENTTARGETS[4];
  let customPaymentTerm = "";
  const customPaymentTermCondition = supplier.paymentTerms?.paymentTargetConditions || "";
  const deliveryTerm = CO_DELIVERYTERMS[0];
  const notify = notifies[0];
  if (supplier.paymentTerms) {
    // try to preload data from customer paymentTerms if given
    const isCustom = !I_PAYMENTTARGETS.some(
      (pt) => supplier.paymentTerms && pt.label === supplier.paymentTerms?.paymentTarget
    );
    if (isCustom) {
      paymentTerm = { value: "custom", label: "Custom" };
      customPaymentTerm = supplier.paymentTerms.paymentTarget.replace(" days", "").trim();
    } else {
      paymentTerm =
        I_PAYMENTTARGETS.find((pt) => pt.label === supplier.paymentTerms?.paymentTarget) || I_PAYMENTTARGETS[4];
    }
  }
  return {
    paymentTerm: paymentTerm,
    customPaymentTerm: customPaymentTerm,
    customPaymentTermCondition: customPaymentTermCondition,
    notify: notify,
    deliveryTerm: deliveryTerm,
  };
}

/**
 * Get the starting point of the supplier order from either shipment if given or purchase information
 * @param order the supplier order
 * @returns {string} the starting point of the order as string
 */
export function getSupplierOrderStartString(order: SupplierOrder | SupplierOrderExtended | undefined): string {
  const shipping = order && order.shipment.length > 0 ? order?.shipment[0].shipping : undefined;
  let start = "";
  if (order && shipping && shipping.startingPoint) {
    if (
      (isAirport(shipping.startingPoint) || isSeaport(shipping.startingPoint)) &&
      (order.transport === T_AIRFREIGHT || order.transport === T_SEAFREIGHT)
    ) {
      start = shipping.startingPoint.name;
    } else if (typeof shipping?.startingPoint === "string") {
      start = shipping.startingPoint;
    } else if (!isAirport(shipping.startingPoint) && !isSeaport(shipping.startingPoint)) {
      const address = shipping.startingPoint;
      start = address.city;
    }
  } else if (order && order.purchaseOrderInformation) {
    if (order.purchaseOrderInformation.startingAirport && order.transport === T_AIRFREIGHT) {
      start = order.purchaseOrderInformation.startingAirport.name;
    } else if (order.purchaseOrderInformation.startingSeaport && order.transport === T_SEAFREIGHT) {
      start = order.purchaseOrderInformation.startingSeaport.name;
    } else if (order.purchaseOrderInformation.startingEUWarehouse && order.transport === T_EUSTOCK) {
      const address = order.purchaseOrderInformation.startingEUWarehouse;
      start = address.city;
    }
  }
  return start;
}

/**
 * Determines whether the order is active or not.
 * @param order Order that should be checked
 * @returns {boolean} True if order is active, false if not
 */
export function isActive(order: SUPPLIER_ORDER_TYPES): boolean {
  return !([SO_ARCHIVED, SO_CANCELED] as Array<SO_STATES>).includes(order.state);
}

/**
 * Retrieve the description value for an adjustable calculation field
 * @param field Field whose description is requested
 * @returns {string} Description
 */
export function getAdjustableFieldDescription(field: ADJUSTABLE_CALCULATION_VALUES): string {
  switch (field) {
    case ADJUSTABLE_CALCULATION_VALUES.TRANSPORT:
      return "Transport";
    case ADJUSTABLE_CALCULATION_VALUES.INSURANCE:
      return "Insurance";
    case ADJUSTABLE_CALCULATION_VALUES.CUSTOMS:
      return "Customs";
    case ADJUSTABLE_CALCULATION_VALUES.WAREHOUSE:
      return "Warehouse";
    case ADJUSTABLE_CALCULATION_VALUES.FOLLOWUP:
      return "Follow Up";
  }
}

/**
 * Retrieve the value of an adjustable field if existing.
 * @param order SupplierOrder whose value should be retrieved
 * @param field Field that should be retrieved
 * @returns {Array<AdjustedCalculationEntry> | undefined}
 */
export function getValueForAdjustableField(
  order: SupplierOrder | SupplierOrderExtended,
  field: ADJUSTABLE_CALCULATION_VALUES
): Array<AdjustedCalculationEntry> | undefined {
  switch (field) {
    case ADJUSTABLE_CALCULATION_VALUES.TRANSPORT:
      return order.calculationDetails?.adjustedCalculation?.totalTransportationCost;
    case ADJUSTABLE_CALCULATION_VALUES.INSURANCE:
      return order.calculationDetails?.adjustedCalculation?.totalInsuranceCost;
    case ADJUSTABLE_CALCULATION_VALUES.CUSTOMS:
      return order.calculationDetails?.adjustedCalculation?.totalCustomsCost;
    case ADJUSTABLE_CALCULATION_VALUES.WAREHOUSE:
      return order.calculationDetails?.adjustedCalculation?.totalWarehouseCost;
    case ADJUSTABLE_CALCULATION_VALUES.FOLLOWUP:
      return order.calculationDetails?.adjustedCalculation?.totalFollowUpCost;
  }
}

/**
 * Get the system estimated value for an adjustable calculation field.
 * @param order SupplierOrder whose system estimated value should be received
 * @param field Field that is requested
 * @returns {number}
 */
export function getSystemEstimateForAdjustableField(
  order: SupplierOrder | SupplierOrderExtended,
  field: ADJUSTABLE_CALCULATION_VALUES
) {
  switch (field) {
    case ADJUSTABLE_CALCULATION_VALUES.TRANSPORT:
      return order.priceTransport;
    case ADJUSTABLE_CALCULATION_VALUES.INSURANCE:
      return order.calculationDetails && "totalInsuranceCost" in order.calculationDetails.details.finalValues
        ? order.calculationDetails.details.finalValues.totalInsuranceCost || 0
        : 0;
    case ADJUSTABLE_CALCULATION_VALUES.CUSTOMS:
      return order.priceCustoms;
    case ADJUSTABLE_CALCULATION_VALUES.WAREHOUSE:
      return order.calculationDetails?.details.finalValues.totalWarehouseCost || 0;
    case ADJUSTABLE_CALCULATION_VALUES.FOLLOWUP:
      return order.calculationDetails?.details.finalValues.totalB2bFollowUpCost || 0;
  }
}
