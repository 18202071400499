import React from "react";
import { getCountryNameForCode, toAbsoluteUrl } from "../../utils/baseUtils";

interface CountryWidgetProps {
  countryCode: string;
  wrapperClasses?: string;
  iconClasses?: string;
}

const CountryWidget: React.FunctionComponent<CountryWidgetProps> = ({ countryCode, wrapperClasses, iconClasses }) => {
  const countryName = getCountryNameForCode(countryCode) || undefined;
  return (
    <div className={wrapperClasses}>
      <img
        src={toAbsoluteUrl(`/assets/media/icons/countries/${countryCode ? countryCode.toLowerCase() : "various"}.png`)}
        className={iconClasses ?? "country-icon mr-2 mb-1"}
        alt={countryName}
      />
      {countryName ?? "Various"}
    </div>
  );
};

export default CountryWidget;
