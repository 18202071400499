import React, { PureComponent } from "react";
import OrderHistoryEntry from "./OrderHistoryEntry";
import Search from "../../common/Search";
import { doFuseSearch } from "../../../utils/baseUtils";
import {
  DataContextAnonymousType,
  DataContextCustomerType,
  DataContextInternalType,
} from "../../../context/dataContext";
import { OrderTimeline } from "../../../model/commonTypes";
import { EXTENDED_ORDER_TYPES } from "../../../utils/orderUtils";
import { EXTENDED_CONTRACT_TYPES } from "../../../utils/contractUtils";

interface OrderHistoryProps {
  order: EXTENDED_ORDER_TYPES | EXTENDED_CONTRACT_TYPES;
  context: DataContextInternalType | DataContextCustomerType | DataContextAnonymousType;
}

interface OrderHistoryState {
  search: string;
}

class OrderHistory extends PureComponent<OrderHistoryProps, OrderHistoryState> {
  constructor(props: OrderHistoryProps) {
    super(props);
    this.state = { search: "" };
  }

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ search: e.target.value });
  };

  /**
   * Filters the timeline by the given search query.
   * @returns { Array<{
   *       order: EXTENDED_ORDER_TYPES | EXTENDED_CONTRACT_TYPES;
   *       timelineEntry: OrderTimeline;
   *       recent: boolean;
   *     }> } Filtered timeline entries
   */
  filterTimeline = (): Array<{
    order: EXTENDED_ORDER_TYPES | EXTENDED_CONTRACT_TYPES;
    timelineEntry: OrderTimeline;
    recent: boolean;
  }> => {
    const { order } = this.props;
    const { search } = this.state;
    let filteredTimeline = order.timeline as Array<OrderTimeline>;
    if (search.trim()) filteredTimeline = doFuseSearch(order.timeline as Array<OrderTimeline>, search, ["type"]);
    const timeline: Array<{
      order: EXTENDED_ORDER_TYPES | EXTENDED_CONTRACT_TYPES;
      timelineEntry: OrderTimeline;
      recent: boolean;
    }> = filteredTimeline.map((t) => {
      return {
        order,
        timelineEntry: t,
        recent: false,
      };
    });
    return timeline.slice().reverse();
  };

  render() {
    const { context } = this.props;
    const { search } = this.state;
    const timeline = this.filterTimeline();

    return (
      <div className="card bg-white">
        <div className="card-header border-0 mt-5">
          <div className="card-title flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">History</span>
            <span className="text-muted fw-bold fs-7">{timeline.length} Entries</span>
          </div>
          <div className="card-toolbar">
            <Search onSearch={this.handleSearch} value={search} />
          </div>
        </div>
        <div className="card-body" style={{ overflowY: "auto", maxHeight: "70vh" }}>
          {timeline.length > 0 ? (
            timeline.map((t) => <OrderHistoryEntry key={t.timelineEntry._id.toString()} entry={t} context={context} />)
          ) : (
            <div className="timeline-content text-muted m-0 p-0 mb-2 text-center">No history available</div>
          )}
        </div>
      </div>
    );
  }
}

export default OrderHistory;
