import React, { useContext } from "react";
import { DataContextInternal } from "../../../context/dataContext";
import OrdersListing from "./OrdersListing";

const OrdersWrapper: React.FunctionComponent = () => {
  const context = useContext(DataContextInternal);
  return <OrdersListing context={context} />;
};

export default OrdersWrapper;
