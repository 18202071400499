import React, { useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";

interface PriceAdjustmentProps {
  show?: boolean;
  onAdjust: (value: string) => void;
  onClose: () => void;
}

const ADJUSTMENT_SHORTCUTS = ["-20", "-10", "-5", "5", "10", "20"];

export const PriceAdjustmentModal: React.FunctionComponent<PriceAdjustmentProps> = ({ show, onAdjust, onClose }) => {
  const [value, setValue] = useState("0");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(value);
  };
  const handleAdjustmentShortcut = (value: string) => setValue(value);

  return (
    <>
      <Modal contentClassName="bg-dark" show={show !== undefined ? show : true} centered>
        <Modal.Header className="border-0 pb-0">
          <Modal.Title>
            <h1 className="fw-bolder d-flex align-items-center text-white">Adjust All Prices</h1>
          </Modal.Title>
          <CloseButton variant={"white"} onClick={onClose} />
        </Modal.Header>
        <Modal.Body>
          <div className="row mt-6">
            <div className="col-3 my-auto">
              <label className="fs-5 fw-bold my-auto">Adjust by:</label>
            </div>
            <div className="col-9">
              <div className="input-group">
                <input
                  type={"number"}
                  className="form-control custom-form-control"
                  value={value}
                  onChange={handleChange}
                  name={"adjustmentValue"}
                />
                <div className="input-group-append rounded-end bg-custom-light-gray">
                  <div className="input-group-text form-control custom-form-control">%</div>
                </div>
              </div>
            </div>
            <div className="col-3"></div>
            <div className="col-9 mt-2">
              {ADJUSTMENT_SHORTCUTS.map((as) => (
                <button
                  key={as.toString()}
                  className={"btn btn-sm py-1 px-2 mr-2 " + (parseFloat(as) > 0 ? "btn-success" : "btn-danger")}
                  onClick={() => handleAdjustmentShortcut(as)}
                >
                  {as}%
                </button>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className={"btn btn-sm btn-text-light"} onClick={onClose}>
            Close
          </button>
          <button
            className={"btn btn-sm btn-outline btn-outline-light"}
            onClick={() => {
              onAdjust(value);
              onClose();
            }}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
