import { BSON } from "realm-web";
import { callFunction } from "../services/dbService";
import { ActiveSubstance } from "../model/activeSubstance.types";

// Backend functions relating to property
export const UPSERTACTIVESUBSTANCE = "upsertActiveSubstance";

/**
 * Inserts a new active substance into the database.
 * @param activeSubstance Active substance that should be inserted into the database
 * @returns {Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>>} Result of the function
 */
export async function insertActiveSubstance(
  activeSubstance: ActiveSubstance
): Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>> {
  return (await callUpsertActiveSubstance(
    activeSubstance,
    true
  )) as Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>;
}

/**
 * Updates an existing active substance inside the database.
 * @param activeSubstance Active substance that should be updated inside the database
 * @returns {Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false>} Result of the function
 */
export async function updateActiveSubstance(
  activeSubstance: ActiveSubstance
): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> {
  return (await callUpsertActiveSubstance(
    activeSubstance,
    false
  )) as Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>;
}

/**
 * Calls the upsert active substance function in backend.
 * @param activeSubstance Active substance that should be upsert
 * @param insert True for insert, else update
 * @returns {Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false>} Result of the function
 */
async function callUpsertActiveSubstance(
  activeSubstance: ActiveSubstance,
  insert: boolean
): Promise<
  Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false
> {
  return callFunction(UPSERTACTIVESUBSTANCE, [activeSubstance, insert]);
}
