import { BSON } from "realm-web";
import { toast } from "react-toastify";
import React, { PureComponent } from "react";
import BaseListing, { BaseListingHeaderDefinition } from "../common/BaseListing";
import { PaginationState } from "../common/Pagination";
import { SystemNotification } from "../../model/systemNotification.types";
import { formatDate } from "../../utils/baseUtils";
import { deleteSystemNotification, resolveConditionsText, resolveTypeText } from "../../utils/systemNotificationUtils";
import { Commodity } from "../../model/commodity.types";
import CreateSystemNotificationModal from "./modals/CreateSystemNotificationModal";
import SimpleConfirmationModal from "../common/SimpleConfirmationModal";

interface SystemNotificationConfigurationProps {
  systemNotifications: Array<SystemNotification>;
  commodities: Array<Commodity>;
}

interface SystemNotificationConfigurationState extends PaginationState {
  saving: boolean;
}

class SystemNotificationConfiguration extends PureComponent<
  SystemNotificationConfigurationProps,
  SystemNotificationConfigurationState
> {
  headerDefinition: Array<BaseListingHeaderDefinition> = [
    { title: "Message", style: { width: "35%" } },
    { title: "Type", style: { width: "15%" } },
    { title: "Valid Until", style: { width: "15%" } },
    { title: "Condition", style: { width: "20%" } },
    { title: "", style: { width: "15%" }, className: "text-right" },
  ];

  constructor(props: SystemNotificationConfigurationProps) {
    super(props);
    this.state = { currentPage: 1, pageSize: 25, saving: false };
  }

  handleChangeCurrentPage = (currentPage: number) => this.setState({ currentPage });
  handleChangePageSize = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });

  handleDeleteSystemNotification = async (_id: BSON.ObjectId) => {
    this.setState({ saving: true });
    try {
      const res = await deleteSystemNotification(_id);
      if (res && res.deletedCount > 0) {
        toast.success("System Notification successfully deleted");
      } else {
        toast.error("Error deleting System Notification");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  render() {
    const { systemNotifications, commodities } = this.props;
    const { currentPage, pageSize, saving } = this.state;

    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="card card-xl-stretch bg-white">
              <div className="card-body" style={{ minHeight: "600px" }}>
                <h3 className="card-title align-items-start flex-column mb-15">
                  <span className="card-label fw-bolder mb-3 fs-3rem">System Notification Configuration</span>
                </h3>
                <BaseListing
                  headerDefinition={this.headerDefinition}
                  bodyContent={
                    <>
                      {systemNotifications.map((sN) => (
                        <tr key={sN._id.toString()}>
                          <td className="text-white align-middle">{sN.message}</td>
                          <td className="text-white align-middle">{resolveTypeText(sN.type)}</td>
                          <td className="text-white align-middle">{formatDate(sN.validUntil)}</td>
                          <td className="text-white align-middle">
                            {resolveConditionsText(sN.condition, commodities).map((c, idx) => (
                              <div key={idx}>{c}</div>
                            ))}
                          </td>
                          <td className="text-white align-middle text-right">
                            <CreateSystemNotificationModal commodities={commodities} systemNotification={sN} />
                            <SimpleConfirmationModal.SimpleConfirmationModalButton
                              buttonText={<i className="fa fa-trash fs-7 text-muted text-hover-danger p-0" />}
                              buttonClasses="btn btn-sm btn-text-success float-right ml-2"
                              size="md"
                              modalTitle="Delete System Notification"
                              modalDescription={
                                <span className="text-white">
                                  Do you really want to delete this System Notification? This can't be undone.
                                </span>
                              }
                              confirmButtonText="Delete"
                              cancelButtonText="Cancel"
                              disabled={saving}
                              onConfirm={() => this.handleDeleteSystemNotification(sN._id)}
                            />
                          </td>
                        </tr>
                      ))}
                    </>
                  }
                  documents={systemNotifications}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  baseSize={25}
                  onPageChange={this.handleChangeCurrentPage}
                  onPageSizeChange={this.handleChangePageSize}
                />
              </div>
              <div className="card-footer text-right border-0">
                <CreateSystemNotificationModal commodities={commodities} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SystemNotificationConfiguration;
