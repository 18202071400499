import React, { PureComponent } from "react";
import { ArticleExtended } from "../../../utils/productArticleUtils";

interface CommodityActiveSubstanceListingProps {
  article: ArticleExtended;
}

class CommodityActiveSubstanceListing extends PureComponent<CommodityActiveSubstanceListingProps> {
  render() {
    const { article } = this.props;
    if (article.activeSubstances.length === 0)
      return <div className="text-center text-muted mb-5">No active substances for this commodity</div>;
    return (
      <table className="table text-white fw-bolder gy-1">
        <thead>
          <tr>
            <th className="border-0">Name</th>
            <th className="border-0">Percentage</th>
            <th className="border-0">NRV</th>
          </tr>
        </thead>
        <tbody>
          {article.activeSubstances.map((aS) => (
            <tr key={aS.substance._id.toString()}>
              <td className="text-white w-25 min-w-125px">{aS.substance.name.en}</td>
              <td className="text-muted w-25">{aS.percentage}%</td>
              <td className="text-muted w-25">{aS.substance.nrv} µg / day</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default CommodityActiveSubstanceListing;
