import { BSON } from "realm-web";
import { toast } from "react-toastify";
// @ts-ignore
import mime from "mime-types";
import userService from "../services/userService";
import { UploadedFile, UploadedFileExtended } from "../model/commodity.types";
import { Supplier, SupplierExtended, SupplierFile } from "../model/supplier.types";
import { SupplierSupplier } from "../model/supplier/supplierSupplier.types";
import { COMMODITY, FINISHEDPRODUCT } from "../services/dbService";
import { BatchFile } from "../model/batch.types";
import { OrderFile } from "../model/commonTypes";
import { Company } from "../model/company.types";
import { UploadedOfferFile } from "../model/commodityOfferRequest.types";
import { D_MASTERSPECIFICATION } from "./commodityUtils";

export const APPROVAL_DOCUMENTS = {
  [COMMODITY]: { path: "documents", types: [D_MASTERSPECIFICATION] },
  [FINISHEDPRODUCT]: { path: "documents", types: [D_MASTERSPECIFICATION] },
};

/**
 * Upload a file that should be publicly accessible
 * @param file the file to upload
 * @param fileName optional, file name
 * @returns {string | false} file alias if upload succeeded, else false
 */
export function uploadPublicFile(file: File, fileName?: string): string | false {
  return uploadFile(file, fileName, undefined, true);
}

/**
 * Upload a file synchronously
 * @param file the file to upload
 * @param fileName optional, file name
 * @param company optional, id of associated company
 * @param pub optional, flag if file should be publicly accessible
 * @returns {string | false} file alias if upload succeeded, else false
 */
export function uploadFile(file: File, fileName?: string, company?: string, pub?: boolean): string | false {
  // Enforce encoded file name
  let name = (fileName ?? file.name).replaceAll("%", "");
  name = encodeURIComponent(name.replace(/\.[^/.]+$/, ""));
  const fileType: string = mime.extension(file.type).toLowerCase() as string;
  let companyId;
  const userCompanyId = userService.getCompany();
  // Set company if needed
  if (company) companyId = company;
  else if (userCompanyId && userCompanyId !== "internal" && BSON.ObjectID.isValid(userCompanyId))
    companyId = userCompanyId.toString();

  const xhr = new XMLHttpRequest();
  // synchronous request
  xhr.open(
    "POST",
    (pub ? process.env.REACT_APP_PUBLIC_UPLOAD_ENDPOINT : process.env.REACT_APP_UPLOAD_ENDPOINT) +
      `${name}.${fileType}`,
    false
  );
  xhr.withCredentials = true;
  xhr.setRequestHeader("Content-Type", "application/octate-stream");
  xhr.setRequestHeader("company", companyId || "");
  xhr.send(file);
  if (xhr.status === 200) {
    return xhr.responseText;
  } else {
    if (xhr.status === 401) {
      toast.error("Your session expired. Please log out and log in again to refresh your session.", {
        autoClose: false,
      });
    }
    console.error("Status code:", xhr.status, xhr.responseText);
    return false;
  }
}

/**
 * Resolve a path to mediahub file
 * @param alias alias of file
 * @param pub optional, flag if it is a public file
 * @returns {string} full path to file
 */
export function resolveFilePath(alias: string, pub?: boolean): string {
  if (pub) return process.env.REACT_APP_MEDIAHUB_PUBLIC_FILE_BASE + alias;
  return process.env.REACT_APP_MEDIAHUB_FILE_BASE + alias;
}

/**
 * Create a commodity file object, upload a file to mediahub and return the prepared file object
 * @param file the file to upload
 * @param fileName file name
 * @param type the type of the file
 * @param supplier optional, related supplier of the document
 * @param validUntil optional, expiration date
 * @param customType optional, custom type for commodity files
 * @param version optional, document version number
 * @returns {UploadedFile | undefined} file object
 */
export function uploadAndGetArticleFileObject(
  file: File,
  fileName: string,
  type: string,
  supplier?: Supplier | SupplierExtended,
  validUntil?: Date | null,
  customType?: string,
  version?: number
): UploadedFileExtended | undefined {
  const alias = uploadFile(file, fileName, supplier ? supplier._id.toString() : undefined);
  if (!alias) {
    toast.error("File upload failed.");
    return;
  }
  const uploadedFile: UploadedFileExtended = {
    _id: new BSON.ObjectId(),
    name: fileName,
    path: alias,
    type: type as "masterSpecification" | "supplierSpecification" | "coa" | "other",
    date: new Date(),
    size: file.size,
    originator: userService.getUserId(),
  };
  const requiresApproval =
    APPROVAL_DOCUMENTS.finishedProduct.types.includes(type) || APPROVAL_DOCUMENTS.commodity.types.includes(type);
  // Set approvedBy to indicate it requires approval
  if (requiresApproval) {
    uploadedFile.approver = null;
    uploadedFile.signedBy = null;
  }
  if (supplier) uploadedFile.supplier = supplier;
  if (validUntil) uploadedFile.validUntil = validUntil;
  if (customType?.trim()) uploadedFile.customType = customType.trim();
  if (version !== undefined) uploadedFile.version = version;
  return uploadedFile;
}

/**
 * Create a file object, upload a file to mediahub and return the prepared file object
 * @param file the file to upload
 * @param fileName file name
 * @param type the type of the file
 * @param supplier optional, related supplier of the document
 * @param validUntil optional, expiration date
 * @param customType optional, custom type for commodity files
 * @returns {SupplierFile | undefined} file object
 */
export function uploadAndGetSupplierFileObject(
  file: File,
  fileName: string,
  type: string,
  supplier?: Supplier | SupplierExtended,
  validUntil?: Date | null,
  customType?: string
): SupplierFile | undefined {
  const alias = uploadFile(file, fileName, supplier ? supplier._id.toString() : undefined);
  if (!alias) {
    toast.error("File upload failed.");
    return;
  }
  const uploadedFile: SupplierFile = {
    _id: new BSON.ObjectId(),
    name: fileName,
    path: alias,
    type: type as "certificate" | "other",
    date: new Date(),
    size: file.size,
  };
  if (validUntil) uploadedFile.validUntil = validUntil;
  if (customType?.trim()) uploadedFile.customType = customType.trim();
  return uploadedFile;
}

/**
 * Create a file object, upload a file to mediahub and return the prepared file object
 * @param file the file to upload
 * @param fileName file name
 * @param type the type of the file
 * @param supplier optional, related supplier of the document
 * @returns {UploadedFile | UploadedOfferFile | undefined} file object
 */
export function uploadAndGetOfferCommodityFileObject(
  file: File,
  fileName: string,
  type: string,
  supplier: SupplierSupplier
): UploadedFile | UploadedOfferFile | undefined {
  const alias = uploadFile(file, fileName, supplier ? supplier._id.toString() : undefined);
  if (!alias) {
    toast.error("File upload failed.");
    return;
  }
  return {
    _id: new BSON.ObjectId(),
    name: fileName,
    path: alias,
    type: type as "supplierSpecification" | "coa",
    date: new Date(),
    size: file.size,
  };
}

/**
 * Create a batch file object, upload a file to mediahub and return the prepared file object
 * @param file the file to upload
 * @param fileName file name
 * @param supplierId optional, related supplier of the document
 * @returns {BatchFile | undefined} file object
 */
export function uploadAndGetBatchFileObject(
  file: File | BatchFile,
  fileName: string,
  supplierId?: BSON.ObjectId | string
): BatchFile | undefined {
  if ("uploadedBy" in file) return file;
  const alias = uploadFile(file, fileName, supplierId?.toString());
  if (!alias) {
    toast.error("File upload failed.");
    return;
  }
  return {
    _id: new BSON.ObjectId(),
    name: fileName,
    path: alias,
    date: new Date(),
    uploadedBy: userService.getUserId(),
  };
}

/**
 * Create an order file object, upload a file to mediahub and return the prepared file object
 * @param file the file to upload
 * @param type file type
 * @param company optional, related company of the document
 * @returns {OrderFile | undefined} file object
 */
export function uploadAndGetOrderFileObject(file: File, type: string, company?: Company): OrderFile | undefined {
  const fileName = file.name;
  const alias = uploadFile(file, fileName, company ? company._id.toString() : undefined);
  if (!alias) {
    toast.error("File upload failed.");
    return;
  }
  return {
    _id: new BSON.ObjectId(),
    path: alias,
    date: new Date(),
    type: type,
    uploadedBy: userService.getUserId(),
  };
}

/**
 * Shorten an alias by replacing the object id
 * @param alias file alias
 * @param replaceValue Optional, if set the object id is replaced with that one, else with dots
 * @returns {string} shortened alias
 */
export function shortenAlias(alias: string, replaceValue?: string): string {
  const split = alias.split("/");
  // Split if alias contains a path and only get the file name
  const fileName = split.length > 1 ? split[1] : split[0];
  const regex = /[a-f\d]{24}/i;
  // Replace object ids if available
  return fileName.replace(regex, replaceValue ?? "(...)");
}

/**
 * Check if the file name does not include invalid characters
 * @param fileName string to check
 * @returns { boolean } true if no invalid characters are contained, else false
 */
export function validateFileName(fileName: string): boolean {
  return !(/\//.test(fileName) || /\\/.test(fileName));
}

/**
 * Download the given content as a file with the given name.
 * Source: https://stackoverflow.com/a/68146412/14564983
 * @param content Content of the file
 * @param fileName Name of the file
 * @param contentType Type of the content
 */
export function downloadFile(content: string, fileName: string, contentType: string) {
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const a = document.createElement("a");
  a.href = url;
  a.setAttribute("download", fileName);
  a.click();
}
