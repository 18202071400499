import React, { PureComponent } from "react";
import { EditorState } from "draft-js";
import WysiwygEditor from "../../common/WYSIWYGEditor";

interface InvoiceFooterProps {
  notes: EditorState;
  isCreditNote: boolean;
  onNotesChange: (notes: EditorState) => void;
  onReminderChange: (notes: EditorState) => void;
  onCancelationChange: (notes: EditorState) => void;
  onCreditNoteChange: (notes: EditorState) => void;
  reminderText?: EditorState;
  cancelationText?: EditorState;
  creditNoteText?: EditorState;
}

class InvoiceFooter extends PureComponent<InvoiceFooterProps> {
  render() {
    const {
      notes,
      isCreditNote,
      onNotesChange,
      onReminderChange,
      onCancelationChange,
      onCreditNoteChange,
      reminderText,
      cancelationText,
      creditNoteText,
    } = this.props;

    // Editor triggers an error message https://github.com/jpuri/react-draft-wysiwyg/issues/951
    return (
      <>
        {reminderText && (
          <div className="mb-2">
            <label className="form-label fs-6 fw-bolder text-gray-700">Reminder Information</label>
            <WysiwygEditor content={reminderText} onChange={onReminderChange} />
          </div>
        )}
        {cancelationText && (
          <div className="mb-2">
            <label className="form-label fs-6 fw-bolder text-gray-700">Cancelation Information</label>
            <WysiwygEditor content={cancelationText} onChange={onCancelationChange} />
          </div>
        )}
        {creditNoteText && isCreditNote && (
          <div className="mb-2">
            <label className="form-label fs-6 fw-bolder text-gray-700">Credit Note Information</label>
            <WysiwygEditor content={creditNoteText} onChange={onCreditNoteChange} />
          </div>
        )}
        <div className="mb-0">
          <label className="form-label fs-6 fw-bolder text-gray-700">Notes</label>
          <WysiwygEditor content={notes} onChange={onNotesChange} />
        </div>
      </>
    );
  }
}

export default InvoiceFooter;
