import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import {
  CO_CANCELED,
  CO_ORDEREDBYCUSTOMER,
  CustomerOrderExtended,
  T_WAREHOUSE,
} from "../../../../../../model/customerOrder.types";
import { SupplierOrder } from "../../../../../../model/supplierOrder.types";
import { getOrderNumber } from "../../../../../../utils/orderUtils";
import { formatCurrency, formatDate, getDocFromCollection } from "../../../../../../utils/baseUtils";
import { BASE_CURRENCY } from "../../../../../../utils/currencyUtils";
import SplitCustomerOrderModal from "../../modals/SplitCustomerOrderModal";
import ConvertToWarehouseOrderModal from "../../modals/ConvertToWarehouseOrderModal";
import { DataContextInternalType } from "../../../../../../context/dataContext";
import {
  getIdentifierForOrderOrContract,
  getLinkForOrderOrContract,
} from "../../../../../../utils/contractAndOrderUtils";
import { getPreviousSupplierOrder } from "../../../../../../utils/customerOrderUtils";

interface WorkflowSupplierOrderCardProps {
  order: CustomerOrderExtended;
  context: DataContextInternalType;
  supplierOrder?: SupplierOrder;
}

class WorkflowSupplierOrderCard extends PureComponent<WorkflowSupplierOrderCardProps> {
  resolveSupplier = (supplier: string) => {
    const supplierDoc = getDocFromCollection(this.props.context.supplier, supplier);
    if (!supplierDoc) return <div />;
    return (
      <Link className="text-white fs-5 mb-1 custom-link" to={"/supplier/" + supplierDoc._id.toString()}>
        {supplierDoc.name}
      </Link>
    );
  };

  render() {
    const { order, context, supplierOrder } = this.props;
    const previousSupplierOrder = getPreviousSupplierOrder(order, context);
    const supplierOrderLink = previousSupplierOrder && getLinkForOrderOrContract(previousSupplierOrder);
    return (
      <div className="opacity-100-hover" style={{ opacity: supplierOrder || order.state === CO_CANCELED ? 0.3 : 1 }}>
        <div className="fw-bolder text-white fs-3 my-5">
          Supplier Order{" "}
          {supplierOrder ? (
            <i className="h2 fas fa-check-circle text-success" />
          ) : order.state !== CO_CANCELED ? (
            <span className="text-warning">[Current Task]</span>
          ) : null}
        </div>
        <div className="row">
          {supplierOrder ? (
            <div className="col-4">
              <div className="d-flex align-items-center rounded p-5 mb-7 bg-success">
                <div className="flex-grow-1 me-2">
                  <Link
                    className="text-white fs-5 mb-1 custom-link"
                    to={"/supplierOrder/" + supplierOrder._id.toString()}
                  >
                    {getOrderNumber(supplierOrder)}
                  </Link>
                  <br />
                  {this.resolveSupplier(supplierOrder.supplier)}
                  <span className="fw-bold d-block text-white">
                    {formatCurrency(supplierOrder.totalPrice, supplierOrder.currency ?? BASE_CURRENCY)}
                    <small style={{ float: "right" }}>
                      {formatCurrency(
                        supplierOrder.totalPrice / supplierOrder.amount,
                        supplierOrder.currency ?? BASE_CURRENCY
                      )}
                      /{supplierOrder.unit}
                    </small>
                  </span>
                  <span className="fw-bold d-block text-white">
                    {supplierOrder.amount} {supplierOrder.unit}
                    <small style={{ float: "right" }}>{formatDate(supplierOrder.createdAt)}</small>
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div>
              {order.state !== CO_CANCELED ? (
                <>
                  <div className="text-white mb-5 fs-6">No commodities were ordered yet</div>
                  <Link
                    to={`/createSupplierOrder/${order._id.toString()}`}
                    className="btn btn-outline btn-outline-warning"
                  >
                    Order Now
                  </Link>
                  <SplitCustomerOrderModal order={order} context={context} />
                  {order.transport !== T_WAREHOUSE && <ConvertToWarehouseOrderModal order={order} context={context} />}
                </>
              ) : (
                <>
                  {order.previousState === CO_ORDEREDBYCUSTOMER ? (
                    <div className="text-white mb-5 fs-6">No commodities were ordered yet</div>
                  ) : (
                    <>
                      <div className="text-white mb-5 fs-6">
                        Note: The supplier order is no longer linked to the customer order since it was canceled. The
                        ordered amount will go to the warehouse instead.
                      </div>
                      {supplierOrderLink && (
                        <>
                          <div className="text-white fs-6">
                            You can still find the status of the order here:{" "}
                            <Link
                              to={supplierOrderLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-white custom-link"
                            >
                              {getIdentifierForOrderOrContract(previousSupplierOrder)}
                            </Link>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default WorkflowSupplierOrderCard;
