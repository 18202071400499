import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import CustomerContractPage from "./CustomerContractPage";
import { DataContextAnonymous, DataContextCustomer } from "../../../context/dataContext";
import userService from "../../../services/userService";
import { ANONYMOUS, CUSTOMER } from "../../../utils/userUtils";

interface CustomerContractWrapperParams {
  id: string;
}

interface CustomerContractWrapperProps extends RouteComponentProps<CustomerContractWrapperParams> {}

const CustomerContractWrapper: React.FunctionComponent<CustomerContractWrapperProps> = (props) => {
  let context;
  if (userService.getUserType() === CUSTOMER) {
    context = useContext(DataContextCustomer);
  } else if (userService.getUserType() === ANONYMOUS) {
    context = useContext(DataContextAnonymous);
  }
  if (!context) return <></>;
  return <CustomerContractPage context={context} {...props} />;
};

export default CustomerContractWrapper;
