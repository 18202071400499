import { isCommoditySnapshot } from "../productArticleUtils";
import { CUSTOMER_ORDER_TYPES, SUPPLIER_ORDER_TYPES } from "../orderUtils";

/**
 * Create a customs clearance instructions html string for pdf generation
 * @param order the supplier or customer order
 * @param country the country of origin of the commodity
 * @param customsValue the customs value
 * @param hsCode the HS Code of the commodity
 * @param btiRef optional, the BTI reference
 * @param startingPoint optional, the starting point of the incoterm
 * @param incoterm optional, the incoterm itself
 * @returns {string} a html string for pdf generation
 */
export function createCustomsClearanceInstruction(
  order: SUPPLIER_ORDER_TYPES | CUSTOMER_ORDER_TYPES,
  country: string,
  customsValue: string,
  hsCode: string,
  btiRef?: string,
  startingPoint?: string,
  incoterm?: string
): string {
  let html = `<head>
  <link href="https://fonts.googleapis.com/css?family=Signika&display=swap" rel="stylesheet">
  <style>tr:nth-child(even) {
      background-color: white;
  }</style>
  <meta http-equiv="content-type" content="text/html; charset=utf-8">
</head>`;
  html += `<body style="font-family: Signika, sans-serif">
  <img src="https://rawbids.com/images/logo-dark.png" alt="Logo" width="180" style="float:right" />
    <span style="font-size: 30px; font-weight: bold">Customs Clearance Instructions</span><br /><br />
`;
  html += getCustomsClearanceBody(order, country, customsValue, hsCode, btiRef, startingPoint, incoterm);
  html += `</body>`;
  return html;
}

/**
 * Get the body of the customs clearance instrucations pdf
 * @param order the supplier order
 * @param country the country of origin of the commodity
 * @param customsValue the customs value
 * @param hsCode the HS Code of the commodity
 * @param btiRef optional, the BTI reference
 * @param startingPoint optional, the starting point of the incoterm
 * @param incoterm optional, the incoterm itself
 * @returns {string} a html string for pdf generation
 */
export function getCustomsClearanceBody(
  order: SUPPLIER_ORDER_TYPES | CUSTOMER_ORDER_TYPES,
  country: string,
  customsValue: string,
  hsCode: string,
  btiRef?: string,
  startingPoint?: string,
  incoterm?: string
): string {
  const { commodity } = order;
  // compatibility for very old orders
  const casNumbers =
    isCommoditySnapshot(commodity) && Array.isArray(commodity.casNumber)
      ? commodity.casNumber.join(", ")
      : isCommoditySnapshot(commodity) && commodity.casNumber;
  const incotermString = `${incoterm ? incoterm : order.terms ? order.terms.deliveryTerms : "-"} ${
    startingPoint ? ` - ${startingPoint}` : ""
  }`;
  return `
  <table style="font-size:16px; width: 100%; display: block">
  <tbody>
      <tr>
  <td style="width:35%"><b>Our Reference: </b></td>
  <td style="width:65%">RB-${order.orderNo}</td>
      </tr>
      <tr>
  <td style="width:35%"><b>Customs Clearance on: </b></td>
  <td style="width:65%">RAWBIDS GmbH, <br/>
  Willy-Brandt-Straße 23, <br/>
  20457 Hamburg</td>
  </tr>
  <tr style="line-height: 150%">
  <td style="width:35%; padding-bottom: 25px"><b>Country of Origin: </b></td>
  <td style="width:65%; padding-bottom: 25px">${country}</td>
      </tr>
      <tr style="line-height: 150%">
  <td style="width:35%; padding-bottom: 25px"><b>Description of goods: </b></td>
  <td style="width:65%; padding-bottom: 25px">${commodity.title.en} ${
    commodity.subtitle.en !== "" ? `- ${commodity.subtitle.en}` : ""
  }</td>
    </tr>
   ${
     casNumbers
       ? `<tr style="line-height: 150%">
         <td style="width:35%; padding-bottom: 25px">
           <b>CAS Number: </b>
         </td>
         <td style="width:65%; padding-bottom: 25px">${casNumbers}</td>
       </tr>`
       : ""
   }
    <tr style="line-height: 150%">
      <td style="width:35%; padding-bottom: 25px"><b>HS-Code: </b></td>
      <td style="width:65%; padding-bottom: 25px">${hsCode}</td>
    </tr>
     <tr style="line-height: 150%">
      <td style="width:35%; padding-bottom: 25px"><b>Duty: </b></td>
      <td style="width:65%; padding-bottom: 25px">${
        commodity.duty && commodity.duty.percentage !== undefined ? commodity.duty.percentage : "-"
      }%</td>
    </tr> 
    ${getBTIRef(btiRef || commodity.btiRefNo)}
    <tr style="line-height: 150%">
      <td style="width:35%; padding-bottom: 25px"><b>Incoterms: </b></td>
      <td style="width:65%; padding-bottom: 25px">${incotermString}</td>
    </tr>
    <tr style="line-height: 150%">
      <td style="width:35%; padding-bottom: 25px"><b>Customs Value: </b></td>
      <td style="width:65%; padding-bottom: 25px">${customsValue}</td>
    </tr>
  </tbody>
</table>`;
}

/**
 * Get the btiRef if one is given
 * @param btiRef The bti reference number
 * @returns {string} HTML with bti reference or empty string
 */
export function getBTIRef(btiRef: string | undefined) {
  if (btiRef) {
    return `<tr style="line-height: 150%">
  <td style="width:35%; padding-bottom: 25px"><b>BTI reference no.: </b></td>
  <td style="width:65%; padding-bottom: 25px">${btiRef}</td>
      </tr>`;
  } else {
    return ``;
  }
}
