import _ from "lodash";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { ContainerCost, Seaport } from "../../../../model/seaport.types";
import { paginate, PaginationState } from "../../../common/Pagination";
import { doFuseSearch, formatCurrency, getComponentState, getCountryNameForCode } from "../../../../utils/baseUtils";
import Search from "../../../common/Search";
import BaseListing from "../../../common/BaseListing";
import CreateSeaportModal from "./CreateSeaportModal";
import SimpleConfirmationModal from "../../../common/SimpleConfirmationModal";
import { CONTAINER_LABEL, updateSeaport } from "../../../../utils/seaportUtils";
import { DataContextInternal } from "../../../../context/dataContext";
import { USD } from "../../../../utils/currencyUtils";

interface SeaportListingProps {
  context: React.ContextType<typeof DataContextInternal>;
}

interface SeaportListingState extends PaginationState {
  search: string;
}

const COMPONENT_NAME = "SeaportListing";

class SeaportListing extends PureComponent<SeaportListingProps, SeaportListingState> {
  constructor(props: SeaportListingProps) {
    super(props);
    this.state = {
      search: "",
      currentPage: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {
    const state = getComponentState(this.props.context, COMPONENT_NAME);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(COMPONENT_NAME, this.state);
  }

  handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ search: e.target.value, currentPage: 1 });

  handleDisableSeaport = async (seaport: Seaport) => {
    const result = await updateSeaport({ disabled: !seaport.disabled }, seaport._id);
    if (result) toast.success(`Seaport successfully ${seaport.disabled ? "disabled" : "enabled"}`);
    else toast.error(`Error ${seaport.disabled ? "disabling" : "enabling"} seaport. Please try again later`);
  };

  getFilteredSeaports = () => {
    const { seaport } = this.props.context;
    const { search } = this.state;
    if (!search.trim()) return _.orderBy(seaport, "locode", "asc");
    return doFuseSearch(seaport, search, ["name", "locode", "country"]);
  };

  render() {
    const { currentPage, pageSize } = this.state;
    const filteredSeaports = this.getFilteredSeaports();
    const paginatedSeaports = paginate(filteredSeaports, currentPage, pageSize);

    const headerDefinition = [
      { title: "Name", style: { width: "20%" } },
      { title: "Locode", style: { width: "10%" } },
      { title: "Country", style: { width: "12%" } },
      { title: "Cost Per m³/t", style: { width: "13%" } },
      { title: "Container Cost", style: { width: "15%" } },
      { title: "YTY Quantity", className: "text-nowrap", style: { width: "15%" } },
      { title: "Action", className: "text-right", style: { width: "15%" } },
    ];
    return (
      <>
        <div className="d-flex align-items-center my-5">
          <div className="w-50">
            <Search
              onSearch={this.handleChangeSearch}
              placeholder="Search..."
              inputClasses="form-control custom-form-control"
            />
          </div>
        </div>
        <BaseListing
          headerDefinition={headerDefinition}
          documents={filteredSeaports}
          noHover={true}
          bodyContent={
            <>
              {paginatedSeaports.map((sp) => (
                <tr key={sp._id.toString()}>
                  <td className="text-white align-middle">{sp.name}</td>
                  <td className="text-white align-middle">{sp.locode}</td>
                  <td className="text-white align-middle">{getCountryNameForCode(sp.country)}</td>
                  <td className="text-white align-middle">
                    {sp.cost ? formatCurrency(sp.cost, sp.currency || USD) : "-"}
                  </td>
                  <td className="text-white align-middle">
                    {sp.containerCost
                      ? Object.entries(sp.containerCost).map(([key, cost]) => (
                          <div key={key}>
                            {CONTAINER_LABEL[key as keyof ContainerCost]}:{" "}
                            {formatCurrency(cost as number, sp.currency || USD)}
                          </div>
                        ))
                      : "-"}
                  </td>
                  <td className="text-white align-middle">
                    <div className="fs-6">
                      <div className="text-muted">N/A</div>
                    </div>
                  </td>
                  <td className="text-white align-middle text-right">
                    <SimpleConfirmationModal.SimpleConfirmationModalButton
                      size="md"
                      modalTitle={`${sp.disabled ? "Enable" : "Disable"} Seaport ${sp.locode}`}
                      onConfirm={() => this.handleDisableSeaport(sp)}
                      confirmButtonText={sp.disabled ? "Enable" : "Disable"}
                      buttonText={sp.disabled ? "Enable" : "Disable"}
                      buttonClasses={"btn btn-text " + (sp.disabled ? "btn-text-success" : "btn-text-danger")}
                      cancelButtonText="Close"
                      modalDescription={
                        <span className="text-white">
                          <h4 className="fw-bolder text-white text-left mt-10 mb-15">
                            Do you really want to {sp.disabled ? "enable" : "disable"} seaport{" "}
                            <em>
                              {sp.name} ({sp.locode})
                            </em>
                            ?
                          </h4>
                          {sp.disabled ? (
                            <span className="fw-bolder d-flex align-items-center text-white text-left">
                              After being enabled the seaport can be used inside the system again.
                            </span>
                          ) : (
                            <>
                              <div className="fw-bolder d-flex align-items-center text-white text-left">
                                After being disabled the seaport can not be used anymore. If the seaport is in use in
                                older orders it will still be shown there.
                              </div>
                              <div className="fw-bolder d-flex align-items-center text-white text-left">
                                You can re-enable the seaport any time via clicking on "Enable" on the seaport overview.
                              </div>
                            </>
                          )}
                        </span>
                      }
                    />
                    <CreateSeaportModal seaport={sp} additionalButtonClasses="btn-sm ml-2" />
                  </td>
                </tr>
              ))}
            </>
          }
          currentPage={currentPage}
          pageSize={pageSize}
          baseSize={25}
          onPageChange={(currentPage) => this.setState({ currentPage })}
          onPageSizeChange={(pageSize) => this.setState({ pageSize, currentPage: 1 })}
        />
        )
      </>
    );
  }
}

export default SeaportListing;
