import _ from "lodash";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import validator from "validator";
import {
  SupplierExtended,
  T_S_UPDATESUPPLIERINFORMATION,
  T_S_UPDATESUPPLIERSTATE,
} from "../../../../model/supplier.types";
import { DataContextInternalType } from "../../../../context/dataContext";
import SupplierPageGeneralInformationBlock from "../SupplierPageGeneralInformationBlock";
import {
  getDefaultReferencedAirports,
  getDefaultReferencedSeaports,
  getSupplierTimelineEntry,
  updateSupplier,
} from "../../../../utils/supplierUtils";
import { SelectOption } from "../../../common/CustomSelect";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import ContactsCard from "../../../common/ContactsCard";
import PackagingDimensionList from "../../../packagingDimensions/internal/PackagingDimensionList";
import { getDefaultAddress, isAddressTooShort } from "../../../../utils/addressUtils";
import { AddressType } from "../../../../model/commonTypes";
import { I_PAYMENTTARGETS } from "../../../../utils/invoiceUtils";

import { reduceSupplier } from "../../../../utils/dataTransformationUtils";

interface SupplierPageGeneralProps {
  supplier: SupplierExtended;
  context: DataContextInternalType;
}

interface SupplierPageGeneralState {
  edit: boolean;
  customPaymentTarget: boolean;
  supplierEdit: SupplierExtended;
  referencedSeaports: Array<SelectOption>;
  referencedAirports: Array<SelectOption>;
}

class SupplierPageGeneral extends PureComponent<SupplierPageGeneralProps, SupplierPageGeneralState> {
  constructor(props: SupplierPageGeneralProps) {
    super(props);
    const isCustom = !I_PAYMENTTARGETS.some((pt) => pt.value === props.supplier.paymentTerms?.paymentTarget);
    this.state = {
      edit: false,
      customPaymentTarget: isCustom,
      supplierEdit: _.cloneDeep(props.supplier),
      referencedSeaports: getDefaultReferencedSeaports(props.supplier.seaportReferences, props.context.seaport),
      referencedAirports: getDefaultReferencedAirports(props.supplier.airportReferences, props.context.airport),
    };
  }

  handleEditToggle = () => this.setState({ edit: !this.state.edit, supplierEdit: _.cloneDeep(this.props.supplier) });

  handleAddAddress = () => {
    const supplier = _.cloneDeep(this.state.supplierEdit);
    supplier.address.push(getDefaultAddress());
    this.setState({ supplierEdit: supplier });
  };

  handleDeleteAddress = (id: BSON.ObjectId) => {
    const supplier = _.cloneDeep(this.state.supplierEdit);
    supplier.address = supplier.address.filter((a) => a._id.toString() !== id.toString());
    this.setState({ supplierEdit: supplier });
  };

  handleEditSupplierSelectCountry = (e: SelectOption, idx: number) => {
    const supplier = _.cloneDeep(this.state.supplierEdit);
    supplier.address[idx].country = e.value;
    this.setState({ supplierEdit: supplier });
  };

  handleEditSupplierSelectContact = (e: SelectOption, idx: number) => {
    const supplier = _.cloneDeep(this.state.supplierEdit);
    supplier.address[idx].contactPerson = e.value;
    this.setState({ supplierEdit: supplier });
  };

  handleEditSupplierSelect = (name: string, e: SelectOption) => {
    const supplier = _.cloneDeep(this.state.supplierEdit);
    _.set(supplier, name, e.value);
    this.setState({ supplierEdit: supplier });
  };

  handleEditSupplierPaymentTargetSelect = (e: SelectOption) => {
    if (e.value !== "custom") {
      const supplier = _.cloneDeep(this.state.supplierEdit);
      const editPaymentTerms = {
        ...supplier.paymentTerms,
        paymentTarget: e.value,
      };
      _.set(supplier, "paymentTerms", editPaymentTerms);
      this.setState({ supplierEdit: supplier, customPaymentTarget: false });
    } else {
      this.setState({ customPaymentTarget: true });
    }
  };

  handleEditRating = (rating: number) => {
    const supplier = _.cloneDeep(this.state.supplierEdit);
    supplier.rating = rating;
    this.setState({ supplierEdit: supplier });
  };

  handleEditSupplierInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field?: string) => {
    const supplier = _.cloneDeep(this.state.supplierEdit);
    const targetName = field ?? e.target.name;
    _.set(supplier, targetName, e.target.type === "number" ? +e.target.value : e.target.value);
    this.setState({ supplierEdit: supplier });
  };

  handleActiveSupplier = async () => {
    const { supplier } = this.props;
    const result = await updateSupplier({ activated: true }, supplier._id);
    if (result && result.modifiedCount > 0) {
      toast.success("Supplier activated successfully");
    } else {
      toast.error("Error activating supplier");
    }
  };

  handleSupplierDisabledFlag = async (disable: boolean) => {
    const { supplier } = this.props;
    const timelineEntry = getSupplierTimelineEntry(T_S_UPDATESUPPLIERSTATE, { name: disable ? "disabled" : "enabled" });
    const result = await updateSupplier({ disabled: disable }, supplier._id, timelineEntry);
    if (result && result.modifiedCount > 0) {
      toast.success("Supplier " + (disable ? "disabled" : "enabled") + " successfully");
    } else {
      toast.error("Error " + (disable ? "disabling" : "enabling") + " supplier");
    }
  };

  handleEditPreparationTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    const supplier = _.cloneDeep(this.state.supplierEdit);
    supplier.transport.preparationTime = +e.target.value;
    this.setState({ supplierEdit: supplier });
  };

  handleEditCustomPaymentTarget = (e: React.ChangeEvent<HTMLInputElement>) => {
    const supplier = _.cloneDeep(this.state.supplierEdit);
    supplier.paymentTerms = {
      ...supplier.paymentTerms,
      paymentTarget: e.target.value,
    };
    this.setState({ supplierEdit: supplier });
  };

  handleEditCustomPaymentTargetConditions = (e: React.ChangeEvent<HTMLInputElement>) => {
    const supplier = _.cloneDeep(this.state.supplierEdit);
    supplier.paymentTerms = {
      paymentTarget: supplier.paymentTerms?.paymentTarget ? supplier.paymentTerms?.paymentTarget : "",
      paymentTargetConditions: e.target.value,
    };
    this.setState({ supplierEdit: supplier });
  };

  handleSaveSupplier = async () => {
    const { supplierEdit, referencedSeaports, referencedAirports } = this.state;
    if (!supplierEdit) return;
    const toSubmit = Object.assign({}, supplierEdit);
    toSubmit.seaportReferences = referencedSeaports.map((opt) => opt.value);
    toSubmit.airportReferences = referencedAirports.map((opt) => opt.value);

    // If no address is primary designate the first one as primary
    if (!toSubmit.address.find((a) => a.type === AddressType.A_PRIMARY)) {
      toSubmit.address[0].type = AddressType.A_PRIMARY;
    }
    const timelineEntry = getSupplierTimelineEntry(T_S_UPDATESUPPLIERINFORMATION);
    const result = await updateSupplier(reduceSupplier(toSubmit), undefined, timelineEntry);
    if (result && result.modifiedCount > 0) {
      toast.success("Supplier data updated successfully");
      this.setState({ edit: false });
    } else {
      toast.error("Error updating Supplier");
    }
  };

  handleEditReferences = (selections: Array<SelectOption>, type: "seaport" | "airport") => {
    // @ts-ignore
    this.setState({ [type === "seaport" ? "referencedSeaports" : "referencedAirports"]: selections });
  };

  handleToggleEUSupplier = () => {
    const supplier = _.cloneDeep(this.state.supplierEdit);
    supplier.euSupplier = !supplier.euSupplier;
    this.setState({ supplierEdit: supplier });
  };

  validateSupplierData = () => {
    const { supplierEdit } = this.state;
    const { name, mail, phone, address, currency } = supplierEdit;
    const errors: Array<string> = [];
    if (name.trim().length < 3) errors.push("Supplier name too short");
    if (isAddressTooShort(address[0])) errors.push("Address too short");
    if (!currency) errors.push("Currency not set");
    if (!address[0].country) errors.push("No country selected");
    if (phone.trim().length < 3 && !validator.isEmail(mail))
      errors.push("Please provide a valid phone number or email");
    return errors;
  };

  render() {
    const { supplier, context } = this.props;
    const { edit, customPaymentTarget, supplierEdit, referencedAirports, referencedSeaports } = this.state;
    const errors = this.validateSupplierData();
    return (
      <>
        <div className="card bg-white">
          <div className="card-header border-0 mt-5">
            <div className="card-title flex-column">
              <h2 className="mb-1">General Information</h2>
              <div className="fs-6 fw-bold text-muted">{supplier.name}</div>
            </div>
            <div className="pt-3">
              <div className="d-flex pt-3 align-items-center w-100">
                <>
                  {edit && (
                    <button
                      className={"btn btn-outline btn-outline-light btn-sm float-right "}
                      onClick={this.handleEditToggle}
                    >
                      Cancel
                    </button>
                  )}
                  {edit ? (
                    <ErrorOverlayButton
                      className={"btn btn-outline btn-outline-light btn-sm float-right ml-2"}
                      errors={errors}
                      buttonText={"Save Supplier"}
                      onClick={this.handleSaveSupplier}
                    />
                  ) : (
                    <button
                      className={"btn btn-outline btn-outline-light btn-sm float-right ml-2"}
                      onClick={this.handleEditToggle}
                    >
                      Edit Supplier
                    </button>
                  )}
                </>
              </div>
            </div>
          </div>
          <div className="card-body p-9 pt-4">
            <div className="pt-0">
              <SupplierPageGeneralInformationBlock
                supplier={edit ? supplierEdit : supplier}
                context={context}
                referencedSeaports={referencedSeaports}
                referencedAirports={referencedAirports}
                edit={edit}
                customPaymentTarget={customPaymentTarget}
                onEditSupplierInput={this.handleEditSupplierInput}
                onEditSupplierSelect={this.handleEditSupplierSelect}
                onEditSupplierPaymentTargetSelect={this.handleEditSupplierPaymentTargetSelect}
                onEditRating={this.handleEditRating}
                onAddAddress={this.handleAddAddress}
                onDeleteAddress={this.handleDeleteAddress}
                onEditSupplierSelectCountry={this.handleEditSupplierSelectCountry}
                onEditSupplierSelectContact={this.handleEditSupplierSelectContact}
                onEditPreparationTime={this.handleEditPreparationTime}
                onEditCustomPaymentTarget={this.handleEditCustomPaymentTarget}
                onEditCustomPaymentTargetConditions={this.handleEditCustomPaymentTargetConditions}
                onEditReferences={this.handleEditReferences}
                onToggleEUSupplier={this.handleToggleEUSupplier}
              />
              <div className="border-bottom-dark-gray" />
              <div className="pt-3">
                <div className="d-flex pt-3 align-items-center w-100">
                  {!supplier.activated && (
                    <button className="btn btn-text btn-text-success btn-sm" onClick={this.handleActiveSupplier}>
                      Activate Supplier
                    </button>
                  )}
                  {supplier.disabled ? (
                    <button
                      className="btn btn-text btn-text-success btn-sm ml-auto"
                      onClick={() => this.handleSupplierDisabledFlag(false)}
                    >
                      Enable Supplier
                    </button>
                  ) : (
                    <button
                      className="btn btn-text btn-text-danger btn-sm ml-auto"
                      onClick={() => this.handleSupplierDisabledFlag(true)}
                    >
                      Disable Supplier
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <ContactsCard company={supplier} type={"supplier"} />
        </div>
        <div className="mt-5">
          <PackagingDimensionList context={context} supplier={supplier} />
        </div>
      </>
    );
  }
}

export default SupplierPageGeneral;
