import React, { PureComponent } from "react";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import { isEqual, cloneDeep } from "lodash";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CloseButton, Modal } from "react-bootstrap";
import { Input } from "../../../common/Input";
import { Commodity, SupplierPrices, CommodityTimelineEntry } from "../../../../model/commodity.types";
import {
  getArticleProperty,
  getCommodityTimelineEntry,
  T_PRICEUPDATED,
  UPDATECOMMODITYSUPPLIERPRICES,
} from "../../../../utils/commodityUtils";
import { DataContextInternalType } from "../../../../context/dataContext";
import BaseListing from "../../../common/BaseListing";
import {
  SupplierExtended,
  SupplierTimelineEntry,
  T_S_ADDCOMMODITYTOSUPPLIER,
  T_S_ADDFINISHEDPRODUCTSUPPLIER,
} from "../../../../model/supplier.types";
import { callFunction } from "../../../../services/dbService";
import Search from "../../../common/Search";
import { doFuseSearch } from "../../../../utils/baseUtils";
import { paginate, PaginationState } from "../../../common/Pagination";
import { PropertyType } from "../../../../utils/propertyUtils";
import { Property } from "../../../../model/property.types";
import { getSupplierTimelineEntry, updateSupplierTimeline } from "../../../../utils/supplierUtils";
import { FinishedProduct, FinishedProductTimelineEntry } from "../../../../model/finishedProduct.types";
import { getFinishedProductTimelineEntry } from "../../../../utils/finishedProductUtils";
import { extendCommodity, extendFinishedProduct } from "../../../../utils/dataTransformationUtils";

interface SelectCommoditiesModalProps extends RouteComponentProps {
  supplier: SupplierExtended;
  finishedProduct: boolean;
  context: DataContextInternalType;
}

interface SelectCommoditiesModalState extends PaginationState {
  show: boolean;
  selectedArticles: Array<Commodity | FinishedProduct>;
  availableArticles: Array<Commodity | FinishedProduct>;
  search: string;
  view: number;
  saving: boolean;
}

class SelectCommoditiesModal extends PureComponent<SelectCommoditiesModalProps, SelectCommoditiesModalState> {
  constructor(props: SelectCommoditiesModalProps) {
    super(props);

    this.state = {
      show: false,
      selectedArticles: [],
      availableArticles: [],
      search: "",
      currentPage: 1,
      pageSize: 15,
      view: 0,
      saving: false,
    };
  }

  componentDidMount() {
    this.getAvailableCommodities();
  }

  componentDidUpdate(prevProps: Readonly<SelectCommoditiesModalProps>) {
    // check if new suppliers were added to commodities to delete them from availableCommodities
    if (!this.props.finishedProduct && !isEqual(prevProps.context.commodity, this.props.context.commodity)) {
      this.getAvailableCommodities();
    } else if (
      this.props.finishedProduct &&
      !isEqual(prevProps.context.finishedProduct, this.props.context.finishedProduct)
    ) {
      this.getAvailableCommodities();
    }
  }

  handleShow = () => this.setState({ show: true, view: 0, selectedArticles: [], search: "" });
  handleHide = () => this.setState({ show: false });

  handleNext = () => {
    const { view } = this.state;
    if (view < 1) this.setState({ view: view + 1 });
  };

  handleBack = () => {
    const { view } = this.state;
    if (view > 0) this.setState({ view: view - 1 });
  };

  handleAddSelectedCommodities = async () => {
    const { supplier, finishedProduct } = this.props;
    const { selectedArticles } = this.state;
    const commodityTimelineEntry: CommodityTimelineEntry | FinishedProductTimelineEntry = finishedProduct
      ? getFinishedProductTimelineEntry(T_PRICEUPDATED, undefined, supplier._id.toString())
      : getCommodityTimelineEntry(T_PRICEUPDATED, undefined, supplier._id.toString());
    const selectedCommodityNames = selectedArticles.map((c) => `${c.title.en}`).join("; ");
    const supplierTimelineEntry: SupplierTimelineEntry = getSupplierTimelineEntry(
      finishedProduct ? T_S_ADDFINISHEDPRODUCTSUPPLIER : T_S_ADDCOMMODITYTOSUPPLIER,
      {
        name: selectedCommodityNames,
      }
    );
    let errorCount = 0;
    let successCount = 0;
    this.setState({ saving: true });
    try {
      for (const commodity of selectedArticles) {
        const newSupplierPrice: SupplierPrices = {
          _id: new BSON.ObjectId(),
          prices: [],
          supplier: supplier._id.toString(),
          contingent: Infinity,
        };
        // New supplier
        commodityTimelineEntry.post = {
          suppliers: [{ ...newSupplierPrice, supplier: supplier ? supplier._id : undefined }],
        };
        const result: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false = await callFunction(
          UPDATECOMMODITYSUPPLIERPRICES,
          [
            commodity._id.toString(),
            { id: this.props.supplier._id, supplier: newSupplierPrice },
            true,
            commodityTimelineEntry,
            finishedProduct,
          ]
        );
        if (result && result.modifiedCount > 0) {
          successCount++;
        } else {
          errorCount++;
        }
      }
      const resSupplier = await updateSupplierTimeline(supplier._id, supplierTimelineEntry);
      if (resSupplier) {
        successCount++;
      } else {
        errorCount++;
      }
    } finally {
      if (successCount > 0 && errorCount === 0) {
        toast.success(`${finishedProduct ? "Finished Product" : "Commodity"} prices updated successfully`);
      } else if (errorCount > 0 && successCount === 0) {
        toast.error(`Error updating ${finishedProduct ? "finished product" : "commodity"} prices`);
      } else if (errorCount > 0 && successCount > 0) {
        toast.error(`Error updating some ${finishedProduct ? "Finished Product" : "Commodity"} prices`);
      }
      this.setState({ saving: false });
    }
    this.handleHide();
  };

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });

  handleClickCheckbox = (id: string) => {
    const availableCommodities = cloneDeep(this.state.availableArticles);
    let selectedCommodities = cloneDeep(this.state.selectedArticles);
    const checkedCommodity = availableCommodities.filter((c) => c._id.toString() === id);

    // if checkedCommodity is already in selectedCommodities then commodity is deleted from selectedCommodities
    if (selectedCommodities.some((sC) => sC._id.toString() === id) && selectedCommodities.length > 0) {
      selectedCommodities = selectedCommodities.filter((sC) => sC._id.toString() !== id);
    }
    // else push checkedCommodity to selectedCommodities
    else {
      selectedCommodities.push(checkedCommodity[0]);
    }
    this.setState({ selectedArticles: selectedCommodities });
  };

  handlePageChange = (page: number) => this.setState({ currentPage: page });

  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });

  getAvailableCommodities = () => {
    const { finishedProduct } = this.props;
    const commodities = finishedProduct
      ? cloneDeep(this.props.context.finishedProduct)
      : cloneDeep(this.props.context.commodity);
    // Filter disabled, not approved commodities and commodities supplier already has listed
    const availableCommodities = finishedProduct
      ? (commodities as Array<FinishedProduct>).filter(
          (c) =>
            c.approved && !c.disabled && !c.suppliers.some((s) => s.supplier === this.props.supplier._id.toString())
        )
      : (commodities as Array<Commodity>).filter(
          (c) =>
            c.approved && !c.disabled && !c.suppliers.some((s) => s.supplier === this.props.supplier._id.toString())
        );
    this.setState({ availableArticles: availableCommodities });
  };

  render() {
    const { finishedProduct, context } = this.props;
    const { show, availableArticles, selectedArticles, search, currentPage, pageSize, view, saving } = this.state;
    const headers = [
      { title: "" },
      { title: `${finishedProduct ? "Finished Product" : "Commodity"} Title` },
      { title: "Category" },
      { title: "Composition" },
      { title: "Organic" },
    ];
    const filteredCommodities = search.trim()
      ? doFuseSearch(availableArticles, search, ["title.en", "subtitle.en"])
      : availableArticles;

    return (
      <>
        <button className="btn btn-outline btn-outline-light btn-sm" onClick={this.handleShow}>
          Add {finishedProduct ? "Finished Products" : "Commodities"}
        </button>
        <Modal contentClassName={"bg-dark"} size={"xl"} show={show} onHide={this.handleHide} centered={true}>
          <Modal.Header className="border-0 pb-0 pt-2">
            <h1 className="m-5">Add {finishedProduct ? "Finished Products" : "Commodities"} to Supplier</h1>
            <CloseButton variant={"white"} onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body className="mx-5 py-0">
            {view === 0 ? (
              <>
                {availableArticles.length > 0 && (
                  <div className="w-50 align-self-end ml-auto my-0">
                    <Search onSearch={this.handleSearch} value={search} />
                  </div>
                )}
                <div className="my-15">
                  {filteredCommodities.length > 0 ? (
                    <BaseListing
                      headerDefinition={headers}
                      bodyContent={
                        <>
                          {paginate(filteredCommodities, currentPage, pageSize).map((c) => {
                            const articleExtended = finishedProduct
                              ? extendFinishedProduct(c as FinishedProduct, context)
                              : extendCommodity(c as Commodity, context);
                            return (
                              <tr key={c._id.toString()} className="text-white fs-6">
                                <td className="align-middle">
                                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                                    <Input
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={selectedArticles.some((sC) => sC._id.toString() === c._id.toString())}
                                      onClick={() => this.handleClickCheckbox(c._id.toString())}
                                    />
                                  </div>
                                </td>
                                <td className="align-middle">
                                  {c.title.en}
                                  <br />
                                  {c.subtitle.en}
                                </td>
                                <td className="align-middle">
                                  {(getArticleProperty(articleExtended.properties, PropertyType.CATEGORY) as Property)
                                    ?.description.en || "-"}
                                </td>
                                <td className="align-middle">
                                  {(
                                    getArticleProperty(articleExtended.properties, PropertyType.COMPOSITION) as Property
                                  )?.description.en || "-"}
                                </td>
                                <td className="align-middle">{c.organic ? "Yes" : "No"}</td>
                              </tr>
                            );
                          })}
                        </>
                      }
                      documents={filteredCommodities}
                      baseSize={15}
                      pageSize={pageSize}
                      currentPage={currentPage}
                      onPageChange={this.handlePageChange}
                      onPageSizeChange={this.handlePageSizeChange}
                    />
                  ) : (
                    <div className="text-white fs-6">
                      No approved and enabled {finishedProduct ? "finished products" : "commodities"} left to add to
                      supplier or search not found.
                    </div>
                  )}
                </div>
              </>
            ) : (
              <BaseListing
                headerDefinition={headers.filter((h) => h !== headers[0])}
                bodyContent={
                  <>
                    {selectedArticles.map((c) => {
                      const articleExtended = finishedProduct
                        ? extendFinishedProduct(c as FinishedProduct, context)
                        : extendCommodity(c as Commodity, context);
                      return (
                        <tr key={c._id.toString()} className="text-white fs-6">
                          <td className="align-middle">
                            {c.title.en}
                            {c.subtitle.en && <span> - </span>}
                            {c.subtitle.en}
                          </td>
                          <td className="align-middle">
                            {(getArticleProperty(articleExtended.properties, PropertyType.CATEGORY) as Property)
                              ?.description.en || "-"}
                          </td>
                          <td className="align-middle">
                            {(getArticleProperty(articleExtended.properties, PropertyType.COMPOSITION) as Property)
                              ?.description.en || "-"}
                          </td>
                          <td className="align-middle">{c.organic ? "Yes" : "No"}</td>
                        </tr>
                      );
                    })}
                  </>
                }
              />
            )}
          </Modal.Body>
          <Modal.Footer className="mx-5">
            {view === 1 ? (
              <>
                <button className="btn  btn-text-white btn-sm " onClick={this.handleBack}>
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-outline btn-outline-light btn-sm "
                  onClick={this.handleAddSelectedCommodities}
                  disabled={saving}
                >
                  Add Selected {finishedProduct ? "Finished Products" : "Commodities"}
                </button>
              </>
            ) : (
              <>
                <button className="btn  btn-text-white btn-sm" onClick={this.handleHide}>
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-outline btn-outline-light btn-sm "
                  onClick={this.handleNext}
                  disabled={selectedArticles.length === 0}
                >
                  Next
                </button>
              </>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default withRouter(SelectCommoditiesModal);
