import React from "react";
import LoginForm from "./LoginForm";
import LoginInformationLinks from "./LoginInformationLinks";
import { toAbsoluteUrl } from "../../utils/baseUtils";

interface ArkLoginProps {
  email: string;
  error: string;
  fromLogout: boolean;
  loggingIn: boolean;
  password: string;
  resetSuccess: boolean;
  onEmailChange: (email: string) => void;
  onPasswordChange: (password: string) => void;
  onSubmit: (e: React.FormEvent) => void;
}

const ArkLogin: React.FC<ArkLoginProps> = ({
  email,
  error,
  fromLogout,
  loggingIn,
  password,
  resetSuccess,
  onEmailChange,
  onPasswordChange,
  onSubmit,
}) => (
  <div className="d-flex flex-column flex-root ark-login bg-white" id="kt_app_root">
    <div className="d-flex flex-column flex-lg-row flex-column-fluid">
      <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
        <div className="d-flex flex-center flex-column flex-lg-row-fluid">
          <div className="w-lg-500px p-10">
            <LoginForm
              email={email}
              password={password}
              error={error}
              loggingIn={loggingIn}
              fromLogout={fromLogout}
              resetSuccess={resetSuccess}
              onEmailChange={onEmailChange}
              onPasswordChange={onPasswordChange}
              onSubmit={onSubmit}
            />
            <LoginInformationLinks />
          </div>
        </div>
      </div>
      <div
        className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2 vh-100 position-relative justify-content-center"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/assets/media/misc/bg-2.jpg")})`,
          backgroundSize: "cover",
        }}
      >
        <div className="position-absolute top-0 w-100 h-100 login-cover-container" />
        <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100 position-relative">
          <a href="#" className="mb-0 mb-lg-12">
            <img alt="Logo" src={toAbsoluteUrl("/assets/media/ark-logo-light.png")} className="h-80px h-lg-100px" />
          </a>
          <p className="d-none d-lg-block fs-2qx fw-bolder text-center mb-7" style={{ color: "#ffffff" }}>
            Simplify your ingredient procurement.
          </p>
          <div className="d-none d-lg-block text-center w-50" style={{ color: "#ffffff", fontSize: "1.2rem" }}>
            Discover our online portal where supplement manufacturers can effortlessly purchase ingredients. We simplify
            the buying process and ensure consistent high quality, giving you complete oversight. Anytime, anywhere.
          </div>
          <a
            className="mt-10"
            style={{ color: "#ffffff", fontWeight: 800, fontSize: "1.5rem" }}
            href="https://rawbids.com/register"
          >
            Register now
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default ArkLogin;
