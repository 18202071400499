import React, { useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import Tooltip from "./Tooltip";

interface SimpleConfirmationModalButtonProps {
  buttonClasses?: string;
  buttonText: JSX.Element | string;
  buttonTooltipText?: string;
  modalTitle?: string;
  modalDescription: JSX.Element | string;
  confirmButtonText: string;
  cancelButtonText: string;
  onConfirm: () => Promise<void> | void;
  buttonId?: string;
  disabled?: boolean;
  size?: "lg" | "sm" | "xl" | "md";
  swapButtons?: boolean;
  hideConfirm?: boolean;
  saving?: boolean;
}

const SimpleConfirmationModalButton: React.FunctionComponent<SimpleConfirmationModalButtonProps> = (props) => {
  const { buttonClasses, buttonText, buttonId, disabled, saving, buttonTooltipText } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setShow(true);
  };

  const Button = () => (
    <button
      className={(buttonClasses ?? "") + (disabled ? " disabled" : "")}
      id={buttonId ? buttonId : ""}
      onClick={disabled || saving ? undefined : handleShow}
      type="button"
      disabled={disabled || saving}
    >
      {buttonText}
    </button>
  );

  return (
    <>
      {buttonTooltipText && !disabled ? (
        <Tooltip tooltipText={buttonTooltipText}>
          <div>
            <Button />
          </div>
        </Tooltip>
      ) : (
        <Button />
      )}
      <SimpleConfirmationModal show={show} onClose={handleClose} {...props} />
    </>
  );
};

interface SimpleConfirmationModalProps {
  show: boolean;
  showModal?: boolean;
  modalTitle?: JSX.Element | string;
  modalDescription: JSX.Element | string;
  confirmButtonText: string;
  cancelButtonText: string;
  checkboxText?: string;
  onClose: () => void;
  onConfirm: () => Promise<void> | void;
  onShowModal?: (checkBoxSelect?: boolean) => void;
  size?: "lg" | "sm" | "xl" | "md";
  swapButtons?: boolean;
  hideConfirm?: boolean;
  saving?: boolean;
}

const SimpleConfirmationModal: React.FunctionComponent<SimpleConfirmationModalProps> = (props) => {
  const {
    show,
    modalTitle,
    modalDescription,
    confirmButtonText,
    cancelButtonText,
    onClose,
    onConfirm,
    showModal,
    onShowModal,
    checkboxText,
    size,
    swapButtons,
    hideConfirm,
    saving,
  } = props;
  const [checkboxSelect, setCheckboxSelect] = useState(showModal);
  const handleConfirm = async () => {
    if (onShowModal) onShowModal(checkboxSelect);
    await onConfirm();
    onClose();
  };
  return (
    <Modal
      show={show}
      contentClassName={"bg-dark"}
      onHide={onClose}
      centered
      size={size === "md" ? undefined : size ? size : "sm"}
      name={"SimpleConfirmationModal"}
    >
      {modalTitle && (
        <Modal.Header className="border-0 pb-0">
          <Modal.Title>
            <h1 className="fw-bolder d-flex align-items-center text-white">{modalTitle}</h1>
          </Modal.Title>
          <CloseButton variant={"white"} disabled={saving} onClick={saving ? undefined : onClose} />
        </Modal.Header>
      )}
      <Modal.Body>
        {modalDescription}
        {/* Checkbox so customer can configure that he doesn't want to see the popup anymore */}
        {onShowModal && (
          <>
            <br />
            <br />
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={!checkboxSelect}
                onChange={() => setCheckboxSelect(!checkboxSelect)}
                id="showModalCheckbox"
              />
              <label className="form-check-label pl-1" htmlFor="showModalCheckbox">
                {checkboxText}
              </label>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {swapButtons ? (
          <>
            {!hideConfirm && (
              <button
                key="add"
                disabled={saving}
                className={"btn btn-light btn-sm " + (saving && "disabled")}
                onClick={saving ? undefined : handleConfirm}
              >
                {confirmButtonText}
              </button>
            )}
            <button
              key="cancel"
              disabled={saving}
              className={"btn btn-outline btn-outline-success btn-sm " + (saving && "disabled")}
              onClick={saving ? undefined : onClose}
            >
              {cancelButtonText}
            </button>
          </>
        ) : (
          <>
            <button
              key="cancel"
              disabled={saving}
              className={"btn btn-light btn-sm " + (saving && "disabled")}
              onClick={saving ? undefined : onClose}
            >
              {cancelButtonText}
            </button>
            {!hideConfirm && (
              <button
                key="add"
                disabled={saving}
                className={"btn btn-light btn-sm " + (saving && "disabled")}
                onClick={saving ? undefined : handleConfirm}
              >
                {confirmButtonText}
              </button>
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

// eslint-disable-next-line
export default { SimpleConfirmationModal, SimpleConfirmationModalButton };
