import React, { PureComponent } from "react";
import Search from "../../../common/Search";
import { DataContextInternalType } from "../../../../context/dataContext";
import { doFuseSearch } from "../../../../utils/baseUtils";
import { CompanyExtended } from "../../../../model/company.types";
import OrderHistoryEntry from "../../../orders/common/OrderHistoryEntry";

interface CustomerPageHistoryProps {
  company: CompanyExtended;
  context: DataContextInternalType;
}

interface CustomerPageHistoryState {
  search: string;
}

class CustomerPageHistory extends PureComponent<CustomerPageHistoryProps, CustomerPageHistoryState> {
  constructor(props: CustomerPageHistoryProps) {
    super(props);
    this.state = {
      search: "",
    };
  }

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });

  getFilteredEntries = () => {
    const { company, context } = this.props;
    const { customerOrder } = context;
    const { search } = this.state;
    const timelineEntries = customerOrder
      .filter((cO) => cO.company === company._id.toString())
      .map((cO) =>
        cO.timeline.map((t) => {
          return {
            order: cO,
            timelineEntry: t,
            recent: false,
          };
        })
      )
      .flat();
    if (search.trim()) return doFuseSearch(timelineEntries, search, ["text", "type", "order.orderNo"]);
    return timelineEntries;
  };

  render() {
    const { context } = this.props;
    const { search } = this.state;
    const timelineEntries = this.getFilteredEntries();
    return (
      <div className="card bg-white">
        <div className="card-header border-0 mt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">History</span>
            <span className="text-muted fw-bold fs-7">{timelineEntries.length} Entries</span>
          </h3>
          <div className="card-toolbar">
            <Search onSearch={this.handleSearch} value={search} />
          </div>
        </div>
        <div className="card-body" style={{ overflowY: "auto", maxHeight: "70vh" }}>
          {timelineEntries.map((entry) => (
            <OrderHistoryEntry
              key={entry.order._id.toString() + entry.timelineEntry._id.toString()}
              entry={entry}
              context={context}
              showOrder={true}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default CustomerPageHistory;
