import _ from "lodash";
import React, { PureComponent, useContext, useEffect, useState } from "react";
import { BSON } from "realm-web";
import { Accordion } from "react-bootstrap";
import { toast } from "react-toastify";
import { SellingPrice, TransportPrices } from "../../../model/commodity.types";
import Search from "../../common/Search";
import { doFuseSearch, formatCurrency, pluralize } from "../../../utils/baseUtils";
import { CustomToggle } from "../../common/CustomToggle";
import { O_TRANSPORTTYPES } from "../../../utils/orderUtils";
import {
  C_UNITS,
  getCommodityTimelineEntry,
  getDefaultSellingPrice,
  getDefaultSellingPrices,
  getDefaultTransportPrices,
  Incoterm,
  SUPPORTED_SELLINGPRICE_TYPES,
  T_SELLINGPRICESUPDATED,
  T_TRANSPORTPRICEUPDATED,
} from "../../../utils/commodityUtils";
import { Input } from "../../common/Input";
import { Action, COMMODITY, FINISHEDPRODUCT, transaction } from "../../../services/dbService";
import PriceGraduationModal from "../../dashboard/supplier/OverviewContent/modals/PriceGraduationModal";
import { PriceAdjustmentModal } from "../../dashboard/supplier/OverviewContent/modals/PriceAdjustmentModal";
import { CUSTOMER_BASE_CURRENCY, SUPPORTED_CURRENCIES } from "../../../utils/currencyUtils";
import { getCustomerOrderCalculation } from "../../../utils/customerOrderUtils";
import { DataContextInternal } from "../../../context/dataContext";
import { CO_TYPES } from "../../../model/customerOrder.types";
import Tooltip from "../../common/Tooltip";
import DateInput from "../../common/DateInput";
import { CommodityPriceRenewAllModal } from "../../common/CommodityPriceTable";
import { FP_UNITS, getFinishedProductTimelineEntry, isFinishedProduct } from "../../../utils/finishedProductUtils";
import { formatArticleUnit, InternalArticleExtended } from "../../../utils/productArticleUtils";

interface CommodityPageSellingPricesProps {
  article: InternalArticleExtended;
}

interface CommodityPageSellingPricesState {
  search: string;
  sellingPrices: Array<TransportPrices>;
}

class CommodityPageSellingPrices extends PureComponent<
  CommodityPageSellingPricesProps,
  CommodityPageSellingPricesState
> {
  constructor(props: CommodityPageSellingPricesProps) {
    super(props);
    this.state = { search: "", sellingPrices: this.getSellingPrices(props) };
  }

  componentDidUpdate = (prevProps: Readonly<CommodityPageSellingPricesProps>) => {
    if (!_.isEqual(prevProps.article.sellingPrices, this.props.article.sellingPrices)) {
      this.setState({ sellingPrices: this.getSellingPrices(this.props) });
    }
  };

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });

  handleNewPrice = (type: string) => {
    const sellingPrices = _.cloneDeep(this.state.sellingPrices);
    const entry = sellingPrices.find((t) => t.type === type);
    if (entry) entry.prices.push(getDefaultSellingPrice());
    this.setState({ sellingPrices });
  };

  handleRemovePrice = (type: string, priceId: BSON.ObjectId | string) => {
    const sellingPrices = _.cloneDeep(this.state.sellingPrices);
    const entry = sellingPrices.find((t) => t.type === type);
    if (entry) entry.prices = entry.prices.filter((p) => p._id.toString() !== priceId.toString());
    this.setState({ sellingPrices });
  };

  handleUpdatePrice = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    type: string,
    priceId: BSON.ObjectId | string
  ) => {
    const sellingPrices = _.cloneDeep(this.state.sellingPrices);
    const entry = sellingPrices.find((t) => t.type === type);
    if (!entry) return;
    const price = entry.prices.find((p) => p._id.toString() === priceId.toString());
    if (price) {
      _.set(price, e.target.name, e.target.type === "number" ? +e.target.value : e.target.value);
      price.date = new Date();
    }
    this.setState({ sellingPrices });
  };

  handleUpdateExpiry = (e: React.ChangeEvent<HTMLInputElement>, type: string, priceId: BSON.ObjectId | string) => {
    const sellingPrices = _.cloneDeep(this.state.sellingPrices);
    const entry = sellingPrices.find((t) => t.type === type);
    if (!entry) return;
    const price = entry.prices.find((p) => p._id.toString() === priceId.toString());
    if (price) {
      price.validUntil = new Date(e.target.value);
      price.date = new Date();
    }
    this.setState({ sellingPrices });
  };

  handleBulkAdjust = (type: string, value: string) => {
    const sellingPrices = _.cloneDeep(this.state.sellingPrices);
    const entry = sellingPrices.find((t) => t.type === type);
    if (!entry) return;
    for (let i = 0; i < entry.prices.length; i++) {
      const val = entry.prices[i].price;
      entry.prices[i].price = Math.round(val * (1 + parseFloat(value) / 100) * 100) / 100;
    }
    this.setState({ sellingPrices });
  };

  handleCreateGraduation = (type: string, results: Array<{ amount: number; price: number }>) => {
    const sellingPrices = _.cloneDeep(this.state.sellingPrices);
    const entry = sellingPrices.find((t) => t.type === type);
    if (!entry) return;
    entry.prices = [];
    for (let i = 0; i < results.length; i++) {
      entry.prices.push({
        _id: new BSON.ObjectId(),
        currency: CUSTOMER_BASE_CURRENCY,
        date: new Date(),
        minOQ: results[i].amount,
        price: results[i].price,
        validUntil: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 14),
      });
    }
    this.setState({ sellingPrices });
  };

  handleSavePricesForType = async (type: string) => {
    const { article } = this.props;
    const finishedProduct = isFinishedProduct(article);
    const sellingPrices = this.filterStateSellingPrices();
    const entry = sellingPrices.find((t) => t.type === type);
    if (!entry) return;
    entry.prices = _.sortBy(entry.prices, (p) => p.minOQ); // So that prices are sorted by MOQ
    const oldEntry = article.sellingPrices?.find((t) => t.type === type);
    const timelineEntry = finishedProduct
      ? getFinishedProductTimelineEntry(T_TRANSPORTPRICEUPDATED, undefined, type)
      : getCommodityTimelineEntry(T_TRANSPORTPRICEUPDATED, undefined, type);
    let action: Action;
    if (oldEntry)
      action = {
        collection: finishedProduct ? FINISHEDPRODUCT : COMMODITY,
        filter: { _id: article._id },
        update: { "sellingPrices.$[t]": entry },
        push: { timeline: timelineEntry },
        arrayFilters: [{ "t.type": type }],
      };
    else
      action = {
        collection: finishedProduct ? FINISHEDPRODUCT : COMMODITY,
        filter: { _id: article._id },
        push: { timeline: timelineEntry, sellingPrices: entry },
      };
    const result = await transaction([action]);
    if (result) {
      toast.success("Selling prices updated successfully");
    } else {
      toast.error("Error updating selling prices");
    }
  };

  handleRenewPrices = (type: string, date: Date) => {
    const sellingPrices = _.cloneDeep(this.state.sellingPrices);
    const sP = sellingPrices.find((s) => s.type === type);
    if (sP) {
      for (let i = 0; i < sP.prices.length; i++) {
        sP.prices[i].validUntil = date;
      }
    }
    this.setState({ sellingPrices });
  };

  handleSaveAll = async () => {
    const { article } = this.props;
    const finishedProduct = isFinishedProduct(article);
    const sellingPrices = this.filterStateSellingPrices();
    const timelineEntry = finishedProduct
      ? getFinishedProductTimelineEntry(T_SELLINGPRICESUPDATED)
      : getCommodityTimelineEntry(T_SELLINGPRICESUPDATED);
    const action: Action = {
      collection: finishedProduct ? FINISHEDPRODUCT : COMMODITY,
      filter: { _id: article._id },
      update: { sellingPrices: sellingPrices },
      push: { timeline: timelineEntry },
    };
    const result = await transaction([action]);
    if (result) {
      toast.success("Selling prices updated successfully");
    } else {
      toast.error("Error updating selling prices");
    }
  };

  filterStateSellingPrices = () => {
    const sellingPrices = _.clone(this.state.sellingPrices);
    for (let i = 0; i < sellingPrices.length; i++) {
      sellingPrices[i].prices = sellingPrices[i].prices.filter((p) => p.price > 0);
    }
    return sellingPrices;
  };

  getSellingPrices = (props: CommodityPageSellingPricesProps) => {
    const sellingPrices = _.cloneDeep(props.article.sellingPrices) || getDefaultSellingPrices(true);
    // Add missing prices
    SUPPORTED_SELLINGPRICE_TYPES.forEach((t) =>
      !sellingPrices.some((sP) => sP.type === t) ? sellingPrices.push(getDefaultTransportPrices(t, true)) : undefined
    );
    return sellingPrices;
  };

  render() {
    const { article } = this.props;
    const { search, sellingPrices } = this.state;
    const finishedProduct = isFinishedProduct(article);
    const filteredSellingPrices = search.trim() ? doFuseSearch(sellingPrices, search, ["type"]) : sellingPrices;
    const prices = article.sellingPrices ? article.sellingPrices.reduce((a, b) => a + b.prices.length, 0) : 0;
    return (
      <div className="card bg-white">
        <div className="card-header border-0 mt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">Selling Prices</span>
            <span className="text-muted fw-bold fs-7">{pluralize(prices, "Price")}</span>
          </h3>
          <div className="card-toolbar">
            <Search value={search} onSearch={this.handleSearch} />
          </div>
        </div>
        <div className="card-body p-4 pt-8">
          <div className="p-4 mb-7 badge badge-warning fs-7 text-black text-wrap lh-base">
            <div className="row mx-2">
              <div className="col-1">
                <i className="flaticon-danger text-black" style={{ fontSize: "50px", color: "#000000" }} />
              </div>
              <div className="col-11 mt-1">
                Selling prices are meant to set prices manually and override supplier prices. Altering them will have an
                impact on the pricing of this {finishedProduct ? "finished product" : "commodity"}. To prevent
                undergoing a certain margin they are ignored if they fall below that margin value.
              </div>
            </div>
          </div>
          <Accordion alwaysOpen={true} defaultActiveKey={filteredSellingPrices.map((tP) => tP.type)}>
            {_.orderBy(filteredSellingPrices, (p) => p.type, "asc").map((tP) => (
              <React.Fragment key={tP.type}>
                <div className="bg-light2 bg-light2-hover rounded p-5 mb-7">
                  <CustomToggle eventKey={tP.type}>
                    <div className="d-flex align-items-center ">
                      <div className="flex-grow-1 me-2">
                        <span className="fw-bolder text-gray-800 fs-6">
                          {O_TRANSPORTTYPES.find((t) => t.value === tP.type)?.label}
                        </span>
                      </div>
                    </div>
                  </CustomToggle>
                  <Accordion.Collapse eventKey={tP.type}>
                    <SellingPriceTable
                      type={tP.type}
                      article={article}
                      sellingPrices={tP.prices}
                      onRemovePrice={this.handleRemovePrice}
                      onNewPrice={this.handleNewPrice}
                      onUpdatePrice={this.handleUpdatePrice}
                      onSavePricesForType={this.handleSavePricesForType}
                      onBulkAdjust={this.handleBulkAdjust}
                      onCreateGraduation={this.handleCreateGraduation}
                      onUpdateExpiry={this.handleUpdateExpiry}
                      onRenewPrices={this.handleRenewPrices}
                    />
                  </Accordion.Collapse>
                </div>
              </React.Fragment>
            ))}
          </Accordion>
          <button className={"btn btn-sm btn-success float-right ml-2 "} onClick={this.handleSaveAll}>
            Save All
          </button>
        </div>
      </div>
    );
  }
}

interface SellingPriceTableProps {
  type: string;
  article: InternalArticleExtended;
  sellingPrices: Array<SellingPrice>;
  onNewPrice: (type: string) => void;
  onRemovePrice: (type: string, priceId: BSON.ObjectId | string) => void;
  onUpdatePrice: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    type: string,
    priceId: BSON.ObjectId | string
  ) => void;
  onSavePricesForType: (type: string) => void;
  onBulkAdjust: (type: string, value: string) => void;
  onCreateGraduation: (type: string, results: Array<{ amount: number; price: number }>) => void;
  onUpdateExpiry: (e: React.ChangeEvent<HTMLInputElement>, type: string, priceId: BSON.ObjectId | string) => void;
  onRenewPrices: (type: string, date: Date) => void;
}

const SellingPriceTable: React.FunctionComponent<SellingPriceTableProps> = ({
  type,
  article,
  sellingPrices,
  onNewPrice,
  onRemovePrice,
  onUpdatePrice,
  onSavePricesForType,
  onBulkAdjust,
  onCreateGraduation,
  onUpdateExpiry,
  onRenewPrices,
}) => {
  const [showGraduationTool, setShowGraduationTool] = useState(false);
  const [showBulkAdjust, setShowBulkAdjust] = useState(false);

  return (
    <>
      <PriceAdjustmentModal
        show={showBulkAdjust}
        onAdjust={(value) => onBulkAdjust(type, value)}
        onClose={() => setShowBulkAdjust(false)}
      />
      <PriceGraduationModal
        show={showGraduationTool}
        onClose={() => setShowGraduationTool(false)}
        unit={article.unit}
        incoterm={Incoterm.DDP}
        currency={CUSTOMER_BASE_CURRENCY}
        onSave={(results) => onCreateGraduation(type, results)}
      />
      <div className="table-responsive mt-5">
        <table className="table align-middle gs-0 gy-1 ">
          <thead>
            <tr className="fw-bolder text-muted">
              <th className="border-bottom-0" />
              <th className="border-bottom-0">MOQ</th>
              <th className="border-bottom-0">Price per {article.unit}</th>
              <th className="border-bottom-0 w-25">Expiry</th>
              <th className="border-bottom-0 text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {sellingPrices.map((price) => (
              <SellingPriceRow
                key={price._id.toString()}
                type={type}
                article={article}
                price={price}
                onUpdatePrice={onUpdatePrice}
                onRemovePrice={onRemovePrice}
                onUpdateExpiry={onUpdateExpiry}
              />
            ))}
            <tr>
              <td colSpan={4} />
              <td className="align-middle text-center">
                <button className={"btn btn-text btn-sm p-0 "} onClick={() => onNewPrice(type)}>
                  <i className="fa fa-plus text-success" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <button className="btn btn-text-success btn-sm float-right" onClick={() => onSavePricesForType(type)}>
          Save
        </button>
        <CommodityPriceRenewAllModal eventKey={type} onRenewPrices={onRenewPrices} />
        <button className="btn btn-text btn-text-muted btn-sm float-right" onClick={() => setShowBulkAdjust(true)}>
          Bulk Adjustment
        </button>
        <button className="btn btn-text btn-text-muted btn-sm float-right" onClick={() => setShowGraduationTool(true)}>
          Graduation Tool
        </button>
      </div>
    </>
  );
};

interface SellingPriceRowProps {
  type: string;
  article: InternalArticleExtended;
  price: SellingPrice;
  onUpdatePrice: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    type: string,
    priceId: BSON.ObjectId | string
  ) => void;
  onRemovePrice: (type: string, priceId: BSON.ObjectId | string) => void;
  onUpdateExpiry: (e: React.ChangeEvent<HTMLInputElement>, type: string, priceId: BSON.ObjectId | string) => void;
}

const SellingPriceRow: React.FunctionComponent<SellingPriceRowProps> = ({
  type,
  article,
  price,
  onUpdatePrice,
  onRemovePrice,
  onUpdateExpiry,
}) => {
  const context = useContext(DataContextInternal);
  const [referencePrice, setReferencePrice] = useState<number | null>(null);
  useEffect(() => {
    getCustomerOrderCalculation(
      article,
      price.minOQ || 1,
      type as CO_TYPES,
      context.currencies,
      price.currency,
      "selling"
    ).then((res) => {
      if (res && res.unitPrice && !isNaN(res.unitPrice)) setReferencePrice(res.unitPrice);
      else setReferencePrice(null);
    });
  }, [price.price, price.minOQ]);

  const getIndicatorInformation = () => {
    if (!referencePrice) return ["bg-secondary", "No information available"];
    if (referencePrice >= price.price)
      return [
        "bg-danger",
        `Price is lower than expected. Expected minimum price (including margin) for ${price.minOQ || 1}${
          article.unit
        } is ${formatCurrency(referencePrice, price.currency)}`,
      ];
    if (referencePrice < price.price)
      return [
        "bg-success",
        `Price exceeds expected minimum price (including margin) of ${formatCurrency(
          referencePrice,
          price.currency
        )} for ${price.minOQ || 1}${formatArticleUnit(article.unit)}`,
      ];
    return ["bg-secondary", "No information available"];
  };

  const [color, text] = getIndicatorInformation();

  return (
    <tr key={price._id.toString()}>
      <td className="align-middle">
        <Tooltip tooltipText={text}>
          <span className={"align-middle dot " + color}>&nbsp;</span>
        </Tooltip>
      </td>
      <td className="align-middle">
        <div className="input-group">
          <Input
            type={"number"}
            value={price.minOQ}
            className={"form-control custom-form-control pt-0 pb-0 bg-dark "}
            name={"minOQ"}
            onChange={(e) => onUpdatePrice(e, type, price._id)}
          />
          <div className="input-group-append rounded-end">
            <select
              className={"form-control custom-form-control bg-dark pt-0 pb-0 disabled"}
              disabled={true}
              value={article.unit}
              name={"unit"}
            >
              {isFinishedProduct(article)
                ? FP_UNITS.map((u) => (
                    <option key={u} value={u}>
                      {u}
                    </option>
                  ))
                : C_UNITS.map((u) => (
                    <option key={u} value={u}>
                      {u}
                    </option>
                  ))}
            </select>
          </div>
        </div>
      </td>
      <td className="align-middle">
        <div className="input-group">
          <Input
            type={"number"}
            value={price.price}
            name={"price"}
            className={"form-control custom-form-control py-0 bg-dark "}
            onChange={(e) => onUpdatePrice(e, type, price._id)}
          />
          <div className="input-group-append rounded-end">
            <select
              className={"form-control custom-form-control bg-dark py-0 " + (article.disabled && "disabled")}
              name={"currency"}
              value={price.currency}
              disabled={article.disabled}
              onChange={article.disabled ? undefined : (e) => onUpdatePrice(e, type, price._id)}
            >
              {SUPPORTED_CURRENCIES.map((c) => (
                <option key={c} value={c}>
                  {c}
                </option>
              ))}
            </select>
          </div>
        </div>
      </td>
      <td className="align-middle">
        <DateInput
          classes="form-control custom-form-control bg-dark"
          value={price.validUntil || null}
          onBlur={(e) => onUpdateExpiry(e, type, price._id)}
          name="expiry"
        />
      </td>
      <td className="align-middle text-center">
        <button className={"btn btn-text-danger btn-sm p-0 "} onClick={() => onRemovePrice(type, price._id)}>
          <i className="fa fa-trash text-danger" />
        </button>
      </td>
    </tr>
  );
};

export default CommodityPageSellingPrices;
