import React, { PureComponent } from "react";
import { CO_T_ARCHIVED, CustomerOrderExtended } from "../../../../../../model/customerOrder.types";
import { getDaysBetween } from "../../../../../../utils/dateUtils";

interface WorkflowCompletedCardProps {
  order: CustomerOrderExtended;
}

class WorkflowCompletedCard extends PureComponent<WorkflowCompletedCardProps> {
  render() {
    const { order } = this.props;
    const archivedDate = order.timeline.find((t) => t.type === CO_T_ARCHIVED)?.date;
    const orderDuration = archivedDate ? getDaysBetween(archivedDate, order.createdAt, true) : null;
    const isLate = archivedDate && archivedDate > order.targetDate;

    return (
      <div className="text-center">
        <i className="mt-4 h1 fas fa-check-circle text-success" />
        <div className="fw-bolder fs-3">Order Completed</div>
        <div className="fs-5">
          <span className="text-muted">
            within{" "}
            <span className={isLate ? "text-danger" : "text-success"}>
              {orderDuration !== null ? orderDuration : "unknown"} days
            </span>
          </span>
        </div>
      </div>
    );
  }
}

export default WorkflowCompletedCard;
