import React, { PureComponent } from "react";
import { UserData } from "../../model/userData.types";
import { getUserName } from "../../utils/userUtils";
import { toAbsoluteUrl } from "../../utils/baseUtils";

interface PersonWidgetProps {
  person: UserData;
}

class PersonWidget extends PureComponent<PersonWidgetProps> {
  render() {
    const { person } = this.props;
    return (
      <div className="symbol-group">
        <div className="symbol symbol-70px symbol-circle">
          <img
            alt="Pic"
            src={person.image ? person.image : toAbsoluteUrl("assets/media/avatars/blank.png")}
            style={{ objectFit: "cover" }}
          />
        </div>
        <div className="text-white font-weight-bolder font-size-sm ml-3">
          <span className="fs-4">{getUserName(person)}</span>
          <br />
          <span className="font-weight-bold text-white-50">{person.position}</span>
          <br />
          <span className="font-weight-bold text-white">
            <a style={{ color: "white", textDecoration: "none" }} href={"mailto:" + person.emails[0].value}>
              {person.emails[0].value}
            </a>
          </span>
          <br />
          <span className="font-weight-bold text-white">{person.phones[0] ? person.phones[0].value : "-"}</span>
        </div>
      </div>
    );
  }
}

export default PersonWidget;
