import { BSON } from "realm-web";
import { Property } from "../model/property.types";
import { callFunction } from "../services/dbService";

export enum PropertyType {
  TAG = "tag",
  COMPOSITION = "composition",
  SOLVENT = "solvent",
  ALLERGEN = "allergen",
  CATEGORY = "category",
  PACKAGING = "packaging",
  CARRIER = "carrier",
  ANALYSISMETHOD = "analysisMethod",
  EXTRACTIONSOURCE = "extractionSource",
  ODOR = "odor",
  TRANSPORTCONDITIONS = "transportConditions",
  DELIVERYINSTRUCTIONS = "deliveryInstructions",
  PURPOSEOFUSE = "purposeOfUse",
  OTHER = "other",
}

export const PROPERTYOPTIONS = [
  { value: PropertyType.TAG, label: "Tag" },
  { value: PropertyType.COMPOSITION, label: "Composition" },
  { value: PropertyType.SOLVENT, label: "Extraction Solvent" },
  { value: PropertyType.ALLERGEN, label: "Allergen" },
  { value: PropertyType.CATEGORY, label: "Category" },
  { value: PropertyType.PACKAGING, label: "Packaging" },
  { value: PropertyType.CARRIER, label: "Carrier" },
  { value: PropertyType.ANALYSISMETHOD, label: "Analysis Method" },
  { value: PropertyType.EXTRACTIONSOURCE, label: "Extraction Source" },
  { value: PropertyType.ODOR, label: "Odor" },
  { value: PropertyType.TRANSPORTCONDITIONS, label: "Transport Conditions" },
  { value: PropertyType.PURPOSEOFUSE, label: "Purpose of Use" },
  { value: PropertyType.OTHER, label: "Other" },
];

export const ADDRESSPROPERTYOPTIONS = [{ value: PropertyType.DELIVERYINSTRUCTIONS, label: "Delivery Instructions" }];
export const PROPERTYOPTIONSALL = [...PROPERTYOPTIONS, ...ADDRESSPROPERTYOPTIONS];
export const PROPERTYOPTIONSALLSELECT = [{ value: "all", label: "All" }, ...PROPERTYOPTIONSALL];

// Backend functions relating to property
export const UPSERTPROPERTY = "upsertProperty";

/**
 * Inserts a new property into the database.
 * @param property Property that should be inserted into the database
 * @returns { Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false> } Result of the function
 */
export async function insertProperty(
  property: Property
): Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false> {
  return (await callUpsertProperty(property, true)) as Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false;
}

/**
 * Updates an existing property inside the database.
 * @param property Property that should be updated inside the database
 * @returns { Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> } Result of the function
 */
export async function updateProperty(
  property: Property
): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> {
  return (await callUpsertProperty(property, false)) as Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false;
}

/**
 * Calls the upsert property function in backend.
 * @param property Property that should be upsert
 * @param insert True for insert, else update
 * @returns { Promise<false | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>> } Result of the function
 */
async function callUpsertProperty(
  property: Property,
  insert: boolean
): Promise<
  false | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>
> {
  return callFunction(UPSERTPROPERTY, [property, insert]);
}

export function resolveProperties(properties: Array<string>, propertyObjects: Array<Property>) {
  const propertiesResolved: Array<Property> = [];
  for (let i = 0; i < properties.length; i++) {
    const prop = propertyObjects.find((p) => p._id.toString() === properties[i]);
    if (prop) propertiesResolved.push(prop);
  }
  return propertiesResolved;
}
