import _ from "lodash";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { SO_T_DOCUMENTUPLOADED, SupplierOrderExtended } from "../../../../../model/supplierOrder.types";
import ErrorOverlayButton from "../../../../common/ErrorOverlayButton";
import { createPDF } from "../../../../../utils/pdfUtils";
import { Action, SUPPLIERORDER, transaction } from "../../../../../services/dbService";
import { resolveFilePath } from "../../../../../utils/fileUtils";
import { Input } from "../../../../common/Input";
import { DataContextInternalType } from "../../../../../context/dataContext";
import { Batch } from "../../../../../model/batch.types";
import CustomSelect, { SelectOption } from "../../../../common/CustomSelect";
import { createShippingMark } from "../../../../../utils/pdf/shippingMarksGenerationUtils";
import { getBatchAmount } from "../../../../../utils/batchUtils";
import DateInput from "../../../../common/DateInput";
import { Textarea } from "../../../../common/Textarea";
import { C_UNITS } from "../../../../../utils/commodityUtils";
import { getSupplierOrderTimelineEntry, SO_SHIPPINGMARK } from "../../../../../utils/supplierOrderUtils";
import userService from "../../../../../services/userService";
import { SupplierSupplierOrderExtended } from "../../../../../model/supplier/supplierSupplierOrder.types";
import { isSupplierOrder } from "../../../../../utils/orderUtils";

interface CreateShippingMarkModalProps {
  order: SupplierOrderExtended | SupplierSupplierOrderExtended;
  context: DataContextInternalType;
}

interface CreateShippingMarkModalState {
  show: boolean;
  saving: boolean;
  batchNo: string;
  batchNoSelect: SelectOption<Batch> | undefined;
  unit: SelectOption;
  netWeight: string;
  grossWeight: string;
  manufacturingDate: Date;
  expiry: Date;
  storageConditions: string;
  orderBatches: Array<Batch>;
}

class CreateShippingMarkModal extends PureComponent<CreateShippingMarkModalProps, CreateShippingMarkModalState> {
  constructor(props: CreateShippingMarkModalProps) {
    super(props);
    this.state = this.getDefaultState(props);
  }

  componentDidUpdate(prevProps: Readonly<CreateShippingMarkModalProps>) {
    const { batch: prevBatches } = prevProps.context;
    const { order: prevOrder } = prevProps;
    const { batch: batches } = this.props.context;
    const { order } = this.props;
    if (!_.isEqual(prevBatches, batches) || !_.isEqual(prevOrder, order)) {
      if (!_.isEqual(this.getOrderBatches(prevBatches, prevOrder), this.getOrderBatches(batches, order))) {
        this.setState(this.getDefaultState(this.props));
      }
    }
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState({ show: false });

  handleChangeBatchNo = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ batchNo: e.currentTarget.value });
  };

  handleChangeBatchNoSelect = (e: SelectOption<Batch>) => {
    if (!e.object) return; // Should never happen
    this.setState({
      batchNoSelect: e,
      netWeight: `${getBatchAmount(e.object)} ${e.object.unit}`,
      expiry: e.object.expiry,
    });
  };

  handleChangeUnit = (e: SelectOption) => {
    this.setState({ unit: e });
  };

  handleChangeNetWeight = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ netWeight: e.currentTarget.value });
  };

  handleChangeGrossWeight = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ grossWeight: e.currentTarget.value });
  };

  handleChangeManufacturingDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const manufacturingDate = new Date(e.target.value);
    if (!manufacturingDate) return;
    this.setState({ manufacturingDate });
  };

  handleChangeExpiry = (e: React.ChangeEvent<HTMLInputElement>) => {
    const expiry = new Date(e.target.value);
    if (!expiry) return;
    this.setState({ expiry });
  };

  handleChangeStorageConditions = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ storageConditions: e.currentTarget.value });
  };

  handleConfirm = async () => {
    const {
      batchNo,
      batchNoSelect,
      unit,
      netWeight,
      grossWeight,
      manufacturingDate,
      expiry,
      storageConditions,
      orderBatches,
    } = this.state;
    const { order } = this.props;
    this.setState({ saving: true });
    try {
      const batchNumber = orderBatches && batchNoSelect ? batchNoSelect.value : batchNo;
      const path = await createPDF(
        createShippingMark(
          order,
          orderBatches && batchNoSelect ? batchNoSelect.value : batchNo,
          netWeight,
          grossWeight,
          manufacturingDate,
          expiry,
          storageConditions,
          unit.label
        ),
        "Shipping-Mark-RB" + order.orderNo + "-" + batchNumber,
        order._id.toString(),
        {
          orientation: "landscape",
          pageSize: "A4",
          marginLeft: "5cm",
          marginRight: "5cm",
          marginTop: "2cm",
          marginBottom: "2cm",
        }
      );
      const action: Array<Action> = [];
      if (!path) {
        console.error("Shipping mark could not be created. Please try again later");
        return;
      } else {
        const fileEntry = {
          _id: new BSON.ObjectId(),
          date: new Date(),
          path,
          type: SO_SHIPPINGMARK,
          uploadedBy: userService.getUserId(),
        };
        // update state and terms in order
        action.push({
          collection: SUPPLIERORDER,
          filter: { _id: order._id },
          push: {
            files: fileEntry,
            timeline: {
              $each: [getSupplierOrderTimelineEntry(SO_T_DOCUMENTUPLOADED, { type: SO_SHIPPINGMARK })],
            },
          },
        });
        window.open(resolveFilePath(path));
      }
      const result = await transaction(action);
      if (result) {
        toast.success(`Customer order updated successfully`);
        this.setState({ show: false });
      } else toast.error(`Customer order could not be updated. Please try again later`);
    } catch (e) {
      toast.error("An error has occurred on updating the order: " + e);
    } finally {
      this.setState({ saving: false });
    }
  };

  getDefaultState = (props: CreateShippingMarkModalProps) => {
    const orderBatches = this.getOrderBatches(props.context.batch, props.order);
    const batchNoSelect =
      orderBatches.length > 0
        ? {
            value: orderBatches[0].lot,
            label: `${orderBatches[0].identifier}, Lot: ${orderBatches[0].lot}`,
            batch: orderBatches[0],
          }
        : undefined;
    const today = new Date();
    const expiryDefault = new Date(today.setFullYear(today.getFullYear() + 2));
    return {
      show: false,
      saving: false,
      batchNo: "",
      batchNoSelect,
      unit: batchNoSelect
        ? { value: batchNoSelect.batch.unit, label: batchNoSelect.batch.unit }
        : { value: "kg", label: "kg" },
      netWeight: batchNoSelect ? `${getBatchAmount(batchNoSelect.batch)}` : "",
      grossWeight: "",
      manufacturingDate: new Date(),
      expiry: batchNoSelect ? batchNoSelect.batch.expiry : expiryDefault,
      storageConditions: "Store in a well-closed container. Away from moisture, light, oxygen.",
      orderBatches,
    };
  };

  getOrderBatches = (batches: Array<Batch>, order: SupplierOrderExtended | SupplierSupplierOrderExtended) => {
    return batches.filter((b) => b.supplierOrder === order._id.toString());
  };

  validateData = () => {
    const { batchNo, orderBatches } = this.state;
    const errors = [];
    if (batchNo.trim() === "" && !(orderBatches.length > 0)) errors.push("Enter the batch number");
    return errors;
  };

  validateGenerateCondition = () => {
    const { order } = this.props;
    const errors: Array<string> = [];
    const warnings: Array<string> = [];
    if (isSupplierOrder(order) && order.customerOrders.length > 0) {
      if (order.customerOrders.every((c) => c.terms?.cleanLabel)) {
        errors.push("Customer wish for clean labels for their order");
      } else if (order.customerOrders.some((c) => c.terms?.cleanLabel)) {
        warnings.push("Some customers wish for a clean label for their order");
      }
    }
    return { errors, warnings };
  };

  render() {
    const {
      show,
      saving,
      batchNo,
      batchNoSelect,
      unit,
      netWeight,
      grossWeight,
      manufacturingDate,
      expiry,
      storageConditions,
      orderBatches,
    } = this.state;
    const orderBatchesSelect: Array<SelectOption<Batch>> = [];
    for (let i = 0; i < orderBatches.length; i++) {
      orderBatchesSelect.push({
        value: orderBatches[i].lot,
        label: `${orderBatches[i].identifier}, Lot: ${orderBatches[i].lot}`,
        object: orderBatches[i],
      });
    }
    const { errors, warnings } = this.validateGenerateCondition();

    return (
      <>
        <ErrorOverlayButton
          warnings={warnings}
          errors={errors}
          className="btn btn-sm btn-outline text-white p-0"
          onClick={this.handleShow}
        >
          Generate
        </ErrorOverlayButton>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Shipping Mark</h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="fw-bolder text-white fs-5 my-2">Supplier Batch Number</div>
            {orderBatches.length > 0 ? (
              <CustomSelect
                options={orderBatchesSelect}
                value={batchNoSelect}
                onChange={this.handleChangeBatchNoSelect}
              />
            ) : (
              <Input
                className="form-control custom-form-control"
                value={batchNo}
                placeholder="Insert Batch number of supplier"
                onChange={this.handleChangeBatchNo}
              />
            )}
            <div className="fw-bolder text-white fs-5 my-2">Unit</div>
            <CustomSelect
              options={C_UNITS.map((unit) => {
                return {
                  value: unit,
                  label: unit,
                };
              })}
              disabled={orderBatches.length > 0}
              value={unit}
              onChange={this.handleChangeUnit}
            />
            <div className="fw-bolder text-white fs-5 my-2">Net Weight</div>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                value={netWeight}
                type="number"
                placeholder="Insert Net Weight"
                onChange={this.handleChangeNetWeight}
              />
              <div className="input-group-append rounded-end">
                <div className="input-group-text form-control custom-form-control">{unit.label}</div>
              </div>
            </div>
            <div className="fw-bolder text-white fs-5 my-2">Gross Weight</div>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                value={grossWeight}
                type="number"
                placeholder="Insert Gross Weight"
                onChange={this.handleChangeGrossWeight}
              />
              <div className="input-group-append rounded-end">
                <div className="input-group-text form-control custom-form-control">{unit.label}</div>
              </div>
            </div>
            <div className="fw-bolder text-white fs-5 my-2">Manufacturing Date</div>
            <DateInput
              classes="form-control custom-form-control"
              name="manufacturingDate"
              value={manufacturingDate}
              onBlur={this.handleChangeManufacturingDate}
            />
            <div className="fw-bolder text-white fs-5 my-2">Expiry</div>
            <DateInput
              classes="form-control custom-form-control"
              name="expiry"
              value={expiry}
              onBlur={this.handleChangeExpiry}
            />
            <div className="fw-bolder text-white fs-5 my-2">Storage Conditions</div>
            <Textarea
              className="form-control custom-form-control"
              value={storageConditions}
              placeholder="Insert storage conditions"
              onChange={this.handleChangeStorageConditions}
            />
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-text text-muted" onClick={this.handleHide}>
              Close
            </button>
            <ErrorOverlayButton
              errors={this.validateData()}
              saving={saving}
              className="btn btn-light btn-sm "
              buttonText="Confirm"
              onClick={this.handleConfirm}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreateShippingMarkModal;
