import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { DataContextInternalType } from "../../../../context/dataContext";
import {
  AddressOrStringSelection,
  ForwardingOrderInfoCreation,
  getAvailableOrders,
  isBackToBackOrder,
} from "../../../../utils/forwardingOrderUtils";
import { Textarea } from "../../../common/Textarea";
import { Input } from "../../../common/Input";
import { SupplierOrderExtended } from "../../../../model/supplierOrder.types";
import { CustomerOrderExtended } from "../../../../model/customerOrder.types";
import { DateType, getOrderNumber, getOrderType, isCustomerOrder, isSupplierOrder } from "../../../../utils/orderUtils";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";
import { getSupplierOrderStartString, SO_DELIVERYTERMS } from "../../../../utils/supplierOrderUtils";
import { getLinkForOrderOrContract } from "../../../../utils/contractAndOrderUtils";
import { getContractNumber } from "../../../../utils/customerContractUtils";
import DateInput from "../../../common/DateInput";
import CalendarWeekSelector from "../../../common/CalendarWeekSelector";
import { formatAddress } from "../../../../utils/addressUtils";
import { formatDate } from "../../../../utils/baseUtils";
import { formatArticleUnit } from "../../../../utils/productArticleUtils";
import { AdditionalDateTypeLogistics } from "../../../../utils/logisticsUtils";
import { formatOrderDate } from "../../../../utils/customerOrderUtils";

interface ForwardingOrderInfoProps {
  context: DataContextInternalType;
  order: SelectOption<CustomerOrderExtended | SupplierOrderExtended>;
  orderInfo: ForwardingOrderInfoCreation;
  isFirst: boolean;
  firstOrder: SupplierOrderExtended | CustomerOrderExtended;
  allUsedOrders: Array<string>;
  returnOrder: boolean;
  onChangeNetWeight: (e: React.ChangeEvent<HTMLInputElement>, orderId: string) => void;
  onChangeGrossWeight: (e: React.ChangeEvent<HTMLInputElement>, orderId: string) => void;
  onChangeOrder: (e: SelectOption<CustomerOrderExtended | SupplierOrderExtended>, orderId: string) => void;
  onChangeCustomsCleared: (orderId: string) => void;
  onChangeDestinationIncoterm: (e: SelectOption, orderId: string) => void;
  onChangeDestinationTo: (e: React.ChangeEvent<HTMLInputElement>, orderId: string) => void;
  onChangeSenderSelect: (e: AddressOrStringSelection, orderId: string) => void;
  onChangeRecipientSelect: (e: AddressOrStringSelection, orderId: string) => void;
  onChangeRecipient: (e: React.ChangeEvent<HTMLTextAreaElement>, orderId: string) => void;
  onChangeInfoTextareaInputs: (e: React.ChangeEvent<HTMLTextAreaElement>, orderId: string) => void;
  onChangeDeliveryDate: (e: React.ChangeEvent<HTMLInputElement>, orderId: string) => void;
  onChangeDeliveryDateCW: (e: Date, orderId: string) => void;
  onChangeAsap: (orderId: string) => void;
  onChangeChecks: (orderId: string, fix: boolean) => void;
  onRemoveOrder: (orderId: string) => void;
}

interface ForwardingOrderInfoState {}

class ForwardingOrderInfo extends PureComponent<ForwardingOrderInfoProps, ForwardingOrderInfoState> {
  handleChangeNetWeight = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.props.onChangeNetWeight(e, this.props.orderInfo.orderId);
  handleChangeGrossWeight = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.props.onChangeGrossWeight(e, this.props.orderInfo.orderId);
  handleChangeOrder = (e: SelectOption<CustomerOrderExtended | SupplierOrderExtended>) =>
    this.props.onChangeOrder(e, this.props.orderInfo.orderId);
  handleChangeCustomsCleared = () => this.props.onChangeCustomsCleared(this.props.orderInfo.orderId);
  handleChangeDestinationIncoterm = (e: SelectOption) =>
    this.props.onChangeDestinationIncoterm(e, this.props.orderInfo.orderId);
  handleChangeDestinationTo = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.props.onChangeDestinationTo(e, this.props.orderInfo.orderId);
  handleChangeSenderSelect = (e: AddressOrStringSelection) =>
    this.props.onChangeSenderSelect(e, this.props.orderInfo.orderId);
  handleChangeRecipientSelect = (e: AddressOrStringSelection) =>
    this.props.onChangeRecipientSelect(e, this.props.orderInfo.orderId);
  handleChangeRecipient = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    this.props.onChangeRecipient(e, this.props.orderInfo.orderId);
  handleChangeInfoTextareaInputs = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    this.props.onChangeInfoTextareaInputs(e, this.props.orderInfo.orderId);
  handleChangeDeliveryDate = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.props.onChangeDeliveryDate(e, this.props.orderInfo.orderId);
  handleChangeDeliveryDateCW = (e: Date) => this.props.onChangeDeliveryDateCW(e, this.props.orderInfo.orderId);
  handleChangeAsap = () => this.props.onChangeAsap(this.props.orderInfo.orderId);
  handleRemoveOrder = () => this.props.onRemoveOrder(this.props.orderInfo.orderId);

  render() {
    const { order, context, orderInfo, isFirst, firstOrder, allUsedOrders, returnOrder, onChangeChecks } = this.props;
    if (!order.object) return <></>; // Should not happen
    const availableOrders = isFirst
      ? getAvailableOrders(context)
      : getAvailableOrders(context, firstOrder, allUsedOrders);
    const notesWithLineBreaks = order.object.noteInternal
      .map((n) => {
        return n.note;
      })
      .join(";\n");
    const transportConditions = order.object?.commodity.transportConditions
      ?.map((t) => {
        const condition = context.property.find((p) => p._id.toString() === t.property);
        return `${condition?.name.en || ""}: ${condition?.description.en || ""}\n`;
      })
      .join(", ");

    return (
      <div className="row mb-2">
        <div className="fw-bolder text-white fs-3 my-5">
          <Link className="fw-bolder text-white my-2 custom-link" to={getLinkForOrderOrContract(order.object)}>
            {getOrderNumber(order.object)}
          </Link>
          {` - ${isSupplierOrder(order.object) ? "Supplier Order" : "Stock Order"}`}
        </div>
        {isFirst && (
          <div className="fw-bolder text-white fs-5 my-5">
            This is the primary order. Form values are taken from this order.
          </div>
        )}
        <div className={"col-4 " + (order.object.noteInternal.length > 0 ? "modal-content-scroll" : "")}>
          <div className="bg-light2 p-4 pt-2 m-0" style={{ marginTop: "35px" }}>
            {order.object.noteInternal.length > 0 && (
              <>
                <div className="text-white fs-4 mb-4">
                  <b>Order Notes</b>
                </div>
                <div className="text-white fs-5 my-2" style={{ whiteSpace: "pre-wrap" }}>
                  {notesWithLineBreaks}
                </div>
              </>
            )}
            <br />
            <div className="text-white fs-4 mb-4">
              <b>Order Information</b>
            </div>
            {isSupplierOrder(order.object) ? (
              <div className="text-white fs-5 my-2">
                <b>Supplier: </b>
                {order.object.supplier.name || "-"}
              </div>
            ) : (
              <div className="text-white fs-5 my-2">
                <b>Customer: </b>
                {`Customer: ${order.object.company.name || "-"}`}
              </div>
            )}
            <div className="text-white fs-5 my-2">
              <b>Commodity: </b>
              {order.object.commodity.title.en + " - " + order.object.commodity.subtitle.en || "-"}
            </div>
            <div className="text-white fs-5 my-2">
              <b>Transport Conditions: </b>
              {transportConditions || "-"}
            </div>
            <div className="text-white fs-5 my-2">
              <b>Amount: </b>
              {order.object.amount + formatArticleUnit(order.object.unit) || "-"}
            </div>
            {isSupplierOrder(order.object) && (
              <div className="text-white fs-5 my-2">
                <b>Customer Orders: </b>
                {order.object.customerOrders.map((cO) => getOrderNumber(cO)).join(", ") || "-"}
              </div>
            )}
            {isSupplierOrder(order.object) && order.object.customerContracts && (
              <div className="text-white fs-5 my-2">
                <b>Customer Contracts: </b>
                {order.object.customerContracts.map((cC) => getContractNumber(cC)).join(", ") || "-"}
              </div>
            )}
            {isSupplierOrder(order.object) ? (
              <div className="text-white fs-5 my-2">
                <b>Customer Order Addresses: </b>
                {order.object.customerOrders
                  .map((cO) => {
                    return typeof cO.destination === "string"
                      ? cO.destination
                      : formatAddress(cO.destination, { withoutBreak: true, withComma: true });
                  })
                  .join(", ") || "-"}
              </div>
            ) : (
              <div className="text-white fs-5 my-2">
                <b>Customer Order Address: </b>
                {typeof order.object.destination === "string"
                  ? order.object.destination
                  : formatAddress(order.object.destination) || "-"}
              </div>
            )}
            {isSupplierOrder(order.object) && order.object.etd && (
              <div className="text-white fs-5 my-2">
                <b>ETD: </b>
                {formatDate(order.object.etd) || "-"}
              </div>
            )}
            {isBackToBackOrder(order.object) && (
              <>
                <div className="text-white fs-5 my-2">
                  <b>Original Customer ETA: </b>
                  {/* if we assume it is back to back supplier order only has one customer order*/}
                  {isSupplierOrder(order.object)
                    ? formatOrderDate(order.object.customerOrders[0], undefined, true)
                    : formatOrderDate(order.object, undefined, true)}
                </div>
                <div className="text-white fs-5 my-2">
                  <b>Changed ETA: </b>
                  {isCustomerOrder(order.object)
                    ? order.object.changedETA
                      ? formatOrderDate(order.object)
                      : "-"
                    : order.object.customerOrders[0].changedETA
                    ? formatOrderDate(order.object.customerOrders[0])
                    : "-"}
                </div>
              </>
            )}
            <div className="text-white fs-5 my-2">
              <b>Transport: </b>
              {getOrderType(order.object)}
            </div>
            <div className="text-white fs-5 my-2">
              <b>Incoterm: </b>
              {`${order.object.terms?.deliveryTerms || ""} ${
                isSupplierOrder(order.object) ? getSupplierOrderStartString(order.object) : ""
              }
          `}
            </div>
            {isSupplierOrder(order.object) && (
              <div className="text-white fs-5 my-2">
                <b>Starting Point: </b>
                {getSupplierOrderStartString(order.object) || "-"}
              </div>
            )}
            <div className="text-white fs-5 my-2">
              <b>Payment Terms: </b>
              {`${order.object.terms?.paymentTerms || ""} ${order.object.terms?.paymentTermConditions || ""}`}
            </div>
            {((isSupplierOrder(order.object) && !order.object.calculationDetails) || isCustomerOrder(order.object)) && (
              <div className="text-white fs-5 my-2">Gross weight is calculated with standard palette data! </div>
            )}
          </div>
        </div>
        <div className="col-8">
          <div className="fw-bolder text-white fs-5 my-2">Order</div>
          <CustomSelect
            options={availableOrders}
            value={order}
            matchFormControl={true}
            onChange={this.handleChangeOrder}
          />
          <div className="fw-bolder text-white fs-5 my-2">Packing</div>
          <Textarea
            className="form-control custom-form-control"
            value={orderInfo.packaging}
            rows={4}
            name="packaging"
            placeholder="Insert Packing information"
            onChange={this.handleChangeInfoTextareaInputs}
          />
          <div className="row align-items-center">
            <div className="col-6">
              <div className="fw-bolder text-white fs-5 my-2">Net Weight</div>
              <div className="input-group">
                <Input
                  className="form-control custom-form-control"
                  value={orderInfo.netWeight}
                  type="number"
                  placeholder="Insert Net Weight"
                  onChange={this.handleChangeNetWeight}
                />
                <div className="input-group-append rounded-end">
                  <div className="input-group-text form-control custom-form-control">kg</div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="fw-bolder text-white fs-5 my-2">Gross Weight</div>
              <div className="input-group">
                <Input
                  className="form-control custom-form-control"
                  value={orderInfo.grossWeight}
                  type="number"
                  placeholder="Insert Gross Weight"
                  onChange={this.handleChangeGrossWeight}
                />
                <div className="input-group-append rounded-end">
                  <div className="input-group-text form-control custom-form-control">kg</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-6">
              <div className="fw-bolder text-white fs-5 my-2">Destination Incoterm</div>
              <CustomSelect
                options={SO_DELIVERYTERMS}
                value={orderInfo.destinationIncotermSelect}
                matchFormControl={true}
                onChange={this.handleChangeDestinationIncoterm}
                placeholder={"Select destination incoterm"}
              />
            </div>
            <div className="col-6">
              <div className="fw-bolder text-white fs-5 my-2">Destination To</div>
              <Input
                className="form-control custom-form-control"
                value={orderInfo.destinationTo}
                placeholder="Insert destination to"
                onChange={this.handleChangeDestinationTo}
              />
            </div>
          </div>
          <div className="align-items-center my-2">
            <div className="form-check form-check-sm form-check-custom form-check-solid">
              <input
                className="form-check-input position-static"
                checked={orderInfo.customsCleared}
                type="checkbox"
                onChange={this.handleChangeCustomsCleared}
              />
              <label className="form-check-label fs-5">Customs Cleared</label>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-6">
              <div className="fw-bolder text-white fs-5 my-2">{returnOrder ? "Transport" : "Sender"} (Supplier)</div>
              <div className="my-2">
                <CustomSelect
                  options={orderInfo.senderSelectOptions}
                  value={orderInfo.senderSelect}
                  matchFormControl={true}
                  onChange={this.handleChangeSenderSelect}
                />
              </div>
              <Textarea
                className="form-control custom-form-control"
                value={orderInfo.sender}
                rows={8}
                name="sender"
                placeholder="Insert Address"
                onChange={this.handleChangeInfoTextareaInputs}
              />
            </div>
            <div className="col-6">
              <div className="fw-bolder text-white fs-5 my-2">
                {returnOrder ? "Sender" : "Transport"} (Customer or Warehouse)
              </div>
              <div className="my-2">
                <CustomSelect
                  options={orderInfo.recipientSelectOptions}
                  value={orderInfo.recipientSelect}
                  matchFormControl={true}
                  onChange={this.handleChangeRecipientSelect}
                />
              </div>
              <Textarea
                className="form-control custom-form-control"
                value={orderInfo.recipient}
                rows={8}
                name="recipient"
                placeholder="Insert Address"
                onChange={this.handleChangeRecipient}
              />
            </div>
          </div>
          <div className="fw-bolder text-white fs-5 my-2">Additional Recipient Information </div>
          <Textarea
            className="form-control custom-form-control"
            value={orderInfo.recipientInformation}
            name="recipientInformation"
            rows={5}
            placeholder="Insert additional recipient information"
            onChange={this.handleChangeInfoTextareaInputs}
          />
          {orderInfo.deliveryDateType !== AdditionalDateTypeLogistics.ASAP &&
            orderInfo.deliveryDateType !== DateType.CW && (
              <>
                <div className="fw-bolder text-white fs-5 my-2">Delivery Date</div>
                <DateInput
                  classes="form-control custom-form-control"
                  name="delivery"
                  min={new Date()}
                  value={orderInfo.deliveryDate ?? new Date()}
                  onBlur={this.handleChangeDeliveryDate}
                />
              </>
            )}
          {orderInfo.deliveryDateType !== AdditionalDateTypeLogistics.ASAP &&
            orderInfo.deliveryDateType === DateType.CW && (
              <>
                <div className="fw-bolder text-white fs-5 my-2">Delivery Date</div>
                <CalendarWeekSelector
                  value={orderInfo.deliveryDate}
                  onSelectCalendarWeek={this.handleChangeDeliveryDateCW}
                />
              </>
            )}
          <div className="row align-items-center">
            <div className="col-4">
              <div className="fw-bolder text-white fs-5 my-2">
                {orderInfo.deliveryDateType === AdditionalDateTypeLogistics.ASAP ? "Delivery Date" : ""}
              </div>
              <div className="form-check form-check-sm form-check-custom form-check-solid">
                <input
                  className="form-check-input position-static"
                  checked={orderInfo.deliveryDateType === AdditionalDateTypeLogistics.ASAP}
                  type="checkbox"
                  onChange={this.handleChangeAsap}
                />
                <label className="form-check-label fs-5">Asap</label>
              </div>
            </div>
            <div className="col-4">
              {orderInfo.deliveryDateType !== AdditionalDateTypeLogistics.ASAP && (
                <div className="align-items-center my-2">
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input position-static"
                      checked={orderInfo.deliveryDateType === DateType.CW}
                      type="checkbox"
                      onChange={() => onChangeChecks(orderInfo.orderId, false)}
                    />
                    <label className="form-check-label fs-5">Calendar Week</label>
                  </div>
                </div>
              )}
            </div>
            <div className="col-4">
              {orderInfo.deliveryDateType !== AdditionalDateTypeLogistics.ASAP &&
                orderInfo.deliveryDateType !== DateType.CW && (
                  <div className="align-items-center my-2">
                    <div className="form-check form-check-sm form-check-custom form-check-solid">
                      <input
                        className="form-check-input position-static"
                        checked={orderInfo.deliveryDateType === DateType.FIX}
                        type="checkbox"
                        onChange={() => onChangeChecks(orderInfo.orderId, true)}
                      />
                      <label className="form-check-label fs-5">Fixed Date</label>
                    </div>
                  </div>
                )}
            </div>
          </div>
          {allUsedOrders.length > 1 && (
            <div className="float-right my-2">
              <button className="btn btn-light btn-sm float-right my-2" onClick={this.handleRemoveOrder}>
                Remove Order
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ForwardingOrderInfo;
