import React, { PureComponent } from "react";
import CreateBatchModal from "../../../../../commodities/internal/modals/CreateBatchModal";
import OrderBatchAccordion from "../../../../../common/OrderBatchAccordion";
import { DataContextInternalType } from "../../../../../../context/dataContext";
import { SO_CANCELED, SupplierOrderExtended } from "../../../../../../model/supplierOrder.types";
import { Batch } from "../../../../../../model/batch.types";
import { getPortNameOrAddress } from "../../../../../../utils/supplierOrderUtils";
import LinkExistingBatchModal from "../modals/LinkExistingBatchModal";
import { formatArticleUnit } from "../../../../../../utils/productArticleUtils";

interface WorkflowGeneralTasksCardProps {
  order: SupplierOrderExtended;
  relatedBatches: Array<Batch>;
  done: boolean;
  context: DataContextInternalType;
}

interface WorkflowGeneralTasksCardState {}

class WorkflowGeneralTasksCard extends PureComponent<WorkflowGeneralTasksCardProps, WorkflowGeneralTasksCardState> {
  render() {
    const { order, context, done, relatedBatches } = this.props;
    const shipment = order.shipment[0];
    const editable = order.state !== SO_CANCELED;
    return (
      <div className="opacity-100-hover" style={{ opacity: done ? 0.3 : 1 }}>
        <div className="fw-bolder text-white fs-3 my-5">
          Book Delivery to Warehouse{" "}
          {done ? (
            <i className="h2 fas fa-check-circle text-success" />
          ) : (
            <span className="text-warning">[Current Task]</span>
          )}
        </div>
        <div className="py-2">
          <div className="bg-light2 rounded p-5 mb-7">
            <div className="row">
              <div className="col-6">
                <div className="fw-bolder text-white fs-5 mb-2">{order.supplier.name}</div>
              </div>
              <div className="col-6 text-right">
                <LinkExistingBatchModal
                  commodity={order.commodity}
                  supplierOrder={order}
                  disabled={!editable}
                  context={context}
                />
                <CreateBatchModal
                  article={order.commodity}
                  context={context}
                  supplierOrder={order}
                  shipment={shipment}
                  disabled={!editable}
                  buttonText="Book Batch"
                  buttonClasses="btn-success btn-sm"
                />
              </div>
              {relatedBatches && relatedBatches.length > 0 ? (
                <div className="col-8 align-self-center">
                  <div className="text-muted mb-2">{relatedBatches.map((sB) => `RB${sB.identifier}`).join(", ")}</div>
                </div>
              ) : (
                <div className="text-muted mb-2">
                  {order.amount}
                  {formatArticleUnit(order.unit, order.commodity)} from{" "}
                  {getPortNameOrAddress(shipment.shipping.startingPoint)} to{" "}
                  {shipment.shipping.destination ? getPortNameOrAddress(shipment.shipping.destination) : "TBD"}
                </div>
              )}
              <div className="col-12 mt-3">
                <OrderBatchAccordion batches={relatedBatches} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WorkflowGeneralTasksCard;
