import userService from "../services/userService";
import { getUserName } from "./userUtils";
import { getDefaultSlackChannel, sendMessage } from "../services/slackService";

export enum DataWarningType {
  MISSING_USERDATA,
}

export enum ReferenceType {
  COMPANY = "Company",
  SUPPLIER = "Supplier",
  FORWARDER = "Forwarder",
}

/**
 * Send a Slack message to the error channel that reports a data inconsistency.
 * @param type Type of warning
 * @param component Component the error occurred
 * @param reference Reference that contains the missing document together with its type
 */
export function sendInconsistentDataWarning(
  type: DataWarningType,
  component: string,
  reference: { id: string; type: ReferenceType }
) {
  let messageHeader;
  let messageContent;
  switch (type) {
    case DataWarningType.MISSING_USERDATA:
      messageHeader = "Missing Userdata Document";
      messageContent = `${reference.type} Document ${reference.id} is missing a Userdata Document inside ${component}.`;
      break;
    default:
      messageHeader = "Unknown Warning";
      messageContent = "Unknown";
  }
  const user = userService.getUserData();
  const warningMessage = `*Data Inconsistency: ${messageHeader}*\n
      COMPONENT: *${component}* at ${window.location.href}\n
      USER: *${user._id.toString()} (${getUserName(user)})*\n
      TYPE: *${user.type}*\n
      ENVIRONMENT: *${process.env.NODE_ENV}*\n
      BACKEND: *${process.env.REACT_APP_NAME || "unknown"}*\n
      VERSION: *${process.env.REACT_APP_VERSION || "unknown"} (${process.env.REACT_APP_VERSION_VILLAGE || "unknown"})*\n
      *ISSUE:* ${messageContent} \n`.replace(/  +/g, "");
  sendMessage(getDefaultSlackChannel(true), warningMessage);
}
