import React, { PureComponent } from "react";
import CustomSelect, { SelectOption } from "../common/CustomSelect";
import { I_ACTIONOPTIONS, I_DUEOPTIONS, I_SORTOPTIONS } from "../../utils/invoiceUtils";
import { SORTORDEROPTIONS } from "../../utils/filterUtils";
import Tooltip from "../common/Tooltip";
import { I_CREDIT_NOTE, I_INVOICE } from "../../model/invoice.types";

interface FinanceFilterProps {
  additionalWrapperClasses?: string;
  due?: SelectOption;
  fileType?: SelectOption;
  sortBy: SelectOption;
  sortOrder: { value: "asc" | "desc"; label: string };
  action?: SelectOption;
  disabledActionTooltip?: string;
  onChangeFileType: (dueOption: SelectOption) => void;
  onChangeDue: (dueOption: SelectOption) => void;
  onChangeSortBy: (sortOption: SelectOption) => void;
  onChangeSortOrder: (sortOrderOption: { value: "asc" | "desc"; label: string }) => void;
  onChangeAction?: (actionOption?: SelectOption) => void;
  onClickAction: () => void;
  actionDisabled: boolean;
}

class FinanceFilter extends PureComponent<FinanceFilterProps> {
  render() {
    const {
      additionalWrapperClasses,
      due,
      fileType,
      sortBy,
      sortOrder,
      action,
      actionDisabled,
      disabledActionTooltip,
      onChangeFileType,
      onChangeDue,
      onChangeSortBy,
      onChangeSortOrder,
      onChangeAction,
      onClickAction,
    } = this.props;
    return (
      <div className={"row " + (additionalWrapperClasses || "")}>
        <div className="col-12 col-md-6 col-lg-3 mb-2">
          <label className="fs-6 form-label fw-bolder text-dark">Type</label>
          <CustomSelect
            options={[
              { value: I_INVOICE, label: "Invoice" },
              { value: I_CREDIT_NOTE, label: "Credit Note" },
            ]}
            placeholder="Select a invoice type"
            onChange={onChangeFileType}
            value={fileType}
            matchFormControl={true}
          />
        </div>
        {fileType?.value !== I_CREDIT_NOTE && (
          <div className="col-12 col-md-6 col-lg-3">
            <label className="fs-6 form-label fw-bolder text-dark">Filter Due Levels</label>
            <CustomSelect
              options={I_DUEOPTIONS}
              placeholder="Select Due Level..."
              onChange={onChangeDue}
              isClearable
              value={due}
              matchFormControl={true}
            />
          </div>
        )}
        <div className="col-12 col-md-6 col-lg-3">
          <label className="fs-6 form-label fw-bolder text-dark">Sort By</label>
          <CustomSelect options={I_SORTOPTIONS} onChange={onChangeSortBy} value={sortBy} matchFormControl={true} />
        </div>
        <div className="col-12 col-md-6 col-lg-3">
          <label className="fs-6 form-label fw-bolder text-dark">Sort Order</label>
          <CustomSelect
            options={SORTORDEROPTIONS}
            onChange={onChangeSortOrder}
            value={sortOrder}
            matchFormControl={true}
          />
        </div>
        <div className="col-12 col-md-6 col-lg-3">
          <label className="fs-6 form-label fw-bolder text-dark">Export Actions</label>
          {action ? (
            <div className="w-100">
              <Tooltip
                tooltipText={disabledActionTooltip || ""}
                show={actionDisabled && disabledActionTooltip ? undefined : false}
              >
                <div>
                  <button
                    className={"btn btn-sm btn-light w-100" + (actionDisabled ? " disabled" : "")}
                    onClick={actionDisabled ? undefined : onClickAction}
                  >
                    {action.label}
                  </button>
                </div>
              </Tooltip>
              {onChangeAction && (
                <button
                  className={"btn btn-sm btn-text text-danger w-25" + (actionDisabled ? " disabled" : "")}
                  disabled={actionDisabled}
                  onClick={actionDisabled ? undefined : () => onChangeAction(undefined)}
                >
                  <i className="fa fa-times text-danger pr-0" />
                </button>
              )}
            </div>
          ) : (
            onChangeAction && (
              <CustomSelect
                options={I_ACTIONOPTIONS}
                placeholder="Select Action..."
                onChange={actionDisabled ? () => true : onChangeAction}
                disabled={actionDisabled}
                isClearable
                value={actionDisabled ? { value: "", label: "Performing Action..." } : action}
                matchFormControl={true}
              />
            )
          )}
        </div>
      </div>
    );
  }
}

export default FinanceFilter;
