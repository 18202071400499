import _ from "lodash";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import ErrorOverlayButton from "../../../../common/ErrorOverlayButton";
import {
  CO_CANCELED,
  CO_PROCESSINGATWAREHOUSE,
  CO_T_DOCUMENTUPLOADED,
  CO_T_STOCKORDERCONFIRMED,
  CustomerOrderExtended,
} from "../../../../../model/customerOrder.types";
import CustomerTerms, { CustomerTermOptions } from "../../../../common/CustomerTerms";
import {
  CO_ORDERCONFIRMATION,
  getCustomerTermOptionFromCustomerOrder,
  getCustomerOrderTimelineEntry,
} from "../../../../../utils/customerOrderUtils";
import { SelectOption } from "../../../../common/CustomSelect";
import { DataContextInternalType } from "../../../../../context/dataContext";
import { CO_FOOTER_HTML, createPDF } from "../../../../../utils/pdfUtils";
import { createOrderConfirmationHTML } from "../../../../../utils/pdf/orderConfirmationGenerationUtils";
import { STANDARDTEXTSHORT } from "../../../../../utils/pdf/templateUtils";
import { Action, CUSTOMERORDER, transaction } from "../../../../../services/dbService";
import { resolveFilePath } from "../../../../../utils/fileUtils";
import { getFormattedCOTerms, getOrderNumber } from "../../../../../utils/orderUtils";
import userService from "../../../../../services/userService";
import { CustomerCustomerOrderExtended } from "../../../../../model/customer/customerCustomerOrder.types";

interface ConfirmCustomerOrderModalProps {
  order: CustomerOrderExtended | CustomerCustomerOrderExtended;
  context: DataContextInternalType;
  stateChangeToProcessing?: boolean;
  generate?: boolean;
}

interface ConfirmCustomerOrderModalState {
  show: boolean;
  saving: boolean;
  customerOrderOption: CustomerTermOptions;
}

class ConfirmCustomerOrderModal extends PureComponent<ConfirmCustomerOrderModalProps, ConfirmCustomerOrderModalState> {
  constructor(props: ConfirmCustomerOrderModalProps) {
    super(props);
    this.state = this.getDefaultState();
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState({ show: false });

  handleChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const customerOrderOption = _.cloneDeep(this.state.customerOrderOption);
    customerOrderOption.note = e.currentTarget.value;
    this.setState({ customerOrderOption });
  };

  handleChangePaymentTerms = (e: SelectOption) => {
    const customerOrderOption = _.cloneDeep(this.state.customerOrderOption);
    customerOrderOption.paymentTerm = e;
    this.setState({ customerOrderOption });
  };

  handleChangeCustomPaymentTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
    const customerOrderOption = _.cloneDeep(this.state.customerOrderOption);
    customerOrderOption.customPaymentTerm = e.currentTarget.value;
    this.setState({ customerOrderOption });
  };

  handleChangeCustomPaymentTermConditions = (e: React.ChangeEvent<HTMLInputElement>) => {
    const customerOrderOption = _.cloneDeep(this.state.customerOrderOption);
    customerOrderOption.customPaymentTermCondition = e.currentTarget.value;
    this.setState({ customerOrderOption });
  };

  handleChangeDeliveryTerms = (e: SelectOption) => {
    const customerOrderOption = _.cloneDeep(this.state.customerOrderOption);
    customerOrderOption.deliveryTerm = e;
    this.setState({ customerOrderOption });
  };

  handleChangeDeliveryCity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const customerOrderOption = _.cloneDeep(this.state.customerOrderOption);
    customerOrderOption.deliveryCity = e.currentTarget.value;
    this.setState({ customerOrderOption });
  };

  handleConfirm = async () => {
    const { customerOrderOption } = this.state;
    const { order, stateChangeToProcessing, context } = this.props;
    this.setState({ saving: true });
    try {
      const terms = getFormattedCOTerms(customerOrderOption);
      const path = await createPDF(
        createOrderConfirmationHTML(
          order,
          STANDARDTEXTSHORT,
          context.currencies,
          terms.deliveryTerms,
          terms.paymentTerms,
          terms.paymentTermConditions,
          terms.note,
          terms.deliveryCity
        ),
        "Order-Confirmation-" + order.orderNo,
        order.company._id.toString(),
        {
          marginLeft: "2cm",
          marginBottom: "4.2cm",
          footerHtml: CO_FOOTER_HTML,
        }
      );
      const action: Array<Action> = [];
      if (!path) {
        console.error("Order confirmation could not be created. Please try again later");
      } else {
        const fileEntry = {
          _id: new BSON.ObjectId(),
          date: new Date(),
          path,
          type: CO_ORDERCONFIRMATION,
          uploadedBy: userService.getUserId(),
        };
        // update state and terms in order
        if (stateChangeToProcessing) {
          action.push({
            collection: CUSTOMERORDER,
            filter: { _id: order._id },
            update: {
              state: CO_PROCESSINGATWAREHOUSE,
              terms: terms,
            },
            push: {
              files: fileEntry,
              timeline: {
                $each: [
                  getCustomerOrderTimelineEntry(CO_T_STOCKORDERCONFIRMED),
                  getCustomerOrderTimelineEntry(CO_T_DOCUMENTUPLOADED, { type: CO_ORDERCONFIRMATION }),
                ],
              },
            },
          });
        } else {
          action.push({
            collection: CUSTOMERORDER,
            filter: { _id: order._id },
            update: {
              terms: terms,
            },
            push: {
              files: fileEntry,
              timeline: getCustomerOrderTimelineEntry(CO_T_DOCUMENTUPLOADED, { type: CO_ORDERCONFIRMATION }),
            },
          });
        }
        window.open(resolveFilePath(path));
      }
      const resultCustomOrders = await transaction(action);
      if (resultCustomOrders) {
        toast.success(`Customer order updated successfully`);
      } else toast.error(`Customer order could not be updated. Please try again later`);
    } catch (e) {
      toast.error("An error has occurred on updating the order: " + e);
    } finally {
      this.setState({ saving: false });
    }
  };

  getDefaultState = (): ConfirmCustomerOrderModalState => {
    const { order } = this.props;
    return {
      show: false,
      saving: false,
      customerOrderOption: getCustomerTermOptionFromCustomerOrder(order),
    };
  };

  validateData = () => {
    const { customerOrderOption } = this.state;
    const errors = [];
    if (
      customerOrderOption.paymentTerm.value === "custom" &&
      customerOrderOption.customPaymentTerm.trim() === "" &&
      customerOrderOption.customPaymentTermCondition.trim() === ""
    )
      errors.push("Select another payment term or enter custom values");
    return errors;
  };

  render() {
    const { generate, order } = this.props;
    const { show, saving, customerOrderOption } = this.state;
    return (
      <>
        {order.state !== CO_CANCELED &&
          (generate ? (
            <button className="btn btn-sm btn-outline text-white p-0" onClick={this.handleShow}>
              Generate
            </button>
          ) : (
            <button className="btn btn-light float-right" onClick={this.handleShow}>
              Confirm
            </button>
          ))}
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered size="lg">
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Order Confirmation</h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <CustomerTerms
              customerTerm={customerOrderOption}
              onChangePaymentTerms={this.handleChangePaymentTerms}
              onChangeCustomPaymentTerm={this.handleChangeCustomPaymentTerms}
              onChangeCustomPaymentTermCondition={this.handleChangeCustomPaymentTermConditions}
              onChangeDeliveryTerms={this.handleChangeDeliveryTerms}
              onChangeDeliveryCity={this.handleChangeDeliveryCity}
              onChangeComment={this.handleChangeNote}
            />
            {generate && (
              <div className="row text-white justify-content-center">
                Do you really want to generate an Order Confirmation for {getOrderNumber(order)}?
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-text text-muted" onClick={this.handleHide}>
              Close
            </button>
            <ErrorOverlayButton
              errors={this.validateData()}
              saving={saving}
              className="btn btn-light btn-sm "
              buttonText="Confirm"
              onClick={this.handleConfirm}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ConfirmCustomerOrderModal;
