import _ from "lodash";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { DataContextInternal } from "../../context/dataContext";
import { paginate, PaginationState } from "../common/Pagination";
import { SelectOption } from "../common/CustomSelect";
import WarehouseListingFilter from "./WarehouseListingFilter";
import {
  B_LISTING_VIEWS,
  B_STATE_OPTIONS,
  extendBatch,
  extendShipmentBatch,
  getFilteredStock,
  getPreparedStockForView,
  isShipmentBatch,
  ShipmentBatch,
  sortStock,
} from "../../utils/batchUtils";
import { B_BLOCKED, B_INCOMING, Batch } from "../../model/batch.types";
import { BatchEntry } from "../batch/BatchEntry";
import BaseListing, { BaseListingHeaderDefinition } from "../common/BaseListing";
import CreateBatchModal from "../commodities/internal/modals/CreateBatchModal";
import EditBatchModal from "../commodities/internal/modals/EditBatchModal";
import { SortColumn } from "../../utils/filterUtils";
import UpdateBatchStateModal from "../commodities/internal/modals/UpdateBatchStateModal";
import { getComponentState } from "../../utils/baseUtils";
import { getAvailableAndIncomingStock } from "../../utils/customerOrderUtils";
import { createStockListingHTML, StockListingRow } from "../../utils/pdf/stockListingGenerationUtils";
import { createPDF } from "../../utils/pdfUtils";
import { resolveFilePath } from "../../utils/fileUtils";
import { formatArticleUnit } from "../../utils/productArticleUtils";
import { isFinishedProduct } from "../../utils/finishedProductUtils";

interface WarehouseListingProps {
  context: React.ContextType<typeof DataContextInternal>;
}

interface WarehouseListingState extends PaginationState {
  search: string;
  packageType?: SelectOption;
  state?: SelectOption;
  view: SelectOption;
  warehouse?: SelectOption;
  group: "all" | "organic" | "conventional";
  generateStockListing: boolean;
  batchToEdit?: Batch;
  batchToHandle?: { batch: Batch; type: "release" | "block" };
  selectedBatchesToEdit: Array<{ batchId: string; packageId: string }>;
  stock: Array<Batch | ShipmentBatch>;
  sortColumn?: SortColumn;
}

const COMPONENT_NAME = "WarehouseListing";

class WarehouseListing extends PureComponent<WarehouseListingProps, WarehouseListingState> {
  constructor(props: WarehouseListingProps) {
    super(props);
    this.state = {
      search: "",
      view: B_LISTING_VIEWS[1],
      group: "all",
      generateStockListing: false,
      currentPage: 1,
      pageSize: 25,
      selectedBatchesToEdit: [],
      stock: [],
      state: B_STATE_OPTIONS[4],
    };
  }

  componentDidMount() {
    const state = getComponentState(this.props.context, COMPONENT_NAME);
    const stock = this.getStock();
    this.setState(state ? { stock, ...state } : { stock });
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(COMPONENT_NAME, this.state);
  }

  componentDidUpdate(prevProps: Readonly<WarehouseListingProps>, prevState: Readonly<WarehouseListingState>) {
    if (
      prevState.search !== this.state.search ||
      prevState.pageSize !== this.state.pageSize ||
      prevState.packageType !== this.state.packageType ||
      prevState.state !== this.state.state ||
      prevState.view !== this.state.view ||
      prevState.warehouse !== this.state.warehouse ||
      prevState.currentPage !== this.state.currentPage
    )
      return;
    if (!_.isEqual(this.props.context.batch, prevProps.context.batch)) {
      const stock = this.getStock();
      const newState: Pick<WarehouseListingState, "stock" | "batchToEdit" | "batchToHandle"> = { stock };
      if (this.state.batchToEdit)
        newState.batchToEdit = stock.find((b) => b._id.toString() === this.state.batchToEdit?._id.toString()) as Batch;
      if (this.state.batchToHandle)
        newState.batchToHandle = {
          batch: stock.find((b) => b._id.toString() === this.state.batchToHandle?.batch._id.toString()) as Batch,
          type: this.state.batchToHandle.type,
        };
      this.setState(newState);
    }
  }

  handlePageChange = (page: number) => this.setState({ currentPage: page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value, currentPage: 1 });
  handleSelectOptionChange = (name: string, e: SelectOption | undefined) =>
    // @ts-ignore
    this.setState({ [name]: e, currentPage: 1 });
  handleViewChange = (e: SelectOption) =>
    this.setState({ view: e, currentPage: 1, stock: this.getStock(e), state: undefined });
  handleGroupChange = (group: "all" | "organic" | "conventional") => this.setState({ group, currentPage: 1 });
  handleEditBatch = (batch: Batch) => this.setState({ batchToEdit: batch });
  handleBatchState = (batch: Batch, type: "release" | "block") => this.setState({ batchToHandle: { batch, type } });
  handleCloseModal = (success?: boolean) => {
    if (success) this.setState({ batchToEdit: undefined, selectedBatchesToEdit: [] });
    this.setState({ batchToEdit: undefined });
  };
  handleCloseStateModal = () => {
    this.setState({ batchToHandle: undefined });
  };
  handleSelectBatch = (batch: Batch) =>
    this.setState({
      selectedBatchesToEdit: this.state.selectedBatchesToEdit.concat(this.getSelectedBatchTuple(batch)),
    });
  handleDeselectBatch = (batch: Batch) => {
    const selectedBatchesToEdit = this.state.selectedBatchesToEdit.filter(
      (b) => b.batchId === batch._id.toString() && b.packageId !== batch.packages[0]._id.toString()
    );
    this.setState({ selectedBatchesToEdit });
  };
  handleSort = (column: string) => {
    const { sortColumn } = this.state;
    let newOrder: "asc" | "desc" = "desc";
    if (sortColumn && column === sortColumn.column) newOrder = sortColumn.order === "asc" ? "desc" : "asc";
    this.setState({
      sortColumn: { column, order: newOrder },
    });
  };

  handleExportStockListing = async () => {
    this.setState({ generateStockListing: true }, async () => {
      const { batch } = this.props.context;
      const articleStocks: Array<StockListingRow> = [];

      try {
        const relatedArticlesMap = new Map();
        batch
          .filter((batchItem) => ![B_BLOCKED, B_INCOMING].includes(batchItem.state))
          .forEach((filteredBatchItem) => {
            const id = filteredBatchItem.commodity._id.toString();
            const title = filteredBatchItem.commodity.title.en;
            const unit = formatArticleUnit(filteredBatchItem.commodity.unit, filteredBatchItem.commodity);

            if (!relatedArticlesMap.has(id)) {
              relatedArticlesMap.set(id, {
                id,
                title,
                unit,
                isFinishedProduct: isFinishedProduct(filteredBatchItem.commodity),
              });
            }
          });

        const relatedArticleIds: Array<{ id: string; title: string; unit: string; isFinishedProduct: boolean }> =
          Array.from(relatedArticlesMap.values());
        const stockDataPromises = relatedArticleIds.map(async (article) => {
          const availableStock = await getAvailableAndIncomingStock(article.id, true);
          return {
            id: article.id,
            title: article.title,
            availableStock: availableStock[0],
            unit: article.unit,
            isFinishedProduct: article.isFinishedProduct,
          };
        });
        const resolvedStockData = await Promise.all(stockDataPromises);
        articleStocks.push(...resolvedStockData);
        const filteredArticleStocks = articleStocks.filter((articleStock) => articleStock.availableStock > 0);

        const path = await createPDF(createStockListingHTML(filteredArticleStocks), "AvailableStockListing");
        if (path) {
          window.open(resolveFilePath(path));
        } else {
          toast.error(`PDF could not be generated`);
        }
      } catch (e) {
        toast.error("An Error occurred: " + e);
      } finally {
        this.setState({ generateStockListing: false });
      }
    });
  };

  getSelectedBatchTuple = (batch: Batch) => {
    return { batchId: batch._id.toString(), packageId: batch.packages[0]._id.toString() };
  };

  getSelectedBatchesList = (filteredStock: Array<Batch>) => {
    const { selectedBatchesToEdit } = this.state;
    return filteredStock.filter((b) =>
      selectedBatchesToEdit.some((s) => b._id.toString() === s.batchId && b.packages[0]._id.toString() === s.packageId)
    );
  };

  getFilteredStock = () => {
    const { search, packageType, state, warehouse, group, stock } = this.state;
    return getFilteredStock(stock, search, packageType, state, warehouse, group);
  };

  getStock = (viewProp?: SelectOption) => {
    const { batch: currentStock, supplierOrder } = this.props.context;
    const view = viewProp ?? this.state.view;
    return getPreparedStockForView(currentStock, view, supplierOrder);
  };

  render() {
    const { context } = this.props;
    const {
      currentPage,
      pageSize,
      search,
      state,
      packageType,
      view,
      group,
      generateStockListing,
      batchToEdit,
      batchToHandle,
      selectedBatchesToEdit,
      sortColumn,
    } = this.state;
    const headerDefinition: Array<BaseListingHeaderDefinition> = [
      { title: "Article", style: { width: "30%" }, sortColumn: "commodity" },
      { title: "Amount", className: "pr-1", sortColumn: "amount", style: { width: "12%" } },
      { title: "Status", className: "pr-1", sortColumn: "state" },
      { title: "Batch No.", className: "pr-1", sortColumn: "batch" },
      { title: "Stocked", className: "pr-1", sortColumn: "stocked" },
      { title: "Expiry", className: "pr-1", sortColumn: "left" },
      { title: "CoA", className: "pr-1" },
      { title: "Actions", className: "text-right" },
    ];
    const filteredStock = sortStock(this.getFilteredStock(), sortColumn);
    const paginatedStock = paginate(filteredStock, currentPage, pageSize);
    const single = view.value === "single";
    if (single) headerDefinition.unshift({ title: "" });
    return (
      <>
        <EditBatchModal
          batch={batchToEdit}
          single={single}
          // Every batch needs to have the same origin batch otherwise something went wrong, and we cant edit different batches
          selectedBatches={
            single && batchToEdit && selectedBatchesToEdit.every((b) => b.batchId === batchToEdit?._id.toString())
              ? this.getSelectedBatchesList(filteredStock as Array<Batch>)
              : undefined
          }
          context={context}
          onClose={this.handleCloseModal}
        />
        <UpdateBatchStateModal batchToHandle={batchToHandle} onClose={this.handleCloseStateModal} />
        <div className="content d-flex flex-column flex-column-fluid">
          <div className="post d-flex flex-column-fluid">
            <div className="container-xxl">
              <div className="card bg-white" style={{ minHeight: "100%" }}>
                <div className="card-body">
                  <h3 className="card-title align-items-start flex-column mb-15">
                    <span className="card-label fw-bolder mb-3 fs-3rem">Warehouse</span>
                    <CreateBatchModal context={context} />
                    <button
                      className="btn btn-outline btn-outline-light float-right mr-2 align-items-center"
                      onClick={this.handleExportStockListing}
                      disabled={generateStockListing}
                    >
                      Gen. Stock Listing
                      {generateStockListing && (
                        <svg
                          className="splash-spinner ml-2"
                          viewBox="0 0 50 50"
                          style={{ height: "16px", width: "16px" }}
                        >
                          <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                        </svg>
                      )}
                    </button>
                  </h3>
                  <WarehouseListingFilter
                    search={search}
                    view={view}
                    group={group}
                    state={state}
                    packageType={packageType}
                    onSearch={this.handleSearch}
                    onSelectOptionChange={this.handleSelectOptionChange}
                    onViewChange={this.handleViewChange}
                    onGroupChange={this.handleGroupChange}
                  />
                  <BaseListing
                    documents={filteredStock}
                    headerDefinition={headerDefinition}
                    bodyContent={
                      <>
                        {paginatedStock.length === 0 ? (
                          <tr>
                            <td className="text-center" colSpan={10}>
                              {single ? "No packages found" : "No batches found"}
                            </td>
                          </tr>
                        ) : (
                          paginatedStock.map((batch) => (
                            <BatchEntry
                              key={single ? batch.packages[0]._id.toString() : batch._id.toString()}
                              commodityColumn={true}
                              batch={
                                isShipmentBatch(batch)
                                  ? extendShipmentBatch(batch, context)
                                  : extendBatch(batch, context)
                              }
                              single={single}
                              // disable checkbox if entry has another batch as its origin
                              checkboxDisabled={
                                single &&
                                selectedBatchesToEdit.some((b) => b.batchId.toString() !== batch._id.toString())
                              }
                              checkboxSelected={
                                single
                                  ? selectedBatchesToEdit.some(
                                      (b) =>
                                        b.batchId === batch._id.toString() &&
                                        b.packageId === batch.packages[0]._id.toString()
                                    )
                                  : undefined
                              }
                              onEditBatch={() => this.handleEditBatch(batch as Batch)}
                              onBatchState={(type: "release" | "block") => this.handleBatchState(batch as Batch, type)}
                              onSelectBatch={single ? () => this.handleSelectBatch(batch as Batch) : undefined}
                              onDeselectBatch={single ? () => this.handleDeselectBatch(batch as Batch) : undefined}
                            />
                          ))
                        )}
                      </>
                    }
                    currentPage={currentPage}
                    pageSize={pageSize}
                    baseSize={25}
                    sortColumn={sortColumn}
                    noHover={true}
                    onPageChange={this.handlePageChange}
                    onPageSizeChange={this.handlePageSizeChange}
                    onSort={this.handleSort}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WarehouseListing;
