import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import SupplierOrderPage from "./SupplierOrderPage";
import { DataContextInternal } from "../../../../context/dataContext";

interface SupplierOrderWrapperParams {
  id: string;
}

interface SupplierOrderWrapperProps extends RouteComponentProps<SupplierOrderWrapperParams> {}

const SupplierOrderWrapper: React.FunctionComponent<SupplierOrderWrapperProps> = (props) => {
  const context = useContext(DataContextInternal);
  return <SupplierOrderPage context={context} {...props} />;
};

export default SupplierOrderWrapper;
