import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import CustomerContractPage from "./CustomerContractPage";
import { DataContextInternal } from "../../../context/dataContext";

interface CustomerContractWrapperParams {
  id: string;
}

interface CustomerContractWrapperProps extends RouteComponentProps<CustomerContractWrapperParams> {}

const CustomerContractWrapper: React.FunctionComponent<CustomerContractWrapperProps> = (props) => {
  const context = useContext(DataContextInternal);
  return <CustomerContractPage context={context} {...props} />;
};

export default CustomerContractWrapper;
