import React, { PureComponent } from "react";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import { SORTORDEROPTIONS } from "../../../utils/filterUtils";
import { FW_SORTOPTIONS, LOGISTICSFILTER, ST_SORTOPTIONS } from "../../../utils/logisticsUtils";
import { FORWARDINGORDER, STORAGEORDER } from "../../../services/dbService";
import Search from "../../common/Search";

interface LogisticsListingFilterProps {
  type: typeof FORWARDINGORDER | typeof STORAGEORDER;
  sortBy: SelectOption;
  filterBy: SelectOption;
  sortOrder: { value: "asc" | "desc"; label: string };
  onChangeFilterBy: (filterBy: SelectOption) => void;
  onChangeSortBy: (sortBy: SelectOption) => void;
  onChangeSortOrder: (sortOrder: { value: "asc" | "desc"; label: string }) => void;
  onChangeSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

class LogisticsListingFilter extends PureComponent<LogisticsListingFilterProps> {
  render() {
    const { type, sortOrder, sortBy, filterBy, onChangeSortBy, onChangeSortOrder, onChangeFilterBy, onChangeSearch } =
      this.props;
    const isFW = type === FORWARDINGORDER;
    const sortOptions = isFW ? FW_SORTOPTIONS.slice() : ST_SORTOPTIONS.slice();

    return (
      <>
        <div className="col-3 mt-6">
          <label className="fs-6 form-label fw-bolder text-dark">Filter by</label>
          <CustomSelect
            options={LOGISTICSFILTER}
            onChange={onChangeFilterBy}
            value={filterBy}
            matchFormControl={true}
          />
        </div>
        <div className="col-3 mt-6">
          <label className="fs-6 form-label fw-bolder text-dark">Sort by</label>
          <CustomSelect options={sortOptions} onChange={onChangeSortBy} value={sortBy} matchFormControl={true} />
        </div>
        <div className="col-3 mt-6">
          <label className="fs-6 form-label fw-bolder text-dark">Sort order</label>
          <CustomSelect
            options={SORTORDEROPTIONS}
            onChange={onChangeSortOrder}
            value={sortOrder}
            matchFormControl={true}
          />
        </div>
        <div className="col-3 mt-6 pt-8">
          <Search
            placeholder={`Search for ${type === FORWARDINGORDER ? "forwarding" : "storage"} orders...`}
            onSearch={onChangeSearch}
          />
        </div>
      </>
    );
  }
}

export default LogisticsListingFilter;
