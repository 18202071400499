import React, { useEffect, useState } from "react";
import { PropertyType } from "../../../utils/propertyUtils";
import { Property } from "../../../model/property.types";
import { getArticleProperty } from "../../../utils/commodityUtils";
import FavoriteStar from "../../common/FavoriteStar";
import { DataContextCustomerType } from "../../../context/dataContext";
import { PriceDevelopment } from "../../../model/statistics/commodityStatistics.types";
import { getGraphColors } from "../../../utils/commodityStatisticUtils";
import CountryWidget from "../../common/CountryWidget";
import CommodityPriceDevelopment from "./CommodityPriceDevelopment";
import { isFinishedProduct } from "../../../utils/finishedProductUtils";
import { ArticleExtended, getArticleStatistics } from "../../../utils/productArticleUtils";

interface CommodityPageSummaryProps {
  article: ArticleExtended;
  context?: DataContextCustomerType;
}

const CommodityPageSummary: React.FunctionComponent<CommodityPageSummaryProps> = React.memo(({ article, context }) => {
  const [priceDevelopment, setPriceDevelopment] = useState<PriceDevelopment | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const category = getArticleProperty(article.properties, PropertyType.CATEGORY) as Property | null;
  const composition = getArticleProperty(article.properties, PropertyType.COMPOSITION) as Property | null;
  const solvent = getArticleProperty(article.properties, PropertyType.SOLVENT) as Property | null;

  useEffect(() => {
    setLoading(true);
    getArticleStatistics(article._id, ["priceDevelopment"]).then((res) => {
      if (res && "priceDevelopment" in res) setPriceDevelopment(res.priceDevelopment);
    });
    setLoading(false);
  }, [article]);

  return (
    <div className="card mb-5 mb-xl-8 bg-white">
      <div className="card-body">
        <div className="d-flex flex-center flex-column py-5">
          {context && <FavoriteStar iconClasses="fs-3" favorite={article._id.toString()} context={context} />}
          <span className="fs-2 text-gray-800 fw-bolder mb-1 text-center">
            {article.disabled && <span className="text-danger mr-2">[DISABLED]</span>}
            {!article.approved && !article.disabled && <span className="text-warning mr-2">[PENDING]</span>}
            {article.title.en}
            {priceDevelopment && (
              <span
                className={
                  "fas ml-2 " +
                  (priceDevelopment.percentage > 1
                    ? "fa-chevron-circle-up"
                    : priceDevelopment.percentage < -1
                    ? "fa-chevron-circle-down"
                    : "fa-chevron-circle-right")
                }
                style={{
                  color: getGraphColors(priceDevelopment?.percentage)[0] || "#000000",
                }}
              />
            )}
          </span>
          {article && (
            <>
              <div className="fs-6 fw-bold text-muted mb-6">{article.subtitle.en}</div>
              <div className="mb-9">
                <div className="badge badge-lg d-inline bg-color-10">
                  HS-Code: <span>{article.hsCode}</span>
                </div>
              </div>
            </>
          )}
          <CommodityPriceDevelopment loading={loading} priceDevelopment={priceDevelopment} informationPosition="top" />
        </div>
        {article && (
          <>
            <div className="d-flex flex-stack fs-4 py-3">
              <div className="fw-bolder rotate">Commodity Details</div>
            </div>
            <div className="border-bottom-dark-gray" />
            <div>
              <div className="pb-5 fs-6">
                <div className="fw-bolder mt-5">Category</div>
                <div className="text-muted">{category ? category.name.en : "-"}</div>
                <div className="fw-bolder mt-5">Composition</div>
                <div className="text-muted">{composition ? composition.name.en : "-"}</div>
                <div className="fw-bolder mt-5">Origin</div>
                <div className="text-muted">
                  <CountryWidget countryCode={article.country.code} />
                </div>
                {!isFinishedProduct(article) && (
                  <>
                    <div className="fw-bolder mt-5">Extraction Solvent</div>
                    <div className="text-muted">{solvent ? solvent.name.en : "-"}</div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default CommodityPageSummary;
