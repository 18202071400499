import React, { PureComponent } from "react";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import {
  O_FILTEROPTIONS,
  O_SORTOPTIONS,
  O_ORDERTYPES,
  getOrdersForTab,
  getCustomerOrdersWithoutSupplierOrder,
  ORDER_TYPES,
  O_TAB_ORDERREQUIRED,
  O_TAB_ORDERCONFIRMED,
  O_TAB_ORDERPROCESSING,
  O_TAB_ORDERARCHIVED,
  O_TAB_ORDERDELIVERY,
  O_TAB_ORDERSHIPPED,
  O_TAB_ORDERED,
  O_TAB_REQUESTED,
} from "../../../utils/orderUtils";
import { SortOption, SORTORDEROPTIONS } from "../../../utils/filterUtils";
import { DataContextType } from "../../../context/dataContext";

interface OrderFilterProps {
  search: string;
  context: DataContextType;
  type: SelectOption;
  filterBy?: SelectOption;
  sortBy: SelectOption;
  sortOrder: SortOption;
  activeTab?: string;
  onChangeFilterBy?: (filterBy: SelectOption) => void;
  onChangeSortBy: (sortBy: SelectOption) => void;
  onChangeSortOrder: (sortOrder: SortOption) => void;
  onChangeOrderType: (orderType: { value: "customer" | "supplier" | "sample" | "contract"; label: string }) => void;
  onChangeActiveTab?: (activeTab: string) => void;
  filteredOrders: Array<ORDER_TYPES>;
}

class OrderFilter extends PureComponent<OrderFilterProps> {
  render() {
    const {
      search,
      type,
      activeTab,
      context,
      filteredOrders,
      filterBy,
      sortBy,
      sortOrder,
      onChangeActiveTab,
      onChangeFilterBy,
      onChangeOrderType,
      onChangeSortOrder,
      onChangeSortBy,
    } = this.props;
    let sortOptions = O_SORTOPTIONS.slice();
    if (type.value === "supplier") sortOptions = sortOptions.concat({ value: "etd", label: "ETD" });
    if (search.trim()) sortOptions.unshift({ value: "", label: "Best Match" });
    return (
      <>
        <div className="row mt-6">
          {onChangeFilterBy && (
            <div className="col-12 col-md-6 col-lg-3">
              <label className="fs-6 form-label fw-bolder text-dark">Filter</label>
              <CustomSelect
                options={O_FILTEROPTIONS}
                placeholder="Select Filter..."
                isClearable
                onChange={onChangeFilterBy}
                value={filterBy}
              />
            </div>
          )}
          <div className="col-12 col-md-6 col-lg-3">
            <label className="fs-6 form-label fw-bolder text-dark">Sort by</label>
            <CustomSelect options={sortOptions} onChange={onChangeSortBy} value={sortBy} />
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <label className="fs-6 form-label fw-bolder text-dark">Sort order</label>
            <CustomSelect options={SORTORDEROPTIONS} onChange={onChangeSortOrder} value={sortOrder} />
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <label className="fs-6 form-label fw-bolder text-dark">Order Type</label>
            <CustomSelect options={O_ORDERTYPES} onChange={onChangeOrderType} value={type} />
          </div>
        </div>
        {activeTab && onChangeActiveTab && (
          <OrderTabs
            context={context}
            type={type.value}
            activeTab={activeTab}
            onActiveTabChange={onChangeActiveTab}
            filteredOrders={filteredOrders}
          />
        )}
      </>
    );
  }
}

export default OrderFilter;

interface OrderTabsProps {
  context: DataContextType;
  type: string;
  activeTab: string;
  onActiveTabChange: (activeTab: string) => void;
  filteredOrders: Array<ORDER_TYPES>;
}

class OrderTabs extends PureComponent<OrderTabsProps> {
  /**
   * Get the tab descriptions for the selected order type.
   * @returns { Array<string> } Order tab descriptions
   */
  getTabDescriptions = (): Array<string> => {
    const { type } = this.props;
    if (type === "customer")
      return [
        "All",
        O_TAB_REQUESTED,
        O_TAB_ORDERREQUIRED,
        O_TAB_ORDERED,
        O_TAB_ORDERSHIPPED,
        O_TAB_ORDERPROCESSING,
        O_TAB_ORDERDELIVERY,
        O_TAB_ORDERARCHIVED,
      ];
    if (type === "sample") return ["All", "Sample Requested", "Request Accepted", O_TAB_ORDERARCHIVED];
    return [
      "All",
      O_TAB_ORDERREQUIRED,
      O_TAB_ORDERED,
      O_TAB_ORDERCONFIRMED,
      O_TAB_ORDERSHIPPED,
      O_TAB_ORDERPROCESSING,
      O_TAB_ORDERDELIVERY,
      O_TAB_ORDERARCHIVED,
    ];
  };

  render() {
    const { activeTab, context, filteredOrders, type, onActiveTabChange } = this.props;
    const tabClasses = "btn btn-sm fw-bolder px-4 btn-text-white nav-button mx-1 text-nowrap ";

    return (
      <div className="mt-6 nav-group nav-group-fluid bg-light no-hover">
        {this.getTabDescriptions().map((tab, idx) => {
          const supplierCreateOrder = type === "supplier" && tab === "Order Required";
          const ordersForTab = supplierCreateOrder
            ? getCustomerOrdersWithoutSupplierOrder(context).length
            : getOrdersForTab(filteredOrders, type, tab, context).length;
          return (
            <button
              className={tabClasses + (activeTab === tab ? " active" : "")}
              onClick={() => onActiveTabChange(tab)}
              key={idx}
            >
              {tab}{" "}
              <span className={supplierCreateOrder && ordersForTab > 0 ? "text-danger" : "text-success"}>
                {ordersForTab}
              </span>
            </button>
          );
        })}
      </div>
    );
  }
}
