import React, { PureComponent } from "react";
import Setting from "./Setting";

class Settings extends PureComponent {
  render() {
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="card bg-white">
              <div className="card-body">
                <h3 className="card-title align-items-start flex-column mb-15">
                  <span className="card-label fw-bolder mb-3 fs-3rem">Settings</span>
                </h3>
                <div className="row">
                  <div className="col-6 mb-6">
                    <Setting type="userData" />
                  </div>
                  <div className="col-6 mb-6">
                    <Setting type="company" />
                  </div>
                  <div className="col-6 mb-6">
                    <Setting type="notification" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;
