import React, { useContext } from "react";
import { DataContextInternal } from "../../../context/dataContext";
import NewsListing from "./NewsListing";

const NewsWrapperInternal: React.FunctionComponent = () => {
  const context = useContext(DataContextInternal);
  return <NewsListing context={context} />;
};

export default NewsWrapperInternal;
