import React, { useEffect, useState } from "react";
import { StatsWidget } from "../../common/StatsWidget";
import { SupplierExtended } from "../../../model/supplier.types";
import { getSupplierStatistics } from "../../../utils/supplierUtils";
import { SupplierStatistics } from "../../../model/statistics/supplierStatistics.types";
import { formatCurrency, formatDateTime } from "../../../utils/baseUtils";
import { getRatingColor, getStatisticText } from "../../../utils/statisticUtils";
import { BASE_CURRENCY } from "../../../utils/currencyUtils";
import { getDaysBetween } from "../../../utils/dateUtils";
import Tooltip from "../../common/Tooltip";

interface SupplierPageStatsProps {
  supplier: SupplierExtended;
}

const SupplierPageStats: React.FunctionComponent<SupplierPageStatsProps> = React.memo(({ supplier }) => {
  const [loading, setLoading] = useState(true);
  const [statistics, setStatistics] = useState<Partial<SupplierStatistics> | undefined>(undefined);

  useEffect(() => {
    getSupplierStatistics(supplier._id, ["generalStatistics"]).then((res) => {
      if (res && "generalStatistics" in res) {
        setStatistics(res);
      }
      setLoading(false);
    });
  }, []);

  const inactive = !statistics?.generalStatistics;

  return (
    <div className="card mb-5 mb-xl-8 bg-white">
      <div className="card-body pt-0">
        <div className="d-flex flex-stack fs-4 py-3 mt-5">
          <div className="fw-bolder rotate collapsible">Evaluation</div>
        </div>
        <div className="border-bottom-dark-gray" />
        <div className="py-2">
          <Tooltip
            show={inactive ? false : undefined}
            tooltipText={
              <div className="text-white text-left">
                Last Update:{" "}
                {statistics?.generalStatistics?.lastUpdate
                  ? formatDateTime(statistics?.generalStatistics?.lastUpdate)
                  : "-"}
              </div>
            }
            tooltipId="errorDescription"
          >
            <div>
              <StatsWidget
                title={"Popularity"}
                text={getStatisticText(statistics?.generalStatistics?.popularity.rating, true)}
                subtext={`Top ${statistics?.generalStatistics?.popularity.percentage || "-"}% out of ${
                  statistics?.generalStatistics?.popularity.totalSuppliers || "-"
                } Suppliers`}
                color={getRatingColor(statistics?.generalStatistics?.popularity.rating)}
                loading={loading}
                inactive={inactive}
              />
              <StatsWidget
                title={"Turnover"}
                text={getStatisticText(statistics?.generalStatistics?.turnover.rating, true)}
                subtext={`Top ${statistics?.generalStatistics?.turnover.percentage || "-"}% with ${
                  statistics?.generalStatistics?.turnover.total !== undefined
                    ? formatCurrency(statistics?.generalStatistics?.turnover.total, BASE_CURRENCY)
                    : "-"
                } total turnover`}
                color={getRatingColor(statistics?.generalStatistics?.turnover.rating)}
                loading={loading}
                inactive={inactive}
              />
              <StatsWidget
                title={"Price Gradient"}
                text={getStatisticText(statistics?.generalStatistics?.priceGradient.rating, true)}
                subtext={`${statistics?.generalStatistics?.priceGradient.prices || "-"} Prices for ${
                  statistics?.generalStatistics?.priceGradient.commodities || "-"
                } commodities`}
                color={getRatingColor(statistics?.generalStatistics?.priceGradient.rating)}
                loading={loading}
                inactive={inactive}
              />
              <StatsWidget
                title={"Price Updates"}
                text={getStatisticText(statistics?.generalStatistics?.priceUpdates.rating, true)}
                subtext={
                  statistics?.generalStatistics?.priceUpdates.lastPriceUpdate
                    ? `Updated ${Math.abs(
                        getDaysBetween(new Date(), statistics.generalStatistics.priceUpdates.lastPriceUpdate, true)
                      )} days ago`
                    : "No analysis available"
                }
                color={getRatingColor(statistics?.generalStatistics?.priceUpdates.rating)}
                loading={loading}
                inactive={inactive}
              />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
});

export default SupplierPageStats;
