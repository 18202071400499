import { BSON } from "realm-web";
import { toast } from "react-toastify";
import userService from "../services/userService";

export const FOOTER_HTML =
  process.env.NODE_ENV === "development"
    ? "https://demohub.rawbids.com/storage/specificationFooter.html" // Only for dev since localhost is not accessible. Update file via 'scp specificationFooter.html mediahub-rawbids-demo:~/work/storage/specificationFooter.html'
    : process.env.REACT_APP_BASE_URL + "/specificationFooter.html"; // load specification footer from mediahub

export const FOOTER_WITH_FINANCIAL_HTML =
  process.env.NODE_ENV === "development"
    ? "https://demohub.rawbids.com/storage/footerWithFinancialInfo.html" // Only for dev since localhost is not accessible. Update file via 'scp footerWithFinancialInfo.html mediahub-rawbids-demo:~/work/storage/footerWithFinancialInfo.html'
    : process.env.REACT_APP_BASE_URL + "/footerWithFinancialInfo.html"; // load footerWithFinancialInfo footer from mediahub

export const FOOTER_WITHOUT_FINANCIAL_HTML =
  process.env.NODE_ENV === "development"
    ? "https://demohub.rawbids.com/storage/footerWithoutFinancialInfo.html" // Only for dev since localhost is not accessible. Update file via 'scp footerWithFinancialInfo.html mediahub-rawbids-demo:~/work/storage/footerWithFinancialInfo.html'
    : process.env.REACT_APP_BASE_URL + "/footerWithoutFinancialInfo.html"; // load footerWithFinancialInfo footer from mediahub

export const PO_FOOTER_HTML =
  process.env.NODE_ENV === "development"
    ? "https://demohub.rawbids.com/storage/purchaseOrderFooter.html" // Only for dev since localhost is not accessible. Update file via 'scp purchaseOrderFooter.html mediahub-rawbids-demo:~/work/storage/purchaseOrderFooter.html'
    : process.env.REACT_APP_BASE_URL + "/purchaseOrderFooter.html"; // load purchase order footer from mediahub

export const CO_FOOTER_HTML =
  process.env.NODE_ENV === "development"
    ? "https://demohub.rawbids.com/storage/customerOrderFooter.html" // Only for dev since localhost is not accessible. Update file via 'scp customerOrderFooter.html mediahub-rawbids-demo:~/work/storage/customerOrderFooter.html'
    : process.env.REACT_APP_BASE_URL + "/customerOrderFooter.html"; // load purchase order footer from mediahub

/**
 * Uploads the given html to the server to turn it into a PDF and returns the path where it was saved.
 * @param html Document that should be transformed
 * @returns {Promise<string>} alias if processing was successful, empty string if not
 */
export async function uploadAndReturnPath(html: string): Promise<string> {
  const res = await fetch(process.env.REACT_APP_MEDIAHUB_PROCESS_PDF || "", {
    method: "POST",
    credentials: "include",
    headers: { "content-type": "application/json; charset=utf-8" },
    body: html,
  });
  // Check for successful request
  if (res && res.ok) return res.text();
  else if (res && res.status === 401)
    toast.error("Your session expired. Please log out and log in again to refresh your session.", { autoClose: false });
  return "";
}

/**
 * Create a pdf from html
 * @param html html content of pdf
 * @param fileName file name, which must not include a file extension. Additionally, an ObjectId for uniqueness is added in backend
 * @param company optional, id of company
 * @param options optional, api2pdf options
 * @returns {Promise<string | undefined>} alias of created pdf
 */
export async function createPDF(
  html: string,
  fileName: string,
  company?: string,
  options?: object
): Promise<string | undefined> {
  const dataObject: { html: string; fileName: string; company?: string; options?: object } = {
    html,
    fileName,
  };
  if (options) dataObject.options = options;
  const companyId = userService.getCompany();
  // Add company if needed
  if (company) dataObject.company = company;
  else if (companyId && companyId !== "internal" && BSON.ObjectID.isValid(companyId))
    dataObject.company = companyId.toString();
  const data = JSON.stringify(dataObject);
  let alias;
  try {
    alias = await uploadAndReturnPath(data);
    return alias;
  } catch (e) {
    toast.error("An unexpected error occurred: " + e);
  }
}
