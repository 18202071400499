import React, { PureComponent } from "react";
import { DataContextAnonymous, DataContextAnonymousType } from "../../../context/dataContext";
import CreateCustomerAccountModal from "../../userData/anonymous/CreateCustomerAccountModal";
import Tooltip from "../Tooltip";

interface AnonymousBlurTextProps {
  additionalClasses?: string;
  children: JSX.Element | string;
}

class AnonymousBlurText extends PureComponent<AnonymousBlurTextProps> {
  static contextType = DataContextAnonymous;
  context!: DataContextAnonymousType;

  handleClickOnBlur = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    e.stopPropagation();
    (this.context.refMap["CreateCustomerAccountModal"] as CreateCustomerAccountModal).handleShow();
  };

  render() {
    const { additionalClasses, children } = this.props;

    return (
      <Tooltip placement="auto" tooltipText={<span className="text-white">Register Now!</span>}>
        <span className={"blur-anonymous " + (additionalClasses ?? "")} onClick={this.handleClickOnBlur}>
          {children}
        </span>
      </Tooltip>
    );
  }
}

export default AnonymousBlurText;
