import React, { PureComponent } from "react";
import Tooltip from "./Tooltip";
import { truncateString } from "../../utils/baseUtils";

interface StringTruncateOverlayProps {
  text: string;
  length: number;
  tooltipId: string;
}

class StringTruncateOverlay extends PureComponent<StringTruncateOverlayProps> {
  render() {
    const { text, length, tooltipId } = this.props;
    return (
      <Tooltip tooltipId={tooltipId} tooltipText={text} show={text.length <= length - 3 ? false : undefined}>
        <div>{truncateString(text, length)}</div>
      </Tooltip>
    );
  }
}

export default StringTruncateOverlay;
