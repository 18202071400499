import React, { PureComponent } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DataContextInternalType } from "../../context/dataContext";
import { BatchComparisonObject, BatchExtended, BatchPackage, BatchTimeline } from "../../model/batch.types";
import { formatDate, formatDateTime, getDocFromCollection } from "../../utils/baseUtils";
import {
  B_COMPARISON_KEYS_DICT,
  BATCH_COMPARISON_KEYS,
  concatPackageInfo,
  getTimelineAmountDiff,
  T_BATCHBLOCKED,
  T_BATCHBOOKOUT,
  T_BATCHBOOKIN,
  T_BATCHCOACREATED,
  T_BATCHCREATED,
  T_BATCHDISABLE,
  T_BATCHEDIT,
  T_BATCHENABLE,
  T_BATCHPACKAGESEDIT,
  T_BATCHRELEASED,
} from "../../utils/batchUtils";
import { searchUserName } from "../../utils/userUtils";
import { formatArticleUnit } from "../../utils/productArticleUtils";

interface BatchTimelineListingProps {
  batch: BatchExtended;
  single?: boolean;
  timeline: Array<BatchTimeline>;
  context: DataContextInternalType;
}

class BatchTimelineListing extends PureComponent<BatchTimelineListingProps> {
  /**
   * Get comparison entries for general data like stocked date, expiration, lot, etc.
   * @param pre the old batch comparison object
   * @param post the new batch comparison object
   * @returns {{
   *       [key: string]:
   *         | { oldValue: string | undefined; newValue: string | undefined }
   *         | Array<{ oldValue: string | undefined; newValue: string | undefined }>;
   *     }} object with comparison keys mapped to old and new values
   */
  getGeneralComparison = (
    pre?: BatchComparisonObject,
    post?: BatchComparisonObject
  ): {
    [key: string]:
      | { oldValue: string | undefined; newValue: string | undefined }
      | Array<{ oldValue: string | undefined; newValue: string | undefined }>;
  } => {
    const { context } = this.props;
    const allEntries: {
      [key: string]:
        | { oldValue: string | undefined; newValue: string | undefined }
        | Array<{ oldValue: string | undefined; newValue: string | undefined }>;
    } = {};
    // Handle general values that always appear in pre and post
    if (pre && post)
      for (const key of BATCH_COMPARISON_KEYS) {
        if (["packages", "customerOrders"].includes(key)) continue;
        if (key in pre && key in post) {
          const valPre = pre[key];
          const valPost = post[key];
          switch (key) {
            case "supplier": {
              const supplierPre = getDocFromCollection(context.supplier, valPre as string);
              const supplierPost = getDocFromCollection(context.supplier, valPost as string);
              allEntries[key] = {
                oldValue: supplierPre?.name || "Unknown Supplier",
                newValue: supplierPost?.name || "Unknown Supplier",
              };
              break;
            }
            case "stockedDate":
            case "expiry":
              allEntries[key] = {
                oldValue: formatDate(valPre as Date),
                newValue: formatDate(valPost as Date),
              };
              break;
            default:
              allEntries[key] = {
                oldValue: typeof valPre === "number" ? (Math.round(valPre * 100) / 100).toString() : valPre?.toString(),
                newValue:
                  typeof valPost === "number" ? (Math.round(valPost * 100) / 100).toString() : valPost?.toString(),
              };
              break;
          }
        }
      }
    return allEntries;
  };

  /**
   * Get comparison entries for packages specifically
   * @param batch the batch
   * @param bPackagesPre optional, list of old packages
   * @param bPackagesPost optional, list of new packages
   * @returns {Array<{oldValue: string | undefined, newValue: string | undefined}> | null} list of old and new values or null
   */
  getPackagesComparison = (
    batch: BatchExtended,
    bPackagesPre?: Array<BatchPackage>,
    bPackagesPost?: Array<BatchPackage>
  ): Array<{ oldValue: string | undefined; newValue: string | undefined }> | null => {
    // Handle packages, added, removed or changed
    const packagesPre = bPackagesPre || [];
    const packagesPost = bPackagesPost || [];
    const packagesOnlyPre: Array<{ oldValue: string | undefined; newValue: string | undefined }> = [];
    const packagesOnlyPost: Array<{ oldValue: string | undefined; newValue: string | undefined }> = [];
    const packagesBoth: Array<{ oldValue: string | undefined; newValue: string | undefined }> = [];
    for (let i = 0; i < packagesPre.length; i++) {
      const pPre = packagesPre[i];
      const pPost = packagesPost.find((p) => p._id.toString() === pPre._id.toString());
      if (pPost)
        packagesBoth.push({ oldValue: concatPackageInfo(pPre, batch), newValue: concatPackageInfo(pPost, batch) });
      else packagesOnlyPre.push({ oldValue: concatPackageInfo(pPre, batch), newValue: undefined });
    }
    for (let i = 0; i < packagesPost.length; i++) {
      const pPost = packagesPost[i];
      if (!packagesPre.some((p) => p._id.toString() === pPost._id.toString()))
        packagesOnlyPost.push({ oldValue: undefined, newValue: concatPackageInfo(pPost, batch) });
    }
    if (packagesOnlyPre.length > 0 || packagesOnlyPost.length > 0 || packagesBoth.length > 0)
      return packagesOnlyPre.concat(packagesBoth).concat(packagesOnlyPost);

    return null;
  };

  /**
   * Get comparison entries for customer orders specifically
   * @param customerOrdersPre optional, old customer orders
   * @param customerOrdersPost optional, new customer orders
   * @returns {Array<{oldValue: string | undefined, newValue: string | undefined}> | null} list of old and new values or null
   */
  getCustomerOrderComparison = (
    customerOrdersPre?: Array<string>,
    customerOrdersPost?: Array<string>
  ): Array<{ oldValue: string | undefined; newValue: string | undefined }> | null => {
    const { context } = this.props;
    // Handle customer orders, added or removed
    const cOrdersPre = customerOrdersPre || [];
    const cOrdersPost = customerOrdersPost || [];
    const changedCOrders: Array<{ oldValue: string | undefined; newValue: string | undefined }> = [];
    for (let i = 0; i < cOrdersPre.length; i++) {
      const cOPre = cOrdersPre[i];
      if (!cOrdersPost.includes(cOPre)) {
        const order = getDocFromCollection(context.customerOrder, cOPre);
        changedCOrders.push({
          oldValue: order ? "Customer Order " + order.orderNo : "Unknown Order",
          newValue: undefined,
        });
      }
    }
    for (let i = 0; i < cOrdersPost.length; i++) {
      const cOPost = cOrdersPost[i];
      if (!cOrdersPre.includes(cOPost)) {
        const order = getDocFromCollection(context.customerOrder, cOPost);
        changedCOrders.push({
          oldValue: undefined,
          newValue: order ? "Customer Order " + order.orderNo : "Unknown Order",
        });
      }
    }
    if (changedCOrders.length > 0) return changedCOrders;
    return null;
  };

  /**
   * Get a description for the given timeline entry
   * @param entry a batch timeline entry
   * @returns {string} a description according to the timeline entry type
   */
  getTimelineEntryDescription = (entry: BatchTimeline): string => {
    const { batch } = this.props;
    let amountDiff;
    switch (entry.type) {
      case T_BATCHCREATED:
        return "Batch was created";
      case T_BATCHENABLE:
        return "Batch was enabled";
      case T_BATCHDISABLE:
        return "Batch was disabled";
      case T_BATCHEDIT:
        return "General batch information was edited";
      case T_BATCHPACKAGESEDIT:
        amountDiff = getTimelineAmountDiff(entry);
        return (
          "Packages were adjusted. " +
          (amountDiff === 0
            ? "The total amount did not change"
            : amountDiff > 0
            ? `${amountDiff} ${formatArticleUnit(batch.unit)} were removed in total`
            : `${Math.abs(amountDiff)} ${formatArticleUnit(batch.unit)} were added in total`)
        );
      case T_BATCHBOOKOUT:
        amountDiff = getTimelineAmountDiff(entry);
        return `${amountDiff} ${formatArticleUnit(batch.unit)} were booked out`;
      case T_BATCHBOOKIN:
        amountDiff = getTimelineAmountDiff(entry); // calculates preAmount - postAmount, so amountDiff will be negative when booking back
        return `${amountDiff * -1} ${formatArticleUnit(batch.unit)} were booked in`; // * -1 to display the value correctly (negative value means something went wrong at rebooking)
      case T_BATCHRELEASED:
        return `Batch was released`;
      case T_BATCHBLOCKED:
        return `Batch was blocked. Reason: ${entry.note || "-"}`;
      case T_BATCHCOACREATED:
        return `Certificate of Analysis was created`;
      default:
        return "Unknown entry";
    }
  };

  /**
   * Render a tooltip displaying the differences occurring in the timeline entry
   * @param entry a batch timeline entry
   * @returns {JSX.Element} a tooltip displaying the differences of the timeline entry
   */
  renderTooltip = (entry: BatchTimeline): JSX.Element => {
    const { batch } = this.props;
    // Collect data
    const allEntries: {
      [key: string]:
        | { oldValue: string | undefined; newValue: string | undefined }
        | Array<{ oldValue: string | undefined; newValue: string | undefined }>;
    } = this.getGeneralComparison(entry.pre, entry.post);

    const packagesEntries = this.getPackagesComparison(batch, entry.pre?.packages, entry.post?.packages);
    if (packagesEntries && packagesEntries.length > 0) allEntries["packages"] = packagesEntries;

    const customerOrderEntries = this.getCustomerOrderComparison(entry.pre?.customerOrders, entry.post?.customerOrders);
    if (customerOrderEntries && customerOrderEntries.length > 0) allEntries["customerOrders"] = customerOrderEntries;

    const comparisonList = Object.entries(allEntries);
    // Render Tooltip
    return (
      <Tooltip id={"timelineComparison"} className="custom-tooltip custom-tooltip-large">
        <div className="pt-2">
          {comparisonList.length > 0 ? (
            comparisonList.map(([key, values], idx) => (
              <React.Fragment key={key}>
                <div className="row py-3 mx-5" style={{ minWidth: "300px" }}>
                  <div className="col-3 font-size-lg text-white my-auto">
                    {B_COMPARISON_KEYS_DICT[key as keyof BatchComparisonObject]}
                  </div>
                  <div className="col-9 font-size-lg font-weight-bolder">
                    {Array.isArray(values)
                      ? values.map((v) => this.renderBasicDiffBody(v.oldValue, v.newValue))
                      : this.renderBasicDiffBody(values.oldValue, values.newValue)}
                  </div>
                </div>
                {idx < comparisonList.length - 1 && <div className="separator" />}
              </React.Fragment>
            ))
          ) : (
            <div className="text-muted p-3">No changes</div>
          )}
        </div>
      </Tooltip>
    );
  };

  /**
   * Renders the body of a basic string diff
   * @param oldValue old value as string
   * @param newValue new value as string
   * @returns {JSX.Element} React element displaying the diff
   */
  renderBasicDiffBody = (oldValue?: string, newValue?: string) => {
    return (
      <div className="row no-gutters">
        <div className="col my-auto pr-2">
          <span className="text-white text-break">{oldValue}</span>
        </div>
        {(!!oldValue && !!newValue && (
          <div className="col-1 my-auto">
            <i className="icon-lg fas fa-arrow-right text-warning align-middle" />
          </div>
        )) ||
          (!!oldValue && !newValue && (
            <div className="col-1 my-auto">
              <i className="icon-lg fas fa-minus text-danger align-middle" />
            </div>
          )) ||
          (!oldValue && !!newValue && (
            <div className="col-1 my-auto">
              <i className="icon-lg fas fas fa-plus text-success align-middle" />
            </div>
          ))}
        <div className="col my-auto pl-2">
          <span className="text-white text-break">{newValue}</span>
        </div>
      </div>
    );
  };

  render() {
    const { single, timeline, context } = this.props;
    if (timeline.length === 0)
      return (
        <tr>
          <td colSpan={10} className="text-center">
            No history available yet
          </td>
        </tr>
      );
    return timeline
      .slice()
      .reverse()
      .map((t) => (
        <tr key={t._id.toString()}>
          <td colSpan={single ? 2 : 1}>
            <div className="text-muted">
              {formatDateTime(t.date)} - {searchUserName(t.person, context.userData)}
            </div>
          </td>
          <td className="text-muted" colSpan={10}>
            <OverlayTrigger show={t.pre || t.post ? undefined : false} overlay={this.renderTooltip(t)}>
              <span className="text-white">
                {this.getTimelineEntryDescription(t)} {(t.pre || t.post) && <i className="fa fa-info-circle" />}
              </span>
            </OverlayTrigger>
          </td>
        </tr>
      ));
  }
}

export default BatchTimelineListing;
