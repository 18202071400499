import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import Helmet from "react-helmet";
import Routes from "./routes/Routes";
import { AppDesign } from "../utils/baseUtils";
require("./" + (process.env.REACT_APP_STYLE || ""));

function App() {
  const useArkDesign = process.env.REACT_APP_DESIGN === AppDesign.ARK;

  return (
    <>
      <Helmet>
        <title>{useArkDesign ? "Ark Ingredients" : "Rawbids"}</title>
        <link rel="icon" href={useArkDesign ? "/arkFavicon.ico" : "/rawbidsFavicon.ico"} />
      </Helmet>
      <BrowserRouter>
        <ToastContainer
          position="top-right"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          transition={Slide}
          theme={"dark"}
        />
        <Routes />
      </BrowserRouter>
    </>
  );
}

export default App;
