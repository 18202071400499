import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { SupplierOrderExtended } from "../../../../model/supplierOrder.types";
import { CustomerOrder } from "../../../../model/customerOrder.types";
import { CustomerContract } from "../../../../model/customerContract.types";
import {
  getAmountForOrderOrContract,
  getCommodityPriceForOrderOrContract,
  getIdentifierForOrderOrContract,
  getLinkForOrderOrContract,
} from "../../../../utils/contractAndOrderUtils";
import { formatArticleUnit } from "../../../../utils/productArticleUtils";
import { DataContextInternalType } from "../../../../context/dataContext";
import { getDocFromCollection } from "../../../../utils/baseUtils";
import { Company } from "../../../../model/company.types";

interface SupplierOrderDestinationsProps {
  order: SupplierOrderExtended;
  context: DataContextInternalType;
}

class SupplierOrderDestinations extends PureComponent<SupplierOrderDestinationsProps> {
  render() {
    const { order, context } = this.props;
    let allDocuments: Array<CustomerOrder | CustomerContract> = order.customerOrders.slice();
    if (order.customerContracts) allDocuments = allDocuments.concat(order.customerContracts);
    return (
      <>
        {allDocuments.length > 0 && (
          <div>
            <div className="card mb-5 mb-xl-8 bg-white">
              <div className="card-body">
                <div className="d-flex flex-stack fs-4 py-3">
                  <div className="fw-bolder rotate collapsible">Destinations</div>
                </div>
                <div className="border-bottom-dark-gray" />
                <div className="pb-0 pt-7 fs-6">
                  {allDocuments.map((doc) => {
                    const company = getDocFromCollection(context.company, doc.company) as Company;
                    return (
                      <div
                        key={doc._id.toString()}
                        className="d-flex align-items-center bg-light rounded p-5 mb-7 no-hover"
                      >
                        <div className="flex-grow-1 me-2">
                          <Link
                            to={"/customer/" + company._id.toString()}
                            className="text-white custom-link fw-bolder fs-6 flex-center flex-stack d-inline-flex"
                          >
                            {company.name}
                          </Link>
                          <span className="text-muted fw-bold d-block">
                            as{" "}
                            <Link to={getLinkForOrderOrContract(doc)} className="text-muted custom-link">
                              {getIdentifierForOrderOrContract(doc)}
                            </Link>
                          </span>
                        </div>
                        <div className="text-right">
                          <div className="fw-bold fs-6 text-gray-400">{getAmountForOrderOrContract(doc)}</div>
                          <span className="text-muted mb-1">{getCommodityPriceForOrderOrContract(doc)}</span>
                        </div>
                      </div>
                    );
                  })}
                  {order.warehouseAmount > 0 && (
                    <div className="d-flex align-items-center bg-light rounded p-5 mb-7 no-hover">
                      <div className="flex-grow-1 me-2">
                        <span className="fw-bolder text-gray-800 fs-6 flex-center flex-stack d-inline-flex">
                          Warehouse
                        </span>
                      </div>
                      <div className="text-right">
                        <div className="fw-bold fs-6 text-gray-400">
                          {order.warehouseAmount} {formatArticleUnit(order.unit)}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default SupplierOrderDestinations;
