import React, { PureComponent } from "react";
import { Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CustomerOrder } from "../../../model/customerOrder.types";
import { CC_STATE, CustomerContractExtended } from "../../../model/customerContract.types";
import { TabDefinition } from "../../common/CustomTypes";
import { DataContextInternalType } from "../../../context/dataContext";
import OrderNotes from "../../orders/common/OrderNotes";
import OrderDocuments from "../../orders/common/OrderDocuments";
import OrderInvoices from "../../orders/common/OrderInvoices";
import OrderHistory from "../../orders/common/OrderHistory";
import CustomerContractOverview from "./tabPanels/CustomerContractOverview";
import CreateCustomerContractCallModal from "../common/CreateCustomerContractCallModal";
import CustomerContractReadyModal from "./modals/CustomerContractReadyModal";
import { allCallsArchived } from "../../../utils/contractUtils";
import SimpleConfirmationModal from "../../common/SimpleConfirmationModal";
import { isContractInactive } from "../../../utils/customerContractUtils";

interface CustomerContractTabPanelProps {
  contract: CustomerContractExtended;
  context: DataContextInternalType;
  onCloseContract: () => Promise<void>;
  calls?: Array<CustomerOrder>;
}

interface CustomerContractTabPanelState {
  currentTab: string;
}

class CustomerContractTabPanel extends PureComponent<CustomerContractTabPanelProps, CustomerContractTabPanelState> {
  constructor(props: CustomerContractTabPanelProps) {
    super(props);
    this.state = { currentTab: this.getTabDefinitions()[0].name };
  }

  componentDidUpdate(prevProps: Readonly<CustomerContractTabPanelProps>) {
    if (prevProps.contract._id.toString() !== this.props.contract._id.toString()) {
      this.setState({ currentTab: this.getTabDefinitions()[0].name });
    }
  }

  getTabDefinitions = (): Array<TabDefinition> => {
    const { contract, context, calls } = this.props;

    return [
      {
        name: "overview",
        tabContent: "Overview",
        component: <CustomerContractOverview contract={contract} calls={calls} />,
      },
      {
        name: "documents",
        tabContent: "Documents",
        component: <OrderDocuments order={contract} context={context} />,
      },
      {
        name: "invoices",
        tabContent: "Invoices",
        component: <OrderInvoices order={contract} context={context} />,
      },
      {
        name: "notes",
        tabContent: "Notes",
        component: <OrderNotes order={contract} context={context} />,
      },
      {
        name: "history",
        tabContent: "History",
        component: <OrderHistory order={contract} context={context} />,
      },
    ];
  };

  /**
   * Handle tab changes
   * @param eventKey new tab
   */
  handleTab = (eventKey: string | null) => {
    if (eventKey && eventKey !== this.state.currentTab) this.setState({ currentTab: eventKey });
  };

  render() {
    const { contract, context, onCloseContract } = this.props;
    const { currentTab } = this.state;
    const tabs = this.getTabDefinitions();
    const readyForClose =
      contract.contractInformation.restAmount === 0 &&
      allCallsArchived(context, contract) &&
      !isContractInactive(contract);
    return (
      <Tab.Container id="customerContractTabs" activeKey={currentTab} onSelect={this.handleTab}>
        <div className="flex-lg-row-fluid ms-lg-15">
          <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
            {tabs.map((t) => (
              <li key={t.name + "_link"} className="nav-item cursor-pointer">
                <Nav.Link
                  role="tab"
                  id={t.name}
                  eventKey={t.name}
                  active={currentTab === t.name}
                  className="text-active-white pb-4 "
                >
                  {t.tabContent}
                </Nav.Link>
              </li>
            ))}
            {readyForClose ? (
              <SimpleConfirmationModal.SimpleConfirmationModalButton
                onConfirm={onCloseContract}
                confirmButtonText="Confirm"
                cancelButtonText="Close"
                buttonText="Close Contract"
                buttonClasses={"btn btn-light ml-auto"}
                modalTitle="Close Contract"
                modalDescription={<span className="text-white">Do you really want to close this Contract?</span>}
              />
            ) : (
              contract.state !== CC_STATE.CANCELED &&
              contract.state !== CC_STATE.CLOSED && (
                <CreateCustomerContractCallModal contract={contract} context={context} />
              )
            )}
            {contract.state === CC_STATE.OPEN && <CustomerContractReadyModal contract={contract} />}
            {contract.state === CC_STATE.OPEN && (
              <li className="nav-item ml-2">
                <Link to={`/createSupplierOrder/contract/${contract._id.toString()}`} className="btn btn-light">
                  Order
                </Link>
              </li>
            )}
          </ul>
          <Tab.Content>
            {tabs.map((t) => (
              <Tab.Pane id={t.name} key={t.name} eventKey={t.name}>
                {t.component}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </div>
      </Tab.Container>
    );
  }
}

export default CustomerContractTabPanel;
