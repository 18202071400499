import React, { PureComponent } from "react";
import { BSON } from "realm-web";
import { SupplierExtended } from "../../../model/supplier.types";
import { Input } from "../../common/Input";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import CompanyCountryWidget from "../../common/CompanyCountryWidget";
import { DataContextInternalType } from "../../../context/dataContext";
import { getAirportName, getStartingAirports } from "../../../utils/airportUtils";
import { getSeaportName, getStartingSeaports } from "../../../utils/seaportUtils";
import { SUPPORTED_CURRENCIES } from "../../../utils/currencyUtils";
import ReactStarsWrapper from "../../common/ReactStarsWrapper";
import { getPaymentTargetDescription, I_PAYMENTTARGETS_WITH_CUSTOM_OPTION } from "../../../utils/invoiceUtils";
import { formatAddress } from "../../../utils/addressUtils";
import AddressInputGroup from "../../common/AddressInputGroup";
import { ADDRESS_TYPE_OPTIONS, AddressType } from "../../../model/commonTypes";
import { getPaymentTargetSelectOption } from "../../../utils/logisticsUtils";

interface SupplierPageGeneralInformationBlockProps {
  supplier: SupplierExtended;
  context: DataContextInternalType;
  referencedSeaports: Array<SelectOption>;
  referencedAirports: Array<SelectOption>;
  edit: boolean;
  customPaymentTarget: boolean;
  onEditSupplierInput: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field?: string) => void;
  onEditSupplierSelect: (name: string, e: SelectOption) => void;
  onEditSupplierPaymentTargetSelect: (e: SelectOption) => void;
  onAddAddress: () => void;
  onDeleteAddress: (id: BSON.ObjectId) => void;
  onEditSupplierSelectCountry: (e: SelectOption, idx: number) => void;
  onEditSupplierSelectContact: (e: SelectOption, idx: number) => void;
  onEditRating: (rating: number) => void;
  onEditPreparationTime: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEditCustomPaymentTarget: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEditCustomPaymentTargetConditions: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEditReferences: (e: Array<SelectOption>, type: "seaport" | "airport") => void;
  onToggleEUSupplier: () => void;
}

class SupplierPageGeneralInformationBlock extends PureComponent<SupplierPageGeneralInformationBlockProps> {
  handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, idx: number) => {
    const field = "address[" + idx + "]." + e.target.name;
    this.props.onEditSupplierInput(e, field);
  };

  render() {
    const {
      supplier,
      context,
      referencedSeaports,
      referencedAirports,
      edit,
      customPaymentTarget,
      onEditSupplierInput,
      onAddAddress,
      onDeleteAddress,
      onEditSupplierSelectCountry,
      onEditSupplierSelectContact,
      onEditRating,
      onEditSupplierSelect,
      onEditSupplierPaymentTargetSelect,
      onEditPreparationTime,
      onEditCustomPaymentTarget,
      onEditCustomPaymentTargetConditions,
      onEditReferences,
      onToggleEUSupplier,
    } = this.props;
    const { seaport, airport } = context;
    const paymentTarget = getPaymentTargetSelectOption(supplier, customPaymentTarget);

    return (
      <div className="fs-6">
        {edit ? (
          <div className="row py-5">
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">Name</label>
              <Input
                type="text"
                value={supplier.name}
                name={"name"}
                onChange={onEditSupplierInput}
                className="form-control custom-form-control"
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">General Mail</label>
              <Input
                type="text"
                value={supplier.mail}
                name={"mail"}
                onChange={onEditSupplierInput}
                className="form-control custom-form-control"
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">VAT-Number</label>
              <Input
                type="text"
                value={supplier.vat}
                name={"vat"}
                onChange={onEditSupplierInput}
                className="form-control custom-form-control"
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">General Phone</label>
              <Input
                type="text"
                value={supplier.phone}
                name={"phone"}
                onChange={onEditSupplierInput}
                className="form-control custom-form-control"
              />
            </div>
            <div className="col-md-12 mt-2">
              {supplier.address.map((a, idx) => (
                <React.Fragment key={a._id.toString()}>
                  <div className="mt-6 border-bottom-dark-gray" />
                  <label className="fs-6 fw-bold mt-4">Address {idx + 1}</label>
                  <div className={"mt-" + (idx === 0 ? "1" : "4")}>
                    <AddressInputGroup
                      address={a}
                      context={context}
                      persons={[supplier.primaryPerson, ...supplier.persons]}
                      onChangeAddress={(e) => this.handleChangeAddress(e, idx)}
                      onChangeAddressType={(e: SelectOption) => onEditSupplierSelect("address[" + idx + "].type", e)}
                      onChangeAddressCountry={(e) => onEditSupplierSelectCountry(e, idx)}
                      onChangeAddressContact={(e: SelectOption) => onEditSupplierSelectContact(e, idx)}
                      onDeleteAddress={idx === 0 ? undefined : onDeleteAddress}
                      onChangeOpeningHours={(e) => this.handleChangeAddress(e, idx)}
                    />
                  </div>
                </React.Fragment>
              ))}
              <button className="btn btn-sm float-right pr-0" onClick={onAddAddress}>
                <i className="fa fa-plus text-success p-0" />
              </button>
            </div>
            <div className="mt-2 border-bottom-dark-gray" />
            <div className="col-6 col-md-3 mt-6">
              <label className="fs-6 fw-bold mb-1">EU Supplier</label>
              <div className="form-check form-check-sm form-check-custom form-check-solid">
                <Input
                  type="checkbox"
                  className="form-check-input"
                  checked={supplier.euSupplier}
                  onClick={onToggleEUSupplier}
                />
              </div>
            </div>
            <div className="col-6 col-md-3 mt-6">
              <label className="fs-6 fw-bold mb-1">Rating</label>
              <ReactStarsWrapper value={supplier.rating} count={5} size={14} onChange={onEditRating} />
            </div>
            <div className="col-md-6 mt-6">
              <label className="fs-6 fw-bold mb-1">Currency</label>
              <CustomSelect
                options={SUPPORTED_CURRENCIES.map((c) => {
                  return { value: c, label: c };
                })}
                value={{ value: supplier.currency, label: supplier.currency }}
                onChange={(e: SelectOption) => onEditSupplierSelect("currency", e)}
                matchFormControl={true}
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">Payment Term</label>
              <CustomSelect
                options={I_PAYMENTTARGETS_WITH_CUSTOM_OPTION}
                value={paymentTarget}
                onChange={(e: SelectOption) => onEditSupplierPaymentTargetSelect(e)}
                matchFormControl={true}
              />
              {customPaymentTarget && (
                <>
                  <label className="fs-6 fw-bold mb-1 mt-2">Payment Target</label>
                  <div className="input-group">
                    <Input
                      type="number"
                      value={
                        supplier.paymentTerms && supplier.paymentTerms.paymentTarget !== ""
                          ? Number(supplier.paymentTerms.paymentTarget.replace(" days", "").trim())
                          : ""
                      }
                      name="customPaymentTarget"
                      onChange={onEditCustomPaymentTarget}
                      className="form-control custom-form-control"
                    />
                    <div className="input-group-append">
                      <span className="input-group-text form-control custom-form-control">days</span>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">Additional Payment Conditions</label>
              <Input
                type="text"
                value={
                  supplier.paymentTerms?.paymentTargetConditions ? supplier.paymentTerms?.paymentTargetConditions : ""
                }
                name="customPaymentTargetConditions"
                onChange={onEditCustomPaymentTargetConditions}
                className="form-control custom-form-control"
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">Notes</label>
              <Input
                type="textarea"
                value={supplier.notes}
                name={"notes"}
                onChange={onEditSupplierInput}
                className="form-control custom-form-control"
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">Preparation Time (in days)</label>
              <Input
                type="number"
                value={supplier.transport.preparationTime}
                name="preparationTime"
                onChange={onEditPreparationTime}
                className="form-control custom-form-control"
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">Seaports</label>
              <CustomSelect
                options={getStartingSeaports(seaport).map((sp) => {
                  return {
                    value: sp._id.toString(),
                    label: getSeaportName(sp),
                  };
                })}
                value={referencedSeaports}
                onChange={(e: Array<SelectOption>) => onEditReferences(e, "seaport")}
                isMulti={true}
                isClearable={true}
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">Airports</label>
              <CustomSelect
                options={getStartingAirports(airport).map((ap) => {
                  return {
                    value: ap._id.toString(),
                    label: getAirportName(ap),
                  };
                })}
                value={referencedAirports}
                onChange={(e: Array<SelectOption>) => onEditReferences(e, "airport")}
                isMulti={true}
                isClearable={true}
              />
            </div>
          </div>
        ) : (
          <div className="d-flex flex-wrap py-5">
            <table className="table fw-bold gy-1">
              <tbody>
                <tr>
                  <td className="text-white w-25 min-w-125px">Name</td>
                  <td className="text-muted w-25">{supplier.name}</td>
                  <td className="text-white w-25 min-w-125px">General Mail</td>
                  <td className="text-muted w-25">{supplier.mail}</td>
                </tr>
                <tr>
                  <td className="text-white w-25 min-w-125px">VAT Number</td>
                  <td className="text-muted w-25">{supplier.vat}</td>
                  <td className="text-white w-25 min-w-125px">General Phone</td>
                  <td className="text-muted w-25">{supplier.phone}</td>
                </tr>
                {supplier.address.map((a) => {
                  const addrType = ADDRESS_TYPE_OPTIONS.find((at) => at.value === a.type)?.label;
                  const addrTypeLabel = `${addrType && a.type !== AddressType.A_OTHER ? addrType + " " : ""}Address`;
                  const contactPerson = context.userData.find((user) => user._id.toString() === a.contactPerson);
                  return (
                    <React.Fragment key={a._id.toString()}>
                      <tr>
                        <td className="text-white w-25 max-w-125px">{addrTypeLabel}</td>
                        <td className="text-muted w-25 display-linebreak">
                          {formatAddress(a, { withoutCountry: true })}
                        </td>
                        <td className="text-white w-25 min-w-125px">Country</td>
                        <td className="text-muted w-25">
                          <CompanyCountryWidget company={supplier} address={a} />
                        </td>
                      </tr>
                      <tr>
                        <td className="text-white w-25 max-w-125px">Contact Person</td>
                        <td className="text-muted w-25 display-linebreak">
                          {contactPerson ? `${contactPerson.prename} ${contactPerson.surname}` : "-"}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
                <tr>
                  <td className="text-white w-25 min-w-125px">EU Supplier</td>
                  <td className="text-muted w-25">{supplier.euSupplier ? "Yes" : "No"}</td>
                  <td className="text-white w-25 min-w-125px">Rating</td>
                  <td className="w-25">
                    <ReactStarsWrapper value={supplier.rating} count={5} size={14} edit={false} />
                  </td>
                </tr>
                <tr>
                  <td className="text-white w-25 min-w-125px">Currency</td>
                  <td className="text-muted w-25">{supplier.currency}</td>
                  <td className="text-white w-25 min-w-125px">Payment Target</td>
                  <td className="text-muted w-25">
                    {supplier.paymentTerms && supplier.paymentTerms.paymentTarget !== ""
                      ? getPaymentTargetDescription(supplier.paymentTerms.paymentTarget)
                      : "not specified"}
                  </td>
                </tr>
                {supplier.paymentTerms?.paymentTargetConditions && (
                  <tr>
                    <td className="text-white w-25 min-w-125px" colSpan={2}></td>
                    <td className="text-white w-25 min-w-125px">Payment Conditions</td>
                    <td className="text-muted w-25">{supplier.paymentTerms.paymentTargetConditions}</td>
                  </tr>
                )}
                <tr>
                  <td className="text-white w-25 min-w-125px">Notes</td>
                  <td className="text-muted w-25">{supplier.notes}</td>
                  <td className="text-white w-25 min-w-125px">Preparation Time</td>
                  <td className="text-muted w-25">{supplier.transport?.preparationTime} day(s)</td>
                </tr>
                <tr>
                  <td className="text-white w-25 min-w-125px">Seaport</td>
                  <td className="text-muted w-25 list-no-bullets">
                    {seaport.map((sp) => {
                      if (supplier.seaportReferences && supplier.seaportReferences.includes(sp._id.toString())) {
                        return <li key={sp._id.toString()}>{getSeaportName(sp)}</li>;
                      }
                    })}
                  </td>
                  <td className="text-white w-25 min-w-125px">Airports</td>
                  <td className="text-muted w-25 list-no-bullets">
                    {airport.map((ap) => {
                      if (supplier.airportReferences && supplier.airportReferences.includes(ap._id.toString())) {
                        return <li key={ap._id.toString()}>{getAirportName(ap)}</li>;
                      }
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

export default SupplierPageGeneralInformationBlock;
