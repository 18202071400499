import React, { useContext } from "react";
import { DataContextInternal } from "../../../context/dataContext";
import CustomerListing from "./CustomerListing";

interface CustomerListingWrapperProps {}

const CustomerListingWrapper: React.FunctionComponent<CustomerListingWrapperProps> = (props) => {
  const context = useContext(DataContextInternal);

  return <CustomerListing {...props} context={context} />;
};

export default CustomerListingWrapper;
