import { BSON } from "realm-web";
import { callFunction } from "../services/dbService";
import { General } from "../model/general.types";

export enum EditableValue {
  CUSTOMERORDERMARGIN = "customerOrderMargin",
  WAREHOUSESURCHARGE = "warehouseSurcharge",
  DELIVERYTIMEFASTEST = "deliveryTimeFastest",
  DELIVERYTIMECHEAPEST = "deliveryTimeCheapest",
  TRANSPORTCOSTCHEAP = "transportCostCheap",
  WAREHOUSEPROCESSINGTIME = "warehouseProcessingTime",
  TRANSPORTCOSTFAST = "transportCostFast",
  CUSTOMERORDERBUFFER = "customerOrderBuffer",
  CUSTOMSCOST = "customsCost",
  SUPPLIERORDERBUFFER = "supplierOrderBuffer",
  SUPPLIERORDERPREPARATIONTIME = "supplierOrderPreparationTime",
}

export const LOT = "lot";
export const CUSTOMERORDERMARGIN = "customerOrderMargin";
export const EXPENSES = "expenses";

// Functions
const UPSERTGENERAL = "upsertGeneral";
const GETRAWBIDSVERSION = "getRawbidsVersion";
const UPDATERAWBIDSVERSION = "updateRawbidsVersion";

/**
 * Inserts a new document into the general collection.
 * @param general Value that should be added
 * @returns {Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>>} Result of the insert
 */
export async function insertGeneral(general: General): Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>> {
  return (await upsertGeneral(general, true)) as Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>;
}

/**
 * Updates a general value.
 * @param general Value that should be updated
 * @returns {Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>>} Result of the update
 */
export async function updateGeneral(general: General): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>> {
  return (await upsertGeneral(general, false)) as Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>;
}

/**
 * Upsert a general value.
 * @param general Value that should be upserted
 * @param insert Flag, indicated if insert or update
 * @returns {Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>>} Result of the upsert
 */
async function upsertGeneral(
  general: General,
  insert: boolean
): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>> {
  return callFunction<
    Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>
  >(UPSERTGENERAL, [general, insert]);
}

/**
 * Retrieve the Rawbids version number from backend.
 * @returns {Promise<string>} Version number as string
 */
export async function getRawbidsVersion(): Promise<string> {
  return callFunction<string>(GETRAWBIDSVERSION, []);
}

/**
 * Update the Rawbids version number in the backend.
 * @param version New version number
 * @returns {Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>>} Result of the call
 */
export async function updateRawbidsVersion(
  version: string
): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>> {
  return callFunction(UPDATERAWBIDSVERSION, [version]);
}

/**
 * Retrieves the general value that is referenced by the given key or undefined if there was no value found.
 * @param generalCollection Collection of general values
 * @param key Key of the value
 * @returns {string | number | Object | undefined} Value of undefined if not found
 */
export function getGeneralValue(generalCollection: Array<General>, key: string): string | number | object | undefined {
  return generalCollection.find((g) => g.key === key)?.value;
}
