import React from "react";
import { SortOrder } from "../../utils/filterUtils";

interface SortIconProps {
  sortOrder: SortOrder;
  onToggleSortOrder: () => void;
}

const SortIcon: React.FunctionComponent<SortIconProps> = ({ sortOrder, onToggleSortOrder }) => {
  return (
    <i
      onClick={onToggleSortOrder}
      className={"icon-light " + (sortOrder === SortOrder.DESC ? "fa fa-sort-numeric-up" : "fa fa-sort-numeric-down")}
    />
  );
};

export default SortIcon;
