import React, { PureComponent } from "react";
import { Nav, Tab } from "react-bootstrap";
import { SupplierExtended } from "../../../model/supplier.types";
import { DataContextInternalType } from "../../../context/dataContext";
import { TabDefinition } from "../../common/CustomTypes";
import SupplierPageGeneral from "./tabPanels/SupplierPageGeneral";
import SupplierPageOrders from "./tabPanels/SupplierPageOrders";
import SupplierPagePrices from "./tabPanels/SupplierPagePrices";
import SupplierPageDocuments from "./tabPanels/SupplierPageDocuments";
import SupplierPageHistory from "./tabPanels/SupplierPageHistory";
import SupplierPageEUStock from "./tabPanels/SupplierPageEUStock";

interface SupplierPageTabPanelProps {
  supplier: SupplierExtended;
  corSelected?: string;
  context: DataContextInternalType;
}

interface SupplierPageTabPanelState {
  currentTab: string;
}

class SupplierPageTabPanel extends PureComponent<SupplierPageTabPanelProps, SupplierPageTabPanelState> {
  constructor(props: SupplierPageTabPanelProps) {
    super(props);
    this.state = { currentTab: this.getDefaultTab() };
  }

  getDefaultTab = () => {
    const { supplier, context, corSelected } = this.props;
    const hasOrders = context.supplierOrder.some((o) => o.supplier === supplier._id.toString());
    if (!hasOrders) return "general";
    if (corSelected) return this.getTabDefinitions()[1].name;
    return this.getTabDefinitions()[0].name;
  };

  getTabDefinitions = (): Array<TabDefinition> => {
    const { supplier, context, corSelected } = this.props;
    return [
      {
        name: "orders",
        tabContent: "Orders",
        component: <SupplierPageOrders supplier={supplier} context={context} />,
      },
      {
        name: "prices",
        tabContent: "Commodities",
        component: (
          <SupplierPagePrices supplier={supplier} corSelected={corSelected} context={context} finishedProduct={false} />
        ),
      },
      {
        name: "pricesFinishedProducts",
        tabContent: "Finished Products",
        component: (
          <SupplierPagePrices supplier={supplier} corSelected={corSelected} context={context} finishedProduct={true} />
        ),
      },
      {
        name: "documents",
        tabContent: "Documents",
        component: <SupplierPageDocuments supplier={supplier} context={context} />,
      },
      {
        name: "euStock",
        tabContent: "EU Stock",
        component: (
          <>
            <SupplierPageEUStock supplier={supplier} context={context} finishedProduct={false} />
            <div className="mb-7" />
            <SupplierPageEUStock supplier={supplier} context={context} finishedProduct={true} />
          </>
        ),
      },
      {
        name: "general",
        tabContent: "General Settings",
        component: <SupplierPageGeneral supplier={supplier} context={context} />,
      },
      {
        name: "history",
        tabContent: "History",
        component: <SupplierPageHistory supplier={supplier} context={context} />,
      },
    ];
  };

  /**
   * Handle tab changes
   * @param eventKey new tab
   */
  handleTab = (eventKey: string | null) => {
    if (eventKey && eventKey !== this.state.currentTab) this.setState({ currentTab: eventKey });
  };

  render() {
    const { supplier } = this.props;
    const { currentTab } = this.state;
    const tabs = this.getTabDefinitions();
    return (
      <Tab.Container id="supplierTabs" activeKey={currentTab} onSelect={this.handleTab}>
        <div className="flex-lg-row-fluid ms-lg-15">
          <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
            {tabs.map(
              (t) =>
                (!t.condition || t.condition(supplier)) && (
                  <li key={t.name + "_link"} className="nav-item cursor-pointer">
                    <Nav.Link
                      role="tab"
                      id={t.name}
                      eventKey={t.name}
                      active={currentTab === t.name}
                      className="text-active-white pb-4 "
                    >
                      {t.tabContent}
                    </Nav.Link>
                  </li>
                )
            )}
          </ul>
          <Tab.Content>
            {tabs.map(
              (t) =>
                (!t.condition || t.condition(supplier)) && (
                  <Tab.Pane id={t.name} key={t.name} eventKey={t.name}>
                    {t.component}
                  </Tab.Pane>
                )
            )}
          </Tab.Content>
        </div>
      </Tab.Container>
    );
  }
}
export default SupplierPageTabPanel;
