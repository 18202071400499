import _ from "lodash";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { Airport } from "../../../../model/airport.types";
import { paginate, PaginationState } from "../../../common/Pagination";
import { doFuseSearch, formatCurrency, getComponentState, getCountryNameForCode } from "../../../../utils/baseUtils";
import Search from "../../../common/Search";
import BaseListing from "../../../common/BaseListing";
import CreateAirportModal from "./CreateAirportModal";
import SimpleConfirmationModal from "../../../common/SimpleConfirmationModal";
import { updateAirport } from "../../../../utils/airportUtils";
import { BASE_CURRENCY } from "../../../../utils/currencyUtils";
import { DataContextInternal } from "../../../../context/dataContext";

interface AirportListingProps {
  context: React.ContextType<typeof DataContextInternal>;
}

interface AirportListingState extends PaginationState {
  search: string;
}

const COMPONENT_NAME = "AirportListing";

class AirportListing extends PureComponent<AirportListingProps, AirportListingState> {
  constructor(props: AirportListingProps) {
    super(props);
    this.state = {
      search: "",
      currentPage: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {
    const state = getComponentState(this.props.context, COMPONENT_NAME);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(COMPONENT_NAME, this.state);
  }

  handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ search: e.target.value, currentPage: 1 });

  handleDisableAirport = async (airport: Airport) => {
    const result = await updateAirport({ disabled: !airport.disabled }, airport._id);
    if (result) toast.success(`Airport successfully ${airport.disabled ? "disabled" : "enabled"}`);
    else toast.error(`Error ${airport.disabled ? "disabling" : "enabling"} airport. Please try again later`);
  };

  getFilteredAirports = () => {
    const { airport } = this.props.context;
    const { search } = this.state;
    if (!search.trim()) return _.orderBy(airport, "iata", "asc");
    return doFuseSearch(airport, search, ["name", "iata", "icao", "country"]);
  };

  render() {
    const { currentPage, pageSize } = this.state;
    const filteredAirports = this.getFilteredAirports();
    const paginatedAirports = paginate(filteredAirports, currentPage, pageSize);

    const headerDefinition = [
      { title: "Name", style: { width: "25%" } },
      { title: "IATA - ICAO", style: { width: "15%" } },
      { title: "Country", style: { width: "20%" } },
      { title: "Cost", style: { width: "10%" } },
      { title: "YTY Quantity", className: "text-nowrap", style: { width: "15%" } },
      { title: "Action", className: "text-right", style: { width: "15%" } },
    ];
    return (
      <>
        <div className="d-flex align-items-center my-5">
          <div className="w-50">
            <Search
              onSearch={this.handleChangeSearch}
              placeholder="Search..."
              inputClasses="form-control custom-form-control"
            />
          </div>
        </div>
        <BaseListing
          headerDefinition={headerDefinition}
          documents={filteredAirports}
          noHover={true}
          bodyContent={
            <>
              {paginatedAirports.map((ap) => (
                <tr key={ap._id.toString()}>
                  <td className="text-white align-middle">{ap.name}</td>
                  <td className="text-white align-middle">
                    {ap.iata} - {ap.icao}
                  </td>
                  <td className="text-white align-middle">{getCountryNameForCode(ap.country)}</td>
                  <td className="text-white align-middle">
                    {ap.cost ? formatCurrency(ap.cost, ap?.currency || BASE_CURRENCY) : "-"}
                  </td>
                  <td className="text-white align-middle">
                    <div className="fs-6">
                      <div className="text-muted">N/A</div>
                    </div>
                  </td>
                  <td className="text-white align-middle text-right">
                    <SimpleConfirmationModal.SimpleConfirmationModalButton
                      size="md"
                      modalTitle={`${ap.disabled ? "Enable" : "Disable"} Airport ${ap.iata} - ${ap.icao}`}
                      onConfirm={() => this.handleDisableAirport(ap)}
                      confirmButtonText={ap.disabled ? "Enable" : "Disable"}
                      buttonText={ap.disabled ? "Enable" : "Disable"}
                      buttonClasses={"btn btn-text " + (ap.disabled ? "btn-text-success" : "btn-text-danger")}
                      cancelButtonText="Close"
                      modalDescription={
                        <span className="text-white">
                          <h4 className="fw-bolder text-white text-left mt-10 mb-15">
                            Do you really want to {ap.disabled ? "enable" : "disable"} airport{" "}
                            <em>
                              {ap.name} ({ap.iata} - {ap.icao})
                            </em>
                            ?
                          </h4>
                          {ap.disabled ? (
                            <span className="fw-bolder d-flex align-items-center text-white text-left">
                              After being enabled the airport can be used inside the system again.
                            </span>
                          ) : (
                            <>
                              <div className="fw-bolder d-flex align-items-center text-white text-left">
                                After being disabled the airport can not be used anymore. If the airport is in use in
                                older orders it will still be shown there.
                              </div>
                              <div className="fw-bolder d-flex align-items-center text-white text-left">
                                You can re-enable the airport any time via clicking on "Enable" on the airport overview.
                              </div>
                            </>
                          )}
                        </span>
                      }
                    />
                    <CreateAirportModal airport={ap} additionalButtonClasses="btn-sm ml-2" />
                  </td>
                </tr>
              ))}
            </>
          }
          currentPage={currentPage}
          pageSize={pageSize}
          baseSize={25}
          onPageChange={(currentPage) => this.setState({ currentPage })}
          onPageSizeChange={(pageSize) => this.setState({ pageSize, currentPage: 1 })}
        />
        )
      </>
    );
  }
}

export default AirportListing;
