import { BSON } from "realm-web";
import { SupplierOrder, SupplierOrderExtended } from "../model/supplierOrder.types";
import { round } from "./baseUtils";
import { OrderFile } from "../model/commonTypes";

export enum ControllingDashboardFinanceDashboardGraphType {
  CD_FDG_WRITTENTURNOVER = "cdFdgWrittenTurnover",
  CD_FDG_WRITTENMARGIN = "cdFdgWrittenMargin",
  CD_FDG_BOOKTURNOVER = "cdFdgBookTurnover",
  CD_FDG_BOOKMARGIN = "cdFdgBookMargin",
  CD_FDG_WAREHOUSE = "cdFdgWarehouse",
  CD_FDG_EXPENSES = "cdFdgExpenses",
}

export enum ControllingDashboardStatisticCardType {
  CD_SC_ORDERS = "cdScOrders",
  CD_SC_REQUESTS = "cdScRequests",
  CD_SC_CONTRACTS = "cdScContracts",
  CD_SC_CUSTOMERS = "cdScCustomers",
  CD_SC_SUPPLIERS = "cdScSuppliers",
  CD_SC_COMMODITIES = "cdScCommodities",
}

export interface PositionType {
  _id: BSON.ObjectId;
  description: string;
  value: number;
  document?: OrderFile;
  isEditing: boolean;
}

/**
 * Retrieve the calculation delta between calculated and adjusted value.
 * @param supplierOrder SupplierOrder whose delta should be calculated
 * @returns {number} Delta of calculated and adjusted value
 */
export function getCalculationDelta(supplierOrder: SupplierOrder | SupplierOrderExtended): number {
  let calculatedValue = 0;
  let adjustedValue = 0;

  if (supplierOrder.calculationDetails?.adjustedCalculation) {
    const cd = supplierOrder.calculationDetails;
    const ac = cd.adjustedCalculation;
    if (ac?.totalTransportationCost) {
      calculatedValue += supplierOrder.priceTransport;
      adjustedValue += ac.totalTransportationCost.reduce((sum, aV) => sum + aV.value, 0);
    }
    if (ac?.totalInsuranceCost) {
      calculatedValue +=
        "totalInsuranceCost" in cd.details.finalValues ? cd.details.finalValues.totalInsuranceCost || 0 : 0;
      adjustedValue += ac.totalInsuranceCost.reduce((sum, aV) => sum + aV.value, 0);
    }
    if (ac?.totalCustomsCost) {
      calculatedValue += supplierOrder.priceCustoms;
      adjustedValue += ac.totalCustomsCost.reduce((sum, aV) => sum + aV.value, 0);
    }
    if (ac?.totalWarehouseCost) {
      calculatedValue += cd.details.finalValues.totalWarehouseCost || 0;
      adjustedValue += ac.totalWarehouseCost.reduce((sum, aV) => sum + aV.value, 0);
    }
    if (ac?.totalFollowUpCost) {
      calculatedValue += cd.details.finalValues.totalB2bFollowUpCost;
      adjustedValue += ac.totalFollowUpCost.reduce((sum, aV) => sum + aV.value, 0);
    }
  }

  return adjustedValue ? round(1 - calculatedValue / adjustedValue, 2) : 0;
}
