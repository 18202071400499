import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContextInternal } from "../../../context/dataContext";
import CommodityPage from "./CommodityPage";

interface CommodityWrapperProps extends RouteComponentProps<CommodityParams> {}

interface CommodityParams {
  id: string;
}

const CommodityWrapper: React.FunctionComponent<CommodityWrapperProps> = (props) => {
  const context = useContext(DataContextInternal);
  return <CommodityPage context={context} {...props} />;
};
export default CommodityWrapper;
