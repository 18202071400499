import _ from "lodash";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { Property } from "../../../../model/property.types";
import { updateProperty } from "../../../../utils/propertyUtils";

interface DisablePropertyModalProps {
  property: Property;
}

interface DisablePropertyModalState {
  show: boolean;
}

class DisablePropertyModal extends PureComponent<DisablePropertyModalProps, DisablePropertyModalState> {
  constructor(props: DisablePropertyModalProps) {
    super(props);
    this.state = { show: false };
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState({ show: false });

  /**
   * Disables the property.
   */
  handleClickDisable = async () => {
    const property = _.cloneDeep(this.props.property);
    property.disabled = !property.disabled;
    const result = await updateProperty(property);
    if (result) {
      toast.success("Property " + (property.disabled ? "disabled" : "enabled") + " successfully");
      this.handleHide();
    } else {
      toast.error("Error " + (property.disabled ? "disabling" : "enabling") + " property");
    }
  };

  render() {
    const { property } = this.props;
    const { show } = this.state;
    const enable = property.disabled;

    return (
      <>
        <button
          className={"btn btn-text " + (enable ? "btn-text-success" : "btn-text-danger")}
          onClick={this.handleShow}
        >
          {enable ? "Enable" : "Disable"}
        </button>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">
                {enable ? "Enable" : "Disable"} {property.name.en}
              </h1>
            </Modal.Title>
            <CloseButton variant={"white"} onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <h2 className="fw-bolder text-white text-center">
              Do you really want to {enable ? "enable" : "disable"} {property.name.en}?
            </h2>
            {enable ? (
              <span className="fw-bolder d-flex align-items-center text-white text-center">
                After being enabled the property can be used inside the system again.
              </span>
            ) : (
              <>
                <span className="fw-bolder d-flex align-items-center text-white text-center">
                  After being disabled the property can not be used inside the system anymore.
                </span>
                <br />
                <span className="fw-bolder d-flex align-items-center text-white text-center">
                  If the property is already used in commodities the property will still be shown there.
                </span>
                <br />
                <span className="fw-bolder d-flex align-items-center text-white text-center">
                  You can re-enable the property any time via clicking on "Enable" on the property overview.
                </span>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-outline btn-text-danger" onClick={this.handleHide}>
              Cancel
            </button>
            <button className="btn btn-sm btn-outline btn-outline-light" onClick={this.handleClickDisable}>
              {enable ? "Enable" : "Disable"}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default DisablePropertyModal;
