import _ from "lodash";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BSON } from "realm-web";
import { CommodityExtended } from "../../../../model/commodity.types";
import { DataContextInternalType } from "../../../../context/dataContext";
import CommodityPageGeneralInformationBlock from "../CommodityPageGeneralInformationBlock";
import { Property } from "../../../../model/property.types";
import { getNumericValue } from "../../../../utils/baseUtils";
import { PROPERTYOPTIONS, PropertyType } from "../../../../utils/propertyUtils";
import PropertiesWidget from "../../../common/PropertiesWidget";
import PropertiesSelection from "../../../common/PropertiesSelection";
import {
  C_G_TYPES,
  COMMODITYCREATIONKEYS,
  generateSubtitle,
  getCommodityEditTimelineEntry,
  getCommodityTimelineEntry,
  insertCommodity,
  T_COMMODITYAPPROVED,
  T_COMMODITYCREATED,
  T_COMMODITYDISABLED,
  T_COMMODITYENABLED,
  updateCommodity,
  P_DRUMS,
} from "../../../../utils/commodityUtils";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import CommodityPageAdvancedInformationBlock from "../CommodityPageAdvancedInformationBlock";
import CreatePropertyModal from "../../../properties/internal/modals/CreatePropertyModal";
import CommodityActiveSubstanceListing from "../../common/CommodityActiveSubstanceListing";
import CommodityActiveSubstanceEditListing from "../CommodityActiveSubstanceEditListing";
import { ActiveSubstance } from "../../../../model/activeSubstance.types";
import CommoditySampleSizes from "../CommoditySampleSizes";
import { SelectOption } from "../../../common/CustomSelect";
import CloneCommodityModal from "../modals/CloneCommodityModal";
import Search from "../../../common/Search";
import MasterSpecification from "../../../specification/internal/MasterSpecification";
import { COMMODITY } from "../../../../services/dbService";
import { TC_TEMPERATURE } from "../../../../utils/finishedProductUtils";
import { TRANSPORTCONDITIONTYPE } from "../../../../model/finishedProduct.types";
import { TransportConditionSelect } from "../../../finishedProducts/internal/FinishedProductPageGeneralInformationBlock";
import { reduceCommodity } from "../../../../utils/dataTransformationUtils";

interface CommodityPageGeneralProps extends RouteComponentProps {
  commodity: CommodityExtended;
  context: DataContextInternalType;
}

interface CommodityPageGeneralState {
  edit: boolean;
  create: boolean;
  commodityEdit: CommodityExtended;
  showPropertyCreationModal: boolean;
  propertyCreationType?: PropertyType;
  search: string;
  saving: boolean;
}

class CommodityPageGeneral extends PureComponent<CommodityPageGeneralProps, CommodityPageGeneralState> {
  constructor(props: CommodityPageGeneralProps) {
    super(props);
    const create = !!new URLSearchParams(props.location.search).get("create");
    const clone = !!new URLSearchParams(props.location.search).get("clone");

    this.state = {
      edit: create || clone ? true : !props.commodity,
      create: create || clone,
      commodityEdit: _.cloneDeep(props.commodity),
      showPropertyCreationModal: false,
      search: "",
      saving: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<CommodityPageGeneralProps>) {
    const create = !!new URLSearchParams(this.props.location.search).get("create");
    const clone = !!new URLSearchParams(this.props.location.search).get("clone");
    if (clone && !this.state.create && !this.state.edit) {
      this.setState({ create: true, edit: true });
    }
    if (!_.isEqual(prevProps.commodity, this.props.commodity)) {
      const commodity = _.cloneDeep(this.props.commodity);
      this.setState({
        commodityEdit: commodity,
        edit: create || clone ? true : !this.props.commodity,
        create: create || clone,
      });
    }
  }

  handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });

  handleEditToggle = () => this.setState({ edit: !this.state.edit, commodityEdit: _.cloneDeep(this.props.commodity) });

  handleCloneCommodity = () => {
    this.props.history.push(`/commodity/${this.state.commodityEdit._id.toString()}?clone=true`);
  };

  handleCancelCreate = () => {
    const clone = !!new URLSearchParams(this.props.location.search).get("clone");
    if (clone) {
      this.props.history.goBack();
    } else {
      this.props.history.push("/articles");
    }
  };

  handleEditCommodityInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const commodity = _.cloneDeep(this.state.commodityEdit);
    _.set(commodity, e.target.name, e.target.type === "number" ? +e.target.value : e.target.value);
    this.setState({ commodityEdit: commodity });
  };

  handleEditCommodityColor = (color: string, min: boolean) => {
    const commodity = _.cloneDeep(this.state.commodityEdit);
    if (min) commodity.color.min = color;
    else commodity.color.max = color;
    this.setState({ commodityEdit: commodity });
  };

  handleEditCommodityDensity = (e: React.ChangeEvent<HTMLInputElement>, min: boolean) => {
    const commodity = _.cloneDeep(this.state.commodityEdit);
    const numericValue = getNumericValue(e);
    if (!numericValue) return;
    if (min) commodity.density.min = +numericValue;
    else commodity.density.max = +numericValue;
    this.setState({ commodityEdit: commodity });
  };

  handleEditCommoditySelectExclusiveProperty = (e: SelectOption<Property>, key: PropertyType) => {
    const commodity = _.cloneDeep(this.state.commodityEdit);
    const { showPropertyCreationModal } = this.state;
    if (e && e.value === "new") {
      this.setState({ showPropertyCreationModal: true, propertyCreationType: key });
    } else {
      if (!e.object) {
        commodity.properties = commodity.properties.filter((p) => p.type !== key);
      } else {
        const index = commodity.properties.findIndex((p) => p.type === key);
        if (index > -1) commodity.properties.splice(index, 1, e.object);
        else commodity.properties.push(e.object);
      }
      this.setState({ commodityEdit: commodity, showPropertyCreationModal });
    }
  };

  handleEditCommodityListProperty = (e: Array<SelectOption<Property>>, key: PropertyType) => {
    const commodity = _.cloneDeep(this.state.commodityEdit);
    commodity.properties = commodity.properties.filter((p) => p.type !== key);
    for (let i = 0; i < e.length; i++) {
      const obj = e[i].object;
      if (obj) commodity.properties.push(obj);
    }
    this.setState({ commodityEdit: commodity });
  };

  handleEditVATPercentage = (e: SelectOption) => {
    const commodity = _.cloneDeep(this.state.commodityEdit);
    commodity.vatPercentage = Number(e.value);
    this.setState({ commodityEdit: commodity });
  };

  handleEditCommodityUnit = (unit: "kg" | "ltr") => {
    const commodity = _.cloneDeep(this.state.commodityEdit);
    commodity.unit = unit;
    this.setState({ commodityEdit: commodity });
  };

  handleEditCAS = (e: CustomEvent<Tagify.ChangeEventData<{ value: string; id: string }>>) => {
    const commodity = _.cloneDeep(this.state.commodityEdit);
    if (!e.detail.value) commodity.casNumber = [];
    const tags = JSON.parse(e.detail.value) as Array<{ value: string }>; // value is a JSON string containing an array of currently selected tags
    commodity.casNumber = tags.map((t: { value: string }) => t.value);
    this.setState({ commodityEdit: commodity });
  };

  handleClickGenerateSubtitle = () => {
    const commodity = _.cloneDeep(this.state.commodityEdit);
    commodity.subtitle.en = generateSubtitle(commodity);
    this.setState({ commodityEdit: commodity });
  };

  handleEditCommoditySelectCountry = (e: SelectOption) => {
    const commodity = _.cloneDeep(this.state.commodityEdit);
    commodity.country = { code: e.value, name: e.label };
    this.setState({ commodityEdit: commodity });
  };

  handleEditCommodityOrganic = () => {
    const commodity = _.cloneDeep(this.state.commodityEdit);
    commodity.organic = !commodity.organic;
    this.setState({ commodityEdit: commodity });
  };

  handleEditFlagValue = (field: "veg" | "law" | "hazardMaterial" | "novelFood" | "cites" | "echa", value: string) => {
    const commodity = _.cloneDeep(this.state.commodityEdit);
    if (field === "veg") {
      commodity.vegan = value === "vegan";
      commodity.vegetarian = value !== "none";
    } else if (field === "law") {
      commodity.kosher = ["kosher", "both"].includes(value);
      commodity.halal = ["halal", "both"].includes(value);
    } else {
      // @ts-ignore
      commodity[field] = !commodity[field];
    }
    this.setState({ commodityEdit: commodity });
  };

  handleEditGrades = (values: Array<SelectOption>) => {
    const commodity = _.cloneDeep(this.state.commodityEdit);
    const vString = values.map((v) => v.value);
    commodity.foodGrade = vString.includes("food");
    commodity.pharmaceuticalGrade = vString.includes("pharmaceutical");
    commodity.feedGrade = vString.includes("feed");
    commodity.cosmeticGrade = vString.includes("cosmetic");
    commodity.uspGrade = vString.includes("usp");
    commodity.medicineGrade = vString.includes("medicine");
    commodity.industrialGrade = vString.includes("industrial");
    this.setState({ commodityEdit: commodity });
  };

  handleEditEqualityValue = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const commodity = _.cloneDeep(this.state.commodityEdit);
    // @ts-ignore
    commodity[e.target.name].lessThan = !commodity[e.target.name].lessThan;
    this.setState({ commodityEdit: commodity });
  };

  handleSelectProperty = (property: Property) => {
    const commodityEdit = _.cloneDeep(this.state.commodityEdit);
    if (!commodityEdit.properties.some((p) => p._id.toString() === property._id.toString())) {
      commodityEdit.properties.push(property);
      this.setState({ commodityEdit });
    }
  };

  handleRemoveProperty = (property: Property) => {
    const commodityEdit = _.cloneDeep(this.state.commodityEdit);
    commodityEdit.properties = commodityEdit.properties.filter((p) => p._id.toString() !== property._id.toString());
    this.setState({ commodityEdit });
  };

  handleCommodityDisabledFlag = async (disable: boolean) => {
    const { commodity } = this.props;
    if (!commodity) return;
    const timelineEntry = getCommodityTimelineEntry(disable ? T_COMMODITYDISABLED : T_COMMODITYENABLED);
    const result = await updateCommodity({ disabled: disable }, commodity._id, timelineEntry);
    if (result && result.modifiedCount > 0) {
      toast.success("Commodity " + (disable ? "disabled" : "enabled") + " successfully");
    } else {
      toast.error("Error " + (disable ? "disabling" : "enabling") + " commodity");
    }
  };

  handleApproveCommodity = async () => {
    const { commodity } = this.props;
    if (!commodity) return;
    const timelineEntry = getCommodityTimelineEntry(T_COMMODITYAPPROVED);
    const result = await updateCommodity({ approved: true }, commodity._id, timelineEntry);
    if (result && result.modifiedCount > 0) {
      toast.success("Commodity approved successfully");
    } else {
      toast.error("Error approving commodity");
    }
  };

  handleAddActiveSubstance = (activeSubstance: ActiveSubstance, percentage: number) => {
    const commodityEdit = _.cloneDeep(this.state.commodityEdit);
    commodityEdit.activeSubstances.push({ substance: activeSubstance, percentage });
    this.setState({ commodityEdit });
  };

  handleEditActiveSubstance = (idx: number, activeSubstance: ActiveSubstance) => {
    const commodityEdit = _.cloneDeep(this.state.commodityEdit);
    commodityEdit.activeSubstances[idx].substance = activeSubstance;
    this.setState({ commodityEdit });
  };

  handleEditActiveSubstancePercentage = (id: string, percentage: number) => {
    const commodityEdit = _.cloneDeep(this.state.commodityEdit);
    const aS = commodityEdit.activeSubstances.find((aS) => aS.substance._id.toString() === id);
    if (!aS) return;
    aS.percentage = percentage;
    this.setState({ commodityEdit });
  };

  handleRemoveActiveSubstance = (id: string) => {
    const commodityEdit = _.cloneDeep(this.state.commodityEdit);
    commodityEdit.activeSubstances = commodityEdit.activeSubstances.filter((aS) => aS.substance._id.toString() !== id);
    this.setState({ commodityEdit });
  };

  handleAddSampleSize = () => {
    const commodityEdit = _.cloneDeep(this.state.commodityEdit);
    if (!commodityEdit.sampleSize) commodityEdit.sampleSize = [];
    commodityEdit.sampleSize.push({ amount: 0, price: 0 });
    this.setState({ commodityEdit });
  };

  handleEditSampleSize = (idx: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const commodityEdit = _.cloneDeep(this.state.commodityEdit);
    if (!commodityEdit.sampleSize) return;
    const field = e.target.name;
    if (field === "amount") commodityEdit.sampleSize[idx].amount = Number(e.target.value);
    else if (field === "price") commodityEdit.sampleSize[idx].price = Number(e.target.value);
    this.setState({ commodityEdit });
  };

  handleRemoveSampleSize = (idx: number) => {
    const commodityEdit = _.cloneDeep(this.state.commodityEdit);
    if (!commodityEdit.sampleSize) return;
    commodityEdit.sampleSize.splice(idx, 1);
    this.setState({ commodityEdit });
  };

  handleAddPackagingSize = () => {
    const commodityEdit = _.cloneDeep(this.state.commodityEdit);
    if (!commodityEdit.packagingSizes) commodityEdit.packagingSizes = [];
    commodityEdit.packagingSizes.push({ _id: new BSON.ObjectId(), type: P_DRUMS, packagingSize: 0 });
    this.setState({ commodityEdit });
  };

  handleEditPackagingSize = (idx: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const commodityEdit = _.cloneDeep(this.state.commodityEdit);
    if (!commodityEdit.packagingSizes) return;
    commodityEdit.packagingSizes[idx].packagingSize = Number(e.target.value);
    this.setState({ commodityEdit });
  };

  handleEditPackagingType = (idx: number, e: SelectOption) => {
    const commodityEdit = _.cloneDeep(this.state.commodityEdit);
    if (!commodityEdit.packagingSizes) return;
    commodityEdit.packagingSizes[idx].type = e.value;
    this.setState({ commodityEdit });
  };

  handleRemovePackagingSize = (idx: number) => {
    const commodityEdit = _.cloneDeep(this.state.commodityEdit);
    if (!commodityEdit.packagingSizes) return;
    commodityEdit.packagingSizes.splice(idx, 1);
    this.setState({ commodityEdit });
  };

  handleCreateCommodity = async () => {
    const commodityEdit = _.cloneDeep(this.state.commodityEdit);
    commodityEdit.timeline = [getCommodityTimelineEntry(T_COMMODITYCREATED)];
    const result = await insertCommodity(reduceCommodity(commodityEdit));
    if (result && result.insertedId) {
      toast.success("Commodity created successfully");
      this.setState({ edit: false }, () => this.props.history.push("/commodity/" + result.insertedId.toString()));
    } else {
      toast.error("Error creating commodity");
    }
    COMMODITYCREATIONKEYS.forEach((key) => localStorage.removeItem(key));
  };

  handleSaveCommodity = async () => {
    const { commodity } = this.props;
    const { commodityEdit } = this.state;
    if (!commodityEdit) return;
    this.setState({ saving: true });
    try {
      const timelineEntry = getCommodityEditTimelineEntry(commodityEdit, commodity);
      // Omit timeline to not get a conflict due to set and push on timeline
      const result = await updateCommodity(
        _.omit(reduceCommodity(commodityEdit), "timeline", "suppliers", ""),
        undefined,
        timelineEntry
      );
      if (result && result.modifiedCount > 0) {
        toast.success("Commodity data updated successfully");
        this.setState({ edit: false });
      } else {
        toast.error("Error updating commodity");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  handleAddTransportCondition = () => {
    const commodityEdit = _.cloneDeep(this.state.commodityEdit);
    if (!commodityEdit.transportConditions) commodityEdit.transportConditions = [];
    commodityEdit.transportConditions.push({
      type: TC_TEMPERATURE,
      property:
        this.props.context.property.find((p) => p.type === PropertyType.TRANSPORTCONDITIONS)?._id.toString() || "",
    });
    this.setState({ commodityEdit });
  };

  handleRemoveTransportCondition = (idx: number) => {
    const commodityEdit = _.cloneDeep(this.state.commodityEdit);
    if (!commodityEdit.transportConditions) return;
    commodityEdit.transportConditions.splice(idx, 1);
    this.setState({ commodityEdit });
  };

  handleEditTransportConditionType = (idx: number, e: SelectOption) => {
    const commodityEdit = _.cloneDeep(this.state.commodityEdit);
    if (!commodityEdit.transportConditions) return;
    commodityEdit.transportConditions[idx].type = e.value as TRANSPORTCONDITIONTYPE;
    this.setState({ commodityEdit });
  };

  handleEditTransportCondition = (idx: number, e: TransportConditionSelect) => {
    const commodityEdit = _.cloneDeep(this.state.commodityEdit);
    if (!commodityEdit.transportConditions) return;
    commodityEdit.transportConditions[idx].property = e.value;
    this.setState({ commodityEdit });
  };

  /**
   * Validate commodity data
   * @param approve optional, flag if it should be specifically checked for approval
   */
  validateCommodityData = (approve?: boolean) => {
    const commodity = approve ? this.props.commodity : this.state.commodityEdit;
    const { title, hsCode, properties, vegetarian, vegan, density, shelfLife, sampleSize } = commodity;
    const errors: Array<string> = [];
    if (title.en.trim().length < 3) errors.push("Commodity name too short");
    if (hsCode.trim().length < 6) errors.push("HS Code missing or malformed");
    if (!properties.some((p) => p.type === PropertyType.COMPOSITION)) errors.push("Commodity composition not set");
    if (!properties.some((p) => p.type === PropertyType.CATEGORY)) errors.push("Commodity category not set");
    if (!vegetarian && vegan) errors.push("Vegan commodities have to be vegetarian as well"); // Should not be possible but as a sanity check
    if (!density.min || !density.max) errors.push("Density can not be 0");
    if (!shelfLife) errors.push("Shelf life has to be set");
    if (sampleSize) {
      if (sampleSize.some((s) => s.amount <= 0)) errors.push("Samples need to have a positive amount");
      for (let i = 0; i < sampleSize.length; i++) {
        const s = sampleSize[i];
        const dup = sampleSize.slice(i + 1, sampleSize.length).find((sd) => s.amount === sd.amount);
        if (dup) {
          errors.push("Sample sizes need to be unique");
          break;
        }
      }
    }
    if (!commodity.unit) errors.push("Unit is not set");
    if (commodity.packagingSizes?.some((pS) => pS.packagingSize === 0)) {
      errors.push("Packaging sizes of size 0 are not allowed");
    }
    if (
      commodity.packagingSizes?.some((pSo) =>
        commodity.packagingSizes?.some(
          (pSi) =>
            pSo.type === pSi.type &&
            pSo.packagingSize === pSi.packagingSize &&
            pSo._id.toString() !== pSi._id.toString()
        )
      )
    ) {
      errors.push("Duplicates in packaging sizes");
    }
    // Additional checks for approval
    // Currently disabled
    // if (approve && errors.length === 0) {
    //   const masterSpec = documents.find((d) => d.type === D_MASTERSPECIFICATION);
    //   if (!masterSpec)
    //     errors.push("No master specification available. Please create and sign the master specification to proceed.");
    // }
    return errors;
  };

  render() {
    const clone = !!new URLSearchParams(this.props.location.search).get("clone");
    const { commodity, context } = this.props;
    const { edit, commodityEdit, create, showPropertyCreationModal, propertyCreationType, search, saving } = this.state;
    const { property } = context;
    const errors = this.validateCommodityData();
    const approvalErrors = this.validateCommodityData(true);
    return (
      <div className="card bg-white">
        {edit && (
          <CreatePropertyModal
            onlyModal={true}
            show={showPropertyCreationModal}
            onHide={() => this.setState({ showPropertyCreationModal: false })}
            type={
              PROPERTYOPTIONS.find((po) => po.value === propertyCreationType) as {
                value: PropertyType;
                label: string;
              }
            }
          />
        )}
        <div className="card-header border-0 mt-5 d-block">
          <div className="card-title flex-column">
            <div className="row w-100">
              <div className="col-6">
                <h2 className="mb-1">{create ? "Create New Commodity" : "General Information"}</h2>
                <div className="fs-6 fw-bold text-muted">
                  {commodity.title.en}
                  {commodity && commodity.subtitle.en.trim() ? `-${commodity.subtitle.en}` : ""}
                </div>
              </div>
              <div className="col-6">
                <div className="pt-3 float-right">
                  <div className="d-flex pt-3 align-items-center w-100">
                    {create ? (
                      <>
                        <button
                          className={
                            "btn btn-outline btn-outline-light btn-sm float-right " + (!commodity && "disabled")
                          }
                          disabled={!commodity}
                          onClick={this.handleCancelCreate}
                        >
                          Cancel
                        </button>
                        {clone ? (
                          <CloneCommodityModal
                            originalCommodity={commodity}
                            clonedCommodity={commodityEdit}
                            onHandleCreate={this.handleCreateCommodity}
                          />
                        ) : (
                          <ErrorOverlayButton
                            errors={errors}
                            className={"btn btn-outline btn-outline-light btn-sm float-right ml-2"}
                            buttonText={"Create Commodity"}
                            onClick={this.handleCreateCommodity}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {edit ? (
                          <>
                            <button
                              className={
                                "btn btn-outline btn-outline-light btn-sm float-right " + (!commodity && "disabled")
                              }
                              disabled={!commodity}
                              onClick={this.handleEditToggle}
                            >
                              Cancel
                            </button>
                            <ErrorOverlayButton
                              errors={commodity.approved ? errors : []}
                              saving={saving}
                              className="btn btn-outline btn-outline-light btn-sm float-right ml-2"
                              buttonText="Save Commodity"
                              disabled={commodity.disabled}
                              onClick={this.handleSaveCommodity}
                            />
                          </>
                        ) : (
                          <>
                            <button
                              className={
                                "btn btn-outline btn-outline-light btn-sm float-right ml-2 " +
                                (commodity.disabled && "disabled")
                              }
                              disabled={commodity.disabled}
                              onClick={commodity.disabled ? undefined : this.handleCloneCommodity}
                            >
                              Clone Commodity
                            </button>
                            <button
                              className={
                                "btn btn-outline btn-outline-light btn-sm float-right ml-2 " +
                                (commodity.disabled && "disabled")
                              }
                              disabled={commodity.disabled}
                              onClick={commodity.disabled ? undefined : this.handleEditToggle}
                            >
                              Edit Commodity
                            </button>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body p-9 pt-4">
          <div className="pt-0">
            <CommodityPageGeneralInformationBlock
              commodity={edit || !commodity ? commodityEdit : commodity}
              edit={edit}
              context={context}
              onEditCommodityInput={this.handleEditCommodityInput}
              onEditCommodityColor={this.handleEditCommodityColor}
              onEditCommodityDensity={this.handleEditCommodityDensity}
              onEditCommoditySelectExclusiveProperty={this.handleEditCommoditySelectExclusiveProperty}
              onEditCommodityListProperty={this.handleEditCommodityListProperty}
              onEditCommoditySelectCountry={this.handleEditCommoditySelectCountry}
              onEditCommodityOrganic={this.handleEditCommodityOrganic}
              onEditCommodityUnit={this.handleEditCommodityUnit}
              onEditCAS={this.handleEditCAS}
              onClickGenerateSubtitle={this.handleClickGenerateSubtitle}
              onAddPackagingSize={this.handleAddPackagingSize}
              onEditPackagingSize={this.handleEditPackagingSize}
              onEditPackagingType={this.handleEditPackagingType}
              onRemovePackagingSize={this.handleRemovePackagingSize}
              onAddTransportCondition={this.handleAddTransportCondition}
              onRemoveTransportCondition={this.handleRemoveTransportCondition}
              onEditTransportCondition={this.handleEditTransportCondition}
              onEditTransportConditionType={this.handleEditTransportConditionType}
              onEditVATPercentage={this.handleEditVATPercentage}
            />
            <div className="border-bottom-dark-gray" />
            <div className="row w-100 align-items-center">
              <div className="col-6">
                <div className="text-white fs-5 fw-bolder">Advanced Information</div>
                <div className="text-muted">Detailed Properties</div>
              </div>
              <div className="col-6 my-4">
                <Search onSearch={this.handleChangeSearch} value={search} placeholder="Search for properties..." />
              </div>
            </div>
            {C_G_TYPES.map((t, idx) => (
              <React.Fragment key={t}>
                {idx !== 0 && <div className="border-bottom-dark-gray" />}
                <CommodityPageAdvancedInformationBlock
                  commodity={edit || !commodity ? commodityEdit : commodity}
                  edit={edit}
                  context={context}
                  onEditCommodityInput={this.handleEditCommodityInput}
                  onEditCommoditySelectExclusiveProperty={this.handleEditCommoditySelectExclusiveProperty}
                  onEditFlagValue={this.handleEditFlagValue}
                  onEditGrades={this.handleEditGrades}
                  onEditEqualityValue={this.handleEditEqualityValue}
                  type={t}
                  search={search}
                />
              </React.Fragment>
            ))}

            <div className="border-bottom-dark-gray" />
            <div className="py-0">
              <div className="d-flex py-6 align-items-center w-100">
                <div className="me-3">
                  <div className="text-white fs-5 fw-bolder">Active Substances</div>
                  <div className="text-muted">Substances like vitamins and their concentration</div>
                </div>
              </div>
              {edit ? (
                <CommodityActiveSubstanceEditListing
                  commodity={commodityEdit}
                  activeSubstances={context.activeSubstance}
                  onAddActiveSubstance={this.handleAddActiveSubstance}
                  onEditActiveSubstancePercentage={this.handleEditActiveSubstancePercentage}
                  onEditActiveSubstance={this.handleEditActiveSubstance}
                  onRemoveActiveSubstance={this.handleRemoveActiveSubstance}
                />
              ) : (
                <div className="py-5">
                  <CommodityActiveSubstanceListing article={commodity} />
                </div>
              )}
            </div>
            <div className="border-bottom-dark-gray" />
            <div className="py-0">
              <div className="d-flex py-6 align-items-center w-100">
                <div className="me-3">
                  <div className="text-white fs-5 fw-bolder">Allergens</div>
                  <div className="text-muted">Substances possibly causing allergic reactions</div>
                </div>
              </div>
              {edit ? (
                <div className="py-5">
                  <PropertiesSelection
                    selectedProperties={commodityEdit.properties}
                    properties={property}
                    types={[PropertyType.ALLERGEN]}
                    onSelectProperty={this.handleSelectProperty}
                    onRemoveProperty={this.handleRemoveProperty}
                    searchPlaceholder={"Search for allergens..."}
                  />
                </div>
              ) : (
                <div className="py-5">
                  <PropertiesWidget
                    properties={commodity?.properties || []}
                    allProperties={property}
                    additionalPropertiesShown={40}
                    allowShowMore={true}
                    types={[PropertyType.ALLERGEN]}
                    placeholder={"No allergens for this commodity"}
                  />
                </div>
              )}
            </div>
            <div className="border-bottom-dark-gray" />
            <div className="py-0">
              <div className="d-flex py-6 align-items-center w-100">
                <div className="me-3">
                  <div className="text-white fs-5 fw-bolder">Commodity Tags</div>
                  <div className="text-muted">Keywords &amp; Additional Properties</div>
                </div>
              </div>
              {edit ? (
                <div className="py-5">
                  <PropertiesSelection
                    selectedProperties={commodityEdit.properties}
                    properties={property}
                    types={[PropertyType.TAG, PropertyType.OTHER]}
                    onSelectProperty={this.handleSelectProperty}
                    onRemoveProperty={this.handleRemoveProperty}
                    searchPlaceholder={"Search for tags and other properties..."}
                  />
                </div>
              ) : (
                <div className="py-5">
                  <PropertiesWidget
                    properties={commodity?.properties || []}
                    allProperties={property}
                    additionalPropertiesShown={40}
                    allowShowMore={true}
                    types={[PropertyType.TAG, PropertyType.OTHER]}
                    placeholder={"No tags for this commodity"}
                  />
                </div>
              )}
            </div>
            <div className="border-bottom-dark-gray" />
            <div className="py-0">
              <div className="d-flex py-6 align-items-center w-100">
                <div className="me-3">
                  <div className="text-white fs-5 fw-bolder">Sample Sizes</div>
                  <div className="text-muted">Amounts that can be provided to the customers as samples</div>
                </div>
              </div>
              <CommoditySampleSizes
                edit={edit}
                commodity={edit || !commodity ? commodityEdit : commodity}
                onAddSampleSize={this.handleAddSampleSize}
                onEditSampleSize={this.handleEditSampleSize}
                onRemoveSampleSize={this.handleRemoveSampleSize}
              />
            </div>
            {create ? (
              <>
                <div className="border-bottom-dark-gray" />
                <div className="pt-6">
                  <ErrorOverlayButton
                    errors={errors}
                    className={"btn btn-outline btn-outline-light btn-sm float-right ml-2"}
                    buttonText={"Create Commodity"}
                    onClick={this.handleCreateCommodity}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="border-bottom-dark-gray" />
                <MasterSpecification
                  article={{ type: COMMODITY, commodity: edit || !commodity ? commodityEdit : commodity }}
                  context={context}
                />
                <div className="border-bottom-dark-gray" />
              </>
            )}
            {commodity && !create && (
              <div className="pt-3">
                <div className="d-flex pt-3 align-items-center w-100">
                  {!commodity.approved && (
                    <ErrorOverlayButton
                      errors={approvalErrors}
                      className="btn btn-text btn-text-success btn-sm"
                      buttonText="Approve Commodity"
                      disabled={commodity.disabled}
                      onClick={this.handleApproveCommodity}
                    />
                  )}
                  {commodity.disabled ? (
                    <button
                      className="btn btn-text btn-text-success btn-sm ml-auto"
                      onClick={() => this.handleCommodityDisabledFlag(false)}
                    >
                      Enable Commodity
                    </button>
                  ) : (
                    <button
                      className="btn btn-text btn-text-danger btn-sm ml-auto"
                      onClick={() => this.handleCommodityDisabledFlag(true)}
                    >
                      Disable Commodity
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CommodityPageGeneral);
