import React, { Component } from "react";
import { ProgressBar } from "react-bootstrap";

interface SplashScreenProps {}

interface SplashScreenState {
  progress: number;
}

class SplashScreen extends Component<SplashScreenProps, SplashScreenState> {
  interval?: NodeJS.Timer;
  intervals: number;
  intervalIncrement: number;

  constructor(props: SplashScreenProps) {
    super(props);
    const expectedTime = localStorage.getItem("t");
    let expectedLoadingTime: number;
    if (!expectedTime || isNaN(+expectedTime)) expectedLoadingTime = 20 * 1000; // Default 20s
    else expectedLoadingTime = +expectedTime;
    this.intervals = Math.floor(Math.random() * (36 - 30 + 1) + 30); // get random amount of intervals between 30 and 36;
    this.intervalIncrement = expectedLoadingTime / this.intervals;
    this.state = {
      progress: 0,
    };
  }

  componentDidMount = () => {
    if (!this.interval)
      this.interval = setInterval(() => this.setState({ progress: this.state.progress + 1 }), this.intervalIncrement);
  };

  componentWillUnmount = () => {
    if (this.interval) clearInterval(this.interval);
  };

  render() {
    const { progress } = this.state;
    return (
      <div className="content d-flex flex-column flex-column-fluid splashscreen">
        <div className="post d-flex flex-column-fluid">
          <div className="container d-flex justify-content-center align-items-center flex-column">
            <div className="d-flex flex-column flex-xl-row">
              <div className="flex-lg-row-fluid">
                <div className="splash-screen-relative d-flex justify-content-center">
                  <svg className="splash-spinner" viewBox="0 0 50 50" style={{ height: "50px", width: "50px" }}>
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="3" />
                  </svg>
                </div>
                <h5 className="text-center text-white">Loading data. This may take a few seconds.</h5>
                <div className="d-flex justify-content-center">
                  <ProgressBar
                    className="mx-auto mt-5 h-5px splashscreen-progressbar"
                    variant={progress > this.intervals * 5 ? "danger" : "success"}
                    now={progress}
                    min={0}
                    max={this.intervals}
                    style={{ backgroundColor: "#6d6d6d", maxWidth: "225px", width: "100%" }}
                  />
                </div>
                <div className="text-white text-center mt-2">
                  {progress > this.intervals * 5
                    ? "Check your internet connection."
                    : progress > this.intervals * 3
                    ? "Loading longer than usual..."
                    : progress > this.intervals * 2
                    ? "Still finishing..."
                    : progress > this.intervals
                    ? "Finishing..."
                    : Math.round((100 / this.intervals) * progress) + " %"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SplashScreen;
