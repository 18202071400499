import { ApexOptions } from "apexcharts";
import _ from "lodash";
import { PriceDevelopment } from "../model/statistics/commodityStatistics.types";

/**
 * Get a tuple of colors for apex charts graph
 * @param percent a percentage number, depending on its value the colors change
 * @returns {[color1: string, color2: string]} tuple of colors for apex charts
 */
export const getGraphColors = (percent?: number): [color1: string, color2: string] => {
  if (!percent) return ["#a1a5b7", "#fafafa"];
  if (percent > 0) return ["#f1416c", "#fafafa"];
  else if (percent < 0) return ["#50cd89", "#fafafa"];
  else return ["#a1a5b7", "#fafafa"];
};

/**
 * Resolve the min and max values for the Y axis for the given price development.
 * @param priceDevelopment Optional, if given the min and max values are calculated, else undefined they are undefined
 * @returns {{ min: undefined | number, max: undefined | number }} Min and max values for the Y axis
 */
export function getMinMaxYAxisValues(priceDevelopment?: PriceDevelopment): {
  min: undefined | number;
  max: undefined | number;
} {
  if (!priceDevelopment || priceDevelopment.priceData.length === 0) return { min: undefined, max: undefined };
  if (priceDevelopment.percentage === 0) {
    // Ensure that the min/max got the same distance to the value so that the line is centered
    return { min: priceDevelopment.priceData[0].price - 1, max: priceDevelopment.priceData[0].price + 1 };
  }
  const prices = priceDevelopment.priceData.map((p) => p.price);
  const start = priceDevelopment.priceData[0].price;
  const min = _.min(prices) || 0;
  const max = _.max(prices) || 0;
  const startMinDiff = Math.abs(start - min);
  const startMaxDiff = Math.abs(max - start);
  const maxDiff = startMaxDiff > startMinDiff ? startMaxDiff : startMinDiff;
  // Ensure that the graph expands in both dimensions correctly and that the first element is the middle of the graph.
  // The additional 5% is added so that the line is not cut.
  if (priceDevelopment.percentage < 0) {
    return { min: start - maxDiff * 1.05, max: start + maxDiff * 1.05 };
  }
  return { min: start - maxDiff * 1.05, max: start + maxDiff * 1.05 };
}

/**
 * Generate the default price development chart options object with help of the price development object.
 * @param priceDevelopment Optional, if given the min and max values for the Y axis are calculated
 * @returns {ApexOptions} Apex charts options object for price development charts
 */
export function getDefaultPriceDevelopmentChartOptions(priceDevelopment?: PriceDevelopment): ApexOptions {
  const { min, max } = getMinMaxYAxisValues(priceDevelopment);
  return {
    tooltip: { enabled: false },
    grid: { show: false },
    xaxis: {
      axisBorder: { show: false },
      axisTicks: { show: false },
      labels: { show: false },
    },
    yaxis: {
      axisBorder: { show: false },
      axisTicks: { show: false },
      labels: { show: false },
      min,
      max,
    },
    chart: {
      animations: { enabled: false },
      zoom: { enabled: false },
      toolbar: { show: false },
      sparkline: { enabled: true },
      type: "area",
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      colors: undefined,
      opacity: 0,
      type: "solid",
    },
  } as ApexOptions;
}
