import _ from "lodash";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import SVG from "react-inlinesvg";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CommodityTimelineEntry, UploadedFileExtended } from "../../model/commodity.types";
import { SupplierExtended, SupplierFile, T_S_DELETESUPPLIERFILE } from "../../model/supplier.types";
import zipUtils from "../../utils/zipUtils";
import { resolveFilePath } from "../../utils/fileUtils";
import {
  D_OTHER,
  D_TYPEOPTIONS,
  getCommodityTimelineEntry,
  T_FILEDELETED,
  updateCommodity,
} from "../../utils/commodityUtils";
import SimpleConfirmationModal from "./SimpleConfirmationModal";
import UpdateDocumentModal from "../commodities/internal/modals/UpdateDocumentModal";
import { CustomToggle } from "./CustomToggle";
import { formatDate, toAbsoluteUrl } from "../../utils/baseUtils";
import UploadDocumentModal, {
  T_GENERALARTICLE,
  T_GENERALSUPPLIER,
  T_SUPPLIER,
} from "../commodities/internal/modals/UploadDocumentModal";
import { getUserName } from "../../utils/userUtils";
import { getSupplierTimelineEntry, SUP_D_TYPEOPTIONS } from "../../utils/supplierUtils";
import { Action, SUPPLIER, transaction } from "../../services/dbService";
import { FinishedProductTimelineEntry } from "../../model/finishedProduct.types";
import {
  getFinishedProductTimelineEntry,
  isFinishedProduct,
  updateFinishedProduct,
} from "../../utils/finishedProductUtils";
import { InternalArticleExtended } from "../../utils/productArticleUtils";
import { reduceUploadedFile } from "../../utils/dataTransformationUtils";

interface SupplierDocumentsProps {
  eventKey: string;
  article?: InternalArticleExtended;
  supplier?: SupplierExtended;
  documents: Array<UploadedFileExtended | SupplierFile>;
  type: "commodity" | "supplier" | "generalSupplier" | "generalCommodity" | "finishedProduct";
  articles?: Array<InternalArticleExtended>;
  noSupplierSelection?: boolean;
}

interface SupplierDocumentsState {
  fileToDelete?: UploadedFileExtended | SupplierFile;
  fileToUpdate?: UploadedFileExtended | SupplierFile;
  generating: boolean;
}

class SupplierAndCommodityDocuments extends PureComponent<SupplierDocumentsProps, SupplierDocumentsState> {
  constructor(props: SupplierDocumentsProps) {
    super(props);
    this.state = {
      generating: false,
    };
  }

  handleDownload = () => {
    if (this.state.generating) return;
    const { supplier, documents } = this.props;
    if (!documents || documents.length === 0) return;
    this.setState({ generating: true });
    const zip = new JSZip();
    const zipFilename = "Export-" + (supplier ? supplier.name : "Files") + "-" + new Date().toISOString() + ".zip";
    let count = 0;
    for (let i = 0; i < documents.length; i++) {
      const file = documents[i];
      zipUtils.getBinaryContent(resolveFilePath(file.path), (err: Error | null, data: string | ArrayBuffer | null) => {
        if (!err) {
          count++;
          zip.file(file.name, data ?? "", { binary: true });
        }
        if (count === documents.length) {
          zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(content, zipFilename);
            this.setState({ generating: false });
          });
        }
      });
    }
  };

  handleRemoveFile = async () => {
    const { article, supplier } = this.props;
    const { fileToDelete } = this.state;
    if (!fileToDelete) return;
    if (article) {
      // Handle article documents
      let documents = _.cloneDeep(article.documents);
      documents = documents.filter((d) => d._id.toString() !== fileToDelete._id.toString());
      const documentsReduced = documents.map((d) => reduceUploadedFile(d));
      const finishedProduct = isFinishedProduct(article);
      const timelineEntry = finishedProduct
        ? getFinishedProductTimelineEntry(T_FILEDELETED, fileToDelete.type, fileToDelete.path)
        : getCommodityTimelineEntry(T_FILEDELETED, fileToDelete.type, fileToDelete.path);
      const result = finishedProduct
        ? await updateFinishedProduct(
            { documents: documentsReduced },
            article._id,
            timelineEntry as FinishedProductTimelineEntry
          )
        : await updateCommodity({ documents: documentsReduced }, article._id, timelineEntry as CommodityTimelineEntry);
      if (result && result.modifiedCount > 0) {
        toast.success("File removed successfully");
      } else {
        toast.error("Error removing file");
      }
    } else if (supplier) {
      // handle supplier documents
      const timelineEntry = getSupplierTimelineEntry(T_S_DELETESUPPLIERFILE, { type: fileToDelete.type });
      const action: Action = {
        collection: SUPPLIER,
        filter: { _id: supplier._id },
        pull: { documents: fileToDelete },
        push: { timeline: timelineEntry },
      };
      const result = await transaction([action]);
      if (result) {
        toast.success("File removed successfully");
      } else {
        toast.error("Error removing file");
      }
    }
  };

  handleCloseRemoveModal = () => this.setState({ fileToDelete: undefined });
  handleCloseUpdateModal = () => this.setState({ fileToUpdate: undefined });

  render() {
    const { article, supplier, documents, type, eventKey, noSupplierSelection, articles } = this.props;
    const { fileToDelete, fileToUpdate, generating } = this.state;
    const allTypes = D_TYPEOPTIONS.concat(SUP_D_TYPEOPTIONS);
    return (
      <>
        <SimpleConfirmationModal.SimpleConfirmationModal
          size={"md"}
          show={!!fileToDelete}
          onConfirm={this.handleRemoveFile}
          onClose={this.handleCloseRemoveModal}
          confirmButtonText={"Remove File"}
          cancelButtonText={"Cancel"}
          modalDescription={
            <p className="text-white">
              Are you sure you want to remove file:{" "}
              <a
                className="custom-link text-white"
                href={fileToDelete && resolveFilePath(fileToDelete.path)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {fileToDelete?.name}
              </a>
              ?
            </p>
          }
          modalTitle={"Remove File"}
        />
        <UpdateDocumentModal
          article={article}
          supplier={supplier}
          file={fileToUpdate}
          onHide={this.handleCloseUpdateModal}
        />
        <div className="bg-light2 rounded p-5 mb-7">
          <CustomToggle eventKey={eventKey}>
            <div className="d-flex align-items-center ">
              <div className="flex-grow-1 me-2" style={{ marginLeft: 15 }}>
                {[T_GENERALSUPPLIER, T_GENERALARTICLE].includes(type) ? (
                  <>
                    <span className="d-inline-flex fw-bolder text-gray-800 custom-link fs-6 flex-center flex-stack">
                      General Files
                    </span>
                    <span className="text-muted fw-bold d-block">Additional files, e.g. flowcharts</span>
                  </>
                ) : (
                  <>
                    <Link
                      className="d-inline-flex fw-bolder text-gray-800 custom-link fs-6 flex-center flex-stack"
                      to={
                        type === T_SUPPLIER && supplier
                          ? `/supplier/${supplier._id.toString()}`
                          : article
                          ? `/${isFinishedProduct(article) ? "finishedProduct" : "commodity"}/${
                              article._id.toString() || ""
                            }`
                          : ""
                      }
                      onClick={(e) => e.stopPropagation()}
                    >
                      {type === T_SUPPLIER && supplier ? supplier.name : article?.title.en}
                    </Link>
                    <span className="text-muted fw-bold d-block">
                      {type === T_SUPPLIER && supplier ? getUserName(supplier.primaryPerson) : article?.subtitle.en}
                    </span>
                  </>
                )}
              </div>
              <div style={{ textAlign: "right", marginRight: 15 }}>
                <span className=" mb-1 fs-6 fw-bold">
                  {documents.length} {documents.length === 1 ? "Document" : "Documents"}
                </span>
                <div className=" text-success">
                  {documents.filter((d) => !d.validUntil || d.validUntil >= new Date()).length} valid
                  <span className={"text-danger ml-2"}>
                    {documents.filter((d) => d.validUntil && d.validUntil < new Date()).length} expired
                  </span>
                </div>
              </div>
            </div>
          </CustomToggle>
          <Accordion.Collapse eventKey={eventKey}>
            <div className="table-responsive mt-5">
              <table className="table table-row-gray-100 align-middle gs-0 gy-1 ">
                <thead>
                  <tr className="fw-bolder text-muted">
                    <th className="border-bottom-0">Type</th>
                    <th className="border-bottom-0">File</th>
                    <th className="border-bottom-0">Valid til</th>
                    <th className="border-bottom-0 text-right">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {documents.length > 0 ? (
                    documents.map((d) => (
                      <tr key={d._id.toString()}>
                        <td className="align-middle">
                          <a href={resolveFilePath(d.path)} target="_blank" rel="noopener noreferrer">
                            <span className={"text-white fw-bolder"}>
                              {allTypes.find((type) => type.value === d.type)?.label}
                            </span>
                            {d.type === D_OTHER && d.customType && (
                              <small className="text-muted ml-2 fw-bolder">({d.customType})</small>
                            )}
                          </a>
                        </td>
                        <td className="align-middle">
                          <a href={resolveFilePath(d.path)} target="_blank" rel="noopener noreferrer">
                            <span className={"text-white fw-bold"}>
                              {d.name}
                              {"version" in d && d.version !== undefined && (
                                <span className="text-white ml-2">v{d.version}</span>
                              )}
                              <small className="text-muted ml-2">
                                {d.size ? Math.round(d.size / 1024) + " KB" : ""}
                              </small>
                            </span>
                          </a>
                        </td>
                        <td className="align-middle">
                          {d.validUntil ? (
                            <span className={d.validUntil > new Date() ? "text-success" : "text-danger"}>
                              {formatDate(d.validUntil)}
                            </span>
                          ) : (
                            <span className="text-white">-</span>
                          )}
                        </td>
                        <td className="text-right align-middle">
                          <button
                            className={
                              "btn btn-text text-danger btn-sm p-2 " +
                              (((article && article.disabled) || (supplier && supplier.disabled)) && "disabled")
                            }
                            disabled={(article && article.disabled) || (supplier && supplier.disabled)}
                            onClick={
                              (article && article.disabled) || (supplier && supplier.disabled)
                                ? undefined
                                : () => this.setState({ fileToUpdate: d })
                            }
                          >
                            <SVG src={toAbsoluteUrl("/assets/media/svg/custom/options.svg")} />
                          </button>
                          <button
                            className={
                              "btn btn-text text-danger btn-sm p-2 " +
                              (((article && article.disabled) || (supplier && supplier.disabled)) && "disabled")
                            }
                            disabled={(article && article.disabled) || (supplier && supplier.disabled)}
                            onClick={
                              (article && article.disabled) || (supplier && supplier.disabled)
                                ? undefined
                                : () => this.setState({ fileToDelete: d })
                            }
                          >
                            x
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="align-middle text-center py-5 cursor-default" colSpan={10}>
                        <span className="text-white fw-bolder">No documents available yet</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <button
                className={"btn btn-text btn-sm text-muted float-right p-0 mr-3 " + (generating && "disabled")}
                disabled={generating}
                onClick={this.handleDownload}
              >
                Export All
              </button>
              <UploadDocumentModal
                article={article}
                supplier={supplier}
                type={type}
                buttonType={"small"}
                articles={articles}
                noSupplierSelection={noSupplierSelection}
              />
            </div>
          </Accordion.Collapse>
        </div>
      </>
    );
  }
}

export default SupplierAndCommodityDocuments;
