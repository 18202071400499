import React, { useContext } from "react";
import { DataContextAnonymous, DataContextCustomer } from "../../../context/dataContext";
import CustomerRequestListing from "./CustomerRequestListing";
import userService from "../../../services/userService";
import { ANONYMOUS, CUSTOMER } from "../../../utils/userUtils";

interface CustomerRequestListingWrapperProps {}

const CustomerRequestListingWrapper: React.FunctionComponent<CustomerRequestListingWrapperProps> = () => {
  let context;
  if (userService.getUserType() === CUSTOMER) {
    context = useContext(DataContextCustomer);
  } else if (userService.getUserType() === ANONYMOUS) {
    context = useContext(DataContextAnonymous);
  }
  if (!context) return <></>;
  return <CustomerRequestListing context={context} />;
};

export default CustomerRequestListingWrapper;
