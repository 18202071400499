import _ from "lodash";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import {
  formatCurrency,
  formatDate,
  formatUnit,
  getDocFromCollection,
  toAbsoluteUrl,
  truncateString,
} from "../../../../../utils/baseUtils";
import {
  SAMO_STATE,
  SAMO_T_ARCHIVED,
  SAMO_T_CANCELED,
  SAMO_T_CONFIRMED,
  SAMO_T_REJECTED,
  SAMO_T_SHIPPED,
  SampleOrderExtended,
} from "../../../../../model/sampleOrder.types";
import { getAvailableAndIncomingStock } from "../../../../../utils/customerOrderUtils";
import {
  DataContextAnonymousType,
  DataContextCustomerType,
  DataContextInternalType,
  isAnonymousContext,
} from "../../../../../context/dataContext";
import { INTERNAL, searchUserName } from "../../../../../utils/userUtils";
import SimpleConfirmationModal from "../../../../common/SimpleConfirmationModal";
import { getSampleOrderTimelineEntry, updateSampleOrder } from "../../../../../utils/sampleOrderUtils";
import { I_SAMPLEINVOICE } from "../../../../../model/invoice.types";
import { resolveFilePath } from "../../../../../utils/fileUtils";
import { CustomerSampleOrderExtended } from "../../../../../model/customer/customerSampleOrder.types";
import { getCW } from "../../../../../utils/dateUtils";
import CountryWidget from "../../../../common/CountryWidget";
import { getLastStateUpdate, getOrderStateDescriptions } from "../../../../../utils/orderUtils";
import AddReferenceModal from "../../../../common/AddReferenceModal";
import { formatAddress, isAddress } from "../../../../../utils/addressUtils";
import AnonymousBlurText from "../../../../common/anonymous/AnonymousBlurText";
import { EURO } from "../../../../../utils/currencyUtils";

interface SampleOrderWorkflowContentProps {
  order: SampleOrderExtended | CustomerSampleOrderExtended;
  context: DataContextInternalType | DataContextCustomerType | DataContextAnonymousType;
}

interface SampleOrderWorkflowContentState {
  amountOnStock: number;
  incomingStock: { [weeks: string]: number } | Record<string, never>;
  validPrices: number;
  processing: boolean;
}

class SampleOrderWorkflowContent extends PureComponent<
  SampleOrderWorkflowContentProps,
  SampleOrderWorkflowContentState
> {
  constructor(props: SampleOrderWorkflowContentProps) {
    super(props);
    this.state = this.getDefaultState();
  }

  async componentDidMount() {
    await this.populateData();
  }

  async componentDidUpdate(prevProps: Readonly<SampleOrderWorkflowContentProps>) {
    const { order, context } = this.props;
    if (
      !_.isEqual(prevProps.order, order) ||
      !_.isEqual(
        prevProps.context.commodity.find((c) => c._id.toString() === order.commodity._id.toString()),
        context.commodity.find((c) => c._id.toString() === order.commodity._id.toString())
      )
    ) {
      await this.populateData();
    }
  }

  handleShipOrder = async () => {
    const { order } = this.props;
    this.setState({ processing: false });
    try {
      const res = await updateSampleOrder(
        { state: SAMO_STATE.SHIPPED },
        order._id,
        getSampleOrderTimelineEntry(SAMO_T_SHIPPED)
      );
      if (res && res.res && res.res.modifiedCount > 0) {
        toast.success("Sample order successfully shipped");
      } else {
        toast.error("Error shipping sample order");
      }
    } finally {
      this.setState({ processing: true });
    }
  };

  handleCloseOrder = async () => {
    const { order } = this.props;
    this.setState({ processing: false });
    try {
      const res = await updateSampleOrder(
        { state: SAMO_STATE.ARCHIVED },
        order._id,
        getSampleOrderTimelineEntry(SAMO_T_ARCHIVED)
      );
      if (res && res.res && res.res.modifiedCount > 0) {
        toast.success("Sample order successfully archived");
      } else {
        toast.error("Error archiving sample order");
      }
    } finally {
      this.setState({ processing: true });
    }
  };

  getDefaultState = (): SampleOrderWorkflowContentState => {
    return { amountOnStock: 0, incomingStock: {}, validPrices: 0, processing: false };
  };

  populateData = async () => {
    const { order, context } = this.props;
    const stockData = await getAvailableAndIncomingStock(order.commodity._id);
    const commodity = context.commodity.find((c) => c._id.toString() === order.commodity._id.toString());
    let validPrices = 0;
    if (commodity) {
      validPrices = commodity.suppliers.reduce(
        (sum, s) =>
          sum + s.prices.reduce((amount, p) => amount + (p.validUntil > new Date() && p.price > 0 ? 1 : 0), 0),
        0
      );
    }
    this.setState({ amountOnStock: stockData[0], incomingStock: stockData[1], validPrices });
  };

  render() {
    const { order, context } = this.props;
    const { amountOnStock, incomingStock, validPrices } = this.state;
    const incomingStockList = Object.entries(incomingStock);
    const isAV = isAnonymousContext(context);
    const canceled = order.timeline.find((t) => t.type === SAMO_T_CANCELED);
    const rejected = order.timeline.find((t) => t.type === SAMO_T_REJECTED);
    const confirmation = order.timeline.find((t) => t.type === SAMO_T_CONFIRMED);
    const shipped = order.timeline.find((t) => t.type === SAMO_T_SHIPPED);
    const invoices = !isAV
      ? context.invoice.filter((i) => i.type === I_SAMPLEINVOICE && i.relatedOrder === order._id.toString())
      : [];
    const archived = order.timeline.find((t) => t.type === SAMO_T_ARCHIVED);
    const { commodity, supplier } = order;
    const supplierName = getDocFromCollection(context.supplier, supplier || "")?.name || "Selected by RAWBIDS";
    const latestUpdate = getLastStateUpdate(order);

    return (
      <div>
        <div className="d-flex py-3 align-items-center w-100 justify-content-between">
          <div className="me-3">
            <div className="text-white fs-3 fw-bolder">Order Information</div>
          </div>
        </div>
        <div className="fs-6">
          <div className="d-flex flex-wrap py-5">
            <table className="table fw-bold gy-1">
              <tbody>
                <tr>
                  <td className="text-white w-25 min-w-125px">Reference</td>
                  <td className="text-muted w-25">
                    {order.customerReference ? (
                      order.customerReference
                    ) : (
                      <AddReferenceModal order={order} alwaysShowButton={true} />
                    )}
                  </td>
                  <td className="text-white w-25 min-w-125px">Commodity</td>
                  <td className="text-muted w-25">{truncateString(commodity.title.en, 20)}</td>
                </tr>
                <tr>
                  <td className="text-white w-25 min-w-125px">Status</td>
                  <td className="text-warning w-25">{getOrderStateDescriptions(order).title}</td>
                  <td className="text-white w-25 min-w-125px">Subtitle</td>
                  <td className="text-muted w-25">{truncateString(commodity.subtitle.en, 20) || "-"}</td>
                </tr>
                <tr>
                  <td className="text-white w-25 min-w-125px">Order Date</td>
                  <td className="text-muted w-25">
                    Week {getCW(order.createdAt) + " (" + formatDate(order.createdAt) + ")"}
                  </td>
                  <td className="text-white w-25 min-w-125px">Supplier</td>
                  <td className="text-muted w-25">{supplierName}</td>
                </tr>
                <tr>
                  <td className="text-white w-25 min-w-125px">Target Week</td>
                  <td className="text-muted w-25">Week {getCW(order.targetDate)}</td>
                  <td className="text-white w-25 min-w-125px">Amount</td>
                  <td className="text-muted w-25">{formatUnit(order.amount, order.unit)}</td>
                </tr>

                <tr>
                  <td className="text-white w-25 min-w-125px">Origin</td>
                  <td className="text-muted w-25">
                    {commodity?.country ? <CountryWidget countryCode={commodity.country.code} /> : "-"}
                  </td>

                  <td className="text-white w-25 min-w-125px">Total Price</td>
                  <td className="text-muted w-25">
                    {isAV ? (
                      <AnonymousBlurText>{formatCurrency(1.23, EURO)}</AnonymousBlurText>
                    ) : (
                      formatCurrency(order.totalPrice, order.currency)
                    )}
                  </td>
                </tr>

                <tr>
                  <td className="text-white w-25 min-w-125px">Incoterm</td>
                  <td className="text-muted w-25">DDP</td>
                  <td className="text-white w-25 min-w-125px">Note</td>
                  <td className="text-muted w-25">{order.noteCustomer.trim() ? order.noteCustomer : "-"}</td>
                </tr>
                <tr>
                  <td className="text-white w-25 min-w-125px">Destination</td>
                  <td className="text-muted w-25">
                    {order.destination === typeof "string" && order.destination.trim()
                      ? order.destination
                      : order.destination && isAddress(order.destination)
                      ? formatAddress(order.destination)
                      : "-"}
                  </td>
                  <td className="text-white w-25 min-w-125px">Last Update</td>
                  <td className="text-muted w-25">{formatDate(latestUpdate ? latestUpdate.date : order.createdAt)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {order.state === SAMO_STATE.REQUESTED && context.type === INTERNAL && (
          <>
            <div className="border-bottom-dark-gray pt-5" />
            <div className="fw-bolder text-white fs-3 my-5">Availability</div>
            <table className="table fw-bold gy-1">
              <tbody>
                <tr>
                  <td className="text-white w-25">Valid Supplier Prices</td>
                  <td className="text-muted w-25 text-left">{validPrices}</td>
                  <td className="text-white w-25">Amount on Stock</td>
                  <td className="text-muted w-25 text-left">
                    <div>
                      {amountOnStock}
                      {order.unit}
                    </div>
                    {incomingStockList.length > 0 &&
                      incomingStockList.slice(0, 2).map(([weeks, amount]) => {
                        return (
                          <div key={weeks} className="text-warning">
                            {`Additional ${amount}${order.unit} will arrive `}
                            {Number(weeks) > 1 ? `in approx. ${weeks} weeks` : `in the next days`}
                          </div>
                        );
                      })}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
        {confirmation && (
          <>
            <div className="border-bottom-dark-gray pt-5" />
            <div className="fw-bolder text-white fs-3 my-5">Confirmation Data</div>
            <table className="table fw-bold gy-1">
              <tbody>
                <tr>
                  <td className="text-white w-25">Confirmed By</td>
                  <td className="text-muted w-25 text-left">{searchUserName(confirmation.person, context.userData)}</td>
                  <td className="text-white w-25">Confirmation Date</td>
                  <td className="text-muted w-25 text-left">{formatDate(confirmation.date)}</td>
                </tr>
              </tbody>
            </table>
          </>
        )}
        {invoices.length > 0 && (
          <>
            <div className="border-bottom-dark-gray pt-5" />
            <div className="fw-bolder text-white fs-3 my-5">Invoices</div>
            <table className="table fw-bold gy-1">
              <tbody>
                {invoices.map((i) => (
                  <tr key={i._id.toString()}>
                    <td className="text-white align-middle w-25">INV-{i.invoiceNumber}</td>
                    <td className="text-white align-middle w-25">{formatDate(i.invoiceDate)}</td>
                    <td className="text-muted align-middle w-25">{formatCurrency(i.total, i.currency)}</td>
                    <td className="text-muted align-middle w-25">
                      <a href={resolveFilePath(i.file)} target="_blank" rel="noopener noreferrer">
                        <img alt="pdf" className="w-30px me-3" src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
        {shipped && (
          <>
            <div className="border-bottom-dark-gray pt-5" />
            <div className="fw-bolder text-white fs-3 my-5">Shipping Data</div>
            <table className="table fw-bold gy-1">
              <tbody>
                <tr>
                  <td className="text-white w-25">Shipped By</td>
                  <td className="text-muted w-25 text-left">{searchUserName(shipped.person, context.userData)}</td>
                  <td className="text-white w-25">Shipping Date</td>
                  <td className="text-muted w-25 text-left">{formatDate(shipped.date)}</td>
                </tr>
              </tbody>
            </table>
          </>
        )}
        {(canceled || rejected) && (
          <>
            <div className="border-bottom-dark-gray pt-5" />
            <div className="fw-bolder text-white fs-3 my-5">{canceled ? "Cancelation" : "Rejection"} Data</div>
            <table className="table fw-bold gy-1">
              <tbody>
                <tr>
                  {canceled ? (
                    <>
                      <td className="text-white w-25">Canceled By</td>
                      <td className="text-muted w-25 text-left">{searchUserName(canceled.person, context.userData)}</td>
                    </>
                  ) : (
                    <>
                      <td className="text-white w-25">Rejection Reason</td>
                      <td className="text-muted w-25 text-left">{rejected?.payload?.reason ?? "-"}</td>
                    </>
                  )}
                  <td className="text-white w-25">{canceled ? "Cancelation" : "Rejection"} Date</td>
                  <td className="text-muted w-25 text-left">{formatDate((canceled || rejected)!.date)}</td>
                </tr>
              </tbody>
            </table>
          </>
        )}
        {archived && (
          <>
            <div className="border-bottom-dark-gray pt-5" />
            <div className="fw-bolder text-white fs-3 my-5">Archive Data</div>
            <table className="table fw-bold gy-1">
              <tbody>
                <tr>
                  <td className="text-white w-25">Archived By</td>
                  <td className="text-muted w-25 text-left">{searchUserName(archived.person, context.userData)}</td>
                  <td className="text-white w-25">Archiving Date</td>
                  <td className="text-muted w-25 text-left">{formatDate(archived.date)}</td>
                </tr>
              </tbody>
            </table>
          </>
        )}
        {order.state === SAMO_STATE.CONFIRMED && context.type === INTERNAL && (
          <>
            <div className="border-bottom-dark-gray pt-5" />
            <SimpleConfirmationModal.SimpleConfirmationModalButton
              onConfirm={this.handleShipOrder}
              size="lg"
              modalTitle="Ship sample order to customer"
              modalDescription={
                <span className="text-white">Please confirm that the sample order was shipped to the customer</span>
              }
              confirmButtonText="Confirm"
              buttonText="Shipped"
              cancelButtonText="Cancel"
              buttonClasses="btn btn-outline btn-outline-light btn-sm mt-6 float-right"
              disabled={order.state !== SAMO_STATE.CONFIRMED}
            />
          </>
        )}
        {order.state === SAMO_STATE.SHIPPED && context.type === INTERNAL && (
          <>
            <div className="border-bottom-dark-gray pt-5" />
            <SimpleConfirmationModal.SimpleConfirmationModalButton
              onConfirm={this.handleCloseOrder}
              size="lg"
              modalTitle="Archive sample order"
              modalDescription={
                <span className="text-white">Please confirm that the sample order is ready to be archived</span>
              }
              confirmButtonText="Confirm"
              buttonText="Archive"
              cancelButtonText="Cancel"
              buttonClasses="btn btn-outline btn-outline-light btn-sm mt-6 float-right"
              disabled={order.state !== SAMO_STATE.SHIPPED}
            />
          </>
        )}
      </div>
    );
  }
}

export default SampleOrderWorkflowContent;
