import _ from "lodash";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import validator from "validator";
import { Input } from "../../../common/Input";
import { Notify } from "../../../../model/notify.types";
import { insertNotify, updateNotify } from "../../../../utils/notifyUtils";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import { Address, AddressType } from "../../../../model/commonTypes";
import { getDefaultAddress, isAddressTooShort } from "../../../../utils/addressUtils";
import AddressInputGroup from "../../../common/AddressInputGroup";
import { SelectOption } from "../../../common/CustomSelect";
import { DataContextInternal } from "../../../../context/dataContext";
import CreatePropertyModal from "../../../properties/internal/modals/CreatePropertyModal";
import { ADDRESSPROPERTYOPTIONS, PropertyType } from "../../../../utils/propertyUtils";

interface AddNotifyProps {
  edit: boolean;
  notify?: Notify;
  context: React.ContextType<typeof DataContextInternal>;
}

interface AddNotifyState {
  show: boolean;
  companyName: string;
  contactPerson: string;
  address: Address;
  showPropertyCreationModal: boolean;
  telephone: string;
  email: string;
  saving: boolean;
}

class AddNotify extends PureComponent<AddNotifyProps, AddNotifyState> {
  constructor(props: AddNotifyProps) {
    super(props);
    this.state = this.getDefaultState(false);
  }

  handleShow = () => this.setState(this.getDefaultState(true));

  handleHide = () => this.setState({ show: false });

  handleSave = async () => {
    const { edit, notify } = this.props;
    const { companyName, contactPerson, address, telephone, email } = this.state;
    this.setState({ saving: true });
    try {
      let res;
      if (edit && notify) {
        res = await updateNotify({ _id: notify._id, companyName, contactPerson, address, telephone, email });
      } else {
        res = await insertNotify({ _id: new BSON.ObjectId(), companyName, contactPerson, address, telephone, email });
      }
      if (res) {
        toast.success("Notify successfully saved");
        this.setState({ show: false });
      } else {
        toast.error("Saving notify failed");
      }
    } catch (e) {
      toast.error("Saving notify failed. Error: " + e);
    } finally {
      this.setState({ saving: false });
    }
  };

  handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const address = _.cloneDeep(this.state.address);
    // @ts-ignore
    address[name] = value;
    this.setState({ address });
  };

  handleChangeAddressType = (type: SelectOption) => {
    const address = _.cloneDeep(this.state.address);
    address.type = type.value as AddressType;
    this.setState({ address });
  };

  handleChangeAddressCountry = (country: SelectOption) => {
    const address = _.cloneDeep(this.state.address);
    address.country = country.value;
    this.setState({ address });
  };

  handleChangeAddressProperty = (properties: Array<SelectOption>) => {
    const address = _.cloneDeep(this.state.address);
    if (properties.some((option) => option.value === "new")) {
      this.setState({ showPropertyCreationModal: true });
    } else {
      address.properties = properties.map((prop) => prop.value);
      this.setState({ address });
    }
  };

  handleChangeOpeningHours = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const address = _.cloneDeep(this.state.address);
    address.openingHours = e.target.value;
    this.setState({ address });
  };

  getDefaultState = (show: boolean) => {
    const { edit, notify } = this.props;
    if (edit && notify) {
      return {
        show,
        companyName: notify.companyName,
        contactPerson: notify.contactPerson,
        address: notify.address,
        showPropertyCreationModal: false,
        telephone: notify.telephone,
        email: notify.email,
        saving: false,
      };
    } else {
      return {
        show,
        companyName: "",
        contactPerson: "",
        address: getDefaultAddress(undefined, AddressType.A_PRIMARY),
        showPropertyCreationModal: false,
        telephone: "",
        email: "",
        saving: false,
      };
    }
  };

  validateData = () => {
    const { companyName, contactPerson, address, telephone, email } = this.state;
    const errors: Array<string> = [];
    if (companyName.trim() === "") errors.push("Company name is missing");
    if (contactPerson.trim() === "") errors.push("Contact person is missing");
    if (isAddressTooShort(address)) errors.push("Address too short");
    if (telephone.trim() === "") errors.push("Telephone number is missing");
    else if (telephone.trim().length < 3) errors.push("Phone number too short");
    if (email.trim() === "") errors.push("Email is missing");
    else if (!validator.isEmail(email)) errors.push("Email is not valid");
    return errors;
  };

  render() {
    const { show, companyName, contactPerson, address, showPropertyCreationModal, telephone, email, saving } =
      this.state;
    const { edit, context } = this.props;
    return (
      <>
        <CreatePropertyModal
          onlyModal={true}
          show={showPropertyCreationModal}
          onHide={() => this.setState({ showPropertyCreationModal: false })}
          type={
            ADDRESSPROPERTYOPTIONS.find((po) => po.value === PropertyType.DELIVERYINSTRUCTIONS) as {
              value: PropertyType;
              label: string;
            }
          }
        />
        <button className="btn btn-sm btn-outline btn-outline-light" onClick={this.handleShow}>
          {edit ? "Edit" : "New Notify"}
        </button>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">{edit ? "Edit" : "New"} notify</h1>
            </Modal.Title>
            <CloseButton variant={"white"} onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-5 ">
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="required fs-5 fw-bold mb-2">Notify Companyname</label>
                <Input
                  type="text"
                  className="form-control custom-form-control"
                  placeholder="Notify Companyname"
                  name="supplier"
                  value={companyName}
                  onChange={(e) => this.setState({ companyName: e.target.value })}
                />
              </div>
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="required fs-5 fw-bold mb-2">Notify Contact Person</label>
                <Input
                  type="text"
                  className="form-control custom-form-control"
                  placeholder="Notify Contact Person"
                  name="supplier"
                  value={contactPerson}
                  onChange={(e) => this.setState({ contactPerson: e.target.value })}
                />
              </div>
              <div className="col-md-12 mt-3 fv-row fv-plugins-icon-container">
                <label className="required fs-5 fw-bold mb-2">Notify Address</label>
                <AddressInputGroup
                  address={address}
                  context={context}
                  fixedType={AddressType.A_PRIMARY}
                  onChangeAddress={this.handleChangeAddress}
                  onChangeAddressType={this.handleChangeAddressType}
                  onChangeAddressCountry={this.handleChangeAddressCountry}
                  onChangeAddressProperty={this.handleChangeAddressProperty}
                  onChangeOpeningHours={this.handleChangeOpeningHours}
                />
              </div>
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="required fs-5 fw-bold mb-2">Notify Telephone</label>
                <Input
                  type="text"
                  className="form-control custom-form-control"
                  placeholder="Phone number"
                  name="supplier"
                  value={telephone}
                  onChange={(e) => this.setState({ telephone: e.target.value })}
                />
              </div>
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="required fs-5 fw-bold mb-2">Notify Contact Mail</label>
                <Input
                  type="text"
                  className="form-control custom-form-control"
                  placeholder="Mail address"
                  name="supplier"
                  value={email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-outline btn-text-white" onClick={this.handleHide}>
              Close
            </button>
            <ErrorOverlayButton
              className="btn btn-sm btn-outline btn-outline-light"
              buttonText={"Save"}
              errors={this.validateData()}
              onClick={this.handleSave}
              saving={saving}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default AddNotify;
