import _ from "lodash";
import React, { useCallback, useContext, useMemo, useState } from "react";
import Search from "../../../common/Search";
import CustomerRequestFilter from "./CustomerRequestFilter";
import { SelectOption } from "../../../common/CustomSelect";
import { SortOption, SORTORDEROPTIONS } from "../../../../utils/filterUtils";
import { CR_AGEOPTIONS, CR_SORTOPTIONS, CustomerRequestAge } from "../../../../utils/customerRequestUtils";
import BaseListing from "../../../common/BaseListing";
import { DataContextInternal } from "../../../../context/dataContext";
import { DAY_IN_MS } from "../../../../utils/dateUtils";
import { paginate, PaginationState } from "../../../common/Pagination";
import CustomerRequestRow from "./CustomerRequestRow";

interface CustomerRequestListState extends PaginationState {
  search: string;
  filterBy: SelectOption;
  sortBy: SelectOption;
  sortOrder: SortOption;
}

const getDefaultState = (): CustomerRequestListState => {
  return {
    search: "",
    filterBy: CR_AGEOPTIONS[0],
    sortBy: CR_SORTOPTIONS[0],
    sortOrder: SORTORDEROPTIONS[1],
    currentPage: 1,
    pageSize: 25,
  };
};

const CustomerRequestList: React.FC = () => {
  const context = useContext(DataContextInternal);

  const [state, setState] = useState<CustomerRequestListState>(getDefaultState());
  const { search, filterBy, sortBy, sortOrder, currentPage, pageSize } = state;

  const handleChangeSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, search: e.target.value, currentPage: 1 }));
  }, []);

  const handleChangeFilterBy = useCallback((filterBy: SelectOption) => {
    setState((prevState) => ({ ...prevState, filterBy, currentPage: 1 }));
  }, []);

  const handleChangeSortBy = useCallback((sortBy: SelectOption) => {
    setState((prevState) => ({ ...prevState, sortBy, currentPage: 1 }));
  }, []);

  const handleChangeSortOrder = useCallback((sortOrder: SortOption) => {
    setState((prevState) => ({ ...prevState, sortOrder }));
  }, []);

  const handleChangePageSize = useCallback((pageSize: number) => {
    setState((prevState) => ({ ...prevState, pageSize: pageSize, currentPage: 1 }));
  }, []);

  const handleChangeCurrentPage = useCallback((currentPage: number) => {
    setState((prevState) => ({ ...prevState, currentPage: currentPage }));
  }, []);

  const filteredCustomerRequests = useMemo(() => {
    let customerRequests = context.customerRequest;
    const now = new Date().getTime();
    switch (filterBy.value) {
      case CustomerRequestAge.LESS_THAN_48H:
        customerRequests = customerRequests.filter((cR) => cR.requestedOn.getTime() > now - 2 * DAY_IN_MS);
        break;
      case CustomerRequestAge.LESS_THAN_1_WEEK:
        customerRequests = customerRequests.filter((cR) => cR.requestedOn.getTime() >= now - 7 * DAY_IN_MS);
        break;
      case CustomerRequestAge.OLD:
        customerRequests = customerRequests.filter((cR) => cR.requestedOn.getTime() < now - 7 * DAY_IN_MS);
        break;
    }
    return customerRequests;
  }, [context.customerRequest, search, filterBy]);

  const sortedCustomerRequests = useMemo(() => {
    return _.orderBy(filteredCustomerRequests, sortBy.value, sortOrder.value);
  }, [filteredCustomerRequests, sortBy, sortOrder]);

  const crPaginated = useMemo(
    () => paginate(sortedCustomerRequests, currentPage, pageSize),
    [sortedCustomerRequests, currentPage, pageSize]
  );

  const headerDefinition = useMemo(
    () => [
      { title: "Request" },
      { title: "Type" },
      { title: "Quantity" },
      { title: "Company & Article", style: { width: "30%" } },
      { title: "Message", style: { width: "30%" } },
      { title: "Status" },
      { title: "" },
    ],
    []
  );

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-6">
          <Search placeholder="Search for customer requests..." onSearch={handleChangeSearch} value={search} />
        </div>
      </div>
      <CustomerRequestFilter
        filterBy={filterBy}
        sortBy={sortBy}
        sortOrder={sortOrder}
        onChangeFilterBy={handleChangeFilterBy}
        onChangeSortBy={handleChangeSortBy}
        onChangeSortOrder={handleChangeSortOrder}
      />
      <BaseListing
        headerDefinition={headerDefinition}
        bodyContent={
          <>
            {crPaginated.length > 0 ? (
              crPaginated.map((cr) => <CustomerRequestRow key={cr._id.toString()} customerRequest={cr} />)
            ) : (
              <tr>
                <td className="text-center" colSpan={7}>
                  No orders found
                </td>
              </tr>
            )}
          </>
        }
        documents={sortedCustomerRequests}
        currentPage={currentPage}
        pageSize={pageSize}
        baseSize={25}
        onPageChange={handleChangeCurrentPage}
        onPageSizeChange={handleChangePageSize}
      />
    </>
  );
};

export default CustomerRequestList;
