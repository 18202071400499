import _ from "lodash";
import { BSON } from "realm-web";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import React, { PureComponent } from "react";
import { DataContextAnonymousType, DataContextCustomerType } from "../../../context/dataContext";
import NewsTags from "../common/NewsTags";
import NewsPreview from "../../common/NewsPreview";
import { Article } from "../../../model/article.types";
import { formatDate, getDocFromCollection } from "../../../utils/baseUtils";
import { getDocumentDB, NEWS } from "../../../services/dbService";
import { getUserName } from "../../../utils/userUtils";
import { getNews } from "../../../utils/articleUtils";

interface NewsArticlePageParams {
  id: string;
}

interface NewsArticlePageProps extends RouteComponentProps<NewsArticlePageParams> {
  context: DataContextCustomerType | DataContextAnonymousType;
}

interface NewsArticlePageState {
  article?: Article;
}

/**
 * Function component to represents the news in a specific page (customer view).
 */
class NewsArticlePage extends PureComponent<NewsArticlePageProps, NewsArticlePageState> {
  _isMounted = false;

  constructor(props: NewsArticlePageProps) {
    super(props);
    const id = props.match.params.id;
    let article;
    if (id && BSON.ObjectId.isValid(id)) article = getDocFromCollection(props.context.news, id);
    this.state = {
      article,
    };
  }

  componentDidMount = async () => {
    const { match, history, context } = this.props;
    if (this.state.article) return;
    const id = match.params.id;
    if (!id || !BSON.ObjectId.isValid(id)) {
      history.push("/news");
      return;
    }
    this._isMounted = true;
    const article = await getDocumentDB<Article>(NEWS, id);
    if (!article) {
      console.error("No article could be loaded for id", id);
      toast.error("The requested article could not be loaded");
      history.push("/news");
      return;
    }
    context.addDocuments(NEWS, [article]);
    if (this._isMounted) this.setState({ article });
  };

  componentDidUpdate(prevProps: Readonly<NewsArticlePageProps>) {
    const { match, context } = this.props;
    if (match.params.id || (!match.params.id && prevProps.match.params.id)) {
      const article = getDocFromCollection(context.news, match.params.id);
      if (!_.isEqual(article, this.state.article)) {
        this.setState({
          article,
        });
      }
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    const { context, history } = this.props;
    const { article } = this.state;
    if (!article) return null;
    const author = getDocFromCollection(context.userData, article.createdBy);
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="card bg-white">
              <div className="card-body">
                <button className="btn btn-light" onClick={history.goBack}>
                  <i className="fa fa-arrow-left mr-1" />
                  Back
                </button>
                <div className="w-60 mx-auto mt-20">
                  <span className="card-label fw-bolder mb-3 fs-2-5rem">{article.title}</span>
                  <div className="row mb-20">
                    <div className="fw-semibold fs-4 text-muted text-white mt-3" style={{ whiteSpace: "pre-wrap" }}>
                      {article.newsText}
                    </div>
                    <div className="fs-6 fw-bold mt-3 d-flex flex-stack">
                      <span>
                        <b>{author ? getUserName(author) : "RAWBIDS GmbH"}</b>
                        <span className="text-muted ml-2">{formatDate(article.creationTime)}</span>
                      </span>
                    </div>
                    <NewsTags tags={article.tags} className="mt-2 ml-2" />
                  </div>
                </div>
                <span className="text-white  p-3 fs-2">Other Articles</span>
                <div className="row">
                  {getNews(context.news)
                    .sort((a, b) => {
                      return b.creationTime.getTime() - a.creationTime.getTime();
                    })
                    .filter((a) => a._id.toString() !== article._id.toString())
                    .slice(0, 3)
                    .map((a) => (
                      <NewsPreview article={a} context={context} key={a._id.toString()} size={"md"} />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(NewsArticlePage);
