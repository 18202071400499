import _ from "lodash";
import { BSON } from "realm-web";
import {
  DataContextAnonymousType,
  DataContextCustomerType,
  DataContextInternalType,
  DataContextSupplierType,
  DefaultDataContextAnonymous,
  DefaultDataContextCustomer,
  DefaultDataContextInternal,
  DefaultDataContextSupplier,
} from "../context/dataContext";
import { ActiveSubstance } from "../model/activeSubstance.types";
import { Airport } from "../model/airport.types";
import { Batch } from "../model/batch.types";
import { Commodity } from "../model/commodity.types";
import { CommodityOfferRequest, CORState } from "../model/commodityOfferRequest.types";
import { Company } from "../model/company.types";
import { CO_CANCELED, CustomerOrder, T_AIRFREIGHT, T_SEAFREIGHT } from "../model/customerOrder.types";
import { Favorites } from "../model/favorites.types";
import { General } from "../model/general.types";
import { Invoice } from "../model/invoice.types";
import { Article } from "../model/article.types";
import { Notification } from "../model/notification.types";
import { Notify } from "../model/notify.types";
import { PriceHistory } from "../model/priceHistory.types";
import { Property } from "../model/property.types";
import { SAMO_STATE, SampleOrder } from "../model/sampleOrder.types";
import { Seaport } from "../model/seaport.types";
import { Service } from "../model/service.types";
import { Supplier } from "../model/supplier.types";
import { SO_CANCELED, SupplierOrder } from "../model/supplierOrder.types";
import { UserData } from "../model/userData.types";
import { VersionHistory } from "../model/versionHistory.types";
import { CustomerCommodity } from "../model/customer/customerCommodity.types";
import {
  ACTIVESUBSTANCE,
  AIRPORT,
  BATCH,
  COMMODITY,
  COMMODITYOFFERREQUEST,
  COMMODITYSTATISTICS,
  COMPANY,
  CONFIGURATION,
  CONTROLLINGSTATISTICS,
  CUSTOMERCONTRACT,
  CUSTOMERORDER,
  CUSTOMERREQUEST,
  CUSTOMERSTATISTICS,
  FAVORITES,
  FINISHEDPRODUCT,
  FORWARDER,
  FORWARDINGORDER,
  GENERAL,
  getCollectionDB,
  getCollectionDBWithQuery,
  INVOICE,
  NEWS,
  NOTIFICATION,
  NOTIFY,
  PRICEGRAPH,
  PRICEHISTORY,
  PROPERTY,
  SAMPLEORDER,
  SEAPORT,
  SERVICE,
  STORAGEORDER,
  SUPPLIER,
  SUPPLIERORDER,
  SUPPLIERSTATISTICS,
  SYSTEMNOTIFICATION,
  USERDATA,
  VERSIONHISTORY,
  WATCHLIST,
} from "../services/dbService";
import { CustomerCustomerOrder } from "../model/customer/customerCustomerOrder.types";
import { CustomerSampleOrder } from "../model/customer/customerSampleOrder.types";
import { SupplierSupplier } from "../model/supplier/supplierSupplier.types";
import { SupplierSupplierOrder } from "../model/supplier/supplierSupplierOrder.types";
import { CC_STATE, CustomerContract } from "../model/customerContract.types";
import { Watchlist } from "../model/watchlist.types";
import { CustomerCustomerContract } from "../model/customer/customerCustomerContract.types";
import { CustomerSupplier } from "../model/customer/customerSupplier.types";
import { ControllingStatistics } from "../model/statistics/controllingStatistics.types";
import { CustomerRequest, CustomerRequestState } from "../model/customerRequest.types";
import { CustomerBatch } from "../model/customer/customerBatch.types";
import { CustomerSupplierOrder } from "../model/customer/customerSupplierOrder.types";
import { CustomerNotify } from "../model/customer/customerNotify.types";
import { ForwardingOrder } from "../model/forwardingOrder.types";
import { StorageOrder } from "../model/storageOrder.types";
import { SystemNotification } from "../model/systemNotification.types";
import { FinishedProduct } from "../model/finishedProduct.types";
import { CustomerFinishedProduct } from "../model/customer/customerFinishedProduct.types";
import {
  cOStatesForDemoData,
  generateCustomerContract,
  generateCustomerOrder,
  generateCustomerRequest,
  generateSampleOrder,
} from "./anonymousViewUtils";
import { AddressType } from "../model/commonTypes";
import { Forwarder } from "../model/forwarder.types";
import { PriceGraph } from "../model/priceGraph.types";
import { BaseConfiguration } from "../model/configuration/configuration.types";
import { extendCustomerCommodity } from "./dataTransformationUtils";

export type COLLECTIONTYPE =
  | typeof ACTIVESUBSTANCE
  | typeof AIRPORT
  | typeof BATCH
  | typeof COMMODITY
  | typeof COMMODITYSTATISTICS
  | typeof COMPANY
  | typeof CONFIGURATION
  | typeof CUSTOMERORDER
  | typeof CUSTOMERCONTRACT
  | typeof CUSTOMERREQUEST
  | typeof CUSTOMERSTATISTICS
  | typeof FINISHEDPRODUCT
  | typeof FORWARDER
  | typeof FORWARDINGORDER
  | typeof GENERAL
  | typeof INVOICE
  | typeof NEWS
  | typeof NOTIFICATION
  | typeof NOTIFY
  | typeof PRICEHISTORY
  | typeof PRICEGRAPH
  | typeof PROPERTY
  | typeof SERVICE
  | typeof STORAGEORDER
  | typeof SUPPLIERORDER
  | typeof SAMPLEORDER
  | typeof SEAPORT
  | typeof SUPPLIER
  | typeof SUPPLIERSTATISTICS
  | typeof USERDATA
  | typeof FAVORITES
  | typeof COMMODITYOFFERREQUEST
  | typeof VERSIONHISTORY
  | typeof WATCHLIST
  | typeof CONTROLLINGSTATISTICS
  | typeof SYSTEMNOTIFICATION;

export type DATABASE_DOCUMENT =
  | ActiveSubstance
  | Airport
  | Batch
  | BaseConfiguration
  | Commodity
  | CommodityOfferRequest
  | Company
  | ControllingStatistics
  | CustomerOrder
  | CustomerContract
  | CustomerRequest
  | CustomerSupplier
  | Favorites
  | FinishedProduct
  | Forwarder
  | ForwardingOrder
  | General
  | Invoice
  | Article
  | Notification
  | Notify
  | CustomerNotify
  | PriceHistory
  | PriceGraph
  | Property
  | SampleOrder
  | Seaport
  | Service
  | StorageOrder
  | Supplier
  | SupplierOrder
  | UserData
  | VersionHistory
  | Watchlist
  | SystemNotification;

export type DATA_TYPES =
  | ActiveSubstance
  | Airport
  | Batch
  | CustomerBatch
  | BaseConfiguration
  | Commodity
  | CustomerCommodity
  | CommodityOfferRequest
  | Company
  | ControllingStatistics
  | CustomerOrder
  | CustomerContract
  | CustomerCustomerOrder
  | CustomerCustomerContract
  | CustomerRequest
  | CustomerSupplier
  | Favorites
  | FinishedProduct
  | CustomerFinishedProduct
  | Forwarder
  | ForwardingOrder
  | General
  | Invoice
  | Article
  | Notification
  | Notify
  | CustomerNotify
  | PriceHistory
  | PriceGraph
  | Property
  | SampleOrder
  | CustomerSampleOrder
  | Seaport
  | Service
  | StorageOrder
  | Supplier
  | SupplierSupplier
  | SupplierOrder
  | CustomerSupplierOrder
  | SupplierSupplierOrder
  | UserData
  | VersionHistory
  | Watchlist
  | SystemNotification;

/**
 * Builds the internal context.
 * @returns { Promise<DataContextInternalType> } Internal database context
 */
export async function buildInternalContext(): Promise<DataContextInternalType> {
  // The order in this list represents the expected executions durations in the backend. The bigger the collection
  // the earlier we want to send the request.
  const collections: Array<COLLECTIONTYPE> = [
    CONFIGURATION,
    COMMODITY,
    USERDATA,
    CUSTOMERORDER,
    CUSTOMERCONTRACT,
    CUSTOMERREQUEST,
    SUPPLIERORDER,
    SAMPLEORDER,
    FINISHEDPRODUCT,
    PRICEGRAPH,
    FORWARDINGORDER,
    STORAGEORDER,
    COMMODITYOFFERREQUEST,
    INVOICE,
    SUPPLIER,
    COMPANY,
    FORWARDER,
    ACTIVESUBSTANCE,
    PROPERTY,
    BATCH,
    NEWS,
    SERVICE,
    SEAPORT,
    AIRPORT,
    NOTIFICATION,
    GENERAL,
    VERSIONHISTORY,
    NOTIFY,
    CONTROLLINGSTATISTICS,
    SYSTEMNOTIFICATION,
  ];
  // Send requests to the backend
  const collectionPromises: { [key: string]: Promise<Array<DATABASE_DOCUMENT>> } = {};
  for (const key of collections) {
    switch (key) {
      case CUSTOMERORDER:
        collectionPromises[key] = getCollectionDBWithQuery(key, { state: { $ne: CO_CANCELED } });
        break;
      case SUPPLIERORDER:
        collectionPromises[key] = getCollectionDBWithQuery(key, { state: { $ne: SO_CANCELED } });
        break;
      case SAMPLEORDER:
        collectionPromises[key] = getCollectionDBWithQuery(key, {
          state: { $nin: [SAMO_STATE.CANCELED, SAMO_STATE.REJECTED] },
        });
        break;
      case COMMODITYOFFERREQUEST:
        collectionPromises[key] = getCollectionDBWithQuery(key, {
          state: { $nin: [CORState.CANCELED, CORState.APPROVED, CORState.REJECTED] },
        });
        break;
      case CUSTOMERCONTRACT:
        collectionPromises[key] = getCollectionDBWithQuery(key, { state: { $ne: CC_STATE.CANCELED } });
        break;
      default:
        collectionPromises[key] = getCollectionDB(key);
    }
  }
  // Await all backend calls and fill the context with database objects
  const dataContextInternal = _.cloneDeep(DefaultDataContextInternal);

  for (const key of collections) {
    dataContextInternal[
      key as keyof Omit<
        DataContextInternalType,
        | "type"
        | "currentView"
        | "innerWidth"
        | "savedState"
        | "refMap"
        | "saveComponentState"
        | "saveRef"
        | "deleteRef"
        | "addDocuments"
        | "currencies"
      >
    ] = (await collectionPromises[key]) as Array<any>; // Need to use any here since it can be one of the backend documents
  }

  return {
    ...dataContextInternal,
  };
}

/**
 * Builds the customer context.
 * @returns { Promise<DataContextCustomerType> } Customer database context
 */
export async function buildCustomerContext(): Promise<DataContextCustomerType> {
  // The order in this list represents the expected executions durations in the backend. The bigger the collection
  // the earlier we want to send the request.
  const collections: Array<COLLECTIONTYPE> = [
    COMMODITY,
    BATCH,
    SUPPLIER,
    CUSTOMERCONTRACT,
    CUSTOMERORDER,
    FINISHEDPRODUCT,
    PRICEGRAPH,
    SUPPLIERORDER,
    CUSTOMERREQUEST,
    USERDATA,
    NEWS,
    ACTIVESUBSTANCE,
    PROPERTY,
    SERVICE,
    COMPANY,
    SAMPLEORDER,
    INVOICE,
    NOTIFICATION,
    FAVORITES,
    NOTIFY,
    VERSIONHISTORY,
    WATCHLIST,
    SYSTEMNOTIFICATION,
  ];
  // Send requests to the backend
  const collectionPromises: { [key: string]: Promise<Array<DATABASE_DOCUMENT>> } = {};
  for (const key of collections) {
    switch (key) {
      case SUPPLIERORDER:
        collectionPromises[key] = getCollectionDBWithQuery(key, { state: { $ne: SO_CANCELED } });
        break;
      default:
        collectionPromises[key] = getCollectionDB(key);
    }
  }
  // Await all backend calls and fill the context with database objects
  const dataContextCustomer = _.cloneDeep(DefaultDataContextCustomer);

  for (const key of collections) {
    dataContextCustomer[
      key as keyof Omit<
        DataContextCustomerType,
        | "type"
        | "innerWidth"
        | "savedState"
        | "refMap"
        | "saveComponentState"
        | "saveRef"
        | "deleteRef"
        | "addDocuments"
        | "currencies"
      >
    ] = (await collectionPromises[key]) as Array<any>; // Need to use any here since it can be one of the backend documents
  }

  return {
    ...dataContextCustomer,
  };
}

/**
 * Builds the customer context.
 * @returns { Promise<DataContextCustomerType> } Customer database context
 */
export async function buildAnonymousContext(): Promise<DataContextAnonymousType> {
  // The order in this list represents the expected executions durations in the backend. The bigger the collection
  // the earlier we want to send the request.
  const collections: Array<COLLECTIONTYPE> = [
    COMMODITY,
    FINISHEDPRODUCT,
    PRICEGRAPH,
    BATCH,
    SUPPLIER,
    CUSTOMERCONTRACT,
    CUSTOMERORDER,
    SUPPLIERORDER,
    CUSTOMERREQUEST,
    USERDATA,
    NEWS,
    ACTIVESUBSTANCE,
    PROPERTY,
    SERVICE,
    INVOICE,
    COMPANY,
    SAMPLEORDER,
    NOTIFICATION,
    FAVORITES,
    NOTIFY,
    VERSIONHISTORY,
    WATCHLIST,
    SYSTEMNOTIFICATION,
  ];
  // Send requests to the backend
  const collectionPromises: { [key: string]: Promise<Array<DATABASE_DOCUMENT>> } = {};

  for (const key of collections) {
    switch (key) {
      case COMMODITY:
        collectionPromises[COMMODITY] = getCollectionDBWithQuery(key, {
          disabled: false,
          approved: true,
        });
        break;
      case FINISHEDPRODUCT:
        collectionPromises[FINISHEDPRODUCT] = getCollectionDBWithQuery(key, {
          disabled: false,
          approved: true,
        });
        break;
      case CUSTOMERORDER:
      case SUPPLIERORDER:
      case SAMPLEORDER:
      case CUSTOMERCONTRACT:
      case CUSTOMERREQUEST:
      case NOTIFY:
        break;
      default:
        collectionPromises[key] = getCollectionDB(key);
    }
  }
  // Await all backend calls and fill the context with database objects
  const dataContextAnonymous = _.cloneDeep(DefaultDataContextAnonymous);

  for (const key of collections) {
    switch (key) {
      case CUSTOMERORDER:
      case SUPPLIERORDER:
      case SAMPLEORDER:
      case CUSTOMERCONTRACT:
      case CUSTOMERREQUEST:
      case NOTIFY:
        break;
      default:
        dataContextAnonymous[
          key as keyof Omit<
            DataContextAnonymousType,
            | "type"
            | "innerWidth"
            | "savedState"
            | "refMap"
            | "saveComponentState"
            | "saveRef"
            | "deleteRef"
            | "addDocuments"
            | "currencies"
            | "configuration"
          >
        ] = (await collectionPromises[key]) as Array<any>; // Need to use any here since it can be one of the backend documents
    }
  }

  // We need extended commodities all the time - thus extend them one time
  const extendedCommodities = dataContextAnonymous.commodity.map((c) =>
    extendCustomerCommodity(c, dataContextAnonymous)
  );
  for (const key of collections) {
    switch (key) {
      case CUSTOMERORDER:
        extendedCommodities.forEach((c, idx) => {
          const { co, so, inv } = generateCustomerOrder(
            c,
            idx,
            Math.random() > 0.5 ? T_SEAFREIGHT : T_AIRFREIGHT,
            cOStatesForDemoData[Math.floor(Math.random() * cOStatesForDemoData.length)]
          );
          dataContextAnonymous.customerOrder.push(co);
          if (so) dataContextAnonymous.supplierOrder.push(so);
          if (inv) dataContextAnonymous.invoice.push(inv);
        });
        break;
      case SAMPLEORDER:
        extendedCommodities
          .filter((c) => c.sampleSize && c.sampleSize.length > 0)
          .forEach((com, idx) => {
            const samoStates = Object.values(SAMO_STATE);
            const samo = generateSampleOrder(com, idx, samoStates[Math.floor(Math.random() * samoStates.length)]);
            dataContextAnonymous.sampleOrder.push(samo);
          });
        break;
      case CUSTOMERCONTRACT:
        extendedCommodities.forEach((c, idx) => {
          const ccStates = Object.values(CC_STATE);
          const { cc, co } = generateCustomerContract(c, idx, ccStates[Math.floor(Math.random() * ccStates.length)]);
          dataContextAnonymous.customerContract.push(cc);
          if (co.length > 0) {
            co.forEach((cO) => dataContextAnonymous.customerOrder.push(cO));
          }
        });
        break;
      case CUSTOMERREQUEST:
        extendedCommodities.forEach((c, idx) => {
          const reqStates = Object.values(CustomerRequestState);
          const req = generateCustomerRequest(c, idx, reqStates[Math.floor(Math.random() * reqStates.length)]);
          dataContextAnonymous.customerRequest.push(req);
        });
        break;
      case NOTIFY:
        dataContextAnonymous.notify = [
          {
            _id: new BSON.ObjectId("000000000000000000000000"),
            address: {
              _id: new BSON.ObjectId(),
              type: AddressType.A_PRIMARY,
              street: "Hauptstraße",
              houseNo: "1",
              postalCode: "29439",
              city: "Lüchow",
              name: "",
              country: "DE",
            },
          },
        ];
        break;
    }
  }
  return {
    ...dataContextAnonymous,
  };
}

/**
 * Builds the supplier context.
 * @returns { Promise<DataContextSupplierType> } Supplier database context
 */
export async function buildSupplierContext(): Promise<DataContextSupplierType> {
  // The order in this list represents the expected executions durations in the backend. The bigger the collection
  // the earlier we want to send the request.
  const collections: Array<COLLECTIONTYPE> = [
    USERDATA,
    ACTIVESUBSTANCE,
    PROPERTY,
    SUPPLIER,
    SUPPLIERORDER,
    SEAPORT,
    AIRPORT,
    COMMODITY,
    FINISHEDPRODUCT,
    PRICEGRAPH,
    INVOICE,
    NOTIFICATION,
    GENERAL,
    FAVORITES,
    COMMODITYOFFERREQUEST,
    VERSIONHISTORY,
    SYSTEMNOTIFICATION,
  ];
  // Send requests to the backend
  const collectionPromises: { [key: string]: Promise<Array<DATABASE_DOCUMENT>> } = {};
  for (const key of collections) {
    collectionPromises[key] = getCollectionDB(key);
  }
  // Await all backend calls and fill the context with database objects
  const dataContextSupplier = _.cloneDeep(DefaultDataContextSupplier);

  for (const key of collections) {
    dataContextSupplier[
      key as keyof Omit<
        DataContextSupplierType,
        | "type"
        | "innerWidth"
        | "savedState"
        | "refMap"
        | "saveComponentState"
        | "saveRef"
        | "deleteRef"
        | "addDocuments"
        | "currencies"
      >
    ] = (await collectionPromises[key]) as Array<any>; // Need to use any here since it can be one of the backend documents
  }

  return {
    ...dataContextSupplier,
  };
}
