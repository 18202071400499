import React, { useState } from "react";
import { Property } from "../../model/property.types";
import PropertyTag from "./PropertyTag";

interface PropertiesWidgetProps {
  properties: Array<Property>;
  allProperties?: Array<Property>;
  additionalPropertiesShown?: number;
  allowShowMore?: boolean;
  types: Array<string>;
  placeholder?: string;
}

const PropertiesWidget: React.FunctionComponent<PropertiesWidgetProps> = ({
  properties,
  allProperties,
  additionalPropertiesShown,
  allowShowMore,
  types,
  placeholder,
}) => {
  const [additionalPropertiesAmount, setAdditionalPropertiesAmount] = useState(additionalPropertiesShown ?? 0);
  const displayedProperties =
    types.length === 0
      ? properties.filter((p) => p.name.en.trim())
      : properties.filter((p) => p.name.en.trim() && types.includes(p.type));
  let additionalDisplayedProperties: Array<Property> = [];
  if (allProperties) {
    additionalDisplayedProperties = allProperties
      .filter(
        (p) =>
          p.name.en.trim() &&
          !p.disabled &&
          types.includes(p.type) &&
          !displayedProperties.some((p2) => p2._id.toString() === p._id.toString())
      )
      .slice(0, additionalPropertiesAmount ?? undefined);
  }

  if (placeholder && displayedProperties.length === 0)
    return <div className="text-center text-muted mb-5">{placeholder}</div>;
  return (
    <div
      className="tagify"
      style={{
        backgroundColor: "rgba(0,0,0,0)",
        border: "none",
        padding: 0,
        cursor: "inherit",
      }}
    >
      {displayedProperties.map((prop) => (
        <PropertyTag key={prop._id.toString()} text={prop.name.en} highlight={!!allProperties} />
      ))}
      {additionalDisplayedProperties &&
        additionalDisplayedProperties.length > 0 &&
        additionalDisplayedProperties.map((prop) => <PropertyTag key={prop._id.toString()} text={prop.name.en} />)}
      {additionalPropertiesShown && additionalPropertiesAmount && allowShowMore && (
        <PropertyTag
          text={"Show More"}
          additionalTextClassName={"text-white"}
          onSelect={() => setAdditionalPropertiesAmount(additionalPropertiesAmount + additionalPropertiesShown)}
        />
      )}
    </div>
  );
};

export default PropertiesWidget;
