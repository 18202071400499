import React, { Component, FunctionComponent, PureComponent } from "react";
import { Switch } from "react-router-dom";
import { BSON } from "realm-web";
import ProtectedRoute from "../routes/ProtectedRoute";
import ScrollTop from "../../components/common/ScrollTop";
import { DataContextSupplier, getDataContextSupplier } from "../../context/dataContext";
import Aside from "../../components/layout/Aside";
import { ActiveSubstance } from "../../model/activeSubstance.types";
import { Property } from "../../model/property.types";
import { Notification } from "../../model/notification.types";
import { Invoice } from "../../model/invoice.types";
import { UserData } from "../../model/userData.types";
import { Commodity } from "../../model/commodity.types";
import { SupplierSupplier } from "../../model/supplier/supplierSupplier.types";
import { listenerSupplier } from "../../services/dbService";
import UserProfile from "../../components/userData/UserProfile";
import { SupplierSupplierOrder } from "../../model/supplier/supplierSupplierOrder.types";
import ChangePassword from "../../components/userData/ChangePassword";
import { Currencies, getCurrencies } from "../../utils/currencyUtils";
import SplashScreen from "../../components/common/SplashScreen";
import { Favorites } from "../../model/favorites.types";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import VersionCheck from "../../components/common/VersionCheck";
import { SUPPLIER } from "../../utils/userUtils";
import { VersionHistory } from "../../model/versionHistory.types";
import Overview from "../../components/dashboard/supplier/Overview";
import CommodityListingWrapper from "../../components/commodities/common/CommodityListingWrapper";
import CommodityWrapper from "../../components/commodities/supplier/CommodityWrapper";
import { CommodityOfferRequest } from "../../model/commodityOfferRequest.types";
import Onboarding from "../../components/common/Onboarding";
import Settings from "../../components/settings/supplier/Settings";
import SupplierWrapper from "../../components/suppliers/supplier/SupplierWrapper";
import { Seaport } from "../../model/seaport.types";
import NotificationConfiguration from "../../components/userData/NotificationConfiguration";
import PackagingDimensionListWrapper from "../../components/packagingDimensions/supplier/PackagingDimensionListWrapper";
import { Airport } from "../../model/airport.types";
import { DATA_TYPES, DATABASE_DOCUMENT } from "../../utils/dataContextUtils";
import { RefMap } from "../../components/common/CustomTypes";
import { sendMessage, getDefaultSlackChannel } from "../../services/slackService";
import { SystemNotification } from "../../model/systemNotification.types";
import { FinishedProduct } from "../../model/finishedProduct.types";
import FinishedProductWrapper from "../../components/finishedProducts/supplier/FinishedProductWrapper";

interface SupplierViewProps {}

interface SupplierViewState {
  loadingData: boolean;
  currencies: Currencies;
  collections: {
    activeSubstance: Array<ActiveSubstance>;
    airport: Array<Airport>;
    commodity: Array<Commodity>;
    finishedProduct: Array<FinishedProduct>;
    property: Array<Property>;
    notification: Array<Notification>;
    seaport: Array<Seaport>;
    invoice: Array<Invoice>;
    supplierOrder: Array<SupplierSupplierOrder>;
    supplier: Array<SupplierSupplier>;
    userData: Array<UserData>;
    favorites: Array<Favorites>;
    commodityOfferRequest: Array<CommodityOfferRequest>;
    versionHistory: Array<VersionHistory>;
    systemNotification: Array<SystemNotification>;
  };
  innerWidth: number;
  savedState: { [key: string]: { state: object; date: Date } };
  refMap: RefMap;
}

class SupplierView extends Component<SupplierViewProps, SupplierViewState> {
  _changeInProgress = false;
  constructor(props: SupplierViewProps) {
    super(props);
    this.state = {
      loadingData: true,
      currencies: {},
      collections: {
        activeSubstance: [],
        airport: [],
        commodity: [],
        finishedProduct: [],
        property: [],
        notification: [],
        seaport: [],
        invoice: [],
        supplierOrder: [],
        supplier: [],
        userData: [],
        favorites: [],
        commodityOfferRequest: [],
        versionHistory: [],
        systemNotification: [],
      },
      innerWidth: window.innerWidth,
      savedState: {},
      refMap: {},
    };
  }

  async componentDidMount() {
    const t = new Date().getTime();
    const data = await getDataContextSupplier();
    const currencies = await getCurrencies();
    // Saving loading time in local storage
    localStorage.setItem("t", (new Date().getTime() - t).toString());
    window.addEventListener("resize", this.handleResize);
    this.setState(
      {
        currencies: currencies.currencies ?? {},
        collections: {
          ...data,
        },
        loadingData: false,
      },
      () => listenerSupplier(this.updateDatabase)
    );
    this.getCurrencies(); // fire and forget
  }

  getCurrencies = async () => {
    const currencies = await getCurrencies();
    if (currencies.currencies) this.setState({ currencies: currencies.currencies });
    setTimeout(() => this.getCurrencies(), 2 * 60 * 60 * 1000);
  };

  handleResize = () => {
    this.setState({ innerWidth: window.innerWidth });
  };

  updateDatabase = async (change: Realm.Services.MongoDB.ChangeEvent<Realm.Services.MongoDB.Document>) => {
    // Ignore events we don't care about
    if (!("ns" in change) || !("coll" in change.ns) || !("documentKey" in change)) return;
    const collection = change.ns.coll as keyof typeof this.state.collections;
    const action = change.operationType;
    const documentKey = change.documentKey._id as BSON.ObjectId;
    if (action === "delete") {
      const { collections } = this.state;
      // @ts-ignore
      collections[collection] = collections[collection].filter(
        (item: DATABASE_DOCUMENT) => item && item._id.toString() !== documentKey.toString()
      ) as Array<DATABASE_DOCUMENT>;
      this.setState({
        collections,
      });
      return;
    }
    let indexNew = null;
    switch (action) {
      case "replace":
      case "update":
        try {
          let iterations = 0;
          while (this._changeInProgress) {
            if (iterations > 100) break; // Just risk it after 5s now - we can't wait too long
            await new Promise((resolve) => setTimeout(resolve, 50));
            iterations++;
          }
          this._changeInProgress = true;
          const collNew = { ...this.state.collections };
          const newCollection = collNew[collection].slice() as Array<DATABASE_DOCUMENT>;
          indexNew = newCollection.findIndex((d: DATABASE_DOCUMENT) => d._id.toString() === documentKey.toString());
          newCollection.splice(indexNew, 1, change.fullDocument as DATABASE_DOCUMENT);
          // @ts-ignore
          collNew[collection] = newCollection;
          this.setState({ collections: collNew });
        } finally {
          this._changeInProgress = false;
        }
        break;
      case "insert":
        try {
          while (this._changeInProgress) {
            await new Promise((resolve) => setTimeout(resolve, 50));
          }
          this._changeInProgress = true;
          const collNew = { ...this.state.collections };
          const collCopy = collNew[collection].slice() as Array<DATABASE_DOCUMENT>;
          collCopy.push(change.fullDocument as DATABASE_DOCUMENT);
          // @ts-ignore
          collNew[collection] = collCopy;
          this.setState({ collections: collNew });
        } finally {
          this._changeInProgress = false;
        }
        break;
      default: {
        const message = `SUPPLIER - Unhandled event received: ${action}`;
        sendMessage(
          getDefaultSlackChannel(true),
          message + `\n *Change Event:* \`\`\` ${JSON.stringify(change, null, 2)} \`\`\`\n`
        );
        break;
      }
    }
  };

  /**
   * Add the given documents to the context and the given collection. Only documents that were not already inside context are added.
   * @param collection Collection that should be updated
   * @param documents Documents that should be added to the context
   */
  addDocuments = (collection: string, documents: Array<DATA_TYPES>) => {
    const { collections } = this.state;

    // @ts-ignore
    if (collection in collections && collections[collection]) {
      // @ts-ignore
      const coll = collections[collection] as Array<DATA_TYPES>;
      let updated = false;
      const newDocuments = [];
      for (const doc of documents) {
        if (coll.some((d: DATA_TYPES) => d._id.toString() === doc._id.toString())) {
          newDocuments.push(doc);
          updated = true;
        }
      }
      coll.concat(newDocuments);
      if (updated) this.setState({ collections });
    }
  };

  /**
   * Save a components state
   * @param key key, i.e. class name
   * @param state the state to save
   */
  saveComponentState = (key: string, state: object) => {
    const savedState = { ...this.state.savedState };
    savedState[key] = { state, date: new Date() };
    this.setState({ savedState });
  };

  /**
   * Save a ref under the given key
   * @param key Key of the ref, e.g. a class name
   * @param ref Ref that should be saved (a component)
   */
  saveRef = (key: string, ref: PureComponent | FunctionComponent) => {
    this.setState((prevState) => {
      return { refMap: { ...prevState.refMap, [key]: ref } };
    });
  };

  /**
   * Deletes the given key from the ref map.
   * @param key Key that should be deleted
   */
  deleteRef = (key: string) => {
    const refMap = { ...this.state.refMap };
    delete refMap[key];
    this.setState({ refMap });
  };

  render() {
    const { loadingData, collections, currencies, innerWidth, savedState, refMap } = this.state;
    const context = {
      type: SUPPLIER,
      ...collections,
      currencies,
      addDocuments: this.addDocuments,
      savedState,
      refMap,
      innerWidth,
      saveComponentState: this.saveComponentState,
      saveRef: this.saveRef,
      deleteRef: this.deleteRef,
    };
    return (
      <DataContextSupplier.Provider value={context}>
        {loadingData ? (
          <SplashScreen />
        ) : (
          <>
            <div className="d-flex flex-column flex-root h-100">
              <div className="page d-flex flex-row flex-column-fluid">
                <Aside view="supplier" />
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                  <Header context={context} />
                  <div className="mb-20 mt-4">
                    <Switch>
                      <ProtectedRoute exact path="/dashboard" render={() => <Overview context={context} />} />
                      <ProtectedRoute exact path="/profile" component={UserProfile} />
                      <ProtectedRoute exact path="/articles" component={CommodityListingWrapper} />
                      <ProtectedRoute exact path="/commodity/:id" component={CommodityWrapper} />
                      <ProtectedRoute exact path="/finishedProduct/:id" component={FinishedProductWrapper} />
                      <ProtectedRoute exact path="/changePassword" component={ChangePassword} />
                      <ProtectedRoute exact path="/onboarding" component={Onboarding} />
                      <ProtectedRoute exact path="/settings" component={Settings} />
                      <ProtectedRoute exact path="/supplier" component={SupplierWrapper} />
                      <ProtectedRoute exact path="/notificationSettings" component={NotificationConfiguration} />
                      <ProtectedRoute
                        exact
                        path="/packagingDimensions"
                        render={() => <PackagingDimensionListWrapper context={context} />}
                      />
                    </Switch>
                  </div>
                  <Footer versionHistory={collections.versionHistory} />
                  <ScrollTop />
                </div>
              </div>
            </div>
            <VersionCheck view={SUPPLIER} />
          </>
        )}
      </DataContextSupplier.Provider>
    );
  }
}

export default SupplierView;
