import _ from "lodash";
import React, { ChangeEvent, PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import CustomSelect, { SelectOption } from "../../../../../../common/CustomSelect";
import ErrorOverlayButton from "../../../../../../common/ErrorOverlayButton";
import { DataContextInternalType } from "../../../../../../../context/dataContext";
import {
  SO_ORDERCONFIRMED,
  SO_REQUESTED,
  SO_T_BOLUPLOADED,
  SO_T_COAUPLOADED,
  SO_T_CUSTOMSINVOICEUPLOADED,
  SO_T_DOCUMENTUPLOADED,
  SO_T_PACKLISTUPLOADED,
  SO_TIMELINETYPE,
  SupplierOrder,
  SupplierOrderExtended,
  SupplierOrderShipment,
} from "../../../../../../../model/supplierOrder.types";
import {
  getSupplierOrderTimelineEntry,
  SO_BOL,
  SO_COA,
  SO_CUSTOMSINVOICE,
  SO_FILETYPES,
  SO_INVOICE,
  SO_ORDERCONFIRMATION,
  SO_ORDERDOCUMENT,
  SO_OTHER,
  SO_PACKAGINGLIST,
  updateSupplierOrder,
  updateSupplierOrderShipment,
} from "../../../../../../../utils/supplierOrderUtils";
import {
  EXTENDED_ORDER_TYPES,
  getOrderNumber,
  isCustomerOrder,
  isSupplierOrder,
} from "../../../../../../../utils/orderUtils";
import {
  CO_ORDERCONFIRMATION,
  CO_OTHER,
  getCustomerOrderTimelineEntry,
  updateCustomerOrder,
} from "../../../../../../../utils/customerOrderUtils";
import { CO_T_DOCUMENTUPLOADED, CustomerOrder } from "../../../../../../../model/customerOrder.types";
import { getSampleOrderTimelineEntry, updateSampleOrder } from "../../../../../../../utils/sampleOrderUtils";
import { SAMO_T_DOCUMENTUPLOADED, SampleOrder } from "../../../../../../../model/sampleOrder.types";
import {
  CC_CONTRACTCONFIRMATION,
  CC_OTHER,
  CC_T_DOCUMENTUPLOADED,
  getCustomerContractTimelineEntry,
  isCustomerContract,
  updateCustomerContract,
} from "../../../../../../../utils/customerContractUtils";
import { CustomerContract } from "../../../../../../../model/customerContract.types";
import { EXTENDED_CONTRACT_TYPES } from "../../../../../../../utils/contractUtils";
import { sendMessage } from "../../../../../../../services/slackService";
import { NotificationRoles } from "../../../../../../../utils/userUtils";
import { uploadAndGetArticleFileObject } from "../../../../../../../utils/fileUtils";
import { Input } from "../../../../../../common/Input";
import { OrderFile } from "../../../../../../../model/commonTypes";

interface WorkflowUploadDocumentModalProps {
  order: EXTENDED_ORDER_TYPES | EXTENDED_CONTRACT_TYPES;
  context: DataContextInternalType;
  shipment?: SupplierOrderShipment;
  fixedTypes?: Array<SelectOption>;
  disabled?: boolean;
}

interface WorkflowUploadDocumentModalState {
  totalPriceForSO: number;
  show: boolean;
  file: File | null;
  saving: boolean;
  useTotalPriceForSO: boolean;
  type?: SelectOption;
}

class WorkflowUploadDocumentModal extends PureComponent<
  WorkflowUploadDocumentModalProps,
  WorkflowUploadDocumentModalState
> {
  fileSelectionRef: React.RefObject<HTMLInputElement>;
  constructor(props: WorkflowUploadDocumentModalProps) {
    super(props);
    this.fileSelectionRef = React.createRef();
    this.state = {
      totalPriceForSO: 0,
      show: false,
      saving: false,
      file: null,
      useTotalPriceForSO: false,
      type: props.fixedTypes ? props.fixedTypes[0] : undefined,
    };
  }

  componentDidUpdate(prevProps: Readonly<WorkflowUploadDocumentModalProps>) {
    if (prevProps.fixedTypes !== this.props.fixedTypes) {
      this.setState({ type: this.props.fixedTypes ? this.props.fixedTypes[0] : undefined });
    }
  }

  handleShow = (type?: SelectOption) =>
    this.setState({
      show: true,
      file: null,
      type: type ?? (this.props.fixedTypes ? this.props.fixedTypes[0] : undefined),
      saving: false,
    });
  handleHide = () => this.setState({ show: false });

  handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    this.setState({ file });
  };

  handleSelectType = (type: SelectOption) => this.setState({ type });

  /**
   * Uploads the given document as the given type of document to the supplier order. Also switches the state to order
   * confirmed when the order confirmation is uploaded.
   */
  handleUploadDocument = async () => {
    const { order } = this.props;
    if (isCustomerOrder(order)) {
      await this.handleUploadCODocument();
    } else if (isSupplierOrder(order)) {
      await this.handleUploadSODocument();
    } else if (isCustomerContract(order)) {
      await this.handleUploadCCDocument();
    } else {
      await this.handleUploadSAMODocument();
    }
  };

  handleUploadCODocument = async () => {
    const files = _.cloneDeep(this.props.order.files);
    const { order } = this.props;
    const { file, type } = this.state;
    if (!(file && type)) return;
    this.setState({ saving: true });
    const uploadedFile = uploadAndGetArticleFileObject(file, file.name, type.value);
    if (!uploadedFile) return;
    const orderFile = {
      _id: uploadedFile._id,
      date: uploadedFile.date,
      path: uploadedFile.path,
      type: uploadedFile.type,
    };
    if (type.value === CO_ORDERCONFIRMATION) {
      for (let i = 0; i < files.length; i++) {
        const f = files[i];
        // If we upload another order confirmation ensure that the old one is no longer kept as order confirmation
        if (f.type === CO_ORDERCONFIRMATION) {
          f.type = CO_OTHER;
          break;
        }
      }
    }
    files.push(orderFile);
    const update: Partial<CustomerOrder> = { files };

    const cOTimeline = getCustomerOrderTimelineEntry(CO_T_DOCUMENTUPLOADED, {
      type: type.value,
      reference: orderFile._id.toString(),
    });
    const result = await updateCustomerOrder({ ...update }, order._id, cOTimeline);
    if (result && result.res && result.res.modifiedCount > 0) {
      toast.success("File uploaded successfully");
      this.setState({ saving: false, show: false });
    } else {
      toast.error("Error uploading file");
      this.setState({ saving: false });
    }
  };

  handleUploadCCDocument = async () => {
    const files = _.cloneDeep(this.props.order.files);
    const { order } = this.props;
    const { file, type } = this.state;
    if (!(file && type)) return;
    this.setState({ saving: true });
    const uploadedFile = uploadAndGetArticleFileObject(file, file.name, type.value);
    if (!uploadedFile) return;
    const orderFile = {
      _id: uploadedFile._id,
      date: uploadedFile.date,
      path: uploadedFile.path,
      type: uploadedFile.type,
    };
    if (type.value === CC_CONTRACTCONFIRMATION) {
      for (let i = 0; i < files.length; i++) {
        const f = files[i];
        // If we upload another contract confirmation ensure that the old one is no longer kept as contract confirmation
        if (f.type === CC_CONTRACTCONFIRMATION) {
          f.type = CC_OTHER;
          break;
        }
      }
    }
    files.push(orderFile);
    const update: Partial<CustomerContract> = { files };

    const cCTimeline = getCustomerContractTimelineEntry(CC_T_DOCUMENTUPLOADED, {
      type: type.value,
      reference: orderFile._id.toString(),
    });
    const result = await updateCustomerContract({ ...update }, order._id, cCTimeline);
    if (result && result.res && result.res.modifiedCount > 0) {
      toast.success("File uploaded successfully");
      this.setState({ saving: false, show: false });
    } else {
      toast.error("Error uploading file");
      this.setState({ saving: false });
    }
  };

  handleUploadSODocument = async () => {
    const files = _.cloneDeep(this.props.order.files);
    const { order, shipment } = this.props;
    const { file, type, useTotalPriceForSO, totalPriceForSO } = this.state;
    if (!(file && type)) return;
    this.setState({ saving: true });
    const uploadedFile = uploadAndGetArticleFileObject(
      file,
      file.name,
      type.value,
      isSupplierOrder(order) ? order.supplier : undefined
    );
    if (!uploadedFile) return;
    const orderFile: OrderFile = {
      _id: uploadedFile._id,
      date: uploadedFile.date,
      path: uploadedFile.path,
      type: uploadedFile.type,
    };

    if (type.value === SO_ORDERCONFIRMATION) {
      for (let i = 0; i < files.length; i++) {
        const f = files[i];
        // If we upload another order confirmation ensure that the old one is no longer kept as order confirmation
        if (f.type === SO_ORDERCONFIRMATION) {
          f.type = SO_OTHER;
          break;
        }
      }
    }

    if (useTotalPriceForSO && (type.value === SO_INVOICE || type.value === SO_CUSTOMSINVOICE)) {
      orderFile.additionalValues = {
        totalPrice: totalPriceForSO,
      };
    }
    files.push(orderFile);
    const update: Partial<SupplierOrder> = { files };

    if (type.value === SO_ORDERCONFIRMATION && order.state === SO_REQUESTED) {
      update.state = SO_ORDERCONFIRMED;
    }

    if (useTotalPriceForSO && (type.value === SO_INVOICE || type.value === SO_CUSTOMSINVOICE)) {
      // TODO RB-797 Adjust order calculation; margin etc
    }

    const sOTimeline = getSupplierOrderTimelineEntry(SO_T_DOCUMENTUPLOADED, {
      type: type.value,
      reference: orderFile._id.toString(),
    });
    let result;
    if (shipment) {
      let shipmentTimelineType: SO_TIMELINETYPE = SO_T_DOCUMENTUPLOADED;
      if (type.value === SO_COA) shipmentTimelineType = SO_T_COAUPLOADED;
      else if (type.value === SO_BOL) shipmentTimelineType = SO_T_BOLUPLOADED;
      else if (type.value === SO_PACKAGINGLIST) shipmentTimelineType = SO_T_PACKLISTUPLOADED;
      else if (type.value === SO_CUSTOMSINVOICE) shipmentTimelineType = SO_T_CUSTOMSINVOICEUPLOADED;
      const shipmentTimeline = getSupplierOrderTimelineEntry(shipmentTimelineType, {
        type: type.value,
        reference: orderFile._id.toString(),
      });
      result = await updateSupplierOrderShipment(
        { ...update },
        shipment._id.toString(),
        order._id,
        undefined,
        sOTimeline,
        shipmentTimeline
      );
    } else {
      result = await updateSupplierOrder({ ...update }, order._id, sOTimeline);
    }
    if (result && result.modifiedCount > 0) {
      toast.success("File uploaded successfully");

      const message = `A new <${process.env.REACT_APP_MEDIAHUB_FILE_BASE || ""}${orderFile.path}|*${
        type.value === SO_COA ? "CoA" : type.value === SO_INVOICE ? "Invoice" : "File"
      }*> was uploaded for <https://${
        process.env.REACT_APP_BASE_URL || ""
      }/supplierOrder/${order._id.toString()}|*${getOrderNumber(order as SupplierOrderExtended)}*>`;
      if (type.value === SO_COA) {
        await sendMessage(NotificationRoles.QM, message, true);
      } else if (type.value === SO_INVOICE) {
        await sendMessage(NotificationRoles.ACCOUNTING, message, true);
      }
      this.setState({ saving: false, show: false });
    } else {
      toast.error("Error uploading file");
      this.setState({ saving: false });
    }
  };

  handleUploadSAMODocument = async () => {
    const files = _.cloneDeep(this.props.order.files);
    const { order } = this.props;
    const { file, type } = this.state;
    if (!(file && type)) return;
    this.setState({ saving: true });
    const uploadedFile = uploadAndGetArticleFileObject(file, file.name, type.value);
    if (!uploadedFile) return;
    const orderFile = {
      _id: uploadedFile._id,
      date: uploadedFile.date,
      path: uploadedFile.path,
      type: uploadedFile.type,
    };
    files.push(orderFile);
    const update: Partial<SampleOrder> = { files };

    const samOTimeline = getSampleOrderTimelineEntry(SAMO_T_DOCUMENTUPLOADED, {
      type: type.value,
      reference: orderFile._id.toString(),
    });
    const result = await updateSampleOrder({ ...update }, order._id, samOTimeline);
    if (result && result.res && result.res.modifiedCount > 0) {
      toast.success("File uploaded successfully");
      this.setState({ saving: false, show: false });
    } else {
      toast.error("Error uploading file");
      this.setState({ saving: false });
    }
  };

  handleChangeTotalPriceForSO = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ totalPriceForSO: Number(e.target.value) });
  };

  handleToggleUseTotalPriceForSO = () => {
    const { useTotalPriceForSO } = this.state;
    this.setState({ useTotalPriceForSO: !useTotalPriceForSO });
  };

  validateData = () => {
    const { file, type } = this.state;
    const errors = [];
    if (!file) errors.push("Document missing");
    if (!type) errors.push("Document type missing");
    return errors;
  };

  render() {
    const { fixedTypes, disabled, order } = this.props;
    const { totalPriceForSO, saving, show, type, useTotalPriceForSO } = this.state;
    const missingData = this.validateData();

    return (
      <>
        <button
          className={"fs-7 btn btn-sm btn-text text-muted " + (disabled && "disabled")}
          disabled={disabled}
          onClick={disabled ? undefined : () => this.handleShow()}
        >
          Upload Document
        </button>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Upload Document</h1>
            </Modal.Title>
            <CloseButton variant={"white"} onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-5 ">
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="required fs-5 fw-bold mb-2">File</label>
                <input
                  type="file"
                  ref={this.fileSelectionRef}
                  accept="*"
                  className="form-control custom-form-control"
                  style={{ padding: "0.375rem 0.75rem" }}
                  name="file"
                  onChange={this.handleSelectFile}
                />
              </div>
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="required fs-5 fw-bold mb-2">Type</label>
                <CustomSelect
                  options={fixedTypes ?? SO_FILETYPES.filter((ft) => ft.value !== SO_ORDERDOCUMENT)}
                  value={type}
                  matchFormControl={true}
                  onChange={this.handleSelectType}
                />
              </div>
              {isSupplierOrder(order) && type && (type.value === SO_INVOICE || type.value === SO_CUSTOMSINVOICE) && (
                <>
                  <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                    <div className="form-check form-check-custom form-check-solid">
                      <Input
                        type="checkbox"
                        className="form-check-input position-static"
                        checked={useTotalPriceForSO}
                        onClick={this.handleToggleUseTotalPriceForSO}
                      />
                      <label className="form-check-label fs-5 fw-bold">Set Total Price</label>
                    </div>
                  </div>
                  {useTotalPriceForSO && (
                    <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                      <label className="fs-5 fw-bold mb-2">Total Price</label>
                      <Input
                        className="form-control custom-form-control"
                        type={"number"}
                        name={"specificSOAmount"}
                        value={totalPriceForSO}
                        onBlur={this.handleChangeTotalPriceForSO}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-outline btn-text-danger" onClick={this.handleHide}>
              Cancel
            </button>
            <ErrorOverlayButton
              errors={missingData}
              className="btn btn-sm btn-outline btn-outline-light"
              buttonText={saving ? "Uploading..." : "Upload Document"}
              onClick={this.handleUploadDocument}
              saving={saving}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default WorkflowUploadDocumentModal;
