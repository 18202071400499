import _ from "lodash";
import React, { PureComponent } from "react";
import { Accordion } from "react-bootstrap";
import { DataContextInternalType } from "../../../context/dataContext";
import { Property } from "../../../model/property.types";
import { PropertyType } from "../../../utils/propertyUtils";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import { getArticleProperty } from "../../../utils/commodityUtils";
import { FinishedProductExtended } from "../../../model/finishedProduct.types";
import { doFuseSearch } from "../../../utils/baseUtils";
import { generateCommodityPropertyTableRows } from "../../commodities/common/CommodityHelper";
import { CustomToggle } from "../../common/CustomToggle";
import {
  filterAdvancedPropertiesFinishedProduct,
  FP_G_GRADE,
  FP_G_PRODUCTINFORMATION,
  FP_G_TYPE,
} from "../../../utils/finishedProductUtils";

interface FinishedProductPageAdvancedInformationBlockProps {
  finishedProduct: FinishedProductExtended;
  edit: boolean;
  context: DataContextInternalType;
  onEditFinishedProductInput: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onEditFinishedProductSelectExclusiveProperty: (e: SelectOption<Property>, key: PropertyType) => void;
  onEditFlagValue: (field: "veg" | "law", value: string) => void;
  onEditGrades: (values: Array<SelectOption>) => void;
  type: FP_G_TYPE;
  search: string;
}

interface FinishedProductPageAdvancedInformationBlockState {
  show: boolean;
}

class FinishedProductPageAdvancedInformationBlock extends PureComponent<
  FinishedProductPageAdvancedInformationBlockProps,
  FinishedProductPageAdvancedInformationBlockState
> {
  constructor(props: FinishedProductPageAdvancedInformationBlockProps) {
    super(props);
    this.state = { show: true };
  }

  filterAdvancedEditableProperties = () => {
    const {
      finishedProduct,
      context,
      onEditFinishedProductSelectExclusiveProperty,
      onEditFlagValue,
      onEditGrades,
      type,
      search,
    } = this.props;
    const { property } = context;
    const packaging = getArticleProperty(finishedProduct.properties, PropertyType.PACKAGING) as Property | null;

    const newPropertyOption = { value: "new", label: "Create New" };
    let properties: Array<{ name: string; element: JSX.Element }> = [];
    switch (type) {
      case FP_G_PRODUCTINFORMATION:
        properties = [
          {
            name: "Vegan/Vegetarian",
            element: (
              <CustomSelect
                options={[
                  { value: "none", label: "None" },
                  { value: "vegetarian", label: "Vegetarian" },
                  { value: "vegan", label: "Vegan" },
                ]}
                value={
                  finishedProduct.vegan
                    ? { value: "vegan", label: "Vegan" }
                    : finishedProduct.vegetarian
                    ? { value: "vegetarian", label: "Vegetarian" }
                    : { value: "none", label: "None" }
                }
                onChange={(e: SelectOption) => onEditFlagValue("veg", e.value)}
                matchFormControl={true}
              />
            ),
          },
          {
            name: "Religious food law",
            element: (
              <CustomSelect
                options={[
                  { value: "none", label: "None" },
                  { value: "kosher", label: "Kosher" },
                  { value: "halal", label: "Halal" },
                  { value: "both", label: "Both" },
                ]}
                value={
                  finishedProduct.kosher && finishedProduct.halal
                    ? { value: "both", label: "Both" }
                    : finishedProduct.kosher
                    ? { value: "kosher", label: "Kosher" }
                    : finishedProduct.halal
                    ? { value: "halal", label: "Halal" }
                    : { value: "none", label: "None" }
                }
                onChange={(e: SelectOption) => onEditFlagValue("law", e.value)}
                matchFormControl={true}
              />
            ),
          },
          {
            name: "Packaging",
            element: (
              <CustomSelect
                options={[newPropertyOption].concat(
                  property
                    .filter((p) => p.type === PropertyType.PACKAGING)
                    .map((p) => {
                      return { value: p._id.toString(), label: p.name.en, object: p };
                    })
                )}
                value={packaging ? { value: packaging._id.toString(), label: packaging.name.en } : undefined}
                onChange={(e: SelectOption<Property>) =>
                  onEditFinishedProductSelectExclusiveProperty(e, PropertyType.PACKAGING)
                }
                matchFormControl={true}
                isClearable={true}
              />
            ),
          },
        ];
        break;
      case FP_G_GRADE:
        properties = [
          {
            name: "Grade",
            element: (
              <CustomSelect
                options={[
                  { value: "food", label: "Food" },
                  { value: "pharmaceutical", label: "Pharmaceutical" },
                  { value: "feed", label: "Feed" },
                  { value: "cosmetic", label: "Cosmetic" },
                  { value: "usp", label: "USP" },
                  { value: "medicine", label: "Medicine" },
                  { value: "industrial", label: "Industrial" },
                ]}
                value={this.generateSelectedGrades()}
                onChange={onEditGrades}
                matchFormControl={this.generateSelectedGrades().length <= 1}
                isMulti={true}
              />
            ),
          },
        ];
    }
    return search.trim() ? doFuseSearch(properties, search, ["name"]) : _.sortBy(properties, (obj) => obj.name);
  };

  generateSelectedGrades = () => {
    const { finishedProduct } = this.props;
    const selectedGrades: Array<SelectOption> = [];
    if (finishedProduct.foodGrade) selectedGrades.push({ value: "food", label: "Food" });
    if (finishedProduct.pharmaceuticalGrade) selectedGrades.push({ value: "pharmaceutical", label: "Pharmaceutical" });
    if (finishedProduct.feedGrade) selectedGrades.push({ value: "feed", label: "Feed" });
    if (finishedProduct.cosmeticGrade) selectedGrades.push({ value: "cosmetic", label: "Cosmetic" });
    if (finishedProduct.uspGrade) selectedGrades.push({ value: "usp", label: "USP" });
    if (finishedProduct.medicineGrade) selectedGrades.push({ value: "medicine", label: "Medicine" });
    if (finishedProduct.industrialGrade) selectedGrades.push({ value: "industrial", label: "Industrial" });
    return selectedGrades;
  };

  generateAdvancedPropertiesList = () => {
    const { finishedProduct, type, search } = this.props;
    const propertiesList = filterAdvancedPropertiesFinishedProduct(finishedProduct, search, type);
    if (propertiesList.length === 0) {
      return (
        <tr>
          <td colSpan={2}>
            <span className="text-white">No result found</span>
          </td>
        </tr>
      );
    }
    return generateCommodityPropertyTableRows(propertiesList);
  };

  generateTitleForType = () => {
    const { type } = this.props;
    switch (type) {
      case FP_G_PRODUCTINFORMATION:
        return "General Product Information";
      case FP_G_GRADE:
        return "Grade";
    }
  };

  render() {
    const { edit, type } = this.props;
    const { show } = this.state;

    return (
      <Accordion defaultActiveKey={type}>
        <div className="py-0">
          <div className="d-flex py-3 align-items-center w-100">
            <div className="me-3 w-100">
              <div className="row">
                <div className="col col-md-6">
                  <div className="text-white fs-5 fw-bolder">
                    <CustomToggle eventKey={type} callback={() => this.setState({ show: !this.state.show })}>
                      {this.generateTitleForType()}
                      <i className={"custom-accordion-toggle fa fa-chevron-up ml-2" + (show ? " show" : "")} />
                    </CustomToggle>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Accordion.Collapse eventKey={type}>
            <div className="fs-6">
              {edit ? (
                <div className="row py-5">
                  {this.filterAdvancedEditableProperties().map((p) => (
                    <div className="col-md-4 mt-2" key={p.name}>
                      <label className="fs-6 fw-bold mb-1">{p.name}</label>
                      {p.element}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="d-flex flex-wrap py-5">
                  <table className="table fw-bold gy-1">
                    <tbody>{this.generateAdvancedPropertiesList()}</tbody>
                  </table>
                </div>
              )}
            </div>
          </Accordion.Collapse>
        </div>
      </Accordion>
    );
  }
}

export default FinishedProductPageAdvancedInformationBlock;
