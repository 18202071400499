import { DayCellContentArg, WeekNumberContentArg } from "@fullcalendar/core";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import multiMonthPlugin from "@fullcalendar/multimonth";
import FullCalendar from "@fullcalendar/react";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import ErrorOverlayButton from "./ErrorOverlayButton";
import { formatDate } from "../../utils/baseUtils";
import { getCW } from "../../utils/dateUtils";

interface CalendarWeekSelectorProps {
  value?: Date | null;
  onSelectCalendarWeek: (date: Date) => void;
  onClear?: () => void;
  /** Optional, minimum date - ignored if allowPast is set to true */
  min?: Date;
  /** Optional, title of the selection modal - if not set the title is "Select Calendar Week" */
  title?: string;
  /** Optional, allows altering the look of the input tag */
  inputClassNames?: string;
  /** Optional, allows the user to select dates in the past - if set the min option is ignored  */
  allowPast?: boolean;
}

interface CalendarWeekSelectorState {
  selectedDate?: Date;
  show: boolean;
}

class CalendarWeekSelector extends PureComponent<CalendarWeekSelectorProps, CalendarWeekSelectorState> {
  constructor(props: CalendarWeekSelectorProps) {
    super(props);
    this.state = this.getDefaultState(false);
  }

  componentDidUpdate = (prevProps: CalendarWeekSelectorProps) => {
    if (prevProps.value?.getTime() !== this.props.value?.getTime()) {
      this.setState({ selectedDate: this.props.value || undefined });
    }
  };

  handleShow = () => this.setState(this.getDefaultState(true));
  handleHide = () => this.setState({ show: false });
  handleReset = () => {
    this.setState({ show: false }, () => this.props.onClear && this.props.onClear());
  };

  handleClickDay = (dateClick: DateClickArg) => {
    const date = dateClick.date;
    // Prevent selecting dates in the past if past is not allowed
    if (this.checkValidityOfDate(date)) {
      this.setState({ selectedDate: date });
    }
  };

  handleClickConfirm = () => {
    const { selectedDate } = this.state;
    // Should always be defined but a little sanity is fine
    if (selectedDate) {
      this.props.onSelectCalendarWeek(selectedDate);
      this.handleHide();
    }
  };

  getDefaultState = (show: boolean): CalendarWeekSelectorState => {
    return { show, selectedDate: this.props.value ? this.props.value : undefined };
  };

  /**
   * Get the class names for the day cells.
   * @param dayCellContent Fullcalendar content object which contains the date and more
   * @returns { Array<string> } CSS classes that should be applied to the day cell
   */
  getDayCellClassNames = (dayCellContent: DayCellContentArg) => {
    // Those classes are always needed so that the borders are shown correctly
    const classNames = [];
    if (this.checkValidityOfDate(dayCellContent.date)) {
      classNames.push("bg-dark");
      classNames.push("valid-week");
      // Hover changes the mouse icon to pointer
      classNames.push("hover");
      // Give the user an indicator that past weeks are not selectable if past is not allowed
    } else {
      classNames.push("bg-light");
    }
    return classNames;
  };

  /**
   * Checks if the date is valid to be selected. If dates in the past are allowed all dates are valid.
   * @param date Date that should be checked
   * @returns { boolean } Indicating the validity of the given date
   */
  checkValidityOfDate = (date: Date) => {
    const { allowPast, min } = this.props;
    const now = min ?? new Date();
    // If past is allowed the date is always valid
    // If the year is after now it is always valid
    // When the year is the same we have to look at the CW
    return (
      allowPast ||
      date.getFullYear() > now.getFullYear() ||
      (date.getFullYear() === now.getFullYear() && getCW(date) >= getCW(now))
    );
  };

  validateData = () => {
    const { selectedDate } = this.state;
    const errors: Array<string> = [];
    if (!selectedDate) errors.push("Please select a calendar week");
    else if (!this.checkValidityOfDate(selectedDate)) errors.push("Calender week can't be in the past");
    return errors;
  };

  /**
   * Renders the day cell description in the top right corner.
   * @param dayCellContent Fullcalendar content object which contains the date and more
   * @returns { JSX.Element } Day cell description which contains the time span it represents
   */
  renderDayCell = (dayCellContent: DayCellContentArg) => {
    const date = dayCellContent.date;
    // Since the date is always wednesday we need to calculate start and end of the week
    const startOfWeek = new Date(date.getTime() - 1000 * 60 * 60 * 24 * 2);
    const endOfWeek = new Date(date.getTime() + 1000 * 60 * 60 * 24 * 4);
    // Indicate that a week is not selectable if past is not allowed
    const textColor = this.checkValidityOfDate(date) ? "text-white" : "text-muted";
    return (
      <div className={"fs-7 mr-2 invisible-link " + textColor}>
        {formatDate(startOfWeek)} - {formatDate(endOfWeek)}{" "}
      </div>
    );
  };

  /**
   * Renders the calendar week icon in the top left corner of a day cell.
   * @param weekNumberContent Fullcalendar content object which contains the date and more
   * @returns { JSX.Element } Calendar week icon
   */
  renderCalendarWeek = (weekNumberContent: WeekNumberContentArg) => {
    const textColor = this.checkValidityOfDate(weekNumberContent.date) ? "text-white" : "text-muted";
    return (
      <div className="fs-5 d-flex align-items-center h-100 ml-2 fw-bolder">
        <div className={textColor}>CW{getCW(weekNumberContent.date)}</div>
      </div>
    );
  };

  render() {
    const { value, title, inputClassNames, onClear } = this.props;
    const { selectedDate, show } = this.state;
    const selectedEvent = selectedDate ? [{ id: "0", title: "", start: selectedDate, allDay: true }] : [];

    const errors = this.validateData();

    return (
      <>
        <div className="input-group">
          <input
            className={"form-control custom-form-control " + (inputClassNames ?? "")}
            type="text"
            onClick={this.handleShow}
            onChange={(e) => e.preventDefault()}
            value={value ? `CW ${getCW(value)}-${value.getFullYear()}` : ""}
            placeholder={"Select Calendar Week"}
          />
          <div className="input-group-append rounded-end bg-custom-light-gray" onClick={this.handleShow}>
            <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
              <i className="far fa-calendar hover text-hover-white align-middle" style={{ fontSize: "1.2rem" }} />
            </div>
          </div>
        </div>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered size="lg">
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">{title ?? "Select Calendar Week"}</h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <FullCalendar
              plugins={[multiMonthPlugin, interactionPlugin]}
              initialView="multiMonthSixMonth"
              views={{
                multiMonthSixMonth: {
                  type: "multiMonth",
                  duration: { months: 6 },
                },
              }}
              multiMonthMinWidth={200}
              weekNumbers={true}
              weekends={false}
              hiddenDays={[1, 2, 4, 5]}
              dateClick={(dateClick) => this.handleClickDay(dateClick)}
              dayCellContent={(dayCellContent) => this.renderDayCell(dayCellContent)}
              weekNumberContent={(weekNumberContent) => this.renderCalendarWeek(weekNumberContent)}
              dayCellClassNames={(dayCellContent) => this.getDayCellClassNames(dayCellContent)}
              events={selectedEvent}
              eventDisplay="background"
              eventBackgroundColor="white"
            />
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-text-white btn-sm" onClick={this.handleHide}>
              Cancel
            </button>
            {onClear && (
              <button className="btn btn-text-white btn-sm" onClick={this.handleReset}>
                Clear
              </button>
            )}
            <ErrorOverlayButton
              errors={errors}
              className="btn btn-sm btn-outline btn-outline-light"
              buttonText="Confirm"
              onClick={this.handleClickConfirm}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CalendarWeekSelector;
