import _ from "lodash";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { CommodityTimelineEntry, UploadedFileExtended } from "../../../../model/commodity.types";
import { Supplier, SupplierExtended, SupplierFile } from "../../../../model/supplier.types";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";
import {
  D_MASTERSPECIFICATION,
  D_OTHER,
  D_SUPPLIERANALYSIS,
  D_SUPPLIERSPECIFICATION,
  D_TYPEOPTIONS,
  getCommodityTimelineEntry,
  T_FILEUPDATED,
  transformDocumentsForDiff,
  updateCommodity,
} from "../../../../utils/commodityUtils";
import DateInput from "../../../common/DateInput";
import { getAllListDifferences } from "../../../../utils/diffUtils";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import { Input } from "../../../common/Input";
import { SUP_D_OTHER, SUP_D_TYPEOPTIONS, updateSupplier } from "../../../../utils/supplierUtils";
import { FinishedProductTimelineEntry } from "../../../../model/finishedProduct.types";
import {
  getFinishedProductTimelineEntry,
  isFinishedProduct,
  updateFinishedProduct,
} from "../../../../utils/finishedProductUtils";
import { InternalArticleExtended } from "../../../../utils/productArticleUtils";
import { SupplierSupplier } from "../../../../model/supplier/supplierSupplier.types";
import { CustomerSupplier } from "../../../../model/customer/customerSupplier.types";
import { reduceUploadedFile } from "../../../../utils/dataTransformationUtils";

interface UpdateDocumentModalProps {
  article?: InternalArticleExtended;
  supplier?: Supplier | SupplierExtended;
  file?: UploadedFileExtended | SupplierFile;
  onHide: () => void;
}

interface UpdateDocumentModalState {
  validUntil: Date | null;
  supplier?: SelectOption<Supplier | SupplierSupplier | CustomerSupplier>;
  saving: boolean;
  type?: SelectOption;
  customType: string;
}

class UpdateDocumentModal extends PureComponent<UpdateDocumentModalProps, UpdateDocumentModalState> {
  constructor(props: UpdateDocumentModalProps) {
    super(props);
    const { file } = props;
    this.state = {
      validUntil: (file && file.validUntil) || null,
      supplier:
        file && "supplier" in file && file.supplier
          ? { label: file.supplier.name, value: file.supplier._id.toString(), object: file.supplier }
          : undefined,
      type: file && D_TYPEOPTIONS.find((t) => t.value === file.type),
      saving: false,
      customType: file && file.customType ? file.customType : "",
    };
  }

  componentDidUpdate(prevProps: Readonly<UpdateDocumentModalProps>) {
    const { file } = this.props;
    if (file && !_.isEqual(prevProps.file, file)) {
      this.setState({
        validUntil: (file && file.validUntil) || null,
        supplier:
          file && "supplier" in file && file.supplier
            ? { label: file.supplier.name, value: file.supplier._id.toString(), object: file.supplier }
            : undefined,
        type: file && D_TYPEOPTIONS.find((t) => t.value === file.type),
        saving: false,
        customType: file && file.customType ? file.customType : "",
      });
    }
  }

  handleDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.setState({ validUntil: value ? new Date(value) : null });
  };

  handleSupplierChange = (e: SelectOption<Supplier>) => this.setState({ supplier: e });

  handleTypeChange = (e: SelectOption) => this.setState({ type: e, customType: "" });

  handleUpdateDocument = async () => {
    const { article, supplier: propSupplier, file, onHide } = this.props;
    const { validUntil, supplier, saving, type, customType } = this.state;
    if (!file || !type || saving) return;
    this.setState({ saving: true });
    try {
      if (article) {
        const finishedProduct = isFinishedProduct(article);
        // handle commodity and finished product documents
        const documents = _.cloneDeep(article.documents);
        const fileToUpdate = documents.find((d) => d._id.toString() === file._id.toString());
        if (!fileToUpdate) return;

        fileToUpdate.validUntil = validUntil || undefined;
        if (supplier?.object) fileToUpdate.supplier = supplier.object;
        else if (fileToUpdate.supplier) fileToUpdate.supplier = undefined;
        fileToUpdate.type = type.value as "supplierSpecification" | "coa" | "other";
        if (type.value === D_OTHER && customType.trim()) fileToUpdate.customType = customType.trim();
        const timelineEntry = finishedProduct
          ? getFinishedProductTimelineEntry(T_FILEUPDATED, fileToUpdate.type, fileToUpdate.path)
          : getCommodityTimelineEntry(T_FILEUPDATED, fileToUpdate.type, fileToUpdate.path);
        // Diff
        const [differentDocsPre, differentDocsPost] = getAllListDifferences(
          transformDocumentsForDiff(article.documents),
          transformDocumentsForDiff(documents)
        );
        if (differentDocsPre.length > 0) timelineEntry.pre = { documents: differentDocsPre };
        if (differentDocsPost.length > 0) timelineEntry.post = { documents: differentDocsPost };

        const documentsReduced = documents.map((d) => reduceUploadedFile(d));
        const result = finishedProduct
          ? await updateFinishedProduct(
              { documents: documentsReduced },
              article._id,
              timelineEntry as FinishedProductTimelineEntry
            )
          : await updateCommodity(
              { documents: documentsReduced },
              article._id,
              timelineEntry as CommodityTimelineEntry
            );
        if (result && result.modifiedCount > 0) {
          toast.success("File updated successfully");
          onHide();
        } else {
          toast.error("Error updating file");
        }
      } else if (propSupplier) {
        // handle supplier documents
        const documents = _.cloneDeep(propSupplier.documents);
        if (!documents) return;
        const fileToUpdate = documents.find((d) => d._id.toString() === file._id.toString());
        if (!fileToUpdate) return;

        fileToUpdate.validUntil = validUntil || undefined;
        fileToUpdate.type = type.value as "certificate" | "other";
        if (type.value === D_OTHER && customType.trim()) fileToUpdate.customType = customType.trim();
        const result = await updateSupplier({ documents }, propSupplier._id);
        if (result && result.modifiedCount > 0) {
          toast.success("File updated successfully");
          onHide();
        } else {
          toast.error("Error updating file");
        }
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  validateData = () => {
    const { supplier, type } = this.state;
    const errors = [];
    if (!type) errors.push("No type selected");
    else if (type.value === D_MASTERSPECIFICATION) errors.push("Master specification cannot be updated");
    else if (type.value === D_SUPPLIERSPECIFICATION && !supplier)
      errors.push("Supplier specification requires a supplier");
    else if (type.value === D_SUPPLIERANALYSIS && !supplier) errors.push("Supplier analysis requires a supplier");
    return errors;
  };

  render() {
    const { article, supplier: propSupplier, file, onHide } = this.props;
    const { validUntil, supplier, saving, type, customType } = this.state;
    const errors = this.validateData();
    const typeOptions = article ? D_TYPEOPTIONS : propSupplier ? SUP_D_TYPEOPTIONS : [];
    return (
      <>
        <Modal contentClassName="bg-dark" show={!!file} onHide={onHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Update Document</h1>
            </Modal.Title>
            <CloseButton variant={"white"} onClick={onHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-5 ">
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="required fs-5 fw-bold mb-2">File Name</label>
                <input
                  type="text"
                  className="form-control custom-form-control"
                  name="fileName"
                  disabled={true}
                  value={file ? file.name : ""}
                />
              </div>
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="required fs-5 fw-bold mb-2">Type</label>
                <CustomSelect
                  options={typeOptions.filter((t) => t.value !== D_MASTERSPECIFICATION)}
                  value={type}
                  disabled={!file}
                  onChange={this.handleTypeChange}
                  matchFormControl={true}
                />
              </div>
              {type && [D_OTHER, SUP_D_OTHER].includes(type.value) && (
                <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                  <label className="fs-5 fw-bold mb-2">Custom Type</label>
                  <Input
                    type="text"
                    className="form-control custom-form-control"
                    name="customType"
                    placeholder={"e.g. Certificate"}
                    autoComplete="off"
                    value={customType}
                    onBlur={(e) => this.setState({ customType: e.target.value })}
                  />
                </div>
              )}
              {((file && "supplier" in file && file.supplier && article && !propSupplier) ||
                (type && type.value === D_SUPPLIERSPECIFICATION && article && !propSupplier)) && (
                <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                  <label className="fs-5 fw-bold mb-2">Supplier</label>
                  <CustomSelect
                    options={article.suppliers.map((s) => {
                      return { label: s.supplier.name, value: s._id.toString(), object: s.supplier };
                    })}
                    value={supplier}
                    onChange={this.handleSupplierChange}
                    matchFormControl={true}
                    isClearable={true}
                  />
                </div>
              )}
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="fs-5 fw-bold mb-2">Expiration Date</label>
                <DateInput
                  classes={"form-control custom-form-control"}
                  value={validUntil}
                  onBlur={this.handleDate}
                  name={"validUntil"}
                  allowClear={true}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-outline btn-text-danger" onClick={onHide}>
              Cancel
            </button>
            <ErrorOverlayButton
              errors={errors}
              className={"btn btn-sm btn-outline btn-outline-light "}
              buttonText={saving ? "Updating..." : "Update Document"}
              onClick={this.handleUpdateDocument}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default UpdateDocumentModal;
