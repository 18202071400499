import React from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

interface WysiwygEditorProps {
  content: EditorState;
  onChange: (content: EditorState) => void;
}

const WysiwygEditor: React.FunctionComponent<WysiwygEditorProps> = ({ content, onChange }) => {
  return (
    <Editor
      editorState={content}
      onEditorStateChange={onChange}
      toolbarClassName="toolbarClassName custom-form-control"
      wrapperClassName="wrapperClassName custom-form-control"
      editorClassName="editorClassName form-control custom-form-control"
      toolbar={{
        options: ["inline", "colorPicker", "list", "fontSize", "history"],
        inline: { options: ["bold", "italic", "underline"] },
        list: { options: ["unordered", "ordered"] },
        fontSize: {
          dropdownClassName: "custom-form-control",
        },
        blockType: {
          inDropdown: true,
          options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6", "Blockquote", "Code"],
          className: undefined,
          component: undefined,
          dropdownClassName: "custom-form-control",
        },
        colorPicker: {
          className: undefined,
          component: undefined,
          popupClassName: "custom-form-control",
          colors: [
            "#000000",
            "#343a40",
            "#6c757d",
            "#dc3545",
            "#fd7e14",
            "#ffc107",
            "#20c997",
            "#28a745",
            "#17a2b8",
            "#007bff",
          ],
        },
      }}
    />
  );
};

export default WysiwygEditor;
