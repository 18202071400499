import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContextInternal } from "../../../context/dataContext";
import ForwarderPage from "./ForwarderPage";

interface ForwarderWrapperProps extends RouteComponentProps<ForwarderParams> {}

interface ForwarderParams {
  id: string;
}
const ForwarderWrapper: React.FunctionComponent<ForwarderWrapperProps> = (props) => {
  const context = useContext(DataContextInternal);
  return <ForwarderPage context={context} {...props} />;
};

export default ForwarderWrapper;
