import React from "react";
import {
  CalculationGeneralValues,
  ContainerData,
  SingleContainerData,
} from "../../../../model/configuration/calculationConfiguration.types";
import { Input } from "../../../common/Input";
import { PaletteDataView } from "../../../order/modals/views/PaletteDataView";
import { CONTAINER_LABEL } from "../../../../utils/seaportUtils";
import { formatCurrency, pluralize } from "../../../../utils/baseUtils";
import Tooltip from "../../../common/Tooltip";
import { CostObjectList } from "./common/CostObjectList";

interface GeneralValuesConfigurationProps {
  generalValues: CalculationGeneralValues;
  onChangePaletteData: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onToggleStackable: () => void;
  onChangeContainerData: (e: React.ChangeEvent<HTMLInputElement>, container: keyof ContainerData) => void;
  onChangeGeneralValues: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const GeneralValuesConfiguration: React.FunctionComponent<GeneralValuesConfigurationProps> = ({
  generalValues,
  onChangePaletteData,
  onToggleStackable,
  onChangeContainerData,
  onChangeGeneralValues,
}) => {
  const { customsFlatRate, customsFeeAgency, b2bFollowUpCost, defaultPalette, containerData, warehouseCost } =
    generalValues;
  return (
    <>
      <h3 className="mb-3 mt-7">Customs</h3>
      <div className="row">
        <div className="col-xl-3">
          <label className="fs-5 fw-bold mb-2">Customs Clearance</label>
          <div className="input-group">
            <Input
              className="form-control custom-form-control"
              type={"number"}
              name={"customsFlatRate.cost"}
              min={0}
              value={customsFlatRate.cost}
              onChange={onChangeGeneralValues}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                {customsFlatRate.currency}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3">
          <label className="fs-5 fw-bold mb-2">
            Fee for Delivery Agency
            <Tooltip tooltipText={"A fee applied to the customs cost for laying out the respective costs"}>
              <span className="ml-2">
                <i className="fa fa-info-circle text-white" />
              </span>
            </Tooltip>
          </label>
          <div className="input-group">
            <Input
              className="form-control custom-form-control"
              type={"number"}
              name={"customsFeeAgency"}
              min={0}
              value={customsFeeAgency * 100}
              onChange={onChangeGeneralValues}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                %
              </div>
            </div>
          </div>
        </div>
      </div>
      <h3 className="mb-3 mt-7">Warehouse Cost</h3>
      <CostObjectList
        cost={warehouseCost.generalCost}
        onChangeValues={onChangeGeneralValues}
        objectPath={"warehouseCost.generalCost"}
      />
      <h3 className="mb-3 mt-7">B2B Follow-Up Cost Per Palette</h3>
      <div className="row">
        {b2bFollowUpCost.paletteCost.map((pC, i) => (
          <div key={pC.palettes.toString()} className={"col-xl-auto " + (i >= 5 && "mt-2")} style={{ width: "20%" }}>
            <label className="fs-5 fw-bold mb-2">{pluralize(pC.palettes, "Palette")}</label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type={"number"}
                name={`b2bFollowUpCost.paletteCost.${i}.cost`}
                min={0}
                value={pC.cost}
                onChange={onChangeGeneralValues}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  {pC.currency}
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className={"col-xl-auto mt-2"} style={{ width: "40%" }}>
          <label className="fs-5 fw-bold mb-2">
            Fallback Cost Per Palette
            <Tooltip
              tooltipText={`If we have more than the defined palettes we calculate this cost times the amount of palette for the follow-up cost calculation. E.g. for 13 palettes: 13 * ${formatCurrency(
                b2bFollowUpCost.fallbackPerPalette.cost,
                b2bFollowUpCost.fallbackPerPalette.currency
              )} = ${formatCurrency(
                b2bFollowUpCost.fallbackPerPalette.cost * 13,
                b2bFollowUpCost.fallbackPerPalette.currency
              )}`}
            >
              <span className="ml-2">
                <i className="fa fa-info-circle text-white" />
              </span>
            </Tooltip>
          </label>
          <div className="input-group">
            <Input
              className="form-control custom-form-control"
              type={"number"}
              name={"b2bFollowUpCost.fallbackPerPalette.cost"}
              min={0}
              value={b2bFollowUpCost.fallbackPerPalette.cost}
              onChange={onChangeGeneralValues}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                {b2bFollowUpCost.fallbackPerPalette.currency}
              </div>
            </div>
          </div>
        </div>
      </div>
      <PaletteDataView
        paletteData={defaultPalette}
        onChangePaletteData={onChangePaletteData}
        onToggleStackable={onToggleStackable}
      />
      <ContainerDataView
        containerData={containerData["20"]}
        container={"20"}
        onChangeContainerData={onChangeContainerData}
      />
      <ContainerDataView
        containerData={containerData["40"]}
        container={"40"}
        onChangeContainerData={onChangeContainerData}
      />
      <ContainerDataView
        containerData={containerData["40HC"]}
        container={"40HC"}
        onChangeContainerData={onChangeContainerData}
      />
    </>
  );
};

interface ContainerDataViewProps {
  containerData: SingleContainerData;
  container: keyof ContainerData;
  onChangeContainerData: (e: React.ChangeEvent<HTMLInputElement>, container: keyof ContainerData) => void;
}

const ContainerDataView: React.FunctionComponent<ContainerDataViewProps> = ({
  containerData,
  container,
  onChangeContainerData,
}) => {
  return (
    <>
      <h3 className="mb-3 mt-7">{CONTAINER_LABEL[container]} Container Data</h3>
      <div className="row">
        <div className="col-6 col-xl-4">
          <label className="fs-5 fw-bold mb-2">Length</label>
          <div className="input-group">
            <Input
              className={"form-control custom-form-control "}
              type={"number"}
              name={"length"}
              min={0}
              value={containerData.length}
              onChange={(e) => onChangeContainerData(e, container)}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                cm
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-xl-4">
          <label className="fs-5 fw-bold mb-2">Width</label>
          <div className="input-group">
            <Input
              className={"form-control custom-form-control "}
              type={"number"}
              name={"width"}
              min={0}
              value={containerData.width}
              onChange={(e) => onChangeContainerData(e, container)}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                cm
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-xl-4">
          <label className="fs-5 fw-bold mb-2">Total Height</label>
          <div className="input-group">
            <Input
              className={"form-control custom-form-control "}
              type={"number"}
              min={0}
              name={"height"}
              value={containerData.height}
              onChange={(e) => onChangeContainerData(e, container)}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                cm
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-xl-4 mt-2">
          <label className="fs-5 fw-bold mb-2">Own Weight</label>
          <div className="input-group">
            <Input
              className={"form-control custom-form-control "}
              type={"number"}
              min={0}
              name={"ownWeight"}
              value={containerData.ownWeight}
              onChange={(e) => onChangeContainerData(e, container)}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                kg
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-xl-4 mt-2">
          <label className="fs-5 fw-bold mb-2">Max Payload Weight</label>
          <div className="input-group">
            <Input
              className={"form-control custom-form-control "}
              type={"number"}
              min={0}
              name={"maxPayloadWeight"}
              value={containerData.maxPayloadWeight}
              onChange={(e) => onChangeContainerData(e, container)}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                kg
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-xl-4 mt-2">
          <label className="fs-5 fw-bold mb-2">Max Total Weight</label>
          <div className="input-group">
            <Input
              className={"form-control custom-form-control disabled "}
              type={"number"}
              disabled
              min={0}
              name={"totalWeight"}
              value={containerData.maxPayloadWeight + containerData.ownWeight}
              onChange={(e) => onChangeContainerData(e, container)}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                kg
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralValuesConfiguration;
