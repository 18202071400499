import React, { useMemo } from "react";
import { UserStateData } from "./CustomTypes";
import { Input } from "./Input";

interface UserCreationProps {
  primaryPerson?: UserStateData;
  additionalPersons: Array<UserStateData>;
  createAppUsers?: boolean;
  sendInvitationMail?: boolean;
  onAddPerson: () => void;
  onRemovePerson: (index: number) => void;
  onPersonChange: (e: React.ChangeEvent<HTMLInputElement>, index?: number) => void;
  onChangeCreateAppUsers?: () => void;
  onChangeSendInvitationMail?: () => void;
}

const UserCreation: React.FunctionComponent<UserCreationProps> = ({
  primaryPerson,
  additionalPersons,
  createAppUsers,
  sendInvitationMail,
  onAddPerson,
  onRemovePerson,
  onPersonChange,
  onChangeCreateAppUsers,
  onChangeSendInvitationMail,
}) => {
  const multipleAccounts = useMemo(() => additionalPersons.length > 0, [additionalPersons]);

  return (
    <div style={{ maxHeight: "50vh", overflowX: "hidden", overflowY: "auto" }}>
      {primaryPerson && (
        <div className="row mb-5 mt-8 ">
          <h3>Primary User</h3>
          <div className="col-md-6 mt-3">
            <label className="required fs-5 fw-bold mb-2">Prename</label>
            <Input
              type="text"
              className="form-control custom-form-control"
              name="prename"
              autoComplete="off"
              value={primaryPerson.prename}
              onChange={onPersonChange}
            />
          </div>
          <div className="col-md-6 mt-3 ">
            <label className="required fs-5 fw-bold mb-2">Surname</label>
            <Input
              type="text"
              className="form-control custom-form-control"
              name="surname"
              autoComplete="off"
              value={primaryPerson.surname}
              onChange={onPersonChange}
            />
          </div>
          <div className="col-md-12 mt-3">
            <label className="fs-5 fw-bold mb-2">Position</label>
            <Input
              type="text"
              className="form-control custom-form-control"
              placeholder="Position"
              name="position"
              autoComplete="off"
              value={primaryPerson.position}
              onChange={onPersonChange}
            />
          </div>
          <div className="col-md-12  mt-3">
            <label className="required fs-5 fw-bold mb-2">Email</label>
            <Input
              type="text"
              className="form-control custom-form-control"
              placeholder="Primary Mail"
              name="mail"
              autoComplete="off"
              value={primaryPerson.mail}
              onChange={onPersonChange}
            />
          </div>
          <div className="col-md-12  mt-3">
            <label className="fs-5 fw-bold mb-2">Phone</label>
            <Input
              type="text"
              className="form-control custom-form-control"
              placeholder="Primary Phone"
              name="phone"
              autoComplete="off"
              value={primaryPerson.phone}
              onChange={onPersonChange}
            />
          </div>
        </div>
      )}
      <div className="row mb-5 mt-8 ">
        {additionalPersons.map((p, idx) => (
          <React.Fragment key={p.id.toString()}>
            <h3>
              Additional User {idx + 1}
              <button className="btn btn-sm btn-text-danger p-0 ml-3" onClick={() => onRemovePerson(idx)}>
                X
              </button>
            </h3>
            <div className="col-md-6 mt-3">
              <label className="required fs-5 fw-bold mb-2">Prename</label>
              <Input
                type="text"
                className="form-control custom-form-control"
                name="prename"
                autoComplete="off"
                value={p.prename}
                onChange={(e) => onPersonChange(e, idx)}
              />
            </div>
            <div className="col-md-6 mt-3">
              <label className="required fs-5 fw-bold mb-2">Surname</label>
              <Input
                type="text"
                className="form-control custom-form-control"
                name="surname"
                autoComplete="off"
                value={p.surname}
                onChange={(e) => onPersonChange(e, idx)}
              />
            </div>
            <div className="col-md-12 mt-3">
              <label className="fs-5 fw-bold mb-2">Position</label>
              <Input
                type="text"
                className="form-control custom-form-control"
                name="position"
                autoComplete="off"
                value={p.position}
                onChange={(e) => onPersonChange(e, idx)}
              />
            </div>
            <div className="col-md-12 mt-3">
              <label className="required fs-5 fw-bold mb-2">Email</label>
              <Input
                type="text"
                className="form-control custom-form-control"
                name="mail"
                autoComplete="off"
                value={p.mail}
                onChange={(e) => onPersonChange(e, idx)}
              />
            </div>
            <div className="col-md-12 mt-3 mb-6">
              <label className="fs-5 fw-bold mb-2">Phone</label>
              <Input
                type="text"
                className="form-control custom-form-control"
                name="phone"
                autoComplete="off"
                value={p.phone}
                onChange={(e) => onPersonChange(e, idx)}
              />
            </div>
          </React.Fragment>
        ))}
      </div>
      <button className={"btn btn-light btn-sm mb-5 "} onClick={onAddPerson}>
        <i className="fa fa-plus text-white" />
      </button>
      {onChangeCreateAppUsers && (
        <div className="d-flex flex-stack mb-5">
          <div className="me-5 fw-bold">
            <label className="fs-6">Generate Invite</label>
            <div className="fs-7 text-muted">
              {`If checked, app account${multipleAccounts ? "s" : ""} will be created for ${
                multipleAccounts ? "all users" : "the user"
              }.`}
            </div>
          </div>
          <label className="form-check form-switch form-check-custom form-check-solid">
            <input
              className="form-check-input position-static"
              type="checkbox"
              checked={createAppUsers}
              onChange={onChangeCreateAppUsers}
            />
            <span className="form-check-label fw-bold text-muted">Active</span>
          </label>
        </div>
      )}
      {createAppUsers && onChangeSendInvitationMail && (
        <div className="d-flex flex-stack mb-5">
          <div className="me-5 fw-bold">
            <label className="fs-6">Send Invitation Mail</label>
            <div className="fs-7 text-muted">{`If checked, invitation mail${
              multipleAccounts ? "s" : ""
            } will be sent to ${multipleAccounts ? "all users" : "the user"}.`}</div>
          </div>
          <label className="form-check form-switch form-check-custom form-check-solid">
            <input
              className="form-check-input position-static"
              type="checkbox"
              checked={sendInvitationMail}
              onChange={onChangeSendInvitationMail}
            />
            <span className="form-check-label fw-bold text-muted">Active</span>
          </label>
        </div>
      )}
    </div>
  );
};

export default UserCreation;
