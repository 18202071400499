import _, { indexOf } from "lodash";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";
import { DataContextInternalType } from "../../../../context/dataContext";
import {
  CO_T_DOCUMENTREPLACED,
  CO_T_DOCUMENTUPLOADED,
  CustomerOrder,
  CustomerOrderExtended,
  T_AIRFREIGHT,
  T_EUSTOCK,
  T_RAILFREIGHT,
  T_SEAAIRCOMBINED,
  T_SEAFREIGHT,
  T_WAREHOUSE,
} from "../../../../model/customerOrder.types";
import {
  getAddressByType,
  getAddressString,
  getStandardWarehouseAddress,
  isAddress,
} from "../../../../utils/addressUtils";
import ForwardingOrderInfo from "./ForwardingOrderInfo";
import { Address, AddressType } from "../../../../model/commonTypes";
import {
  FWO_REMARKS_AIR,
  FWO_REMARKS_RAIL,
  FWO_REMARKS_ROAD,
  FWO_REMARKS_SEA,
  FWO_REMARKS_SEAAIRCOMBINED,
  getAvailableOrders,
  getForwardingOrderTimelineEntry,
  getWarningText,
  insertForwardingOrder,
  isBackToBackOrder,
  updateForwardingOrder,
  getAddress,
  getWarnings,
  OverwriteFiles,
  ForwardingOrderInfoCreation,
  AddressOrStringSelection,
  getDefaultForwardingOrderInfo,
  getSupplierAddressContactPerson,
  FWO_WARNINGS,
  getNetWeightOfOrderInExistingForwardingOrders,
} from "../../../../utils/forwardingOrderUtils";
import {
  SO_T_DOCUMENTREPLACED,
  SO_T_DOCUMENTUPLOADED,
  SupplierOrder,
  SupplierOrderExtended,
} from "../../../../model/supplierOrder.types";
import {
  DateType,
  getOrderNumber,
  isCustomerOrder,
  isSupplierOrder,
  O_TRANSPORTTYPES_FORWARDINGORDER,
} from "../../../../utils/orderUtils";
import { Input } from "../../../common/Input";
import WysiwygEditor from "../../../common/WYSIWYGEditor";
import {
  getSupplierOrderStartString,
  getSupplierOrderTimelineEntry,
  SO_DELIVERYTERMS,
  SO_FORWARDINGORDER,
} from "../../../../utils/supplierOrderUtils";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import {
  ForwardingOrder,
  ForwardingOrderInformation,
  FWO_STATES,
  FWO_TIMELINE,
} from "../../../../model/forwardingOrder.types";
import { createPDF, FOOTER_WITHOUT_FINANCIAL_HTML } from "../../../../utils/pdfUtils";
import { createForwardingOrder } from "../../../../utils/pdf/forwardingOrderGenerationUtils";
import { Action, CUSTOMERORDER, SUPPLIERORDER, transaction } from "../../../../services/dbService";
import { resolveFilePath, shortenAlias } from "../../../../utils/fileUtils";
import { CO_FORWARDINGORDER, getCustomerOrderTimelineEntry } from "../../../../utils/customerOrderUtils";
import userService from "../../../../services/userService";
import { ADMIN, SCM } from "../../../../utils/userUtils";
import { getEditorStateAsHTML, getStringAsEditorState } from "../../../../utils/wysiwygUtils";
import { getBatchPackagingInfo } from "../../../../utils/batchUtils";
import { isSeaport } from "../../../../utils/seaportUtils";
import { isAirport } from "../../../../utils/airportUtils";
import { AdditionalDateTypeLogistics, getGrossWeight } from "../../../../utils/logisticsUtils";
import { getDocFromCollection } from "../../../../utils/baseUtils";
import {
  extendCustomerOrder,
  extendSupplierOrder,
  reduceCustomerOrder,
  reduceSupplierOrder,
} from "../../../../utils/dataTransformationUtils";
import { isAnyFinishedProduct } from "../../../../utils/productArticleUtils";

interface ForwardingOrderParams {
  id?: string;
}

interface CreateForwardingOrderProps extends RouteComponentProps<ForwardingOrderParams> {
  context: DataContextInternalType;
}

interface CreateForwardingOrderState {
  edit: boolean;
  filesToOverwrite?: Array<OverwriteFiles>;
  saving: "draft" | "forwardingOrder" | false;
  orders: Array<SelectOption<CustomerOrderExtended | SupplierOrderExtended>>;
  type: SelectOption;
  forwarder: SelectOption | undefined;
  orderInfos: Array<ForwardingOrderInfoCreation>;
  startIncoterm: SelectOption | undefined;
  startingFrom: string;
  returnOrder: boolean;
  remarks: EditorState;
  warnings: Array<string>;
  oneAddress: boolean;
}

class CreateForwardingOrder extends PureComponent<CreateForwardingOrderProps, CreateForwardingOrderState> {
  constructor(props: CreateForwardingOrderProps) {
    super(props);
    this.state = this.getDefaultState(props);
  }

  async componentDidMount() {
    await this.populateData();
  }

  handleChangeOrder = async (e: SelectOption<CustomerOrderExtended | SupplierOrderExtended>, orderId: string) => {
    const { context } = this.props;
    const { orderInfos, orders, type } = _.cloneDeep(this.state);
    const order = orders.find((o) => o.object?._id.toString() === orderId);
    const info = orderInfos.find((foi) => foi.orderId === orderId);
    if (order && info && e.object) {
      const orderIndex = indexOf(orders, order);
      const infoIndex = indexOf(orderInfos, info);
      if (orderIndex === 0) {
        // if the first order changes we need to reset since the rest is dependent on that
        await this.handleMainOrderChange(e.object);
      } else {
        orders[orderIndex] = this.getOrderSelectOption(e.object);
        orderInfos[infoIndex] = await this.getForwardingOrderOrderInfoCreation(e.object);
        const warnings = getWarnings(context, orders, orderInfos, type.value);
        this.setState({ orders, orderInfos: orderInfos, warnings });
      }
    }
  };

  handleMainOrderChange = async (order: SupplierOrderExtended | CustomerOrderExtended) => {
    const { context } = this.props;
    const orders = [this.getOrderSelectOption(order)];
    const orderInfos = [await this.getForwardingOrderOrderInfoCreation(order)];
    const type = isSupplierOrder(order)
      ? order.transport === T_AIRFREIGHT
        ? { value: T_AIRFREIGHT, label: "Air" }
        : order.transport === T_EUSTOCK || order.transport === T_WAREHOUSE
        ? { value: T_EUSTOCK, label: "Road" }
        : { value: T_SEAFREIGHT, label: "Sea" }
      : // customerOrders should always be loaded as road
        { value: T_EUSTOCK, label: "Road" };
    const remarks = this.getRemarks(type);
    const startIncoterm =
      isSupplierOrder(order) && order.terms
        ? SO_DELIVERYTERMS.find((t) => t.value === order.terms?.deliveryTerms)
        : undefined;
    const startingFrom = isSupplierOrder(order) ? getSupplierOrderStartString(order) : "";
    this.setState({
      orders,
      orderInfos,
      startIncoterm,
      startingFrom,
      type,
      forwarder: undefined,
      remarks,
      warnings: getWarnings(context, orders, orderInfos, type.value),
    });
  };

  handleChangeType = (e: SelectOption) => {
    const { context } = this.props;
    const { orders, orderInfos } = this.state;
    const remark = this.getRemarks(e);
    const warnings = getWarnings(context, orders, orderInfos, e.value);
    this.setState({ type: e, remarks: remark, warnings });
  };

  handleChangeForwarder = (e: SelectOption) => {
    this.setState({ forwarder: e });
  };

  handleChangeNetWeight = async (e: React.ChangeEvent<HTMLInputElement>, orderId: string) => {
    const forwardingOrderOrderInfos = _.cloneDeep(this.state.orderInfos);
    const info = forwardingOrderOrderInfos.find((foi) => foi.orderId.toString() === orderId);
    if (info) {
      info.netWeight = e.currentTarget.valueAsNumber;
      const order = this.state.orders.find((o) => o.object?._id.toString() === info.orderId);
      if (order && order.object) info.grossWeight = await getGrossWeight(order.object, info.netWeight);
      this.setState({ orderInfos: forwardingOrderOrderInfos });
    }
  };

  handleChangeGrossWeight = (e: React.ChangeEvent<HTMLInputElement>, orderId: string) => {
    const forwardingOrderOrderInfos = _.cloneDeep(this.state.orderInfos);
    const info = forwardingOrderOrderInfos.find((foi) => foi.orderId.toString() === orderId);
    if (info) {
      info.grossWeight = e.currentTarget.valueAsNumber;
      this.setState({ orderInfos: forwardingOrderOrderInfos });
    }
  };

  handleAddOrder = async () => {
    const { context } = this.props;
    const { orderInfos, orders, type } = _.cloneDeep(this.state);
    const usedOrders = orders.map((o) => o.object?._id.toString() ?? "");
    const order = getAvailableOrders(context, orders[0].object, usedOrders)[0];
    if (!order?.object) {
      toast.error("No suitable order found to add");
      return;
    }
    orders.push(this.getOrderSelectOption(order.object));
    orderInfos.push(await this.getForwardingOrderOrderInfoCreation(order.object));
    const warnings = getWarnings(context, orders, orderInfos, type.value);
    this.setState({ orders, orderInfos, warnings });
  };

  handleRemoveOrder = (orderId: string) => {
    const { context } = this.props;
    const { orderInfos, orders, type } = _.cloneDeep(this.state);
    const leftOrders = orders.filter((o) => o.object?._id.toString() !== orderId);
    const leftInfos = orderInfos.filter((foi) => foi.orderId !== orderId);
    const warnings = getWarnings(context, leftOrders, leftInfos, type.value);
    this.setState({ orders: leftOrders, orderInfos: leftInfos, warnings });
  };

  handleChangeStartIncoterm = (e: SelectOption) => {
    this.setState({ startIncoterm: e });
  };

  handleChangeStartFrom = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ startingFrom: e.currentTarget.value });
  };

  handleChangeDestinationIncoterm = (e: SelectOption, orderId: string) => {
    const forwardingOrderOrderInfos = _.cloneDeep(this.state.orderInfos);
    const info = forwardingOrderOrderInfos.find((foi) => foi.orderId.toString() === orderId);
    if (info) {
      info.destinationIncotermSelect = e;
      this.setState({ orderInfos: forwardingOrderOrderInfos });
    }
  };

  handleChangeDestinationTo = (e: React.ChangeEvent<HTMLInputElement>, orderId: string) => {
    const forwardingOrderOrderInfos = _.cloneDeep(this.state.orderInfos);
    const info = forwardingOrderOrderInfos.find((foi) => foi.orderId.toString() === orderId);
    if (info) {
      info.destinationTo = e.currentTarget.value;
      this.setState({ orderInfos: forwardingOrderOrderInfos });
    }
  };

  handleChangeSenderSelect = (e: AddressOrStringSelection, orderId: string) => {
    const forwardingOrderOrderInfos = _.cloneDeep(this.state.orderInfos);
    const info = forwardingOrderOrderInfos.find((foi) => foi.orderId.toString() === orderId);
    if (info) {
      info.senderSelect = e;
      info.sender = e.text;
      this.setState({ orderInfos: forwardingOrderOrderInfos });
    }
  };

  handleChangeRecipientSelect = (e: AddressOrStringSelection, orderId: string) => {
    const { context } = this.props;
    const { type, orders } = this.state;
    const forwardingOrderOrderInfos = _.cloneDeep(this.state.orderInfos);
    const info = forwardingOrderOrderInfos.find((foi) => foi.orderId.toString() === orderId);
    if (info) {
      info.recipientSelect = e;
      info.recipient = e.text;
      info.recipientInformation = e.additionalInfo;
      const warnings = getWarnings(context, orders, forwardingOrderOrderInfos, type.value);
      this.setState({ orderInfos: forwardingOrderOrderInfos, warnings });
    }
  };

  handleChangeRecipient = (e: React.ChangeEvent<HTMLTextAreaElement>, orderId: string) => {
    const { context } = this.props;
    const { orders, type } = this.state;
    const forwardingOrderOrderInfos = _.cloneDeep(this.state.orderInfos);
    const info = forwardingOrderOrderInfos.find((foi) => foi.orderId.toString() === orderId);
    if (info) {
      info.recipient = e.currentTarget.value;
      const warnings = getWarnings(context, orders, forwardingOrderOrderInfos, type.value);
      this.setState({ orderInfos: forwardingOrderOrderInfos, warnings });
    }
  };

  handleChangeInfoTextareaInputs = (e: React.ChangeEvent<HTMLTextAreaElement>, orderId: string) => {
    const forwardingOrderOrderInfos = _.cloneDeep(this.state.orderInfos);
    const info = forwardingOrderOrderInfos.find((foi) => foi.orderId.toString() === orderId);
    if (info) {
      // @ts-ignore
      info[e.target.name] = e.currentTarget.value;
      this.setState({ orderInfos: forwardingOrderOrderInfos });
    }
  };

  handleChangeReturnOrder = () => {
    this.setState({
      returnOrder: !this.state.returnOrder,
    });
  };

  handleChangeCustomsState = (orderId: string) => {
    const forwardingOrderOrderInfos = _.cloneDeep(this.state.orderInfos);
    const info = forwardingOrderOrderInfos.find((foi) => foi.orderId.toString() === orderId);
    if (info) {
      info.customsCleared = !info.customsCleared;
      this.setState({ orderInfos: forwardingOrderOrderInfos });
    }
  };

  handleChangeDelivery = (e: React.ChangeEvent<HTMLInputElement>, orderId: string) => {
    const deliveryDate = new Date(e.target.value);
    if (!deliveryDate) return;
    const forwardingOrderOrderInfos = _.cloneDeep(this.state.orderInfos);
    const info = forwardingOrderOrderInfos.find((foi) => foi.orderId.toString() === orderId);
    if (info) {
      info.deliveryDate = deliveryDate;
      this.setState({ orderInfos: forwardingOrderOrderInfos });
    }
  };

  handleChangeCWDelivery = (e: Date, orderId: string) => {
    const forwardingOrderOrderInfos = _.cloneDeep(this.state.orderInfos);
    const info = forwardingOrderOrderInfos.find((foi) => foi.orderId.toString() === orderId);
    if (info) {
      info.deliveryDate = e;
      this.setState({ orderInfos: forwardingOrderOrderInfos });
    }
  };

  handleChangeAsap = (orderId: string) => {
    const { context } = this.props;
    const { orders, type } = this.state;
    const forwardingOrderOrderInfos = _.cloneDeep(this.state.orderInfos);
    const info = forwardingOrderOrderInfos.find((foi) => foi.orderId.toString() === orderId);
    const order = orders.find((o) => o.object?._id.toString() === orderId)?.object;
    if (info) {
      if (info.deliveryDateType !== AdditionalDateTypeLogistics.ASAP) {
        info.deliveryDate = undefined;
      } else if (order) {
        info.deliveryDate = order.changedETA ? order.changedETA : order.targetDate ? order.targetDate : new Date();
      }
      info.deliveryDateType =
        info.deliveryDateType === AdditionalDateTypeLogistics.ASAP ? undefined : AdditionalDateTypeLogistics.ASAP;
      this.setState({
        orderInfos: forwardingOrderOrderInfos,
        warnings: getWarnings(context, orders, forwardingOrderOrderInfos, type.value),
      });
    }
  };

  handleChangeChecks = (orderId: string, fix: boolean) => {
    const { context } = this.props;
    const { orders, type } = this.state;
    const forwardingOrderOrderInfos = _.cloneDeep(this.state.orderInfos);
    const info = forwardingOrderOrderInfos.find((foi) => foi.orderId.toString() === orderId);
    if (info) {
      if (fix) info.deliveryDateType = info.deliveryDateType === DateType.FIX ? undefined : DateType.FIX;
      else info.deliveryDateType = info.deliveryDateType === DateType.CW ? undefined : DateType.CW;
      this.setState({
        orderInfos: forwardingOrderOrderInfos,
        warnings: getWarnings(context, orders, forwardingOrderOrderInfos, type.value),
      });
    }
  };

  handleChangeRemarks = (e: EditorState) => {
    this.setState({ remarks: e });
  };

  handleChangeOneAddress = () => this.setState({ oneAddress: !this.state.oneAddress });

  handleChangeCheckFilesToOverwrite = (fileId: string) => {
    const { filesToOverwrite } = _.cloneDeep(this.state);
    const toOverwrite = filesToOverwrite && filesToOverwrite.find((fto) => fto.file._id.toString() === fileId);
    if (toOverwrite) {
      toOverwrite.overwrite = !toOverwrite.overwrite;
    }
    this.setState({ filesToOverwrite: filesToOverwrite });
  };

  handleCreateDraft = async () => {
    const { context } = this.props;
    const { orders } = this.state;
    this.setState({ saving: "draft" });
    try {
      const ordersOnly = orders.map((o) => {
        return o.object;
      }) as Array<CustomerOrderExtended | SupplierOrderExtended>;
      const path = await createPDF(
        createForwardingOrder(context, ordersOnly, this.getForwardingOrder(), true),
        "DRAFT-ForwardingOrder",
        orders[0].object?._id.toString(),
        {
          marginLeft: "2cm",
          marginBottom: "4.2cm",
          footerHtml: FOOTER_WITHOUT_FINANCIAL_HTML,
        }
      );
      if (!path) {
        console.error("Forwarding order draft could not be created. Please try again later");
        return;
      } else {
        window.open(resolveFilePath(path));
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  handleConfirm = async () => {
    const { context } = this.props;
    const { type, returnOrder, orders, edit, filesToOverwrite } = this.state;
    this.setState({ saving: "forwardingOrder" });
    const forwardingOrder: ForwardingOrder = this.getForwardingOrder();
    try {
      const typeName = type.value === T_SEAAIRCOMBINED ? "SeaAirCombined" : type.label;
      const ordersOnly = orders.map((o) => {
        return o.object;
      }) as Array<CustomerOrderExtended | SupplierOrderExtended>;
      let result;
      let path;
      if (edit) {
        const fileName =
          "ForwardingOrderBy" + typeName + forwardingOrder.forwardingOrderNo + `${returnOrder ? "-return" : ""}`;
        path = await createPDF(
          createForwardingOrder(context, ordersOnly, forwardingOrder),
          fileName,
          orders[0].object?._id.toString(),
          {
            marginLeft: "2cm",
            marginBottom: "4.2cm",
            footerHtml: FOOTER_WITHOUT_FINANCIAL_HTML,
          }
        );
        if (!path) {
          console.error("Forwarding order PDF could not be created. Please try again later");
          return;
        } else {
          forwardingOrder.file = path;
          const res = await updateForwardingOrder(forwardingOrder, forwardingOrder._id);
          if (res && res.res.modifiedCount) {
            let actions: Array<Action> = [];
            // orders where pdf is replaced and therefore does not need additional upload
            const ordersReplaced: Array<string> = [];
            if (filesToOverwrite) {
              // replace forwarding order pdf in orders
              for (let i = 0; i < filesToOverwrite.length; i++) {
                const order = filesToOverwrite[i].order;
                const type = isSupplierOrder(order) ? SO_FORWARDINGORDER : CO_FORWARDINGORDER;
                const collection = isSupplierOrder(order) ? SUPPLIERORDER : CUSTOMERORDER;
                if (filesToOverwrite[i].overwrite) {
                  const file = filesToOverwrite[i].file;
                  actions.push({
                    collection: collection,
                    filter: { _id: order._id },
                    update: {
                      "files.$[f].path": path,
                      "files.$[f].date": new Date(),
                      "files.$[f].uploadedBy": userService.getUserId(),
                    },
                    arrayFilters: [{ "f._id": file._id }],
                    push: {
                      timeline: {
                        $each: [
                          isSupplierOrder(order)
                            ? getSupplierOrderTimelineEntry(SO_T_DOCUMENTREPLACED, { type })
                            : getCustomerOrderTimelineEntry(CO_T_DOCUMENTREPLACED, { type }),
                        ],
                      },
                    },
                  });
                  ordersReplaced.push(order._id.toString());
                }
              }
              // upload file to orders which are not in ordersReplaced
              for (let i = 0; i < orders.length; i++) {
                const order = orders[i].object;
                if (order && !ordersReplaced.includes(order._id.toString())) {
                  actions.push(this.getFileEntryAction(path, order, forwardingOrder._id.toString()));
                }
              }
            } else {
              actions = this.getActionsForUploadOrderFile(path, forwardingOrder._id.toString());
            }
            result = await transaction(actions);
          } else {
            toast.error("Error creating forwarding order PDF");
          }
        }
      } else {
        const fO = await insertForwardingOrder(forwardingOrder); // insert forwarding order into DB
        if (fO) {
          forwardingOrder.forwardingOrderNo = fO.forwardingOrderNo; // Create PDF with forwarding order number generated by backend
          const fileName =
            "ForwardingOrderBy" + typeName + forwardingOrder.forwardingOrderNo + `${returnOrder ? "-return" : ""}`;
          path = await createPDF(
            createForwardingOrder(context, ordersOnly, forwardingOrder),
            fileName,
            orders[0].object?._id.toString(),
            {
              marginLeft: "2cm",
              marginBottom: "4.2cm",
              footerHtml: FOOTER_WITHOUT_FINANCIAL_HTML,
            }
          );
          if (!path) {
            console.error("Forwarding order PDF could not be created. Please try again later");
            return;
          } else {
            const res = await updateForwardingOrder({ file: path }, fO.res.insertedId);
            if (res && res.res.modifiedCount) {
              const actions: Array<Action> = this.getActionsForUploadOrderFile(path, forwardingOrder._id.toString());
              result = await transaction(actions);
            } else {
              toast.error("Error creating forwarding order PDF");
            }
          }
        } else {
          toast.error("Error creating forwarding order");
        }
      }
      if (result && path) {
        window.open(resolveFilePath(path));
        toast.success("Successfully updated related orders");
        const query = new URLSearchParams(this.props.location.search);
        if (query.has("redirect")) {
          // Go back to page before
          this.props.history.goBack();
        } else this.props.history.push("/logistics");
      } else {
        toast.error("Error updating related orders");
      }
    } catch (e) {
      toast.error("An error has occurred on creating the forwarding order: " + e);
    } finally {
      this.setState({ saving: false });
    }
  };

  /**
   * Add the actions for updating the related orders with the file path
   * @param path the file path
   * @param forwardingOrderId The id of the forwarding order
   * @returns {Array<Action>} The actions for updating related orders with file paths
   */
  getActionsForUploadOrderFile = (path: string, forwardingOrderId: string): Array<Action> => {
    const { orders } = this.state;
    const actions: Array<Action> = [];
    // update orders and upload forwarding order pdf
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i].object;
      if (order) actions.push(this.getFileEntryAction(path, order, forwardingOrderId));
    }
    return actions;
  };

  /**
   * Get the action to update one specific order with the file
   * @param path The file path
   * @param order The customer order supplier order
   * @param forwardingOrderId The id of the forwarding order
   * @returns {Action} The action for the order
   */
  getFileEntryAction = (
    path: string,
    order: CustomerOrderExtended | SupplierOrderExtended,
    forwardingOrderId: string
  ): Action => {
    const type = isSupplierOrder(order) ? SO_FORWARDINGORDER : CO_FORWARDINGORDER;
    const collection = isSupplierOrder(order) ? SUPPLIERORDER : CUSTOMERORDER;
    const fileEntry = {
      _id: new BSON.ObjectId(),
      date: new Date(),
      path,
      type,
      uploadedBy: userService.getUserId(),
      reference: forwardingOrderId,
    };
    return {
      collection: collection,
      filter: { _id: order._id },
      push: {
        files: fileEntry,
        timeline: isSupplierOrder(order)
          ? getSupplierOrderTimelineEntry(SO_T_DOCUMENTUPLOADED, { type })
          : getCustomerOrderTimelineEntry(CO_T_DOCUMENTUPLOADED, { type }),
      },
    };
  };

  getRemarks = (type: SelectOption) => {
    const html =
      type.value === T_EUSTOCK
        ? FWO_REMARKS_ROAD
        : type.value === T_SEAFREIGHT
        ? FWO_REMARKS_SEA
        : type.value === T_AIRFREIGHT
        ? FWO_REMARKS_AIR
        : type.value === T_RAILFREIGHT
        ? FWO_REMARKS_RAIL
        : FWO_REMARKS_SEAAIRCOMBINED;

    const blocksFromHTML = convertFromHTML(html);
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    return EditorState.createWithContent(state);
  };

  /**
   * Get the ForwardingOrderInfoCreation Object for a specific order and get the default values for it
   * @param order The related order to get the default values from
   * @returns {Promise<ForwardingOrderInfoCreation>} The ForwardingOrderInfoCreation object
   */
  getForwardingOrderOrderInfoCreation = async (
    order: SupplierOrderExtended | CustomerOrderExtended
  ): Promise<ForwardingOrderInfoCreation> => {
    const { context } = this.props;
    let packaging = "";
    const bWithPackages = isSupplierOrder(order)
      ? context.batch.filter((b) => b.supplierOrder === order._id.toString() && b.packages.length > 0)
      : order.usedBatches?.filter((b) => b.packages.length > 0);
    packaging += bWithPackages
      ?.map((b) => {
        return getBatchPackagingInfo(b);
      })
      .join(", \n");
    const isFp = isAnyFinishedProduct(order.commodity);
    const fp = context.finishedProduct.find((fp) => fp._id.toString() === order.commodity._id.toString());
    const netWeightInAllExistingFWOs = getNetWeightOfOrderInExistingForwardingOrders(order, context);
    const netWeight =
      isFp && fp
        ? order.amount * fp.weightPerUnit - netWeightInAllExistingFWOs
        : order.amount - netWeightInAllExistingFWOs;
    const grossWeight = (await getGrossWeight(order, netWeight)) || 0;
    const senderSelect = this.preloadAddressSelection(order, true);
    const recipientSelect = this.preloadAddressSelection(order, false);
    const destinationIncSelect = SO_DELIVERYTERMS.find((t) => t.value === order.terms?.deliveryTerms);
    return {
      _id: new BSON.ObjectId(),
      orderId: order._id.toString(),
      orderNo: order.orderNo,
      packaging: packaging,
      netWeight: netWeight > 0 ? netWeight : 0, // for sanity
      grossWeight,
      customsCleared: isCustomerOrder(order),
      destinationIncotermSelect:
        isCustomerOrder(order) && order.terms && destinationIncSelect ? destinationIncSelect : { value: "", label: "" },
      destinationTo: isCustomerOrder(order)
        ? typeof order.destination === "string"
          ? order.destination
          : order.destination.city
        : // we assume that back to back order has only one customerOrder in the supplierOrder
        isBackToBackOrder(order) &&
          order.customerOrders[0].destination &&
          typeof order.customerOrders[0].destination !== "string"
        ? order.customerOrders[0].destination.city
        : "",
      sender: senderSelect.text,
      senderSelect,
      senderSelectOptions: this.getAllSenderSelections(order),
      recipient: recipientSelect.text,
      recipientSelect,
      recipientSelectOptions: this.getAllRecipientSelections(order),
      recipientInformation: recipientSelect.additionalInfo,
      deliveryDate:
        isCustomerOrder(order) && order.changedETA
          ? order.changedETA
          : isCustomerOrder(order) && order.targetDate
          ? order.targetDate
          : undefined,
      deliveryDateType:
        isCustomerOrder(order) && order.changedETAType
          ? order.changedETAType
          : isCustomerOrder(order) && order.targetDateType
          ? order.targetDateType
          : isCustomerOrder(order) && !order.targetDateType && !order.changedETAType
          ? undefined
          : AdditionalDateTypeLogistics.ASAP,
    };
  };

  /**
   * Get all possible sender addresses for an order for the select options
   * @param order The related order
   * @returns {Array<AddressOrStringSelection>} The select options for the sender addresses
   */
  getAllSenderSelections = (order: SupplierOrderExtended | CustomerOrderExtended): Array<AddressOrStringSelection> => {
    const { context } = this.props;
    const addressSelections: Array<AddressOrStringSelection> = [];
    if (isSupplierOrder(order)) {
      for (let i = 0; i < order.supplier.address.length; i++) {
        const address = order.supplier.address[i];
        const { contactPerson, backupUsed } = getSupplierAddressContactPerson(
          context,
          address,
          order.supplier._id.toString()
        );
        addressSelections.push({
          value: getAddressString(address),
          label: getAddressString(address),
          address: address,
          text: getAddress(this.props.context, address, order.supplier.name, contactPerson, false, true),
          // supplier address has no additional information yet
          additionalInfo: "",
          backupUsed: backupUsed,
        });
      }
    } else {
      // warehouse
      const glommAddress = getStandardWarehouseAddress(this.props.context); // Glomm should always be there, so default only for safety
      addressSelections.push({
        value: glommAddress?.addressString || "",
        label: glommAddress?.addressString || "",
        address: glommAddress?.address || "",
        text: getAddress(this.props.context, undefined, undefined, undefined, true, true),
        // notifies do not have additional information yet
        additionalInfo: "",
      });
      if (order.transport !== T_EUSTOCK) {
        const supplierOrders = this.props.context.supplierOrder;
        const supplierOrder = supplierOrders.find((sO) => sO.customerOrders.some((cO) => cO === order._id.toString()));
        if (supplierOrder) {
          const sO = extendSupplierOrder(supplierOrder, context);
          for (let i = 0; i < sO.supplier.address.length; i++) {
            const supplierAddress = sO.supplier.address[i];
            const addressString = getAddressString(supplierAddress);
            addressSelections.push({
              value: addressString,
              label: addressString,
              address: supplierAddress,
              text: getAddress(this.props.context, supplierAddress, sO.supplier.name, undefined, false, true),
              additionalInfo: "",
            });
          }
        }
      }
    }
    return addressSelections;
  };

  /**
   * Get all possible recipient addresses for an order for the select options
   * @param order The related order
   * @returns {Array<AddressOrStringSelection>} The select options for the recipient addresses
   */
  getAllRecipientSelections = (
    order: SupplierOrderExtended | CustomerOrderExtended
  ): Array<AddressOrStringSelection> => {
    const addressSelections: Array<AddressOrStringSelection> = [];
    if (isSupplierOrder(order)) {
      const glommAddress = getStandardWarehouseAddress(this.props.context); // Glomm should always be there, so default only for safety
      addressSelections.push({
        value: glommAddress?.addressString || "",
        label: glommAddress?.addressString || "",
        address: glommAddress?.address || "",
        text: getAddress(this.props.context, undefined, undefined, undefined, true, true),
        additionalInfo: "",
      });
      for (let i = 0; i < order.customerOrders.length; i++) {
        const cO = order.customerOrders[i];
        const additionalInfo = this.getAdditionalInfoStringAddress(cO.destination);
        const additionalDeliveryInfo = this.getAdditionalInfoStringDeliveryNote(cO);
        const separator = additionalInfo !== "" && additionalDeliveryInfo !== "" ? "\n" : "";
        const addressString = getAddressString(cO.destination);
        addressSelections.push({
          value: addressString,
          label: addressString,
          address: cO.destination,
          text: getAddress(
            this.props.context,
            cO.destination,
            order.supplier.name,
            isAddress(cO.destination) && cO.destination.contactPerson ? cO.destination.contactPerson : "",
            false,
            true
          ),
          additionalInfo: additionalInfo + separator + additionalDeliveryInfo,
        });
      }
    } else {
      const addressString = getAddressString(order.destination);
      const additionalInfo = this.getAdditionalInfoStringAddress(order.destination);
      const additionalDeliveryInfo = this.getAdditionalInfoStringDeliveryNote(order);
      const separator = additionalInfo !== "" && additionalDeliveryInfo !== "" ? "\n" : "";
      addressSelections.push({
        value: addressString,
        label: addressString,
        address: order.destination,
        text: getAddress(
          this.props.context,
          order.destination,
          order.company.name,
          isAddress(order.destination) && order.destination.contactPerson ? order.destination.contactPerson : "",
          false,
          true
        ),
        additionalInfo: additionalInfo + separator + additionalDeliveryInfo,
      });
    }
    return addressSelections;
  };

  /**
   * Get the additional information of an address as a string
   * @param address The related address
   * @returns {string} The additional info on this address as a string
   */
  getAdditionalInfoStringAddress = (address: Address | string): string => {
    const properties = this.props.context.property;
    if (typeof address !== "string" && address.properties && address.properties.length > 0) {
      const propertyStrings = [];
      for (let i = 0; i < address.properties.length; i++) {
        const propertyId = address.properties[i];
        const foundProperty = properties.find((p) => p._id.toString() === propertyId);
        if (foundProperty && !foundProperty.disabled) {
          propertyStrings.push(`${foundProperty.name.en}: ${foundProperty.description.en}`);
        }
      }
      return propertyStrings.join("\n");
    } else {
      return "";
    }
  };

  /**
   * Get the additional info of a delivery note of a customer order if given
   * @param order The related customer order
   * @returns {string} The additional information from shippingInformation or an empty string if not given
   */
  getAdditionalInfoStringDeliveryNote = (order: CustomerOrder | CustomerOrderExtended): string => {
    if (order.shippingInformation) return order.shippingInformation.additionalInformation;
    return "";
  };

  /**
   * Preload the selection for the sender or recipient address
   * @param order The related customer or supplier order
   * @param forSender boolean, flag if the selection is for sender or recipient
   * @returns {AddressOrStringSelection} The select option that is preloaded
   */
  preloadAddressSelection = (
    order: SupplierOrderExtended | CustomerOrderExtended,
    forSender: boolean
  ): AddressOrStringSelection => {
    const { context } = this.props;
    if (forSender) {
      let senderSelect: AddressOrStringSelection;
      if (isSupplierOrder(order)) {
        let address: Address | string;
        // for EU stock, check if there is an address of the supplier in shipment, if shipment not there in purchaseOrderInformation
        // for other supplier orders get the shipping address or the first address of the supplier
        if (order.transport === T_EUSTOCK && order.shipment.length > 0 && order.shipment[0].shipping.startingPoint) {
          address =
            !isSeaport(order.shipment[0].shipping.startingPoint) && !isAirport(order.shipment[0].shipping.startingPoint)
              ? order.shipment[0].shipping.startingPoint
              : ""; // should never happen
        } else if (order.transport === T_EUSTOCK && order.purchaseOrderInformation?.startingEUWarehouse)
          address = order.purchaseOrderInformation.startingEUWarehouse;
        else {
          const shippingAddress = getAddressByType(order.supplier.address, AddressType.A_SHIPPING);
          address = shippingAddress ? shippingAddress : order.supplier.address[0];
        }
        const addressString = getAddressString(address);
        const { contactPerson, backupUsed } = getSupplierAddressContactPerson(
          context,
          address,
          order.supplier._id.toString()
        );
        senderSelect = {
          value: addressString,
          label: addressString,
          address: address,
          text: getAddress(this.props.context, address, order.supplier.name, contactPerson, false, true),
          additionalInfo: "",
          backupUsed: backupUsed,
        };
      } else {
        // warehouse for customer orders
        const glommAddress = getStandardWarehouseAddress(this.props.context); // Glomm should always be there, so default only for safety
        senderSelect = {
          value: glommAddress?.addressString || "",
          label: glommAddress?.addressString || "",
          address: glommAddress?.address || "",
          text: getAddress(this.props.context, undefined, undefined, undefined, true, true),
          additionalInfo: "",
        };
      }
      return senderSelect;
    } else {
      let recipientSelect: AddressOrStringSelection;
      if (isSupplierOrder(order)) {
        if (isBackToBackOrder(order)) {
          // in case of Back to Back there is exactly one customer order
          const addressString = getAddressString(order.customerOrders[0].destination);
          const additionalInfo = this.getAdditionalInfoStringAddress(order.customerOrders[0].destination);
          const additionalDeliveryInfo = this.getAdditionalInfoStringDeliveryNote(order.customerOrders[0]);
          const separator = additionalInfo !== "" && additionalDeliveryInfo !== "" ? "\n" : "";
          const company = getDocFromCollection(context.company, order.customerOrders[0].company);
          recipientSelect = {
            value: addressString,
            label: addressString,
            address: order.customerOrders[0].destination,
            text: getAddress(
              this.props.context,
              order.customerOrders[0].destination,
              company?.name,
              isAddress(order.customerOrders[0].destination) && order.customerOrders[0].destination.contactPerson
                ? order.customerOrders[0].destination.contactPerson
                : "",
              false,
              true
            ),
            additionalInfo: additionalInfo + separator + additionalDeliveryInfo,
          };
        } else {
          const glommAddress = getStandardWarehouseAddress(this.props.context); // Glomm should always be there, so default only for safety
          recipientSelect = {
            value: glommAddress?.addressString || "",
            label: glommAddress?.addressString || "",
            address: glommAddress?.address || "",
            text: getAddress(this.props.context, undefined, undefined, undefined, true, true),
            additionalInfo: "",
          };
        }
      } else {
        const addressString = getAddressString(order.destination);
        const additionalInfo = this.getAdditionalInfoStringAddress(order.destination);
        const additionalDeliveryInfo = this.getAdditionalInfoStringDeliveryNote(order);
        const separator = additionalInfo !== "" && additionalDeliveryInfo !== "" ? "\n" : "";
        recipientSelect = {
          value: addressString,
          label: addressString,
          address: order.destination,
          text: getAddress(
            this.props.context,
            order.destination,
            order.company.name,
            isAddress(order.destination) && order.destination.contactPerson ? order.destination.contactPerson : "",
            false,
            true
          ),
          additionalInfo: additionalInfo + separator + additionalDeliveryInfo,
        };
      }
      return recipientSelect;
    }
  };

  getOrderSelectOption = (order: SupplierOrderExtended | CustomerOrderExtended) => {
    return { value: order._id.toString(), label: getOrderNumber(order), object: order };
  };

  /**
   * Get the forwarding order and change ForwardingOrderInfoCreation object into ForwardingOrderInformation object
   * @returns {ForwardingOrder} The forwarding order
   */
  getForwardingOrder = (): ForwardingOrder => {
    const { context, match } = this.props;
    const {
      type,
      forwarder,
      returnOrder,
      orderInfos,
      startIncoterm,
      startingFrom,
      remarks,
      edit,
      warnings,
      oneAddress,
    } = this.state;
    const id = match.params.id;
    const fw = context.forwardingOrder.find((fO) => fO._id.toString() === id);
    const htmlRemarks = getEditorStateAsHTML(remarks);
    const orderInformation: Array<ForwardingOrderInformation> = [];
    for (let i = 0; i < orderInfos.length; i++) {
      orderInformation.push({
        _id: orderInfos[i]._id,
        orderId: orderInfos[i].orderId,
        orderNo: orderInfos[i].orderNo,
        packaging: orderInfos[i].packaging,
        netWeight: orderInfos[i].netWeight,
        grossWeight: orderInfos[i].grossWeight,
        customsCleared: orderInfos[i].customsCleared,
        destinationIncoterm: orderInfos[i].destinationIncotermSelect.value,
        destinationTo: orderInfos[i].destinationTo,
        sender: orderInfos[i].sender,
        recipient: orderInfos[i].recipient,
        recipientInformation: orderInfos[i].recipientInformation,
        deliveryDate: orderInfos[i].deliveryDate,
        deliveryDateType: orderInfos[i].deliveryDateType,
      });
    }
    const forwardingOrder: ForwardingOrder = {
      _id: edit && fw ? fw._id : new BSON.ObjectId(),
      forwardingOrderNo: edit && fw ? fw.forwardingOrderNo : "",
      orderInformation,
      takeOver: {
        startIncoterm: startIncoterm ? startIncoterm.value : "", // validation should prevent default case
        startingFrom,
      },
      state: FWO_STATES.FWO_IN_TRANSIT,
      transportType: type.value,
      forwarder: forwarder ? forwarder.value : "", // validation should prevent default case
      remarks: htmlRemarks,
      returnOrder: returnOrder,
      file: edit && fw ? fw.file : "",
      createdAt: edit && fw ? fw.createdAt : new Date(),
      timeline: [getForwardingOrderTimelineEntry(FWO_TIMELINE.FWO_T_IN_TRANSIT)],
    };
    if (warnings.length > 0) {
      forwardingOrder.warnings = warnings;
    }
    if (oneAddress) forwardingOrder.oneAddress = oneAddress;
    return forwardingOrder;
  };

  /**
   * Get all possible forwarders for an order for the select options
   * Excludes forwarders if they are disabled, have the customer from an order on their blacklist and/or do not deliver to the country of the destination address
   * @returns { {selectOptions: Array<SelectOption>, countriesResolved: boolean, destinationCountries: Array<string>} } The select options for the forwarder, whether they could be filtered by country and for which countries the orders are intended
   */
  getForwarderSelectOptions = (): {
    selectOptions: Array<SelectOption>;
    countriesResolved: boolean;
    destinationCountries: Array<string>;
  } => {
    const { context } = this.props;
    const { orders, orderInfos } = this.state;
    let countriesResolved = true;
    const orderCompanyIds: Array<string> = [];
    const destinationCountries: Array<string> = [];
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i].object;
      const orderRecipient = orderInfos[i].recipientSelect.address;
      if (isAddress(orderRecipient)) {
        destinationCountries.push(orderRecipient.country);
      } else {
        // if no country could be loaded from the address (e.g. because it was a string), a warning will be displayed
        countriesResolved = false;
      }
      if (order && isBackToBackOrder(order)) {
        if (isSupplierOrder(order)) {
          orderCompanyIds.push(order.customerOrders[0].company);
        } else {
          orderCompanyIds.push(order.company._id.toString());
        }
      }
    }
    const selectOptions = context.forwarder
      .filter(
        (f) =>
          // exclude disabled forwarders
          !f.disabled &&
          // exclude forwarders whose blacklists contain any of the orderCompanyIds.
          !f.blacklists.some((id) => orderCompanyIds.includes(id)) &&
          // include only forwarders which deliver to all countries in destinationCountries
          // if no destinationCountries could be determined from the order addresses, ignore this step
          (destinationCountries.length === 0 ||
            (f.countries.length > 0 && destinationCountries.every((country) => f.countries.includes(country))))
      )
      .map((f) => ({ value: f._id.toString(), label: f.name }));
    return {
      selectOptions: selectOptions,
      countriesResolved: countriesResolved,
      destinationCountries: destinationCountries,
    };
  };

  getDefaultState = (props: CreateForwardingOrderProps): CreateForwardingOrderState => {
    const { context, match, location } = props;
    const id = match.params.id; // id will be passed on edit
    const queryParams = new URLSearchParams(location.search);
    const orderIds = queryParams.get("orderIds"); // orderIds will be passed on FWO creation from SCM dashboard
    const fw = context.forwardingOrder.find((fO) => fO._id.toString() === id);
    // state if forwarding order is edited
    if (id && fw) {
      const orders: Array<SelectOption<CustomerOrderExtended | SupplierOrderExtended>> = [];
      const orderInfos: Array<ForwardingOrderInfoCreation> = [];
      for (let i = 0; i < fw.orderInformation.length; i++) {
        const info = fw.orderInformation[i];
        let order: SupplierOrder | CustomerOrder | undefined = context.supplierOrder.find(
          (sO) => sO._id.toString() === info.orderId
        );
        let orderExtended: SupplierOrderExtended | CustomerOrderExtended | undefined;
        if (!order) {
          order = context.customerOrder.find((cO) => cO._id.toString() === info.orderId);
          if (order) {
            orderExtended = extendCustomerOrder(order, context);
            orders.push({ value: order._id.toString(), label: getOrderNumber(order), object: orderExtended });
          }
        } else {
          orderExtended = extendSupplierOrder(order, context);
          orders.push({ value: order._id.toString(), label: getOrderNumber(order), object: orderExtended });
        }
        if (order && orderExtended) {
          const incoterm = SO_DELIVERYTERMS.find((t) => t.value === info.destinationIncoterm);
          orderInfos.push({
            ...info,
            destinationIncotermSelect: incoterm
              ? incoterm
              : {
                  value: "",
                  label: "",
                },
            destinationTo: info.destinationTo,
            senderSelect: {
              value: "",
              label: "",
              address: "",
              text: info.sender,
              additionalInfo: fw.returnOrder && info.recipientInformation ? info.recipientInformation : "",
            },
            senderSelectOptions: this.getAllSenderSelections(orderExtended),
            recipientSelect: {
              value: "",
              label: "",
              address: "",
              text: info.recipient,
              additionalInfo: !fw.returnOrder && info.recipientInformation ? info.recipientInformation : "",
            },
            recipientSelectOptions: this.getAllRecipientSelections(orderExtended),
            customsCleared: info.customsCleared === undefined ? false : info.customsCleared,
            sender: info.sender,
            recipient: info.recipient,
            recipientInformation: info.recipientInformation,
            deliveryDate: info.deliveryDate ? info.deliveryDate : order.deliveryDate,
            deliveryDateType: info.deliveryDateType,
          });
        }
      }
      const mainOrder = orders[0];
      const transportTypeOnEdit = O_TRANSPORTTYPES_FORWARDINGORDER.find((t) => t.value === fw.transportType);
      const type =
        transportTypeOnEdit ||
        (mainOrder.object?.transport === T_AIRFREIGHT
          ? { value: T_AIRFREIGHT, label: "Air" }
          : mainOrder.object?.transport === T_EUSTOCK || mainOrder.object?.transport === T_WAREHOUSE
          ? { value: T_EUSTOCK, label: "Road" }
          : { value: T_SEAFREIGHT, label: "Sea" });
      const forwarderOnEdit = context.forwarder.find((f) => f._id.toString() === fw.forwarder);
      const forwarderSelectOption = {
        value: forwarderOnEdit?._id.toString() || "",
        label: forwarderOnEdit?.name || "",
      };
      const filesToOverwrite: Array<OverwriteFiles> = [];
      for (let i = 0; i < orders.length; i++) {
        const oO = orders[i].object;
        if (oO) {
          for (let j = 0; j < oO.files.length; j++) {
            const file = oO.files[j];
            if (
              (file.type === SO_FORWARDINGORDER || file.type === CO_FORWARDINGORDER) &&
              // if there is no reference, better show all files
              (file.reference ? fw._id.toString() === file.reference : true)
            ) {
              filesToOverwrite.push({ order: oO, file: file, overwrite: true });
            }
          }
        }
      }
      return {
        edit: true,
        filesToOverwrite,
        saving: false,
        type,
        forwarder: forwarderSelectOption,
        orders,
        orderInfos,
        startIncoterm: SO_DELIVERYTERMS.find((t) => t.value === fw.takeOver.startIncoterm),
        startingFrom: fw.takeOver.startingFrom,
        returnOrder: fw.returnOrder,
        remarks: getStringAsEditorState(fw.remarks),
        warnings: fw.warnings ? fw.warnings : [],
        oneAddress: fw.oneAddress !== undefined ? fw.oneAddress : false,
      };
    } else if (orderIds) {
      // state if forwarding order is created from pregrouped orders in SCM dashboard
      const ids = orderIds.split(",");
      const orders: Array<SelectOption<SupplierOrderExtended | CustomerOrderExtended>> = [];
      const orderInfos: Array<ForwardingOrderInfoCreation> = [];
      for (let i = 0; i < ids.length; i++) {
        let order: SupplierOrder | CustomerOrder | undefined = context.supplierOrder.find(
          (sO) => sO._id.toString() === ids[i]
        );
        if (!order) {
          order = context.customerOrder.find((cO) => cO._id.toString() === ids[i]);
          if (order) {
            const orderExtended = extendCustomerOrder(order, context);
            orders.push({ value: order._id.toString(), label: getOrderNumber(order), object: orderExtended });
            orderInfos.push(getDefaultForwardingOrderInfo(order));
          }
        } else {
          const orderExtended = extendSupplierOrder(order, context);
          orders.push({ value: order._id.toString(), label: getOrderNumber(order), object: orderExtended });
          orderInfos.push(getDefaultForwardingOrderInfo(order));
        }
      }
      const mainOrder = orders[0];
      const type =
        mainOrder.object?.transport === T_AIRFREIGHT
          ? { value: T_AIRFREIGHT, label: "Air" }
          : mainOrder.object?.transport === T_EUSTOCK
          ? { value: T_EUSTOCK, label: "Road" }
          : { value: T_SEAFREIGHT, label: "Sea" };
      return {
        edit: false,
        filesToOverwrite: undefined,
        saving: false,
        type,
        forwarder: undefined,
        orders,
        orderInfos,
        startIncoterm:
          isSupplierOrder(mainOrder.object) && mainOrder.object?.terms
            ? SO_DELIVERYTERMS.find((t) => t.value === mainOrder.object?.terms?.deliveryTerms)
            : undefined,
        startingFrom: isSupplierOrder(mainOrder.object) ? getSupplierOrderStartString(mainOrder.object) : "",
        returnOrder: false,
        remarks: this.getRemarks(type),
        warnings: [],
        oneAddress: false,
      };
    }
    // state if forwarding order is created
    else {
      const availableOrders = getAvailableOrders(props.context);
      if (availableOrders.length > 0) {
        const mainOrder = availableOrders[0];
        const type =
          mainOrder.object?.transport === T_AIRFREIGHT
            ? { value: T_AIRFREIGHT, label: "Air" }
            : mainOrder.object?.transport === T_EUSTOCK
            ? { value: T_EUSTOCK, label: "Road" }
            : { value: T_SEAFREIGHT, label: "Sea" };
        return {
          edit: false,
          filesToOverwrite: undefined,
          saving: false,
          type,
          forwarder: undefined,
          orders: [mainOrder],
          orderInfos: mainOrder.object
            ? [
                getDefaultForwardingOrderInfo(
                  isSupplierOrder(mainOrder.object)
                    ? reduceSupplierOrder(mainOrder.object)
                    : reduceCustomerOrder(mainOrder.object)
                ),
              ]
            : [],
          startIncoterm:
            isSupplierOrder(mainOrder.object) && mainOrder.object?.terms
              ? SO_DELIVERYTERMS.find((t) => t.value === mainOrder.object?.terms?.deliveryTerms)
              : undefined,
          startingFrom: isSupplierOrder(mainOrder.object) ? getSupplierOrderStartString(mainOrder.object) : "",
          returnOrder: false,
          remarks: this.getRemarks(type),
          warnings: [],
          oneAddress: false,
        };
      } else {
        // state in case no order can be selected
        return {
          edit: false,
          filesToOverwrite: undefined,
          saving: false,
          type: { value: T_SEAFREIGHT, label: "Sea" },
          forwarder: undefined,
          orders: [],
          orderInfos: [],
          startIncoterm: undefined,
          startingFrom: "",
          returnOrder: false,
          remarks: this.getRemarks({ value: T_SEAFREIGHT, label: "Sea" }),
          warnings: [],
          oneAddress: false,
        };
      }
    }
  };

  populateData = async () => {
    const { context } = this.props;

    if (!this.state.edit) {
      const orders = _.cloneDeep(this.state.orders);
      const populatedForwardingOrderInfoCreation: Array<ForwardingOrderInfoCreation> = [];
      for (const order of orders) {
        if (!order.object) continue; // Should never happen
        const forwardingOrderInfo = await this.getForwardingOrderOrderInfoCreation(order.object);
        populatedForwardingOrderInfoCreation.push(forwardingOrderInfo);
      }
      this.setState({
        orderInfos: populatedForwardingOrderInfoCreation,
        warnings: getWarnings(context, this.state.orders, populatedForwardingOrderInfoCreation, this.state.type.value),
      });
    }
  };

  validateData = () => {
    const { forwarder, startIncoterm, startingFrom, orderInfos } = this.state;
    const errors: Array<string> = [];
    if (orderInfos.length === 0) errors.push("No orders available for forwarding at the moment");
    if (orderInfos.some((oi) => oi.sender.trim() === "")) errors.push("Enter sender address for all orders");
    if (orderInfos.some((oi) => oi.recipient.trim() === "")) errors.push("Enter recipient address for all orders");
    if (orderInfos.some((oi) => oi.destinationIncotermSelect.value === ""))
      errors.push("Select destination incoterm for all orders");
    if (forwarder === undefined) errors.push("Select forwarder");
    if (startIncoterm === undefined) errors.push("Select start incoterm");
    if (startingFrom.trim() === "") errors.push("Enter starting from");
    if (orderInfos.some((oi) => oi.destinationTo.trim() === "")) errors.push("Enter destination to for all orders");
    return errors;
  };

  render() {
    const { context, match } = this.props;
    const {
      edit,
      filesToOverwrite,
      orderInfos,
      orders,
      type,
      forwarder,
      returnOrder,
      remarks,
      saving,
      startIncoterm,
      startingFrom,
      warnings,
      oneAddress,
    } = this.state;
    const roles = userService.getRoles();
    const isSCMOrAdmin = roles.includes(ADMIN) || roles.includes(SCM);
    // necessary for layout
    const forwardingOrderBlocks = [];
    const usedOrders = orders.map((o) => o.object?._id.toString()) as Array<string>;
    if (orderInfos) {
      for (let i = 0; i < orderInfos.length; i++) {
        const order = orders.find((o) => o.object?._id.toString() === orderInfos[i].orderId);
        if (order && orders[0].object) {
          forwardingOrderBlocks.push({
            jsx: (
              <div className="row mb-2">
                <ForwardingOrderInfo
                  context={context}
                  order={order}
                  orderInfo={orderInfos[i]}
                  isFirst={i === 0}
                  firstOrder={orders[0].object}
                  allUsedOrders={usedOrders}
                  returnOrder={returnOrder}
                  onChangeNetWeight={this.handleChangeNetWeight}
                  onChangeGrossWeight={this.handleChangeGrossWeight}
                  onChangeOrder={this.handleChangeOrder}
                  onChangeCustomsCleared={this.handleChangeCustomsState}
                  onChangeDestinationIncoterm={this.handleChangeDestinationIncoterm}
                  onChangeDestinationTo={this.handleChangeDestinationTo}
                  onChangeSenderSelect={this.handleChangeSenderSelect}
                  onChangeRecipientSelect={this.handleChangeRecipientSelect}
                  onChangeRecipient={this.handleChangeRecipient}
                  onChangeInfoTextareaInputs={this.handleChangeInfoTextareaInputs}
                  onChangeDeliveryDate={this.handleChangeDelivery}
                  onChangeDeliveryDateCW={this.handleChangeCWDelivery}
                  onChangeAsap={this.handleChangeAsap}
                  onChangeChecks={this.handleChangeChecks}
                  onRemoveOrder={this.handleRemoveOrder}
                />
              </div>
            ),
            key: order.object?._id.toString(),
          });
        }
      }
    }
    const id = match.params.id;
    const fwToEdit = context.forwardingOrder.find((fO) => fO._id.toString() === id);
    const errors = this.validateData();
    const forwarderSelectOptions = this.getForwarderSelectOptions();

    return (
      <div className="content d-flex flex-column flex-column-fluid">
        {isSCMOrAdmin ? (
          <div className="container-xxl">
            <div className="ms-lg-15">
              <div className="card bg-white">
                <div className="card-header mt-6 border-none">
                  <div className="card-title flex-column">
                    <h2 className="mb-1">
                      {edit && fwToEdit
                        ? `Edit Forwarding Order FW-${fwToEdit.forwardingOrderNo}`
                        : "Create Forwarding Order"}
                    </h2>
                  </div>
                </div>
                <div className="card-body p-9 pt-0">
                  <div className="border-bottom-dark-gray pt-5 my-2" />
                  {forwardingOrderBlocks.map((row) => {
                    return <React.Fragment key={row.key}>{row.jsx}</React.Fragment>;
                  })}
                  <div className="pt-5 my-2">
                    <button className="btn btn-light btn-sm" onClick={this.handleAddOrder}>
                      Add Order
                    </button>
                  </div>
                  <div className="border-bottom-dark-gray pt-5 my-2" />
                  {warnings.length > 0 && (
                    <div
                      className="p-4 my-7 badge badge-warning text-black text-wrap lh-base w-100"
                      style={{ fontSize: "14px", color: "#000000" }}
                    >
                      <div className="row mx-2">
                        <div className="col-1">
                          <i className="flaticon-danger text-black" style={{ fontSize: "50px", color: "#000000" }} />
                        </div>
                        <div className="col-11 my-auto">
                          <b>Warnings: </b>
                          {warnings
                            .map((w) => {
                              if (w !== FWO_WARNINGS.ALREADYEXISTINGFWOS) return getWarningText(w);
                              else {
                                const alreadyUsedWarnings = [];
                                for (let i = 0; i < orders.length; i++) {
                                  const order = orders[i].object;
                                  if (order) {
                                    const usedAmount = getNetWeightOfOrderInExistingForwardingOrders(order, context);
                                    if (usedAmount > 0) {
                                      alreadyUsedWarnings.push(
                                        `Order ${getOrderNumber(
                                          order
                                        )} has forwarding orders already with a total net weight of ${usedAmount}`
                                      );
                                    }
                                  }
                                }
                                return alreadyUsedWarnings.join(", ");
                              }
                            })
                            .join(", ")}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row pt-5 align-items-center mb-4">
                    <span className="col-4 col-form-label fs-6 fw-bold">Type</span>
                    <div className="col-8">
                      <CustomSelect
                        options={O_TRANSPORTTYPES_FORWARDINGORDER}
                        value={type}
                        matchFormControl={true}
                        onChange={this.handleChangeType}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mb-4">
                    <span className="col-4 col-form-label fs-6 fw-bold">Forwarder</span>
                    <div className="col-8">
                      <CustomSelect
                        options={forwarderSelectOptions.selectOptions}
                        value={forwarder}
                        matchFormControl={true}
                        onChange={this.handleChangeForwarder}
                      />
                      {forwarderSelectOptions.selectOptions.length === 0 && (
                        <span className="text-warning">
                          No matching forwarders found. Either the forwarders are disabled, blacklisted for the company
                          or do not deliver to{" "}
                          {forwarderSelectOptions.destinationCountries.length > 0
                            ? forwarderSelectOptions.destinationCountries.join(", ")
                            : "the selected destination country"}
                        </span>
                      )}
                      {!forwarderSelectOptions.countriesResolved && (
                        <span className="text-warning">
                          The country of one or more delivery addresses could not be verified. Please check the
                          deliverability to the specified address(es).
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row align-items-center mb-4">
                    <span className="col-4 col-form-label fs-6 fw-bold">Starting Incoterm</span>
                    <div className="col-8">
                      <div className="fw-bolder text-white fs-5 my-2">
                        <CustomSelect
                          options={SO_DELIVERYTERMS}
                          value={startIncoterm}
                          matchFormControl={true}
                          onChange={this.handleChangeStartIncoterm}
                          placeholder={"Select starting incoterm"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center mb-4">
                    <span className="col-4 col-form-label fs-6 fw-bold">Starting From</span>
                    <div className="col-8">
                      <div className="fw-bolder text-white fs-5 my-2">
                        <Input
                          className="form-control custom-form-control"
                          value={startingFrom}
                          onChange={this.handleChangeStartFrom}
                          placeholder="Insert starting from"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <span className="col-4 col-form-label fs-6 fw-bold"></span>
                    <div className="col-8">
                      <div className="form-check form-check-sm form-check-custom form-check-solid">
                        <input
                          className="form-check-input position-static"
                          checked={returnOrder}
                          type="checkbox"
                          onChange={this.handleChangeReturnOrder}
                        />
                        <label className="form-check-label fs-5">Return Shipment</label>
                      </div>
                    </div>
                  </div>
                  {orders.length > 1 && (
                    <div className="row mb-4">
                      <span className="col-4 col-form-label fs-6 fw-bold"></span>
                      <div className="col-8">
                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                          <input
                            className="form-check-input position-static"
                            checked={oneAddress}
                            type="checkbox"
                            onChange={this.handleChangeOneAddress}
                          />
                          <label className="form-check-label fs-5">Use only address of first order on pdf</label>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row mb-4">
                    <span className="col-4 col-form-label fs-6 fw-bold">Remarks</span>
                    <div className="col-8">
                      <WysiwygEditor content={remarks} onChange={this.handleChangeRemarks} />
                    </div>
                  </div>
                  {edit &&
                    filesToOverwrite &&
                    filesToOverwrite.map((fto) => {
                      return (
                        <div className="row mb-4" key={fto.file._id.toString()}>
                          <span className="col-4 col-form-label fs-6 fw-bold"></span>
                          <div className="col-8">
                            <div className="form-check form-check-sm form-check-custom form-check-solid">
                              <input
                                className="form-check-input position-static"
                                checked={fto.overwrite}
                                type="checkbox"
                                onChange={() => this.handleChangeCheckFilesToOverwrite(fto.file._id.toString())}
                              />
                              <label className="form-check-label fs-5">
                                Overwrite {shortenAlias(fto.file.path)} in order {getOrderNumber(fto.order)}
                              </label>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="card-footer pt-0 border-0">
                  <div className="border-bottom-dark-gray pt-5" />
                  <div className="pt-3">
                    <div className="d-flex pt-3 align-items-center w-100">
                      <button
                        className="btn btn-light btn-sm float-right"
                        onClick={() => {
                          this.props.history.push("/logistics");
                        }}
                      >
                        Back to Listing
                      </button>
                      <button
                        className="btn btn-light btn-sm float-right ml-4"
                        onClick={() => {
                          this.props.history.push("/scm");
                        }}
                      >
                        Back to Dashboard
                      </button>
                      <ErrorOverlayButton
                        errors={errors}
                        className="btn btn-link btn-sm btn-text-white ml-auto"
                        saving={!!saving}
                        buttonText={saving === "draft" ? "Generating" : "Preview"}
                        onClick={this.handleCreateDraft}
                      />
                      <ErrorOverlayButton
                        errors={errors}
                        className={"btn btn-light btn-sm ml-4 float-right"}
                        buttonText={
                          saving === "forwardingOrder"
                            ? "Generating"
                            : edit
                            ? "Update Forwarding Order"
                            : "Create Forwarding Order"
                        }
                        saving={!!saving}
                        onClick={this.handleConfirm}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="post d-flex flex-column-fluid">
            <div className="container-xxl">
              <div className="card bg-white min-h-100">
                <div className="card-body">
                  <h3 className="card-title ">
                    {edit && fwToEdit
                      ? `Edit Forwarding Order FW-${fwToEdit.forwardingOrderNo}`
                      : "Create Forwarding Order"}
                  </h3>
                  <h5 className="mt-20 text-center">
                    <span className="text-muted">You do not have access to this section</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(CreateForwardingOrder);
