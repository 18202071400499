import React, { useContext } from "react";
import { DataContextInternal } from "../../../context/dataContext";
import ForwarderListing from "./ForwarderListing";

interface ForwarderListingWrapperProps {}

const ForwarderListingWrapper: React.FunctionComponent<ForwarderListingWrapperProps> = (props) => {
  const context = useContext(DataContextInternal);

  return <ForwarderListing {...props} context={context} />;
};

export default ForwarderListingWrapper;
