import { BSON } from "realm-web";
import { CommoditySnapshot } from "./commodity.types";
import { FinishedProductSnapshot } from "./finishedProduct.types";
import { CustomerContract } from "./customerContract.types";
import { CustomerOrder } from "./customerOrder.types";
import { SupplierOrder } from "./supplierOrder.types";
import { Supplier } from "./supplier.types";

export const B_INCOMING = "incoming";
export const B_RELEASED = "released";
export const B_BLOCKED = "blocked";

export interface Batch {
  _id: BSON.ObjectId;
  lot: string;
  state: typeof B_INCOMING | typeof B_RELEASED | typeof B_BLOCKED;
  identifier: string;
  stockedDate: Date;
  expiry: Date;
  commodity: CommoditySnapshot | FinishedProductSnapshot; // Snapshot of commodity or finished product that this batch contains with ONLY the used variant
  packages: Array<BatchPackage>;
  notes: string;
  supplier: string;
  supplierOrder?: string;
  customerOrders: Array<string>;
  customerContracts?: Array<string>;
  unit: "kg" | "ltr" | "1000 pcs";
  price: number; // Price per unit (kg/ltr/1000pcs) netto
  priceCurrency: string;
  supplierCoA: BatchFile;
  ownCoA: BatchFile | null;
  timeline: Array<BatchTimeline>;
  disabled: boolean; // Should be set when batch amount is 0 - because we have to track stuff
}

export interface BatchExtended
  extends Omit<Batch, "supplier" | "supplierOrder" | "customerOrders" | "customerContracts"> {
  supplier: Supplier;
  supplierOrder?: SupplierOrder;
  customerOrders: Array<CustomerOrder>;
  customerContracts?: Array<CustomerContract>;
}

export interface BatchFile {
  _id: BSON.ObjectId;
  name: string;
  path: string;
  date: Date;
  uploadedBy: string;
}

export interface BatchPackage {
  _id: BSON.ObjectId; // Note: On edit/book out these ids may not be persistent!!
  number: number;
  amountEach: number;
  packageType: PackageType;
  warehouse: "main" | "outside" | "bio";
  warehouseLocation: string;
  usedOrders?: Array<string>;
  // add list of customer orders the package was used for
}

export type PackageType = "drums" | "barrel" | "bags" | "bottle" | "can" | "carton" | "drumsOpen";

export interface BatchTimeline {
  _id: BSON.ObjectId;
  person: string;
  date: Date;
  type: string;
  note?: string;
  pre?: BatchComparisonObject;
  post?: BatchComparisonObject;
}

export interface BatchComparisonObject {
  lot?: string;
  state?: string;
  stockedDate?: Date;
  expiry?: Date;
  packages?: Array<BatchPackage>;
  notes?: string;
  supplier?: string;
  customerOrders?: Array<string>;
  unit?: "kg" | "ltr";
  price?: number;
  priceCurrency?: string;
  supplierCoA?: BatchFile;
  ownCoA?: BatchFile;
}
