import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContextAnonymous, DataContextCustomer } from "../../../context/dataContext";
import ActiveOrders from "../../common/customer/ActiveOrders";
import CustomerDashboardPriceTrends from "./CustomerDashboardPriceTrends";
import CustomerDashboardNews from "./CustomerDashboardNews";
import CustomerDashboardFavorites from "./CustomerDashboardFavorites";
import CustomerDashboardDeliveries from "./CustomerDashboardDeliveries";
import OrderUpdates from "../../common/customer/OrderUpdates";
import userService from "../../../services/userService";
import { ANONYMOUS, CUSTOMER } from "../../../utils/userUtils";

interface CustomerDashboardProps extends RouteComponentProps {}

const CustomerDashboard: React.FunctionComponent<CustomerDashboardProps> = (props) => {
  let context;
  if (userService.getUserType() === CUSTOMER) {
    context = useContext(DataContextCustomer);
  } else if (userService.getUserType() === ANONYMOUS) {
    context = useContext(DataContextAnonymous);
  }
  if (!context) return <></>;
  const cardHeight = 570;
  return (
    <div className="d-flex flex-column flex-column-fluid pt-10">
      <div className="d-flex flex-column-fluid">
        <div className="container-fluid dashboard-container">
          <div className="row g-6">
            <div className="col-12 col-xxl-6" style={{ height: cardHeight }}>
              <ActiveOrders context={context} {...props} />
            </div>
            <div className="col-12 col-xl-6 col-xxl-3" style={{ height: cardHeight }}>
              <OrderUpdates context={context} />
            </div>
            <div className="col-12 col-xl-6 col-xxl-3" style={{ height: cardHeight }}>
              <CustomerDashboardPriceTrends context={context} />
            </div>
            <div className="col-12 col-xl-6 col-xxl-4" style={{ height: cardHeight }}>
              <CustomerDashboardFavorites context={context} {...props} />
            </div>
            <div className="col-12 col-xl-6 col-xxl-4" style={{ height: cardHeight }}>
              <CustomerDashboardDeliveries context={context} {...props} />
            </div>
            <div className="col-12 col-xxl-4" style={{ height: cardHeight }}>
              <CustomerDashboardNews context={context} {...props} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDashboard;
