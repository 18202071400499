import JSZip from "jszip";
import { resolveFilePath } from "./fileUtils";
import { saveAs } from "file-saver";

/**
 * Get response data from XHLHttpRequest
 * @param xhr XMLHttpRequest
 * @returns {any} data according to the responseType
 */
// just use the responseText with xhr1, response with xhr2.
const _getBinaryFromXHR = (xhr: XMLHttpRequest): any => xhr.response || xhr.responseText;

/**
 * Fetch a file and get its content
 * @param path the path to the resource to GET
 * @param callback the callback function
 * @returns {Promise<any> | undefined} promise or undefined whether a callback was provided or not
 */
function getBinaryContent(
  path: string,
  callback: (err: Error | null, data: ArrayBuffer | string | null) => void
): Promise<any> | undefined {
  let promise: Promise<any> | undefined, resolve: (value: any) => void, reject: (reason?: any) => void;

  if (!callback && typeof Promise !== "undefined") {
    promise = new Promise(function (_resolve, _reject) {
      resolve = _resolve;
      reject = _reject;
    });
  } else {
    resolve = function (data: ArrayBuffer | string) {
      callback(null, data);
    };
    reject = function (err: Error) {
      callback(err, null);
    };
  }

  try {
    const xhr = new XMLHttpRequest();
    if (!xhr) {
      reject!(new Error("XHR could not be created"));
      return promise;
    }
    xhr.open("GET", path, true);
    xhr.withCredentials = true;

    // recent browsers
    if ("responseType" in xhr) {
      xhr.responseType = "arraybuffer";
    }
    // older browser
    if (xhr.overrideMimeType) {
      xhr.overrideMimeType("text/plain; charset=x-user-defined");
    }

    xhr.onreadystatechange = function () {
      if (xhr && xhr.readyState === 4) {
        if (xhr.status === 200 || xhr.status === 0) {
          try {
            resolve(_getBinaryFromXHR(xhr));
          } catch (err) {
            reject(err);
          }
        } else {
          reject(new Error("Error for " + path + " : " + this.status + " " + this.statusText));
        }
      }
    };

    xhr.send();
  } catch (e) {
    reject!(e);
  }

  return promise;
}

/**
 * ZIPs the given documents into an archive with the given name.
 * @param fileName Name of the archive
 * @param documents List of files that should be zipped
 * @param callback Optional callback after successful execution
 */
export function zipDocuments(fileName: string, documents: Array<string>, callback?: () => void) {
  const zip = new JSZip();
  const zipFilename = fileName + ".zip";
  let count = 0;
  for (let i = 0; i < documents.length; i++) {
    const file = documents[i];
    getBinaryContent(resolveFilePath(file), (err: Error | null, data: string | ArrayBuffer | null) => {
      if (!err) {
        count++;
        zip.file(file, data ?? "", { binary: true });
      }
      if (count === documents.length) {
        zip.generateAsync({ type: "blob" }).then((content) => {
          saveAs(content, zipFilename);
          callback && callback();
        });
      }
    });
  }
}

export default { getBinaryContent };
