import { Link } from "react-router-dom";
import React, { ChangeEvent, PureComponent } from "react";
import {
  DataContextType,
  isCustomerContext,
  isSupplierContext,
  isInternalContext,
  resolveContextType,
  isAnonymousContext,
  DataContextInternalType,
  DataContextCustomerType,
  DataContextAnonymousType,
  DataContextSupplierType,
} from "../../../context/dataContext";
import Search from "../../../components/common/Search";
import { getUserName } from "../../../utils/userUtils";
import { getOrderNumber, getOrderStateDescriptions, isSupplierOrder } from "../../../utils/orderUtils";
import { doFuseSearch, formatCurrency, formatUnit, getDocFromCollection, round } from "../../../utils/baseUtils";
import { resolveFilePath } from "../../../utils/fileUtils";
import { Company } from "../../../model/company.types";
import { Commodity } from "../../../model/commodity.types";
import { CustomerCommodity } from "../../../model/customer/customerCommodity.types";
import { I_CREDITNOTECUSTOMER, I_CUSTOMERINVOICE, I_SUPPLIERINVOICE, Invoice } from "../../../model/invoice.types";
import { SupplierOrder } from "../../../model/supplierOrder.types";
import { CustomerOrder } from "../../../model/customerOrder.types";
import { CustomerCustomerOrder } from "../../../model/customer/customerCustomerOrder.types";
import { getInvoiceStateDescription } from "../../../utils/invoiceUtils";
import { getLinkForOrderOrContract } from "../../../utils/contractAndOrderUtils";
import { FinishedProduct } from "../../../model/finishedProduct.types";
import { CustomerFinishedProduct } from "../../../model/customer/customerFinishedProduct.types";
import { isAnyFinishedProduct } from "../../../utils/productArticleUtils";
import { hasPricesInAnonymousView } from "../../../utils/anonymousViewUtils";
import AnonymousBlurText from "../../common/anonymous/AnonymousBlurText";
import { CUSTOMER_BASE_CURRENCY } from "../../../utils/currencyUtils";

enum ExpandType {
  ARTICLES = "articles",
  CUSTOMERS = "customers",
  INVOICES = "invoices",
  ORDERS = "orders",
}

interface DashboardSearchState {
  query: string;
  showDropdown: boolean;
  expandArticles: boolean;
  expandCustomers: boolean;
  expandInvoices: boolean;
  expandOrders: boolean;
  invoicesLink: boolean;
}

export default class DashboardSearch extends PureComponent<Record<string, unknown>, DashboardSearchState> {
  static contextType = resolveContextType();
  context!: DataContextType;

  constructor(props: Record<string, unknown>) {
    super(props);
    this.state = this.getDefaultState();
  }

  getDefaultState = (): DashboardSearchState => {
    return {
      query: "",
      showDropdown: false,
      expandArticles: false,
      expandCustomers: false,
      expandInvoices: false,
      expandOrders: false,
      invoicesLink: false,
    };
  };

  handleExpand = (name: ExpandType) => {
    switch (name) {
      case ExpandType.ARTICLES:
        this.setState({ expandArticles: true });
        break;
      case ExpandType.CUSTOMERS:
        this.setState({ expandCustomers: true });
        break;
      case ExpandType.INVOICES:
        this.setState({ expandInvoices: true });
        break;
      case ExpandType.ORDERS:
        this.setState({ expandOrders: true });
        break;
    }
  };

  handleCollapse = (name: ExpandType) => {
    switch (name) {
      case ExpandType.ARTICLES:
        this.setState({ expandArticles: false });
        break;
      case ExpandType.CUSTOMERS:
        this.setState({ expandCustomers: false });
        break;
      case ExpandType.INVOICES:
        this.setState({ expandInvoices: false });
        break;
      case ExpandType.ORDERS:
        this.setState({ expandOrders: false });
        break;
    }
  };

  handleResetData = () => {
    this.setState(this.getDefaultState());
  };

  handleClick = () => {
    this.setState({ showDropdown: true });
  };

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    if (query.length < this.state.query.length) {
      this.setState({
        query,
        expandInvoices: false,
        expandOrders: false,
        expandArticles: false,
        expandCustomers: false,
        invoicesLink: false,
      });
    } else {
      this.setState({ query, showDropdown: query.length > 0, invoicesLink: false });
    }
    if (["invoice", "invoices"].includes(query.trim().toLowerCase())) {
      this.setState({ query, showDropdown: true, invoicesLink: true });
      this.handleExpand(ExpandType.INVOICES);
    }
  };

  /**
   * Filters the data for the search dropdown.
   * @returns {
   *  { filteredArticles: Array<Commodity | CustomerCommodity | FinishedProduct | CustomerFinishedProduct>;
   *    filteredCustomers: Array<Company>;
   *    filteredInvoices: Array<Invoice>;
   *    filteredOrders: Array<CustomerCustomerOrder | SupplierOrder | CustomerOrder> }
   *  } Filtered data
   */
  filterData = (): {
    filteredArticles: Array<Commodity | CustomerCommodity | FinishedProduct | CustomerFinishedProduct>;
    filteredCustomers: Array<Company>;
    filteredInvoices: Array<Invoice>;
    filteredOrders: Array<CustomerCustomerOrder | SupplierOrder | CustomerOrder>;
  } => {
    const { query } = this.state;
    let filteredArticles: Array<Commodity | CustomerCommodity | FinishedProduct | CustomerFinishedProduct> = [];
    let filteredCustomers: Array<Company> = [];
    let filteredInvoices: Array<Invoice> = [];
    let filteredOrders: Array<CustomerCustomerOrder | SupplierOrder | CustomerOrder> = [];

    if (isInternalContext(this.context)) {
      const { commodity, finishedProduct, company, invoice, customerOrder, supplierOrder } = this.context;
      filteredArticles = commodity;
      // Can't be oneliner due to types
      filteredArticles = filteredArticles.concat(finishedProduct);
      filteredCustomers = company;
      filteredInvoices = invoice;
      filteredOrders = [...customerOrder, ...supplierOrder];
    } else if (isCustomerContext(this.context)) {
      const { commodity, invoice, customerOrder } = this.context;
      filteredArticles = commodity.filter((item) => item.approved && !item.disabled);
      filteredOrders = [...customerOrder];
      filteredInvoices = invoice;
    } else if (isAnonymousContext(this.context)) {
      const { commodity, customerOrder } = this.context;
      filteredArticles = commodity.filter((item) => item.approved && !item.disabled);
      filteredOrders = [...customerOrder];
    } else if (isSupplierContext(this.context)) {
      const { commodity } = this.context;
      filteredArticles = commodity.filter((item) => item.approved && !item.disabled);
    }

    if (query.trim()) {
      filteredCustomers = doFuseSearch(filteredCustomers, query, ["name"]);

      filteredOrders = doFuseSearch(filteredOrders, query, [
        "orderNo",
        "commodity.title.en",
        "company.name",
        "supplier.name",
      ]);

      filteredArticles = doFuseSearch(filteredArticles, query, ["title.en", "subtitle.en"]);

      if (!isAnonymousContext(this.context)) {
        if (!["invoice", "invoices"].includes(query.toLowerCase())) {
          filteredInvoices = doFuseSearch(filteredInvoices, query.trim().toLowerCase().replace("inv-", ""), [
            "invoiceNumber",
            "company.name",
            "total",
            "relatedOrder.orderNo",
          ]);
        } else {
          filteredInvoices = this.context.invoice;
        }
      }
    }
    return { filteredArticles, filteredCustomers, filteredInvoices, filteredOrders };
  };

  /**
   * Shortens a list of string equally. If the total size of all strings reaches the maximum size we equally reduce the
   * length of each string instead of just reducing one of them.
   * @param strings List of strings that should be shortened equally
   * @param targetLength Maximum length of the combined string
   * @returns { Array<string> } Shortened strings
   */
  shortenStringsEqually = (strings: Array<string>, targetLength: number): Array<string> => {
    let currentLength = strings.reduce((sum, str) => sum + str.length, 0);
    const shortenedIndices = new Set<number>();
    while (currentLength > targetLength) {
      const maxLength = Math.max(...strings.map((str) => str.length));
      const longestStringIndex = strings.findIndex(
        (str, idx) => str.length === maxLength && !shortenedIndices.has(idx)
      );
      const indexToShorten =
        longestStringIndex !== -1 ? longestStringIndex : strings.findIndex((str) => str.length === maxLength);
      strings[indexToShorten] = strings[indexToShorten].slice(0, -1);
      shortenedIndices.add(indexToShorten);
      currentLength = strings.reduce((sum, str) => sum + str.length, 0) + 3 * shortenedIndices.size;
    }
    shortenedIndices.forEach((index) => {
      strings[index] += "...";
    });
    return strings;
  };

  /**
   * Retrieve the best price from all from prices.
   * @param article Article whose from prices should be checked
   * @returns {string | JSX.Element} Price in € or "On Request" if no price is available or blurred price in AV
   */
  getMinFromPrice = (
    article: Commodity | CustomerCommodity | FinishedProduct | CustomerFinishedProduct
  ): string | JSX.Element => {
    if (isAnonymousContext(this.context)) {
      if (!hasPricesInAnonymousView(article._id.toString(), this.context.configuration)) {
        return <AnonymousBlurText>{formatCurrency(1.23, CUSTOMER_BASE_CURRENCY)}</AnonymousBlurText>;
      }
    }
    let min = article.fromPrice?.seafreight;
    if (
      !min ||
      !min.price ||
      (article.fromPrice?.airfreight.price && min.price > article.fromPrice?.airfreight.price)
    ) {
      min = article.fromPrice?.airfreight;
    }
    if (!min || !min.price || (article.fromPrice?.warehouse.price && min.price > article.fromPrice?.warehouse.price)) {
      min = article.fromPrice?.warehouse;
    }
    return min && min.price && min.price !== Infinity ? formatCurrency(min.price, min.currency) : "On Request";
  };

  render() {
    const { query, showDropdown, expandArticles, expandCustomers, expandInvoices, expandOrders } = this.state;
    const isInternal = isInternalContext(this.context);
    const isCustomerOrAnonymous = isCustomerContext(this.context) || isAnonymousContext(this.context);
    const { filteredArticles, filteredCustomers, filteredInvoices, filteredOrders } = this.filterData();

    return (
      <>
        <div className={showDropdown ? "search-backdrop show" : ""}></div>
        <div onBlur={this.handleResetData}>
          <div className="search-container">
            <Search
              value={query}
              onChange={this.handleChange}
              onClick={this.handleClick}
              placeholder="Search for anything..."
              hideButton={true}
              prependClasses={"z-11 " + (showDropdown ? "border-bottom-0" : "")}
              inputClasses={"z-11 " + (showDropdown ? "border-bottom-0" : "")}
            />
            <div
              className={
                "search-options card border-none border-top-0 z-11 bg-custom-medium-gray " +
                (showDropdown && "show pt-6")
              }
              onMouseDown={(e) => e.preventDefault()}
            >
              {filteredOrders.length > 0 && (
                <div className=" px-5">
                  <h2>Orders</h2>
                  <hr style={{ color: "white" }} />
                  {filteredOrders.slice(0, expandOrders ? undefined : 6).map((data) => (
                    <Link
                      key={data._id.toString()}
                      to={getLinkForOrderOrContract(data)}
                      onClick={this.handleResetData}
                      className="text-white fs-6 search-option-link"
                    >
                      <div className="search-option" key={data._id.toString()}>
                        {getOrderNumber(data)}
                        <span className="text-muted fs-7 ml-3">
                          {isSupplierOrder(data)
                            ? this.shortenStringsEqually(
                                [
                                  data.commodity.title.en,
                                  "from",
                                  getDocFromCollection(this.context.supplier, data.supplier)!.name,
                                ],
                                45
                              ).join(" ")
                            : isInternal
                            ? this.shortenStringsEqually(
                                [
                                  data.commodity.title.en,
                                  "for",
                                  getDocFromCollection((this.context as DataContextInternalType).company, data.company)!
                                    .name,
                                ],
                                45
                              ).join(" ")
                            : this.shortenStringsEqually(
                                [data.commodity.title.en + " " + data.commodity.subtitle.en],
                                45
                              )}
                        </span>

                        {isInternal && isSupplierOrder(data) && (
                          <span className="badge badge-pill badge-warning badge-sm ml-3 p-1 float-right">
                            <small>Supplier</small>
                          </span>
                        )}
                        {isInternal && !isSupplierOrder(data) && (
                          <span className="badge badge-pill badge-success badge-sm ml-3 p-1 float-right">
                            <small>Customer</small>
                          </span>
                        )}
                        {isCustomerOrAnonymous && (
                          <span
                            className="badge badge-pill badge-gray badge-sm ml-3 p-1 float-right"
                            style={{ width: "6rem", marginTop: "1px" }}
                          >
                            <small>{this.shortenStringsEqually([getOrderStateDescriptions(data).title], 10)}</small>
                          </span>
                        )}
                        <small className=" float-right">{formatUnit(round(data.amount, 2), data.unit)}</small>
                      </div>
                    </Link>
                  ))}
                  {!expandOrders && filteredOrders.length > 5 && (
                    <button
                      className="btn btn-text-white btn-sm float-right pr-0"
                      onClick={() => this.handleExpand(ExpandType.ORDERS)}
                    >
                      Show {filteredOrders.length - 5} more results
                    </button>
                  )}
                  {expandOrders && (
                    <button
                      className="btn btn-text-white btn-sm float-right pr-0"
                      onClick={() => this.handleCollapse(ExpandType.ORDERS)}
                    >
                      Show less results
                    </button>
                  )}
                </div>
              )}
              {filteredArticles.length > 0 && (
                <div className="px-5">
                  <h2>Articles</h2>
                  <hr style={{ color: "white" }} />
                  {filteredArticles.slice(0, expandArticles ? undefined : 6).map((data) => (
                    <Link
                      key={data._id.toString()}
                      className="text-white fs-6 search-option-link"
                      to={`/${isAnyFinishedProduct(data) ? "finishedProduct" : "commodity"}/${data._id.toString()}`}
                      onClick={this.handleResetData}
                    >
                      <div className="search-option" key={data._id.toString()}>
                        {this.shortenStringsEqually([data.title.en, data.subtitle.en], 55)[0]}
                        <span className="text-muted fs-7 ml-2">
                          {this.shortenStringsEqually([data.title.en, data.subtitle.en], 55)[1]}
                        </span>
                        {isInternal && <span className="float-right">{data.suppliers.length} Suppliers</span>}
                        {isCustomerOrAnonymous && (
                          <>
                            <span
                              className="badge badge-pill badge-gray badge-sm ml-3 p-1 float-right"
                              style={{ width: "6rem", marginTop: "1px" }}
                            >
                              <small>{this.getMinFromPrice(data)}</small>
                            </span>
                            <small className="float-right"> {"Art.-No. " + data.articleNo}</small>
                          </>
                        )}
                      </div>
                    </Link>
                  ))}
                  {!expandArticles && filteredArticles.length > 6 && (
                    <button
                      className="btn btn-text-white btn-sm float-right pr-0"
                      onClick={() => this.handleExpand(ExpandType.ARTICLES)}
                    >
                      Show {filteredArticles.length - 6} more results
                    </button>
                  )}
                  {expandArticles && (
                    <button
                      className="btn btn-text-white btn-sm float-right pr-0"
                      onClick={() => this.handleCollapse(ExpandType.ARTICLES)}
                    >
                      Show less results
                    </button>
                  )}
                </div>
              )}
              {filteredInvoices.length > 0 && (
                <div className="px-5">
                  <h2>Invoices</h2>
                  <hr style={{ color: "white" }} />
                  {filteredInvoices.slice(0, expandInvoices ? undefined : 6).map((data) => {
                    const relatedOrder = data.relatedOrder
                      ? data.type === I_SUPPLIERINVOICE
                        ? getDocFromCollection(
                            (this.context as DataContextInternalType | DataContextSupplierType).supplierOrder,
                            data.relatedOrder
                          )
                        : [I_CUSTOMERINVOICE, I_CREDITNOTECUSTOMER].includes(data.type)
                        ? getDocFromCollection(
                            (
                              this.context as
                                | DataContextInternalType
                                | DataContextCustomerType
                                | DataContextAnonymousType
                            ).customerOrder,
                            data.relatedOrder
                          )
                        : getDocFromCollection(
                            (
                              this.context as
                                | DataContextInternalType
                                | DataContextCustomerType
                                | DataContextAnonymousType
                            ).sampleOrder,
                            data.relatedOrder
                          )
                      : undefined;
                    return (
                      <a
                        key={data._id.toString()}
                        href={resolveFilePath(data.file.toString())}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={this.handleResetData}
                        className="text-white fs-6 search-option-link"
                      >
                        <div className="search-option" key={data.invoiceNumber}>
                          <span className="text-white fw-bolder fs-6">INV-{data.invoiceNumber}</span>
                          <span className="text-muted fs-7 ml-2">
                            {this.shortenStringsEqually(
                              [
                                (relatedOrder?.orderNo && getOrderNumber(relatedOrder)) || "",
                                " ",
                                relatedOrder?.commodity.title.en || "",
                                " ",
                                relatedOrder?.commodity.subtitle.en || "",
                              ],
                              40
                            )}
                          </span>
                          <span
                            className="badge badge-pill badge-gray badge-sm ml-3 p-1 float-right"
                            style={{ width: "6rem", marginTop: "1px" }}
                          >
                            <small>{getInvoiceStateDescription(data.state)}</small>
                          </span>
                          <small className="float-right">{formatCurrency(data.total, data.currency)}</small>
                        </div>
                      </a>
                    );
                  })}
                  {!expandInvoices && filteredInvoices.length > 6 && (
                    <button
                      className="btn btn-text-white btn-sm float-right pr-0"
                      onClick={() => this.handleExpand(ExpandType.INVOICES)}
                    >
                      Show {filteredInvoices.length - 6} more results
                    </button>
                  )}
                  {expandInvoices && (
                    <button
                      className="btn btn-text-white btn-sm float-right pr-0"
                      onClick={() => this.handleCollapse(ExpandType.INVOICES)}
                    >
                      Show less results
                    </button>
                  )}
                </div>
              )}
              {isInternal && filteredCustomers.length > 0 && (
                <div className="mb-10 px-5">
                  <h2>Customers</h2>
                  <hr style={{ color: "white" }} />
                  {filteredCustomers.slice(0, expandCustomers ? undefined : 6).map((data) => {
                    const primaryPerson = getDocFromCollection(this.context.userData, data.primaryPerson);
                    return (
                      <Link
                        key={data._id.toString()}
                        className="text-white fs-6 search-option-link"
                        to={"/customer/" + data._id.toString()}
                        onClick={this.handleResetData}
                      >
                        <div className="search-option" key={data._id.toString()}>
                          {this.shortenStringsEqually([data.name || "", getUserName(primaryPerson) || ""], 50)[0]}

                          <span className="text-muted fs-7 ml-2">
                            {this.shortenStringsEqually([data.name || "", getUserName(primaryPerson) || ""], 50)[1]}
                          </span>
                        </div>
                      </Link>
                    );
                  })}
                  {!expandCustomers && filteredCustomers.length > 6 && (
                    <button
                      className="btn btn-text-white btn-sm float-right"
                      onClick={() => this.handleExpand(ExpandType.CUSTOMERS)}
                    >
                      Show {filteredCustomers.length - 6} more results
                    </button>
                  )}
                  {expandCustomers && (
                    <button
                      className="btn btn-text-white btn-sm float-right pr-0"
                      onClick={() => this.handleCollapse(ExpandType.CUSTOMERS)}
                    >
                      Show less results
                    </button>
                  )}
                </div>
              )}
              {filteredCustomers.length === 0 &&
                filteredOrders.length === 0 &&
                filteredArticles.length === 0 &&
                filteredInvoices.length === 0 && (
                  <div className="search-option w-100 text-center">
                    <p>No results found.</p>
                  </div>
                )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
