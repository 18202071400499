import React from "react";
import { Link } from "react-router-dom";
import { Commodity, CommoditySnapshot } from "../../model/commodity.types";
import { CustomerCommodity } from "../../model/customer/customerCommodity.types";
import Tooltip from "./Tooltip";
import { FinishedProduct, FinishedProductSnapshot } from "../../model/finishedProduct.types";
import { CustomerFinishedProduct } from "../../model/customer/customerFinishedProduct.types";
import { isFinishedProduct } from "../../utils/finishedProductUtils";

interface OpenCommodityIconProps {
  article:
    | Commodity
    | CustomerCommodity
    | CommoditySnapshot
    | FinishedProduct
    | CustomerFinishedProduct
    | FinishedProductSnapshot;
}

const OpenCommodityIcon: React.FunctionComponent<OpenCommodityIconProps> = ({ article }) => {
  return (
    <Link
      onClick={(e) => e.stopPropagation()}
      to={`/${isFinishedProduct(article) ? "finishedProduct" : "commodity"}/${article._id.toString()}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Tooltip tooltipText="Open Commodity">
        <i className="fas fa-external-link-alt text-white text-hover-gray-300 hover ml-2" />
      </Tooltip>
    </Link>
  );
};

export default OpenCommodityIcon;
