import { BSON } from "realm-web";

export enum VersionHistoryType {
  FEATURE = "feature",
  CHANGE = "change",
  BUG_FIX = "bugfix",
  GENERAL = "general",
}

export interface VersionHistory {
  _id: BSON.ObjectId;
  version: string;
  content: Array<Content>;
  date: Date;
}

export interface Content {
  type: VersionHistoryType;
  content: string;
  reason: string;
  forCustomersVisible: boolean;
  forSuppliersVisible: boolean;
}

export interface ContentPrepared {
  features: Array<Content>;
  changes: Array<Content>;
  bugfixes: Array<Content>;
  general: Array<Content>;
}
