import React, { useContext } from "react";
import { DataContextInternal } from "../../../context/dataContext";
import ContractListing from "./ContractListing";

const ContractListingWrapper: React.FunctionComponent = () => {
  const context = useContext(DataContextInternal);
  return <ContractListing context={context} />;
};

export default ContractListingWrapper;
