import _ from "lodash";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import CalendarWeekSelector from "../../../../common/CalendarWeekSelector";
import CustomSelect, { SelectOption } from "../../../../common/CustomSelect";
import ErrorOverlayButton from "../../../../common/ErrorOverlayButton";
import { Input } from "../../../../common/Input";
import {
  CO_T_SPLIT,
  CO_TRANSPORT,
  CustomerOrderExtended,
  T_SEAFREIGHT,
} from "../../../../../model/customerOrder.types";
import { Action, CUSTOMERORDER, INSERT, transaction, UPDATE } from "../../../../../services/dbService";
import {
  getCustomerOrderTimelineEntry,
  getEarliestDeliveryDate,
  formatDateFromType,
} from "../../../../../utils/customerOrderUtils";
import { DateType, getOrderNumber, O_TRANSPORTTYPES } from "../../../../../utils/orderUtils";
import { DataContextInternalType } from "../../../../../context/dataContext";
import DateInput from "../../../../common/DateInput";
import { reduceCustomerOrder } from "../../../../../utils/dataTransformationUtils";

interface SplitCustomerOrderModalProps {
  order: CustomerOrderExtended;
  context: DataContextInternalType;
}

interface SplitCustomerOrderModalState {
  splitOrder: CustomerOrderExtended;
  authorizationGuarantee: boolean;
  saving: boolean;
  show: boolean;
  earliestDeliveryDate: Date;
}

class SplitCustomerOrderModal extends PureComponent<SplitCustomerOrderModalProps, SplitCustomerOrderModalState> {
  constructor(props: SplitCustomerOrderModalProps) {
    super(props);
    this.state = this.getDefaultState(false, props);
  }

  async componentDidMount() {
    await this.populateData();
  }

  async componentDidUpdate(prevProps: Readonly<SplitCustomerOrderModalProps>) {
    const { order, context } = this.props;
    if (!_.isEqual(prevProps.order, order) || !_.isEqual(prevProps.context, context)) {
      await this.populateData();
    }
  }

  handleShow = () => {
    const { earliestDeliveryDate } = this.state;
    this.setState(this.getDefaultState(true, this.props, earliestDeliveryDate));
  };
  handleHide = () => this.setState({ show: false });

  handleChangeTransport = (e: SelectOption) => {
    const splitOrder = _.cloneDeep(this.state.splitOrder);
    splitOrder.transport = e.value as CO_TRANSPORT;
    if (e.value === T_SEAFREIGHT && splitOrder.targetDateType === DateType.FIX) splitOrder.targetDateType = undefined;
    this.setState({ splitOrder });
  };

  handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const splitOrder = _.cloneDeep(this.state.splitOrder);
    splitOrder.amount = e.target.valueAsNumber;
    this.setState({ splitOrder });
  };

  handleChangeTargetDateType = (newType: DateType) => {
    const splitOrder = _.cloneDeep(this.state.splitOrder);
    splitOrder.targetDateType === newType
      ? (splitOrder.targetDateType = undefined)
      : (splitOrder.targetDateType = newType);
    this.setState({ splitOrder });
  };

  handleChangeTargetDate = (e: React.ChangeEvent<HTMLInputElement> | Date) => {
    let targetDate;
    if (e instanceof Date) {
      targetDate = e;
    } else {
      targetDate = new Date(e.target.value);
    }
    if (!targetDate) return;
    const splitOrder = _.cloneDeep(this.state.splitOrder);
    splitOrder.targetDate = targetDate;
    this.setState({ splitOrder });
  };

  handleToggleAuthorizationGuarantee = () =>
    this.setState({ authorizationGuarantee: !this.state.authorizationGuarantee });

  handleConfirmSplitOrder = async () => {
    const { order } = this.props;
    const splitOrder = reduceCustomerOrder(_.cloneDeep(this.state.splitOrder));
    this.setState({ saving: true });
    try {
      const { orderNo, splitOrderNo } = this.getOrderNumbers();
      splitOrder._id = new BSON.ObjectId();
      const orderOriginalNewAmount = order.amount - splitOrder.amount;
      const orderOriginalNewCommodityCost = (order.priceCommodities / order.amount) * orderOriginalNewAmount;
      const orderSplitCommodityCost = (order.priceCommodities / order.amount) * splitOrder.amount;
      const splitOrders = _.clone(order.splitOrders) ?? [order._id.toString(), splitOrder._id.toString()];
      if (order.splitOrders && order.splitOrders.length > 0) {
        splitOrders.push(splitOrder._id.toString());
      }
      const timelineEntry = getCustomerOrderTimelineEntry(CO_T_SPLIT);
      splitOrder.orderNo = splitOrderNo.split("RB-")[1];
      splitOrder.splitOrders = splitOrders;
      splitOrder.services = [];
      splitOrder.timeline = [timelineEntry];
      splitOrder.priceServices = 0;
      splitOrder.priceCommodities = orderSplitCommodityCost;
      splitOrder.totalPrice = orderSplitCommodityCost;
      splitOrder.createdAt = new Date();
      const actions: Array<Action> = [];
      actions.push({
        collection: CUSTOMERORDER,
        action: UPDATE,
        filter: { _id: order._id },
        update: {
          orderNo: orderNo.split("RB-")[1],
          amount: orderOriginalNewAmount,
          splitOrders,
          priceCommodities: orderOriginalNewCommodityCost,
          totalPrice: orderOriginalNewCommodityCost + order.priceServices,
        },
        push: { timeline: timelineEntry },
      });
      const remainingSplitOrders = splitOrders.filter(
        (sO) => ![order._id.toString(), splitOrder._id.toString()].includes(sO)
      );
      // Ensure that all other orders that are part of this split are updated too
      for (let i = 0; i < remainingSplitOrders.length; i++) {
        const rSO = remainingSplitOrders[i];
        actions.push({
          collection: CUSTOMERORDER,
          action: UPDATE,
          filter: { _id: new BSON.ObjectId(rSO) },
          update: {
            splitOrders,
          },
        });
      }
      actions.push({ collection: CUSTOMERORDER, action: INSERT, object: splitOrder });
      const res = await transaction(actions);
      if (res) {
        toast.success("Order successfully split");
        this.handleHide();
        // Reload to ensure that all orders are refreshed
        window.location.reload();
      } else {
        toast.error("Error splitting order");
      }
    } catch (e) {
      console.error("ERROR SPLITTING ORDER:", e);
      toast.error("Error splitting order");
    } finally {
      this.setState({ saving: false });
    }
  };

  getDefaultState = (
    show: boolean,
    props?: SplitCustomerOrderModalProps,
    earliestDeliveryDate?: Date
  ): SplitCustomerOrderModalState => {
    const splitOrder = _.cloneDeep(props ? props.order : this.props.order);
    splitOrder._id = new BSON.ObjectId();
    splitOrder.orderNo = "-1";
    splitOrder.amount = 0;
    return {
      saving: false,
      show,
      splitOrder,
      authorizationGuarantee: false,
      earliestDeliveryDate: earliestDeliveryDate ? earliestDeliveryDate : new Date(),
    };
  };

  populateData = async () => {
    const { splitOrder } = this.state;
    const earliestDeliveryDate = await getEarliestDeliveryDate(
      splitOrder.transport,
      splitOrder.supplier?._id,
      splitOrder.commodity,
      splitOrder.amount
    );
    this.setState({ earliestDeliveryDate });
  };

  getOrderNumbers = () => {
    const { order } = this.props;
    let orderNo = getOrderNumber(order);
    let splitOrderNo = getOrderNumber(order);
    if (order.splitOrders && order.splitOrders.length > 0) {
      const sOnSplit = splitOrderNo.split("-");
      splitOrderNo = sOnSplit[0] + "-" + sOnSplit[1] + "-" + (order.splitOrders.length + 1);
    } else {
      orderNo += "-1";
      splitOrderNo += "-2";
    }
    return { orderNo, splitOrderNo };
  };

  validateData = () => {
    const { order } = this.props;
    const { splitOrder, authorizationGuarantee } = this.state;
    const errors: Array<string> = [];
    if (order.amount - splitOrder.amount < 0) errors.push("Can't order more than the customer bought");
    if (splitOrder.amount <= 0) errors.push("Split order amount has to be above 0");
    if (!authorizationGuarantee) errors.push("Guarantee that the customer has authorized this action");
    return errors;
  };

  render() {
    const { order } = this.props;
    const { splitOrder, authorizationGuarantee, show, saving, earliestDeliveryDate } = this.state;

    const errors = this.validateData();
    const { orderNo, splitOrderNo } = this.getOrderNumbers();

    return (
      <>
        <button className="btn btn-outline btn-outline-warning ml-2" onClick={this.handleShow}>
          Split Order
        </button>
        <Modal contentClassName="bg-dark" show={show} size="xl" onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">
                Split Customer Order {getOrderNumber(order)}
              </h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-lg-5">
                <div className="row mt-2">
                  <div className="col-12 text-center">
                    <span className="fs-5">Original Order</span>
                  </div>
                  <div className="col-12 border-bottom-dark-gray pt-5" />
                  <div className="col-12 mt-2">
                    <label className="fs-6 fw-bold mb-1">Order No.</label>
                    <input type="text" value={orderNo} disabled={true} className="form-control custom-form-control" />
                  </div>
                  <div className="col-12 mt-2">
                    <label className="fs-6 fw-bold mb-1">Transport</label>
                    <input
                      type="text"
                      value={O_TRANSPORTTYPES.find((t) => t.value === order.transport)?.label}
                      disabled={true}
                      className="form-control custom-form-control"
                    />
                  </div>
                  <div className="col-12 mt-2">
                    <label className="fs-6 fw-bold mb-1">Target Date</label>
                    <input
                      type="text"
                      value={formatDateFromType(order.targetDate, order.targetDateType)}
                      disabled={true}
                      className="form-control custom-form-control"
                    />
                  </div>
                  <div className="col-12 mt-2">
                    <label className="fs-6 fw-bold mb-1">Amount</label>
                    <div className="input-group">
                      <input
                        type="number"
                        value={order.amount - splitOrder.amount}
                        disabled={true}
                        className="form-control custom-form-control"
                      />
                      <div className="input-group-append rounded-end bg-custom-light-gray">
                        <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                          {order.unit}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-none d-lg-block col-2 text-center my-auto">
                <i className="fa fa-exchange-alt fs-3rem" />
              </div>
              <div className="col-12 col-lg-5">
                <div className="row mt-2">
                  <div className="col-12 text-center">
                    <span className="fs-5">Split Order</span>
                  </div>
                  <div className="col-12 border-bottom-dark-gray pt-5" />
                  <div className="col-12 mt-2">
                    <label className="fs-6 fw-bold mb-1">Order No.</label>
                    <input
                      type="text"
                      value={splitOrderNo}
                      disabled={true}
                      className="form-control custom-form-control"
                    />
                  </div>
                  <div className="col-12 mt-2">
                    <label className="fs-6 fw-bold mb-1">Transport</label>
                    <CustomSelect
                      options={O_TRANSPORTTYPES}
                      value={O_TRANSPORTTYPES.find((t) => t.value === splitOrder.transport)}
                      matchFormControl={true}
                      onChange={this.handleChangeTransport}
                    />
                  </div>
                  <div className="col-12 mt-2">
                    <label className="fs-6 fw-bold mb-1">Target Date</label>
                    {splitOrder.targetDateType === DateType.CW ? (
                      <CalendarWeekSelector
                        value={splitOrder.targetDate}
                        onSelectCalendarWeek={this.handleChangeTargetDate}
                        min={earliestDeliveryDate}
                      />
                    ) : (
                      <DateInput
                        classes="form-control custom-form-control"
                        value={splitOrder.targetDate}
                        onBlur={this.handleChangeTargetDate}
                        name={"targetDate"}
                        min={earliestDeliveryDate}
                      />
                    )}
                  </div>
                  <div className="col-12 mt-2 d-flex flex-row">
                    <div className="form-check form-check-sm form-check-custom form-check-solid me-5">
                      <input
                        className="form-check-input position-static"
                        checked={splitOrder.targetDateType === DateType.CW}
                        type="checkbox"
                        onChange={() => this.handleChangeTargetDateType(DateType.CW)}
                      />
                      <label className="form-check-label fs-6">Calendar Week</label>
                    </div>
                    <div className="form-check form-check-sm form-check-custom form-check-solid">
                      <input
                        className="form-check-input position-static"
                        checked={splitOrder.targetDateType === DateType.FIX}
                        type="checkbox"
                        disabled={splitOrder.transport === T_SEAFREIGHT}
                        onChange={() => this.handleChangeTargetDateType(DateType.FIX)}
                      />
                      <label
                        className={`form-check-label fs-6 ${splitOrder.transport === T_SEAFREIGHT ? "text-muted" : ""}`}
                      >
                        Fixed Date
                      </label>
                    </div>
                  </div>
                  <div className="col-12 mt-2">
                    <label className="fs-6 fw-bold mb-1">Amount</label>
                    <div className="input-group">
                      <Input
                        type="number"
                        value={splitOrder.amount}
                        className="form-control custom-form-control"
                        min={0}
                        onChange={this.handleChangeAmount}
                      />
                      <div className="input-group-append rounded-end bg-custom-light-gray">
                        <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                          {order.unit}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-10">
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                  <input
                    className="form-check-input position-static"
                    checked={authorizationGuarantee}
                    type="checkbox"
                    onChange={this.handleToggleAuthorizationGuarantee}
                  />
                  <label className="form-check-label fs-5" onClick={this.handleToggleAuthorizationGuarantee}>
                    I guarantee that the customer has authorised me to split this order. I understand that unauthorized
                    orders can be harmful to business and may result in disciplinary consequences.
                  </label>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-outline text-muted" onClick={this.handleHide}>
              Close
            </button>
            <ErrorOverlayButton
              errors={errors}
              saving={saving}
              className="btn btn-sm btn-outline btn-outline-light"
              buttonText="Confirm"
              onClick={this.handleConfirmSplitOrder}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default SplitCustomerOrderModal;
