import _ from "lodash";
import React, { PureComponent, useMemo } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CustomerCommodity } from "../../../../model/customer/customerCommodity.types";
import { DataContextAnonymousType, DataContextCustomerType } from "../../../../context/dataContext";
import { getSimilarArticles } from "../../../../utils/commodityUtils";
import BaseListing from "../../../common/BaseListing";
import PropertiesWidget from "../../../common/PropertiesWidget";
import CountryWidget from "../../../common/CountryWidget";
import { CustomerFinishedProduct } from "../../../../model/customer/customerFinishedProduct.types";
import { isFinishedProduct } from "../../../../utils/finishedProductUtils";
import { CustomerArticle, CustomerArticleExtended, isAnyFinishedProduct } from "../../../../utils/productArticleUtils";
import { resolveProperties } from "../../../../utils/propertyUtils";

interface CommodityPageSimilarCommoditiesProps {
  article: CustomerArticleExtended;
  context: DataContextCustomerType | DataContextAnonymousType;
}

interface CommodityPageSimilarCommoditiesState {
  similarArticles: Array<CustomerArticle>;
}

class CommodityPageSimilarCommodities extends PureComponent<
  CommodityPageSimilarCommoditiesProps,
  CommodityPageSimilarCommoditiesState
> {
  constructor(props: CommodityPageSimilarCommoditiesProps) {
    super(props);
    this.state = { similarArticles: [] };
  }

  componentDidMount() {
    const { article, context } = this.props;
    this.setState({ similarArticles: getSimilarArticles(article, context.commodity) });
  }

  componentDidUpdate(prevProps: Readonly<CommodityPageSimilarCommoditiesProps>) {
    const { article, context } = this.props;
    if (!_.isEqual(prevProps.article, article)) {
      this.setState({ similarArticles: getSimilarArticles(article, context.commodity) });
    }
  }

  render() {
    const { article, context } = this.props;
    const { similarArticles } = this.state;
    const isFP = isAnyFinishedProduct(article);
    const headerDefinition = [
      { title: isFP ? "Finished Product" : "Commodity" },
      { title: "Properties" },
      { title: "Origin", style: { width: "10%" }, className: "text-right" },
    ];

    return (
      <div className="card bg-white">
        <div className="card-header border-0 mt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">
              Similar <span>{isFP ? "Finished Products" : "Commodities"}</span>
            </span>
            <span className="text-muted fw-bold fs-7">
              {similarArticles.length} <span>{isFP ? "Finished Products" : "Commodities"}</span> found
            </span>
          </h3>
        </div>
        <div className="card-body p-9 pt-4">
          <BaseListing
            headerDefinition={headerDefinition}
            bodyContent={
              similarArticles.length > 0 ? (
                <>
                  {similarArticles.map((s) => (
                    <SimilarCommodityRouter key={s._id.toString()} article={s} context={context} />
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={4} className="text-center">
                    <span>No similar commodities found</span>
                  </td>
                </tr>
              )
            }
          />
        </div>
      </div>
    );
  }
}

interface SimilarArticleProps extends RouteComponentProps {
  article: CustomerCommodity | CustomerFinishedProduct;
  context: DataContextCustomerType | DataContextAnonymousType;
}

const SimilarArticle: React.FunctionComponent<SimilarArticleProps> = ({ article, context, history }) => {
  const forwardCommodity = (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.stopPropagation();
    history.push(`/${isFinishedProduct(article) ? "finishedProduct" : "commodity"}/${article._id.toString()}`);
  };

  const properties = useMemo(() => {
    return resolveProperties(article.properties, context.property);
  }, [article]);

  return (
    <tr onClick={forwardCommodity}>
      <td className="align-middle">
        <span className="text-white fs-5 mb-1 custom-link">
          {article.title.en}
          {article.organic && <i title="organic" className="fas fa-leaf text-success" />}
        </span>
        <div className="text-muted">{article.subtitle.en}</div>
      </td>
      <td className="align-middle">
        <PropertiesWidget properties={properties} types={[]} />
      </td>
      <td className="align-middle text-nowrap">
        <CountryWidget countryCode={article.country.code} />
      </td>
    </tr>
  );
};

const SimilarCommodityRouter = withRouter(SimilarArticle);

export default CommodityPageSimilarCommodities;
