import { CONFIGURATION, getDb } from "../services/dbService";
import { BaseConfiguration } from "../model/configuration/configuration.types";
import { DataContextInternalType } from "../context/dataContext";

export enum CONFIG {
  CALCULATION = "calculationConfiguration",
  ANONYMOUS = "anonymousConfiguration",
  GRADUATION = "graduationConfiguration",
  RESPONSIBLE = "responsibleConfiguration",
}

export type ConfigurationTypes = CONFIG;

/**
 * Retrieves the calculation matching the key.
 * @param key Key of the configuration
 * @returns {Promise<T>} Matching configuration
 */
export const getConfiguration = async <T extends BaseConfiguration>(key: ConfigurationTypes): Promise<T> => {
  return getDb()?.collection(CONFIGURATION).findOne({ key }) as Promise<T>;
};

/**
 * Retrieves the given configuration from context.
 * @param key Key of the configuration
 * @param context Context that should be used
 * @returns {T|undefined} Configuration if found, else undefined
 */
export const getConfigurationFromContext = <T extends BaseConfiguration>(
  key: ConfigurationTypes,
  context: DataContextInternalType
): T | undefined => {
  return context.configuration.find((c) => c.key === key) as T | undefined;
};
