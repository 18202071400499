// Backend functions relating to seaports
import { BSON } from "realm-web";
import { callFunction } from "../services/dbService";
import { Seaport } from "../model/seaport.types";
import { Airport } from "../model/airport.types";
import { Address } from "../model/commonTypes";

export const UPSERTSEAPORT = "upsertSeaport";

export const CONTAINER_LABEL = {
  "20": "20'",
  "40": "40'",
  "40HC": "40' HC",
};

/**
 * Inserts a new seaport into the database.
 * @param seaport Seaport that should be inserted into the database
 * @returns {Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false>} Result of the function
 */
export async function insertSeaport(
  seaport: Seaport | Omit<Seaport, "_id">
): Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false> {
  return (await callUpsertSeaport(seaport, true)) as Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false;
}

/**
 * Updates an existing seaport inside the database.
 * @param seaport Seaport that should be updated inside the database
 * @param seaportId optional, seaport id for partial updates
 * @returns {Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false>} Result of the function
 */
export async function updateSeaport(
  seaport: Partial<Seaport>,
  seaportId?: BSON.ObjectId
): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> {
  if (seaportId) seaport._id = seaportId;
  return (await callUpsertSeaport(seaport, false)) as Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false;
}

/**
 * Calls the upsert seaport function in backend.
 * @param seaport Seaport that should be upsert
 * @param insert True for insert, else update
 * @returns {Promise<false | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>>} Result of the function
 */
async function callUpsertSeaport(
  seaport: Partial<Seaport>,
  insert: boolean
): Promise<
  false | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>
> {
  return callFunction(UPSERTSEAPORT, [seaport, insert]);
}

/**
 * Get a description for a seaport
 * @param seaport a seaport object or string for backwards compatibility
 * @returns {string} a description of the port with name and locode or the passed string itself if a string was passed
 */
export function getSeaportName(seaport: Seaport | string): string {
  if (typeof seaport === "object") {
    return `${seaport.name} (${seaport.locode})`;
  }
  return seaport;
}

/**
 * Get all seaports valid as a starting seaport
 * @param seaports list of seaports
 * @returns {Array<Seaport>} list of filtered seaports feasible as starting ports
 */
export function getStartingSeaports(seaports: Array<Seaport>): Array<Seaport> {
  return seaports.filter((s) => s.cost !== undefined && s.containerCost !== undefined && !s.disabled);
}

/**
 * Get all seaports valid as a destination seaport
 * @param seaports list of seaports
 * @returns {Array<Seaport>} list of filtered seaports feasible as destination ports
 */
export function getDestinationSeaports(seaports: Array<Seaport>): Array<Seaport> {
  return seaports.filter((s) => s.cost === undefined && !s.disabled);
}

/**
 * Check if a port is a seaport
 * @param port the port to check
 * @returns {boolean} true if port is a seaport, else false
 */
export function isSeaport(port: Seaport | Airport | Address | string): port is Seaport {
  if (typeof port === "string") return false;
  return "locode" in port;
}
