import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import OrderSummary from "./OrderSummary";
import OrderPageTabPanel from "./OrderPageTabPanel";
import { DataContextAnonymousType, DataContextCustomerType } from "../../../../context/dataContext";
import {
  CustomerCustomerOrder,
  CustomerCustomerOrderExtended,
} from "../../../../model/customer/customerCustomerOrder.types";
import { getDocFromCollection } from "../../../../utils/baseUtils";
import { CUSTOMERORDER, getDocumentDB } from "../../../../services/dbService";

import { extendCustomerCustomerOrder } from "../../../../utils/dataTransformationUtils";

interface OrderPageParams {
  id: string;
}

interface OrderPageProps extends RouteComponentProps<OrderPageParams> {
  context: DataContextCustomerType | DataContextAnonymousType;
}

interface OrderPageState {
  order?: CustomerCustomerOrderExtended;
}

class OrderPage extends PureComponent<OrderPageProps, OrderPageState> {
  _isMounted = false;
  constructor(props: OrderPageProps) {
    super(props);
    this.state = {
      order: this.getCustomerOrder(props),
    };
  }

  componentDidMount = async () => {
    const { match, history, context } = this.props;
    if (this.state.order) return;
    const id = match.params.id;
    if (!id || !BSON.ObjectId.isValid(id)) {
      history.push("/orders");
      return;
    }
    this._isMounted = true;
    const order = await getDocumentDB<CustomerCustomerOrder>(CUSTOMERORDER, id);
    if (!order) {
      console.error("No order could be loaded for id", id);
      toast.error("The requested order could not be loaded");
      history.push("/orders");
      return;
    }
    context.addDocuments(CUSTOMERORDER, [order]);
    const orderExtended = extendCustomerCustomerOrder(order, context);
    if (this._isMounted) this.setState({ order: orderExtended });
  };

  componentDidUpdate(prevProps: Readonly<OrderPageProps>) {
    const { match } = this.props;
    if (match.params.id || (!match.params.id && prevProps.match.params.id)) {
      const order = this.getCustomerOrder(this.props);
      if (!_.isEqual(order, this.state.order)) {
        this.setState({ order });
      }
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  getCustomerOrder = (props: OrderPageProps): CustomerCustomerOrderExtended | undefined => {
    const order = getDocFromCollection(props.context.customerOrder, props.match.params.id);
    if (order) return extendCustomerCustomerOrder(order, props.context);
  };

  render() {
    const { context } = this.props;
    const { order } = this.state;
    if (!order) return <div />;
    return (
      <div>
        <div className="content d-flex flex-column flex-column-fluid">
          <div className="post d-flex flex-column-fluid">
            <div className="container-xxl">
              <div className="d-flex flex-column flex-xl-row">
                <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
                  <OrderSummary order={order} context={context} />
                </div>
                <OrderPageTabPanel order={order} context={context} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrderPage;
