import _ from "lodash";
import React, { PureComponent } from "react";
import { Accordion } from "react-bootstrap";
import { CommodityExtended } from "../../../model/commodity.types";
import { PropertyType } from "../../../utils/propertyUtils";
import { Property } from "../../../model/property.types";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import { DataContextInternalType } from "../../../context/dataContext";
import { Input } from "../../common/Input";
import {
  C_G_GRADE,
  C_G_CONTAMINANTS,
  C_G_PHYSICALCHEMICAL,
  C_G_PRODUCTINFORMATION,
  C_G_TYPE,
  filterAdvancedProperties,
  getArticleProperty,
  C_G_REGULATORY_STATEMENT,
} from "../../../utils/commodityUtils";
import { CustomToggle } from "../../common/CustomToggle";
import { doFuseSearch } from "../../../utils/baseUtils";
import { generateCommodityPropertyTableRows } from "../common/CommodityHelper";

interface CommodityPageAdvancedInformationBlockProps {
  commodity: CommodityExtended;
  edit: boolean;
  context: DataContextInternalType;
  onEditCommodityInput: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onEditCommoditySelectExclusiveProperty: (e: SelectOption<Property>, key: PropertyType) => void;
  onEditFlagValue: (field: "veg" | "law" | "hazardMaterial" | "novelFood" | "cites" | "echa", value: string) => void;
  onEditGrades: (values: Array<SelectOption>) => void;
  onEditEqualityValue: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  type: C_G_TYPE;
  search: string;
}

interface CommodityPageAdvancedInformationBlockState {
  show: boolean;
}

class CommodityPageAdvancedInformationBlock extends PureComponent<
  CommodityPageAdvancedInformationBlockProps,
  CommodityPageAdvancedInformationBlockState
> {
  constructor(props: CommodityPageAdvancedInformationBlockProps) {
    super(props);
    this.state = { show: true };
  }

  filterAdvancedEditableProperties = () => {
    const {
      commodity,
      context,
      onEditCommodityInput,
      onEditCommoditySelectExclusiveProperty,
      onEditEqualityValue,
      onEditFlagValue,
      onEditGrades,
      type,
      search,
    } = this.props;
    const { property } = context;
    const packaging = getArticleProperty(commodity.properties, PropertyType.PACKAGING) as Property | null;
    const odor = getArticleProperty(commodity.properties, PropertyType.ODOR) as Property | null;

    const newPropertyOption = { value: "new", label: "Create New" };
    let properties: Array<{ name: string; element: JSX.Element }> = [];
    switch (type) {
      case C_G_PHYSICALCHEMICAL:
        properties = [
          {
            name: "Appearance",
            element: (
              <Input
                type="text"
                value={commodity.appearance?.en}
                name="appearance.en"
                onChange={onEditCommodityInput}
                placeholder="White Powder"
                className="form-control custom-form-control"
              />
            ),
          },
          {
            name: "Odor",
            element: (
              <CustomSelect
                options={[newPropertyOption].concat(
                  property
                    .filter((p) => p.type === PropertyType.ODOR)
                    .map((p) => {
                      return { value: p._id.toString(), label: p.name.en, object: p };
                    })
                )}
                value={odor ? { value: odor._id.toString(), label: odor.name.en } : undefined}
                onChange={(e: SelectOption<Property>) => onEditCommoditySelectExclusiveProperty(e, PropertyType.ODOR)}
                matchFormControl={true}
                isClearable={true}
              />
            ),
          },
          {
            name: "Loss on Drying",
            element: (
              <div className="input-group">
                <div className="input-group-prepend bg-custom-light-gray select-wrapper">
                  <select
                    className="form-control custom-form-control pt-0 pb-0"
                    style={{ minWidth: "70px" }}
                    name="lossOnDrying"
                    value={commodity.lossOnDrying.lessThan ? "leq" : "eq"}
                    onChange={onEditEqualityValue}
                  >
                    <option key="leq" value="leq">
                      ≤
                    </option>
                    <option key="eq" value="eq">
                      =
                    </option>
                  </select>
                </div>
                <Input
                  type="number"
                  value={commodity.lossOnDrying.amount}
                  name="lossOnDrying.amount"
                  onChange={onEditCommodityInput}
                  min={0}
                  className="form-control custom-form-control"
                />
                <div className="input-group-append rounded-end bg-custom-light-gray">
                  <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                    %
                  </div>
                </div>
              </div>
            ),
          },
          {
            name: "Total Ash",
            element: (
              <div className="input-group">
                <div className="input-group-prepend bg-custom-light-gray select-wrapper">
                  <select
                    className="form-control custom-form-control pt-0 pb-0"
                    style={{ minWidth: "70px" }}
                    name="ash"
                    value={commodity.ash.lessThan ? "leq" : "eq"}
                    onChange={onEditEqualityValue}
                  >
                    <option key="leq" value="leq">
                      ≤
                    </option>
                    <option key="eq" value="eq">
                      =
                    </option>
                  </select>
                </div>
                <Input
                  type="number"
                  value={commodity.ash.amount}
                  name="ash.amount"
                  onChange={onEditCommodityInput}
                  min={0}
                  className="form-control custom-form-control"
                />
                <div className="input-group-append rounded-end bg-custom-light-gray">
                  <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                    %
                  </div>
                </div>
              </div>
            ),
          },
          {
            name: "Particle Size",
            element: (
              <Input
                type="text"
                value={commodity.particleSize.en}
                name="particleSize.en"
                onChange={onEditCommodityInput}
                placeholder="e.g. 95% through 80 mesh"
                className="form-control custom-form-control"
              />
            ),
          },
          {
            name: "Specific Rotation",
            element: (
              <textarea
                value={commodity.specificRotation.en}
                name="specificRotation.en"
                onChange={onEditCommodityInput}
                placeholder="e.g. *L-Leucin +14.9 to 17.3 "
                className="form-control custom-form-control"
                rows={1}
              />
            ),
          },
          {
            name: "Purity",
            element: (
              <Input
                type="text"
                value={commodity.purity.en}
                name="purity.en"
                onChange={onEditCommodityInput}
                placeholder="e.g. 99.0-101.0 % accord. to EP 10.0"
                className="form-control custom-form-control"
              />
            ),
          },
          {
            name: "pH",
            element: (
              <Input
                type="number"
                value={commodity.ph}
                name="ph"
                onChange={onEditCommodityInput}
                placeholder="6.9"
                min={0}
                className="form-control custom-form-control"
              />
            ),
          },
          {
            name: "Limits",
            element: (
              <textarea
                value={commodity.limits.en}
                name="limits.en"
                onChange={onEditCommodityInput}
                placeholder="e.g. Chlorid: max.200 ppm"
                className="form-control custom-form-control"
                rows={1}
              />
            ),
          },
        ];
        break;
      case C_G_CONTAMINANTS:
        properties = [
          {
            name: "Aflatoxins",
            element: (
              <Input
                type="text"
                value={commodity.aflatoxins.en}
                name="aflatoxins.en"
                onChange={onEditCommodityInput}
                placeholder="e.g. max. 10 ppb"
                className="form-control custom-form-control"
              />
            ),
          },
          {
            name: "PAH 4",
            element: (
              <Input
                type="text"
                value={commodity.pah4.en}
                name="pah4.en"
                onChange={onEditCommodityInput}
                placeholder="e.g. max.50ppb"
                className="form-control custom-form-control"
              />
            ),
          },
          {
            name: "Benzopyrene",
            element: (
              <Input
                type="text"
                value={commodity.benzoypyrene.en}
                name="benzoypyrene.en"
                onChange={onEditCommodityInput}
                placeholder="e.g. max.10ppb"
                className="form-control custom-form-control"
              />
            ),
          },
          {
            name: "Max. Allowed ETO",
            element: (
              <textarea
                value={commodity.maxAllowedWETO.en}
                name="maxAllowedWETO.en"
                onChange={onEditCommodityInput}
                placeholder="e.g. < 0.025 ppm"
                className="form-control custom-form-control"
                rows={1}
              />
            ),
          },
        ];
        break;
      case C_G_PRODUCTINFORMATION:
        properties = [
          {
            name: "Vegan/Vegetarian",
            element: (
              <CustomSelect
                options={[
                  { value: "none", label: "None" },
                  { value: "vegetarian", label: "Vegetarian" },
                  { value: "vegan", label: "Vegan" },
                ]}
                value={
                  commodity.vegan
                    ? { value: "vegan", label: "Vegan" }
                    : commodity.vegetarian
                    ? { value: "vegetarian", label: "Vegetarian" }
                    : { value: "none", label: "None" }
                }
                onChange={(e: SelectOption) => onEditFlagValue("veg", e.value)}
                matchFormControl={true}
              />
            ),
          },
          {
            name: "Religious food law",
            element: (
              <CustomSelect
                options={[
                  { value: "none", label: "None" },
                  { value: "kosher", label: "Kosher" },
                  { value: "halal", label: "Halal" },
                  { value: "both", label: "Both" },
                ]}
                value={
                  commodity.kosher && commodity.halal
                    ? { value: "both", label: "Both" }
                    : commodity.kosher
                    ? { value: "kosher", label: "Kosher" }
                    : commodity.halal
                    ? { value: "halal", label: "Halal" }
                    : { value: "none", label: "None" }
                }
                onChange={(e: SelectOption) => onEditFlagValue("law", e.value)}
                matchFormControl={true}
              />
            ),
          },
          {
            name: "Packaging",
            element: (
              <CustomSelect
                options={[newPropertyOption].concat(
                  property
                    .filter((p) => p.type === PropertyType.PACKAGING)
                    .map((p) => {
                      return { value: p._id.toString(), label: p.name.en, object: p };
                    })
                )}
                value={packaging ? { value: packaging._id.toString(), label: packaging.name.en } : undefined}
                onChange={(e: SelectOption<Property>) =>
                  onEditCommoditySelectExclusiveProperty(e, PropertyType.PACKAGING)
                }
                matchFormControl={true}
                isClearable={true}
              />
            ),
          },
          {
            name: "Hazard Material",
            element: (
              <div className="form-check form-switch form-check-custom form-check-solid ">
                <input
                  className="form-check-input position-static"
                  checked={commodity.hazardMaterial}
                  type="checkbox"
                  onChange={() => onEditFlagValue("hazardMaterial", "")}
                />
              </div>
            ),
          },
          {
            name: "Novel Food",
            element: (
              <div className="form-check form-switch form-check-custom form-check-solid ">
                <input
                  className="form-check-input position-static"
                  checked={commodity.novelFood}
                  type="checkbox"
                  onChange={() => onEditFlagValue("novelFood", "")}
                />
              </div>
            ),
          },
          {
            name: "CITES",
            element: (
              <div className="form-check form-switch form-check-custom form-check-solid ">
                <input
                  className="form-check-input position-static"
                  checked={commodity.cites}
                  type="checkbox"
                  onChange={() => onEditFlagValue("cites", "")}
                />
              </div>
            ),
          },
          {
            name: "ECHA",
            element: (
              <div className="form-check form-switch form-check-custom form-check-solid ">
                <input
                  className="form-check-input position-static"
                  checked={commodity.echa}
                  type="checkbox"
                  onChange={() => onEditFlagValue("echa", "")}
                />
              </div>
            ),
          },
          {
            name: "Ratio Extract",
            element: (
              <Input
                type="text"
                value={commodity.ratioExtract || ""}
                name="ratioExtract"
                onChange={onEditCommodityInput}
                placeholder="5:1"
                className="form-control custom-form-control"
              />
            ),
          },
        ];
        break;
      case C_G_GRADE:
        properties = [
          {
            name: "Grade",
            element: (
              <CustomSelect
                options={[
                  { value: "food", label: "Food" },
                  { value: "pharmaceutical", label: "Pharmaceutical" },
                  { value: "feed", label: "Feed" },
                  { value: "cosmetic", label: "Cosmetic" },
                  { value: "usp", label: "USP" },
                  { value: "medicine", label: "Medicine" },
                  { value: "industrial", label: "Industrial" },
                ]}
                value={this.generateSelectedGrades()}
                onChange={onEditGrades}
                matchFormControl={this.generateSelectedGrades().length <= 1}
                isMulti={true}
              />
            ),
          },
        ];
        break;
      case C_G_REGULATORY_STATEMENT:
        properties = [
          {
            name: "Max. Allowed Heavy Metals",
            element: (
              <textarea
                value={commodity.maxAllowedHeavyMetals.en}
                name="maxAllowedHeavyMetals.en"
                onChange={onEditCommodityInput}
                placeholder="e.g. Arsenic: max. 1ppm"
                className="form-control custom-form-control"
                rows={1}
              />
            ),
          },
          {
            name: "Max. Allowed Microbiology",
            element: (
              <textarea
                value={commodity.maxAllowedMicrobiology.en}
                name="maxAllowedMicrobiology.en"
                onChange={onEditCommodityInput}
                placeholder="e.g. Total plate count: ≤ 1,000 CFU/g"
                className="form-control custom-form-control"
                rows={1}
              />
            ),
          },
          {
            name: "Possible Cross Contamination",
            element: (
              <textarea
                value={commodity.possibleCrossContamination.en}
                name="possibleCrossContamination.en"
                onChange={onEditCommodityInput}
                placeholder="e.g. BHA/BHT/Related compounds"
                className="form-control custom-form-control"
                rows={1}
              />
            ),
          },
          {
            name: "Regulatory Data",
            element: (
              <textarea
                value={commodity.regulatoryData.en}
                name="regulatoryData.en"
                onChange={onEditCommodityInput}
                placeholder="e.g. Not a hazardous substance acc."
                className="form-control custom-form-control"
                rows={1}
              />
            ),
          },
          {
            name: "Total Residual Organic Solvents",
            element: (
              <textarea
                value={commodity.totalResidualOrganicSolvents.en}
                name="totalResidualOrganicSolvents.en"
                onChange={onEditCommodityInput}
                placeholder="e.g. max 5.000 ppm,Ethanol max. 5.000 ppm"
                className="form-control custom-form-control"
                rows={1}
              />
            ),
          },
        ];
    }
    return search.trim() ? doFuseSearch(properties, search, ["name"]) : _.sortBy(properties, (obj) => obj.name);
  };

  generateSelectedGrades = () => {
    const { commodity } = this.props;
    const selectedGrades: Array<SelectOption> = [];
    if (commodity.foodGrade) selectedGrades.push({ value: "food", label: "Food" });
    if (commodity.pharmaceuticalGrade) selectedGrades.push({ value: "pharmaceutical", label: "Pharmaceutical" });
    if (commodity.feedGrade) selectedGrades.push({ value: "feed", label: "Feed" });
    if (commodity.cosmeticGrade) selectedGrades.push({ value: "cosmetic", label: "Cosmetic" });
    if (commodity.uspGrade) selectedGrades.push({ value: "usp", label: "USP" });
    if (commodity.medicineGrade) selectedGrades.push({ value: "medicine", label: "Medicine" });
    if (commodity.industrialGrade) selectedGrades.push({ value: "industrial", label: "Industrial" });
    return selectedGrades;
  };

  generateAdvancedPropertiesList = () => {
    const { commodity, type, search } = this.props;
    const propertiesList = filterAdvancedProperties(commodity, search, type);
    if (propertiesList.length === 0) {
      return (
        <tr>
          <td colSpan={2}>
            <span className="text-white">No result found</span>
          </td>
        </tr>
      );
    }
    return generateCommodityPropertyTableRows(propertiesList);
  };

  generateTitleForType = () => {
    const { type } = this.props;
    switch (type) {
      case C_G_PHYSICALCHEMICAL:
        return "Physical & Chemical Parameters";
      case C_G_CONTAMINANTS:
        return "Contaminants";
      case C_G_PRODUCTINFORMATION:
        return "General Product Information";
      case C_G_GRADE:
        return "Grade";
      case C_G_REGULATORY_STATEMENT:
        return "Regulatory Statements";
    }
  };

  render() {
    const { edit, type } = this.props;
    const { show } = this.state;

    return (
      <Accordion defaultActiveKey={type}>
        <div className="py-0">
          <div className="d-flex py-3 align-items-center w-100">
            <div className="me-3 w-100">
              <div className="row">
                <div className="col col-md-6">
                  <div className="text-white fs-5 fw-bolder">
                    <CustomToggle eventKey={type} callback={() => this.setState({ show: !this.state.show })}>
                      {this.generateTitleForType()}
                      <i className={"custom-accordion-toggle fa fa-chevron-up ml-2" + (show ? " show" : "")} />
                    </CustomToggle>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Accordion.Collapse eventKey={type}>
            <div className="fs-6">
              {edit ? (
                <div className="row py-5">
                  {this.filterAdvancedEditableProperties().map((p) => (
                    <div className="col-md-4 mt-2" key={p.name}>
                      <label className="fs-6 fw-bold mb-1">{p.name}</label>
                      {p.element}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="d-flex flex-wrap py-5">
                  <table className="table fw-bold gy-1">
                    <tbody>{this.generateAdvancedPropertiesList()}</tbody>
                  </table>
                </div>
              )}
            </div>
          </Accordion.Collapse>
        </div>
      </Accordion>
    );
  }
}

export default CommodityPageAdvancedInformationBlock;
