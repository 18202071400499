import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import auth from "../../services/authService";
import userService from "../../services/userService";

interface ProtectedRouteProps {
  children?: any;
  path: string;
  component?: React.ComponentType<any>;
  render?: any;
  exact?: boolean;
}

const ProtectedRoute: React.FunctionComponent<ProtectedRouteProps> = ({
  children,
  component,
  render,
  path,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(routeProps: any) => {
        if (!auth.isLoggedIn()) {
          window.location.href = "/login";
          return null;
        } else if (!userService.getUserData().onboardingDone && path !== "/onboarding") {
          return <Redirect to={{ pathname: "/onboarding" }} />;
        }
        if (typeof children === "function") {
          return children(routeProps);
        }
        if (!routeProps.match) {
          return null;
        }
        if (children) {
          return children;
        }
        if (component) {
          return React.createElement(component, routeProps);
        }
        if (render) {
          return render(routeProps);
        }

        return null;
      }}
    />
  );
};

export default ProtectedRoute;
