import {
  CustomerCustomerContract,
  CustomerCustomerContractExtended,
} from "../model/customer/customerCustomerContract.types";
import { CustomerContract, CustomerContractExtended } from "../model/customerContract.types";
import { D_MASTERSPECIFICATION } from "./commodityUtils";
import { CC_FILETYPES } from "./customerContractUtils";
import { DataContextCustomerType, DataContextInternalType } from "../context/dataContext";
import { CO_ARCHIVED } from "../model/customerOrder.types";

export type CONTRACT_TYPES = CustomerCustomerContract | CustomerContract;
export type EXTENDED_CONTRACT_TYPES = CustomerContractExtended | CustomerCustomerContractExtended;

/**
 * Collect files related to a contract
 * @param contract any customer contract
 * @returns {Array<{ _id: string; title: string; date: Date; link: string;}>} list of prepared files
 */
export const collectFilesForContract = (
  contract: CONTRACT_TYPES | EXTENDED_CONTRACT_TYPES
): Array<{ _id: string; title: string; date: Date; link: string }> => {
  let files: Array<{
    _id: string;
    title: string;
    date: Date;
    link: string;
  }> = [];
  let masterSpec;
  if (contract.commodity.documents.length > 0) {
    masterSpec = contract.commodity.documents.find((d) => d.type === D_MASTERSPECIFICATION);
  }
  files = files.concat(
    contract.files.map((f) => {
      const title = CC_FILETYPES.find((ft) => ft.value === f.type)?.label ?? "Unknown";
      return {
        _id: f._id.toString(),
        title,
        date: f.date,
        link: f.path,
      };
    })
  );

  // If master spec was not overwritten with an uploaded spec push commodity master spec to front
  if (masterSpec)
    files.unshift({
      _id: masterSpec._id.toString(),
      title: `Specification ${contract.commodity.title.en}`,
      date: contract.createdAt,
      link: masterSpec.path,
    });
  return files;
};

/**
 * Check if the contract has only archived call offs
 * @param context Internal context
 * @param contract Contract to be checked
 * @returns {boolean} Flag if the contract has only archived call offs
 */
export function allCallsArchived(
  context: DataContextInternalType | DataContextCustomerType,
  contract: CONTRACT_TYPES | EXTENDED_CONTRACT_TYPES
): boolean {
  const { customerOrder } = context;
  const contractCalloffs = customerOrder.filter(
    (cO) => cO.contractInformation && cO.contractInformation._id.toString() === contract._id.toString()
  );
  return contractCalloffs.every((cc) => cc.state === CO_ARCHIVED);
}
