import React from "react";
import { Address, AddressType } from "../../model/commonTypes";
import { Company, CompanyExtended } from "../../model/company.types";
import { getCountryNameForCode, toAbsoluteUrl } from "../../utils/baseUtils";
import { getAddressByType } from "../../utils/addressUtils";
import { Forwarder, ForwarderExtended } from "../../model/forwarder.types";
import { SupplierType } from "../../utils/supplierUtils";

interface CompanyCountryWidgetProps {
  company: Company | CompanyExtended | SupplierType | Forwarder | ForwarderExtended;
  address?: Address;
  wrapperClasses?: string;
  iconClasses?: string;
}

const CompanyCountryWidget: React.FunctionComponent<CompanyCountryWidgetProps> = ({
  company,
  address,
  wrapperClasses,
  iconClasses,
}) => {
  const addr = address ?? getAddressByType(company.address, AddressType.A_PRIMARY);
  const countryName = addr?.country ? getCountryNameForCode(addr.country) : undefined;
  return (
    <div className={wrapperClasses}>
      {countryName && (
        <img
          src={toAbsoluteUrl(`/assets/media/icons/countries/${addr?.country.toLowerCase() || ""}.png`)}
          className={iconClasses ?? "country-icon mb-1 mx-3"}
          alt={addr?.country || "unknown"}
        />
      )}
      {countryName ? countryName : "Unknown"}
    </div>
  );
};

export default CompanyCountryWidget;
