import _ from "lodash";
import React, { useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { CashflowData } from "../../../../model/statistics/controllingStatistics.types";
import { getMonthString } from "../../../../utils/dateUtils";
import { formatCurrency } from "../../../../utils/baseUtils";

interface CashflowCardProps {
  data: CashflowData;
  warehouseData: { value: Array<number>; currency: string };
}

const currentMonth = new Date().getMonth();

const CashflowCard: React.FC<CashflowCardProps> = ({ data, warehouseData }) => {
  const [showDiff, setShowDiff] = useState<boolean>(false);

  const handleToggle = () => setShowDiff(!showDiff);

  const categories = [
    currentMonth - 2,
    currentMonth - 1,
    currentMonth,
    currentMonth + 1,
    currentMonth + 2,
    currentMonth + 3,
  ].map((cM) => getMonthString(cM));

  const incomeSeries = useMemo(
    () => [
      { x: categories[0], y: data.beforeLastMonth.income, fillColor: "rgba(80, 205, 137, 1)" },
      { x: categories[1], y: data.lastMonth.income, fillColor: "rgba(80, 205, 137, 1)" },
      { x: categories[2], y: data.currentMonth.income, fillColor: "rgba(80, 205, 137, 1)" },
      { x: categories[3], y: data.nextMonth.income, fillColor: "rgba(80, 205, 137, 0.3)" },
      { x: categories[4], y: data.afterNextMonth.income, fillColor: "rgba(80, 205, 137, 0.3)" },
      { x: categories[5], y: data.afterAfterNextMonth.income, fillColor: "rgba(80, 205, 137, 0.3)" },
    ],
    [data]
  );

  const expensesSeries = useMemo(
    () => [
      { x: categories[0], y: data.beforeLastMonth.expenses, fillColor: "rgba(255, 38, 90, 1)" },
      { x: categories[1], y: data.lastMonth.expenses, fillColor: "rgba(255, 38, 90, 1)" },
      { x: categories[2], y: data.currentMonth.expenses, fillColor: "rgba(255, 38, 90, 1)" },
      { x: categories[3], y: data.nextMonth.expenses, fillColor: "rgba(255, 38, 90, 0.3)" },
      { x: categories[4], y: data.afterNextMonth.expenses, fillColor: "rgba(255, 38, 90, 0.3)" },
      { x: categories[5], y: data.afterAfterNextMonth.expenses, fillColor: "rgba(255, 38, 90, 0.3)" },
    ],
    [data]
  );

  const warehouseSeries = useMemo(
    () => [
      { x: categories[0], y: warehouseData.value[currentMonth > 1 ? currentMonth - 2 : 0] },
      { x: categories[1], y: warehouseData.value[currentMonth > 0 ? currentMonth - 1 : 0] },
      { x: categories[2], y: warehouseData.value[currentMonth] },
      { x: categories[3], y: warehouseData.value[currentMonth < 11 ? currentMonth + 1 : 11] },
      { x: categories[4], y: warehouseData.value[currentMonth < 10 ? currentMonth + 2 : 11] },
      { x: categories[5], y: warehouseData.value[currentMonth < 9 ? currentMonth + 3 : 11] },
    ],
    [warehouseData]
  );

  const diffData = useMemo(
    () => [
      data.beforeLastMonth.income - data.beforeLastMonth.expenses,
      data.lastMonth.income - data.lastMonth.expenses,
      data.currentMonth.income - data.currentMonth.expenses,
      data.nextMonth.income - data.nextMonth.expenses,
      data.afterNextMonth.income - data.afterNextMonth.expenses,
      data.afterAfterNextMonth.income - data.afterAfterNextMonth.expenses,
    ],
    [data]
  );

  const diffSeries = useMemo(
    () => [
      {
        x: categories[0],
        y: Math.abs(diffData[0]),
        fillColor: diffData[0] >= 0 ? "rgba(80, 205, 137, 1)" : "rgba(255, 38, 90, 1)",
      },
      {
        x: categories[1],
        y: Math.abs(diffData[1]),
        fillColor: diffData[1] >= 0 ? "rgba(80, 205, 137, 1)" : "rgba(255, 38, 90, 1)",
      },
      {
        x: categories[2],
        y: Math.abs(diffData[2]),
        fillColor: diffData[2] >= 0 ? "rgba(80, 205, 137, 1)" : "rgba(255, 38, 90, 1)",
      },
      {
        x: categories[3],
        y: Math.abs(diffData[3]),
        fillColor: diffData[3] >= 0 ? "rgba(80, 205, 137, 0.3)" : "rgba(255, 38, 90, 0.3)",
      },
      {
        x: categories[4],
        y: Math.abs(diffData[4]),
        fillColor: diffData[4] >= 0 ? "rgba(80, 205, 137, 0.3)" : "rgba(255, 38, 90, 0.3)",
      },
      {
        x: categories[5],
        y: Math.abs(diffData[5]),
        fillColor: diffData[5] >= 0 ? "rgba(80, 205, 137, 0.3)" : "rgba(255, 38, 90, 0.3)",
      },
    ],
    [diffData]
  );

  const maxValue =
    _.max([
      data.beforeLastMonth.income,
      data.beforeLastMonth.expenses,
      data.lastMonth.income,
      data.lastMonth.expenses,
      data.currentMonth.income,
      data.currentMonth.expenses,
      data.nextMonth.income,
      data.nextMonth.expenses,
      data.afterNextMonth.income,
      data.afterNextMonth.expenses,
      data.afterAfterNextMonth.income,
      data.afterAfterNextMonth.expenses,
    ]) || 0;
  const yAxisMax = 200000 * (_.toInteger(maxValue / 200000) + 1);

  const options: ApexOptions = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: showDiff ? "30%" : "45%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: [1, 1, 1, 2],
      colors: ["transparent", "transparent", "transparent", "#1E90FF"],
    },
    xaxis: {
      categories: categories,
      labels: {
        show: true,
        style: {
          colors: "#9aa0ac",
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: true,
        color: "#78909c",
      },
      axisTicks: {
        show: true,
        color: "#78909c",
      },
    },
    yaxis: [
      { seriesName: "Expenses", show: false, min: 0, max: yAxisMax },
      {
        seriesName: "Income",
        labels: {
          show: true,
          style: {
            colors: "#9aa0ac",
            fontSize: "12px",
          },
          formatter: (value) => `${value / 1000} Tsd`,
        },
        axisBorder: {
          show: true,
          color: "#78909c",
        },
        axisTicks: {
          show: true,
          color: "#78909c",
        },
        tickAmount: 4,
        min: 0,
        max: yAxisMax,
      },
      { seriesName: "Difference", show: false, min: 0, max: yAxisMax },
      {
        opposite: true,
        seriesName: "Warehouse",
        labels: {
          show: true,
          style: {
            colors: "#9aa0ac",
            fontSize: "12px",
          },
          formatter: (value) => `${value / 1000000} Mio.`,
        },
        axisBorder: {
          show: true,
          color: "#9aa0ac",
        },
        axisTicks: {
          show: true,
          color: "#9aa0ac",
        },
      },
    ],
    grid: {
      show: true,
      borderColor: "#d3d3d3",
      strokeDashArray: 5,
      padding: {
        top: -20,
        bottom: -10,
      },
    },
    tooltip: {
      theme: "dark",
      y: {
        formatter: (val: number) => formatCurrency(val, "EUR"),
      },
    },
    legend: {
      show: false,
    },
  };

  const series: ApexOptions["series"] = [
    {
      type: "column",
      name: "Expenses",
      data: expensesSeries,
    },
    {
      type: "column",
      name: "Income",
      data: incomeSeries,
    },
    {
      type: "column",
      name: "Difference",
      data: showDiff ? diffSeries : [],
    },
    {
      type: "line",
      name: "Warehouse",
      data: warehouseSeries,
    },
  ];

  return (
    <div className="card bg-white rounded h-100">
      <div className="card-header border-0">
        <div className="card-title d-flex justify-content-between align-items-center w-100">
          <h2 className="mb-0">Cashflow</h2>
          <label className="form-check form-switch form-check-custom form-check-solid ml-auto mr-3">
            <small className="form-check-label fw-bold xt-muted mr-1">Show Diff.</small>
            <input
              className="form-check-input position-static"
              type="checkbox"
              style={{ height: 20, width: 40 }}
              checked={showDiff}
              onChange={handleToggle}
            />
          </label>
          <label className="form-check form-switch form-check-custom form-check-solid mr-3">
            <small className="form-check-label fw-bold text-muted mr-1">Time Frame</small>
            <select className="form-control custom-form-control py-0" disabled style={{ width: 150 }}>
              <option>6 Months</option>
            </select>
          </label>
        </div>
      </div>
      <ReactApexChart options={options} series={series} type="bar" height={200} />
    </div>
  );
};

export default CashflowCard;
