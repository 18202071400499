import { ApexOptions } from "apexcharts";
import React, { PureComponent } from "react";
import Chart from "react-apexcharts";
import { GeneralStatistics } from "../../../model/statistics/controllingStatistics.types";
import { round } from "../../../utils/baseUtils";

interface StatsWidgetProps {
  generalStatistics: GeneralStatistics;
}

class StatsWidget extends PureComponent<StatsWidgetProps> {
  render() {
    const { activeCommoditiesPercentage, activeSuppliersPercentage, activeCustomersPercentage } =
      this.props.generalStatistics;
    return (
      <div className="card bg-white rounded h-250px ">
        <div className="card-body d-flex justify-content-between flex-column px-0 pb-0">
          <div className="px-9">
            <h2 className="mb-0">General Statistics</h2>
            <div className="d-flex justify-content-between align-items-center h-100">
              <Stat title="Active Commodities" value={round(activeCommoditiesPercentage, 2)} />
              <Stat title="Active Suppliers" value={round(activeSuppliersPercentage, 2)} />
              <Stat title="Active Customers" value={round(activeCustomersPercentage, 2)} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

interface StatProps {
  title: string;
  value: number;
}

class Stat extends PureComponent<StatProps> {
  options: ApexOptions = {
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#222",
          margin: 5,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: "16px",
            fontWeight: "600",
            color: "white",
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      colors: ["#fff"],
    },
  };

  render() {
    const { title, value } = this.props;

    return (
      <div className="text-center w-100 mb-5">
        <Chart options={this.options} series={[value]} type="radialBar" width="100%" />
        <span className="text-white text-nowrap">{title}</span>
      </div>
    );
  }
}

export default StatsWidget;
