import React, { PureComponent } from "react";
import OrderWidget from "../../../../common/OrderWidget";
import { DataContextInternalType } from "../../../../../context/dataContext";
import { SupplierOrderExtended } from "../../../../../model/supplierOrder.types";
import { CustomerOrder } from "../../../../../model/customerOrder.types";
import { CustomerContract } from "../../../../../model/customerContract.types";
import AttachOrderModal from "./modals/AttachOrderModal";
import { isCustomerOrder } from "../../../../../utils/orderUtils";

import { extendCustomerContract, extendCustomerOrder } from "../../../../../utils/dataTransformationUtils";

interface SupplierOrderCustomerOrdersProps {
  order: SupplierOrderExtended;
  context: DataContextInternalType;
}

class SupplierOrderCustomerOrders extends PureComponent<SupplierOrderCustomerOrdersProps> {
  render() {
    const { order, context } = this.props;
    let allDocuments: Array<CustomerOrder | CustomerContract> = order.customerOrders.slice();
    if (order.customerContracts) allDocuments = allDocuments.concat(order.customerContracts);
    return (
      <div className="card bg-white">
        <div className="card-header border-0 mt-5">
          <div className="card-title flex-column">
            <h2 className="mb-1">Customer Orders & Contracts</h2>
          </div>
        </div>
        <div className="card-body p-9 pt-0">
          <div className="mt-5 pt-2">
            {allDocuments.length > 0 ? (
              allDocuments.map((doc) => (
                <OrderWidget
                  key={doc._id.toString()}
                  type="customer"
                  order={
                    isCustomerOrder(doc) ? extendCustomerOrder(doc, context) : extendCustomerContract(doc, context)
                  }
                  context={context}
                  showServices={true}
                />
              ))
            ) : (
              <div className="pt-5 fw-bold text-white">No related customer orders</div>
            )}
          </div>
        </div>
        <div className="card-body p-9 pt-0">
          <div className="mt-5 pt-2">
            <AttachOrderModal context={context} order={order} />
          </div>
        </div>
      </div>
    );
  }
}

export default SupplierOrderCustomerOrders;
