import { BSON } from "realm-web";
import countryList from "i18n-iso-countries";
import React, { PureComponent } from "react";
import { ForwarderExtended } from "../../../model/forwarder.types";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import { getPaymentTargetDescription, I_PAYMENTTARGETS_WITH_CUSTOM_OPTION } from "../../../utils/invoiceUtils";
import { getPaymentTargetSelectOption } from "../../../utils/logisticsUtils";
import { Input } from "../../common/Input";
import AddressInputGroup from "../../common/AddressInputGroup";
import { ADDRESS_TYPE_OPTIONS, AddressType } from "../../../model/commonTypes";
import { DataContextInternalType } from "../../../context/dataContext";
import { formatAddress } from "../../../utils/addressUtils";
import CompanyCountryWidget from "../../common/CompanyCountryWidget";
import { getCountryNameForCode } from "../../../utils/baseUtils";
import CountryWidget from "../../common/CountryWidget";

interface ForwarderPageGeneralInformationBlockProps {
  context: DataContextInternalType;
  forwarder: ForwarderExtended;
  edit: boolean;
  customPaymentTarget: boolean;
  onEditForwarderInput: (e: React.ChangeEvent<HTMLInputElement>, field?: string) => void;
  onEditForwarderSelect: (name: string, e: SelectOption | Array<SelectOption>) => void;
  onEditForwarderSelectAddressField: (name: string, e: SelectOption, idx: number) => void;
  onAddAddress: () => void;
  onDeleteAddress: (id: BSON.ObjectId) => void;
  onEditForwarderSelectPaymentTarget: (e: SelectOption) => void;
  onEditCustomPaymentTarget: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEditCustomPaymentTargetConditions: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

class ForwarderPageGeneralInformationBlock extends PureComponent<ForwarderPageGeneralInformationBlockProps> {
  handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const field = "address[" + idx + "]." + e.target.name;
    this.props.onEditForwarderInput(e, field);
  };

  render() {
    const {
      context,
      forwarder,
      edit,
      customPaymentTarget,
      onEditForwarderInput,
      onEditForwarderSelect,
      onEditForwarderSelectAddressField,
      onAddAddress,
      onDeleteAddress,
      onEditForwarderSelectPaymentTarget,
      onEditCustomPaymentTarget,
      onEditCustomPaymentTargetConditions,
    } = this.props;
    const paymentTarget = getPaymentTargetSelectOption(forwarder, customPaymentTarget);
    const existingBlacklistedSuppliers = forwarder.blacklists.reduce((acc, comp) => {
      const company = context.company.find((company) => company._id.toString() === comp);
      if (company) {
        acc.push({ value: company._id.toString(), label: company.name });
      }
      return acc;
    }, [] as { value: string; label: string }[]);
    const existingCountries = forwarder.countries.map((c) => {
      return {
        value: c,
        label: getCountryNameForCode(c),
      };
    });
    const blacklistSelectOptions = () =>
      context.company.map((c) => ({
        value: c._id.toString(),
        label: c.name,
      }));
    const countrySelectOptions = () =>
      Object.values(countryList.getNames("en", { select: "alias" })).map((item: string) => ({
        value: countryList.getAlpha2Code(item, "en"),
        label: item,
      }));
    return (
      <div className="fs-6">
        {edit ? (
          <div className="row py-5">
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">Name</label>
              <Input
                type="text"
                value={forwarder.name}
                name={"name"}
                onChange={onEditForwarderInput}
                className="form-control custom-form-control"
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">General Mail</label>
              <Input
                type="text"
                value={forwarder.mail}
                name={"mail"}
                onChange={onEditForwarderInput}
                className="form-control custom-form-control"
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">General Phone</label>
              <Input
                type="text"
                value={forwarder.phone}
                name={"phone"}
                onChange={onEditForwarderInput}
                className="form-control custom-form-control"
              />
            </div>
            <div className="col-md-12 mt-2">
              {forwarder.address.map((a, idx) => (
                <React.Fragment key={a._id.toString()}>
                  <div className="mt-6 border-bottom-dark-gray" />
                  <label className={"fs-6 fw-bold mt-4"}>Address {idx + 1}</label>
                  <AddressInputGroup
                    address={a}
                    persons={[forwarder.primaryPerson, ...forwarder.persons]}
                    context={context}
                    onChangeAddress={(e) => this.handleChangeAddress(e, idx)}
                    onChangeAddressType={(e: SelectOption) => onEditForwarderSelect("address[" + idx + "].type", e)}
                    onChangeAddressCountry={(e: SelectOption) => onEditForwarderSelectAddressField("country", e, idx)}
                    onChangeAddressContact={(e: SelectOption) =>
                      onEditForwarderSelectAddressField("contactPerson", e, idx)
                    }
                    onDeleteAddress={idx === 0 ? undefined : onDeleteAddress}
                  />
                </React.Fragment>
              ))}
              <button className="btn btn-sm float-right pr-0" onClick={onAddAddress}>
                <i className="fa fa-plus text-success p-0" />
              </button>
            </div>
            <div className="mt-2 border-bottom-dark-gray" />
            <div className="col-md-6 mt-6">
              <label className="fs-6 fw-bold mb-1">Payment Term</label>
              <CustomSelect
                options={I_PAYMENTTARGETS_WITH_CUSTOM_OPTION}
                value={paymentTarget}
                onChange={(e: SelectOption) => onEditForwarderSelectPaymentTarget(e)}
                matchFormControl={true}
              />
              {customPaymentTarget && (
                <>
                  <label className="fs-6 fw-bold mb-1 mt-2">Payment Target</label>
                  <div className="input-group">
                    <Input
                      type="number"
                      value={
                        forwarder.paymentTerms && forwarder.paymentTerms.paymentTarget !== ""
                          ? Number(forwarder.paymentTerms.paymentTarget.replace(" days", "").trim())
                          : ""
                      }
                      name="customPaymentTarget"
                      onChange={onEditCustomPaymentTarget}
                      className="form-control custom-form-control"
                    />
                    <div className="input-group-append">
                      <span className="input-group-text form-control custom-form-control">days</span>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="col-md-6 mt-6">
              <label className="fs-6 fw-bold mb-1">Additional Payment Conditions</label>
              <Input
                type="text"
                value={forwarder.paymentTerms?.paymentTargetConditions ?? ""}
                name="customPaymentTargetConditions"
                onChange={onEditCustomPaymentTargetConditions}
                className="form-control custom-form-control"
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">Blacklisted Customers</label>
              <CustomSelect
                options={blacklistSelectOptions()}
                value={existingBlacklistedSuppliers}
                onChange={(e: Array<SelectOption>) => onEditForwarderSelect("blacklists", e)}
                isMulti={true}
                isClearable={true}
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">Delivery Countries</label>
              <CustomSelect
                options={countrySelectOptions()}
                value={existingCountries}
                onChange={(e: Array<SelectOption>) => onEditForwarderSelect("countries", e)}
                isMulti={true}
                isClearable={true}
              />
            </div>
          </div>
        ) : (
          <div className="d-flex flex-wrap py-5">
            <table className="table fw-bold gy-1">
              <tbody>
                <tr>
                  <td className="text-white w-25 min-w-125px">Name</td>
                  <td className="text-muted w-25">{forwarder.name}</td>
                  <td className="text-white w-25 min-w-125px">General Mail</td>
                  <td className="text-muted w-25">{forwarder.mail}</td>
                </tr>
                <tr>
                  <td className="text-white w-25 min-w-125px">General Phone</td>
                  <td className="text-muted w-25">{forwarder.phone}</td>
                </tr>
                {forwarder.address.map((a) => {
                  const addrType = ADDRESS_TYPE_OPTIONS.find((at) => at.value === a.type)?.label;
                  const addrTypeLabel = `${addrType && a.type !== AddressType.A_OTHER ? addrType + " " : ""}Address`;
                  const contactPerson = context.userData.find((user) => user._id.toString() === a.contactPerson);
                  return (
                    <React.Fragment key={a._id.toString()}>
                      <tr>
                        <td className="text-white w-25 max-w-125px">{addrTypeLabel}</td>
                        <td className="text-muted w-25 display-linebreak">
                          {formatAddress(a, { withoutCountry: true })}
                        </td>
                        <td className="text-white w-25 min-w-125px">Address Country</td>
                        <td className="text-muted w-25">
                          <CompanyCountryWidget company={forwarder} address={a} />
                        </td>
                      </tr>
                      <tr>
                        <td className="text-white w-25 max-w-125px">Contact person</td>
                        <td className="text-muted w-25 display-linebreak">
                          {contactPerson ? `${contactPerson.prename} ${contactPerson.surname}` : "-"}
                        </td>
                        <td className="text-white w-25 max-w-125px">Position</td>
                        <td className="text-muted w-25 display-linebreak">
                          {contactPerson?.position !== "" || contactPerson?.position !== undefined
                            ? contactPerson?.position
                            : "-"}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
                <tr>
                  <td className="text-white w-25 min-w-125px">Payment Target</td>
                  <td className="text-muted w-25">
                    {forwarder.paymentTerms && forwarder.paymentTerms.paymentTarget !== ""
                      ? getPaymentTargetDescription(forwarder.paymentTerms.paymentTarget)
                      : "not specified"}
                  </td>
                  {forwarder.paymentTerms?.paymentTargetConditions ? (
                    <>
                      <td className="text-white w-25 min-w-125px">Payment Conditions</td>
                      <td className="text-muted w-25">{forwarder.paymentTerms.paymentTargetConditions}</td>
                    </>
                  ) : (
                    <td />
                  )}
                </tr>
                <tr>
                  <td className="text-white w-25 max-w-125px">Blacklisted Customers</td>
                  <td className="text-muted w-25">
                    {forwarder.blacklists.length > 0
                      ? forwarder.blacklists
                          .map((c) => {
                            const company = context.company.find((comp) => comp._id.toString() === c);
                            return company?.name;
                          })
                          .join("\n")
                      : "-"}
                  </td>
                  <td className="text-white w-25 max-w-125px">Delivery Countries</td>
                  <td className="text-muted w-25">
                    <>
                      {forwarder.countries.length > 0 ? (
                        forwarder.countries.map((c) => {
                          return <CountryWidget key={c} countryCode={c} />;
                        })
                      ) : (
                        <span>-</span>
                      )}
                    </>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

export default ForwarderPageGeneralInformationBlock;
