import _ from "lodash";
import React, { PureComponent } from "react";
import {
  SupplierExtended,
  SupplierTimelineEntry,
  T_S_ADDCOMMODITYTOSUPPLIER,
  T_S_ADDFINISHEDPRODUCTSUPPLIER,
  T_S_ADDPACKAGINGDIMENSIONS,
  T_S_ADDUSERTOSUPPLIER,
  T_S_ASSIGNPACKAGINGDIMENSION,
  T_S_DELETESUPPLIERFILE,
  T_S_UPDATECOMMODITYPRICE,
  T_S_UPDATEFINISHEDPRODUCTPRICE,
  T_S_UPDATEPACKAGINGDIMENSIONS,
  T_S_UPDATESUPPLIERINFORMATION,
  T_S_UPDATESUPPLIERSTATE,
  T_S_UPLOADSUPPLIERFILE,
} from "../../../../model/supplier.types";
import Search from "../../../common/Search";
import { DataContextInternalType } from "../../../../context/dataContext";
import { doFuseSearch, getDocFromCollection } from "../../../../utils/baseUtils";
import { SupplierOrder } from "../../../../model/supplierOrder.types";
import OrderHistoryEntry from "../../../orders/common/OrderHistoryEntry";
import { OrderTimeline } from "../../../../model/commonTypes";
import { ExtendedSupplierTimelineEntry } from "../../../../utils/supplierUtils";
import HistoryEntry from "../../../common/HistoryEntry";
import Tooltip from "../../../common/Tooltip";
import { PriceHistoryEntry } from "../../../common/internal/PriceHistoryEntry";

interface SupplierPageHistoryProps {
  supplier: SupplierExtended;
  context: DataContextInternalType;
}

interface SupplierPageHistoryState {
  search: string;
}

class SupplierPageHistory extends PureComponent<SupplierPageHistoryProps, SupplierPageHistoryState> {
  constructor(props: SupplierPageHistoryProps) {
    super(props);
    this.state = {
      search: "",
    };
  }

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });

  getEntryDescription = (entry: SupplierTimelineEntry) => {
    const { context } = this.props;
    switch (entry.type) {
      case T_S_ADDPACKAGINGDIMENSIONS:
        return "New Palette Data was added";
      case T_S_UPDATEPACKAGINGDIMENSIONS:
        return "Palette Data was updated";
      case T_S_ASSIGNPACKAGINGDIMENSION:
        return `Palette data was assigned${entry.payload?.name ? ` to ${entry.payload?.name}` : ""}`;
      case T_S_UPLOADSUPPLIERFILE:
        return `General file was uploaded${entry.payload?.type === "certificate" ? ": Certificate" : ""}`;
      case T_S_DELETESUPPLIERFILE:
        return `General file was deleted${entry.payload?.type === "certificate" ? ": Certificate" : ""}`;
      case T_S_ADDUSERTOSUPPLIER:
        return `User was added to the supplier${entry.payload?.name ? `: ${entry.payload?.name}` : ""}`;
      case T_S_ADDCOMMODITYTOSUPPLIER:
        return `Commodity was added to the supplier${entry.payload?.name ? `: ${entry.payload?.name}` : ""}`;
      case T_S_ADDFINISHEDPRODUCTSUPPLIER:
        return `Finished product was added to the supplier${entry.payload?.name ? `: ${entry.payload?.name}` : ""}`;
      case T_S_UPDATESUPPLIERSTATE:
        return `Supplier ${entry.payload?.name ? ` was ${entry.payload?.name}` : " status changed"}`;
      case T_S_UPDATESUPPLIERINFORMATION:
        return "Supplier information was updated";
      case T_S_UPDATECOMMODITYPRICE: {
        const commodity = entry.payload?.reference
          ? getDocFromCollection(context.commodity, entry.payload.reference)
          : undefined;
        return commodity ? (
          <Tooltip
            tooltipClass="custom-tooltip custom-tooltip-large"
            tooltipText={<PriceHistoryEntry entry={entry} commodity={commodity} context={context} />}
          >
            <span>Prices for {commodity.title.en} were updated</span>
          </Tooltip>
        ) : (
          "Prices for commodity were updated"
        );
      }
      case T_S_UPDATEFINISHEDPRODUCTPRICE: {
        const commodity = entry.payload?.reference
          ? getDocFromCollection(context.finishedProduct, entry.payload.reference)
          : undefined;
        return commodity ? (
          <Tooltip
            tooltipClass="custom-tooltip custom-tooltip-large"
            tooltipText={<PriceHistoryEntry entry={entry} commodity={commodity} context={context} />}
          >
            <span>Prices for {commodity.title.en} were updated</span>
          </Tooltip>
        ) : (
          "Prices for commodity were updated"
        );
      }
      default:
        return "Unknown entry";
    }
  };

  getFilteredEntries = () => {
    const { supplier, context } = this.props;
    const { supplierOrder } = context;
    const { search } = this.state;
    const timelineEntriesSO: Array<{
      order: SupplierOrder;
      timelineEntry: OrderTimeline;
      recent: boolean;
    }> = supplierOrder
      .filter((sO) => sO.supplier === supplier._id.toString())
      .map((sO) =>
        sO.timeline.map((t) => {
          return {
            order: sO,
            timelineEntry: t,
            recent: false,
          };
        })
      )
      .flat();
    const timelineEntriesSup = supplier.timeline?.map((entry) => {
      const extendedEntry = { ...entry } as ExtendedSupplierTimelineEntry;
      extendedEntry.text = this.getEntryDescription(extendedEntry);
      return extendedEntry;
    });
    const combinedEntries = [...timelineEntriesSO, ...(timelineEntriesSup ?? [])];
    const sortedEntries: (
      | { order: SupplierOrder; timelineEntry: OrderTimeline; recent: boolean }
      | ExtendedSupplierTimelineEntry
    )[] = _.orderBy(
      combinedEntries,
      function (
        entry: { order: SupplierOrder; timelineEntry: OrderTimeline; recent: boolean } | ExtendedSupplierTimelineEntry
      ) {
        if ("timelineEntry" in entry && entry.timelineEntry) {
          return entry.timelineEntry.date;
        } else if ("date" in entry) {
          return entry.date;
        } else {
          return null;
        }
      },
      "desc"
    );
    if (search.trim()) return doFuseSearch(sortedEntries, search, ["text", "type", "order.orderNo"]);
    return sortedEntries;
  };

  render() {
    const { context } = this.props;
    const { search } = this.state;
    const timelineEntries = this.getFilteredEntries();
    return (
      <div className="card bg-white">
        <div className="card-header border-0 mt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">History</span>
            <span className="text-muted fw-bold fs-7">{timelineEntries.length} Entries</span>
          </h3>
          <div className="card-toolbar">
            <Search onSearch={this.handleSearch} value={search} />
          </div>
        </div>
        <div className="card-body" style={{ overflowY: "auto", maxHeight: "70vh" }}>
          {timelineEntries.map((entry) =>
            "order" in entry ? (
              <OrderHistoryEntry
                key={entry.order._id.toString() + entry.timelineEntry._id.toString()}
                entry={entry}
                context={context}
                showOrder={true}
              />
            ) : (
              <HistoryEntry key={entry._id.toString()} entry={entry} context={context} />
            )
          )}
        </div>
      </div>
    );
  }
}

export default SupplierPageHistory;
