import React, { PureComponent } from "react";
import OrderBatchAccordion from "../../../../../common/OrderBatchAccordion";
import { DataContextInternalType } from "../../../../../../context/dataContext";
import { CO_CANCELED, CustomerOrderExtended } from "../../../../../../model/customerOrder.types";
import { SupplierOrder } from "../../../../../../model/supplierOrder.types";
import { getRelatedBatches } from "../../../../../../utils/supplierOrderUtils";
import { getPreviousSupplierOrder } from "../../../../../../utils/customerOrderUtils";

interface WorkflowGeneralInformationCardProps {
  order: CustomerOrderExtended;
  supplierOrder?: SupplierOrder;
  context: DataContextInternalType;
}

class WorkflowGeneralInformationCard extends PureComponent<WorkflowGeneralInformationCardProps> {
  render() {
    const { order, supplierOrder, context } = this.props;
    const previousSupplierOrder = getPreviousSupplierOrder(order, context);
    // use previousSupplierOrder if order was canceled and supplierOrder does not exist anymore to still display the information for reference
    const displayedSO = supplierOrder ?? previousSupplierOrder;
    const batches = getRelatedBatches(displayedSO, context);

    return (
      <div
        className="opacity-100-hover"
        style={{ opacity: batches.length > 0 || order.state === CO_CANCELED ? 0.3 : 1 }}
      >
        <div className="fw-bolder text-white fs-3 my-5">
          General Information{" "}
          {batches.length > 0 ? (
            <i className="h2 fas fa-check-circle text-success" />
          ) : order.state !== CO_CANCELED ? (
            <span className="text-warning">[Current Task]</span>
          ) : null}
        </div>
        {previousSupplierOrder && (
          <div className="text-white">
            Note: The supplier order is no longer linked to the customer order. This data is only displayed for
            completeness.
          </div>
        )}
        <div className="row py-2">
          <div className="bg-light2 rounded p-5 mb-7">
            <div className="row">
              <div className="col-12">
                <div className="fw-bolder text-white fs-5 my-2">Packages</div>
                <div className="row my-1">
                  <div className="col-12">
                    {batches && batches.length > 0 && <OrderBatchAccordion batches={batches} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WorkflowGeneralInformationCard;
