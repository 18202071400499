import React, { Component, FunctionComponent, PureComponent } from "react";
import { Switch } from "react-router-dom";
import { BSON } from "realm-web";
import ProtectedRoute from "../routes/ProtectedRoute";
import ScrollTop from "../../components/common/ScrollTop";
import { DataContextCustomer, getDataContextCustomer } from "../../context/dataContext";
import Aside from "../../components/layout/Aside";
import { listenerCustomer } from "../../services/dbService";
import { ActiveSubstance } from "../../model/activeSubstance.types";
import { Company } from "../../model/company.types";
import { Property } from "../../model/property.types";
import { Notification } from "../../model/notification.types";
import { Invoice } from "../../model/invoice.types";
import { Service } from "../../model/service.types";
import { UserData } from "../../model/userData.types";
import { CustomerCommodity } from "../../model/customer/customerCommodity.types";
import { CustomerCustomerOrder } from "../../model/customer/customerCustomerOrder.types";
import UserProfile from "../../components/userData/UserProfile";
import ChangePassword from "../../components/userData/ChangePassword";
import { Currencies, getCurrencies } from "../../utils/currencyUtils";
import SplashScreen from "../../components/common/SplashScreen";
import OrdersWrapper from "../../components/orders/customer/OrdersWrapper";
import Settings from "../../components/settings/customer/Settings";
import CompanySettingsWrapper from "../../components/customers/customer/CompanySettingsWrapper";
import OrderWrapper from "../../components/orders/customer/customerOrder/OrderWrapper";
import CommodityWrapper from "../../components/commodities/customer/CommodityWrapper";
import CustomerDashboard from "../../components/dashboard/customer/CustomerDashboard";
import { Favorites } from "../../model/favorites.types";
import CommodityListingWrapper from "../../components/commodities/common/CommodityListingWrapper";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import VersionCheck from "../../components/common/VersionCheck";
import { CUSTOMER } from "../../utils/userUtils";
import { VersionHistory } from "../../model/versionHistory.types";
import { CustomerSampleOrder } from "../../model/customer/customerSampleOrder.types";
import SampleOrderWrapper from "../../components/orders/customer/sampleOrder/SampleOrderWrapper";
import { Article } from "../../model/article.types";
import CustomerNewsListingWrapper from "../../components/news/customer/CustomerNewsListingWrapper";
import { DATA_TYPES, DATABASE_DOCUMENT } from "../../utils/dataContextUtils";
import { Watchlist } from "../../model/watchlist.types";
import { CustomerCustomerContract } from "../../model/customer/customerCustomerContract.types";
import CustomerContractListingWrapper from "../../components/customerContract/customer/CustomerContractListingWrapper";
import CustomerContractWrapper from "../../components/customerContract/customer/CustomerContractWrapper";
import { CustomerSupplier } from "../../model/customer/customerSupplier.types";
import { CustomerRequest } from "../../model/customerRequest.types";
import { RefMap } from "../../components/common/CustomTypes";
import CustomerRequestListingWrapper from "../../components/customerRequests/customer/CustomerRequestListingWrapper";
import NewsArticlePageWrapper from "../../components/news/customer/NewsArticlePageWrapper";
import { CustomerSupplierOrder } from "../../model/customer/customerSupplierOrder.types";
import { CustomerBatch } from "../../model/customer/customerBatch.types";
import { CustomerNotify } from "../../model/customer/customerNotify.types";
import Onboarding from "../../components/userData/customer/Onboarding";
import userService from "../../services/userService";
import { sendMessage, getDefaultSlackChannel } from "../../services/slackService";
import { SystemNotification } from "../../model/systemNotification.types";
import { CustomerFinishedProduct } from "../../model/customer/customerFinishedProduct.types";
import Privacy from "../../components/formality/Privacy";
import { PriceGraph } from "../../model/priceGraph.types";
import FinishedProductWrapper from "../../components/finishedProducts/customer/FinishedProductWrapper";
import CustomerNotificationConfigurationWrapper from "../../components/userData/customer/CustomerNotificationConfigurationWrapper";
import InvoiceListingWrapper from "../../components/finance/customer/InvoiceListingWrapper";

interface CustomerViewProps {}

interface CustomerViewState {
  loadingData: boolean;
  currencies: Currencies;
  collections: {
    activeSubstance: Array<ActiveSubstance>;
    batch: Array<CustomerBatch>;
    commodity: Array<CustomerCommodity>;
    company: Array<Company>;
    customerContract: Array<CustomerCustomerContract>;
    customerOrder: Array<CustomerCustomerOrder>;
    finishedProduct: Array<CustomerFinishedProduct>;
    priceGraph: Array<PriceGraph>;
    supplierOrder: Array<CustomerSupplierOrder>;
    customerRequest: Array<CustomerRequest>;
    sampleOrder: Array<CustomerSampleOrder>;
    property: Array<Property>;
    notification: Array<Notification>;
    invoice: Array<Invoice>;
    news: Array<Article>;
    service: Array<Service>;
    supplier: Array<CustomerSupplier>;
    userData: Array<UserData>;
    favorites: Array<Favorites>;
    notify: Array<CustomerNotify>;
    versionHistory: Array<VersionHistory>;
    watchlist: Array<Watchlist>;
    systemNotification: Array<SystemNotification>;
  };
  innerWidth: number;
  savedState: { [key: string]: { state: object; date: Date } };
  refMap: RefMap;
}

class CustomerView extends Component<CustomerViewProps, CustomerViewState> {
  _changeInProgress = false;

  constructor(props: CustomerViewProps) {
    super(props);
    this.state = {
      loadingData: true,
      currencies: {},
      collections: {
        activeSubstance: [],
        batch: [],
        commodity: [],
        company: [],
        customerContract: [],
        customerOrder: [],
        supplierOrder: [],
        customerRequest: [],
        finishedProduct: [],
        priceGraph: [],
        sampleOrder: [],
        property: [],
        notification: [],
        invoice: [],
        news: [],
        service: [],
        supplier: [],
        userData: [],
        favorites: [],
        notify: [],
        versionHistory: [],
        watchlist: [],
        systemNotification: [],
      },
      innerWidth: window.innerWidth,
      savedState: {},
      refMap: {},
    };
  }

  async componentDidMount() {
    const t = new Date().getTime();
    const data = await getDataContextCustomer();
    const currencies = await getCurrencies();
    // Saving loading time in local storage
    localStorage.setItem("t", (new Date().getTime() - t).toString());
    window.addEventListener("resize", this.handleResize);
    this.setState(
      {
        currencies: currencies.currencies ?? {},
        collections: {
          ...data,
        },
        loadingData: false,
      },
      () => listenerCustomer(this.updateDatabase)
    );
    this.getCurrencies(); // fire and forget
  }

  handleResize = () => {
    this.setState({ innerWidth: window.innerWidth });
  };

  getCurrencies = async () => {
    const currencies = await getCurrencies();
    if (currencies.currencies) this.setState({ currencies: currencies.currencies });
    setTimeout(() => this.getCurrencies(), 2 * 60 * 60 * 1000);
  };

  updateDatabase = async (change: Realm.Services.MongoDB.ChangeEvent<Realm.Services.MongoDB.Document>) => {
    // Ignore events we don't care about
    if (!("ns" in change) || !("coll" in change.ns) || !("documentKey" in change)) return;
    const collection = change.ns.coll as keyof typeof this.state.collections;
    const action = change.operationType;
    const documentKey = change.documentKey._id as BSON.ObjectId;
    if (action === "delete") {
      const { collections } = this.state;
      // @ts-ignore
      collections[collection] = collections[collection].filter(
        (item: DATABASE_DOCUMENT) => item && item._id.toString() !== documentKey.toString()
      ) as Array<DATABASE_DOCUMENT>;
      this.setState({
        collections,
      });
      return;
    }
    let indexNew = null;
    switch (action) {
      case "replace":
      case "update":
        try {
          let iterations = 0;
          while (this._changeInProgress) {
            if (iterations > 100) break; // Just risk it after 5s now - we can't wait too long
            await new Promise((resolve) => setTimeout(resolve, 50));
            iterations++;
          }
          this._changeInProgress = true;
          const collNew = { ...this.state.collections };
          const newCollection = collNew[collection].slice() as Array<DATABASE_DOCUMENT>;
          indexNew = newCollection.findIndex((d: DATABASE_DOCUMENT) => d._id.toString() === documentKey.toString());
          newCollection.splice(indexNew, 1, change.fullDocument as DATABASE_DOCUMENT);
          // @ts-ignore
          collNew[collection] = newCollection;
          this.setState({ collections: collNew });
        } finally {
          this._changeInProgress = false;
        }
        break;
      case "insert":
        try {
          while (this._changeInProgress) {
            await new Promise((resolve) => setTimeout(resolve, 50));
          }
          this._changeInProgress = true;
          const collNew = { ...this.state.collections };
          const collCopy = collNew[collection].slice() as Array<DATABASE_DOCUMENT>;
          collCopy.push(change.fullDocument as DATABASE_DOCUMENT);
          // @ts-ignore
          collNew[collection] = collCopy;
          this.setState({ collections: collNew });
        } finally {
          this._changeInProgress = false;
        }
        break;
      default: {
        const message = `CUSTOMER - Unhandled event received: ${action}`;
        sendMessage(
          getDefaultSlackChannel(true),
          message + `\n *Change Event:* \`\`\` ${JSON.stringify(change, null, 2)} \`\`\`\n`
        );
        break;
      }
    }
  };

  /**
   * Add the given documents to the context and the given collection. Only documents that were not already inside context are added.
   * @param collection Collection that should be updated
   * @param documents Documents that should be added to the context
   */
  addDocuments = (collection: string, documents: Array<DATA_TYPES>) => {
    const { collections } = this.state;

    // @ts-ignore
    if (collection in collections && collections[collection]) {
      // @ts-ignore
      const coll = collections[collection] as Array<DATA_TYPES>;
      let updated = false;
      const newDocuments = [];
      for (const doc of documents) {
        if (coll.some((d: DATA_TYPES) => d._id.toString() === doc._id.toString())) {
          newDocuments.push(doc);
          updated = true;
        }
      }
      coll.concat(newDocuments);
      if (updated) this.setState({ collections });
    }
  };

  /**
   * Save a components state
   * @param key key, i.e. class name
   * @param state the state to save
   */
  saveComponentState = (key: string, state: object) => {
    const savedState = { ...this.state.savedState };
    savedState[key] = { state, date: new Date() };
    this.setState({ savedState });
  };

  /**
   * Save a ref under the given key
   * @param key Key of the ref, e.g. a class name
   * @param ref Ref that should be saved (a component)
   */
  saveRef = (key: string, ref: PureComponent | FunctionComponent) => {
    this.setState((prevState) => {
      return { refMap: { ...prevState.refMap, [key]: ref } };
    });
  };

  /**
   * Deletes the given key from the ref map.
   * @param key Key that should be deleted
   */
  deleteRef = (key: string) => {
    const refMap = { ...this.state.refMap };
    delete refMap[key];
    this.setState({ refMap });
  };

  render() {
    const { loadingData, collections, currencies, innerWidth, savedState, refMap } = this.state;
    const context = {
      type: CUSTOMER,
      ...collections,
      currencies,
      addDocuments: this.addDocuments,
      savedState,
      refMap,
      innerWidth,
      saveComponentState: this.saveComponentState,
      saveRef: this.saveRef,
      deleteRef: this.deleteRef,
    };

    return (
      <DataContextCustomer.Provider value={context}>
        {loadingData ? (
          <SplashScreen />
        ) : (
          <>
            {!userService.getUserData().onboardingDone ? (
              <Onboarding user={userService.getUserData()} companies={collections.company} />
            ) : (
              <div className="d-flex flex-column flex-root">
                <div className="page d-flex flex-row flex-column-fluid">
                  <Aside view="customer" />

                  <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <Header context={context} />
                    <div className="mb-20 mt-10">
                      <Switch>
                        <ProtectedRoute exact path="/dashboard" component={CustomerDashboard} />
                        <ProtectedRoute exact path="/commodity/:id" component={CommodityWrapper} />
                        <ProtectedRoute exact path="/finishedProduct/:id" component={FinishedProductWrapper} />
                        <ProtectedRoute exact path="/articles" component={CommodityListingWrapper} />
                        <ProtectedRoute exact path="/articles/:id" component={CommodityListingWrapper} />
                        <ProtectedRoute exact path="/order/:id" component={OrderWrapper} />
                        <ProtectedRoute exact path="/contract/:id" component={CustomerContractWrapper} />
                        <ProtectedRoute exact path="/sampleOrder/:id" component={SampleOrderWrapper} />
                        <ProtectedRoute exact path="/orders" render={() => <OrdersWrapper orderType={"customer"} />} />
                        <ProtectedRoute exact path="/samples" render={() => <OrdersWrapper orderType={"sample"} />} />
                        <ProtectedRoute exact path="/contracts" component={CustomerContractListingWrapper} />
                        <ProtectedRoute exact path="/requests" component={CustomerRequestListingWrapper} />
                        <ProtectedRoute exact path="/invoices" component={InvoiceListingWrapper} />
                        <ProtectedRoute exact path="/news" component={CustomerNewsListingWrapper} />
                        <ProtectedRoute exact path="/article/:id" component={NewsArticlePageWrapper} />
                        <ProtectedRoute exact path="/settings" component={Settings} />
                        <ProtectedRoute exact path="/profile" component={UserProfile} />
                        <ProtectedRoute exact path="/company" component={CompanySettingsWrapper} />
                        <ProtectedRoute exact path="/changePassword" component={ChangePassword} />
                        <ProtectedRoute exact path="/privacy" component={Privacy} />
                        <ProtectedRoute
                          exact
                          path="/notificationSettings"
                          component={CustomerNotificationConfigurationWrapper}
                        />
                      </Switch>
                    </div>
                    <Footer versionHistory={collections.versionHistory} />
                    <ScrollTop />
                  </div>
                </div>
              </div>
            )}
            <VersionCheck view={CUSTOMER} />
          </>
        )}
      </DataContextCustomer.Provider>
    );
  }
}

export default CustomerView;
