import React, { PureComponent, useMemo } from "react";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, EditorState } from "draft-js";
import { DataContextInternal, DataContextInternalType } from "../../../context/dataContext";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { I_TYPES, I_CURRENCIES, I_PAYMENTTARGETS } from "../../../utils/invoiceUtils";
import { Company } from "../../../model/company.types";
import { Supplier } from "../../../model/supplier.types";
import { I_CREDIT_NOTE, I_INVOICE, Invoice, Position } from "../../../model/invoice.types";
import { isActive } from "../../../utils/customerOrderUtils";
import { EXTENDED_ORDER_TYPES, getOrderNumber } from "../../../utils/orderUtils";
import { CustomerOrder } from "../../../model/customerOrder.types";

interface InvoiceSettingsProps {
  generating: "" | "draft" | "invoice";
  type: SelectOption;
  company?: Company | Supplier;
  order?: EXTENDED_ORDER_TYPES;
  onCompanyChange: (company: SelectOption<Company>) => void;
  onOrderChange: (order: SelectOption<EXTENDED_ORDER_TYPES>) => void;
  currency: SelectOption;
  onTypeChange: (type: SelectOption) => void;
  onCurrencyChange: (currency: SelectOption) => void;
  onCreateDocument: (draft: boolean) => void;
  companyName: string;
  companyAddress: string;
  companyVAT: string;
  positions: Array<Position>;
  subtotal: number;
  invoice?: Invoice;
  paymentTarget: SelectOption;
  onPaymentTargetChange: (paymentTarget: SelectOption) => void;
  reverseCharge: boolean;
  onReverseChargeChange: () => void;
  nonEU: boolean;
  onNonEUChange: () => void;
  notes: EditorState;
  context: React.ContextType<typeof DataContextInternal>;
}

class InvoiceSettings extends PureComponent<InvoiceSettingsProps> {
  /**
   * Checks for all kinds of errors and disables the "create" button until all errors are fixed
   * @returns {Array<string>} list of errors
   */
  checkForErrors = () => {
    const { company, type, companyName, companyAddress, reverseCharge, nonEU, notes, companyVAT, positions } =
      this.props;
    const errors = [];

    if (!company) errors.push("No recipient selected");
    if (!companyName || !companyAddress) errors.push("Recipient address incomplete");

    if (positions.length === 0) errors.push("At least one position is required");

    for (let i = 0; i < positions.length; i++) {
      const p = positions[i];
      if (!p.title || !p.quantity || !p.unit) errors.push("Position " + (i + 1) + " incomplete");
    }

    if (type.value !== I_CREDIT_NOTE) {
      if ((reverseCharge || nonEU) && !companyVAT) errors.push("Reverse-charge: VAT required");

      if (reverseCharge && !nonEU && !draftToHtml(convertToRaw(notes.getCurrentContent())).match(/reverse/gi))
        errors.push("Reverse-charge: Note required"); // check if notes include the term "reverse" when reverse-charge is checked. This is to avoid that someone deletes the text block. A custom note is okay, but it has to contain the word "reverse" or "reverse-charge"

      if (!notes) {
        errors.push("Payment terms required");
        errors.push("Valid without signature note required");
      }
    }

    return errors;
  };

  render() {
    const {
      context,
      generating,
      type,
      company,
      order,
      onTypeChange,
      onCompanyChange,
      onOrderChange,
      currency,
      onCurrencyChange,
      onCreateDocument,
      paymentTarget,
      onPaymentTargetChange,
      reverseCharge,
      onReverseChargeChange,
      nonEU,
      onNonEUChange,
    } = this.props;

    const companyOrders = company
      ? context.customerOrder.filter((cO) => isActive(cO) && cO.company === company._id.toString())
      : [];

    return (
      <div className="flex-lg-auto min-w-lg-300px">
        <OverallSettings
          type={type}
          order={order}
          company={company}
          companyOrders={companyOrders}
          onTypeChange={onTypeChange}
          onCompanyChange={onCompanyChange}
          onOrderChange={onOrderChange}
          context={context}
        />
        <div className="card bg-white">
          <div className="card-body p-10">
            <div className="mb-5">
              <label className="fs-6 form-label fw-bolder text-dark">Currency</label>
              <CustomSelect
                options={I_CURRENCIES}
                value={currency}
                onChange={(e: SelectOption) => onCurrencyChange(e)}
              />
            </div>
            <DetailedSettings
              type={type}
              paymentTarget={paymentTarget}
              onPaymentTargetChange={onPaymentTargetChange}
              reverseCharge={reverseCharge}
              onReverseChargeChange={onReverseChargeChange}
              nonEU={nonEU}
              onNonEUChange={onNonEUChange}
            />
            <div className="separator mb-8" style={{ borderBottom: "3px solid #232323" }} />
            <div className="mb-0">
              <div className="row mb-0">
                <div className="col">
                  <ErrorOverlayButton
                    errors={this.checkForErrors()}
                    saving={generating === "draft"}
                    className="btn btn-light btn-active-light-primary w-100"
                    buttonText={generating === "draft" ? "Generating" : "Preview"}
                    onClick={() => onCreateDocument(true)}
                  />
                </div>
                <div className="col">
                  <ErrorOverlayButton
                    errors={this.checkForErrors()}
                    saving={generating === "invoice"}
                    className="btn btn-light btn-active-light-primary w-100"
                    buttonText={generating === "invoice" ? "Generating" : "Create"}
                    onClick={() => onCreateDocument(false)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

interface OverallSettingsProps {
  type: SelectOption;
  company?: Company | Supplier;
  order?: EXTENDED_ORDER_TYPES;
  companyOrders: Array<CustomerOrder>;
  onTypeChange: (type: SelectOption) => void;
  onCompanyChange: (company: SelectOption<Company>) => void;
  onOrderChange: (order: SelectOption<EXTENDED_ORDER_TYPES>) => void;
  context: DataContextInternalType;
}
const OverallSettings: React.FunctionComponent<OverallSettingsProps> = ({
  type,
  company,
  order,
  companyOrders,
  onTypeChange,
  onCompanyChange,
  onOrderChange,
  context,
}) => {
  const typeOptions = useMemo(() => {
    return I_TYPES.filter((iType) => iType.value === I_INVOICE || iType.value === I_CREDIT_NOTE);
  }, []);
  const isSelectionEnabled = type.value === I_INVOICE || type.value === I_CREDIT_NOTE;
  return (
    <div className="card bg-white mb-10">
      <div className="card-body p-10 ">
        <div className="mb-5">
          <label className="fs-6 form-label fw-bolder text-dark">Type</label>
          <CustomSelect
            options={typeOptions}
            value={type}
            isClearable={false}
            onChange={onTypeChange}
            disabled={!isSelectionEnabled}
          />
        </div>
        <div className="mb-5">
          <label className="fs-6 form-label fw-bolder text-dark">Company</label>
          <CustomSelect
            options={context.company.map((c: Company) => {
              return { value: c._id.toString(), label: c.name, object: c };
            })}
            value={
              company
                ? {
                    value: company._id.toString(),
                    label: company.name,
                    object: company,
                  }
                : undefined
            }
            placeholder={"Select company"}
            isClearable={true}
            disabled={!isSelectionEnabled}
            onChange={(e: SelectOption<Company>) => onCompanyChange(e)}
          />
        </div>
        <div className="mb-5">
          <label className="form-label fw-bolder fs-6 text-gray-700">Order</label>
          <CustomSelect
            options={companyOrders.map((cO) => {
              return { value: cO._id.toString(), label: getOrderNumber(cO), object: cO };
            })}
            value={order ? { value: order._id.toString(), label: getOrderNumber(order), object: order } : undefined}
            placeholder={"Optional Order"}
            disabled={!company || !isSelectionEnabled}
            onChange={(e: { value: string; label: string; object: EXTENDED_ORDER_TYPES }) => onOrderChange(e)}
          />
        </div>
      </div>
    </div>
  );
};

interface DetailedSettingsProps {
  type: SelectOption;
  invoice?: Invoice;
  paymentTarget: SelectOption;
  onPaymentTargetChange: (paymentTarget: SelectOption) => void;
  reverseCharge: boolean;
  onReverseChargeChange: () => void;
  nonEU: boolean;
  onNonEUChange: () => void;
}

const DetailedSettings: React.FunctionComponent<DetailedSettingsProps> = ({
  type,
  invoice,
  paymentTarget,
  onPaymentTargetChange,
  reverseCharge,
  onReverseChargeChange,
  nonEU,
  onNonEUChange,
}) => {
  if (type.value === I_CREDIT_NOTE) return <></>;
  return (
    <>
      <div className="mb-5">
        <label className="fs-6 form-label fw-bolder text-dark">Payment Target</label>
        <CustomSelect
          options={I_PAYMENTTARGETS}
          value={paymentTarget}
          onChange={(e: SelectOption) => onPaymentTargetChange(e)}
          disabled={!!invoice}
        />
      </div>
      <div className="separator mb-8" style={{ borderBottom: "3px solid #232323" }} />
      <div className="mb-4">
        <div className="form-check form-switch form-check-custom form-check-solid mt-2">
          <input
            className="form-check-input position-static"
            checked={reverseCharge}
            onChange={onReverseChargeChange}
            type="checkbox"
          />
          <label className="form-check-label text-muted">Reverse-Charge</label>
        </div>
      </div>
      <div className="mb-8">
        <div className="form-check form-switch form-check-custom form-check-solid mt-2">
          <input
            className="form-check-input position-static"
            checked={nonEU}
            onChange={onNonEUChange}
            type="checkbox"
          />
          <label className="form-check-label text-muted">Non-EU country</label>
        </div>
      </div>
    </>
  );
};

export default InvoiceSettings;
