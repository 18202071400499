import { Invoice, Position, VATPosition } from "../../model/invoice.types";
import { formatCurrency, formatDate } from "../baseUtils";
import {
  extendInvoice,
  getSubtotalWithoutDiscount,
  getTotalOpenSumToPay,
  I_P_DUNNING,
  I_P_PAYMENT,
} from "../invoiceUtils";
import { getOrderNumber, isCustomerOrder } from "../orderUtils";
import { ORGANICCODE } from "./templateUtils";
import { DataContextInternalType } from "../../context/dataContext";

/**
 * Automatically creates an invoice title from positions with max. length of 78 chars
 * @param positions list of invoice positions
 * @returns {string} title for invoice
 */
function getInvoiceTitle(positions: Array<Position>): string {
  let title = "";
  for (let i = 0; i < positions.length; i++) {
    if ([I_P_DUNNING, I_P_PAYMENT].includes(positions[i].unit)) continue;
    title += positions[i].title + (i !== positions.length - 1 ? ", " : "");
  }
  if (title.length > 75) {
    title = title.substring(0, 75) + "...";
  }
  return title;
}

/**
 * Creates the Invoice-HTML
 * @param draft a boolean value if a draft should be created
 * @param invoice an invoice object
 * @param VAT an array of VATPosition objects to list on the bottom of the invoice
 * @param companyName a string that describes the name of the company
 * @param companyAddress a string that describes the address of the company
 * @param companyVAT a string that describes the VAT of the company
 * @param deliveryDate the date of the deliveryDate input field
 * @param invoiceDate the date of the invoiceDate input field
 * @param notes the notes below the positions, as string with html tags
 * @param reminder Text for reminder
 * @param cancelation Text for cancelation
 * @param reverseCharge boolean, if reverse-charge is applied
 * @param nonEU boolean, if nonEU is applied
 * @param context Data context - needed to resolve data
 * @param isCreditNote optional boolean, if invoice generation should be considered as credit note
 * @param creditNoteInvoiceText optional Text for credit Notes to replace with regular notes
 * @returns {string} a html string that can be used to create the PDF
 */
export function createInvoiceHTML(
  draft: boolean,
  invoice: Invoice,
  VAT: Array<VATPosition>,
  companyName: string,
  companyAddress: string,
  companyVAT: string,
  deliveryDate: Date,
  invoiceDate: Date,
  notes: string,
  reminder: string,
  cancelation: string,
  reverseCharge: boolean,
  nonEU: boolean,
  context: DataContextInternalType,
  isCreditNote?: boolean,
  creditNoteInvoiceText?: string
): string {
  const total = getTotalOpenSumToPay(invoice, false);
  const paymentsAmount = invoice.payments.reduce((sum, p) => sum - p.amount, 0);
  const dunningAmount = invoice.reminders.reduce((sum, r) => sum + r.dunningFee, 0);
  const invoiceNumber =
    (!isCreditNote && invoice.paymentTarget === -1 ? "A" : "") + (isCreditNote ? "C-" : "") + invoice.invoiceNumber;
  const subtotalWithoutDiscount = getSubtotalWithoutDiscount(invoice);
  const extendedInvoice = extendInvoice(invoice, context);

  let html =
    // Header
    `<head><link href='https://fonts.googleapis.com/css?family=Signika&display=swap' rel='stylesheet'><style>tr:nth-child(even) {background-color: white;}</style><meta http-equiv='content-type' content='text/html; charset=utf-8'></head>` +
    `<body style='font-family: Signika'><img src='https://rawbids.com/images/logo-dark.png' alt="Rawbids Logo" width='180' style='float:right'/>` +
    `<br/><br/><br/><br/><span style='font-size: 30px; font-weight: bold'>${
      (reminder ? "Reminder for " : cancelation ? "Cancelation for " : "") +
      (draft
        ? "DRAFT"
        : (!isCreditNote && invoice.paymentTarget === -1
            ? "Prepayment Invoice Nr. "
            : isCreditNote
            ? "Credit Note Nr. "
            : "Invoice Nr. ") + invoiceNumber)
    }` +
    `</span><br/><span style='font-size: 20px; font-weight: bold'>${getInvoiceTitle(
      invoice.positions
    )}</span><br/><br/><br/>` +
    `<table cellpadding='5' cellspacing='0' style='width: 100%'><tbody>` +
    `<tr style='width: 100%; vertical-align: top'>` +
    `<td style='vertical-align: top'><span style='font-size:16px;  width: 33%; vertical-align: top'>Recipient:<br/><b>${companyName}</b><br/>${companyAddress.replace(
      /\n/g,
      "<br/>"
    )}<br/>${companyVAT ? "VAT-ID: " + companyVAT : ""}</span>
    </td>` +
    `<td style='vertical-align: top'><span style='font-size:16px; width: 33%; vertical-align: top'>Issued by:<br/><b>RAWBIDS GmbH</b><br/>Willy-Brandt-Str. 23, DE - 20457 Hamburg<br/>Germany<br/>VAT-ID: DE354508196</span></td>` +
    `<td style='text-align: right; vertical-align: top'><span style='font-size:16px; width: 33%; vertical-align: top'><b>${
      isCreditNote ? "Credit Note number:" : "Invoice number:"
    } ${draft ? "DRAFT" : invoiceNumber}</b><br/>
    ${extendedInvoice.relatedOrder ? `<b>Order number: ${getOrderNumber(extendedInvoice.relatedOrder)}</b><br/>` : ""}
    ${
      (extendedInvoice.relatedOrder &&
        isCustomerOrder(extendedInvoice.relatedOrder) &&
        extendedInvoice.relatedOrder.customerReference &&
        `<span>Reference number: ${extendedInvoice.relatedOrder.customerReference}</span><br/>`) ||
      ""
    }
    ${extendedInvoice.relatedOrder?.commodity.organic ? `${ORGANICCODE}<br/>` : ""}
    ${isCreditNote ? "Credit Note" : "Invoice"} date: ${invoiceDate.toLocaleDateString()}`;

  if (!isCreditNote) {
    html += `<br/>Delivery date: ${deliveryDate.toLocaleDateString()}`;
  }

  html += `<br/><br/>Contact: info@rawbids.com</span></td></tr></tbody></table><br/>`;
  if (reminder)
    html += `<table style='font-size:16px; width: 100%; display: block; margin-top:20px; margin-bottom:20px'><tbody><tr><td>${reminder}</td></tr></tbody></table>`;
  if (cancelation)
    html += `<table style='font-size:16px; width: 100%; display: block; margin-top:20px; margin-bottom:20px'><tbody><tr><td>${cancelation}</td></tr></tbody></table>`;
  if (creditNoteInvoiceText)
    html += `<table style='font-size:16px; width: 100%; display: block; margin-top:20px; margin-bottom:20px'><tbody><tr><td>${creditNoteInvoiceText}</td></tr></tbody></table>`;
  html +=
    `<span style='font-size:22px; font-weight: bold;'>Positions:</span><br/>` +
    `<table cellpadding='5' cellspacing='0' style='font-size:15px; width:100%; background-color:#fafafa; margin-top:20px' ><thead><tr style='background-color:#333333; color: #ffffff'><th><b>Pos.</b></th>` +
    `<th style='min-width: 250px'><b style='white-space:nowrap'>Description</b></th><th><b>Amount</b></th><th><b style='white-space:nowrap;'>Unit</b></th><th><b style='white-space:nowrap;'>Per unit</b></th>` +
    `<th><b style='white-space:nowrap;'>VAT</b></th><th><b style='white-space:nowrap;'>Discount</b></th><th><b style='white-space:nowrap; float: right'>Total</b></th></tr></thead><tbody>`;
  // Positions
  for (let i = 0; i < invoice.positions.length; i++) {
    const p = invoice.positions[i];
    html +=
      `<tr><td>&nbsp;&nbsp;${i + 1}</td>` +
      `<td style='width: 45%'><b>${p.title}</b><br/>${p.description.replace(/\n/g, "<br/>")}</td>` +
      `<td>${p.quantity}</td>` +
      `<td>${p.unit}</td>` +
      `<td>${formatCurrency(+p.price, invoice.currency)}</td>` +
      `<td>${!reverseCharge && !nonEU ? p.vatPercentage : 0}%</td>` +
      `<td>${p.discount ? p.discount + "%" : ""}</td>` +
      `<td style='float: right; text-align: right'>${formatCurrency(p.quantity * p.price, invoice.currency)}</td></tr>`;
  }
  html +=
    `</tbody></table><br/>` +
    `<table style='width: 50%; float: right; display: block'><tbody>` +
    `<tr><td>Subtotal (net)</td><td style='float: right; text-align: right'><span style='float: right; text-align: right'><b>${formatCurrency(
      subtotalWithoutDiscount,
      invoice.currency
    )}</b></span></td></tr>`;
  if (invoice.positions.some((p) => Boolean(p.discount))) {
    html += `<tr><td>Discount</td><td style='float: right; text-align: right'><span style='float: right; text-align: right'><b>${formatCurrency(
      invoice.subtotal - subtotalWithoutDiscount,
      invoice.currency
    )}</b></span></td></tr>`;
    html += `<tr><td>Subtotal after Discount</td><td style='float: right; text-align: right'><span style='float: right; text-align: right'><b>${formatCurrency(
      invoice.subtotal,
      invoice.currency
    )}</b></span></td></tr>`;
  }
  // VAT
  if (!reverseCharge && !nonEU) {
    for (let i = 0; i < VAT.length; i++) {
      html +=
        `<tr><td>VAT ${VAT[i].percentage}% (of ${formatCurrency(VAT[i].netValue, invoice.currency)})</td>` +
        `<td style='float: right; text-align: right'><span style='float: right; text-align: right'><b>${formatCurrency(
          VAT[i].vatAmount,
          invoice.currency
        )}</b></span></td></tr>`;
    }
  } else {
    html +=
      `<tr><td>VAT 0% (of ${formatCurrency(VAT[0].netValue, invoice.currency)})</td>` +
      `<td style='float: right; text-align: right'><span style='float: right; text-align: right'><b>${formatCurrency(
        VAT[0].vatAmount,
        invoice.currency
      )}</b></span></td></tr>`;
  }
  if (paymentsAmount) {
    html +=
      `<tr><td>Payments</td><td style='float: right; text-align: right'>` +
      `<span style='float: right; text-align: right'><b>${formatCurrency(
        paymentsAmount,
        invoice.currency
      )}</b></span></td></tr>`;
  }
  if (dunningAmount) {
    html +=
      `<tr><td>Dunning Fees</td><td style='float: right; text-align: right'>` +
      `<span style='float: right; text-align: right'><b>${formatCurrency(
        dunningAmount,
        invoice.currency
      )}</b></span></td></tr>`;
  }
  html += `<tr><td>Total</td><td style='float: right; text-align: right'><span style='float: right; text-align: right'><b style='font-size: 20px'>${formatCurrency(
    total,
    invoice.currency
  )}</b></span></td></tr></tbody></table><br/><br/><br/><br/><br/><br/>`;
  html += `<table style='font-size:16px; width: 100%; display: block'><tbody>`;

  if (
    !isCreditNote &&
    extendedInvoice.relatedOrder &&
    isCustomerOrder(extendedInvoice.relatedOrder) &&
    extendedInvoice.relatedOrder.terms
  ) {
    html += `<tr><td><span style='font-weight: bold;'>Terms of delivery: </span>${
      extendedInvoice.relatedOrder.terms.deliveryTerms
    } ${extendedInvoice.relatedOrder.terms.deliveryCity || ""}<br/></td></tr>`;
  }
  html += `<tr><td><span style='font-size:22px; font-weight: bold;'>Notes:</span><br/><br/>${notes}</td></tr>`;
  html += `</tbody></table>`;
  html += "</body>";

  return html;
}

/**
 * The standard footer text, including IBAN and valid without signature note. Needs to be combined with the payment term sentence.
 */
export const standardText =
  " delivery to our account <b>DE92 4226 0001 0152 9336 00 (Volksbank Ruhr Mitte, GENODEM1GBU)</b><br/>" +
  "We thank you for your order and the trust you have placed in us.<br/><br/>With kind regards and best wishes<br/>" +
  "<b>RAWBIDS GmbH</b><br/><br/>" +
  "<b><i>This document is valid without signature.</i></b>";

export const standardTextShort =
  "With kind regards and best wishes<br/>" +
  "<b>RAWBIDS GmbH</b><br/><br/>" +
  "<b><i>This document is valid without signature.</i></b>";

/**
 * Note that's required if reverse-charge applies.
 */
export const reverseChargeText =
  "<p>Reverse-charge procedure - tax liability of the recipient of the service.<br>The invoice is issued without VAT, as the reverse-charge procedure applies.<br>The recipient of the service must declare and pay the VAT.</p>";
/**
 * Regex for "getNote()" to find and replace reverseChargeText.
 */
export const reverseChargeRegex =
  /<p>Reverse-charge procedure - tax liability of the recipient of the service.<br>The invoice is issued without VAT, as the reverse-charge procedure applies.<br>The recipient of the service must declare and pay the VAT.<\/p>/gm;
/**
 * Note that's required if nonEU applies
 */
export const nonEUText =
  "<p>Tax liability of the recipient of the service.<br>The invoice is issued without VAT.<br>The recipient of the service must declare and pay the VAT.</p>";
/**
 * Regex for "getNote()" to find and replace nonEUText.
 */
export const nonEURegex =
  /<p>Tax liability of the recipient of the service.<br>The invoice is issued without VAT.<br>The recipient of the service must declare and pay the VAT.<\/p>/gm;

export const reminderText = (invoiceNo: string, invoiceDate: Date) => {
  return `We would like to kindly remind you that the invoice <b>INV-${invoiceNo}</b> from <b>${formatDate(
    invoiceDate
  )}</b> has not been paid yet.<br /><br />Payments made by <b>${formatDate(
    new Date()
  )}</b> have been taken into account. If you have already made a payment in the meantime, please consider this letter as irrelevant.`;
};

export const cancelationText = (invoiceNo: string) => {
  return `Below you will find the cancellation invoice for <b>INV-${invoiceNo}:</b>`;
};

export const creditNoteText = () => {
  return `Below you will find the credit note for your last order. Please check the details and let us know if you have any questions or require further assistance. We appreciate your trust and look forward to continuing to provide you with the best possible service.`;
};
