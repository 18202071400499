import React, { useCallback, useMemo } from "react";
import { formatCurrency, round } from "../../../../utils/baseUtils";
import { EURO } from "../../../../utils/currencyUtils";
import { ADJUSTABLE_CALCULATION_VALUES } from "../../../../utils/supplierOrderUtils";
import { SupplierOrderExtended } from "../../../../model/supplierOrder.types";

interface CostTileProps {
  value: number;
  order: SupplierOrderExtended;
  field: ADJUSTABLE_CALCULATION_VALUES;
  adjustedValue?: number;
  visualizeDelta?: boolean;
  onClick: (order: SupplierOrderExtended, field: ADJUSTABLE_CALCULATION_VALUES) => void;
}

const BASE_WHITE = "0, 0, 0";
const BASE_GREEN = "66, 220, 130";
const BASE_RED = "255, 38, 90";

const CostTile: React.FC<CostTileProps> = ({ value, order, field, adjustedValue, visualizeDelta, onClick }) => {
  const backgroundColor = useMemo(() => {
    if (!visualizeDelta || adjustedValue === undefined) {
      return "rgba(0,0,0,0)";
    } else {
      const delta = round(1 - value / adjustedValue, 2);
      const isExtreme = Math.abs(delta) > 0.2;
      const opacity = isExtreme ? 0.6 : 0.3;
      const baseColor = delta > 0 ? BASE_RED : delta < 0 ? BASE_GREEN : BASE_WHITE;
      return `rgba(${baseColor}, ${opacity})`;
    }
  }, [value, visualizeDelta, adjustedValue]);

  const handleClick = useCallback(() => {
    onClick(order, field);
  }, [order, field]);

  return (
    <td className="tile" onClick={handleClick} style={visualizeDelta ? { backgroundColor } : undefined}>
      <span style={{ opacity: adjustedValue !== undefined ? 1 : 0.5 }}>
        {formatCurrency(adjustedValue ?? value, EURO)}
      </span>
    </td>
  );
};

export default CostTile;
