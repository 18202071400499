import _ from "lodash";
import { CloseButton, Modal } from "react-bootstrap";
import Selecto from "react-selecto";
import React, { PureComponent, useState } from "react";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import {
  CO_ORDEREDBYCUSTOMER,
  CO_PROCESSINGATWAREHOUSE,
  CO_T_COMMODITIESBOOKED,
  T_WAREHOUSE,
  CustomerOrderTimelineEntry,
  UsedBatch,
  UsedBatchPackage,
  CustomerOrderExtended,
} from "../../../../../../../model/customerOrder.types";
import { B_RELEASED, Batch, BatchPackage } from "../../../../../../../model/batch.types";
import { DataContextInternalType } from "../../../../../../../context/dataContext";
import {
  B_PACKAGE_DICT,
  B_WAREHOUSE_OPTIONS,
  bookUsedBatches,
  getBatchAmount,
  getBatchBookOutTimelineEntry,
  getDefaultUsedBatch,
  getDefaultUsedBatches,
  getSelectUsedBatchPackage,
} from "../../../../../../../utils/batchUtils";
import CustomSelect, { SelectOption } from "../../../../../../common/CustomSelect";
import ErrorOverlayButton from "../../../../../../common/ErrorOverlayButton";
import BaseListing from "../../../../../../common/BaseListing";
import { Input } from "../../../../../../common/Input";
import userService from "../../../../../../../services/userService";
import { getOrderNumber } from "../../../../../../../utils/orderUtils";
import Tooltip from "../../../../../../common/Tooltip";
import OrderBatchStateDropdown from "../../../../../../common/internal/OrderBatchStateDropdown";
import { getDocFromCollection } from "../../../../../../../utils/baseUtils";
import { updateCustomerOrder } from "../../../../../../../utils/customerOrderUtils";
import { formatArticleUnit } from "../../../../../../../utils/productArticleUtils";

interface UsedBatchesModalProps {
  order: CustomerOrderExtended;
  batches?: Array<Batch>;
  context: DataContextInternalType;
  disabled?: boolean;
}

interface UsedBatchesModalState {
  show: boolean;
  saving: boolean;
  continueSelectWithoutDeselect: boolean;
  step: number;
  selectedBatches: Array<Batch>;
  usedBatches: Array<UsedBatch>;
  selectedBatch?: SelectOption<Batch> | null;
  itemsPerRow?: SelectOption;
}

const CSS_TO_BE_SELECTED = "selecto-to-be-selected";
const CSS_TO_BE_REMOVED = "selecto-to-be-removed";
const ITEMS_PER_ROW = [
  { value: "col-1", label: "12" },
  { value: "col-2", label: "6" },
  { value: "col-3", label: "4" },
  { value: "col-4", label: "3" },
  { value: "col-6", label: "2" },
] as Array<SelectOption>;

class UsedBatchesModal extends PureComponent<UsedBatchesModalProps, UsedBatchesModalState> {
  constructor(props: UsedBatchesModalProps) {
    super(props);
    this.state = this.getDefaultState(props);
  }

  componentDidUpdate(prevProps: Readonly<UsedBatchesModalProps>) {
    const { order, context } = this.props;
    const { selectedBatches, usedBatches } = this.state;
    if (!_.isEqual(prevProps.order, order)) this.setState(this.getDefaultState(this.props));
    else if (!_.isEqual(prevProps.context.batch, context.batch)) {
      const newSelectedBatches = selectedBatches
        .map((b) => getDocFromCollection(context.batch, b._id))
        .filter((b) => !!b) as Array<Batch>;
      // if state changed due to releasing batch in this process also change it in usedBatches
      for (let i = 0; i < newSelectedBatches.length; i++) {
        const usedBatch = usedBatches.find((uB) => uB.batchId === newSelectedBatches[i]._id.toString());
        if (usedBatch) usedBatch.state = newSelectedBatches[i].state;
      }
      if (!_.isEqual(selectedBatches, newSelectedBatches))
        this.setState({ selectedBatches: newSelectedBatches, usedBatches });
    }
  }

  handleShow = () => this.setState(this.getDefaultState(this.props, true));
  handleHide = () => !this.state.saving && this.setState({ show: false });

  handleSelectPackage = (batchId?: string, packageId?: string, customAmount?: number) => {
    if (!batchId || !packageId) return;
    const { selectedBatches } = this.state;
    const usedBatches = _.cloneDeep(this.state.usedBatches);
    const batch = selectedBatches.find((b) => b._id.toString() === batchId);
    if (!batch) return;
    const p = batch.packages.find((p) => p._id.toString() === packageId);
    if (!p) return;
    const usedBatch = usedBatches.find((b) => b.batchId === batchId);
    if (!usedBatch) return;
    // Check if already selected, seems to appear rarely with selecto
    if (usedBatch.packages.some((p) => p.packageId === packageId)) return;
    usedBatch.packages.push(getSelectUsedBatchPackage(p, customAmount));
    usedBatch.totalAmountUsed = usedBatch.packages.reduce((a, b) => a + b.amountUsed, 0);
    this.setState({ usedBatches });
  };

  handleSelectAllPackages = (batchId: string) => {
    const { selectedBatches } = this.state;
    const usedBatches = _.cloneDeep(this.state.usedBatches);
    const batch = selectedBatches.find((b) => b._id.toString() === batchId);
    if (!batch) return;
    const usedBatch = usedBatches.find((b) => b.batchId === batchId);
    if (!usedBatch) return;
    usedBatch.packages = batch.packages.filter((pkg) => pkg.amountEach > 0).map((p) => getSelectUsedBatchPackage(p));
    usedBatch.totalAmountUsed = usedBatch.packages.reduce((a, b) => a + b.amountUsed, 0);
    this.setState({ usedBatches });
  };

  handleDeselectPackage = (batchId?: string, packageId?: string) => {
    if (!batchId || !packageId) return;
    const usedBatches = _.cloneDeep(this.state.usedBatches);
    const usedBatch = usedBatches.find((b) => b.batchId === batchId);
    if (!usedBatch) return;
    usedBatch.packages = usedBatch.packages.filter((p) => p.packageId !== packageId);
    usedBatch.totalAmountUsed = usedBatch.packages.reduce((a, b) => a + b.amountUsed, 0);
    this.setState({ usedBatches });
  };

  handleEditSelectedPackage = (batchId: string, packageId: string, customAmount: number) => {
    if (!batchId || !packageId) return;
    const usedBatches = _.cloneDeep(this.state.usedBatches);
    const usedBatch = usedBatches.find((b) => b.batchId === batchId);
    if (!usedBatch) return;
    const usedPackage = usedBatch.packages.find((p) => p.packageId === packageId);
    if (!usedPackage) return;
    usedPackage.amountUsed = customAmount;
    usedBatch.totalAmountUsed = usedBatch.packages.reduce((a, b) => a + b.amountUsed, 0);
    this.setState({ usedBatches });
  };

  handleAddUsedBatch = () => {
    const { selectedBatch, usedBatches, selectedBatches } = this.state;
    if (!selectedBatch?.object) return;
    this.setState({
      selectedBatch: null,
      usedBatches: usedBatches.concat(getDefaultUsedBatch(selectedBatch.object)),
      selectedBatches: selectedBatches.concat(selectedBatch.object),
    });
  };

  handleNext = () => {
    const { step } = this.state;
    if (step < 1) this.setState({ step: step + 1 });
  };

  handleBack = () => {
    const { step } = this.state;
    if (step > 0) this.setState({ step: step - 1 });
  };

  handleConfirmUsedBatches = async () => {
    const { order } = this.props;
    const { selectedBatches, usedBatches } = this.state;
    const batchesToUpdate = usedBatches.filter((uB) => uB.packages.length > 0);
    this.setState({ saving: true });
    try {
      const orderTimelineEntry: CustomerOrderTimelineEntry = {
        _id: new BSON.ObjectId(),
        date: new Date(),
        type: CO_T_COMMODITIESBOOKED,
        person: userService.getUserId(),
        payload: null,
      };
      const batchTimelineEntries = getBatchBookOutTimelineEntry(selectedBatches, batchesToUpdate);
      const res = await bookUsedBatches(order._id.toString(), usedBatches, orderTimelineEntry, batchTimelineEntries);
      if (res) {
        toast.success("Batches successfully booked out");
        this.setState({ show: false });
        if (order.transport === T_WAREHOUSE && order.state === CO_ORDEREDBYCUSTOMER)
          await updateCustomerOrder({ state: CO_PROCESSINGATWAREHOUSE }, order._id);
      } else {
        toast.error("Batches could not be booked out");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  getDefaultState = (props: UsedBatchesModalProps, show?: boolean) => {
    const { order } = props;
    if (order.usedBatches)
      return {
        show: !!show,
        saving: false,
        continueSelectWithoutDeselect: false,
        usedBatches: order.usedBatches,
        selectedBatches: [],
        step: 1,
      };
    return {
      show: !!show,
      saving: false,
      continueSelectWithoutDeselect: false,
      usedBatches: getDefaultUsedBatches(props.batches),
      selectedBatches: props.batches || [],
      step: 0,
    };
  };

  getSelectableBatches = () => {
    const { order, context } = this.props;
    const { selectedBatches } = this.state;
    return context.batch.filter(
      (b) =>
        !b.disabled &&
        b.commodity._id.toString() === order.commodity._id.toString() &&
        getBatchAmount(b) > 0 &&
        !selectedBatches.some((sB) => sB._id.toString() === b._id.toString())
    );
  };

  isSelected = (batchId?: string, packageId?: string) =>
    this.state.usedBatches.some((b) => b.batchId === batchId && b.packages.some((p) => p.packageId === packageId));

  validateData = () => {
    const { order } = this.props;
    const { usedBatches } = this.state;
    const totalAmount = usedBatches.reduce((a, b) => a + b.totalAmountUsed, 0);
    const unit = usedBatches[0]?.unit || order.unit;

    const errors = [];
    const warnings = [];
    if (totalAmount <= 0) errors.push("No used batches selected");
    if (!usedBatches.every((b) => b.unit === unit)) errors.push("Batches with different units used");
    if (totalAmount !== order.amount && unit === order.unit)
      warnings.push(
        `Selected amount ${totalAmount}${formatArticleUnit(unit)} differs from ordered amount ${
          order.amount
        }${formatArticleUnit(order.unit)}`
      );

    return [errors, warnings];
  };

  render() {
    const { order, disabled } = this.props;
    const {
      show,
      saving,
      usedBatches,
      continueSelectWithoutDeselect,
      selectedBatch,
      selectedBatches,
      step,
      itemsPerRow,
    } = this.state;
    const selectableBatches = this.getSelectableBatches();
    const [errors, warnings] = this.validateData();
    return (
      <>
        <span className="fw-bolder text-white">
          Commissioning:{" "}
          <Tooltip tooltipText="Book or view used batches">
            <span
              className={
                ((saving || disabled) && "disabled") +
                (order.usedBatches ? " text-success text-success-hover" : " text-danger text-danger-hover")
              }
              onClick={saving || disabled ? undefined : this.handleShow}
            >
              {order.usedBatches ? "Done" : "Pending"}
            </span>
          </Tooltip>
        </span>
        <Modal
          contentClassName="bg-dark"
          show={show}
          backdrop={"static"}
          fullscreen={true}
          onHide={this.handleHide}
          centered
        >
          <Modal.Header className="">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">
                Used Batches for order {getOrderNumber(order)}
              </h1>
            </Modal.Title>
            <CloseButton variant={"white"} onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            {/* d-none here to not 'reset' selecto component when going to another step, overview is handled as usual */}
            <div className={step !== 0 ? "d-none" : ""}>
              <div className="mb-15">
                <div className="float-right form-check form-switch form-check-custom form-check-solid mt-2">
                  <label
                    className={
                      "form-check-label fs-7 fw-bold mr-2 text-white " +
                      (!continueSelectWithoutDeselect && "text-muted")
                    }
                  >
                    Select Only
                  </label>
                  <input
                    className="form-check-input position-static"
                    checked={!continueSelectWithoutDeselect}
                    onChange={() => this.setState({ continueSelectWithoutDeselect: !continueSelectWithoutDeselect })}
                    type="checkbox"
                  />
                  <label
                    className={
                      "form-check-label fs-7 fw-bold text-white " + (continueSelectWithoutDeselect && "text-muted")
                    }
                  >
                    Allow Deselect
                  </label>
                </div>
                <div className="mt-2">
                  <div className="row">
                    <div className="col-auto my-auto">
                      <label className=" fs-7 fw-bold mr-2 text-white my-auto">Items Per Row</label>
                    </div>
                    <div className="col-lg-1 col-md-2 col-4">
                      <CustomSelect
                        options={ITEMS_PER_ROW}
                        placeholder={"Automatic"}
                        value={itemsPerRow}
                        onChange={(e: SelectOption) => this.setState({ itemsPerRow: e })}
                        isClearable={true}
                        matchFormControl={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {selectedBatches &&
                selectedBatches.map((b) => (
                  <UsedBatchesPackageSelection
                    key={b._id.toString()}
                    batch={b}
                    usedBatches={usedBatches}
                    continueSelectWithoutDeselect={continueSelectWithoutDeselect}
                    itemsPerRow={itemsPerRow}
                    isSelected={this.isSelected}
                    onSelectPackage={this.handleSelectPackage}
                    onSelectAll={this.handleSelectAllPackages}
                    onDeselectPackage={this.handleDeselectPackage}
                    onEditSelectedPackage={this.handleEditSelectedPackage}
                  />
                ))}
              <div className="row my-5 mx-lg-10 mx-xl-20 px-20">
                <div className="col-auto">
                  <div style={{ minWidth: "200px" }}>
                    <CustomSelect
                      options={selectableBatches.map((sB) => {
                        return { value: sB._id.toString(), label: `RB${sB.identifier}`, object: sB };
                      })}
                      value={selectedBatch}
                      matchFormControl={true}
                      isClearable={true}
                      placeholder={"Select Batch..."}
                      onChange={(e: SelectOption<Batch>) => this.setState({ selectedBatch: e })}
                    />
                  </div>
                </div>
                <div className="col mx-0">
                  <button
                    title={"Add Batch"}
                    disabled={!selectedBatch}
                    className={"btn btn-icon btn-sm " + (!selectedBatch && "disabled")}
                    onClick={this.handleAddUsedBatch}
                  >
                    <i className={"fa fa-plus px-1 " + (selectedBatch ? "text-success" : "text-muted")} />
                  </button>
                </div>
              </div>
            </div>
            {step === 1 && (
              <UsedBatchesModalOverview
                onlyShowBatches={!!order.usedBatches}
                usedBatches={usedBatches}
                selectedBatches={selectedBatches}
                warnings={warnings}
              />
            )}
          </Modal.Body>
          <Modal.Footer className="border-top">
            {order.usedBatches ? (
              <button className="btn btn-sm btn-outline btn-outline-light" onClick={this.handleHide}>
                Close
              </button>
            ) : (
              <>
                {step === 0 ? (
                  <button className="btn btn-sm btn-text-white" onClick={this.handleHide}>
                    Close
                  </button>
                ) : (
                  <button className="btn btn-sm btn-text-danger" onClick={this.handleHide}>
                    Cancel
                  </button>
                )}
                {step > 0 && (
                  <button className={"btn btn-sm btn-outline btn-outline-light"} onClick={this.handleBack}>
                    Back
                  </button>
                )}
                <ErrorOverlayButton
                  errors={errors}
                  className={"btn btn-sm btn-outline btn-outline-light"}
                  saving={saving}
                  disabled={disabled}
                  buttonText={step === 0 ? "Continue" : "Confirm Used Batches"}
                  onClick={step === 0 ? this.handleNext : this.handleConfirmUsedBatches}
                />
              </>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

interface UsedBatchesPackageSelectionProps {
  batch: Batch;
  usedBatches: Array<UsedBatch>;
  continueSelectWithoutDeselect: boolean;
  itemsPerRow?: SelectOption;
  onSelectPackage: (batchId?: string, packageId?: string, customAmount?: number) => void;
  onSelectAll: (batchId: string) => void;
  onDeselectPackage: (batchId?: string, packageId?: string) => void;
  onEditSelectedPackage: (batchId: string, packageId: string, customAmount: number) => void;
  isSelected: (batchId?: string, packageId?: string) => boolean;
}

const UsedBatchesPackageSelection: React.FunctionComponent<UsedBatchesPackageSelectionProps> = ({
  batch,
  usedBatches,
  continueSelectWithoutDeselect,
  itemsPerRow,
  onSelectPackage,
  onSelectAll,
  onDeselectPackage,
  onEditSelectedPackage,
  isSelected,
}) => {
  const usedBatch = usedBatches.find((b) => b.batchId === batch._id.toString());

  const getPackageSelected = (p: BatchPackage) => {
    return usedBatch?.packages.find((p2) => p._id.toString() === p2.packageId);
  };
  const isValid = !batch.disabled && batch.state === B_RELEASED;
  return (
    <>
      {/*Unique drag containers for each selection seem to be necessary*/}
      <UsedBatchesSelecto
        dragContainer={".drag-" + batch._id.toString()}
        selectableTargets={[`.drag-${batch._id.toString()} .selecto-selectable`]}
        continueSelectWithoutDeselect={continueSelectWithoutDeselect}
        onSelectPackage={onSelectPackage}
        onDeselectPackage={onDeselectPackage}
        isSelected={isSelected}
      />
      <div className={"selecto-area selecto-container drag-" + batch._id.toString()}>
        <div className="row mx-lg-10 mx-xl-20 p-20">
          <div className="col-12 mt-5 mb-10">
            <label className="text-white align-middle h3 fw-bold mb-2">
              Batch RB{batch.identifier}
              {isValid ? (
                <span className="ml-2 btn btn-sm py-1 px-2 text-uppercase btn-success" style={{ cursor: "initial" }}>
                  {batch.state}
                </span>
              ) : (
                <OrderBatchStateDropdown batch={batch} dropdownClasses={"ml-2 d-inline"} />
              )}
            </label>
            <div className="fs-6 text-muted">
              {getBatchAmount(batch)}
              {formatArticleUnit(batch.unit, batch.commodity)} available in {batch.packages.length} packages
              {usedBatch && usedBatch.totalAmountUsed > 0 ? (
                <>
                  <span className="mx-2">-</span>
                  <span className="fw-bold">
                    {usedBatch?.totalAmountUsed || 0}
                    {formatArticleUnit(batch.unit, batch.commodity)}
                  </span>{" "}
                  <span>currently selected</span>
                </>
              ) : (
                ""
              )}
              {batch.state === B_RELEASED && (
                <button className="btn btn-text text-white" onClick={() => onSelectAll(batch._id.toString())}>
                  Select All
                </button>
              )}
            </div>
          </div>
          {isValid &&
            batch.packages
              .filter((p) => p.amountEach > 0)
              .map((p) => {
                const selectedPackage = getPackageSelected(p);
                return (
                  <UsedBatchesPackageSelectionItem
                    key={p._id.toString()}
                    batch={batch}
                    p={p}
                    selectedPackage={selectedPackage}
                    itemsPerRow={itemsPerRow}
                    isSelected={!!selectedPackage}
                    onEditSelectedPackage={onEditSelectedPackage}
                  />
                );
              })}
        </div>
      </div>
      <div className="border-bottom-dark-gray my-10" />
    </>
  );
};

interface UsedBatchesSelectoProps {
  dragContainer: string;
  selectableTargets: Array<string>;
  continueSelectWithoutDeselect: boolean;
  onSelectPackage: (batchId?: string, packageId?: string, customAmount?: number) => void;
  onDeselectPackage: (batchId?: string, packageId?: string) => void;
  isSelected: (batchId?: string, packageId?: string) => boolean;
}

const UsedBatchesSelecto: React.FunctionComponent<UsedBatchesSelectoProps> = ({
  dragContainer,
  selectableTargets,
  continueSelectWithoutDeselect,
  onSelectPackage,
  onDeselectPackage,
  isSelected,
}) => {
  return (
    <Selecto
      dragContainer={dragContainer}
      selectableTargets={selectableTargets}
      selectByClick={true}
      selectFromInside={true}
      continueSelect={true}
      continueSelectWithoutDeselect={continueSelectWithoutDeselect}
      hitRate={0}
      onDragStart={(e) => {
        // prevent clicks on specific items like buttons
        if ("drag" in e.inputEvent.target.dataset && e.inputEvent.target.dataset.drag) e.stop();
      }}
      onSelect={(e) => {
        e.added.forEach((el) => {
          if (!el.classList.contains(CSS_TO_BE_SELECTED) && !isSelected(el.dataset.batch, el.dataset.package))
            el.classList.add(CSS_TO_BE_SELECTED);
          el.classList.remove(CSS_TO_BE_REMOVED);
        });
        e.removed.forEach((el) => {
          if (!el.classList.contains(CSS_TO_BE_REMOVED) && isSelected(el.dataset.batch, el.dataset.package))
            el.classList.add(CSS_TO_BE_REMOVED);
          el.classList.remove(CSS_TO_BE_SELECTED);
        });
      }}
      onSelectEnd={(e) => {
        e.added.forEach((el) =>
          onSelectPackage(
            el.dataset.batch,
            el.dataset.package,
            el.dataset.customamount ? +el.dataset.customamount : undefined
          )
        );
        e.removed.forEach((el) => onDeselectPackage(el.dataset.batch, el.dataset.package));
        e.added
          .concat(e.removed)
          .concat(e.selected)
          .forEach((el) => {
            el.classList.remove(CSS_TO_BE_SELECTED);
            el.classList.remove(CSS_TO_BE_REMOVED);
          });
      }}
    />
  );
};

interface UsedBatchesPackageSelectionItemProps {
  batch: Batch;
  p: BatchPackage;
  selectedPackage: UsedBatchPackage | undefined;
  itemsPerRow?: SelectOption;
  isSelected: boolean;
  onEditSelectedPackage: (batchId: string, packageId: string, customAmount: number) => void;
}

const UsedBatchesPackageSelectionItem: React.FunctionComponent<UsedBatchesPackageSelectionItemProps> = ({
  batch,
  selectedPackage,
  p,
  itemsPerRow,
  isSelected,
  onEditSelectedPackage,
}) => {
  const [edit, setEdit] = useState(false);
  const [customAmount, setCustomAmount] = useState(p.amountEach - 1);
  const [saved, setSaved] = useState(false);

  const handleCustomAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;
    if (value > p.amountEach) setCustomAmount(p.amountEach);
    else if (value <= 0) setCustomAmount(1);
    else setCustomAmount(value);
  };

  const handleEditSelectedPackage = () => {
    setEdit(false);
    setSaved(true);
    if (!isSelected) return;
    onEditSelectedPackage(batch._id.toString(), p._id.toString(), customAmount);
  };

  return (
    <div className={itemsPerRow ? itemsPerRow.value : "col-6 col-md-3 col-lg-2"}>
      <div
        className={
          "card bg-light2 rounded p-5 mb-5 cursor-pointer selecto-selectable " + (isSelected && "selecto-selected")
        }
        data-batch={batch._id.toString()}
        data-package={p._id.toString()}
        data-customamount={saved ? customAmount : undefined}
      >
        <h6 className="text-left text-muted">
          #{p.number}{" "}
          {!edit ? (
            <button
              className="btn btn-icon btn-sm p-0 float-right h-auto w-auto align-middle"
              data-drag="no-drag"
              onClick={() => setEdit(!edit)}
            >
              <i className="fa fa-edit text-gray-300 text-hover-white p-0" data-drag="no-drag" />
            </button>
          ) : (
            <button
              className="btn btn-icon btn-sm p-0 float-right h-auto w-auto align-middle"
              data-drag="no-drag"
              onClick={handleEditSelectedPackage}
            >
              <i className="fa fa-check text-success text-hover-success p-0" data-drag="no-drag" />
            </button>
          )}
        </h6>
        <div style={{ minHeight: "20px" }}>
          {edit ? (
            <h5 className="text-left mb-0">
              <Input
                type={"number"}
                data-drag="no-drag"
                min={0}
                max={p.amountEach}
                value={customAmount}
                className={"d-inline form-control custom-form-control rounded-0 py-auto px-1 mr-1"}
                style={{ maxWidth: "3rem", fontSize: "1.15rem", maxHeight: "20px" }}
                name={"customAmount"}
                onBlur={handleCustomAmount}
              />
              {batch.unit} of {p.amountEach}
              {batch.unit} {B_PACKAGE_DICT[p.packageType]}
            </h5>
          ) : (
            <h5 className="text-left mb-0">
              {selectedPackage ? selectedPackage.amountUsed : saved ? customAmount : p.amountEach}
              {formatArticleUnit(batch.unit, batch.commodity)}
              {saved && customAmount !== p.amountEach
                ? ` of ${p.amountEach}${formatArticleUnit(batch.unit, batch.commodity)}`
                : ""}{" "}
              {B_PACKAGE_DICT[p.packageType]}
            </h5>
          )}
        </div>
        <span className="text-left fs-6 text-white">
          {B_WAREHOUSE_OPTIONS.find((wo) => wo.value === p.warehouse)?.label ?? "Unknown"} - {p.warehouseLocation}
        </span>
      </div>
    </div>
  );
};

interface UsedBatchesModalOverviewProps {
  onlyShowBatches?: boolean;
  usedBatches: Array<UsedBatch>;
  selectedBatches: Array<Batch>;
  warnings: Array<string>;
}

const UsedBatchesModalOverview: React.FunctionComponent<UsedBatchesModalOverviewProps> = ({
  onlyShowBatches,
  usedBatches,
  selectedBatches,
  warnings,
}) => {
  const getGroupedPackages = (batch: UsedBatch) => {
    const packagesMap: { [packageType: string]: Array<UsedBatchPackage> } = {};
    for (let i = 0; i < batch.packages.length; i++) {
      const pack = batch.packages[i];
      const type = pack.packageType + pack.amountUsed + formatArticleUnit(batch.unit);
      if (type in packagesMap) packagesMap[type].push(pack);
      else packagesMap[type] = [pack];
    }
    return packagesMap;
  };
  const headerDefinition = [
    { title: "Packages", style: { width: "30%" } },
    { title: "Package Type", style: { width: "19%" } },
    { title: "Locations", style: { width: "30%" } },
    { title: "Amount per Package", style: { width: "13%" } },
    { title: "Total Amount", style: { width: "13%" } },
  ];

  return (
    <div>
      <div className="row mx-lg-10 mx-xl-20 px-20 py-5">
        <div className="col-12 mt-5 mb-10">
          {!onlyShowBatches && warnings.length > 0 && (
            <div className="mb-20">
              <label className="text-white h3 fw-bold mb-2">Warnings</label>
              <div className="fs-6 text-muted">
                {warnings.length} {warnings.length === 1 ? "warning" : "warnings"} for selected{" "}
                {selectedBatches.length === 1 ? "batch" : "batches"}
              </div>
              <div className="row">
                {warnings.map((w) => (
                  <div key={w} className="col-12 col-lg-6 col-xxl-4">
                    <div className="mt-5 card bg-warning px-10 py-5">
                      <span className="h5 mb-0">{w}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {usedBatches.filter((uB) => uB.packages.length > 0).length > 0 ? (
            usedBatches.map((uB, idx) => {
              const groupedPackages = getGroupedPackages(uB);
              const originBatch = selectedBatches.find((b) => b._id.toString() === uB.batchId);
              const totalAvailable = originBatch?.packages.reduce((a, b) => a + b.amountEach, 0) || 0;
              return (
                <div className={idx < usedBatches.length - 1 ? "mb-20" : ""} key={uB._id.toString()}>
                  <label className="text-white h3 fw-bold mb-2">Batch RB{uB.identifier}</label>
                  <div className="fs-6 text-muted">
                    {uB.totalAmountUsed}
                    {formatArticleUnit(uB.unit)}
                    {onlyShowBatches ? "" : ` of ${totalAvailable}${formatArticleUnit(uB.unit)}`} used
                  </div>
                  <div className="px-10 mt-10 card " style={{ background: "#232323" }}>
                    <BaseListing
                      headerDefinition={headerDefinition}
                      bodyContent={
                        <>
                          {Object.entries(groupedPackages).map(([type, packages]) => {
                            return (
                              <tr key={uB._id.toString() + type} className="">
                                <td className="align-middle text-white">
                                  {packages.map((p) => `#${p.number}`).join(" ")}
                                </td>
                                <td className="align-middle text-white">
                                  {packages[0].amountUsed}
                                  {formatArticleUnit(uB.unit)}
                                  {packages[0].amountUsed !== packages[0].amountEach
                                    ? ` of ${packages[0].amountEach}${formatArticleUnit(uB.unit)}`
                                    : ""}{" "}
                                  {B_PACKAGE_DICT[packages[0].packageType]}
                                </td>
                                <td className="align-middle text-white">
                                  {Array.from(
                                    new Set(
                                      packages.map(
                                        (p) =>
                                          `${p.warehouseLocation}  (${
                                            B_WAREHOUSE_OPTIONS.find((o) => o.value === p.warehouse)?.label ?? "Unknown"
                                          })`
                                      )
                                    )
                                  ).join(", ")}
                                </td>
                                <td className="align-middle text-white">
                                  {packages[0].amountUsed}
                                  {formatArticleUnit(uB.unit)}
                                </td>
                                <td className="align-middle text-white fw-bolder">
                                  {packages.reduce((a, b) => a + b.amountUsed, 0)}
                                  {formatArticleUnit(uB.unit)}
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      }
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <tr>
              <td className="text-center" colSpan={10}>
                No batches selected
              </td>
            </tr>
          )}
        </div>
      </div>
    </div>
  );
};

export default UsedBatchesModal;
