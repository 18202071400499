import React, { PureComponent } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import httpService from "../../services/httpService";
import SplashScreen from "./SplashScreen";
import { toAbsoluteUrl } from "../../utils/baseUtils";
import {
  CUSTOMER_NOTIFICATION_REASON_DESCRIPTIONS,
  SUPPLIER_NOTIFICATION_REASON_DESCRIPTIONS,
} from "../../utils/notificationUtils";
import { R_DELAYEDORDERS, R_EXPIREDPRICES, R_PRICEUPDATEREQUESTS, R_SUMMARY } from "../../model/userData.types";

interface DisableNotificationState {
  response?: any;
}

interface DisableNotificationProps extends RouteComponentProps {}

class DisableNotification extends PureComponent<DisableNotificationProps, DisableNotificationState> {
  constructor(props: DisableNotificationProps) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const { id, type } = Object.fromEntries(query.entries());
    const application = process.env.REACT_APP_NAME;
    try {
      const response = await httpService.get(
        `https://eu-central-1.aws.data.mongodb-api.com/app/${application || ""}/endpoint/disableNotification?id=` +
          id +
          `&type=` +
          type
      );
      this.setState({ response });
    } catch (error) {
      this.setState({ response: "failed" });
    }
  }

  getLabel(type: string): string {
    const LABELS: { [key: string]: string } = {
      [R_EXPIREDPRICES]: SUPPLIER_NOTIFICATION_REASON_DESCRIPTIONS[R_EXPIREDPRICES].label,
      [R_SUMMARY]: SUPPLIER_NOTIFICATION_REASON_DESCRIPTIONS[R_SUMMARY].label,
      [R_DELAYEDORDERS]: CUSTOMER_NOTIFICATION_REASON_DESCRIPTIONS[R_DELAYEDORDERS].label,
      [R_PRICEUPDATEREQUESTS]: SUPPLIER_NOTIFICATION_REASON_DESCRIPTIONS[R_PRICEUPDATEREQUESTS].label,
    };
    if (LABELS[type]) {
      return LABELS[type];
    } else {
      return "";
    }
  }

  render() {
    const { response } = this.state;
    if (!response) {
      return <SplashScreen />;
    }
    const label = this.getLabel(response.data);
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="bg-dark text-white p-10 d-flex flex-column align-items-center">
          <Link to="/">
            <img alt="Logo" src={toAbsoluteUrl("/assets/media/logo.png")} className="mb-7 h-80px h-80px" />
          </Link>
          {response.status === 200 ? (
            <>
              <h1 className="fw-bolder d-flex align-items-center text-white">Successfully disabled notification</h1>
              <div className="text-white mt-3">
                You will no longer receive Mails {label ? "regarding " + label : ""}
              </div>
              <div className="text-white mt-3">
                You can turn this notification back on in your&nbsp;
                <Link className="text-muted fs-8 custom-link" to="/notificationSettings">
                  Notification Settings
                </Link>
              </div>
            </>
          ) : (
            <>
              <h1 className="fw-bolder d-flex align-items-center text-white">Failed to disable notification</h1>
              <div className="text-white mt-3">
                You can also edit your notifications in your&nbsp;
                <Link className="text-muted fs-8 custom-link" to="/notificationSettings">
                  Notification Settings
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default DisableNotification;
