import _ from "lodash";
import { BSON } from "realm-web";
import { CustomerSampleOrder, CustomerSampleOrderExtended } from "../model/customer/customerSampleOrder.types";
import {
  SAMO_STATE,
  SAMO_TIMELINETYPE,
  SampleOrder,
  SampleOrderExtended,
  SampleOrderTimelineEntry,
  SampleOrderTimelineEntryPayload,
} from "../model/sampleOrder.types";
import { callFunction } from "../services/dbService";
import userService from "../services/userService";
import { SelectOption } from "../components/common/CustomSelect";
import { DataContextAnonymousType, DataContextCustomerType, DataContextInternalType } from "../context/dataContext";
import { getDocFromCollection } from "./baseUtils";
import { UserData } from "../model/userData.types";
import { Company } from "../model/company.types";
import { SAMPLE_ORDER_TYPES } from "./orderUtils";

export const SAMO_FILTER_STATES = [
  { value: SAMO_STATE.REQUESTED, label: "Sample Requested" },
  { value: SAMO_STATE.CONFIRMED, label: "Request Confirmed" },
  { value: SAMO_STATE.REJECTED, label: "Request Rejected" },
  { value: SAMO_STATE.SHIPPED, label: "Sample Shipped" },
  { value: SAMO_STATE.ARCHIVED, label: "Order Archived" },
  { value: SAMO_STATE.CANCELED, label: "Order Canceled" },
];

// Functions
const PLACESAMPLEORDER = "placeSampleOrder";
const UPSERTSAMPLEORDER = "upsertSampleOrder";

// File types
export const SAMO_CONFIRMATION = "samoConfirmation";

export const SAMO_FILETYPES: Array<SelectOption> = [{ value: SAMO_CONFIRMATION, label: "Sample Order Confirmation" }];

/**
 * Places the given sample order via backend call
 * @param sampleOrder Sample order that should be placed
 * @returns {Promise<{ res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; orderNumber: string }>} Result of the call
 */
export async function placeSampleOrder(
  sampleOrder: CustomerSampleOrder | SampleOrder
): Promise<{ res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; orderNumber: string }> {
  return callFunction(PLACESAMPLEORDER, [sampleOrder]);
}

/**
 * Update the referenced sample order.
 * @param sampleOrder Update content
 * @param sampleOrderId ID of the sample order
 * @param timelineEntry Optional timeline entries that should be added
 * @returns {Promise<{ res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>, orderNumber: string }>} Result of the update
 */
export async function updateSampleOrder(
  sampleOrder: Partial<CustomerSampleOrder | SampleOrder>,
  sampleOrderId?: BSON.ObjectId,
  timelineEntry?: SampleOrderTimelineEntry
): Promise<{ res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>; orderNumber: string }> {
  if (sampleOrderId) sampleOrder._id = sampleOrderId;
  return upsertSampleOrder(sampleOrder, false, timelineEntry);
}

/**
 * Generates a timeline entry for a sample order
 * @param type Type of the entry
 * @param payload Optional payload of the entry
 * @returns {SampleOrderTimelineEntry} Timeline entry object
 */
export function getSampleOrderTimelineEntry(
  type: SAMO_TIMELINETYPE,
  payload?: SampleOrderTimelineEntryPayload
): SampleOrderTimelineEntry {
  return {
    _id: new BSON.ObjectId(),
    date: new Date(),
    type: type,
    person: userService.getUserId(),
    payload: payload ?? null,
  };
}

/**
 * Get the matching tab for the order's state
 * @return {number} the matching order detail page tab number
 */
export const getOrderStateRanking = (order: SAMPLE_ORDER_TYPES): number => {
  switch (order.state) {
    case SAMO_STATE.REQUESTED:
      return 0;
    case SAMO_STATE.CONFIRMED:
      return 1;
    case SAMO_STATE.SHIPPED:
      return 2;
    case SAMO_STATE.ARCHIVED:
      return 3;
    case SAMO_STATE.REJECTED:
      return 4;
    case SAMO_STATE.CANCELED:
      return 5;
    default:
      return -1;
  }
};

/**
 * Upserts the given sample order.
 * @param sampleOrder Sample order that should be updated or inserted
 * @param insert If set the sample order is inserted
 * @param timelineEntry Optional timeline entry to add
 * @returns {Promise<{ res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>, orderNumber: string }>} Result of the upsert
 */
async function upsertSampleOrder(
  sampleOrder: Partial<CustomerSampleOrder | SampleOrder>,
  insert: boolean,
  timelineEntry?: SampleOrderTimelineEntry
): Promise<{ res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>; orderNumber: string }> {
  return callFunction(UPSERTSAMPLEORDER, [sampleOrder, insert, timelineEntry]);
}

/**
 * Sort sample orders
 * @param orders list of sample orders
 * @returns {Array<SampleOrder | CustomerSampleOrder>} list of sample orders sorted by state
 */
export const sortSampleOrdersByState = (
  orders: Array<SampleOrder | CustomerSampleOrder>
): Array<SampleOrder | CustomerSampleOrder> => _.orderBy(orders, (o) => getOrderStateRanking(o), "asc");

export function extendSampleOrder(sampleOrder: SampleOrder, context: DataContextInternalType): SampleOrderExtended {
  const person = getDocFromCollection(context.userData, sampleOrder.person) as UserData;
  const company = getDocFromCollection(context.company, sampleOrder.company) as Company;
  return { ...sampleOrder, person, company };
}

export function extendCustomerSampleOrder(
  sampleOrder: CustomerSampleOrder,
  context: DataContextCustomerType | DataContextAnonymousType
): CustomerSampleOrderExtended {
  const person = getDocFromCollection(context.userData, sampleOrder.person) as UserData;
  const company = getDocFromCollection(context.company, sampleOrder.company) as Company;
  return { ...sampleOrder, person, company };
}
