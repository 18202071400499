import _ from "lodash";
import React, { PureComponent } from "react";
import { Nav } from "react-bootstrap";
import WorkflowDocumentsCard from "./workflowCards/WorkflowDocumentsCard";
import WorkflowShipmentCard from "./workflowCards/WorkflowShipmentCard";
import WorkflowShippingInformationCard from "./workflowCards/WorkflowShippingInformationCard";
import WorkflowGeneralTasksCard from "./workflowCards/WorkflowGeneralTasksCard";
import WorkflowCompletedCard from "./workflowCards/WorkflowCompletedCard";
import WorkflowFulfillmentCard from "./workflowCards/WorkflowFulfillmentCard";
import WorkflowCreateOrderCard from "./workflowCards/WorkflowCreateOrderCard";
import { DataContextInternalType } from "../../../../../context/dataContext";
import {
  SO_ARCHIVED,
  SO_ARRIVEDATSTARTINGPORT,
  SO_CANCELED,
  SO_HANDLEDATCUSTOMS,
  SO_HANDLEDATWAREHOUSE,
  SO_ORDERCONFIRMED,
  SO_REQUESTED,
  SO_SHIPPEDFROMSUPPLIER,
  SO_SHIPPEDTOWAREHOUSE,
  SO_STATES,
  SO_T_BATCHCREATED,
  SupplierOrderExtended,
} from "../../../../../model/supplierOrder.types";
import { CO_ARCHIVED, CO_CANCELED, CO_SHIPPEDTOCUSTOMER, CO_STATES } from "../../../../../model/customerOrder.types";
import { getOrderStateRanking } from "../../../../../utils/supplierOrderUtils";
import { CO_ORDERCONFIRMATION } from "../../../../../utils/customerOrderUtils";
import { B_BLOCKED, B_RELEASED, Batch } from "../../../../../model/batch.types";

interface SupplierOrderWorkflowProps {
  order: SupplierOrderExtended;
  context: DataContextInternalType;
}

interface SupplierOrderWorkflowState {
  activeFilter: string;
  currentTab: string;
  relatedBatches: Array<Batch>;
}

class SupplierOrderWorkflow extends PureComponent<SupplierOrderWorkflowProps, SupplierOrderWorkflowState> {
  constructor(props: SupplierOrderWorkflowProps) {
    super(props);
    this.state = {
      activeFilter: "",
      currentTab: this.getTabThatRequiresAttention(),
      relatedBatches: this.getRelatedBatches(props),
    };
  }

  componentDidUpdate(prevProps: Readonly<SupplierOrderWorkflowProps>) {
    if (prevProps.order.state !== this.props.order.state) {
      this.setState({
        currentTab: this.getTabThatRequiresAttention(),
        relatedBatches: this.getRelatedBatches(this.props),
      });
    } else {
      const relatedBatches = this.getRelatedBatches(this.props);
      if (!_.isEqual(this.state.relatedBatches, relatedBatches)) this.setState({ relatedBatches });
    }
  }

  handleActiveFilter = (aF: string) => {
    this.setState({ activeFilter: aF === this.state.activeFilter ? "" : aF });
  };

  getRelatedBatches = (props: SupplierOrderWorkflowProps) => {
    const { context, order } = props;
    // Exclude disabled batches
    return context.batch.filter((b) => !b.disabled && b.supplierOrder === order._id.toString());
  };

  getSteps = (): Array<{ name: string; condition?: () => boolean }> => {
    const { order } = this.props;
    const orderStateRank = getOrderStateRanking(order);
    return [
      {
        name: "Ordered",
      },
      {
        name: "Shipping",
        condition: () => orderStateRank >= 2 || order.shipment.length > 0,
      },
      {
        name: "Processing",
        condition: () => orderStateRank >= 4,
      },
      {
        name: "Closed",
        condition: () => ([SO_CANCELED, SO_ARCHIVED] as Array<SO_STATES>).includes(order.state),
      },
    ];
  };

  isDone = (tab: string) => {
    const { order } = this.props;
    const stateRank = getOrderStateRanking(order);
    if (stateRank === -1) return false;
    switch (tab) {
      case "Ordered":
        return stateRank > 1;
      case "Shipping":
        return stateRank > 4;
      case "Processing":
        return stateRank > 5;
      case "Closed":
        return stateRank === 6;
      default:
        return false;
    }
  };

  getTabThatRequiresAttention = () => {
    const { order } = this.props;
    switch (order.state) {
      case SO_REQUESTED:
      case SO_ORDERCONFIRMED:
        return "Ordered";
      case SO_SHIPPEDFROMSUPPLIER:
      case SO_HANDLEDATCUSTOMS:
      case SO_SHIPPEDTOWAREHOUSE:
        return "Shipping";
      case SO_HANDLEDATWAREHOUSE:
        return "Processing";
      case SO_ARCHIVED:
      case SO_CANCELED:
        return "Closed";
      default:
        return "";
    }
  };

  getLinkClasses = (tab: string, currentProgress: string) => {
    const { order } = this.props;

    const done = this.isDone(tab);
    const disabled = !done && tab !== currentProgress;
    if (order.state === CO_CANCELED) return "text-white py-2 progress-background-canceled";
    let classes = "text-white py-2 ";
    if (disabled) classes += "text-muted ";
    if (done) classes += "progress-background ";
    if (tab === currentProgress) classes += "progress-btn ";
    return classes;
  };

  render() {
    const { order, context } = this.props;
    const { activeFilter, relatedBatches } = this.state;
    const tabs = this.getSteps();
    const currentProgress = this.getTabThatRequiresAttention();
    const gotOrderConfirmation = order.files.some((f) => f.type === CO_ORDERCONFIRMATION);
    const allCOsDone =
      getOrderStateRanking(order) > 4 &&
      order.customerOrders.every((cO) => ([CO_SHIPPEDTOCUSTOMER, CO_ARCHIVED] as Array<CO_STATES>).includes(cO.state));

    return (
      <div className="card bg-white">
        <div className="card-header border-0 mt-5">
          <div className="card-title flex-column">
            <h2 className="mb-1">Workflow</h2>
          </div>
        </div>
        <div className="card-body p-9 pt-0">
          <ul className="nav-group nav-group-fluid custom-form-control">
            {tabs.map((t) => (
              <li key={t.name + "_link"} className="btn btn-sm fw-bolder custom-form-control p-0 ">
                <Nav.Link
                  active={currentProgress === t.name}
                  className={this.getLinkClasses(t.name, currentProgress)}
                  style={{ opacity: activeFilter && activeFilter !== t.name ? 0.3 : 1 }}
                  onClick={
                    this.isDone(t.name) || t.name === currentProgress
                      ? () => this.handleActiveFilter(t.name)
                      : undefined
                  }
                >
                  {t.name}
                </Nav.Link>
              </li>
            ))}
          </ul>
          {allCOsDone && (
            <>
              <div className="border-bottom-dark-gray pt-5" />
              <WorkflowCompletedCard order={order} context={context} />
            </>
          )}
          {order.shipment[0]?.timeline.some((t) => t.type === SO_T_BATCHCREATED) &&
            (!activeFilter || activeFilter === "Processing") && (
              <>
                <div className="border-bottom-dark-gray pt-5" />
                <WorkflowFulfillmentCard
                  order={order}
                  context={context}
                  done={allCOsDone}
                  relatedBatches={relatedBatches}
                />
              </>
            )}
          {order.state !== SO_CANCELED && this.isDone("Shipping") && (!activeFilter || activeFilter === "Processing") && (
            <>
              <div className="border-bottom-dark-gray pt-5" />
              <WorkflowGeneralTasksCard
                order={order}
                context={context}
                relatedBatches={relatedBatches}
                done={
                  relatedBatches.length > 0 && relatedBatches.every((b) => [B_BLOCKED, B_RELEASED].includes(b.state))
                }
              />
            </>
          )}
          {order.shipment[0] &&
            (!activeFilter || activeFilter === "Shipping") &&
            order.shipment[0].state !== SO_ARRIVEDATSTARTINGPORT &&
            getOrderStateRanking(order) > 1 && (
              <>
                <div className="border-bottom-dark-gray pt-5" />
                <WorkflowShippingInformationCard order={order} context={context} done={this.isDone("Shipping")} />
              </>
            )}
          {gotOrderConfirmation && (!activeFilter || activeFilter === "Shipping") && (
            <>
              <div className="border-bottom-dark-gray pt-5" />
              <WorkflowShipmentCard
                order={order}
                done={
                  order.shipment.length > 0 &&
                  getOrderStateRanking(order) > 1 &&
                  order.shipment[0].state !== SO_ARRIVEDATSTARTINGPORT
                }
                context={context}
              />
            </>
          )}
          {(!activeFilter || activeFilter === "Ordered") && (
            <>
              <div className="border-bottom-dark-gray pt-5" />
              <WorkflowDocumentsCard order={order} context={context} done={gotOrderConfirmation} />
            </>
          )}
          {(!activeFilter || activeFilter === "Ordered") && (
            <>
              <div className="border-bottom-dark-gray pt-5" />
              <WorkflowCreateOrderCard order={order} context={context} />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default SupplierOrderWorkflow;
