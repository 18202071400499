import _ from "lodash";
import { toast } from "react-toastify";
import React, { PureComponent } from "react";
import CustomSelect, { SelectOption } from "../common/CustomSelect";
import {
  NotificationSettings,
  R_DELAYEDORDERS,
  R_EXPIREDPRICES,
  R_PRICEUPDATEREQUESTS,
  R_SUMMARY,
} from "../../model/userData.types";
import userService from "../../services/userService";
import {
  getDefaultReason,
  NOTIFICATION_INTERVALS,
  SUPPLIER_NOTIFICATION_REASON_DESCRIPTIONS,
  SUPPLIER_NOTIFICATION_REASONS,
  NOTIFICATION_TYPES,
  CUSTOMER_NOTIFICATION_REASONS,
  CUSTOMER_NOTIFICATION_REASON_DESCRIPTIONS,
} from "../../utils/notificationUtils";
import ErrorOverlayButton from "../common/ErrorOverlayButton";
import { updateUserData } from "../../utils/userUtils";

interface NotificationConfigurationProps {
  customer?: boolean;
}

interface NotificationConfigurationState {
  notificationSettings: NotificationSettings;
  saving: boolean;
}

class NotificationConfiguration extends PureComponent<NotificationConfigurationProps, NotificationConfigurationState> {
  constructor(props: NotificationConfigurationProps) {
    super(props);
    const userdata = userService.getUserData();
    const notificationSettings = _.cloneDeep(userdata.notifications);
    if (!notificationSettings.email)
      notificationSettings.email = userdata.emails[0]?.value || userService.getUserMail() || "";
    this.state = {
      notificationSettings,
      saving: false,
    };
  }

  handleReset = async () => {
    await userService.getUser()?.refreshCustomData();

    const userdata = userService.getUserData();
    const notificationSettings = _.cloneDeep(userdata.notifications);
    if (!notificationSettings.email)
      notificationSettings.email = userdata.emails[0]?.value || userService.getUserMail() || "";
    this.setState({ notificationSettings });
  };

  handleChangeMail = (e: SelectOption) => {
    const notificationSettings = _.cloneDeep(this.state.notificationSettings);
    notificationSettings.email = e.value;
    this.setState({ notificationSettings });
  };

  handleChangeReasonInterval = (e: SelectOption, reason?: string) => {
    const notificationSettings = _.cloneDeep(this.state.notificationSettings);
    const reasons = this.props.customer ? CUSTOMER_NOTIFICATION_REASONS : SUPPLIER_NOTIFICATION_REASONS;
    if (!reason) {
      reasons.forEach((r) => {
        const reasonSetting = notificationSettings.settings.find((s) => s.reason === r);
        if (!reasonSetting || !reasonSetting.enabled) return;
        reasonSetting.interval = +e.value;
      });
    } else {
      const reasonSetting = notificationSettings.settings.find((s) => s.reason === reason);
      if (!reasonSetting) return;
      reasonSetting.interval = +e.value;
    }

    this.setState({ notificationSettings });
  };

  /**
   * Handles clicking a checkbox. Checkboxes are handled as a matrix of reason x type.
   * @param reason optional, notification reason to check
   */
  handleCheckboxClick = (reason?: string) => {
    const notificationSettings = _.cloneDeep(this.state.notificationSettings);
    if (!reason) {
      const allChecked = this.isAllChecked();
      if (this.props.customer) {
        CUSTOMER_NOTIFICATION_REASONS.forEach((r) => {
          const reasonSetting = notificationSettings.settings.find((s) => s.reason === r);
          if (!reasonSetting) {
            notificationSettings.settings.push(getDefaultReason(r, !allChecked, r === R_DELAYEDORDERS));
          } else reasonSetting.enabled = !allChecked;
        });
      } else {
        SUPPLIER_NOTIFICATION_REASONS.forEach((r) => {
          const reasonSetting = notificationSettings.settings.find((s) => s.reason === r);
          if (!reasonSetting) notificationSettings.settings.push(getDefaultReason(r, !allChecked));
          else reasonSetting.enabled = !allChecked;
        });
      }
    } else {
      const reasonSetting = notificationSettings.settings.find((s) => s.reason === reason);

      if (!reasonSetting) {
        if (reason === R_DELAYEDORDERS) notificationSettings.settings.push(getDefaultReason(reason, false, true));
        else if (reason !== R_DELAYEDORDERS) notificationSettings.settings.push(getDefaultReason(reason));
      } else reasonSetting.enabled = !reasonSetting.enabled;
    }

    this.setState({ notificationSettings });
  };

  handleSave = async () => {
    const { notificationSettings } = this.state;
    this.setState({ saving: true });
    try {
      const user = userService.getUserData();
      // Phones and mails are always part of the update since the check for changes would be unnecessary complex
      const result = await updateUserData(user, { notifications: notificationSettings });
      if (result && result.modifiedCount > 0) {
        // Refresh user custom data since mongo handles it other than normal data
        userService.getUser()?.refreshCustomData();
        toast.success("Notification settings updated successfully");
      } else {
        toast.error("Error updating notification settings. Please try again later.");
      }
    } finally {
      // Unset saving flag so that buttons can be clicked again
      this.setState({ saving: false });
    }
  };

  /**
   * Check whether the given checkbox should be checked or not.
   * @param reason Reason for the notification
   * @returns Boolean indicating checked or not
   */
  isChecked = (reason: string) => {
    const { notificationSettings } = this.state;
    return notificationSettings.settings.some((s) => s.reason === reason && s.enabled);
  };

  /**
   * Helper function to check if all checkboxes for a given type (or all types) are checked.
   * @returns True if ALL reasons are checked for the given type (or all types). False if not
   */
  isAllChecked = () => {
    const { notificationSettings } = this.state;
    const reasons = this.props.customer ? CUSTOMER_NOTIFICATION_REASONS : SUPPLIER_NOTIFICATION_REASONS;
    return (
      notificationSettings.settings.length === reasons.length && notificationSettings.settings.every((s) => s.enabled)
    );
  };

  getGeneralInterval = () => {
    const { notificationSettings } = this.state;
    let generalInterval: SelectOption = { value: "", label: "Disabled" };
    let interval: number | undefined = undefined;
    for (let i = 0; i < notificationSettings.settings.length; i++) {
      const setting = notificationSettings.settings[i];
      if (!setting.enabled) continue;
      if (interval !== undefined && setting.interval !== interval) {
        return { value: "various", label: "Various intervals" };
      } else {
        interval = setting.interval;
      }
    }
    generalInterval =
      NOTIFICATION_INTERVALS.find((i) => interval && i.value === interval.toString()) || generalInterval;
    return generalInterval;
  };

  getRows = (
    reasons:
      | Array<typeof R_DELAYEDORDERS>
      | Array<typeof R_SUMMARY | typeof R_EXPIREDPRICES | typeof R_PRICEUPDATEREQUESTS>,
    descriptions: {
      [key: string]: { label: string; interval: string };
    }
  ) => {
    const { notificationSettings } = this.state;

    return reasons.map((r) => {
      const reasonSetting = notificationSettings.settings.find((s) => s.reason === r);
      const interval =
        reasonSetting && reasonSetting.enabled
          ? NOTIFICATION_INTERVALS.find((r) => r.value === reasonSetting.interval.toString())
          : { value: "", label: "Disabled" };
      return (
        <tr key={r}>
          <td className="text-left align-middle">
            <span className={"text-white"}>{descriptions[r].label}</span>
          </td>
          {NOTIFICATION_TYPES.map((t) => {
            return (
              <td key={t} className="text-center align-middle">
                <input type="checkbox" checked={this.isChecked(r)} onChange={() => this.handleCheckboxClick(r)} />
              </td>
            );
          })}
          <td className="align-middle ">
            <div className="ml-auto" style={{ maxWidth: "200px" }}>
              {descriptions[r].interval === "always" ? (
                <CustomSelect
                  options={NOTIFICATION_INTERVALS}
                  disabled={true}
                  matchFormControl={true}
                  placeholder={"Always"}
                  value={{ value: "0", label: "Always" }}
                />
              ) : (
                <CustomSelect
                  options={NOTIFICATION_INTERVALS}
                  disabled={!reasonSetting || !reasonSetting.enabled}
                  matchFormControl={true}
                  placeholder={"Select an interval"}
                  value={interval}
                  onChange={(e: SelectOption) => this.handleChangeReasonInterval(e, r)}
                />
              )}
            </div>
          </td>
        </tr>
      );
    });
  };

  render() {
    const { notificationSettings, saving } = this.state;
    const { customer } = this.props;

    const userdata = userService.getUserData();
    const emails = userdata.emails.map((e) => e.value);
    const userMail = userService.getUserMail();
    if (userMail && !emails.includes(userMail)) emails.push(userMail);
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="card bg-white min-h-100">
              <div className="card-body p-9">
                <h3 className="card-title align-items-start flex-column mb-15">
                  <span className="card-label fw-bolder mb-3 fs-3rem">Notification Settings</span>
                </h3>
                <div className="row">
                  <div className="col-2" />
                  <div className="col-8">
                    <div className="row">
                      <div id="contactMail" className="col-4 mb-5">
                        <h6 className="text-white font-weight-bold">Notification Email</h6>
                        <CustomSelect
                          options={emails.map((e) => {
                            return { value: e, label: e };
                          })}
                          matchFormControl={true}
                          placeholder={"Select a mail address"}
                          value={
                            notificationSettings.email
                              ? { value: notificationSettings.email, label: notificationSettings.email }
                              : undefined
                          }
                          onChange={this.handleChangeMail}
                        />
                      </div>
                      <div id="contactMail" className="col-4 mb-5">
                        <h6 className="text-white font-weight-bold">Language</h6>
                        <CustomSelect
                          matchFormControl={true}
                          disabled={true}
                          value={{ value: "en", label: "English" }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <table className="table align-middle">
                          <thead>
                            <tr className="fw-bolder text-white">
                              <th className="border-bottom-0 text-left align-middle" style={{ width: "40%" }}>
                                Notification Type
                              </th>
                              {NOTIFICATION_TYPES.map((t) => (
                                <th
                                  key={t}
                                  className="border-bottom-0 text-center align-middle"
                                  style={{ width: "20%" }}
                                >
                                  {_.upperFirst(t)}
                                </th>
                              ))}
                              <th className="border-bottom-0 text-center align-middle" style={{ width: "40%" }}>
                                Interval
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="bg-light">
                              <td className="text-left align-middle">All</td>
                              {NOTIFICATION_TYPES.map((t) => (
                                <td key={t} className="text-center align-middle">
                                  <input
                                    type="checkbox"
                                    id={t}
                                    checked={this.isAllChecked()}
                                    onChange={() => this.handleCheckboxClick()}
                                  />
                                </td>
                              ))}
                              <td className="align-middle">
                                <div className="ml-auto" style={{ maxWidth: "200px" }}>
                                  <CustomSelect
                                    options={NOTIFICATION_INTERVALS}
                                    disabled={notificationSettings.settings.filter((r) => r.enabled).length === 0}
                                    matchFormControl={true}
                                    placeholder={"Select an interval"}
                                    value={this.getGeneralInterval()}
                                    onChange={(e: SelectOption) => this.handleChangeReasonInterval(e)}
                                  />
                                </div>
                              </td>
                            </tr>
                            {this.getRows(
                              customer ? CUSTOMER_NOTIFICATION_REASONS : SUPPLIER_NOTIFICATION_REASONS,
                              customer
                                ? CUSTOMER_NOTIFICATION_REASON_DESCRIPTIONS
                                : SUPPLIER_NOTIFICATION_REASON_DESCRIPTIONS
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer border-0 text-right">
                <button
                  className={"btn btn-sm btn-outline btn-outline-light mr-2" + (saving ? " disabled" : "")}
                  onClick={saving ? undefined : this.handleReset}
                >
                  Reset
                </button>
                <ErrorOverlayButton
                  errors={[]}
                  className={"btn btn-sm btn-outline btn-outline-light" + (saving ? " disabled" : "")}
                  buttonText="Save"
                  onClick={saving ? undefined : this.handleSave}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotificationConfiguration;
