export enum DashboardColumn {
  OPEN = "orderRequired",
  ORDERED = "ordered",
  ORDERCONFIRMED = "orderConfirmed",
  SHIPPED = "shipped",
  PROCESSING = "processing",
  DELIVERY = "delivery",
}

/**
 * Retrieve the display string for the given dashboard column
 * @param column Dashboard column enum
 * @returns {string} Display string for the column
 */
export function getDashboardColumnString(column: DashboardColumn): string {
  switch (column) {
    case DashboardColumn.OPEN:
      return "Order Required";
    case DashboardColumn.ORDERED:
      return "Ordered";
    case DashboardColumn.ORDERCONFIRMED:
      return "Order Confirmed";
    case DashboardColumn.SHIPPED:
      return "Shipped";
    case DashboardColumn.PROCESSING:
      return "Processing";
    case DashboardColumn.DELIVERY:
      return "Delivery";
    default:
      return "Unknown";
  }
}
