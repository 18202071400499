import React from "react";
import { Link } from "react-router-dom";
import { CustomerContract } from "../../../model/customerContract.types";
import { CustomerCustomerContract } from "../../../model/customer/customerCustomerContract.types";
import HoverPopover from "../../common/HoverPopover";
import { getOrderNumber } from "../../../utils/orderUtils";
import Tooltip from "../../common/Tooltip";
import { formatDate, formatUnit } from "../../../utils/baseUtils";

interface CallReferencesProps {
  contract: CustomerContract | CustomerCustomerContract;
  link: string;
}

const CallReferences: React.FunctionComponent<CallReferencesProps> = ({ contract, link }) => {
  const { calls } = contract;
  if (calls.length === 0) return <span>-</span>;
  if (calls.length > 20)
    return (
      <HoverPopover
        popoverStyle={{ maxWidth: "95vw", border: "none" }}
        content={
          <div className="card overflow-auto" style={{ background: "#3f3f3f" }}>
            <div className="card-body">
              <div className="text-white">
                {calls.map((c, idx) => (
                  <div key={c._id.toString()} className="row" style={{ minWidth: "300px" }}>
                    <div className="col-7 px-2 my-auto fw-bolder fs-7 text-left">
                      <Link
                        className="custom-link text-white"
                        to={`${link}${c._id.toString()}`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        Order {getOrderNumber(c)}
                      </Link>
                    </div>
                    <div className="col-5 pr-2 pl-0 my-auto fs-7 text-left">{formatUnit(c.amount, c.unit)}</div>
                    {idx < calls.length - 1 && <div className="border-bottom-dark-gray my-2" />}
                  </div>
                ))}
              </div>
            </div>
          </div>
        }
      >
        <div>
          {calls.slice(0, 20).map((c) => (
            <Tooltip key={c._id.toString()} tooltipText={`${getOrderNumber(c)}, called on ${formatDate(c.createdAt)}`}>
              <div className={"badge badge-sm badge-light m-1"}>{formatUnit(c.amount, c.unit)}</div>
            </Tooltip>
          ))}
          <div className="text-muted fs-7">and {calls.length - 20} more</div>
        </div>
      </HoverPopover>
    );
  return (
    <div>
      {calls.map((c) => (
        <Tooltip key={c._id.toString()} tooltipText={`${getOrderNumber(c)}, called on ${formatDate(c.createdAt)}`}>
          <div className={"badge badge-sm badge-light m-1"}>{formatUnit(c.amount, c.unit)}</div>
        </Tooltip>
      ))}
    </div>
  );
};

export default CallReferences;
