import { BSON } from "realm-web";
import { Forwarder, ForwarderExtended } from "../model/forwarder.types";
import { callFunction } from "../services/dbService";
import { doFuseSearch } from "./baseUtils";
import { UserData } from "../model/userData.types";
import { Address } from "../model/commonTypes";

export const F_SORTOPTIONS = [{ value: "name", label: "Name" }];

// Backend functions relating to forwarder
const UPSERTFORWARDER = "upsertForwarder";

/**
 * Inserts a new forwarder into the database.
 * @param forwarder forwarder that should be inserted into the database
 * @param newPersons List of users that need to be added
 * @returns  { Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false> } Result of the function
 */
export async function insertForwarder(
  forwarder: Forwarder,
  newPersons: Array<UserData>
): Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false> {
  return (await callUpsertForwarder(forwarder, true, newPersons)) as
    | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>
    | false;
}

/**
 * Updates an existing forwarder inside the database.
 * @param forwarder Forwarder that should be updated inside the database
 * @param forwarderId Optional id of forwarder if it is not contained in forwarder object
 * @returns { Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> } Result of the function
 */
export async function updateForwarder(
  forwarder: Partial<Forwarder>,
  forwarderId?: BSON.ObjectId
): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> {
  if (forwarderId) forwarder._id = forwarderId;
  return (await callUpsertForwarder(forwarder, false)) as Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false;
}

/**
 * Calls the upsert forwarder function in backend.
 * @param company Forwarder that should be upsert
 * @param insert True for insert, else update
 * @param newPersons Optional, list of users that should be added
 * @returns { Promise<false | Realm.Services.MongoDB.InsertOneResult | Realm.Services.MongoDB.UpdateResult> } Result of the function
 */
async function callUpsertForwarder(
  company: Partial<Forwarder | ForwarderExtended>,
  insert: boolean,
  newPersons?: Array<UserData>
): Promise<
  false | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>
> {
  return callFunction(UPSERTFORWARDER, [company, insert, newPersons]);
}

/**
 * Get all similar forwarders
 * @param forwarder the forwarder to get similar ones for
 * @param forwarders list of all forwarders
 * @returns {Array<Forwarder>} list of similar forwarders
 */
export const getSimilarForwarders = (forwarder: Forwarder, forwarders: Array<Forwarder>): Array<Forwarder> => {
  // Threshold 0.2 is always subject to change
  const threshold = 0.2;
  let similarForwarders = doFuseSearch(forwarders, forwarder.name, ["name"], { threshold });
  similarForwarders = similarForwarders.filter((f) => f._id.toString() !== forwarder._id.toString());
  return Array.from(new Set(similarForwarders));
};

/**
 * Get a default forwarder. Note: This forwarder has an invalid primary person!
 * @param name optional name of forwarder
 * @param address optional address of forwarder
 * @param phone optional phone number of forwarder
 * @param mail optional mail address of forwarder
 * @returns {Forwarder} default forwarder
 */
export const getDefaultForwarder = (name?: string, address?: Address, mail?: string, phone?: string): Forwarder => {
  return {
    _id: new BSON.ObjectId(),
    name: name || "",
    mail: mail || "",
    phone: phone || "",
    address: address ? [address] : [],
    primaryPerson: "",
    persons: [],
    blacklists: [],
    disabled: false,
    countries: [],
  };
};
