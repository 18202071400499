import { getArticleProperty } from "../commodityUtils";
import { PropertyType } from "../propertyUtils";
import { Property } from "../../model/property.types";
import { SelectedSpecificationUserData } from "../userUtils";
import { resolveFilePath } from "../fileUtils";
import { COMMODITY, FINISHEDPRODUCT } from "../../services/dbService";
import { MasterSpecificationType } from "../../components/specification/common/CustomTypes";
import { ORGANICCODE } from "./templateUtils";
import { getArticleProperties } from "../productArticleUtils";

/**
 * Get the html string for master specification of a commodity of finished product
 * @param specificationObject the commodity or finishedProduct document
 * @param originator originator/creator of the commodity
 * @param approver approver of commodity data
 * @param version version number of the master specification
 * @returns {string} html string for master specification
 */
export function createMasterSpecificationHTML(
  specificationObject: MasterSpecificationType,
  originator: SelectedSpecificationUserData,
  approver: SelectedSpecificationUserData,
  version: number
): string {
  const objectForSpecification =
    specificationObject.type === COMMODITY ? specificationObject.commodity : specificationObject.finishedProduct;
  const properties = getArticleProperties(objectForSpecification.properties);
  const allergens = properties.allergen;
  const category = properties.category[0];
  const purposeOfUse = properties.purposeOfUse;

  let documentInformationRows: Array<[key: string, value: string | Array<string>]>;
  let productSummaryRows: Array<[key: string, value: string | Array<string>]>;
  let generalInformationRows: Array<[key: string, value: string | Array<string>]>;
  let carcinogenicSubstancesRows: Array<[key: string, value: string]>;
  let physicalAndChemicalInformationRows: Array<[key: string, value: string]>;
  let heavyMetalInformationRows: Array<[key: string, value: string]>;
  let microBiologyInformationRows: Array<[key: string, value: string]>;
  let dietInformationRows: Array<[key: string, value: string]>;

  const confirmationInformationRows: Array<[key: string, value: string]> = [
    [
      "Confirmation of BSE / TSE Status<br><span style='font-weight: 200'>This is to certify that this product complies with all relevant current UK and EU Legislative requirements in regard to Transmissible Spongiform Encephalopathies (TSE) and Bovine Spongiform Encephalopathy (BSE) for human food, and so is free of TSE/BSE.</span>",
      "",
    ],
    [
      "Confirmation of GMO Status<br><span style='font-weight: 200'>This is to certify that this product is not manufactured from GM raw materials and is therefore not subject to labelling under current regulations.</span>",
      "",
    ],
    [
      "Confirmation of Non-Irradiation Status<br><span style='font-weight: 200'>This is to certify that this product, whole or in part, has not been subjected to Ionising Radiation as per European Directives.</span>",
      "",
    ],
    [
      "Confirmation of Nandrolone Status<br><span style='font-weight: 200'>This is to certify that this product, whole or in part, has not come into contact with Nandrolone or any of its precursors in any way.</span>",
      "",
    ],
    [
      "Confirmation of IOC Product Status<br><span style='font-weight: 200'>This is to certify that this product, whole or in part, has not come into contact with any product/s, which is banned by the IOC (International Olympics Committee) and or WADA.</span>",
      "",
    ],
    [
      "Confirmation of Animal Testing Status<br><span style='font-weight: 200'>This is to certify that all the products sold by RAWBIDS have not been tested on animals in any part of its manufacture in accordance with current regulations.</span>",
      "",
    ],
    [
      "Confirmation of Pesticides Status<br><span style='font-weight: 200'>This is to certify that the above-mentioned product complies with the EU max residue limits (MRLs) on pesticides.</span>",
      "",
    ],
    [
      "Confirmation of Nanoparticles Status<br><span style='font-weight: 200'>This is to certify that unless otherwise stated, the above-mentioned product is free of nanoparticles. Commission Recommendation, defines as follows: \"'Nanomaterial' means a natural, incidental or manufactured material containing particles, in an unbound state or as an aggregate or as an agglomerate and where, for 50 % or more of the particles in the number size distribution, one or more external dimensions is in the size range 1 nm - 100 nm\".</span>",
      "",
    ],
    [
      "Packaging Status<br><span style='font-weight: 200'>We hereby certify that the packaging used in the above-mentioned material conforms to EU regulations and subsequent amendments on food grade packaging</span>",
      "",
    ],
    [
      "Confirmation of PAH status<br><span style='font-weight: 200'>This is to certify that the above-mentioned product complies with the max PAH limits set by the EU regulations</span>",
      "",
    ],
  ];

  let gradeInformationRows: Array<[key: string, value: string]>;

  switch (specificationObject.type) {
    case COMMODITY: {
      const commodity = specificationObject.commodity;
      documentInformationRows = [
        ["Document Type", "Specification Sheet"],
        ["Doc. Identifier", `400${commodity.articleNo}00${version.toString().padEnd(4, "0")}`],
        ["Version", "1.0"],
        ["Revision", `${version}`],
        ["Release", `${approver.prename} ${approver.surname} [${approver.date.toISOString().split("T")[0]}]`],
      ];
      productSummaryRows = [
        ["Product Type", `${category && category.name.en}`],
        ["Article Number", commodity.articleNo],
        ["CAS Number", commodity.casNumber.join(" / ")],
        ["System ID", `${commodity._id.toString()}`],
        ["&nbsp", "&nbsp"],
      ];
      generalInformationRows = [
        ["Product Name", `<b>${commodity.title.en}</b>`],
        ["Product Quality", `${commodity.subtitle.en}`],
        ["Article Number", commodity.articleNo],
        [
          "Active Substance",
          commodity.activeSubstances.map((aS) => `${aS.percentage.toFixed(2)}% ${aS.substance.name.en}`).join(", "),
        ],
        ["Allergen", allergens.map((allergen) => allergen.name.en).join(", ")],
        ["Category", (getArticleProperty(commodity.properties, PropertyType.CATEGORY) as Property)?.name.en || "-"],
        [
          "Composition",
          (getArticleProperty(commodity.properties, PropertyType.COMPOSITION) as Property)?.name.en || "-",
        ],
        ["Botanical Name", commodity.botanicalName || "-"],
        ["Used Part", commodity.part.en || "-"],
        [
          "Extraction Source",
          (getArticleProperty(commodity.properties, PropertyType.EXTRACTIONSOURCE) as Property)?.name.en || "-",
        ],
        [
          "Extraction Solvent",
          (getArticleProperty(commodity.properties, PropertyType.SOLVENT) as Property)?.name.en || "-",
        ],
        ["Carrier", (getArticleProperty(commodity.properties, PropertyType.CARRIER) as Property)?.name.en || "-"],
        ["Organic", commodity.organic ? "YES" : "NO"],
        [
          "Analysis Method",
          (getArticleProperty(commodity.properties, PropertyType.ANALYSISMETHOD) as Property)?.name.en || "-",
        ],
        ["Origin Country", commodity.country.name || "-"],
        ["Shelf Life", commodity.shelfLife + " Months" || "-"],
        ["Storage Conditions", commodity.storageConditions.en || "-"],
        ["Packaging", (getArticleProperty(commodity.properties, PropertyType.PACKAGING) as Property)?.name.en || "-"],
        ["CAS Number", commodity.casNumber.join(" / ")],
        ["Total Residual Organic Solvents", commodity.totalResidualOrganicSolvents.en || "-"],
        ["Purpose of Use", purposeOfUse.map((pOU) => pOU.name.en).join(",")],
        ["German VAT Rate", commodity.vatPercentage ? `${commodity.vatPercentage}%` : "Not Specified"],
      ];
      carcinogenicSubstancesRows = [
        ["Aflatoxins", commodity.aflatoxins.en || "-"],
        ["PAH4", commodity.pah4.en || "-"],
        ["Benzopyrene", commodity.benzoypyrene.en || "-"],
        ["Ethylene oxide", commodity.maxAllowedWETO.en || "-"],
      ];
      physicalAndChemicalInformationRows = [
        ["Appearance", commodity.appearance.en || "-"],
        ["Odor", (getArticleProperty(commodity.properties, PropertyType.ODOR) as Property)?.name.en || "-"],
        [
          "Loss on Drying",
          commodity.lossOnDrying.amount
            ? (commodity.lossOnDrying.lessThan ? "≤ " : "") + commodity.lossOnDrying.amount.toString() + "%"
            : "-",
        ],
        [
          "Total Ash",
          commodity.ash.amount ? (commodity.ash.lessThan ? "≤ " : "") + commodity.ash.amount.toString() + "%" : "-",
        ],
        [
          "Bulk Density",
          commodity.density ? commodity.density.min + "kg/m³ to " + commodity.density.max + "kg/m³" : "-",
        ],
        ["Particle Size", commodity.particleSize.en || "-"],
        ["Specific Rotation", commodity.specificRotation.en || "-"],
        ["Purity", commodity.purity.en || "-"],
        ["pH", commodity.ph?.toString() || "-"],
      ];
      heavyMetalInformationRows = [["Max. Heavy Metals", commodity.maxAllowedHeavyMetals.en || "-"]];
      microBiologyInformationRows = [["Max. Microbiology", commodity.maxAllowedMicrobiology.en || "-"]];
      dietInformationRows = [
        [
          commodity.vegetarian
            ? "Vegetarian<br><span style='font-weight: 200'>Product is suitable for vegetarian diets.</span>"
            : "",
          commodity.vegetarian ? "YES" : "",
        ],
        [
          commodity.vegan ? "Vegan<br><span style='font-weight: 200'>Product is suitable for vegan diets.</span>" : "",
          commodity.vegan ? "YES" : "",
        ],
        [
          commodity.halal ? "Halal<br><span style='font-weight: 200'>Product is Halal certified.</span>" : "",
          commodity.halal ? "YES" : "",
        ],
        [
          commodity.kosher ? "Kosher<br><span style='font-weight: 200'>Product is Kosher certified.</span>" : "",
          commodity.kosher ? "YES" : "",
        ],
      ];
      confirmationInformationRows.push([commodity.regulatoryData.en || "", ""]);
      gradeInformationRows = [
        ["Food Grade", commodity.foodGrade ? "YES" : "NO"],
        ["Pharmaceutical Grade", commodity.pharmaceuticalGrade ? "YES" : "NO"],
        ["USP Grade", commodity.uspGrade ? "YES" : "NO"],
      ];
      break;
    }
    case FINISHEDPRODUCT: {
      const finishedProduct = specificationObject.finishedProduct;
      documentInformationRows = [
        ["Document Type", "Specification Sheet"],
        ["Doc. Identifier", `400${finishedProduct.articleNo}00${version.toString().padEnd(4, "0")}`],
        ["Version", "1.0"],
        ["Revision", `${version}`],
        ["Release", `${approver.prename} ${approver.surname} [${approver.date.toISOString().split("T")[0]}]`],
      ];
      productSummaryRows = [
        ["Product Type", `${category && category.name.en}`],
        ["Article Number", finishedProduct.articleNo],
        ["System ID", `${finishedProduct._id.toString()}`],
        ["&nbsp", "&nbsp"],
      ];
      generalInformationRows = [
        ["Product Name", `<b>${finishedProduct.title.en}</b>`],
        ["Product Quality", `${finishedProduct.subtitle.en}`],
        ["Article Number", finishedProduct.articleNo],
        [
          "Active Substance",
          finishedProduct.activeSubstances
            .map((aS) => `${aS.percentage.toFixed(2)}% ${aS.substance.name.en}`)
            .join(", "),
        ],
        ["Allergen", allergens.map((allergen) => allergen.name.en).join(", ")],
        [
          "Category",
          (getArticleProperty(finishedProduct.properties, PropertyType.CATEGORY) as Property)?.name.en || "-",
        ],
        [
          "Composition",
          (getArticleProperty(finishedProduct.properties, PropertyType.COMPOSITION) as Property)?.name.en || "-",
        ],
        [
          "Extraction Source",
          (getArticleProperty(finishedProduct.properties, PropertyType.EXTRACTIONSOURCE) as Property)?.name.en || "-",
        ],
        [
          "Extraction Solvent",
          (getArticleProperty(finishedProduct.properties, PropertyType.SOLVENT) as Property)?.name.en || "-",
        ],
        ["Carrier", (getArticleProperty(finishedProduct.properties, PropertyType.CARRIER) as Property)?.name.en || "-"],
        ["Organic", finishedProduct.organic ? "YES" : "NO"],
        [
          "Analysis Method",
          (getArticleProperty(finishedProduct.properties, PropertyType.ANALYSISMETHOD) as Property)?.name.en || "-",
        ],
        ["Origin Country", finishedProduct.country.name || "-"],
        ["Shelf Life", finishedProduct.shelfLife + " Months" || "-"],
        ["Storage Conditions", finishedProduct.storageConditions.en || "-"],
        [
          "Packaging",
          (getArticleProperty(finishedProduct.properties, PropertyType.PACKAGING) as Property)?.name.en || "-",
        ],
        ["Purpose of Use", purposeOfUse.map((pOU) => pOU.name.en).join(",")],
        ["German VAT Rate", finishedProduct.vatPercentage ? `${finishedProduct.vatPercentage}%` : "Not Specified"],
      ];
      carcinogenicSubstancesRows = [];
      physicalAndChemicalInformationRows = [];
      heavyMetalInformationRows = [];
      microBiologyInformationRows = [];
      dietInformationRows = [
        [
          finishedProduct.vegetarian
            ? "Vegetarian<br><span style='font-weight: 200'>Product is suitable for vegetarian diets.</span>"
            : "",
          finishedProduct.vegetarian ? "YES" : "",
        ],
        [
          finishedProduct.vegan
            ? "Vegan<br><span style='font-weight: 200'>Product is suitable for vegan diets.</span>"
            : "",
          finishedProduct.vegan ? "YES" : "",
        ],
        [
          finishedProduct.halal ? "Halal<br><span style='font-weight: 200'>Product is Halal certified.</span>" : "",
          finishedProduct.halal ? "YES" : "",
        ],
        [
          finishedProduct.kosher ? "Kosher<br><span style='font-weight: 200'>Product is Kosher certified.</span>" : "",
          finishedProduct.kosher ? "YES" : "",
        ],
      ];
      gradeInformationRows = [
        ["Food Grade", finishedProduct.foodGrade ? "YES" : "NO"],
        ["Pharmaceutical Grade", finishedProduct.pharmaceuticalGrade ? "YES" : "NO"],
        ["USP Grade", finishedProduct.uspGrade ? "YES" : "NO"],
      ];
      break;
    }
  }

  // building potential empty table entries
  const carcinogenicSubstancesRowsHTML =
    carcinogenicSubstancesRows.length !== 0
      ? `<table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px" >
      ${createHeader("Carcinogenic Substances")}
      <tbody>
        ${carcinogenicSubstancesRows.reduce((resHtml, def) => resHtml + createRow(def), "")}
      </tbody>
    </table>
    <br/>`
      : ``;

  const physicalAndChemicalInformationRowsHTML =
    physicalAndChemicalInformationRows.length !== 0
      ? `<table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px" >
      ${createHeader("Physical & Chemical Parameters")}
      <tbody>
        ${physicalAndChemicalInformationRows.reduce((resHtml, def) => resHtml + createRow(def), "")}
      </tbody>
    </table>
    <br/>`
      : ``;

  const heavyMetalInformationRowsHTML =
    heavyMetalInformationRows.length !== 0
      ? `<table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px" >
      ${createHeader("Heavy Metals")}
      <tbody>
        ${heavyMetalInformationRows.reduce((resHtml, def) => resHtml + createRow(def), "")}
      </tbody>
    </table>
    <br/>`
      : ``;

  const microBiologyInformationRowsHTML =
    microBiologyInformationRows.length !== 0
      ? `<table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px" >
      ${createHeader("Microbiology")}
      <tbody>
        ${microBiologyInformationRows.reduce((resHtml, def) => resHtml + createRow(def), "")}
      </tbody>
    </table>
    <br/>`
      : ``;

  const dietInformationRowsHTML = dietInformationRows.some((value) => value[1] !== "")
    ? `<table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px" >
      ${createHeader("Diet Information")}
      <tbody>
        ${dietInformationRows.reduce((resHtml, def) => resHtml + createRow(def), "")}
      </tbody>
    </table>
    <br/>`
    : ``;

  const productInfoTable =
    specificationObject.type === COMMODITY
      ? `<table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa;" >`
      : `<table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-bottom: 25px;" >`;

  return `<html lang="en">
<head>
  <link href="https://fonts.googleapis.com/css?family=Arimo" rel="stylesheet">
  <style>tr:nth-child(even) {background-color: white;}</style>
  <meta charset="UTF-8"/>
  <title>Specification Sheet ${objectForSpecification.title.en}</title>
</head>
<body style="font-family: Arimo, sans-serif">
    <img alt="Rawbids Logo" src="https://rawbids.com/images/logo-dark.png" width="180" style="float:right"/>
    <br/><br/><br/><br/>
    <table style="width: 100%"><tr>
    <td>
    <span style="font-size: 28px; font-weight: 900">Specification Sheet</span><br/><br/>
    <span style="font-size: 20px; font-weight: bold">${objectForSpecification.title.en}</span>
    <br/>
    <span style="font-size: 18px; font-weight: bold; font-style: italic">${objectForSpecification.subtitle.en}</span>
    </td>
   <td style="float:right">
    <div style="float: right; text-align: center; background-color: #333333; border-radius: 10px; padding: 5px; margin-bottom: 5px">
    <img alt="QR" style=" border-radius: 8px" src="https://v2018.api2pdf.com/zebra?apikey={{API_KEY}}&format=QR_CODE&value=https://${
      process.env.REACT_APP_BASE_URL || ""
    }${
    specificationObject.type === COMMODITY ? "/commodity/" : "/finishedProduct/"
  }${objectForSpecification._id.toString()}"/><br/><span style="font-size: 12px; text-align: center; color: white">Open digital<br/>specification sheet</span></div>
</td></tr></table>
   <table style="font-size:15px; width:100%; margin-top:10px"><tr><td style="width: 45%"> ${productInfoTable}
      ${createHeader("Product Information")}
      <tbody>
        ${productSummaryRows.reduce((resHtml, def) => resHtml + createRow(def), "")}
      </tbody>
    </table><br/></td><td style="width: 45%"><table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa;" >
      ${createHeader("Document Information")}
      <tbody>
        ${documentInformationRows.reduce((resHtml, def) => resHtml + createRow(def), "")}
      </tbody>
    </table><span style="font-size: 12px; float: right"><i>Detailed release information on last page</i></span></td></tr></table>
    <table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px" >
      ${createHeader("General Information")}
      <tbody>
        ${generalInformationRows.reduce((resHtml, def) => resHtml + createRow(def), "")}
      </tbody>
    </table>
    <br/>
    ${physicalAndChemicalInformationRowsHTML}
    ${carcinogenicSubstancesRowsHTML}
    ${heavyMetalInformationRowsHTML}
    ${microBiologyInformationRowsHTML}
    ${dietInformationRowsHTML}
    <table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px" >
      ${createHeader("Confirmations")}
      <tbody>
        ${confirmationInformationRows.reduce((resHtml, def) => resHtml + createRow(def), "")}
      </tbody>
    </table>
    <br/>
    <table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px" >
      ${createHeader("Grade")}
      <tbody>
        ${gradeInformationRows.reduce((resHtml, def) => resHtml + createRow(def), "")}
      </tbody>
    </table>
    <br/>
    ${
      approver && approver.signature && originator && originator.signature
        ? `<div >
      <table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px" >
        ${createHeader("Document Approval")}
        <tbody>
          <tr><td style="text-align: left" colspan="2">Document ID: 400${objectForSpecification.articleNo}00${version
            .toString()
            .padEnd(4, "0")}</td></tr>
          <tr><td style="text-align: left" colspan="2">Version: ${version}</td></tr>
          <tr><td style="text-align: left" colspan="2">Status: released</td></tr>
         ${
           specificationObject.type === COMMODITY && specificationObject.commodity.organic
             ? `<tr><td style="text-align: left" colspan="2">${ORGANICCODE}</td></tr>`
             : ""
         }
          <tr>
            <td>
              <table>
                <tbody>
                  <tr>
                    <td style="width: 50%; text-align: right">
                      <div>Originator</div>
                      <div><b>${originator.prename} ${originator.surname}</b></div>
                      <div><i>${originator.position}</i></div>
                      <div>Approved ${originator.date.toISOString().split("T")[0]}</div>
                    </td>
                    <td style="width: 50%;">
                      <img style="max-width: 150px; padding-left: 20px" src=${resolveFilePath(
                        originator.signature.path,
                        originator.signature.isPublic
                      )} alt="Signature"/>
                    </td>
                  </tr>
                </tbody>
              </table>
           </td>
           <td>
            <table>
               <tbody>
                 <tr>
                   <td style="width: 50%; text-align: right">
                      <div>Approver</div>
                      <div><b>${approver.prename} ${approver.surname}</b></div>
                      <div><i>${approver.position}</i></div>
                      <div>Released ${approver.date.toISOString().split("T")[0]}</div>
                   </td>
                   <td style="width: 50%;">
                     <img style="max-width: 150px; padding-left: 20px" src=${resolveFilePath(
                       approver.signature.path,
                       approver.signature.isPublic
                     )} alt="Signature"/>
                   </td>
                 </tr>
               </tbody>
             </table>
           </td>
          </tr>
        </tbody>
      </table>
    </div>`
        : ""
    }
    <br/>
    <hr><span>End of document.</span>
    <br/>
</body>
</html>
`;
}

const createHeader = (title: string) =>
  `<thead><tr style="background-color:#333333; color: #ffffff; text-align: left" ><th colSpan="2"><b>${title}</b></th></tr></thead>`;

const createRow = (row: [key: string, value: string | Array<string>]) => {
  return (
    row[0] &&
    `<tr><td style="text-align: left; min-width: 150px"><b>${row[0]}</b></td><td style="text-align: right;"><span>${
      Array.isArray(row[1]) ? row[1].join("<br/>") : row[1]
    }</span></td></tr>`
  );
};
