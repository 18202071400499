import _ from "lodash";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { CompanyExtended } from "../../../../model/company.types";
import { DataContextInternalType } from "../../../../context/dataContext";
import { Invoice } from "../../../../model/invoice.types";
import Search from "../../../common/Search";
import BaseListing from "../../../common/BaseListing";
import { paginate, PaginationState } from "../../../common/Pagination";
import { doFuseSearch } from "../../../../utils/baseUtils";
import InvoiceRow from "../../../finance/internal/InvoiceRow";

interface CustomerPageInvoicesProps {
  company: CompanyExtended;
  context: DataContextInternalType;
}

interface CustomerPageInvoicesState extends PaginationState {
  search: string;
  invoices: Array<Invoice>;
}

class CustomerPageInvoices extends PureComponent<CustomerPageInvoicesProps, CustomerPageInvoicesState> {
  constructor(props: CustomerPageInvoicesProps) {
    super(props);
    this.state = {
      search: "",
      currentPage: 1,
      pageSize: 25,
      invoices: this.getRelevantInvoices(props),
    };
  }

  componentDidUpdate = (
    prevProps: Readonly<CustomerPageInvoicesProps>,
    prevState: Readonly<CustomerPageInvoicesState>
  ) => {
    // Update if company changed
    if (!_.isEqual(prevProps.company, this.props.company)) {
      this.setState({ invoices: this.getRelevantInvoices(this.props) });
      return;
    }
    // Update if a change appeared, but it was not a state update, e.g. search. We assume it must be context update then
    if (_.isEqual(prevState, this.state)) {
      const invoices = this.getRelevantInvoices(this.props);
      if (!_.isEqual(invoices, this.state.invoices)) this.setState({ invoices });
    }
  };

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value, currentPage: 1 });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleCurrentPageChange = (currentPage: number) => this.setState({ currentPage });

  getRelevantInvoices = (props: CustomerPageInvoicesProps) => {
    const { invoice } = props.context;
    return invoice.filter((i) => i.company._id.toString() === props.company._id.toString());
  };

  render() {
    const { company, context } = this.props;
    const { search, currentPage, pageSize, invoices } = this.state;
    const headerDefinition = [
      { title: "Invoice" },
      { title: "Amount" },
      { title: "Reference" },
      { title: "Due" },
      { title: "File" },
      { title: "Action", className: "text-right" },
    ];
    const filteredInvoices = search.trim()
      ? doFuseSearch(invoices, search, ["invoiceNumber", "relatedOrder.orderNo"])
      : invoices;
    const paginatedInvoices = paginate(filteredInvoices, currentPage, pageSize);
    return (
      <div className="card bg-white">
        <div className="card-header border-0 mt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">Invoices</span>
            <span className="text-muted fw-bold fs-7">{invoices.length} Invoices</span>
          </h3>
          <div className="card-toolbar">
            <Search onSearch={this.handleSearch} value={search} />
          </div>
        </div>
        <div className="card-body p-4 pt-8">
          <BaseListing
            headerDefinition={headerDefinition}
            bodyContent={
              <>
                {paginatedInvoices.length > 0 ? (
                  paginatedInvoices.map((i) => (
                    <InvoiceRow key={i._id.toString()} invoice={i} compact={true} context={context} />
                  ))
                ) : (
                  <tr>
                    <td className="text-center" colSpan={10}>
                      No invoices found
                    </td>
                  </tr>
                )}
              </>
            }
            baseSize={25}
            currentPage={currentPage}
            pageSize={pageSize}
            documents={filteredInvoices}
            noHover={true}
            onPageChange={this.handleCurrentPageChange}
            onPageSizeChange={this.handlePageSizeChange}
          />
          <Link
            className="btn btn-outline btn-outline-light float-right mt-2"
            to={`/createInvoice/customer/${company._id.toString()}`}
          >
            New Invoice
          </Link>
        </div>
      </div>
    );
  }
}

export default CustomerPageInvoices;
