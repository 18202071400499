import _ from "lodash";
import { BSON } from "realm-web";
import {
  CO_ARCHIVED,
  CO_ARRIVEDATSTARTINGPORT,
  CO_CANCELED,
  CO_HANDLEDATCUSTOMS,
  CO_HANDLEDATWAREHOUSE,
  CO_ORDEREDATSUPPLIER,
  CO_ORDEREDBYCUSTOMER,
  CO_PERFORMINGSERVICES,
  CO_PROCESSINGATWAREHOUSE,
  CO_REQUESTEDBYCUSTOMER,
  CO_REQUESTEDSTOCK,
  CO_SHIPPEDFROMSUPPLIER,
  CO_SHIPPEDTOCUSTOMER,
  CO_SHIPPEDTOWAREHOUSE,
  CO_STATES,
  CO_T_SERVICEADDED,
  CO_TIMELINETYPE,
  CO_TRANSPORT,
  CO_TYPES,
  CustomerOrder,
  CustomerOrderExtended,
  CustomerOrderService,
  CustomerOrderTerms,
  CustomerOrderTimelineEntry,
  CustomerOrderTimelineEntryPayload,
  CustomerOrderTrackingInformation,
  T_AIRFREIGHT,
  T_EUSTOCK,
  T_SEAFREIGHT,
  T_SPECIALREQUEST,
  T_WAREHOUSE,
} from "../model/customerOrder.types";
import { callFunction } from "../services/dbService";
import { Address, AddressType, CustomerPriceInfo, OrderFile } from "../model/commonTypes";
import userService from "../services/userService";
import { SelectOption } from "../components/common/CustomSelect";
import { CustomerCustomerOrder, CustomerCustomerOrderExtended } from "../model/customer/customerCustomerOrder.types";
import { Service } from "../model/service.types";
import { DataContextCustomer, DataContextInternalType } from "../context/dataContext";
import { BASE_CURRENCY, Currencies, CUSTOMER_BASE_CURRENCY } from "./currencyUtils";
import {
  CUSTOMER_ORDER_TYPES,
  DateType,
  EXTENDED_ORDER_TYPES,
  getOrderNumber,
  isCustomerOrder,
  isSupplierOrder,
  ORDER_TYPES,
} from "./orderUtils";
import { formatCurrency, formatDate, getDocFromCollection } from "./baseUtils";
import { getDefaultSlackChannel, NotificationType, sendMessage } from "../services/slackService";
import { MatchingIncomingOrderableStock } from "../components/common/CustomTypes";
import { CONTRACT_TYPES, EXTENDED_CONTRACT_TYPES } from "./contractUtils";
import { Company } from "../model/company.types";
import { I_PAYMENTTARGETS } from "./invoiceUtils";
import { CustomerTermOptions } from "../components/common/CustomerTerms";
import { getAddressByType } from "./addressUtils";
import { CUSTOMER, getUserName, INTERNAL } from "./userUtils";
import { SupplierOrder } from "../model/supplierOrder.types";
import { General } from "../model/general.types";
import { Incoterm } from "./commodityUtils";
import { I_CREDITNOTECUSTOMER, I_CUSTOMERINVOICE, Invoice } from "../model/invoice.types";
import { isCustomerFinishedProduct, isFinishedProduct } from "./finishedProductUtils";
import { getCW, getFullCWString } from "./dateUtils";
import {
  Article,
  ArticleExtended,
  ArticleSnapshot,
  CustomerArticle,
  CustomerArticleExtended,
  formatArticleUnit,
  isAnyFinishedProduct,
} from "./productArticleUtils";

export interface ExtendedCustomerOrderTimelineEntry extends CustomerOrderTimelineEntry {
  text: string | JSX.Element;
  order: CustomerOrder;
}

export interface AlternativePrice {
  method: CO_TYPES;
  priceInfo: CustomerPriceInfo | null;
}

export const CO_FILTER_STATES = [
  { value: CO_ORDEREDBYCUSTOMER, label: "Ordered" },
  { value: CO_ORDEREDATSUPPLIER, label: "Commodities Ordered" },
  { value: CO_SHIPPEDFROMSUPPLIER, label: "Shipped By Supplier" },
  { value: CO_HANDLEDATCUSTOMS, label: "Customs Done" },
  { value: CO_SHIPPEDTOWAREHOUSE, label: "Shipped To Warehouse" },
  { value: CO_HANDLEDATWAREHOUSE, label: "Arrived At Warehouse" },
  { value: CO_PERFORMINGSERVICES, label: "Performing Services" },
  { value: CO_SHIPPEDTOCUSTOMER, label: "In Delivery" },
  { value: CO_ARCHIVED, label: "Archived" },
  { value: CO_CANCELED, label: "Canceled" },
];

export const CO_ARRIVAL_STATUS = [
  { value: "0", label: "Delivered" },
  { value: "-1", label: "less than 1 Week" },
  { value: "1-2", label: "1-2 Weeks" },
  { value: "2-4", label: "2-4 Weeks" },
  { value: "4-8", label: "4-8 Weeks" },
  { value: "8+", label: "more than 8 weeks" },
];

export const CO_TRANSPORT_MODE = [
  { value: T_WAREHOUSE, label: "Warehouse" },
  { value: T_AIRFREIGHT, label: "Airfreight" },
  { value: T_SEAFREIGHT, label: "Seafreight" },
  { value: "misc", label: "Miscellaneous" },
];

export const CO_SORTOPTIONS = [
  { value: "createdAt", label: "Order Date" },
  { value: "deliveryDate", label: "Delivery Date" },
  { value: "state", label: "Status" },
  { value: "amount", label: "Quantity" },
  { value: "commodity.title.en", label: "Commodity Name" },
  { value: "commodity.country.name", label: "Country" },
  { value: "customerReference", label: "Reference" },
];

// #TODO More detailed processing handling is done in RB-204
export const CO_PROCESSINGTASKS = [
  { value: "repackagingPerformed", label: "Repackaging performed" },
  { value: "deliveryNoteGenerated", label: "Delivery Note generated" },
  { value: "shippedToCustomer", label: "Shipped to Customer" },
] as const;

// File types
export const CO_DELIVERYNOTE = "deliveryNote";
export const CO_REQUESTCONFIRMATION = "requestConfirmation";
export const CO_ORDERCONFIRMATION = "orderConfirmation";
export const CO_CUSTOMERFILE = "customerFile";
export const CO_FORWARDINGORDER = "cOForwardingOrder";
export const CO_OTHER = "other";

export const CO_FILETYPES: Array<SelectOption> = [
  { value: CO_DELIVERYNOTE, label: "Delivery Note" },
  { value: CO_ORDERCONFIRMATION, label: "Order Confirmation" },
  { value: CO_REQUESTCONFIRMATION, label: "Request Confirmation" },
  { value: CO_CUSTOMERFILE, label: "Customer File" },
  { value: CO_FORWARDINGORDER, label: "Forwarding Order" },
  { value: CO_OTHER, label: "Other" },
];

export const CO_DELIVERYTERMS: Array<SelectOption> = Object.values(Incoterm).map((i) => {
  return { value: i, label: i };
});

export const CO_STANDARDINCOTERM: SelectOption = { value: Incoterm.DDP, label: Incoterm.DDP };

// Function names
export const UPSERTCUSTOMERORDER = "upsertCustomerOrder";
export const UPDATESERVICEONORDERS = "updateServiceOnOrders";
export const BOOKSERVICE = "bookService";
export const GETCUSTOMERORDERCALCULATION = "getCustomerOrderCalculation";
export const GETCUSTOMERPRICEGRADUATIONDATA = "getCustomerPriceGraduationData";
export const GETAVAILABLEANDINCOMINGSTOCK = "getAvailableAndIncomingStock";
export const GETINCOMINGORDERABLESTOCK = "getIncomingOrderableStock";
export const PLACECUSTOMERORDER = "placeCustomerOrder";
export const GETAPPROXIMATEDELIVERYTIME = "getApproximateDeliveryTime";
export const GETEARLIESTDELIVERYDATE = "getEarliestDeliveryDate";

export const FALLBACKSUPPLIERPREPARATIONTIME = 5;

export const O_DELIVERYTIMES: { [method: string]: string } = {
  [T_SEAFREIGHT]: "9-10",
  [T_AIRFREIGHT]: "2",
  [T_WAREHOUSE]: "1",
};

/**
 * Get fallback values for delivery times if the backend values cannot be retrieved
 * @param transport transportation method for which the fallback value should be retrieved
 * @returns { number } fallback delivery time in days
 */
export function getFallbackDeliveryTime(transport: CO_TYPES | CO_TRANSPORT): number {
  switch (transport) {
    case T_SEAFREIGHT:
      return 70;
    case T_AIRFREIGHT:
      return 14;
    case T_EUSTOCK:
      return 14;
    case T_WAREHOUSE:
      return 7;
    default:
      return 70;
  }
}

/**
 * Returns the time until the given delivery date as string to display
 * @param deliveryDate date when the order should be delivered
 * @returns { string } time until delivery as string label
 */
export const getDeliveryTimeLabel = (deliveryDate: Date): string => {
  const today = new Date();
  const deliveryTime = (deliveryDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24);
  if (deliveryTime < 7) return "a few days";
  const deliveryTimeInWeeks = Math.floor(deliveryTime / 7);
  return deliveryTimeInWeeks + "-" + (deliveryTimeInWeeks + 1) + " weeks"; // displays one more week than calculated as buffer
};

/**
 * Get the approximate delivery time per transport method in days
 * @param transport Method whose time should be retrieved
 * @param isCustomerOrder boolean, if true, uses values for customer orders, if false values for supplier orders
 * @returns { Promise<number> } Delivery time in days; -1 if no delivery time for the given transport was found
 */
export async function getApproximateDeliveryTime(
  transport: CO_TYPES | CO_TRANSPORT,
  isCustomerOrder: boolean
): Promise<number> {
  const approximateDeliveryTime: General = await callFunction(GETAPPROXIMATEDELIVERYTIME, [transport, isCustomerOrder]);
  return Number(approximateDeliveryTime.value);
}

/**
 * Returns a formatted string based on the given date format (fixed/CW)
 * @param date the date to format
 * @param prefix optional, can be used if another prefix than "CW" should be used for the week display (e.g. "Week")
 * @param targetDateType optional, indicates as what the date should be formatted (CW, Fix)
 * @returns { string } the formatted target date string
 */
export function formatDateFromType(date: Date | null, targetDateType?: DateType, prefix?: string): string {
  if (date) {
    return targetDateType === DateType.CW
      ? `${prefix ?? "CW"} ${getCW(date)}-${date.getFullYear()}`
      : targetDateType === DateType.FIX
      ? `${formatDate(date)} (fixed)`
      : `${formatDate(date)}`;
  }
  return "";
}

/**
 * Returns the changedETA or targetDate as formatted string
 * If the order is a supplier order, the string will be formatted as CW
 * If the order is a customer order, the string will be formatted depending on the type (CW/Fix/none)
 * @param order the order which includes the targetDate/changedETA to format
 * @param prefix optional, can be used if another prefix than "CW" should be used for the week display (e.g. "Week")
 * @param useTargetDateOnly optional, if true only the target date will be used
 * @returns { string } the formatted date string
 */
export function formatOrderDate(
  order: ORDER_TYPES | EXTENDED_ORDER_TYPES | CONTRACT_TYPES | EXTENDED_CONTRACT_TYPES,
  prefix?: string,
  useTargetDateOnly?: boolean
): string {
  return isCustomerOrder(order)
    ? formatDateFromType(
        useTargetDateOnly ? order.targetDate : order.changedETA || order.targetDate,
        useTargetDateOnly ? order.targetDateType : order.changedETAType || order.targetDateType,
        prefix
      )
    : isSupplierOrder(order)
    ? getFullCWString(useTargetDateOnly ? order.targetDate : order.changedETA || order.targetDate, prefix)
    : order.targetDate
    ? getFullCWString(order.targetDate, prefix)
    : "-";
}

/**
 * Generate the processing tasks check list.
 * @returns { Array<{task: SelectOption, done: boolean}> } List of tasks, marked as undone
 */
export function getProcessingTasksChecklist(
  order: CustomerOrderExtended
): Array<{ task: SelectOption; done: boolean }> {
  const taskCheckList = [];
  for (let i = 0; i < CO_PROCESSINGTASKS.length; i++) {
    const task = CO_PROCESSINGTASKS[i];
    let done = false;
    switch (task.value) {
      case "repackagingPerformed":
        done = !!order.shippingInformation;
        break;
      case "deliveryNoteGenerated":
        done = !!order.files.find((f) => f.type === "deliveryNote");
        break;
      case "shippedToCustomer":
        done = ([CO_SHIPPEDTOCUSTOMER, CO_ARCHIVED] as Array<CO_STATES>).includes(order.state);
        break;
    }
    taskCheckList.push({ task, done });
  }
  return taskCheckList;
}

/**
 * Calls the getIncomingOrderableStock function in backend.
 * @param commodity ID of the commodity whose incoming stock should be received
 * @param amount amount to get matching incoming stock for
 * @param currencies currency exchange rates
 * @param targetCurrency optional, target currency
 * @returns { Promise<MatchingIncomingOrderableStock> }
 */
export async function getIncomingOrderableStock(
  commodity: BSON.ObjectId | string,
  amount: number,
  currencies: Currencies,
  targetCurrency?: string
): Promise<MatchingIncomingOrderableStock> {
  return callFunction(GETINCOMINGORDERABLESTOCK, [
    commodity,
    amount,
    currencies,
    targetCurrency ?? CUSTOMER_BASE_CURRENCY,
  ]);
}

/**
 * Inserts a customer order into the database.
 * @param customerOrder Order that should be inserted into the database
 * @returns { Promise<{ res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>, orderNumber: string } | false> } Result of the insert
 */
export async function insertCustomerOrder(
  customerOrder: CustomerOrder
): Promise<{ res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; orderNumber: string } | false> {
  return (await callUpsertCustomerOrder(customerOrder, true)) as
    | { res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; orderNumber: string }
    | false;
}

/**
 * Updates a customer order inside the database.
 * @param customerOrder Order that should be updated - partial orders are allowed
 * @param customerOrderId Optional id of customer order if it is not contained in supplier object
 * @param timelineEntry Optional timeline entry
 * @returns { Promise<{res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>, orderNumber: string } | false> } Result of the update
 */
export async function updateCustomerOrder(
  customerOrder: Partial<CustomerOrder>,
  customerOrderId?: BSON.ObjectId,
  timelineEntry?: CustomerOrderTimelineEntry
): Promise<{ res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>; orderNumber: string } | false> {
  if (customerOrderId) customerOrder._id = customerOrderId;
  return (await callUpsertCustomerOrder(customerOrder, false, timelineEntry)) as
    | { res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>; orderNumber: string }
    | false;
}

/**
 * Calls the upsert customer order function in the backend.
 * @param customerOrder Order that should be upserted
 * @param insert Indicates whether the insert or the update case should be called
 * @param timelineEntry Optional timeline entry
 * @returns { Promise<{ res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>, orderNumber: string } | false> } Result of the upsert
 */
async function callUpsertCustomerOrder(
  customerOrder: Partial<CustomerOrder>,
  insert: boolean,
  timelineEntry?: CustomerOrderTimelineEntry
): Promise<
  | {
      res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>;
      orderNumber: string;
    }
  | false
> {
  return callFunction(UPSERTCUSTOMERORDER, [customerOrder, insert, timelineEntry]);
}

/**
 * Wrapper for updating multiple customer orders after a service was changed.
 * @param service Service that changed
 * @param customerOrderIds Customer orders that are affected
 * @returns { Promise<boolean> } Indicating the success of the operation
 */
export async function updateServiceOnMultipleCustomerOrders(
  service: CustomerOrderService,
  customerOrderIds: Array<BSON.ObjectId>
): Promise<boolean> {
  return callFunction(UPDATESERVICEONORDERS, [service, customerOrderIds]);
}

/**
 * Book a service for an order
 * @param order related order
 * @param service order service with all information about price, etc. that should be added
 * @param timelineEntry timeline entry for order
 * @returns {Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false>}
 */
export async function bookService(
  order: CUSTOMER_ORDER_TYPES,
  service: CustomerOrderService,
  timelineEntry: CustomerOrderTimelineEntry
): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> {
  return callFunction(BOOKSERVICE, [order, service, timelineEntry]);
}

/**
 * Get a file object for customer order files
 * @param path path to the file
 * @param type type of the file
 * @returns {OrderFile} order file
 */
export const getCustomerOrderFile = (path: string, type: typeof CO_DELIVERYNOTE): OrderFile => {
  return {
    _id: new BSON.ObjectId(),
    date: new Date(),
    path,
    type,
  } as OrderFile;
};

/**
 * Get the version number of a document pdf by the order
 * @param order given order
 * @param date Date of given document
 * @param doctype string the type of the document
 * @returns {number} the version number of the document
 */
export const getDocumentVersion = (
  order: ORDER_TYPES | EXTENDED_ORDER_TYPES | CONTRACT_TYPES | EXTENDED_CONTRACT_TYPES,
  date: Date,
  doctype: string
): number => {
  const documents = order.files
    .filter((f) => f.type === doctype)
    .sort((a: OrderFile, b: OrderFile) => {
      return a.date.getTime() - b.date.getTime();
    });
  let versionNo = -1;
  if (documents.length !== 0) {
    versionNo = documents.findIndex((d) => d.date === date);
  }
  return versionNo + 1;
};

/**
 * Generates a timeline entry for a customer order
 * @param type Type of the entry
 * @param payload Optional payload of the entry
 * @returns { CustomerOrderTimelineEntry } Timeline entry object
 */
export function getCustomerOrderTimelineEntry(
  type: CO_TIMELINETYPE,
  payload?: CustomerOrderTimelineEntryPayload
): CustomerOrderTimelineEntry {
  return {
    _id: new BSON.ObjectId(),
    date: new Date(),
    type: type,
    person: userService.getUserId(),
    payload: payload ?? null,
  } as CustomerOrderTimelineEntry;
}

/**
 * Get default tracking information
 * @returns { CustomerOrderTrackingInformation } tracking information object
 */
export function getDefaultTrackingInformation(): CustomerOrderTrackingInformation {
  return {
    trackingNumber: "",
    deliveryCompany: "",
    trackingLink: "",
  };
}

/**
 * Get the matching tab for the order's state
 * @param order Order whose tab should be resolved
 * @return {string} the matching order detail page tab
 */
export const getTabThatRequiresAttention = (order: CUSTOMER_ORDER_TYPES): string => {
  switch (order.state) {
    case CO_REQUESTEDBYCUSTOMER:
    case CO_REQUESTEDSTOCK:
      return "requested";
    case CO_ORDEREDBYCUSTOMER:
    case CO_ORDEREDATSUPPLIER:
      return "ordered";
    case CO_ARRIVEDATSTARTINGPORT:
    case CO_SHIPPEDFROMSUPPLIER:
    case CO_HANDLEDATCUSTOMS:
    case CO_SHIPPEDTOWAREHOUSE:
      return "shipping";
    case CO_HANDLEDATWAREHOUSE:
    case CO_PROCESSINGATWAREHOUSE:
    case CO_PERFORMINGSERVICES:
      return "processing";
    case CO_SHIPPEDTOCUSTOMER:
    case CO_CANCELED:
    case CO_ARCHIVED:
      return "closed";
    default:
      return "";
  }
};

/**
 * Get the matching rank for the order's state
 * @param order customer order for which the ranking should be checked
 * @param usePreviousState optional, previousState will be used to get the ranking if true
 * @return {number} the rank according to the order's state
 */
export const getOrderStateRanking = (order: CUSTOMER_ORDER_TYPES, usePreviousState?: boolean): number => {
  const state = usePreviousState ? order.previousState : order.state;
  switch (state) {
    case CO_REQUESTEDBYCUSTOMER:
    case CO_REQUESTEDSTOCK:
      return 0;
    case CO_ORDEREDBYCUSTOMER:
      return 0.5;
    case CO_ORDEREDATSUPPLIER:
      return 1;
    case CO_ARRIVEDATSTARTINGPORT:
      return 1.5;
    case CO_SHIPPEDFROMSUPPLIER:
      return 2;
    case CO_HANDLEDATCUSTOMS:
      return 3;
    case CO_SHIPPEDTOWAREHOUSE:
      return 4;
    case CO_HANDLEDATWAREHOUSE:
      return 5;
    case CO_PERFORMINGSERVICES:
    case CO_PROCESSINGATWAREHOUSE:
      return 6;
    case CO_SHIPPEDTOCUSTOMER:
      return 7;
    case CO_ARCHIVED:
      return 8;
    case CO_CANCELED:
      return 9;
    default:
      return -1;
  }
};

/**
 * Create and book an order service
 * @param order customer order
 * @param service the service to book
 * @param price the price for the service
 * @returns {Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false>}
 */
export const bookOrderService = async (
  order: CUSTOMER_ORDER_TYPES,
  service: Service,
  price: number
): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> => {
  const timelineEntry: CustomerOrderTimelineEntry = {
    _id: new BSON.ObjectId(),
    date: new Date(),
    type: CO_T_SERVICEADDED,
    person: userService.getUserId(),
    payload: { reference: service._id.toString(), name: `RB-${service.serviceNo} ${service.title.en}` },
  };
  const orderService: CustomerOrderService = {
    service: service,
    performed: false,
    files: [],
    priceOrderCurrency: price,
  };
  return await bookService(order, orderService, timelineEntry);
};

/**
 * Calls the get customer order calculation function in backend.
 * @param article Commodity or finished product whose prices should be calculated
 * @param amount Amount that is required
 * @param method Method that should be used for price retrieval
 * @param currencies currency exchange rates
 * @param targetCurrency optional, target currency
 * @param skip optional, enum to skip selling prices or supplier price calculation. possible values "selling" | "supplier"
 * @param supplierId optional, id of a specifically selected supplier
 * @param allowFallback optional, flag if a fallback should be used if no existing prices
 * @param supplierOrderId optional, id of a specific supplier order to get a warehouse price for
 * @returns { Promise<{
 *     unitPrice: number;
 *     totalPrice: number;
 *   }> } Promise which contains the price info
 */
export async function getCustomerOrderCalculation(
  article: ArticleExtended | ArticleSnapshot,
  amount: number,
  method: CO_TYPES,
  currencies: Currencies,
  targetCurrency?: string,
  skip?: "selling" | "supplier",
  supplierId?: string,
  allowFallback?: boolean,
  supplierOrderId?: string | BSON.ObjectId
): Promise<CustomerPriceInfo | null> {
  if (amount === 0)
    return {
      unitPrice: 0,
      totalPrice: 0,
    };
  if (!isFinishedProduct(article))
    return callFunction(GETCUSTOMERORDERCALCULATION, [
      article,
      amount,
      method,
      targetCurrency ?? CUSTOMER_BASE_CURRENCY,
      currencies,
      skip,
      supplierId,
      allowFallback,
      supplierOrderId,
    ]);
  else return null;
}

/**
 * Get customer order calculations for all methods
 * @param commodity Article whose prices should be calculated
 * @param amount Amount that is required
 * @param currencies currency exchange rates
 * @param targetCurrency optional, target currency
 * @param skip optional, enum to skip selling prices or supplier price calculation. possible values "selling" | "supplier"
 * @param supplierId optional, id of a specifically selected supplier
 * @returns {Promise<Array<AlternativePrice> | null>} Promise containing a list of alternative prices or null
 */
export async function getAlternativePrices(
  commodity: ArticleExtended | ArticleSnapshot,
  amount: number,
  currencies: Currencies,
  targetCurrency?: string,
  skip?: "selling" | "supplier",
  supplierId?: string
): Promise<Array<AlternativePrice>> {
  const methods = [T_SEAFREIGHT, T_AIRFREIGHT, T_WAREHOUSE];
  const results = [];
  for (let i = 0; i < methods.length; i++) {
    results.push({
      method: methods[i],
      priceInfo: await getCustomerOrderCalculation(
        commodity,
        amount,
        methods[i],
        currencies,
        targetCurrency,
        skip,
        supplierId,
        !supplierId // only fallback if no supplier is selected
      ),
    });
  }
  return results;
}

/**
 * Get price graduation data
 * @param commodity a commodity id
 * @param type order type, i.e. limit, warehouse, fastest or cheapest
 * @param context data context with currencies
 * @returns {Promise<Array<{x: number, y: number}> | null>} series of coordinates or null
 */
export async function getCustomerPriceGraduationData(
  commodity: BSON.ObjectId | string,
  type: CO_TRANSPORT,
  context: React.ContextType<typeof DataContextCustomer>
): Promise<Array<{ x: number; y: number }> | null> {
  return callFunction(GETCUSTOMERPRICEGRADUATIONDATA, [commodity, type, CUSTOMER_BASE_CURRENCY, context.currencies]);
}

/**
 * Get the available and incoming stock for a commodity
 * @param commodity Commodity that should be checked
 * @param onlyAvailable Flag to get only the available amount, incoming stock will be {}
 * @returns { Promise<[amount: number, incomingStock: {[weeks: string]: number}, earliestExpiry: Date | undefined]> } Tripe of total available amount, incoming stock object and the earliest expiry
 */
export async function getAvailableAndIncomingStock(
  commodity: BSON.ObjectId | string,
  onlyAvailable?: boolean
): Promise<[amount: number, incomingStock: { [weeks: string]: number }, earliestExpiry: Date | undefined]> {
  return callFunction(GETAVAILABLEANDINCOMINGSTOCK, [commodity, onlyAvailable]);
}

/**
 * Get the available stock
 * @param article Article that should be checked
 * @returns {[number, { [weeks: string]: number }, Date | undefined]} tripe with total amount of available stock
 */
export async function getAvailableStock(
  article: CustomerArticleExtended | CustomerArticle | undefined
): Promise<
  [number, Record<string, never>, Date | undefined] | [number, { [weeks: string]: number }, Date | undefined]
> {
  if (!article || isCustomerFinishedProduct(article, CUSTOMER)) return [0, {}, undefined];
  const data = await getAvailableAndIncomingStock(article._id, true);
  if (!data) return [0, {}, undefined];
  return data;
}

/**
 * Places a new customer order
 * @param customerOrder Wrapper for the customer order information
 * @param supplierOrderId ID of the related supplier order
 * @param currencies Currencies - needed to converse costs
 * @returns { Promise<{ res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; orderNumber: string; error?: string } | undefined> } Result of the insert
 */
export async function placeCustomerOrder(
  customerOrder: Partial<CustomerCustomerOrder>,
  supplierOrderId?: string,
  currencies?: Currencies
): Promise<
  { res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; orderNumber: string; error?: string } | undefined
> {
  return callFunction(PLACECUSTOMERORDER, [customerOrder, supplierOrderId, currencies]);
}

/**
 * Determines whether the order is active or not.
 * @param order Order that should be checked
 * @returns { boolean } True if order is active, false if not
 */
export function isActive(
  order: CustomerOrder | CustomerCustomerOrder | CustomerOrderExtended | CustomerCustomerOrderExtended
): boolean {
  return !([CO_ARCHIVED, CO_CANCELED] as Array<CO_STATES>).includes(order.state);
}

/**
 * Loads the terms for a given order into the form of the input fields
 * @param order CustomerOrder, the given order
 * @returns {CustomerTermOptions} for an order or contract
 */
export function getCustomerTermOptionFromCustomerOrder(
  order: CustomerOrderExtended | CustomerCustomerOrderExtended
): CustomerTermOptions {
  let paymentTerm = I_PAYMENTTARGETS[4];
  let customPaymentTerm = "";
  let customPaymentTermCondition = "";
  let deliveryTerm = CO_STANDARDINCOTERM;
  const deliveryCity = order.terms?.deliveryCity
    ? order.terms.deliveryCity
    : getAddressByType(order.company.address, AddressType.A_SHIPPING)?.city || "";
  let note = "";
  // try to preload data from order if given
  if (order.terms) {
    const isCustom = !I_PAYMENTTARGETS.some((pt) => pt.label === order.terms?.paymentTerms);
    if (isCustom) {
      paymentTerm = { value: "custom", label: "Custom" };
      customPaymentTerm = order.terms.paymentTerms.replace(" days", "").trim();
    } else {
      paymentTerm =
        I_PAYMENTTARGETS.find((pt) => pt.label === order.terms?.paymentTerms.replace(" days", "")) ||
        I_PAYMENTTARGETS[4];
    }
    customPaymentTermCondition = order.terms.paymentTermConditions || "";
    deliveryTerm = { value: order.terms.deliveryTerms, label: order.terms.deliveryTerms };
    note = order.terms.note || "";
  } else if (!order.terms && (order.company.paymentTerms || order.company.paymentTarget)) {
    // try to preload data from customer paymentTerms if given
    return getCustomerTermOptionFromCompany(
      order.company,
      `${order.amount} ${order.unit} for ${order.company.name}`,
      order._id.toString()
    );
  }
  return {
    id: order._id.toString(),
    title: `${order.amount} ${formatArticleUnit(order.unit)} for ${order.company.name}`,
    paymentTerm: paymentTerm,
    customPaymentTerm: customPaymentTerm,
    customPaymentTermCondition: customPaymentTermCondition,
    note: note,
    deliveryTerm: deliveryTerm,
    deliveryCity: deliveryCity,
    cleanLabel: order.terms?.cleanLabel ?? false,
  };
}

/**
 * Loads the terms for an order into the form of the input fields by using the company
 * @param company Company, the given customer
 * @param title string, the title of this term
 * @param id string, the order or contract id later on
 * @returns {CustomerTermOptions} for an order or contract
 */
export function getCustomerTermOptionFromCompany(company: Company, title: string, id: string): CustomerTermOptions {
  let paymentTerm = I_PAYMENTTARGETS[4];
  let customPaymentTerm = "";
  let customPaymentTermCondition = "";
  if (company.paymentTerms) {
    // try to preload data from customer paymentTerms if given
    const isCustom = !I_PAYMENTTARGETS.some(
      (pt) => company.paymentTerms && pt.label === company.paymentTerms?.paymentTarget
    );
    if (isCustom) {
      paymentTerm = { value: "custom", label: "Custom" };
      customPaymentTerm = company.paymentTerms.paymentTarget.replace(" days", "").trim();
    } else {
      paymentTerm =
        I_PAYMENTTARGETS.find((pt) => pt.label === company.paymentTerms?.paymentTarget) || I_PAYMENTTARGETS[4];
    }
    customPaymentTermCondition = company.paymentTerms?.paymentTargetConditions || "";
  } else if (!company.paymentTerms && company.paymentTarget !== undefined) {
    // try to preload data from customer paymentTarget if given and paymentTerms are missing
    paymentTerm = I_PAYMENTTARGETS.find((pt) => pt.value === company.paymentTarget?.toString()) || I_PAYMENTTARGETS[4];
  }
  return {
    id: id,
    title: title,
    paymentTerm: paymentTerm,
    customPaymentTerm: customPaymentTerm,
    customPaymentTermCondition: customPaymentTermCondition,
    note: "",
    deliveryTerm: CO_STANDARDINCOTERM,
    deliveryCity: getAddressByType(company.address, AddressType.A_SHIPPING)?.city || "",
    cleanLabel: company.cleanLabel ?? false,
  };
}

/**
 * Reformat the payment terms of a customer to be inserted into a new order
 * @param company Company, the customer of the order or contract
 * @param deliveryAddress Optional, if set this address is used to resolve the delivery city
 * @param cleanLabel Optional, if set the clean label flag of the company object is overwritten
 * @returns {CustomerOrderTerms} the terms reformatted for the database
 */
export function getPaymentTermsFromCompany(
  company: Company,
  deliveryAddress?: Address,
  cleanLabel?: boolean
): CustomerOrderTerms {
  let paymentTerm = I_PAYMENTTARGETS[4].label;
  const customPaymentTermCondition = company.paymentTerms?.paymentTargetConditions || "";
  const deliveryCity = (deliveryAddress ?? getAddressByType(company.address, AddressType.A_SHIPPING))?.city || "";
  if (company.paymentTerms) {
    // try to preload data from customer paymentTerms if given
    paymentTerm = company.paymentTerms.paymentTarget;
  } else if (!company.paymentTerms && company.paymentTarget !== undefined && company.paymentTarget !== null) {
    // try to preload data from customer paymentTarget if given and paymentTerms are missing
    if (company.paymentTarget === 0) {
      paymentTerm = "Due Immediately";
    } else if (company.paymentTarget === -1) {
      paymentTerm = "In Advance";
    } else {
      paymentTerm = company.paymentTarget.toString() + " days";
    }
  }
  return {
    paymentTerms: paymentTerm,
    paymentTermConditions: customPaymentTermCondition,
    deliveryTerms: CO_STANDARDINCOTERM.label,
    deliveryCity: deliveryCity,
    note: "",
    cleanLabel: cleanLabel ?? company.cleanLabel,
  };
}

/**
 * Sort customer orders
 * @param orders list of customer orders
 * @returns {Array<CustomerOrder | CustomerCustomerOrder>} list of customer orders sorted by state
 */
export const sortCustomerOrdersByState = (
  orders: Array<CustomerOrder | CustomerCustomerOrder>
): Array<CustomerOrder | CustomerCustomerOrder> => _.orderBy(orders, (o) => getOrderStateRanking(o), "asc");

/**
 * Get the earliest estimated delivery date for a customer order (taking the time for a supplier order in consideration)
 * @param method transport type, e.g. sea freight
 * @param supplierId optional, the supplier from which the goods are ordered
 * @param article optional, the commodity or finished product which is ordered
 * @param amount optional, the ordered amount
 * @returns {Promise<Date>} the earliest delivery date
 */
export async function getEarliestDeliveryDate(
  method: CO_TYPES,
  supplierId?: string | BSON.ObjectId,
  article?: Article | ArticleExtended | ArticleSnapshot,
  amount?: number
): Promise<Date> {
  return callFunction<Date>(GETEARLIESTDELIVERYDATE, [
    method,
    supplierId && typeof supplierId === "string" ? supplierId : supplierId ? supplierId.toString() : undefined,
    article?._id.toString(),
    amount,
  ]);
}

/**
 * Function to send order specific slack notifications.
 * @param customerOrder the corresponding customer order.
 * @param id the id of the corresponding customer order.
 * @param company Customer that created the customer order
 */
export function sendSlackNotification(
  customerOrder: CustomerCustomerOrder | CustomerCustomerOrderExtended,
  id: string,
  company: Company
) {
  const { amount, commodity, currency, totalPrice, limitTimeFrame, priceCommodities, request } = customerOrder;
  const user = userService.getUserData();
  let message = `<https://${process.env.REACT_APP_BASE_URL || ""}/customerOrder/${id}|*${getOrderNumber(
    customerOrder
  )}*>: <https://${process.env.REACT_APP_BASE_URL || ""}/customer/${company._id.toString()}|*${
    company.name || ""
  }*> just ordered *${amount}${formatArticleUnit(commodity.unit)}* of <https://${
    process.env.REACT_APP_BASE_URL || ""
  }/${isAnyFinishedProduct(commodity) ? "finishedProduct" : "commodity"}/${commodity._id.toString()}|*${
    commodity.title.en || ""
  }*> ${
    request
      ? `as a *request* with *target price: ${formatCurrency(priceCommodities, currency)}/kg* and a *time frame of ${
          limitTimeFrame || 0
        } days*. Total amount: *${formatCurrency(totalPrice, BASE_CURRENCY)}*`
      : `for *${formatCurrency(totalPrice, currency)}*`
  } `;
  if (user && user.type === INTERNAL) {
    message += ` (by ${getUserName(user)})`;
  }
  sendMessage(getDefaultSlackChannel(false, NotificationType.ORDER), message);
}

/**
 * Check if given amount is deliverable due to packaging sizes of the commodity or finished product
 * @param article the commodity or finished product
 * @param currentAmount the amount that should be ordered
 * @param currentMethod optional, given order method
 * @returns {{upper: number, lower: number} | null} object with next higher and lower suitable amount that can be delivered
 */
export function validateAmount(
  article: ArticleExtended | ArticleSnapshot,
  currentAmount: number,
  currentMethod: CO_TYPES
): { upper: number; lower: number } | null {
  let suitableAmount: { upper: number; lower: number } | null = { upper: 0, lower: 0 };
  if (article) {
    const sizes = Array.from(
      new Set(
        article.packagingSizes && article.packagingSizes.length > 0
          ? article.packagingSizes.map((pS) => {
              return pS.packagingSize;
            })
          : [25] // if no packagingSizes are given, use fallback value of 25
      )
    );
    if (sizes.some((s) => currentAmount % s === 0) || currentMethod === T_SPECIALREQUEST) {
      suitableAmount = null;
    } else {
      for (let i = 0; i < sizes.length; i++) {
        const remainder = currentAmount % sizes[i];
        const newSuitableAmount = {
          upper: currentAmount - remainder + sizes[i],
          lower: currentAmount - remainder,
        };
        // if suitableAmounts 0 then always set first time
        if (suitableAmount.upper === 0 && suitableAmount.lower === 0) {
          suitableAmount = newSuitableAmount;
        }
        // give the smallest range for suitable amounts
        if (newSuitableAmount.upper - newSuitableAmount.lower < suitableAmount.upper - suitableAmount.lower) {
          suitableAmount = newSuitableAmount;
        }
      }
    }
  }
  return suitableAmount;
}

/**
 * Get the presumably earliest delivery date for yet incoming stock
 * @param incomingStock the incoming stock
 * @returns { Date } expected delivery date
 */
export function getIncomingStockDeliveryDate(incomingStock: MatchingIncomingOrderableStock): Date {
  const date = new Date();
  // Set fixed hours
  date.setHours(12, 0, 0, 0);
  return new Date(date.setDate(new Date().getDate() + 7 * (incomingStock.inWeeks + 1)));
}

/**
 * Checks whether there was a supplier order attached to the customer order
 * @param order the customer order the supplier order was attached to
 * @param context data context with supplier orders
 * @returns {SupplierOrder | undefined} supplier order if it was removed before, undefined if there was no supplier order or no matching supplier order was found
 */
export function getPreviousSupplierOrder(
  order: CustomerOrderExtended,
  context: DataContextInternalType
): SupplierOrder | undefined {
  return order.previousSupplierOrder
    ? context.supplierOrder.find((sO) => sO._id.toString() === order.previousSupplierOrder?.toString())
    : undefined;
}

/**
 * Collect all invoices related to the customer order.
 * @param orderId Id of the customer order to check for invoices
 * @param context data context with invoices
 * @returns { Array<Invoice> } List of all related invoices
 */
export function getRelatedInvoices(orderId: BSON.ObjectId, context: DataContextInternalType): Array<Invoice> {
  return context.invoice.filter((i) => {
    if (!i.relatedOrder) return false;
    if ([I_CUSTOMERINVOICE, I_CREDITNOTECUSTOMER].includes(i.type)) return i.relatedOrder === orderId.toString();
    const relOrder = getDocFromCollection(context.supplierOrder, i.relatedOrder);
    if (relOrder) {
      return relOrder.customerOrders.some((cO) => cO === orderId.toString());
    }
  });
}
