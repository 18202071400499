import React, { useContext } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DataContextInternal } from "../../context/dataContext";
import CreateSupplierOrder from "./CreateSupplierOrder";
import { SO_REQUESTED } from "../../model/supplierOrder.types";

interface EditSupplierOrderWrapperParams {
  id: string;
  type?: string;
}

interface EditSupplierOrderWrapperProps extends RouteComponentProps<EditSupplierOrderWrapperParams> {}

const EditSupplierOrderWrapper: React.FunctionComponent<EditSupplierOrderWrapperProps> = (props) => {
  const context = useContext(DataContextInternal);
  const query = new URLSearchParams(location.search).get("edit");
  const supplierOrder = context.supplierOrder.find((sO) => sO._id.toString() === query);
  if (!supplierOrder || supplierOrder.state !== SO_REQUESTED) props.history.push("/orders");
  return (
    <CreateSupplierOrder
      context={context}
      existingSupplierOrder={supplierOrder}
      forStock={!props.match.params.id}
      {...props}
    />
  );
};

export default withRouter(EditSupplierOrderWrapper);
