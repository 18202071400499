import React, { useEffect, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { BSON } from "realm-web";
import { Input } from "./Input";
import { C_UNITS, SUPPORTED_INCOTERMS } from "../../utils/commodityUtils";
import DateInput from "./DateInput";
import { Price, SupplierPricesExtended } from "../../model/commodity.types";
import { getDaysUntil } from "../../utils/dateUtils";
import { SUPPORTED_CURRENCIES } from "../../utils/currencyUtils";
import Tooltip from "./Tooltip";
import { Supplier, SupplierExtended } from "../../model/supplier.types";
import { SupplierSupplier } from "../../model/supplier/supplierSupplier.types";
import { FP_UNITS, isFinishedProduct } from "../../utils/finishedProductUtils";
import { InternalArticleExtended, SupplierArticleExtended } from "../../utils/productArticleUtils";
import { SupplierSupplierPricesExtended } from "../../model/supplier/supplierCommodity.types";

import { reduceSupplierSupplier } from "../../utils/dataTransformationUtils";

interface CommodityPriceTableProps {
  commodity: InternalArticleExtended;
  disabled?: boolean;
  isNew?: boolean;
  supplierDisabled?: boolean;
  supplier: SupplierExtended;
  eventKey: string;
  prices: Array<Price>;
  onNewPrice: (entryId: BSON.ObjectId | string) => void;
  onRemovePrice: (entryId: BSON.ObjectId | string, priceId: BSON.ObjectId | string) => void;
  onUpdatePrice: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    entryId: BSON.ObjectId | string,
    priceId: BSON.ObjectId | string
  ) => void;
  onSave: (entryId: BSON.ObjectId | string) => void;
  onRenewPrices: (entryId: string, date: Date, priceId?: BSON.ObjectId | string) => void;
  onSetLeadTime: (entryId: BSON.ObjectId | string, value: string) => void;
  onDisable: (entryId: BSON.ObjectId | string) => void;
}

const CommodityPriceTable: React.FunctionComponent<CommodityPriceTableProps> = ({
  commodity,
  eventKey,
  disabled,
  isNew,
  supplierDisabled,
  supplier,
  prices,
  onNewPrice,
  onRemovePrice,
  onUpdatePrice,
  onSave,
  onRenewPrices,
  onSetLeadTime,
  onDisable,
}) => {
  const [priceToRenew, setPriceToRenew] = useState<Price | undefined>(undefined);

  return (
    <div className="table-responsive mt-5">
      <table className="table align-middle gs-0 gy-1 ">
        <thead>
          <tr className="fw-bolder text-muted">
            <th className="border-bottom-0">MOQ</th>
            <th className="border-bottom-0">Price per {commodity.unit}</th>
            <th className="border-bottom-0">
              Preparation Time
              <Tooltip
                tooltipText={
                  <span className="text-white">
                    Preparation time can be set individually for different quantities or for the whole commodity by
                    using 'Set Preparation Time'.
                  </span>
                }
              >
                <i className="fa fa-question-circle text-muted opacity-25 ml-2" />
              </Tooltip>
            </th>
            <th className="border-bottom-0">Age</th>
            <th className="border-bottom-0">Expiration</th>
            <th className="border-bottom-0 text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {prices.map((price) => (
            <tr key={price._id.toString()}>
              <td className="align-middle">
                <div className="input-group">
                  <Input
                    type={"number"}
                    value={price.minOQ}
                    disabled={disabled}
                    className={"form-control custom-form-control pt-0 pb-0 bg-dark " + (disabled && "disabled")}
                    name={"minOQ"}
                    onBlur={disabled ? undefined : (e) => onUpdatePrice(e, eventKey, price._id)}
                  />
                  <div className="input-group-append rounded-end" style={disabled ? { opacity: 0.7 } : undefined}>
                    <select
                      className="form-control custom-form-control bg-dark pt-0 pb-0 disabled pl-0"
                      disabled={true}
                      value={commodity.unit}
                      name="unit"
                    >
                      {(isFinishedProduct(commodity) ? FP_UNITS : C_UNITS).map((u) => (
                        <option key={u} value={u}>
                          {u}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </td>
              <td className="align-middle">
                <div className="input-group">
                  <Input
                    type="number"
                    value={price.price}
                    name="price"
                    className={"form-control custom-form-control py-0 bg-dark " + (disabled && "disabled")}
                    disabled={disabled}
                    onBlur={disabled ? undefined : (e) => onUpdatePrice(e, eventKey, price._id)}
                  />
                  <div className="input-group-append rounded-end" style={disabled ? { opacity: 0.7 } : undefined}>
                    <select
                      className={"form-control custom-form-control bg-dark pr-1 pl-0 py-0 " + (disabled && "disabled")}
                      name="currency"
                      value={price.currency}
                      disabled={disabled}
                      onChange={disabled ? undefined : (e) => onUpdatePrice(e, eventKey, price._id)}
                    >
                      {SUPPORTED_CURRENCIES.map((c) => (
                        <option key={c} value={c}>
                          {c}
                        </option>
                      ))}
                    </select>
                    <select
                      className={"form-control custom-form-control bg-dark pr-1 pl-0 py-0 " + (disabled && "disabled")}
                      name="incoterm"
                      value={price.incoterm}
                      disabled={disabled}
                      onChange={disabled ? undefined : (e) => onUpdatePrice(e, eventKey, price._id)}
                    >
                      {SUPPORTED_INCOTERMS.map((i) => (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </td>
              <td className="align-middle">
                <div className="input-group">
                  <Input
                    style={{ maxWidth: "120px" }}
                    type="number"
                    value={price.leadTime}
                    name="leadTime"
                    className="form-control custom-form-control py-0 pr-0 bg-dark"
                    onBlur={disabled ? undefined : (e) => onUpdatePrice(e, eventKey, price._id)}
                  />
                  <div className="input-group-append rounded-end pl-1">
                    <div className="input-group-text form-control custom-form-control bg-dark">days</div>
                  </div>
                </div>
              </td>
              <td className="align-middle">
                <span className="text-white text-nowrap">{Math.abs(getDaysUntil(price.date, true))} day(s)</span>
              </td>
              <td className="align-middle">
                <DateInput
                  value={price.validUntil}
                  onBlur={() => true}
                  name={"validUntil"}
                  disabled={disabled}
                  onClick={(e) => {
                    e.preventDefault(); // prevent default date selection
                    setPriceToRenew(price);
                  }}
                  tabIndex={-1} // prevent focus via tab
                  classes={"form-control custom-form-control bg-dark " + (disabled && "disabled")}
                />
              </td>
              <td className="align-middle text-center">
                <button
                  className={"btn btn-text-danger btn-sm p-0 " + (disabled && "disabled")}
                  disabled={disabled}
                  onClick={disabled ? undefined : () => onRemovePrice(eventKey, price._id)}
                >
                  <i className="fa fa-trash text-danger" />
                </button>
              </td>
            </tr>
          ))}
          <tr>
            <td className="align-middle" colSpan={5} />
            <td className="align-middle text-center">
              <button
                className={"btn btn-text btn-sm p-0 " + (disabled && "disabled")}
                disabled={disabled}
                onClick={disabled ? undefined : () => onNewPrice(eventKey)}
              >
                <i className="fa fa-plus text-success" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <button
        className={"btn btn-text-success btn-sm float-right " + (disabled && "disabled")}
        disabled={disabled}
        onClick={disabled ? undefined : () => onSave(eventKey)}
      >
        Save
      </button>
      {!isNew && (
        <>
          <CommodityPriceRenewAllModal
            eventKey={eventKey}
            price={priceToRenew}
            disabled={disabled}
            onRenewPrices={onRenewPrices}
            onClose={() => setPriceToRenew(undefined)}
          />
          <SetLeadTimeModal
            eventKey={eventKey}
            article={commodity}
            supplier={reduceSupplierSupplier(supplier)}
            disabled={disabled}
            onSetLeadTime={onSetLeadTime}
          />
        </>
      )}
      <button className="btn btn-text-muted btn-sm float-right disabled">Export</button>
      <button
        className={"btn btn-text-muted btn-sm float-right " + ((disabled || isNew) && "disabled")}
        disabled={disabled || isNew}
        onClick={disabled || isNew ? undefined : () => onDisable(eventKey)}
      >
        {supplierDisabled ? "Activate" : "Deactivate"}
      </button>
    </div>
  );
};

interface CommodityPriceRenewAllModalProps {
  eventKey: string;
  disabled?: boolean;
  price?: Price;
  onRenewPrices: (entryId: string, date: Date, priceId?: BSON.ObjectId | string) => void;
  onClose?: () => void;
  show?: boolean;
}

const RENEW_SHORTCUTS = [7, 14, 28, 90];

export const CommodityPriceRenewAllModal: React.FunctionComponent<CommodityPriceRenewAllModalProps> = ({
  eventKey,
  disabled,
  price,
  onRenewPrices,
  onClose,
  show: showProps,
}) => {
  let defaultDate = price && price.validUntil > new Date() ? price.validUntil : new Date();
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const [date, setDate] = useState(new Date(defaultDate));

  useEffect(() => {
    defaultDate = price && price.validUntil >= new Date() ? price.validUntil : new Date();
    setDate(new Date(defaultDate));
  }, [price]);

  const handleShow = () => setShow(true);
  const handleHide = () => {
    setShow(false);
    if (onClose) onClose();
  };
  const handleDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = new Date(e.target.value);
    if (isNaN(val.getTime())) return;
    setDate(val);
  };
  const handleRenewShortcut = (days: number) =>
    setDate(new Date(new Date(defaultDate).setDate(defaultDate.getDate() + days)));

  const handleRenewAll = () => {
    setSaving(true);
    onRenewPrices(eventKey, date);
    handleHide();
    setSaving(false);
  };
  const handleRenewSingle = () => {
    if (!price) return;
    setSaving(true);
    onRenewPrices(eventKey, date, price._id);
    handleHide();
    setSaving(false);
  };
  return (
    <>
      {showProps === undefined && (
        <button
          className={"btn btn-text-muted btn-sm float-right " + (disabled && "disabled")}
          disabled={disabled}
          onClick={disabled ? undefined : handleShow}
        >
          Renew All
        </button>
      )}
      <Modal
        contentClassName="bg-dark"
        show={showProps !== undefined ? showProps : show || !!price}
        onHide={handleHide}
        centered
      >
        <Modal.Header className="border-0 pb-0">
          <Modal.Title>
            <h1 className="fw-bolder d-flex align-items-center text-white">
              Renew Expiration {price ? "Date" : "Dates"}
            </h1>
          </Modal.Title>
          <CloseButton variant={"white"} onClick={handleHide} />
        </Modal.Header>
        <Modal.Body>
          <div className="row mt-6">
            <div className="col-4 my-auto">
              <label className="fs-5 fw-bold my-auto">New Expiration:</label>
            </div>
            <div className="col-8">
              <DateInput
                classes="form-control custom-form-control"
                value={date}
                onBlur={handleDate}
                name={"expirationDate"}
              />
            </div>
            <div className="col-4" />
            <div className="col-8 mt-2">
              {RENEW_SHORTCUTS.map((rs) => (
                <button
                  key={rs.toString()}
                  className={"btn btn-sm btn-light py-1 px-2 mr-2 " + (saving && "disabled")}
                  disabled={saving}
                  onClick={saving ? undefined : () => handleRenewShortcut(rs)}
                >
                  {rs} days
                </button>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={"btn btn-sm btn-text-light " + (saving && "disabled")}
            disabled={saving}
            onClick={saving ? undefined : handleHide}
          >
            Close
          </button>
          <button
            className={"btn btn-sm btn-outline btn-outline-light " + (saving && "disabled")}
            disabled={saving}
            onClick={saving ? undefined : handleRenewAll}
          >
            Set All
          </button>
          {price && (
            <button
              className={"btn btn-sm btn-outline btn-outline-light " + (saving && "disabled")}
              disabled={saving}
              onClick={saving ? undefined : handleRenewSingle}
            >
              Set
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

interface SetLeadTimeModalProps {
  eventKey: string;
  disabled?: boolean;
  article: InternalArticleExtended | SupplierArticleExtended;
  supplier?: Supplier | SupplierSupplier | SupplierExtended;
  onSetLeadTime: (entryId: BSON.ObjectId | string, value: string) => void;
  onClose?: () => void;
  show?: boolean;
}

export const SetLeadTimeModal: React.FunctionComponent<SetLeadTimeModalProps> = ({
  eventKey,
  disabled,
  article,
  supplier,
  onSetLeadTime,
  onClose,
  show: showProps,
}) => {
  // Has to be done in two lines so that TS is realizing that the types are fine
  const suppliers: Array<SupplierPricesExtended | SupplierSupplierPricesExtended> = article.suppliers;
  const entry = suppliers.find((s) => s.supplier._id.toString() === supplier?._id.toString());
  const [show, setShow] = useState(false);
  const [value, setValue] = useState(entry && entry.leadTime ? entry.leadTime.toString() : "5");
  const handleShow = () => setShow(true);
  const handleHide = () => {
    setShow(false);
    onClose && onClose();
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isNaN(parseFloat(value))) return; // value will later be used as number
    setValue(value);
  };

  return (
    <>
      {showProps === undefined && (
        <button
          className={"btn btn-text-muted btn-sm float-right " + (disabled && "disabled")}
          disabled={disabled}
          onClick={disabled ? undefined : handleShow}
        >
          Set Preparation Time
        </button>
      )}
      <Modal contentClassName="bg-dark" show={showProps !== undefined ? showProps : show} onHide={handleHide} centered>
        <Modal.Header className="border-0 pb-0">
          <Modal.Title>
            <h1 className="fw-bolder d-flex align-items-center text-white">Set Commodity Preparation Time</h1>
          </Modal.Title>
          <CloseButton variant={"white"} onClick={handleHide} />
        </Modal.Header>
        <Modal.Body>
          <div className="row mt-6">
            {entry?.leadTime && (
              <div className="fs-5 text-white mb-4">
                Current preparation time for this commodity is set to {entry.leadTime.toString()} days
              </div>
            )}
            <div className="col-5 my-auto">
              <label className="fs-5 fw-bold my-auto">Set new Preparation Time:</label>
            </div>
            <div className="col-7">
              <div className="input-group">
                <Input
                  type={"number"}
                  className="form-control custom-form-control"
                  value={value}
                  onChange={handleChange}
                  name={"leadTimeValue"}
                />
                <div className="input-group-append rounded-end bg-custom-light-gray">
                  <div className="input-group-text form-control custom-form-control">days</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-6">
            <div className="fs-5 fw-bold text-warning my-auto">
              Changing this value will overwrite individually set preparation times!
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className={"btn btn-sm btn-text-light"} onClick={handleHide}>
            Close
          </button>
          <button
            className={"btn btn-sm btn-outline btn-outline-light"}
            onClick={() => {
              onSetLeadTime(eventKey, value);
              handleHide();
            }}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CommodityPriceTable;
