import _ from "lodash";
import { Link } from "react-router-dom";
import React, { PureComponent } from "react";
import { DataContextInternal } from "../../../context/dataContext";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import { SortOption, SORTORDEROPTIONS } from "../../../utils/filterUtils";
import { doFuseSearch, getComponentState } from "../../../utils/baseUtils";
import { paginate, PaginationState } from "../../common/Pagination";
import {
  CC_SORTOPTIONS,
  CC_TAB_ARCHIVED,
  CC_TAB_OPEN,
  CC_TAB_ORDERED,
  CC_TAB_PARTIAL,
  CC_TAB_READY,
  isContractInactive,
} from "../../../utils/customerContractUtils";
import { CC_STATE, CustomerContract } from "../../../model/customerContract.types";
import Search from "../../common/Search";
import BaseListing from "../../common/BaseListing";
import ContractRow from "./ContractRow";

interface ContractListingProps {
  context: React.ContextType<typeof DataContextInternal>;
}

interface ContractListingState extends PaginationState {
  sortBy: SelectOption;
  sortOrder: SortOption;
  filterBy?: SelectOption;
  search: string;
  activeTab: string;
}

const COMPONENT_NAME = "ContractListing";

class ContractListing extends PureComponent<ContractListingProps, ContractListingState> {
  constructor(props: ContractListingProps) {
    super(props);
    this.state = {
      activeTab: "",
      currentPage: 1,
      pageSize: 25,
      sortBy: CC_SORTOPTIONS[0],
      sortOrder: SORTORDEROPTIONS[0],
      search: "",
    };
  }

  componentDidMount() {
    const state = getComponentState(this.props.context, COMPONENT_NAME);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(COMPONENT_NAME, this.state);
  }

  handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    // Special handling for sortBy when searching
    if (!val.trim())
      this.setState({
        search: e.target.value,
        currentPage: 1,
        sortBy: CC_SORTOPTIONS[0],
      });
    else
      this.setState({
        search: e.target.value,
        currentPage: 1,
        sortBy: { value: "", label: "Best Match" },
        sortOrder: SORTORDEROPTIONS[0],
      });
  };
  handleChangeSortBy = (sortBy: SelectOption) => this.setState({ sortBy, currentPage: 1 });
  handleChangeSortOrder = (sortOrder: SortOption) => this.setState({ sortOrder, currentPage: 1 });
  handleChangeFilterBy = (filterBy?: SelectOption) => this.setState({ filterBy, currentPage: 1 });
  handleChangeActiveTab = (activeTab: string) => this.setState({ activeTab, currentPage: 1 });
  handleChangePageSize = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleChangeCurrentPage = (currentPage: number) => this.setState({ currentPage });

  /**
   * Filter and sort the contracts.
   * @returns { Array<CustomerContract> } Filtered and sorted contracts
   */
  getFilteredContracts = () => {
    const { search, sortBy, sortOrder } = this.state;
    let contractsFiltered = this.props.context.customerContract.slice();

    if (search.trim()) {
      contractsFiltered = doFuseSearch(contractsFiltered, search, [
        "contractNo",
        "commodity.title.en",
        "customerReference",
        "state",
        "company.name",
      ]);
    }

    // Handle sorting for search
    if (!sortBy.value && search.trim()) {
      if (sortOrder.value === "desc") return contractsFiltered.reverse();
      return contractsFiltered;
    }
    // put canceled contracts at the end
    const contractsCanceled = _.orderBy(
      contractsFiltered.filter((cc) => isContractInactive(cc)),
      sortBy.value,
      sortOrder.value
    );
    contractsFiltered = contractsFiltered.filter(
      (cc) => cc.state !== CC_STATE.CANCELED && cc.state !== CC_STATE.CLOSED
    );
    contractsFiltered = _.orderBy(contractsFiltered, sortBy.value, sortOrder.value);
    contractsFiltered = contractsFiltered.concat(contractsCanceled);
    return contractsFiltered;
  };

  /**
   * Get contracts for the selected tab
   * @param filteredContracts filtered contract list
   * @param tab optional, selected tab
   * @returns { Array<CustomerContract> } contracts for selected tab
   */
  getContractsForTab(filteredContracts: Array<CustomerContract>, tab?: string) {
    const activeTab = tab ?? this.state.activeTab;
    switch (activeTab) {
      case CC_TAB_OPEN:
        return filteredContracts.filter((c) => c.state === CC_STATE.OPEN);
      case CC_TAB_ORDERED:
        return filteredContracts.filter((c) => c.state === CC_STATE.ORDERED);
      case CC_TAB_READY:
        return filteredContracts.filter((c) => c.state === CC_STATE.READY);
      case CC_TAB_PARTIAL:
        return filteredContracts.filter(
          (c) => c.state === CC_STATE.READY && c.contractInformation.restAmount < c.contractInformation.totalAmount
        );
      case CC_TAB_ARCHIVED:
        return filteredContracts.filter((c) => [CC_STATE.CANCELED, CC_STATE.CLOSED].includes(c.state));
    }
    return filteredContracts;
  }

  render() {
    const { activeTab, currentPage, filterBy, pageSize, sortBy, sortOrder, search } = this.state;
    const filteredContracts = this.getFilteredContracts();
    const tabContracts = this.getContractsForTab(filteredContracts);
    const paginatedContracts = paginate(tabContracts, currentPage, pageSize);

    const headerDefinition = [
      { title: "Contract", style: { width: "30%" } },
      {
        title: "Call-Offs",
        style: { width: "15%", whiteSpace: "nowrap" },
      },
      { title: "Information", style: { width: "20%" } },
      { title: "Progress", style: { width: "15%" } },
      { title: "Validity", style: { width: "20%" } },
    ];
    const tabs = ["", CC_TAB_OPEN, CC_TAB_ORDERED, CC_TAB_READY, CC_TAB_PARTIAL, CC_TAB_ARCHIVED];
    const sortOptions = CC_SORTOPTIONS.slice();
    if (search.trim()) sortOptions.unshift({ value: "", label: "Best Match" });
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="card bg-white" style={{ minHeight: "100%" }}>
              <div className="card-body">
                <h3 className="card-title align-items-start flex-column mb-15">
                  <span className="card-label fw-bolder mb-3 fs-3rem">Contracts</span>
                  <div className="float-right">
                    <Link className="btn btn-outline btn-outline-light" to="/createCustomerContract">
                      New Contract
                    </Link>
                  </div>
                </h3>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <Search placeholder="Search for contracts..." onSearch={this.handleChangeSearch} value={search} />
                  </div>
                  <div className="col-12 col-md-6 text-right">
                    <Link className="btn btn-sm btn-light" to="/canceledContracts">
                      Canceled Contracts
                    </Link>
                  </div>
                </div>
                <div className="row mt-6">
                  <div className="col-12 col-md-6 col-lg-4">
                    <label className="fs-6 form-label fw-bolder text-dark">Filter</label>
                    <CustomSelect
                      options={[]}
                      placeholder="Select Filter..."
                      disabled={true}
                      isClearable
                      onChange={this.handleChangeFilterBy}
                      value={filterBy}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <label className="fs-6 form-label fw-bolder text-dark">Sort by</label>
                    <CustomSelect options={sortOptions} onChange={this.handleChangeSortBy} value={sortBy} />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <label className="fs-6 form-label fw-bolder text-dark">Sort order</label>
                    <CustomSelect options={SORTORDEROPTIONS} onChange={this.handleChangeSortOrder} value={sortOrder} />
                  </div>
                </div>
                <div className="mt-6 nav-group nav-group-fluid bg-light no-hover">
                  {tabs.map((tab, idx) => {
                    const contractsForTab = this.getContractsForTab(filteredContracts, tab).length;
                    return (
                      <button
                        className={
                          "btn btn-sm fw-bolder px-4 btn-text-white nav-button mx-1 text-nowrap " +
                          (activeTab === tab ? " active" : "")
                        }
                        onClick={() => this.handleChangeActiveTab(tab)}
                        key={idx}
                      >
                        {tab || "All"}
                        <span className={"text-success ml-2"}>{contractsForTab}</span>
                      </button>
                    );
                  })}
                </div>
                <BaseListing
                  headerDefinition={headerDefinition}
                  bodyContent={
                    <>
                      {paginatedContracts.length > 0 ? (
                        paginatedContracts.map((pC) => <ContractRow key={pC._id.toString()} contract={pC} />)
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={8}>
                            No contracts found
                          </td>
                        </tr>
                      )}
                    </>
                  }
                  documents={tabContracts}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  baseSize={25}
                  onPageChange={this.handleChangeCurrentPage}
                  onPageSizeChange={this.handleChangePageSize}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContractListing;
