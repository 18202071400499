import countryList from "i18n-iso-countries";
import React, { PureComponent } from "react";
import { Input } from "../../common/Input";
import CompanyCountryWidget from "../../common/CompanyCountryWidget";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import { SupplierSupplierExtended } from "../../../model/supplier/supplierSupplier.types";
import { getSeaportName, getStartingSeaports } from "../../../utils/seaportUtils";
import { DataContextInternalType, DataContextSupplierType } from "../../../context/dataContext";
import { getAirportName, getStartingAirports } from "../../../utils/airportUtils";
import { SUPPORTED_CURRENCIES } from "../../../utils/currencyUtils";
import { formatAddress } from "../../../utils/addressUtils";

interface SupplierPageGeneralInformationBlockProps {
  supplier: SupplierSupplierExtended;
  context: DataContextSupplierType | DataContextInternalType;
  referencedSeaports: Array<SelectOption>;
  referencedAirports: Array<SelectOption>;
  edit: boolean;
  onEditSupplierInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEditPreparationTime: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEditReferences: (e: Array<SelectOption>, type: "seaport" | "airport") => void;
}

class SupplierPageGeneralInformationBlock extends PureComponent<SupplierPageGeneralInformationBlockProps> {
  render() {
    const {
      supplier,
      context,
      referencedSeaports,
      referencedAirports,
      edit,
      onEditSupplierInput,
      onEditPreparationTime,
      onEditReferences,
    } = this.props;
    const { seaport, airport } = context;
    const address = formatAddress(supplier.address[0]);

    return (
      <div className="fs-6">
        {edit ? (
          <div className="row py-5">
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">Name</label>
              <Input type="text" value={supplier.name} disabled={true} className="form-control custom-form-control" />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">General Mail</label>
              <Input
                type="text"
                value={supplier.mail}
                name="mail"
                onChange={onEditSupplierInput}
                className="form-control custom-form-control"
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">VAT-Number</label>
              <Input type="text" value={supplier.vat} disabled={true} className="form-control custom-form-control" />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">General Phone</label>
              <Input
                type="text"
                value={supplier.phone}
                name="phone"
                onChange={onEditSupplierInput}
                className="form-control custom-form-control"
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">Address</label>
              <Input type="text" value={address} disabled={true} className="form-control custom-form-control" />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">Country</label>
              <CustomSelect
                options={Object.values(countryList.getNames("en", { select: "alias" })).map((item: string) => {
                  return {
                    value: countryList.getAlpha2Code(item, "en"),
                    label: item,
                  };
                })}
                value={
                  supplier?.address[0].country
                    ? {
                        value: supplier.address[0].country,
                        label: countryList.getName(supplier.address[0].country, "en", { select: "alias" }),
                      }
                    : undefined
                }
                disabled={true}
                onChange={() => true}
                matchFormControl={true}
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-2">Currency</label>
              <CustomSelect
                options={SUPPORTED_CURRENCIES.map((c) => {
                  return { value: c, label: c };
                })}
                value={{ value: supplier.currency, label: supplier.currency }}
                disabled={true}
                onChange={() => true}
                matchFormControl={true}
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">Preparation Time (in days)</label>
              <Input
                type="number"
                value={supplier.transport.preparationTime}
                name="preparationTime"
                onChange={onEditPreparationTime}
                className="form-control custom-form-control"
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-2">Seaports</label>
              <CustomSelect
                options={getStartingSeaports(seaport).map((sp) => {
                  return {
                    value: sp._id.toString(),
                    label: getSeaportName(sp),
                  };
                })}
                value={referencedSeaports}
                onChange={(e: Array<SelectOption>) => onEditReferences(e, "seaport")}
                isMulti={true}
                isClearable={true}
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">Airports</label>
              <CustomSelect
                options={getStartingAirports(airport).map((ap) => {
                  return {
                    value: ap._id.toString(),
                    label: getAirportName(ap),
                  };
                })}
                value={referencedAirports}
                onChange={(e: Array<SelectOption>) => onEditReferences(e, "airport")}
                isMulti={true}
                isClearable={true}
              />
            </div>
          </div>
        ) : (
          <div className="d-flex flex-wrap py-5">
            <table className="table fw-bold gy-1">
              <tbody>
                <tr>
                  <td className="text-white w-25 min-w-125px">Name</td>
                  <td className="text-muted w-25">{supplier.name}</td>
                  <td className="text-white w-25 min-w-125px">General Mail</td>
                  <td className="text-muted w-25">{supplier.mail}</td>
                </tr>
                <tr>
                  <td className="text-white w-25 min-w-125px">VAT Number</td>
                  <td className="text-muted w-25">{supplier.vat}</td>
                  <td className="text-white w-25 min-w-125px">General Phone</td>
                  <td className="text-muted w-25">{supplier.phone}</td>
                </tr>
                <tr>
                  <td className="text-white w-25 max-w-125px">Address</td>
                  <td className="text-muted w-25 display-linebreak">{address}</td>
                  <td className="text-white w-25 min-w-125px">Country</td>
                  <td className="text-muted w-25">
                    <CompanyCountryWidget company={supplier} />
                  </td>
                </tr>
                <tr>
                  <td className="text-white w-25 min-w-125px">Preparation Time</td>
                  <td className="text-muted w-25">{supplier.transport?.preparationTime} day(s)</td>
                </tr>
                <tr>
                  <td className="text-white w-25 min-w-125px">Seaport</td>
                  <td className="text-muted w-25 list-no-bullets">
                    {seaport.map((sp) => {
                      if (supplier.seaportReferences && supplier.seaportReferences.includes(sp._id.toString())) {
                        return <li>{getSeaportName(sp)}</li>;
                      }
                    })}
                  </td>
                  <td className="text-white w-25 min-w-125px">Airports</td>
                  <td className="text-muted w-25 list-no-bullets">
                    {airport.map((ap) => {
                      if (supplier.airportReferences && supplier.airportReferences.includes(ap._id.toString())) {
                        return <li>{getAirportName(ap)}</li>;
                      }
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

export default SupplierPageGeneralInformationBlock;
