import _ from "lodash";
import React, { PureComponent, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import validator from "validator";
import { Supplier, SupplierExtended, T_S_ADDUSERTOSUPPLIER } from "../../../../model/supplier.types";
import { Company, CompanyExtended } from "../../../../model/company.types";
import { UserStateData } from "../../CustomTypes";
import UserCreation from "../../UserCreation";
import ErrorOverlayButton from "../../ErrorOverlayButton";
import { callFunction, FORWARDER } from "../../../../services/dbService";
import { CUSTOMER, getDefaultUser, getUserName, SUPPLIER, SUPPLIER as U_SUPPLIER } from "../../../../utils/userUtils";
import { callSetUserAndApiKeyValidity, deleteUser, registerUser } from "../../../../services/authService";
import { getDefaultSlackChannel, NotificationType, sendMessage } from "../../../../services/slackService";
import { UserData } from "../../../../model/userData.types";
import { Input } from "../../Input";
import Tooltip from "../../Tooltip";
import { getSupplierTimelineEntry } from "../../../../utils/supplierUtils";
import { callSendInvitationMail } from "../../../../utils/notificationUtils";
import userService from "../../../../services/userService";
import { Forwarder, ForwarderExtended } from "../../../../model/forwarder.types";

interface CreateContactModalProps {
  company: Company | CompanyExtended | Supplier | SupplierExtended | Forwarder | ForwarderExtended;
  type: "company" | "supplier" | "forwarder";
}

interface CreateContactModalState {
  newContacts: Array<UserStateData>;
  createAppUsers: boolean;
  sendInvitationMail: boolean;
  show: boolean;
  saving: boolean;
  createdLinks: Array<{ user: UserData; invitationLink: string }>;
  step: number;
}

class CreateContactModal extends PureComponent<CreateContactModalProps, CreateContactModalState> {
  constructor(props: CreateContactModalProps) {
    super(props);
    this.state = {
      show: false,
      saving: false,
      newContacts: [],
      createAppUsers: false,
      sendInvitationMail: false,
      createdLinks: [],
      step: 1,
    };
  }

  handleShow = () =>
    this.setState({
      show: true,
      newContacts: [{ id: new BSON.ObjectId(), prename: "", surname: "", mail: "", phone: "", position: "" }],
      createdLinks: [],
      step: 1,
    });
  handleHide = () => this.setState({ show: false });
  handleToggleCreateAppUsers = () => this.setState({ createAppUsers: !this.state.createAppUsers });
  handleToggleSendInvitationMail = () => this.setState({ sendInvitationMail: !this.state.sendInvitationMail });

  handleAddContact = () => {
    const newContacts = _.cloneDeep(this.state.newContacts);
    newContacts.push({ id: new BSON.ObjectId(), prename: "", surname: "", mail: "", phone: "", position: "" });
    this.setState({ newContacts });
  };

  handleRemoveContact = (index: number) => {
    const newContacts = _.cloneDeep(this.state.newContacts);
    newContacts.splice(index, 1);
    this.setState({ newContacts });
  };

  handleContactChange = (e: React.ChangeEvent<HTMLInputElement>, index?: number) => {
    if (index === undefined) return;
    const newContacts = _.cloneDeep(this.state.newContacts);
    const person = newContacts[index];
    _.set(person, e.target.name, e.target.value);
    this.setState({ newContacts });
  };

  handleSaveContacts = async () => {
    const { company, type } = this.props;
    const { newContacts, createAppUsers, sendInvitationMail } = this.state;
    if (newContacts.length === 0) return;
    this.setState({ saving: true });
    const newLinks = [];
    try {
      let result;
      // option to create app users is only shown when creating customer and supplier users
      if (createAppUsers) {
        for (let i = 0; i < newContacts.length; i++) {
          const c = newContacts[i];
          const registerRes = await registerUser(c.mail);
          if (!registerRes) {
            toast.error("User could not be registered");
            await sendMessage(
              getDefaultSlackChannel(false, NotificationType.REGISTRATION),
              `Account creation for ${getUserName(c)} failed`
            );
            return;
          }
          const { id: userId, token } = registerRes;
          const typeString = type === SUPPLIER ? "Supplier" : "Customer";
          if (!userId) {
            toast.error("User could not be registered");
            await deleteUser(userId);
            await sendMessage(
              getDefaultSlackChannel(false, NotificationType.REGISTRATION),
              `${typeString} account creation failed in Step 1`
            );
            return;
          }
          const cObj = getDefaultUser(
            c.prename,
            c.surname,
            c.mail,
            company._id,
            type === "supplier" ? U_SUPPLIER : CUSTOMER,
            c.phone.trim(),
            c.position
          );
          cObj.userId = userId;
          result = await callSetUserAndApiKeyValidity(cObj);
          if (result && result.result) {
            const link = `https://${process.env.REACT_APP_BASE_URL || ""}/invitation?t=${token.key}`;
            toast.success(`${typeString} account successfully created`);
            await sendMessage(
              getDefaultSlackChannel(false, NotificationType.REGISTRATION),
              `${getUserName(userService.getUserData())} created a ${typeString} account for ${getUserName(c)} of ${
                company.name
              } - Link: ${link}`
            );
            newLinks.push({ user: cObj, invitationLink: link });
            if (sendInvitationMail) {
              result = await callSendInvitationMail(userId, link);
              if (result) {
                toast.success("Invitation mail sent successfully");
              } else toast.error("Invitation mail could not be sent");
            }
          } else {
            toast.error(`${typeString} account could not be linked with userdata`);
            await deleteUser(userId);
            await sendMessage(
              getDefaultSlackChannel(false, NotificationType.REGISTRATION),
              `${typeString} account creation failed in Step 2`
            );
          }
        }
      } else {
        const contactObjects = newContacts.map((c) =>
          getDefaultUser(
            c.prename,
            c.surname,
            c.mail,
            company._id,
            type === "supplier" ? U_SUPPLIER : type === "company" ? CUSTOMER : FORWARDER,
            c.phone.trim(),
            c.position
          )
        );
        const contactNames = newContacts.map((c) => `${c.prename} ${c.surname}`).join("; ");
        const timelineEntry =
          type === "supplier" ? getSupplierTimelineEntry(T_S_ADDUSERTOSUPPLIER, { name: contactNames }) : undefined;
        result = await callFunction("createUsersAndUpdateCompany", [contactObjects, company, type, timelineEntry]);
      }
      if (result) {
        toast.success("Contacts successfully added");
        if (createAppUsers) this.setState({ step: 2, createdLinks: newLinks });
        else this.setState({ show: false });
      } else toast.error("Contacts could not be created");
    } finally {
      this.setState({ saving: false });
    }
  };

  validateData = () => {
    const { newContacts } = this.state;
    const errors: Array<string> = [];
    newContacts.forEach((p, idx) => {
      if (p.prename.trim().length < 2) errors.push(`Person ${idx + 1} prename too short`);
      if (p.surname.trim().length < 2) errors.push(`Person ${idx + 1} surname too short`);
      if (!validator.isEmail(p.mail)) errors.push(`Person ${idx + 1} email is not valid`);
    });
    return errors;
  };

  render() {
    const { show, newContacts, createAppUsers, sendInvitationMail, saving, step, createdLinks } = this.state;
    const { type } = this.props;
    const showInviteOption = type !== "forwarder";
    const errors = this.validateData();
    return (
      <>
        <button className={"btn btn-light float-right mt-2 p-3 pt-1 pb-1"} onClick={this.handleShow}>
          +
        </button>
        <Modal contentClassName={"bg-dark"} show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="text-white">Create Additional Contacts</h1>
            </Modal.Title>
            <CloseButton variant={"white"} onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body className="mx-5 py-0">
            {step === 1 && (
              <UserCreation
                additionalPersons={newContacts}
                createAppUsers={showInviteOption ? createAppUsers : false}
                sendInvitationMail={showInviteOption ? sendInvitationMail : false}
                onAddPerson={this.handleAddContact}
                onRemovePerson={this.handleRemoveContact}
                onPersonChange={this.handleContactChange}
                onChangeCreateAppUsers={showInviteOption ? this.handleToggleCreateAppUsers : undefined}
                onChangeSendInvitationMail={showInviteOption ? this.handleToggleSendInvitationMail : undefined}
              />
            )}
            {step === 2 && (
              <div className="form-group col-12 mt-10">
                <label className="fs-5 fw-bold mb-2">Invitation Links</label>
                {createdLinks.map((inv) => (
                  <InvitationLink key={inv.user._id.toString()} user={inv.user} link={inv.invitationLink} />
                ))}
                <div className="text-danger">Note: The invitation link won't be available afterwards.</div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-text-white" onClick={this.handleHide}>
              Close
            </button>
            {step === 1 && newContacts.length > 0 && (
              <ErrorOverlayButton
                errors={errors}
                className="btn btn-sm btn-outline btn-outline-light"
                buttonText="Save"
                saving={saving}
                onClick={this.handleSaveContacts}
              />
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

interface InvitationLinkProps {
  user: UserData;
  link: string;
}

const InvitationLink: React.FunctionComponent<InvitationLinkProps> = ({ user, link }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyToClipboard = async () => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(link);
    } else {
      document.execCommand("copy", true, link);
    }
    setCopied(true);
    setTimeout(() => setCopied(false), 5 * 1000);
  };

  return (
    <div className="mb-5">
      <div className="text-white mb-2">{getUserName(user)}</div>
      <div className="input-group">
        <Input type="text" className="form-control custom-form-control" name="tokenLink" disabled={true} value={link} />
        <div className="input-group-append">
          <Tooltip tooltipText={copied ? "Copied!" : "Copy to clipboard!"}>
            <button className="btn btn-sm btn-outline btn-outline-light" onClick={handleCopyToClipboard}>
              <i className="flaticon2-copy pr-0" />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default CreateContactModal;
