import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import {
  CO_CANCELED,
  CustomerOrderExtended,
  T_EUSTOCK,
  T_WAREHOUSE,
} from "../../../../../../model/customerOrder.types";
import { formatDate, toAbsoluteUrl } from "../../../../../../utils/baseUtils";
import { getDaysBetween, getDaysUntil } from "../../../../../../utils/dateUtils";
import { resolveFilePath, shortenAlias } from "../../../../../../utils/fileUtils";
import { SupplierOrder } from "../../../../../../model/supplierOrder.types";
import { getPortNameOrAddress, getShipmentFiles } from "../../../../../../utils/supplierOrderUtils";
import { getOrderNumber } from "../../../../../../utils/orderUtils";
import { getPreviousSupplierOrder } from "../../../../../../utils/customerOrderUtils";
import { DataContextInternalType } from "../../../../../../context/dataContext";
import { formatAddress } from "../../../../../../utils/addressUtils";

interface WorkflowShippingInformationCardProps {
  order: CustomerOrderExtended;
  supplierOrder?: SupplierOrder;
  context: DataContextInternalType;
}

class WorkflowShippingCard extends PureComponent<WorkflowShippingInformationCardProps> {
  render() {
    const { order, supplierOrder, context } = this.props;
    const previousSupplierOrder = getPreviousSupplierOrder(order, context);
    // use previousSupplierOrder if order was canceled and supplierOrder does not exist anymore to still display the information for reference
    const displayedSO = supplierOrder ?? previousSupplierOrder;
    const shipment = displayedSO && displayedSO.shipment[0];
    let bol, packagingList, customsInvoice;
    if (displayedSO && shipment) {
      const files = getShipmentFiles(displayedSO, shipment);
      bol = files.bol;
      packagingList = files.packagingList;
      customsInvoice = files.customsInvoice;
    }

    return (
      <div className="opacity-100-hover" style={{ opacity: shipment || order.transport === T_WAREHOUSE ? 0.3 : 1 }}>
        <div className="fw-bolder text-white fs-3 my-5">
          Shipping Information{" "}
          {shipment || order.transport === T_WAREHOUSE ? (
            <i className="h2 fas fa-check-circle text-success" />
          ) : order.state !== CO_CANCELED ? (
            <span className="text-warning">[Current Task]</span>
          ) : null}
        </div>
        {previousSupplierOrder && (
          <div className="text-white">
            Note: The supplier order is no longer linked to the customer order. This data is only displayed for
            completeness.
          </div>
        )}
        {shipment && (
          <>
            <table className="table align-middle table-row-dashed gy-5 dataTable no-footer no-hover">
              <thead>
                <tr>
                  <th className="text-white" style={{ width: "15%" }}>
                    Order
                  </th>
                  <th className="text-white" style={{ width: "15%" }}>
                    Tracking Number
                  </th>
                  <th className="text-white" style={{ width: "17%" }}>
                    Shipping
                  </th>
                  <th className="text-white" style={{ width: "17%" }}>
                    Arrival
                  </th>
                  <th className="text-white text-nowrap" style={{ width: "18%" }}>
                    Starting Point
                  </th>
                  <th className="text-white" style={{ width: "18%" }}>
                    Destination
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="py-5 mb-1">
                  <td className="mt-2">
                    <Link
                      to={`/supplierOrder/${displayedSO._id.toString()}`}
                      className="text-muted custom-link fw-bold"
                    >
                      {getOrderNumber(displayedSO)}
                    </Link>
                  </td>
                  {shipment.shipping ? (
                    <>
                      <td className="mt-2">
                        <span className="text-muted fw-bold">{shipment.shipping.trackingNumber}</span>
                      </td>
                      <td className="mt-2">
                        <span className="text-muted fw-bold">
                          {shipment.shipping.shipped ? formatDate(shipment.shipping.shipped) : "not shipped yet"}
                        </span>
                        <small className={"ml-1 " + (shipment.shipping.eta ? "text-success" : "text-danger")}>
                          {shipment.shipping.shipped
                            ? Math.abs(
                                shipment.shipping.eta
                                  ? getDaysBetween(shipment.shipping.shipped, shipment.shipping.eta, true)
                                  : getDaysUntil(shipment.shipping.shipped, true)
                              )
                            : "-"}
                          d
                        </small>
                      </td>
                      <td className="mt-2">
                        <span className="text-muted fw-bold">
                          {shipment.shipping.eta ? formatDate(shipment.shipping.eta) : "TBD"}
                        </span>
                      </td>
                      <td className="mt-2">
                        <span className="text-muted fw-bold">
                          {getPortNameOrAddress(shipment.shipping.startingPoint)}
                        </span>
                      </td>
                      <td className="mt-2">
                        <span className="text-muted fw-bold">
                          {order.transport !== T_EUSTOCK
                            ? shipment.shipping.destination
                              ? getPortNameOrAddress(shipment.shipping.destination)
                              : "TBD"
                            : typeof order.destination === "string"
                            ? order.destination
                            : formatAddress(order.destination)}
                        </span>
                      </td>
                    </>
                  ) : (
                    <td colSpan={4}>
                      <span className="text-muted text-center fw-bold">No information available</span>
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
            <div className="border-bottom-dark-gray pt-5" />
            <div className="fw-bolder text-white fs-3 my-5">Documents</div>
            {previousSupplierOrder && (
              <div className="text-white">
                Note: The supplier order is no longer linked to the customer order. This data is only displayed for
                completeness.
              </div>
            )}
            <div className="row py-2">
              <div className="table-responsive mt-5 pt-2 bg-light2">
                <table className="table table-row-gray-100 align-middle border-0 gy-5 dataTable no-footer">
                  <thead>
                    <tr className="fw-bolder text-muted">
                      <th className="border-0">Type</th>
                      <th className="border-0">Title</th>
                      <th className="border-0">Uploaded</th>
                      <th className="border-0 text-right">File</th>
                    </tr>
                  </thead>
                  <tbody>
                    {displayedSO && (
                      <>
                        {order.transport !== T_EUSTOCK && (
                          <>
                            <tr>
                              <td>
                                <span className="text-white fw-bolder">BOL</span>
                              </td>
                              <td>
                                <span className={"fw-bold " + (bol ? "text-white" : "text-danger")}>
                                  {bol ? (
                                    <a
                                      className="text-white custom-link"
                                      href={resolveFilePath(bol.path)}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >{`BOL-${displayedSO.orderNo}.pdf`}</a>
                                  ) : (
                                    "Missing"
                                  )}
                                </span>
                              </td>
                              <td>
                                <span className={"fw-bold " + (bol ? "text-success" : "text-danger")}>
                                  {bol ? formatDate(bol.date) : "Missing"}
                                </span>
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {bol && (
                                  <a href={resolveFilePath(bol.path)} target="_blank" rel="noopener noreferrer">
                                    <img
                                      alt="pdf"
                                      className="w-30px me-3"
                                      src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                                    />
                                  </a>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className="text-white fw-bolder">Customs Invoice</span>
                              </td>
                              <td>
                                <span className={"fw-bold " + (customsInvoice ? "text-white" : "text-danger")}>
                                  {customsInvoice ? (
                                    <a
                                      className="text-white custom-link"
                                      href={resolveFilePath(customsInvoice.path)}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="text-white fw-bold">{shortenAlias(customsInvoice.path)}</span>
                                    </a>
                                  ) : (
                                    "Missing"
                                  )}
                                </span>
                              </td>
                              <td>
                                <span className={"fw-bold " + (customsInvoice ? "text-success" : "text-danger")}>
                                  {customsInvoice ? formatDate(customsInvoice.date) : "Missing"}
                                </span>
                              </td>
                              <td className="text-right">
                                {customsInvoice && (
                                  <a
                                    href={resolveFilePath(customsInvoice.path)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      alt="pdf"
                                      className="w-30px me-3"
                                      src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                                    />
                                  </a>
                                )}
                              </td>
                            </tr>
                          </>
                        )}
                        <tr>
                          <td>
                            <span className="text-white fw-bolder">Packaging List</span>
                          </td>
                          <td>
                            <span className={"fw-bold " + (packagingList ? "text-white" : "text-danger")}>
                              {packagingList ? (
                                <a
                                  className="text-white custom-link"
                                  href={resolveFilePath(packagingList.path)}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {shortenAlias(packagingList.path)}
                                </a>
                              ) : (
                                "Missing"
                              )}
                            </span>
                          </td>
                          <td>
                            <span className={"fw-bold " + (packagingList ? "text-success" : "text-danger")}>
                              {packagingList ? formatDate(packagingList.date) : "Missing"}
                            </span>
                          </td>
                          <td className="text-right">
                            {packagingList && (
                              <a href={resolveFilePath(packagingList.path)} target="_blank" rel="noopener noreferrer">
                                <img
                                  alt="pdf"
                                  className="w-30px me-3"
                                  src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                                />
                              </a>
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default WorkflowShippingCard;
