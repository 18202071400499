import React, { useContext } from "react";
import { DataContextCustomer } from "../../../context/dataContext";
import CompanySettings from "./CompanySettings";

const CompanySettingsWrapper: React.FunctionComponent = () => {
  const context = useContext(DataContextCustomer);
  return <CompanySettings context={context} />;
};

export default CompanySettingsWrapper;
