import _ from "lodash";
import validator from "validator";
import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { ForwarderExtended } from "../../../../model/forwarder.types";
import { getDefaultAddress, isAddressTooShort } from "../../../../utils/addressUtils";
import { SelectOption } from "../../../common/CustomSelect";
import { updateForwarder } from "../../../../utils/forwarderUtils";
import { AddressType } from "../../../../model/commonTypes";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import ContactsCard from "../../../common/ContactsCard";
import ForwarderPageGeneralInformationBlock from "../ForwarderPageGeneralInformationBlock";
import { DataContextInternalType } from "../../../../context/dataContext";
import { I_PAYMENTTARGETS } from "../../../../utils/invoiceUtils";
import { reduceForwarder } from "../../../../utils/dataTransformationUtils";

interface ForwarderPageGeneralProps {
  context: DataContextInternalType;
  forwarder: ForwarderExtended;
}

interface ForwarderPageGeneralState {
  edit: boolean;
  forwarderEdit: ForwarderExtended;
  customPaymentTarget: boolean;
}

class ForwarderPageGeneral extends PureComponent<ForwarderPageGeneralProps, ForwarderPageGeneralState> {
  constructor(props: ForwarderPageGeneralProps) {
    super(props);
    const isCustom = !I_PAYMENTTARGETS.some((pt) => pt.value === props.forwarder.paymentTerms?.paymentTarget);
    this.state = {
      edit: false,
      customPaymentTarget: isCustom,
      forwarderEdit: _.cloneDeep(props.forwarder),
    };
  }

  handleEditToggle = () => this.setState({ edit: !this.state.edit, forwarderEdit: _.cloneDeep(this.props.forwarder) });

  handleAddAddress = () => {
    const forwarder = _.cloneDeep(this.state.forwarderEdit);
    forwarder.address.push(getDefaultAddress());
    this.setState({ forwarderEdit: forwarder });
  };

  handleDeleteAddress = (id: BSON.ObjectId) => {
    const forwarder = _.cloneDeep(this.state.forwarderEdit);
    forwarder.address = forwarder.address.filter((a) => a._id.toString() !== id.toString());
    this.setState({ forwarderEdit: forwarder });
  };

  handleEditForwarderSelect = (name: string, e: SelectOption | Array<SelectOption>) => {
    const forwarder = _.cloneDeep(this.state.forwarderEdit);
    if (Array.isArray(e)) {
      _.set(
        forwarder,
        name,
        e.map((option) => option.value)
      );
    } else {
      _.set(forwarder, name, e.value);
    }
    this.setState({ forwarderEdit: forwarder });
  };

  handleEditForwarderSelectAddressField = (name: string, e: SelectOption, idx: number) => {
    const forwarder = _.cloneDeep(this.state.forwarderEdit);
    _.set(forwarder.address[idx], name, e.value);
    this.setState({ forwarderEdit: forwarder });
  };

  handleEditForwarderSelectPaymentTarget = (e: SelectOption) => {
    if (e.value !== "custom") {
      const forwarder = _.cloneDeep(this.state.forwarderEdit);
      const editPaymentTerms = {
        ...forwarder.paymentTerms,
        paymentTarget: e.value,
      };
      _.set(forwarder, "paymentTerms", editPaymentTerms);
      this.setState({ forwarderEdit: forwarder, customPaymentTarget: false });
    } else {
      this.setState({ customPaymentTarget: true });
    }
  };

  handleEditForwarderCustomPaymentTarget = (e: React.ChangeEvent<HTMLInputElement>) => {
    const forwarder = _.cloneDeep(this.state.forwarderEdit);
    forwarder.paymentTerms = {
      ...forwarder.paymentTerms,
      paymentTarget: e.target.value,
    };
    this.setState({ forwarderEdit: forwarder });
  };

  handleEditForwarderCustomPaymentTargetConditions = (e: React.ChangeEvent<HTMLInputElement>) => {
    const forwarder = _.cloneDeep(this.state.forwarderEdit);
    forwarder.paymentTerms = {
      paymentTarget: forwarder.paymentTerms?.paymentTarget ? forwarder.paymentTerms?.paymentTarget : "",
      paymentTargetConditions: e.target.value,
    };
    this.setState({ forwarderEdit: forwarder });
  };

  handleEditForwarderInput = (e: React.ChangeEvent<HTMLInputElement>, field?: string) => {
    const forwarder = _.cloneDeep(this.state.forwarderEdit);
    const targetName = field ?? e.target.name;
    _.set(forwarder, targetName, e.target.type === "number" ? +e.target.value : e.target.value);
    this.setState({ forwarderEdit: forwarder });
  };

  handleForwarderDisabledFlag = async (disable: boolean) => {
    const { forwarder } = this.props;
    const result = await updateForwarder({ disabled: disable }, forwarder._id);
    if (result && result.modifiedCount > 0) {
      toast.success("Forwarder " + (disable ? "disabled" : "enabled") + " successfully");
    } else {
      toast.error("Error " + (disable ? "disabling" : "enabling") + " forwarder");
    }
  };

  handleSaveForwarder = async () => {
    const { forwarderEdit } = this.state;
    if (!forwarderEdit) return;
    // If no address is primary designate the first one as primary
    if (!forwarderEdit.address.find((a) => a.type === AddressType.A_PRIMARY)) {
      forwarderEdit.address[0].type = AddressType.A_PRIMARY;
    }
    const result = await updateForwarder(reduceForwarder(forwarderEdit));
    if (result && result.modifiedCount > 0) {
      toast.success("Forwarder data updated successfully");
      this.setState({ edit: false });
    } else {
      toast.error("Error updating forwarder");
    }
  };

  validateForwarderData = () => {
    const { forwarderEdit } = this.state;
    const { name, mail, phone, address } = forwarderEdit;
    const errors: Array<string> = [];
    if (name.trim().length < 3) errors.push("Forwarder name too short");
    if (isAddressTooShort(address[0])) errors.push("Address too short");
    if (!address[0].country) errors.push("No country selected");
    if (phone.trim().length < 3) errors.push("Phone number too short");
    if (!validator.isEmail(mail)) errors.push("Email is not valid");
    return errors;
  };

  render() {
    const { context, forwarder } = this.props;
    const { edit, forwarderEdit, customPaymentTarget } = this.state;
    const errors = this.validateForwarderData();
    return (
      <>
        <div className="card bg-white">
          <div className="card-header border-0 mt-5">
            <div className="card-title flex-column">
              <h2 className="mb-1">General Information</h2>
              <div className="fs-6 fw-bold text-muted">{forwarder.name}</div>
            </div>
            <div className="pt-3">
              <div className="d-flex pt-3 align-items-center w-100">
                {edit && (
                  <button
                    className={"btn btn-outline btn-outline-light btn-sm float-right "}
                    onClick={this.handleEditToggle}
                  >
                    Cancel
                  </button>
                )}
                {edit ? (
                  <ErrorOverlayButton
                    className={"btn btn-outline btn-outline-light btn-sm float-right ml-2"}
                    errors={errors}
                    buttonText={"Save Forwarder"}
                    onClick={this.handleSaveForwarder}
                  />
                ) : (
                  <button
                    className={"btn btn-outline btn-outline-light btn-sm float-right ml-2"}
                    onClick={this.handleEditToggle}
                  >
                    Edit Forwarder
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="card-body p-9 pt-4">
            <div className="pt-0">
              <ForwarderPageGeneralInformationBlock
                context={context}
                forwarder={edit ? forwarderEdit : forwarder}
                edit={edit}
                customPaymentTarget={customPaymentTarget}
                onEditForwarderInput={this.handleEditForwarderInput}
                onEditForwarderSelect={this.handleEditForwarderSelect}
                onEditForwarderSelectAddressField={this.handleEditForwarderSelectAddressField}
                onAddAddress={this.handleAddAddress}
                onDeleteAddress={this.handleDeleteAddress}
                onEditForwarderSelectPaymentTarget={this.handleEditForwarderSelectPaymentTarget}
                onEditCustomPaymentTarget={this.handleEditForwarderCustomPaymentTarget}
                onEditCustomPaymentTargetConditions={this.handleEditForwarderCustomPaymentTargetConditions}
              />
              <div className="border-bottom-dark-gray" />
              <div className="pt-3">
                <div className="d-flex pt-3 align-items-center w-100">
                  {forwarder.disabled ? (
                    <button
                      className="btn btn-text btn-text-success btn-sm ml-auto"
                      onClick={() => this.handleForwarderDisabledFlag(false)}
                    >
                      Enable Forwarder
                    </button>
                  ) : (
                    <button
                      className="btn btn-text btn-text-danger btn-sm ml-auto"
                      onClick={() => this.handleForwarderDisabledFlag(true)}
                    >
                      Disable Forwarder
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <ContactsCard company={forwarder} type={"forwarder"} />
        </div>
      </>
    );
  }
}

export default ForwarderPageGeneral;
