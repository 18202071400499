import React, { PureComponent } from "react";
import Select from "react-select";
import { AddressSelectOption } from "../../utils/addressUtils";

export interface SelectOption<T = void> {
  value: string;
  label: string;
  object?: T;
  [key: string]: any;
}

export interface OrderSelectOption {
  value: "asc" | "desc";
  label: string;
}

interface CustomSelectProps {
  options?: Array<SelectOption<unknown> | AddressSelectOption>;
  onChange?: (e: any) => void;
  value?: Array<SelectOption<unknown>> | SelectOption<unknown> | AddressSelectOption | null;
  isClearable?: boolean;
  disabled?: boolean;
  matchFormControl?: boolean;
  placeholder?: string;
  isMulti?: boolean;
  additionalClasses?: string;
  classNamePrefix?: string;
  menuPlacement?: "top" | "bottom";
}

interface CustomSelectState {
  zIndex: number;
}

class CustomSelect extends PureComponent<CustomSelectProps, CustomSelectState> {
  constructor(props: CustomSelectProps) {
    super(props);
    this.state = { zIndex: 1 };
  }
  base4 = getComputedStyle(document.documentElement).getPropertyValue("--base4");
  base9 = getComputedStyle(document.documentElement).getPropertyValue("--base9");
  base9a = getComputedStyle(document.documentElement).getPropertyValue("--base9a");
  base11 = getComputedStyle(document.documentElement).getPropertyValue("--base11");

  // https://stackoverflow.com/questions/63212723/using-dark-mode-in-react-select
  colors = {
    danger: "#DE350B",
    dangerLight: "#FFBDAD",
    neutral90: "hsl(0, 0%, 100%)",
    neutral80: this.base9a, // placeholder color
    neutral70: "hsl(0, 0%, 90%)",
    neutral60: "hsl(0, 0%, 80%)",
    neutral50: this.base9,
    neutral40: this.base11, // disabled text color
    neutral30: "hsl(0, 0%, 50%)",
    neutral20: "hsl(0, 0%, 40%)",
    neutral10: "hsl(0, 0%, 30%)",
    neutral5: this.base4, // disabled color - Same as standard color so that opacity handles the rest when disabled to match normal forms
    neutral0: this.base4,
    primary: "rgba(38,132,255,0)", // focus color
    primary75: "#DEEBFF",
    primary50: "#B2D4FF",
    primary25: "#b2b4bd", // hover color
  };

  render() {
    const {
      disabled,
      isClearable,
      onChange,
      options,
      value,
      matchFormControl,
      placeholder,
      isMulti,
      additionalClasses,
      classNamePrefix,
      menuPlacement,
    } = this.props;
    const { zIndex } = this.state;
    return (
      <div className="position-relative" style={{ zIndex }}>
        <Select
          className={"select-default " + (disabled ? "disabled " : "") + additionalClasses}
          options={options}
          theme={(theme) => ({ ...theme, colors: this.colors })}
          styles={{
            option: (provided, state) => ({
              ...provided,
              color: this.base9a,
              opacity: state.isDisabled ? 0.2 : 1,
              cursor: state.isDisabled ? "not-allowed" : undefined,
            }),
            control: (base, state) =>
              matchFormControl
                ? {
                    ...base,
                    height: "calc(1.5em + 0.75rem + 2px)",
                    minHeight: "calc(1.5em + 0.75rem + 2px)",
                    opacity: state.isDisabled ? "0.7" : undefined,
                  }
                : { ...base, opacity: state.isDisabled ? "0.7" : undefined },
          }}
          value={value || null} /* Component only resets properly with null and not with undefined */
          onChange={disabled ? undefined : onChange}
          isDisabled={disabled}
          isClearable={isClearable}
          isMulti={isMulti}
          placeholder={placeholder}
          classNamePrefix={classNamePrefix}
          onMenuOpen={() => this.setState({ zIndex: 2 })}
          onMenuClose={() => this.setState({ zIndex: 1 })}
          menuPlacement={menuPlacement ?? "bottom"}
        />
      </div>
    );
  }
}

export default CustomSelect;
