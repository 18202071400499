import React, { PureComponent } from "react";
import _ from "lodash";
import { CO_CANCELED, CustomerOrderExtended } from "../../../../../../model/customerOrder.types";
import { B_PACKAGE_DICT } from "../../../../../../utils/batchUtils";
import { CO_DELIVERYNOTE } from "../../../../../../utils/customerOrderUtils";
import { DI_UNITLOAD } from "../../../../../../utils/deliveryInformationUtils";
import { resolveFilePath, shortenAlias } from "../../../../../../utils/fileUtils";
import { round } from "../../../../../../utils/baseUtils";

interface WorkflowShippingInformationCardProps {
  order: CustomerOrderExtended;
}

class WorkflowShippingInformationCard extends PureComponent<WorkflowShippingInformationCardProps> {
  render() {
    const { order } = this.props;
    const { shippingInformation, files, trackingInformation } = order;
    const deliveryNotes = files.filter((f) => f.type === CO_DELIVERYNOTE);
    const allDone = shippingInformation && deliveryNotes.length > 0 && trackingInformation;

    return (
      <div className="opacity-100-hover" style={{ opacity: allDone || order.state === CO_CANCELED ? 0.3 : 1 }}>
        <div className="fw-bolder text-white fs-3 my-5">
          Fulfillment Information{" "}
          {allDone ? (
            <i className="h2 fas fa-check-circle text-success" />
          ) : order.state !== CO_CANCELED ? (
            <span className="text-warning">[Current Task]</span>
          ) : null}
        </div>
        <div className="row py-2">
          <div className="bg-light2 rounded p-5 mb-7">
            <div className="row">
              <div className="col-12">
                <div className="fw-bolder text-white fs-5 my-2">Delivery Information</div>
                <div className="row my-1">
                  <div className="col-12">
                    {shippingInformation ? (
                      <table className="table align-middle table-row-dashed gy-5 dataTable no-footer table-no-padding">
                        <thead>
                          <tr className="text-start text-gray-400 fw-bolder fs-7 gs-0 px-0 border-bottom-dark-gray">
                            <th className="pb-2 pt-0 px-1" style={{ cursor: "initial" }}>
                              <span>Description</span>
                            </th>
                            <th className="pb-2 pt-0 px-1" style={{ cursor: "initial" }}>
                              <span>Batch</span>
                            </th>
                            <th className="text-right pb-2 pt-0 px-1" style={{ cursor: "initial" }}>
                              <span>Packages</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-gray-600 fs-7">
                          {shippingInformation.shippingGroups.map((sg, idx) => (
                            <tr
                              key={sg._id.toString()}
                              className={
                                "px-0 " +
                                (idx < shippingInformation.shippingGroups.length - 1 ? "border-bottom-dark-gray" : "")
                              }
                            >
                              <td className="align-middle py-2 px-1">
                                <span className="text-white">{sg.description}</span>
                              </td>
                              <td className="align-middle py-2 px-1">
                                <span className="text-white">{sg.lot}</span>
                              </td>
                              <td className="align-middle text-right py-2 px-1">
                                <span className="text-white">{`${sg.packageAmount} x ${sg.packageNetWeight}kg/${
                                  (_.get(B_PACKAGE_DICT, sg.packageType) as string | undefined) || "Unknown"
                                }`}</span>
                              </td>
                            </tr>
                          ))}
                          <tr className="px-0 border-bottom-dark-gray">
                            <td className="align-middle py-2 px-1">
                              <span className="text-white">{`${
                                DI_UNITLOAD.find((ul) => ul.value === shippingInformation.unitLoad)?.label ||
                                shippingInformation.unitLoad
                              }: ${shippingInformation.unitLoadAmount} ${
                                shippingInformation.shipVia.trim() ? `(${shippingInformation.shipVia.trim()})` : ""
                              }`}</span>
                            </td>
                            <td className="text-right py-2 px-1">
                              <span className="text-white ">Total (net):</span>
                            </td>
                            <td className="align-middle text-right py-2 px-1">
                              <span className="text-white">
                                {shippingInformation.shippingGroups.reduce(
                                  (a, b) => round(a + b.packageAmount * b.packageNetWeight, 2),
                                  0
                                )}
                                kg
                              </span>
                            </td>
                          </tr>
                          <tr className="px-0 border-bottom-dark-gray">
                            <td className="align-middle py-2 px-1" colSpan={10}>
                              <span className="text-white">
                                Additional Information: {shippingInformation.additionalInformation}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ) : (
                      <div className="text-muted">No delivery information provided yet</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="fw-bolder text-white fs-5 my-2">Delivery Note</div>
                <div className="row my-1">
                  <div className="col-12">
                    {deliveryNotes.length > 0 ? (
                      deliveryNotes.map((f) => (
                        <div key={f._id.toString()}>
                          <a
                            href={resolveFilePath(f.path)}
                            className="custom-link"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="text-white fw-bold">{shortenAlias(f.path)}</span>
                          </a>
                        </div>
                      ))
                    ) : (
                      <div className="text-muted ">No delivery notes available</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="fw-bolder text-white fs-5 my-2">Tracking Number</div>
                <div className="row my-1">
                  <div className="col-12">
                    {trackingInformation ? (
                      trackingInformation.trackingLink.trim() ? (
                        <a
                          href={trackingInformation.trackingLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-white fw-bold custom-link"
                        >{`${trackingInformation.trackingNumber} (${trackingInformation.deliveryCompany})`}</a>
                      ) : (
                        <span className="text-white fw-bold">{`${trackingInformation.trackingNumber} (${trackingInformation.deliveryCompany})`}</span>
                      )
                    ) : (
                      <span className="text-muted">Order not shipped yet</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WorkflowShippingInformationCard;
