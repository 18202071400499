import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { DataContextInternal } from "../../../context/dataContext";
import ServiceListing from "./ServiceListing";
import CreateServiceModal from "./modals/CreateServiceModal";

class ServiceOverview extends PureComponent {
  static contextType = DataContextInternal;
  context!: React.ContextType<typeof DataContextInternal>;

  render() {
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="card bg-white min-h-100">
              <div className="card-body">
                <h3 className="card-title align-items-start flex-column mb-15">
                  <span className="card-label fw-bolder mb-3 fs-3rem">Services</span>
                  <div className="float-right">
                    <Link to="/settings">
                      <button className="btn btn-text text-white">Back</button>
                    </Link>
                    <CreateServiceModal />
                  </div>
                </h3>
                <div className="row">
                  <div className="col-12">
                    <ServiceListing context={this.context} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceOverview;
