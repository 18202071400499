import _ from "lodash";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import {
  CustomerOrderExtended,
  CustomerOrderTrackingInformation,
} from "../../../../../../../model/customerOrder.types";
import { Input } from "../../../../../../common/Input";
import { getDefaultTrackingInformation } from "../../../../../../../utils/customerOrderUtils";
import ErrorOverlayButton from "../../../../../../common/ErrorOverlayButton";
import Tooltip from "../../../../../../common/Tooltip";

interface WorkflowTrackingInformationModalProps {
  order: CustomerOrderExtended;
  saving: boolean;
  trackingInformation?: CustomerOrderTrackingInformation;
  onAddTrackingInformation: (trackingInformation: CustomerOrderTrackingInformation) => void;
  disabled?: boolean;
}

interface WorkflowTrackingInformationModalState {
  show: boolean;
  trackingInformation: CustomerOrderTrackingInformation;
}

class WorkflowTrackingInformationModal extends PureComponent<
  WorkflowTrackingInformationModalProps,
  WorkflowTrackingInformationModalState
> {
  constructor(props: WorkflowTrackingInformationModalProps) {
    super(props);
    this.state = {
      show: false,
      trackingInformation: props.trackingInformation ?? getDefaultTrackingInformation(),
    };
  }

  componentDidUpdate(
    prevProps: Readonly<WorkflowTrackingInformationModalProps>,
    prevState: Readonly<WorkflowTrackingInformationModalState>
  ) {
    if (
      this.props.trackingInformation &&
      _.isEqual(prevState, this.state) &&
      !_.isEqual(this.props.trackingInformation, this.state.trackingInformation) &&
      !_.isEqual(this.props.trackingInformation, prevProps.trackingInformation)
    ) {
      this.setState({ trackingInformation: this.props.trackingInformation });
    }
  }

  handleShow = () =>
    this.setState({
      show: true,
      trackingInformation: this.props.trackingInformation ?? getDefaultTrackingInformation(),
    });
  handleHide = () => this.setState({ show: false });

  handleAddTrackingInformation = () => {
    this.props.onAddTrackingInformation(this.state.trackingInformation);
    this.handleHide();
  };

  handleChangeTrackingInformation = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    if (!this.state.trackingInformation) return;
    const trackingInformation = _.cloneDeep(this.state.trackingInformation);
    _.set(trackingInformation, e.target.name, e.target.value);
    this.setState({ trackingInformation });
  };

  validateData = () => {
    const { trackingInformation } = this.state;
    const { trackingNumber, deliveryCompany } = trackingInformation;
    const errors = [];
    if (!trackingNumber.trim()) errors.push("Enter a tracking number");
    if (!deliveryCompany.trim()) errors.push("Enter the delivery company");
    return errors;
  };

  render() {
    const { order, saving, disabled } = this.props;
    const { show, trackingInformation } = this.state;
    const errors = this.validateData();
    return (
      <>
        <span className="fw-bolder text-white">
          Tracking:{" "}
          <Tooltip tooltipText="Add tracking information for the customer">
            <span
              className={
                ((saving || disabled) && "disabled") +
                (order.trackingInformation ? " text-success text-success-hover" : " text-danger text-danger-hover")
              }
              onClick={saving || disabled ? undefined : this.handleShow}
            >
              {order.trackingInformation ? "Done" : "Pending"}
            </span>
          </Tooltip>
        </span>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Add Tracking Information</h1>
            </Modal.Title>
            <CloseButton variant={"white"} onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-5">
              <div className="col-12">
                <div className="row mb-5 ">
                  <div className="col-12 mt-5">
                    <label className="required fs-5 fw-bold mb-2">Tracking Number</label>
                    <Input
                      type="text"
                      className="form-control custom-form-control pt-0 pb-0"
                      placeholder={"e.g. 123-4567890"}
                      value={trackingInformation.trackingNumber}
                      name={"trackingNumber"}
                      onChange={this.handleChangeTrackingInformation}
                    />
                  </div>
                  <div className="col-12 mt-5">
                    <label className="required fs-5 fw-bold mb-2">Delivery Company</label>
                    <Input
                      type="text"
                      className="form-control custom-form-control pt-0 pb-0"
                      placeholder={"e.g. DHL"}
                      value={trackingInformation.deliveryCompany}
                      name={"deliveryCompany"}
                      onChange={this.handleChangeTrackingInformation}
                    />
                  </div>
                  <div className="col-12 mt-5">
                    <label className="fs-5 fw-bold mb-2">Tracking Link</label>
                    <Input
                      type="text"
                      className="form-control custom-form-control pt-0 pb-0"
                      placeholder={"https://..."}
                      value={trackingInformation.trackingLink}
                      name={"trackingLink"}
                      onChange={this.handleChangeTrackingInformation}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-text-light" onClick={this.handleHide}>
              Close
            </button>
            <ErrorOverlayButton
              errors={errors}
              className={"btn btn-sm btn-outline btn-outline-light "}
              saving={saving}
              buttonText={(this.props.trackingInformation ? "Update" : "Add") + " Tracking Information"}
              onClick={this.handleAddTrackingInformation}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default WorkflowTrackingInformationModal;
