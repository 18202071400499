import React, { PureComponent } from "react";
import Lottie from "lottie-react";
import { DataContextAnonymousType, DataContextCustomerType } from "../../../context/dataContext";
import NewsPreview from "../../common/NewsPreview";
import Search from "../../common/Search";
import Pagination, { paginate, PaginationState } from "../../common/Pagination";
import { doFuseSearch } from "../../../utils/baseUtils";
import astronautAnimation from "../../../assets/animation.json";
import { getNews } from "../../../utils/articleUtils";

interface CustomerNewsListingProps {
  context: DataContextCustomerType | DataContextAnonymousType;
}

interface CustomerNewsListingState extends PaginationState {
  search: string;
}

/**
 * Function component to represents the news in a specific page (customer view).
 */
class CustomerNewsListing extends PureComponent<CustomerNewsListingProps, CustomerNewsListingState> {
  constructor(props: CustomerNewsListingProps) {
    super(props);
    this.state = {
      pageSize: 25,
      currentPage: 1,
      search: "",
    };
  }

  handlePageChange = (page: number) => this.setState({ currentPage: page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value, currentPage: 1 });

  getFilteredNews = () => {
    const { context } = this.props;
    const { search } = this.state;
    let filteredNews = getNews(context.news);
    if (search) filteredNews = doFuseSearch(filteredNews, search, ["tags", "title", "text"]);
    else
      filteredNews = filteredNews.sort((a, b) => {
        return b.creationTime.getTime() - a.creationTime.getTime();
      });

    return filteredNews;
  };

  render() {
    const { context } = this.props;
    const { pageSize, currentPage, search } = this.state;

    const news = this.getFilteredNews();
    const newsCopy = news.slice();
    const firstArticle = newsCopy.shift();
    const paginatedNews = paginate(newsCopy, currentPage, pageSize);

    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="card bg-white">
              <div className="card-body">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder mb-3 fs-3rem">News & Updates</span>
                  <span className="float-right mt-3">
                    <Search placeholder="Search articles..." onSearch={this.handleSearch} value={search} />
                  </span>
                </h3>
                <p className="mb-15 fs-5 text-muted">
                  Showing {paginatedNews.length + (firstArticle ? 1 : 0)} of {news.length} matching articles
                </p>
                {news.length > 0 && firstArticle ? (
                  <>
                    <span className="text-white p-3 fs-2">
                      {search.trim() ? "Best Matching Article" : "Latest Article"}
                    </span>
                    <div className="row">
                      <NewsPreview article={firstArticle} context={context} size={"lg"} />
                    </div>
                    {paginatedNews.length > 0 && (
                      <>
                        <span className="text-white p-3 fs-2">
                          {search.trim() ? "Other Articles" : "Older Articles"}
                        </span>
                        <div className="row">
                          {paginatedNews.map((article) => (
                            <NewsPreview key={article._id.toString()} article={article} context={context} />
                          ))}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <div className="text-center">
                    <Lottie animationData={astronautAnimation} loop={true} className="w-50 mx-auto mt-20" />
                    <div className="my-20">
                      <h1 className="mb-5">
                        It's lonely out here in space... <br />
                        just like our news list!
                      </h1>
                      <h6 className="text-muted">
                        {search.trim()
                          ? "Unfortunately we couldn't find any matching articles, but new interesting articles will arrive soon!"
                          : "Interesting news articles will show up here soon!"}
                      </h6>
                    </div>
                  </div>
                )}
                <Pagination
                  itemsCount={news.length}
                  pageSize={pageSize}
                  baseSize={9}
                  onPageChange={this.handlePageChange}
                  currentPage={currentPage}
                  onPageSizeChange={this.handlePageSizeChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CustomerNewsListing;
