import React from "react";
import { PropertyType } from "../../../utils/propertyUtils";
import { FinishedProductExtended, FinishedProductSnapshot } from "../../../model/finishedProduct.types";
import { CustomerFinishedProductExtended } from "../../../model/customer/customerFinishedProduct.types";
import { FP_FINISHEDPRODUCT_PROPERTIES } from "../../../utils/finishedProductUtils";
import { getArticleProperty } from "../../../utils/commodityUtils";
import { Property } from "../../../model/property.types";
import { formatCurrency } from "../../../utils/baseUtils";
import CountryWidget from "../../common/CountryWidget";
import { DataContextInternalType } from "../../../context/dataContext";

export interface FinishedProductUpdateContent {
  label: string;
  property: FP_FINISHEDPRODUCT_PROPERTIES | PropertyType;
  value: (
    finishedProduct: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended,
    context: DataContextInternalType
  ) => string | number | JSX.Element;
}

export const FP_G_BASIC_UPDATE: Array<FinishedProductUpdateContent> = [
  {
    label: "Title",
    property: "title",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) => obj.title?.en,
  },
  {
    label: "Subtitle",
    property: "subtitle",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) =>
      obj.subtitle?.en ? obj.subtitle.en : "-",
  },
  {
    label: "Category",
    property: PropertyType.CATEGORY,
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) => {
      const category = getArticleProperty(obj.properties, PropertyType.CATEGORY) as Property | null;
      return category ? category.name.en : "-";
    },
  },
  {
    label: "Article Number",
    property: "articleNo",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) =>
      obj.articleNo ? obj.articleNo : "-",
  },
  {
    label: "HS-Code",
    property: "hsCode",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) =>
      obj.hsCode ? obj.hsCode : "-",
  },
  {
    label: "Note",
    property: "note",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) =>
      obj.note ? obj.note : "-",
  },
  {
    label: "Shelf Life",
    property: "shelfLife",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) =>
      obj.shelfLife ? obj.shelfLife + " months" : "-",
  },
  {
    label: "Duty",
    property: "duty",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) =>
      obj.duty ? (
        <>
          {obj.duty.percentage !== undefined && obj.duty.percentage !== null && (
            <div>{obj.duty.percentage.toFixed(2) + "%"}</div>
          )}
          {obj.duty.dumpingFee && <div>Dumping Fee: {formatCurrency(obj.duty.dumpingFee, "EUR") + " per 100 KG"}</div>}
        </>
      ) : (
        "-"
      ),
  },
  {
    label: "Packaging",
    property: "packagingSizes",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) =>
      obj.packagingSizes
        ? obj.packagingSizes
            .map((pS) => {
              return `${pS.packagingSize} kg ${pS.type}`;
            })
            .join(", ")
        : "-",
  },
  {
    label: "Composition",
    property: PropertyType.COMPOSITION,
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) => {
      const composition = getArticleProperty(obj.properties, PropertyType.COMPOSITION) as Property | null;
      return composition ? composition.name.en : "-";
    },
  },
  {
    label: "Organic",
    property: "organic",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) =>
      obj.organic !== undefined ? (obj.organic ? "Yes" : "No") : "-",
  },
  {
    label: "Color",
    property: "color",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) => obj.color,
  },
  {
    label: "Origin Country",
    property: "country",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) =>
      obj.country ? <CountryWidget countryCode={obj.country.code} /> : "-",
  },
  {
    label: "Storage Conditions",
    property: "storageConditions",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) =>
      obj.storageConditions?.en ? obj.storageConditions.en : "-",
  },
  {
    label: "Transport Conditions",
    property: PropertyType.TRANSPORTCONDITIONS,
    value: (
      obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended,
      context: DataContextInternalType
    ) =>
      obj.transportConditions
        ? obj.transportConditions
            .map((tc) => {
              const condition = context.property.find((p) => tc.property === p._id.toString());
              return condition ? `${condition.name.en}: ${condition.description.en}` : "-";
            })
            .join(", ")
        : "-",
  },
  {
    label: "Weight per Unit",
    property: "weightPerUnit",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) =>
      obj.weightPerUnit ? obj.weightPerUnit : 1,
  },
  {
    label: "Volume per Unit",
    property: "volumePerUnit",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) =>
      obj.volumePerUnit ? obj.volumePerUnit : 1,
  },
  {
    label: "BTI Reference No",
    property: "btiRefNo",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) =>
      obj.btiRefNo ? obj.btiRefNo : "-",
  },
  {
    label: "Purpose of Use",
    property: PropertyType.PURPOSEOFUSE,
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) => {
      const purposeOfUse = getArticleProperty(
        obj.properties,
        PropertyType.PURPOSEOFUSE,
        true
      ) as Array<Property> | null;
      return purposeOfUse && purposeOfUse.length > 0
        ? purposeOfUse.map((pOU) => pOU.name.en).join(",")
        : "Not Specified";
    },
  },
  {
    label: "German VAT Rate",
    property: "vatPercentage",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) =>
      obj.vatPercentage ? `${obj.vatPercentage}%` : "Not Specified",
  },
];

export const FP_G_PRODUCTINFORMATION_UPDATE: Array<FinishedProductUpdateContent> = [
  {
    label: "Vegetarian",
    property: "vegetarian",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) =>
      obj.vegetarian !== undefined ? (obj.vegetarian ? "Yes" : "No") : "-",
  },
  {
    label: "Vegan",
    property: "vegan",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) =>
      obj.vegan !== undefined ? (obj.vegan ? "Yes" : "No") : "-",
  },
  {
    label: "Halal",
    property: "halal",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) =>
      obj.halal !== undefined ? (obj.halal ? "Yes" : "No") : "-",
  },
  {
    label: "Kosher",
    property: "kosher",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) =>
      obj.kosher !== undefined ? (obj.kosher ? "Yes" : "No") : "-",
  },
  {
    label: "Packaging",
    property: PropertyType.PACKAGING,
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) => {
      const packaging = getArticleProperty(obj.properties, PropertyType.PACKAGING) as Property | null;
      return packaging ? packaging.name.en : "-";
    },
  },
];

export const FP_G_GRADE_UPDATE: Array<FinishedProductUpdateContent> = [
  {
    label: "Food Grade",
    property: "foodGrade",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) =>
      obj.foodGrade !== undefined ? (obj.foodGrade ? "Yes" : "No") : "-",
  },
  {
    label: "Pharmaceutical Grade",
    property: "pharmaceuticalGrade",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) =>
      obj.pharmaceuticalGrade !== undefined ? (obj.pharmaceuticalGrade ? "Yes" : "No") : "-",
  },
  {
    label: "Feed Grade",
    property: "feedGrade",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) =>
      obj.feedGrade !== undefined ? (obj.feedGrade ? "Yes" : "No") : "-",
  },
  {
    label: "Cosmetic Grade",
    property: "cosmeticGrade",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) =>
      obj.cosmeticGrade !== undefined ? (obj.cosmeticGrade ? "Yes" : "No") : "-",
  },
  {
    label: "USP Grade",
    property: "uspGrade",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) =>
      obj.uspGrade !== undefined ? (obj.uspGrade ? "Yes" : "No") : "-",
  },
  {
    label: "Medicine Grade",
    property: "medicineGrade",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) =>
      obj.medicineGrade !== undefined ? (obj.medicineGrade ? "Yes" : "No") : "-",
  },
  {
    label: "Industrial Grade",
    property: "industrialGrade",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) =>
      obj.industrialGrade !== undefined ? (obj.industrialGrade ? "Yes" : "No") : "-",
  },
];

export const FP_G_OTHER_CATEGORIES_UPDATE: Array<FinishedProductUpdateContent> = [
  {
    label: "Active Substances",
    property: "activeSubstances",
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) => {
      return obj.activeSubstances
        ? obj.activeSubstances
            .map((as) => {
              return `${as.substance.name.en} with ${as.percentage}, NRV: ${as.substance.nrv}`;
            })
            .join(", ")
        : "-";
    },
  },
  {
    label: "Allergens",
    property: PropertyType.ALLERGEN,
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) => {
      const allergens = getArticleProperty(obj.properties, PropertyType.ALLERGEN, true) as Array<Property> | null;
      return allergens
        ? allergens
            .map((a) => {
              return a.name.en;
            })
            .join(", ")
        : "-";
    },
  },
  {
    label: "Finished Product Tags",
    property: PropertyType.TAG,
    value: (obj: FinishedProductSnapshot | CustomerFinishedProductExtended | FinishedProductExtended) => {
      const tags = getArticleProperty(obj.properties, PropertyType.TAG, true) as Array<Property> | null;
      return tags
        ? tags
            .map((t) => {
              return t.name.en;
            })
            .join(", ")
        : "-";
    },
  },
];

export const FP_UPDATE_TABLE_DEFINITIONS: Array<{
  content: Array<FinishedProductUpdateContent>;
  mainTitle: string;
  subtitle: string;
}> = [
  { content: FP_G_BASIC_UPDATE, mainTitle: "Basic Information", subtitle: "General Properties" },
  {
    content: FP_G_PRODUCTINFORMATION_UPDATE,
    mainTitle: "Advanced Properties",
    subtitle: "General Product Information",
  },
  { content: FP_G_GRADE_UPDATE, mainTitle: "Advanced Properties", subtitle: "Grade" },
  { content: FP_G_OTHER_CATEGORIES_UPDATE, mainTitle: "Advanced Properties", subtitle: "Other Categories" },
];
