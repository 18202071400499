import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import { Textarea } from "../../../common/Textarea";
import { callPushToArray } from "../../../../utils/baseUtils";
import { EXTENDED_ORDER_TYPES, getNoteObject, resolveOrderCollection } from "../../../../utils/orderUtils";
import { CUSTOMERCONTRACT } from "../../../../services/dbService";
import { EXTENDED_CONTRACT_TYPES } from "../../../../utils/contractUtils";

interface AddNoteModalProps {
  order: EXTENDED_ORDER_TYPES | EXTENDED_CONTRACT_TYPES;
}

interface AddNoteModalState {
  note: string;
  show: boolean;
  saving: boolean;
}

class AddNoteModal extends PureComponent<AddNoteModalProps, AddNoteModalState> {
  constructor(props: AddNoteModalProps) {
    super(props);
    this.state = this.getDefaultState(false);
  }

  handleShow = () => this.setState(this.getDefaultState(true));
  handleHide = () => this.setState({ show: false });
  handleChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => this.setState({ note: e.target.value });

  handleAddNote = async () => {
    const { order } = this.props;
    const { note } = this.state;
    this.setState({ saving: true });
    try {
      const collection = "contractInformation" in order ? CUSTOMERCONTRACT : resolveOrderCollection(order);
      let result;
      if (collection) {
        result = await callPushToArray(collection, order._id, "noteInternal", getNoteObject(note));
      }
      if (result && result.modifiedCount > 0) {
        toast.success("Note successfully added");
        this.handleHide();
      } else {
        toast.error("Error adding note");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  getDefaultState = (show: boolean) => {
    return { note: "", show, saving: false };
  };

  render() {
    const { note, show, saving } = this.state;
    return (
      <>
        <button type="button" className="btn btn-outline btn-outline-light btn-sm" onClick={this.handleShow}>
          Add Note
        </button>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Add Note</h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="mt-5">
              <label className="fs-5 fw-bold mb-2">Note</label>
              <Textarea className="form-control custom-form-control" value={note} onChange={this.handleChangeNote} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-outline btn-outline-light" onClick={this.handleHide}>
              Close
            </button>
            <ErrorOverlayButton
              errors={!note.trim() ? ["Please insert a note"] : []}
              saving={saving}
              className="btn btn-sm btn-outline btn-outline-light"
              buttonText="Add Note"
              onClick={this.handleAddNote}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default AddNoteModal;
