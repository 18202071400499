import React, { useContext } from "react";
import { DataContextAnonymous, DataContextCustomer } from "../../../context/dataContext";
import CustomerContractListing from "./CustomerContractListing";
import userService from "../../../services/userService";
import { ANONYMOUS, CUSTOMER } from "../../../utils/userUtils";

const CustomerContractListingWrapper: React.FunctionComponent = () => {
  let context;
  if (userService.getUserType() === CUSTOMER) {
    context = useContext(DataContextCustomer);
  } else if (userService.getUserType() === ANONYMOUS) {
    context = useContext(DataContextAnonymous);
  }
  if (!context) return null;
  return <CustomerContractListing context={context} />;
};

export default CustomerContractListingWrapper;
