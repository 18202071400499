import React, { Component } from "react";
import { ActiveSubstance } from "../../../model/activeSubstance.types";
import CreateActiveSubstanceModal from "./modals/CreateActiveSubstanceModal";
import DisableActiveSubstanceModal from "./modals/DisableActiveSubstanceModal";

interface ActiveSubstanceProps {
  activeSubstance: ActiveSubstance;
}

class ActiveSubstanceRow extends Component<ActiveSubstanceProps> {
  render() {
    const { activeSubstance } = this.props;
    return (
      <tr>
        <td className="text-white align-middle">{activeSubstance.name.en}</td>
        <td className="text-white align-middle">{activeSubstance.nrv ? activeSubstance.nrv + " µg" : "-"}</td>
        <td className="text-white align-middle">{activeSubstance.disabled ? "Disabled" : "Enabled"}</td>
        <td className="text-right align-middle">
          <DisableActiveSubstanceModal activeSubstance={activeSubstance} />
          <CreateActiveSubstanceModal activeSubstance={activeSubstance} additionalButtonClasses="btn-sm ml-2" />
        </td>
      </tr>
    );
  }
}

export default ActiveSubstanceRow;
