// Backend functions relating to airports
import { BSON } from "realm-web";
import { callFunction } from "../services/dbService";
import { Airport } from "../model/airport.types";
import { Seaport } from "../model/seaport.types";
import { Address } from "../model/commonTypes";

export const UPSERTAIRPORT = "upsertAirport";

/**
 * Inserts a new airport into the database.
 * @param airport Airport that should be inserted into the database
 * @returns {Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false>} Result of the function
 */
export async function insertAirport(
  airport: Airport | Omit<Airport, "_id">
): Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false> {
  return (await callUpsertAirport(airport, true)) as Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false;
}

/**
 * Updates an existing airport inside the database.
 * @param airport Airport that should be updated inside the database
 * @param airportId optional, airport id for partial updates
 * @returns {Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false>} Result of the function
 */
export async function updateAirport(
  airport: Partial<Airport>,
  airportId?: BSON.ObjectId
): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> {
  if (airportId) airport._id = airportId;
  return (await callUpsertAirport(airport, false)) as Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false;
}

/**
 * Calls the upsert airport function in backend.
 * @param airport Airport that should be upsert
 * @param insert True for insert, else update
 * @returns {Promise<false | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>} Result of the function
 */
async function callUpsertAirport(
  airport: Partial<Airport>,
  insert: boolean
): Promise<
  false | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>
> {
  return callFunction(UPSERTAIRPORT, [airport, insert]);
}

/**
 * Get a description for an airport
 * @param airport a airport object or string for backwards compatibility
 * @returns {string} a description of the airport with name and iata/icao code or the passed string itself if a string was passed
 */
export function getAirportName(airport: Airport | string): string {
  if (typeof airport === "object") {
    return `${airport.name} (${airport.iata} - ${airport.icao})`;
  }
  return airport;
}

/**
 * Get all airports valid as a starting airport
 * @param airports list of airports
 * @returns {Array<Airport>} list of filtered airports feasible as starting airports
 */
export function getStartingAirports(airports: Array<Airport>): Array<Airport> {
  return airports.filter((a) => a.cost !== undefined && !a.disabled);
}

/**
 * Get all airports valid as a destination airport
 * @param airports list of airports
 * @returns {Array<Airport>} list of filtered airports feasible as destination airports
 */
export function getDestinationAirports(airports: Array<Airport>): Array<Airport> {
  return airports.filter((a) => a.cost === undefined && !a.disabled);
}

/**
 * Check if a port is an airport
 * @param port the port to check
 * @returns {boolean} true if port is an airport, else false
 */
export function isAirport(port: Airport | Seaport | Address | string): port is Airport {
  if (typeof port === "string") return false;
  return "icao" in port;
}
