import React, { useContext } from "react";
import { DataContextInternal } from "../../../context/dataContext";
import SupplierListing from "./SupplierListing";

interface SupplierListingWrapperProps {}

const SupplierListingWrapper: React.FunctionComponent<SupplierListingWrapperProps> = (props) => {
  const context = useContext(DataContextInternal);

  return <SupplierListing {...props} context={context} />;
};

export default SupplierListingWrapper;
