import React, { useCallback, useContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { DataContextInternal } from "../../../../context/dataContext";
import { EXPENSES, insertGeneral, updateGeneral } from "../../../../utils/generalUtils";
import { formatCurrency } from "../../../../utils/baseUtils";
import { EURO } from "../../../../utils/currencyUtils";
import { Input } from "../../../common/Input";
import { getMonthString } from "../../../../utils/dateUtils";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";

interface Expenses {
  [key: string]: Array<number>;
}

const ExpenseValues: React.FC = () => {
  const context = useContext(DataContextInternal);

  const [newExpenses, setNewExpenses] = useState<Expenses>({});

  const handleAddNewExpenses = () => {
    setNewExpenses((prevState) => {
      const newExpenses = { ...prevState };
      newExpenses["0"] = new Array(12).fill(0) as Array<number>;
      return newExpenses;
    });
  };

  const handleRemoveNewExpenses = useCallback(
    (year: string) => {
      setNewExpenses((prevState) => {
        const newExpenses = { ...prevState };
        delete newExpenses[year];
        return newExpenses;
      });
    },
    [newExpenses]
  );

  const handleSaveChanges = useCallback(async (expenses: Expenses, yearOld: string) => {
    const expensesGeneral = context.general.find((g) => g.key === EXPENSES);
    let res;
    try {
      if (!expensesGeneral) {
        res = await insertGeneral({ _id: new BSON.ObjectId(), key: EXPENSES, value: expenses, lastUpdate: new Date() });
      } else {
        const existingExpenses = expensesGeneral.value as Expenses;
        const newKey = Object.keys(expenses);
        existingExpenses[newKey[0]] = expenses[newKey[0]];
        if (yearOld !== newKey[0]) delete existingExpenses[yearOld];
        res = await updateGeneral({ ...expensesGeneral, value: existingExpenses, lastUpdate: new Date() });
      }
      if (res) {
        toast.success("Expenses updated successfully");
        setNewExpenses({});
      } else {
        toast.error("Error updating expenses");
      }
    } catch (e) {
      console.error("ERROR:", e);
      toast.error("Error updating expenses");
    }
  }, []);

  const expenses = useMemo(() => {
    return context.general.find((g) => g.key === EXPENSES)?.value as Expenses | undefined;
  }, [context.general]);
  const years = expenses ? Object.keys(expenses).sort((a, b) => Number(a) - Number(b)) : [];

  const newYears = Object.keys(newExpenses);

  return (
    <div className="content d-flex flex-column flex-column-fluid">
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl" style={{ maxWidth: "1560px" }}>
          <div className="card bg-white">
            <div className="card-body">
              <h3 className="card-title align-items-start flex-column mb-15">
                <span className="card-label fw-bolder mb-3 fs-3rem">Expenses Values Configuration</span>
              </h3>
              <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-hover no-footer">
                <thead>
                  <tr className="fw-bolder text-muted">
                    <th style={{ width: "8.333%" }}>Year</th>
                    <th style={{ width: "8.333%" }} />
                    <th style={{ width: "8.333%" }} />
                    <th style={{ width: "8.333%" }} />
                    <th style={{ width: "8.333%" }} />
                    <th style={{ width: "8.333%" }} />
                    <th style={{ width: "8.333%" }} />
                    <th style={{ width: "8.333%" }} />
                    <th style={{ width: "8.333%" }} />
                    <th style={{ width: "8.333%" }} />
                    <th style={{ width: "8.333%" }} />
                    <th style={{ width: "8.333%" }} />
                  </tr>
                </thead>
                <tbody>
                  {!expenses ? (
                    <tr>
                      <td colSpan={13} className="text-white align-middle">
                        <span>No expenses configuration found. Please add one.</span>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {years.map((y) => (
                        <ExpensesRow key={y} expenses={expenses} year={y} onSaveChanges={handleSaveChanges} />
                      ))}
                      {newExpenses !== {} &&
                        Object.keys(newExpenses).map((y) => (
                          <ExpensesRow
                            key={y}
                            expenses={newExpenses}
                            year={y}
                            onSaveChanges={handleSaveChanges}
                            onRemoveEntry={handleRemoveNewExpenses}
                          />
                        ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div className="card-footer text-right border-0">
              <button
                className="btn btn-sm btn-outline btn-outline-light"
                disabled={newYears.includes("0")}
                onClick={handleAddNewExpenses}
              >
                Add Year
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface ExpensesRowProps {
  expenses: Expenses;
  year: string;
  onSaveChanges: (expenses: Expenses, yearOld: string) => Promise<void>;
  onRemoveEntry?: (year: string) => void;
}

const ExpensesRow: React.FC<ExpensesRowProps> = ({ expenses, year, onSaveChanges, onRemoveEntry }) => {
  const newEntry = useMemo(() => Boolean(onRemoveEntry), [onRemoveEntry]);

  const [edit, setEdit] = useState<boolean>(newEntry);
  const [saving, setSaving] = useState<boolean>(false);
  const [expensesEdit, setExpensesEdit] = useState<Array<number>>(Array.from(expenses[year]));
  const [yearEdit, setYearEdit] = useState<number>(Number(year));

  const handleToggleEdit = () => setEdit(!edit);

  const handleReset = () => {
    setExpensesEdit(Array.from(expenses[year]));
    setEdit(false);
  };

  const handleRemoveEntry = () => onRemoveEntry && onRemoveEntry(year);
  const handleSaveChanges = async () => {
    setSaving(true);
    await onSaveChanges({ [yearEdit]: expensesEdit }, year);
    setSaving(false);
    setEdit(false);
  };

  const handleEditExpenses = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
      setExpensesEdit((prevState) => {
        const expensesEdit = Array.from(prevState);
        expensesEdit[idx] = e.target.valueAsNumber;
        return expensesEdit;
      });
    },
    [expensesEdit]
  );

  const handleEditExpensesYear = (e: React.ChangeEvent<HTMLInputElement>) => setYearEdit(e.target.valueAsNumber);

  const errors = useMemo(() => {
    const errors: Array<string> = [];
    if (yearEdit < 2023) errors.push("Year must be after 2022.");
    if (expensesEdit.some((e) => e < 0)) errors.push("Expenses must be positive or 0");
    return errors;
  }, [expensesEdit, yearEdit]);

  return (
    <>
      <tr>
        <td className="text-white align-middle">
          {edit ? (
            <Input type="number" onChange={handleEditExpensesYear} value={Number(yearEdit)} allowNegative={false} />
          ) : (
            <h2 className="text-white">{year}</h2>
          )}
        </td>
        <td colSpan={8} />
        <td colSpan={3} className="text-white align-middle text-right">
          {edit && (
            <ErrorOverlayButton
              saving={saving}
              className="btn btn-sm btn-outline btn-outline-light mr-1"
              onClick={newEntry ? handleRemoveEntry : handleReset}
            >
              {newEntry ? "Remove" : "Cancel"}
            </ErrorOverlayButton>
          )}
          <ErrorOverlayButton
            errors={edit ? errors : []}
            saving={saving}
            className="btn btn-sm btn-outline btn-outline-light"
            onClick={edit ? handleSaveChanges : handleToggleEdit}
          >
            {edit ? "Save" : "Edit"}
          </ErrorOverlayButton>
        </td>
      </tr>
      <tr>
        {expensesEdit.map((e, idx) => (
          <td className="text-white align-middle" key={idx}>
            <label className="fs-6 fw-bold mb-2">{getMonthString(idx)}</label>
            <br />
            {edit ? (
              <Input type="number" onChange={(e) => handleEditExpenses(e, idx)} allowNegative={false} value={e} />
            ) : (
              <span>{formatCurrency(e, EURO)}</span>
            )}
          </td>
        ))}
      </tr>
    </>
  );
};

export default ExpenseValues;
