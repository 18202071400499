import { SelectOption } from "../components/common/CustomSelect";
import { R_DELAYEDORDERS, R_EXPIREDPRICES, R_PRICEUPDATEREQUESTS, R_SUMMARY } from "../model/userData.types";
import { callFunction } from "../services/dbService";

export const SUPPLIER_NOTIFICATION_REASONS: Array<
  typeof R_SUMMARY | typeof R_EXPIREDPRICES | typeof R_PRICEUPDATEREQUESTS
> = [R_SUMMARY, R_EXPIREDPRICES, R_PRICEUPDATEREQUESTS];

export const SUPPLIER_NOTIFICATION_REASON_DESCRIPTIONS = {
  [R_SUMMARY]: { label: "Summary", interval: "" },
  [R_EXPIREDPRICES]: { label: "Expired Prices", interval: "" },
  [R_PRICEUPDATEREQUESTS]: { label: "Price Update Requests", interval: "" },
};

export const CUSTOMER_NOTIFICATION_REASONS: Array<typeof R_DELAYEDORDERS> = [R_DELAYEDORDERS];

export const CUSTOMER_NOTIFICATION_REASON_DESCRIPTIONS = {
  [R_DELAYEDORDERS]: { label: "Delayed Orders", interval: "always" },
};

export const T_EMAIL = "email";

export const NOTIFICATION_TYPES = [T_EMAIL];

export const NOTIFICATION_INTERVALS: Array<SelectOption> = [
  { value: "1", label: "Daily" },
  { value: "2", label: "every 2 Days" },
  { value: "5", label: "every 5 Days" },
  { value: "7", label: "Weekly" },
  { value: "14", label: "every 2 Weeks" },
];

// function names
const SENDINVITATIONLINK = "sendInvitationLink";

export const getDefaultReason = (reason: string, checked?: boolean, noInterval?: boolean) => {
  return {
    reason,
    enabled: checked !== undefined ? checked : true,
    interval: noInterval ? 0 : 7,
  };
};

/**
 * Calls the sendInvitationLink function in the backend to send an invitation mail to the given user
 * @param userId user to which the mail should be sent
 * @param link invitation link to display inside the mail
 * @returns { Promise<boolean> } Result of the function, true if mail was sent, false if not
 */
export async function callSendInvitationMail(userId: string, link: string): Promise<boolean> {
  return callFunction(SENDINVITATIONLINK, [userId, link]);
}
