import React from "react";
import { getUserName, SelectedSpecificationUserData } from "../../../utils/userUtils";
import CustomSelect, { SelectOption } from "../CustomSelect";
import { Input } from "../Input";
import DateInput from "../DateInput";
import { resolveFilePath } from "../../../utils/fileUtils";
import Tooltip from "../Tooltip";

interface DocumentApprovalUserProps {
  type: "approver" | "originator";
  disabled?: boolean;
  userData?: SelectedSpecificationUserData;
  internalUsers: Array<SelectOption<SelectedSpecificationUserData>>;
  onSelectUser: (type: "approver" | "originator", e: SelectOption<SelectedSpecificationUserData>) => void;
  onChangePosition: (type: "approver" | "originator", e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDate: (type: "approver" | "originator", e: React.ChangeEvent<HTMLInputElement>) => void;
  onUploadSignature: (type: "approver" | "originator", e: React.ChangeEvent<HTMLInputElement>) => void;
  onRemoveSignature: (type: "approver" | "originator") => void;
  wrapperClasses?: string;
}

const DocumentApprovalUser: React.FunctionComponent<DocumentApprovalUserProps> = ({
  type,
  disabled,
  userData,
  internalUsers,
  onSelectUser,
  onChangePosition,
  onChangeDate,
  onUploadSignature,
  onRemoveSignature,
  wrapperClasses,
}) => {
  const signature = userData?.signature;
  const selectFileRef = React.useRef<HTMLInputElement>(null);

  return (
    <div className={wrapperClasses ?? `px-10 mt-5`}>
      <CustomSelect
        options={internalUsers}
        disabled={disabled}
        value={userData ? { value: userData._id.toString(), label: getUserName(userData) } : undefined}
        matchFormControl={true}
        onChange={(e: SelectOption<SelectedSpecificationUserData>) => onSelectUser(type, e)}
        placeholder={`Select ${type} ...`}
      />
      <Input
        className={"form-control custom-form-control disabled mt-4"}
        disabled={true}
        name={"name"}
        type="text"
        value={userData ? `${userData.prename} ${userData.surname}` : ""}
        placeholder={"Name"}
      />
      <Input
        className={"form-control custom-form-control mt-4 " + ((!userData || disabled) && "disabled")}
        disabled={!userData || disabled}
        name={"position"}
        type="text"
        value={userData?.position || ""}
        onChange={(e) => onChangePosition(type, e)}
        placeholder={"Position"}
      />
      <DateInput
        classes="form-control custom-form-control mt-4"
        value={userData?.date || null}
        disabled={disabled}
        onBlur={(e) => onChangeDate(type, e)}
        name="date"
      />
      <div className="text-center mt-4">
        {signature ? (
          <>
            <img
              style={{ maxWidth: "150px" }}
              src={resolveFilePath(signature.path, signature.isPublic)}
              alt="signature"
            />
            <Tooltip
              tooltipText="Delete signature file"
              tooltipClass="custom-tooltip-danger"
              tooltipId="errorDescription"
            >
              <button
                className={
                  "badge badge-circle remove-signature-button-relative border-0 remove-signature-button-danger " +
                  (disabled && "disabled")
                }
                disabled={disabled}
                onClick={() => onRemoveSignature(type)}
              >
                <i className="fas fa-times" />
              </button>
            </Tooltip>
          </>
        ) : (
          <>
            <button
              className={
                "btn btn-outline btn-outline-light btn-sm px-2 py-1 " + ((!userData || disabled) && "disabled")
              }
              disabled={!userData || disabled}
              onClick={!userData ? undefined : () => selectFileRef.current?.click()}
            >
              Upload Signature
            </button>
            <input
              type="file"
              ref={selectFileRef}
              accept="image/png, image/jpeg"
              style={{ display: "none" }}
              onChange={(e) => onUploadSignature(type, e)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default DocumentApprovalUser;
