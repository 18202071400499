import React from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import Login from "../../components/authentication/Login";
import Logout from "../../components/authentication/Logout";
import BasePage from "../BasePage";
import authService from "../../services/authService";
import PasswordReset from "../../components/authentication/PasswordReset";
import PasswordResetRequest from "../../components/authentication/PasswordResetRequest";
import ApiKeyLogin from "../../components/common/ApiKeyLogin";
import DisableNotification from "../../components/common/DisableNotification";

const Routes = withRouter(({ history }) => {
  const isAuthorized = authService.isLoggedIn();
  if (
    !isAuthorized &&
    history.location.pathname !== "/login" &&
    history.location.pathname !== "/logout" &&
    !history.location.pathname.startsWith("/passwordReset") &&
    !history.location.pathname.startsWith("/invitation") &&
    !history.location.pathname.startsWith("/disableEmailNotification")
  ) {
    window.location.href = "/login";
    return null;
  }
  return (
    <Switch>
      <Route path="/logout" component={Logout} />
      <Route path="/login" component={Login} />
      <Route path="/passwordResetRequest" component={PasswordResetRequest} />
      <Route path="/passwordReset" component={PasswordReset} />
      <Route path="/invitation" component={ApiKeyLogin} />
      <Route path="/disableEmailNotification" component={DisableNotification} />
      <Redirect from="/" exact to="/dashboard" />
      <BasePage />
    </Switch>
  );
});

export default Routes;
