import _ from "lodash";
import React, { Component } from "react";
import { Property } from "../../../model/property.types";
import CreatePropertyModal from "./modals/CreatePropertyModal";
import DisablePropertyModal from "./modals/DisablePropertyModal";

interface PropertyProps {
  property: Property;
}

class PropertyRow extends Component<PropertyProps> {
  render() {
    const { property } = this.props;
    return (
      <tr>
        <td className="text-white align-middle">{property.name.en}</td>
        <td className="text-white align-middle">{property.description.en}</td>
        <td className="text-white align-middle">{_.upperFirst(property.type)}</td>
        <td className="text-white align-middle">{property.disabled ? "Disabled" : "Enabled"}</td>
        <td className="text-right align-middle">
          <DisablePropertyModal property={property} />
          <CreatePropertyModal property={property} additionalButtonClasses="btn-sm ml-2" />
        </td>
      </tr>
    );
  }
}

export default PropertyRow;
