import { BSON } from "realm-web";
import { Action, callFunction, getDb, SUPPLIER, SUPPLIERSTATISTICS, transaction } from "../services/dbService";
import { Commodity } from "../model/commodity.types";
import { doFuseSearch, getDocFromCollection } from "./baseUtils";
import {
  PackagingDimension,
  S_TIMELINETYPE,
  Supplier,
  SupplierExtended,
  SupplierSnapshot,
  SupplierTimelineEntry,
  SupplierTimelineEntryPayload,
} from "../model/supplier.types";
import { UserData } from "../model/userData.types";
import userService from "../services/userService";
import { Address } from "../model/commonTypes";
import { SO_ARCHIVED, SO_CANCELED, SO_STATES, SupplierOrder } from "../model/supplierOrder.types";
import { getDaysBetween } from "./dateUtils";
import { convertCurrency, Currencies } from "./currencyUtils";
import { SupplierStatistics } from "../model/statistics/supplierStatistics.types";
import { Seaport } from "../model/seaport.types";
import { SelectOption } from "../components/common/CustomSelect";
import { getSeaportName } from "./seaportUtils";
import { Airport } from "../model/airport.types";
import { getAirportName } from "./airportUtils";
import { getStandardPackagingDimension } from "./packagingDimensionUtils";
import { FinishedProduct } from "../model/finishedProduct.types";
import { SupplierSupplier, SupplierSupplierExtended } from "../model/supplier/supplierSupplier.types";
import { Company, CompanyExtended } from "../model/company.types";
import { Forwarder, ForwarderExtended } from "../model/forwarder.types";

export type SupplierType = Supplier | SupplierSupplier | SupplierExtended | SupplierSupplierExtended;

export interface ExtendedSupplierTimelineEntry extends SupplierTimelineEntry {
  text: string | JSX.Element;
}

export const SUP_D_CERTIFICATE = "certificate";
export const SUP_D_OTHER = "other";

export const SUP_D_TYPEOPTIONS = [
  { value: SUP_D_CERTIFICATE, label: "Certificate" },
  { value: SUP_D_OTHER, label: "Other" },
];

export const S_GENERALFILTEROPTIONS = [
  { value: "openOrders", label: "Open Orders", isDisabled: true },
  { value: "overdueOrders", label: "Overdue Orders", isDisabled: true },
  { value: "activeWithin3M", label: "Active within 3M", isDisabled: true },
  { value: "inactiveSince3M", label: "Inactive since 3M", isDisabled: true },
  { value: "goodRating", label: "Good Rating" },
  { value: "badRating", label: "Bad Rating" },
];

export const S_SORTOPTIONS = [
  { value: "name", label: "Name" },
  { value: "openOrders", label: "Open Order Volume", isDisabled: true },
  { value: "rating", label: "Rating" },
  { value: "activity", label: "Activity", isDisabled: true },
  { value: "creationDate", label: "Creation Date", isDisabled: true },
];

export const S_COMMODITYOPTIONS = [
  { value: "openRequests", label: "Open Requests to Offer" },
  { value: "requestInReview", label: "Requests in Review" },
];

// Backend functions relating to supplier
const UPSERTSUPPLIER = "upsertSupplier";
const INSERTMANYSUPPLIERSANDRELATEDDOCUMENTS = "insertManySuppliersAndRelatedDocuments";

/**
 * Inserts a new supplier into the database.
 * @param supplier supplier that should be inserted into the database
 * @param newPersons List of new persons
 * @returns  { Promise<Realm.Services.MongoDB.InsertOneResult | false> } Result of the function
 */
export async function insertSupplier(
  supplier: Supplier,
  newPersons: Array<UserData>
): Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false> {
  return (await callUpsertSupplier(supplier, true, undefined, newPersons)) as
    | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>
    | false;
}
/**
 * Updates an existing supplier inside the database.
 * @param supplier supplier that should be updated inside the database
 * @param supplierId Optional id of supplier if it is not contained in supplier object
 * @param timelineEntry optional, timeline entry to push on update
 * @returns { Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> } Result of the function
 */
export async function updateSupplier(
  supplier: Partial<Supplier>,
  supplierId?: BSON.ObjectId,
  timelineEntry?: SupplierTimelineEntry
): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> {
  if (supplierId) supplier._id = supplierId;
  return (await callUpsertSupplier(supplier, false, timelineEntry)) as
    | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>
    | false;
}
/**
 * Calls the upsert supplier function in backend.
 * @param supplier supplier that should be upsert
 * @param insert True for insert, else update
 * @param timelineEntry optional, timeline entry to push on update
 * @param newPersons optional, new persons - on insert
 * @returns {Promise<false | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>>} Result of the function
 */
async function callUpsertSupplier(
  supplier: Partial<Supplier>,
  insert: boolean,
  timelineEntry?: SupplierTimelineEntry,
  newPersons?: Array<UserData>
): Promise<
  false | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>
> {
  return callFunction(UPSERTSUPPLIER, [supplier, insert, timelineEntry, newPersons]);
}

/**
 * Inserts the given users and suppliers into the database
 * @param users List of users
 * @param suppliers List of suppliers
 * @returns { Promise<boolean> } Indicating the success of the insert
 */
export async function insertSuppliersAndRelatedDocuments(
  users: Array<UserData>,
  suppliers: Array<Supplier>
): Promise<boolean> {
  return callFunction(INSERTMANYSUPPLIERSANDRELATEDDOCUMENTS, [users, suppliers]);
}

/**
 * Adds a timeline entry to a supplier
 * @param supplierId id of the supplier for which the timeline entry should be added
 * @param timelineEntry the timeline entry to add
 * @returns Promise<boolean> Indicating the success of the timeline update
 */
export async function updateSupplierTimeline(
  supplierId: BSON.ObjectId,
  timelineEntry: SupplierTimelineEntry
): Promise<boolean> {
  const action: Action = {
    collection: SUPPLIER,
    filter: { _id: supplierId },
    push: { timeline: timelineEntry },
  };
  return transaction([action]);
}

/**
 * Get stats for supplier commodities
 * @param supplier name of which we want to know the number of commodities
 * @param commodities all commodities
 * @returns {[amount: number, update: Date | undefined]} tuple with amount and lasat update
 */
export function getSupplierCommodityStats(
  supplier: string,
  commodities: Array<Commodity>
): [amount: number, update: Date | undefined] {
  let amount = 0;
  let lastUpdate;
  for (let i = 0; i < commodities.length; i++) {
    const sup = commodities[i].suppliers.find((s) => s.supplier === supplier);
    if (sup) {
      amount++;
      for (let j = 0; j < sup.prices.length; j++) {
        const price = sup.prices[j];
        if (!lastUpdate || price.date > lastUpdate) lastUpdate = price.date;
      }
    }
  }
  return [amount, lastUpdate];
}

/**
 * Generate the amount of valid and invalid commodities for the given supplier.
 * @param supplier Supplier whose commodities should be checked
 * @param commodities List of commodities
 * @param finishedProducts List of finished products
 * @returns {{ validArticles: number, invalidArticles: number }} Amount of valid and invalid commodities
 */
export function getSupplierArticleStatus(
  supplier: Supplier,
  commodities: Array<Commodity>,
  finishedProducts: Array<FinishedProduct>
): { validArticles: number; invalidArticles: number } {
  // #TODO: Move this calculation to the backend - RB-335
  let validArticles = 0;
  let invalidArticles = 0;
  if (!supplier.disabled) {
    const now = new Date();
    for (let i = 0; i < commodities.length; i++) {
      const c = commodities[i];
      if (c.disabled || !c.approved) continue;
      for (let j = 0; j < c.suppliers.length; j++) {
        const s = c.suppliers[j];
        if (s.disabled) continue;
        if (s.supplier === supplier._id.toString()) {
          if (s.prices.some((p) => p.validUntil > now && p.price > 0)) {
            validArticles++;
          } else {
            invalidArticles++;
          }
        }
      }
    }
    for (let i = 0; i < finishedProducts.length; i++) {
      const fp = finishedProducts[i];
      if (fp.disabled || !fp.approved) continue;
      for (let j = 0; j < fp.suppliers.length; j++) {
        const s = fp.suppliers[j];
        if (s.disabled) continue;
        if (s.supplier === supplier._id.toString()) {
          if (s.prices.some((p) => p.validUntil > now && p.price > 0)) {
            validArticles++;
          } else {
            invalidArticles++;
          }
        }
      }
    }
  }
  return { validArticles, invalidArticles };
}

/**
 * Get all orders of the supplier and their value in the currency of the supplier or a given one.
 * @param supplier Supplier whose orders should be resolved
 * @param orders Orders to check
 * @param currencies List of currencies
 * @param currency Optional, target currency, if not given the currency of the supplier is used
 * @returns { [openOrders: Array<SupplierOrder>, openValue: number] }
 */
export function getSupplierOpenOrders(
  supplier: Supplier,
  orders: Array<SupplierOrder>,
  currencies: Currencies,
  currency?: string
): [openOrders: Array<SupplierOrder>, openValue: number] {
  const openOrders = orders.filter(
    (o) => o.supplier === supplier._id.toString() && !([SO_ARCHIVED, SO_CANCELED] as Array<SO_STATES>).includes(o.state)
  );
  // Collect all the different currencies that occur in the orders of the supplier (will most likely be 1 all the time)
  const openOrdersValuesMap: { [key: string]: number } = {};
  for (let i = 0; i < openOrders.length; i++) {
    const o = openOrders[i];
    if (openOrdersValuesMap[o.currency]) openOrdersValuesMap[o.currency] += o.totalPrice;
    else openOrdersValuesMap[o.currency] = o.totalPrice;
  }
  // Convert to the currency of the supplier
  let openValue = 0;
  const cur = currency ?? supplier.currency;
  for (const key in openOrdersValuesMap) {
    if (key === cur) openValue += openOrdersValuesMap[key];
    else openValue += convertCurrency(openOrdersValuesMap[key], key, cur, currencies);
  }
  return [openOrders, openValue];
}

/**
 * Get the supplier activity level.
 * @param openOrders Open orders of the supplier
 * @param lastCommodityUpdate Last commodity update of the supplier
 * @returns { number } Activity level, where 100 is maximum
 */
export function getSupplierActivity(openOrders: Array<SupplierOrder>, lastCommodityUpdate?: Date): number {
  // Calculate activity by looking at latest orders and changes
  const latestOrderDate = openOrders.reduce(
    (latest, oO) => (oO.createdAt > latest ? oO.createdAt : latest),
    new Date(0)
  );
  const latestAction =
    lastCommodityUpdate && lastCommodityUpdate > latestOrderDate ? lastCommodityUpdate : latestOrderDate;
  const sinceLatest = getDaysBetween(new Date(), latestAction);
  // If there were action in the last 14 days it is counted as 100% activity, after that it is getting less
  return (sinceLatest <= 14 ? 1 : (90 - sinceLatest) / 90) * 100;
}

/**
 * Get all similar suppliers
 * @param supplier the supplier to get similar ones for
 * @param suppliers list of all suppliers
 * @returns {Array<Supplier>} list of similar suppliers
 */
export const getSimilarSuppliers = (supplier: Supplier, suppliers: Array<Supplier>): Array<Supplier> => {
  // Threshold 0.2 is always subject to change
  const threshold = 0.2;
  let similarSuppliers = doFuseSearch(suppliers, supplier.name, ["name"], {
    threshold,
  });
  // Only exact match here
  similarSuppliers = similarSuppliers.concat(doFuseSearch(suppliers, supplier.vat, ["vat"], { threshold: 0 }));
  // Remove supplier itself
  similarSuppliers = similarSuppliers.filter((c) => c._id.toString() !== supplier._id.toString());
  // Remove duplicates
  return Array.from(new Set(similarSuppliers));
};

/**
 * Get a default Supplier. Note: This supplier has an invalid primary person!
 * @param name optional name of supplier
 * @param vat optional vat of supplier
 * @param address optional address of supplier
 * @param currency optional currency of supplier
 * @param phone optional phone number of supplier
 * @param mail optional mail address of supplier
 * @returns {Supplier} default supplier
 */
export const getDefaultSupplier = (
  name?: string,
  vat?: string,
  address?: Address,
  currency?: string,
  phone?: string,
  mail?: string
): Supplier => {
  return {
    _id: new BSON.ObjectId(),
    name: name || "",
    internalContact: userService.getUserId(),
    rating: -1, // invalid rating as default
    currency: currency || "",
    vat: vat || "",
    mail: mail || "",
    phone: phone || "",
    address: address ? [address] : [],
    primaryPerson: "",
    persons: [],
    activated: true,
    disabled: false,
    notes: "",
    airportReferences: [],
    seaportReferences: [],
    transport: {
      preparationTime: 0,
      land: false,
      water: false,
      air: false,
    },
    packagingDimensions: [],
    timeline: [],
  };
};

/**
 * Get supplier statistics
 * @param supplierId id of supplier to get statistics for
 * @param statistics optional, list of statistic names to get
 * @returns {Promise<Partial<SupplierStatistics> | undefined>} statistics if found for supplier
 */
export async function getSupplierStatistics(
  supplierId: string | BSON.ObjectId,
  statistics?: Array<string>
): Promise<Partial<SupplierStatistics>> {
  const db = getDb();
  const collection = db?.collection(SUPPLIERSTATISTICS);
  const projection: { [field: string]: number } = {};
  if (statistics) statistics.forEach((s) => (projection[s] = 1));
  return collection?.findOne({ supplier: supplierId.toString() }, { projection: statistics ? projection : undefined });
}

/**
 * Get default seaport references select options
 * @param seaportReferences list of seaport references
 * @param seaports list of seaport documents
 * @returns {Array<SelectOption>} list of select options
 */
export const getDefaultReferencedSeaports = (
  seaportReferences: Array<string> | undefined,
  seaports: Array<Seaport>
): Array<SelectOption> => {
  return seaportReferences
    ? (seaportReferences
        .map((sp) => {
          const doc = getDocFromCollection(seaports, sp);
          return doc ? { value: sp, label: getSeaportName(doc) } : undefined;
        })
        .filter((sp) => sp) as Array<SelectOption>)
    : [];
};

/**
 * Get default airport references select options
 * @param airportReferences list of airport references
 * @param airports list of airport documents
 * @returns {Array<SelectOption>} list of select options
 */
export const getDefaultReferencedAirports = (
  airportReferences: Array<string> | undefined,
  airports: Array<Airport>
): Array<SelectOption> => {
  return airportReferences
    ? (airportReferences
        .map((ap) => {
          const doc = getDocFromCollection(airports, ap);
          return doc ? { value: ap, label: getAirportName(doc) } : undefined;
        })
        .filter((ap) => ap) as Array<SelectOption>)
    : [];
};

/**
 * Generates a timeline entry for a supplier
 * @param type Type of the entry
 * @param payload Optional payload of the entry
 * @returns { SupplierTimelineEntry } Timeline entry object
 */
export function getSupplierTimelineEntry(
  type: S_TIMELINETYPE,
  payload?: SupplierTimelineEntryPayload
): SupplierTimelineEntry {
  return {
    _id: new BSON.ObjectId(),
    date: new Date(),
    type: type,
    person: userService.getUserId(),
    payload: payload ?? null,
  } as SupplierTimelineEntry;
}

/**
 * Retrieves the matching palette of the given supplier for the referenced commodity
 * @param supplier Supplier whose palette should be resolved
 * @param commodityId ID of the commodity whose palette should be resolved
 * @param transportType Type of transport the palette should support
 * @returns {PackagingDimension} Packaging dimension of the supplier for the given commodity - or standard palette
 */
export function getPaletteForCommodity(
  supplier: Supplier,
  commodityId: string | undefined,
  transportType: string
): PackagingDimension {
  const palette = supplier.packagingDimensions?.find(
    (pD) =>
      !pD.transportTypes ||
      pD.transportTypes.some(
        (pDtT) =>
          transportType.startsWith(pDtT) &&
          (!commodityId || pD.commodities === "all" || pD.commodities?.includes(commodityId))
      )
  );
  return palette ?? getStandardPackagingDimension();
}

/**
 * Generate a supplier snapshot from a supplier.
 * @param supplier Supplier whose snapshot should be generated
 * @returns {SupplierSnapshot} Snapshot of the supplier
 */
export function getSupplierSnapshot(supplier: Supplier | SupplierSupplier): SupplierSnapshot {
  return { _id: supplier._id, name: supplier.name, snapshotDate: new Date() };
}

/**
 * Checks if the given object is a Supplier document in supplier view representation
 * @param supplier Supplier, Company or Forwarder which should be checked
 * @returns {boolean} Indicates whether the object is a Supplier document in supplier view representation or not
 */
export function isSupplierSupplier(
  supplier: SupplierType | Company | CompanyExtended | Forwarder | ForwarderExtended
): supplier is SupplierSupplier | SupplierSupplierExtended {
  return "internalContact" in supplier && typeof supplier.internalContact !== "string" && !("creditLimit" in supplier);
}
