import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContextAnonymous, DataContextCustomer } from "../../../context/dataContext";
import CommodityPage from "../../commodities/customer/CommodityPage";
import userService from "../../../services/userService";
import { ANONYMOUS, CUSTOMER } from "../../../utils/userUtils";

interface FinishedProductWrapperProps extends RouteComponentProps<FinishedProductParams> {}

interface FinishedProductParams {
  id: string;
}

const FinishedProductWrapper: React.FunctionComponent<FinishedProductWrapperProps> = (props) => {
  let context;
  if (userService.getUserType() === CUSTOMER) {
    context = useContext(DataContextCustomer);
  } else if (userService.getUserType() === ANONYMOUS) {
    context = useContext(DataContextAnonymous);
  }
  if (!context) return <></>;
  return <CommodityPage context={context} {...props} />;
};

export default FinishedProductWrapper;
