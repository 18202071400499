import React, { PureComponent } from "react";
import CustomerSpecificTask from "../../CustomerSpecificTask";
import ServicesTask from "../../ServicesTask";
import { DataContextInternalType } from "../../../../../../context/dataContext";
import { Batch } from "../../../../../../model/batch.types";
import { GroupedCustomerOrderService } from "../../../../../../model/commonTypes";
import { SO_CANCELED, SupplierOrderExtended } from "../../../../../../model/supplierOrder.types";

import { extendCustomerOrder } from "../../../../../../utils/dataTransformationUtils";

interface WorkflowFulfillmentCardProps {
  order: SupplierOrderExtended;
  context: DataContextInternalType;
  relatedBatches: Array<Batch>;
  done: boolean;
}

interface WorkflowFulfillmentCardState {}

class WorkflowFulfillmentCard extends PureComponent<WorkflowFulfillmentCardProps, WorkflowFulfillmentCardState> {
  /**
   * If multiple customers ordered the same service we can cover this with performing it once, thus they need to be grouped.
   * @returns { Array<GroupedCustomerOrderService> }: Grouped services
   */
  groupServices = () => {
    const { customerOrders } = this.props.order;
    const services: Array<GroupedCustomerOrderService> = [];
    for (let i = 0; i < customerOrders.length; i++) {
      const cO = customerOrders[i];
      if (cO.services) {
        for (let j = 0; j < cO.services.length; j++) {
          const s = cO.services[j];
          const service = services.find((gS) => gS.service._id.toString() === s.service._id.toString());
          if (service) {
            service.customerOrders.push(cO);
          } else {
            services.push({ ...s, customerOrders: [cO] });
          }
        }
      }
    }
    return services;
  };

  render() {
    const { order, context, done, relatedBatches } = this.props;
    const editable = order.state !== SO_CANCELED;
    const services = this.groupServices();
    const servicesDone = services.every((s) => s.performed);

    return (
      <>
        <div className="opacity-100-hover" style={{ opacity: done || !editable ? 0.3 : 1 }}>
          <div className="fw-bolder text-white fs-3 my-5">
            Customer Specific Tasks{" "}
            {done ? (
              <i className="h2 fas fa-check-circle text-success" />
            ) : !editable ? (
              <i className="h2 fas fa-exclamation-circle text-danger" />
            ) : (
              <span className="text-warning">[Current Task]</span>
            )}
          </div>
          {order.customerOrders.map((cO) => (
            <CustomerSpecificTask
              key={cO._id.toString()}
              order={extendCustomerOrder(cO, context)}
              batches={relatedBatches}
              editable={editable}
              context={context}
            />
          ))}
        </div>
        {services.length > 0 && (
          <div className="opacity-100-hover" style={{ opacity: servicesDone ? 0.3 : 1 }}>
            <div className="fw-bolder text-white fs-3 mb-5">
              Services - ({services.reduce((d, s) => d + (s.performed ? 1 : 0), 0)}/{services.length} done){" "}
              {servicesDone ? (
                <i className="h2 fas fa-check-circle text-success" />
              ) : (
                <span className="text-warning">[Current Task]</span>
              )}
            </div>
            {services.map((s) => (
              <ServicesTask key={s.service._id.toString()} service={s} editable={editable} context={context} />
            ))}
          </div>
        )}
      </>
    );
  }
}

export default WorkflowFulfillmentCard;
