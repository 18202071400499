import React, { PureComponent } from "react";
import BaseListing from "../../common/BaseListing";
import { doFuseSearch, getComponentState } from "../../../utils/baseUtils";
import Search from "../../common/Search";
import ActiveSubstanceRow from "./ActiveSubstanceRow";
import { paginate, PaginationState } from "../../common/Pagination";
import { DataContextInternal } from "../../../context/dataContext";

interface ActiveSubstanceListingProps {
  context: React.ContextType<typeof DataContextInternal>;
}

interface ActiveSubstanceListingState extends PaginationState {
  search: string;
}

const COMPONENT_NAME = "ActiveSubstanceListing";

class ActiveSubstanceListing extends PureComponent<ActiveSubstanceListingProps, ActiveSubstanceListingState> {
  headerDefinition = [
    { title: "Name", style: { width: "35%" } },
    { title: "Nrv", style: { width: "40%" } },
    { title: "Status", style: { width: "10%" } },
    { title: "Action", className: "text-right", style: { width: "15%" } },
  ];

  constructor(props: ActiveSubstanceListingProps) {
    super(props);
    this.state = {
      search: "",
      currentPage: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {
    const state = getComponentState(this.props.context, COMPONENT_NAME);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(COMPONENT_NAME, this.state);
  }

  handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ search: e.target.value, currentPage: 1 });
  };

  /**
   * Filters the active substances by name if a search query was entered.
   * @returns { Array<ActiveSubstance> } Filtered services
   */
  getFilteredActiveSubstances = () => {
    const { activeSubstance } = this.props.context;
    const { search } = this.state;
    if (!search.trim()) return activeSubstance;
    return doFuseSearch(activeSubstance, search, ["name.en"]);
  };

  render() {
    const { currentPage, search, pageSize } = this.state;
    const filteredActiveSubstances = this.getFilteredActiveSubstances();
    const paginatedActiveSubstances = paginate(filteredActiveSubstances, currentPage, pageSize);

    return (
      <>
        <div className="d-flex align-items-center my-5">
          <div className="w-50">
            <Search
              onSearch={this.handleChangeSearch}
              placeholder="Search..."
              inputClasses="form-control custom-form-control"
              value={search}
            />
          </div>
        </div>
        <BaseListing
          headerDefinition={this.headerDefinition}
          documents={filteredActiveSubstances}
          noHover={true}
          bodyContent={
            <>
              {paginatedActiveSubstances.map((aS) => (
                <ActiveSubstanceRow key={aS._id.toString()} activeSubstance={aS} />
              ))}
            </>
          }
          currentPage={currentPage}
          pageSize={pageSize}
          baseSize={25}
          onPageChange={(currentPage) => this.setState({ currentPage })}
          onPageSizeChange={(pageSize) => this.setState({ pageSize, currentPage: 1 })}
        />
        )
      </>
    );
  }
}

export default ActiveSubstanceListing;
