import { BSON } from "realm-web";
import { CompanySnapshot } from "./company.types";
import { Address, OrderFile } from "./commonTypes";
import { ContractPeriods } from "../utils/customerContractUtils";
import { DateType } from "../utils/orderUtils";
import { ArticleSnapshot } from "../utils/productArticleUtils";

export enum CustomerRequestType {
  CONTRACTREQUEST = "contractRequest",
  STANDARDREQUEST = "standardRequest",
}

export enum CustomerRequestState {
  OPEN = "requestOpen",
  CLOSED = "requestClosed",
  REJECTED = "requestRejected",
  CANCELED = "requestCanceled",
}

export enum CustomerRequestTimelineTypes {
  CREATED = "requestOpen",
  CLOSED = "requestClosed",
  CANCELED = "requestCanceled",
}

export interface CustomerRequest {
  _id: BSON.ObjectId;
  state: CustomerRequestState;
  requestNo: string;
  requestedOn: Date;
  commodity: ArticleSnapshot;
  company: CompanySnapshot;
  amount: number;
  type: CustomerRequestType;
  customerReference: string;
  requestData: ContractData | StandardRequestData;
  attachments: Array<OrderFile>;
  message: string;
  timeline: Array<RequestTimelineEntry>;
}

export interface ContractData {
  period: ContractPeriods;
  minimumCallQuantity: number;
  supplier?: string | null;
}

export interface StandardRequestData {
  targetDate: Date;
  targetDateType?: DateType; // indicates how the targetDate should be treated (CW, fix, ...)
  destination: Address | string; // string for backwards compatibility
}

export interface RequestTimelineEntry {
  _id: BSON.ObjectId;
  date: Date; // When did this happen?
  type: CustomerRequestTimelineTypes; // What happened?
  person: string;
  payload: object | null; // Additional data for certain types
}
