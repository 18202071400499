import React, { useContext } from "react";
import userService from "../../../services/userService";
import { ANONYMOUS, CUSTOMER } from "../../../utils/userUtils";
import { DataContextAnonymous, DataContextCustomer } from "../../../context/dataContext";
import InvoiceListing from "./InvoiceListing";

const InvoiceListingWrapper: React.FC = () => {
  let context;
  if (userService.getUserType() === CUSTOMER) {
    context = useContext(DataContextCustomer);
  } else if (userService.getUserType() === ANONYMOUS) {
    context = useContext(DataContextAnonymous);
  }
  if (!context) return <></>;
  return <InvoiceListing context={context} />;
};

export default InvoiceListingWrapper;
