import _ from "lodash";
import React, { PureComponent } from "react";
import {
  AirFreightCalculationValues,
  calculateCBMAndGrossWeight,
  getB2BFollowUpCost,
  recalculatePaletteDataOnChange,
} from "../../../../utils/priceCalculationUtils";
import { PaletteDataView } from "./PaletteDataView";
import { Commodity, Duty } from "../../../../model/commodity.types";
import { Input } from "../../../common/Input";
import { EURO, USD } from "../../../../utils/currencyUtils";
import { pluralize } from "../../../../utils/baseUtils";
import CalculationCostObject from "./CalculationCostObject";
import WarehouseCostView from "./WarehouseCostView";
import { SelectOption } from "../../../common/CustomSelect";
import { PackagingDimension } from "../../../../model/supplier.types";
import {
  generateValidPalettesForCommodity,
  packagingDimensionAsPaletteData,
} from "../../../../utils/packagingDimensionUtils";
import { T_AIRFREIGHT } from "../../../../model/customerOrder.types";
import { DataContextInternal } from "../../../../context/dataContext";

interface AirFreightCalculationViewProps {
  totalAmount: number;
  isCalculator?: boolean;
  commodity?: Commodity;
  calculationValues: AirFreightCalculationValues;
}

interface AirFreightCalculationViewState {
  calculationValues: AirFreightCalculationValues;
  palette?: SelectOption<PackagingDimension>;
}

class AirFreightCalculationView extends PureComponent<AirFreightCalculationViewProps, AirFreightCalculationViewState> {
  static contextType = DataContextInternal;
  context!: React.ContextType<typeof DataContextInternal>;

  constructor(props: AirFreightCalculationViewProps) {
    super(props);
    this.state = {
      calculationValues: _.cloneDeep(props.calculationValues),
    };
  }

  componentDidUpdate(prevProps: AirFreightCalculationViewProps) {
    if (!_.isEqual(prevProps.calculationValues, this.props.calculationValues)) {
      this.setState({ calculationValues: _.cloneDeep(this.props.calculationValues) });
    }
  }

  handleChangeDuty = (e: React.ChangeEvent<HTMLInputElement>) => {
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    const name = e.target.name as keyof Duty;
    calculationValues.duty[name] = +e.target.value;
    this.setState({ calculationValues });
  };

  handleChangeAirFreightCost = (e: React.ChangeEvent<HTMLInputElement>) => {
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    calculationValues.airFreightCost.cost = +e.target.value;
    this.setState({ calculationValues });
  };

  handleChangeRelevantFollowupCost = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    calculationValues.otherCost.followUpCost[index].cost = +e.target.value;
    this.setState({ calculationValues });
  };

  handleChangePaletteData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    recalculatePaletteDataOnChange(calculationValues, this.props.totalAmount, e);
    this.setState({ calculationValues, palette: undefined });
  };

  handleChangeNumValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    const name = e.target.name as keyof AirFreightCalculationValues;
    const value = +e.target.value;
    _.set(
      calculationValues,
      name,
      ["customsFeeAgency", "customsFreightCoefficient"].includes(name) ? value / 100 : value
    );
    this.setState({ calculationValues });
  };

  handleChangeAdditionalFreightCost = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    calculationValues.otherCost.additionalFreightCost[index].costPerUnit = +e.target.value;
    this.setState({ calculationValues });
  };

  handleChangeAirportCost = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    calculationValues.otherCost.airportCost[index].costPerUnit = +e.target.value;
    this.setState({ calculationValues });
  };

  handleChangeWarehouseGeneralCost = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    calculationValues.warehouseCost.generalCost[index].costPerUnit = +e.target.value;
    this.setState({ calculationValues });
  };

  handleChangePalette = (palette?: SelectOption<PackagingDimension>) => {
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    if (palette?.object) {
      const paletteData = palette.object;
      calculationValues.paletteData = packagingDimensionAsPaletteData(paletteData);
      const [cbm, weight, palettes] = calculateCBMAndGrossWeight(this.props.totalAmount, paletteData);
      calculationValues.baseValues = {
        cbm,
        weight,
        palettes,
      };
      calculationValues.b2bFollowUpCost = getB2BFollowUpCost(palettes);
    }
    this.setState({ palette, calculationValues });
  };

  /**
   * Used for parent component to get state values
   */
  getCalculationValues = () => {
    return this.state.calculationValues;
  };

  render() {
    const { totalAmount, isCalculator, commodity } = this.props;
    const { calculationValues, palette } = this.state;
    const {
      airFreightCost,
      airportStorageTime,
      customsFeeAgency,
      otherCost,
      customsFreightCoefficient,
      b2bFollowUpCost,
      paletteData,
      duty,
      baseValues,
      warehouseCost,
      insurance,
      minimumAbsoluteMargin,
    } = calculationValues;
    const { airportCost, additionalFreightCost, followUpCost } = otherCost;
    let relevantFollowupCostIndex = followUpCost.findIndex(
      (entry) => entry.from < baseValues.weight && baseValues.weight <= entry.to
    );
    if (relevantFollowupCostIndex < 0) relevantFollowupCostIndex = followUpCost.length - 1;
    const relevantFollowupCost = followUpCost[relevantFollowupCostIndex];
    return (
      <div>
        <h3 className="mb-3 mt-7">Transport</h3>
        <div className="row">
          <div className="col-xl-4">
            <label className="fs-5 fw-bold mb-2">Air Freight Cost per Kg</label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type={"number"}
                min={0}
                name={"airFreightCost"}
                value={airFreightCost.cost}
                onChange={this.handleChangeAirFreightCost}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  {airFreightCost.currency || USD}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <label className="fs-5 fw-bold mb-2">Follow-up Cost to Warehouse</label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type={"number"}
                min={0}
                name={"relevantFollowupCost"}
                value={relevantFollowupCost.cost}
                onChange={(e) => this.handleChangeRelevantFollowupCost(e, relevantFollowupCostIndex)}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  {relevantFollowupCost.currency || USD}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <label className="fs-5 fw-bold mb-2">
              B2B Follow-up Cost (based on {pluralize(baseValues.palettes, "palette")})
            </label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type={"number"}
                min={0}
                name={"b2bFollowUpCost"}
                value={b2bFollowUpCost}
                onChange={this.handleChangeNumValue}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  {EURO}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 mt-2">
            <label className="fs-5 fw-bold mb-2">Average Expected Time in Airport</label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type={"number"}
                min={0}
                name={"airportStorageTime"}
                value={airportStorageTime}
                onChange={this.handleChangeNumValue}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  days
                </div>
              </div>
            </div>
          </div>
          {additionalFreightCost.map((aFC, index) => (
            <CalculationCostObject
              key={index.toString()}
              cost={aFC}
              onChangeCost={(e) => this.handleChangeAdditionalFreightCost(e, index)}
            />
          ))}
        </div>
        <h3 className="mb-3 mt-7">Airport</h3>
        <div className="row">
          {airportCost.map((aC, index) => (
            <CalculationCostObject
              key={index.toString()}
              sizeClassName={"col-xl-4 " + (index > 2 && " mt-2")}
              cost={aC}
              onChangeCost={(e) => this.handleChangeAirportCost(e, index)}
            />
          ))}
        </div>
        <h3 className="mb-3 mt-7">Customs</h3>
        <div className="row">
          <div className="col-xl-3">
            <label className="fs-5 fw-bold mb-2">Duty Percentage</label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type={"number"}
                min={0}
                name={"percentage"}
                value={duty.percentage}
                onChange={this.handleChangeDuty}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  %
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3">
            <label className="fs-5 fw-bold mb-2">Dumping Fee per 100kg</label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type={"number"}
                name={"dumpingFee"}
                min={0}
                value={duty.dumpingFee || 0}
                placeholder={"None"}
                onChange={this.handleChangeDuty}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  {EURO}/100kg
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3">
            <label className="fs-5 fw-bold mb-2">Customs Freight Coefficient</label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type={"number"}
                name={"customsFreightCoefficient"}
                min={0}
                value={customsFreightCoefficient * 100}
                onChange={this.handleChangeNumValue}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  %
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3">
            <label className="fs-5 fw-bold mb-2">Fee for Delivery Agency</label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type={"number"}
                name={"customsFeeAgency"}
                min={0}
                value={customsFeeAgency * 100}
                onChange={this.handleChangeNumValue}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  %
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3 className="mb-3 mt-7">Insurance</h3>
        <div className="row">
          <div className="col-xl-4">
            <label className="fs-5 fw-bold mb-2">Minimum Cost</label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type="number"
                name="insurance.min.value"
                value={insurance.min.value}
                onChange={this.handleChangeNumValue}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  {insurance.min.currency}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <label className="fs-5 fw-bold mb-2">Percentage on Purchase Price</label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type="number"
                name="insurance.marginQuote"
                value={insurance.marginQuote}
                onChange={this.handleChangeNumValue}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  %
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <label className="fs-5 fw-bold mb-2">Percentage</label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type="number"
                name="insurance.insuranceValue"
                value={insurance.insuranceValue}
                onChange={this.handleChangeNumValue}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  %
                </div>
              </div>
            </div>
          </div>
        </div>
        <WarehouseCostView
          warehouseCost={warehouseCost}
          onChangeWarehouseCost={this.handleChangeWarehouseGeneralCost}
        />
        {isCalculator && (
          <>
            <h3 className="mb-3 mt-7">Additional Settings</h3>
            <div className="row">
              <div className="col-xl-4">
                <label className="fs-5 fw-bold">Minimum Total Margin</label>
                <div className="input-group">
                  <Input
                    className="form-control custom-form-control"
                    type={"number"}
                    min={0}
                    name={"minimumAbsoluteMargin.value"}
                    value={minimumAbsoluteMargin.value}
                    onChange={this.handleChangeNumValue}
                  />
                  <div className="input-group-append rounded-end bg-custom-light-gray">
                    <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                      {minimumAbsoluteMargin.currency || EURO}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <PaletteDataView
          totalAmount={totalAmount}
          paletteData={paletteData}
          baseValues={baseValues}
          palette={palette}
          palettes={isCalculator ? generateValidPalettesForCommodity(commodity, T_AIRFREIGHT, this.context) : []}
          onChangePaletteData={this.handleChangePaletteData}
          onChangePalette={isCalculator ? this.handleChangePalette : undefined}
        />
      </div>
    );
  }
}

export default AirFreightCalculationView;
