import React from "react";
import { Link } from "react-router-dom";
import { AppDesign } from "../../utils/baseUtils";

interface LoginFormProps {
  email: string;
  error: string;
  fromLogout: boolean;
  loggingIn: boolean;
  password: string;
  resetSuccess: boolean;
  onEmailChange: (email: string) => void;
  onPasswordChange: (password: string) => void;
  onSubmit: (e: React.FormEvent) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({
  email,
  password,
  error,
  loggingIn,
  fromLogout,
  resetSuccess,
  onEmailChange,
  onPasswordChange,
  onSubmit,
}) => {
  const isArkViewEnabled = process.env.REACT_APP_DESIGN === AppDesign.ARK;
  return (
    <form className="form w-100" onSubmit={loggingIn ? undefined : onSubmit}>
      <div className="text-center mb-11">
        <h1 className="text-dark fw-bolder mb-3 fs-2x">{isArkViewEnabled ? "Sign In" : "Login"}</h1>
        {fromLogout && !error && <p className="text-center text-success">Successfully logged out</p>}
        {resetSuccess && <p className="text-center text-success">Password successfully reset</p>}
        {error && <p className="text-center text-danger">{error}</p>}
      </div>
      <div className="mb-8">
        <input
          name="email"
          id="login-email"
          className="form-control form-control-lg form-control-solid"
          type="email"
          placeholder="Email"
          autoComplete="off"
          value={email}
          required={true}
          onChange={(e) => onEmailChange(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <input
          name="password"
          id="login-password"
          className="form-control form-control-lg form-control-solid"
          type="password"
          placeholder="Password"
          autoComplete="off"
          value={password}
          required={true}
          onChange={(e) => onPasswordChange(e.target.value)}
        />
      </div>
      <div className="d-flex flex-stack flex-wrap gap-3 fs-base mb-8 mt-2">
        <div />
        <Link to="/passwordResetRequest" id="login_forgot" className="link-dark">
          Forgot Password?
        </Link>
      </div>
      <div className="d-grid mb-10">
        <button
          type="submit"
          id="login-submit"
          className={`btn btn-dark${loggingIn ? " disabled" : ""}`}
          disabled={loggingIn}
        >
          {!loggingIn ? (
            <span className="indicator-label">Login</span>
          ) : (
            <span className="indicator-progress d-block">
              Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      <div className={`text-gray-500 text-center fw-semibold${isArkViewEnabled ? " fs-6" : ""}`}>
        {isArkViewEnabled ? "Don't have access to ARK yet?" : "Not a Member yet?"}
        <a href="https://rawbids.com/register" className={`${isArkViewEnabled ? "link-primary" : "link-dark"} ml-2`}>
          {isArkViewEnabled ? "Sign up" : "Register Now"}
        </a>
      </div>
    </form>
  );
};

export default LoginForm;
