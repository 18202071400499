import React, { PureComponent } from "react";
import { T_CONTRACT, T_SPECIALREQUEST } from "../../../model/customerOrder.types";

interface CreateCustomerOrderFinalizationProps {
  method: string;
}

interface CreateCustomerOrderFinalizationState {
  seconds: number;
}

class CreateCustomerOrderFinalization extends PureComponent<
  CreateCustomerOrderFinalizationProps,
  CreateCustomerOrderFinalizationState
> {
  constructor(props: CreateCustomerOrderFinalizationProps) {
    super(props);
    this.state = {
      seconds: 3,
    };
  }

  componentDidMount() {
    const interval = setInterval(() => {
      if (this.state.seconds > 0) this.setState({ seconds: this.state.seconds - 1 });
      else clearInterval(interval);
    }, 1000);
  }

  render() {
    const { seconds } = this.state;
    const { method } = this.props;
    const request = method === T_SPECIALREQUEST || method === T_CONTRACT;

    return (
      <>
        <div className="bg-light2 rounded p-5 d-flex flex-grow-1 flex-column justify-content-center">
          <div className="w-100">
            <div className="text-center">
              <div className="text-white">
                <svg
                  className={request ? "checkmark_warning" : "checkmark_success"}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  <circle
                    className={request ? "checkmark__circle_warning" : "checkmark__circle_success"}
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />
                  <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
                <span className="fs-1 fw-bolder">{request ? "Request sent" : "Order placed"}</span>
                <br />
                <span className="text-muted mt-2 d-block mb-10">You will be redirected in {seconds} seconds...</span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CreateCustomerOrderFinalization;
