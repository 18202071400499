import React, { PureComponent } from "react";
import countryList from "i18n-iso-countries";
import { BSON } from "realm-web";
import { Input } from "./Input";
import CustomSelect, { SelectOption } from "./CustomSelect";
import { Address, ADDRESS_TYPE_OPTIONS, AddressType } from "../../model/commonTypes";
import { getCountryNameForCode, getDocFromCollection } from "../../utils/baseUtils";
import { getAddressProperty } from "../../utils/addressUtils";
import { DataContextType, isInternalContext } from "../../context/dataContext";
import { PropertyType } from "../../utils/propertyUtils";
import { UserData } from "../../model/userData.types";
import { Property } from "../../model/property.types";
import { Textarea } from "./Textarea";
import { getUserName } from "../../utils/userUtils";

interface AddressInputGroupProps {
  address: Address;
  persons?: Array<UserData>;
  context?: DataContextType;
  disabled?: boolean;
  fixedType?: AddressType;
  onChangeAddress: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeAddressType: (e: SelectOption) => void;
  onChangeAddressCountry: (e: SelectOption) => void;
  onChangeAddressContact?: (e: SelectOption) => void;
  onChangeAddressProperty?: (e: Array<SelectOption>, key: PropertyType) => void;
  onDeleteAddress?: (id: BSON.ObjectId) => void;
  onChangeOpeningHours?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

class AddressInputGroup extends PureComponent<AddressInputGroupProps> {
  render() {
    const {
      disabled,
      address,
      persons,
      context,
      fixedType,
      onChangeAddress,
      onChangeAddressType,
      onChangeAddressCountry,
      onChangeAddressContact,
      onChangeAddressProperty,
      onDeleteAddress,
      onChangeOpeningHours,
    } = this.props;
    const allProperties = context && context.property;
    const existingProperties = context
      ? (getAddressProperty(address, PropertyType.DELIVERYINSTRUCTIONS, context, true) as Array<Property>)
      : undefined;
    const newPropertyOption = { value: "new", label: "Create new delivery instruction" };
    const propertySelectOptions = (properties: Property[] | undefined): Array<SelectOption<Property>> =>
      properties
        ? properties
            .filter((p) => p.type === PropertyType.DELIVERYINSTRUCTIONS)
            .map((p) => ({ value: p._id.toString(), label: p.name.en, object: p }))
        : [];
    const addressContactPerson =
      address.contactPerson && context ? getDocFromCollection(context.userData, address.contactPerson) : undefined;

    return (
      <div className="row">
        <div className="pr-1 col-8">
          <Input
            type="text"
            disabled={disabled}
            value={address.name}
            name="name"
            placeholder="Optional, recipient (e.g. different company)"
            onChange={onChangeAddress}
            className="form-control custom-form-control mb-2"
          />
        </div>
        <div className={onDeleteAddress ? "col-3 px-1" : "col-4 pl-1"}>
          <CustomSelect
            options={ADDRESS_TYPE_OPTIONS}
            disabled={Boolean(fixedType) || disabled}
            onChange={onChangeAddressType}
            value={{
              value: fixedType ?? address.type,
              label: ADDRESS_TYPE_OPTIONS.find((a) => a.value === (fixedType ?? address.type))!.label,
            }}
            matchFormControl={true}
          />
        </div>
        {onDeleteAddress && (
          <div className="col-1 pl-1">
            <button className="btn btn-sm btn-light w-100 text-center" onClick={() => onDeleteAddress(address._id)}>
              <i className="fa fa-trash pl-1" />
            </button>
          </div>
        )}
        <div className="col-8 pr-1">
          <Input
            type="text"
            disabled={disabled}
            value={address.street}
            name="street"
            placeholder="Street Name"
            onChange={onChangeAddress}
            className="form-control custom-form-control mb-2"
          />
        </div>
        <div className="col-4 pl-1">
          <Input
            type="text"
            disabled={disabled}
            value={address.houseNo}
            name="houseNo"
            placeholder="No."
            onChange={onChangeAddress}
            className="form-control custom-form-control mb-2"
          />
        </div>
        <div className="col-4 pr-1">
          <Input
            type="text"
            disabled={disabled}
            value={address.postalCode}
            name="postalCode"
            placeholder="Postal Code"
            onChange={onChangeAddress}
            className="form-control custom-form-control mb-2"
          />
        </div>
        <div className="col-8 col-lg-4 pl-1 px-lg-1">
          <Input
            type="text"
            disabled={disabled}
            value={address.city}
            name="city"
            placeholder="City"
            onChange={onChangeAddress}
            className="form-control custom-form-control mb-2"
          />
        </div>
        <div className="col-12 col-md-8 col-lg-4 pl-lg-1">
          <CustomSelect
            options={Object.values(countryList.getNames("en", { select: "alias" })).map((item: string) => {
              return {
                value: countryList.getAlpha2Code(item, "en"),
                label: item,
              };
            })}
            disabled={disabled}
            value={{ value: address.country, label: getCountryNameForCode(address.country) }}
            placeholder={"Select Country..."}
            matchFormControl={true}
            onChange={onChangeAddressCountry}
          />
        </div>
        {persons && onChangeAddressContact && (
          <div className={"mb-2 col-12 " + (context && "col-lg-6")}>
            <label className="fs-6 fw-bold">Contact Person</label>
            <CustomSelect
              options={persons.map((p) => {
                return {
                  value: p._id.toString(),
                  label: getUserName(p),
                };
              })}
              disabled={disabled}
              value={
                addressContactPerson
                  ? {
                      value: addressContactPerson._id.toString(),
                      label: `${addressContactPerson.prename} ${addressContactPerson.surname}`,
                    }
                  : undefined
              }
              matchFormControl={true}
              onChange={onChangeAddressContact}
            />
          </div>
        )}
        {context && onChangeAddressProperty && (
          <div className={"col-12 " + (persons && "col-lg-6")}>
            <label className="fs-6 fw-bold">Delivery Instructions</label>
            <CustomSelect
              options={
                isInternalContext(context)
                  ? [newPropertyOption, ...propertySelectOptions(allProperties)]
                  : propertySelectOptions(allProperties)
              }
              value={
                existingProperties
                  ? existingProperties.map((p) => {
                      return { value: p._id.toString(), label: p.name.en };
                    })
                  : undefined
              }
              onChange={(e: Array<SelectOption>) => {
                if (onChangeAddressProperty) {
                  onChangeAddressProperty(e, PropertyType.DELIVERYINSTRUCTIONS);
                }
              }}
              isMulti={true}
              isClearable={true}
            />
          </div>
        )}
        {onChangeOpeningHours && (
          <div className="mb-2 col-12">
            <label className="fs-6 fw-bold">Opening Hours</label>
            <Textarea
              disabled={disabled}
              value={address.openingHours}
              name="openingHours"
              placeholder={"e.g. Mo-Fr 07:00 - 20:00\n      Sa 09:00 - 14:00"}
              onChange={onChangeOpeningHours}
              className="form-control custom-form-control mb-2"
            />
          </div>
        )}
      </div>
    );
  }
}

export default AddressInputGroup;
