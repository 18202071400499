import React, { useContext } from "react";
import SCMDashboard from "./SCMDashboard";
import { DataContextInternal } from "../../../context/dataContext";

const SCMDashboardWrapper: React.FunctionComponent = () => {
  const context = useContext(DataContextInternal);
  return <SCMDashboard context={context} />;
};

export default SCMDashboardWrapper;
