import { BSON } from "realm-web";
import { Company, CompanySnapshot } from "./company.types";
import { Supplier, SupplierSnapshot } from "./supplier.types";
import { CustomerOrderExtended } from "./customerOrder.types";
import { SupplierOrderExtended } from "./supplierOrder.types";
import { SampleOrderExtended } from "./sampleOrder.types";
import { CustomerCustomerOrderExtended } from "./customer/customerCustomerOrder.types";
import { CustomerSampleOrderExtended } from "./customer/customerSampleOrder.types";

export enum I_STATE {
  OPEN = "open",
  PARTLY_PAID = "partlyPaid",
  PAID = "paid",
  CANCELED = "canceled",
}

export const I_INVOICE = "invoice" as const;
export const I_CREDIT_NOTE = "creditNote" as const;
export const I_SUPPLIERINVOICE = "supplierInvoice";
export const I_CUSTOMERINVOICE = "customerInvoice";
export const I_SAMPLEINVOICE = "sampleInvoice";
export const I_CREDITNOTESAMPLE = "creditNoteSample";
export const I_CREDITNOTECUSTOMER = "creditNoteCustomer";

export type InvoiceType =
  | typeof I_SUPPLIERINVOICE
  | typeof I_CUSTOMERINVOICE
  | typeof I_SAMPLEINVOICE
  | typeof I_CREDITNOTECUSTOMER
  | typeof I_CREDITNOTESAMPLE;

export interface Invoice {
  _id: BSON.ObjectId;
  invoiceNumber: string;
  invoiceDate: Date;
  paymentTarget: number;
  type: InvoiceType;
  state: I_STATE;
  company: CompanySnapshot | SupplierSnapshot;
  relatedOrder?: string;
  subtotal: number; // Total amount without taxes
  total: number; // Total amount that has to be paid
  currency: string;
  positions: Array<Position>;
  reminders: Array<Reminder>; // Only relevant for customerInvoices
  payments: Array<Payment>;
  file: string; // Path to PDF
  cancelation?: Cancelation;
  reverseCharge: boolean;
  nonEU: boolean;
}

export interface InvoiceExtended extends Omit<Invoice, "company" | "relatedOrder"> {
  company: Company | Supplier;
  relatedOrder?:
    | CustomerOrderExtended
    | CustomerCustomerOrderExtended
    | SupplierOrderExtended
    | SampleOrderExtended
    | CustomerSampleOrderExtended;
}

export interface Payment {
  _id: BSON.ObjectId;
  date: Date;
  amount: number;
  person: string;
}

export interface Position {
  _id: BSON.ObjectId;
  title: string;
  description: string;
  quantity: number;
  unit: string;
  price: number;
  vatPercentage: number;
  discount?: number; // in %
  total: number;
}

export const REMINDER = "reminder" as const;
export const FIRSTDUNNING = "firstDunning" as const;
export const SECONDDUNNING = "secondDunning" as const;

export interface Reminder {
  _id: BSON.ObjectId;
  type: typeof REMINDER | typeof FIRSTDUNNING | typeof SECONDDUNNING;
  date: Date;
  dunningFee: number;
  reminderFile: string; // Path to PDF
}

export interface Cancelation {
  _id: BSON.ObjectId;
  date: Date;
  note: string;
  cancelationFile: string; // Path to PDF
}

export interface VATPosition {
  netValue: number;
  percentage: number;
  vatAmount: number;
}
