import React from "react";
import NewsTags from "../news/common/NewsTags";
import { DataContextInternal } from "../../context/dataContext";
import { Article, ArticleType } from "../../model/article.types";
import { ARTICLE_TYPES } from "../../utils/articleUtils";
import { formatDate, getDocFromCollection, toAbsoluteUrl } from "../../utils/baseUtils";
import { resolveFilePath } from "../../utils/fileUtils";
import { getUserName } from "../../utils/userUtils";

interface NewsEntryProps {
  article: Article;
  context: React.ContextType<typeof DataContextInternal>;
  onClick?: () => void;
}

const NewsEntry: React.FunctionComponent<NewsEntryProps> = ({ article, context, onClick }) => {
  const author = getDocFromCollection(context.userData, article.createdBy);
  const fallBackImage = toAbsoluteUrl("/assets/media/newsPlaceholder.png");
  const imagePath = article.imagePath.trim() !== "" ? resolveFilePath(article.imagePath, false) : fallBackImage;

  return (
    <div className="col-md-4 p-5 py-10 cursor-pointer background-hover-light" onClick={onClick}>
      <div className="me-md-6">
        <div
          className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px"
          style={{ backgroundImage: `url("${imagePath}"), url("${fallBackImage}")` }}
        />
        <div className="mt-5">
          <span className="fs-4 fw-bold lh-base">{article.title}</span>
          <div className="fw-semibold fs-6 text-muted text-gray-900 mt-3" style={{ whiteSpace: "pre-wrap" }}>
            {article.newsText.length > 300 ? article.newsText.slice(0, 297) + "..." : article.newsText}
          </div>
          <NewsTags tags={article.tags} className="mt-2" />
          <div className="fs-6 fw-bold mt-3 d-flex flex-stack">
            <span>
              <b>{author ? getUserName(author) : "Unknown Author"}</b>
              <span className="text-muted ml-2">{formatDate(article.creationTime)}</span>
            </span>
            <span className="text-muted ml-2">{ARTICLE_TYPES[article.type || ArticleType.NEWS]}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsEntry;
