import _ from "lodash";
import { BSON } from "realm-web";
import React, { PureComponent, useState } from "react";
import { Link } from "react-router-dom";
import { Accordion, ProgressBar } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  SupplierExtended,
  T_S_UPDATECOMMODITYPRICE,
  T_S_UPDATEFINISHEDPRODUCTPRICE,
} from "../../../../model/supplier.types";
import { DataContextInternalType } from "../../../../context/dataContext";
import { CommodityExtended } from "../../../../model/commodity.types";
import Search from "../../../common/Search";
import { CustomToggle } from "../../../common/CustomToggle";
import PropertiesWidget from "../../../common/PropertiesWidget";
import {
  D_MASTERSPECIFICATION,
  getBestPrice,
  getCommoditySupplierDiff,
  getCommodityTimelineEntry,
  getDefaultPrice,
  getMinOQ,
  getPriceRange,
  getSupplierPriceRange,
  T_PRICESUPDATED,
  T_PRICEUPDATED,
  transformSuppliersForDiff,
  UPDATECOMMODITYSUPPLIERPRICES,
} from "../../../../utils/commodityUtils";
import { doFuseSearch, formatDate, getDocFromCollection, toAbsoluteUrl } from "../../../../utils/baseUtils";
import CommodityPriceTable from "../../../common/CommodityPriceTable";
import {
  Action,
  callFunction,
  COMMODITY,
  COMMODITYOFFERREQUEST,
  FINISHEDPRODUCT,
  transaction,
  UpdateAction,
} from "../../../../services/dbService";
import { PropertyType } from "../../../../utils/propertyUtils";
import CommoditySupplierSettingsModal from "../../../common/CommoditySupplierSettingsModal";
import {
  CommodityOfferRequest,
  COR_ARTICLE_TYPE,
  COR_T_APPROVED,
  COR_T_INREVIEW,
  CORState,
} from "../../../../model/commodityOfferRequest.types";
import { Textarea } from "../../../common/Textarea";
import { resolveFilePath } from "../../../../utils/fileUtils";
import SimpleConfirmationModal from "../../../common/SimpleConfirmationModal";
import { getCORTimelineEntry } from "../../../../utils/commodityOfferRequestUtils";
import RejectCommodityOfferRequestModal from "../modals/RejectCommodityOfferRequestModal";
import OrderHistoryEntry from "../../../orders/common/OrderHistoryEntry";
import SelectCommoditiesModal from "../modals/SelectCommoditiesModal";
import { Currencies, EURO } from "../../../../utils/currencyUtils";
import { getSupplierTimelineEntry, updateSupplierTimeline } from "../../../../utils/supplierUtils";
import { CommoditySupplierSettingsUpdate } from "../../../common/CustomTypes";
import { getCommoditySupplierSettingsUpdateActions } from "../../../../utils/commoditySupplierUtils";
import { FinishedProductExtended } from "../../../../model/finishedProduct.types";
import { getFinishedProductTimelineEntry, isFinishedProduct } from "../../../../utils/finishedProductUtils";
import { InternalArticleExtended } from "../../../../utils/productArticleUtils";
import { extendCommodity, extendFinishedProduct } from "../../../../utils/dataTransformationUtils";

interface SupplierPagePricesProps {
  supplier: SupplierExtended;
  context: DataContextInternalType;
  corSelected?: string;
  finishedProduct: boolean;
}

interface SupplierPagePricesState {
  search: string;
  articles: Array<InternalArticleExtended>;
  activeCORs: Array<CommodityOfferRequest>;
}

class SupplierPagePrices extends PureComponent<SupplierPagePricesProps, SupplierPagePricesState> {
  originArticles: Array<InternalArticleExtended>;
  constructor(props: SupplierPagePricesProps) {
    super(props);
    const articles = this.getRelevantArticles(props);
    const activeCORs = this.getActiveCORs(props);
    this.originArticles = articles;
    this.state = { search: "", articles, activeCORs };
  }

  componentDidUpdate = (prevProps: Readonly<SupplierPagePricesProps>) => {
    if (!_.isEqual(prevProps.supplier, this.props.supplier)) {
      this.setState({
        articles: this.getRelevantArticles(this.props),
        activeCORs: this.getActiveCORs(this.props),
      });
    } else {
      const articles = this.getRelevantArticles(this.props);
      // Only update if supplier data between origin and current property commodities changed to not overwrite changes unnecessarily
      const someChanged = this.props.finishedProduct
        ? (this.originArticles as Array<FinishedProductExtended>).some(
            (c) =>
              !_.isEqual(
                c.suppliers,
                (articles as Array<FinishedProductExtended>).find((c2) => c2._id.toString() === c._id.toString())
                  ?.suppliers
              )
          )
        : (this.originArticles as Array<CommodityExtended>).some(
            (c) =>
              !_.isEqual(
                c.suppliers,
                (articles as Array<CommodityExtended>).find((c2) => c2._id.toString() === c._id.toString())?.suppliers
              )
          );
      if (this.originArticles.length !== articles.length || someChanged) {
        this.originArticles = articles;
        this.setState({ articles: articles });
      }
      const activeCORs = this.getActiveCORs(this.props);
      if (!_.isEqual(activeCORs, this.state.activeCORs)) {
        this.setState({ activeCORs });
      }
    }
  };

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });

  handleNewPrice = (entryId: BSON.ObjectId | string) => {
    const { context } = this.props;
    const commodities = _.cloneDeep(this.state.articles);
    const com = commodities.find((c) => c._id.toString() === entryId.toString());
    if (!com) return;
    const entry = com.suppliers.find((s) => s.supplier._id.toString() === this.props.supplier._id.toString());
    if (entry) {
      const supplier = getDocFromCollection(context.supplier, entry.supplier._id);
      entry.prices.push(
        getDefaultPrice(
          entry.prices[entry.prices.length - 1],
          entry.leadTime || supplier?.transport.preparationTime,
          supplier?.euSupplier ? EURO : undefined
        )
      );
      this.setState({ articles: commodities });
    }
  };

  handleRemovePrice = (entryId: BSON.ObjectId | string, priceId: BSON.ObjectId | string) => {
    const commodities = _.cloneDeep(this.state.articles);
    const com = commodities.find((c) => c._id.toString() === entryId.toString());
    if (!com) return;
    const entry = com.suppliers.find((s) => s.supplier._id.toString() === this.props.supplier._id.toString());
    if (entry) entry.prices = entry.prices.filter((p) => p._id.toString() !== priceId.toString());
    this.setState({ articles: commodities });
  };

  handleUpdatePrice = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    entryId: BSON.ObjectId | string,
    priceId: BSON.ObjectId | string
  ) => {
    const commodities = _.cloneDeep(this.state.articles);
    const com = commodities.find((c) => c._id.toString() === entryId.toString());
    if (!com) return;
    const entry = com.suppliers.find((s) => s.supplier._id.toString() === this.props.supplier._id.toString());
    if (!entry) return;
    const price = entry.prices.find((p) => p._id.toString() === priceId.toString());
    if (price) _.set(price, e.target.name, e.target.type === "number" ? +e.target.value : e.target.value);
    this.setState({ articles: commodities });
  };

  handleSaveSettings = async (
    commodityId: BSON.ObjectId | string,
    supplierId: BSON.ObjectId | string,
    settings: CommoditySupplierSettingsUpdate,
    finishedProduct: boolean
  ) => {
    const actions = getCommoditySupplierSettingsUpdateActions(commodityId, supplierId, settings, finishedProduct);
    if (actions.length > 0) {
      const res = await transaction(actions);
      if (res) {
        toast.success("Update successful");
      } else {
        toast.error("Data couldn't be updated");
      }
    }
  };

  handleDisableCommodity = (entryId: BSON.ObjectId | string) => {
    const commodities = _.cloneDeep(this.state.articles);
    const com = commodities.find((c) => c._id.toString() === entryId.toString());
    if (!com) return;
    const entry = com.suppliers.find((s) => s.supplier._id.toString() === this.props.supplier._id.toString());
    if (!entry) return;
    entry.disabled = !entry.disabled;
    this.setState({ articles: commodities }, () => this.handleSaveCommodity(entryId));
  };

  handleRenewPrices = (entryId: BSON.ObjectId | string, date: Date, priceId?: BSON.ObjectId | string) => {
    const commodities = _.cloneDeep(this.state.articles);
    const com = commodities.find((c) => c._id.toString() === entryId.toString());
    if (!com) return;
    const entry = com.suppliers.find((s) => s.supplier._id.toString() === this.props.supplier._id.toString());
    if (!entry) return;
    if (priceId) {
      const price = entry.prices.find((p) => p._id.toString() === priceId.toString());
      if (!price) return;
      const val = new Date(date);
      if (isNaN(val.getTime())) return;
      _.set(price, "validUntil", val);
      price.date = new Date();
    } else {
      for (let i = 0; i < entry.prices.length; i++) {
        const val = new Date(date);
        if (isNaN(val.getTime())) return;
        _.set(entry.prices[i], "validUntil", val);
        entry.prices[i].date = new Date();
      }
    }
    this.setState({ articles: commodities }, () => this.handleSaveCommodity(entryId));
  };

  handleSetLeadTime = (entryId: BSON.ObjectId | string, value: string) => {
    const commodities = _.cloneDeep(this.state.articles);
    const com = commodities.find((c) => c._id.toString() === entryId.toString());
    if (!com) return;
    const entry = com.suppliers.find((s) => s.supplier._id.toString() === this.props.supplier._id.toString());
    if (!entry) return;
    const val = parseFloat(value);
    entry.leadTime = val; // Set lead time globally for offered commodity
    entry.prices = entry.prices.map((price) => ({ ...price, leadTime: val })); // Overwrite individual lead times if global lead time is set
    this.setState({ articles: commodities }, () => this.handleSaveCommodity(entryId));
  };

  handleSaveCommodity = async (entryId: BSON.ObjectId | string) => {
    const { supplier, context, finishedProduct } = this.props;
    const { articles } = this.state;
    const originCommodity = finishedProduct
      ? context.finishedProduct.find((fp) => fp._id.toString() === entryId.toString())
      : context.commodity.find((c) => c._id.toString() === entryId.toString());
    const commodity = articles.find((c) => c._id.toString() === entryId.toString());
    if (!commodity || !originCommodity) return;
    const supplierUpdate = commodity.suppliers.find((s) => s.supplier._id.toString() === supplier._id.toString());
    const oldSupplier = originCommodity.suppliers.find((s) => s.supplier === supplier._id.toString());
    if (!supplierUpdate || !oldSupplier) return;

    supplierUpdate.prices.sort((p1, p2) => p1.minOQ - p2.minOQ);
    const commodityTimelineEntry = getCommodityTimelineEntry(T_PRICESUPDATED);
    const supplierTimelineEntry = getSupplierTimelineEntry(
      finishedProduct ? T_S_UPDATEFINISHEDPRODUCTPRICE : T_S_UPDATECOMMODITYPRICE,
      {
        reference: commodity._id.toString(),
      }
    );
    // Diff
    const [differentSuppliersPre, differentSuppliersPost] = getCommoditySupplierDiff(
      transformSuppliersForDiff([oldSupplier]),
      transformSuppliersForDiff([supplierUpdate])
    );
    if (differentSuppliersPre.length > 0) {
      commodityTimelineEntry.pre = { suppliers: differentSuppliersPre };
      supplierTimelineEntry.pre = differentSuppliersPre;
    }
    if (differentSuppliersPost.length > 0) {
      commodityTimelineEntry.post = { suppliers: differentSuppliersPost };
      supplierTimelineEntry.post = differentSuppliersPost;
    }
    const resCommodity = await callFunction<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false>(
      UPDATECOMMODITYSUPPLIERPRICES,
      [
        entryId.toString(),
        { id: oldSupplier._id.toString(), supplier: supplierUpdate },
        false,
        commodityTimelineEntry,
        finishedProduct,
      ]
    );
    const resSupplier = await updateSupplierTimeline(supplier._id, supplierTimelineEntry);
    if (resCommodity && resCommodity.modifiedCount > 0 && resSupplier) {
      toast.success(`${finishedProduct ? "Finished Product" : "Commodity"} prices updated successfully`);
    } else {
      toast.error(`Error updating ${finishedProduct ? "finished product" : "commodity"} prices`);
    }
  };

  getRelevantArticles = (props: SupplierPagePricesProps) => {
    const { context, finishedProduct } = props;
    if (finishedProduct) {
      const fps = context.finishedProduct.filter((fp) =>
        fp.suppliers.some((s) => s.supplier === props.supplier._id.toString())
      );
      return fps.map((fp) => extendFinishedProduct(fp, props.context));
    }
    const com = context.commodity.filter((com) =>
      com.suppliers.some((s) => s.supplier === props.supplier._id.toString())
    );
    return com.map((c) => extendCommodity(c, context));
  };

  getActiveCORs = (props: SupplierPagePricesProps) => {
    const { commodityOfferRequest } = props.context;
    return commodityOfferRequest.filter(
      (cor) =>
        cor.supplier === props.supplier._id.toString() &&
        ![CORState.CANCELED, CORState.REJECTED, CORState.APPROVED].includes(cor.state)
    );
  };

  render() {
    const { supplier, corSelected, context, finishedProduct } = this.props;
    const { search, articles, activeCORs } = this.state;
    const filteredCommodities = search.trim() ? doFuseSearch(articles, search, ["title.en", "subtitle.en"]) : articles;

    return (
      <div className="card bg-white">
        <div className="card-header border-0 mt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">
              {finishedProduct ? "Finished Product" : "Commodity"} Prices
            </span>
            <span className="text-muted fw-bold fs-7">
              {articles.length} {finishedProduct ? "Finished Products" : "Commodities"}
            </span>
          </h3>
          <div className="card-toolbar row">
            <div className="col-5 p-0">
              <SelectCommoditiesModal context={context} supplier={supplier} finishedProduct={finishedProduct} />
            </div>
            <div className="col-7 px-0 float-right">
              <Search onSearch={this.handleSearch} value={search} />
            </div>
          </div>
        </div>
        <div className="card-body p-4 pt-8">
          <Accordion
            alwaysOpen={true}
            defaultActiveKey={corSelected ?? filteredCommodities.map((c) => c._id.toString())}
          >
            {activeCORs.map((cor) => (
              <SupplierPageCommodityOfferRequest
                key={cor._id.toString()}
                eventKey={cor._id.toString()}
                supplier={supplier}
                commodityOfferRequest={cor}
                context={context}
                finishedProduct={finishedProduct}
              />
            ))}
            {filteredCommodities.map((com) => (
              <SupplierPagePrice
                key={com._id.toString()}
                eventKey={com._id.toString()}
                supplier={supplier}
                commodity={com}
                onNewPrice={this.handleNewPrice}
                onRemovePrice={this.handleRemovePrice}
                onUpdatePrice={this.handleUpdatePrice}
                onSaveCommodity={this.handleSaveCommodity}
                onRenewPrices={this.handleRenewPrices}
                onSetLeadTime={this.handleSetLeadTime}
                onSaveSettings={this.handleSaveSettings}
                onDisableCommodity={this.handleDisableCommodity}
                currencies={context.currencies}
              />
            ))}
          </Accordion>
        </div>
      </div>
    );
  }
}

interface SupplierPagePriceProps {
  eventKey: string;
  supplier: SupplierExtended;
  commodity: InternalArticleExtended;
  onNewPrice: (entryId: BSON.ObjectId | string) => void;
  onRemovePrice: (entryId: BSON.ObjectId | string, priceId: BSON.ObjectId | string) => void;
  onUpdatePrice: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    entryId: BSON.ObjectId | string,
    priceId: BSON.ObjectId | string
  ) => void;
  onSaveCommodity: (entryId: BSON.ObjectId | string) => void;
  onRenewPrices: (entryId: BSON.ObjectId | string, date: Date, priceId?: BSON.ObjectId | string) => void;
  onSetLeadTime: (entryId: BSON.ObjectId | string, value: string) => void;
  onSaveSettings: (
    commodityId: BSON.ObjectId | string,
    supplierId: BSON.ObjectId | string,
    settings: CommoditySupplierSettingsUpdate,
    finishedProduct: boolean
  ) => Promise<void>;
  onDisableCommodity: (entryId: BSON.ObjectId | string) => void;
  currencies: Currencies;
}

const SupplierPagePrice: React.FunctionComponent<SupplierPagePriceProps> = ({
  eventKey,
  supplier,
  commodity,
  onNewPrice,
  onRemovePrice,
  onUpdatePrice,
  onSaveCommodity,
  onRenewPrices,
  onSetLeadTime,
  onSaveSettings,
  onDisableCommodity,
  currencies,
}) => {
  const commoditySupplier = commodity.suppliers.find((s) => s.supplier._id.toString() === supplier._id.toString())!;
  const prices = commoditySupplier.prices;
  const minOQ = getMinOQ(prices);
  const bestPrice = getBestPrice(prices);
  const supplierPriceRange = getSupplierPriceRange(prices, currencies);
  const commodityPriceRange = getPriceRange(commodity, currencies);
  const disabled = commoditySupplier.disabled || supplier.disabled || prices.length === 0;
  const now = new Date();
  const validPrices = prices.filter((p) => p.validUntil >= now && p.price > 0).length;
  const invalidPrices = prices.filter((p) => p.validUntil < now || p.price <= 0).length;
  return (
    <div className="bg-light2 bg-light2-hover rounded p-5 mb-7">
      <CustomToggle eventKey={eventKey}>
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 me-2">
            <Link
              to={`/${isFinishedProduct(commodity) ? "finishedProduct" : "commodity"}/${commodity._id.toString()}`}
              onClick={(e) => e.stopPropagation()}
              className={"d-inline-flex fw-bolder text-gray-800 custom-link fs-6 " + (disabled && "text-muted")}
            >
              {commodity.disabled ? (
                <span className="text-danger mr-2">[DISABLED]</span>
              ) : commoditySupplier.disabled ? (
                <span className="text-warning mr-2">[DEACTIVATED]</span>
              ) : prices.length === 0 ? (
                <span className="text-warning mr-2">[NO PRICES]</span>
              ) : (
                ""
              )}
              {commodity.title.en}
            </Link>
            <span className="text-muted fw-bold d-block">{commodity.subtitle.en}</span>
            <div style={{ textAlign: "left", marginRight: 25 }}>
              <PropertiesWidget
                properties={commodity.properties}
                types={[PropertyType.CATEGORY, PropertyType.COMPOSITION]}
              />
            </div>
          </div>
          <div style={{ textAlign: "right", marginRight: 25 }}>
            <span className=" mb-1 fs-6 fw-bold text-nowrap">{minOQ >= 0 ? `${minOQ} ${commodity.unit}` : "N/A"}</span>
            <div className="text-muted text-nowrap">MOQ</div>
          </div>
          <div style={{ textAlign: "right", marginRight: 25 }}>
            <span className="mb-1 fs-6 fw-bold text-nowrap">
              {!commoditySupplier.contingent
                ? "-"
                : commoditySupplier.contingent !== Infinity
                ? `${commoditySupplier.contingent} ${commodity.unit || ""}`
                : "unlimited"}
            </span>
            <div className="text-muted text-nowrap">Contingent</div>
          </div>
          <div style={{ textAlign: "right", marginRight: 25 }}>
            <span className=" mb-1 fs-6 fw-bold text-nowrap">{bestPrice ? bestPrice : "N/A"}</span>
            <div className="text-muted text-nowrap">Best Price</div>
          </div>
          <div style={{ textAlign: "right", marginRight: 25 }}>
            <span className=" mb-1 fs-6 fw-bold text-nowrap">{prices.length} Prices</span>
            <div className="text-nowrap fs-8">
              <span className={validPrices > 0 ? "text-success" : "text-muted"}>{validPrices} valid</span>{" "}
              <span className={invalidPrices > 0 ? "text-danger" : "text-muted"}>{invalidPrices} invalid</span>
            </div>
          </div>
          <div style={{ textAlign: "right" }}>
            <div className="text-muted text-nowrap">Price Level</div>
            {commodityPriceRange ? (
              <ProgressBar style={{ backgroundColor: "rgb(74,74,74)", height: 5 }}>
                <ProgressBar
                  now={supplierPriceRange.min}
                  min={commodityPriceRange.min}
                  max={commodityPriceRange.max}
                  style={{ backgroundColor: "red" }}
                />
                <ProgressBar
                  now={supplierPriceRange.max}
                  min={commodityPriceRange.min}
                  max={commodityPriceRange.max}
                  style={{ backgroundColor: "rgb(74,74,74)" }}
                />
              </ProgressBar>
            ) : (
              <ProgressBar style={{ backgroundColor: "rgb(74,74,74)", height: 5 }} />
            )}
          </div>
          <div onClick={(e) => e.stopPropagation()}>
            <CommoditySupplierSettingsModal
              article={commodity}
              supplierPrice={commoditySupplier}
              onSaveSettings={onSaveSettings}
            />
          </div>
        </div>
      </CustomToggle>
      <Accordion.Collapse eventKey={eventKey}>
        <CommodityPriceTable
          commodity={commodity}
          disabled={commodity.disabled || supplier.disabled}
          supplierDisabled={commoditySupplier.disabled}
          supplier={commoditySupplier.supplier}
          eventKey={eventKey}
          prices={prices}
          onNewPrice={onNewPrice}
          onRemovePrice={onRemovePrice}
          onUpdatePrice={onUpdatePrice}
          onSave={onSaveCommodity}
          onRenewPrices={onRenewPrices}
          onSetLeadTime={onSetLeadTime}
          onDisable={onDisableCommodity}
        />
      </Accordion.Collapse>
    </div>
  );
};

export default SupplierPagePrices;

enum ButtonAction {
  REVIEW,
  APPROVE,
}

interface SupplierPageCommodityOfferRequestProps {
  eventKey: string;
  supplier: SupplierExtended;
  commodityOfferRequest: CommodityOfferRequest;
  context: DataContextInternalType;
  finishedProduct: boolean;
}

const SupplierPageCommodityOfferRequest: React.FunctionComponent<SupplierPageCommodityOfferRequestProps> = ({
  eventKey,
  supplier,
  commodityOfferRequest,
  context,
  finishedProduct,
}) => {
  const isFP = commodityOfferRequest.article.type === COR_ARTICLE_TYPE.FINISHEDPRODUCT;
  let article: InternalArticleExtended | undefined;
  if (isFP) {
    const fp = getDocFromCollection(context.finishedProduct, commodityOfferRequest.article.id);
    if (fp) article = extendFinishedProduct(fp, context);
  } else {
    const com = getDocFromCollection(context.commodity, commodityOfferRequest.article.id);
    if (com) article = extendCommodity(com, context);
  }
  if (!article) return <div />;

  const [saving, setSaving] = useState(false);

  const handleClickButton = async (button: ButtonAction) => {
    setSaving(true);
    const state = button === ButtonAction.REVIEW ? CORState.IN_REVIEW : CORState.APPROVED;
    try {
      const actions: Array<Action> = [
        {
          collection: COMMODITYOFFERREQUEST,
          filter: { _id: commodityOfferRequest._id },
          push: {
            timeline: getCORTimelineEntry(button === ButtonAction.REVIEW ? COR_T_INREVIEW : COR_T_APPROVED),
          },
          update: { state },
        },
      ];
      const res = await transaction(actions);
      if (res) {
        toast.success(
          `${finishedProduct ? "Finished Product" : "Commodity"} Offer Request successfully ${
            button === ButtonAction.REVIEW ? "set in review" : "approved"
          }`
        );
      } else {
        toast.error(
          `Error ${button === ButtonAction.REVIEW ? "setting" : "approving"} ${
            finishedProduct ? "Finished Product" : "Commodity"
          } Offer Request ${button === ButtonAction.REVIEW ? "in review" : ""}`
        );
      }
      if (button === ButtonAction.APPROVE) {
        const timelineEntry = finishedProduct
          ? getFinishedProductTimelineEntry(T_PRICEUPDATED, undefined, supplier._id.toString())
          : getCommodityTimelineEntry(T_PRICEUPDATED, undefined, supplier._id.toString());
        const resCom = await callFunction<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false>(
          UPDATECOMMODITYSUPPLIERPRICES,
          [
            article?._id.toString(),
            {
              id: new BSON.ObjectId(),
              supplier: { _id: new BSON.ObjectId(), supplier, contingent: Infinity, prices: [] },
            },
            true,
            timelineEntry,
            finishedProduct,
          ]
        );
        if (resCom && resCom.modifiedCount) {
          toast.success(`${finishedProduct ? "Finished Product" : "Commodity"} is now activated for the supplier`);
          // Add documents to article
          const action: UpdateAction = {
            collection: finishedProduct ? FINISHEDPRODUCT : COMMODITY,
            filter: { _id: article?._id },
            push: { documents: { $each: [commodityOfferRequest.coa, commodityOfferRequest.specification] } },
          };
          const res = await transaction([action]);
          if (res) {
            toast.success("Documents successfully added to article");
          } else {
            toast.error("Documents could not be added to article - please add them manually");
          }
        } else {
          toast.error(`Error while activating ${finishedProduct ? "finished product" : "commodity"} for supplier`);
        }
      } else {
        const masterSpec = article?.documents.find((d) => d.type === D_MASTERSPECIFICATION)?.path;
        if (masterSpec) window.open(resolveFilePath(masterSpec));
        if (commodityOfferRequest.coa) window.open(resolveFilePath(commodityOfferRequest.coa.path));
        if (commodityOfferRequest.specification) window.open(resolveFilePath(commodityOfferRequest.specification.path));
      }
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="bg-light2 bg-light2-hover rounded p-5 mb-7">
      <CustomToggle eventKey={eventKey}>
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 me-2">
            <Link
              to={`/${isFP ? "finishedProduct" : "commodity"}/${commodityOfferRequest.article.id}`}
              onClick={(e) => e.stopPropagation()}
              className="d-inline-flex fw-bolder text-gray-800 custom-link fs-6"
            >
              {commodityOfferRequest.state === CORState.REQUESTED ? (
                <span className="text-danger mr-2">[REQUESTED]</span>
              ) : commodityOfferRequest.state === CORState.IN_REVIEW ? (
                <span className="text-warning mr-2">[IN REVIEW]</span>
              ) : (
                ""
              )}
              {article.title.en}
            </Link>
            <span className="text-muted fw-bold d-block">{article.subtitle.en}</span>
            <div style={{ textAlign: "left", marginRight: 25 }}>
              <PropertiesWidget
                properties={article.properties}
                types={[PropertyType.CATEGORY, PropertyType.COMPOSITION]}
              />
            </div>
          </div>
          <div style={{ textAlign: "right", marginRight: 25 }}>
            <span className=" mb-1 fs-6 fw-bold text-nowrap">{formatDate(commodityOfferRequest.created)}</span>
            <div className="text-muted text-nowrap">Request Date</div>
          </div>
        </div>
      </CustomToggle>
      <Accordion.Collapse eventKey={eventKey}>
        <div className="table-responsive mt-5">
          <table className="table align-middle gs-0 gy-1">
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="border-bottom-0">Note</th>
                <th className="border-bottom-0">COA</th>
                <th className="border-bottom-0">Specification</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="align-middle">
                  <div className="input-group">
                    <Textarea
                      className="form-control custom-form-control"
                      value={commodityOfferRequest.note.trim() ? commodityOfferRequest.note : "No note available"}
                      disabled={true}
                    />
                  </div>
                </td>
                <td>
                  {commodityOfferRequest.coa ? (
                    <a href={resolveFilePath(commodityOfferRequest.coa.path)} target="_blank" rel="noopener noreferrer">
                      <img alt="pdf" className="w-30px mr-2" src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} />
                      <span className="text-white fw-bold">
                        COA
                        {commodityOfferRequest.coa.size && (
                          <small className="text-muted ml-2">
                            {Math.round(commodityOfferRequest.coa.size / 1024)} KB
                          </small>
                        )}
                      </span>
                    </a>
                  ) : (
                    <div className="text-error">Missing</div>
                  )}
                </td>
                <td>
                  {commodityOfferRequest.specification ? (
                    <a
                      href={resolveFilePath(commodityOfferRequest.specification.path)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img alt="pdf" className="w-30px mr-2" src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} />
                      <span className="text-white fw-bold">
                        Specification
                        {commodityOfferRequest.specification.size && (
                          <small className="text-muted ml-2">
                            {Math.round(commodityOfferRequest.specification.size / 1024)} KB
                          </small>
                        )}
                      </span>
                    </a>
                  ) : (
                    <div className="text-error">Missing</div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="fw-bolder text-white mb-2">History</div>
          <div className="mb-2">
            {commodityOfferRequest.timeline.map((entry) => (
              <OrderHistoryEntry
                key={entry._id.toString()}
                entry={{ order: commodityOfferRequest, timelineEntry: entry, recent: false }}
                context={context}
                showOrder={false}
              />
            ))}
          </div>
          {commodityOfferRequest.state === CORState.REQUESTED ? (
            <button
              className="btn btn-sm btn-text-success float-right ml-2"
              onClick={() => handleClickButton(ButtonAction.REVIEW)}
              disabled={saving}
            >
              Review
            </button>
          ) : (
            <SimpleConfirmationModal.SimpleConfirmationModalButton
              buttonText="Approve"
              buttonClasses="btn btn-sm btn-text-success float-right ml-2"
              size="md"
              modalTitle="Approve Commodity Offer Request"
              modalDescription={
                <span className="text-white">
                  Do you really want to approve the {finishedProduct ? "Finished Product" : "Commodity"} Offer Request?
                  The supplier can start inserting his prices afterwards.
                </span>
              }
              confirmButtonText="Approve"
              cancelButtonText="Cancel"
              disabled={saving}
              onConfirm={() => handleClickButton(ButtonAction.APPROVE)}
            />
          )}
          <RejectCommodityOfferRequestModal commodityOfferRequest={commodityOfferRequest} />
        </div>
      </Accordion.Collapse>
    </div>
  );
};
