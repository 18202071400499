import _ from "lodash";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { CommodityTimelineEntry } from "../../../../model/commodity.types";
import { Supplier, SupplierExtended, T_S_UPLOADSUPPLIERFILE } from "../../../../model/supplier.types";
import { Input } from "../../../common/Input";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";
import {
  D_MASTERSPECIFICATION,
  D_OTHER,
  D_SUPPLIERANALYSIS,
  D_TYPEOPTIONS,
  getCommodityTimelineEntry,
  T_FILEUPLOADED,
  updateCommodity,
} from "../../../../utils/commodityUtils";
import DateInput from "../../../common/DateInput";
import {
  uploadAndGetArticleFileObject,
  uploadAndGetSupplierFileObject,
  validateFileName,
} from "../../../../utils/fileUtils";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import { Action, SUPPLIER, transaction } from "../../../../services/dbService";
import { getSupplierTimelineEntry, SUP_D_TYPEOPTIONS } from "../../../../utils/supplierUtils";
import { FinishedProductTimelineEntry } from "../../../../model/finishedProduct.types";
import {
  getFinishedProductTimelineEntry,
  isFinishedProduct,
  updateFinishedProduct,
} from "../../../../utils/finishedProductUtils";
import { InternalArticleExtended } from "../../../../utils/productArticleUtils";
import { reduceUploadedFile } from "../../../../utils/dataTransformationUtils";

export const T_COMMODITY = "commodity";
export const T_SUPPLIER = "supplier";
export const T_GENERALSUPPLIER = "generalSupplier";
export const T_GENERALARTICLE = "generalCommodity";
export const T_FINISHEDPRODUCT = "finishedProduct";

interface UploadDocumentModalProps {
  article?: InternalArticleExtended; // required for supplier selection
  supplier?: Supplier | SupplierExtended; // required for commodity or finished product selection. Existence of both commodity and supplier means we update an existing doc
  articles?: Array<InternalArticleExtended>; // if type is commodity
  buttonType: "small" | "wide";
  type: "commodity" | "supplier" | "generalSupplier" | "generalCommodity" | "finishedProduct"; // type commodity and finishedProduct require supplier and allows selection of commodity or finished product, type supplier requires commodity or finished product and allows selection of commodity, generalSupplier allows optional supplier selection
  noSupplierSelection?: boolean;
}

interface UploadDocumentModalState {
  show: boolean;
  file?: File;
  fileName: string;
  validUntil: Date | null;
  supplier?: SelectOption<Supplier | SupplierExtended>;
  article?: SelectOption<InternalArticleExtended>;
  saving: boolean;
  type?: SelectOption;
  customType: string;
}

class UploadDocumentModal extends PureComponent<UploadDocumentModalProps, UploadDocumentModalState> {
  fileSelectionRef: React.RefObject<HTMLInputElement>;
  constructor(props: UploadDocumentModalProps) {
    super(props);
    this.fileSelectionRef = React.createRef();
    this.state = {
      show: false,
      fileName: "",
      customType: "",
      saving: false,
      validUntil: null,
      supplier: props.supplier
        ? { value: props.supplier._id.toString(), label: props.supplier.name, object: props.supplier }
        : undefined,
      article: props.article
        ? { value: props.article._id.toString(), label: props.article.title.en, object: props.article }
        : undefined,
    };
  }

  handleShow = () =>
    this.setState({
      show: true,
      file: undefined,
      fileName: "",
      customType: "",
      validUntil: null,
      saving: false,
      type: undefined,
      supplier: this.props.supplier
        ? { value: this.props.supplier._id.toString(), label: this.props.supplier.name, object: this.props.supplier }
        : undefined,
      article: this.props.article
        ? {
            value: this.props.article._id.toString(),
            label: this.props.article.title.en,
            object: this.props.article,
          }
        : undefined,
    });

  handleHide = () => {
    if (this.fileSelectionRef.current) this.fileSelectionRef.current.value = "";
    this.setState({ show: false });
  };

  handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    const newState: { file?: File; fileName: string } = { file: file || undefined, fileName: "" };
    if (file) newState.fileName = file.name;
    this.setState(newState);
  };

  handleDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.setState({ validUntil: value ? new Date(value) : null });
  };

  handleSupplierChange = (e: SelectOption<Supplier>) => this.setState({ supplier: e });
  handleArticleChange = (e: SelectOption<InternalArticleExtended>) => this.setState({ article: e });
  handleTypeChange = (e: SelectOption) => this.setState({ type: e, customType: "" });

  handleUpload = async () => {
    const { type: propType, supplier: propSupplier } = this.props;
    const { file, fileName, validUntil, supplier, article, type, customType } = this.state;
    if (!file || !type) return;
    if (propType === T_GENERALARTICLE && propSupplier && !article) {
      // Update supplier documents
      this.setState({ saving: true });
      try {
        const supplierFile = uploadAndGetSupplierFileObject(
          file,
          fileName,
          type.value,
          propSupplier,
          validUntil,
          customType
        );
        if (!supplierFile) return;
        const timelineEntry = getSupplierTimelineEntry(T_S_UPLOADSUPPLIERFILE, { type: supplierFile.type });
        const action: Action = {
          collection: SUPPLIER,
          filter: { _id: propSupplier._id },
          push: { documents: supplierFile, timeline: timelineEntry },
        };
        const result = await transaction([action]);
        if (result) {
          toast.success("File uploaded successfully");
          this.setState({ show: false });
        } else {
          toast.error("Error uploading file");
        }
      } finally {
        this.setState({ saving: false });
      }
    } else {
      // Update commodity or finished product documents
      if (!article?.object || (!supplier && propType === T_SUPPLIER)) return;
      if (article) {
        const documents = _.cloneDeep(article.object.documents);
        const finishedProduct = isFinishedProduct(article.object);
        this.setState({ saving: true });
        try {
          const uploadedFile = uploadAndGetArticleFileObject(
            file,
            fileName,
            type.value,
            supplier?.object,
            validUntil,
            customType
          );
          if (!uploadedFile) return;
          documents.push(uploadedFile);
          const timelineEntry = finishedProduct
            ? getFinishedProductTimelineEntry(T_FILEUPLOADED, uploadedFile.type, uploadedFile.path)
            : getCommodityTimelineEntry(T_FILEUPLOADED, uploadedFile.type, uploadedFile.path);
          const documentsReduced = documents.map((d) => reduceUploadedFile(d));
          const result = finishedProduct
            ? await updateFinishedProduct(
                { documents: documentsReduced },
                article.object._id,
                timelineEntry as FinishedProductTimelineEntry
              )
            : await updateCommodity(
                { documents: documentsReduced },
                article.object._id,
                timelineEntry as CommodityTimelineEntry
              );
          if (result && result.modifiedCount > 0) {
            toast.success("File uploaded successfully");
            this.setState({ show: false });
          } else {
            toast.error("Error uploading file");
          }
        } finally {
          this.setState({ saving: false });
        }
      }
    }
  };

  validateData = () => {
    const { type: propType, supplier: propSupplier } = this.props;
    const { file, fileName, supplier, type, customType, article } = this.state;
    const typeOptions = article ? D_TYPEOPTIONS : propSupplier ? SUP_D_TYPEOPTIONS : [];
    const errors = [];
    if (!file) errors.push("No file selected");
    if (fileName.trim().length <= 3) errors.push("File name too short");
    if (!validateFileName(fileName)) errors.push("File name contains invalid characters");
    if (propType === T_COMMODITY && !article) errors.push("No commodity selected");
    if (propType === T_FINISHEDPRODUCT && !article) errors.push("No finished product selected");
    if (propType === T_SUPPLIER && !supplier) errors.push("No supplier selected");
    if (!type) errors.push("No document type selected");
    if (type && !typeOptions.some((t) => t.value === type.value))
      errors.push(`Type ${type.label} is not allowed for this selection`);
    if (type?.value === D_OTHER && customType.trim() && customType.trim().length <= 3)
      errors.push("Enter a descriptive custom type. Minimum length: 4");
    if (type?.value === D_SUPPLIERANALYSIS && !supplier) errors.push("Supplier analysis requires a supplier");
    return errors;
  };

  render() {
    const {
      article: propCommodity,
      supplier: propSupplier,
      type: propType,
      articles,
      buttonType,
      noSupplierSelection,
    } = this.props;
    const { show, fileName, validUntil, saving, supplier, type, article, customType } = this.state;
    const errors = this.validateData();
    const disabled =
      propSupplier && propCommodity
        ? propSupplier?.disabled || propCommodity?.disabled
        : (propType === T_COMMODITY && propSupplier?.disabled) || (propType === T_SUPPLIER && propCommodity?.disabled);
    const typeOptions = article ? D_TYPEOPTIONS : propSupplier ? SUP_D_TYPEOPTIONS : [];
    return (
      <>
        {buttonType === "small" ? (
          <button
            className={"btn btn-text btn-sm text-muted float-right p-0 mr-5 " + (disabled && "disabled")}
            disabled={disabled}
            onClick={disabled ? undefined : this.handleShow}
          >
            Upload Document
          </button>
        ) : (
          <button
            className={"btn btn-light w-100 " + (disabled && "disabled")}
            disabled={disabled}
            onClick={disabled ? undefined : this.handleShow}
          >
            Upload Document
          </button>
        )}
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Upload Document</h1>
            </Modal.Title>
            <CloseButton variant={"white"} onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-5 ">
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="required fs-5 fw-bold mb-2">File</label>
                <input
                  type="file"
                  ref={this.fileSelectionRef}
                  accept="*"
                  className="form-control custom-form-control"
                  style={{ padding: "0.375rem 0.75rem" }}
                  name="file"
                  onChange={this.handleSelectFile}
                />
              </div>
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="required fs-5 fw-bold mb-2">File Name</label>
                <Input
                  type="text"
                  className="form-control custom-form-control"
                  name="fileName"
                  placeholder={"File Name"}
                  autoComplete="off"
                  value={fileName}
                  onBlur={(e) => this.setState({ fileName: e.target.value })}
                />
              </div>
              {!noSupplierSelection && [T_SUPPLIER, T_GENERALSUPPLIER].includes(propType) && propCommodity && (
                <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                  <label className={"fs-5 fw-bold mb-2 " + (propType === T_SUPPLIER ? "required" : "")}>Supplier</label>
                  <CustomSelect
                    options={propCommodity.suppliers.map((s) => {
                      return {
                        label: s.supplier.name,
                        value: s._id.toString(),
                        object: s.supplier,
                        isDisabled: s.supplier.disabled,
                      };
                    })}
                    disabled={!!propSupplier} // Disable if supplier was passed as property
                    isClearable={propType === T_GENERALSUPPLIER}
                    value={supplier}
                    onChange={this.handleSupplierChange}
                    matchFormControl={true}
                  />
                </div>
              )}
              {[T_COMMODITY, T_GENERALARTICLE, T_FINISHEDPRODUCT].includes(propType) && propSupplier && articles && (
                <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                  <label
                    className={
                      "fs-5 fw-bold mb-2 " +
                      (propType === T_COMMODITY || propType === T_FINISHEDPRODUCT ? "required" : "")
                    }
                  >
                    Article
                  </label>
                  <CustomSelect
                    options={articles.map((c) => {
                      return { label: c.title.en, value: c._id.toString(), object: c, isDisabled: c.disabled };
                    })}
                    disabled={!!propCommodity}
                    isClearable={propType === T_GENERALARTICLE}
                    value={article}
                    onChange={this.handleArticleChange}
                    matchFormControl={true}
                  />
                </div>
              )}
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="required fs-5 fw-bold mb-2">Type</label>
                <CustomSelect
                  options={typeOptions.filter((t) => t.value !== D_MASTERSPECIFICATION)}
                  value={type}
                  onChange={this.handleTypeChange}
                  matchFormControl={true}
                />
              </div>
              {type && type.value === D_OTHER && (
                <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                  <label className="fs-5 fw-bold mb-2">Custom Type</label>
                  <Input
                    type="text"
                    className="form-control custom-form-control"
                    name="customType"
                    placeholder={"e.g. Certificate"}
                    autoComplete="off"
                    value={customType}
                    onBlur={(e) => this.setState({ customType: e.target.value })}
                  />
                </div>
              )}
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="fs-5 fw-bold mb-2">Expiration Date</label>
                <DateInput
                  classes={"form-control custom-form-control"}
                  value={validUntil}
                  onBlur={this.handleDate}
                  name={"validUntil"}
                  allowClear={true}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-outline btn-text-white" onClick={this.handleHide}>
              Close
            </button>
            <ErrorOverlayButton
              errors={errors}
              className={"btn btn-sm btn-outline btn-outline-light "}
              saving={saving}
              buttonText={saving ? "Uploading..." : "Upload Document"}
              onClick={this.handleUpload}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default UploadDocumentModal;
