import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import CustomerOrderPage from "./CustomerOrderPage";
import { DataContextInternal } from "../../../../context/dataContext";

interface CustomerOrderWrapperParams {
  id: string;
}

interface CustomerOrderWrapperProps extends RouteComponentProps<CustomerOrderWrapperParams> {}

const CustomerOrderWrapper: React.FunctionComponent<CustomerOrderWrapperProps> = (props) => {
  const context = useContext(DataContextInternal);
  return <CustomerOrderPage context={context} {...props} />;
};

export default CustomerOrderWrapper;
