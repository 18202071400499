import { BSON } from "realm-web";
import { callFunction } from "../services/dbService";
import { Notify } from "../model/notify.types";

// Backend functions relating to property
export const UPSERTNOTIFY = "upsertNotify";

/**
 * Inserts a new notify into the database.
 * @param notify Notify that should be inserted into the database
 * @returns {Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false>} Result of the function
 */
export async function insertNotify(
  notify: Notify
): Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false> {
  return (await callUpsertNotify(notify, true)) as Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>;
}

/**
 * Updates an existing notify inside the database.
 * @param notify Notify that should be updated inside the database
 * @returns {Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false >} Result of the function
 */
export async function updateNotify(
  notify: Notify
): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> {
  return (await callUpsertNotify(notify, false)) as Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>;
}

/**
 * Calls the upsert notify function in backend.
 * @param notify Notify that should be upsert
 * @param insert True for insert, else update
 * @returns {Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false>} Result of the function
 */
async function callUpsertNotify(
  notify: Notify,
  insert: boolean
): Promise<
  Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false
> {
  return callFunction(UPSERTNOTIFY, [notify, insert]);
}
