export enum SortOrder {
  ASC = "asc",
  DESC = "desc",
}

export const SORTORDEROPTIONS: Array<SortOption> = [
  { value: SortOrder.ASC, label: "Ascending" },
  { value: SortOrder.DESC, label: "Descending" },
];

export interface SortOption {
  value: SortOrder;
  label: string;
}

export interface SortColumn {
  column: string;
  order: "asc" | "desc";
}
