import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { CloseButton, Modal } from "react-bootstrap";
import { uploadAndGetOrderFileObject } from "../../../../utils/fileUtils";
import { STO_STORAGENOTICE } from "../../../../utils/storageOrderUtils";
import { Action, STORAGEORDER, transaction } from "../../../../services/dbService";
import { StorageOrder } from "../../../../model/storageOrder.types";
import userService from "../../../../services/userService";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";

interface UploadStorageNoticeModalProps {
  storageOrder: StorageOrder;
  buttonText: string;
  disabled: boolean;
}

interface UploadStorageNoticeModalState {
  show: boolean;
  file?: File;
  saving: boolean;
}

class UploadStorageNoticeModal extends PureComponent<UploadStorageNoticeModalProps, UploadStorageNoticeModalState> {
  fileSelectionRef: React.RefObject<HTMLInputElement>;
  constructor(props: UploadStorageNoticeModalProps) {
    super(props);
    this.fileSelectionRef = React.createRef();
    this.state = {
      show: false,
      saving: false,
    };
  }

  handleShow = () => {
    this.setState({
      show: true,
      file: undefined,
      saving: false,
    });
  };

  handleHide = () => {
    if (this.fileSelectionRef.current) this.fileSelectionRef.current.value = "";
    this.setState({ show: false });
  };

  handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    const newState: { file?: File } = { file: file || undefined };
    this.setState(newState);
  };

  handleUpload = async () => {
    const { storageOrder } = this.props;
    const { file } = this.state;
    if (!file) return;

    this.setState({ saving: true });
    try {
      let actions: Array<Action> = [];
      const storageNoticeDocument = storageOrder.files.find((f) => f.type === STO_STORAGENOTICE);
      const storageOrderFile = uploadAndGetOrderFileObject(file, STO_STORAGENOTICE);
      if (!storageOrderFile) return;
      if (storageNoticeDocument) {
        actions.push({
          collection: STORAGEORDER,
          filter: { _id: storageOrder._id },
          update: {
            "files.$[f].path": storageOrderFile.path,
            "files.$[f].date": new Date(),
            "files.$[f].uploadedBy": userService.getUserId(),
          },
          arrayFilters: [{ "f._id": storageNoticeDocument._id }],
        });
      } else {
        actions.push({
          collection: STORAGEORDER,
          filter: { _id: storageOrder._id },
          push: {
            files: storageOrderFile,
          },
        });
      }
      const result = await transaction(actions);
      if (result) {
        toast.success("File uploaded successfully");
        this.setState({ show: false });
      } else {
        toast.error("Error uploading file");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  validateData = () => {
    const { file } = this.state;
    const errors = [];
    if (!file) errors.push("No file selected");
    return errors;
  };

  render() {
    const { disabled, buttonText } = this.props;
    const { show, saving } = this.state;
    const errors = this.validateData();
    return (
      <>
        <button
          className={"btn btn-outline btn-outline-light btn-sm px-2 p-1 " + (disabled && "disabled")}
          onClick={disabled ? undefined : this.handleShow}
        >
          {buttonText.trim() ? buttonText : "Upload Document"}
        </button>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Upload Storage Notice</h1>
            </Modal.Title>
            <CloseButton variant={"white"} onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-5 ">
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="required fs-5 fw-bold mb-2">File</label>
                <input
                  type="file"
                  ref={this.fileSelectionRef}
                  accept="*"
                  className="form-control custom-form-control"
                  style={{ padding: "0.375rem 0.75rem" }}
                  name="file"
                  onChange={this.handleSelectFile}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-outline btn-text-white" onClick={this.handleHide}>
              Close
            </button>
            <ErrorOverlayButton
              errors={errors}
              className={"btn btn-sm btn-outline btn-outline-light "}
              saving={saving}
              buttonText={saving ? "Uploading..." : "Upload Document"}
              onClick={this.handleUpload}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default UploadStorageNoticeModal;
