import _ from "lodash";
import { toast } from "react-toastify";
import { formatCurrency } from "./baseUtils";
import { callFunction } from "../services/dbService";

export const EURO = "EUR";
export const CNY = "CNY";
export const USD = "USD";
export const EXCHANGERATE_BASE = EURO;
export const BASE_CURRENCY = USD;
export const CUSTOMER_BASE_CURRENCY = EURO;

export const SUPPORTED_CURRENCIES = [EURO, USD, CNY];
export const DEFAULT_SO_CURRENCY_OPTION = { value: USD, label: USD };
export const DEFAULT_EU_SUPPLIER_CURRENCY_OPTION = { value: EURO, label: EURO };
export const SUPPORTED_CURRENCIES_OPTIONS = [
  DEFAULT_EU_SUPPLIER_CURRENCY_OPTION,
  DEFAULT_SO_CURRENCY_OPTION,
  { value: CNY, label: CNY },
];

export interface Currencies {
  [key: string]: number;
}

/**
 * Get the currencies and there conversion from EUR from the exchangerate service.
 * @returns { {currencies?: Currencies, date?: Date } } Currencies with the date they were polled
 */
export async function getCurrencies(): Promise<{ currencies?: Currencies; date?: Date }> {
  try {
    const result = await callFunction<Currencies>("getCurrencies", []);
    if (result && !_.isEmpty(result)) {
      return { currencies: result, date: new Date() };
    }
    return { currencies: {}, date: new Date("0000-00-00") };
  } catch (e) {
    console.error("An unexpected error occurred", e);
    return { currencies: {}, date: new Date("0000-00-00") };
  }
}

/**
 * Convert a value from one currency to another
 * @param value the numeric value
 * @param currencyFrom the current currency of the value
 * @param currencyTo the target currency
 * @param currencies List of currencies
 * @returns {number} converted currency
 */
export function convertCurrency(
  value: number,
  currencyFrom: string,
  currencyTo: string,
  currencies: Currencies
): number {
  if (currencyFrom === currencyTo) return value;
  if (!currencies[currencyFrom] || !currencies[currencyTo]) {
    toast.error("Issue while retrieving conversion rates! Converted currencies are not correct!");
    return value;
  } else {
    // If EUR is our base the currencies object contains all needed data
    if (currencyFrom === "EUR") return value * currencies[currencyTo];
    // If EUR is our target the calculation has just to be turned around
    if (currencyTo === "EUR") return value / currencies[currencyFrom];
    // Else we will convert the base into EUR and then into the target currency
    const fromInBase = value / currencies[currencyFrom];
    return fromInBase * currencies[currencyTo];
  }
}

/**
 * Convert a value from one currency to another and format it
 * @param value the numeric value
 * @param currencyFrom the current currency of the value
 * @param currencyTo the target currency
 * @param currencies List of currencies
 * @returns {string} converted and formatted currency
 */
export function convertAndFormatCurrency(
  value: number,
  currencyFrom: string,
  currencyTo: string,
  currencies: Currencies
): string {
  const converted = convertCurrency(value, currencyFrom, currencyTo, currencies);
  return formatCurrency(converted, currencyTo);
}

/**
 * Get the required currencies from the currencies object
 * @param currencies object with currencies and their exchange rate
 * @param requiredCurrencies specific currencies to pick
 * @returns {Currencies} object of desired currencies
 */
export function getRelevantCurrencyExchangeRates(
  currencies: Currencies,
  requiredCurrencies: Array<string>
): Currencies {
  // Filter out invalid currencies. Always include exchange rate base, base and customer base currency
  const pickedCurrencies = Array.from(
    new Set(
      SUPPORTED_CURRENCIES.concat(
        requiredCurrencies.map((c) => (c in currencies ? c : undefined)).filter((c) => c) as Array<string>
      )
    )
  );
  return _.pick(currencies, pickedCurrencies);
}
