import React, { PureComponent } from "react";
import { Accordion } from "react-bootstrap";
import {
  C_G_GRADE,
  C_G_CONTAMINANTS,
  C_G_PHYSICALCHEMICAL,
  C_G_PRODUCTINFORMATION,
  C_G_REGULATORY_STATEMENT,
  C_G_TYPE,
  filterAdvancedProperties,
} from "../../../utils/commodityUtils";
import { CustomToggle } from "../../common/CustomToggle";
import { DataContextType } from "../../../context/dataContext";
import { generateCommodityPropertyTableRows } from "./CommodityHelper";
import { ArticleExtended } from "../../../utils/productArticleUtils";

interface CommodityPageAdvancedInformationBlockProps {
  article: ArticleExtended;
  type: C_G_TYPE;
  search: string;
  context: DataContextType;
}

interface CommodityPageAdvancedInformationBlockState {
  show: boolean;
}

class CommodityPageAdvancedInformationBlock extends PureComponent<
  CommodityPageAdvancedInformationBlockProps,
  CommodityPageAdvancedInformationBlockState
> {
  constructor(props: CommodityPageAdvancedInformationBlockProps) {
    super(props);
    this.state = { show: props.type !== C_G_REGULATORY_STATEMENT };
  }

  handleToggleShow = () => this.setState({ show: this.state.show });

  generateAdvancedPropertiesList = (): JSX.Element | Array<JSX.Element> => {
    const { article, type, search } = this.props;
    const propertiesList = filterAdvancedProperties(article, search, type);
    if (propertiesList.length === 0) {
      return (
        <tr>
          <td colSpan={2}>
            <span className="text-white">No result found</span>
          </td>
        </tr>
      );
    }
    const elements = [];

    // Regulatory data is not splits into 2 cols and thus handled differently
    if (type === C_G_REGULATORY_STATEMENT) {
      for (let i = 0; i < propertiesList.length; i++) {
        const e1 = propertiesList[i];
        elements.push(
          <React.Fragment key={e1.label}>
            <tr className="pb-0">
              <td className="text-white min-w-125px ">{e1.label}</td>
            </tr>
            <tr>
              <td className="text-muted pb-4">
                <div className={"overflow-auto text-break " + (e1?.className || "")}>{e1.value}</div>
              </td>
            </tr>
          </React.Fragment>
        );
      }
      return elements;
    } else {
      return generateCommodityPropertyTableRows(propertiesList);
    }
  };

  /**
   * Generates the matching title for the property type.
   * @returns { string } Title for the type
   */
  generateTitleForType = (): string => {
    const { type } = this.props;
    switch (type) {
      case C_G_PHYSICALCHEMICAL:
        return "Physical & Chemical Parameters";
      case C_G_CONTAMINANTS:
        return "Contaminants";
      case C_G_REGULATORY_STATEMENT:
        return "Limits & Regulatory Information";
      case C_G_PRODUCTINFORMATION:
        return "Labels & Standards";
      case C_G_GRADE:
        return "Grade";
    }
  };

  render() {
    const { type } = this.props;
    const { show } = this.state;
    const withAccordion = type === C_G_REGULATORY_STATEMENT;

    return (
      <Accordion>
        <div className="py-0">
          <div className="d-flex py-3 align-items-center w-100">
            <div className="me-3 w-100">
              <div className="row">
                <div className="col col-md-6">
                  <div className="text-white fs-3 fw-bolder">
                    {withAccordion ? (
                      <CustomToggle eventKey={type} callback={this.handleToggleShow}>
                        {this.generateTitleForType()}
                        <i className={"custom-accordion-toggle fa fa-chevron-down ml-2" + (show ? " show" : "")} />
                      </CustomToggle>
                    ) : (
                      this.generateTitleForType()
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {withAccordion ? (
            <Accordion.Collapse eventKey={type}>
              <div className="fs-6">
                <div className="d-flex flex-wrap py-5">
                  <table className="table fw-bold gy-1">
                    <tbody>{this.generateAdvancedPropertiesList()}</tbody>
                  </table>
                </div>
              </div>
            </Accordion.Collapse>
          ) : (
            <div className="fs-6">
              <div className="d-flex flex-wrap py-5">
                <table className="table fw-bold gy-1">
                  <tbody>{this.generateAdvancedPropertiesList()}</tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </Accordion>
    );
  }
}

export default CommodityPageAdvancedInformationBlock;
