// Note axios error interceptor, only modify here
import axios from "axios";
import { toast } from "react-toastify";
import { getDefaultSlackChannel, sendMessage } from "./slackService";
import userService from "./userService";
import { getUserName } from "../utils/userUtils";

axios.interceptors.request.use((config) => {
  config.timeout = 15000; // 15s
  return config;
});

axios.interceptors.response.use(undefined, async (error: any) => {
  const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;
  if (error.code === "ECONNABORTED" && error.message.includes("timeout")) {
    sendMessage(
      getDefaultSlackChannel(true),
      `Request timed out for user ${getUserName(userService.getUserData())} (${userService.getUserId()}). Url: ${
        error.config.url
      }`
    );
  } else if (!expectedError) {
    toast.error("An unexpected error occurred");
  } else if (expectedError) {
    // 4XX errors should probably be handled more specifically in the future
    toast.error("A client error occurred: " + error.message);
  }

  return Promise.reject(error);
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
