import _ from "lodash";
import React, { PureComponent, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContextAnonymousType, DataContextCustomerType } from "../../../context/dataContext";
import userService from "../../../services/userService";
import FavoriteStar from "../../common/FavoriteStar";
import { Commodity } from "../../../model/commodity.types";
import { FavoritesExtended } from "../../../utils/favoritesUtils";
import { CustomerCommodity } from "../../../model/customer/customerCommodity.types";
import { formatCurrency, getDocFromCollection, pluralize } from "../../../utils/baseUtils";
import { CUSTOMER_BASE_CURRENCY } from "../../../utils/currencyUtils";
import HoverableLink from "../../common/HoverableLink";
import { FinishedProduct } from "../../../model/finishedProduct.types";
import { CustomerFinishedProduct } from "../../../model/customer/customerFinishedProduct.types";
import { isCustomerFinishedProduct } from "../../../utils/finishedProductUtils";
import { CUSTOMER } from "../../../utils/userUtils";

interface CustomerDashboardFavoritesProps extends RouteComponentProps {
  context: DataContextCustomerType | DataContextAnonymousType;
}

interface CustomerDashboardFavoritesState {
  favorites?: FavoritesExtended;
}

class CustomerDashboardFavorites extends PureComponent<
  CustomerDashboardFavoritesProps,
  CustomerDashboardFavoritesState
> {
  constructor(props: CustomerDashboardFavoritesProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState({ favorites: this.prepareFavorites() });
  }

  componentDidUpdate(prevProps: Readonly<CustomerDashboardFavoritesProps>) {
    if (!_.isEqual(prevProps.context.favorites, this.props.context.favorites)) {
      this.setState({ favorites: this.prepareFavorites() });
    }
  }

  prepareFavorites = () => {
    const { favorites, commodity, finishedProduct } = this.props.context;
    const fav = _.cloneDeep(favorites.find((f) => f.company === userService.getCompany()));
    if (!fav) return undefined;
    const favArticles: Array<CustomerCommodity | CustomerFinishedProduct> = [];
    for (let i = 0; i < fav.favorites.length; i++) {
      const f = fav.favorites[i];
      const com = getDocFromCollection(commodity, f);
      if (com) favArticles.push(com);
      else {
        const fp = getDocFromCollection(finishedProduct, f);
        if (fp) favArticles.push(fp);
      }
    }
    return { _id: fav._id, company: fav.company, favorites: favArticles };
  };

  render() {
    const { history } = this.props;
    const { favorites } = this.state;
    const hasFavorites = favorites && favorites.favorites.length > 0;
    const favoritesAmount = favorites?.favorites.length || 0;

    return (
      <div className="card h-100 bg-white">
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">Favorites</span>
            <span className="text-muted fw-bold fs-7">{pluralize(favoritesAmount, "Article")} marked as favorites</span>
          </h3>
        </div>
        <div className="card-body" style={{ overflowY: "auto" }}>
          {hasFavorites ? (
            favorites.favorites.map((f) => <CustomerDashboardFavorite key={f._id.toString()} article={f} />)
          ) : (
            <div className="p-7 text-center text-muted h6">
              No Favorites. Start marking your most used commodities as favorites to quickly see recent price updates
              here!
            </div>
          )}
        </div>
        <div className="card-footer border-top-0 pt-4">
          <button type="button" className="btn btn-light w-100" onClick={() => history.push("/articles")}>
            Show All Commodities
          </button>
        </div>
      </div>
    );
  }
}

interface CustomerDashboardFavoriteProps {
  article: Commodity | CustomerCommodity | FinishedProduct | CustomerFinishedProduct;
}

const CustomerDashboardFavorite: React.FunctionComponent<CustomerDashboardFavoriteProps> = ({ article }) => {
  const [hoverStar, setHoverStar] = useState(false);

  const handleHoverStar = (type: "enter" | "leave") => setHoverStar(type === "enter");

  const isFP = isCustomerFinishedProduct(article, CUSTOMER);

  return (
    <HoverableLink to={`/${isFP ? "finishedProduct" : "commodity"}/${article._id.toString()}`} hovering={hoverStar}>
      <div className={"bg-light rounded p-5 py-1 mb-3 " + (hoverStar ? "no-hover" : "")}>
        <div className="row">
          <div className="col-12 col-xl-6 mb-2 mb-xl-0 pr-0">
            <div className="d-flex align-items-center h-100">
              <FavoriteStar
                favorite={article._id.toString()}
                selected={true}
                iconClasses="fs-3 mr-3"
                onHoverStar={handleHoverStar}
              />
              <div className="flex-grow-1 mr-2">
                <div className="text-white fs-5">{article.title.en}</div>
                {article.subtitle.en.trim().length > 0 && (
                  <span className="text-muted d-block mb-2" style={{ fontSize: "0.85rem" }}>
                    {article.subtitle.en}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-6 pl-0">
            {isFP ? (
              <div className="py-1 row h-100">
                <div className="col-4" />
                <div className="col-4 align-content-center">
                  <div className="align-middle text-right text-center">
                    <span className="text-muted d-block text-nowrap">Prices on Request</span>
                  </div>
                </div>
                <div className="col-4" />
              </div>
            ) : (
              <div className="py-1 row">
                <div className="col-4">
                  <div className="align-middle text-right text-center">
                    <span className="fs-5 text-white text-nowrap">
                      {article.fromPrice?.seafreight.price
                        ? formatCurrency(article.fromPrice?.seafreight.price, CUSTOMER_BASE_CURRENCY)
                        : "-"}
                    </span>
                    <small className="text-muted d-block text-nowrap">Seafreight</small>
                  </div>
                </div>
                <div className="col-4">
                  <div className="align-middle text-right text-center">
                    <span className="fs-5 text-white text-nowrap">
                      {article.fromPrice?.airfreight.price
                        ? formatCurrency(article.fromPrice?.airfreight.price, CUSTOMER_BASE_CURRENCY)
                        : "-"}
                    </span>
                    <small className="text-muted d-block text-nowrap">Airfreight</small>
                  </div>
                </div>
                <div className="col-4">
                  <div className="align-middle text-right text-center">
                    <span className="fs-5 text-white text-nowrap">
                      {article.fromPrice?.warehouse.price
                        ? formatCurrency(article.fromPrice?.warehouse.price, CUSTOMER_BASE_CURRENCY)
                        : "-"}
                    </span>
                    <small className="text-muted d-block text-nowrap">Warehouse</small>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </HoverableLink>
  );
};

export default CustomerDashboardFavorites;
