import React from "react";
import ReactApexChart from "react-apexcharts";
import { PriceDevelopment } from "../../../model/statistics/commodityStatistics.types";
import { getDefaultPriceDevelopmentChartOptions, getGraphColors } from "../../../utils/commodityStatisticUtils";
import { formatCurrency } from "../../../utils/baseUtils";
import { CUSTOMER_BASE_CURRENCY } from "../../../utils/currencyUtils";

interface CommodityPagePriceDevelopmentProps {
  priceDevelopment?: PriceDevelopment;
  informationPosition: "top" | "left";
  width?: number;
  price?: number;
  loading: boolean;
}

const CommodityPagePriceDevelopment: React.FunctionComponent<CommodityPagePriceDevelopmentProps> = ({
  priceDevelopment,
  informationPosition,
  width,
  price,
  loading,
}) => {
  const getGraphOptions = () => {
    const options = getDefaultPriceDevelopmentChartOptions(priceDevelopment);
    const data: Array<number> = priceDevelopment ? priceDevelopment.priceData.map((pD) => pD.price) : [];
    const ser = [
      {
        name: "priceData",
        data: data,
      },
    ];
    options.colors = getGraphColors(priceDevelopment?.percentage);
    return { options, series: ser };
  };

  const { options, series } = getGraphOptions();

  return (
    <>
      {informationPosition === "top" && (
        <div className="fw-bolder mb-3 text-white text-center">
          Price Development
          {loading ? (
            <div className="text-center text-muted mt-2">Loading...</div>
          ) : priceDevelopment ? (
            <span
              className={
                "ml-2 text-" +
                (priceDevelopment.percentage > 1 ? "danger" : priceDevelopment.percentage < -1 ? "success" : "muted")
              }
              style={{ fontSize: 12 }}
            >
              <span>{priceDevelopment.percentage > 0 ? "+" : " "}</span>
              {priceDevelopment.percentage}%
            </span>
          ) : (
            <div className="text-center text-muted mt-2">N/A</div>
          )}
        </div>
      )}
      {price !== undefined &&
        (price > 0 && !isNaN(price) ? (
          <div className="fw-bolder text-white text-center">{formatCurrency(price, CUSTOMER_BASE_CURRENCY)}</div>
        ) : (
          <div className="fw-bolder text-muted text-center">Price on Request</div>
        ))}
      <div className="d-flex flex-wrap flex-center">
        {informationPosition === "left" &&
          (loading ? (
            <div className="text-center text-muted mt-2">Loading...</div>
          ) : (
            priceDevelopment && (
              <div
                className={
                  "mr-2 text-" +
                  (priceDevelopment.percentage > 1 ? "danger" : priceDevelopment.percentage < -1 ? "success" : "muted")
                }
              >
                <span className="fs-7">
                  <span>{priceDevelopment.percentage > 0 ? "+" : " "}</span>
                  {priceDevelopment.percentage}%
                </span>
              </div>
            )
          ))}
        <div className="d-inline-flex">
          <div id="chart">
            {!loading && series && priceDevelopment && (
              <ReactApexChart options={options} series={series} type="area" height={50} width={(width ?? 50) + "%"} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CommodityPagePriceDevelopment;
