import { BSON } from "realm-web";
import { CommoditySupplierSettingsUpdate } from "../components/common/CustomTypes";
import { COMMODITY, FINISHEDPRODUCT, SUPPLIER, UpdateAction } from "../services/dbService";

/**
 * Get list of actions for updating commodity or finished product supplier settings
 * @param articleId a commodity or finished product id
 * @param supplierId a supplier id
 * @param settings object with information about what settings should be updated
 * @param finishedProduct flag if commodityId is a finished product
 * @returns {Array<UpdateAction>} list of update actions
 */
export const getCommoditySupplierSettingsUpdateActions = (
  articleId: BSON.ObjectId | string,
  supplierId: BSON.ObjectId | string,
  settings: CommoditySupplierSettingsUpdate,
  finishedProduct: boolean
): Array<UpdateAction> => {
  const actions: Array<UpdateAction> = [];
  Object.entries(settings).forEach(([key, value]) => {
    switch (key) {
      case "contingent":
        actions.push({
          collection: finishedProduct ? FINISHEDPRODUCT : COMMODITY,
          filter: { _id: new BSON.ObjectId(articleId.toString()) },
          update: { "suppliers.$[s].contingent": value },
          arrayFilters: [{ "s.supplier": supplierId.toString() }],
        });
        break;
      case "packagingDimension": {
        const { added, removed }: { added: Array<string>; removed: Array<string> } = value;
        const arrayToChange = finishedProduct
          ? "packagingDimensions.$[pD].finishedProducts"
          : "packagingDimensions.$[pD].commodities";
        if (added.length > 0)
          actions.push({
            collection: SUPPLIER,
            filter: { _id: new BSON.ObjectId(supplierId) },
            push: { [arrayToChange]: articleId.toString() },
            arrayFilters: [{ "pD._id": { $in: added.map((p) => new BSON.ObjectId(p)) } }],
          });
        if (removed.length > 0)
          actions.push({
            collection: SUPPLIER,
            filter: { _id: new BSON.ObjectId(supplierId) },
            pull: { [arrayToChange]: articleId.toString() },
            arrayFilters: [{ "pD._id": { $in: removed.map((p) => new BSON.ObjectId(p)) } }],
          });
        break;
      }
      default:
        console.error("Unknown settings update.");
    }
  });
  return actions;
};
