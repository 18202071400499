import _ from "lodash";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../utils/baseUtils";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";
import { DataContextInternal } from "../../../../context/dataContext";
import { PropertyType, resolveProperties } from "../../../../utils/propertyUtils";
import PropertiesWidget from "../../../common/PropertiesWidget";
import { Commodity } from "../../../../model/commodity.types";
import { Input } from "../../../common/Input";
import { COMMODITYCREATIONKEYS, getDefaultCommodity, getSimilarArticles } from "../../../../utils/commodityUtils";
import { FinishedProduct } from "../../../../model/finishedProduct.types";
import { getDefaultFinishedProduct } from "../../../../utils/finishedProductUtils";
import { Property } from "../../../../model/property.types";

interface CreateCommodityModalProps extends RouteComponentProps {
  finishedProduct?: boolean;
}

interface CreateCommodityModalState {
  show: boolean;
  showSimilar: boolean;
  view: number;
  title: string;
  subtitle: string;
  category: SelectOption | undefined;
  composition: SelectOption | undefined;
  organic: boolean;
  similarArticles: Array<Commodity | FinishedProduct>;
}

class CreateCommodityModal extends PureComponent<CreateCommodityModalProps, CreateCommodityModalState> {
  static contextType = DataContextInternal;
  context!: React.ContextType<typeof DataContextInternal>;

  constructor(props: CreateCommodityModalProps) {
    super(props);
    this.state = {
      show: false,
      showSimilar: false,
      view: 0,
      title: "",
      subtitle: "",
      category: undefined,
      composition: undefined,
      organic: false,
      similarArticles: [],
    };
  }

  componentDidUpdate(prevProps: Readonly<CreateCommodityModalProps>, prevState: Readonly<CreateCommodityModalState>) {
    const { finishedProduct } = this.props;
    const { title, subtitle } = this.state;
    if (title !== prevState.title || subtitle !== prevState.subtitle) {
      let similarArticles;
      if (finishedProduct)
        similarArticles = getSimilarArticles(
          getDefaultFinishedProduct(title, subtitle),
          this.context.finishedProduct
        ) as Array<FinishedProduct>;
      else
        similarArticles = getSimilarArticles(
          getDefaultCommodity(title, subtitle),
          this.context.commodity
        ) as Array<Commodity>;
      this.setState({ similarArticles: similarArticles });
    }
  }

  isNextStepReady = () => {
    const { view, title, category, composition } = this.state;
    if (view === 0) return title.length > 3 && !!category && !!composition;
    else if (view === 1) return true;
    return false;
  };

  handleShow = () =>
    this.setState({
      show: true,
      showSimilar: false,
      view: 0,
      title: "",
      subtitle: "",
      category: undefined,
      composition: undefined,
      organic: false,
      similarArticles: [],
    });
  handleHide = () => this.setState({ show: false });

  handleNext = () => {
    const { view } = this.state;
    if (view < 2) this.setState({ view: view + 1 });
  };
  handleBack = () => {
    const { view, showSimilar } = this.state;
    if (showSimilar) this.setState({ view: 0, showSimilar: false });
    if (view > 0) this.setState({ view: view - 1 });
  };

  // @ts-ignore
  handleChangeProperty = (name: string, entry: SelectOption) => this.setState({ [name]: entry });

  handleCreateCommodity = () => {
    const { finishedProduct, history } = this.props;
    if (!this.isNextStepReady()) return;
    COMMODITYCREATIONKEYS.forEach((key) => {
      const value = _.get(this.state, key);
      localStorage.setItem(key, JSON.stringify(typeof value === "object" && "value" in value ? value.value : value));
    });
    history.push(`/${finishedProduct ? "finishedProduct" : "commodity"}?create=true`);
  };

  render() {
    const { finishedProduct } = this.props;
    const { show, showSimilar, view, title, subtitle, composition, category, similarArticles, organic } = this.state;
    const { property } = this.context;
    const nextStepDisabled = !this.isNextStepReady();
    const articleHeading = finishedProduct ? "Finished Product" : "Commodity";
    return (
      <>
        <button className="btn btn-outline btn-outline-light float-right" onClick={this.handleShow}>
          New {articleHeading}
        </button>
        <Modal
          contentClassName={"bg-dark"}
          show={show}
          size={view === 1 || showSimilar ? "lg" : undefined}
          onHide={this.handleHide}
          centered
        >
          <Modal.Header className="border-0 pb-0">
            <CloseButton variant={"white"} onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body className="mx-5 py-0">
            <div className="pb-5">
              <h1 className="fw-bolder d-flex align-items-center text-white">New {articleHeading}</h1>
              <div className="text-muted fw-bold fs-6">
                Create a {finishedProduct ? "finished product" : "commodity"} from scratch
              </div>
            </div>
            {view === 0 && !showSimilar && (
              <>
                {similarArticles.length > 0 && (
                  <div className="d-flex bg-danger rounded border-warning  p-5 my-5">
                    <span className="svg-icon svg-icon-2tx svg-icon-white me-4">
                      <SVG src={toAbsoluteUrl("/assets/media/svg/custom/warning-circle.svg")} />
                    </span>
                    <div className="d-flex flex-stack flex-grow-1">
                      <div className="fw-bolder">
                        <div className="fs-6 ">
                          {similarArticles.length} similar{" "}
                          {finishedProduct
                            ? similarArticles.length === 1
                              ? "finished product"
                              : "finished products"
                            : similarArticles.length === 1
                            ? "commodity"
                            : "commodities"}{" "}
                          already existing!
                          <br />
                          <u className="cursor-pointer" onClick={() => this.setState({ showSimilar: true })}>
                            Check now
                          </u>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row mb-5 ">
                  <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                    <label className="required fs-5 fw-bold mb-2">Title</label>
                    <Input
                      type="text"
                      className="form-control custom-form-control"
                      name="commodityTitle"
                      placeholder={`${articleHeading} Name`}
                      autoComplete="off"
                      value={title}
                      onBlur={(e) => this.setState({ title: e.target.value })}
                    />
                  </div>
                  <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                    <label className="fs-5 fw-bold mb-2">Subtitle</label>
                    <Input
                      type="text"
                      className="form-control custom-form-control"
                      name="commoditySubtitle"
                      placeholder=""
                      autoComplete="off"
                      value={subtitle}
                      onBlur={(e) => this.setState({ subtitle: e.target.value })}
                    />
                  </div>
                  <div className="col-md-6 mt-3 fv-row fv-plugins-icon-container">
                    <label className="required fs-5 fw-bold mb-2">Category</label>
                    <CustomSelect
                      options={property
                        .filter((p) => p.type === PropertyType.CATEGORY)
                        .map((p) => {
                          return { value: p._id.toString(), label: p.name.en };
                        })}
                      value={category}
                      onChange={(e: SelectOption) => this.handleChangeProperty("category", e)}
                    />
                  </div>
                  <div className="col-md-6 mt-3 fv-row fv-plugins-icon-container">
                    <label className="required fs-5 fw-bold mb-2">Composition</label>
                    <CustomSelect
                      options={property
                        .filter((p) => p.type === PropertyType.COMPOSITION)
                        .map((p) => {
                          return { value: p._id.toString(), label: p.name.en };
                        })}
                      value={composition}
                      onChange={(e: SelectOption) => this.handleChangeProperty("composition", e)}
                    />
                  </div>
                  <div className="col-12">
                    <div className="form-check form-switch form-check-custom form-check-solid mt-5">
                      <input
                        className="form-check-input position-static"
                        checked={organic}
                        onChange={() => this.setState({ organic: !organic })}
                        type="checkbox"
                      />
                      <label className="form-check-label fs-5 fw-bold">Organic</label>
                    </div>
                  </div>
                </div>
              </>
            )}
            {(view === 1 || showSimilar) && (
              <>
                <div
                  className={" d-flex rounded p-5 my-5 " + (similarArticles.length > 0 ? "bg-danger" : "bg-success")}
                >
                  <span className="svg-icon svg-icon-2tx svg-icon-white me-4">
                    <SVG src={toAbsoluteUrl("/assets/media/svg/custom/warning-circle.svg")} />
                  </span>
                  <div className="d-flex flex-stack flex-grow-1">
                    <div className="fw-bolder">
                      <div className="fs-6 ">
                        {similarArticles.length} similar{" "}
                        {finishedProduct
                          ? similarArticles.length === 1
                            ? "finished product"
                            : "finished products"
                          : similarArticles.length === 1
                          ? "commodity"
                          : "commodities"}{" "}
                        already existing!
                      </div>
                    </div>
                  </div>
                </div>
                {similarArticles.length > 0 && (
                  <div className="row mb-5 ">
                    <div className="col-md-12 fv-row fv-plugins-icon-container mt-5 table-responsive">
                      <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                        <thead>
                          <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                            <th>{articleHeading}</th>
                            <th>Category</th>
                            <th>Composition</th>
                          </tr>
                        </thead>
                        <tbody className="fw-bold text-gray-600">
                          {similarArticles.map((com) => {
                            const properties: Array<Property> = resolveProperties(
                              com.properties,
                              this.context.property
                            );
                            return (
                              <tr key={com._id.toString()}>
                                <td className="align-middle">
                                  <span className="text-white fs-5 mb-1 ">
                                    {com.title.en}
                                    {com && com.organic && <i title={"organic"} className="fas fa-leaf text-success" />}
                                  </span>
                                  <div>{com && <div className="text-muted">{com.subtitle.en}</div>}</div>
                                </td>
                                <td className="align-middle">
                                  <PropertiesWidget properties={properties} types={[PropertyType.CATEGORY]} />
                                </td>
                                <td className="align-middle">
                                  <PropertiesWidget properties={properties} types={[PropertyType.COMPOSITION]} />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </>
            )}
            <div className="border-bottom-dark-gray" />
          </Modal.Body>
          <Modal.Footer className="mx-5">
            <button className="btn  btn-text-danger btn-sm " onClick={this.handleHide}>
              Cancel
            </button>
            {(view > 0 || showSimilar) && (
              <button type="button" className="btn btn-outline btn-outline-light btn-sm" onClick={this.handleBack}>
                Back
              </button>
            )}
            {view < 1 && !showSimilar ? (
              <button
                type="button"
                disabled={nextStepDisabled}
                className={"btn btn-outline btn-outline-light btn-sm " + (nextStepDisabled ? "disabled" : "")}
                onClick={this.handleNext}
              >
                Next
              </button>
            ) : (
              view === 1 &&
              !showSimilar && (
                <button
                  type="button"
                  disabled={nextStepDisabled}
                  className={"btn btn-outline btn-outline-light btn-sm " + (nextStepDisabled ? "disabled" : "")}
                  onClick={this.handleCreateCommodity}
                >
                  Create {articleHeading}
                </button>
              )
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
export default withRouter(CreateCommodityModal);
