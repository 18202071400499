import _ from "lodash";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { General } from "../../../../model/general.types";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import { Input } from "../../../common/Input";
import { EditableValue, insertGeneral, updateGeneral } from "../../../../utils/generalUtils";

interface AddNewValueModalProps {
  generalCollection: Array<General>;
  general?: General;
}

interface AddNewValueModalState {
  show: boolean;
  key: string;
  value: string | number;
  mode: SelectOption;
  date: string;
  saving: boolean;
}

class AddNewValueModal extends PureComponent<AddNewValueModalProps, AddNewValueModalState> {
  constructor(props: AddNewValueModalProps) {
    super(props);
    this.state = this.getDefaultState(false);
  }

  handleShow = () => this.setState(this.getDefaultState(true));
  handleHide = () => this.setState(this.getDefaultState(false));

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  handleModeChange = (mode: SelectOption) => this.setState({ mode });

  handleCreateGeneral = async () => {
    const { general } = this.props;
    const { key, value, date, mode } = this.state;
    try {
      this.setState({ saving: true });
      let res, success;
      if (general) {
        general.value = value;
        general.date = date;
        general.lastUpdate = new Date();
        res = await updateGeneral(general);
        success = res && res.modifiedCount > 0;
      } else {
        res = await insertGeneral({
          _id: new BSON.ObjectId(),
          key,
          value: mode.value === "number" ? Number(value) : value,
          date,
          lastUpdate: new Date(),
        });
        success = res && res.insertedId;
      }
      if (success) {
        toast.success("General value successfully " + (general ? "updated" : "inserted"));
      } else {
        toast.error("Error " + (general ? "updating" : "inserting") + " general value.");
      }
    } finally {
      this.setState({ saving: false });
    }
    this.handleHide();
  };

  getDefaultState = (show: boolean) => {
    const { general } = this.props;
    return {
      show,
      key: general?.key ?? "",
      value: (["string", "number"].includes(typeof general?.value) ? general?.value : "") as string | number,
      mode:
        typeof general?.value === "number"
          ? { value: "number", label: "Number" }
          : { value: "string", label: "String" },
      date: general?.date ?? "",
      saving: false,
    };
  };

  validateInput = () => {
    const { general, generalCollection } = this.props;
    const { key, value } = this.state;
    const errors = [];
    if (!key.trim()) errors.push("Key can't be empty");
    if (!value.toString().trim()) errors.push("Value can't be empty");
    if (!general && generalCollection.some((g) => g.key === key.trim())) errors.push("Key already exists");
    return errors;
  };

  render() {
    const { general } = this.props;
    const { show, key, value, date, mode, saving } = this.state;
    const errors = this.validateInput();

    return (
      <>
        {general ? (
          <ErrorOverlayButton
            errors={
              !_.includes(Object.values(EditableValue), general.key)
                ? ["This value is not editable since it is an automatically managed value."]
                : []
            }
            className="btn btn-sm btn-outline btn-outline-light"
            buttonText="Edit"
            onClick={this.handleShow}
          />
        ) : (
          <button className="btn btn-sm btn-outline btn-outline-light" onClick={this.handleShow}>
            Add Value
          </button>
        )}
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">
                {general ? "Edit" : "New"} general value
              </h1>
            </Modal.Title>
            <CloseButton variant={"white"} onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-3">
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="required fs-5 fw-bold mb-2">Key</label>
                <Input
                  type="text"
                  className="form-control custom-form-control"
                  name="key"
                  value={key}
                  disabled={!!general}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="required fs-5 fw-bold mb-2">Value</label>
                <div className="input-group">
                  <Input
                    type={mode.value === "string" ? "text" : "number"}
                    className="form-control custom-form-control"
                    name="value"
                    value={value}
                    disabled={saving}
                    onChange={this.handleInputChange}
                  />
                  <div className="input-group-append ml-1">
                    <CustomSelect
                      options={[
                        { value: "string", label: "String" },
                        { value: "number", label: "Number" },
                      ]}
                      onChange={this.handleModeChange}
                      matchFormControl={true}
                      value={mode}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="required fs-5 fw-bold mb-2">Date (Optional)</label>
                <Input
                  type="text"
                  className="form-control custom-form-control"
                  name="date"
                  value={date}
                  disabled={saving}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-outline btn-text-danger" onClick={this.handleHide}>
              Cancel
            </button>
            <ErrorOverlayButton
              errors={errors}
              className={"btn btn-sm btn-outline btn-outline-light " + (errors.length > 0 || saving ? "disabled" : "")}
              buttonText={saving ? "Saving..." : general ? "Confirm" : "Create"}
              onClick={this.handleCreateGeneral}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default AddNewValueModal;
