import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContextInternal } from "../../../context/dataContext";
import SupplierPage from "./SupplierPage";

interface SupplierWrapperProps extends RouteComponentProps<SupplierParams> {}

interface SupplierParams {
  id: string;
}

const SupplierWrapper: React.FunctionComponent<SupplierWrapperProps> = (props) => {
  const context = useContext(DataContextInternal);
  return <SupplierPage context={context} {...props} />;
};
export default SupplierWrapper;
