import { BSON } from "realm-web";
import { CO_TYPES } from "./customerOrder.types";

export enum SN_TYPE {
  SN_T_ORDERMASK = "snOrderMask",
}

export interface SystemNotificationConditions {
  commodities?: Array<string>; // Limit the notification to specific commodities
  origins?: Array<string>; // Limit the notification to specific origin countries
  transport?: Array<CO_TYPES>; // Limit the notification to specific transport types
}

export interface SystemNotification {
  _id: BSON.ObjectId;
  type: SN_TYPE;
  validUntil: Date;
  message: string;
  condition: SystemNotificationConditions;
}
