import _ from "lodash";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { resolveFilePath, shortenAlias, uploadAndGetArticleFileObject } from "../../../../utils/fileUtils";
import SimpleConfirmationModal from "../../../common/SimpleConfirmationModal";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import { GroupedCustomerOrderService, OrderFile } from "../../../../model/commonTypes";
import { D_SERVICERESULT } from "../../../../utils/serviceUtils";
import { updateServiceOnMultipleCustomerOrders } from "../../../../utils/customerOrderUtils";
import { DataContextInternalType } from "../../../../context/dataContext";
import { CustomerOrderExtended, CustomerOrderService } from "../../../../model/customerOrder.types";

interface ServicesTaskProps {
  order?: CustomerOrderExtended;
  service: GroupedCustomerOrderService | CustomerOrderService; // CustomerOrderService only together with order, a single customer order
  editable: boolean;
  context: DataContextInternalType;
}

interface ServicesTaskState {
  saving: boolean;
  fileToDelete?: OrderFile;
}

class ServicesTask extends PureComponent<ServicesTaskProps, ServicesTaskState> {
  selectFileRef: React.RefObject<HTMLInputElement>;

  constructor(props: ServicesTaskProps) {
    super(props);
    this.selectFileRef = React.createRef();
    this.state = { saving: false };
  }

  handleUploadServiceFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    this.setState({ saving: true });
    const service = _.cloneDeep(this.props.service);
    const uploadedFile = uploadAndGetArticleFileObject(e.target.files[0], "ServiceResult", D_SERVICERESULT);
    if (!uploadedFile) {
      this.setState({ saving: false });
      return;
    }

    service.files.push({
      _id: new BSON.ObjectId(),
      path: uploadedFile.path,
      date: uploadedFile.date,
      type: uploadedFile.type,
    });
    await this.performUpdateAndRefreshData(service, "File successfully uploaded", "Error uploading file");
  };

  handleClickPerformed = async () => {
    this.setState({ saving: true });
    const service = _.cloneDeep(this.props.service);
    service.performed = true;
    await this.performUpdateAndRefreshData(service, "Service successfully performed", "Error performing service");
  };

  handleRemoveFile = async () => {
    const { fileToDelete } = this.state;
    if (!fileToDelete) return;
    this.setState({ saving: true });
    const service = _.cloneDeep(this.props.service);
    service.files = service.files.filter((f) => f._id.toString() !== fileToDelete._id.toString());
    await this.performUpdateAndRefreshData(service, "File successfully removed", "Error removing file");
  };

  handleCloseRemoveModal = () => this.setState({ fileToDelete: undefined });

  /**
   * Wrapper for performing the updates on the service.
   * @param service Updated service
   * @param successText Toast text that should be shown on success
   * @param errorText Toast text that should be shown on error
   */
  performUpdateAndRefreshData = async (
    service: GroupedCustomerOrderService | CustomerOrderService,
    successText: string,
    errorText: string
  ) => {
    const { order } = this.props;
    const serviceCO = _.omit(service, ["customerOrders"]) as CustomerOrderService;
    const cOs = order ? [order._id] : "customerOrders" in service ? service.customerOrders.map((cO) => cO._id) : [];
    if (cOs.length === 0) toast.warning("No customer orders to update");
    const res = await updateServiceOnMultipleCustomerOrders(serviceCO, cOs);
    try {
      if (res) {
        toast.success(successText);
      } else {
        toast.error(errorText);
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  render() {
    const { service, editable } = this.props;
    const { fileToDelete, saving } = this.state;
    return (
      <div className="py-2">
        <div className="bg-light2 rounded p-5 mb-7">
          <div className="d-flex align-items-center">
            <div className="flex-grow-1 me-2">
              <span className="fw-bolder text-gray-800 fs-6 flex-center flex-stack">
                RBS-{service.service.serviceNo} {service.service.title.en}
              </span>
              <span className="text-muted fw-bold d-block">{service.service.description.en}</span>
            </div>
            <div className="text-right">
              <div className="fw-bold fs-6 text-gray-400">{service.service.duration} day(s) duration</div>
              {"customerOrders" in service && (
                <span className="text-muted mb-1">for {service.customerOrders.length} order(s)</span>
              )}
            </div>
          </div>
          <div className="mt-5">
            <div className="fw-bolder text-white fs-5 mt-4">Service Documents</div>
            <div>
              {service.files.length > 0 ? (
                service.files.map((d) => (
                  <React.Fragment key={d._id.toString()}>
                    <a
                      className="text-white fw-bold ml-3"
                      href={resolveFilePath(d.path)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {shortenAlias(d.path)}
                    </a>
                    {!service.performed && (
                      <>
                        <button
                          className={"btn btn-text text-danger btn-sm p-2 " + (!editable && "disabled")}
                          disabled={!editable}
                          onClick={!editable ? undefined : () => this.setState({ fileToDelete: d })}
                        >
                          x
                        </button>
                        <SimpleConfirmationModal.SimpleConfirmationModal
                          size="md"
                          show={!!fileToDelete}
                          onConfirm={this.handleRemoveFile}
                          onClose={this.handleCloseRemoveModal}
                          confirmButtonText="Remove File"
                          cancelButtonText="Cancel"
                          modalDescription={
                            <p className="text-white">
                              Are you sure you want to remove file:{" "}
                              <a
                                className="custom-link text-white"
                                href={fileToDelete && resolveFilePath(fileToDelete.path)}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {fileToDelete?.path}
                              </a>
                              ?
                            </p>
                          }
                          modalTitle={"Remove File"}
                        />
                      </>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <span className="text-muted fw-bold ml-3">No documents uploaded</span>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-right">
              {service.performed ? (
                <i className="h2 fas fa-check-circle text-success mr-1" />
              ) : (
                <>
                  <button
                    className="btn btn-light custom-form-control-user btn-sm mr-2"
                    onClick={() => this.selectFileRef.current?.click()}
                  >
                    Upload File
                  </button>
                  <input
                    type="file"
                    ref={this.selectFileRef}
                    accept="*"
                    style={{ display: "none" }}
                    disabled={saving || !editable}
                    onChange={saving || !editable ? undefined : (e) => this.handleUploadServiceFile(e)}
                  />
                  <ErrorOverlayButton
                    className="btn btn-success btn-sm"
                    errors={
                      service.files.length === 0
                        ? ["Please upload a document that proves that the service was performed"]
                        : []
                    }
                    buttonText="Performed"
                    onClick={this.handleClickPerformed}
                    disabled={!editable}
                    saving={saving}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServicesTask;
