import _ from "lodash";
import React, { useContext, useMemo } from "react";
import { DataContextInternal } from "../../../../context/dataContext";
import { DeliveryTime, getDeliveryTimeDisplayText } from "../../../../utils/settingsUtils";
import BaseListing, { BaseListingHeaderDefinition } from "../../../common/BaseListing";
import { formatDate, pluralize } from "../../../../utils/baseUtils";
import EditDeliveryTimeSettingModal from "./EditDeliveryTimeSettingModal";
import userService from "../../../../services/userService";
import { SCM } from "../../../../utils/userUtils";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";

const headerDefinition: Array<BaseListingHeaderDefinition> = [
  { title: "Key", style: { width: "45%" } },
  { title: "Value", style: { width: "20%" } },
  { title: "Last Update", style: { width: "15%" } },
  { title: "", style: { width: "20%" } },
];

const DeliveryTimeSetting: React.FC = () => {
  const context = useContext(DataContextInternal);

  const deliveryTimeValues = useMemo(
    () => context.general.filter((g) => _.includes(Object.values(DeliveryTime), g.key)),
    [context.general]
  );

  const isSCMorAdmin = useMemo(() => userService.isAdmin() || userService.getRoles().includes(SCM), []);

  return (
    <div className="content d-flex flex-column flex-column-fluid">
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl">
          <div className="card bg-white">
            <div className="card-body">
              <h3 className="card-title align-items-start flex-column mb-15">
                <span className="card-label fw-bolder mb-3 fs-3rem">Delivery Time Configuration</span>
              </h3>
              <BaseListing
                headerDefinition={headerDefinition}
                bodyContent={
                  <>
                    {deliveryTimeValues.map((v) => (
                      <tr key={v._id.toString()}>
                        <td className="text-white align-middle">{getDeliveryTimeDisplayText(v.key)}</td>
                        <td className="text-white align-middle">{pluralize(Number(v.value), "day")}</td>
                        <td className="text-white align-middle">{v.lastUpdate ? formatDate(v.lastUpdate) : "-"}</td>
                        <td className="text-white align-middle text-right">
                          {isSCMorAdmin ? (
                            <EditDeliveryTimeSettingModal setting={v} />
                          ) : (
                            <ErrorOverlayButton
                              errors={["Only SCM and Admin Users can edit this value"]}
                              className="btn btn-sm btn-outline btn-outline-light"
                              onClick={undefined}
                              buttonText="Edit"
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  </>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryTimeSetting;
