import React from "react";
import { Link } from "react-router-dom";
import NewsTags from "../news/common/NewsTags";
import { Article } from "../../model/article.types";
import { formatDate, getDocFromCollection, toAbsoluteUrl, truncateString } from "../../utils/baseUtils";
import { DataContextAnonymousType, DataContextCustomerType } from "../../context/dataContext";
import { getUserName } from "../../utils/userUtils";
import { resolveFilePath } from "../../utils/fileUtils";

interface NewsEntryProps {
  article: Article;
  size?: "sm" | "md" | "lg";
  hover?: boolean;
  /** Needed in case the entry is wrapped with an anchor since we don't want to nest anchors **/
  noTitleLink?: boolean;
  context: DataContextCustomerType | DataContextAnonymousType;
}

const NewsPreview: React.FunctionComponent<NewsEntryProps> = ({ article, context, hover, size, noTitleLink }) => {
  const author = getDocFromCollection(context.userData, article.createdBy);
  const fallBackImage = toAbsoluteUrl("/assets/media/newsPlaceholder.png");
  const imagePath = article.imagePath.trim() !== "" ? resolveFilePath(article.imagePath, false) : fallBackImage;

  const ArticleFooter = (
    <>
      <NewsTags tags={article.tags} className="mt-2" />
      <div className="fs-6 fw-bold mt-3 d-flex flex-stack">
        <span>
          <b>{author ? getUserName(author) : "RAWBIDS GmbH"}</b>
          <span className="text-muted ml-2">{formatDate(article.creationTime)}</span>
        </span>
        <Link to={`/article/${article._id.toString()}`} className="btn btn-sm btn-light">
          Read Full Article
        </Link>
      </div>
    </>
  );

  if (size === "sm")
    return (
      <div className={"bg-light my-3 p-4 " + (!hover && "no-hover")}>
        <div className="d-flex align-items-center ">
          <div className="symbol symbol-75px mr-4">
            <img
              style={{ objectFit: "cover" }}
              src={imagePath}
              onError={(e) => {
                if ("src" in e.target) (e.target as HTMLImageElement).src = fallBackImage;
              }}
              alt="Article Image"
            />
          </div>
          <div className="flex-grow-1">
            <div className="flex-grow-1">
              {noTitleLink ? (
                <span className={"fs-6 fw-bold lh-base " + (!hover && "custom-link")}>{article.title}</span>
              ) : (
                <Link
                  to={`/article/${article._id.toString()}`}
                  className={"fs-6 fw-bold lh-base " + (!hover && "custom-link")}
                >
                  {article.title}
                </Link>
              )}
              <div className="fw-semibold text-muted " style={{ whiteSpace: "pre-wrap" }}>
                {truncateString(article.newsText, 170).replaceAll("\n", " ")}
              </div>
              <NewsTags tags={article.tags} tagClassName="badge-light-gray" />
            </div>
          </div>
        </div>
      </div>
    );
  if (size === "lg")
    return (
      <>
        <div className="col-4 p-5 mb-10">
          <div
            className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-250px mr-4"
            style={{ backgroundImage: `url("${imagePath}"), url("${fallBackImage}")` }}
          />
        </div>
        <div className="mt-5 col-8 p-5 mb-10">
          {noTitleLink ? (
            <span className={"fs-4 fw-bold lh-base " + (!hover && "custom-link")}>{article.title}</span>
          ) : (
            <Link to={`/article/${article._id.toString()}`} className="fs-4 fw-bold custom-link lh-base">
              {article.title}
            </Link>
          )}
          <div className="fw-semibold fs-6 text-muted mt-3" style={{ whiteSpace: "pre-wrap" }}>
            {truncateString(article.newsText, 550).replaceAll("\n", " ")}
          </div>
          {ArticleFooter}
        </div>
      </>
    );
  return (
    <div className="col-md-4 p-5 py-10">
      <div className="me-md-6">
        <div
          className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px"
          style={{ backgroundImage: `url("${imagePath}"), url("${fallBackImage}")` }}
        />
        <div className="mt-5">
          {noTitleLink ? (
            <span className={"fs-4 fw-bold lh-base " + (!hover && "custom-link")}>{article.title}</span>
          ) : (
            <Link to={`/article/${article._id.toString()}`} className="fs-4 fw-bold custom-link lh-base">
              {article.title}
            </Link>
          )}
          <div className="fw-semibold fs-6 text-muted text-gray-900 mt-3" style={{ whiteSpace: "pre-wrap" }}>
            {truncateString(article.newsText, 300).replaceAll("\n", " ")}
          </div>
          {ArticleFooter}
        </div>
      </div>
    </div>
  );
};

export default NewsPreview;
