import React from "react";
import { formatUnit } from "../../../utils/baseUtils";

interface CustomerOrderFileUploadProps {
  files?: Array<File>;
  onSelectFiles: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDeselectFiles: () => void;
}

const CustomerOrderFileUpload: React.FunctionComponent<CustomerOrderFileUploadProps> = ({
  files,
  onSelectFiles,
  onDeselectFiles,
}) => {
  const file = files && files.length > 0 ? files[0] : undefined;
  const selectFileRef = React.useRef<HTMLInputElement>(null);

  const handleOpenDialog = () => selectFileRef && selectFileRef.current?.click();

  return (
    <div className="text-right">
      {file && (
        <div className="d-inline mr-3">
          <span className="text-white align-middle">{file.name}</span>
          <small className="text-muted ml-2">{formatUnit(file.size / 1000, "KB")}</small>
        </div>
      )}
      <input type="file" ref={selectFileRef} accept="*" className="d-none" onChange={onSelectFiles} />
      <button className="btn btn-light btn-sm d-inline" onClick={file ? onDeselectFiles : handleOpenDialog}>
        {file ? "Remove File" : "Upload File"}
      </button>
    </div>
  );
};

export default CustomerOrderFileUpload;
