/**
 * Get short text for commodity statistics
 * @param rating a rating, i.e. -1, 0 or 1 or undefined
 * @param alternateText optional, flag to use an alternate text
 * @returns {string} title for commodity statistics
 */
export const getStatisticText = (rating?: -1 | 0 | 1, alternateText?: boolean): string => {
  switch (rating) {
    case -1:
      return alternateText ? "Low" : "Bad";
    case 0:
      return alternateText ? "Medium" : "Okay";
    case 1:
      return alternateText ? "High" : "Good";
    default:
      return "TBD";
  }
};

/**
 * Get color for a statistic rating
 * @param rating the rating, -1, 0 or 1 with 1 being good and -1 being bad
 * @returns {string} bootstrap color string, e.g., success, danger, ...
 */
export const getRatingColor = (rating?: -1 | 0 | 1): string => {
  switch (rating) {
    case -1:
      return "danger";
    case 0:
      return "warning";
    case 1:
      return "success";
    default:
      return "light";
  }
};
