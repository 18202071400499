import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ErrorOverlayButton from "../common/ErrorOverlayButton";
import userService from "../../services/userService";

interface ToolProps extends RouteComponentProps {
  type:
    | "commodityParsingTool"
    | "supplierParsingTool"
    | "customerParsingTool"
    | "seaportParsingTool"
    | "masterSpecGenerationTool"
    | "priceCalculator";
  disabled?: boolean;
  requiredRoles?: Array<string>;
}

class Tool extends PureComponent<ToolProps> {
  resolveTitle = () => {
    switch (this.props.type) {
      case "commodityParsingTool":
        return "Commodity Parsing Tool";
      case "customerParsingTool":
        return "Customer Parsing Tool";
      case "supplierParsingTool":
        return "Supplier Parsing Tool";
      case "seaportParsingTool":
        return "Seaport Parsing Tool";
      case "masterSpecGenerationTool":
        return "Master Specification Generation Tool";
      case "priceCalculator":
        return "Price Calculator";
    }
  };

  resolveDescriptions = () => {
    switch (this.props.type) {
      case "commodityParsingTool":
        return "Parses a CSV that contains and adds all commodities that can be parsed correctly to the database.";
      case "customerParsingTool":
        return "Parses a CSV that contains and adds all customers that can be parsed correctly to the database.";
      case "supplierParsingTool":
        return "Parses a CSV that contains and adds all suppliers that can be parsed correctly to the database.";
      case "seaportParsingTool":
        return "Parses a CSV that contains and adds all seaports that can be parsed correctly to the database.";
      case "masterSpecGenerationTool":
        return "Generates the master specification with signatures of originator and approver for all commodities.";
      case "priceCalculator":
        return "Calculate the DDP price based on the FOB price or the FOB price based on the DDP price.";
    }
  };

  render() {
    const { history, type, disabled, requiredRoles } = this.props;
    const toolDisabled = disabled || (requiredRoles && !userService.getRoles().some((r) => requiredRoles.includes(r)));

    return (
      <div className="card card-view card-container">
        <div className="card-body">
          <h1>{this.resolveTitle()}</h1>
          <p className="fs-5 text-white">{this.resolveDescriptions()}</p>
          <ErrorOverlayButton
            errors={toolDisabled ? ["Additional privileges required."] : []}
            className={"btn btn-outline btn-outline-light float-right"}
            buttonText={"Start"}
            onClick={() => history.push("/" + type)}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(Tool);
