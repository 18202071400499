import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import { DataContextSupplierType } from "../../../context/dataContext";
import { Commodity } from "../../../model/commodity.types";
import { getDocFromCollection } from "../../../utils/baseUtils";
import { COMMODITY, FINISHEDPRODUCT, getDocumentDB } from "../../../services/dbService";
import CommodityPageSummary from "./CommodityPageSummary";
import CommodityPageTabPanel from "./CommodityPageTabPanel";
import { FinishedProduct } from "../../../model/finishedProduct.types";
import { SupplierArticleExtended } from "../../../utils/productArticleUtils";

import { extendSupplierCommodity, extendSupplierFinishedProduct } from "../../../utils/dataTransformationUtils";

interface CommodityParams {
  id: string;
}

interface CommodityProps extends RouteComponentProps<CommodityParams> {
  context: DataContextSupplierType;
  isFP?: boolean;
}

interface CommodityState {
  article?: SupplierArticleExtended;
}

class CommodityPage extends PureComponent<CommodityProps, CommodityState> {
  _isMounted = false;
  constructor(props: CommodityProps) {
    super(props);
    this.state = {
      article: this.getArticle(props),
    };
  }

  componentDidMount = async () => {
    const { match, history, context, isFP } = this.props;
    if (this.state.article) return;
    const id = match.params.id;
    if (!id || !BSON.ObjectId.isValid(id)) {
      history.push("/articles");
      return;
    }
    this._isMounted = true;
    let article: Commodity | FinishedProduct | undefined;
    let articleExtended: SupplierArticleExtended | undefined;
    if (isFP) {
      article = await getDocumentDB<FinishedProduct>(FINISHEDPRODUCT, id);
      if (article) articleExtended = extendSupplierFinishedProduct(article, context);
    } else {
      article = await getDocumentDB<Commodity>(COMMODITY, id);
      if (article) articleExtended = extendSupplierCommodity(article, context);
    }
    if (!article) {
      console.error("No commodity could be loaded for id", id);
      toast.error("The requested commodity could not be loaded");
      history.push("/articles");
      return;
    }
    context.addDocuments(isFP ? FINISHEDPRODUCT : COMMODITY, [article]);
    if (this._isMounted) this.setState({ article: articleExtended });
  };

  componentDidUpdate(prevProps: Readonly<CommodityProps>) {
    const { match } = this.props;
    if (match.params.id || (!match.params.id && prevProps.match.params.id)) {
      const article = this.getArticle(this.props);
      if (!_.isEqual(article, this.state.article)) {
        this.setState({
          article,
        });
      }
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  /**
   * Get the initial commodity data
   * @param props Commodity properties
   * @returns {SupplierArticleExtended | undefined} a commodity object or undefined
   */
  getArticle = (props: CommodityProps): SupplierArticleExtended | undefined => {
    if (props.isFP) {
      const finishedProduct = getDocFromCollection(props.context.finishedProduct, props.match.params.id);
      if (finishedProduct) return extendSupplierFinishedProduct(finishedProduct, props.context);
    } else {
      const commodity = getDocFromCollection(props.context.commodity, props.match.params.id);
      if (commodity) return extendSupplierCommodity(commodity, props.context);
    }
  };

  render() {
    const { context } = this.props;
    const { article } = this.state;
    if (!article) return null;
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="d-flex flex-column flex-xl-row">
              <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
                <CommodityPageSummary article={article} context={context} />
                {/*QuickActions temporarily disabled*/}
                {/*<CommodityPageQuickActions commodity={commodity} context={context} />*/}
              </div>
              <CommodityPageTabPanel article={article} context={context} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CommodityPage;
