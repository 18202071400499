import React, { Component } from "react";
import PropertyTag from "./PropertyTag";
import { Property } from "../../model/property.types";
import Search from "./Search";
import { doFuseSearch } from "../../utils/baseUtils";

interface PropertiesSelectionProps {
  selectedProperties: Array<Property>;
  searchPlaceholder?: string;
  properties: Array<Property>;
  types: Array<string>;
  onSelectProperty: (property: Property) => void;
  onRemoveProperty: (property: Property) => void;
}

interface PropertiesSelectionState {
  search: string;
}

class PropertiesSelection extends Component<PropertiesSelectionProps, PropertiesSelectionState> {
  constructor(props: PropertiesSelectionProps) {
    super(props);
    this.state = {
      search: "",
    };
  }

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });

  render() {
    const { properties, types, selectedProperties, searchPlaceholder, onRemoveProperty, onSelectProperty } = this.props;
    const { search } = this.state;
    const displayedSelected = selectedProperties.filter((prop) => types.includes(prop.type));
    let displayedProperties = properties.filter(
      (p) => !p.disabled && !selectedProperties.some((p2) => p._id.toString() === p2._id.toString())
    );
    displayedProperties =
      types.length === 0 ? displayedProperties : displayedProperties.filter((prop) => types.includes(prop.type));
    if (search.trim())
      displayedProperties = doFuseSearch(displayedProperties, search, ["name.en", "description.en", "type"]);
    return (
      <>
        <div className="d-flex my-5">
          <Search
            placeholder={searchPlaceholder ?? "Search for properties..."}
            onSearch={this.handleSearch}
            value={search}
          />
        </div>
        <div
          className="tagify form-control overflow-auto p-0 border-none"
          style={{ backgroundColor: "rgba(0,0,0,0)", height: 250 }}
        >
          {displayedSelected.map((prop) => (
            <PropertyTag
              selected={true}
              highlightSelected={true}
              key={"s_" + prop._id.toString()}
              text={prop.name.en}
              onRemove={() => onRemoveProperty(prop)}
            />
          ))}
          {displayedProperties.map((prop) => (
            <PropertyTag key={"n_" + prop._id.toString()} text={prop.name.en} onSelect={() => onSelectProperty(prop)} />
          ))}
        </div>
      </>
    );
  }
}

export default PropertiesSelection;
