import _ from "lodash";
import validator from "validator";
import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CloseButton, Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toast } from "react-toastify";
import { DataContextInternal } from "../../../../context/dataContext";
import { getDocFromCollection, toAbsoluteUrl } from "../../../../utils/baseUtils";
import { Input } from "../../../common/Input";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import { getDefaultUser, getUserName } from "../../../../utils/userUtils";
import { UserStateData } from "../../../common/CustomTypes";
import UserCreation from "../../../common/UserCreation";
import { formatAddress, getDefaultAddress, isAddressTooShort } from "../../../../utils/addressUtils";
import { Address, AddressType } from "../../../../model/commonTypes";
import { SelectOption } from "../../../common/CustomSelect";
import AddressInputGroup from "../../../common/AddressInputGroup";
import { Forwarder } from "../../../../model/forwarder.types";
import { FORWARDER } from "../../../../services/dbService";
import { getDefaultForwarder, getSimilarForwarders, insertForwarder } from "../../../../utils/forwarderUtils";

interface CreateForwarderModalProps extends RouteComponentProps {}

interface CreateForwarderModalState {
  show: boolean;
  showSimilar: boolean;
  view: number;
  name: string;
  mail: string;
  phone: string;
  address: Address;
  countries?: Array<SelectOption>;
  primaryPerson: UserStateData;
  additionalPersons: Array<UserStateData>;
  similarForwarders: Array<Forwarder>;
}

class CreateForwarderModal extends PureComponent<CreateForwarderModalProps, CreateForwarderModalState> {
  static contextType = DataContextInternal;
  context!: React.ContextType<typeof DataContextInternal>;

  constructor(props: CreateForwarderModalProps) {
    super(props);
    this.state = {
      show: false,
      showSimilar: false,
      view: 0,
      name: "",
      mail: "",
      phone: "",
      address: getDefaultAddress("DE", AddressType.A_PRIMARY),
      primaryPerson: { id: new BSON.ObjectId(), prename: "", surname: "", mail: "", phone: "", position: "" },
      additionalPersons: [],
      similarForwarders: [],
    };
  }

  componentDidUpdate(prevProps: Readonly<CreateForwarderModalProps>, prevState: Readonly<CreateForwarderModalState>) {
    const { name } = this.state;
    if (name !== prevState.name) {
      this.setState({ similarForwarders: getSimilarForwarders(getDefaultForwarder(name), this.context.forwarder) });
    }
  }

  handleShow = () =>
    this.setState({
      show: true,
      showSimilar: false,
      view: 0,
      name: "",
      mail: "",
      phone: "",
      address: getDefaultAddress("DE", AddressType.A_PRIMARY),
      primaryPerson: { id: new BSON.ObjectId(), prename: "", surname: "", mail: "", phone: "", position: "" },
      additionalPersons: [],
      similarForwarders: [],
    });

  handleHide = () => this.setState({ show: false });
  handleShowSimilar = () => this.setState({ showSimilar: true });

  handleNext = () => {
    const { view } = this.state;
    if (view < 2) this.setState({ view: view + 1 });
  };

  handleBack = () => {
    const { view, showSimilar } = this.state;
    if (showSimilar) this.setState({ view: 0, showSimilar: false });
    if (view > 0) this.setState({ view: view - 1 });
  };

  handleChangeSelect = (name: string, entry: SelectOption) => {
    if (name === "country") {
      const address = _.cloneDeep(this.state.address);
      address.country = entry.value;
      this.setState({ address });
    }
    // @ts-ignore
    this.setState({ [name]: entry });
  };

  handleChangeInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAddPerson = () => {
    const additionalPersons = _.cloneDeep(this.state.additionalPersons);
    additionalPersons.push({ id: new BSON.ObjectId(), prename: "", surname: "", mail: "", phone: "", position: "" });
    this.setState({ additionalPersons });
  };

  handleRemovePerson = (index: number) => {
    const additionalPersons = _.cloneDeep(this.state.additionalPersons);
    additionalPersons.splice(index, 1);
    this.setState({ additionalPersons });
  };

  handlePersonChange = (e: React.ChangeEvent<HTMLInputElement>, index?: number) => {
    if (index !== undefined) {
      const additionalPersons = _.cloneDeep(this.state.additionalPersons);
      const person = additionalPersons[index];
      _.set(person, e.target.name, e.target.value);
      this.setState({ additionalPersons });
    } else {
      const primaryPerson = _.cloneDeep(this.state.primaryPerson);
      _.set(primaryPerson, e.target.name, e.target.value);
      this.setState({ primaryPerson });
    }
  };

  handleChangeAddressValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const address = _.cloneDeep(this.state.address);
    // @ts-ignore
    address[e.target.name as keyof Address] = e.target.value;
    this.setState({ address });
  };

  handleChangeAddressValueType = (e: SelectOption) => {
    const address = _.cloneDeep(this.state.address);
    address.type = e.value as AddressType;
    this.setState({ address });
  };

  handleCreateForwarderAndUsers = async () => {
    const { name, mail, phone, address, primaryPerson, additionalPersons } = this.state;
    const forwarder = getDefaultForwarder(name, address, mail, phone);
    const newPersons = additionalPersons.map((p) =>
      getDefaultUser(p.prename, p.surname, p.mail, forwarder._id, FORWARDER, p.phone.trim(), p.position)
    );
    forwarder.persons = newPersons.map((p) => p._id.toString());
    const primary = getDefaultUser(
      primaryPerson.prename,
      primaryPerson.surname,
      primaryPerson.mail,
      forwarder._id,
      FORWARDER,
      primaryPerson.phone.trim(),
      primaryPerson.position
    );
    forwarder.primaryPerson = primary._id.toString();
    // primary person is contact person for each address as default
    forwarder.address = forwarder.address.map((address) => ({
      ...address,
      contactPerson: primary._id.toString(),
    }));
    newPersons.push(primary);
    const result = await insertForwarder(forwarder, newPersons);
    if (result) {
      toast.success("Forwarder and user created successfully");
      this.setState({ show: false }, () => this.props.history.push("/forwarder/" + forwarder._id.toString()));
    } else {
      toast.error("Error creating forwarder and user");
    }
  };

  validateStepData = () => {
    const { view, name, mail, phone, address, primaryPerson, additionalPersons } = this.state;
    const errors: Array<string> = [];
    if (view === 0) {
      if (name.trim().length < 3) errors.push("Company name too short");
      if (isAddressTooShort(address)) errors.push("Address too short");
      if (phone.trim().length < 3) errors.push("Phone number too short");
      if (!validator.isEmail(mail)) errors.push("Email is not valid");
    } else if (view === 1) {
      if (primaryPerson.prename.trim().length < 2) errors.push("Primary person prename too short");
      if (primaryPerson.surname.trim().length < 2) errors.push("Primary person surname too short");
      if (!validator.isEmail(primaryPerson.mail)) errors.push("Primary person email is not valid");
      additionalPersons.forEach((p, idx) => {
        if (p.prename.trim().length < 2) errors.push(`Person ${idx + 1} prename too short`);
        if (p.surname.trim().length < 2) errors.push(`Person ${idx + 1} surname too short`);
        if (!validator.isEmail(p.mail)) errors.push(`Person ${idx + 1} email is not valid`);
      });
    }
    return errors;
  };

  render() {
    const { show, showSimilar, view, name, mail, phone, address, primaryPerson, additionalPersons, similarForwarders } =
      this.state;
    const errors = this.validateStepData();
    return (
      <>
        <button className="btn btn-outline btn-outline-light float-right" onClick={this.handleShow}>
          New Forwarder
        </button>
        <Modal
          contentClassName={"bg-dark"}
          size={view === 2 || showSimilar ? "lg" : undefined}
          show={show}
          onHide={this.handleHide}
          centered
        >
          <Modal.Header className="border-0 pb-0">
            <CloseButton variant={"white"} onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body className="mx-5 py-0">
            <div className="pb-5">
              <h1 className="fw-bolder d-flex align-items-center text-white">New Forwarder</h1>
              <div className="text-muted fw-bold fs-6">Forwarder and user creation</div>
            </div>
            {view === 0 && !showSimilar && (
              <>
                {similarForwarders.length > 0 && (
                  <div className="d-flex bg-danger rounded border-warning  p-5 my-5">
                    <span className="svg-icon svg-icon-2tx svg-icon-white me-4">
                      <SVG src={toAbsoluteUrl("/assets/media/svg/custom/warning-circle.svg")} />
                    </span>
                    <div className="d-flex flex-stack flex-grow-1">
                      <div className="fw-bolder">
                        <div className="fs-6 ">
                          {similarForwarders.length} similar{" "}
                          {similarForwarders.length === 1 ? "forwarder" : "forwarders"} already existing!
                          <br />
                          <u className="cursor-pointer" onClick={this.handleShowSimilar}>
                            Check now
                          </u>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row mb-5 ">
                  <div className="col-md-12 mt-5">
                    <label className="required fs-5 fw-bold mb-2">Forwarder Name</label>
                    <Input
                      type="text"
                      className="form-control custom-form-control"
                      name="name"
                      placeholder={"Forwarder Name"}
                      autoComplete="off"
                      value={name}
                      onBlur={this.handleChangeInput}
                    />
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="fs-6 fw-bold mb-1">Address</label>
                    <AddressInputGroup
                      address={address}
                      onChangeAddress={this.handleChangeAddressValue}
                      onChangeAddressType={this.handleChangeAddressValueType}
                      onChangeAddressCountry={(e) => this.handleChangeSelect("country", e)}
                    />
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="required fs-5 fw-bold mb-2">Email</label>
                    <Input
                      type="text"
                      className="form-control custom-form-control"
                      name="mail"
                      autoComplete="off"
                      value={mail}
                      onBlur={this.handleChangeInput}
                    />
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="required fs-5 fw-bold mb-2">Phone</label>
                    <Input
                      type="text"
                      className="form-control custom-form-control"
                      name="phone"
                      autoComplete="off"
                      value={phone}
                      onBlur={this.handleChangeInput}
                    />
                  </div>
                </div>
              </>
            )}
            {view === 1 && !showSimilar && (
              <UserCreation
                primaryPerson={primaryPerson}
                additionalPersons={additionalPersons}
                onAddPerson={this.handleAddPerson}
                onRemovePerson={this.handleRemovePerson}
                onPersonChange={this.handlePersonChange}
              />
            )}
            {(view === 2 || showSimilar) && (
              <>
                <div
                  className={" d-flex rounded p-5 my-5 " + (similarForwarders.length > 0 ? "bg-danger" : "bg-success")}
                >
                  <span className="svg-icon svg-icon-2tx svg-icon-white me-4">
                    <SVG src={toAbsoluteUrl("/assets/media/svg/custom/warning-circle.svg")} />
                  </span>
                  <div className="d-flex flex-stack flex-grow-1">
                    <div className="fw-bolder">
                      <div className="fs-6 ">
                        {similarForwarders.length} similar {similarForwarders.length === 1 ? "forwarder" : "forwarders"}{" "}
                        already existing!
                      </div>
                    </div>
                  </div>
                </div>
                {similarForwarders.length > 0 && (
                  <div className="row mb-5 ">
                    <div className="col-md-12 mt-5 table-responsive">
                      <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                        <thead>
                          <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                            <th>Name</th>
                            <th>Address</th>
                            <th>Responsible</th>
                          </tr>
                        </thead>
                        <tbody className="fw-bold text-gray-600">
                          {similarForwarders.map((forwarder) => {
                            const primaryPerson = getDocFromCollection(this.context.userData, forwarder.primaryPerson)!;
                            return (
                              <tr key={forwarder._id.toString()}>
                                <td className="align-middle">
                                  <span className="text-white fs-5 mb-1 ">{forwarder.name}</span>
                                </td>
                                <td className="align-middle">
                                  <span className="text-white mb-1 ">{formatAddress(forwarder.address[0])}</span>
                                  <div className="text-muted">{forwarder.address[0].country}</div>
                                </td>
                                <td className="align-middle">
                                  <span className="text-white mb-1 ">{getUserName(primaryPerson)}</span>
                                  <div className="text-muted">
                                    {primaryPerson.emails.length > 0 ? primaryPerson.emails[0].value : "-"}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </>
            )}
            <div className="border-bottom-dark-gray" />
          </Modal.Body>
          <Modal.Footer className="mx-5">
            <button className="btn btn-text-danger btn-sm " onClick={this.handleHide}>
              Cancel
            </button>
            {(view > 0 || showSimilar) && (
              <button className="btn btn-outline btn-outline-light btn-sm" onClick={this.handleBack}>
                Back
              </button>
            )}
            {view < 2 && !showSimilar ? (
              <ErrorOverlayButton
                errors={errors}
                className={"btn btn-outline btn-outline-light btn-sm"}
                buttonText={"Next"}
                onClick={this.handleNext}
              />
            ) : (
              view === 2 &&
              !showSimilar && (
                <button
                  className="btn btn-outline btn-outline-light btn-sm"
                  onClick={this.handleCreateForwarderAndUsers}
                >
                  Create Forwarder
                </button>
              )
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default withRouter(CreateForwarderModal);
