import _ from "lodash";
import React, { PureComponent } from "react";
import { Accordion, CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { B_BLOCKED, B_INCOMING, B_RELEASED, Batch } from "../../../../model/batch.types";
import {
  EditBatch,
  getBatchFromEditBatch,
  getBatchPackagesEditTimelineEntry,
  getBatchTimelineEntry,
  getDefaultBatchPackage,
  getEditBatch,
  T_BATCHDISABLE,
  T_BATCHENABLE,
  updateBatch,
  updateBatchPackages,
  B_WAREHOUSE_OPTIONS,
  B_STATES,
  getSupplierOrdersForCommodity,
} from "../../../../utils/batchUtils";
import { C_PACKAGE_OPTIONS } from "../../../../utils/commodityUtils";
import { Supplier } from "../../../../model/supplier.types";
import { Input } from "../../../common/Input";
import DateInput from "../../../common/DateInput";
import { C_UNITS } from "../../../../utils/commodityUtils";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";
import { shortenAlias } from "../../../../utils/fileUtils";
import { CustomToggle } from "../../../common/CustomToggle";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import DisableBatchDialog from "../../../batch/modals/DisableBatchDialog";
import { SUPPORTED_CURRENCIES } from "../../../../utils/currencyUtils";
import { sendMessage } from "../../../../services/slackService";
import { NotificationRoles } from "../../../../utils/userUtils";
import { getOrderNumber } from "../../../../utils/orderUtils";
import { DataContextInternalType } from "../../../../context/dataContext";
import { SupplierOrder } from "../../../../model/supplierOrder.types";
import { isAnyFinishedProduct } from "../../../../utils/productArticleUtils";
import { getDocFromCollection } from "../../../../utils/baseUtils";

interface EditBatchModalProps {
  batch?: Batch;
  selectedBatches?: Array<Batch>;
  single?: boolean;
  onClose: (success?: boolean) => void;
  context: DataContextInternalType;
}

interface EditBatchModalState {
  editBatch: EditBatch | null;
  showGeneralInformation: boolean;
  showPackages: boolean;
  saving: boolean;
  confirmationDialog?: boolean;
  note: string;
}

class EditBatchModal extends PureComponent<EditBatchModalProps, EditBatchModalState> {
  selectFileRef: React.RefObject<HTMLInputElement>;
  selectRawbidsCoAFileRef: React.RefObject<HTMLInputElement>;
  constructor(props: EditBatchModalProps) {
    super(props);
    this.selectFileRef = React.createRef();
    this.selectRawbidsCoAFileRef = React.createRef();
    this.state = {
      editBatch: props.batch ? getEditBatch(props.batch, props.context, props.selectedBatches) : null,
      showGeneralInformation: !props.single,
      showPackages: true,
      saving: false,
      confirmationDialog: false,
      note: "",
    };
  }

  componentDidUpdate(prevProps: Readonly<EditBatchModalProps>) {
    if (
      !_.isEqual(prevProps.batch, this.props.batch) ||
      !_.isEqual(prevProps.selectedBatches, this.props.selectedBatches)
    )
      this.setState({
        editBatch: this.props.batch
          ? getEditBatch(this.props.batch, this.props.context, this.props.selectedBatches)
          : null,
        showGeneralInformation: !this.props.single,
        showPackages: true,
        confirmationDialog: false,
      });
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    if (!this.state.editBatch || this.props.single) return;
    const editBatch = _.cloneDeep(this.state.editBatch);
    _.set(editBatch, e.target.name, e.target.type === "number" ? +e.target.value : e.target.value);
    this.setState({ editBatch });
  };

  handleSupplierChange = (e: SelectOption<Supplier>) => {
    if (!this.state.editBatch || this.props.single) return;
    const editBatch = _.cloneDeep(this.state.editBatch);
    editBatch.supplier = e;
    this.setState({ editBatch });
  };

  handleStateChange = (e: { value: typeof B_INCOMING | typeof B_RELEASED | typeof B_BLOCKED; label: string }) => {
    if (!this.state.editBatch || this.props.single) return;
    const editBatch = _.cloneDeep(this.state.editBatch);
    editBatch.state = e.value;
    this.setState({ editBatch });
  };

  handleSelectCOA = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !this.state.editBatch || this.props.single) return;
    const editBatch = _.cloneDeep(this.state.editBatch);
    editBatch.supplierCoA = e.target.files[0];
    this.setState({ editBatch });
  };

  handleSelectOwnCOA = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !this.state.editBatch || this.props.single) return;
    const editBatch = _.cloneDeep(this.state.editBatch);
    editBatch.ownCoA = e.target.files[0];
    this.setState({ editBatch });
  };

  handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!this.state.editBatch || this.props.single) return;
    const editBatch = _.cloneDeep(this.state.editBatch);
    const val = new Date(e.target.value);
    if (isNaN(val.getTime())) return;
    _.set(editBatch, e.target.name, val);
    this.setState({ editBatch });
  };

  handleChangePackage = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, index: number) => {
    if (!this.state.editBatch) return;
    const editBatch = _.cloneDeep(this.state.editBatch);
    const batchPackage = editBatch.packages[index];
    _.set(batchPackage, e.target.name, e.target.type === "number" ? +e.target.value : e.target.value);
    this.setState({ editBatch });
  };

  handleAddPackage = () => {
    if (!this.state.editBatch || this.props.single) return;
    const editBatch = _.cloneDeep(this.state.editBatch);
    editBatch.packages.push(getDefaultBatchPackage(this.state.editBatch, this.props.batch));
    this.setState({ editBatch });
  };

  handleRemovePackage = (index: number) => {
    if (!this.state.editBatch || this.props.single) return;
    const editBatch = _.cloneDeep(this.state.editBatch);
    editBatch.packages.splice(index, 1);
    this.setState({ editBatch });
  };

  handleDisableBatch = async (disable: boolean) => {
    if (this.props.single) return;
    const { batch, onClose } = this.props;
    if (!batch) return;
    this.setState({ saving: true });
    try {
      const timeline = batch.timeline.slice();
      timeline.push(getBatchTimelineEntry(disable ? T_BATCHDISABLE : T_BATCHENABLE, this.state.note));
      const result = await updateBatch({ disabled: disable, timeline }, batch._id);
      if (result && result.modifiedCount > 0) {
        toast.success("Batch " + (disable ? "disabled" : "enabled") + " successfully");
        onClose();
      } else {
        toast.error("Error " + (disable ? "disabling" : "enabling") + " batch");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  handleSupplierOrderChange = (e: SelectOption<SupplierOrder> | undefined) => {
    const editBatch = _.cloneDeep(this.state.editBatch);
    if (!editBatch) return;
    editBatch.supplierOrder = e?.object;
    const sup = e?.object?.supplier;
    if (sup) {
      const supplier = getDocFromCollection(this.props.context.supplier, sup);
      if (supplier) editBatch.supplier = { value: supplier._id.toString(), label: supplier.name, object: supplier };
    }
    this.setState({ editBatch });
  };

  handleUpdateBatch = async () => {
    const { single, batch } = this.props;
    const { editBatch } = this.state;
    if (single || !editBatch || !batch) return;
    this.setState({ saving: true });
    // Get batch and upload new coa if needed
    const finalBatch = getBatchFromEditBatch(editBatch, batch);
    if (!finalBatch) {
      this.setState({ saving: false });
      return;
    }
    try {
      const result = await updateBatch(finalBatch);
      if (result && result.modifiedCount > 0) {
        toast.success("Batch updated successfully");
        if (batch.supplierCoA.path !== finalBatch.supplierCoA.path) {
          const message = `A new <${process.env.REACT_APP_MEDIAHUB_FILE_BASE || ""}${
            finalBatch.supplierCoA.path
          }|*CoA*> was uploaded for Batch *RB${batch.identifier}*`;
          await sendMessage(NotificationRoles.QM, message, true);
        }
        this.props.onClose();
      } else {
        toast.error("Error updating batch");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  handleUpdatePackages = async () => {
    const { single, batch } = this.props;
    const { editBatch } = this.state;
    if (!single || !batch || !editBatch) return;
    this.setState({ saving: true });
    try {
      const timelineEntry = getBatchPackagesEditTimelineEntry(batch, editBatch);
      const result = await updateBatchPackages(editBatch._id, editBatch.packages, timelineEntry);
      if (result) {
        toast.success("Packages updated successfully");
        // Clear selected batches on success
        this.props.onClose(true);
      } else {
        toast.error("Error updating packages");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  handleChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => this.setState({ note: e.target.value });
  handleShowConfirmationDialog = () => this.setState({ confirmationDialog: true });
  handleHideConfirmationDialog = () => this.setState({ confirmationDialog: false });

  validateBatchData = () => {
    if (!this.state.editBatch) return ["No batch found"];
    const { single } = this.props;
    const { editBatch: batch } = this.state;
    const { lot, price, state, packages, supplier, supplierCoA, ownCoA } = batch;
    const errors: Array<string> = [];
    if (!single) {
      if (!lot.trim()) errors.push("LOT number required");
      if (!supplierCoA) errors.push("Supplier Certificate of Analysis required");
      if (price <= 0) errors.push("Invalid price");
      if (!supplier) errors.push("Supplier required");
      if (packages.some((p, idx) => packages.findIndex((p2) => p2.number === p.number) !== idx))
        errors.push("Non unique identifiers");
      if (state === B_RELEASED && !ownCoA) errors.push("Batch cannot be released without a Rawbids CoA");
    }
    if (packages.some((p) => !p.warehouseLocation.trim())) errors.push("Invalid packages: warehouse location missing");
    return errors;
  };

  render() {
    const { batch, single, onClose, context } = this.props;
    const { editBatch, showGeneralInformation, showPackages, saving, confirmationDialog, note } = this.state;
    if (!batch || !editBatch) return null;
    const errors = this.validateBatchData();
    return (
      <Modal contentClassName="bg-dark" show={!!editBatch} size={"lg"} onHide={onClose} centered>
        <Modal.Header className="border-0 pb-0">
          <Modal.Title>
            <h1 className="fw-bolder d-flex align-items-center text-white">
              Edit {single && <span>Packages Of</span>} Batch RB{batch.identifier}
              <small className="ml-2 mt-1">({batch.lot})</small>
            </h1>
          </Modal.Title>
          <CloseButton variant={"white"} onClick={() => onClose()} />
        </Modal.Header>
        <Modal.Body>
          {!confirmationDialog ? (
            <>
              <Accordion defaultActiveKey={single ? undefined : "generalInformation"}>
                <CustomToggle
                  eventKey={"generalInformation"}
                  callback={() => this.setState({ showGeneralInformation: !this.state.showGeneralInformation })}
                >
                  <h5>
                    General Information
                    <i
                      className={"custom-accordion-toggle fa fa-chevron-up ml-2 " + (showGeneralInformation && "show")}
                    />
                  </h5>
                </CustomToggle>
                <Accordion.Collapse eventKey={"generalInformation"}>
                  <div className="row mb-5">
                    <div className="col-md-6 mt-5">
                      <label className="fs-5 fw-bold mb-2">Commodity</label>
                      <Input
                        type="text"
                        className="form-control custom-form-control"
                        name="commodity"
                        value={editBatch.commodity.title.en}
                        disabled={true}
                      />
                    </div>
                    <div className="col-md-6 mt-5">
                      <label className="fs-5 fw-bold mb-2">Supplier Order</label>
                      <CustomSelect
                        options={getSupplierOrdersForCommodity(editBatch.commodity, context)}
                        value={
                          editBatch.supplierOrder
                            ? {
                                value: editBatch.supplierOrder._id.toString(),
                                label: getOrderNumber(editBatch.supplierOrder),
                              }
                            : undefined
                        }
                        onChange={this.handleSupplierOrderChange}
                        matchFormControl={true}
                        isClearable={true}
                      />
                    </div>
                    <div className="col-md-6 mt-5">
                      <label className="required fs-5 fw-bold mb-2">LOT-Number</label>
                      <Input
                        type="text"
                        className="form-control custom-form-control"
                        name="lot"
                        placeholder={"LOT number"}
                        autoComplete="off"
                        value={editBatch.lot}
                        disabled={single}
                        onBlur={single ? undefined : this.handleChange}
                      />
                    </div>
                    <div className="col-md-6 mt-5">
                      <label className="fs-5 fw-bold mb-2">Stored on</label>
                      <DateInput
                        classes="form-control custom-form-control"
                        value={editBatch.stockedDate}
                        onBlur={single ? () => true : this.handleDateChange}
                        disabled={single}
                        name={"stockedDate"}
                      />
                    </div>
                    <div className="col-md-6 mt-5">
                      <label className="required fs-5 fw-bold mb-2">Price (net)</label>
                      <div className="input-group">
                        <Input
                          type="number"
                          min={0}
                          className="form-control custom-form-control pt-0 pb-0"
                          name={"price"}
                          value={editBatch.price}
                          disabled={single}
                          onBlur={single ? undefined : this.handleChange}
                        />
                        <div className="input-group-append bg-custom-light-gray select-wrapper">
                          <select
                            className="form-control custom-form-control pt-0 pb-0"
                            style={{ minWidth: "70px" }}
                            name={"priceCurrency"}
                            value={editBatch.priceCurrency}
                            disabled={single}
                            onChange={single ? undefined : this.handleChange}
                          >
                            {SUPPORTED_CURRENCIES.map((c) => (
                              <option key={c} value={c}>
                                {c}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="input-group-append bg-custom-light-gray">
                          <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                            /
                          </div>
                        </div>
                        <div className="input-group-append bg-custom-light-gray rounded-end">
                          {isAnyFinishedProduct(editBatch.commodity) ? (
                            <div className="my-auto mr-2">1000 pcs</div>
                          ) : (
                            <div className="select-wrapper">
                              <select
                                className="form-control custom-form-control pt-0 pb-0"
                                style={{ minWidth: "60px" }}
                                value={editBatch.unit}
                                onChange={single ? undefined : this.handleChange}
                                disabled={single}
                                name={"unit"}
                              >
                                {C_UNITS.map((u) => (
                                  <option key={u} value={u}>
                                    {u}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mt-5">
                      <label className="required fs-5 fw-bold mb-2">Supplier</label>
                      <CustomSelect
                        options={context.supplier
                          .filter((s) => !s.disabled && s.activated)
                          .map((sF) => {
                            return { label: sF.name, value: sF._id.toString(), object: sF };
                          })}
                        value={editBatch.supplier}
                        disabled={single}
                        onChange={single ? () => true : this.handleSupplierChange}
                        matchFormControl={true}
                      />
                    </div>
                    <div className="col-md-6 mt-5">
                      <label className="required fs-5 fw-bold mb-2">State</label>
                      <CustomSelect
                        options={B_STATES}
                        value={B_STATES.find((s) => s.value === editBatch.state) || B_STATES[1]}
                        disabled={single}
                        onChange={single ? () => true : this.handleStateChange}
                        matchFormControl={true}
                      />
                    </div>
                    <div className="col-md-6 mt-5">
                      <label className="required fs-5 fw-bold mb-2">Best before</label>
                      <DateInput
                        classes="form-control custom-form-control"
                        value={editBatch.expiry}
                        onBlur={single ? () => true : this.handleDateChange}
                        disabled={single}
                        name={"expiry"}
                      />
                    </div>
                    <div className="col-md-6 mt-5">
                      <label className="required fs-5 fw-bold mb-2">Supplier CoA</label>
                      <div>
                        <button
                          className="btn btn-light btn-sm"
                          type="button"
                          disabled={single}
                          onClick={() => this.selectFileRef.current?.click()}
                        >
                          Select File
                        </button>
                        <input
                          type="file"
                          ref={this.selectFileRef}
                          accept="*"
                          style={{ display: "none" }}
                          onChange={single ? undefined : this.handleSelectCOA}
                        />
                        {editBatch.supplierCoA && (
                          <span className={"text-white fw-bold ml-3"}>
                            <>
                              {"_id" in editBatch.supplierCoA
                                ? shortenAlias(editBatch.supplierCoA.path)
                                : editBatch.supplierCoA.name}
                              <small className="text-muted ml-2">
                                {"_id" in editBatch.supplierCoA
                                  ? "(uploaded)"
                                  : Math.round(editBatch.supplierCoA.size / 1024) + " KB"}
                              </small>
                            </>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 mt-5">
                      <label className="fs-5 fw-bold mb-2">Rawbids CoA</label>
                      <div>
                        <button
                          className="btn btn-light btn-sm"
                          type="button"
                          disabled={single}
                          onClick={() => this.selectRawbidsCoAFileRef.current?.click()}
                        >
                          Select File
                        </button>
                        <input
                          type="file"
                          ref={this.selectRawbidsCoAFileRef}
                          accept="*"
                          style={{ display: "none" }}
                          onChange={single ? undefined : this.handleSelectOwnCOA}
                        />
                        {editBatch.ownCoA && (
                          <span className={"text-white fw-bold ml-3"}>
                            <>
                              {"_id" in editBatch.ownCoA ? shortenAlias(editBatch.ownCoA.path) : editBatch.ownCoA.name}
                              <small className="text-muted ml-2">
                                {"_id" in editBatch.ownCoA
                                  ? "(uploaded)"
                                  : Math.round(editBatch.ownCoA.size / 1024) + " KB"}
                              </small>
                            </>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 mt-5">
                      <label className="fs-5 fw-bold mb-2">Additional Notes</label>
                      <textarea
                        className="form-control custom-form-control"
                        rows={3}
                        name={"notes"}
                        value={editBatch.notes}
                        disabled={single}
                        onChange={single ? undefined : this.handleChange}
                      />
                    </div>
                  </div>
                </Accordion.Collapse>
              </Accordion>
              <Accordion defaultActiveKey="packages">
                <CustomToggle
                  eventKey={"packages"}
                  callback={() => this.setState({ showPackages: !this.state.showPackages })}
                >
                  <h5 className="mt-10">
                    Packages
                    <i className={"custom-accordion-toggle fa fa-chevron-up ml-2 " + (showPackages && "show")} />
                  </h5>
                </CustomToggle>
                <Accordion.Collapse eventKey={"packages"}>
                  <div className="row mb-5 ">
                    <div className="col-md-12">
                      {editBatch.packages.map((p, idx) => (
                        <div key={p._id.toString()} className="row my-4">
                          <div className="col-auto">
                            <div className="input-group">
                              <div className="input-group-prepend rounded-start bg-custom-light-gray">
                                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                                  &#35;
                                </div>
                              </div>
                              <Input
                                type="number"
                                style={{ maxWidth: "60px" }}
                                min={0}
                                integerOnly={true}
                                className={"form-control custom-form-control " + (single && "disabled")}
                                name="number"
                                value={p.number}
                                disabled={single}
                                onChange={(e) => this.handleChangePackage(e, idx)}
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="input-group">
                              <input
                                type="number"
                                className="form-control custom-form-control disabled"
                                name="amountPackages"
                                disabled={true}
                                value={1}
                              />
                              <div className="input-group-append rounded-end bg-custom-light-gray select-wrapper">
                                <select
                                  className="form-control custom-form-control py-0"
                                  name="packageType"
                                  value={p.packageType}
                                  onChange={(e) => this.handleChangePackage(e, idx)}
                                >
                                  {C_PACKAGE_OPTIONS.map((o: SelectOption) => (
                                    <option key={o.value} value={o.value}>
                                      {o.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="input-group">
                              <Input
                                type="number"
                                min={0}
                                value={p.amountEach.toString()}
                                name="amountEach"
                                onBlur={(e) => this.handleChangePackage(e, idx)}
                                className="form-control custom-form-control"
                              />
                              <div className="input-group-append rounded-end bg-custom-light-gray">
                                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                                  {editBatch.unit}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="input-group">
                              <div className="input-group-prepend rounded-start bg-custom-light-gray select-wrapper">
                                <select
                                  className="form-control custom-form-control py-0"
                                  style={{ minWidth: "90px" }}
                                  name="warehouse"
                                  placeholder="Warehouse"
                                  value={p.warehouse}
                                  onChange={(e) => this.handleChangePackage(e, idx)}
                                >
                                  {B_WAREHOUSE_OPTIONS.map((o) => (
                                    <option key={o.value} value={o.value}>
                                      {o.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <Input
                                type="text"
                                placeholder={"Location, e.g. A73"}
                                value={p.warehouseLocation}
                                name="warehouseLocation"
                                onBlur={(e) => this.handleChangePackage(e, idx)}
                                className="form-control custom-form-control"
                              />
                            </div>
                          </div>
                          {!single && (
                            <div className="col-auto">
                              <button
                                className={
                                  "btn btn-light btn-sm float-right " +
                                  (p.usedOrders && p.usedOrders.length > 0 && "disabled")
                                }
                                disabled={p.usedOrders && p.usedOrders.length > 0}
                                onClick={
                                  p.usedOrders && p.usedOrders.length > 0
                                    ? undefined
                                    : () => this.handleRemovePackage(idx)
                                }
                              >
                                <i className="fa fa-trash text-white p-0" />
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                      {!single && (
                        <div className="row">
                          <div className="col-12">
                            <button className="btn btn-light btn-sm float-right" onClick={this.handleAddPackage}>
                              <i className="fa fa-plus text-white p-0" />
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Accordion.Collapse>
              </Accordion>
            </>
          ) : (
            <DisableBatchDialog batch={batch} note={note} onChangeNote={this.handleChangeNote} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className={"btn btn-sm btn-outline " + (confirmationDialog ? "btn-outline-light" : "btn-text-danger")}
            onClick={confirmationDialog ? this.handleHideConfirmationDialog : () => onClose()}
          >
            {confirmationDialog ? "Back" : "Cancel"}
          </button>
          {!single && (
            <>
              <button
                className={
                  "btn btn-sm btn-outline " +
                  (confirmationDialog ? "btn-outline-light" : !batch?.disabled ? "btn-text-danger" : "btn-text-success")
                }
                onClick={
                  confirmationDialog
                    ? () => this.handleDisableBatch(!batch?.disabled)
                    : this.handleShowConfirmationDialog
                }
              >
                {!batch?.disabled ? "Disable Batch" : "Enable Batch"}
              </button>
            </>
          )}
          {!confirmationDialog && (
            <ErrorOverlayButton
              errors={errors}
              saving={saving}
              className={"btn btn-sm btn-outline btn-outline-light"}
              buttonText={single ? "Update Packages" : "Update Batch"}
              onClick={single ? this.handleUpdatePackages : this.handleUpdateBatch}
            />
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EditBatchModal;
