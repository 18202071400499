import React from "react";
import { DataContextInternal } from "../../context/dataContext";
import { searchUserName } from "../../utils/userUtils";
import { formatDate } from "../../utils/baseUtils";
import { ExtendedCommodityTimelineEntry } from "../../utils/commodityUtils";
import { ExtendedSupplierOrderTimelineEntry } from "../../utils/supplierOrderUtils";
import { ExtendedCustomerOrderTimelineEntry } from "../../utils/customerOrderUtils";
import { ExtendedSupplierTimelineEntry } from "../../utils/supplierUtils";

interface HistoryEntryProps {
  entry:
    | ExtendedSupplierOrderTimelineEntry
    | ExtendedCommodityTimelineEntry
    | ExtendedCustomerOrderTimelineEntry
    | ExtendedSupplierTimelineEntry;
  context: React.ContextType<typeof DataContextInternal>;
}

const HistoryEntry: React.FunctionComponent<HistoryEntryProps> = ({ entry, context }) => {
  return (
    <div className="m-0 timeline-item no-hover" style={{ borderLeft: "3px solid #343434" }}>
      <div className="timeline-content text-muted ps-3 my-0 ml-2">
        <div style={{ maxWidth: "75%" }}>{entry.text}</div>
        <div className="float-right">
          <span className="text-muted ml-2">
            {searchUserName(entry.person, context.userData)} - {formatDate(entry.date)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default HistoryEntry;
