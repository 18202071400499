import React, { PureComponent } from "react";

class MissingRoleView extends PureComponent {
  render() {
    return (
      <div className="card bg-white mx-auto mt-5 w-25">
        <div className="card-body">
          <span className="text-white">
            There is a problem with your account. Please contact our team to resolve the issue.
          </span>
          <br />
          <a href="mailto:developer@commodity-trading-systems.com?subject=Rawbids Account Issue">Send Mail</a>
        </div>
      </div>
    );
  }
}

export default MissingRoleView;
