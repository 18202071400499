import _ from "lodash";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import UploadOfferDocumentModal from "./UploadOfferDocumentModal";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import { Textarea } from "../../../common/Textarea";
import { DataContextSupplierType } from "../../../../context/dataContext";
import {
  CommodityOfferRequest,
  COR_ARTICLE_TYPE,
  COR_T_CREATED,
  UploadedOfferFile,
} from "../../../../model/commodityOfferRequest.types";
import {
  CORFiles,
  getCORTimelineEntry,
  getDefaultCommodityOfferRequest,
  getValidCommodities,
  insertCommodityOfferRequest,
} from "../../../../utils/commodityOfferRequestUtils";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";
import { getDocFromCollection } from "../../../../utils/baseUtils";
import { getDefaultSlackChannel, NotificationType, sendMessage } from "../../../../services/slackService";
import { isAnyFinishedProduct, SupplierArticleExtended } from "../../../../utils/productArticleUtils";

interface OfferCommodityModalProps {
  article?: SupplierArticleExtended;
  outline?: boolean;
  context: DataContextSupplierType;
}

interface OfferCommodityModalState {
  commodityOfferRequest: CommodityOfferRequest;
  article?: SupplierArticleExtended;
  show: boolean;
  stage: "commodity" | "information";
  saving: boolean;
}

class OfferCommodityModal extends PureComponent<OfferCommodityModalProps, OfferCommodityModalState> {
  constructor(props: OfferCommodityModalProps) {
    super(props);
    this.state = this.getDefaultState(props, false);
  }

  handleShow = () => this.setState(this.getDefaultState(this.props, true));
  handleHide = () => this.setState({ show: false });
  handleSwitchStage = () => this.setState({ stage: this.state.stage === "commodity" ? "information" : "commodity" });

  handleSelectCommodity = (article: SelectOption<SupplierArticleExtended>) => {
    const commodityOfferRequest = _.cloneDeep(this.state.commodityOfferRequest);
    commodityOfferRequest.article = {
      type: isAnyFinishedProduct(article.object) ? COR_ARTICLE_TYPE.FINISHEDPRODUCT : COR_ARTICLE_TYPE.COMMODITY,
      id: article.value,
    };
    this.setState({ article: article.object, commodityOfferRequest });
  };

  handleAddFile = (type: CORFiles, file: UploadedOfferFile) => {
    const commodityOfferRequest = _.cloneDeep(this.state.commodityOfferRequest);
    if (type === CORFiles.COA) commodityOfferRequest.coa = file;
    else commodityOfferRequest.specification = file;
    this.setState({ commodityOfferRequest });
  };

  handleChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const commodityOfferRequest = _.cloneDeep(this.state.commodityOfferRequest);
    commodityOfferRequest.note = e.target.value;
    this.setState({ commodityOfferRequest });
  };

  handleConfirm = async () => {
    const commodityOfferRequest = _.cloneDeep(this.state.commodityOfferRequest);
    this.setState({ saving: true });
    try {
      commodityOfferRequest.timeline = [getCORTimelineEntry(COR_T_CREATED)];
      const result = await insertCommodityOfferRequest(commodityOfferRequest);
      if (result && result.insertedId) {
        this.sendSlackNotification(result.insertedId.toString());
        toast.success("Commodity Offer Request successfully created");
        this.setState({ show: false });
      } else {
        toast.error("Error creating commodity offer request");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  sendSlackNotification = (id: string) => {
    const { article: articleDocument, commodityOfferRequest } = this.state;
    const { article, supplier: supplierId } = commodityOfferRequest;
    const supplier = getDocFromCollection(this.props.context.supplier, supplierId);
    const message = `<https://${process.env.REACT_APP_BASE_URL || ""}/supplier/${supplierId}|*${
      supplier?.name || ""
    }*> requested to offer <https://${process.env.REACT_APP_BASE_URL || ""}/${
      article.type === COR_ARTICLE_TYPE.FINISHEDPRODUCT ? "finishedProduct" : "commodity"
    }/${article.id}|*${articleDocument?.title.en || ""}*>. Review <https://${
      process.env.REACT_APP_BASE_URL || ""
    }/supplier/${supplierId}/${id}|*request*>.`;
    sendMessage(getDefaultSlackChannel(false, NotificationType.REQUEST), message);
  };

  /**
   * Retrieves the default state of the component.
   * @param props Props of the component
   * @param show Determines the state of the show flag
   * @returns { OfferCommodityModalState } Default state of the component
   */
  getDefaultState = (props: OfferCommodityModalProps, show: boolean): OfferCommodityModalState => {
    return {
      show,
      saving: false,
      stage: props.article ? "information" : "commodity",
      article: props.article,
      commodityOfferRequest: getDefaultCommodityOfferRequest(props.article),
    };
  };

  validateData = () => {
    const { article, stage, commodityOfferRequest } = this.state;
    const errors: Array<string> = [];
    if (stage === "commodity") {
      if (!article) errors.push("Please select a commodity");
    } else {
      if (!commodityOfferRequest.coa) errors.push("Please upload a COA");
      if (!commodityOfferRequest.specification) errors.push("Please upload a specification");
    }
    return errors;
  };

  render() {
    const { context, article: cProps, outline } = this.props;
    const { commodityOfferRequest, article, stage, show, saving } = this.state;
    const errors = this.validateData();

    return (
      <>
        <button
          className={"btn w-100 fw-bolder " + (outline ? "btn-sm btn-outline btn-outline-light" : " btn-light")}
          onClick={this.handleShow}
        >
          Offer Commodity
        </button>
        <Modal contentClassName="bg-dark" show={show} size="lg" onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Upload Files</h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            {stage === "information" && article ? (
              <div className="row">
                <div className="col-12">
                  <div className=" fs-6 mb-5">
                    Please upload your specification and an exemplary CoA. For quality assurance reasons, these
                    documents will be reviewed by us and you will then be approved for this raw material.
                  </div>
                </div>
                <div className="col-md-2 mt-5 ml-0">
                  <div className="col-12 mt-0 pl-0">
                    <label className="fs-5 fw-bold mb-2">Specification</label>
                    {commodityOfferRequest.specification ? (
                      <div className="text-success mb-5">
                        <i className="fas fa-check-circle text-success mr-1" />
                        uploaded
                      </div>
                    ) : (
                      <div className="text-danger mb-5">
                        <i className="fas fa-times-circle text-danger mr-1" />
                        missing
                      </div>
                    )}
                    <UploadOfferDocumentModal
                      article={article}
                      type={CORFiles.SPECIFICATION}
                      context={context}
                      onAddFile={this.handleAddFile}
                    />
                  </div>
                </div>
                <div className="col-md-2 mt-5">
                  <div className="col-12 mt-0 pl-0">
                    <label className="fs-5 fw-bold mb-2">Latest CoA</label>
                    {commodityOfferRequest.coa ? (
                      <div className="text-success mb-5">
                        <i className="fas fa-check-circle text-success mr-1" />
                        uploaded
                      </div>
                    ) : (
                      <div className="text-danger mb-5">
                        <i className="fas fa-times-circle text-danger mr-1" />
                        missing
                      </div>
                    )}
                    <UploadOfferDocumentModal
                      article={article}
                      type={CORFiles.COA}
                      context={context}
                      onAddFile={this.handleAddFile}
                    />
                  </div>
                </div>
                <div className="col-8 mt-5">
                  <label className="fs-5 fw-bold mb-2">Optional Note</label>
                  <Textarea
                    className="form-control custom-form-control"
                    placeholder="Optional information you want to add..."
                    rows={3}
                    value={commodityOfferRequest.note}
                    onChange={this.handleChangeNote}
                  />
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <div className="fs-5">Please select the Commodity you want to offer on Rawbids.</div>
                </div>
                <div className="col-12 mt-4">
                  <CustomSelect
                    options={getValidCommodities(context.commodity).map((c) => {
                      return { value: c._id.toString(), label: c.title.en, object: c };
                    })}
                    value={
                      article
                        ? { value: article._id.toString(), label: article.title.en, object: article }
                        : { value: "", label: "Select Commodity" }
                    }
                    onChange={this.handleSelectCommodity}
                  />
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-outline btn-outline-light" onClick={this.handleHide}>
              Close
            </button>
            {!cProps && stage === "information" && (
              <button className="btn btn-sm btn-outline btn-outline-light" onClick={this.handleSwitchStage}>
                Back
              </button>
            )}
            <ErrorOverlayButton
              errors={errors}
              className="btn btn-sm btn-outline btn-outline-light"
              buttonText={stage === "commodity" ? "Continue" : "Confirm"}
              saving={saving}
              onClick={stage === "commodity" ? this.handleSwitchStage : this.handleConfirm}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default OfferCommodityModal;
