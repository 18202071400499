import _ from "lodash";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { ActiveSubstance } from "../../../../model/activeSubstance.types";
import { updateActiveSubstance } from "../../../../utils/activeSubstanceUtils";

interface DisableActiveSubstanceModalProps {
  activeSubstance: ActiveSubstance;
}

interface DisableActiveSubstanceModalState {
  show: boolean;
}

class DisableActiveSubstanceModal extends PureComponent<
  DisableActiveSubstanceModalProps,
  DisableActiveSubstanceModalState
> {
  constructor(props: DisableActiveSubstanceModalProps) {
    super(props);
    this.state = { show: false };
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState({ show: false });

  /**
   * Disables the property.
   */
  handleClickDisable = async () => {
    const activeSubstance = _.cloneDeep(this.props.activeSubstance);
    activeSubstance.disabled = !activeSubstance.disabled;
    const result = await updateActiveSubstance(activeSubstance);
    if (result) {
      toast.success("Property " + (activeSubstance.disabled ? "disabled" : "enabled") + " successfully");
      this.handleHide();
    } else {
      toast.error("Error " + (activeSubstance.disabled ? "disabling" : "enabling") + " property");
    }
  };

  render() {
    const { activeSubstance } = this.props;
    const { show } = this.state;
    const enable = activeSubstance.disabled;

    return (
      <>
        <button
          className={"btn btn-text " + (enable ? "btn-text-success" : "btn-text-danger")}
          onClick={this.handleShow}
        >
          {enable ? "Enable" : "Disable"}
        </button>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">
                {enable ? "Enable" : "Disable"} {activeSubstance.name.en}
              </h1>
            </Modal.Title>
            <CloseButton variant={"white"} onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <h2 className="fw-bolder text-white text-center">
              Do you really want to {enable ? "enable" : "disable"} {activeSubstance.name.en}?
            </h2>
            {enable ? (
              <span className="fw-bolder d-flex align-items-center text-white text-center">
                After being enabled the active substance can be used inside the system again.
              </span>
            ) : (
              <>
                <span className="fw-bolder d-flex align-items-center text-white text-center">
                  After being disabled the active substance can not be added to commodities anymore. If the active
                  substance is already used it will still be shown there.
                </span>
                <br />
                <span className="fw-bolder d-flex align-items-center text-white text-center">
                  You can re-enable the active substance any time via clicking on "Enable" on the active substance
                  overview.
                </span>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-outline btn-text-danger" onClick={this.handleHide}>
              Cancel
            </button>
            <button className="btn btn-sm btn-outline btn-outline-light" onClick={this.handleClickDisable}>
              {enable ? "Enable" : "Disable"}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default DisableActiveSubstanceModal;
