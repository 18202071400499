import { BSON } from "realm-web";
import { callFunction } from "../services/dbService";
import {
  STO_TIMELINE,
  StorageOrder,
  StorageOrderTimelineEntry,
  StorageOrderTimelineEntryPayload,
} from "../model/storageOrder.types";
import userService from "../services/userService";
import { OrderFile } from "../model/commonTypes";

export const UPSERTSTORAGEORDER = "upsertStorageOrder";

// file types
export const STO_STORAGEORDER = "storageOrder";
export const STO_STORAGENOTICE = "storageNotice";

export const STO_REMARKS =
  "In case of any damage please inform us before transportation of goods. Please let us have your shipping advice and don’t hesitate to contact us for any further requests.";

/**
 * Inserts a new storageOrder into the database.
 * @param storageOrder storageOrder that should be inserted into the database
 * @returns {Promise<{ res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; storageOrderNo: string } | false>} Result of the function
 */
export async function insertStorageOrder(
  storageOrder: StorageOrder
): Promise<{ res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; storageOrderNo: string } | false> {
  return (await callUpsertStorageOrder(storageOrder, true)) as
    | { res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; storageOrderNo: string }
    | false;
}

/**
 * Updates an existing storageOrder inside the database.
 * @param storageOrder storageOrder that should be updated inside the database
 * @param storageOrderId optional id of storage order if it is not contained in storage order object
 * @returns {Promise<{ res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>; storageOrderNo: string } | false>} Result of the function
 */
export async function updateStorageOrder(
  storageOrder: Partial<StorageOrder>,
  storageOrderId: BSON.ObjectId
): Promise<{ res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>; storageOrderNo: string } | false> {
  if (storageOrderId) storageOrder._id = storageOrderId;
  return (await callUpsertStorageOrder(storageOrder, false)) as
    | { res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>; storageOrderNo: string }
    | false;
}

/**
 * Calls the upsert storageOrder function in backend.
 * @param storageOrder storageOrder that should be upsert
 * @param insert True for insert, else update
 * @returns {Promise<{ res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>; storageOrderNo: string }} Result of the function
 */
async function callUpsertStorageOrder(
  storageOrder: Partial<StorageOrder>,
  insert: boolean
): Promise<{
  res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>;
  storageOrderNo: string;
}> {
  return callFunction(UPSERTSTORAGEORDER, [storageOrder, insert]);
}

/**
 * Generates a timeline entry for a storage order
 * @param type Type of the entry
 * @param payload Optional payload of the entry
 * @returns {StorageOrderTimelineEntry} Timeline entry object
 */
export function getStorageOrderTimelineEntry(
  type: STO_TIMELINE,
  payload?: StorageOrderTimelineEntryPayload
): StorageOrderTimelineEntry {
  return {
    _id: new BSON.ObjectId(),
    date: new Date(),
    type: type,
    person: userService.getUserId(),
    payload: payload ?? null,
  };
}

/**
 * Get a file object for supplier order files
 * @param path path to the file
 * @param type type of the file
 * @returns {OrderFile} order file
 */
export const getStorageOrderFile = (path: string, type: typeof STO_STORAGEORDER): OrderFile => {
  return {
    _id: new BSON.ObjectId(),
    date: new Date(),
    path,
    type,
  };
};
