import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContextAnonymous, DataContextCustomer } from "../../../../context/dataContext";
import SampleOrderPage from "../../common/sampleOrder/SampleOrderPage";
import userService from "../../../../services/userService";
import { ANONYMOUS, CUSTOMER } from "../../../../utils/userUtils";

interface SampleOrderWrapperParams {
  id: string;
}

interface SampleOrderWrapperProps extends RouteComponentProps<SampleOrderWrapperParams> {}

const SampleOrderWrapper: React.FunctionComponent<SampleOrderWrapperProps> = (props) => {
  let context;
  if (userService.getUserType() === CUSTOMER) {
    context = useContext(DataContextCustomer);
  } else if (userService.getUserType() === ANONYMOUS) {
    context = useContext(DataContextAnonymous);
  }
  if (!context) return <></>;
  return <SampleOrderPage context={context} {...props} />;
};

export default SampleOrderWrapper;
