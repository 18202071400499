import _ from "lodash";
import React, { FormEvent, PureComponent } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { toAbsoluteUrl } from "../../utils/baseUtils";
import authService from "../../services/authService";

interface PasswordResetProps extends RouteComponentProps {}

interface PasswordResetState {
  data: {
    password: string;
    passwordRepeat: string;
    token: string | null;
    tokenId: string | null;
  };
  errors: { password?: string; passwordRepeat?: string };
  resetting: boolean;
}

class PasswordReset extends PureComponent<PasswordResetProps, PasswordResetState> {
  constructor(props: PasswordResetProps) {
    super(props);
    const query = new URLSearchParams(props.location.search);
    const token = query.get("token");
    const tokenId = query.get("tokenId");
    this.state = {
      data: {
        password: "",
        passwordRepeat: "",
        token: token,
        tokenId: tokenId,
      },
      errors: {},
      resetting: false,
    };
  }

  handleSubmit = async (e: FormEvent) => {
    // Disable default behavior
    e.preventDefault();
    const { data } = this.state;
    if (!data.token || !data.tokenId) return;
    this.setState({ resetting: true });
    const errors = { ...this.state.errors };
    if (data.password.length < 10) {
      errors.password = "Password is too short. At least 10 characters required";
      this.setState({ errors, resetting: false });
    } else if (data.password !== data.passwordRepeat) {
      errors.passwordRepeat = "Passwords do not match";
      this.setState({ errors, resetting: false });
    } else {
      try {
        await authService.resetPassword(data.token, data.tokenId, data.password);
        window.location.href = "/login?success=true";
      } catch (ex: any) {
        const errors = { ...this.state.errors };
        errors.passwordRepeat = ex.error as string;
        this.setState({ errors });
      } finally {
        this.setState({ resetting: false });
      }
    }
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...this.state.data };
    _.set(data, e.target.name, e.target.value);
    this.setState({ data });
  };

  render() {
    if (!this.state.data.token || !this.state.data.tokenId) {
      return <Redirect to="/" />;
    }
    const { data, errors, resetting } = this.state;
    return (
      <div
        className="d-flex flex-column"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/assets/media/misc/bg-1.jpg")})`,
          height: "100%",
          backgroundSize: "cover",
          justifyContent: "center",
        }}
      >
        <div className="d-flex flex-column flex-column flex-lg-row">
          <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
            <div className="d-flex flex-center flex-lg-start flex-column">
              <img alt="Logo" src={toAbsoluteUrl("/assets/media/logo.png")} className="mb-7 h-80px h-80px" />
              <h2 className="text-white fw-bold m-0">The biggest virtual marketplace for premium ingredients.</h2>
            </div>
          </div>
          <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20">
            <div
              className="bg-body d-flex flex-column align-items-stretch flex-center w-md-600px p-20 border-none"
              style={{ borderRadius: "1.5rem" }}
            >
              <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
                <form className="form w-100" onSubmit={resetting ? undefined : this.handleSubmit}>
                  <div className="text-center mb-11">
                    <h1 className="text-dark fw-bolder mb-3 fs-2x">Set Password</h1>
                    <p className="text-center text-dark">Please enter your new password and repeat it</p>
                  </div>
                  <div className="mb-8">
                    <input
                      name="password"
                      id="login-password"
                      className="form-control form-control-lg form-control-solid"
                      type="password"
                      placeholder="New Password"
                      autoComplete={"off"}
                      value={data.password}
                      required={true}
                      onChange={this.handleChange}
                    />
                    {errors.password && <p className="text-center text-danger mt-2">{errors.password}</p>}
                  </div>
                  <div className="mb-3">
                    <input
                      className="form-control form-control-lg form-control-solid"
                      type="password"
                      placeholder="Repeat New Password"
                      autoComplete={"off"}
                      name={"passwordRepeat"}
                      value={data.passwordRepeat}
                      required={true}
                      onChange={this.handleChange}
                    />
                    {errors.passwordRepeat ? (
                      <p className="text-center text-danger mt-2">{errors.passwordRepeat}</p>
                    ) : (
                      <p className="mt-2">&nbsp;</p>
                    )}
                  </div>
                  <div className="d-grid mb-10">
                    <button
                      type="submit"
                      className={"btn btn-dark" + (resetting ? " disabled" : "")}
                      disabled={resetting}
                    >
                      {!resetting ? (
                        <span className="indicator-label">Confirm Password</span>
                      ) : (
                        <span className="indicator-progress d-block">
                          Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </div>
                </form>
              </div>
              <div className="d-flex flex-stack px-lg-10">
                <div className="me-0">
                  <button className="btn btn-flex btn-link fs-base disabled">
                    <img
                      data-kt-element="current-lang-flag"
                      className="w-20px h-20px rounded me-3"
                      src={toAbsoluteUrl("assets/media/flags/united-states.svg")}
                      alt=""
                    />
                    <span data-kt-element="current-lang-name" className="me-1 link-dark">
                      English
                    </span>
                  </button>
                </div>
                <div className="d-flex text-primary fs-base gap-5">
                  <a
                    href="https://rawbids.com/files/terms_of_purchase.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-dark"
                  >
                    Terms of Purchase
                  </a>
                  <a
                    href="https://rawbids.com/files/terms_of_sale.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-dark"
                  >
                    Terms of Sale
                  </a>
                  <a href="mailto:info@rawbids.com" className="link-dark">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PasswordReset;
