import React from "react";
import Dropzone, { Accept } from "react-dropzone";
import { toAbsoluteUrl } from "../../utils/baseUtils";
import { resolveFilePath } from "../../utils/fileUtils";

interface FileUploadDropzoneProps {
  onDrop: <T extends File>(acceptedFiles: T[]) => void;
  previewImage: File | string | undefined;
  acceptance: Accept;
}

/**
 * React component to represent a file uploader for news entities.
 */
const FileUploadDropzone: React.FunctionComponent<FileUploadDropzoneProps> = ({ onDrop, previewImage, acceptance }) => {
  // Creating subsection for image preview
  const preview = previewImage && (
    <div>
      <div className="text-white my-2">Image preview</div>
      <div className="previewInner">
        <img
          src={typeof previewImage === "string" ? resolveFilePath(previewImage) : URL.createObjectURL(previewImage)}
          // Revoke data uri after image is loaded
          onLoad={() => {
            typeof previewImage === "string"
              ? resolveFilePath(previewImage)
              : URL.revokeObjectURL(URL.createObjectURL(previewImage));
          }}
          alt="preview image"
        />
      </div>
    </div>
  );

  return (
    <Dropzone onDrop={onDrop} maxSize={100 * 1024 * 1024} accept={acceptance}>
      {({ getRootProps, getInputProps }) => (
        <section className="p-2">
          <div id="dropzone_1" {...getRootProps({ className: "dropzone custom-form-control" })}>
            <input {...getInputProps()} />
            <div className="dropzone-msg-desc">
              <p> Drag 'n' drop some file here, or click on the dropzone </p>
              <button id="uploadButton" {...getRootProps()} type="button" className={"btn float-middle "}>
                <img src={toAbsoluteUrl("/assets/media/svg/custom/Upload.svg")} alt={"upload image"} />
              </button>
            </div>
          </div>
          <aside className="previewContainer">{preview}</aside>
        </section>
      )}
    </Dropzone>
  );
};

export default FileUploadDropzone;
