import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { ActiveSubstance } from "../../../../model/activeSubstance.types";
import { insertActiveSubstance, updateActiveSubstance } from "../../../../utils/activeSubstanceUtils";

interface CreateActiveSubstanceModalProps {
  activeSubstance?: ActiveSubstance;
  additionalButtonClasses?: string;
}

interface CreateActiveSubstanceModalState {
  step: number;
  show: boolean;
  saving: boolean;
  name: string;
  nrv: string;
}

class CreateActiveSubstanceModal extends PureComponent<
  CreateActiveSubstanceModalProps,
  CreateActiveSubstanceModalState
> {
  constructor(props: CreateActiveSubstanceModalProps) {
    super(props);
    this.state = this.getDefaultState(false);
  }

  handleShow = () => this.setState(this.getDefaultState(true));
  handleHide = () => this.setState({ show: false });
  handleNext = () => this.setState({ step: 2 });
  handleBack = () => this.setState({ step: 1 });

  handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: e.target.value });
  };

  handleNrvChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Remove leading zeros
    let value = e.target.value.replaceAll(/^0+/g, "0");
    if ((!Number(value) && Number(value) !== 0) || Number(value) < 0) return;
    if (!value.includes(".")) value = Number(value).toString();
    this.setState({ nrv: value });
  };

  /**
   * Handles the creation / edit of the active substance
   */
  handleCreateActiveSubstance = async () => {
    const { activeSubstance } = this.props;
    const { name, nrv } = this.state;
    this.setState({ saving: true });
    const aS = {
      _id: activeSubstance ? activeSubstance._id : new BSON.ObjectId(),
      name: { en: name },
      nrv: Number(nrv !== "" ? nrv : 0),
      disabled: false,
    };
    let result;
    // If a property was passed as props we are in edit case
    if (activeSubstance) {
      result = await updateActiveSubstance(aS);
    } else {
      result = await insertActiveSubstance(aS);
    }
    if (result) {
      toast.success("Property " + (activeSubstance ? "edited" : "inserted") + " successfully");
      this.setState({ show: false, saving: false });
    } else {
      toast.error("Error " + (activeSubstance ? "editing" : "inserting") + " property");
    }
  };

  /**
   * Returns the default state of the component.
   * @param show: Indicates which values should be used for show
   * @returns { CreateActiveSubstanceModalState } Default state with given show value
   */
  getDefaultState = (show: boolean) => {
    const { activeSubstance } = this.props;
    return {
      show,
      step: 1,
      saving: false,
      name: activeSubstance ? activeSubstance.name.en : "",
      nrv: activeSubstance ? activeSubstance.nrv : "0",
    } as CreateActiveSubstanceModalState;
  };

  /**
   * Checks if the given input is valid. Also checks if there are any changes in edit case.
   * @returns { boolean } True if input is valid, else false
   */
  inputValid = () => {
    const { activeSubstance } = this.props;
    const { name, nrv } = this.state;
    const nt = name.trim();
    return (
      nt !== "" &&
      nt.length >= 3 &&
      (!activeSubstance || activeSubstance.name.en !== name || activeSubstance.nrv.toString() !== nrv)
    );
  };

  render() {
    const { activeSubstance, additionalButtonClasses } = this.props;
    const { name, nrv, saving, show, step } = this.state;
    const onSummary = step === 2;

    return (
      <>
        <span
          className={"btn btn-outline btn-outline-light " + (additionalButtonClasses ?? "")}
          onClick={this.handleShow}
        >
          {activeSubstance ? "Edit" : "New Active Substance"}
        </span>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">
                {activeSubstance ? "Edit" : "New"} active substance
              </h1>
            </Modal.Title>
            <CloseButton variant={"white"} onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-3">
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="required fs-5 fw-bold mb-2">Name</label>
                <input
                  type="text"
                  className={"form-control custom-form-control " + (onSummary ? "disabled" : "")}
                  name="name"
                  value={name}
                  disabled={onSummary}
                  onChange={this.handleNameChange}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="fs-5 fw-bold mb-2">NRV (nutrient reference value) in µg per day</label>
                <input
                  type="number"
                  className={"form-control custom-form-control " + (onSummary ? "disabled" : "")}
                  name="nrv"
                  value={nrv}
                  disabled={onSummary}
                  min={0}
                  onChange={this.handleNrvChange}
                />
              </div>
            </div>
            {onSummary && (
              <div className="row">
                <h2 className="fw-bolder text-white text-center">Please check that the data above is correct!</h2>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-sm btn-outline btn-text-white"
              onClick={step === 1 ? this.handleHide : this.handleBack}
            >
              {step === 1 ? "Close" : "Back"}
            </button>
            <button
              className={"btn btn-sm btn-outline btn-outline-light " + (this.inputValid() && !saving ? "" : "disabled")}
              disabled={!this.inputValid() || saving}
              onClick={step === 1 ? this.handleNext : this.handleCreateActiveSubstance}
            >
              {saving ? "Saving..." : step === 1 ? "Next" : activeSubstance ? "Confirm" : "Create"}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreateActiveSubstanceModal;
