import React, { useContext } from "react";
import InvoiceListing from "./InvoiceListing";
import { DataContextInternal } from "../../../context/dataContext";

const InvoicesWrapper: React.FunctionComponent = () => {
  const context = useContext(DataContextInternal);
  return <InvoiceListing context={context} />;
};

export default InvoicesWrapper;
