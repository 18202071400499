import React, { useContext } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DataContextInternal } from "../../context/dataContext";
import CreateSupplierOrder from "./CreateSupplierOrder";

interface CreateSupplierOrderParams {
  id: string;
  type?: string;
}

interface CreateSupplierOrderWrapperProps extends RouteComponentProps<CreateSupplierOrderParams> {
  forStock?: boolean;
}

const CreateSupplierOrderWrapper: React.FunctionComponent<CreateSupplierOrderWrapperProps> = (props) => {
  const context = useContext(DataContextInternal);
  return <CreateSupplierOrder context={context} {...props} />;
};

export default withRouter(CreateSupplierOrderWrapper);
