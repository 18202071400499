import React, { PureComponent } from "react";
import { BSON } from "realm-web";
import InvoicePosition from "./InvoicePosition";
import { SelectOption } from "../../common/CustomSelect";
import { formatCurrency } from "../../../utils/baseUtils";
import { Position, VATPosition } from "../../../model/invoice.types";
import { I_P_DUNNING, I_P_PAYMENT } from "../../../utils/invoiceUtils";

interface InvoicePositionsProps {
  positions: Array<Position>;
  subtotal: number;
  total: number;
  reverseCharge: boolean;
  nonEU: boolean;
  vatPositions: Array<VATPosition>;
  currency: SelectOption;
  onAddPosition: () => void;
  onRemovePosition: (id: BSON.ObjectId) => void;
  onPositionChange: (id: BSON.ObjectId, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

class InvoicePositions extends PureComponent<InvoicePositionsProps> {
  render() {
    const {
      positions,
      subtotal,
      total,
      vatPositions,
      currency,
      reverseCharge,
      nonEU,
      onAddPosition,
      onRemovePosition,
      onPositionChange,
    } = this.props;

    const amountPaid = positions.reduce((sum, p) => sum + (p.unit === I_P_PAYMENT ? p.total : 0), 0);
    const dunningFees = positions.reduce((sum, p) => sum + (p.unit === I_P_DUNNING ? p.total : 0), 0);

    return (
      <div className="table-responsive mb-10">
        <table className="table g-5 gs-0 mb-0 fw-bolder text-gray-700 table-no-padding">
          <thead className="bg-white-2 ">
            <tr className="border-bottom-dark-gray fs-7 fw-bolder text-white text-uppercase">
              <th className="min-w-325px text-white">Position</th>
              <th className="min-w-80px text-white bg-white">Quantity</th>
              <th className="min-w-75px text-white">Unit</th>
              <th className="min-w-80px text-white">Price</th>
              <th className="min-w-75px text-white">VAT %</th>
              <th className="min-w-90px text-white">Discount %</th>
              <th className="min-w-80px text-white text-end">Total</th>
              <th className="text-white text-end" />
            </tr>
          </thead>
          <tbody className="border-bottom-dark-gray">
            {positions.map((position) => (
              <InvoicePosition
                key={position._id.toString()}
                currency={currency}
                position={position}
                reverseCharge={reverseCharge}
                nonEU={nonEU}
                onRemovePosition={onRemovePosition}
                onPositionChange={onPositionChange}
              />
            ))}
          </tbody>
          <tfoot className="invoiceFooter">
            <tr className="border-top-dark align-top fs-6 fw-bolder text-gray-700">
              <th className="text-primary">
                <button className="btn btn-link text-white py-1 pt-5" type="button" onClick={onAddPosition}>
                  Add Position
                </button>
              </th>
              <th />
              <th className="border-bottom-dark text-muted" colSpan={3}>
                <div className="d-flex flex-column align-items-start">
                  <div className="fs-5 text-muted pt-5">Subtotal</div>
                </div>
              </th>
              <th colSpan={2} className="border-bottom-dark text-end">
                <div className="fs-5 pt-5 text-muted"> {formatCurrency(subtotal, currency.value)}</div>
              </th>
            </tr>
            {vatPositions.map((obj: VATPosition) => (
              <tr className="align-top fw-bolder text-gray-700 g-2" key={obj.percentage}>
                <th colSpan={2} />
                <th colSpan={3}>
                  <span className="fs-5 text-muted">
                    VAT {!reverseCharge && !nonEU ? obj.percentage : 0}% of{" "}
                    {formatCurrency(obj.netValue, currency.value)}
                  </span>
                </th>
                <th colSpan={2} className="text-end fs-5 text-muted">
                  {formatCurrency(obj.vatAmount, currency.value)}
                </th>
              </tr>
            ))}
            {amountPaid > 0 && (
              <tr className="align-top fw-bolder text-gray-700">
                <th colSpan={2} />
                <th colSpan={3}>
                  <span className="fs-5 text-muted">Payments</span>
                </th>
                <th colSpan={2} className="text-end fs-5">
                  {formatCurrency(amountPaid, currency.value)}
                </th>
              </tr>
            )}
            {dunningFees > 0 && (
              <tr className="align-top fw-bolder text-gray-700">
                <th colSpan={2} />
                <th colSpan={3}>
                  <span className="fs-5 text-muted">Dunning Fees</span>
                </th>
                <th colSpan={2} className="text-end fs-5">
                  {formatCurrency(dunningFees, currency.value)}
                </th>
              </tr>
            )}
            <tr className="align-top fw-bolder text-gray-700">
              <th colSpan={2} />
              <th className="fs-2 ps-0" colSpan={3}>
                Total
              </th>
              <th colSpan={2} className="text-end fs-2 text-nowrap">
                <span style={{ color: "#50cd89" }}>{formatCurrency(total, currency.value)}</span>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }
}

export default InvoicePositions;
