import React, { PureComponent } from "react";
import { DataContextAnonymousType, DataContextCustomerType } from "../../../context/dataContext";
import {
  CustomerCustomerOrder,
  CustomerCustomerOrderExtended,
} from "../../../model/customer/customerCustomerOrder.types";
import { mergeAndSortOrderTimelines } from "../../../utils/orderUtils";
import { CustomerOrderTimelineEntry } from "../../../model/customerOrder.types";
import CustomSelect, { SelectOption } from "../CustomSelect";
import OrderHistoryEntry from "../../orders/common/OrderHistoryEntry";

interface OrderUpdatesProps {
  context: DataContextCustomerType | DataContextAnonymousType;
  order?: CustomerCustomerOrderExtended;
}

interface OrderUpdatesState {
  type: SelectOption;
}

class OrderUpdates extends PureComponent<OrderUpdatesProps, OrderUpdatesState> {
  constructor(props: OrderUpdatesProps) {
    super(props);
    this.state = { type: { value: "full", label: "Full" } };
  }

  handleSetType = (type: SelectOption) => this.setState({ type });

  render() {
    const { context, order } = this.props;
    const { type } = this.state;

    const timeline = mergeAndSortOrderTimelines<
      CustomerCustomerOrder | CustomerCustomerOrderExtended,
      CustomerOrderTimelineEntry
    >(order ? [order] : context.customerOrder);
    // 50 updates should be enough
    const latestUpdates = timeline.slice(0, 50);
    const updated = order
      ? order.timeline.length
      : Array.from(new Set(latestUpdates.map((t) => t.order._id.toString()))).length;
    return (
      <div className="card h-100 bg-white">
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <div className="d-flex align-items-center">
              <span className="card-label fw-bolder fs-3 mb-1">Updates</span>
              <div className="blinking-dot align-self-center"></div>
            </div>
            <span className="text-muted fw-bold fs-7">
              {updated} {order ? "Updates for Order" : "Orders were updated"}
            </span>
          </h3>
          {!order && (
            <div className="card-toolbar" style={{ width: "120px" }}>
              <div className="w-100">
                <CustomSelect
                  options={[
                    { value: "full", label: "Full" },
                    { value: "compact", label: "Compact" },
                  ]}
                  onChange={(type: SelectOption) => {
                    this.handleSetType(type);
                  }}
                  matchFormControl={true}
                  value={type}
                  classNamePrefix="muted"
                />
              </div>
            </div>
          )}
        </div>
        <div className="card-body scroll-y mb-5">
          {latestUpdates.map((entry) => (
            <OrderHistoryEntry
              key={entry.order._id.toString() + entry.timelineEntry._id.toString()}
              entry={entry}
              context={context}
              showOrder={!order}
              hideUser={true}
              extended={order ? false : type.value === "full"}
              lessWidth={false}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default OrderUpdates;
