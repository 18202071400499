import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { SupplierExtended } from "../../../../model/supplier.types";
import { DataContextInternalType } from "../../../../context/dataContext";
import BaseListing from "../../../common/BaseListing";
import { paginate, PaginationState } from "../../../common/Pagination";
import Search from "../../../common/Search";
import { doFuseSearch } from "../../../../utils/baseUtils";
import ContactPersonWidget from "../../../common/ContactPersonWidget";
import ReactStarsWrapper from "../../../common/ReactStarsWrapper";
import { InternalArticleExtended } from "../../../../utils/productArticleUtils";

import { extendSupplier } from "../../../../utils/dataTransformationUtils";

interface AddEUStockModalProps {
  article: InternalArticleExtended;
  context: DataContextInternalType;
  onAddEUStock: (supplier: SupplierExtended) => void;
}

interface AddEUStockModalState extends PaginationState {
  search: string;
  show: boolean;
}

class AddEUStockModal extends PureComponent<AddEUStockModalProps, AddEUStockModalState> {
  constructor(props: AddEUStockModalProps) {
    super(props);
    this.state = { show: false, currentPage: 1, pageSize: 15, search: "" };
  }

  handleShow = () => this.setState({ show: true, search: "" });
  handleHide = () => this.setState({ show: false });

  handlePageChange = (page: number) => this.setState({ currentPage: page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });

  handleAddEUStock = (s: SupplierExtended) => {
    this.props.onAddEUStock(s);
    this.handleHide();
  };

  filterSuppliers = () => {
    const { article, context } = this.props;
    const { supplier } = context;
    const { search } = this.state;
    // Check that we don't add a supplier that already got an EU Stock
    const supWithEUStock = article.supplierEUStocks?.map((ses) => ses.supplier) || [];
    let filteredSuppliers = supplier.filter(
      (s) => !s.disabled && s.activated && !supWithEUStock.includes(s._id.toString())
    );
    if (search.trim()) {
      filteredSuppliers = doFuseSearch(filteredSuppliers, search, ["name"]);
    }
    return filteredSuppliers.map((fS) => extendSupplier(fS, context));
  };

  render() {
    const { show, search, currentPage, pageSize } = this.state;
    const headers = [
      { title: "Supplier Name" },
      { title: "Rating" },
      { title: "Mail" },
      { title: "Phone" },
      { title: "" },
    ];
    const suppliersFiltered = this.filterSuppliers();

    return (
      <>
        <button type="button" className="btn btn-light btn-sm float-right" onClick={this.handleShow}>
          Add EU Stock
        </button>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered size="xl">
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Add EU Stock</h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="w-50 float-right my-15 mt-0">
              <Search onSearch={this.handleSearch} value={search} />
            </div>
            <div className="mt-15">
              <BaseListing
                headerDefinition={headers}
                bodyContent={
                  <>
                    {paginate(suppliersFiltered, currentPage, pageSize).map((s) => {
                      return (
                        <tr key={s._id.toString()} className="text-white fs-6">
                          <td className="align-middle">
                            {s.name}
                            <ContactPersonWidget person={s.primaryPerson} spanClasses="text-muted fw-bold d-block" />
                          </td>
                          <td className="align-middle">
                            <ReactStarsWrapper classNames="mx-auto" value={s.rating} count={5} size={14} edit={false} />
                          </td>
                          <td className="align-middle">{s.phone}</td>
                          <td className="align-middle">{s.mail}</td>
                          <td className="align-middle">
                            <button
                              type="button"
                              className="btn btn-light btn-sm float-right mr-2"
                              onClick={() => this.handleAddEUStock(s)}
                            >
                              Add
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                }
                documents={suppliersFiltered}
                baseSize={15}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
                onPageSizeChange={this.handlePageSizeChange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-outline btn-text-white" onClick={this.handleHide}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default AddEUStockModal;
