import React from "react";

interface NewsTagsProps {
  tags: Array<string>;
  className?: string;
  tagClassName?: string;
}

/**
 * Function component represent the news tags of a NewsCard (internal,customer).
 */
const NewsTags: React.FunctionComponent<NewsTagsProps> = ({ tags, className, tagClassName }) => {
  return (
    <div
      className={"tagify " + className}
      style={{
        backgroundColor: "rgba(0,0,0,0)",
        border: "none",
        padding: 0,
        cursor: "inherit",
      }}
    >
      {tags.map((tag, key) => (
        <span key={tag + key} className={"badge badge-sm badge-medium-gray mr-1 mt-1 " + tagClassName}>
          {tag}
        </span>
      ))}
    </div>
  );
};

export default NewsTags;
