import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import {
  DataContextAnonymousType,
  DataContextCustomerType,
  DataContextInternalType,
  isAnonymousContext,
} from "../../../context/dataContext";
import { I_CREDITNOTECUSTOMER, I_CREDITNOTESAMPLE, Invoice } from "../../../model/invoice.types";
import { formatCurrency, formatDate, toAbsoluteUrl } from "../../../utils/baseUtils";
import { resolveFilePath } from "../../../utils/fileUtils";
import { extendInvoice, I_STATEDESCRIPTION } from "../../../utils/invoiceUtils";
import BaseListing from "../../common/BaseListing";
import userService from "../../../services/userService";
import { INTERNAL } from "../../../utils/userUtils";
import { EXTENDED_ORDER_TYPES } from "../../../utils/orderUtils";
import { isCustomerContract } from "../../../utils/customerContractUtils";
import { EXTENDED_CONTRACT_TYPES } from "../../../utils/contractUtils";
import { CO_CANCELED } from "../../../model/customerOrder.types";

interface OrderInvoicesProps {
  order: EXTENDED_ORDER_TYPES | EXTENDED_CONTRACT_TYPES;
  context: DataContextInternalType | DataContextCustomerType | DataContextAnonymousType;
}

interface OrderInvoicesState {
  invoices: Array<Invoice>;
}

class OrderInvoices extends PureComponent<OrderInvoicesProps, OrderInvoicesState> {
  headerDefinition = [
    { title: "Invoice", style: { width: "30%" } },
    { title: "Customer", style: { width: "20%" } },
    { title: "Date", style: { width: "15%" } },
    { title: "Payment", style: { width: "10%" } },
    { title: "State", style: { width: "10%" } },
    { title: "File", className: "text-right", style: { width: "15%" } },
  ];

  constructor(props: OrderInvoicesProps) {
    super(props);
    this.state = { invoices: this.getRelatedInvoices() };
  }

  /**
   * Collect all invoices related to the customer order.
   * @returns { Array<Invoice> } List of all related invoices
   */
  getRelatedInvoices = (): Array<Invoice> => {
    const { context, order } = this.props;
    if (isAnonymousContext(context)) return [];
    return context.invoice.filter(
      (i) =>
        i.relatedOrder &&
        ![I_CREDITNOTESAMPLE, I_CREDITNOTECUSTOMER].includes(i.type) &&
        (i.relatedOrder === order._id.toString() ||
          (isCustomerContract(order) && order.calls.some((c) => c._id.toString() === i.relatedOrder)))
    );
  };

  render() {
    const { order, context } = this.props;
    const { invoices } = this.state;
    const view = userService.getUserType();

    return (
      <div className="card bg-white">
        <div className="card-header border-0 mt-5">
          <div className="card-title flex-column">
            <h2 className="mb-1">Invoices</h2>
          </div>
          {view === INTERNAL && order.state !== CO_CANCELED && (
            <div className="card-toolbar">
              <Link to={"/createInvoice/" + order._id.toString()}>
                <button type="button" className="btn btn-outline btn-outline-light btn-sm ml-2 mr-3">
                  Create Invoice
                </button>
              </Link>
              <Link to={"/createCreditNote/" + order._id.toString()}>
                <button type="button" className="btn btn-outline btn-outline-light btn-sm">
                  Create Credit Note
                </button>
              </Link>
            </div>
          )}
        </div>
        <div className="card-body p-9 pt-0">
          <div className="table-responsive mt-5 pt-2">
            <BaseListing
              headerDefinition={this.headerDefinition}
              bodyContent={
                <>
                  {invoices.length > 0 ? (
                    invoices.map((i) => {
                      const invoiceExtended = extendInvoice(i, context);
                      return (
                        <tr key={i._id.toString()}>
                          <td className="align-middle">
                            <div className="fw-bold text-white">INV-{i.invoiceNumber}</div>
                            <div className="text-muted">{formatCurrency(i.total, i.currency)}</div>
                          </td>
                          <td className="align-middle">
                            <span className="fw-bold text-white">{invoiceExtended.company.name}</span>
                          </td>
                          <td className="align-middle">
                            <span className="fw-bold text-success">{formatDate(i.invoiceDate)}</span>
                          </td>
                          <td className="align-middle">
                            <span className="fw-bold text-white">
                              {i.paymentTarget === -1 ? "In Advance" : i.paymentTarget + " days"}
                            </span>
                          </td>
                          <td className="align-middle">
                            <span className="fw-bold text-white">
                              {I_STATEDESCRIPTION.find((s) => s.value === i.state)!.label}
                            </span>
                          </td>
                          <td className="align-middle text-right">
                            <a href={resolveFilePath(i.file)} target="_blank" rel="noopener noreferrer">
                              <img
                                alt="pdf"
                                className="w-30px me-3"
                                src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                              />
                            </a>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={6}>
                        <span className="fw-bold text-white">No invoices available</span>
                      </td>
                    </tr>
                  )}
                </>
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default OrderInvoices;
