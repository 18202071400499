import React, { useEffect, useState } from "react";

interface DateInputProps {
  value: Date | null;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  name: string;
  min?: Date;
  max?: Date;
  disabled?: boolean;
  classes?: string;
  allowClear?: boolean;
  tabIndex?: number;
}

const DateInput: React.FunctionComponent<DateInputProps> = ({
  value,
  onBlur,
  onClick,
  name,
  min,
  max,
  disabled,
  classes,
  allowClear,
  tabIndex,
}) => {
  const [date, setDate] = useState(value);

  useEffect(() => {
    setDate(value);
  }, [value]);

  const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if ((e.target.valueAsDate || allowClear) && onBlur) onBlur(e);
  };

  const formatDate = (date: Date | undefined) => {
    // needed to display the date in the user's local timezone; en-CA ensures correct format for input field
    return date ? date.toLocaleDateString("en-CA", { year: "numeric", month: "2-digit", day: "2-digit" }) : undefined;
  };

  return (
    <input
      type="date"
      className={classes ? classes : "form-control"}
      name={name}
      min={min ? formatDate(min) : undefined}
      max={max ? formatDate(max) : undefined}
      value={date ? formatDate(date) : undefined}
      disabled={!!disabled}
      tabIndex={tabIndex}
      onChange={(e) => (e.target.valueAsDate || allowClear) && setDate(e.target.valueAsDate)}
      onBlur={disabled ? undefined : handleOnBlur}
      onClick={onClick ? onClick : undefined}
    />
  );
};

export default DateInput;
