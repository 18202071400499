import React from "react";
import { Input } from "../../../common/Input";
import { EURO } from "../../../../utils/currencyUtils";
import { Duty } from "../../../../model/commodity.types";

interface SeaFreightCustomsViewProps {
  duty: Duty;
  customsFlatRate: number;
  customsFeeAgency: number;
  onChangeDuty: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeNumValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SeaFreightCustomsView: React.FunctionComponent<SeaFreightCustomsViewProps> = ({
  duty,
  customsFlatRate,
  customsFeeAgency,
  onChangeDuty,
  onChangeNumValue,
}) => {
  return (
    <>
      <h3 className="mb-3 mt-7">Customs</h3>
      <div className="row">
        <div className="col-xl-3">
          <label className="fs-5 fw-bold mb-2">Duty Percentage</label>
          <div className="input-group">
            <Input
              className="form-control custom-form-control"
              type={"number"}
              min={0}
              name={"percentage"}
              value={duty.percentage}
              onChange={onChangeDuty}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                %
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3">
          <label className="fs-5 fw-bold mb-2">Dumping Fee per 100kg</label>
          <div className="input-group">
            <Input
              className="form-control custom-form-control"
              type={"number"}
              name={"dumpingFee"}
              min={0}
              value={duty.dumpingFee || 0}
              placeholder={"None"}
              onChange={onChangeDuty}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                {EURO}/100kg
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3">
          <label className="fs-5 fw-bold mb-2">Customs Flat Rate</label>
          <div className="input-group">
            <Input
              className="form-control custom-form-control"
              type={"number"}
              name={"customsFlatRate"}
              min={0}
              value={customsFlatRate}
              onChange={onChangeNumValue}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                {EURO}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3">
          <label className="fs-5 fw-bold mb-2">Fee for Delivery Agency</label>
          <div className="input-group">
            <Input
              className="form-control custom-form-control"
              type={"number"}
              name={"customsFeeAgency"}
              min={0}
              value={customsFeeAgency * 100}
              onChange={onChangeNumValue}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                %
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SeaFreightCustomsView;
