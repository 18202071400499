import React, { PureComponent } from "react";
import DateInput from "../../common/DateInput";
import { Input } from "../../common/Input";
import { SelectOption } from "../../common/CustomSelect";
import { I_CREDIT_NOTE } from "../../../model/invoice.types";

interface InvoiceHeaderProps {
  type: SelectOption;
  companyName: string;
  companyAddress: string;
  companyVAT: string;
  invoiceNumber: string;
  reminderOrCancelation: boolean;
  deliveryDate: Date;
  invoiceDate: Date;
  onDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

class InvoiceHeader extends PureComponent<InvoiceHeaderProps> {
  render() {
    const {
      type,
      invoiceNumber,
      invoiceDate,
      reminderOrCancelation,
      deliveryDate,
      companyName,
      companyAddress,
      companyVAT,
      onInputChange,
      onDateChange,
    } = this.props;
    return (
      <div className="row gx-10 mb-5">
        <div className="col-lg-6">
          <div className="mb-5">
            <Input
              type="text"
              className="form-control custom-form-control"
              placeholder="Company"
              value={companyName}
              name={"companyName"}
              disabled={reminderOrCancelation}
              onChange={onInputChange}
            />
          </div>
          <div className="mb-5">
            <textarea
              rows={3}
              className="form-control  custom-form-control"
              placeholder="Address"
              value={companyAddress}
              name={"companyAddress"}
              disabled={reminderOrCancelation}
              onChange={onInputChange}
            />
          </div>
          <div className="mb-5">
            <Input
              className="form-control custom-form-control fw-bolder"
              placeholder="VAT-ID"
              type="text"
              value={companyVAT}
              name={"companyVAT"}
              disabled={reminderOrCancelation}
              onChange={onInputChange}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="d-flex align-items-center justify-content-end flex-equal mb-5">
            <div className="fs-6 fw-bolder text-white text-nowrap">
              {type.value !== I_CREDIT_NOTE ? "Invoice" : "Credit Note"} Number
            </div>
            <div className="position-relative d-flex align-items-center w-150px pl-3">
              <Input
                className="form-control custom-form-control fw-bolder disabled"
                value={invoiceNumber}
                placeholder={"Auto Generated"}
              />
            </div>
          </div>
          {type.value !== I_CREDIT_NOTE && (
            <div className="d-flex align-items-center justify-content-end flex-equal mb-5">
              <div className="fs-6 fw-bolder text-white text-nowrap">Delivery Date</div>
              <div className="position-relative d-flex align-items-center w-150px pl-3">
                <DateInput
                  classes="form-control custom-form-control"
                  value={deliveryDate}
                  onBlur={onDateChange}
                  name={"deliveryDate"}
                />
              </div>
            </div>
          )}
          <div className="d-flex align-items-center justify-content-end flex-equal mb-5">
            <div className="fs-6 fw-bolder text-white text-nowrap">
              {type.value !== I_CREDIT_NOTE ? "Invoice" : "Credit Note"} Date
            </div>
            <div className="position-relative d-flex align-items-center w-150px pl-3">
              <DateInput
                classes="form-control custom-form-control"
                value={invoiceDate}
                onBlur={onDateChange}
                disabled={reminderOrCancelation}
                name={"invoiceDate"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InvoiceHeader;
