import React, { useEffect, useState } from "react";

interface TextareaProps
  extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {}

export const Textarea: React.FunctionComponent<TextareaProps> = ({ value, onChange, onBlur, ...props }) => {
  const [internalVal, setValue] = useState(value);
  if (onBlur && onChange) console.error("onBlur and onChange given. onChange will not be utilized");
  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <textarea
      {...props}
      value={internalVal}
      onChange={(e) => setValue(e.target.value)}
      onBlur={onBlur ? onBlur : onChange}
    />
  );
};
