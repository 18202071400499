import _ from "lodash";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { Commodity } from "../../../../model/commodity.types";
import { SupplierExtended } from "../../../../model/supplier.types";
import { DataContextInternalType } from "../../../../context/dataContext";
import BaseListing from "../../../common/BaseListing";
import { paginate, PaginationState } from "../../../common/Pagination";
import { getArticleProperty } from "../../../../utils/commodityUtils";
import { PropertyType, resolveProperties } from "../../../../utils/propertyUtils";
import { Property } from "../../../../model/property.types";
import Search from "../../../common/Search";
import { doFuseSearch } from "../../../../utils/baseUtils";
import { FinishedProduct } from "../../../../model/finishedProduct.types";

interface AddEUStockModalProps {
  supplier: SupplierExtended;
  context: DataContextInternalType;
  onAddEUStock: (article: Commodity | FinishedProduct) => void;
  finishedProduct: boolean;
}

interface AddEUStockModalState extends PaginationState {
  search: string;
  show: boolean;
}

class AddEUStockModal extends PureComponent<AddEUStockModalProps, AddEUStockModalState> {
  constructor(props: AddEUStockModalProps) {
    super(props);
    this.state = { show: false, currentPage: 1, pageSize: 15, search: "" };
  }

  handleShow = () => this.setState({ show: true, search: "" });
  handleHide = () => this.setState({ show: false });

  handlePageChange = (page: number) => this.setState({ currentPage: page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });

  handleAddEUStock = (c: Commodity | FinishedProduct) => {
    this.props.onAddEUStock(_.cloneDeep(c));
    this.handleHide();
  };

  filterArticles = () => {
    const { finishedProduct: isFinishedProduct } = this.props;
    const { commodity, finishedProduct } = this.props.context;
    const { search } = this.state;
    let filteredArticles = isFinishedProduct
      ? finishedProduct.filter((fp) => fp.approved && !fp.disabled)
      : commodity.filter((c) => c.approved && !c.disabled);
    if (search.trim()) {
      if (isFinishedProduct) {
        filteredArticles = doFuseSearch(filteredArticles as Array<FinishedProduct>, search, [
          "title.en",
          "subtitle.en",
        ]);
      } else {
        filteredArticles = doFuseSearch(filteredArticles as Array<Commodity>, search, ["title.en", "subtitle.en"]);
      }
    }
    return filteredArticles;
  };

  getPaginationContent = (
    articlesFiltered: Array<Commodity | FinishedProduct>,
    currentPage: number,
    pageSize: number
  ) => {
    const { context } = this.props;
    return paginate(articlesFiltered, currentPage, pageSize).map((c) => {
      const properties = resolveProperties(c.properties, context.property);
      return (
        <tr key={c._id.toString()} className="text-white fs-6">
          <td className="align-middle">
            {c.title.en}
            <br />
            {c.subtitle.en}
          </td>
          <td className="align-middle">
            {(getArticleProperty(properties, PropertyType.CATEGORY) as Property)?.description.en || "-"}
          </td>
          <td className="align-middle">
            {(getArticleProperty(properties, PropertyType.COMPOSITION) as Property)?.description.en || "-"}
          </td>
          <td className="align-middle">{c.organic ? "Yes" : "No"}</td>
          <td className="align-middle">
            <button
              type="button"
              className="btn btn-light btn-sm float-right mr-2"
              onClick={() => this.handleAddEUStock(c)}
            >
              Add
            </button>
          </td>
        </tr>
      );
    });
  };

  render() {
    const { finishedProduct } = this.props;
    const { show, search, currentPage, pageSize } = this.state;

    const headers = [
      { title: `${finishedProduct ? "Finished Product" : "Commodity"} Title` },
      { title: "Category" },
      { title: "Composition" },
      { title: "Organic" },
      { title: "" },
    ];

    const articlesFiltered = this.filterArticles();

    return (
      <>
        <button type="button" className="btn btn-light btn-sm float-right" onClick={this.handleShow}>
          Add EU Stock
        </button>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered size="xl">
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Add EU Stock</h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="w-50 float-right m-15 mt-0">
              <Search onSearch={this.handleSearch} value={search} />
            </div>
            <div className="my-15">
              <BaseListing
                headerDefinition={headers}
                bodyContent={<>{this.getPaginationContent(articlesFiltered, currentPage, pageSize)}</>}
                documents={articlesFiltered}
                baseSize={15}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
                onPageSizeChange={this.handlePageSizeChange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-outline btn-text-white" onClick={this.handleHide}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default AddEUStockModal;
