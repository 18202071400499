import _ from "lodash";
import React, { PureComponent } from "react";
import { WarehouseCalculationValues } from "../../../utils/priceCalculationUtils";
import { DataContextInternal } from "../../../context/dataContext";
import { Input } from "../../common/Input";
import { EURO, USD } from "../../../utils/currencyUtils";

interface WarehouseCalculationViewProps {
  calculationValues: WarehouseCalculationValues;
}

interface WarehouseCalculationViewState {
  calculationValues: WarehouseCalculationValues;
}

class WarehouseCalculationView extends PureComponent<WarehouseCalculationViewProps, WarehouseCalculationViewState> {
  static contextType = DataContextInternal;
  context!: React.ContextType<typeof DataContextInternal>;

  constructor(props: WarehouseCalculationViewProps) {
    super(props);
    this.state = {
      calculationValues: _.cloneDeep(props.calculationValues),
    };
  }

  componentDidUpdate(prevProps: WarehouseCalculationViewProps) {
    if (!_.isEqual(prevProps.calculationValues, this.props.calculationValues)) {
      this.setState({ calculationValues: _.cloneDeep(this.props.calculationValues) });
    }
  }

  handleChangeNumValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    const name = e.target.name as keyof WarehouseCalculationValues;
    _.set(calculationValues, name, +e.target.value);
    this.setState({ calculationValues });
  };

  /**
   * Used for parent component to get state values
   */
  getCalculationValues = () => {
    return this.state.calculationValues;
  };

  render() {
    const { calculationValues } = this.state;
    const { minimumAbsoluteMargin, totalLogisticCost } = calculationValues;
    return (
      <div>
        <h3 className="mb-3 mt-7">Logistic</h3>
        <div className="row">
          <div className="col-xl-4">
            <label className="fs-5 fw-bold">Total Logistic Cost</label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type={"number"}
                min={0}
                name={"totalLogisticCost.cost"}
                value={totalLogisticCost.cost}
                onChange={this.handleChangeNumValue}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  {totalLogisticCost.currency || USD}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <label className="fs-5 fw-bold">Amount for Logistic Cost</label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type={"number"}
                min={0}
                name={"totalLogisticCost.amount"}
                value={totalLogisticCost.amount}
                onChange={this.handleChangeNumValue}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  {totalLogisticCost.unit || "kg"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3 className="mb-3 mt-7">Additional Settings</h3>
        <div className="row">
          <div className="col-xl-4">
            <label className="fs-5 fw-bold">Minimum Total Margin</label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type={"number"}
                min={0}
                name={"minimumAbsoluteMargin.value"}
                value={minimumAbsoluteMargin.value}
                onChange={this.handleChangeNumValue}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  {minimumAbsoluteMargin.currency || EURO}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WarehouseCalculationView;
