import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import BaseListing, { BaseListingHeaderDefinition } from "../../../common/BaseListing";
import { paginate, PaginationState } from "../../../common/Pagination";
import { Commodity } from "../../../../model/commodity.types";
import { CONFIG, getConfiguration } from "../../../../utils/configurationUtils";
import { AnonymousConfiguration } from "../../../../model/configuration/anonymousConfiguration.types";
import { DataContextInternalType } from "../../../../context/dataContext";
import AddArticlePriceToAnonymousViewModal from "../modals/AddArticlePriceToAnonymousViewModal";
import { FinishedProduct } from "../../../../model/finishedProduct.types";
import SimpleConfirmationModal from "../../../common/SimpleConfirmationModal";
import { getFullArticleName } from "../../../../utils/productArticleUtils";
import { isFinishedProduct } from "../../../../utils/finishedProductUtils";
import userService from "../../../../services/userService";
import { Action, CONFIGURATION, transaction } from "../../../../services/dbService";
import { resolveProperties } from "../../../../utils/propertyUtils";

interface AnonymousViewArticlePricesProps {
  context: DataContextInternalType;
}

interface AnonymousViewArticlePricesState extends PaginationState {
  anonymousViewArticlePrices: Array<Commodity | FinishedProduct>;
  saving: boolean;
}

class AnonymousViewArticlePrices extends PureComponent<
  AnonymousViewArticlePricesProps,
  AnonymousViewArticlePricesState
> {
  headerDefinition: Array<BaseListingHeaderDefinition> = [
    { title: "Article", style: { width: "40%" } },
    { title: "Type", style: { width: "20%" } },
    { title: "Properties", style: { width: "30%" } },
    { title: "", style: { width: "10%" }, className: "text-right" },
  ];

  constructor(props: AnonymousViewArticlePricesProps) {
    super(props);
    this.state = { currentPage: 1, pageSize: 25, saving: false, anonymousViewArticlePrices: [] };
  }

  async componentDidMount() {
    await this.generateArticleData();
  }

  handleChangeCurrentPage = (currentPage: number) => this.setState({ currentPage });
  handleChangePageSize = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });

  handleRemoveArticle = async (id: string, type: "commodity" | "finishedProduct") => {
    this.setState({ saving: true });
    try {
      let pre;
      let post;
      let pull;
      if (type === "commodity") {
        pre = { commodities: [id] };
        post = { commodities: [] };
        pull = { "values.commodities": id };
      } else {
        pre = { finishedProducts: [id] };
        post = { finishedProducts: [] };
        pull = { "values.finishedProducts": id };
      }
      const timelineEntry = {
        _id: new BSON.ObjectId(),
        date: new Date(),
        person: userService.getUserId(),
        pre,
        post,
      };
      const action: Action = {
        collection: CONFIGURATION,
        filter: { key: CONFIG.ANONYMOUS },
        update: { lastUpdate: new Date() },
        push: {
          timeline: timelineEntry,
        },
        pull,
      };
      const result = await transaction([action]);
      if (result) {
        await this.generateArticleData();
        toast.success("Article successfully removed from Anonymous View.");
      } else {
        toast.error("Articles could not be removed from Anonymous View. Please try again later.");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  handleAddSuccess = async () => {
    await this.generateArticleData();
  };

  generateArticleData = async () => {
    const { context } = this.props;
    const config = await getConfiguration<AnonymousConfiguration>(CONFIG.ANONYMOUS);
    if (config) {
      const anonymousViewArticlePrices: Array<Commodity | FinishedProduct> = [];
      for (let i = 0; i < config.values.commodities.length; i++) {
        const c = config.values.commodities[i];
        const commodity = context.commodity.find((com) => com._id.toString() === c);
        if (commodity) anonymousViewArticlePrices.push(commodity);
      }
      for (let i = 0; i < config.values.finishedProducts.length; i++) {
        const fp = config.values.finishedProducts[i];
        const finishedProduct = context.finishedProduct.find((finPro) => finPro._id.toString() === fp);
        if (finishedProduct) anonymousViewArticlePrices.push(finishedProduct);
      }
      this.setState({ anonymousViewArticlePrices });
    }
  };

  render() {
    const { context } = this.props;
    const { currentPage, pageSize, anonymousViewArticlePrices, saving } = this.state;

    const articlesPagination = paginate(anonymousViewArticlePrices, currentPage, pageSize);

    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="card card-xl-stretch bg-white">
              <div className="card-body" style={{ minHeight: "600px" }}>
                <h3 className="card-title align-items-start flex-column mb-15">
                  <span className="card-label fw-bolder mb-3 fs-3rem">Anonymous View Articles with Prices</span>
                </h3>
                <BaseListing
                  headerDefinition={this.headerDefinition}
                  bodyContent={
                    <>
                      {articlesPagination.map((a) => {
                        const properties = resolveProperties(a.properties, context.property);
                        return (
                          <tr key={a._id.toString()}>
                            <td className="text-white align-middle">{getFullArticleName(a)}</td>
                            <td className="text-white align-middle">
                              {isFinishedProduct(a) ? "Finished Product" : "Commodity"}
                            </td>
                            <td className="text-white align-middle">
                              {properties.map((p) => (
                                <span
                                  key={p._id.toString()}
                                  className="badge badge-gray fw-bolder m-1 px-2 py-2 text-white"
                                >
                                  {p.name.en}
                                </span>
                              ))}
                            </td>
                            <td className="text-white align-middle text-right">
                              <SimpleConfirmationModal.SimpleConfirmationModalButton
                                buttonText={<i className="fa fa-trash fs-7 text-muted text-hover-danger p-0" />}
                                buttonClasses="btn btn-text btn-sm px-2 py-0 float-right"
                                onConfirm={() =>
                                  this.handleRemoveArticle(
                                    a._id.toString(),
                                    isFinishedProduct(a) ? "finishedProduct" : "commodity"
                                  )
                                }
                                modalTitle={`Remove ${getFullArticleName(a)} from Anonymous View`}
                                modalDescription={
                                  <span className="text-white">
                                    Do you really want to remove <b>{getFullArticleName(a)}</b> from the Anonymous View?
                                  </span>
                                }
                                disabled={saving}
                                cancelButtonText="Close"
                                confirmButtonText="Confirm"
                                size="md"
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  }
                  documents={anonymousViewArticlePrices}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  baseSize={25}
                  onPageChange={this.handleChangeCurrentPage}
                  onPageSizeChange={this.handleChangePageSize}
                />
              </div>
              <div className="card-footer text-right border-0">
                <AddArticlePriceToAnonymousViewModal
                  onSuccess={this.handleAddSuccess}
                  context={context}
                  anonymousViewArticles={anonymousViewArticlePrices}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AnonymousViewArticlePrices;
