import React from "react";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import { O_SORTOPTIONS } from "../../../utils/orderUtils";
import { SortOrder } from "../../../utils/filterUtils";
import {
  CO_FILTER_STATES,
  CO_ARRIVAL_STATUS,
  CO_TRANSPORT_MODE,
  CO_SORTOPTIONS,
} from "../../../utils/customerOrderUtils";
import { SAMO_FILTER_STATES } from "../../../utils/sampleOrderUtils";
import SortIcon from "../../common/SortIcon";
import { DataContextAnonymousType, DataContextCustomerType } from "../../../context/dataContext";
import { PropertyType } from "../../../utils/propertyUtils";

interface OrderFilterProps {
  search: string;
  status?: SelectOption;
  deliveryStatus?: SelectOption;
  transport?: SelectOption;
  category?: SelectOption;
  orderType: "customer" | "sample";
  sortColumn: SelectOption;
  sortOrder: SortOrder;
  context: DataContextCustomerType | DataContextAnonymousType;
  onChangeSelect: (option: SelectOption | undefined, name: string) => void;
  onToggleSortOrder: () => void;
}

const OrderFilter: React.FunctionComponent<OrderFilterProps> = ({
  search,
  status,
  deliveryStatus,
  transport,
  category,
  orderType,
  sortOrder,
  sortColumn,
  context,
  onChangeSelect,
  onToggleSortOrder,
}) => {
  const sortOptions = O_SORTOPTIONS.slice();
  if (search.trim()) sortOptions.unshift({ value: "", label: "Best Match" });
  const categories = context.property.filter((p) => p.type === PropertyType.CATEGORY);
  return (
    <div className="row g-8">
      <div className="col-lg-3">
        <label className="fs-6 form-label fw-bolder text-dark">Status</label>
        <CustomSelect
          options={orderType === "sample" ? SAMO_FILTER_STATES : CO_FILTER_STATES}
          placeholder="All States"
          matchFormControl={true}
          isClearable
          onChange={(e: SelectOption) => onChangeSelect(e, "status")}
          value={status}
        />
      </div>
      <div className="col-lg-3">
        <label className="fs-6 form-label fw-bolder text-dark">Arrival</label>
        <CustomSelect
          options={CO_ARRIVAL_STATUS}
          matchFormControl={true}
          placeholder={"All Arrivals"}
          isClearable={true}
          onChange={(e: SelectOption) => onChangeSelect(e, "deliveryStatus")}
          value={deliveryStatus}
        />
      </div>
      {orderType === "sample" ? (
        <div className="col-lg-3">
          <label className="fs-6 form-label fw-bolder text-dark">Commodity Category</label>
          <CustomSelect
            options={categories.map((p) => {
              return { value: p._id.toString(), label: p.name.en };
            })}
            placeholder="All Categories"
            matchFormControl={true}
            isClearable
            onChange={(e: SelectOption) => onChangeSelect(e, "category")}
            value={category}
          />
        </div>
      ) : (
        <div className="col-lg-3">
          <label className="fs-6 form-label fw-bolder text-dark">Transport</label>
          <CustomSelect
            options={CO_TRANSPORT_MODE}
            matchFormControl={true}
            placeholder={"All Transport Modes"}
            isClearable={true}
            onChange={(e: SelectOption) => onChangeSelect(e, "transport")}
            value={transport}
          />
        </div>
      )}
      <div className="col-lg-3">
        <label className="fs-6 form-label fw-bolder text-dark">Sort</label>
        <label className="form-label text-muted float-right cursor-pointer btn-text">
          <SortIcon sortOrder={sortOrder} onToggleSortOrder={onToggleSortOrder} />
        </label>
        <CustomSelect
          options={CO_SORTOPTIONS}
          matchFormControl={true}
          placeholder={"Order Date"}
          onChange={(e: SelectOption) => onChangeSelect(e, "sortColumn")}
          value={sortColumn}
        />
      </div>
    </div>
  );
};

export default OrderFilter;
