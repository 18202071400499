import { BSON } from "realm-web";
import { callFunction } from "../services/dbService";
import userService from "../services/userService";
import { Favorites } from "../model/favorites.types";
import { Commodity } from "../model/commodity.types";
import { CustomerCommodity } from "../model/customer/customerCommodity.types";
import { FinishedProduct } from "../model/finishedProduct.types";
import { CustomerFinishedProduct } from "../model/customer/customerFinishedProduct.types";

export interface FavoritesExtended extends Omit<Favorites, "favorites"> {
  favorites: Array<Commodity | CustomerCommodity | FinishedProduct | CustomerFinishedProduct>;
}

// Backend functions
export const UPSERTFAVORITES = "upsertFavorites";

/**
 * Calls the upsert favorites function in backend.
 * @param favorite Commodity id to add or remove to/from favorites
 * @param remove True for remove, else add
 * @returns {Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false>} Result of the function
 */
export async function upsertFavorites(
  favorite: string,
  remove?: boolean
): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> {
  const company = userService.getCompany();
  if (!company) return false;
  return await callFunction(UPSERTFAVORITES, [company, favorite, !!remove]);
}
