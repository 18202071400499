import React from "react";
import CommodityPageSellingPrices from "../CommodityPageSellingPrices";
import { InternalArticleExtended } from "../../../../utils/productArticleUtils";
import ArticleGraduatedPrices from "../ArticleGraduatedPrices";

interface ArticlePageSellingPricesProps {
  article: InternalArticleExtended;
}

const ArticleSellingPricesTab: React.FC<ArticlePageSellingPricesProps> = ({ article }) => {
  return (
    <>
      <CommodityPageSellingPrices article={article} />
      <div className="my-5" />
      <ArticleGraduatedPrices article={article} />
    </>
  );
};

export default ArticleSellingPricesTab;
