import _ from "lodash";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { CommodityOfferRequest } from "../../../../model/commodityOfferRequest.types";
import { formatDate, toAbsoluteUrl } from "../../../../utils/baseUtils";
import { resolveFilePath } from "../../../../utils/fileUtils";
import OrderHistoryEntry from "../../../orders/common/OrderHistoryEntry";
import { DataContextSupplier } from "../../../../context/dataContext";

interface CommodityOfferRequestDetailsModalProps {
  commodityOfferRequest: CommodityOfferRequest;
  context: React.ContextType<typeof DataContextSupplier>;
}

interface CommodityOfferRequestDetailsModalState {
  commodityOfferRequest: CommodityOfferRequest;
  saving: boolean;
  show: boolean;
}

class CommodityOfferRequestDetailsModal extends PureComponent<
  CommodityOfferRequestDetailsModalProps,
  CommodityOfferRequestDetailsModalState
> {
  constructor(props: CommodityOfferRequestDetailsModalProps) {
    super(props);
    this.state = { show: false, saving: false, commodityOfferRequest: _.cloneDeep(props.commodityOfferRequest) };
  }

  componentDidUpdate(prevProps: Readonly<CommodityOfferRequestDetailsModalProps>) {
    if (!_.isEqual(prevProps.commodityOfferRequest, this.props.commodityOfferRequest)) {
      this.setState({ commodityOfferRequest: _.cloneDeep(this.props.commodityOfferRequest) });
    }
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState({ show: false });

  render() {
    const { context } = this.props;
    const { show, commodityOfferRequest, saving } = this.state;
    return (
      <>
        <button className="btn btn-sm btn-outline btn-outline-light" onClick={this.handleShow}>
          Details
        </button>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Request Details</h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-5">
              <div className="col-md-4 mt-5">
                <div className="fs-6">
                  <div className="fw-bolder">Request Date</div>
                  <div className="text-muted">{formatDate(commodityOfferRequest.created)}</div>
                </div>
              </div>
              <div className="col-md-4 mt-5">
                <div className="fs-6">
                  {commodityOfferRequest.coa ? (
                    <a href={resolveFilePath(commodityOfferRequest.coa.path)} target="_blank" rel="noopener noreferrer">
                      <div className="align-items-center">
                        <img alt="pdf" className="w-30px mr-2" src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} />
                        <span className="text-white fw-bold align-middle">
                          COA
                          {commodityOfferRequest.coa.size && (
                            <small className="text-muted ml-2">
                              {Math.round(commodityOfferRequest.coa.size / 1024)} KB
                            </small>
                          )}
                        </span>
                      </div>
                    </a>
                  ) : (
                    <>
                      <div className="fw-bolder mt-5">COA</div>
                      <div className="text-error">Missing</div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-4 mt-5">
                <div className="fs-6">
                  {commodityOfferRequest.specification ? (
                    <a
                      href={resolveFilePath(commodityOfferRequest.specification.path)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="align-items-center">
                        <img alt="pdf" className="w-30px mr-2" src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} />
                        <span className="text-white fw-bold align-middle">
                          Spec.
                          {commodityOfferRequest.specification.size && (
                            <small className="text-muted ml-2">
                              {Math.round(commodityOfferRequest.specification.size / 1024)} KB
                            </small>
                          )}
                        </span>
                      </div>
                    </a>
                  ) : (
                    <>
                      <div className="fw-bolder mt-5">Specification</div>
                      <div className="text-error">Missing</div>
                    </>
                  )}
                </div>
              </div>
              <div className="card bg-light border-none mt-6 p-3 py-5">
                {commodityOfferRequest.timeline.map((entry) => (
                  <OrderHistoryEntry
                    key={entry._id.toString()}
                    entry={{ order: commodityOfferRequest, timelineEntry: entry, recent: false }}
                    context={context}
                    showOrder={false}
                  />
                ))}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className={"btn btn-sm btn-outline btn-outline-light mr-2" + (saving ? " disabled" : "")}
              disabled={saving}
              onClick={this.handleHide}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CommodityOfferRequestDetailsModal;
