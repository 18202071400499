import { SupplierOrderExtended } from "../../model/supplierOrder.types";
import { formatCurrency, formatDate } from "../baseUtils";
import { Seaport } from "../../model/seaport.types";
import { Airport } from "../../model/airport.types";
import { getSeaportName } from "../seaportUtils";
import { getAirportName } from "../airportUtils";
import { Notify } from "../../model/notify.types";
import { ARBITRATIONTEXT } from "./templateUtils";
import { formatAddress } from "../addressUtils";
import { Address } from "../../model/commonTypes";
import { T_EUSTOCK } from "../../model/customerOrder.types";
import { formatArticleUnit, isCommoditySnapshot } from "../productArticleUtils";

export const shippingInstructionsSea =
  "Delivery: prompt shipment by sea to Hamburg. Please send the collection date once available.\n" +
  "Documents: please upload copies of all shipping documents in advance, especially COA.\n" +
  "We need the Original documents, especially released BL at least 10 days prior to ETA port Hamburg.\n\n" +
  "Quality: freshly produced material, from one batch per product as agreed specification. According to EC with Pesticides, ETO, Solvents Residue, Microbiology, Heavy Metals, PAH4 and Benzopyrene.\n\n" +
  "Please send samples of 3 x 100gr separately to our following address:\n" +
  "Glomm Logistics GmbH\n" +
  "c/o RAWBIDS GmbH\n" +
  "Gutenbergring 22\n" +
  "D-22848 Norderstedt\n" +
  "Contact: +49 151 26057562, yo@rawbids.com\n" +
  "Please do not issue your sample shipping invoice to our warehouse but to our address.\n" +
  "RAWBIDS GmbH, Willy-Brandt-Straße 23, 20457 Hamburg\n\n" +
  "Remarks: please state the measurements of the pallet(s) and the quantity net/gross per pallet together with your confirmation. The shipping marks will be provided after the CoA was received and will be sent to you then.\n\n" +
  "We expect always the same quality. In case of any changes (eg. Specification, Production Process, Production site) " +
  "you are obliged to inform us in written form before delivery. In case of closing the production of the product(s) " +
  "you are obliged to inform us in written form at least 3 months before.";

export const shippingInstructionsAir =
  "Delivery: prompt shipment by air. Please send the collection date once available.\n" +
  "Documents: please upload copies of all shipping documents in advance, especially COA.\n" +
  "We need the Original documents, especially AWB at least 10 days prior to ETA airport Frankfurt.\n\n" +
  "Quality: freshly produced material, from one batch per product as agreed specification. According to EC with Pesticides, ETO, Solvents Residue, Microbiology, Heavy Metals, PAH4 and Benzopyrene.\n\n" +
  "Please send samples of 3 x 100gr separately to our following address:\n" +
  "Glomm Logistics GmbH\n" +
  "c/o RAWBIDS GmbH\n" +
  "Gutenbergring 22\n" +
  "D-22848 Norderstedt\n" +
  "Contact: +49 151 26057562, yo@rawbids.com\n" +
  "Please do not issue your sample shipping invoice to our warehouse but to our address.\n" +
  "RAWBIDS GmbH, Willy-Brandt-Straße 23, 20457 Hamburg\n\n" +
  "Remarks: please state the measurements of the pallet(s) and the quantity net/gross per pallet together with your confirmation. The shipping marks will be provided after the CoA was received and will be sent to you then.\n\n" +
  "We expect always the same quality. In case of any changes (eg. Specification, Production Process, Production site) " +
  "you are obliged to inform us in written form before delivery. In case of closing the production of the product(s) " +
  "you are obliged to inform us in written form at least 3 months before.";

export const shippingInstructionsEUStock =
  "Delivery: prompt shipment. Please send the collection date and your warehouse address asap.\n" +
  "Documents: please provide copies of all shipping documents in advance, especially COA.\n" +
  "Quality: freshly produced material, from one batch per product as agreed specification. According to EC with Pesticides, ETO, Solvents Residue, Microbiology, Heavy Metals and PHA4.\n\n" +
  "Please send sample of 3 x 100gr separately to our following address:\n" +
  "Glomm Logistics GmbH\n" +
  "c/o RAWBIDS GmbH\n" +
  "Gutenbergring 22\n" +
  "D-22848 Norderstedt\n" +
  "Contact: +49 151 26057562, yo@rawbids.com\n" +
  "Please do not issue your sample shipping invoice to our warehouse but to our address.\n" +
  "RAWBIDS GmbH, Willy-Brandt-Straße 23, 20457 Hamburg\n\n" +
  "Remarks: please state the measurements of the pallet(s) and the quantity net/gross per pallet together with your confirmation.\n\n" +
  "We expect always the same quality. In case of any changes (eg. Specification, Production Process, Production site) " +
  "you are obliged to inform us in written form before delivery. In case of closing the production of the product(s) " +
  "you are obliged to inform us in written form at least 3 months before.";

/**
 * Create a purchase order html string for pdf generation
 * @param supplierOrder The supplier order
 * @param startingPoint Port where the order starts
 * @param shippingInstruction Additional instructions for shipping for the supplier
 * @param notify notify for the supplier order
 * @param deliveryTerms terms of delivery
 * @param paymentTerms terms of payment
 * @param paymentTermConditions conditions additional to payment terms
 * @param draft Optional, flag if a draft is being generated
 * @param additionalInfo Optional, additional note for the supplier order
 * @returns { string } a html string for pdf generation
 */
export function createSupplierOrderHTML(
  supplierOrder: SupplierOrderExtended,
  startingPoint: Seaport | Airport | Address | undefined,
  shippingInstruction: string,
  notify?: Notify,
  deliveryTerms?: string,
  paymentTerms?: string,
  paymentTermConditions?: string,
  draft?: boolean,
  additionalInfo?: string
): string {
  const { commodity, supplier, orderNo, amount, unit, currency, priceCommodities, targetDate, etd } = supplierOrder;
  const delivery = deliveryTerms ? deliveryTerms : supplierOrder.terms ? supplierOrder.terms.deliveryTerms : "";
  const payment = paymentTerms ? paymentTerms : supplierOrder.terms ? supplierOrder.terms.paymentTerms : "";
  const paymentConditions =
    paymentTermConditions !== undefined
      ? paymentTermConditions
      : supplierOrder.terms
      ? supplierOrder.terms.paymentTermConditions
      : "";
  const address = supplier.address[0];
  const start = startingPoint
    ? "locode" in startingPoint
      ? " - " + getSeaportName(startingPoint)
      : "street" in startingPoint
      ? " - " + formatAddress(startingPoint, { withComma: true })
      : " - " + getAirportName(startingPoint)
    : "";
  const notes = supplierOrder.noteInternal.map((n) => {
    return n.note;
  });
  const additionalInformation = additionalInfo ? additionalInfo : notes.join("<br/>");
  let html = `
  <head>
    <link href="https://fonts.googleapis.com/css?family=Signika&display=swap" rel="stylesheet">
      <style>tr:nth-child(even) {
          background-color: white;
      }</style>
    <meta http-equiv="content-type" content="text/html; charset=utf-8">
  </head>
  <body style="font-family: Signika,serif">
    <img src="https://rawbids.com/images/logo-dark.png" alt="Logo" width="180" style="float:right" />
    <span style="font-size: 30px; font-weight: bold">Purchase Order ${draft ? "DRAFT" : "RB-" + orderNo}</span><br />
    <span style="font-size: 20px; font-weight: bold">${commodity.title.en}</span><br /><br /><br />
    <table cellpadding="5" cellspacing="0" style="width: 100%">
      <tr style="width: 100%; vertical-align: top">
        <td style="vertical-align: top; width: 50%">
          <span style="font-size:16px; vertical-align: top">Recipient:<br/>
          <b>${supplier.name}</b> 
          <br/>${formatAddress(address, { withBrTag: true })}<br />${
    supplier.vat ? `VAT-ID: ${supplier.vat}` : ""
  }</span>
        </td>
        <td style="vertical-align: top">
          <div style="font-size:16px; width: 50%; vertical-align: top; margin-left: 200px">
            Issued by:<br/><b>RAWBIDS GmbH</b>
            <br/>Willy-Brandt-Str. 23, 20457 Hamburg <br />Germany<br />VAT-ID: DE354508196
            <br/>Contact: info@rawbids.com<br/><br/>Hamburg, ${formatDate(new Date())}
          </div>
        </td>
      </tr>
    </table>
    <table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px">
      <thead>
        <tr style="background-color:#333333; color: #ffffff">
          <th style="min-width: 150px; text-align: left"><b style="white-space:nowrap">Commodity</b></th>
          <th style="text-align: left"><b>Additional Information</b></th>
          <th style="text-align: left"><b>Quantity</b></th>
          <th style="text-align: left"><b style="white-space:nowrap;">Price (${unit})</b></th>
          <th style="text-align: left"><b style="white-space:nowrap;">Price (total)</b></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><b>${commodity.title.en}</b><br/>${commodity.subtitle.en}</td>
          <td>${additionalInformation}</td>
          <td>${amount}${formatArticleUnit(unit, commodity)}</td>
          <td>${formatCurrency(priceCommodities / amount, currency)}</td>
          <td>${formatCurrency(priceCommodities, currency)}</td>
        </tr>
      </tbody>
    </table>
    <br /><br />
    <table style="font-size:16px; width: 100%; display: block">
      <tbody>
        <tr><td>
            <b>Terms of delivery: </b>${delivery !== "" ? delivery : ""} ${
    supplierOrder.transport !== T_EUSTOCK ? start : ""
  }<br /><br /> 
           </td></tr>
        <tr>
            <td> <b>CAS Number: </b>${isCommoditySnapshot(commodity) ? commodity.casNumber.join(", ") : "-"}<br /><br />
            </td>
        </tr>
        <tr><td>
             <b>HS Code: </b>${commodity.hsCode}<br /><br /> </td>
        </tr>
       ${
         supplierOrder.commodity.organic
           ? ` <tr><td>
             <b>Certified Organic Code: </b>DE-ÖKO-006<br /><br /> </td>
        </tr>`
           : ""
       }
        <!-- If no etd is given, it is an old order, so targetDate was set as etd before-->
         <tr><td>   <b>ETD: </b>${formatDate(etd ? etd : targetDate)}<br /><br /> </td>
        </tr>
        <tr><td>  <b>${payment.trim() !== "" || paymentConditions?.trim() !== "" ? "Terms of payment: " : ""}</b>${
    payment && paymentConditions
      ? payment + " " + paymentConditions
      : payment && !paymentConditions
      ? payment
      : paymentConditions && !payment
      ? paymentConditions
      : ""
  }<br /><br /> </td>
        </tr>
          <tr><td>  <b>Consignee:</b><br />
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Company Name: RAWBIDS GmbH <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Contact
              Person: Mark Steinebrunner<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Address: Willy-Brandt-Str. 23, DE - 20457 Hamburg<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Email: ms@rawbids.com
           </td>
        </tr>
        <tr><td>  </div><b>Notify:</b><br />`;
  if (notify) {
    html += `<div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Company Name: ${
      notify.companyName
    }<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Contact
              Person: ${notify.contactPerson}<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Address: ${formatAddress(
      notify.address,
      { withComma: true }
    )}<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Telephone:
              ${notify?.telephone}<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Email: ${notify.email}
            </div>`;
  } else {
    html += `<div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - 
            </div>`;
  }
  html += `<br /><br /> </td>
        </tr>
        <tr><td>  Shipping Instructions: <span style="white-space: pre-wrap">${shippingInstruction}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <br/>
    <br/>
    <div style="margin-top: auto; font-size:12px; position: absolute; bottom: 0">
        <div>${ARBITRATIONTEXT}</div>
    </div>
  </body>`;
  return html;
}
