import React from "react";

const LoginInformationLinks: React.FC = () => (
  <div className="d-flex text-primary fs-base gap-5 login-information">
    <a
      href="https://rawbids.com/files/terms_of_purchase.pdf"
      target="_blank"
      rel="noopener noreferrer"
      className="link-dark"
    >
      Terms of Purchase
    </a>
    <a
      href="https://rawbids.com/files/terms_of_sale.pdf"
      target="_blank"
      rel="noopener noreferrer"
      className="link-dark"
    >
      Terms of Sale
    </a>
    <a href="mailto:info@rawbids.com" className="link-dark">
      Contact Us
    </a>
  </div>
);

export default LoginInformationLinks;
