import { BSON } from "realm-web";

export enum ArticleType {
  NEWS = "news",
  MARKETREPORT = "marketReport",
}

export interface ArticleTimeline {
  _id: BSON.ObjectId;
  person: string;
  date: Date;
}

export interface Article {
  _id: BSON.ObjectId;
  title: string;
  type?: ArticleType;
  newsText: string;
  creationTime: Date;
  lastWrite: Date;
  tags: Array<string>;
  imagePath: string;
  timeline: Array<ArticleTimeline>;
  createdBy: string;
}
