import React, { useEffect, useState } from "react";
// @ts-ignore
import ReactStars from "react-rating-stars-component";

interface ReactStarsWrapperProps {
  classNames?: string;
  value: number;
  count: number;
  size: number;
  edit?: boolean;
  onChange?: (rating: number) => (void | Promise<void>) | null;
}

const ReactStarsWrapper: React.FunctionComponent<ReactStarsWrapperProps> = ({
  classNames,
  value,
  count,
  size,
  edit,
  onChange,
}) => {
  const [starKeyForce, setStarKeyForce] = useState(0);

  useEffect(() => {
    setStarKeyForce((prev) => prev + 1);
  }, [classNames, value, count, size, edit, onChange]);
  return (
    <ReactStars
      classNames={classNames}
      value={value}
      count={count}
      size={size}
      activeColor="#ffd700"
      edit={edit}
      onChange={onChange}
      key={starKeyForce}
    />
  );
};

export default ReactStarsWrapper;
