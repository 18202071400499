import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { Textarea } from "../../../common/Textarea";
import { DataContextCustomer } from "../../../../context/dataContext";
import { getDefaultSlackChannel, NotificationType, sendMessage } from "../../../../services/slackService";
import userService from "../../../../services/userService";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";

interface OfferCommodityModalProps {}

interface OfferCommodityModalState {
  show: boolean;
  text: string;
}

class OfferCommodityModal extends PureComponent<OfferCommodityModalProps, OfferCommodityModalState> {
  user = userService.getUserData();
  static contextType = DataContextCustomer;
  context!: React.ContextType<typeof DataContextCustomer>;

  constructor(props: OfferCommodityModalProps) {
    super(props);
    this.state = { show: false, text: "" };
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState({ show: false, text: "" });
  handleChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => this.setState({ text: e.target.value });

  handleSendRequest = () => {
    const companyName = this.context.company.find((c) => c._id.toString() === this.user.company)?.name;
    const message = `New commodity requested by ${this.user.prename} ${this.user.surname} from ${companyName || ""}: ${
      this.state.text
    }`;
    sendMessage(getDefaultSlackChannel(false, NotificationType.REQUEST), message);
    toast.success("Commodity successfully requested");
    this.handleHide();
  };

  validateData = () => {
    const { text } = this.state;
    const errors = [];
    if (text.trim() === "") errors.push("Please enter a text message");
    if (text.length < 10) errors.push("Text is too short.");
    return errors;
  };

  render() {
    const { text, show } = this.state;

    return (
      <>
        <button className="btn btn-light btn-text float-right" onClick={this.handleShow}>
          Request Commodity
        </button>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Request Commodity</h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <div className=" fs-6 mb-5">
                  If you are looking for a special commodity that we do not have in our offer, feel free to send us a
                  request. Please describe the commodity in detail, then we will check whether we can add the commodity
                  to our offer.
                </div>
              </div>
              <div className="col-12 mt-5">
                <label className="fs-5 fw-bold mb-2">Commodity Description</label>
                <Textarea
                  className="form-control custom-form-control"
                  placeholder="Please describe your requested commodity..."
                  rows={3}
                  value={text}
                  onChange={this.handleChangeText}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-light" onClick={this.handleHide}>
              Close
            </button>
            <ErrorOverlayButton
              errors={this.validateData()}
              className={"btn btn-sm btn-light"}
              buttonText={"Send Request"}
              onClick={this.handleSendRequest}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default OfferCommodityModal;
