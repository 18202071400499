import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { formatDate, truncateString } from "../../../utils/baseUtils";
import { INTERNAL, searchUserName } from "../../../utils/userUtils";
import { DataContextType } from "../../../context/dataContext";
import { OrderTimeline } from "../../../model/commonTypes";
import {
  EXTENDED_ORDER_TYPES,
  getOrderNumber,
  getTimelineEntryText,
  isCustomerOrder,
  ORDER_TYPES,
} from "../../../utils/orderUtils";
import { CommodityOfferRequest } from "../../../model/commodityOfferRequest.types";
import { isCommodityOfferRequest } from "../../../utils/commodityOfferRequestUtils";
import userService from "../../../services/userService";
import {
  getContractNumber,
  isCustomerContract,
  isCustomerCustomerContract,
} from "../../../utils/customerContractUtils";
import { CONTRACT_TYPES, EXTENDED_CONTRACT_TYPES } from "../../../utils/contractUtils";
import Tooltip from "../../common/Tooltip";

interface OrderHistoryEntryProps {
  entry: {
    order: ORDER_TYPES | EXTENDED_ORDER_TYPES | CommodityOfferRequest | CONTRACT_TYPES | EXTENDED_CONTRACT_TYPES;
    timelineEntry: OrderTimeline;
    recent: boolean;
  };
  context: DataContextType;
  showOrder?: boolean;
  hideUser?: boolean;
  extended?: boolean;
  lessWidth?: boolean;
  orderLink?: boolean;
}

class OrderHistoryEntry extends PureComponent<OrderHistoryEntryProps> {
  /**
   * Resolves the description of the timeline entry.
   * @returns {string | JSX.Element} Description of the entry
   */
  getEntryText = (): string | JSX.Element => {
    const { context, entry } = this.props;
    const { timelineEntry } = entry;
    const text = getTimelineEntryText(timelineEntry, context.type);
    if (timelineEntry.payload && "calculation" in timelineEntry.payload) {
      return (
        <Tooltip
          tooltipText={
            <span className="text-white">
              {timelineEntry.payload.calculation?.split("\n").map((t, idx) => (
                <div key={idx}>{t}</div>
              ))}
            </span>
          }
        >
          <span>{text}</span>
        </Tooltip>
      );
    }
    return text;
  };

  /**
   * Shortens the commodity title based upon the current innerWidth.
   * @param title Title that should be shortened
   * @returns {string} Shortened title
   */
  shortenCommodityTitle = (title: string): string => {
    const { context } = this.props;
    const { innerWidth } = context;
    if (innerWidth > 2300) return truncateString(title, 45);
    if (innerWidth > 2200) return truncateString(title, 40);
    if (innerWidth > 2100) return truncateString(title, 35);
    if (innerWidth > 2000) return truncateString(title, 30);
    if (innerWidth > 1900) return truncateString(title, 25);
    if (innerWidth > 1800) return truncateString(title, 22);
    if (innerWidth > 1700) return truncateString(title, 18);
    // On smaller width there is just not enough space to show the commodity name properly
    return "";
  };

  render() {
    const { entry, context, showOrder, hideUser, extended, lessWidth, orderLink } = this.props;

    return (
      <div
        className={"m-0 timeline-item no-hover " + (entry.recent && "new")}
        style={{ borderLeft: "3px solid " + (entry.recent ? "#787878" : "#343434") }}
      >
        <div className={"timeline-content text-muted ps-3 m-0 " + (extended ? "py-3" : "py-1")}>
          {!isCommodityOfferRequest(entry.order) && showOrder && (
            <>
              {extended || orderLink ? (
                <Link
                  to={`/${
                    userService.getUserType() === INTERNAL
                      ? isCustomerOrder(entry.order)
                        ? "customerOrder"
                        : "supplierOrder"
                      : "order"
                  }/${entry.order._id.toString()}`}
                  className="fw-bolder text-white custom-link mx-2"
                >
                  {isCustomerContract(entry.order) || isCustomerCustomerContract(entry.order)
                    ? `Contract ${getContractNumber(entry.order)}`
                    : `Order ${getOrderNumber(entry.order)}`}
                </Link>
              ) : (
                <span className="fw-bolder text-white mx-2">
                  {isCustomerContract(entry.order) || isCustomerCustomerContract(entry.order)
                    ? `Contract ${getContractNumber(entry.order)}`
                    : `Order ${getOrderNumber(entry.order)}`}
                </span>
              )}
              {!isCommodityOfferRequest(entry.order) && extended && (
                <span className="text-muted">{this.shortenCommodityTitle(entry.order.commodity.title.en)}</span>
              )}
            </>
          )}
          {(hideUser || lessWidth) && (
            <span className="float-right">
              <small>{formatDate(entry.timelineEntry.date)}</small>
            </span>
          )}
          {showOrder && <br />}
          <div className="ml-2">
            <span>{this.getEntryText()}</span>
            {!hideUser && !lessWidth && (
              <div className="float-right">
                <span className="text-muted ml-2">
                  {searchUserName(entry.timelineEntry.person, context.userData)} -{" "}
                  {formatDate(entry.timelineEntry.date)}
                </span>
              </div>
            )}
            {isCustomerOrder(entry.order) && extended && entry.order.customerReference.trim().length > 0 && (
              <>
                <br />
                <span className="text-muted">Ref: {entry.order.customerReference}</span>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default OrderHistoryEntry;
