import { BSON } from "realm-web";
import { CO_TIMELINETYPE, CustomerOrder, CustomerOrderService } from "./customerOrder.types";
import { AdditionalSOInvoiceFileValues, SO_TIMELINETYPE } from "./supplierOrder.types";
import { SAMO_TIMELINETYPE } from "./sampleOrder.types";
import { Commodity } from "./commodity.types";
import { CustomerCommodity } from "./customer/customerCommodity.types";
import { PriceDevelopment } from "./statistics/commodityStatistics.types";
import { COR_TIMELINETYPE } from "./commodityOfferRequest.types";
import { CC_TIMELINETYPE } from "../utils/customerContractUtils";
import { SelectOption } from "../components/common/CustomSelect";
import { FinishedProduct } from "./finishedProduct.types";
import { CustomerFinishedProduct } from "./customer/customerFinishedProduct.types";

export interface OrderFile {
  _id: BSON.ObjectId;
  date: Date;
  path: string; // Path to file
  type: string; // Types to be defined
  uploadedBy?: string;
  reference?: string; // especially for forwarding order files
  additionalValues?: AdditionalSOInvoiceFileValues;
}

export interface Range {
  min: number | string;
  max: number | string;
}

export enum AddressType {
  A_PRIMARY = "addrPrimary",
  A_SHIPPING = "addrShipping",
  A_INVOICE = "addrInvoice",
  A_OTHER = "addrOther",
}

export const ADDRESS_TYPE_OPTIONS: Array<SelectOption> = [
  { value: AddressType.A_PRIMARY, label: "Primary" },
  { value: AddressType.A_SHIPPING, label: "Shipping" },
  { value: AddressType.A_INVOICE, label: "Invoicing" },
  { value: AddressType.A_OTHER, label: "Other" },
];

export interface Address {
  _id: BSON.ObjectId;
  /** Additional name like another company or a recipient **/
  name: string;
  /** Street name **/
  street: string;
  /** House number **/
  houseNo: string;
  /** Postal / ZIP code of the city **/
  postalCode: string;
  /** City name **/
  city: string;
  /** Type of address, currently primary, invoice and other are possible **/
  type: AddressType;
  /** Country code where the address is located **/
  country: string;
  /** Properties containing special delivery instructions for the address, e.g. required forklift **/
  properties?: Array<string>;
  /** Contact person for that address as id (string) **/
  contactPerson?: string;
  /** Opening hours of the address **/
  openingHours?: string;
}

export interface GroupedCustomerOrderService extends CustomerOrderService {
  customerOrders: Array<CustomerOrder>;
}

export interface Empty {}

export interface OrderTimeline {
  _id: BSON.ObjectId;
  date: Date; // When did this happen?
  type: SO_TIMELINETYPE | CO_TIMELINETYPE | SAMO_TIMELINETYPE | COR_TIMELINETYPE | CC_TIMELINETYPE; // What happened?
  person: string;
  payload?: OrderTimelinePayload | null; // Additional data for certain types, tbd
}

export interface OrderTimelinePayload {
  type?: string;
  checked?: boolean;
  reference?: string;
  name?: string;
  reason?: string;
  calculation?: string;
}

export interface Note {
  _id: BSON.ObjectId;
  note: string;
  person: string;
  date: Date;
}

export interface PriceTickerData {
  article: Commodity | CustomerCommodity | FinishedProduct | CustomerFinishedProduct;
  price: number;
  priceCurrency: string;
  priceDevelopment?: PriceDevelopment;
  priceGraph: string;
}

export interface TreemapData {
  commodity: CustomerCommodity;
  priceDevelopment: PriceDevelopment;
}

export interface TrendData {
  category: string;
  change: number;
}

export interface SignedFile {
  originator: string; // user who created/updated the document
  approver: null | string; // null = approval required, objectId = user who approved it
  approvalDate: Date; // time of approval/upload
  signedBy: null | string;
  signatureDate: Date;
}

export interface VersionedFile {
  version: number;
}

export interface PublicFile {
  isPublic: boolean;
}

export interface CustomerPriceInfo {
  unitPrice: number;
  totalPrice: number;
  isFallback?: boolean;
  multiSource?: boolean;
}

export interface LanguageObject {
  en: string;
}

export interface GraduatedPrices {
  // Empty string is the value for no selected supplier - thus this is shown as a single case here
  sea: Array<{ supplier: "" | string; prices: Array<GraduatedPrice> }>;
  air: Array<{ supplier: "" | string; prices: Array<GraduatedPrice> }>;
}

export interface GraduatedPrice {
  amount: number;
  unitPrice: number;
  currency: string;
}
