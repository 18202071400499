import React, { useMemo } from "react";
import CustomSelect, { SelectOption } from "./CustomSelect";
import { Input } from "./Input";
import { SO_DELIVERYTERMS, SupplierTermOptions } from "../../utils/supplierOrderUtils";
import { Notify } from "../../model/notify.types";
import { I_PAYMENTTARGETS_WITH_CUSTOM_OPTION } from "../../utils/invoiceUtils";
import { CO_TRANSPORT, T_EUSTOCK } from "../../model/customerOrder.types";
import { Incoterm } from "../../utils/commodityUtils";

interface SupplierOrderTermsProps {
  supplierTermOption: SupplierTermOptions;
  notifies: Array<Notify>;
  transportMethod: CO_TRANSPORT;
  onChangePaymentTerms: (e: SelectOption) => void;
  onChangeCustomPaymentTerm: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeCustomPaymentTermCondition: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDeliveryTerms: (e: SelectOption) => void;
  onChangeNotify: (e: SelectOption<Notify>) => void;
}

const SupplierOrderTerms: React.FunctionComponent<SupplierOrderTermsProps> = ({
  supplierTermOption,
  notifies,
  transportMethod,
  onChangePaymentTerms,
  onChangeCustomPaymentTerm,
  onChangeCustomPaymentTermCondition,
  onChangeDeliveryTerms,
  onChangeNotify,
}) => {
  const notifySelects = useMemo(
    () =>
      notifies.map((n) => {
        return { value: n._id.toString(), label: n.companyName, object: n };
      }),
    [notifies]
  );

  return (
    <>
      {(transportMethod !== T_EUSTOCK ||
        (transportMethod === T_EUSTOCK && supplierTermOption.deliveryTerm.label === Incoterm.DDP)) && (
        <div className="row mb-2">
          <span className="col-4 col-form-label fs-6 fw-bold">Notify</span>
          <div className="col-8">
            <CustomSelect
              options={notifySelects}
              onChange={onChangeNotify}
              matchFormControl={true}
              placeholder={notifies.length > 0 ? "Please select a notify" : "No notify available, see settings"}
              value={
                notifies.length > 0
                  ? {
                      value: supplierTermOption.notify._id.toString(),
                      label: supplierTermOption.notify.companyName,
                      object: supplierTermOption.notify,
                    }
                  : undefined
              }
            />
            <small className="mt-1">&nbsp;</small>
          </div>
        </div>
      )}
      <div className="row mb-2">
        <span className="col-4 col-form-label fs-6 fw-bold">Payment Terms</span>
        <div className="col-8">
          <CustomSelect
            options={I_PAYMENTTARGETS_WITH_CUSTOM_OPTION}
            onChange={onChangePaymentTerms}
            matchFormControl={true}
            placeholder={"Please select payment terms"}
            value={supplierTermOption.paymentTerm}
          />
          <small className="mt-1">&nbsp;</small>
        </div>
      </div>
      {supplierTermOption.paymentTerm.value === "custom" && (
        <div className="row mb-2">
          <span className="col-4 col-form-label fs-6 fw-bold">Custom payment term</span>
          <div className="col-8">
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type="number"
                value={supplierTermOption.customPaymentTerm}
                onChange={onChangeCustomPaymentTerm}
              />
              <div className="input-group-append disabled" style={{ opacity: "0.7" }}>
                <span className="input-group-text form-control custom-form-control">days</span>
              </div>
            </div>
            <small className="mt-1">&nbsp;</small>
          </div>
        </div>
      )}
      <div className="row mb-2">
        <span className="col-4 col-form-label fs-6 fw-bold">Additional Payment Conditions</span>
        <div className="col-8">
          <Input
            className="form-control custom-form-control"
            value={supplierTermOption.customPaymentTermCondition}
            onChange={onChangeCustomPaymentTermCondition}
          />
          <small className="mt-1">&nbsp;</small>
        </div>
      </div>
      <div className="row mb-2">
        <span className="col-4 col-form-label fs-6 fw-bold">Terms of Delivery</span>
        <div className="col-8">
          <CustomSelect
            options={SO_DELIVERYTERMS}
            onChange={onChangeDeliveryTerms}
            matchFormControl={true}
            placeholder={"Please select delivery terms"}
            value={supplierTermOption.deliveryTerm}
          />
          <small className="mt-1">&nbsp;</small>
        </div>
      </div>
    </>
  );
};

export default SupplierOrderTerms;
