import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import countryList from "i18n-iso-countries";
import { Airport } from "../../../../model/airport.types";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import { insertAirport } from "../../../../utils/airportUtils";
import { Input } from "../../../common/Input";
import Tooltip from "../../../common/Tooltip";
import { CNY, SUPPORTED_CURRENCIES } from "../../../../utils/currencyUtils";
import { AIRPORT, transaction, UpdateAction } from "../../../../services/dbService";

interface CreateAirportModalProps {
  airport?: Airport;
  additionalButtonClasses?: string;
  onlyModal?: boolean;
  show?: boolean;
  onHide?: (airportId?: string | BSON.ObjectId) => void;
}

interface CreateAirportModalState {
  show: boolean;
  saving: boolean;
  step: number;
  name: string;
  iata: string;
  icao: string;
  cost: number;
  currency: string;
  country?: SelectOption;
}

class CreateAirportModal extends PureComponent<CreateAirportModalProps, CreateAirportModalState> {
  constructor(props: CreateAirportModalProps) {
    super(props);
    this.state = this.getDefaultState(props.show);
  }

  /**
   * Get default state for the component
   * @param show optional, value for the show flag of the modal
   * @returns {CreateAirportModalState} Default state initialized with proper data
   */
  getDefaultState = (show?: boolean) => {
    const { airport: propAirport } = this.props;
    return {
      show: !!show,
      saving: false,
      name: propAirport?.name ?? "",
      iata: propAirport?.iata ?? "",
      icao: propAirport?.icao ?? "",
      country: propAirport
        ? {
            value: propAirport.country,
            label: countryList.getName(propAirport.country, "en", {
              select: "alias",
            }),
          }
        : undefined,
      cost: propAirport?.cost !== undefined ? propAirport.cost : 0,
      currency: propAirport?.currency ?? CNY,
      step: 1,
    };
  };

  componentDidUpdate(prevProps: Readonly<CreateAirportModalProps>) {
    if (prevProps.show !== this.props.show) {
      this.setState(this.getDefaultState(this.props.show));
    }
  }

  handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };
  handleChangeCost = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ cost: +e.target.value });
  handleChangeCurrency = (e: React.ChangeEvent<HTMLSelectElement>) => this.setState({ currency: e.target.value });
  handleSelectCountry = (e: SelectOption) => this.setState({ country: e });

  handleShow = () => this.setState(this.getDefaultState(true));
  handleHide = (airportId?: string | BSON.ObjectId) => {
    this.setState({ show: false });
    if (this.props.onHide) this.props.onHide(airportId);
  };
  handleNext = () => this.setState({ step: 2 });
  handleBack = () => this.setState({ step: 1 });

  handleCreateAirport = async () => {
    const { airport: airportProp } = this.props;
    const { name, iata, icao, currency, country, cost } = this.state;
    if (!country) return;
    const airport: Omit<Airport, "_id"> = {
      name,
      iata,
      icao,
      country: country.value,
      disabled: false,
      currency,
    };
    if (cost > 0) airport.cost = cost;

    try {
      this.setState({ saving: true });
      let result;
      if (airportProp) {
        const action: UpdateAction = {
          collection: AIRPORT,
          filter: { _id: airportProp._id },
          update: airport,
        };
        if (airportProp.cost && cost === 0) action.unset = { cost: "" };
        result = await transaction([action]);
      } else {
        result = await insertAirport(airport);
      }
      if (
        (airportProp && result) ||
        (result && typeof result === "object" && "insertedId" in result && result.insertedId)
      ) {
        toast.success(`Airport successfully ${airportProp ? "updated" : "created"}`);
        this.handleHide(
          typeof result === "object" && "insertedId" in result && result.insertedId ? result.insertedId : undefined
        );
      } else toast.error(`Airport could not be ${airportProp ? "updated" : "created"}. Please try again later.`);
    } catch (e) {
      toast.error(`An unknown error occurred: ${JSON.stringify(e) || ""}. Please try again later.`);
    } finally {
      this.setState({ saving: false });
    }
  };

  validateData = () => {
    const errors = [];
    const { name, iata, icao, country, cost } = this.state;
    if (name.trim().length < 3) errors.push("Invalid name. Name should have at least 3 characters");
    if (iata.trim().length !== 3) errors.push("Invalid IATA code. A IATA usually consists of 3 characters");
    if (icao.trim().length !== 4) errors.push("Invalid ICAO code. A ICAO usually consists of 4 characters");
    if (!country) errors.push("Please select the country the port is located in");
    if (cost < 0) errors.push("Cost cannot be negative");
    return errors;
  };

  render() {
    const { airport, additionalButtonClasses, onlyModal } = this.props;
    const { show, name, icao, iata, currency, country, step, saving, cost } = this.state;
    const onSummary = step === 2;
    const errors = this.validateData();

    return (
      <>
        {!onlyModal && (
          <button
            className={"btn btn-outline btn-outline-light " + (additionalButtonClasses ?? "")}
            onClick={this.handleShow}
          >
            {airport ? "Edit" : "New Airport"}
          </button>
        )}
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">{airport ? "Edit" : "New"} Airport</h1>
            </Modal.Title>
            <CloseButton variant={"white"} onClick={() => this.handleHide()} />
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-3">
              <div className="col-md-12 mt-5">
                <label className="required fs-5 fw-bold mb-2">Name</label>
                <input
                  type="text"
                  className={"form-control custom-form-control " + (onSummary ? "disabled" : "")}
                  name="name"
                  value={name}
                  disabled={onSummary}
                  onChange={this.handleTextChange}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12 mt-5">
                <label className="fs-5 required fw-bold mb-2">IATA</label>
                <Tooltip
                  tooltipText=" An IATA airport code, also known as an IATA location identifier, IATA station code, or simply a
                        location identifier, is a three-letter geocode designating many airports around the world."
                >
                  <span className="ml-2">
                    <i className="fa fa-info-circle text-white" />
                  </span>
                </Tooltip>
                <input
                  type="text"
                  className={"form-control custom-form-control " + (onSummary ? "disabled" : "")}
                  name="iata"
                  value={iata}
                  disabled={onSummary}
                  onChange={this.handleTextChange}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12 mt-5">
                <label className="fs-5 required fw-bold mb-2">ICAO</label>
                <Tooltip
                  tooltipText="The ICAO airport code or location indicator is a four-letter code designating aerodromes around
                        the world."
                >
                  <span className="ml-2">
                    <i className="fa fa-info-circle text-white" />
                  </span>
                </Tooltip>
                <input
                  type="text"
                  className={"form-control custom-form-control " + (onSummary ? "disabled" : "")}
                  name="icao"
                  value={icao}
                  disabled={onSummary}
                  onChange={this.handleTextChange}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12 mt-5">
                <label className="required fs-5 fw-bold mb-2">Country</label>
                <CustomSelect
                  options={Object.values(countryList.getNames("en", { select: "alias" })).map((item: string) => {
                    return {
                      value: countryList.getAlpha2Code(item, "en"),
                      label: item,
                    };
                  })}
                  disabled={onSummary}
                  value={country ? country : undefined}
                  onChange={onSummary ? () => true : this.handleSelectCountry}
                  matchFormControl={true}
                />
              </div>
            </div>
            <div className="row mb-6">
              <div className="col-md-12 mt-5">
                <label className="fs-5 fw-bold mb-2">
                  Air Freight Cost per kg
                  <Tooltip
                    tooltipText={
                      "This value is required for airports used as a starting point. For destination airports, e.g. Frankfurt this is not required. "
                    }
                  >
                    <span className="ml-2">
                      <i className="fa fa-info-circle text-white" />
                    </span>
                  </Tooltip>
                </label>
                <div className="input-group">
                  <Input
                    className={"form-control custom-form-control"}
                    type={"number"}
                    min={0}
                    value={cost}
                    onChange={this.handleChangeCost}
                  />
                  <div className="input-group-append bg-custom-light-gray select-wrapper">
                    <select
                      className="form-control custom-form-control pt-0 pb-0"
                      style={{ minWidth: "70px" }}
                      name={"currency"}
                      value={currency}
                      onChange={this.handleChangeCurrency}
                    >
                      {SUPPORTED_CURRENCIES.map((c) => (
                        <option key={c} value={c}>
                          {c}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {onSummary && (
              <h4 className="fw-bolder text-white text-center mt-15">
                Please check and confirm that the data is correct.
              </h4>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-sm btn-outline btn-text-white"
              onClick={step === 1 ? () => this.handleHide() : this.handleBack}
            >
              {step === 1 ? "Close" : "Back"}
            </button>
            <ErrorOverlayButton
              errors={errors}
              className={"btn btn-sm btn-outline btn-outline-light"}
              buttonText={saving ? "Saving..." : step === 1 ? "Next" : airport ? "Continue" : "Confirm"}
              saving={saving}
              onClick={step === 1 ? this.handleNext : this.handleCreateAirport}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreateAirportModal;
