import React, { Component } from "react";

interface PropertyTagProps {
  text: string;
  additionalTextClassName?: string;
  selected?: boolean;
  highlight?: boolean;
  highlightSelected?: boolean;
  onSelect?: () => void;
  onRemove?: () => void;
}

class PropertyTag extends Component<PropertyTagProps> {
  handleSelect = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    this.props.onSelect && this.props.onSelect();
  };

  handleRemove = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    this.props.onRemove && this.props.onRemove();
  };

  render() {
    const { text, selected, highlight, highlightSelected, additionalTextClassName } = this.props;
    return (
      <div
        className={
          "tagify__tag " + (highlight || (highlightSelected && selected) ? "highlight" : selected ? "selected" : "")
        }
        onClick={selected ? this.handleRemove : this.handleSelect}
      >
        <div>
          <span
            className={
              "tagify__tag-text " + (additionalTextClassName || (!selected && !highlight ? "text-muted " : ""))
            }
          >
            {text}
          </span>
        </div>
        {selected && <span className="tagify__tag__removeBtn" />}
      </div>
    );
  }
}

export default PropertyTag;
