import _ from "lodash";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import validator from "validator";
import ErrorOverlayButton from "../../ErrorOverlayButton";
import { Input } from "../../Input";
import { UserData } from "../../../../model/userData.types";
import { getUserName, SUPPLIER } from "../../../../utils/userUtils";
import { callSetUserAndApiKeyValidity, deleteUser, registerUser } from "../../../../services/authService";
import { getDefaultSlackChannel, NotificationType, sendMessage } from "../../../../services/slackService";
import Tooltip from "../../Tooltip";
import { callSendInvitationMail } from "../../../../utils/notificationUtils";
import userService from "../../../../services/userService";

interface AccessAdjustmentModalProps {
  user: UserData;
}

interface AccessAdjustmentModalState {
  user: UserData;
  show: boolean;
  linkView: boolean;
  link: string;
  saving: boolean;
  copied: boolean;
  sendInvitationMail: boolean;
}

class AccessAdjustmentModal extends PureComponent<AccessAdjustmentModalProps, AccessAdjustmentModalState> {
  constructor(props: AccessAdjustmentModalProps) {
    super(props);
    this.state = this.getDefaultState(false, props);
  }

  componentDidUpdate(prevProps: Readonly<AccessAdjustmentModalProps>) {
    if (!_.isEqual(prevProps.user, this.props.user)) this.setState({ user: _.cloneDeep(this.props.user) });
  }

  handleShow = () => this.setState(this.getDefaultState(true, this.props));
  handleHide = () => this.setState({ show: false });

  handleChangePerson = (e: React.ChangeEvent<HTMLInputElement>) => {
    const user = _.cloneDeep(this.state.user);
    const { name, value } = e.target;
    switch (name) {
      case "mail":
        if (!user.emails[0]) user.emails.push({ value, description: "Primary email address" });
        else user.emails[0].value = value;

        break;
      case "phone":
        if (!user.phones[0]) user.phones.push({ value, description: "Primary phone number" });
        else user.phones[0].value = value;
        break;
      default:
        // @ts-ignore
        user[name] = value;
    }
    this.setState({ user });
  };

  handleToggleSendInvitationMail = () => this.setState({ sendInvitationMail: !this.state.sendInvitationMail });

  handleSaveAccess = async () => {
    const { sendInvitationMail } = this.state;
    const user = _.cloneDeep(this.state.user);
    this.setState({ saving: true });
    const grantAccess = !this.props.user.userId;
    const typeString = user.type === SUPPLIER ? "Supplier" : "Customer";
    try {
      let result;
      if (grantAccess) {
        const registerRes = await registerUser(user.emails[0].value);
        if (!registerRes) {
          toast.error("App User could not be registered");
          await sendMessage(
            getDefaultSlackChannel(false, NotificationType.REGISTRATION),
            `Account creation for ${getUserName(user)} failed`
          );
          return;
        }
        const { id: userId, token } = registerRes;
        if (!userId) {
          toast.error("User could not be registered");
          await deleteUser(userId);
          await sendMessage(
            getDefaultSlackChannel(false, NotificationType.REGISTRATION),
            `${typeString} account creation failed in Step 1`
          );
          return;
        }
        user.userId = userId;
        result = await callSetUserAndApiKeyValidity(user, true);
        if (result && result.result) {
          const link = `https://${process.env.REACT_APP_BASE_URL || ""}/invitation?t=${token.key}`;
          toast.success(`${typeString} account successfully created`);
          await sendMessage(
            getDefaultSlackChannel(false, NotificationType.REGISTRATION),
            `${getUserName(userService.getUserData())} created a ${typeString} account for ${getUserName(
              user
            )} - Link: ${link}`
          );
          if (sendInvitationMail) {
            result = await callSendInvitationMail(userId, link);
            if (result) {
              toast.success("Invitation mail sent successfully");
            } else toast.error("Invitation mail could not be sent");
          }
          this.setState({ linkView: true, link });
        } else {
          toast.error(`${typeString} account could not be linked with userdata`);
          await deleteUser(userId);
          await sendMessage(
            getDefaultSlackChannel(false, NotificationType.REGISTRATION),
            `${typeString} account creation failed in Step 2`
          );
        }
      } else {
        result = await deleteUser(user.userId);
        if (result) {
          toast.success(`${typeString} account successfully revoked`);
          await sendMessage(
            getDefaultSlackChannel(false, NotificationType.REGISTRATION),
            `App account for ${getUserName(user)} was deleted by ${getUserName(userService.getUserData())}`
          );
          this.setState({ show: false });
        } else {
          toast.error(`${typeString} account could not be deleted`);
          await sendMessage(
            getDefaultSlackChannel(false, NotificationType.REGISTRATION),
            `Error: App account for ${getUserName(user)} could not be deleted by ${getUserName(
              userService.getUserData()
            )}`
          );
        }
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  handleCopyToClipboard = async () => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(this.state.link);
    } else {
      document.execCommand("copy", true, this.state.link);
    }
    this.setState({ copied: true });
  };

  getDefaultState = (show: boolean, props: AccessAdjustmentModalProps) => {
    return {
      show,
      saving: false,
      user: _.cloneDeep(props.user),
      linkView: false,
      link: "",
      copied: false,
      sendInvitationMail: false,
    };
  };

  validateData = () => {
    const { user } = this.state;
    const errors: Array<string> = [];
    if (!user.prename.trim()) errors.push("Prename has to be set");
    if (!user.emails[0] || !validator.isEmail(user.emails[0].value))
      errors.push("Email is invalid. Without an email the user can not access the system");
    return errors;
  };

  render() {
    const { show, saving, user, linkView, link, copied, sendInvitationMail } = this.state;
    const grantAccess = !this.props.user.userId;
    const errors = this.validateData();

    return (
      <>
        <button className="btn btn-sm px-2 py-1 btn-outline btn-outline-light float-right" onClick={this.handleShow}>
          {user.userId ? "Revoke" : "Grant"}
        </button>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="text-white">{grantAccess ? "Grant" : "Revoke"} Rawbids Access</h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-5 text-white">
              {linkView ? (
                <div className="form-group col-12">
                  <label className="fs-5 fw-bold mb-2">Login Link</label>
                  <div className="input-group">
                    <Input
                      type="text"
                      className="form-control custom-form-control"
                      name="tokenLink"
                      disabled={true}
                      value={link}
                    />
                    <div className="input-group-append">
                      <Tooltip tooltipText={copied ? "Copied!" : "Copy to clipboard!"}>
                        <button
                          className="btn btn-sm btn-outline btn-outline-light"
                          onClick={this.handleCopyToClipboard}
                        >
                          <i className="flaticon2-copy pr-0" />
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="text-danger">Note: The invitation link won't be available afterwards.</div>
                </div>
              ) : grantAccess ? (
                <>
                  <div className="col-md-6 mt-3">
                    <label className="required fs-5 fw-bold mb-2">Prename</label>
                    <Input
                      type="text"
                      className="form-control custom-form-control"
                      name="prename"
                      autoComplete="off"
                      value={user.prename}
                      onChange={this.handleChangePerson}
                    />
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="required fs-5 fw-bold mb-2">Surname</label>
                    <Input
                      type="text"
                      className="form-control custom-form-control"
                      name="surname"
                      autoComplete="off"
                      value={user.surname}
                      onChange={this.handleChangePerson}
                    />
                  </div>
                  <div className="col-md-12  mt-3">
                    <label className="required fs-5 fw-bold mb-2">Email</label>
                    <Input
                      type="text"
                      className="form-control custom-form-control"
                      name="mail"
                      autoComplete="off"
                      value={user.emails[0]?.value ?? ""}
                      onChange={this.handleChangePerson}
                    />
                  </div>
                  <div className="col-md-12  mt-3">
                    <label className="fs-5 fw-bold mb-2">Phone</label>
                    <Input
                      type="text"
                      className="form-control custom-form-control"
                      name="phone"
                      autoComplete="off"
                      value={user.phones[0]?.value ?? ""}
                      onChange={this.handleChangePerson}
                    />
                  </div>
                  <div className="d-flex flex-stack mt-5">
                    <div className="me-5 fw-bold">
                      <label className="fs-6">Send Invitation Mail</label>
                      <div className="fs-7 text-muted">If checked, an invitation mail will be sent to the user.</div>
                    </div>
                    <label className="form-check form-switch form-check-custom form-check-solid">
                      <input
                        className="form-check-input position-static"
                        type="checkbox"
                        checked={sendInvitationMail}
                        onChange={this.handleToggleSendInvitationMail}
                      />
                      <span className="form-check-label fw-bold text-muted">Active</span>
                    </label>
                  </div>
                </>
              ) : (
                <div className="h3 text-white">
                  Do you really want to revoke the access for {getUserName(user)}? This can't be undone without granting
                  the user a new account.
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-text-white" onClick={this.handleHide}>
              Close
            </button>
            {!linkView && (
              <ErrorOverlayButton
                errors={errors}
                className="btn btn-sm btn-outline btn-outline-light"
                buttonText={grantAccess ? "Grant" : "Revoke"}
                saving={saving}
                onClick={this.handleSaveAccess}
              />
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default AccessAdjustmentModal;
