import _ from "lodash";
import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import countryList from "i18n-iso-countries";
import { ContainerCost, Seaport } from "../../../../model/seaport.types";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import { CONTAINER_LABEL, insertSeaport } from "../../../../utils/seaportUtils";
import { Input } from "../../../common/Input";
import Tooltip from "../../../common/Tooltip";
import { SUPPORTED_CURRENCIES, USD } from "../../../../utils/currencyUtils";
import { SEAPORT, transaction, UpdateAction } from "../../../../services/dbService";

interface CreateSeaportModalProps {
  seaport?: Seaport;
  additionalButtonClasses?: string;
  onlyModal?: boolean;
  show?: boolean;
  onHide?: (seaportId?: string | BSON.ObjectId) => void;
}

interface CreateSeaportModalState {
  show: boolean;
  saving: boolean;
  step: number;
  name: string;
  locode: string;
  cost: number;
  containerCost: ContainerCost;
  currency: string;
  country?: SelectOption;
}

class CreateSeaportModal extends PureComponent<CreateSeaportModalProps, CreateSeaportModalState> {
  constructor(props: CreateSeaportModalProps) {
    super(props);
    this.state = this.getDefaultState(props.show);
  }

  /**
   * Get default state for the component
   * @param show optional, value for the show flag of the modal
   * @returns {CreateSeaportModalState} Default state initialized with proper data
   */
  getDefaultState = (show?: boolean) => {
    const { seaport: propSeaport } = this.props;
    return {
      show: !!show,
      saving: false,
      name: propSeaport?.name ?? "",
      locode: propSeaport?.locode ?? "",
      country: propSeaport
        ? {
            value: propSeaport.country,
            label: countryList.getName(propSeaport.country, "en", {
              select: "alias",
            }),
          }
        : undefined,
      currency: propSeaport?.currency || USD,
      containerCost: propSeaport?.containerCost ?? {
        "20": 0,
        "40": 0,
        "40HC": 0,
      },
      cost: propSeaport?.cost ?? 0,
      step: 1,
    };
  };

  componentDidUpdate(prevProps: Readonly<CreateSeaportModalProps>) {
    if (prevProps.show !== this.props.show) {
      this.setState(this.getDefaultState(this.props.show));
    }
  }

  handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };
  handleChangeCost = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ cost: +e.target.value });
  handleChangeContainerCost = (e: React.ChangeEvent<HTMLInputElement>, key: keyof ContainerCost) => {
    const containerCost = _.cloneDeep(this.state.containerCost);
    containerCost[key] = +e.target.value;
    this.setState({ containerCost });
  };
  handleChangeCurrency = (e: React.ChangeEvent<HTMLSelectElement>) => this.setState({ currency: e.target.value });
  handleSelectCountry = (e: SelectOption) => this.setState({ country: e });

  handleShow = () => this.setState(this.getDefaultState(true));
  handleHide = (seaportId?: string | BSON.ObjectId) => {
    this.setState({ show: false });
    if (this.props.onHide) this.props.onHide(seaportId);
  };
  handleNext = () => this.setState({ step: 2 });
  handleBack = () => this.setState({ step: 1 });

  handleCreateSeaport = async () => {
    const { seaport: seaportProp } = this.props;
    const { name, locode, country, cost, containerCost, currency } = this.state;
    if (!country) return;
    const seaport: Omit<Seaport, "_id"> = {
      name,
      locode,
      country: country.value,
      disabled: false,
      currency,
    };
    if (cost > 0) seaport.cost = cost;
    if (Object.values(containerCost).every((c) => c > 0)) seaport.containerCost = containerCost;
    try {
      this.setState({ saving: true });
      let result;
      if (seaportProp) {
        const action: UpdateAction = {
          collection: SEAPORT,
          filter: { _id: seaportProp._id },
          update: seaport,
        };
        if (
          (seaportProp.cost || seaportProp.containerCost) &&
          (cost === 0 || Object.values(containerCost).every((c) => c === 0))
        )
          action.unset = { cost: "", containerCost: "" };
        result = await transaction([action]);
      } else {
        result = await insertSeaport(seaport);
      }
      if (
        (seaportProp && result) ||
        (result && typeof result === "object" && "insertedId" in result && result.insertedId)
      ) {
        toast.success(`Seaport successfully ${seaportProp ? "updated" : "created"}`);
        this.handleHide(
          typeof result === "object" && "insertedId" in result && result.insertedId ? result.insertedId : undefined
        );
      } else toast.error(`Seaport could not be ${seaportProp ? "updated" : "created"}. Please try again later.`);
    } catch (e) {
      toast.error(`An unknown error occurred: ${JSON.stringify(e) || ""}. Please try again later.`);
    } finally {
      this.setState({ saving: false });
    }
  };

  validateData = () => {
    const errors = [];
    const { name, locode, country, cost, containerCost } = this.state;
    if (name.trim().length < 3) errors.push("Invalid name. Name should have at least 3 characters");
    if (locode.trim().length !== 5) errors.push("Invalid LOCODE. A LOCODE usually consists of 5 characters");
    if (!country) errors.push("Please select the country the port is located in");
    if (cost < 0) errors.push("Cost cannot be negative");
    // Either all need to be set or none at all
    const containerCostValues = Object.values(containerCost);
    if (
      ((containerCostValues.some((c) => c > 0) || cost > 0) && containerCostValues.some((c) => c === 0)) ||
      (cost <= 0 && containerCostValues.some((c) => c > 0))
    )
      errors.push("Either none of the cost or all costs are required.");
    return errors;
  };

  render() {
    const { seaport, additionalButtonClasses, onlyModal } = this.props;
    const { show, name, locode, country, step, saving, cost, containerCost, currency } = this.state;
    const onSummary = step === 2;
    const errors = this.validateData();

    return (
      <>
        {!onlyModal && (
          <button
            className={"btn btn-outline btn-outline-light " + (additionalButtonClasses ?? "")}
            onClick={this.handleShow}
          >
            {seaport ? "Edit" : "New Seaport"}
          </button>
        )}
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">{seaport ? "Edit" : "New"} Seaport</h1>
            </Modal.Title>
            <CloseButton variant={"white"} onClick={() => this.handleHide()} />
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-3">
              <div className="col-md-12 mt-5">
                <label className="required fs-5 fw-bold mb-2">Name</label>
                <input
                  type="text"
                  className={"form-control custom-form-control " + (onSummary ? "disabled" : "")}
                  name="name"
                  value={name}
                  disabled={onSummary}
                  onChange={this.handleTextChange}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12 mt-5">
                <label className="fs-5 required fw-bold mb-2">LOCODE</label>
                <Tooltip
                  tooltipText={
                    <span className="text-white">
                      The <em>United Nations Code for Trade and Transport Locations</em> typically consists of 5
                      letters, starting with the 2 letter country code.
                    </span>
                  }
                >
                  <span className="ml-2">
                    <i className="fa fa-info-circle text-white" />
                  </span>
                </Tooltip>
                <input
                  type="text"
                  className={"form-control custom-form-control " + (onSummary ? "disabled" : "")}
                  name="locode"
                  value={locode}
                  disabled={onSummary}
                  onChange={this.handleTextChange}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12 mt-5">
                <label className="required fs-5 fw-bold mb-2">Country</label>
                <CustomSelect
                  options={Object.values(countryList.getNames("en", { select: "alias" })).map((item: string) => {
                    return {
                      value: countryList.getAlpha2Code(item, "en"),
                      label: item,
                    };
                  })}
                  disabled={onSummary}
                  value={country ? country : undefined}
                  onChange={onSummary ? () => true : this.handleSelectCountry}
                  matchFormControl={true}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12 mt-5">
                <label className="fs-5 fw-bold mb-2">
                  Sea Freight Cost per t and m³{" "}
                  <Tooltip
                    tooltipText={
                      "This value and the container cost are required for seaports used as a starting point. For destination seaports, e.g. Hamburg this is not required. "
                    }
                  >
                    <span className="ml-2">
                      <i className="fa fa-info-circle text-white" />
                    </span>
                  </Tooltip>
                </label>
                <div className="input-group">
                  <Input
                    className={"form-control custom-form-control"}
                    type={"number"}
                    min={0}
                    value={cost}
                    onChange={this.handleChangeCost}
                  />
                  <div className="input-group-append bg-custom-light-gray select-wrapper">
                    <select
                      className="form-control custom-form-control pt-0 pb-0"
                      style={{ minWidth: "70px" }}
                      name={"currency"}
                      value={currency}
                      onChange={this.handleChangeCurrency}
                    >
                      {SUPPORTED_CURRENCIES.map((c) => (
                        <option key={c} value={c}>
                          {c}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {(Object.entries(CONTAINER_LABEL) as Array<[key: keyof ContainerCost, label: string]>).map(
              ([key, label]) => (
                <div className="row mb-3" key={key}>
                  <div className="col-md-12 mt-5">
                    <label className="fs-5 fw-bold mb-2">Cost for {label} Container</label>
                    <div className="input-group">
                      <Input
                        className={"form-control custom-form-control"}
                        type={"number"}
                        min={0}
                        value={containerCost[key]}
                        onChange={(e) => this.handleChangeContainerCost(e, key)}
                      />
                      <div className="input-group-append bg-custom-light-gray select-wrapper">
                        <select
                          className="form-control custom-form-control pt-0 pb-0"
                          style={{ minWidth: "70px" }}
                          name={"currency"}
                          value={currency}
                          onChange={this.handleChangeCurrency}
                        >
                          {SUPPORTED_CURRENCIES.map((c) => (
                            <option key={c} value={c}>
                              {c}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
            {onSummary && (
              <h4 className="fw-bolder text-white text-center mt-15">
                Please check and confirm that the data is correct.
              </h4>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-sm btn-outline btn-text-white"
              onClick={step === 1 ? () => this.handleHide() : this.handleBack}
            >
              {step === 1 ? "Close" : "Back"}
            </button>
            <ErrorOverlayButton
              errors={errors}
              className={"btn btn-sm btn-outline btn-outline-light"}
              buttonText={saving ? "Saving..." : step === 1 ? "Next" : seaport ? "Continue" : "Confirm"}
              saving={saving}
              onClick={step === 1 ? this.handleNext : this.handleCreateSeaport}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreateSeaportModal;
