import _ from "lodash";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import validator from "validator";
import { DataContextSupplierType } from "../../../../context/dataContext";
import SupplierPageGeneralInformationBlock from "../SupplierPageGeneralInformationBlock";
import {
  getDefaultReferencedAirports,
  getDefaultReferencedSeaports,
  getSupplierTimelineEntry,
  updateSupplier,
} from "../../../../utils/supplierUtils";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import ContactsCard from "../../../common/ContactsCard";
import { SupplierSupplierExtended } from "../../../../model/supplier/supplierSupplier.types";
import { SelectOption } from "../../../common/CustomSelect";
import { isAddressTooShort } from "../../../../utils/addressUtils";
import { T_S_UPDATESUPPLIERINFORMATION } from "../../../../model/supplier.types";

import { reduceSupplierSupplier } from "../../../../utils/dataTransformationUtils";

interface SupplierPageGeneralProps {
  supplier: SupplierSupplierExtended;
  context: DataContextSupplierType;
}

interface SupplierPageGeneralState {
  edit: boolean;
  referencedSeaports: Array<SelectOption>;
  referencedAirports: Array<SelectOption>;
  supplierEdit: SupplierSupplierExtended;
}

class SupplierPageGeneral extends PureComponent<SupplierPageGeneralProps, SupplierPageGeneralState> {
  constructor(props: SupplierPageGeneralProps) {
    super(props);
    this.state = {
      edit: false,
      supplierEdit: _.cloneDeep(props.supplier),
      referencedSeaports: getDefaultReferencedSeaports(props.supplier.seaportReferences, props.context.seaport),
      referencedAirports: getDefaultReferencedAirports(props.supplier.airportReferences, props.context.airport),
    };
  }

  handleEditToggle = () => this.setState({ edit: !this.state.edit, supplierEdit: _.cloneDeep(this.props.supplier) });

  handleEditSupplierInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const supplier = _.cloneDeep(this.state.supplierEdit);
    _.set(supplier, e.target.name, e.target.type === "number" ? +e.target.value : e.target.value);
    this.setState({ supplierEdit: supplier });
  };

  handleEditPreparationTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    const supplier = _.cloneDeep(this.state.supplierEdit);
    supplier.transport.preparationTime = +e.target.value;
    this.setState({ supplierEdit: supplier });
  };

  handleEditReferences = (selections: Array<SelectOption>, type: "seaport" | "airport") => {
    // @ts-ignore
    this.setState({ [type === "seaport" ? "referencedSeaports" : "referencedAirports"]: selections });
  };

  handleSaveSupplier = async () => {
    const { supplierEdit, referencedSeaports, referencedAirports } = this.state;
    if (!supplierEdit) return;
    const toSubmit = Object.assign({}, supplierEdit);
    toSubmit.seaportReferences = referencedSeaports.map((opt) => opt.value);
    toSubmit.airportReferences = referencedAirports.map((opt) => opt.value);

    const timelineEntry = getSupplierTimelineEntry(T_S_UPDATESUPPLIERINFORMATION);
    const result = await updateSupplier(reduceSupplierSupplier(toSubmit), undefined, timelineEntry);
    if (result && result.modifiedCount > 0) {
      toast.success("Supplier data updated successfully");
      this.setState({ edit: false });
    } else {
      toast.error("Error updating Supplier");
    }
  };

  validateSupplierData = () => {
    const { supplierEdit } = this.state;
    const { name, mail, phone, address, currency } = supplierEdit;
    const errors: Array<string> = [];
    if (name.trim().length < 3) errors.push("Supplier name too short");
    if (isAddressTooShort(address[0])) errors.push("Address too short");
    if (!currency) errors.push("Currency not set");
    if (!address[0].country) errors.push("No country selected");
    if (phone.trim().length < 3 && !validator.isEmail(mail))
      errors.push("Please provide a valid phone number or email");
    return errors;
  };

  render() {
    const { supplier, context } = this.props;
    const { edit, supplierEdit, referencedAirports, referencedSeaports } = this.state;
    const errors = this.validateSupplierData();
    return (
      <>
        <div className="card bg-white">
          <div className="card-header border-0 mt-5">
            <div className="card-title flex-column">
              <h2 className="mb-1">General Information</h2>
              <div className="fs-6 fw-bold text-muted">{supplier.name}</div>
            </div>
            <div className="pt-3">
              <div className="d-flex pt-3 align-items-center w-100">
                <>
                  {edit && (
                    <button
                      className={"btn btn-outline btn-outline-light btn-sm float-right "}
                      onClick={this.handleEditToggle}
                    >
                      Cancel
                    </button>
                  )}
                  {edit ? (
                    <ErrorOverlayButton
                      className={"btn btn-outline btn-outline-light btn-sm float-right ml-2"}
                      errors={errors}
                      buttonText={"Save Supplier"}
                      onClick={this.handleSaveSupplier}
                    />
                  ) : (
                    <button
                      className={"btn btn-outline btn-outline-light btn-sm float-right ml-2"}
                      onClick={this.handleEditToggle}
                    >
                      Edit Data
                    </button>
                  )}
                </>
              </div>
            </div>
          </div>
          <div className="card-body p-9 pt-4">
            <div className="pt-0">
              <SupplierPageGeneralInformationBlock
                supplier={edit ? supplierEdit : supplier}
                context={context}
                referencedSeaports={referencedSeaports}
                referencedAirports={referencedAirports}
                edit={edit}
                onEditSupplierInput={this.handleEditSupplierInput}
                onEditPreparationTime={this.handleEditPreparationTime}
                onEditReferences={this.handleEditReferences}
              />
            </div>
          </div>
        </div>
        <div className="mt-5">
          <ContactsCard company={supplier} type="supplier" />
        </div>
      </>
    );
  }
}

export default SupplierPageGeneral;
