import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { getArticleProperty } from "../../../utils/commodityUtils";
import { PropertyType } from "../../../utils/propertyUtils";
import { Property } from "../../../model/property.types";
import CountryWidget from "../../common/CountryWidget";
import { DataContextSupplierType } from "../../../context/dataContext";
import userService from "../../../services/userService";
import { round } from "../../../utils/baseUtils";
import { isSupplierFinishedProduct } from "../../../utils/finishedProductUtils";
import { SUPPLIER } from "../../../utils/userUtils";
import { SupplierArticleExtended } from "../../../utils/productArticleUtils";

interface CommoditySummaryProps {
  context: DataContextSupplierType;
  article?: SupplierArticleExtended;
  withLink?: boolean;
}

class CommodityPageSummary extends PureComponent<CommoditySummaryProps> {
  render() {
    const { article, context, withLink } = this.props;
    const composition = getArticleProperty(article?.properties, PropertyType.COMPOSITION) as Property | null;
    const solvent = getArticleProperty(article?.properties, PropertyType.SOLVENT) as Property | null;
    const sup = context.supplier.find((s) => s._id.toString() === userService.getCompany());
    const isFP = article && isSupplierFinishedProduct(article, SUPPLIER);
    const usedPalette = sup?.packagingDimensions?.find(
      (pd) =>
        pd[isFP ? "finishedProducts" : "commodities"] === "all" ||
        (article && pd[isFP ? "finishedProducts" : "commodities"]?.includes(article._id.toString()))
    );
    const category = getArticleProperty(article?.properties, PropertyType.CATEGORY) as Property | null;

    return (
      <div className="card mb-5 mb-xl-8 bg-white">
        <div className="card-body">
          <div className="d-flex flex-center flex-column py-5">
            <span className="fs-2 text-gray-800 fw-bolder mb-7 text-center">
              {article && (
                <>
                  {article.disabled && <span className="text-danger mr-2">[DISABLED]</span>}
                  {!article.approved && !article.disabled && <span className="text-warning mr-2">[PENDING]</span>}
                </>
              )}
              {article ? article.title.en : "No commodity selected"}
            </span>
            <div className="mb-7">
              <div className="badge badge-lg d-inline" style={{ backgroundColor: "#232323" }}>
                HS-Code: <span>{article ? article.hsCode : "-"}</span>
              </div>
            </div>
          </div>
          <div className="d-flex flex-stack fs-4 pb-3">
            <div className="fw-bolder rotate">Commodity Quickinfo</div>
          </div>
          <div className="border-bottom-dark-gray" />
          <div className="pb-5 fs-6">
            <div className="fw-bolder mt-5">{isFP ? "Category" : "CAS Number"}</div>
            <div className="text-muted">
              {article ? (isFP ? category?.name.en : article.casNumber.join(" / ")) : "-"}
            </div>
            <div className="fw-bolder mt-5">Composition</div>
            <div className="text-muted">{composition ? composition.name.en : "-"}</div>
            <div className="fw-bolder mt-5">Origin</div>
            <div className="text-muted">
              {article && article.country ? <CountryWidget countryCode={article.country.code} /> : "-"}
            </div>
            <div className="fw-bolder mt-5">Extraction Solvent</div>
            <div className="text-muted">{solvent ? solvent.name.en : "-"}</div>
            <div className="fw-bolder mt-5">Active Ingredients</div>
            {article && article.activeSubstances.length > 0 ? (
              article.activeSubstances.map((aS) => (
                <div key={aS.substance._id.toString()} className="text-muted">
                  {aS.percentage.toFixed(2)}% {aS.substance.name.en}
                </div>
              ))
            ) : (
              <div className="text-muted">-</div>
            )}
            <div className="fw-bolder mt-5">Organic</div>
            <div className="text-muted">
              {article ? article.organic ? <span className="text-success">Yes</span> : "No" : "-"}
            </div>
            {isFP ? (
              <>
                <div className="fw-bolder mt-5">Color</div>
                <div className="text-muted">{article?.color}</div>
              </>
            ) : (
              <>
                <div className="fw-bolder mt-5">Density</div>
                <div className="text-muted">
                  {article
                    ? `${round(Number(article.density.min), 3)} - ${round(Number(article.density.max), 3)} kg/m³`
                    : "-"}
                </div>
                <div className="fw-bolder mt-5">Appearance</div>
                <div className="text-muted">{article && article.appearance.en ? article.appearance.en : "-"}</div>
              </>
            )}
            <div className="fw-bolder mt-5">Used Palette</div>
            <div className="text-muted">
              {article && usedPalette ? (
                <Link className="text-muted custom-link" to="/packagingDimensions">
                  {usedPalette.description} {usedPalette.length} x {usedPalette.width} x {usedPalette.height} (
                  {usedPalette.cbm}m³)
                </Link>
              ) : (
                "-"
              )}
            </div>
          </div>
          {withLink &&
            (article ? (
              <Link
                to={`/${isFP ? "finishedProduct" : "commodity"}/${article._id.toString()}`}
                className="btn btn-light w-100 fw-bolder"
              >
                Open Commodity
              </Link>
            ) : (
              <button className="btn btn-light w-100 fw-bolder disabled" disabled={true}>
                Open Commodity
              </button>
            ))}
        </div>
      </div>
    );
  }
}

export default CommodityPageSummary;
