import React, { PureComponent } from "react";
import { Cookies, withCookies } from "react-cookie";
import { RouteComponentProps } from "react-router-dom";
import { getAndRemoveQuery } from "../../utils/queryUtils";
import authService, { DEFAULT_SESSION_KEY_NAME } from "../../services/authService";
import userService from "../../services/userService";
import { ANONYMOUS } from "../../utils/userUtils";
import { getDefaultSlackChannel, NotificationType, sendMessage } from "../../services/slackService";
import RawbidsLogin from "./RawbidsLogin";
import ArkLogin from "./ArkLogin";
import { Empty } from "../../model/commonTypes";
import { AppDesign } from "../../utils/baseUtils";

interface LocationState {
  from: Location;
}

interface LoginProps extends RouteComponentProps<Empty, Empty, LocationState> {
  cookies: Cookies;
}

interface LoginState {
  email: string;
  password: string;
  error: string;
  loggingIn: boolean;
  fromLogout: boolean;
  resetSuccess: boolean;
}

class Login extends PureComponent<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);
    const fromLogout = !!getAndRemoveQuery(props.location, props.history, "logout");
    const success = !!getAndRemoveQuery(props.location, props.history, "success");
    this.state = {
      email: "",
      password: "",
      error: "",
      loggingIn: false,
      fromLogout,
      resetSuccess: success,
    };
  }

  /**
   * Handle the login of the user.
   * @param e Event that triggered the login
   */
  handleLogin = async (e: React.FormEvent) => {
    const { email, password } = this.state;
    this.setState({ loggingIn: true, error: "" });
    e.preventDefault();
    try {
      await authService.login(email, password);
      await authService.createAndSetApiKey(this.props.cookies, DEFAULT_SESSION_KEY_NAME);
      if (userService.getUserType() === ANONYMOUS) {
        sendMessage(
          getDefaultSlackChannel(false, NotificationType.ANONYMOUS),
          `New anonymous User login:\nBrowser/OS: ${navigator.userAgent}\nLanguage: ${navigator.language}\nScreenwidth: ${window.innerWidth}px\n`
        );
      }
      // Do full reload of page after login
      const { state } = this.props.location;
      window.location.href = state ? state.from.pathname : "/";
    } catch (ex) {
      console.error(ex);
      this.setState({ error: "Invalid username or password" });
    } finally {
      this.setState({ loggingIn: false });
    }
  };

  handleMailChange = (email: string) => this.setState({ email, error: "" });
  handlePasswordChange = (password: string) => this.setState({ password, error: "" });

  render() {
    const { email, password, error, loggingIn, fromLogout, resetSuccess } = this.state;
    const isArkViewEnabled = process.env.REACT_APP_DESIGN === AppDesign.ARK;

    return isArkViewEnabled ? (
      <ArkLogin
        email={email}
        error={error}
        fromLogout={fromLogout}
        loggingIn={loggingIn}
        password={password}
        resetSuccess={resetSuccess}
        onEmailChange={this.handleMailChange}
        onPasswordChange={this.handlePasswordChange}
        onSubmit={this.handleLogin}
      />
    ) : (
      <RawbidsLogin
        email={email}
        error={error}
        fromLogout={fromLogout}
        loggingIn={loggingIn}
        password={password}
        resetSuccess={resetSuccess}
        onEmailChange={this.handleMailChange}
        onPasswordChange={this.handlePasswordChange}
        onSubmit={this.handleLogin}
      />
    );
  }
}

export default withCookies(Login);
