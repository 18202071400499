import React from "react";
import { Input } from "../../../common/Input";
import { CalculationBaseValues } from "../../../../utils/priceCalculationUtils";
import { ceil } from "../../../../utils/baseUtils";
import Tooltip from "../../../common/Tooltip";
import { PaletteData } from "../../../../model/configuration/calculationConfiguration.types";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";
import { PackagingDimension } from "../../../../model/supplier.types";

interface PaletteDataViewProps {
  totalAmount?: number;
  paletteData: PaletteData;
  baseValues?: CalculationBaseValues;
  useTotals?: boolean;
  palette?: SelectOption<PackagingDimension>;
  palettes?: Array<SelectOption<PackagingDimension>>;
  onChangePaletteData: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onToggleStackable?: () => void;
  onToggleTotals?: () => void;
  onChangeBaseValue?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangePalette?: (e: SelectOption<PackagingDimension>) => void;
}

export const PaletteDataView: React.FunctionComponent<PaletteDataViewProps> = ({
  totalAmount,
  paletteData,
  baseValues,
  useTotals,
  palette,
  palettes,
  onChangePaletteData,
  onChangeBaseValue,
  onToggleTotals,
  onToggleStackable,
  onChangePalette,
}) => {
  const paletteRest = totalAmount !== undefined ? (totalAmount / paletteData.netWeight) % 1 : 0;
  const smallRestPalette = totalAmount !== undefined ? totalAmount > 0 && paletteRest > 0 && paletteRest < 0.6 : false;
  return (
    <>
      <h3 className="mb-3 mt-7">
        Palette Data{" "}
        {baseValues && smallRestPalette && (
          <Tooltip
            tooltipText={
              <span className="text-warning fw-bold ">
                The current calculation would result in a small incomplete palette ({ceil(paletteRest, 5)}), which may
                lead to an inaccurate calculation. It is recommended to either use manual total CBM, weight and palette
                values or increase/reduce the commodity amount, e.g. increase to{" "}
                <span className="text-decoration-underline fw-bold ">
                  <em>{baseValues.palettes * paletteData.netWeight}kg</em>
                </span>{" "}
                for complete palettes.
              </span>
            }
          >
            <i className="fas fa-exclamation-triangle text-warning ml-1" style={{ fontSize: "18px" }} />
          </Tooltip>
        )}
      </h3>
      {onChangePalette && (
        <div className="row">
          <div className="col-6 col-xl-4">
            <label className="fs-5 fw-bold mb-2">Existing Palettes</label>
            <CustomSelect
              matchFormControl={true}
              value={palette ?? undefined}
              onChange={onChangePalette}
              options={palettes ?? []}
              disabled={!palettes || palettes.length === 0}
            />
          </div>
        </div>
      )}
      {baseValues && onToggleTotals && (
        <div>
          <span className="form-check form-switch form-check-custom form-check-solid">
            <label className="form-check-label text-white mr-1 ml-0">Palette Calculation</label>
            <input
              className="form-check-input position-static"
              checked={useTotals}
              onChange={onToggleTotals}
              type="checkbox"
            />
            <label className="form-check-label text-white">Manual Values</label>
          </span>
        </div>
      )}
      <div className="row">
        <div className="col-6 col-xl-4 mt-2">
          <label className="fs-5 fw-bold mb-2">Length</label>
          <div className="input-group">
            <Input
              className={"form-control custom-form-control " + (useTotals && "disabled")}
              disabled={useTotals}
              type={"number"}
              name={"length"}
              min={0}
              value={paletteData.length}
              onChange={onChangePaletteData}
            />
            <div
              className="input-group-append rounded-end bg-custom-light-gray"
              style={useTotals ? { opacity: 0.7 } : undefined}
            >
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                cm
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-xl-4 mt-2">
          <label className="fs-5 fw-bold mb-2">Width</label>
          <div className="input-group">
            <Input
              className={"form-control custom-form-control " + (useTotals && "disabled")}
              disabled={useTotals}
              type={"number"}
              name={"width"}
              min={0}
              value={paletteData.width}
              onChange={onChangePaletteData}
            />
            <div
              className="input-group-append rounded-end bg-custom-light-gray"
              style={useTotals ? { opacity: 0.7 } : undefined}
            >
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                cm
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-xl-4">
          <label className="fs-5 fw-bold mb-2 mt-2">Total Height</label>
          {onToggleStackable && (
            <span className="d-inline form-check form-switch form-check-custom form-check-solid fs-5 float-right">
              <input
                className="form-check-input position-static ml-2 mt-1"
                style={{ height: "1.25rem" }}
                checked={!!paletteData.stackable}
                onChange={onToggleStackable}
                type="checkbox"
              />
              <label className={"form-check-label fw-bold " + (paletteData.stackable ? "text-white" : "text-muted")}>
                Stackable
              </label>
            </span>
          )}
          <div className="input-group">
            <Input
              className={"form-control custom-form-control " + (useTotals && "disabled")}
              disabled={useTotals}
              type={"number"}
              min={0}
              name={"height"}
              value={paletteData.height}
              onChange={onChangePaletteData}
            />
            <div
              className="input-group-append rounded-end bg-custom-light-gray"
              style={useTotals ? { opacity: 0.7 } : undefined}
            >
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                cm
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-xl-4 mt-2">
          <label className="fs-5 fw-bold mb-2">CBM</label>
          <div className="input-group">
            <Input
              className={"form-control custom-form-control " + (useTotals && "disabled")}
              disabled={useTotals}
              type={"number"}
              min={0}
              name={"CBM"}
              value={paletteData.cbm}
              onChange={onChangePaletteData}
            />
            <div
              className="input-group-append rounded-end bg-custom-light-gray"
              style={useTotals ? { opacity: 0.7 } : undefined}
            >
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                m³
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-xl-4 mt-2">
          <label className="fs-5 fw-bold mb-2">Commodity Amount per Palette</label>
          <div className="input-group">
            <Input
              className={"form-control custom-form-control " + (useTotals && "disabled")}
              disabled={useTotals}
              type={"number"}
              min={0}
              name={"netWeight"}
              value={paletteData.netWeight}
              onChange={onChangePaletteData}
            />
            <div
              className="input-group-append rounded-end bg-custom-light-gray"
              style={useTotals ? { opacity: 0.7 } : undefined}
            >
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                kg
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-xl-4 mt-2">
          <label className="fs-5 fw-bold mb-2">Total Palette Weight</label>
          <div className="input-group">
            <Input
              className={"form-control custom-form-control " + (useTotals && "disabled")}
              disabled={useTotals}
              type={"number"}
              min={0}
              name={"grossWeight"}
              value={paletteData.grossWeight}
              onChange={onChangePaletteData}
            />
            <div
              className="input-group-append rounded-end bg-custom-light-gray"
              style={useTotals ? { opacity: 0.7 } : undefined}
            >
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                kg
              </div>
            </div>
          </div>
        </div>
        {baseValues && onChangeBaseValue && (
          <>
            <div className="col-xl-4 mt-2">
              <label className="fs-5 fw-bold mb-2">Total CBM</label>
              <div className="input-group">
                <Input
                  className={"form-control custom-form-control " + (!useTotals && "disabled")}
                  disabled={!useTotals}
                  type={"number"}
                  min={0}
                  name="cbm"
                  value={baseValues.cbm}
                  onChange={onChangeBaseValue}
                />
                <div
                  className="input-group-append rounded-end bg-custom-light-gray"
                  style={!useTotals ? { opacity: 0.7 } : undefined}
                >
                  <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                    m³
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 mt-2">
              <label className="fs-5 fw-bold mb-2">Total Weight</label>
              <div className="input-group">
                <Input
                  className={"form-control custom-form-control " + (!useTotals && "disabled")}
                  disabled={!useTotals}
                  type={"number"}
                  min={0}
                  name="weight"
                  value={baseValues.weight}
                  onChange={onChangeBaseValue}
                />
                <div
                  className="input-group-append rounded-end bg-custom-light-gray"
                  style={!useTotals ? { opacity: 0.7 } : undefined}
                >
                  <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                    kg
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 mt-2">
              <label className="fs-5 fw-bold mb-2">Total Palettes</label>
              <div className="input-group">
                <Input
                  className={"form-control custom-form-control " + (!useTotals && "disabled")}
                  disabled={!useTotals}
                  type={"number"}
                  min={0}
                  integerOnly={true}
                  name="palettes"
                  value={baseValues.palettes}
                  onChange={onChangeBaseValue}
                />
                <div
                  className="input-group-append rounded-end bg-custom-light-gray"
                  style={!useTotals ? { opacity: 0.7 } : undefined}
                >
                  <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                    Palettes
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
