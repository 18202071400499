import _ from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { CO_CANCELED, CustomerOrder } from "../../../model/customerOrder.types";
import { SO_CANCELED, SupplierOrder } from "../../../model/supplierOrder.types";
import { SAMO_STATE, SampleOrder } from "../../../model/sampleOrder.types";
import { DataContextInternal } from "../../../context/dataContext";
import { CUSTOMERORDER, getCollectionDBWithQuery, SAMPLEORDER, SUPPLIERORDER } from "../../../services/dbService";
import Search from "../../common/Search";
import OrderFilter from "../internal/OrderFilter";
import BaseListing from "../../common/BaseListing";
import OrderRow from "../internal/OrderRow";
import { doFuseSearch } from "../../../utils/baseUtils";
import { O_ORDER_TYPES_WITH_CONTRACT, O_SORTOPTIONS } from "../../../utils/orderUtils";
import { SelectOption } from "../../common/CustomSelect";
import { SortOption, SORTORDEROPTIONS } from "../../../utils/filterUtils";
import { paginate, PaginationState } from "../../common/Pagination";
import SplashScreen from "../../common/SimpleSplashScreen";

enum CANCELED_TYPE {
  CUSTOMER_ORDER = "customer",
  SUPPLIER_ORDER = "supplier",
  SAMPLE_ORDER = "sample",
}

interface CanceledOrdersState extends PaginationState {
  type: SelectOption;
  sortOrder: SortOption;
  sortBy: SelectOption;
  orders?: Array<CustomerOrder | SupplierOrder | SampleOrder>;
  search: string;
  loading: boolean;
}

const getDefaultState = (): CanceledOrdersState => {
  return {
    type: O_ORDER_TYPES_WITH_CONTRACT[0],
    sortOrder: SORTORDEROPTIONS[0],
    sortBy: O_SORTOPTIONS[0],
    currentPage: 1,
    pageSize: 25,
    search: "",
    loading: false,
  };
};

const CanceledOrders: React.FC = () => {
  const context = useContext(DataContextInternal);

  const [state, setState] = useState<CanceledOrdersState>(getDefaultState());
  const { search, type, orders, sortOrder, sortBy, currentPage, pageSize, loading } = state;

  const handleChangeSortBy = (sortBy: SelectOption) => setState((prevState) => ({ ...prevState, sortBy }));
  const handleChangeSortOrder = (sortOrder: SortOption) => setState((prevState) => ({ ...prevState, sortOrder }));
  const handleChangeType = (type: SelectOption) => setState((prevState) => ({ ...prevState, type }));
  const handleChangeCurrentPage = (currentPage: number) => setState((prevState) => ({ ...prevState, currentPage }));
  const handleChangePageSize = (pageSize: number) => setState((prevState) => ({ ...prevState, pageSize }));
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    setState((prevState) => ({ ...prevState, search: e.target.value }));

  useEffect(() => {
    // Retrieve canceled orders
    async function getOrders() {
      setState((prevState) => ({ ...prevState, loading: true }));
      try {
        let orders: Array<CustomerOrder> | Array<SupplierOrder> | Array<SampleOrder>;
        switch (type.value) {
          case CANCELED_TYPE.CUSTOMER_ORDER:
            orders = await getCollectionDBWithQuery<CustomerOrder>(CUSTOMERORDER, { state: CO_CANCELED });
            break;
          case CANCELED_TYPE.SUPPLIER_ORDER:
            orders = await getCollectionDBWithQuery<SupplierOrder>(SUPPLIERORDER, { state: SO_CANCELED });
            break;
          case CANCELED_TYPE.SAMPLE_ORDER:
            orders = await getCollectionDBWithQuery<SampleOrder>(SAMPLEORDER, {
              state: { $in: [SAMO_STATE.CANCELED, SAMO_STATE.REJECTED] },
            });
            break;
        }
        setState((prevState) => ({ ...prevState, orders }));
      } catch (e) {
        console.error("ERROR:", e);
        toast.error("Error loading canceled order data");
      } finally {
        setState((prevState) => ({ ...prevState, loading: false }));
      }
    }
    getOrders();
  }, [type]);

  const filteredOrders = useMemo(() => {
    if (!orders) return [];
    if (!search.trim()) return orders;
    switch (type.value) {
      case CANCELED_TYPE.CUSTOMER_ORDER:
        return doFuseSearch(orders, search, [
          "orderNo",
          "commodity.title.en",
          "customerReference",
          "state",
          "supplier.name",
          "company.name",
        ]);
      case CANCELED_TYPE.SUPPLIER_ORDER:
        return doFuseSearch(orders, search, [
          "orderNo",
          "commodity.title.en",
          "customerReference",
          "state",
          "supplier.name",
          "company.name",
        ]);
      case CANCELED_TYPE.SAMPLE_ORDER:
        return doFuseSearch(orders, search, [
          "orderNo",
          "commodity.title.en",
          "customerReference",
          "state",
          "supplier.name",
          "company.name",
        ]);
      default:
        return orders;
    }
  }, [search, type, orders]);

  const sortedOrders = useMemo(() => {
    if (filteredOrders.length < 2) return filteredOrders;
    return _.orderBy(filteredOrders, sortBy.value, sortOrder.value);
  }, [filteredOrders, sortBy, sortOrder]);

  const paginatedOrders = useMemo(
    () => paginate(sortedOrders, currentPage, pageSize),
    [sortedOrders, pageSize, currentPage]
  );

  const headerDefinition = useMemo(
    () => [
      { title: "Order", style: { width: "40%" } },
      {
        title:
          type.value === CANCELED_TYPE.SUPPLIER_ORDER
            ? "Customer Orders"
            : type.value === CANCELED_TYPE.CUSTOMER_ORDER
            ? "Supplier Orders"
            : "References",
        style: { width: "15%", whiteSpace: "nowrap" },
      },
      { title: "Status", style: { width: "20%" } },
      { title: "Progress", style: { width: "13%" } },
      { title: "Type", style: { width: "12%" } },
      { title: "", style: { width: "0%" } },
    ],
    [type]
  );

  return (
    <div className="content d-flex flex-column flex-column-fluid">
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl">
          <div className="card bg-white" style={{ minHeight: "100%" }}>
            <div className="card-body">
              <h3 className="card-title align-items-start flex-column mb-15">
                <span className="card-label fw-bolder mb-3 fs-3rem">Canceled {type.label}</span>
                <div className="float-right">
                  <Link className="btn btn-outline btn-outline-light" to="/createCustomerOrder">
                    New Order
                  </Link>
                </div>
              </h3>
              <div className="row">
                <div className="col-12 col-md-6">
                  <Search placeholder="Search for orders..." onSearch={handleSearch} value={search} />
                </div>
                <div className="col-12 col-md-6 text-right">
                  <Link className="btn btn-sm btn-light" to="/orders">
                    Active Orders
                  </Link>
                </div>
              </div>
              <OrderFilter
                search={search}
                context={context}
                type={type}
                sortBy={sortBy}
                sortOrder={sortOrder}
                onChangeSortBy={handleChangeSortBy}
                onChangeSortOrder={handleChangeSortOrder}
                onChangeOrderType={handleChangeType}
                filteredOrders={filteredOrders}
              />
              {loading ? (
                <SplashScreen description="Loading Data" withoutBorder={true} />
              ) : (
                <BaseListing
                  headerDefinition={headerDefinition}
                  bodyContent={
                    <>
                      {paginatedOrders.length > 0 ? (
                        paginatedOrders.map((pO) => <OrderRow key={pO._id.toString()} order={pO} context={context} />)
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={8}>
                            No orders found
                          </td>
                        </tr>
                      )}
                    </>
                  }
                  documents={filteredOrders}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  baseSize={25}
                  onPageChange={handleChangeCurrentPage}
                  onPageSizeChange={handleChangePageSize}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanceledOrders;
