import { saveAs } from "file-saver";
import JSZip from "jszip";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { resolveFilePath } from "../../../utils/fileUtils";
import { SUP_D_TYPEOPTIONS } from "../../../utils/supplierUtils";
import { T_GENERALARTICLE, T_GENERALSUPPLIER, T_SUPPLIER } from "../internal/modals/UploadDocumentModal";
import zipUtils from "../../../utils/zipUtils";
import { D_OTHER, D_TYPEOPTIONS } from "../../../utils/commodityUtils";
import { Commodity, UploadedFileExtended } from "../../../model/commodity.types";
import { Supplier, SupplierFile } from "../../../model/supplier.types";
import { ArticleExtended } from "../../../utils/productArticleUtils";

interface SupplierAndCommodityDocumentsProps {
  article: ArticleExtended;
  supplier?: Supplier;
  documents: Array<UploadedFileExtended | SupplierFile>;
  type: "commodity" | "supplier" | "generalSupplier" | "generalCommodity";
  commodities?: Array<Commodity>;
  noSupplierSelection?: boolean;
}

interface SupplierAndCommodityDocumentsState {
  fileToDelete?: UploadedFileExtended | SupplierFile;
  fileToUpdate?: UploadedFileExtended | SupplierFile;
  generating: boolean;
}

class SupplierAndCommodityDocuments extends PureComponent<
  SupplierAndCommodityDocumentsProps,
  SupplierAndCommodityDocumentsState
> {
  constructor(props: SupplierAndCommodityDocumentsProps) {
    super(props);
    this.state = {
      generating: false,
    };
  }

  handleDownload = () => {
    if (this.state.generating) return;
    const { supplier, documents } = this.props;
    if (!documents || documents.length === 0) return;
    this.setState({ generating: true });
    const zip = new JSZip();
    const zipFilename = "Export-" + (supplier ? supplier.name : "Files") + "-" + new Date().toISOString() + ".zip";
    let count = 0;
    for (let i = 0; i < documents.length; i++) {
      const file = documents[i];
      zipUtils.getBinaryContent(resolveFilePath(file.path), (err: Error | null, data: string | ArrayBuffer | null) => {
        if (!err) {
          count++;
          zip.file(file.name, data ?? "", { binary: true });
        }
        if (count === documents.length) {
          zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(content, zipFilename);
            this.setState({ generating: false });
          });
        }
      });
    }
  };

  render() {
    const { article, supplier, documents, type } = this.props;
    const allTypes = D_TYPEOPTIONS.concat(SUP_D_TYPEOPTIONS);
    return (
      <>
        <div className="bg-light2 rounded p-5 mb-7">
          <div className="d-flex align-items-center ">
            <div className="flex-grow-1 me-2" style={{ marginLeft: 15 }}>
              {[T_GENERALSUPPLIER, T_GENERALARTICLE].includes(type) ? (
                <>
                  <span className="fw-bolder text-gray-800 fs-6">General Files</span>
                  <span className="text-muted fw-bold d-block">Documentation, e.g. Flowcharts</span>
                </>
              ) : (
                <>
                  {type === T_SUPPLIER ? (
                    <span className="fw-bolder text-gray-800 fs-6">{supplier?.name || "Unknown"}</span>
                  ) : (
                    <Link
                      className="fw-bolder text-gray-800 custom-link fs-6"
                      to={`/commodity/${article._id.toString()}`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {article.title.en}
                    </Link>
                  )}
                  <span className="text-muted fw-bold d-block">{type === T_SUPPLIER ? "" : article.subtitle.en}</span>
                </>
              )}
            </div>
            <div style={{ textAlign: "right", marginRight: 15 }}>
              <span className=" mb-1 fs-6 fw-bold">
                {documents.length} {documents.length === 1 ? "Document" : "Documents"}
              </span>
            </div>
          </div>
          <div className="table-responsive mt-5">
            <table className="table table-row-gray-100 align-middle gs-0 gy-1 ">
              <thead>
                <tr className="fw-bolder text-muted">
                  <th className="border-bottom-0">Type</th>
                  <th className="border-bottom-0">Name</th>
                  <th className="border-bottom-0 text-right">Upload Date</th>
                </tr>
              </thead>
              <tbody>
                {documents.length > 0 ? (
                  documents.map((d) => (
                    <tr key={d._id.toString()}>
                      <td className="align-middle">
                        <a href={resolveFilePath(d.path)} target="_blank" rel="noopener noreferrer">
                          <span className={"text-white fw-bolder"}>
                            {allTypes.find((type) => type.value === d.type)?.label}
                          </span>
                          {d.type === D_OTHER && d.customType && (
                            <small className="text-muted ml-2 fw-bolder">({d.customType})</small>
                          )}
                        </a>
                      </td>
                      <td className="align-middle">
                        <a href={resolveFilePath(d.path)} target="_blank" rel="noopener noreferrer">
                          <span className={"text-white fw-bold"}>
                            {d.name}
                            {"version" in d && d.version !== undefined && (
                              <span className="text-white ml-2">v{d.version}</span>
                            )}
                            <small className="text-muted ml-2">{d.size ? Math.round(d.size / 1024) + " KB" : ""}</small>
                          </span>
                        </a>
                      </td>
                      <td className="align-middle float-right">
                        {d.date ? (
                          <span className={"text-muted"}>{d.date.toLocaleDateString()}</span>
                        ) : (
                          <span className="text-white">-</span>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="align-middle text-center py-5 cursor-default" colSpan={10}>
                      <span className="text-white fw-bolder">No documents available yet</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default SupplierAndCommodityDocuments;
