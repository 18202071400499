import { saveAs } from "file-saver";
import JSZip from "jszip";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import WorkflowUploadDocumentModal from "../workflowTabPanels/modals/WorkflowUploadDocumentModal";
import SimpleConfirmationModal from "../../../../../common/SimpleConfirmationModal";
import { DataContextInternalType } from "../../../../../../context/dataContext";
import { OrderFile } from "../../../../../../model/commonTypes";
import {
  SO_T_DOCUMENTREMOVED,
  SupplierOrder,
  SupplierOrderExtended,
} from "../../../../../../model/supplierOrder.types";
import { formatDate } from "../../../../../../utils/baseUtils";
import { resolveFilePath, shortenAlias } from "../../../../../../utils/fileUtils";
import {
  getSupplierOrderTimelineEntry,
  isActive,
  SO_FILETYPES,
  SO_INVOICE,
  SO_ORDERCONFIRMATION,
  updateSupplierOrder,
} from "../../../../../../utils/supplierOrderUtils";
import zipUtils from "../../../../../../utils/zipUtils";
import Tooltip from "../../../../../common/Tooltip";

interface WorkflowDocumentsCardProps {
  order: SupplierOrderExtended;
  context: DataContextInternalType;
  done: boolean;
}

interface WorkflowDocumentsCardState {
  orderConfirmation?: OrderFile;
  invoice?: OrderFile;
  generating: boolean;
  saving: boolean;
}

class WorkflowDocumentsCard extends PureComponent<WorkflowDocumentsCardProps, WorkflowDocumentsCardState> {
  fileUploadRef?: WorkflowUploadDocumentModal;

  constructor(props: WorkflowDocumentsCardProps) {
    super(props);
    this.state = {
      ...this.getFiles(props.order),
      generating: false,
      saving: false,
    };
  }

  setRef = (ref: WorkflowUploadDocumentModal | null) => {
    if (ref) this.fileUploadRef = ref;
  };

  /**
   * Handles the removal of a document.
   * @param type Type of the document that should be removed
   */
  handleRemoveDocument = async (type: string) => {
    const { order } = this.props;
    this.setState({ saving: true });
    try {
      const files = order.files.filter((f) => f.type !== type);
      const update: Partial<SupplierOrder> = { files };
      const sOTimeline = getSupplierOrderTimelineEntry(SO_T_DOCUMENTREMOVED, { type });
      const res = await updateSupplierOrder(update, order._id, sOTimeline);
      if (res && res.modifiedCount > 0) {
        toast.success("File removed successfully");
      } else {
        toast.error("Error removing file");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  /**
   * Bundles all for the ordered workflow relevant documents into a zip and downloads them.
   */
  handleDownloadDocuments = () => {
    const { order } = this.props;
    const { orderConfirmation, invoice } = this.state;
    this.setState({ generating: true });
    try {
      const zip = new JSZip();
      const zipFilename = "Export-SupplierOrder-" + order.orderNo + "-" + new Date().toISOString() + ".zip";
      const files = [orderConfirmation, invoice].filter((f) => f);
      let count = 0;
      for (let i = 0; i < files.length; i++) {
        const d = files[i]!;
        zipUtils.getBinaryContent(resolveFilePath(d.path), (err: Error | null, data: string | ArrayBuffer | null) => {
          if (!err) {
            count++;
            zip.file(d.path, data ?? "", { binary: true });
          }
          if (count === files.length) {
            zip.generateAsync({ type: "blob" }).then((content) => {
              saveAs(content, zipFilename);
            });
          }
        });
      }
    } finally {
      this.setState({ generating: false });
    }
  };

  handleClickMissingDocument = (fileType: string) => {
    const fileSelectOption = SO_FILETYPES.find((f) => f.value === fileType);
    this.fileUploadRef?.handleShow(fileSelectOption);
  };

  /**
   * Checks all relevant files for existence and returns them.
   * @param order Order whose file should be checked
   * @returns {{ orderConfirmation: OrderFile | undefined, invoice: OrderFile | undefined}} Relevant files
   */
  getFiles = (
    order: SupplierOrderExtended
  ): {
    orderConfirmation: OrderFile | undefined;
    invoice: OrderFile | undefined;
  } => {
    let orderConfirmation, invoice;
    for (let i = 0; i < order.files.length; i++) {
      const f = order.files[i];
      // Uploaded specification overwrites commodity master spec
      if (f.type === SO_ORDERCONFIRMATION) orderConfirmation = f;
      else if (f.type === SO_INVOICE) invoice = f;
    }
    return { orderConfirmation, invoice };
  };

  render() {
    const { order, context, done } = this.props;
    const { orderConfirmation, invoice } = this.state;
    const noFiles = !(orderConfirmation || invoice);
    const allFiles = orderConfirmation && invoice;
    const active = isActive(order);

    return (
      <div className="opacity-100-hover" style={{ opacity: done || !active ? 0.3 : 1 }}>
        <div className="fw-bolder text-white fs-3 my-5">
          Documents{" "}
          {done || !active ? (
            <i className="h2 fas fa-check-circle text-success" />
          ) : (
            <span className="text-warning">[Current Task]</span>
          )}
        </div>
        <div className="py-0">
          <div className="table-responsive mt-5 pt-2 bg-light2">
            <table className="table table-row-gray-100 align-middle">
              <thead>
                <tr className="fw-bolder text-muted">
                  <th className="border-bottom-0">Type</th>
                  <th className="border-bottom-0">Title</th>
                  <th className="border-bottom-0">Uploaded</th>
                  <th className="border-bottom-0 text-right">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="align-middle">
                    <span className="text-white fw-bolder">Order Confirmation</span>
                    <Tooltip tooltipText={"Order Confirmation from Supplier"}>
                      <span className="ml-2">
                        <i className="fa fa-info-circle text-white" />
                      </span>
                    </Tooltip>
                  </td>
                  <td className="align-middle">
                    {orderConfirmation ? (
                      <a href={resolveFilePath(orderConfirmation.path)} target="_blank" rel="noopener noreferrer">
                        <span className="text-white fw-bold">{shortenAlias(orderConfirmation.path)}</span>
                      </a>
                    ) : (
                      <span
                        className="text-danger fw-bold cursor-pointer"
                        onClick={() => this.handleClickMissingDocument(SO_ORDERCONFIRMATION)}
                      >
                        missing
                      </span>
                    )}
                  </td>
                  <td className="align-middle">
                    <span className="text-success">{orderConfirmation && formatDate(orderConfirmation.date)}</span>
                  </td>
                  <td className="align-middle text-right">
                    {!done && orderConfirmation && (
                      <SimpleConfirmationModal.SimpleConfirmationModalButton
                        size="md"
                        modalTitle="Remove Order Confirmation"
                        onConfirm={() => this.handleRemoveDocument(SO_ORDERCONFIRMATION)}
                        confirmButtonText="Confirm"
                        buttonText="x"
                        buttonClasses="btn btn-text text-danger btn-sm p-2"
                        cancelButtonText="Close"
                        modalDescription={
                          <span className="text-white">
                            Do you really want to remove the order confirmation? This can not be undone.
                          </span>
                        }
                      />
                    )}
                  </td>
                </tr>
                <tr className="my-2">
                  <td className="align-middle">
                    <span className="text-white fw-bolder">Supplier Invoice</span>
                  </td>
                  <td className="align-middle">
                    {invoice ? (
                      <a href={resolveFilePath(invoice.path)} target="_blank" rel="noopener noreferrer">
                        <span className="text-white fw-bold">{shortenAlias(invoice.path)}</span>
                      </a>
                    ) : (
                      <span
                        className="text-warning fw-bold cursor-pointer"
                        onClick={() => this.handleClickMissingDocument(SO_INVOICE)}
                      >
                        optional
                      </span>
                    )}
                  </td>
                  <td className="align-middle">
                    <span className="text-success">{invoice && formatDate(invoice.date)}</span>
                  </td>
                  <td className="align-middle text-right">
                    {!done && invoice && (
                      <SimpleConfirmationModal.SimpleConfirmationModalButton
                        size="md"
                        modalTitle="Remove Order Confirmation"
                        onConfirm={() => this.handleRemoveDocument(SO_INVOICE)}
                        confirmButtonText="Confirm"
                        buttonText="x"
                        buttonClasses="btn btn-text text-danger btn-sm p-2"
                        cancelButtonText="Close"
                        modalDescription={
                          <span className="text-white">
                            Do you really want to remove the order confirmation? This can not be undone.
                          </span>
                        }
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan={4} className="align-middle text-right">
                    {active && !allFiles && (
                      <WorkflowUploadDocumentModal order={order} context={context} ref={this.setRef} />
                    )}
                    {!noFiles && (
                      <button className="fs-7 btn btn-text text-muted" onClick={this.handleDownloadDocuments}>
                        Export All
                      </button>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default WorkflowDocumentsCard;
