import _ from "lodash";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { Accordion } from "react-bootstrap";
import { formatCurrency, getDocFromCollection, pluralize } from "../../../utils/baseUtils";
import Search from "../../common/Search";
import { CustomToggle } from "../../common/CustomToggle";
import { formatArticleUnit, InternalArticleExtended } from "../../../utils/productArticleUtils";
import { GraduatedPrice } from "../../../model/commonTypes";
import { DataContextInternal } from "../../../context/dataContext";
import { SortOrder } from "../../../utils/filterUtils";

enum PriceType {
  AIR = "Air Freight",
  SEA = "Sea Freight",
}

interface ArticleGraduatedPricesProps {
  article: InternalArticleExtended;
}

const ArticleGraduatedPrices: React.FC<ArticleGraduatedPricesProps> = ({ article }) => {
  const context = useContext(DataContextInternal);
  const [search, setSearch] = useState<string>("");

  const handleChangeSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value), []);

  const resolveSupplier = (id: string) => {
    if (!id) return "Best Price";
    const sup = getDocFromCollection(context.supplier, id);
    if (sup) return sup.name;
    return "Unknown";
  };

  const graduatedPricesPrepared = useMemo(() => {
    const gP = article.graduatedPrices;
    const priceMap: { [key: string]: { air: Array<GraduatedPrice>; sea: Array<GraduatedPrice> } } = {};
    if (!gP) return priceMap;
    for (let i = 0; i < gP.air.length; i++) {
      const aP = gP.air[i];
      if (priceMap[aP.supplier]?.air) priceMap[aP.supplier].air = priceMap[aP.supplier].air.concat(aP.prices);
      else priceMap[aP.supplier] = { air: aP.prices, sea: [] };
    }
    for (let i = 0; i < gP.sea.length; i++) {
      const aP = gP.sea[i];
      if (priceMap[aP.supplier]?.sea) priceMap[aP.supplier].sea = priceMap[aP.supplier].sea.concat(aP.prices);
      else priceMap[aP.supplier] = { air: [], sea: aP.prices };
    }
    const keys = Object.keys(priceMap);
    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      priceMap[k].air = _.orderBy(priceMap[k].air, "amount", SortOrder.ASC);
      priceMap[k].sea = _.orderBy(priceMap[k].sea, "amount", SortOrder.ASC);
    }
    return priceMap;
  }, [article.graduatedPrices]);

  const keys = useMemo(() => Object.keys(graduatedPricesPrepared), [graduatedPricesPrepared]);

  return (
    <div className="card bg-white">
      <div className="card-header border-0 mt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3 mb-1">Graduated Prices</span>
          <span className="text-muted fw-bold fs-7">{pluralize(0, "Price")}</span>
        </h3>
        <div className="card-toolbar">
          <Search value={search} onSearch={handleChangeSearch} />
        </div>
      </div>
      <div className="card-body p-4 pt-8">
        <div className="p-4 mb-7 badge badge-light-info fs-7 text-black text-wrap lh-base">
          <div className="row mx-2">
            <div className="col-1">
              <i className="flaticon-info text-black" style={{ fontSize: "50px", color: "#000000" }} />
            </div>
            <div className="col-11 mt-1">
              {keys.length ? (
                <span>
                  Graduated Prices are calculated automatically. They are listed here for information but can't be
                  adjusted from inside the system. If you observe issues with them please notify the development team.
                </span>
              ) : (
                <span>
                  No graduated prices available. This is most likely because there are no active prices for this
                  article. After creating or extending prices, the graduated prices should be calculated within a few
                  minutes.
                </span>
              )}
            </div>
          </div>
        </div>
        <Accordion alwaysOpen={true} defaultActiveKey="">
          {keys.map((key) => (
            <div key={key} className="bg-light2 bg-light2-hover rounded p-5 mb-7">
              <CustomToggle eventKey={key}>
                <div className="d-flex align-items-center ">
                  <div className="flex-grow-1 me-2">
                    <span className="fw-bolder text-gray-800 fs-6">{resolveSupplier(key)}</span>
                  </div>
                </div>
              </CustomToggle>
              <Accordion.Collapse eventKey={key}>
                <div className="table-responsive mt-5">
                  <table className="table align-middle gs-0 gy-1 ">
                    <thead>
                      <tr className="fw-bolder text-muted">
                        <th className="border-bottom-0" style={{ width: "33%" }}>
                          Type
                        </th>
                        <th className="border-bottom-0" style={{ width: "33%" }}>
                          Amount
                        </th>
                        <th className="border-bottom-0" style={{ width: "33%" }}>
                          Price per {article.unit}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {graduatedPricesPrepared[key].air.map((price, idx) => (
                        <ArticleGraduationPriceRow key={idx} article={article} price={price} type={PriceType.AIR} />
                      ))}
                      {graduatedPricesPrepared[key].sea.map((price, idx) => (
                        <ArticleGraduationPriceRow key={idx} article={article} price={price} type={PriceType.SEA} />
                      ))}
                    </tbody>
                  </table>
                </div>
              </Accordion.Collapse>
            </div>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

interface ArticleGraduationPriceRowProps {
  article: InternalArticleExtended;
  price: GraduatedPrice;
  type: PriceType;
}

const ArticleGraduationPriceRow: React.FC<ArticleGraduationPriceRowProps> = ({ article, price, type }) => {
  return (
    <tr>
      <td className="text-white">{type}</td>
      <td className="text-white">
        {price.amount} {formatArticleUnit(article.unit, article)}
      </td>
      <td className="text-white">{formatCurrency(price.unitPrice, price.currency)}</td>
    </tr>
  );
};

export default ArticleGraduatedPrices;
