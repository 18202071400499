import _ from "lodash";
import React, { PureComponent } from "react";
import { DataContextInternal } from "../../../../context/dataContext";
import { EUStockCalculationValues, recalculatePaletteDataOnChange } from "../../../../utils/priceCalculationUtils";
import { Input } from "../../../common/Input";
import { EURO } from "../../../../utils/currencyUtils";
import { PaletteDataView } from "./PaletteDataView";
import { pluralize } from "../../../../utils/baseUtils";
import WarehouseCostView from "./WarehouseCostView";

interface EuStockCalculationViewProps {
  totalAmount: number;
  calculationValues: EUStockCalculationValues;
}

interface EuStockCalculationViewState {
  calculationValues: EUStockCalculationValues;
}

class EuStockCalculationView extends PureComponent<EuStockCalculationViewProps, EuStockCalculationViewState> {
  static contextType = DataContextInternal;
  context!: React.ContextType<typeof DataContextInternal>;

  constructor(props: EuStockCalculationViewProps) {
    super(props);
    this.state = {
      calculationValues: _.cloneDeep(props.calculationValues),
    };
  }

  componentDidUpdate(prevProps: EuStockCalculationViewProps) {
    if (!_.isEqual(prevProps.calculationValues, this.props.calculationValues)) {
      this.setState({ calculationValues: _.cloneDeep(this.props.calculationValues) });
    }
  }

  handleChangeNumValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    const name = e.target.name as keyof EUStockCalculationValues;
    const value = +e.target.value;
    _.set(
      calculationValues,
      name,
      ["customsFeeAgency", "customsFreightCoefficient"].includes(name) ? value / 100 : value
    );
    this.setState({ calculationValues });
  };

  handleChangeWarehouseGeneralCost = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    calculationValues.warehouseCost.generalCost[index].costPerUnit = +e.target.value;
    this.setState({ calculationValues });
  };

  handleChangePaletteData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    recalculatePaletteDataOnChange(calculationValues, this.props.totalAmount, e);
    this.setState({ calculationValues });
  };

  /**
   * Used for parent component to get state values
   */
  getCalculationValues = () => {
    return this.state.calculationValues;
  };

  render() {
    const { totalAmount } = this.props;
    const { calculationValues } = this.state;
    const { paletteData, baseValues, warehouseCost, b2bFollowUpCost } = calculationValues;
    return (
      <div>
        <h3 className="mb-3 mt-7">Transport</h3>
        <div className="row">
          <div className="col-xl-4">
            <label className="fs-5 fw-bold mb-2">
              B2B Follow-up Cost (based on {pluralize(baseValues.palettes, "palette")})
            </label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type={"number"}
                min={0}
                name={"b2bFollowUpCost"}
                value={b2bFollowUpCost}
                onChange={this.handleChangeNumValue}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  {EURO}
                </div>
              </div>
            </div>
          </div>
        </div>

        <WarehouseCostView
          warehouseCost={warehouseCost}
          onChangeWarehouseCost={this.handleChangeWarehouseGeneralCost}
        />
        <PaletteDataView
          totalAmount={totalAmount}
          paletteData={paletteData}
          baseValues={baseValues}
          onChangePaletteData={this.handleChangePaletteData}
        />
      </div>
    );
  }
}

export default EuStockCalculationView;
