import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { insertProperty, PROPERTYOPTIONSALL, PropertyType, updateProperty } from "../../../../utils/propertyUtils";
import { Property } from "../../../../model/property.types";
import CustomSelect from "../../../common/CustomSelect";

interface CreatePropertyModalProps {
  property?: Property;
  additionalButtonClasses?: string;
  onlyModal?: boolean;
  show?: boolean;
  onHide?: () => void;
  type?: { value: PropertyType; label: string };
}

interface CreatePropertyModalState {
  step: number;
  show: boolean;
  saving: boolean;
  name: string;
  description: string;
  type: { value: PropertyType; label: string };
}

class CreatePropertyModal extends PureComponent<CreatePropertyModalProps, CreatePropertyModalState> {
  constructor(props: CreatePropertyModalProps) {
    super(props);
    this.state = this.getDefaultState(false);
  }

  componentDidUpdate(prevProps: Readonly<CreatePropertyModalProps>) {
    if (prevProps.show !== this.props.show) {
      this.setState(this.getDefaultState(!!this.props.show));
    }
  }

  handleShow = () => this.setState(this.getDefaultState(true));
  handleHide = () => {
    const { onHide } = this.props;
    this.setState({ show: false, saving: false });
    if (onHide) onHide();
  };
  handleNext = () => this.setState({ step: 2 });
  handleBack = () => this.setState({ step: 1 });

  handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  handleTypeChange = (type: { value: PropertyType; label: string }) => {
    this.setState({ type });
  };

  /**
   * Handles the creation / edit of the property
   */
  handleCreateProperty = async () => {
    const { property } = this.props;
    const { name, description, type } = this.state;
    this.setState({ saving: true });
    const prop = {
      _id: property ? property._id : new BSON.ObjectId(),
      name: { en: name },
      description: { en: description },
      type: type.value,
      disabled: false,
    };
    let result;
    // If a property was passed as props we are in edit case
    if (property) {
      result = await updateProperty(prop);
    } else {
      result = await insertProperty(prop);
    }
    if (result) {
      toast.success("Property " + (property ? "edited" : "inserted") + " successfully");
      this.handleHide();
    } else {
      toast.error("Error " + (property ? "editing" : "inserting") + " property");
    }
  };

  /**
   * Returns the default state of the component.
   * @param show Indicates which values should be used for show
   * @returns { CreatePropertyModalState } Default state with given show value
   */
  getDefaultState = (show: boolean): CreatePropertyModalState => {
    const { property, show: showProps, type } = this.props;
    return {
      show: showProps ?? show,
      step: 1,
      saving: false,
      name: property ? property.name.en : "",
      description: property ? property.description.en : "",
      type: type
        ? type
        : property
        ? PROPERTYOPTIONSALL.find((po) => po.value === property.type)
        : PROPERTYOPTIONSALL[0],
    } as CreatePropertyModalState;
  };

  /**
   * Checks if the given input is valid. Also checks if there are any changes in edit case.
   * @returns { boolean } True if input is valid, else false
   */
  inputValid = (): boolean => {
    const { property } = this.props;
    const { name, description, type } = this.state;
    const nt = name.trim();
    return (
      nt !== "" &&
      nt.length >= 3 &&
      (!property ||
        property.name.en !== name ||
        property.description.en !== description ||
        property.type !== type.value)
    );
  };

  render() {
    const { additionalButtonClasses, property, onlyModal } = this.props;
    const { description, name, type, saving, show, step } = this.state;
    const onSummary = step === 2;

    return (
      <>
        {!onlyModal && (
          <button
            className={"btn btn-outline btn-outline-light " + (additionalButtonClasses ?? "")}
            onClick={this.handleShow}
          >
            {property ? "Edit" : "New Property"}
          </button>
        )}
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">{property ? "Edit" : "New"} property</h1>
            </Modal.Title>
            <CloseButton variant={"white"} onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-3">
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="required fs-5 fw-bold mb-2">Name</label>
                <input
                  type="text"
                  className={"form-control custom-form-control " + (onSummary ? "disabled" : "")}
                  name="name"
                  value={name}
                  disabled={onSummary}
                  onChange={this.handleTextChange}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="fs-5 fw-bold mb-2">Description</label>
                <input
                  type="textarea"
                  className={"form-control custom-form-control " + (onSummary ? "disabled" : "")}
                  name="description"
                  value={description}
                  disabled={onSummary}
                  onChange={this.handleTextChange}
                />
              </div>
            </div>
            <div className="row mb-6">
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="required fs-5 fw-bold mb-2">Type</label>
                <CustomSelect
                  options={PROPERTYOPTIONSALL}
                  onChange={this.handleTypeChange}
                  value={type}
                  disabled={step === 2 || !!this.props.type}
                />
              </div>
            </div>
            {onSummary && (
              <div className="row">
                <h2 className="fw-bolder text-white text-center">Please check that the data above is correct!</h2>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-sm btn-outline btn-text-white"
              onClick={step === 1 ? this.handleHide : this.handleBack}
            >
              {step === 1 ? "Close" : "Back"}
            </button>
            <button
              className={"btn btn-sm btn-outline btn-outline-light " + (this.inputValid() || saving ? "" : "disabled")}
              disabled={!this.inputValid() || saving}
              onClick={step === 1 ? this.handleNext : this.handleCreateProperty}
            >
              {saving ? "Saving..." : step === 1 ? "Next" : property ? "Confirm" : "Create"}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreatePropertyModal;
