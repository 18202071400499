import _ from "lodash";
import React, { PureComponent } from "react";
import ReactApexChart from "react-apexcharts";
import { Overlay, Popover } from "react-bootstrap";
import { ApexOptions } from "apexcharts";
import { formatCurrency } from "../../utils/baseUtils";
import { BASE_CURRENCY } from "../../utils/currencyUtils";
import { formatArticleUnit, InternalArticleExtended } from "../../utils/productArticleUtils";

interface SupplierPriceGraphPopoverProps {
  article: InternalArticleExtended;
  betterPriceMOQ: number | null;
  amount: number;
}

interface SupplierPriceGraphPopoverState {
  series: ApexAxisChartSeries | null;
  maxXValue: number;
  percent: number;
  show: boolean;
}

class SupplierPriceGraphPopover extends PureComponent<SupplierPriceGraphPopoverProps, SupplierPriceGraphPopoverState> {
  overlayRef: React.RefObject<HTMLDivElement>;
  constructor(props: SupplierPriceGraphPopoverProps) {
    super(props);
    this.overlayRef = React.createRef();
    this.state = { series: [], percent: 0, show: false, maxXValue: 0 };
  }

  componentDidMount() {
    this.collectData();
  }

  componentDidUpdate(prevProps: Readonly<SupplierPriceGraphPopoverProps>) {
    if (
      !_.isEqual(prevProps.article.suppliers, this.props.article.suppliers) ||
      prevProps.amount !== this.props.amount
    ) {
      this.collectData();
    }
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState({ show: false });

  collectData = () => {
    const { article, amount } = this.props;

    const series: ApexAxisChartSeries | null = [];
    let maxValidMOQ = 0;
    for (let i = 0; i < article.suppliers.length; i++) {
      const cSupplier = article.suppliers[i];
      if (cSupplier.disabled || cSupplier.supplier.disabled) continue;
      const validSortedPrices = cSupplier.prices
        .filter((p) => p.validUntil > new Date() && p.price > 0)
        .sort((a, b) => a.minOQ - b.minOQ);
      if (validSortedPrices.length === 0) continue;
      const highestMOQ = validSortedPrices[validSortedPrices.length - 1].minOQ; // highest moq is last element
      if (highestMOQ > maxValidMOQ) maxValidMOQ = highestMOQ;
      const data = validSortedPrices.map((vP) => {
        return { x: vP.minOQ, y: vP.price };
      });

      // Extend data if necessary to not only have 1 data point
      if (data.length === 1) {
        if (data[0].x === 0) {
          data.push({ x: 100, y: data[0].y });
          data.push({ x: 1000, y: data[0].y });
          if (maxValidMOQ < 1000) maxValidMOQ = 1000;
        } else {
          data.push({ x: 0, y: data[0].y });
          data.push({ x: 5 * data[0].x, y: data[0].y });
          if (5 * data[0].x < 1000) maxValidMOQ = 5 * data[0].x;
        }
      }

      series.push({
        name: cSupplier.supplier.name,
        data: data,
      });
    }

    // Include currently selected amount if higher than max moq
    if (maxValidMOQ < amount) maxValidMOQ = amount;
    // Factor to extend x axis a bit
    const XFACTOR = 1.15;

    // Extend data with highest found moq to get a line to the end of the chart
    for (let i = 0; i < series.length; i++) {
      const data = series[i].data as Array<{ x: number; y: number }>;
      // Add entry for 0
      if (data[0]?.y !== 0) data.unshift({ x: 0, y: data[0].y });
      if (data[data.length - 1]?.x < maxValidMOQ * XFACTOR)
        data.push({ x: maxValidMOQ * XFACTOR, y: data[data.length - 1].y });
    }

    this.setState({ series: series.length > 0 ? series : null, maxXValue: maxValidMOQ * XFACTOR });
  };

  render() {
    const { article, amount, betterPriceMOQ } = this.props;
    const { series, show } = this.state;

    if (!series) return <span>Supplier</span>;

    const maxY =
      Math.ceil(
        series.reduce((max, ser) => {
          const data = ser.data as Array<{ x: number; y: number }>;
          const dMax = data.reduce((lMax, val) => (val.y > lMax ? val.y : lMax), 0);
          return dMax > max ? dMax : max;
        }, 0) / 10
      ) * 10;

    const options: ApexOptions = {
      tooltip: {
        enabled: true,
        x: {
          show: false,
        },
        y: {
          formatter: (val: number) => `${formatCurrency(val, BASE_CURRENCY)}/${article.unit}`,
        },
      },
      grid: { show: false },
      xaxis: {
        type: "numeric",
        axisBorder: { show: false },
        axisTicks: { show: false },
        labels: {
          show: true,
          style: {
            colors: "#aaa",
          },
          formatter: (val: string) => Math.round(+val) + formatArticleUnit(article.unit),
        },
      },
      yaxis: {
        min: 0,
        max: maxY > 0 ? maxY : undefined,
        tickAmount: 10,
        axisBorder: { show: false },
        axisTicks: { show: false },
        labels: {
          minWidth: 50,
          show: true,
          style: {
            colors: "#aaa",
          },
          offsetX: -20,
          formatter: (val: number) => formatCurrency(val, BASE_CURRENCY),
        },
      },
      chart: {
        zoom: { enabled: false },
        animations: { enabled: false },
        toolbar: { show: false },
        sparkline: { enabled: false },
        type: "area",
      },
      dataLabels: {
        enabled: true,
        formatter: (val: number) => formatCurrency(val, BASE_CURRENCY),
      },
      stroke: {
        curve: "stepline",
        width: 2,
      },
      fill: {
        opacity: 0,
        type: "solid",
      },
      legend: {
        labels: {
          useSeriesColors: true,
        },
      },
      annotations: {
        xaxis: [
          {
            x: amount,
            borderColor: "#775DD0",
            label: {
              orientation: "horizontal",
              text: `${amount}${formatArticleUnit(article.unit)}`,
              style: {
                color: "#ffffff",
                background: "#775DD0",
              },
            },
          },
        ],
      },
    };
    if (options && options.annotations && options.annotations.xaxis && betterPriceMOQ !== null) {
      options.annotations.xaxis.push({
        x: betterPriceMOQ,
        borderColor: "#ffc700",
        label: {
          orientation: "horizontal",
          text: `${betterPriceMOQ}${formatArticleUnit(article.unit)}`,
          style: {
            color: "#000000",
            background: "#ffc700",
          },
        },
      });
    }

    return (
      <>
        <span onClick={this.handleShow} ref={this.overlayRef}>
          Supplier
          <i className="fa fa-info-circle ml-2 align-middle" />
        </span>
        <Overlay rootClose show={show} onHide={this.handleHide} target={this.overlayRef.current} placement={"right"}>
          <Popover style={{ maxWidth: "95vw" }}>
            <div className="card bg-white overflow-auto">
              <div className="card-header border-0 mt-5">
                <div className="card-title flex-column">
                  <h2>Price Graduation</h2>
                  <div className="text-muted fw-normal" style={{ fontSize: "13px" }}>
                    Chart &amp; Price List
                  </div>
                </div>
              </div>
              <div className="card-body p-9 pt-4 " style={{ minWidth: "900px" }}>
                <div className="row">
                  <div className="col-12">
                    <ReactApexChart options={options} series={series} type="area" />
                  </div>
                </div>
              </div>
            </div>
          </Popover>
        </Overlay>
      </>
    );
  }
}

export default SupplierPriceGraphPopover;
