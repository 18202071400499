import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import * as Realm from "realm-web";
import authService, { callCheckTokenValidity } from "../../services/authService";
import userService from "../../services/userService";

interface ApiKeyLoginProps extends RouteComponentProps {}

interface ApiKeyLoginState {}

class ApiKeyLogin extends PureComponent<ApiKeyLoginProps, ApiKeyLoginState> {
  async componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const apiKey = query.get("t");
    if (!apiKey) {
      window.location.href = "/logout";
      console.error("Invalid token");
      return;
    }
    const credentials = Realm.Credentials.apiKey(apiKey);
    try {
      await authService.loginWithCredentials(credentials);
      const userMail = userService.getUserMail();
      const valid = userMail && (await callCheckTokenValidity(userMail));
      if (!valid) {
        window.location.href = "/logout";
        return;
      }
      // Do full reload of page after login
      window.location.href = "/dashboard";
    } catch (ex: any) {
      console.error(ex.error);
      window.location.href = "/logout";
    }
  }

  render() {
    return <div />;
  }
}

export default ApiKeyLogin;
