import _ from "lodash";
import React, { PureComponent, useContext, useMemo } from "react";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import userService from "../../../../services/userService";
import {
  getUserName,
  INTERNAL,
  REQUIRED_ROLES_RESPONSIBLE_CONFIGURATION,
  UserSnapshot,
} from "../../../../utils/userUtils";
import { DashboardColumn, getDashboardColumnString } from "../../../../utils/internalDashboardUtils";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";
import { DataContextInternal, DataContextInternalType } from "../../../../context/dataContext";
import { UserData } from "../../../../model/userData.types";
import {
  ResponsibleConfiguration as ResponsibleConfigurationType,
  ResponsibleConfigurationValues,
} from "../../../../model/configuration/responsibleConfiguration.types";
import { CONFIG, getConfigurationFromContext } from "../../../../utils/configurationUtils";
import { CONFIGURATION, transaction, UpdateAction } from "../../../../services/dbService";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";

const ResponsibleConfigurationWrapper: React.FunctionComponent = () => {
  const context = useContext(DataContextInternal);
  const responsibleConfiguration = useMemo(
    () => getConfigurationFromContext<ResponsibleConfigurationType>(CONFIG.RESPONSIBLE, context),
    [context.configuration]
  );
  return <ResponsibleConfiguration context={context} configuration={responsibleConfiguration} />;
};

interface ResponsibleConfigurationProps {
  context: DataContextInternalType;
  configuration?: ResponsibleConfigurationType;
}

interface ResponsibleConfigurationState {
  internalUsers: Array<SelectOption<UserData>>;
  responsible: { [key: string]: Array<SelectOption<UserSnapshot>> };
  saving: boolean;
}

class ResponsibleConfiguration extends PureComponent<ResponsibleConfigurationProps, ResponsibleConfigurationState> {
  constructor(props: ResponsibleConfigurationProps) {
    super(props);
    this.state = this.getDefaultState(props);
  }

  handleChangeResponsible = (column: DashboardColumn, responsible: Array<SelectOption<UserSnapshot>>) => {
    const responsibleState = _.cloneDeep(this.state.responsible);
    _.set(responsibleState, column, responsible);
    this.setState({ responsible: responsibleState });
  };

  handleResetResponsible = () => this.setState(this.getDefaultState(this.props));

  handleSaveResponsible = async () => {
    const { responsible } = this.state;
    this.setState({ saving: true });
    try {
      const values: ResponsibleConfigurationValues = {};
      for (const column in responsible) {
        values[column] = responsible[column].map((u) => {
          const { _id, prename, surname, image } = u.object!;
          return { _id, prename, surname, image };
        });
      }
      const timelineEntry = {
        _id: new BSON.ObjectId(),
        date: new Date(),
        person: userService.getUserId(),
        pre: this.props.configuration?.values,
        post: values,
      };
      const action: UpdateAction = {
        collection: CONFIGURATION,
        filter: { key: CONFIG.RESPONSIBLE },
        update: { values, lastUpdate: new Date() },
        push: { timeline: timelineEntry },
      };
      const res = await transaction([action]);
      if (res) {
        toast.success("Responsible configuration updated successfully");
      } else {
        toast.error("Error updating responsible configuration");
      }
    } catch (e) {
      console.error("ERROR:", e);
      toast.error("Error updating responsible configuration");
    } finally {
      this.setState({ saving: false });
    }
  };

  getDefaultState = (props: ResponsibleConfigurationProps): ResponsibleConfigurationState => {
    const internalUsers: Array<SelectOption<UserData>> = [];
    for (let i = 0; i < props.context.userData.length; i++) {
      const u = props.context.userData[i];
      if (u.type !== INTERNAL) continue;
      internalUsers.push({ value: u._id.toString(), label: getUserName(u), object: u });
    }
    const responsibleValues = props.configuration?.values;
    const responsible: { [key: string]: Array<SelectOption<UserSnapshot>> } = {};
    if (responsibleValues) {
      for (const value in responsibleValues) {
        responsible[value] = responsibleValues[value].map((u) => {
          return { value: u._id.toString(), label: getUserName(u), object: u };
        });
      }
    }
    return { internalUsers, responsible, saving: false };
  };

  render() {
    const { configuration } = this.props;
    const { internalUsers, responsible, saving } = this.state;

    if (!userService.getRoles().some((r) => REQUIRED_ROLES_RESPONSIBLE_CONFIGURATION.includes(r)))
      return (
        <div className="content d-flex flex-column flex-column-fluid">
          <div className="post d-flex flex-column-fluid">
            <div className="container-xxl">
              <div className="card bg-white min-h-100">
                <div className="card-body">
                  <h3 className="card-title ">
                    <span className="card-label fw-bolder fs-3rem">Responsible Configuration</span>
                  </h3>
                  <h5 className="mt-20 text-center">
                    <span className="text-muted">You do not have access to this section</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    if (!configuration) {
      return (
        <div className="content d-flex flex-column flex-column-fluid">
          <div className="post d-flex flex-column-fluid">
            <div className="container-xxl">
              <div className="card bg-white min-h-100">
                <div className="card-body">
                  <h3 className="card-title ">
                    <span className="card-label fw-bolder fs-3rem">Responsible Configuration</span>
                  </h3>
                  <h5 className="mt-20 text-center">
                    <span className="text-muted">
                      Configuration values for responsible configuration could not be loaded.
                    </span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="card bg-white min-h-100">
              <div className="card-body">
                <h3 className="card-title mb-15">
                  <span className="card-label fw-bolder fs-3rem">Responsible Configuration</span>
                </h3>
                {Object.values(DashboardColumn).map((dC) => (
                  <ColumnConfiguration
                    key={dC}
                    column={dC}
                    internalUsers={internalUsers}
                    responsible={(_.get(responsible, dC) as Array<SelectOption<UserData>> | undefined) || []}
                    onChangeResponsible={this.handleChangeResponsible}
                  />
                ))}
              </div>
              <div className="card-footer border-0 text-right">
                <ErrorOverlayButton
                  saving={saving}
                  className="btn btn-sm btn-light mr-3"
                  onClick={this.handleResetResponsible}
                >
                  Reset
                </ErrorOverlayButton>
                <ErrorOverlayButton
                  className="btn btn-sm btn-outline btn-outline-light"
                  onClick={this.handleSaveResponsible}
                >
                  Save
                </ErrorOverlayButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

interface ColumnConfigurationProps {
  column: DashboardColumn;
  internalUsers: Array<SelectOption<UserData>>;
  responsible: Array<SelectOption<UserData>>;
  onChangeResponsible: (column: DashboardColumn, responsible: Array<SelectOption<UserData>>) => void;
}

const ColumnConfiguration: React.FunctionComponent<ColumnConfigurationProps> = ({
  column,
  internalUsers,
  responsible,
  onChangeResponsible,
}) => {
  return (
    <div className="row my-3">
      <div className="col-6">
        <span className="text-white">{getDashboardColumnString(column)}</span>
      </div>
      <div className="col-6">
        <span className="text-white">
          <CustomSelect
            options={internalUsers}
            value={responsible}
            isMulti={true}
            onChange={(e: Array<SelectOption<UserData>>) => onChangeResponsible(column, e)}
          />
        </span>
      </div>
    </div>
  );
};

export default ResponsibleConfigurationWrapper;
