import _ from "lodash";
import { BSON } from "realm-web";
import { callFunction } from "../services/dbService";
import {
  FinishedProduct,
  FinishedProductComparisonObject,
  FinishedProductExtended,
  FinishedProductSnapshot,
  FinishedProductTimelineEntry,
  PackagingSizeFinishedProduct,
} from "../model/finishedProduct.types";
import {
  CustomerFinishedProduct,
  CustomerFinishedProductExtended,
} from "../model/customer/customerFinishedProduct.types";
import { Commodity, CommoditySnapshot } from "../model/commodity.types";
import { CustomerCommodity } from "../model/customer/customerCommodity.types";
import { Property } from "../model/property.types";
import {
  getArticleProperty,
  P_CARTON,
  T_BATCHCREATED,
  T_FILEDELETED,
  T_FILEUPDATED,
  T_FILEUPLOADED,
  T_MASTERSPECCREATED,
  T_MASTERSPECSIGNED,
  T_PRICESUPDATED,
  T_PRICEUPDATED,
  T_SELLINGPRICESUPDATED,
  T_SUPPLIERPRICEUPDATED,
  T_TRANSPORTPRICEUPDATED,
} from "./commodityUtils";
import { T_WAREHOUSE } from "../model/customerOrder.types";
import { BASE_CURRENCY } from "./currencyUtils";
import userService from "../services/userService";
import { getAllListDifferences, getAllListDifferencesGeneral } from "./diffUtils";
import { doFuseSearch } from "./baseUtils";
import { PropertyType } from "./propertyUtils";
import { ANONYMOUS, CUSTOMER, INTERNAL, SUPPLIER } from "./userUtils";
import { Article, ArticleExtended, ArticleSnapshot } from "./productArticleUtils";
import { SupplierFinishedProductExtended } from "../model/supplier/supplierFinishedProduct.types";

const UPSERTFINISHEDPRODUCT = "upsertFinishedProduct";

export const FP_UNITS = ["1000 pcs"];

// Transport condition types
export const TC_TEMPERATURE = "tcTemperature";
export const TC_HAZARDMATERIAL = "tcHazardMaterial";
export const TRANSPORTCONDITIONS = [
  { value: TC_HAZARDMATERIAL, label: "Hazard Material" },
  { value: TC_TEMPERATURE, label: "Temperature Condition" },
];

// Timeline entries
export const T_FINISHEDPRODUCTCREATED = "finishedProductCreated";
export const T_FINISHEDPRODUCTEDITED = "finishedProductEdited";
export const T_FINISHEDPRODUCTAPPROVED = "finishedProductApproved";
export const T_FINISHEDPRODUCTDISABLED = "finishedProductDisabled";
export const T_FINISHEDPRODUCTENABLED = "finishedProductEnabled";
export const T_FIINISHEDPRODUCTORDEREDFROMSUPPLIER = "finishedProductOrderedFromSupplier";
export const T_FINISHEDPRODUCTBATCHCREATED = "finishedProductBatchCreated";

export type FP_TIMELINETYPE =
  | typeof T_FINISHEDPRODUCTCREATED
  | typeof T_FINISHEDPRODUCTEDITED
  | typeof T_FINISHEDPRODUCTAPPROVED
  | typeof T_FINISHEDPRODUCTDISABLED
  | typeof T_FINISHEDPRODUCTENABLED
  | typeof T_FILEUPLOADED
  | typeof T_FILEUPDATED
  | typeof T_FILEDELETED
  | typeof T_PRICEUPDATED
  | typeof T_PRICESUPDATED
  | typeof T_SUPPLIERPRICEUPDATED
  | typeof T_MASTERSPECCREATED
  | typeof T_MASTERSPECSIGNED
  | typeof T_SELLINGPRICESUPDATED
  | typeof T_TRANSPORTPRICEUPDATED
  | typeof T_FIINISHEDPRODUCTORDEREDFROMSUPPLIER
  | typeof T_FINISHEDPRODUCTBATCHCREATED
  | typeof T_BATCHCREATED;

// finished product field groups
export const FP_G_PRODUCTINFORMATION = "productInformationFinishedProduct";
export const FP_G_GRADE = "gradeFinishedProduct";

export type FP_G_TYPE = typeof FP_G_PRODUCTINFORMATION | typeof FP_G_GRADE;
export const FP_G_TYPES = [FP_G_PRODUCTINFORMATION, FP_G_GRADE] as const;

export const FINISHEDPRODUCT_COMPARISON_KEYS: Array<keyof FinishedProductComparisonObject> = [
  "title",
  "subtitle",
  "suppliers",
  "supplierEUStocks",
  "sellingPrices",
  "organic",
  "note",
  "country",
  "approved",
  "hsCode",
  "btiRefNo",
  "activeSubstances",
  "properties",
  "color",
  "vegetarian",
  "vegan",
  "halal",
  "kosher",
  "foodGrade",
  "pharmaceuticalGrade",
  "shelfLife",
  "storageConditions",
  "feedGrade",
  "cosmeticGrade",
  "uspGrade",
  "medicineGrade",
  "industrialGrade",
  "packagingSizes",
  "duty",
  "transportConditions",
  "weightPerUnit",
  "volumePerUnit",
  "fromPrice",
];

export type FP_FINISHEDPRODUCT_PROPERTIES = keyof FinishedProductSnapshot &
  keyof CustomerFinishedProduct &
  keyof FinishedProduct;

/**
 * Inserts a new finishedProduct into the database.
 * @param finishedProduct FinishedProduct that should be inserted into the database
 * @returns { Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false> } Result of the function
 */
export async function insertFinishedProduct(
  finishedProduct: FinishedProduct
): Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false> {
  return (await callUpsertFinishedProduct(finishedProduct, true)) as
    | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>
    | false;
}

/**
 * Updates an existing finishedProduct inside the database.
 * @param finishedProduct FinishedProduct that should be updated inside the database
 * @param finishedProductId Optional id of finishedProduct if it is not contained in finishedProduct object
 * @param timelineEntry optional, timeline entry to push on update
 * @returns { Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> } Result of the function
 */
export async function updateFinishedProduct(
  finishedProduct: Partial<FinishedProduct>,
  finishedProductId?: BSON.ObjectId,
  timelineEntry?: FinishedProductTimelineEntry
): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> {
  if (finishedProductId) finishedProduct._id = finishedProductId;
  return (await callUpsertFinishedProduct(finishedProduct, false, timelineEntry)) as
    | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>
    | false;
}

/**
 * Calls the upsert finishedProduct function in backend.
 * @param finishedProduct FinishedProduct that should be upsert
 * @param insert True for insert, else update
 * @param timelineEntry optional, timeline entry to push on update
 * @returns { Promise<false | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>> } Result of the function
 */
async function callUpsertFinishedProduct(
  finishedProduct: Partial<FinishedProduct>,
  insert: boolean,
  timelineEntry?: FinishedProductTimelineEntry
): Promise<
  false | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>
> {
  return callFunction(UPSERTFINISHEDPRODUCT, [finishedProduct, insert, timelineEntry]);
}

/**
 * Determines if the given finished product is for internal  or not.
 * @param finishedProduct FinishedProduct or commodity that should be checked
 * @returns { boolean } Indicating if the finished product is for internal or not
 */
export function isFinishedProduct(
  finishedProduct: Article | ArticleExtended | ArticleSnapshot | undefined
): finishedProduct is
  | FinishedProduct
  | FinishedProductExtended
  | CustomerFinishedProduct
  | CustomerFinishedProductExtended {
  if (!finishedProduct) return false;
  return "weightPerUnit" in finishedProduct;
}

/**
 * Determines if the given finished product is for internal or not.
 * @param article FinishedProduct or commodity that should be checked
 * @param view Current view
 * @returns { boolean } Indicating if the finished product is for customer or not
 */
export function isInternalFinishedProduct(
  article: Article | ArticleExtended | ArticleSnapshot | undefined,
  view: string
): article is FinishedProduct | FinishedProductExtended {
  return view === INTERNAL && isFinishedProduct(article);
}

/**
 * Determines if the given finished product is for customer or not.
 * @param article FinishedProduct or commodity that should be checked
 * @param view Current view
 * @returns { boolean } Indicating if the finished product is for customer or not
 */
export function isCustomerFinishedProduct(
  article: Article | ArticleExtended | ArticleSnapshot | undefined,
  view: string
): article is CustomerFinishedProduct | CustomerFinishedProductExtended {
  return view === CUSTOMER && isFinishedProduct(article);
}

/**
 * Determines if the given finished product is for anonymous or not.
 * @param article FinishedProduct or commodity that should be checked
 * @param view Current view
 * @returns { boolean } Indicating if the finished product is for anonymous or not
 */
export function isAnonymousFinishedProduct(
  article:
    | FinishedProduct
    | FinishedProductSnapshot
    | CustomerFinishedProduct
    | Commodity
    | CommoditySnapshot
    | CustomerCommodity,
  view: string
): article is CustomerFinishedProduct {
  return view === ANONYMOUS && isFinishedProduct(article);
}

/**
 * Determines if the given finished product is for supplier or not.
 * @param article FinishedProduct or commodity that should be checked
 * @param view Current view
 * @returns { boolean } Indicating if the finished product is for supplier or not
 */
export function isSupplierFinishedProduct(
  article: Article | ArticleExtended,
  view: string
): article is SupplierFinishedProductExtended | FinishedProduct {
  return view === SUPPLIER && isFinishedProduct(article);
}

/**
 * Get a default finished product
 * @param title optional title
 * @param subtitle optional subtitle
 * @param category optional category
 * @param composition optional composition
 * @param organic optional organic
 * @returns {FinishedProduct} empty finished product
 */
export const getDefaultFinishedProduct = (
  title?: string,
  subtitle?: string,
  category?: Property,
  composition?: Property,
  organic?: boolean
): FinishedProduct => {
  return {
    _id: new BSON.ObjectId(),
    title: { en: title || "" },
    subtitle: { en: subtitle || "" },
    suppliers: [],
    supplierEUStocks: [],
    sellingPrices: [],
    articleNo: "",
    approved: false,
    disabled: false,
    country: { code: "cn", name: "China" },
    organic: Boolean(organic),
    note: "",
    vegetarian: false,
    vegan: false,
    halal: false,
    kosher: false,
    foodGrade: false,
    pharmaceuticalGrade: false,
    cosmeticGrade: false,
    uspGrade: false,
    industrialGrade: false,
    medicineGrade: false,
    feedGrade: false,
    properties: category && composition ? [category._id.toString(), composition._id.toString()] : [],
    shelfLife: 0,
    documents: [],
    duty: { percentage: 0 },
    storageConditions: { en: "" },
    transportConditions: [],
    hsCode: "",
    unit: "1000 pcs",
    weightPerUnit: 0,
    volumePerUnit: 0,
    color: "",
    packagingSizes: [getDefaultPackagingSize()],
    fromPrice: {
      airfreight: { price: 0, currency: BASE_CURRENCY },
      seafreight: { price: 0, currency: BASE_CURRENCY },
      warehouse: { price: 0, currency: BASE_CURRENCY },
      fastestValidDelivery: T_WAREHOUSE,
    },
    activeSubstances: [],
    timeline: [],
    graduatedPrices: { air: [], sea: [] },
    lastUpdate: new Date(),
    vatPercentage: 19,
  };
};

/**
 * Generate default finished product extended object
 * @param title Optional, title for the finished product
 * @param subtitle Optional, subtitle for the finished product
 * @param category Optional, category of the finished product
 * @param composition Optional, composition of the finished product
 * @param organic Optional, organic flag
 * @returns {FinishedProductExtended} Default extended finished product object with given information
 */
export function getDefaultFinishedProductExtended(
  title?: string,
  subtitle?: string,
  category?: Property,
  composition?: Property,
  organic?: boolean
): FinishedProductExtended {
  const fp = getDefaultFinishedProduct(title, subtitle, category, composition, organic);
  const properties: Array<Property> = [];
  if (category) properties.push(category);
  if (composition) properties.push(composition);
  return { ...fp, properties, suppliers: [], activeSubstances: [], documents: [] };
}

/**
 * Get a standard timeline entry without pre and post
 * @param type the timeline type
 * @param subtype optional, additional subtype
 * @param reference optional, additional reference, e.g. for an order
 * @returns {FinishedProductTimelineEntry} a finished product timeline entry with the given information
 */
export const getFinishedProductTimelineEntry = (
  type: FP_TIMELINETYPE,
  subtype?: string,
  reference?: string
): FinishedProductTimelineEntry => {
  const timelineEntry: FinishedProductTimelineEntry = {
    _id: new BSON.ObjectId(),
    person: userService.getUserId(),
    date: new Date(),
    type,
  };
  if (subtype) timelineEntry.subtype = subtype;
  if (reference) timelineEntry.reference = reference;
  return timelineEntry;
};

/**
 * Get a finished product edit timeline entry with differences between origin and update finished product
 * @param finishedProduct the updated finished product object
 * @param originFinishedProduct the origin finished product object
 * @returns {FinishedProductTimelineEntry} finished product timeline entry
 */
export const getFinishedProductEditTimelineEntry = (
  finishedProduct: FinishedProduct | CustomerFinishedProduct,
  originFinishedProduct: FinishedProduct | CustomerFinishedProduct
): FinishedProductTimelineEntry => {
  // Suppliers are changed separately, so they do not have to be handled here currently
  const timelineEntry = getFinishedProductTimelineEntry(T_FINISHEDPRODUCTEDITED);
  const preObject: FinishedProductComparisonObject = {};
  const postObject: FinishedProductComparisonObject = {};
  for (const key of FINISHEDPRODUCT_COMPARISON_KEYS) {
    // special handling for lists, etc.
    if (["documents", "suppliers", "properties", "activeSubstances"].includes(key)) continue;
    let preVal;
    let postVal;
    switch (key) {
      default:
        preVal = originFinishedProduct[key];
        postVal = finishedProduct[key];
    }
    if (!_.isEqual(preVal, postVal)) {
      _.set(preObject, key, preVal);
      _.set(postObject, key, postVal);
    }
  }

  // Properties
  const [differentPropsPre, differentPropsPost] = getAllListDifferencesGeneral(
    originFinishedProduct.properties,
    finishedProduct.properties
  );
  if (differentPropsPre.length > 0 || differentPropsPost.length > 0) {
    // Only save ids
    _.set(preObject, "properties", differentPropsPre);
    _.set(postObject, "properties", differentPropsPost);
  }

  // Active Substances
  const [differentASPre, differentASPost] = getAllListDifferencesGeneral(
    originFinishedProduct.activeSubstances,
    finishedProduct.activeSubstances
  );
  // Ids with percentage
  if (differentASPre.length > 0 || differentASPost.length > 0) {
    _.set(preObject, "activeSubstances", differentASPre);
    _.set(postObject, "activeSubstances", differentASPost);
  }

  // Documents
  const [differentDocsPre, differentDocsPost] = getAllListDifferences(
    originFinishedProduct.documents,
    finishedProduct.documents
  );
  // Save full document
  if (differentDocsPre.length > 0) _.set(preObject, "documents", differentDocsPre);
  if (differentDocsPost.length > 0) _.set(postObject, "documents", differentDocsPost);

  timelineEntry.pre = preObject;
  timelineEntry.post = postObject;
  return timelineEntry;
};

/**
 * Filters the advanced properties of the given commodity.
 * @param finishedProduct Finished product whose properties should be filtered
 * @param search Search query
 * @param type Type of commodity fields that should be returned
 * @returns { Array<{ label: string; value: string, className?: string }> } List of properties with their values
 */
export function filterAdvancedPropertiesFinishedProduct(
  finishedProduct: FinishedProductExtended | CustomerFinishedProductExtended,
  search: string,
  type: FP_G_TYPE
): Array<{ label: string; value: string; className?: string }> {
  const packaging = getArticleProperty(finishedProduct.properties, PropertyType.PACKAGING) as Property | null;

  let properties: Array<{ label: string; value: string; className?: string }> = [];

  switch (type) {
    case FP_G_PRODUCTINFORMATION:
      properties = [
        {
          label: "Vegetarian",
          value: finishedProduct.vegetarian ? "Yes" : "No",
          className: finishedProduct.vegetarian ? "text-success" : "",
        },
        {
          label: "Vegan",
          value: finishedProduct.vegan ? "Yes" : "No",
          className: finishedProduct.vegan ? "text-success" : "",
        },
        {
          label: "Halal",
          value: finishedProduct.halal ? "Yes" : "No",
          className: finishedProduct.halal ? "text-success" : "",
        },
        {
          label: "Kosher",
          value: finishedProduct.kosher ? "Yes" : "No",
          className: finishedProduct.kosher ? "text-success" : "",
        },
        { label: "Packaging", value: packaging ? packaging.name.en : "-" },
      ];
      break;
    case FP_G_GRADE:
      properties = [
        {
          label: "Food Grade",
          value: finishedProduct.foodGrade ? "Yes" : "No",
          className: finishedProduct.foodGrade ? "text-success" : "",
        },
        {
          label: "Pharmaceutical Grade",
          value: finishedProduct.pharmaceuticalGrade ? "Yes" : "No",
          className: finishedProduct.pharmaceuticalGrade ? "text-success" : "",
        },
        {
          label: "Feed Grade",
          value: finishedProduct.feedGrade ? "Yes" : "No",
          className: finishedProduct.feedGrade ? "text-success" : "",
        },
        {
          label: "Cosmetic Grade",
          value: finishedProduct.cosmeticGrade ? "Yes" : "No",
          className: finishedProduct.cosmeticGrade ? "text-success" : "",
        },
        {
          label: "USP Grade",
          value: finishedProduct.uspGrade ? "Yes" : "No",
          className: finishedProduct.uspGrade ? "text-success" : "",
        },
        {
          label: "Medicine Grade",
          value: finishedProduct.medicineGrade ? "Yes" : "No",
          className: finishedProduct.medicineGrade ? "text-success" : "",
        },
        {
          label: "Industrial Grade",
          value: finishedProduct.industrialGrade ? "Yes" : "No",
          className: finishedProduct.industrialGrade ? "text-success" : "",
        },
      ];
      break;
  }
  return search.trim() ? doFuseSearch(properties, search, ["label"]) : _.sortBy(properties, (obj) => obj.label);
}

/**
 * Get default packaging size object for finished products.
 * @param type Optional, type of the packaging size
 * @returns {PackagingSizeFinishedProduct} Default packaging size object
 */
export function getDefaultPackagingSize(type?: string): PackagingSizeFinishedProduct {
  return {
    _id: new BSON.ObjectId(),
    type: type ?? P_CARTON,
    packagingSize: 0,
    grossWeight: 0,
    dimension: { length: 0, height: 0, width: 0 },
  };
}
