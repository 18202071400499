import React, { PureComponent } from "react";
import { Nav, Tab } from "react-bootstrap";
import { TabDefinition } from "../../../common/CustomTypes";
import { SupplierOrderExtended } from "../../../../model/supplierOrder.types";
import { DataContextInternalType } from "../../../../context/dataContext";
import SupplierOrderWorkflow from "./tabPanels/SupplierOrderWorkflow";
import SupplierOrderCustomerOrders from "./tabPanels/SupplierOrderCustomerOrders";
import OrderDocuments from "../../common/OrderDocuments";
import OrderInvoices from "../../common/OrderInvoices";
import OrderHistory from "../../common/OrderHistory";
import SupplierOrderCalculation from "./tabPanels/SupplierOrderCalculation";
import OrderNotes from "../../common/OrderNotes";

interface SupplierOrderTabPanelProps {
  order: SupplierOrderExtended;
  context: DataContextInternalType;
}

interface SupplierOrderTabPanelState {
  currentTab: string;
}

class SupplierOrderTabPanel extends PureComponent<SupplierOrderTabPanelProps, SupplierOrderTabPanelState> {
  constructor(props: SupplierOrderTabPanelProps) {
    super(props);
    this.state = { currentTab: this.getTabDefinitions()[0].name };
  }

  componentDidUpdate(prevProps: Readonly<SupplierOrderTabPanelProps>) {
    if (prevProps.order._id.toString() !== this.props.order._id.toString()) {
      this.setState({ currentTab: this.getTabDefinitions()[0].name });
    }
  }

  getTabDefinitions = (): Array<TabDefinition> => {
    const { order, context } = this.props;
    return [
      {
        name: "workflow",
        tabContent: "Workflow",
        component: <SupplierOrderWorkflow order={order} context={context} />,
      },
      {
        name: "calculation",
        tabContent: "Calculation",
        component: <SupplierOrderCalculation order={order} context={context} />,
      },
      {
        name: "customerOrders",
        tabContent: "Customer Orders",
        component: <SupplierOrderCustomerOrders order={order} context={context} />,
      },
      {
        name: "documents",
        tabContent: "Documents",
        component: <OrderDocuments order={order} context={context} />,
      },
      {
        name: "invoices",
        tabContent: "Invoices",
        component: <OrderInvoices order={order} context={context} />,
      },
      {
        name: "notes",
        tabContent: "Notes",
        component: <OrderNotes order={order} context={context} />,
      },
      {
        name: "history",
        tabContent: "History",
        component: <OrderHistory order={order} context={context} />,
      },
    ];
  };

  /**
   * Handle tab changes
   * @param eventKey new tab
   */
  handleTab = (eventKey: string | null) => {
    if (eventKey && eventKey !== this.state.currentTab) this.setState({ currentTab: eventKey });
  };

  render() {
    const { currentTab } = this.state;
    const tabs = this.getTabDefinitions();

    return (
      <Tab.Container id="supplierOrderTabs" activeKey={currentTab} onSelect={this.handleTab}>
        <div className="flex-lg-row-fluid ms-lg-15">
          <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
            {tabs.map((t) => (
              <li key={t.name + "_link"} className="nav-item cursor-pointer">
                <Nav.Link
                  role="tab"
                  id={t.name}
                  eventKey={t.name}
                  active={currentTab === t.name}
                  className="text-active-white pb-4 "
                >
                  {t.tabContent}
                </Nav.Link>
              </li>
            ))}
          </ul>
          <Tab.Content>
            {tabs.map((t) => (
              <Tab.Pane id={t.name} key={t.name} eventKey={t.name}>
                {t.component}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </div>
      </Tab.Container>
    );
  }
}

export default SupplierOrderTabPanel;
