import _ from "lodash";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { downloadFile } from "../../utils/fileUtils";
import { ALLOWED_SEPARATORS, exportSeaportsAsCSV, parseCSV } from "../../utils/csvUtils";
import CustomSelect, { SelectOption } from "../common/CustomSelect";
import { DataContextInternal } from "../../context/dataContext";
import { formatDate } from "../../utils/baseUtils";
import { Seaport } from "../../model/seaport.types";
import { Action, insertMultipleDocuments, SEAPORT, transaction } from "../../services/dbService";
import { USD } from "../../utils/currencyUtils";
import userService from "../../services/userService";
import { REQUIRED_ROLES_CALCULATION_CONFIGURATION } from "../../utils/userUtils";

interface SeaportParsingToolProps {}

interface SeaportParsingToolState {
  content: Array<string>;
  separator: SelectOption;
  processing: boolean;
  seaportsNew: Array<Seaport>;
  seaportsUpdated: Array<Partial<Seaport>>;
  stage: "initial" | "generating" | "parsing" | "summary" | "success" | "failure";
}

class SeaportParsingTool extends PureComponent<SeaportParsingToolProps, SeaportParsingToolState> {
  static contextType = DataContextInternal;
  context!: React.ContextType<typeof DataContextInternal>;
  selectFileRef: React.RefObject<HTMLInputElement>;

  constructor(props: SeaportParsingToolProps) {
    super(props);
    this.selectFileRef = React.createRef();
    this.state = this.getDefaultState();
  }

  handleDownloadSeaportsCSV = () => {
    this.setState({ stage: "generating" });
    const csv = exportSeaportsAsCSV(this.context.seaport);
    downloadFile(csv, `Seaports_${formatDate(new Date())}.csv`, "text/plain");
    this.setState({ stage: "initial" });
  };

  handleChangeSeparator = (separator: SelectOption) => this.setState({ separator });

  handleParseSeaportCSV = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const { seaport } = this.context;
    const { separator } = this.state;
    this.setState({ processing: true });
    const file = await e.target.files[0].text();
    const content: Array<string> = file.split(/[\r\n]+/);
    this.setState({ content });
    const parsedCSV = parseCSV(content, separator.value);
    const seaportsNew = [];
    const seaportsUpdated = [];
    for (let i = 1; i < parsedCSV.length; i++) {
      try {
        const l = parsedCSV[i];
        const [locode, name, country, cost, cost20, cost40, cost40HC] = l;
        if (!locode.trim()) {
          console.error("LINE", i, "CONTAINED NO TITLE - SKIPPING");
          continue;
        }
        const existingSeaport = seaport.find((s) => s.locode.trim().toLowerCase() === locode.trim().toLowerCase());
        const seaportObject: Seaport = {
          _id: existingSeaport ? existingSeaport._id : new BSON.ObjectId(),
          locode,
          name,
          country,
          disabled: existingSeaport ? existingSeaport.disabled : false,
        };
        if (cost.trim() && !isNaN(+cost)) {
          seaportObject.cost = Number(cost);
          seaportObject.currency = existingSeaport ? existingSeaport.currency : USD;
        }
        if (cost20.trim() && cost40.trim() && cost40HC.trim() && !isNaN(+cost20 + +cost40 + +cost40HC)) {
          seaportObject.containerCost = {
            "20": +cost20,
            "40": +cost40,
            "40HC": +cost40HC,
          };
          seaportObject.currency = existingSeaport ? existingSeaport.currency : USD;
        }

        if (existingSeaport) {
          seaportsUpdated.push(seaportObject);
        } else {
          seaportsNew.push(seaportObject);
        }
      } catch (e) {
        console.error("ERROR IN LINE", i, ":", e);
      }
    }
    this.setState({
      seaportsNew,
      seaportsUpdated,
      processing: false,
      stage: "summary",
    });
  };

  handleClickCancel = () => {
    this.setState(this.getDefaultState());
  };

  handleClickAddToDatabase = async () => {
    const { seaportsNew, seaportsUpdated } = this.state;
    this.setState({ processing: true, stage: "parsing" });
    try {
      let res: boolean;
      if (seaportsNew.length > 0) {
        res = await insertMultipleDocuments(seaportsNew, SEAPORT);
        if (!res) {
          toast.error(`Error adding new seaports`);
          this.setState({ stage: "failure" });
          return;
        }
      }
      if (seaportsUpdated.length > 0) {
        const actions: Array<Action> = [];
        for (let i = 0; i < seaportsUpdated.length; i++) {
          const s = seaportsUpdated[i];
          const update = _.omit(s, ["locode", "_id"]);
          actions.push({ collection: SEAPORT, filter: { locode: s.locode }, update });
        }
        res = await transaction(actions);
        if (!res) {
          toast.error(`Error updating seaports`);
          this.setState({ stage: "failure" });
          return;
        }
      }
      this.setState({ stage: "success" });
      toast.success("Seaports updated successfully");
    } catch (e) {
      console.error("ERROR WRITING TO DB:", e);
    } finally {
      this.setState({ processing: false });
    }
  };

  getDefaultState = (): SeaportParsingToolState => {
    return {
      content: [],
      seaportsNew: [],
      seaportsUpdated: [],
      processing: false,
      separator: ALLOWED_SEPARATORS[0],
      stage: "initial",
    };
  };

  render() {
    const { processing, content, seaportsNew, seaportsUpdated, separator, stage } = this.state;
    if (!userService.getRoles().some((r) => REQUIRED_ROLES_CALCULATION_CONFIGURATION.includes(r)))
      return (
        <div className="content d-flex flex-column flex-column-fluid">
          <div className="post d-flex flex-column-fluid">
            <div className="container-xxl">
              <div className="card bg-white min-h-100">
                <div className="card-body">
                  <h3 className="card-title ">
                    <span className="card-label fw-bolder fs-3rem">Seaport Parsing Tool</span>
                  </h3>
                  <h5 className="mt-20 text-center">
                    <span className="text-muted">You do not have access to this section</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="card bg-white">
              <div className="card-body">
                <h3 className="card-title align-items-start flex-column mb-15">
                  <span className="card-label fw-bolder mb-3 fs-3rem">Seaport Parsing Tool</span>
                </h3>
                {["generating", "parsing"].includes(stage) ? (
                  <div className="row">
                    <div className="col text-center m-10">
                      <span className="h3 text-white">Processing Data - Please wait!</span>
                    </div>
                  </div>
                ) : stage === "initial" ? (
                  <>
                    <span className="text-white">Please provide a CSV in the following format:</span>
                    <div className="my-4">
                      <button
                        className="btn btn-outline btn-outline-light"
                        onClick={processing ? undefined : this.handleDownloadSeaportsCSV}
                        disabled={processing}
                      >
                        Export Seaports CSV
                      </button>
                    </div>
                    <div>
                      <span className="text-white h3">Important Information:</span>
                      <br />
                      <span className="text-white ">- Do NOT use the selected separator inside any fields</span>
                      <br />
                      <span className="text-white ">- Seaports are created as disabled</span>
                      <br />
                    </div>
                    <div className="border-bottom-dark-gray mt-4" />
                    <div className="my-4">
                      <span className="text-white">
                        Please provide a CSV which fits the specified format and upload it - you can still check your
                        input before any database operation is performed.
                      </span>
                      <div className="my-4 row">
                        <div className="col-4">
                          <button
                            className="btn btn-outline btn-outline-light"
                            type="button"
                            disabled={processing}
                            onClick={() => this.selectFileRef.current?.click()}
                          >
                            Upload Seaports CSV
                          </button>
                          <input
                            type="file"
                            ref={this.selectFileRef}
                            accept="text/csv"
                            style={{ display: "none" }}
                            onChange={processing ? undefined : this.handleParseSeaportCSV}
                          />
                        </div>
                        <div className="col-6 text-right align-self-center">
                          <span className="text-white">Separator:</span>
                        </div>
                        <div className="col-2">
                          <CustomSelect
                            options={ALLOWED_SEPARATORS}
                            onChange={this.handleChangeSeparator}
                            value={separator}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : stage === "summary" ? (
                  <div className="row">
                    <div className="col-12 m-10">
                      <span className="h3 text-white">
                        {content.length - 1} Lines were provided
                        <br />
                        {seaportsNew.length} new Seaports were parsable
                        <br />
                        {seaportsUpdated.length} updated Seaports were parsable
                        <br />
                      </span>
                    </div>
                    <div className="border-bottom-dark-gray" />
                    <div className="col-12 mt-4">
                      <button
                        className="btn btn-outline btn-outline-light float-right ml-2"
                        onClick={this.handleClickAddToDatabase}
                      >
                        Add to Database
                      </button>
                      <button
                        className="btn btn-outline btn-outline-light float-right"
                        onClick={this.handleClickCancel}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : stage === "success" ? (
                  <div className="row">
                    <div className="col-12 m-10">
                      <span className="h3 text-white">
                        {seaportsNew.length} new Seaports were added
                        <br />
                        {seaportsUpdated.length} Seaports were updated
                        <br />
                      </span>
                    </div>
                    <div className="border-bottom-dark-gray" />
                    <div className="col-12 mt-4">
                      <button
                        className="btn btn-outline btn-outline-light float-right"
                        onClick={this.handleClickCancel}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-12 m-10">
                      <span className="h3 text-white">Updating seaports failed. Please contact the IT-Department.</span>
                    </div>
                    <div className="border-bottom-dark-gray" />
                    <div className="col-12 mt-4">
                      <button
                        className="btn btn-outline btn-outline-light float-right"
                        onClick={this.handleClickCancel}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SeaportParsingTool;
