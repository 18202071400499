import React, { useContext } from "react";
import ControllingDashboard from "./ControllingDashboard";
import { DataContextInternal } from "../../../context/dataContext";

const ControllingDashboardWrapper: React.FunctionComponent = () => {
  const context = useContext(DataContextInternal);
  return <ControllingDashboard context={context} />;
};

export default ControllingDashboardWrapper;
