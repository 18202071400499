import React, { useContext } from "react";
import { DataContextInternal } from "../../../context/dataContext";
import LogisticsListing from "./LogisticsListing";

const LogisticsListingWrapper: React.FunctionComponent = () => {
  const context = useContext(DataContextInternal);
  return <LogisticsListing context={context} />;
};

export default LogisticsListingWrapper;
