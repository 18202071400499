import _ from "lodash";
import React from "react";

interface PaginationProps {
  itemsCount: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  currentPage: number;
  onPageSizeChange?: (size: number) => void;
  additionalSize?: {
    onAdditionalSizeChange: (size: number) => void;
    baseSize: number;
    size: number;
    sizeOptions: Array<number>;
    sizeDescription: string;
  };
  baseSize?: number;
  additionalPageSizeClasses?: string;
}

export interface PaginationState {
  currentPage: number;
  pageSize: number;
  itemsCount?: number;
}

const Pagination: React.FunctionComponent<PaginationProps> = ({
  itemsCount,
  pageSize,
  onPageChange,
  currentPage,
  onPageSizeChange,
  baseSize,
  additionalSize,
  additionalPageSizeClasses,
}) => {
  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
  });

  // Handle resizing of the window
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
      });
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const pagesCount = Math.ceil(itemsCount / pageSize);
  if (itemsCount === 0) return null;

  // Show less options on smaller screens
  const availWidth = dimensions.width;
  let rangePerSide = 6;
  if (availWidth < 400) {
    rangePerSide = 1;
  } else if (availWidth < 600) {
    rangePerSide = 2;
  } else if (availWidth < 800) {
    rangePerSide = 4;
  }
  let pages = _.range(1, pagesCount + 1);
  if (pagesCount > rangePerSide * 2 - 1) {
    const defaultMax = Math.max(1, currentPage - rangePerSide);
    const defaultMin = Math.min(currentPage + rangePerSide, pagesCount);
    const range = defaultMax - defaultMin + rangePerSide * 2;
    if (range > 0 && currentPage < rangePerSide * 2) {
      pages = _.range(defaultMax, Math.min(currentPage + rangePerSide + range, pagesCount + 1));
    } else if (range > 0) {
      pages = _.range(Math.max(1, currentPage - rangePerSide - range), defaultMin);
    } else {
      pages = _.range(defaultMax, defaultMin);
    }

    // Check that there are always at least 2 additional pages shown on first page
    if (currentPage === 1 && pages[pages.length - 1] === currentPage + 1 && pagesCount > currentPage + 1) {
      pages = pages.concat([currentPage + 2]);
      // Check that when not on last page the next page is always shown
    } else if (pages[pages.length - 1] === currentPage && currentPage !== pagesCount) {
      pages = pages.concat([currentPage + 1]);
      // Check that when on last page this page is always shown
    } else if (pages[pages.length - 1] === currentPage - 1 && currentPage === pagesCount) {
      pages = pages.concat([currentPage]);
    }
  }

  if (baseSize === undefined) {
    baseSize = 10;
  }

  return (
    <div className="d-flex flex-wrap justify-content-center">
      <div className="d-flex align-items-center">
        <div className="dataTables_paginate paging_simple_numbers">
          <ul className="pagination">
            <li className={"paginate_button page-item " + (currentPage === 1 ? "disabled" : "")}>
              <button onClick={() => onPageChange(1)} title="First" className={"page-link"}>
                {"<<"}
              </button>
            </li>
            <li className={"paginate_button page-item " + (currentPage === 1 ? "disabled" : "")}>
              <button
                onClick={() => onPageChange(currentPage === 1 ? 1 : currentPage - 1)}
                title="Previous"
                className={"page-link"}
              >
                {"<"}
              </button>
            </li>
            {pagesCount > 10 && !pages.find((e) => e === 2) && (
              <li className="paginate_button page-item ">
                <button className="page-link" onClick={() => onPageChange(2)}>
                  ...
                </button>
              </li>
            )}
            {pages.map((page) => (
              <li
                key={page.toString()}
                className={"paginate_button page-item " + (page === currentPage ? "active" : "")}
              >
                <button
                  key={page}
                  className={"page-link"}
                  data-page={page}
                  title={page.toString()}
                  onClick={() => onPageChange(page)}
                >
                  {page}
                </button>
              </li>
            ))}
            {pagesCount > 10 && !pages.find((e) => e === pagesCount) && (
              <>
                {!pages.find((e) => e === pagesCount - 1) && (
                  <li className="paginate_button page-item ">
                    <button className="page-link" onClick={() => onPageChange(pagesCount - 1)}>
                      ...
                    </button>
                  </li>
                )}
                <li className={"paginate_button page-item " + (pagesCount === currentPage ? "active" : "")}>
                  <button className={"page-link"} onClick={() => onPageChange(pagesCount)}>
                    {pagesCount}
                  </button>
                </li>
              </>
            )}
            <li className={"paginate_button page-item " + (currentPage === pagesCount ? "disabled" : "")}>
              <button
                onClick={() => onPageChange(currentPage === pagesCount ? pagesCount : currentPage + 1)}
                title="Next"
                className={"page-link"}
              >
                {">"}
              </button>
            </li>
            <li className={"paginate_button page-item " + (currentPage === pagesCount ? "disabled" : "")}>
              <button onClick={() => onPageChange(pagesCount)} title="Last" className={"page-link"}>
                {">>"}
              </button>
            </li>
          </ul>
        </div>
      </div>
      {onPageSizeChange && (
        <div className={"d-flex align-items-center ml-2" + (additionalPageSizeClasses ?? "")}>
          <div className="dataTables_length">
            <select
              className="form-select form-select-sm form-select-solid custom-form-control"
              title="Select page size"
              tabIndex={-98}
              value={pageSize}
              onChange={(value) => onPageSizeChange(Number(value.target.value))}
            >
              <option value={baseSize}>{baseSize}</option>
              <option value={baseSize * 2}>{baseSize * 2}</option>
              <option value={baseSize * 3}>{baseSize * 3}</option>
              <option value={baseSize * 5}>{baseSize * 5}</option>
              <option value={baseSize * 10}>{baseSize * 10}</option>
            </select>
          </div>
        </div>
      )}
      {additionalSize && (
        <div className={"d-flex align-items-center ml-2" + (additionalPageSizeClasses ?? "")}>
          <div className="dataTables_length ml-2" style={{ width: "auto" }}>
            <div className="row">
              <div className="col-6 my-auto">
                <label className="fs-5 fw-bold my-auto">{additionalSize.sizeDescription}: </label>
              </div>
              <div className="col-6">
                <select
                  id="additionalSize"
                  className="form-select form-select-sm form-select-solid custom-form-control"
                  title="Select page size"
                  tabIndex={-98}
                  value={additionalSize.size}
                  onChange={(value) => additionalSize.onAdditionalSizeChange(Number(value.target.value))}
                >
                  <option value={additionalSize.baseSize}>{additionalSize.baseSize}</option>
                  {additionalSize.sizeOptions
                    .sort((a, b) => b - a)
                    .map((sizeOption) => (
                      <option key={"additionalSize-" + sizeOption} value={sizeOption}>
                        {sizeOption}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export function paginate<T>(items: Array<T>, pageNumber: number, pageSize: number): Array<T> {
  const startIndex = (pageNumber - 1) * pageSize;
  return _(items).slice(startIndex).take(pageSize).value();
}

export default Pagination;
