import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { CloseButton, Modal } from "react-bootstrap";
import {
  CO_CANCELED,
  CO_ORDEREDBYCUSTOMER,
  CO_REQUESTEDBYCUSTOMER,
  CO_T_REQUESTAPPROVED,
  CO_T_REQUESTREJECTED,
  CustomerOrderExtended,
} from "../../../../model/customerOrder.types";
import { getOrderNumber } from "../../../../utils/orderUtils";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import { getCustomerOrderTimelineEntry, updateCustomerOrder } from "../../../../utils/customerOrderUtils";
import { Textarea } from "../../../common/Textarea";
import { Action, CUSTOMERORDER, transaction } from "../../../../services/dbService";
import { DataContextInternalType } from "../../../../context/dataContext";

interface LimitOrderHandlingModalProps {
  order: CustomerOrderExtended;
  context: DataContextInternalType;
}

interface LimitOrderHandlingModalState {
  show: boolean;
  type: "reject" | "approve";
  saving: boolean;
  reason: string;
}

class LimitOrderHandlingModal extends PureComponent<LimitOrderHandlingModalProps, LimitOrderHandlingModalState> {
  constructor(props: LimitOrderHandlingModalProps) {
    super(props);
    this.state = {
      show: false,
      saving: false,
      type: "approve",
      reason: "",
    };
  }

  handleShowReject = () => this.setState({ show: true, type: "reject", reason: "" });
  handleShowApprove = () => this.setState({ show: true, type: "approve", reason: "" });
  handleHide = () => this.setState({ show: false });
  handleChangeReason = (e: React.ChangeEvent<HTMLTextAreaElement>) => this.setState({ reason: e.target.value });

  handleConfirm = async () => {
    const { order } = this.props;
    const { type, reason } = this.state;
    this.setState({ saving: true });
    try {
      const timelineEntry = getCustomerOrderTimelineEntry(
        type === "approve" ? CO_T_REQUESTAPPROVED : CO_T_REQUESTREJECTED,
        type === "approve" ? undefined : { reason }
      );
      const targetState = type === "approve" ? CO_ORDEREDBYCUSTOMER : CO_CANCELED;
      let result;
      if (type === "approve") {
        const action: Action = {
          collection: CUSTOMERORDER,
          filter: { _id: order._id },
          update: { state: targetState },
          push: { timeline: timelineEntry },
        };
        result = await transaction([action]);
      } else {
        result = await updateCustomerOrder({ state: targetState }, order._id, timelineEntry);
      }
      if (result === true || (result && result.res && result.res.modifiedCount > 0)) {
        toast.success(`Order successfully ${type === "approve" ? "approved" : "rejected"}`);
      } else toast.error(`Order could not be ${type === "approve" ? "approved" : "rejected"}. Please try again later`);
    } finally {
      this.setState({ saving: false, show: false });
    }
  };

  render() {
    const { order } = this.props;
    const { show, type, saving, reason } = this.state;
    return (
      <>
        <button
          type="button"
          disabled={order.state !== CO_REQUESTEDBYCUSTOMER}
          onClick={this.handleShowReject}
          className={"btn btn-text-white " + (order.state !== CO_REQUESTEDBYCUSTOMER && "disabled")}
        >
          Reject
        </button>
        <button
          type="button"
          disabled={order.state !== CO_REQUESTEDBYCUSTOMER}
          onClick={this.handleShowApprove}
          className={"btn btn-outline btn-outline-white ml-2 " + (order.state !== CO_REQUESTEDBYCUSTOMER && "disabled")}
        >
          Approve
        </button>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">
                {type === "approve"
                  ? `Approve Request ${getOrderNumber(order, true)}`
                  : `Reject Request ${getOrderNumber(order)}`}
              </h1>
            </Modal.Title>
            <CloseButton variant={"white"} onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="text-white fs-6 mt-10 mb-5">
              <div>
                Are you sure you want to{" "}
                {type === "approve" ? (
                  <span className="text-success">approve</span>
                ) : (
                  <span className="text-danger">reject</span>
                )}{" "}
                the request for{" "}
                <em>
                  {order.amount}
                  {order.unit}
                </em>{" "}
                of <em>{order.commodity.title.en}</em>.{type === "reject" && <span> Please provide a reason:</span>}
              </div>
              {type === "reject" && (
                <div className="mt-10">
                  <Textarea
                    className={"form-control custom-form-control"}
                    rows={2}
                    name="reason"
                    value={reason}
                    onChange={this.handleChangeReason}
                  />
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className={"btn btn-text-white " + (saving && "disabled")}
              disabled={saving}
              onClick={saving ? undefined : this.handleHide}
            >
              Close
            </button>
            <ErrorOverlayButton
              errors={[]}
              className={"btn btn-outline btn-outline-light"}
              buttonText={"Confirm"}
              onClick={this.handleConfirm}
              saving={saving}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default LimitOrderHandlingModal;
