import React, { PureComponent } from "react";
import { StatisticsData } from "../../../model/statistics/controllingStatistics.types";
import { formatCurrency } from "../../../utils/baseUtils";
import { ControllingDashboardStatisticCardType } from "../../../utils/controllingDashboardUtils";
import { getDaysBetween } from "../../../utils/dateUtils";

interface StatisticCardProps {
  type: ControllingDashboardStatisticCardType;
  statisticsData: { date: Date; statisticsData: StatisticsData };
  statisticsDataOld: { date: Date; statisticsData: StatisticsData };
}

class StatisticCard extends PureComponent<StatisticCardProps> {
  /**
   * Resolves the title and other information for the statistics card based upon the type
   * @returns { { title: string, subtitle: string, movement: number} } The shown (sub-)title and the movement
   */
  resolveTitle = (): { title: string; subtitle: string; movement: number } => {
    const { type, statisticsData, statisticsDataOld } = this.props;
    const {
      ordersAmount,
      ordersTurnover,
      ordersTurnoverCurrency,
      requestsAmount,
      requestsTurnover,
      requestsTurnoverCurrency,
      contractsAmount,
      contractsTurnover,
      contractsTurnoverCurrency,
      customersAmount,
      customersActiveAmount,
      suppliersAmount,
      suppliersActiveAmount,
      commoditiesAmount,
      commoditiesActiveAmount,
    } = statisticsData.statisticsData;
    switch (type) {
      case ControllingDashboardStatisticCardType.CD_SC_ORDERS:
        return {
          title: `${ordersAmount} Orders`,
          subtitle: formatCurrency(ordersTurnover, ordersTurnoverCurrency),
          movement: ordersAmount - statisticsDataOld.statisticsData.ordersAmount,
        };
      case ControllingDashboardStatisticCardType.CD_SC_REQUESTS:
        return {
          title: `${requestsAmount} Requests`,
          subtitle: "~" + formatCurrency(requestsTurnover, requestsTurnoverCurrency),
          movement: requestsAmount - statisticsDataOld.statisticsData.requestsAmount,
        };
      case ControllingDashboardStatisticCardType.CD_SC_CONTRACTS:
        return {
          title: `${contractsAmount} Contracts`,
          subtitle: formatCurrency(contractsTurnover, contractsTurnoverCurrency),
          movement: contractsAmount - statisticsDataOld.statisticsData.contractsAmount,
        };
      case ControllingDashboardStatisticCardType.CD_SC_CUSTOMERS:
        return {
          title: `${customersAmount} Customers`,
          subtitle: `${customersActiveAmount} active`,
          movement: customersAmount - statisticsDataOld.statisticsData.customersAmount,
        };
      case ControllingDashboardStatisticCardType.CD_SC_SUPPLIERS:
        return {
          title: `${suppliersAmount} Suppliers`,
          subtitle: `${suppliersActiveAmount} active`,
          movement: suppliersAmount - statisticsDataOld.statisticsData.suppliersAmount,
        };
      case ControllingDashboardStatisticCardType.CD_SC_COMMODITIES:
        return {
          title: `${commoditiesAmount} Commodities`,
          subtitle: `${commoditiesActiveAmount} active`,
          movement: commoditiesAmount - statisticsDataOld.statisticsData.commoditiesAmount,
        };
    }
  };

  render() {
    const { statisticsData, statisticsDataOld } = this.props;
    const { title, subtitle, movement } = this.resolveTitle();
    return (
      <div className="card bg-white rounded h-100">
        <div className="my-3 d-flex justify-content-between flex-column px-0 pb-0">
          <div className="px-9">
            <div className="d-flex justify-content-between align-items-center">
              <h2>{title}</h2>
              <span className="fs-6 fw-bolder text-muted">{subtitle}</span>
            </div>
            <span
              className={
                "mr-1 fa fa-arrow-circle-" +
                (movement > 0 ? "up text-success" : movement < 0 ? "down text-danger" : "right text-muted")
              }
            />
            <span className="text-muted">
              {movement > 0 ? "+" + movement : movement} since{" "}
              {getDaysBetween(statisticsData.date, statisticsDataOld.date, true)}d
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default StatisticCard;
