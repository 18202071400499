import React, { PureComponent } from "react";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { Input } from "../common/Input";
import { Batch } from "../../model/batch.types";
import { getArticleProperty } from "../../utils/commodityUtils";
import { PropertyType } from "../../utils/propertyUtils";
import { Property } from "../../model/property.types";
import CustomSelect, { SelectOption } from "../common/CustomSelect";
import { Textarea } from "../common/Textarea";
import { Action, transaction, USERDATA } from "../../services/dbService";
import { uploadPublicFile } from "../../utils/fileUtils";
import { createCoAHTML } from "../../utils/pdf/certificateOfAnalysisGenerationUtils";
import userService from "../../services/userService";
import DocumentApprovalUser from "../common/internal/DocumentApprovalUser";
import { F_SIGNATURE, getUserName, INTERNAL, SelectedSpecificationUserData } from "../../utils/userUtils";
import { UserFile } from "../../model/userData.types";
import { DataContextInternalType } from "../../context/dataContext";
import DateInput from "../common/DateInput";
import { formatArticleUnit, isAnyFinishedProduct, isCommoditySnapshot } from "../../utils/productArticleUtils";
import { getDocFromCollection } from "../../utils/baseUtils";

interface CreateRawbidsCoAProps {
  batch: Batch;
  context: DataContextInternalType;
}

export interface Header {
  item: string;
  input: string;
}

export interface CoAInput {
  itemName: string;
  spec: string;
  result: string;
}

interface SelectablesCoA {
  itemName: string;
  spec: string;
}

interface CreateRawbidsCoAState {
  headerInfoOne: Array<Header>;
  headerInfoTwo: Array<{ item: string; input: Date }>;
  country: Header;
  physicalProperties?: Array<CoAInput>;
  meshSize?: CoAInput;
  purity?: CoAInput;
  heavyMetals?: Array<CoAInput>;
  microbiologicalAnalysis?: Array<CoAInput>;
  microbiologicalAnalysisSelectables?: Array<CoAInput>;
  allergyAndAdditional: Array<CoAInput>;
  carcinogenicSubstances?: Array<CoAInput>;
  storageConditions: string;
  furtherRemarks: Array<CoAInput>;
  furtherRemarksText: string;
  otherPossibleInputs: Array<SelectablesCoA>;
  generating: boolean;
  missingSpecification: boolean;
  originator?: SelectedSpecificationUserData;
}

class CreateRawbidsCoA extends PureComponent<CreateRawbidsCoAProps, CreateRawbidsCoAState> {
  constructor(props: CreateRawbidsCoAProps) {
    super(props);
    const today = new Date();
    const { batch } = props;
    const allergens = getArticleProperty(batch.commodity.properties, PropertyType.ALLERGEN, true) as Array<Property>;
    const category = getArticleProperty(batch.commodity.properties, PropertyType.CATEGORY, false) as Property;
    const analysisMethod = batch.commodity.properties.filter((p) => p.type === PropertyType.ANALYSISMETHOD)[0];
    const solvent = batch.commodity.properties.filter((p) => p.type === PropertyType.SOLVENT)[0];
    const odor = batch.commodity.properties.filter((p) => p.type === PropertyType.ODOR)[0];
    const carrier = batch.commodity.properties.filter((p) => p.type === PropertyType.CARRIER)[0];
    const sO = batch.supplierOrder ? getDocFromCollection(props.context.supplierOrder, batch.supplierOrder) : undefined;

    if (isAnyFinishedProduct(batch.commodity)) {
      this.state = {
        headerInfoOne: [
          { item: "Product Name: ", input: batch.commodity.title.en.toString() },
          { item: "Batch Number: ", input: "RB" + batch.identifier },
          { item: "LOT: ", input: batch.lot },
          {
            item: "Batch Quantity: ",
            input: sO ? sO.amount.toString() + " " + formatArticleUnit(sO.unit, sO.commodity) : "-",
          },
          {
            item: "Packing: ",
            input: batch.commodity.properties.find((p) => p.type === PropertyType.PACKAGING)?.name.en || "-",
          },
          { item: "Organic Number: ", input: batch.commodity.organic ? "" : "-" },
        ],
        headerInfoTwo: [
          {
            item: "Report Date: ",
            input: today,
          },
          {
            item: "Expiration Date: ",
            input: batch.expiry,
          },
          { item: "Manufacture Date: ", input: today },
          { item: "Analysis Date: ", input: today },
        ],
        country: { item: "Country of Origin: ", input: batch.commodity.country.name },
        allergyAndAdditional: [
          {
            itemName: "Allergen",
            spec: allergens.length > 0 ? "Yes" : "No",
            result:
              allergens
                .map((allergen) => allergen.name.en)
                .join(", ")
                .toString() || "No",
          },
          {
            itemName: "Vegetarian",
            spec: batch.commodity.vegetarian ? "Yes" : "No",
            result: batch.commodity.vegetarian
              ? "This product is suitable for vegetarian nutrition."
              : "This product is not suitable for vegetarian nutrition.",
          },
          {
            itemName: "Vegan",
            spec: batch.commodity.vegan ? "Yes" : "No",
            result: batch.commodity.vegan
              ? "This product is suitable for vegan nutrition."
              : "This product is not suitable for vegan nutrition.",
          },
          {
            itemName: "Halal",
            spec: batch.commodity.halal ? "Yes" : "No",
            result: batch.commodity.halal ? "This product is halal certified." : "This product is not halal certified.",
          },

          {
            itemName: "Kosher",
            spec: batch.commodity.kosher ? "Yes" : "No",
            result: batch.commodity.kosher
              ? "This product is kosher certified."
              : "This product is not kosher certified.",
          },
          {
            itemName: "Organic",
            spec: batch.commodity.organic ? "Yes" : "No",
            result: batch.commodity.organic ? "This product is organic." : "This product is not organic.",
          },
        ],
        storageConditions: "Air-tight original sealed container, cool and dark conditions.",
        furtherRemarks: [],
        otherPossibleInputs: [
          {
            itemName: "Active Substances",
            spec: batch.commodity.activeSubstances
              ? batch.commodity.activeSubstances
                  .map(
                    (substance) =>
                      substance.substance.name.en + " with " + substance.percentage.toFixed(2).toString() + " %"
                  )
                  .join(", ")
                  .toString() || ""
              : "-",
          },
          { itemName: "Food Grade", spec: batch.commodity.foodGrade ? "Yes" : "No" },
          { itemName: "Pharmaceutical Grade", spec: batch.commodity.pharmaceuticalGrade ? "Yes" : "No" },
          { itemName: "Feed Grade", spec: batch.commodity.feedGrade ? "Yes" : "No" },
          { itemName: "Cosmetic Grade", spec: batch.commodity.cosmeticGrade ? "Yes" : "No" },
          { itemName: "USP Grade", spec: batch.commodity.uspGrade ? "Yes" : "No" },
          { itemName: "Medicine Grade", spec: batch.commodity.medicineGrade ? "Yes" : "No" },
          { itemName: "Industrial Grade", spec: batch.commodity.industrialGrade ? "Yes" : "No" },
          {
            itemName: "Shelf Life",
            spec: batch.commodity.shelfLife ? batch.commodity.shelfLife.toString() : "-",
          },
          {
            itemName: "Category",
            spec: category ? category.name.en : "-",
          },
        ],
        furtherRemarksText: "",
        generating: false,
        missingSpecification: false,
        // placeholder to be exchanged in componentDidMount since context is not available yet
        originator: {
          _id: new BSON.ObjectId(),
          company: "",
          date: new Date(),
          emails: [],
          files: [],
          image: "",
          notifications: { language: "en", settings: [] },
          onboardingDone: true,
          phones: [],
          position: "",
          prename: "",
          roles: [""],
          signature: undefined,
          surname: "",
          type: "internal",
          userId: "",
        },
      };
    } else {
      this.state = {
        headerInfoOne: [
          { item: "Product Name: ", input: batch.commodity.title.en.toString() },
          { item: "Batch Number: ", input: "RB" + batch.identifier },
          { item: "LOT: ", input: batch.lot },
          {
            item: "Batch Quantity: ",
            input: sO ? sO.amount.toString() + " " + sO.unit : "-",
          },
          {
            item: "Packing: ",
            input: batch.commodity.properties.find((p) => p.type === PropertyType.PACKAGING)?.name.en || "-",
          },
          { item: "Organic Number: ", input: batch.commodity.organic ? "" : "-" },
        ],
        headerInfoTwo: [
          {
            item: "Report Date: ",
            input: today,
          },
          {
            item: "Expiration Date: ",
            input: batch.expiry,
          },
          { item: "Manufacture Date: ", input: today },
          { item: "Analysis Date: ", input: today },
        ],
        country: { item: "Country of Origin: ", input: batch.commodity.country.name },
        physicalProperties: isCommoditySnapshot(batch.commodity)
          ? [
              {
                itemName: "Appearance",
                spec: batch.commodity.appearance?.en || "-",
                result: "",
              },
            ]
          : [],
        meshSize: {
          itemName: "Mesh Size",
          spec:
            isCommoditySnapshot(batch.commodity) && batch.commodity.particleSize.en
              ? batch.commodity.particleSize.en
              : "-",
          result: "",
        },
        purity: {
          itemName: "Purity",
          spec:
            (isCommoditySnapshot(batch.commodity) && batch.commodity.purity.en
              ? batch.commodity.purity.en
              : isCommoditySnapshot(batch.commodity) && batch.commodity.ratioExtract
              ? batch.commodity.ratioExtract
              : "-") + (analysisMethod ? ` (${analysisMethod.name.en} method)` : ""),
          result: "",
        },
        // hard coded master specification values if none in specification (replaced in componentDidMount)
        heavyMetals: [
          { itemName: "Arsenic", spec: "≤ 1.0 ppm", result: " ppm" },
          { itemName: "Lead", spec: "≤ 3.0 ppm", result: " ppm" },
          { itemName: "Mercury", spec: "≤ 0.1 ppm", result: " ppm" },
          { itemName: "Cadmium", spec: "≤ 1.0 ppm", result: " ppm" },
        ],
        // hard coded master specification values if none in specification (replaced in componentDidMount)
        microbiologicalAnalysis: [
          { itemName: "Total plate count", spec: "1.000 cfu/g", result: " cfu/g" },
          { itemName: "Yeast and Moulds", spec: "≤ 100 cfu/g", result: " cfu/g" },
        ],
        microbiologicalAnalysisSelectables: [
          { itemName: "E. Coli", spec: "Negative", result: "" },
          { itemName: "Salmonella", spec: "Negative", result: "" },
        ],
        allergyAndAdditional: [
          {
            itemName: "Allergen",
            spec: allergens.length > 0 ? "Yes" : "No",
            result:
              allergens
                .map((allergen) => allergen.name.en)
                .join(", ")
                .toString() || "No",
          },
          {
            itemName: "Vegetarian",
            spec: batch.commodity.vegetarian ? "Yes" : "No",
            result: batch.commodity.vegetarian
              ? "This product is suitable for vegetarian nutrition."
              : "This product is not suitable for vegetarian nutrition.",
          },
          {
            itemName: "Vegan",
            spec: batch.commodity.vegan ? "Yes" : "No",
            result: batch.commodity.vegan
              ? "This product is suitable for vegan nutrition."
              : "This product is not suitable for vegan nutrition.",
          },
          {
            itemName: "Halal",
            spec: batch.commodity.halal ? "Yes" : "No",
            result: batch.commodity.halal ? "This product is halal certified." : "This product is not halal certified.",
          },

          {
            itemName: "Kosher",
            spec: batch.commodity.kosher ? "Yes" : "No",
            result: batch.commodity.kosher
              ? "This product is kosher certified."
              : "This product is not kosher certified.",
          },
          {
            itemName: "Organic",
            spec: batch.commodity.organic ? "Yes" : "No",
            result: batch.commodity.organic ? "This product is organic." : "This product is not organic.",
          },
        ],
        carcinogenicSubstances: isCommoditySnapshot(batch.commodity)
          ? [
              { itemName: "Aflatoxins", spec: batch.commodity.aflatoxins.en || "≤ 10 ppb", result: " ppb" },
              { itemName: "PAH4", spec: batch.commodity.pah4.en || "≤ 50 ppb", result: " ppb" },
              { itemName: "Benzopyrene", spec: batch.commodity.benzoypyrene.en || "≤ 10 ppb", result: " ppb" },
              { itemName: "Ethylene Oxide", spec: batch.commodity.maxAllowedWETO.en || "≤ 0.1 ppb", result: " ppb" },
            ]
          : [],
        storageConditions: "Air-tight original sealed container, cool and dark conditions.",
        furtherRemarks: [],
        furtherRemarksText: "",
        otherPossibleInputs: isCommoditySnapshot(batch.commodity)
          ? [
              {
                itemName: "Active Substances",
                spec: batch.commodity.activeSubstances
                  ? batch.commodity.activeSubstances
                      .map(
                        (substance) =>
                          substance.substance.name.en + " with " + substance.percentage.toFixed(2).toString() + " %"
                      )
                      .join(", ")
                      .toString() || ""
                  : "-",
              },
              {
                itemName: "Loss on Drying",
                spec: batch.commodity.lossOnDrying.amount
                  ? (batch.commodity.lossOnDrying.lessThan ? "≤ " : "") +
                    batch.commodity.lossOnDrying.amount.toString() +
                    "%"
                  : "-",
              },
              { itemName: "Hazardous", spec: batch.commodity.hazardMaterial ? "Yes" : "No" },
              { itemName: "Part", spec: batch.commodity.part.en ? batch.commodity.part.en : "-" },
              { itemName: "Food Grade", spec: batch.commodity.foodGrade ? "Yes" : "No" },
              { itemName: "Pharmaceutical Grade", spec: batch.commodity.pharmaceuticalGrade ? "Yes" : "No" },
              { itemName: "Feed Grade", spec: batch.commodity.feedGrade ? "Yes" : "No" },
              { itemName: "Cosmetic Grade", spec: batch.commodity.cosmeticGrade ? "Yes" : "No" },
              { itemName: "USP Grade", spec: batch.commodity.uspGrade ? "Yes" : "No" },
              { itemName: "Medicine Grade", spec: batch.commodity.medicineGrade ? "Yes" : "No" },
              { itemName: "Industrial Grade", spec: batch.commodity.industrialGrade ? "Yes" : "No" },
              { itemName: "Novel Food", spec: batch.commodity.novelFood ? "Yes" : "No" },
              {
                itemName: "CITES (Convention on International Trade in Endangered Species of Wild Fauna and Flora) ",
                spec: batch.commodity.cites ? "Yes" : "No",
              },
              { itemName: "ECHA (European Chemicals Agency", spec: batch.commodity.echa ? "Yes" : "No" },

              {
                itemName: "Shelf Life",
                spec: batch.commodity.shelfLife ? batch.commodity.shelfLife.toString() : "-",
              },
              {
                itemName: "Possible Cross Contamination",
                spec: batch.commodity.possibleCrossContamination.en
                  ? batch.commodity.possibleCrossContamination.en
                  : "-",
              },
              {
                itemName: "Solvent",
                spec: solvent ? solvent.name.en.toString() : "-",
              },
              {
                itemName: "Category",
                spec: category ? category.name.en : "-",
              },
              {
                itemName: "Analysis Method",
                spec: analysisMethod ? analysisMethod.name.en : "-",
              },
              {
                itemName: "Odor",
                spec: odor ? odor.name.en.toString() : "-",
              },
              {
                itemName: "Carrier",
                spec: carrier ? carrier.name.en.toString() : "-",
              },
              {
                itemName: "Sample Size",
                spec: batch.commodity.sampleSize
                  ? batch.commodity.sampleSize
                      .map((sample) => sample.amount.toString() + "kg for " + sample.price.toString() + " USD")
                      .join(", ")
                      .toString() || ""
                  : "-",
              },
            ]
          : [],
        generating: false,
        missingSpecification: false,
        // placeholder to be exchanged in componentDidMount since context is not available yet
        originator: {
          _id: new BSON.ObjectId(),
          company: "",
          date: new Date(),
          emails: [],
          files: [],
          image: "",
          notifications: { language: "en", settings: [] },
          onboardingDone: true,
          phones: [],
          position: "",
          prename: "",
          roles: [""],
          signature: undefined,
          surname: "",
          type: "internal",
          userId: "",
        },
      };
    }
  }

  componentDidMount() {
    const { commodity } = this.props.batch;
    let missingSpecification = false;
    if (isCommoditySnapshot(commodity)) {
      // get heavyMetals from specification
      if (commodity.maxAllowedHeavyMetals.en) {
        this.getHeavyMetalSpecs(commodity.maxAllowedHeavyMetals.en);
      }
      if (commodity.maxAllowedMicrobiology.en) {
        this.getMicroBioSpecs(commodity.maxAllowedMicrobiology.en);
      }
      const batch = this.props.batch;
      // check if relevant values for the CoA are missing in the specification of the commodity to warn user
      missingSpecification =
        isCommoditySnapshot(batch.commodity) &&
        !batch.commodity.appearance?.en &&
        !batch.commodity.particleSize.en &&
        !batch.commodity.ratioExtract &&
        !batch.commodity.purity.en &&
        !batch.commodity.properties.filter((p) => p.type === PropertyType.ANALYSISMETHOD)[0]?.name?.en;
    }
    const defaultOriginator = this.props.context.userData
      .filter((uD) => uD.type === INTERNAL && uD._id.toString() === userService.getUserData()._id.toString())
      .map((uD) => {
        return { value: uD._id.toString(), label: getUserName(uD), object: uD };
      });
    const userData = cloneDeep(defaultOriginator[0].object) as SelectedSpecificationUserData;
    userData.signature = userData?.files?.find((f) => f.type === F_SIGNATURE);
    userData.date = new Date();
    this.setState({ missingSpecification, originator: userData });
  }

  handleSelect = (e: SelectOption, group: string, index: number) => {
    if (group === "physical" && this.state.physicalProperties) {
      const physical = [...this.state.physicalProperties];
      physical[index].result = e.value;
      this.setState({ physicalProperties: physical });
    } else if (group === "purity" && this.state.purity) {
      const purity = { ...this.state.purity };
      purity.result = e.value;
      this.setState({ purity });
    } else if (group === "microbiologicalAnalysisSelectables" && this.state.microbiologicalAnalysisSelectables) {
      const microbiologicalAnalysisSelectables = [...this.state.microbiologicalAnalysisSelectables];
      microbiologicalAnalysisSelectables[index].result = e.value;
      this.setState({ microbiologicalAnalysisSelectables });
    }
  };

  handleChangeCoAInput = (e: React.ChangeEvent<HTMLInputElement>, group: string, index: number) => {
    if (group === "header1") {
      const header = [...this.state.headerInfoOne];
      header[index].input = e.currentTarget.value;
      this.setState({ headerInfoOne: header });
    } else if (group === "country") {
      const country = this.state.country;
      country.input = e.currentTarget.value;
      this.setState({ country });
    } else if (group === "meshsize" && this.state.meshSize) {
      const meshsize = this.state.meshSize;
      meshsize.result = e.currentTarget.value;
      this.setState({ meshSize: meshsize });
    } else if (group === "heavyMetal" && this.state.heavyMetals) {
      const heavyMetals = [...this.state.heavyMetals];
      heavyMetals[index].result = e.currentTarget.value;
      this.setState({ heavyMetals });
    } else if (group === "microbiological" && this.state.microbiologicalAnalysis) {
      const microbiologial = [...this.state.microbiologicalAnalysis];
      microbiologial[index].result = e.currentTarget.value;
      this.setState({ microbiologicalAnalysis: microbiologial });
    } else if (group === "carcinogenic" && this.state.carcinogenicSubstances) {
      const carcinogenic = [...this.state.carcinogenicSubstances];
      carcinogenic[index].result = e.currentTarget.value;
      this.setState({ carcinogenicSubstances: carcinogenic });
    }
  };

  handleCoADate = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const date = [...this.state.headerInfoTwo];
    date[index].input = new Date(e.target.value);
    this.setState({ headerInfoTwo: date });
  };

  handleChangeCoATextarea = (e: React.ChangeEvent<HTMLTextAreaElement>, group: string, index: number) => {
    if (group === "allergyAndAdditional") {
      const allergyAndAdditional = [...this.state.allergyAndAdditional];
      allergyAndAdditional[index].result = e.currentTarget.value;
      this.setState({ allergyAndAdditional });
    } else if (group === "storage") {
      const storage = e.currentTarget.value;
      this.setState({ storageConditions: storage });
    } else if (group === "furtherRemarksText") {
      const furtherRemarksText = e.currentTarget.value;
      this.setState({ furtherRemarksText });
    }
  };

  handleInputFurtherRemark = (e: React.ChangeEvent<HTMLInputElement>, indexRemark: number) => {
    const furtherRemarks = [...this.state.furtherRemarks];
    furtherRemarks[indexRemark].result = e.currentTarget.value;
    this.setState({ furtherRemarks });
  };

  handleAddFurtherRemark = (e: SelectOption) => {
    const furtherRemarks = [...this.state.furtherRemarks];
    const possibleOptions = [...this.state.otherPossibleInputs];
    furtherRemarks.push({ itemName: e.label, spec: e.value, result: "" });
    const newPossibleOptions = possibleOptions.filter((opt) => opt.itemName !== e.label);
    this.setState({ furtherRemarks, otherPossibleInputs: newPossibleOptions });
  };

  handleRemoveFurtherRemark = (item: CoAInput) => {
    let furtherRemarks = [...this.state.furtherRemarks];
    const possibleOptions = [...this.state.otherPossibleInputs];
    furtherRemarks = furtherRemarks.filter((opt) => opt.itemName !== item.itemName);
    possibleOptions.push({ itemName: item.itemName, spec: item.spec });
    this.setState({ furtherRemarks, otherPossibleInputs: possibleOptions });
  };

  handleSelectUser = (type: "approver" | "originator", e: SelectOption<SelectedSpecificationUserData>) => {
    if (type === "approver") return;
    const userData = cloneDeep(e.object) as SelectedSpecificationUserData;
    userData.signature = userData?.files?.find((f) => f.type === F_SIGNATURE);
    userData.date = new Date();
    this.setState({ originator: userData });
  };

  handleChangePosition = (type: "approver" | "originator", e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === "approver") return;
    const userData = cloneDeep(this.state.originator);
    if (!userData) return;
    userData.position = e.target.value;
    this.setState({ originator: userData });
  };

  handleChangeDate = (type: "approver" | "originator", e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === "approver") return;
    const userData = cloneDeep(this.state.originator);
    if (!userData) return;
    const targetDate = new Date(e.target.value);
    if (!targetDate) return;
    userData.date = targetDate;
    this.setState({ originator: userData });
  };

  handleUploadSignature = async (type: "approver" | "originator", e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === "approver") return;
    const userData = cloneDeep(this.state.originator);
    const file: File | null = e.target.files ? e.target.files[0] : null;
    if (!file || !userData) return;
    const alias = uploadPublicFile(file, `Signature-${userData.prename || ""}${userData.surname || ""}`);
    if (!alias) {
      toast.error("File upload failed.");
      return;
    }
    const userFile: UserFile = {
      _id: new BSON.ObjectId(),
      path: alias,
      type: F_SIGNATURE,
      date: new Date(),
      uploadedBy: userService.getUserId(),
      isPublic: true,
    };
    const action: Action = {
      collection: USERDATA,
      filter: { _id: userData._id },
      push: { files: userFile },
    };
    const result = await transaction([action]);
    if (result) {
      toast.success("Signature uploaded successfully");
      userData.signature = userFile;
      this.setState({ originator: userData });
    } else {
      toast.error("Error uploading signature image");
    }
  };

  handleRemoveSignature = async (type: "approver" | "originator") => {
    if (type === "approver") return;
    const userData = cloneDeep(this.state.originator);
    if (!userData) return;
    const action: Action = {
      collection: USERDATA,
      filter: { _id: userData._id },
      pull: { files: userData.signature },
    };
    const result = await transaction([action]);
    if (result) {
      toast.success("Signature deleted successfully");
      userData.signature = undefined;
      this.setState({ originator: userData });
    } else {
      toast.error("Error deleting signature");
    }
  };

  handleCreateCoA = () => {
    const { batch } = this.props;
    const {
      headerInfoOne,
      headerInfoTwo,
      country,
      physicalProperties,
      meshSize,
      purity,
      heavyMetals,
      microbiologicalAnalysis,
      microbiologicalAnalysisSelectables,
      allergyAndAdditional,
      carcinogenicSubstances,
      storageConditions,
      furtherRemarks,
      furtherRemarksText,
      originator,
    } = this.state;

    this.setState({ generating: true });
    if (originator) {
      try {
        return createCoAHTML(
          batch,
          headerInfoOne,
          headerInfoTwo,
          country,
          allergyAndAdditional,
          storageConditions,
          furtherRemarks,
          furtherRemarksText,
          originator,
          physicalProperties,
          meshSize,
          purity,
          heavyMetals,
          microbiologicalAnalysis,
          microbiologicalAnalysisSelectables,
          carcinogenicSubstances
        );
      } finally {
        this.setState({ generating: false });
      }
    } else {
      toast.error("Cannot create CoA, please select an originator!");
      this.setState({ generating: false });
    }
  };

  handleCheckCommodity = () => {
    window.open(
      `/${
        isCommoditySnapshot(this.props.batch.commodity) ? "commodity" : "finishedProduct"
      }/${this.props.batch.commodity._id.toString()}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  getHeavyMetalSpecs = (heavyMetalsString: string) => {
    const heavyMetals = heavyMetalsString.split(", ");
    const itemsHeavyMetals = heavyMetals.map((item) => item.split(":"));
    const heavyMetalSpecs = itemsHeavyMetals.map((item) => item[1].replace("max", "≤"));
    const heavyMetalsObjArray: Array<CoAInput> = [];
    for (let i = 0; itemsHeavyMetals.length > i; i++) {
      heavyMetalsObjArray.push({ itemName: itemsHeavyMetals[i][0], spec: heavyMetalSpecs[i], result: "" });
    }
    this.setState({ heavyMetals: heavyMetalsObjArray });
  };

  getMicroBioSpecs = (maxMicroBioString: string) => {
    const microBio = maxMicroBioString.split(". ");
    const itemsMicroBio = microBio.map((item) => item.split(":"));
    let microBioSpecs = itemsMicroBio.map((item) => item[1].replaceAll("max", "≤"));
    microBioSpecs = microBioSpecs.map((item) => item.replace("cfu,g", "cfu/g"));
    microBioSpecs = microBioSpecs.map((item) => item.replace(",g", ""));
    microBioSpecs = microBioSpecs.map((item) => item.replace(".", ""));
    // since first two items and second two need different input, they are separately in state
    const microBioOne: Array<CoAInput> = [];
    const microBioTwo: Array<CoAInput> = [];
    for (let microBio = 0; itemsMicroBio.length > microBio; microBio++) {
      if (microBioSpecs[microBio] !== "negative") {
        microBioOne.push({ itemName: itemsMicroBio[microBio][0], spec: microBioSpecs[microBio], result: "" });
      } else {
        microBioTwo.push({ itemName: itemsMicroBio[microBio][0], spec: microBioSpecs[microBio], result: "" });
      }
    }
    this.setState({ microbiologicalAnalysis: microBioOne, microbiologicalAnalysisSelectables: microBioTwo });
  };

  render() {
    const { context } = this.props;
    const {
      headerInfoOne,
      headerInfoTwo,
      country,
      physicalProperties,
      meshSize,
      purity,
      heavyMetals,
      microbiologicalAnalysis,
      microbiologicalAnalysisSelectables,
      allergyAndAdditional,
      carcinogenicSubstances,
      storageConditions,
      furtherRemarks,
      furtherRemarksText,
      otherPossibleInputs,
      originator,
      generating,
      missingSpecification,
    } = this.state;

    const otherOptions: Array<SelectOption> = [];
    for (const option of otherPossibleInputs) {
      otherOptions.push({ value: option.spec, label: option.itemName });
    }

    const internalUsers = context.userData
      .filter((uD) => uD.type === INTERNAL)
      .map((uD) => {
        return {
          value: uD._id.toString(),
          label: getUserName(uD),
          object: uD,
        } as SelectOption<SelectedSpecificationUserData>;
      });

    return (
      <div className="mt-10">
        <div className="container">
          {missingSpecification && (
            <div className="mb-10">
              <label className="text-white h3 fw-bold mb-2">Warnings</label>
              <div className="row alert bg-light mx-4">
                <div className="col fw-bold alert-text my-auto">
                  This batch is missing relevant specification values! Please keep commodities up to date before
                  offering them to customers!
                </div>
                <div className="col col-md-3">
                  <button
                    className="btn btn-sm btn-outline btn-outline-light float-right"
                    style={{ maxWidth: "fit-content" }}
                    type="button"
                    onClick={this.handleCheckCommodity}
                  >
                    Check Commodity
                  </button>
                </div>
              </div>
            </div>
          )}
          <h5 className="fs-5 fw-bold mb-5">General Information</h5>
          <div className="row mb-2">
            <div className="col fs-6 fw-bold mb-1">
              <table className="table fs-6 fw-bold gy-1">
                <tbody>
                  {headerInfoOne.map((item, index) => (
                    <tr key={item.item}>
                      <td style={{ verticalAlign: "middle" }}>{item.item}</td>
                      <td style={{ width: "70%" }}>
                        <Input
                          className="fs-6 form-control custom-form-control ml-1 w-100"
                          onChange={(e) => this.handleChangeCoAInput(e, "header1", index)}
                          value={item.input}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="col fs-6 fw-bold mb-1">
              <table className="table fs-6 fw-bold gy-1">
                <tbody>
                  {headerInfoTwo.map((item, index) => (
                    <tr key={item.item}>
                      <td style={{ verticalAlign: "middle" }}>{item.item}</td>
                      <td style={{ width: "70%" }}>
                        <DateInput
                          classes="fs-6 form-control custom-form-control ml-1 w-100"
                          value={item.input}
                          onBlur={(e) => this.handleCoADate(e, index)}
                          name="date"
                        />
                      </td>
                    </tr>
                  ))}
                  <tr key={country.item}>
                    <td style={{ verticalAlign: "middle" }}>{country.item}</td>
                    <td style={{ width: "70%" }}>
                      <Input
                        className="fs-6 form-control custom-form-control ml-1 w-100"
                        onChange={(e) => this.handleChangeCoAInput(e, "country", 0)}
                        value={country.input}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {physicalProperties && meshSize && (
            <>
              <h5 className="mt-10 mb-5">Physical Properties</h5>
              <table className="table fs-6 fw-bold gy-1">
                <tbody>
                  <tr>
                    <td>Item</td>
                    <td>Specification</td>
                    <td>Result</td>
                  </tr>
                  {physicalProperties.map((item, index) => (
                    <tr key={item.itemName}>
                      <td style={{ width: "25%", verticalAlign: "middle" }}>{item.itemName}</td>
                      <td style={{ width: "35%", verticalAlign: "middle" }}>{item.spec}</td>
                      <td style={{ width: "40%" }}>
                        <CustomSelect
                          matchFormControl={true}
                          options={[
                            { value: "Conform", label: "Conform" },
                            { value: "Non-conform", label: "Non-Conform" },
                          ]}
                          onChange={(e: SelectOption) => this.handleSelect(e, "physical", index)}
                        />
                      </td>
                    </tr>
                  ))}
                  <tr key={meshSize.itemName}>
                    <td style={{ width: "25%", verticalAlign: "middle" }}>{meshSize.itemName}</td>
                    <td style={{ width: "35%", verticalAlign: "middle" }}>{meshSize.spec}</td>
                    <td style={{ width: "40%" }}>
                      <Input
                        className="col fs-6 form-control custom-form-control"
                        value={meshSize.result}
                        onChange={(e) => this.handleChangeCoAInput(e, "meshsize", 0)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          {purity && (
            <>
              <h5 className="mt-10">General Analysis</h5>
              <table className="table fs-6 fw-bold gy-1">
                <tbody>
                  <tr>
                    <td>Item</td>
                    <td>Specification</td>
                    <td>Result</td>
                  </tr>

                  <tr>
                    <td style={{ width: "25%", verticalAlign: "middle" }}>{purity.itemName}</td>
                    <td style={{ width: "35%", verticalAlign: "middle" }}>{purity.spec}</td>
                    <td>
                      <CustomSelect
                        matchFormControl={true}
                        options={[
                          { value: "Conform", label: "Conform" },
                          { value: "Non-conform", label: "Non-Conform" },
                        ]}
                        onChange={(e: SelectOption) => this.handleSelect(e, "purity", 0)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          {heavyMetals && (
            <>
              <h5 className="mt-10">Heavy Metals Analysis</h5>
              <table className="table fs-6 fw-bold gy-1">
                <tbody>
                  <tr>
                    <td>Item</td>
                    <td>Specification</td>
                    <td>Result</td>
                  </tr>
                  {heavyMetals.map((item, index) => (
                    <tr key={item.itemName}>
                      <td style={{ width: "25%", verticalAlign: "middle" }}>{item.itemName}</td>
                      <td style={{ width: "35%", verticalAlign: "middle" }}>{item.spec}</td>
                      <td style={{ width: "40%" }}>
                        <Input
                          className="col fs-6 form-control custom-form-control"
                          value={item.result}
                          onChange={(e) => this.handleChangeCoAInput(e, "heavyMetal", index)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
          {microbiologicalAnalysis && microbiologicalAnalysisSelectables && (
            <>
              <h5 className="mt-10">Microbiological Analysis</h5>
              <table className="table fs-6 fw-bold gy-1">
                <tbody>
                  <tr>
                    <td>Item</td>
                    <td>Specification</td>
                    <td>Result</td>
                  </tr>
                  {microbiologicalAnalysis.map((item, index) => (
                    <tr key={item.itemName}>
                      <td style={{ width: "25%", verticalAlign: "middle" }}>{item.itemName}</td>
                      <td style={{ width: "35%", verticalAlign: "middle" }}>{item.spec}</td>
                      <td style={{ width: "40%" }}>
                        <Input
                          className="col fs-6 form-control custom-form-control"
                          value={item.result}
                          onChange={(e) => this.handleChangeCoAInput(e, "microbiological", index)}
                        />
                      </td>
                    </tr>
                  ))}
                  {microbiologicalAnalysisSelectables.map((item, index) => (
                    <tr key={item.itemName}>
                      <td style={{ width: "25%", verticalAlign: "middle" }}>{item.itemName}</td>
                      <td style={{ width: "35%", verticalAlign: "middle" }}>{item.spec}</td>
                      <td style={{ width: "40%" }}>
                        <CustomSelect
                          matchFormControl={true}
                          options={[
                            { value: "Conform", label: "Conform" },
                            { value: "Non-conform", label: "Non-Conform" },
                          ]}
                          onChange={(e: SelectOption) =>
                            this.handleSelect(e, "microbiologicalAnalysisSelectables", index)
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
          <h5 className="mt-10">Allergy & Additional Certifications</h5>
          <table className="table fs-6 fw-bold gy-1">
            <tbody>
              <tr>
                <td>Item</td>
                <td>Yes / No</td>
                <td>Specified</td>
              </tr>
              {allergyAndAdditional.map((item, index) => (
                <tr key={item.itemName}>
                  <td style={{ width: "25%", verticalAlign: "middle" }}>{item.itemName}</td>
                  <td style={{ width: "35%", verticalAlign: "middle" }}>{item.spec}</td>
                  <td style={{ width: "40%" }}>
                    <Textarea
                      rows={1}
                      value={item.result}
                      className="col fs-6 form-control custom-form-control"
                      onChange={(e) => this.handleChangeCoATextarea(e, "allergyAndAdditional", index)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {carcinogenicSubstances && (
            <>
              <h5 className="mt-10">Carcinogenic Substances</h5>
              <table className="table fs-6 fw-bold gy-1">
                <tbody>
                  <tr>
                    <th>Item</th>
                    <th>Specification</th>
                    <th>Result</th>
                  </tr>
                  {carcinogenicSubstances.map((item, index) => (
                    <tr key={item.itemName}>
                      <td style={{ width: "25%", verticalAlign: "middle" }}>{item.itemName}</td>
                      <td style={{ width: "35%", verticalAlign: "middle" }}>{item.spec}</td>
                      <td style={{ width: "40%" }}>
                        <Input
                          className="col fs-6 form-control custom-form-control"
                          value={item.result}
                          onChange={(e) => this.handleChangeCoAInput(e, "carcinogenic", index)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
          <h5 className="mt-10">Storage Conditions & Packing</h5>
          <table className="table fs-6 fw-bold gy-1">
            <tbody>
              <tr>
                <td>
                  <Textarea
                    className="col fs-6 form-control custom-form-control"
                    value={storageConditions}
                    onChange={(e) => this.handleChangeCoATextarea(e, "storage", 0)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <h5 className="mt-10">Further Remarks</h5>
          {furtherRemarks.length > 0 && (
            <table className="table fs-6 fw-bold gy-1">
              <tbody>
                <tr>
                  <td>Item</td>
                  <td>Specification</td>
                  <td>Result</td>
                </tr>
                {furtherRemarks.map((item, index) => (
                  <tr key={item.itemName}>
                    <td style={{ width: "25%", verticalAlign: "middle" }}>{item.itemName}</td>
                    <td style={{ width: "35%", verticalAlign: "middle" }}>{item.spec}</td>
                    <td style={{ width: "35%" }}>
                      <Input
                        className="col fs-6 form-control custom-form-control"
                        value={item.result}
                        onChange={(e) => this.handleInputFurtherRemark(e, index)}
                      />
                    </td>
                    <td style={{ width: "5%", padding: "0px", verticalAlign: "middle" }}>
                      <button className="btn btn-text btn-sm" onClick={() => this.handleRemoveFurtherRemark(item)}>
                        <i className="fa fa-times text-danger pr-0" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <table className="table fs-6 fw-bold gy-1">
            <tbody>
              <tr>
                <td>
                  <CustomSelect
                    placeholder="Select further information..."
                    matchFormControl={true}
                    options={otherOptions}
                    onChange={this.handleAddFurtherRemark}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <h5 className="mt-10">Additional Information</h5>
          <table className="table fs-6 fw-bold gy-1">
            <tbody>
              <tr>
                <td>
                  <Textarea
                    className="col fs-6 form-control custom-form-control"
                    placeholder="Any information still missing"
                    value={furtherRemarksText}
                    onChange={(e) => this.handleChangeCoATextarea(e, "furtherRemarksText", 0)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <h5 className="mt-10">Originator</h5>
          <table className="table fs-6 fw-bold gy-1">
            <tbody>
              <tr>
                <td>
                  <DocumentApprovalUser
                    type="originator"
                    disabled={generating}
                    userData={originator}
                    internalUsers={internalUsers}
                    onSelectUser={this.handleSelectUser}
                    onChangePosition={this.handleChangePosition}
                    onChangeDate={this.handleChangeDate}
                    onUploadSignature={this.handleUploadSignature}
                    onRemoveSignature={this.handleRemoveSignature}
                    wrapperClasses="px-0 mt-5"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default CreateRawbidsCoA;
