import React, { PureComponent } from "react";
import { DataContextSupplierType } from "../../../context/dataContext";
import { getDocFromCollection } from "../../../utils/baseUtils";
import userService from "../../../services/userService";
import PackagingDimensionList from "./PackagingDimensionList";

import { extendSupplierSupplier } from "../../../utils/dataTransformationUtils";

interface PackagingDimensionListWrapperProps {
  context: DataContextSupplierType;
}

class PackagingDimensionListWrapper extends PureComponent<PackagingDimensionListWrapperProps> {
  render() {
    const { context } = this.props;
    const supplier = getDocFromCollection(context.supplier, userService.getCompany() || "");
    if (!supplier) return <></>;
    return <PackagingDimensionList context={context} supplier={extendSupplierSupplier(supplier, context)} />;
  }
}

export default PackagingDimensionListWrapper;
