import _ from "lodash";
import React, { PureComponent } from "react";
import { ActiveSubstance } from "../../../model/activeSubstance.types";
import { Input } from "../../common/Input";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import { InternalArticleExtended } from "../../../utils/productArticleUtils";

interface CommodityActiveSubstanceEditListingProps {
  commodity: InternalArticleExtended;
  activeSubstances: Array<ActiveSubstance>;
  onAddActiveSubstance: (activeSubstance: ActiveSubstance, percentage: number) => void;
  onEditActiveSubstancePercentage: (id: string, percentage: number) => void;
  onEditActiveSubstance: (idx: number, activeSubstance: ActiveSubstance) => void;
  onRemoveActiveSubstance: (id: string) => void;
}

interface CommodityActiveSubstanceEditListingState {
  activeSubstances: Array<{ substance: ActiveSubstance; percentage: number }>;
}

class CommodityActiveSubstanceEditListing extends PureComponent<
  CommodityActiveSubstanceEditListingProps,
  CommodityActiveSubstanceEditListingState
> {
  constructor(props: CommodityActiveSubstanceEditListingProps) {
    super(props);
    this.state = { activeSubstances: _.cloneDeep(props.commodity.activeSubstances) };
  }

  getValidActiveSubstances = () => {
    const { activeSubstances, commodity } = this.props;
    const cAS = commodity.activeSubstances.map((aS) => aS.substance._id.toString());
    const validAS = activeSubstances.filter((aS) => !aS.disabled && !cAS.includes(aS._id.toString()));
    return validAS.map((vAS) => {
      return { value: vAS._id.toString(), label: vAS.name.en, object: vAS };
    });
  };

  render() {
    const {
      commodity,
      onAddActiveSubstance,
      onEditActiveSubstance,
      onEditActiveSubstancePercentage,
      onRemoveActiveSubstance,
    } = this.props;

    const validActiveSubstances = this.getValidActiveSubstances();

    return (
      <table className="table table-no-padding text-white fw-bolder dataTable gy-1">
        <thead>
          <tr>
            <th className="w-25 border-0">Name</th>
            <th className="w-25 border-0">Percentage</th>
            <th className="w-25 border-0">NRV</th>
            <th className="text-right w-25 border-0">Actions</th>
          </tr>
        </thead>
        <tbody>
          {commodity.activeSubstances.map((aS, idx) => (
            <tr key={aS.substance._id.toString()}>
              <td className="text-white min-w-125px align-middle " style={{ position: "relative", zIndex: 90 }}>
                <CustomSelect
                  options={
                    [{ value: aS.substance._id.toString(), label: aS.substance.name.en, object: aS.substance }].concat(
                      validActiveSubstances
                    ) as Array<SelectOption<ActiveSubstance>>
                  }
                  value={
                    {
                      value: aS.substance._id.toString(),
                      label: aS.substance.name.en,
                      object: aS.substance,
                    } as SelectOption<ActiveSubstance>
                  }
                  onChange={(e: SelectOption<ActiveSubstance>) => onEditActiveSubstance(idx, e.object!)}
                  matchFormControl={true}
                />
              </td>
              <td className="text-muted align-middle">
                <div className="input-group">
                  <Input
                    className="form-control custom-form-control"
                    type="number"
                    name="amount"
                    value={aS.percentage}
                    onChange={(e) => onEditActiveSubstancePercentage(aS.substance._id.toString(), +e.target.value)}
                  />
                  <div className="input-group-append rounded-end bg-custom-light-gray">
                    <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                      %
                    </div>
                  </div>
                </div>
              </td>
              <td className="text-muted align-middle">{aS.substance.nrv} µg / day</td>
              <td className="text-right">
                <button
                  className="btn btn-sm float-right pr-0"
                  onClick={() => onRemoveActiveSubstance(aS.substance._id.toString())}
                >
                  <i className="fa fa-trash text-danger p-0" />
                </button>
              </td>
            </tr>
          ))}
          {validActiveSubstances.length > 0 && (
            <tr>
              <td colSpan={3} />
              <td className="text-right">
                <button
                  className="btn btn-sm float-right pr-0"
                  onClick={() => onAddActiveSubstance(validActiveSubstances[0].object, 0)}
                >
                  <i className="fa fa-plus text-success p-0" />
                </button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }
}

export default CommodityActiveSubstanceEditListing;
