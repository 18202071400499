import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { PDFDocument } from "pdf-lib";
import { toast } from "react-toastify";
import { uploadFile } from "../../../../utils/fileUtils";
import { UploadedFileExtended } from "../../../../model/commodity.types";
import {
  D_MASTERSPECIFICATION,
  getCommodityTimelineEntry,
  T_MASTERSPECCREATED,
} from "../../../../utils/commodityUtils";
import { Action, COMMODITY, transaction } from "../../../../services/dbService";
import userService from "../../../../services/userService";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import { MasterSpecificationType } from "../../common/CustomTypes";

interface UploadSignedSpecificationModalProps {
  specification: UploadedFileExtended | undefined;
  article: MasterSpecificationType;
}

interface UploadSignedSpecificationModalState {
  show: boolean;
  saving: boolean;
  file?: File;
  pdfDoc?: PDFDocument;
}

class UploadSignedSpecificationModal extends PureComponent<
  UploadSignedSpecificationModalProps,
  UploadSignedSpecificationModalState
> {
  fileSelectionRef: React.RefObject<HTMLInputElement>;
  constructor(props: UploadSignedSpecificationModalProps) {
    super(props);
    this.fileSelectionRef = React.createRef();
    this.state = {
      show: false,
      saving: false,
    };
  }

  handleUploadSpecification = async () => {
    const { article, specification } = this.props;
    const { file } = this.state;
    if (!file || !specification) return;
    this.setState({ saving: true });
    const alias = uploadFile(file, specification.name);
    if (!alias) {
      toast.error("File upload failed. Please try again later");
      return;
    }
    try {
      const timelineEntry = getCommodityTimelineEntry(T_MASTERSPECCREATED, D_MASTERSPECIFICATION, alias);

      const objectId = article.type === COMMODITY ? article.commodity._id : article.finishedProduct._id;

      const action: Action = {
        collection: article.type,
        filter: { _id: objectId },
        update: {
          "documents.$[filter].path": alias,
          "documents.$[filter].signedBy": userService.getUserId(),
          "documents.$[filter].signatureDate": new Date(),
        }, // replace existing master spec and set signature values
        arrayFilters: [{ "filter._id": specification._id }],
        push: { timeline: timelineEntry },
      };

      const res = await transaction([action]);
      if (res) {
        toast.success("Master specification successfully uploaded");
        this.setState({ show: false });
      } else toast.error("Error uploading master specification.");
    } finally {
      this.setState({ saving: false });
    }
  };
  handleSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    const pdfDoc = file ? await PDFDocument.load(await file.arrayBuffer(), { ignoreEncryption: true }) : undefined;
    this.setState({ file: file || undefined, pdfDoc });
  };
  handleShow = () => this.setState({ show: true });
  handleHide = () => !this.state.saving && this.setState({ show: false });

  validateData = () => {
    const { file, pdfDoc } = this.state;
    const errors: Array<string> = [];
    if (!file) errors.push("No file selected. Please select a signed PDF");
    if (!pdfDoc) errors.push("Please select a valid PDF file");
    if (pdfDoc && !pdfDoc.isEncrypted) errors.push("PDF is not signed");
    return errors;
  };

  render() {
    const { specification } = this.props;
    const { saving, show, file, pdfDoc } = this.state;
    const errors = this.validateData();

    return (
      <>
        <ErrorOverlayButton
          errors={!specification ? ["No master specification available yet"] : []}
          className="fs-7 btn btn-text text-danger px-0"
          buttonText="Unsigned"
          onClick={this.handleShow}
        />
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Upload Signed Specification</h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="mt-5">
              <div className="col-md-12 mt-5">
                <label className="required fs-5 fw-bold mb-2">File</label>
                <input
                  type="file"
                  ref={this.fileSelectionRef}
                  accept=".pdf"
                  className="form-control custom-form-control"
                  style={{ padding: "0.375rem 0.75rem" }}
                  name="file"
                  onChange={this.handleSelectFile}
                />
              </div>
              <div className="col-md-12 mt-5">
                <label className="fs-5 fw-bold mb-2">Status</label>
                <div
                  className={
                    file && pdfDoc
                      ? pdfDoc.isEncrypted
                        ? "text-success fw-bold"
                        : "text-danger fw-bold"
                      : "text-muted"
                  }
                >
                  {file && pdfDoc ? (pdfDoc.isEncrypted ? "Signed" : "Unsigned") : "No file selected"}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-outline btn-outline-light" onClick={this.handleHide}>
              Close
            </button>
            <ErrorOverlayButton
              errors={errors}
              saving={saving}
              className="btn btn-sm btn-outline btn-outline-light"
              buttonText="Upload Specification"
              onClick={this.handleUploadSpecification}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default UploadSignedSpecificationModal;
