import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { B_BLOCKED, B_INCOMING, B_RELEASED, Batch } from "../../../model/batch.types";
import UpdateBatchStateModal from "../../commodities/internal/modals/UpdateBatchStateModal";

interface OrderBatchStateDropdownProps {
  batch: Batch;
  dropdownClasses?: string;
}

const OrderBatchStateDropdown: React.FunctionComponent<OrderBatchStateDropdownProps> = ({ batch, dropdownClasses }) => {
  const [show, setShow] = useState<false | "release" | "block">(false);
  return (
    <>
      <UpdateBatchStateModal batchToHandle={show ? { batch, type: show } : undefined} onClose={() => setShow(false)} />
      <Dropdown className={dropdownClasses || ""} onClick={(e) => e.stopPropagation()}>
        <Dropdown.Toggle
          disabled={batch.disabled}
          className={
            "btn-sm py-1 px-2 text-uppercase show-arrow " +
            (batch.disabled
              ? "btn-light"
              : batch.state === B_BLOCKED
              ? "btn-danger"
              : batch.state === B_INCOMING
              ? "btn-warning"
              : "btn-success")
          }
        >
          {batch.disabled ? "disabled" : batch.state}
        </Dropdown.Toggle>
        <Dropdown.Menu className="custom-dropdown">
          {batch.state !== B_RELEASED && !batch.disabled && (
            <Dropdown.Item className="text-white" onClick={() => setShow("release")}>
              Release
            </Dropdown.Item>
          )}
          {batch.state !== B_BLOCKED && !batch.disabled && (
            <Dropdown.Item className="text-white" onClick={() => setShow("block")}>
              Block
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default OrderBatchStateDropdown;
