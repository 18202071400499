import React, { useCallback } from "react";
import { toast } from "react-toastify";
import { formatDate, formatUnit, truncateString } from "../../../utils/baseUtils";
import Tooltip from "../../common/Tooltip";
import {
  CustomerRequest,
  CustomerRequestState,
  CustomerRequestTimelineTypes,
  CustomerRequestType,
} from "../../../model/customerRequest.types";
import {
  CR_STATEDESCRIPTION,
  getCustomerRequestTimelineEntry,
  getRequestNumber,
  updateCustomerRequest,
} from "../../../utils/customerRequestUtils";
import SimpleConfirmationModal from "../../common/SimpleConfirmationModal";
import { formatArticleUnit } from "../../../utils/productArticleUtils";
import { DataContextAnonymousType, DataContextCustomerType, isAnonymousContext } from "../../../context/dataContext";
import CreateCustomerAccountModal from "../../userData/anonymous/CreateCustomerAccountModal";

interface CustomerRequestRowProps {
  request: CustomerRequest;
  context: DataContextCustomerType | DataContextAnonymousType;
}

const CustomerRequestRow: React.FunctionComponent<CustomerRequestRowProps> = ({ request, context }) => {
  const type = request.type === CustomerRequestType.CONTRACTREQUEST ? "Contract" : "Order";

  const inactive = [CustomerRequestState.CANCELED, CustomerRequestState.REJECTED, CustomerRequestState.CLOSED].includes(
    request.state
  );

  const handleCancelRequest = useCallback(async () => {
    const update = {
      state: CustomerRequestState.CANCELED,
    };
    const timelineEntry = getCustomerRequestTimelineEntry(CustomerRequestTimelineTypes.CANCELED);
    try {
      const result = await updateCustomerRequest(update, request._id, timelineEntry);
      if (result && result.res && result.res.modifiedCount > 0) {
        toast.success("Request canceled successfully");
      } else toast.error("Request could not be canceled");
    } catch (e) {
      toast.error("Error canceling request: " + e);
      console.error(e);
    }
  }, [request]);

  const handleClickCancelAnonymous = useCallback(() => {
    (context.refMap["CreateCustomerAccountModal"] as CreateCustomerAccountModal).handleShow();
  }, []);

  const isAV = isAnonymousContext(context);

  const message = request.message + (request.customerReference ? ` - Ref: ${request.customerReference}` : "");
  return (
    <tr className="border-bottom-dark-gray" style={{ borderWidth: "20px", opacity: inactive ? 0.5 : 1 }}>
      <td className="align-middle">
        <span className="text-white fs-5 d-inline-block">
          <Tooltip tooltipText={"Created: " + formatDate(request.requestedOn)} delay={{ show: 500, hide: 0 }}>
            <div className="text-white fs-5 mb-1 h5">{getRequestNumber(request)}</div>
          </Tooltip>
        </span>
      </td>
      <td className="align-middle">
        <div className="text-white fs-5 mb-1 h5">{type}</div>
      </td>
      <td className="align-middle">
        <div className="text-white fs-5 mb-1 h5">
          {request.amount} {formatArticleUnit(request.commodity.unit, request.commodity)}
        </div>
      </td>
      <td className="align-middle">
        <span className="text-white fs-5 d-inline-block">{truncateString(request.commodity.title.en, 40)}</span>
        {request.commodity.organic && <i title="organic" className="fas fa-leaf text-success d-inline-block ml-2" />}
        <span className="text-muted d-block" style={{ fontSize: "0.85rem" }}>
          {truncateString(request.commodity.subtitle.en, 50)}
        </span>
      </td>
      <td className="text-white align-middle" style={{ lineHeight: 1.2 }}>
        {message.length > 120 ? (
          <Tooltip tooltipText={message}>
            <small className="text-muted ">{truncateString(message, 120)}</small>
          </Tooltip>
        ) : (
          <small className="text-muted">{message}</small>
        )}
      </td>
      <td className="text-light align-middle">
        <div
          className={
            request.state === CustomerRequestState.OPEN
              ? "text-warning"
              : request.state === CustomerRequestState.CLOSED
              ? "text-success"
              : "text-danger"
          }
        >
          {CR_STATEDESCRIPTION[request.state]}
        </div>
        <small className="text-muted">
          {formatDate(request.timeline.slice().reverse().at(0)?.date || request.requestedOn)}
        </small>
      </td>
      <td className="text-light align-middle">
        {isAV ? (
          <button
            className="btn btn-text p-0 text-hover-light"
            disabled={request.state !== CustomerRequestState.OPEN}
            onClick={handleClickCancelAnonymous}
          >
            <i className="fa fa-times text-muted pr-0" />
          </button>
        ) : (
          <>
            <div>
              <SimpleConfirmationModal.SimpleConfirmationModalButton
                size="md"
                disabled={request.state !== CustomerRequestState.OPEN}
                modalTitle="Finish Request"
                onConfirm={handleCancelRequest}
                confirmButtonText="Confirm"
                buttonText={
                  <i
                    className={
                      "fa fa-check pr-0 " +
                      (request.state === CustomerRequestState.OPEN ? "text-success" : "text-muted")
                    }
                  />
                }
                buttonTooltipText="Finish Request"
                buttonClasses="btn btn-text p-0 text-hover-light "
                cancelButtonText="Close"
                modalDescription={
                  <span className="text-white fs-6">
                    <div>
                      Do you really want to mark {getRequestNumber(request)}:{" "}
                      {formatUnit(request.amount, request.commodity.unit)} of {request.commodity.title.en} as finished?
                      <br />
                      Our team will consider this request as done after performing this action.
                    </div>
                  </span>
                }
              />
            </div>
            <div>
              <SimpleConfirmationModal.SimpleConfirmationModalButton
                size="md"
                disabled={request.state !== CustomerRequestState.OPEN}
                modalTitle="Cancel Request"
                onConfirm={handleCancelRequest}
                confirmButtonText="Confirm"
                buttonText={<i className="fa fa-times text-muted pr-0" />}
                buttonTooltipText="Cancel Request"
                buttonClasses="btn btn-text p-0 text-hover-light "
                cancelButtonText="Close"
                modalDescription={
                  <span className="text-white fs-6">
                    <div>
                      Do you really want to cancel {getRequestNumber(request)}:{" "}
                      {formatUnit(request.amount, request.commodity.unit)} of {request.commodity.title.en}?
                    </div>
                  </span>
                }
              />
            </div>
          </>
        )}
      </td>
    </tr>
  );
};

export default CustomerRequestRow;
