import _ from "lodash";
import React, { PureComponent } from "react";
import StatisticCard from "./StatisticCard";
import TurnoverDevelopmentGraph from "./components/TurnoverDevelopmentGraph";
import GeneralStatisticsGraph from "./GeneralStatisticsGraph";
import ActiveCustomersListing from "./ActiveCustomersListing";
import {
  ControllingDashboardFinanceDashboardGraphType,
  ControllingDashboardStatisticCardType,
} from "../../../utils/controllingDashboardUtils";
import { ControllingStatistics } from "../../../model/statistics/controllingStatistics.types";
import SplashScreen from "../../common/SimpleSplashScreen";
import { DataContextInternalType } from "../../../context/dataContext";
import OrderRentabilityCard from "./components/OrderRentabilityCard";
import CashflowCard from "./components/CashflowCard";
import FinanceDataGraph from "./components/FinanceDataGraph";

interface ControllingDashboardProps {
  context: DataContextInternalType;
}

interface ControllingDashboardState {
  newestStatistics?: ControllingStatistics;
  statistic30daysAgo?: ControllingStatistics;
}

class ControllingDashboard extends PureComponent<ControllingDashboardProps, ControllingDashboardState> {
  constructor(props: ControllingDashboardProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.generateData();
  }

  componentDidUpdate(prevProps: Readonly<ControllingDashboardProps>) {
    if (_.isEqual(prevProps.context.controllingStatistics, this.props.context.controllingStatistics)) {
      this.generateData();
    }
  }

  generateData = () => {
    const { controllingStatistics } = this.props.context;
    if (controllingStatistics.length > 0) {
      const newestStatistics = controllingStatistics[controllingStatistics.length - 1];
      let statistic30daysAgo;
      if (controllingStatistics.length > 30) {
        statistic30daysAgo = controllingStatistics[controllingStatistics.length - 30];
      } else {
        statistic30daysAgo = controllingStatistics[0];
      }
      this.setState({ newestStatistics, statistic30daysAgo });
    }
  };

  render() {
    const { context } = this.props;
    const { newestStatistics, statistic30daysAgo } = this.state;
    if (!newestStatistics || !statistic30daysAgo) return <SplashScreen description="Loading Data" />;
    const { financeData, statisticsData, generalStatistics, mostActiveCustomers, cashflowData, date } =
      newestStatistics;
    const {
      statisticsData: statisticsDataOld,
      mostActiveCustomers: mostActiveCustomersOld,
      date: dateOld,
    } = statistic30daysAgo;
    const currentYear = new Date().getFullYear();
    const turnoverStatistics = context.controllingStatistics
      .filter((conStats) => conStats.date.getFullYear() === currentYear)
      .map((cS) => {
        return { date: cS.date, turnover: cS.turnoverCurrentYear };
      });

    return (
      <>
        <div className="d-flex flex-column flex-column-fluid pt-10 pl-2">
          <div className="container-fluid dashboard-container">
            <div className="row">
              <div className="col-12 col-xxl-6">
                <div className="row h-100">
                  <div className="col-12 col-md-6 col-xxl-4 mb-3">
                    <FinanceDataGraph
                      data={financeData}
                      type={ControllingDashboardFinanceDashboardGraphType.CD_FDG_BOOKTURNOVER}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-xxl-4 mb-3">
                    <FinanceDataGraph
                      data={financeData}
                      type={ControllingDashboardFinanceDashboardGraphType.CD_FDG_WRITTENTURNOVER}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-xxl-4 mb-3">
                    <FinanceDataGraph
                      data={financeData}
                      type={ControllingDashboardFinanceDashboardGraphType.CD_FDG_WAREHOUSE}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-xxl-4 mb-3">
                    <FinanceDataGraph
                      data={financeData}
                      type={ControllingDashboardFinanceDashboardGraphType.CD_FDG_BOOKMARGIN}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-xxl-4 mb-3">
                    <FinanceDataGraph
                      data={financeData}
                      type={ControllingDashboardFinanceDashboardGraphType.CD_FDG_WRITTENMARGIN}
                    />
                  </div>

                  <div className="col-12 col-md-6 col-xxl-4 mb-3">
                    <FinanceDataGraph
                      data={financeData}
                      type={ControllingDashboardFinanceDashboardGraphType.CD_FDG_EXPENSES}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-xxl-6">
                <div className="mb-3">
                  <CashflowCard
                    data={cashflowData}
                    warehouseData={{
                      value: financeData.warehouseValueMonths,
                      currency: financeData.warehouseValueCurrency,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-xxxl-6">
                <OrderRentabilityCard />
              </div>
              <div className="col-12 col-xxxl-6">
                <div className="row h-100">
                  <div className="col-12 mb-3">
                    <TurnoverDevelopmentGraph turnoverStatistics={turnoverStatistics} />
                  </div>
                  <div className="col-12 col-md-6 col-xxl-4 mb-3">
                    <StatisticCard
                      statisticsData={{ date, statisticsData }}
                      statisticsDataOld={{ date: dateOld, statisticsData: statisticsDataOld }}
                      type={ControllingDashboardStatisticCardType.CD_SC_ORDERS}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-xxl-4 mb-3">
                    <StatisticCard
                      statisticsData={{ date, statisticsData }}
                      statisticsDataOld={{ date: dateOld, statisticsData: statisticsDataOld }}
                      type={ControllingDashboardStatisticCardType.CD_SC_CUSTOMERS}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-xxl-4 mb-3">
                    <StatisticCard
                      statisticsData={{ date, statisticsData }}
                      statisticsDataOld={{ date: dateOld, statisticsData: statisticsDataOld }}
                      type={ControllingDashboardStatisticCardType.CD_SC_REQUESTS}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-xxl-4 mb-3">
                    <StatisticCard
                      statisticsData={{ date, statisticsData }}
                      statisticsDataOld={{ date: dateOld, statisticsData: statisticsDataOld }}
                      type={ControllingDashboardStatisticCardType.CD_SC_SUPPLIERS}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-xxl-4 mb-3">
                    <StatisticCard
                      statisticsData={{ date, statisticsData }}
                      statisticsDataOld={{ date: dateOld, statisticsData: statisticsDataOld }}
                      type={ControllingDashboardStatisticCardType.CD_SC_CONTRACTS}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-xxl-4 mb-3">
                    <StatisticCard
                      statisticsData={{ date, statisticsData }}
                      statisticsDataOld={{ date: dateOld, statisticsData: statisticsDataOld }}
                      type={ControllingDashboardStatisticCardType.CD_SC_COMMODITIES}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-12 col-xxl-6 mb-3">
                <ActiveCustomersListing
                  mostActiveCustomers={mostActiveCustomers}
                  mostActiveCustomersOld={mostActiveCustomersOld}
                />
              </div>
              <div className="col-12 col-xxl-6 mb-35">
                <GeneralStatisticsGraph generalStatistics={generalStatistics} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ControllingDashboard;
