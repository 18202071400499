import React, { PureComponent } from "react";
import { ProgressBar } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { formatDate } from "../../../../utils/baseUtils";
import { CommodityOfferRequestExtended } from "../../../../model/commodityOfferRequest.types";
import {
  getCommodityOfferRequestProgress,
  resolveCommodityOfferRequestState,
  resolveLatestStateUpdateDate,
} from "../../../../utils/commodityOfferRequestUtils";
import { getTimeDiffString } from "../../../../utils/dateUtils";

interface CommodityOfferRequestRowProps extends RouteComponentProps {
  commodityOfferRequest: CommodityOfferRequestExtended;
}

class CommodityOfferRequestRow extends PureComponent<CommodityOfferRequestRowProps> {
  forwardSupplier = (e: React.MouseEvent<HTMLTableRowElement>) => {
    const { commodityOfferRequest, history } = this.props;
    e.stopPropagation();
    history.push(`/supplier/${commodityOfferRequest.supplier._id.toString()}/${commodityOfferRequest._id.toString()}`);
  };

  render() {
    const { commodityOfferRequest } = this.props;
    const progress = getCommodityOfferRequestProgress(commodityOfferRequest);
    const updateDate = resolveLatestStateUpdateDate(commodityOfferRequest);

    return (
      <tr className="cursor-pointer-row" onClick={this.forwardSupplier}>
        <td className="align-middle">
          <div className="text-white fs-5 text-ellipsis" style={{ maxWidth: "25rem" }}>
            {commodityOfferRequest.supplier.name}
          </div>
          <div className="text-muted fs-7">{commodityOfferRequest.article.document.title.en}</div>
        </td>
        <td className="text-light align-middle">
          <div className={"text-" + progress[1]}>{resolveCommodityOfferRequestState(commodityOfferRequest)}</div>
          <div className="text-muted">{getTimeDiffString(updateDate ?? commodityOfferRequest.created)}</div>
        </td>
        <td className="text-light align-middle">{formatDate(commodityOfferRequest.created)}</td>
        <td className="text-light align-middle">
          <ProgressBar style={{ backgroundColor: "#232323", height: 5 }}>
            <ProgressBar min={0} max={100} now={progress[0]} variant={progress[1]} />
          </ProgressBar>
          <div className="fs-7 text-nowrap text-center">{progress[0]}%</div>
        </td>
      </tr>
    );
  }
}

export default withRouter(CommodityOfferRequestRow);
