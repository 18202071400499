import _ from "lodash";
import React, { PureComponent } from "react";
import { DataContextInternalType } from "../../../../context/dataContext";
import Search from "../../../common/Search";
import { doFuseSearch } from "../../../../utils/baseUtils";
import OrderWidget from "../../../common/OrderWidget";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";
import {
  INTERNAL_EXTENDED_ORDER_TYPES,
  INTERNAL_ORDER_TYPES,
  isCustomerOrder,
  isSampleOrder,
  isSupplierOrder,
  O_ORDERTYPES,
} from "../../../../utils/orderUtils";
import CommodityPageOrderUpdates from "../../common/CommodityPageOrderUpdates";
import { sortCustomerOrdersByState } from "../../../../utils/customerOrderUtils";
import { CustomerOrder } from "../../../../model/customerOrder.types";
import { SupplierOrder } from "../../../../model/supplierOrder.types";
import { sortSupplierOrdersByState } from "../../../../utils/supplierOrderUtils";
import { extendSampleOrder, sortSampleOrdersByState } from "../../../../utils/sampleOrderUtils";
import { SampleOrder } from "../../../../model/sampleOrder.types";
import { isFinishedProduct } from "../../../../utils/finishedProductUtils";
import { InternalArticleExtended } from "../../../../utils/productArticleUtils";
import { extendCustomerOrder, extendSupplierOrder } from "../../../../utils/dataTransformationUtils";

interface CommodityPageOrdersProps {
  article: InternalArticleExtended;
  context: DataContextInternalType;
}

interface CommodityPageOrdersState {
  activeOrders: Array<INTERNAL_ORDER_TYPES>;
  orderFilter: SelectOption;
  ordersShown: number;
  search: string;
}

class CommodityPageOrders extends PureComponent<CommodityPageOrdersProps, CommodityPageOrdersState> {
  constructor(props: CommodityPageOrdersProps) {
    super(props);
    this.state = {
      search: "",
      orderFilter: O_ORDERTYPES[1], // supplier orders as default
      activeOrders: this.getRelevantOrders(props),
      ordersShown: 25,
    };
  }

  componentDidUpdate = (
    prevProps: Readonly<CommodityPageOrdersProps>,
    prevState: Readonly<CommodityPageOrdersState>
  ) => {
    if (!_.isEqual(prevProps.article, this.props.article)) {
      this.setState({ activeOrders: this.getRelevantOrders(this.props) });
      return;
    }
    // Update if a change appeared, but it was not a state update, e.g. search. We assume it must be context update then
    if (_.isEqual(prevState, this.state)) {
      const orders = this.getRelevantOrders(this.props);
      if (!_.isEqual(orders, this.state.activeOrders)) this.setState({ activeOrders: orders });
    }
  };

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });
  handleChangeOrderFilter = (orderFilter: SelectOption) => this.setState({ orderFilter, ordersShown: 25 });
  handleShowMore = () => this.setState({ ordersShown: this.state.ordersShown + 25 });

  getFilteredOrders = () => {
    const { search, orderFilter, activeOrders } = this.state;
    let filteredOrders = activeOrders.slice();
    if (orderFilter.value === "customer") filteredOrders = filteredOrders.filter((o) => isCustomerOrder(o));
    else if (orderFilter.value === "sample") filteredOrders = filteredOrders.filter((o) => isSampleOrder(o));
    else filteredOrders = filteredOrders.filter((o) => isSupplierOrder(o));
    if (search.trim())
      filteredOrders = doFuseSearch(filteredOrders, search, [
        "orderNo",
        "transport",
        "company.name",
        "supplier.name",
        "amount",
      ]);
    return filteredOrders;
  };

  getRelevantOrders = (props: CommodityPageOrdersProps) => {
    const { customerOrder, supplierOrder, sampleOrder } = this.props.context;
    let orders: Array<INTERNAL_ORDER_TYPES> = customerOrder.filter(
      (cO) => cO.commodity._id.toString() === props.article._id.toString()
    );
    orders = orders.concat(supplierOrder.filter((sO) => sO.commodity._id.toString() === props.article._id.toString()));
    orders = orders.concat(
      sampleOrder.filter((samO) => samO.commodity._id.toString() === props.article._id.toString())
    );
    return orders;
  };

  getSortedOrders = (orders: Array<INTERNAL_ORDER_TYPES>): Array<INTERNAL_ORDER_TYPES> => {
    if (orders.length === 0) return [];
    if (isCustomerOrder(orders[0]))
      return sortCustomerOrdersByState(orders as Array<CustomerOrder>) as Array<CustomerOrder>;
    if (isSupplierOrder(orders[0]))
      return sortSupplierOrdersByState(orders as Array<SupplierOrder>) as Array<SupplierOrder>;
    if (isSampleOrder(orders[0])) return sortSampleOrdersByState(orders as Array<SampleOrder>) as Array<SampleOrder>;
    return orders;
  };

  extendOrder = (order: INTERNAL_ORDER_TYPES) => {
    const { context } = this.props;
    if (isSampleOrder(order)) return extendSampleOrder(order, context);
    if (isCustomerOrder(order)) return extendCustomerOrder(order, context);
    return extendSupplierOrder(order, context);
  };

  render() {
    const { context, article } = this.props;
    const { search, orderFilter, ordersShown } = this.state;
    const type = orderFilter.value === "supplier" ? "supplier" : "customer";
    const filteredOrders = this.getFilteredOrders();
    const sortedOrders = this.getSortedOrders(filteredOrders);
    return (
      <>
        <div className="card bg-white">
          <div className="card-header border-0 mt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder fs-3 mb-1">Related Orders</span>
              <span className="text-muted fw-bold fs-7">
                {filteredOrders.length} orders for this {isFinishedProduct(article) ? "finished product" : "commodity"}
              </span>
            </h3>
            <div className="card-toolbar ">
              <div className="min-w-200px">
                <CustomSelect
                  options={O_ORDERTYPES}
                  onChange={this.handleChangeOrderFilter}
                  value={orderFilter}
                  matchFormControl={true}
                />
              </div>
            </div>
            <div className="card-toolbar">
              <Search onSearch={this.handleSearch} value={search} />
            </div>
          </div>
          <div className="card-body modal-content-scroll">
            {sortedOrders.slice(0, ordersShown).map((o) => (
              <OrderWidget
                key={o._id.toString()}
                type={type}
                order={this.extendOrder(o) as INTERNAL_EXTENDED_ORDER_TYPES}
              />
            ))}
            {sortedOrders.length > ordersShown && (
              <div className="align-items-center bg-light rounded p-5 mb-7" onClick={this.handleShowMore}>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 me-2">
                    <div className="text-white text-center">Show More</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <CommodityPageOrderUpdates orders={filteredOrders} context={context} />
      </>
    );
  }
}

export default CommodityPageOrders;
