import React from "react";
import { AirFreightValues } from "../../../../model/configuration/calculationConfiguration.types";
import { CostObjectList } from "./common/CostObjectList";
import { RangeCostObjectList } from "./common/RangeCostObjectList";
import { Input } from "../../../common/Input";
import Tooltip from "../../../common/Tooltip";

interface AirFreightValuesConfigurationProps {
  airFreightValues: AirFreightValues;
  onChangeAirFreightValues: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AirFreightValuesConfiguration: React.FunctionComponent<AirFreightValuesConfigurationProps> = ({
  airFreightValues,
  onChangeAirFreightValues,
}) => {
  const {
    airFreightCost,
    airportStorageTime,
    customsAirFreightCoefficient,
    defaultAirportFreightCost,
    minimumMargin,
    insurance,
  } = airFreightValues;
  return (
    <>
      <h3 className="mb-3 mt-7">Base Values</h3>
      <div className="row">
        <div className="col-xl-3">
          <label className="fs-5 fw-bold mb-2">Minimum Total Margin</label>
          <div className="input-group">
            <Input
              className="form-control custom-form-control"
              type={"number"}
              name={"minimumMargin.value"}
              min={0}
              value={minimumMargin.value}
              onChange={onChangeAirFreightValues}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                {minimumMargin.currency}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3">
          <label className="fs-5 fw-bold mb-2">Fallback Airfreight Cost</label>
          <div className="input-group">
            <Input
              className="form-control custom-form-control"
              type={"number"}
              name={"defaultAirportFreightCost.cost"}
              min={0}
              value={defaultAirportFreightCost.cost}
              onChange={onChangeAirFreightValues}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                {defaultAirportFreightCost.currency}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3">
          <label className="fs-5 fw-bold mb-2">
            Average Time In Airport
            <Tooltip
              tooltipText={
                "The average time we expect our orders to remain in the airport until fully handled. Each day results in additional cost"
              }
            >
              <span className="ml-2">
                <i className="fa fa-info-circle text-white" />
              </span>
            </Tooltip>
          </label>
          <div className="input-group">
            <Input
              className="form-control custom-form-control"
              type={"number"}
              name={"airportStorageTime"}
              min={0}
              value={airportStorageTime}
              onChange={onChangeAirFreightValues}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                days
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3">
          <label className="fs-5 fw-bold mb-2">
            Customs Airfreight Coefficient
            <Tooltip
              tooltipText={"The percentage of the total air freight costs to be added to the customs cost calculation"}
            >
              <span className="ml-2">
                <i className="fa fa-info-circle text-white" />
              </span>
            </Tooltip>
          </label>
          <div className="input-group">
            <Input
              className="form-control custom-form-control"
              type={"number"}
              name={"customsAirFreightCoefficient"}
              min={0}
              value={customsAirFreightCoefficient * 100}
              onChange={onChangeAirFreightValues}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                %
              </div>
            </div>
          </div>
        </div>
      </div>
      <h3 className="mb-3 mt-7">Freight Cost</h3>
      <CostObjectList
        cost={airFreightCost.additionalFreightCost}
        onChangeValues={onChangeAirFreightValues}
        objectPath={"airFreightCost.additionalFreightCost"}
      />
      <h3 className="mb-3 mt-7">Airport Cost</h3>
      <CostObjectList
        cost={airFreightCost.airportCost}
        onChangeValues={onChangeAirFreightValues}
        objectPath={"airFreightCost.airportCost"}
      />
      <h3 className="mb-3 mt-7">Insurance</h3>
      <div className="row">
        <div className="col-xl-4">
          <label className="fs-5 fw-bold mb-2">Minimum Cost</label>
          <div className="input-group">
            <Input
              className="form-control custom-form-control"
              type="number"
              name="insurance.min.value"
              value={insurance.min.value}
              onChange={onChangeAirFreightValues}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                {insurance.min.currency}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4">
          <label className="fs-5 fw-bold mb-2">Percentage on Purchase Price</label>
          <div className="input-group">
            <Input
              className="form-control custom-form-control"
              type="number"
              name="insurance.marginQuote"
              value={insurance.marginQuote}
              onChange={onChangeAirFreightValues}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                %
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4">
          <label className="fs-5 fw-bold mb-2">Percentage</label>
          <div className="input-group">
            <Input
              className="form-control custom-form-control"
              type="number"
              name="insurance.insuranceValue"
              value={insurance.insuranceValue}
              onChange={onChangeAirFreightValues}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                %
              </div>
            </div>
          </div>
        </div>
      </div>
      <h3 className="mb-3 mt-7">Follow-Up Cost</h3>
      <RangeCostObjectList
        cost={airFreightCost.followUpCost}
        onChangeValues={onChangeAirFreightValues}
        objectPath={"airFreightCost.followUpCost"}
      />
    </>
  );
};

export default AirFreightValuesConfiguration;
