import _ from "lodash";
import React, { PureComponent } from "react";
import CreateSampleOrderModal from "./CreateSampleOrderModal";
import { DataContextAnonymousType, DataContextCustomerType } from "../../../context/dataContext";
import { CustomerCommodityExtended } from "../../../model/customer/customerCommodity.types";
import ReactStarsWrapper from "../../common/ReactStarsWrapper";
import { CustomerSampleOrder } from "../../../model/customer/customerSampleOrder.types";
import { formatDate } from "../../../utils/baseUtils";

interface CommodityPageSamplesProps {
  commodity: CustomerCommodityExtended;
  context: DataContextCustomerType | DataContextAnonymousType;
}

interface CommodityPageSamplesState {
  relevantSampleOrders: Array<CustomerSampleOrder>;
}

class CommodityPageSamplesWidget extends PureComponent<CommodityPageSamplesProps, CommodityPageSamplesState> {
  constructor(props: CommodityPageSamplesProps) {
    super(props);
    this.state = {
      relevantSampleOrders: this.getRelevantOrders(props),
    };
  }

  componentDidUpdate = (prevProps: Readonly<CommodityPageSamplesProps>) => {
    if (!_.isEqual(prevProps.commodity, this.props.commodity)) {
      this.setState({ relevantSampleOrders: this.getRelevantOrders(this.props) });
    } else if (_.isEqual(prevProps.context.sampleOrder, this.props.context.sampleOrder)) {
      const orders = this.getRelevantOrders(this.props);
      if (!_.isEqual(orders, this.state.relevantSampleOrders)) this.setState({ relevantSampleOrders: orders });
    }
  };

  /**
   * Get a list of sample orders for the commodity that contain rating
   * @param props the components properties
   * @returns {Array<CustomerSampleOrder>} list of sample orders
   */
  getRelevantOrders = (props: CommodityPageSamplesProps): Array<CustomerSampleOrder> => {
    const { commodity } = props;
    const { sampleOrder } = props.context;
    return sampleOrder.filter(
      (samO) => samO.commodity._id.toString() === commodity._id.toString() && samO.sampleRating
    );
  };

  render() {
    const { commodity, context } = this.props;
    const { relevantSampleOrders } = this.state;

    return (
      <>
        <div className="card bg-white  mt-8">
          <div className="card-header border-0 mt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder fs-3 mb-1">Samples</span>
            </h3>
          </div>
          <div className="card-body pt-0 modal-content-scroll" style={{ maxHeight: 300 }}>
            {relevantSampleOrders.map((rSO) => {
              if (!rSO.sampleRating) return <React.Fragment key={rSO._id.toString()}></React.Fragment>;
              return (
                <div key={rSO._id.toString()} className="align-items-center bg-light no-hover rounded p-5 mb-2">
                  <div className="d-flex align-items-center justify-content-between">
                    <ReactStarsWrapper
                      value={rSO.sampleRating.rating}
                      count={5}
                      size={16}
                      classNames="d-block mr-0 ml-auto"
                      edit={false}
                    />
                    <div className="text-right">
                      <div className="text-right text-muted">{formatDate(rSO.sampleRating.ratingDate)}</div>
                    </div>
                  </div>
                  <div className="d-block">{rSO.sampleRating.ratingText}</div>
                </div>
              );
            })}
            <CreateSampleOrderModal
              context={context}
              commodity={commodity}
              buttonText="Order Sample"
              buttonClass="btn btn-light w-100 mt-5"
            />
          </div>
        </div>
      </>
    );
  }
}

export default CommodityPageSamplesWidget;
