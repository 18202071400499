import React, { PureComponent } from "react";
import { CommodityTimelineEntry } from "../../../../model/commodity.types";
import { DataContextInternalType } from "../../../../context/dataContext";
import Search from "../../../common/Search";
import { doFuseSearch, getDocFromCollection } from "../../../../utils/baseUtils";
import {
  ExtendedCommodityTimelineEntry,
  T_BATCHCREATED,
  T_COMMODITYAPPROVED,
  T_COMMODITYCREATED,
  T_COMMODITYDISABLED,
  T_COMMODITYEDITED,
  T_COMMODITYENABLED,
  T_FILEDELETED,
  T_FILEUPDATED,
  T_FILEUPLOADED,
  T_MASTERSPECCREATED,
  T_MASTERSPECSIGNED,
  T_ORDEREDFROMSUPPLIER,
  T_PRICESUPDATED,
  T_PRICEUPDATED,
  T_SELLINGPRICESUPDATED,
  T_SUPPLIERPRICEUPDATED,
  T_TRANSPORTPRICEUPDATED,
} from "../../../../utils/commodityUtils";
import HistoryEntry from "../../../common/HistoryEntry";
import { getBatchAmount } from "../../../../utils/batchUtils";
import { O_TRANSPORTTYPES } from "../../../../utils/orderUtils";
import Tooltip from "../../../common/Tooltip";
import { PriceHistoryEntry } from "../../../common/internal/PriceHistoryEntry";
import { FinishedProductTimelineEntry } from "../../../../model/finishedProduct.types";
import {
  T_FIINISHEDPRODUCTORDEREDFROMSUPPLIER,
  T_FINISHEDPRODUCTAPPROVED,
  T_FINISHEDPRODUCTBATCHCREATED,
  T_FINISHEDPRODUCTCREATED,
  T_FINISHEDPRODUCTDISABLED,
  T_FINISHEDPRODUCTEDITED,
  T_FINISHEDPRODUCTENABLED,
} from "../../../../utils/finishedProductUtils";
import { formatArticleUnit, InternalArticleExtended } from "../../../../utils/productArticleUtils";

interface CommodityPageHistoryProps {
  commodity: InternalArticleExtended;
  context: DataContextInternalType;
}

interface CommodityPageHistoryState {
  search: string;
}

class CommodityPageHistory extends PureComponent<CommodityPageHistoryProps, CommodityPageHistoryState> {
  constructor(props: CommodityPageHistoryProps) {
    super(props);
    this.state = {
      search: "",
    };
  }

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });

  getEntryDescription = (entry: CommodityTimelineEntry | FinishedProductTimelineEntry) => {
    const { context, commodity } = this.props;
    const fileType =
      entry.subtype === "coa"
        ? "CoA"
        : entry.subtype === "masterSpecification"
        ? "master specification"
        : entry.subtype === "supplierSpecification"
        ? "supplier specification"
        : "file";
    let supplierOrder;
    let batch;
    switch (entry.type) {
      case T_COMMODITYCREATED:
        return "Commodity was created";
      case T_FINISHEDPRODUCTCREATED:
        return "Finished product was created";
      case T_COMMODITYEDITED:
        return "Commodity information was updated";
      case T_FINISHEDPRODUCTEDITED:
        return "Finished product information was updated";
      case T_FILEUPLOADED:
        return `A ${fileType} was uploaded`;
      case T_FILEUPDATED:
        return `A ${fileType} was updated`;
      case T_FILEDELETED:
        return `A ${fileType} was deleted`;
      case T_PRICEUPDATED:
      case T_PRICESUPDATED:
        return (
          <Tooltip
            tooltipId={entry._id.toString()}
            tooltipClass="custom-tooltip custom-tooltip-large"
            tooltipText={<PriceHistoryEntry entry={entry} commodity={commodity} context={context} />}
          >
            <span>Prices were updated</span>
          </Tooltip>
        );
      case T_SUPPLIERPRICEUPDATED:
        return (
          <Tooltip
            tooltipClass="custom-tooltip custom-tooltip-large"
            tooltipText={<PriceHistoryEntry entry={entry} commodity={commodity} context={context} />}
          >
            <span>
              Supplier {entry.reference ? getDocFromCollection(context.supplier, entry.reference)?.name : "Unknown"}{" "}
              updated prices
            </span>
          </Tooltip>
        );
      case T_COMMODITYDISABLED:
        return `Commodity was disabled`;
      case T_FINISHEDPRODUCTDISABLED:
        return `Finished product was disabled`;
      case T_COMMODITYENABLED:
        return `Commodity was enabled`;
      case T_FINISHEDPRODUCTENABLED:
        return `Finished product was enabled`;
      case T_COMMODITYAPPROVED:
        return "Commodity was approved";
      case T_FINISHEDPRODUCTAPPROVED:
        return `Finished product was approved`;
      case T_ORDEREDFROMSUPPLIER:
        supplierOrder = entry.reference ? getDocFromCollection(context.supplierOrder, entry.reference) : undefined;
        return `Article was ordered from ${
          supplierOrder
            ? getDocFromCollection(context.supplier, supplierOrder.supplier)?.name || "supplier"
            : "supplier"
        }`;
      case T_FIINISHEDPRODUCTORDEREDFROMSUPPLIER:
        supplierOrder = entry.reference ? getDocFromCollection(context.supplierOrder, entry.reference) : undefined;
        return `Finished product was ordered from ${
          supplierOrder
            ? getDocFromCollection(context.supplier, supplierOrder.supplier)?.name || "supplier"
            : "supplier"
        }`;
      case T_BATCHCREATED:
        batch = entry.reference ? getDocFromCollection(context.batch, entry.reference) : undefined;
        return `Commodity was stocked${batch ? `: RB${batch.identifier} - ${getBatchAmount(batch)}${batch.unit}` : ""}`;
      case T_FINISHEDPRODUCTBATCHCREATED:
        batch = entry.reference ? getDocFromCollection(context.batch, entry.reference) : undefined;
        return `Finished product was stocked${
          batch
            ? `: RB${batch.identifier} - ${getBatchAmount(batch)}${formatArticleUnit(batch.unit, batch.commodity)}`
            : ""
        }`;
      case T_MASTERSPECCREATED:
        return "The master specification PDF was generated";
      case T_MASTERSPECSIGNED:
        return "The master specification was digitally signed";
      case T_SELLINGPRICESUPDATED:
        return "Selling prices were updated";
      case T_TRANSPORTPRICEUPDATED:
        return `${O_TRANSPORTTYPES.find((t) => t.value === entry.reference)?.label || "Unknown"} prices were updated`;
      default:
        return "Unknown entry";
    }
  };

  getFilteredEntries = () => {
    const { commodity } = this.props;
    const { search } = this.state;
    const timelineEntries = commodity.timeline.map((e) => {
      const extendedEntry = { ...e } as ExtendedCommodityTimelineEntry;
      extendedEntry.text = this.getEntryDescription(extendedEntry);
      return extendedEntry;
    });
    if (search.trim()) return doFuseSearch(timelineEntries, search, ["text", "type", "subtype"]);
    return timelineEntries.slice().reverse();
  };

  render() {
    const { context } = this.props;
    const { search } = this.state;
    const timelineEntries = this.getFilteredEntries();
    return (
      <div className="card bg-white">
        <div className="card-header border-0 mt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">History</span>
            <span className="text-muted fw-bold fs-7">{timelineEntries.length} Entries</span>
          </h3>
          <div className="card-toolbar">
            <Search onSearch={this.handleSearch} value={search} />
          </div>
        </div>
        <div className="card-body" style={{ overflowY: "auto", maxHeight: "70vh" }}>
          {timelineEntries.map((entry) => (
            <HistoryEntry key={entry._id.toString()} entry={entry} context={context} />
          ))}
        </div>
      </div>
    );
  }
}

export default CommodityPageHistory;
