import React, { Component } from "react";
import { Service } from "../../../model/service.types";
import { formatCurrency } from "../../../utils/baseUtils";
import DisableServiceModal from "./modals/DisableServiceModal";
import CreateServiceModal from "./modals/CreateServiceModal";
import { CUSTOMER_BASE_CURRENCY } from "../../../utils/currencyUtils";

interface ActiveSubstanceProps {
  service: Service;
}

class ActiveSubstanceRow extends Component<ActiveSubstanceProps> {
  render() {
    const { service } = this.props;
    return (
      <tr>
        <td className="text-white align-middle">{service.title.en}</td>
        <td className="text-white align-middle">{service.description.en}</td>
        <td className="text-white align-middle">{formatCurrency(service.price, CUSTOMER_BASE_CURRENCY)}</td>
        <td className="text-white align-middle">
          {service.duration} {service.duration === 1 ? "day" : "days"}
        </td>
        <td className="text-right align-middle">
          <DisableServiceModal service={service} />
          <CreateServiceModal service={service} additionalButtonClasses="btn-sm ml-2" />
        </td>
      </tr>
    );
  }
}

export default ActiveSubstanceRow;
