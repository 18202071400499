import _ from "lodash";
import React, { PureComponent } from "react";
import { Nav } from "react-bootstrap";
import {
  CO_ARCHIVED,
  CO_CANCELED,
  CO_HANDLEDATCUSTOMS,
  CO_HANDLEDATWAREHOUSE,
  CO_ORDEREDATSUPPLIER,
  CO_ORDEREDBYCUSTOMER,
  CO_PERFORMINGSERVICES,
  CO_PROCESSINGATWAREHOUSE,
  CO_SHIPPEDFROMSUPPLIER,
  CO_SHIPPEDTOCUSTOMER,
  CO_SHIPPEDTOWAREHOUSE,
  CO_STATES,
  CustomerOrderExtended,
  T_WAREHOUSE,
} from "../../../../../model/customerOrder.types";
import { DataContextInternalType } from "../../../../../context/dataContext";
import { SupplierOrder } from "../../../../../model/supplierOrder.types";
import { getOrderStateRanking } from "../../../../../utils/customerOrderUtils";
import WorkflowSupplierOrderCard from "./workflowCards/WorkflowSupplierOrderCard";
import WorkflowOrderConfirmationCard from "./workflowCards/WorkflowOrderConfirmationCard";
import WorkflowShippingCard from "./workflowCards/WorkflowShippingCard";
import WorkflowGeneralInformationCard from "./workflowCards/WorkflowGeneralInformationCard";
import WorkflowProcessingTasksCard from "./workflowCards/WorkflowProcessingTasksCard";
import WorkflowServicesCard from "./workflowCards/WorkflowServicesCard";
import WorkflowShippingInformationCard from "./workflowCards/WorkflowShippingInformationCard";
import WorkflowInvoicesCard from "./workflowCards/WorkflowInvoicesCard";
import WorkflowProcessingStockCard from "./workflowCards/WorkflowProcessingStockCard";
import WorkflowCompletedCard from "./workflowCards/WorkflowCompletedCard";
import WorkflowCloseOrder from "./workflowCards/WorkflowCloseOrder";

interface CustomerOrderWorkflowProps {
  order: CustomerOrderExtended;
  context: DataContextInternalType;
  supplierOrder?: SupplierOrder;
}

interface CustomerOrderWorkflowState {
  activeFilter: string;
  currentTab: string;
}

class CustomerOrderWorkflow extends PureComponent<CustomerOrderWorkflowProps, CustomerOrderWorkflowState> {
  constructor(props: CustomerOrderWorkflowProps) {
    super(props);
    this.state = { activeFilter: "", currentTab: this.getTabThatRequiresAttention() };
  }

  componentDidUpdate(prevProps: Readonly<CustomerOrderWorkflowProps>) {
    if (!_.isEqual(prevProps.order, this.props.order))
      this.setState({ currentTab: this.getTabThatRequiresAttention() });
  }

  handleActiveFilter = (aF: string) => {
    this.setState({ activeFilter: aF === this.state.activeFilter ? "" : aF });
  };

  getSteps = (): Array<{ name: string; condition?: () => boolean }> => {
    const { order } = this.props;
    const orderStateRank = getOrderStateRanking(order);
    return [
      {
        name: "Ordered",
      },
      {
        name: "Shipping",
        condition: () => orderStateRank >= 2,
      },
      {
        name: "Processing",
        condition: () => orderStateRank >= 4,
      },
      {
        name: "Closed",
        condition: () => ([CO_CANCELED, CO_ARCHIVED] as Array<CO_STATES>).includes(order.state),
      },
    ];
  };

  getTabThatRequiresAttention = () => {
    const { order } = this.props;
    switch (order.state) {
      case CO_ORDEREDBYCUSTOMER:
      case CO_ORDEREDATSUPPLIER:
        return "Ordered";
      case CO_SHIPPEDFROMSUPPLIER:
      case CO_HANDLEDATCUSTOMS:
      case CO_SHIPPEDTOWAREHOUSE:
        return "Shipping";
      case CO_HANDLEDATWAREHOUSE:
      case CO_PROCESSINGATWAREHOUSE:
      case CO_PERFORMINGSERVICES:
        return "Processing";
      case CO_SHIPPEDTOCUSTOMER:
      case CO_CANCELED:
      case CO_ARCHIVED:
        return "Closed";
      default:
        return "";
    }
  };

  isDone = (tab: string) => {
    const { order } = this.props;
    const stateRank = getOrderStateRanking(order);
    if (stateRank === -1) return false;
    switch (tab) {
      case "Ordered":
        return stateRank > 1;
      case "Shipping":
        return stateRank > 4;
      case "Processing":
        return stateRank > 6;
      case "Closed":
        return stateRank > 7;
      default:
        return false;
    }
  };

  getLinkClasses = (tab: string, currentProgress: string) => {
    const { order } = this.props;
    const done = this.isDone(tab);
    const disabled = !done && tab !== currentProgress;
    if (order.state === CO_CANCELED) return "text-white py-2 progress-background-canceled";
    let classes = "text-white py-2 ";
    if (disabled) classes += "text-muted ";
    if (done) classes += "progress-background ";
    if (tab === currentProgress) classes += "progress-btn ";
    return classes;
  };

  render() {
    const { order, supplierOrder, context } = this.props;
    const { activeFilter } = this.state;
    const tabs = this.getSteps();
    const currentProgress = this.getTabThatRequiresAttention();
    const isWarehouse = order.transport === T_WAREHOUSE;
    const orderStateRanking =
      order.state === CO_CANCELED ? getOrderStateRanking(order, true) : getOrderStateRanking(order);

    return (
      <div className="card bg-white">
        <div className="card-header border-0 mt-5">
          <div className="card-title flex-column">
            <h2 className="mb-1">Workflow</h2>
          </div>
        </div>
        <div className="card-body p-9 pt-0">
          <ul className="nav-group nav-group-fluid custom-form-control">
            {tabs.map((t) => (
              <li key={t.name + "_link"} className="btn btn-sm fw-bolder custom-form-control p-0 ">
                <Nav.Link
                  active={currentProgress === t.name}
                  className={this.getLinkClasses(t.name, currentProgress)}
                  style={{ opacity: activeFilter && activeFilter !== t.name ? 0.3 : 1 }}
                  onClick={
                    this.isDone(t.name) || t.name === currentProgress
                      ? () => this.handleActiveFilter(t.name)
                      : undefined
                  }
                >
                  {t.name}
                </Nav.Link>
              </li>
            ))}
          </ul>
          {order.state === CO_ARCHIVED && (
            <>
              <div className="border-bottom-dark-gray pt-5" />
              <WorkflowCompletedCard order={order} />
            </>
          )}
          {order.state === CO_SHIPPEDTOCUSTOMER && (
            <>
              <div className="border-bottom-dark-gray pt-5" />
              <WorkflowCloseOrder order={order} />
            </>
          )}
          {orderStateRanking > 6 && (!activeFilter || activeFilter === "Closed") && (
            <>
              <div className="border-bottom-dark-gray pt-5" />
              <WorkflowInvoicesCard order={order} context={context} />
            </>
          )}
          {isWarehouse ? (
            <>
              <div className="border-bottom-dark-gray pt-5" />
              <WorkflowProcessingStockCard order={order} context={context} />
            </>
          ) : (
            <>
              {orderStateRanking > 4 && (!activeFilter || activeFilter === "Processing") && (
                <>
                  <div className="border-bottom-dark-gray pt-5" />
                  <WorkflowProcessingTasksCard order={order} />
                </>
              )}
              {orderStateRanking > 4 &&
                order.services.every((s) => s.performed) &&
                (!activeFilter || activeFilter === "Processing") && (
                  <>
                    <div className="border-bottom-dark-gray pt-5" />
                    <WorkflowShippingInformationCard order={order} />
                  </>
                )}
              {orderStateRanking > 4 && order.services.length > 0 && (!activeFilter || activeFilter === "Processing") && (
                <>
                  <div className="border-bottom-dark-gray pt-5" />
                  <WorkflowServicesCard order={order} context={context} />
                </>
              )}
              {orderStateRanking > 2 && (!activeFilter || activeFilter === "Processing") && (
                <>
                  <div className="border-bottom-dark-gray pt-5" />
                  <WorkflowGeneralInformationCard order={order} supplierOrder={supplierOrder} context={context} />
                </>
              )}
            </>
          )}
          {!isWarehouse && orderStateRanking > 1 && (!activeFilter || activeFilter === "Shipping") && (
            <>
              <div className="border-bottom-dark-gray pt-5" />
              <WorkflowShippingCard order={order} supplierOrder={supplierOrder} context={context} />
            </>
          )}
          {(isWarehouse || supplierOrder || order.previousSupplierOrder) &&
            (!activeFilter || activeFilter === "Ordered") && (
              <>
                <div className="border-bottom-dark-gray pt-5" />
                <WorkflowOrderConfirmationCard order={order} context={context} />
              </>
            )}
          {!isWarehouse && (!activeFilter || activeFilter === "Ordered") && orderStateRanking > 0 && (
            <>
              <div className="border-bottom-dark-gray pt-5" />
              <WorkflowSupplierOrderCard order={order} supplierOrder={supplierOrder} context={context} />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default CustomerOrderWorkflow;
