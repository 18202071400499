import { BSON } from "realm-web";
import { Service } from "../model/service.types";
import { callFunction } from "../services/dbService";

export const D_SERVICERESULT = "serviceResult";

// Backend functions relating to services
export const UPSERTSERVICE = "upsertService";

/**
 * Inserts a new service into the database.
 * @param service Service that should be inserted into the database
 * @returns {Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false>} Result of the function
 */
export async function insertService(
  service: Service
): Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false> {
  return (await callUpsertService(service, true)) as Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false;
}

/**
 * Updates an existing service inside the database.
 * @param service Service that should be updated inside the database
 * @returns {Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false>} Result of the function
 */
export async function updateService(
  service: Service
): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> {
  return (await callUpsertService(service, false)) as Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false;
}

/**
 * Calls the upsert service function in backend.
 * @param service Service that should be upsert
 * @param insert True for insert, else update
 * @returns {Promise<false | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>>} Result of the function
 */
async function callUpsertService(
  service: Service,
  insert: boolean
): Promise<
  false | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>
> {
  return callFunction(UPSERTSERVICE, [service, insert]);
}
