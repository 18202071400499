import React, { PureComponent } from "react";
import { toAbsoluteUrl } from "../../utils/baseUtils";

class MobileView extends PureComponent {
  render() {
    return (
      <div
        className="d-flex flex-column h-100 justify-content-center"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/assets/media/misc/bg-1.jpg")})`,
          backgroundSize: "cover",
        }}
      >
        <div className="d-flex justify-content-center p-12 p-lg-20">
          <div
            className="bg-body d-flex flex-column align-items-stretch flex-center p-20 w-600px border-none"
            style={{ borderRadius: "1.5rem" }}
          >
            <h1 className={"text-black"}>Sorry!</h1>
            <span>
              To ensure the best user experience, Rawbids is not available on mobile devices. Please access Rawbids from
              a desktop device.
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default MobileView;
