import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import BaseListing from "../../../../../common/BaseListing";
import { DataContextInternalType } from "../../../../../../context/dataContext";
import { CO_CANCELED, CustomerOrderExtended } from "../../../../../../model/customerOrder.types";
import { formatCurrency, formatDate, toAbsoluteUrl } from "../../../../../../utils/baseUtils";
import { resolveFilePath } from "../../../../../../utils/fileUtils";
import { getRelatedInvoices } from "../../../../../../utils/customerOrderUtils";
import { extendInvoice } from "../../../../../../utils/invoiceUtils";

interface WorkflowInvoicesCardProps {
  order: CustomerOrderExtended;
  context: DataContextInternalType;
}

class WorkflowInvoicesCard extends PureComponent<WorkflowInvoicesCardProps> {
  headerDefinitionInvoices = [
    { title: "Type", style: { width: "30%" } },
    { title: "Company", style: { width: "30%" } },
    { title: "Invoice Date", style: { width: "20%" } },
    { title: "Total", style: { width: "10%" } },
    { title: "File", className: "text-right", style: { width: "10%" } },
  ];

  render() {
    const { order, context } = this.props;
    const invoices = getRelatedInvoices(order._id, context);

    return (
      <div className="opacity-100-hover" style={{ opacity: invoices.length > 0 ? 0.3 : 1 }}>
        <div className="fw-bolder text-white fs-3 my-5">
          Invoices{" "}
          {invoices.length > 0 ? (
            <i className="h2 fas fa-check-circle text-success" />
          ) : (
            <span className="text-warning">[Current Task]</span>
          )}
        </div>
        <div className="row py-2">
          <div className="bg-light2 rounded p-5 mb-7">
            <div className="table-responsive">
              <BaseListing
                headerDefinition={this.headerDefinitionInvoices}
                bodyContent={
                  <>
                    {invoices.length > 0 ? (
                      invoices.map((i) => {
                        const invoiceExtended = extendInvoice(i, context);
                        return (
                          <tr key={i._id.toString()}>
                            <td className="align-middle">
                              <span className="text-white fw-bolder">
                                {i.type === "supplierInvoice" ? "Supplier" : "Customer"}
                              </span>
                            </td>
                            <td className="align-middle">
                              <span className="fw-bold text-white">{invoiceExtended.company.name}</span>
                            </td>
                            <td className="align-middle">
                              <span className="fw-bold text-success">{formatDate(i.invoiceDate)}</span>
                            </td>
                            <td className="align-middle">
                              <span className="fw-bold text-white">{formatCurrency(i.total, i.currency)}</span>
                            </td>
                            <td className="text-right align-middle">
                              <a href={resolveFilePath(i.file)} target="_blank" rel="noopener noreferrer">
                                <img
                                  alt="pdf"
                                  className="w-30px me-3"
                                  src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                                />
                              </a>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={10} className="text-center align-middle">
                          No invoices available yet
                        </td>
                      </tr>
                    )}
                  </>
                }
              />
            </div>
            {order.state !== CO_CANCELED && (
              <div className="float-right">
                <Link
                  className="btn btn-light custom-form-control-user btn-sm"
                  to={"/createInvoice/" + order._id.toString()}
                >
                  Create Invoice
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default WorkflowInvoicesCard;
