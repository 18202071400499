import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContextAnonymous, DataContextCustomer } from "../../../../context/dataContext";
import OrderPage from "./OrderPage";
import userService from "../../../../services/userService";
import { ANONYMOUS, CUSTOMER } from "../../../../utils/userUtils";

interface OrderWrapperParams {
  id: string;
}

interface OrderWrapperProps extends RouteComponentProps<OrderWrapperParams> {}

const OrderWrapper: React.FunctionComponent<OrderWrapperProps> = (props) => {
  let context;
  if (userService.getUserType() === CUSTOMER) {
    context = useContext(DataContextCustomer);
  } else if (userService.getUserType() === ANONYMOUS) {
    context = useContext(DataContextAnonymous);
  }
  if (!context) return <></>;
  return <OrderPage context={context} {...props} />;
};

export default OrderWrapper;
