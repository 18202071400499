import React, { PureComponent } from "react";
import CalendarWeekSelector from "../../../common/CalendarWeekSelector";
import { CustomerOrder, CustomerOrderExtended, T_SEAFREIGHT } from "../../../../model/customerOrder.types";
import { getCW } from "../../../../utils/dateUtils";
import { ChangeDateAction, DateType } from "../../../../utils/orderUtils";
import DateInput from "../../../common/DateInput";
import { formatDate } from "../../../../utils/baseUtils";

interface ChangedEtaCustomerOrderRowsProps {
  action: ChangeDateAction;
  orderId: string;
  order: CustomerOrder | CustomerOrderExtended;
  changedDate: Date;
  changedDateType?: DateType;
  sendMail: boolean;
  onChangeDate: (orderId: string, value: Date | React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDateType: (orderId: string, type: DateType) => void;
  onChangeSendMail: (orderId: string) => void;
}

interface ChangedEtaCustomerOrderRowsState {}

class ChangedEtaCustomerOrderRows extends PureComponent<
  ChangedEtaCustomerOrderRowsProps,
  ChangedEtaCustomerOrderRowsState
> {
  render() {
    const {
      action,
      orderId,
      order,
      changedDate,
      changedDateType,
      sendMail,
      onChangeSendMail,
      onChangeDate,
      onChangeDateType,
    } = this.props;
    const changeTW = action === ChangeDateAction.CHANGETARGETWEEK;
    const changeETA = action === ChangeDateAction.CHANGEETA;
    const changeETD = action === ChangeDateAction.CHANGEETD;
    const originalDatesText = (
      <span
        className={order.targetDateType === DateType.FIX ? "text-warning fw-normal" : "text-muted fw-normal"}
        style={{ fontSize: "13px" }}
      >
        {order.targetDateType === DateType.CW ? (
          `Original Target Week: ${getCW(order.targetDate)}-${order.targetDate.getFullYear()}`
        ) : order.targetDateType === DateType.FIX ? (
          <>
            <span>Original Target Date: {formatDate(order.targetDate)} (fixed). </span>
            <span className="fw-bolder">Do not change a fixed date without permission!</span>
          </>
        ) : (
          `Original Target Date: ${formatDate(order.targetDate)}`
        )}
      </span>
    );

    return (
      <div className="mb-5 p-0">
        <div className="mb-4 p-0">
          <label className="fs-5 fw-bold mb-2">
            New{" "}
            {changeTW
              ? `Target ${changedDateType === DateType.CW ? "Week" : "Date"}`
              : changeETD
              ? "ETD"
              : changeETA
              ? "ETA"
              : "Date"}{" "}
            of Customer Order RB-
            {order.orderNo}
          </label>
          {changeTW || changeETA ? (
            <>
              {changedDateType === DateType.CW ? (
                <CalendarWeekSelector
                  onSelectCalendarWeek={(e: Date) => onChangeDate(orderId, e)}
                  value={changedDate}
                />
              ) : (
                <DateInput
                  classes="form-control custom-form-control"
                  name="delivery"
                  value={changedDate}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => onChangeDate(orderId, e)}
                />
              )}
              {originalDatesText}
              <div className="row mt-3">
                <div className="col-12 d-flex flex-row">
                  <div className="form-check form-check-sm form-check-custom form-check-solid me-5">
                    <input
                      className="form-check-input position-static"
                      checked={changedDateType === DateType.CW}
                      type="checkbox"
                      onChange={() => onChangeDateType(orderId, DateType.CW)}
                    />
                    <label className="form-check-label fs-6">Calendar Week</label>
                  </div>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input position-static"
                      checked={changedDateType === DateType.FIX}
                      type="checkbox"
                      disabled={order.transport === T_SEAFREIGHT}
                      onChange={() => onChangeDateType(orderId, DateType.FIX)}
                    />
                    <label className={`form-check-label fs-6 ${order.transport === T_SEAFREIGHT ? "text-muted" : ""}`}>
                      Fixed Date
                    </label>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <CalendarWeekSelector onSelectCalendarWeek={(e: Date) => onChangeDate(orderId, e)} value={changedDate} />
              {originalDatesText}
            </>
          )}
        </div>
        {changeETA && (
          <div className="form-check form-check-sm form-check-custom form-check-solid">
            <input
              className="form-check-input position-static"
              type="checkbox"
              checked={sendMail}
              onChange={() => onChangeSendMail(orderId)}
            />
            <label className="form-check-label fs-5">
              Send an email to the customer that the order will be delayed/changed.
            </label>
          </div>
        )}
      </div>
    );
  }
}

export default ChangedEtaCustomerOrderRows;
