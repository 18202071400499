import _ from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { paginate, PaginationState } from "../../common/Pagination";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import { SortOption, SORTORDEROPTIONS } from "../../../utils/filterUtils";
import { O_SORTOPTIONS } from "../../../utils/orderUtils";
import Search from "../../common/Search";
import BaseListing from "../../common/BaseListing";
import ContractRow from "./ContractRow";
import { DataContextInternal } from "../../../context/dataContext";
import { CC_SORTOPTIONS } from "../../../utils/customerContractUtils";
import { CC_STATE, CustomerContract } from "../../../model/customerContract.types";
import { CUSTOMERCONTRACT, getCollectionDBWithQuery } from "../../../services/dbService";
import { doFuseSearch } from "../../../utils/baseUtils";
import SplashScreen from "../../common/SimpleSplashScreen";

interface CanceledContractsState extends PaginationState {
  sortBy: SelectOption;
  sortOrder: SortOption;
  contracts?: Array<CustomerContract>;
  search: string;
  loading: boolean;
}

const getDefaultState = (): CanceledContractsState => {
  return {
    sortBy: O_SORTOPTIONS[0],
    sortOrder: SORTORDEROPTIONS[0],
    search: "",
    loading: false,
    currentPage: 1,
    pageSize: 25,
  };
};

const CanceledContracts: React.FC = () => {
  const context = useContext(DataContextInternal);

  const [state, setState] = useState<CanceledContractsState>(getDefaultState());
  const { contracts, sortBy, sortOrder, search, pageSize, currentPage, loading } = state;

  useEffect(() => {
    // Retrieve canceled contracts
    async function getContracts() {
      setState((prevState) => ({ ...prevState, loading: true }));
      try {
        const contracts = await getCollectionDBWithQuery<CustomerContract>(CUSTOMERCONTRACT, {
          state: CC_STATE.CANCELED,
        });
        setState((prevState) => ({ ...prevState, contracts }));
      } catch (e) {
        console.error("ERROR:", e);
        toast.error("Error loading canceled contract data");
      } finally {
        setState((prevState) => ({ ...prevState, loading: false }));
      }
    }
    getContracts();
  }, [context.customerContract]);

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    setState((prevState) => ({ ...prevState, search: e.target.value }));
  const handleChangeSortBy = (sortBy: SelectOption) => setState((prevState) => ({ ...prevState, sortBy }));
  const handleChangeSortOrder = (sortOrder: SortOption) => setState((prevState) => ({ ...prevState, sortOrder }));
  const handleChangeCurrentPage = (currentPage: number) => setState((prevState) => ({ ...prevState, currentPage }));
  const handleChangePageSize = (pageSize: number) => setState((prevState) => ({ ...prevState, pageSize }));

  const filteredContracts = useMemo(() => {
    if (!contracts) return [];
    if (!search.trim()) return contracts;
    return doFuseSearch(contracts, search, [
      "contractNo",
      "commodity.title.en",
      "customerReference",
      "state",
      "company.name",
    ]);
  }, [contracts, search]);

  const sortedContracts = useMemo(() => {
    if (filteredContracts.length < 2) return filteredContracts;
    return _.orderBy(filteredContracts, sortBy.value, sortOrder.value);
  }, [filteredContracts, sortBy, sortOrder]);

  const paginatedContracts = useMemo(() => {
    return paginate(sortedContracts, currentPage, pageSize);
  }, [sortedContracts, currentPage, pageSize]);

  const headerDefinition = [
    { title: "Contract", style: { width: "40%" } },
    {
      title: "Call-Offs",
      style: { width: "20%", whiteSpace: "nowrap" },
    },
    { title: "Information", style: { width: "25%" } },
    { title: "Progress", style: { width: "15%" } },
  ];

  return (
    <div className="content d-flex flex-column flex-column-fluid">
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl">
          <div className="card bg-white" style={{ minHeight: "100%" }}>
            <div className="card-body">
              <h3 className="card-title align-items-start flex-column mb-15">
                <span className="card-label fw-bolder mb-3 fs-3rem">Contracts</span>
                <div className="float-right">
                  <Link className="btn btn-outline btn-outline-light" to="/createCustomerContract">
                    New Contract
                  </Link>
                </div>
              </h3>
              <div className="row">
                <div className="col-12 col-md-6">
                  <Search placeholder="Search for contracts..." onSearch={handleChangeSearch} value={search} />
                </div>
                <div className="col-12 col-md-6 text-right">
                  <Link className="btn btn-sm btn-light" to="/contracts">
                    Active Contracts
                  </Link>
                </div>
              </div>
              <div className="row mt-6">
                <div className="col-12 col-md-6 col-lg-4">
                  <label className="fs-6 form-label fw-bolder text-dark">Sort by</label>
                  <CustomSelect options={CC_SORTOPTIONS} onChange={handleChangeSortBy} value={sortBy} />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <label className="fs-6 form-label fw-bolder text-dark">Sort order</label>
                  <CustomSelect options={SORTORDEROPTIONS} onChange={handleChangeSortOrder} value={sortOrder} />
                </div>
              </div>
              {loading ? (
                <SplashScreen description="Loading Data" withoutBorder={true} />
              ) : (
                <BaseListing
                  headerDefinition={headerDefinition}
                  bodyContent={
                    <>
                      {paginatedContracts.length > 0 ? (
                        paginatedContracts.map((pC) => <ContractRow key={pC._id.toString()} contract={pC} style={{}} />)
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={8}>
                            No contracts found
                          </td>
                        </tr>
                      )}
                    </>
                  }
                  documents={sortedContracts}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  baseSize={25}
                  onPageChange={handleChangeCurrentPage}
                  onPageSizeChange={handleChangePageSize}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanceledContracts;
