import React from "react";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import astronautAnimation from "../../assets/animation.json";

interface ListPlaceholderProps {
  type: string;
  buttonText?: string;
  overwriteText?: string;
}

const ListPlaceholder: React.FunctionComponent<ListPlaceholderProps> = ({ type, buttonText, overwriteText }) => {
  return (
    <>
      <Lottie animationData={astronautAnimation} loop={true} className="w-50 mx-auto mt-20" />
      <div className="my-20">
        <h1 className="mb-5">
          It's lonely out here in space... <br />
          just like your {type} list!
        </h1>
        <h6 className="text-muted">{overwriteText ?? `Once you placed your first ${type}, it will show up here.`}</h6>
        <Link to="/articles">
          <button className="btn btn-light btn-text mt-5">{buttonText ?? "Start now"}</button>
        </Link>
      </div>
    </>
  );
};

export default ListPlaceholder;
