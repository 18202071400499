import React from "react";
import { formatCurrency, round } from "../../utils/baseUtils";
import {
  getSupplierOrderCalculation,
  AirFreightPriceCalculation,
  AirFreightCalculationValues,
  SeaFreightPriceCalculation,
  SeaFreightCalculationValues,
  EUStockPriceCalculation,
  EUStockCalculationValues,
} from "../../utils/priceCalculationUtils";
import CreateSupplierOrderCalculationValuesModal from "./modals/CreateSupplierOrderCalculationValuesModal";
import { SelectedSupplier } from "../../utils/supplierOrderUtils";
import { Commodity } from "../../model/commodity.types";
import { FinishedProduct } from "../../model/finishedProduct.types";

interface CreateSupplierOrderCalculationProps {
  article: Commodity | FinishedProduct;
  totalTurnover: number;
  totalAmount: number;
  selectedSupplier: SelectedSupplier | null;
  currency: string;
  calculationDetails: SeaFreightPriceCalculation | AirFreightPriceCalculation | EUStockPriceCalculation | null;
  calculationValues: SeaFreightCalculationValues | AirFreightCalculationValues | EUStockCalculationValues;
  onSetCalculationValues: (
    calculationValues: SeaFreightCalculationValues | AirFreightCalculationValues | EUStockCalculationValues
  ) => void;
}

const getAmount = (amount: number, currency: string, negative?: boolean) => (
  <span className="text-white fw-bold">
    {negative ? "-" : "+"}
    {formatCurrency(amount, currency)}
  </span>
);

const CreateSupplierOrderCalculation: React.FunctionComponent<CreateSupplierOrderCalculationProps> = ({
  article,
  totalAmount,
  totalTurnover,
  selectedSupplier,
  calculationDetails,
  calculationValues,
  currency,
  onSetCalculationValues,
}) => {
  const calculation = getSupplierOrderCalculation(totalTurnover, calculationDetails);
  const percentageMargin = !calculation.totalTurnover
    ? 0
    : round((calculation.totalMargin / calculation.totalTurnover) * 100, 2);
  return (
    <>
      <div className="fw-bolder text-white fs-3 my-5">
        Calculation
        <CreateSupplierOrderCalculationValuesModal
          article={article}
          calculationDetails={calculationDetails}
          selectedSupplier={selectedSupplier}
          totalAmount={totalAmount}
          calculationValues={calculationValues}
          onSetCalculationValues={onSetCalculationValues}
          currency={currency}
        />
      </div>
      <div className="row my-1">
        <div className="col-6 text-left">
          <span className="text-muted">Total Turnover</span>
        </div>
        <div className="col-6 text-right">{getAmount(calculation.totalTurnover, currency)}</div>
      </div>
      <div className="row my-1">
        <div className="col-6 text-left">
          <span className="text-muted">Cost of Commodity</span>
        </div>
        <div className="col-6 text-right">{getAmount(calculation.commodityCost, currency, true)}</div>
      </div>
      {calculation.warehouseCost && (
        <div className="row my-1">
          <div className="col-6 text-left">
            <span className="text-muted">Additional Stock</span>
          </div>
          <div className="col-6 text-right">{getAmount(calculation.warehouseCost, currency)}</div>
        </div>
      )}
      <div className="row my-1">
        <div className="col-6 text-left">
          <span className="text-muted">Transport</span>
        </div>
        <div className="col-6 text-right">{getAmount(calculation.transportCost, currency, true)}</div>
      </div>
      <div className="row my-1">
        <div className="col-6 text-left">
          <span className="text-muted">Customs</span>
        </div>
        <div className="col-6 text-right">
          {calculation.customsCost ? (
            getAmount(calculation.customsCost, currency, true)
          ) : (
            <span className="text-white fw-bold">-</span>
          )}
        </div>
      </div>
      {calculation.warehouseHandlingCost && (
        <div className="row my-1">
          <div className="col-6 text-left">
            <span className="text-muted">Warehouse Handling</span>
          </div>
          <div className="col-6 text-right">{getAmount(calculation.warehouseHandlingCost, currency, true)}</div>
        </div>
      )}
      {calculation.totalInsuranceCost !== undefined && calculation.totalInsuranceCost > 0 && (
        <div className="row my-1">
          <div className="col-6 text-left">
            <span className="text-muted">Insurance</span>
          </div>
          <div className="col-6 text-right">{getAmount(calculation.totalInsuranceCost, currency, true)}</div>
        </div>
      )}
      <div className="row my-1">
        <div className="col-6 text-left">
          <span className="text-muted">Margin</span>
        </div>
        <div className="col-6 text-right">
          <span className={"fw-bold " + (calculation.totalMargin >= 0 ? "text-success" : "text-danger")}>
            {calculation.totalMargin >= 0 ? "+" : ""}
            {formatCurrency(calculation.totalMargin, currency)}
          </span>
        </div>
      </div>
      <div className="row my-1">
        <div className="col-6 text-left">
          <span className="text-muted">Margin %</span>
        </div>
        <div className="col-6 text-right">
          <span
            className={
              "fw-bold " +
              (percentageMargin >= 25 ? "text-success" : percentageMargin >= 10 ? "text-warning" : "text-danger")
            }
          >
            {`${percentageMargin}%`}
          </span>
        </div>
      </div>
    </>
  );
};

export default CreateSupplierOrderCalculation;
