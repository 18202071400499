import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";
import { ArticleGraduationType } from "../../../../model/configuration/graduationConfiguration.types";
import { Property } from "../../../../model/property.types";
import GraduationCard, { GraduationReference } from "./GraduationCard";
import { CALC_AIRFREIGHT, CALC_SEAFREIGHT } from "../../../../model/supplierOrder.types";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";

interface AddCategoryForGraduationModalState {
  selectedCategories: Array<SelectOption>;
  graduation: ArticleGraduationType;
  errors: Array<string>;
}

interface AddCategoryForGraduationModalProps {
  categories: Array<Property>;
  onAddCategories: (
    selectedCategories: Array<string>,
    graduationSea: Array<number>,
    graduationAir: Array<number>
  ) => void;
  onClose: () => void;
  onShow: () => void;
  show: boolean;
}

const AddCategoryForGraduationModal: React.FunctionComponent<AddCategoryForGraduationModalProps> = ({
  categories,
  onAddCategories,
  onClose,
  onShow,
  show,
}) => {
  const defaultState: AddCategoryForGraduationModalState = useMemo(() => {
    return {
      selectedCategories: [],
      graduation: { air: [], sea: [] },
      errors: [],
    };
  }, [show]);
  const [{ selectedCategories, graduation, errors }, setState] =
    useState<AddCategoryForGraduationModalState>(defaultState);

  useEffect(() => {
    if (show) {
      setState(defaultState);
    }
  }, [show]);

  useEffect(() => {
    validateData();
  }, [selectedCategories, graduation]);

  const handleSelection = useCallback((e: Array<SelectOption>) => {
    setState((prevState) => {
      return {
        ...prevState,
        selectedCategories: e,
      };
    });
  }, []);

  const handleChangeGraduation = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, idx: number, reference?: GraduationReference) => {
      if (!e.target.value || !reference) return;
      const graduationCopy = _.clone(graduation);
      if (reference.tab === CALC_SEAFREIGHT) {
        graduationCopy.sea[idx] = Number(e.target.value);
      } else {
        graduationCopy.air[idx] = Number(e.target.value);
      }
      setState((prevState) => {
        return {
          ...prevState,
          graduation: graduationCopy,
        };
      });
    },
    [graduation]
  );

  const handleAddGraduation = useCallback(
    (reference?: GraduationReference) => {
      if (!reference) return;
      const graduationCopy = _.clone(graduation);
      if (reference.tab === CALC_SEAFREIGHT) {
        graduationCopy.sea.push(0);
      } else {
        graduationCopy.air.push(0);
      }

      setState((prevState) => {
        return {
          ...prevState,
          graduation: graduationCopy,
        };
      });
    },
    [graduation]
  );

  const handleDeleteGraduationEntry = useCallback(
    (idx: number, reference?: GraduationReference) => {
      if (!reference) return;
      const graduationCopy = _.clone(graduation);
      if (reference.tab === CALC_SEAFREIGHT) {
        graduationCopy.sea.splice(idx, 1);
      } else {
        graduationCopy.air.splice(idx, 1);
      }
      setState((prevState) => {
        return {
          ...prevState,
          graduation: graduationCopy,
        };
      });
    },
    [graduation]
  );

  const handleDeleteGraduation = useCallback(
    (reference: GraduationReference) => {
      const graduationCopy = _.clone(graduation);
      if (reference.tab === CALC_SEAFREIGHT) {
        graduationCopy.sea = [];
      } else {
        graduationCopy.air = [];
      }
      setState((prevState) => {
        return {
          ...prevState,
          graduation: graduationCopy,
        };
      });
    },
    [graduation]
  );

  const validateData = () => {
    const errors: Array<string> = [];

    if (selectedCategories.length === 0) {
      errors.push("Please select at least one category");
    }

    if (graduation.air.length === 0 && graduation.sea.length === 0) {
      errors.push("Please add at least one graduation");
    }

    setState((prevState) => {
      return {
        ...prevState,
        errors: errors,
      };
    });
  };

  const graduationSeaReference: GraduationReference = useMemo(() => {
    return {
      type: "default",
      tab: CALC_SEAFREIGHT,
    };
  }, []);

  const graduationAirReference: GraduationReference = useMemo(() => {
    return {
      type: "default",
      tab: CALC_AIRFREIGHT,
    };
  }, []);

  return (
    <>
      <button className="btn btn-outline btn-outline-light" onClick={onShow}>
        Add Graduation
      </button>
      <Modal
        show={show}
        contentClassName={"bg-dark"}
        onHide={onClose}
        centered
        size="lg"
        name={"AddCategoryForGraduationModal"}
      >
        <Modal.Header className="border-0 pb-0">
          <Modal.Title>
            <h1 className="fw-bolder d-flex align-items-center text-white">Add Graduation for Category</h1>
          </Modal.Title>
          <CloseButton variant={"white"} onClick={onClose} />
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <label className="fs-6 fw-bold mb-1">Please select one or more categories</label>
            <CustomSelect
              options={categories.map((category) => {
                return { value: category._id.toString(), label: category.name.en };
              })}
              value={selectedCategories}
              onChange={handleSelection}
              matchFormControl={true}
              isMulti={true}
            />
          </div>
          <div className="card bg-white">
            <div className="row mt-5">
              <div className="col-6 rounded">
                <GraduationCard
                  title="Sea Freight"
                  reference={graduationSeaReference}
                  graduations={graduation.sea}
                  onChangeGraduation={handleChangeGraduation}
                  onAddGraduation={handleAddGraduation}
                  onDeleteGraduation={handleDeleteGraduationEntry}
                  isFullyDeletable
                >
                  <>
                    {graduation.sea.length > 0 && (
                      <button
                        className="btn btn-text-danger btn-sm float-right"
                        onClick={() => handleDeleteGraduation(graduationSeaReference)}
                      >
                        Delete
                      </button>
                    )}
                  </>
                </GraduationCard>
              </div>
              <div className="col-6 rounded">
                <GraduationCard
                  title="Air Freight"
                  reference={graduationAirReference}
                  graduations={graduation.air}
                  onChangeGraduation={handleChangeGraduation}
                  onAddGraduation={handleAddGraduation}
                  onDeleteGraduation={handleDeleteGraduationEntry}
                  isFullyDeletable
                >
                  <>
                    {graduation.air.length > 0 && (
                      <button
                        className="btn btn-text-danger btn-sm float-right"
                        onClick={() => handleDeleteGraduation(graduationAirReference)}
                      >
                        Delete
                      </button>
                    )}
                  </>
                </GraduationCard>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-light btn-sm " onClick={onClose}>
            Cancel
          </button>
          <ErrorOverlayButton
            errors={errors}
            className={"btn btn-light btn-sm"}
            buttonText={"Confirm"}
            onClick={() =>
              onAddCategories(
                selectedCategories.map((selC) => selC.value),
                graduation.sea,
                graduation.air
              )
            }
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddCategoryForGraduationModal;
