import React, { PureComponent } from "react";
import { toAbsoluteUrl } from "../../../utils/baseUtils";
import { collectFilesForOrder } from "../../../utils/orderUtils";
import { getDaysUntil } from "../../../utils/dateUtils";
import { resolveFilePath, shortenAlias } from "../../../utils/fileUtils";
import { getOrderStateRanking } from "../../../utils/customerOrderUtils";
import {
  CO_CANCELED,
  CO_REQUESTEDBYCUSTOMER,
  CO_STATES,
  CustomerOrderExtended,
} from "../../../model/customerOrder.types";
import {
  DataContextAnonymousType,
  DataContextCustomerType,
  DataContextInternalType,
  isAnonymousContext,
} from "../../../context/dataContext";
import { INTERNAL } from "../../../utils/userUtils";
import { getOrderInformation } from "./OrderHelper";
import Search from "../../common/Search";
import { CustomerCustomerOrderExtended } from "../../../model/customer/customerCustomerOrder.types";

interface OrderDetailsProps {
  order: CustomerOrderExtended | CustomerCustomerOrderExtended;
  hideDocuments?: boolean;
  context: DataContextInternalType | DataContextCustomerType | DataContextAnonymousType;
}

interface OrderDetailsState {
  search: string;
}

class OrderDetails extends PureComponent<OrderDetailsProps, OrderDetailsState> {
  constructor(props: OrderDetailsProps) {
    super(props);
    this.state = { search: "" };
  }

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });

  render() {
    const { order, hideDocuments, context } = this.props;
    const { search } = this.state;

    const files = collectFilesForOrder(order);
    const doneServices = order.services.filter((s) => s.performed).length;
    const openServices = order.services.length - doneServices;

    return (
      <div>
        <div className="d-flex py-3 align-items-center w-100 justify-content-between">
          <div className="me-3">
            <div className="text-white fs-3 fw-bolder">Order Information</div>
          </div>
          <div>
            <Search value={search} onSearch={this.handleSearch} />
          </div>
        </div>
        <div className="fs-6">
          <div className="d-flex flex-wrap py-5">
            <table className="table fw-bold gy-1">
              <tbody>
                {getOrderInformation(order, search, isAnonymousContext(context)).map((i, idx) => (
                  <React.Fragment key={idx}>{i}</React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {!hideDocuments && (
          <>
            <div className="d-flex py-3 align-items-center w-100">
              <div className="mr-3">
                <div className="text-white fs-5 fw-bolder">Files &amp; Documents</div>
                <div className="text-muted">
                  {files.length === 1 ? `1 file` : `${files.length} files`} for this order
                </div>
              </div>
            </div>
            <div className="card mt-2 bg-light2 rounded">
              <div className="card-body pt-8">
                <div className="d-flex flex-column">
                  {files.length === 0 ? (
                    <div className={"d-flex align-items-center mx-auto text-muted"}>No files available</div>
                  ) : (
                    files
                      .slice()
                      .reverse()
                      .map((f, idx) => {
                        const days = Math.abs(getDaysUntil(f.date, true));
                        const totalNoDocs = files.filter((file) => f.title === file.title).length;
                        if (f.version === totalNoDocs || f.title === `Specification ${order.commodity.title.en}`) {
                          return (
                            <div
                              key={f._id.toString()}
                              className={"d-flex align-items-center " + (idx < files.length - 1 && "mb-5")}
                            >
                              <div className="symbol symbol-30px mr-4">
                                <img alt="pdf" src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} />
                              </div>
                              <div className="fw-bold">
                                <a
                                  className="fs-6 fw-bolder custom-link"
                                  href={resolveFilePath(f.link)}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {f.title}
                                </a>
                                <div className="text-gray-400">
                                  {days === 0 ? "Today" : days === 1 ? `${days} day ago` : `${days} days ago`}
                                </div>
                              </div>
                              <button
                                type="button"
                                disabled={true}
                                className=" d-none btn btn-sm btn-icon btn-active-light-primary ml-auto"
                              >
                                <i className="fa fa-download" />
                              </button>
                            </div>
                          );
                        }
                      })
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        {context.type === INTERNAL && order.services.length > 0 && (
          <>
            <div className="d-flex py-3 mt-10 align-items-center w-100">
              <div className="mr-3">
                <div className="text-white fs-5 fw-bolder">Services</div>
                <div className="text-muted">
                  {doneServices} {doneServices === 1 ? "service" : "services"} completed, {openServices}{" "}
                  {openServices === 1 ? "service" : "services"} open
                </div>
              </div>
            </div>
            {order.services.map((s) => (
              <div key={s.service._id.toString()} className="row py-2">
                <div className="bg-light2 rounded p-5 ">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1 me-2">
                      <span className="fw-bolder text-gray-800 fs-6 flex-center flex-stack ">
                        {([CO_CANCELED, CO_REQUESTEDBYCUSTOMER] as Array<CO_STATES>).includes(
                          order.state
                        ) ? null : s.performed ? (
                          <i className="fas fa-check-circle text-success mr-2" style={{ fontSize: "1.4rem" }} />
                        ) : getOrderStateRanking(order) > 6 ? (
                          <i className="fas fa-clock text-warning mr-2" style={{ fontSize: "1.4rem" }} />
                        ) : (
                          ""
                        )}
                        <span>
                          RBS-{s.service.serviceNo} {s.service.title.en}
                        </span>
                      </span>
                      <span className="text-muted fw-bold d-block">{s.service.description.en}</span>
                    </div>
                    <div className="text-right">
                      <div className="fw-bold fs-6 text-gray-400">
                        {s.performed ? "Done" : `${s.service.duration} day(s) duration`}
                      </div>
                    </div>
                  </div>
                  {s.performed && (
                    <div className="mt-2 fs-6">
                      <div>
                        {s.files.length > 0 ? (
                          s.files.map((d) => (
                            <span key={d._id.toString()} className="p-0 mr-2" style={{ whiteSpace: "nowrap" }}>
                              <img
                                alt="pdf"
                                className="mr-2"
                                style={{ height: 18, width: 18 }}
                                src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                              />
                              <a
                                key={d._id.toString()}
                                className="text-white custom-link fw-bold mt-2"
                                href={resolveFilePath(d.path)}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {shortenAlias(d.path)}
                              </a>
                            </span>
                          ))
                        ) : (
                          <span className="text-muted fw-bold ml-3">No files available</span>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    );
  }
}

export default OrderDetails;
