import React, { useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

interface HoverPopoverProps {
  content: JSX.Element;
  children: JSX.Element | string;
  popoverStyle?: React.CSSProperties;
}

const HoverPopover: React.FunctionComponent<HoverPopoverProps> = ({ content, children, popoverStyle }) => {
  const [showPopover, setShowPopover] = useState(false);
  let timer: any = null;

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  });

  const handleMouseEnter = () => {
    timer = setTimeout(() => {
      setShowPopover(true);
    }, 0);
  };

  const handleMouseLeave = () => {
    clearTimeout(timer);
    setShowPopover(false);
  };
  return (
    <OverlayTrigger
      placement="top"
      show={showPopover}
      overlay={
        <Popover style={popoverStyle} onMouseEnter={() => setShowPopover(true)} onMouseLeave={handleMouseLeave}>
          {content}
        </Popover>
      }
    >
      <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </span>
    </OverlayTrigger>
  );
};

export default HoverPopover;
