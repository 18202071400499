import _ from "lodash";
import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { CloseButton, Modal } from "react-bootstrap";
import { Address, AddressType } from "../../../model/commonTypes";
import { SelectOption } from "../../common/CustomSelect";
import { getDefaultAddress, isAddressTooShort } from "../../../utils/addressUtils";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import AddressInputGroup from "../../common/AddressInputGroup";
import userService from "../../../services/userService";
import { COMPANY, transaction, UpdateAction } from "../../../services/dbService";
import { DataContextAnonymousType, DataContextCustomerType } from "../../../context/dataContext";
import { UserData } from "../../../model/userData.types";
import { getDocFromCollection } from "../../../utils/baseUtils";

interface CreateAddressModalProps {
  onHide: (address: Address) => void;
  context: DataContextCustomerType | DataContextAnonymousType;
}

interface CreateAddressModalState {
  address: Address;
  allPersons: Array<UserData>;
  show: boolean;
  saving: boolean;
}

class CreateAddressModal extends PureComponent<CreateAddressModalProps, CreateAddressModalState> {
  constructor(props: CreateAddressModalProps) {
    super(props);
    this.state = {
      show: false,
      saving: false,
      address: getDefaultAddress(),
      allPersons: [],
    };
  }

  componentDidMount() {
    const { context } = this.props;
    const company = getDocFromCollection(context.company, userService.getCompany());
    if (!company) return;
    const allPersons = [company.primaryPerson, ...company.persons];
    this.setState({ allPersons: allPersons.map((p) => getDocFromCollection(context.userData, p) as UserData) });
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState({ show: false });

  handleChangeAddressValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const address = _.cloneDeep(this.state.address);
    // @ts-ignore
    address[e.target.name as keyof Address] = e.target.value;
    this.setState({ address });
  };

  handleSelectAddressValueType = (e: SelectOption) => {
    const address = _.cloneDeep(this.state.address);
    address.type = e.value as AddressType;
    this.setState({ address });
  };

  handleSelectCountry = (e: SelectOption) => {
    const address = _.cloneDeep(this.state.address);
    address.country = e.value;
    this.setState({ address });
  };

  handleSelectAddressContact = (e: SelectOption) => {
    const address = _.cloneDeep(this.state.address);
    address.contactPerson = e.value;
    this.setState({ address });
  };

  handleSelectAddressProperty = (e: Array<SelectOption>) => {
    const address = _.cloneDeep(this.state.address);
    address.properties = e.map((option) => option.value);
    this.setState({ address });
  };

  handleAddAddress = async () => {
    const { address } = this.state;
    const companyId = userService.getCompany();
    if (!companyId) return;
    try {
      this.setState({ saving: true });
      const actions: Array<UpdateAction> = [];
      // In case new address is primary, set other primary address to other
      if (address.type === AddressType.A_PRIMARY) {
        const action: UpdateAction = {
          collection: COMPANY,
          filter: { _id: new BSON.ObjectId(companyId) },
          update: { "address.$[add].type": AddressType.A_OTHER },
          arrayFilters: [{ "add.type": AddressType.A_PRIMARY }],
        };
        actions.push(action);
      }
      const action: UpdateAction = {
        collection: COMPANY,
        filter: { _id: new BSON.ObjectId(companyId) },
        push: { address },
      };
      actions.push(action);

      const res = await transaction(actions);
      if (res) {
        toast.success("Address successfully added");
        this.setState({ show: false }, () => this.props.onHide(address));
      } else toast.error("Error adding address. Please try again later.");
    } catch (e) {
      console.error("Address could not be added:", e);
    } finally {
      this.setState({ saving: false });
    }
  };

  validateData = () => {
    const { address } = this.state;
    const errors: Array<string> = [];
    if (isAddressTooShort(address)) errors.push("Address too short");
    if (!address.contactPerson) errors.push("No contact person selected");
    return errors;
  };

  render() {
    const { context } = this.props;
    const { show, address, saving, allPersons } = this.state;
    return (
      <>
        <button className="btn btn-light btn-sm" onClick={this.handleShow}>
          +
        </button>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Add New Address</h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-5 ">
              <div className="col-md-12 mt-5">
                <label className="fs-6 fw-bold mb-1">Address</label>
                <AddressInputGroup
                  address={address}
                  context={context}
                  persons={allPersons}
                  onChangeAddress={this.handleChangeAddressValue}
                  onChangeAddressType={this.handleSelectAddressValueType}
                  onChangeAddressCountry={this.handleSelectCountry}
                  onChangeAddressContact={this.handleSelectAddressContact}
                  onChangeAddressProperty={this.handleSelectAddressProperty}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-light" onClick={this.handleHide}>
              Close
            </button>
            <ErrorOverlayButton
              errors={this.validateData()}
              saving={saving}
              className={"btn btn-sm btn-light"}
              buttonText={"Add & Select Address"}
              onClick={this.handleAddAddress}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreateAddressModal;
