import React from "react";
import { SeaFreightCostObject, SeaFreightValues } from "../../../../model/configuration/calculationConfiguration.types";
import { Input } from "../../../common/Input";
import { CONTAINER_LABEL } from "../../../../utils/seaportUtils";
import { ContainerCost } from "../../../../model/seaport.types";
import { RangeCostObjectList } from "./common/RangeCostObjectList";
import { CostObjectList } from "./common/CostObjectList";

interface SeaFreightValuesConfigurationProps {
  seaFreightValues: SeaFreightValues;
  onChangeSeaFreightValues: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SeaFreightValuesConfiguration: React.FunctionComponent<SeaFreightValuesConfigurationProps> = ({
  seaFreightValues,
  onChangeSeaFreightValues,
}) => {
  const { seaFreightCost, defaultSeaportFreightCost, minimumMargin, insurance } = seaFreightValues;
  const { fcl, lcl } = seaFreightCost;
  const containerCost = Object.entries(defaultSeaportFreightCost.cost.containerCost) as Array<
    [key: keyof ContainerCost, cost: number]
  >;
  return (
    <>
      <h3 className="mb-3 mt-7">Base Values</h3>
      <div className="row">
        <div className="col-xl-4">
          <label className="fs-5 fw-bold mb-2">Minimum Total Margin</label>
          <div className="input-group">
            <Input
              className="form-control custom-form-control"
              type={"number"}
              name={"minimumMargin.value"}
              min={0}
              value={minimumMargin.value}
              onChange={onChangeSeaFreightValues}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                {minimumMargin.currency}
              </div>
            </div>
          </div>
        </div>
      </div>
      <h3 className="mb-3 mt-7">Fallback Seaport Cost</h3>
      <div className="row">
        <div className="col-xl-3">
          <label className="fs-5 fw-bold mb-2">Sea Freight Cost Per Ton/m³</label>
          <div className="input-group">
            <Input
              className="form-control custom-form-control"
              type={"number"}
              name={"defaultSeaportFreightCost.cost.cost"}
              min={0}
              value={defaultSeaportFreightCost.cost.cost}
              onChange={onChangeSeaFreightValues}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                {defaultSeaportFreightCost.currency}
              </div>
            </div>
          </div>
        </div>
        {containerCost.map(([container, cost]) => (
          <div className="col-xl-3 " key={container}>
            <label className="fs-5 fw-bold mb-2">Sea Freight Cost Per {CONTAINER_LABEL[container]} Container</label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type={"number"}
                min={0}
                name={`defaultSeaportFreightCost.cost.containerCost.${container}`}
                value={cost}
                onChange={onChangeSeaFreightValues}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  {defaultSeaportFreightCost.currency}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <h3 className="mb-3 mt-7">FCL Freight Cost</h3>
      <CostObjectList
        cost={fcl.additionalFreightCost}
        onChangeValues={onChangeSeaFreightValues}
        objectPath={"seaFreightCost.fcl.additionalFreightCost"}
      />
      <h3 className="mb-3 mt-7">FCL Follow-Up Cost</h3>
      <CostObjectList
        cost={fcl.followUpCost}
        onChangeValues={onChangeSeaFreightValues}
        objectPath={"seaFreightCost.fcl.followUpCost"}
      />
      <h3 className="mb-3 mt-7">LCL Freight Cost</h3>
      <SeaFreightCostObjectList
        cost={lcl.additionalFreightCost}
        onChangeSeaFreightValues={onChangeSeaFreightValues}
        objectPath={"seaFreightCost.lcl.additionalFreightCost"}
      />
      <h3 className="mb-3 mt-7">Insurance</h3>
      <div className="row">
        <div className="col-xl-4">
          <label className="fs-5 fw-bold mb-2">Minimum Cost</label>
          <div className="input-group">
            <Input
              className="form-control custom-form-control"
              type="number"
              name="insurance.min.value"
              value={insurance.min.value}
              onChange={onChangeSeaFreightValues}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                {insurance.min.currency}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4">
          <label className="fs-5 fw-bold mb-2">Percentage</label>
          <div className="input-group">
            <Input
              className="form-control custom-form-control"
              type="number"
              name="insurance.insuranceValue"
              value={insurance.insuranceValue}
              onChange={onChangeSeaFreightValues}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                %
              </div>
            </div>
          </div>
        </div>
      </div>
      <h3 className="mb-3 mt-7">LCL Follow-Up Cost</h3>
      <RangeCostObjectList
        cost={lcl.followUpCost}
        onChangeValues={onChangeSeaFreightValues}
        objectPath={"seaFreightCost.lcl.followUpCost"}
      />
    </>
  );
};

interface SeaFreightCostObjectListProps {
  cost: Array<SeaFreightCostObject>;
  objectPath: string;
  onChangeSeaFreightValues: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SeaFreightCostObjectList: React.FunctionComponent<SeaFreightCostObjectListProps> = ({
  cost,
  objectPath,
  onChangeSeaFreightValues,
}) => {
  let size = 3;
  if (cost.some((c) => c.costPerCBM !== undefined)) size++;

  return (
    <div className="row">
      <div className={"col-auto "} style={{ width: `${100 / size}%` }}>
        <label className="fs-5 fw-bold mb-2">Description</label>
      </div>
      <div className={"col-auto "} style={{ width: `${100 / size}%` }}>
        <label className="fs-5 fw-bold mb-2">Cost Per Unit</label>
      </div>
      {size === 4 && (
        <div className={"col-auto "} style={{ width: `${100 / size}%` }}>
          <label className="fs-5 fw-bold mb-2">Cost Per m³</label>
        </div>
      )}
      <div className={"col-auto "} style={{ width: `${100 / size}%` }}>
        <label className="fs-5 fw-bold mb-2">Minimum Cost</label>
      </div>
      {cost.map((costObject, index) => (
        <SeaFreightCostObjectConfiguration
          key={costObject.description}
          objectPath={objectPath}
          costObject={costObject}
          onChangeSeaFreightValues={onChangeSeaFreightValues}
          index={index}
          size={size}
        />
      ))}
    </div>
  );
};

interface SeaFreightCostObjectConfigurationProps {
  costObject: SeaFreightCostObject;
  onChangeSeaFreightValues: (e: React.ChangeEvent<HTMLInputElement>) => void;
  objectPath: string;
  size: number;
  index: number;
}

const SeaFreightCostObjectConfiguration: React.FunctionComponent<SeaFreightCostObjectConfigurationProps> = ({
  costObject,
  onChangeSeaFreightValues,
  objectPath,
  index,
  size,
}) => {
  return (
    <>
      <div className={"col-auto " + (index > 0 && "mt-2")} style={{ width: `${100 / size}%` }}>
        <Input
          className="form-control custom-form-control"
          type={"text"}
          min={0}
          name={`${objectPath}.${index}.description`}
          value={costObject.description}
          onChange={onChangeSeaFreightValues}
        />
      </div>
      <div className={"col-auto " + (index > 0 && "mt-2")} style={{ width: `${100 / size}%` }}>
        <div className="input-group">
          <Input
            className="form-control custom-form-control"
            type={"number"}
            min={0}
            name={`${objectPath}.${index}.costPerWeight`}
            value={costObject.costPerWeight}
            onChange={onChangeSeaFreightValues}
          />
          <div className="input-group-append rounded-end bg-custom-light-gray">
            <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
              {costObject.currency}/{costObject.weightUnit}
            </div>
          </div>
        </div>
      </div>
      {size === 4 && (
        <div className={"col-auto " + (index > 0 && "mt-2")} style={{ width: `${100 / size}%` }}>
          <div className="input-group">
            <Input
              className="form-control custom-form-control"
              type={"number"}
              min={0}
              name={`${objectPath}.${index}.costPerCBM`}
              value={costObject.costPerCBM || 0}
              onChange={onChangeSeaFreightValues}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                {costObject.currency}/m³
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={"col-auto " + (index > 0 && "mt-2")} style={{ width: `${100 / size}%` }}>
        <div className="input-group">
          <Input
            className={"form-control custom-form-control " + (costObject.minimum === undefined && "disabled")}
            type={"number"}
            min={0}
            disabled={costObject.minimum === undefined}
            name={`${objectPath}.${index}.minimum`}
            value={costObject.minimum || 0}
            onChange={onChangeSeaFreightValues}
          />
          <div className="input-group-append rounded-end bg-custom-light-gray">
            <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
              {costObject.currency}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SeaFreightValuesConfiguration;
