import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import SupplierOrderDestinations from "./SupplierOrderDestinations";
import SupplierOrderSummary from "./SupplierOrderSummary";
import SupplierOrderTabPanel from "./SupplierOrderTabPanel";
import { DataContextInternalType } from "../../../../context/dataContext";
import { SupplierOrder, SupplierOrderExtended } from "../../../../model/supplierOrder.types";
import { getDocFromCollection } from "../../../../utils/baseUtils";
import { getDocumentDB, SUPPLIERORDER } from "../../../../services/dbService";

import { extendSupplierOrder } from "../../../../utils/dataTransformationUtils";
import SplashScreen from "../../../common/SimpleSplashScreen";

interface SupplierOrderParams {
  id: string;
}

interface SupplierOrderPageProps extends RouteComponentProps<SupplierOrderParams> {
  context: DataContextInternalType;
}

interface SupplierOrderPageState {
  order?: SupplierOrderExtended;
}

class SupplierOrderPage extends PureComponent<SupplierOrderPageProps, SupplierOrderPageState> {
  _isMounted = false;
  constructor(props: SupplierOrderPageProps) {
    super(props);
    this.state = { order: this.getSupplierOrder(props) };
  }

  componentDidMount = async () => {
    const { match, history, context } = this.props;
    if (this.state.order) return;
    const id = match.params.id;
    if (!id || !BSON.ObjectId.isValid(id)) {
      history.push("/orders");
      return;
    }
    this._isMounted = true;
    const order = await getDocumentDB<SupplierOrder>(SUPPLIERORDER, id);
    if (!order) {
      console.error("No order could be loaded for id", id);
      toast.error("The requested order could not be loaded");
      history.push("/orders");
      return;
    }
    context.addDocuments(SUPPLIERORDER, [order]);
    const orderExtended = extendSupplierOrder(order, context);
    if (this._isMounted) this.setState({ order: orderExtended });
  };

  componentDidUpdate(prevProps: Readonly<SupplierOrderPageProps>) {
    const { match } = this.props;
    if (match.params.id || (!match.params.id && prevProps.match.params.id)) {
      const order = this.getSupplierOrder(this.props);
      if (order && !_.isEqual(order, this.state.order)) {
        this.setState({ order });
      }
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  /**
   * Retrieve the supplier order that it referenced by the ID inside the URL
   * @param props Props of the component - needed since the function is called inside constructor
   * @returns {SupplierOrderExtended | undefined}
   */
  getSupplierOrder = (props: SupplierOrderPageProps): SupplierOrderExtended | undefined => {
    const { context, match } = props;
    const order = getDocFromCollection(context.supplierOrder, match.params.id);
    if (order) return extendSupplierOrder(order, context);
  };

  render() {
    const { context } = this.props;
    const { order } = this.state;
    if (!order) return <SplashScreen description="Loading Supplier Order..." />;

    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="d-flex flex-column flex-xl-row">
              <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
                <SupplierOrderSummary order={order} context={context} />
                <SupplierOrderDestinations order={order} context={context} />
              </div>
              <SupplierOrderTabPanel order={order} context={context} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SupplierOrderPage;
