import _ from "lodash";
import Fuse from "fuse.js";
import countries from "i18n-iso-countries";
import React from "react";
import { BSON } from "realm-web";
import { callFunction } from "../services/dbService";
import { DataContextType } from "../context/dataContext";

export const SMALL_SPACE_UNI = "\u202F";

export enum AppDesign {
  RAWBIDS = "rawbids",
  ARK = "ark",
}

export const PUSHTOARRAY = "pushToArray";

/**
 * Calls the push to array function in the backend.
 * @param collection the collection of the document
 * @param documentId id of the document to update
 * @param key key of the field to push to
 * @param item item or list of items to push to array
 * @returns {Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false>} Result of the push update
 */
export async function callPushToArray(
  collection: string,
  documentId: string | BSON.ObjectId,
  key: string | Array<string>,
  item: object | Array<object> | Array<Array<object>>
): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> {
  return await callFunction<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false>(PUSHTOARRAY, [
    collection,
    documentId,
    key,
    item,
  ]);
}

/**
 * Gets a document from a given collection
 * @param collection the collection to search
 * @param id the objectId to search for
 * @returns {T | undefined} found document or undefined
 */
export function getDocFromCollection<T extends { _id: BSON.ObjectId }>(
  collection: Array<T>,
  id: BSON.ObjectId | string
): T | undefined {
  return collection.find((d: T) => d._id.toString() === id.toString());
}

/**
 * Execute Fuse search
 * @param documents list of documents to filter/search
 * @param query the search query
 * @param keys keys of a document to compare
 * @param additionalOptions additional Fuse Options
 * @returns {Array<T>} list of filtered documents
 */
export function doFuseSearch<T>(
  documents: Array<T>,
  query: string,
  keys: Array<string>,
  additionalOptions?: Fuse.IFuseOptions<T>
): Array<T> {
  let options: Fuse.IFuseOptions<T> = {
    includeScore: true,
    includeMatches: true,
    ignoreLocation: true,
    threshold: 0.2,
    keys: keys,
    useExtendedSearch: true,
  };
  if (additionalOptions) options = _.merge(options, additionalOptions);
  const fuse = new Fuse(documents.slice(), options);
  const result = fuse.search(query);
  return result.map((res) => res.item);
}

/**
 * Format a date to default preferred format.
 * @param date Date that should be formatted
 * @returns {string} Formatted date
 */
export function formatDate(date: Date): string {
  return date.toLocaleDateString(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
}

/**
 * Format a date to default preferred format.
 * @param date Date that should be formatted
 * @param seconds Optional, if set seconds are also shown
 * @returns {string} Formatted date
 */
export function formatDateTime(date: Date, seconds?: boolean): string {
  return date.toLocaleDateString(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    second: seconds ? "2-digit" : undefined,
  });
}

/**
 * Format a value to the specified currency
 * @param value A numeric value
 * @param currency A currency string
 * @returns {string} Currency string
 */
export function formatCurrency(value: number | string, currency: string): string {
  return (+value).toLocaleString(undefined, {
    style: "currency",
    currency,
  });
}

/**
 * Format a value with the specific unit
 * @param value A numeric value
 * @param unit Any unit
 * @returns {string} formatted string
 */
export function formatUnit(value: number | string, unit: string): string {
  return `${value.toLocaleString()}${SMALL_SPACE_UNI}${unit}`;
}

/**
 * Get and properly convert number values from a change event
 * @param e Input change event
 * @param allowNegative Allows negative values in the input field
 * @returns {undefined | string} converted number without leading zeros etc.
 */
export const getNumericValue = (
  e: React.ChangeEvent<HTMLInputElement>,
  allowNegative?: boolean
): undefined | string => {
  let value = e.target.value.replaceAll(/^0+/g, "0");
  if (!Number(value) && Number(value) !== 0) return;
  if (!allowNegative && Number(value) < 0) value = Math.abs(Number(value)).toString();
  if (!value.includes(".")) value = Number(value).toString();
  return value;
};

export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname;

/**
 * Transform a list of object to a list of objects only containing the _id property
 * @param list list to transform
 * @returns {Array<{_id: string}>} list of ids
 */
export function toIdObjectList<T extends { _id: BSON.ObjectId | string }>(list: Array<T>): Array<{ _id: string }> {
  return list.map((e) => {
    return { _id: e._id.toString() };
  });
}

/**
 * Get the country name for a commodity
 * @param code a country code
 * @param locale optional, language parameter
 * @returns {string} Name of the commodity origin country
 */
export const getCountryNameForCode = (code: string, locale?: string): string => {
  return countries.getName(code, locale ?? "en", { select: "alias" }) || "";
};

/**
 * Concat value and a word. If value is 1 the singular of the word is used otherwise an s is added for plural
 * @param value any numerical value
 * @param singularWord any word which plural form is the singular word extended with an s
 * @returns {string} concatenated singular or plural version of the value and word
 */
export const pluralize = (value: number, singularWord: string): string => {
  return value === 1 ? `${value} ${singularWord}` : `${value} ${singularWord}s`;
};

/**
 * Round a number to the give amount of decimal digits
 * @param value the value to round
 * @param digits amount of desired decimal digits
 * @returns {number} rounded number
 */
export const round = (value: number, digits: number): number => {
  const factor = Math.pow(10, digits);
  return Math.round(value * factor) / factor;
};

/**
 * Ceil a number to the give amount of decimal digits
 * @param value the value to round
 * @param digits amount of desired decimal digits
 * @returns {number} ceiled number
 */
export const ceil = (value: number, digits: number): number => {
  const factor = Math.pow(10, digits);
  return Math.ceil(value * factor) / factor;
};

/**
 * Count the decimals of a number
 * @param value any number
 * @returns {number | string} the number of decimals
 */
export const countDecimals = (value: number): number | string => {
  if (Math.floor(value.valueOf()) === value.valueOf()) return 0;

  const str = value.toString();
  if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
    return str.split("-")[1] || 0;
  } else if (str.indexOf(".") !== -1) {
    return str.split(".")[1].length || 0;
  }
  return str.split("-")[1] || 0;
};

/**
 * Get saved state from context
 * @param context the data context or external manufacturer context
 * @param key the key, i.e. the component name to get the state for
 * @returns {object | null} the saved components state or null if the state is too old or not existing
 */
export function getComponentState(context: DataContextType, key: string): object | null {
  const { savedState } = context;
  const now = new Date().getTime();
  const state = key in savedState ? savedState[key] : null;
  // Check if state is older than 2 minutes
  if (state && now - state.date.getTime() < 2 * 60 * 1000) return state.state;
  return null;
}

/**
 * Truncate the given text when it passed the defined length.
 * @param text Text that should be truncated
 * @param length Maximum text length - due to appending the dots we truncate three characters earlier
 * @returns {string} Truncated string
 */
export function truncateString(text: string, length: number): string {
  if (text.length <= length - 3) return text;
  return text.slice(0, length - 3) + "...";
}
