import { SupplierSupplier, SupplierSupplierExtended } from "../model/supplier/supplierSupplier.types";
import { Supplier, SupplierExtended } from "../model/supplier.types";
import {
  DataContextAnonymousType,
  DataContextCustomerType,
  DataContextInternalType,
  DataContextSupplierType,
  DataContextType,
} from "../context/dataContext";
import { UserData } from "../model/userData.types";
import { getDocFromCollection } from "./baseUtils";
import { CustomerContract, CustomerContractExtended } from "../model/customerContract.types";
import {
  CustomerCustomerContract,
  CustomerCustomerContractExtended,
} from "../model/customer/customerCustomerContract.types";
import { Company, CompanyExtended } from "../model/company.types";
import { CustomerOrder, CustomerOrderExtended } from "../model/customerOrder.types";
import { CustomerCustomerOrder, CustomerCustomerOrderExtended } from "../model/customer/customerCustomerOrder.types";
import { SupplierOrder, SupplierOrderExtended } from "../model/supplierOrder.types";
import {
  Commodity,
  CommodityExtended,
  SupplierPrices,
  SupplierPricesExtended,
  UploadedFile,
  UploadedFileExtended,
} from "../model/commodity.types";
import {
  CustomerCommodity,
  CustomerCommodityExtended,
  CustomerSupplierPricesExtended,
} from "../model/customer/customerCommodity.types";
import { Property } from "../model/property.types";
import { ActiveSubstance } from "../model/activeSubstance.types";
import { SupplierCommodityExtended, SupplierSupplierPricesExtended } from "../model/supplier/supplierCommodity.types";
import {
  FinishedProduct,
  FinishedProductExtended,
  SupplierPricesFinishedProduct,
  SupplierPricesFinishedProductExtended,
} from "../model/finishedProduct.types";
import {
  CustomerFinishedProduct,
  CustomerFinishedProductExtended,
} from "../model/customer/customerFinishedProduct.types";
import { SupplierFinishedProductExtended } from "../model/supplier/supplierFinishedProduct.types";
import { Forwarder, ForwarderExtended } from "../model/forwarder.types";

/* ### SUPPLIER ### */
/**
 * Extends the common fields in suppliers.
 * @param supplier Supplier document in database representation
 * @param context Data context - needed to resolve user data
 * @returns { {internalContact: UserData, primaryPerson: UserData, persons: Array<UserData>} } All resolved common fields
 */
function extendCommonSupplierFields(
  supplier: Supplier | SupplierSupplier,
  context: DataContextType
): { internalContact: UserData; primaryPerson: UserData; persons: Array<UserData> } {
  const internalContact = getDocFromCollection(context.userData, supplier.internalContact)!;
  const primaryPerson = getDocFromCollection(context.userData, supplier.primaryPerson)!;
  const persons: Array<UserData> = [];
  for (let i = 0; i < supplier.persons.length; i++) {
    const p = getDocFromCollection(context.userData, supplier.persons[i]);
    if (p) persons.push(p);
  }
  return { internalContact, primaryPerson, persons };
}

/**
 * Extends an internal supplier document.
 * @param supplier Supplier document in internal database representation
 * @param context Data context - needed to resolve data
 * @returns {SupplierExtended} Extended internal supplier document with resolved data
 */
export function extendSupplier(supplier: Supplier, context: DataContextType): SupplierExtended {
  const { internalContact, primaryPerson, persons } = extendCommonSupplierFields(supplier, context);
  return { ...supplier, internalContact, primaryPerson, persons };
}

/**
 * Extends an supplier supplier document.
 * @param supplier Supplier document in supplier database representation
 * @param context Data context - needed to resolve data
 * @returns {SupplierSupplierExtended} Extended supplier supplier document with resolved data
 */
export function extendSupplierSupplier(supplier: SupplierSupplier, context: DataContextType): SupplierSupplierExtended {
  const { internalContact, primaryPerson, persons } = extendCommonSupplierFields(supplier, context);
  return { ...supplier, internalContact, primaryPerson, persons };
}

/**
 * Reduce the common fields of a supplier document.
 * @param supplier Supplier document that should be resolved
 * @returns { {internalContact: string, primaryPerson: string, persons: Array<string>} } Reduced common fields
 */
function reduceCommonSupplierFields(supplier: SupplierExtended | SupplierSupplierExtended): {
  internalContact: string;
  primaryPerson: string;
  persons: Array<string>;
} {
  const internalContact = supplier.internalContact._id.toString();
  const primaryPerson = supplier.primaryPerson._id.toString();
  const persons: Array<string> = [];
  for (let i = 0; i < supplier.persons.length; i++) {
    persons.push(supplier.persons[i]._id.toString());
  }
  return { internalContact, primaryPerson, persons };
}

/**
 * Reduce an extended internal supplier document.
 * @param supplier Supplier document that should be reduced
 * @returns {Supplier} Reduced internal supplier document
 */
export function reduceSupplier(supplier: SupplierExtended): Supplier {
  return { ...supplier, ...reduceCommonSupplierFields(supplier) };
}

/**
 * Reduce an extended supplier supplier document.
 * @param supplier Supplier document that should be reduced
 * @returns {SupplierSupplier} Reduced supplier supplier document
 */
export function reduceSupplierSupplier(supplier: SupplierSupplierExtended): SupplierSupplier {
  return { ...supplier, ...reduceCommonSupplierFields(supplier) };
}

/* ### CONTRACT ### */
/**
 * Extend the common fields of customer contracts.
 * @param customerContract Customer contract document
 * @param context Data context - needed to resolve data
 * @returns { {company: Company, person: UserData} }
 */
function extendCommonCustomerContractFields(
  customerContract: CustomerContract | CustomerCustomerContract,
  context: DataContextInternalType | DataContextCustomerType
): { company: Company; person: UserData } {
  const company = getDocFromCollection(context.company, customerContract.company) as Company;
  const person = getDocFromCollection(context.userData, customerContract.person) as UserData;
  return { company, person };
}

/**
 * Extend an internal customer contract document
 * @param customerContract Customer contract that should be extended
 * @param context Data context - needed to resolve data
 * @returns {CustomerContractExtended} Extended internal customer contract
 */
export function extendCustomerContract(
  customerContract: CustomerContract,
  context: DataContextInternalType
): CustomerContractExtended {
  const supplier = customerContract.supplier
    ? getDocFromCollection(context.supplier, customerContract.supplier)
    : undefined;
  return { ...customerContract, ...extendCommonCustomerContractFields(customerContract, context), supplier };
}

/**
 * Extend a customer customer contract document
 * @param customerContract Customer contract that should be extended
 * @param context Data context - needed to resolve data
 * @returns {CustomerCustomerContractExtended} Extend customer customer contract
 */
export function extendCustomerCustomerContract(
  customerContract: CustomerCustomerContract,
  context: DataContextCustomerType
): CustomerCustomerContractExtended {
  const supplier = customerContract.supplier
    ? getDocFromCollection(context.supplier, customerContract.supplier)
    : undefined;
  return { ...customerContract, ...extendCommonCustomerContractFields(customerContract, context), supplier };
}

/**
 * Reduce the common fields of a customer contract document
 * @param customerContract Customer contact document in database representation
 * @returns { {company: string, supplier: string | undefined, person: string} }
 */
export function reduceCommonCustomerContractFields(
  customerContract: CustomerContractExtended | CustomerCustomerContractExtended
): { company: string; supplier: string | undefined; person: string } {
  const company = customerContract.company._id.toString();
  const supplier = customerContract.supplier?._id.toString();
  const person = customerContract.person._id.toString();
  return { company, supplier, person };
}

/**
 * Reduce a customer contract document
 * @param customerContract Extended customer contract document
 * @returns {CustomerContract} Reduced customer contract document
 */
export function reduceCustomerContract(customerContract: CustomerContractExtended): CustomerContract {
  return { ...customerContract, ...reduceCommonCustomerContractFields(customerContract) };
}

/**
 * Reduce a customer customer contract document
 * @param customerContract Extended customer customer contract document
 * @returns {CustomerCustomerContractExtended} Reduced customer customer contract document
 */
export function reduceCustomerCustomerContract(
  customerContract: CustomerCustomerContractExtended
): CustomerCustomerContract {
  return { ...customerContract, ...reduceCommonCustomerContractFields(customerContract) };
}

/* ### COMPANY ### */
/**
 * Extend a company document
 * @param company Company document in database representation
 * @param context Data context - needed to resolve data
 * @returns {CompanyExtended} Extended company document with resolved data
 */
export function extendCompany(
  company: Company,
  context: DataContextInternalType | DataContextCustomerType
): CompanyExtended {
  const internalContact = getDocFromCollection(context.userData, company.internalContact) as UserData;
  const primaryPerson = getDocFromCollection(context.userData, company.primaryPerson) as UserData;
  const persons: Array<UserData> = [];
  for (let i = 0; i < company.persons.length; i++) {
    const p = getDocFromCollection(context.userData, company.persons[i]);
    if (p) persons.push(p);
  }
  return { ...company, internalContact, primaryPerson, persons };
}

/**
 * Reduce an extended company document.
 * @param company Extended company document
 * @returns {Company} Reduced company document in database representation
 */
export function reduceCompany(company: CompanyExtended): Company {
  const internalContact = company.internalContact._id.toString();
  const primaryPerson = company.primaryPerson._id.toString();
  const persons = company.persons.map((p) => p._id.toString());
  return { ...company, internalContact, primaryPerson, persons };
}

/* ### CUSTOMER ORDER ### */
/**
 * Extend common fields of a customer order document
 * @param customerOrder Customer order document in database representation
 * @param context Data context - needed to resolve data
 * @returns { {company: Company, person: UserData} } Resolved common fields
 */
function extendCommonCustomerOrderFields(
  customerOrder: CustomerOrder | CustomerCustomerOrder,
  context: DataContextInternalType | DataContextCustomerType | DataContextAnonymousType
): { company: Company; person: UserData } {
  const company = getDocFromCollection(context.company, customerOrder.company) as Company;
  const person = getDocFromCollection(context.userData, customerOrder.person) as UserData;
  return { company, person };
}

/**
 * Extend an internal customer order document
 * @param customerOrder Customer order document in internal database representation
 * @param context Data context - needed to resolve data
 * @returns {CustomerOrderExtended} Extended internal customer order document
 */
export function extendCustomerOrder(
  customerOrder: CustomerOrder,
  context: DataContextInternalType
): CustomerOrderExtended {
  const supplier = customerOrder.supplier ? getDocFromCollection(context.supplier, customerOrder.supplier) : null;
  return { ...customerOrder, ...extendCommonCustomerOrderFields(customerOrder, context), supplier };
}

/**
 * Extend a customer customer order document
 * @param customerOrder Customer order document in customer database representation
 * @param context Data context - needed to resolve data
 * @returns {CustomerCustomerOrderExtended} Extended customer customer order document
 */
export function extendCustomerCustomerOrder(
  customerOrder: CustomerCustomerOrder,
  context: DataContextCustomerType | DataContextAnonymousType
): CustomerCustomerOrderExtended {
  const supplier = customerOrder.supplier ? getDocFromCollection(context.supplier, customerOrder.supplier) : null;
  return { ...customerOrder, ...extendCommonCustomerOrderFields(customerOrder, context), supplier };
}

/**
 * Reduce an internal customer order document to database representation
 * @param customerOrder Customer order document in internal extended representation
 * @returns {CustomerOrder} Customer order document in internal database representation
 */
export function reduceCustomerOrder(customerOrder: CustomerOrderExtended): CustomerOrder {
  const company = customerOrder.company._id.toString();
  const person = customerOrder.person._id.toString();
  const supplier = customerOrder.supplier?._id.toString();
  return { ...customerOrder, company, person, supplier };
}

/* ### SUPPLIER ORDER ### */
/**
 * Extends an internal supplier order document
 * @param supplierOrder Supplier order document in internal database representation
 * @param context Data context - needed for resolving data
 * @returns {SupplierOrderExtended} Extended internal supplier order document
 */
export function extendSupplierOrder(
  supplierOrder: SupplierOrder,
  context: DataContextInternalType
): SupplierOrderExtended {
  const supplier = getDocFromCollection(context.supplier, supplierOrder.supplier) as Supplier;
  const person = getDocFromCollection(context.userData, supplierOrder.person) as UserData;
  const customerOrders: Array<CustomerOrder> = [];
  for (let i = 0; i < supplierOrder.customerOrders.length; i++) {
    const cO = getDocFromCollection(context.customerOrder, supplierOrder.customerOrders[i]);
    if (cO) customerOrders.push(cO);
  }
  let customerContracts: Array<CustomerContract> | undefined = undefined;
  if (supplierOrder.customerContracts) {
    customerContracts = [];
    for (let i = 0; i < supplierOrder.customerContracts.length; i++) {
      const cC = getDocFromCollection(context.customerContract, supplierOrder.customerContracts[i]);
      if (cC) customerContracts.push(cC);
    }
  }
  return { ...supplierOrder, supplier, person, customerOrders, customerContracts };
}

/**
 * Reduce an internal supplier order document to database representation
 * @param supplierOrder Extended internal supplier order document
 * @returns {SupplierOrder} Reduced internal supplier order document
 */
export function reduceSupplierOrder(supplierOrder: SupplierOrderExtended): SupplierOrder {
  const supplier = supplierOrder.supplier._id.toString();
  const person = supplierOrder.person._id.toString();
  const customerOrders = supplierOrder.customerOrders.map((cO) => cO._id.toString());
  const customerContracts = supplierOrder.customerContracts?.map((cC) => cC._id.toString());
  return { ...supplierOrder, supplier, person, customerOrders, customerContracts };
}

/* ### COMMODITY ### */
/**
 * Extend common commodity fields
 * @param commodity Internal or customer commodity document
 * @param context Data context - needed for resolving data
 * @returns {
 *  {
 *    properties: Array<Property>,
 *    activeSubstances: Array<{ substance: ActiveSubstance; percentage: number }>,
 *    documents: Array<UploadedFileExtended>
 *  }
 * }: Common commodity fields in resolved representation
 */
function extendCommonCommodityFields(
  commodity: Commodity | CustomerCommodity,
  context: DataContextInternalType | DataContextCustomerType | DataContextAnonymousType | DataContextSupplierType
): {
  properties: Array<Property>;
  activeSubstances: Array<{ substance: ActiveSubstance; percentage: number }>;
  documents: Array<UploadedFileExtended>;
} {
  const { property, supplier, activeSubstance } = context;

  const properties: Array<Property> = [];
  for (let i = 0; i < commodity.properties.length; i++) {
    const prop = getDocFromCollection(property, commodity.properties[i]);
    if (prop) properties.push(prop);
  }

  const activeSubstances: Array<{ substance: ActiveSubstance; percentage: number }> = [];
  for (let i = 0; i < commodity.activeSubstances.length; i++) {
    const { substance, percentage } = commodity.activeSubstances[i];
    const aS = getDocFromCollection(activeSubstance, substance);
    if (aS) activeSubstances.push({ substance: aS, percentage: percentage });
  }

  const documents: Array<UploadedFileExtended> = [];
  for (let i = 0; i < commodity.documents.length; i++) {
    const d = commodity.documents[i];
    if (!d.supplier) {
      documents.push({ ...d, supplier: undefined });
    } else {
      const sup = getDocFromCollection(supplier, d.supplier);
      documents.push({ ...d, supplier: sup });
    }
  }
  return { properties, activeSubstances, documents };
}

/**
 * Extend an internal commodity document
 * @param commodity Commodity document in internal database representation
 * @param context Data context - needed for resolving data
 * @returns {CommodityExtended} Extended internal commodity document
 */
export function extendCommodity(commodity: Commodity, context: DataContextInternalType): CommodityExtended {
  const { supplier } = context;

  const suppliers: Array<SupplierPricesExtended> = [];
  for (let i = 0; i < commodity.suppliers.length; i++) {
    const s = commodity.suppliers[i];
    const sup = getDocFromCollection(supplier, s.supplier);
    if (sup) {
      suppliers.push({ ...s, supplier: extendSupplier(sup, context) });
    }
  }

  return { ...commodity, ...extendCommonCommodityFields(commodity, context), suppliers };
}

/**
 * Extend a customer commodity document
 * @param commodity Commodity document in customer database representation
 * @param context Data context - needed for resolving data
 * @returns {CustomerCommodityExtended} Extended customer commodity document
 */
export function extendCustomerCommodity(
  commodity: CustomerCommodity,
  context: DataContextCustomerType | DataContextAnonymousType
): CustomerCommodityExtended {
  const { supplier } = context;

  const suppliers: Array<CustomerSupplierPricesExtended> = [];
  for (let i = 0; i < commodity.suppliers.length; i++) {
    const s = commodity.suppliers[i];
    const sup = getDocFromCollection(supplier, s.supplier);
    if (sup) {
      suppliers.push({ ...s, supplier: sup });
    }
  }

  return { ...commodity, ...extendCommonCommodityFields(commodity, context), suppliers };
}

/**
 * Extend a supplier commodity document
 * @param commodity Commodity document in supplier database representation
 * @param context Data context - needed for resolving data
 * @returns {SupplierCommodityExtended} Extended supplier commodity document
 */
export function extendSupplierCommodity(
  commodity: Commodity,
  context: DataContextSupplierType
): SupplierCommodityExtended {
  const { supplier } = context;

  const suppliers: Array<SupplierSupplierPricesExtended> = [];
  for (let i = 0; i < commodity.suppliers.length; i++) {
    const s = commodity.suppliers[i];
    const sup = getDocFromCollection(supplier, s.supplier);
    if (sup) {
      suppliers.push({ ...s, supplier: sup });
    }
  }

  return { ...commodity, ...extendCommonCommodityFields(commodity, context), suppliers };
}

/**
 * Reduce an internal commodity document.
 * @param commodity Commodity in extended internal representation
 * @returns {Commodity} Reduced internal commodity document
 */
export function reduceCommodity(commodity: CommodityExtended | SupplierCommodityExtended): Commodity {
  const properties = commodity.properties.map((p) => p._id.toString());
  const activeSubstances = commodity.activeSubstances.map(({ substance, percentage }) => {
    return { substance: substance._id.toString(), percentage };
  });
  const documents = commodity.documents.map((d) => {
    return { ...d, supplier: d.supplier?._id.toString() };
  });
  const suppliers = commodity.suppliers.map((s) => {
    return { ...s, supplier: s.supplier._id.toString() };
  });

  return { ...commodity, properties, activeSubstances, documents, suppliers };
}

/* ### FINISHED PRODUCT ### */
/**
 * Extend common finished product fields.
 * @param finishedProduct Internal or customer finished product document in database representation
 * @param context Data context - needed to resolve data
 * @returns {
 *  {
 *    properties: Array<Property>,
 *    activeSubstances: Array<{ substance: ActiveSubstance; percentage: number }>,
 *    documents: Array<UploadedFileExtended>
 *   }
 *  }: Resolved common fields
 */
function extendCommonFinishedProductFields(
  finishedProduct: FinishedProduct | CustomerFinishedProduct,
  context: DataContextInternalType | DataContextCustomerType | DataContextAnonymousType | DataContextSupplierType
): {
  properties: Array<Property>;
  activeSubstances: Array<{ substance: ActiveSubstance; percentage: number }>;
  documents: Array<UploadedFileExtended>;
} {
  const { property, activeSubstance, supplier } = context;
  const properties: Array<Property> = [];
  for (let i = 0; i < finishedProduct.properties.length; i++) {
    const prop = getDocFromCollection(property, finishedProduct.properties[i]);
    if (prop) properties.push(prop);
  }

  const activeSubstances: Array<{ substance: ActiveSubstance; percentage: number }> = [];
  for (let i = 0; i < finishedProduct.activeSubstances.length; i++) {
    const { substance, percentage } = finishedProduct.activeSubstances[i];
    const aS = getDocFromCollection(activeSubstance, substance);
    if (aS) activeSubstances.push({ substance: aS, percentage: percentage });
  }

  const documents: Array<UploadedFileExtended> = [];
  for (let i = 0; i < finishedProduct.documents.length; i++) {
    const d = finishedProduct.documents[i];
    if (!d.supplier) {
      documents.push({ ...d, supplier: undefined });
    } else {
      const sup = getDocFromCollection(supplier, d.supplier);
      documents.push({ ...d, supplier: sup });
    }
  }
  return { properties, activeSubstances, documents };
}

/**
 * Extend an internal finished product document
 * @param finishedProduct Finished product document in internal database representation
 * @param context Data context - needed for resolving data
 * @returns {FinishedProductExtended} Extended internal finished product document
 */
export function extendFinishedProduct(
  finishedProduct: FinishedProduct,
  context: DataContextInternalType
): FinishedProductExtended {
  const { supplier } = context;

  const suppliers: Array<SupplierPricesExtended> = [];
  for (let i = 0; i < finishedProduct.suppliers.length; i++) {
    const s = finishedProduct.suppliers[i];
    const sup = getDocFromCollection(supplier, s.supplier);
    if (sup) {
      suppliers.push({ ...s, supplier: extendSupplier(sup, context) });
    }
  }

  return { ...finishedProduct, ...extendCommonFinishedProductFields(finishedProduct, context), suppliers };
}

/**
 * Extend a customer finished product document
 * @param finishedProduct Finished product document in customer database representation
 * @param context Data context - needed for resolving data
 * @returns {CustomerFinishedProductExtended} Extended customer finished product document
 */
export function extendCustomerFinishedProduct(
  finishedProduct: CustomerFinishedProduct,
  context: DataContextCustomerType | DataContextAnonymousType
): CustomerFinishedProductExtended {
  const { supplier } = context;

  const suppliers: Array<CustomerSupplierPricesExtended> = [];
  for (let i = 0; i < finishedProduct.suppliers.length; i++) {
    const s = finishedProduct.suppliers[i];
    const sup = getDocFromCollection(supplier, s.supplier);
    if (sup) {
      suppliers.push({ ...s, supplier: sup });
    }
  }

  return { ...finishedProduct, ...extendCommonFinishedProductFields(finishedProduct, context), suppliers };
}

/**
 * Extend a supplier finished product document
 * @param finishedProduct Finished product document in supplier database representation
 * @param context Data context - needed for resolving data
 * @returns {SupplierFinishedProductExtended} Extended supplier finished product document
 */
export function extendSupplierFinishedProduct(
  finishedProduct: FinishedProduct,
  context: DataContextSupplierType
): SupplierFinishedProductExtended {
  const { supplier } = context;

  const suppliers: Array<SupplierSupplierPricesExtended> = [];
  for (let i = 0; i < finishedProduct.suppliers.length; i++) {
    const s = finishedProduct.suppliers[i];
    const sup = getDocFromCollection(supplier, s.supplier);
    if (sup) {
      suppliers.push({ ...s, supplier: sup });
    }
  }
  return { ...finishedProduct, ...extendCommonFinishedProductFields(finishedProduct, context), suppliers };
}

/**
 * Reduce an extended finished product document.
 * @param finishedProduct Extended finished product document
 * @returns {FinishedProduct | CustomerFinishedProduct} Reduced finished product document in database representation
 */
export function reduceFinishedProduct(
  finishedProduct: FinishedProductExtended | CustomerFinishedProductExtended | SupplierFinishedProductExtended
): FinishedProduct | CustomerFinishedProduct {
  const properties = finishedProduct.properties.map((p) => p._id.toString());
  const activeSubstances = finishedProduct.activeSubstances.map(({ substance, percentage }) => {
    return { substance: substance._id.toString(), percentage };
  });
  const documents = finishedProduct.documents.map((d) => {
    return { ...d, supplier: d.supplier?._id.toString() };
  });
  const suppliers = finishedProduct.suppliers.map((s) => {
    return { ...s, supplier: s.supplier._id.toString() };
  });
  return { ...finishedProduct, properties, activeSubstances, documents, suppliers };
}

/* ### FORWARDER ### */
/**
 * Extend a forwarder document
 * @param forwarder Forwarder document in database representation
 * @param context Data context - needed to resolve data
 * @returns {ForwarderExtended} Extended forwarder document
 */
export function extendForwarder(forwarder: Forwarder, context: DataContextInternalType): ForwarderExtended {
  const primaryPerson = getDocFromCollection(context.userData, forwarder.primaryPerson) as UserData;
  const persons: Array<UserData> = [];
  for (let i = 0; i < forwarder.persons.length; i++) {
    const p = getDocFromCollection(context.userData, forwarder.persons[i]);
    if (p) persons.push(p);
  }
  return { ...forwarder, primaryPerson, persons };
}

/**
 * Reduce a forwarder document
 * @param forwarder Extended forwarder document
 * @returns {Forwarder} Forwarder document in database representation
 */
export function reduceForwarder(forwarder: ForwarderExtended): Forwarder {
  const primaryPerson = forwarder.primaryPerson._id.toString();
  const persons = forwarder.persons.map((p) => p._id.toString());
  return { ...forwarder, primaryPerson, persons };
}

/* ### MISC ### */
/**
 * Reduce an uploaded file object
 * @param uploadedFile Extended uploaded file object
 * @returns {UploadedFile} Reduced uploaded filed object
 */
export function reduceUploadedFile(uploadedFile: UploadedFileExtended): UploadedFile {
  const reducedUploadedFile = { ...uploadedFile, supplier: uploadedFile.supplier?._id.toString() };
  if (!reducedUploadedFile.supplier) delete reducedUploadedFile.supplier;
  return reducedUploadedFile;
}

/**
 * Extend a suppliers prices array of an article.
 * @param supplierPrices Supplier prices in database representation
 * @param context Data context - needed to resolve data
 * @returns {SupplierPricesExtended | SupplierPricesFinishedProductExtended} Extended supplier prices
 */
export function extendSupplierPrices(
  supplierPrices: Array<SupplierPrices | SupplierPricesFinishedProduct>,
  context: DataContextInternalType
): Array<SupplierPricesExtended | SupplierPricesFinishedProductExtended> {
  return supplierPrices.map((sP) => {
    const supplier = extendSupplier(getDocFromCollection(context.supplier, sP.supplier) as Supplier, context);
    return { ...sP, supplier };
  });
}
