import _ from "lodash";
import React, { PureComponent } from "react";
import { Accordion } from "react-bootstrap";
import { SupplierEUStockPrices } from "../../../../model/commodity.types";
import CommodityEUStockEntry from "../../common/CommodityEUStockEntry";
import userService from "../../../../services/userService";
import { getDefaultEUStock } from "../../../../utils/commodityUtils";
import { SupplierArticleExtended } from "../../../../utils/productArticleUtils";

interface CommodityPageEUStockProps {
  article: SupplierArticleExtended;
}

interface CommodityPageEUStockState {
  euStocks: Array<SupplierEUStockPrices>;
}

class CommodityPageEUStock extends PureComponent<CommodityPageEUStockProps, CommodityPageEUStockState> {
  constructor(props: CommodityPageEUStockProps) {
    super(props);
    this.state = {
      euStocks: this.getEUStocks(props),
    };
  }

  componentDidUpdate(prevProps: Readonly<CommodityPageEUStockProps>) {
    if (!_.isEqual(prevProps.article.supplierEUStocks, this.props.article.supplierEUStocks)) {
      this.setState({ euStocks: this.getEUStocks(this.props) });
    }
  }

  getEUStocks = (props: CommodityPageEUStockProps) => {
    const existingStocks = props.article.supplierEUStocks?.filter((ses) => ses.supplier === userService.getCompany());
    return _.cloneDeep(
      existingStocks && existingStocks.length > 0 ? existingStocks : [getDefaultEUStock(userService.getCompany(), true)]
    );
  };

  render() {
    const { article } = this.props;
    const { euStocks } = this.state;

    return (
      <div className="card bg-white">
        <div className="card-header border-0 mt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">EU Stock</span>
            <span className="text-muted fw-bold fs-7">{article.supplierEUStocks?.length || 0} Entries</span>
          </h3>
        </div>
        <div className="card-body p-4 pt-4">
          <Accordion alwaysOpen={true}>
            {euStocks.map((esp) => (
              <div key={esp._id.toString()}>
                <CommodityEUStockEntry article={article} euStockPrice={esp} />
              </div>
            ))}
          </Accordion>
        </div>
      </div>
    );
  }
}

export default CommodityPageEUStock;
