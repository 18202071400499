import React, { PureComponent } from "react";
import Search from "../../common/Search";
import { filterBasicProperties, generateCommodityPropertyTableRows } from "./CommodityHelper";
import { ArticleExtended } from "../../../utils/productArticleUtils";

interface CommodityPageGeneralInformationBlockProps {
  article: ArticleExtended;
  onSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  search?: string;
}

class CommodityPageGeneralInformationBlock extends PureComponent<CommodityPageGeneralInformationBlockProps> {
  generateBasicPropertiesList = (): JSX.Element | Array<JSX.Element> => {
    const { article, search } = this.props;
    const propertiesList = filterBasicProperties(article, search || "");
    if (propertiesList.length === 0) {
      return (
        <tr>
          <td colSpan={2}>
            <span className="text-white">No result found</span>
          </td>
        </tr>
      );
    }
    return generateCommodityPropertyTableRows(propertiesList);
  };

  render() {
    const { onSearch, search } = this.props;

    return (
      <div className="py-0">
        <div className="d-flex py-3 align-items-center w-100 justify-content-between">
          <div className="me-3">
            <div className="text-white fs-3 fw-bolder">Basic Information</div>
          </div>
          <div>{onSearch && <Search onSearch={onSearch} value={search} placeholder="Search for properties..." />}</div>
        </div>
        <div className="fs-6">
          <div className="d-flex flex-wrap py-5">
            <table className="table fw-bold gy-1">
              <tbody>{this.generateBasicPropertiesList()}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default CommodityPageGeneralInformationBlock;
