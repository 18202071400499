import { BSON } from "realm-web";
import { DateTypeLogistics } from "../utils/logisticsUtils";

// states
export enum FWO_STATES {
  FWO_CREATED = "forwardingOrderCreated",
  FWO_IN_TRANSIT = "forwardingOrderInTransit", // ETA of all orders inside the FWO was updated
  FWO_ARCHIVED = "forwardingOrderArchived", // not B2B: batches are booked and "Einlagerungsanzeige" uploaded; B2B: To be defined
  FWO_CANCELED = "forwardingOrderCanceled",
}

export interface ForwardingOrder {
  _id: BSON.ObjectId;
  forwardingOrderNo: string;
  orderInformation: Array<ForwardingOrderInformation>;
  takeOver: {
    startIncoterm: string;
    startingFrom: string;
  };
  state: FWO_STATES;
  transportType: string;
  forwarder: string;
  remarks: string;
  returnOrder: boolean;
  file: string; // Path to PDF
  createdAt: Date;
  warnings?: Array<string>;
  oneAddress?: boolean; // print only address and data of first order on PDF if there are multiple
  timeline: Array<ForwardingOrderTimelineEntry>;
}

export interface ForwardingOrderInformation {
  _id: BSON.ObjectId;
  orderId: string; // id of the supplier or customer stock order
  orderNo: string;
  packaging: string;
  netWeight: number;
  grossWeight: number;
  customsCleared: boolean;
  destinationIncoterm: string;
  destinationTo: string;
  sender: string;
  recipient: string;
  recipientInformation?: string;
  deliveryDate?: Date;
  deliveryDateType?: DateTypeLogistics;
}

// timeline
export enum FWO_TIMELINE {
  FWO_T_CREATED = "forwardingOrderCreated",
  FWO_T_IN_TRANSIT = "forwardingOrderInTransit",
  FWO_T_REMOVEDORDER = "orderRemovedFromForwardingOrder",
  FWO_T_CANCELED = "forwardingOrderCanceled",
  FWO_T_ARCHIVED = "forwardingOrderArchived",
}

export interface ForwardingOrderTimelineEntryPayload {}

export interface ForwardingOrderTimelineEntry {
  // Might be incomplete
  _id: BSON.ObjectId;
  date: Date; // When did this happen?
  type: FWO_TIMELINE; // What happened?
  person: string;
  payload: ForwardingOrderTimelineEntryPayload | null; // Additional data for certain types, tbd
}
