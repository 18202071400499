import React from "react";
import { formatCurrency } from "../../../utils/baseUtils";
import { CUSTOMER_BASE_CURRENCY } from "../../../utils/currencyUtils";

interface CommodityPriceInformationProps {
  price?: number;
  loading: boolean;
  amount: string;
}

const CommodityPriceInformation: React.FunctionComponent<CommodityPriceInformationProps> = ({
  price,
  loading,
  amount,
}) => {
  return (
    <div className="pt-5 w-100">
      {price !== undefined &&
        (price > 0 && !isNaN(price) ? (
          <>
            <div className="fw-bolder text-white text-center" style={{ fontSize: "2.2rem" }}>
              {loading ? (
                <div>
                  Calculating...
                  <span className="spinner-border spinner-border-sm align-middle text-muted"></span>
                </div>
              ) : (
                formatCurrency(price, CUSTOMER_BASE_CURRENCY)
              )}
            </div>
            <div className="fw-bolder mb-3 text-white text-center">
              <div className="text-center text-muted">{amount}</div>
            </div>
          </>
        ) : (
          <div className="fw-bolder text-center bg-light no-hover py-5 mb-5 card">Price on Request</div>
        ))}
    </div>
  );
};

export default CommodityPriceInformation;
