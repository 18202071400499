import React, { PureComponent } from "react";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";
import { COR_FILTEROPTIONS, COR_SORTOPTIONS } from "../../../../utils/commodityOfferRequestUtils";
import { SORTORDEROPTIONS } from "../../../../utils/filterUtils";

interface RequestFilterProps {
  filterBy: SelectOption;
  sortBy: SelectOption;
  sortOrder: { value: "asc" | "desc"; label: string };
  onChangeFilterBy: (filterBy: SelectOption) => void;
  onChangeSortBy: (sortBy: SelectOption) => void;
  onChangeSortOrder: (sortOrder: { value: "asc" | "desc"; label: string }) => void;
}

class CommodityOfferRequestFilter extends PureComponent<RequestFilterProps> {
  render() {
    const { filterBy, sortBy, sortOrder, onChangeFilterBy, onChangeSortBy, onChangeSortOrder } = this.props;
    return (
      <div className="row mt-6">
        <div className="col-12 col-md-6 col-lg-3">
          <label className="fs-6 form-label fw-bolder text-dark">Filter</label>
          <CustomSelect options={COR_FILTEROPTIONS} onChange={onChangeFilterBy} value={filterBy} />
        </div>
        <div className="col-12 col-md-6 col-lg-3">
          <label className="fs-6 form-label fw-bolder text-dark">Sort by</label>
          <CustomSelect options={COR_SORTOPTIONS} onChange={onChangeSortBy} value={sortBy} />
        </div>
        <div className="col-12 col-md-6 col-lg-3">
          <label className="fs-6 form-label fw-bolder text-dark">Sort order</label>
          <CustomSelect options={SORTORDEROPTIONS} onChange={onChangeSortOrder} value={sortOrder} />
        </div>
      </div>
    );
  }
}

export default CommodityOfferRequestFilter;
