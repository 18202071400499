import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import AddNewValueModal from "./modals/AddNewValueModal";
import { General } from "../../../model/general.types";
import userService from "../../../services/userService";
import { formatDateTime } from "../../../utils/baseUtils";

interface GeneralValuesSettingProps extends RouteComponentProps {
  general: Array<General>;
}

class GeneralValuesSetting extends PureComponent<GeneralValuesSettingProps> {
  render() {
    const { general, history } = this.props;
    if (!userService.isAdmin()) {
      history.push("/settings");
      return <></>;
    }
    // TODO: Really log changes to slack RB-287
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="card bg-white">
              <div className="card-body">
                <h3 className="card-title align-items-start flex-column mb-15">
                  <span className="card-label fw-bolder mb-3 fs-3rem">General Values Configuration</span>
                </h3>
                <div className="w-100 bg-warning text-center h3 p-10">
                  EDITING VALUES CAN HAVE HEAVY IMPACTS ON PRICE OR DELIVERY TIME CALCULATION!
                  <br />
                  Do not change any values without a valid reason. Every change will be logged.
                </div>
                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                  <thead>
                    <tr className="fw-bolder text-muted">
                      <th style={{ width: "27%" }}>Key</th>
                      <th style={{ width: "27%" }}>Value</th>
                      <th style={{ width: "26%" }}>Last Update</th>
                      <th style={{ width: "20%" }} className="text-right">
                        Edit
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {general
                      .filter((g) => ["string", "number"].includes(typeof g.value))
                      .map((g) => (
                        <tr key={g._id.toString()}>
                          <td className="text-white align-middle">{g.key}</td>
                          <td className="text-white align-middle">{g.value as "string" | "number"}</td>
                          <td className="text-white align-middle">
                            {g.lastUpdate ? formatDateTime(g.lastUpdate) : "-"}
                          </td>
                          <td className="text-white align-middle text-right">
                            <AddNewValueModal generalCollection={general} general={g} />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="card-footer text-right border-0">
                <AddNewValueModal generalCollection={general} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(GeneralValuesSetting);
