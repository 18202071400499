import _ from "lodash";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContextInternalType } from "../../../context/dataContext";
import { CC_STATE, CustomerContract, CustomerContractExtended } from "../../../model/customerContract.types";
import { CustomerOrder } from "../../../model/customerOrder.types";
import { getDocFromCollection } from "../../../utils/baseUtils";
import { CUSTOMERCONTRACT, getDocumentDB } from "../../../services/dbService";
import CustomerContractSummary from "../CustomerContractSummary";
import CustomerContractTabPanel from "./CustomerContractTabPanel";
import { SupplierOrder } from "../../../model/supplierOrder.types";
import CustomerOrderSupplierOrder from "../../orders/internal/customerOrder/CustomerOrderSupplierOrder";
import {
  CC_T_CLOSED,
  getCustomerContractTimelineEntry,
  updateCustomerContract,
} from "../../../utils/customerContractUtils";

import { extendCustomerContract } from "../../../utils/dataTransformationUtils";

interface CustomerContractParams {
  id: string;
}

interface CustomerContractPageProps extends RouteComponentProps<CustomerContractParams> {
  context: DataContextInternalType;
}

interface CustomerContractPageState {
  contract?: CustomerContractExtended;
  calls?: Array<CustomerOrder>;
  supplierOrder?: SupplierOrder;
}

class CustomerContractPage extends PureComponent<CustomerContractPageProps, CustomerContractPageState> {
  _isMounted = false;
  constructor(props: CustomerContractPageProps) {
    super(props);
    const contract = this.getCustomerContract(props);
    this.state = {
      contract,
      calls: this.getRelatedCustomerOrders(contract),
      supplierOrder: this.getRelatedSupplierOrders(contract),
    };
  }

  componentDidMount = async () => {
    const { match, history, context } = this.props;
    if (this.state.contract) return;
    const id = match.params.id;
    if (!id || !BSON.ObjectId.isValid(id)) {
      history.push("/contracts");
      return;
    }
    this._isMounted = true;
    const contract = await getDocumentDB<CustomerContract>(CUSTOMERCONTRACT, id);
    if (!contract) {
      console.error("No contract could be loaded for id", id);
      toast.error("The requested contract could not be loaded");
      history.push("/contracts");
      return;
    }
    context.addDocuments(CUSTOMERCONTRACT, [contract]);
    const contractExtended = extendCustomerContract(contract, context);
    if (this._isMounted)
      this.setState({ contract: contractExtended, calls: this.getRelatedCustomerOrders(contractExtended) });
  };

  componentDidUpdate(prevProps: Readonly<CustomerContractPageProps>, prevState: Readonly<CustomerContractPageState>) {
    const { match, context } = this.props;
    if (match.params.id || (!match.params.id && prevProps.match.params.id)) {
      const contract = this.getCustomerContract(this.props);
      if (contract && !_.isEqual(contract, this.state.contract)) {
        this.setState({
          contract,
          calls: this.getRelatedCustomerOrders(contract),
          supplierOrder: this.getRelatedSupplierOrders(contract),
        });
      } else if (
        !_.isEqual(prevState.contract, this.state.contract) ||
        !_.isEqual(context.customerOrder, prevProps.context.customerOrder) ||
        !_.isEqual(context.supplierOrder, prevProps.context.supplierOrder)
      ) {
        this.setState({
          calls: this.getRelatedCustomerOrders(contract),
          supplierOrder: this.getRelatedSupplierOrders(contract),
        });
      }
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  handleCloseContract = async () => {
    const { contract } = this.state;
    if (contract) {
      const timelineEntry = getCustomerContractTimelineEntry(CC_T_CLOSED);
      const update = { state: CC_STATE.CLOSED };
      try {
        const result = await updateCustomerContract(update, contract._id, timelineEntry);
        if (result) {
          toast.success("Closing contract successfully");
        } else {
          toast.error("Error closing contract");
        }
      } catch (e) {
        toast.error("Error closing contract: " + e);
        console.error(e);
      }
    }
  };

  getCustomerContract(props: CustomerContractPageProps): CustomerContractExtended | undefined {
    const contract = getDocFromCollection(props.context.customerContract, props.match.params.id);
    if (contract) return extendCustomerContract(contract, props.context);
  }

  /**
   * Retrieve the customer orders related to the customer contract.
   * @param contract contract to get calls for
   * @returns {Array<CustomerOrder> | undefined} List of related calls
   */
  getRelatedCustomerOrders = (contract?: CustomerContractExtended): Array<CustomerOrder> | undefined => {
    const { context } = this.props;
    return contract
      ? context.customerOrder.filter((cO) => contract.calls.some((c) => c._id.toString() === cO._id.toString())).slice()
      : undefined;
  };

  /**
   * Retrieve the supplier orders related to the customer order.
   * @param contract Contract that should be checked
   * @returns {SupplierOrder | undefined} List of related supplier orders
   */
  getRelatedSupplierOrders = (contract?: CustomerContractExtended): SupplierOrder | undefined => {
    const { context } = this.props;
    return contract
      ? context.supplierOrder.find((sO) => sO.customerContracts?.some((cO) => cO === contract._id.toString()))
      : undefined;
  };

  render() {
    const { context } = this.props;
    const { contract, calls, supplierOrder } = this.state;

    if (!contract) return <div />;
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="d-flex flex-column flex-xl-row">
              <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
                <CustomerContractSummary contract={contract} context={context} />

                <CustomerOrderSupplierOrder supplierOrder={supplierOrder} context={context} />
              </div>
              <CustomerContractTabPanel
                contract={contract}
                context={context}
                calls={calls}
                onCloseContract={this.handleCloseContract}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerContractPage;
