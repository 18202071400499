import React, { PureComponent } from "react";
import { Accordion } from "react-bootstrap";
import Search from "../../../common/Search";
import { UploadedFileExtended } from "../../../../model/commodity.types";
import UploadDocumentModal, { T_GENERALSUPPLIER, T_SUPPLIER } from "../modals/UploadDocumentModal";
import { doFuseSearch } from "../../../../utils/baseUtils";
import SupplierAndCommodityDocuments from "../../../common/SupplierAndCommodityDocuments";
import { InternalArticleExtended } from "../../../../utils/productArticleUtils";
import { DataContextInternalType } from "../../../../context/dataContext";
import { Supplier } from "../../../../model/supplier.types";

import { extendSupplier } from "../../../../utils/dataTransformationUtils";

interface CommodityPageDocumentsProps {
  article: InternalArticleExtended;
  context: DataContextInternalType;
}

interface CommodityPageDocumentsState {
  search: string;
}

class CommodityPageDocuments extends PureComponent<CommodityPageDocumentsProps, CommodityPageDocumentsState> {
  constructor(props: CommodityPageDocumentsProps) {
    super(props);
    this.state = {
      search: "",
    };
  }

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });

  getSupplierDocuments = () => {
    const { article } = this.props;
    const { search } = this.state;
    let documents = article.documents.slice();
    if (search.trim()) documents = doFuseSearch(documents, search, ["name", "type", "supplier.name"]);
    const documentsMap: { [id: string]: Array<UploadedFileExtended> } = { general: [] };
    for (let i = 0; i < documents.length; i++) {
      const doc = documents[i];
      if (!doc.supplier) {
        documentsMap["general"].push(doc);
        continue;
      }
      if (doc.supplier._id.toString() in documentsMap) {
        documentsMap[doc.supplier._id.toString()].push(doc);
      } else {
        documentsMap[doc.supplier._id.toString()] = [doc];
      }
    }
    return { documents, documentsMap };
  };

  render() {
    const { article, context } = this.props;
    const { search } = this.state;
    const { documents, documentsMap } = this.getSupplierDocuments();
    return (
      <div className="card bg-white">
        <div className="card-header border-0 mt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">Documents</span>
            <span className="text-muted fw-bold fs-7">
              {documents.length} {documents.length === 1 ? "Document" : "Documents"}
            </span>
          </h3>
          <div className="card-toolbar">
            <Search onSearch={this.handleSearch} value={search} />
          </div>
        </div>
        <div className="card-body p-4 pt-4">
          <div className=" pt-0">
            <div className="card-body p-4 modal-content-scroll">
              <Accordion alwaysOpen={true} defaultActiveKey={Object.keys(documentsMap)}>
                {Object.entries(documentsMap).map(([id, docs]) => {
                  const supplier = docs[0] && docs[0].supplier;
                  return (
                    <SupplierAndCommodityDocuments
                      key={id}
                      eventKey={id}
                      type={id === "general" ? T_GENERALSUPPLIER : T_SUPPLIER}
                      article={article}
                      supplier={supplier ? extendSupplier(supplier as Supplier, context) : undefined}
                      documents={docs}
                      noSupplierSelection={id === "general"}
                    />
                  );
                })}
              </Accordion>
              <UploadDocumentModal article={article} type={T_GENERALSUPPLIER} buttonType={"wide"} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CommodityPageDocuments;
