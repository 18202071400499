import React from "react";

interface StatsWidgetProps {
  title: string;
  text: string;
  subtext: string;
  color: string;
  inactive?: boolean;
  loading?: boolean;
}

export const StatsWidget: React.FunctionComponent<StatsWidgetProps> = React.memo(
  ({ title, text, subtext, color, inactive, loading }) => {
    if (loading) {
      return (
        <div className="d-flex flex-stack my-5">
          <div className="d-flex">
            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
              <div className="flex-grow-1 me-2">
                <span className="text-gray-800 fs-6 fw-bolder">
                  {title} {loading && <span className="text-muted">[LOADING]</span>}
                </span>
                <span className="text-muted fw-bold d-block fs-7">Loading...</span>
              </div>
            </div>
          </div>
          <span className={"badge fw-bolder my-2 text-uppercase badge-light"} style={{ opacity: 0.5 }}>
            TBD
          </span>
        </div>
      );
    }
    return (
      <div className="d-flex flex-stack my-5">
        <div className="d-flex">
          <div className="d-flex align-items-center flex-row-fluid flex-wrap">
            <div className="flex-grow-1 me-2">
              <span className="text-gray-800 fs-6 fw-bolder">
                {title} {inactive && <span className="text-warning">[INACTIVE]</span>}
              </span>
              <span className="text-muted fw-bold d-block fs-7">{subtext}</span>
            </div>
          </div>
        </div>
        <span className={"badge fw-bolder my-2 text-uppercase badge-" + color} style={inactive ? { opacity: 0.5 } : {}}>
          {text}
        </span>
      </div>
    );
  }
);
