export enum SettingType {
  TRANSPORT = "transport",
  PROPERTIES = "properties",
  CONFIGURATIONS = "configurations",
  VALUES = "values",
}

export enum SettingEntry {
  USERDATA = "userData",
  SEAPORTS = "seaports",
  AIRPORTS = "airports",
  NOTIFIES = "notifies",
  PROPERTIES = "properties",
  ACTIVE_SUBSTANCES = "activeSubstances",
  SERVICES = "services",
  SYSTEM_NOTIFICATION = "systemNotification",
  PRICE_GRADUATION = "priceGraduation",
  VERSION_HISTORY = "versionHistory",
  ANONYMOUS_VIEW_ARTICLE_PRICES = "anonymousViewArticlePrices",
  RESPONSIBLE = "responsible",
  GENERAL_VALUES = "generalValues",
  CALCULATION_VALUES = "calculationValues",
  DELIVERY_TIMES = "deliveryTimes",
  EXPENSE_VALUES = "expenseValues",
}

export enum DeliveryTime {
  AIR_CO = "deliveryTimeAirCO",
  SEA_CO = "deliveryTimeSeaCO",
  EU_STOCK_CO = "deliveryTimeEUStockCO",
  WAREHOUSE_CO = "deliveryTimeWarehouseCO",
  AIR_SO = "deliveryTimeAirSO",
  SEA_SO = "deliveryTimeSeaSO",
  EU_STOCK_SO = "deliveryTimeEUStockSO",
  SUPPLIER_PREPARATION = "supplierOrderPreparationTime",
}

/**
 * Retrieve a user readable short description of the given delivery time value.
 * @param key DeliveryTime value, string is supported for easier handling
 * @returns {string} User readable description
 */
export function getDeliveryTimeDisplayText(key: DeliveryTime | string): string {
  switch (key) {
    case DeliveryTime.AIR_CO:
      return "Air: Port -> Customer";
    case DeliveryTime.SEA_CO:
      return "Sea: Port -> Customer";
    case DeliveryTime.EU_STOCK_CO:
      return "EU Stock: Warehouse -> Customer";
    case DeliveryTime.WAREHOUSE_CO:
      return "Stock: Warehouse -> Customer";
    case DeliveryTime.AIR_SO:
      return "Air: Starting Port -> Warehouse";
    case DeliveryTime.SEA_SO:
      return "Sea: Starting Port -> Warehouse";
    case DeliveryTime.EU_STOCK_SO:
      return "EU Stock: Supplier -> Warehouse";
    case DeliveryTime.SUPPLIER_PREPARATION:
      return "Supplier Preparation Time Fallback";
    default:
      return "Unknown value";
  }
}
