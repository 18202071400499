import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { I_CUSTOMERINVOICE, I_SAMPLEINVOICE, I_STATE, Invoice, Payment } from "../../../../model/invoice.types";
import { Input } from "../../../common/Input";
import { callPayInvoice, getTotalOpenSumToPay } from "../../../../utils/invoiceUtils";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import userService from "../../../../services/userService";
import DateInput from "../../../common/DateInput";
import { getOrderNumber } from "../../../../utils/orderUtils";
import { DataContextInternalType } from "../../../../context/dataContext";
import { getDocFromCollection } from "../../../../utils/baseUtils";

interface PayInvoiceModalProps {
  invoice: Invoice;
  noButton?: boolean;
  context: DataContextInternalType;
}

interface PayInvoiceModalState {
  show: boolean;
  amount: number;
  paymentDate: Date;
  fullyPaid: boolean;
}

class PayInvoiceModal extends PureComponent<PayInvoiceModalProps, PayInvoiceModalState> {
  constructor(props: PayInvoiceModalProps) {
    super(props);
    this.state = this.getDefaultState(props, false);
  }

  handleShow = () => this.setState(this.getDefaultState(this.props, true));
  handleHide = () => this.setState(this.getDefaultState(this.props, false));

  handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = +e.target.value;
    this.setState({ amount, fullyPaid: amount === getTotalOpenSumToPay(this.props.invoice) });
  };

  handleCheckFullyPaid = () => {
    if (this.state.fullyPaid) this.setState({ amount: 0, fullyPaid: false });
    else this.setState({ amount: getTotalOpenSumToPay(this.props.invoice), fullyPaid: true });
  };

  handleChangePayDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.setState({ paymentDate: value ? new Date(value) : new Date() });
  };

  handlePayInvoice = async () => {
    const { invoice } = this.props;
    const { amount, fullyPaid } = this.state;
    const payment: Payment = { _id: new BSON.ObjectId(), date: new Date(), amount, person: userService.getUserId() };
    try {
      const res = await callPayInvoice(invoice._id, payment, fullyPaid ? I_STATE.PAID : I_STATE.PARTLY_PAID);
      if (res && res.modifiedCount > 0) {
        toast.success("Payment added successfully");
        this.handleHide();
      } else {
        toast.error("Error adding payment");
      }
    } catch (e) {
      console.error(e);
      toast.error("Error adding payment");
    }
  };

  getDefaultState = (props: PayInvoiceModalProps, show: boolean) => {
    return { amount: getTotalOpenSumToPay(props.invoice), paymentDate: new Date(), fullyPaid: true, show };
  };

  validateData = () => {
    const { invoice } = this.props;
    const { amount, paymentDate } = this.state;
    const errors = [];
    const remaining = getTotalOpenSumToPay(invoice);
    if ((remaining > 0 && amount <= 0) || (remaining === 0 && amount < 0)) errors.push("Amount has to be positive");
    if (amount > remaining) errors.push("Amount can't be more than the remaining sum");
    if (paymentDate > new Date()) errors.push("Payment date can't be in the future");
    return errors;
  };

  render() {
    const { invoice, noButton, context } = this.props;
    const { show, amount, paymentDate, fullyPaid } = this.state;
    const errors = this.validateData();
    const order =
      show && invoice.relatedOrder
        ? invoice.type === I_CUSTOMERINVOICE
          ? getDocFromCollection(context.customerOrder, invoice.relatedOrder)
          : invoice.type === I_SAMPLEINVOICE
          ? getDocFromCollection(context.sampleOrder, invoice.relatedOrder)
          : getDocFromCollection(context.supplierOrder, invoice.relatedOrder)
        : undefined;

    return (
      <>
        {noButton ? (
          <div onClick={this.handleShow}>Pay</div>
        ) : (
          <button className="btn btn-sm btn-outline btn-outline-light" onClick={this.handleShow}>
            Pay
          </button>
        )}
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered size="lg">
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">
                Pay INV-{invoice.paymentTarget === -1 && <span>A</span>}
                {invoice.invoiceNumber}
              </h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-3 col-lg-2 d-flex align-items-center mt-4">
                <span className="fs-6 fw-bolder text-white">Order</span>
              </div>
              <div className="col-9 col-lg-4 mt-4">
                <Input
                  className="form-control custom-form-control"
                  type="text"
                  disabled={true}
                  value={order ? getOrderNumber(order) : "No related order"}
                />
              </div>
              <div className="col-3 col-lg-2 d-flex align-items-center mt-4">
                <span className="fs-6 fw-bolder text-white">
                  {invoice.type === I_CUSTOMERINVOICE ? "Customer" : "Supplier"}
                </span>
              </div>
              <div className="col-9 col-lg-4 mt-4">
                <Input
                  className="form-control custom-form-control"
                  type="text"
                  disabled={true}
                  value={invoice.company.name}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-3 col-lg-2 d-flex align-items-center mt-4">
                <span className="fs-6 fw-bolder text-white">Amount Paid</span>
              </div>
              <div className="col-9 col-lg-4 mt-4">
                <div className="input-group">
                  <Input
                    className="form-control custom-form-control"
                    type="number"
                    name="amount"
                    value={amount}
                    min={0}
                    max={getTotalOpenSumToPay(invoice)}
                    onChange={this.handleChangeAmount}
                  />
                  <div className="input-group-append rounded-end bg-custom-light-gray">
                    <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                      {invoice.currency}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3 col-lg-2 d-flex align-items-center mt-4">
                <span className="fs-6 fw-bolder text-white">Pay Date</span>
              </div>
              <div className="col-9 col-lg-4 mt-4">
                <DateInput
                  classes="form-control custom-form-control"
                  value={paymentDate}
                  onBlur={this.handleChangePayDate}
                  name={"payDate"}
                  min={order?.createdAt}
                  max={new Date()}
                />
              </div>
              <div className="col-3 col-lg-2 d-flex align-items-center mt-4">
                <span className="fs-6 fw-bolder text-white">Fully Paid</span>
              </div>
              <div className="col-9 col-lg-4 mt-4">
                <div className="input-group">
                  <div className="form-check form-switch form-check-custom form-check-solid mt-2">
                    <input
                      className="form-check-input position-static"
                      checked={fullyPaid}
                      onChange={this.handleCheckFullyPaid}
                      type="checkbox"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-text-danger" onClick={this.handleHide}>
              Cancel
            </button>
            <ErrorOverlayButton
              errors={errors}
              className="btn btn-sm btn-outline btn-outline-light"
              buttonText="Pay Invoice"
              onClick={this.handlePayInvoice}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default PayInvoiceModal;
