import _ from "lodash";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { Service } from "../../../../model/service.types";
import { updateService } from "../../../../utils/serviceUtils";

interface DisableServiceModalProps {
  service: Service;
}

interface DisableServiceModalState {
  show: boolean;
}

class DisableServiceModal extends PureComponent<DisableServiceModalProps, DisableServiceModalState> {
  constructor(props: DisableServiceModalProps) {
    super(props);
    this.state = { show: false };
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState({ show: false });

  /**
   * Disables the service.
   */
  handleClickDisable = async () => {
    const service = _.cloneDeep(this.props.service);
    service.disabled = !service.disabled;
    const result = await updateService(service);
    if (result) {
      toast.success("Service " + (service.disabled ? "disabled" : "enabled") + " successfully");
      this.handleHide();
    } else {
      toast.error("Error " + (service.disabled ? "disabling" : "enabling") + " service");
    }
  };

  render() {
    const { service } = this.props;
    const { show } = this.state;
    const enable = service.disabled;

    return (
      <>
        <button
          className={"btn btn-text " + (enable ? "btn-text-success" : "btn-text-danger")}
          onClick={this.handleShow}
        >
          {enable ? "Enable" : "Disable"}
        </button>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">
                {enable ? "Enable" : "Disable"} {service.title.en}
              </h1>
            </Modal.Title>
            <CloseButton variant={"white"} onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <h2 className="fw-bolder text-white text-center">
              Do you really want to {enable ? "enable" : "disable"} {service.title.en}?
            </h2>
            {enable ? (
              <span className="fw-bolder d-flex align-items-center text-white text-center">
                After being enabled the service can be used inside the system again.
              </span>
            ) : (
              <>
                <span className="fw-bolder d-flex align-items-center text-white text-center">
                  After being disabled the service can not be used inside the system anymore.
                </span>
                <br />
                <span className="fw-bolder d-flex align-items-center text-white text-center">
                  If the service is already used in orders the service will still be shown there.
                </span>
                <br />
                <span className="fw-bolder d-flex align-items-center text-white text-center">
                  You can re-enable the service any time via clicking on "Enable" on the service overview.
                </span>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-outline btn-text-danger" onClick={this.handleHide}>
              Cancel
            </button>
            <button className="btn btn-sm btn-outline btn-outline-light" onClick={this.handleClickDisable}>
              {enable ? "Enable" : "Disable"}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default DisableServiceModal;
