import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { SupplierOrderExtended } from "../../../../../../model/supplierOrder.types";
import { formatDate, getDocFromCollection } from "../../../../../../utils/baseUtils";
import { getOrderNumber } from "../../../../../../utils/orderUtils";
import { CustomerOrder } from "../../../../../../model/customerOrder.types";
import { CustomerContract } from "../../../../../../model/customerContract.types";
import {
  getAmountForOrderOrContract,
  getIdentifierForOrderOrContract,
  getLinkForOrderOrContract,
} from "../../../../../../utils/contractAndOrderUtils";
import { formatArticleUnit } from "../../../../../../utils/productArticleUtils";
import { DataContextInternalType } from "../../../../../../context/dataContext";
import { Company } from "../../../../../../model/company.types";

interface WorkflowCreateOrderCardProps {
  order: SupplierOrderExtended;
  context: DataContextInternalType;
}

class WorkflowCreateOrderCard extends PureComponent<WorkflowCreateOrderCardProps> {
  render() {
    const { order, context } = this.props;
    let allDocuments: Array<CustomerOrder | CustomerContract> = order.customerOrders.slice();
    if (order.customerContracts) allDocuments = allDocuments.concat(order.customerContracts);
    return (
      <div className="opacity-100-hover" style={{ opacity: 0.3 }}>
        <div className="fw-bolder text-white fs-3 my-5">
          Create Order <i className="h2 fas fa-check-circle text-success" />
        </div>
        <div className="py-0">
          <div className="table-responsive mt-5 pt-2 bg-light2">
            <table className="table table-row-gray-100 align-middle">
              <thead>
                <tr className="fw-bolder text-muted">
                  <th className="border-bottom-0" style={{ width: "10%" }}>
                    Company
                  </th>
                  <th className="border-bottom-0 w-50" />
                  <th className="border-bottom-0">Amount</th>
                  <th className="border-bottom-0">Ordered</th>
                  <th className="border-bottom-0">Order</th>
                </tr>
              </thead>
              <tbody>
                {allDocuments.map((doc) => {
                  const company = getDocFromCollection(context.company, doc.company) as Company;
                  return (
                    <tr key={doc._id.toString()}>
                      <td className="align-middle">
                        <span className="badge badge-pill badge-success">Customer</span>
                      </td>
                      <td className="align-middle">
                        <Link className="text-white fw-bolder custom-link" to={"/customer/" + company._id.toString()}>
                          {company.name}
                        </Link>
                      </td>
                      <td className="align-middle">
                        <span className="text-danger">-{getAmountForOrderOrContract(doc)}</span>
                      </td>
                      <td className="align-middle">
                        <span className="text-white">{formatDate(doc.createdAt)}</span>
                      </td>
                      <td className="align-middle">
                        <Link className="fw-bolder text-gray-800 fs-6 custom-link" to={getLinkForOrderOrContract(doc)}>
                          {getIdentifierForOrderOrContract(doc)}
                        </Link>
                      </td>
                    </tr>
                  );
                })}
                {/* #TODO: RB-294 - Show related supplier orders */}
                <tr>
                  <td className="align-middle">
                    <span className="badge badge-pill badge-warning">Supplier</span>
                  </td>
                  <td className="align-middle">
                    <Link
                      className="text-white fw-bolder custom-link"
                      to={"/supplier/" + order.supplier._id.toString()}
                    >
                      {order.supplier.name}
                    </Link>
                  </td>
                  <td className="align-middle">
                    <span className="text-success">
                      +{order.amount}
                      {formatArticleUnit(order.unit, order.commodity)}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span className="text-white">{formatDate(order.createdAt)}</span>
                  </td>
                  <td className="align-middle">
                    {" "}
                    <Link
                      className="fw-bolder text-gray-800 fs-6 custom-link"
                      to={"/supplierOrder/" + order._id.toString()}
                    >
                      {getOrderNumber(order)}
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default WorkflowCreateOrderCard;
