import React, { PureComponent } from "react";
import AddNoteModal from "./modals/AddNoteModal";
import ContactPersonWidget from "../../common/ContactPersonWidget";
import { DataContextInternalType } from "../../../context/dataContext";
import { formatDateTime, getDocFromCollection } from "../../../utils/baseUtils";
import { INTERNAL_EXTENDED_ORDER_TYPES } from "../../../utils/orderUtils";
import { CustomerContractExtended } from "../../../model/customerContract.types";

interface OrderNotesProps {
  order: INTERNAL_EXTENDED_ORDER_TYPES | CustomerContractExtended;
  context: DataContextInternalType;
}

class OrderNotes extends PureComponent<OrderNotesProps> {
  render() {
    const { order, context } = this.props;
    return (
      <div className="card bg-white">
        <div className="card-header border-0 mt-5">
          <div className="card-title flex-column">
            <h2 className="mb-1">Notes</h2>
          </div>
          <div className="card-toolbar">
            <AddNoteModal order={order} />
          </div>
        </div>
        <div className="card-body p-9 pt-0">
          <div className="row">
            {order.noteInternal.length > 0 ? (
              order.noteInternal
                .slice()
                .reverse()
                .map((n, idx) => (
                  <React.Fragment key={n._id.toString()}>
                    <div className="col-12 mt-5">
                      <div className="bg-light no-hover p-4">{n.note}</div>
                      <div className="row mt-2 mx-2">
                        <div className="col-6">
                          <ContactPersonWidget
                            person={getDocFromCollection(context.userData, n.person)}
                            spanClasses="text-muted fw-bold d-block"
                          />
                        </div>
                        <div className="col-6 text-right text-muted">{formatDateTime(n.date)}</div>
                      </div>
                    </div>
                    {idx !== order.noteInternal.length - 1 && <div className="border-bottom-dark-gray pt-5" />}
                  </React.Fragment>
                ))
            ) : (
              <div className="col-12 mt-5 text-center fs-5">No note added yet</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default OrderNotes;
