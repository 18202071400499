import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import SampleOrderPage from "../../common/sampleOrder/SampleOrderPage";
import { DataContextInternal } from "../../../../context/dataContext";

interface SampleOrderWrapperParams {
  id: string;
}

interface SampleOrderWrapperProps extends RouteComponentProps<SampleOrderWrapperParams> {}

const SampleOrderWrapper: React.FunctionComponent<SampleOrderWrapperProps> = (props) => {
  const context = useContext(DataContextInternal);
  return <SampleOrderPage context={context} {...props} />;
};

export default SampleOrderWrapper;
