import React, { PureComponent } from "react";
import { Nav, Tab } from "react-bootstrap";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { DataContextInternalType } from "../../../context/dataContext";
import { TabDefinition } from "../../common/CustomTypes";
import CommodityPageGeneral from "./tabPanels/CommodityPageGeneral";
import CommodityPagePrices from "./tabPanels/CommodityPagePrices";
import CommodityPageHistory from "./tabPanels/CommodityPageHistory";
import CommodityPageDocuments from "./tabPanels/CommodityPageDocuments";
import CommodityPageStock from "./tabPanels/CommodityPageStock";
import CommodityPageOrders from "./tabPanels/CommodityPageOrders";
import { isFinishedProduct } from "../../../utils/finishedProductUtils";
import FinishedProductPageGeneral from "../../finishedProducts/internal/tabPanels/FinishedProductPageGeneral";
import { InternalArticleExtended } from "../../../utils/productArticleUtils";
import ArticleSellingPricesTab from "./tabPanels/ArticleSellingPricesTab";

interface CommodityPageTabPanelProps extends RouteComponentProps {
  create: boolean;
  article: InternalArticleExtended;
  context: DataContextInternalType;
}

interface CommodityPageTabPanelState {
  currentTab: string;
}

class CommodityPageTabPanel extends PureComponent<CommodityPageTabPanelProps, CommodityPageTabPanelState> {
  constructor(props: CommodityPageTabPanelProps) {
    super(props);
    const query = new URLSearchParams(props.location.search);
    const tab = query.get("tab");
    const tabDefs = this.getTabDefinitions();
    this.state = { currentTab: tabDefs.find((t) => t.name === tab)?.name || tabDefs[0].name };
  }

  getTabDefinitions = (): Array<TabDefinition> => {
    const { create, article, context } = this.props;
    if (isFinishedProduct(article)) {
      return [
        {
          name: "general",
          tabContent: "General Information",
          component: <FinishedProductPageGeneral finishedProduct={article} context={context} />,
        },
        {
          name: "prices",
          tabContent: "Prices",
          component: <CommodityPagePrices article={article} context={context} />,
          condition: () => !create,
        },
        {
          name: "sellingPrices",
          tabContent: "Selling Prices",
          component: <ArticleSellingPricesTab article={article} />,
          condition: () => !create,
        },
        {
          name: "documents",
          tabContent: "Documents",
          component: <CommodityPageDocuments article={article} context={context} />,
          condition: () => !create,
        },
        {
          name: "orders",
          tabContent: "Orders",
          component: <CommodityPageOrders article={article} context={context} />,
          condition: () => !create,
        },
        {
          name: "stock",
          tabContent: "Stock",
          component: <CommodityPageStock article={article} context={context} />,
          condition: () => !create,
        },
        {
          name: "history",
          tabContent: "History",
          component: <CommodityPageHistory commodity={article} context={context} />,
          condition: () => !create,
        },
      ];
    } else {
      return [
        {
          name: "general",
          tabContent: "General Information",
          component: <CommodityPageGeneral commodity={article} context={context} />,
        },
        {
          name: "prices",
          tabContent: "Prices",
          component: <CommodityPagePrices article={article} context={context} />,
          condition: () => !create,
        },
        {
          name: "sellingPrices",
          tabContent: "Selling Prices",
          component: <ArticleSellingPricesTab article={article} />,
          condition: () => !create,
        },
        {
          name: "documents",
          tabContent: "Documents",
          component: <CommodityPageDocuments article={article} context={context} />,
          condition: () => !create,
        },
        {
          name: "orders",
          tabContent: "Orders",
          component: <CommodityPageOrders article={article} context={context} />,
          condition: () => !create,
        },
        {
          name: "stock",
          tabContent: "Stock",
          component: <CommodityPageStock article={article} context={context} />,
          condition: () => !create,
        },
        {
          name: "history",
          tabContent: "History",
          component: <CommodityPageHistory commodity={article} context={context} />,
          condition: () => !create,
        },
      ];
    }
  };

  /**
   * Handle tab changes
   * @param eventKey new tab
   */
  handleTab = (eventKey: string | null) => {
    if (eventKey && eventKey !== this.state.currentTab) this.setState({ currentTab: eventKey });
  };

  render() {
    const { article, create } = this.props;
    const { currentTab } = this.state;
    const canNotOrder = article.disabled || !article.approved;
    const tabs = this.getTabDefinitions();
    return (
      <Tab.Container id="commodityTabs" activeKey={currentTab} onSelect={this.handleTab}>
        <div className="flex-lg-row-fluid ms-lg-15">
          <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
            {tabs.map(
              (t) =>
                (!t.condition || t.condition(article)) && (
                  <li key={t.name + "_link"} className="nav-item cursor-pointer">
                    <Nav.Link
                      role="tab"
                      id={t.name}
                      eventKey={t.name}
                      active={currentTab === t.name}
                      className="text-active-white pb-4 "
                    >
                      {t.tabContent}
                    </Nav.Link>
                  </li>
                )
            )}
            {!create && (
              <li className="nav-item ms-auto">
                <Link to={canNotOrder ? "" : "/createStockOrder/" + article._id.toString()}>
                  <button
                    disabled={canNotOrder}
                    className={"btn btn-outline btn-outline-light " + (canNotOrder && "disabled")}
                  >
                    New Order
                  </button>
                </Link>
              </li>
            )}
          </ul>
          <Tab.Content>
            {tabs.map(
              (t) =>
                (!t.condition || t.condition(article)) && (
                  <Tab.Pane id={t.name} key={t.name} eventKey={t.name}>
                    {t.component}
                  </Tab.Pane>
                )
            )}
          </Tab.Content>
        </div>
      </Tab.Container>
    );
  }
}
export default withRouter(CommodityPageTabPanel);
