import { BSON } from "realm-web";
import auth from "../services/authService";
import { ADMIN, ANONYMOUS, CUSTOMER, INTERNAL, SUPPLIER } from "../utils/userUtils";
import { UserData } from "../model/userData.types";

function getUser() {
  return auth.getUser();
}
function getUserMail() {
  return auth.getUser()?.profile.email as string;
}

function getUserData(): UserData {
  return BSON.EJSON.deserialize(auth.getUser()?.customData!) as UserData;
}

function getCompany() {
  return (auth.getUser()?.customData.company || "") as string;
}

function getUserType(): typeof INTERNAL | typeof CUSTOMER | typeof SUPPLIER | typeof ANONYMOUS {
  return auth.getUser()?.customData.type as typeof INTERNAL | typeof CUSTOMER | typeof SUPPLIER | typeof ANONYMOUS;
}

function getUserId(): string {
  const id = (auth.getUser()?.customData._id || "") as string | BSON.ObjectId;
  return id.toString();
}

function isAdmin(): boolean {
  return getUserData()?.roles.includes(ADMIN);
}

function getRoles(): Array<string> {
  return getUserData().roles;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getUser,
  getUserMail,
  getUserData,
  getUserType,
  getCompany,
  getUserId,
  getRoles,
  isAdmin,
};
