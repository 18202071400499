import { BSON } from "realm-web";
import { PublicFile } from "./commonTypes";

export const R_EXPIREDPRICES = "expiredPrices";
export const R_SUMMARY = "summary";
export const R_PRICEUPDATEREQUESTS = "priceUpdateRequests";

export const R_DELAYEDORDERS = "delayedOrders";

export interface UserData {
  _id: BSON.ObjectId;
  prename: string;
  surname: string;
  position: string;
  company: string; // Internal or company/supplier/forwarder ID oID as string
  type: "supplier" | "customer" | "forwarder" | "internal" | "anonymous";
  emails: Array<{ value: string; description: string }>; //
  phones: Array<{ value: string; description: string }>;
  roles: Array<string>;
  notificationRoles?: Array<string>;
  image: string;
  notifications: NotificationSettings;
  onboardingDone: boolean;
  userId: string; // MongoDB user ids to match login users with userdata documents
  slackId?: string;
  files?: Array<UserFile>;
}

export interface LastNotifications {
  [reason: string]: Date;
}

export interface NotificationSettings {
  language: string;
  email?: string;
  settings: Array<NotificationSetting>;
  lastNotifications?: LastNotifications;
}

export interface NotificationSetting {
  reason: string;
  enabled: boolean;
  interval: number; // number of days
}

export interface UserFile extends Partial<PublicFile> {
  _id: BSON.ObjectId;
  path: string;
  type: "signature";
  date: Date;
  uploadedBy: string;
}
