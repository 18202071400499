import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import _ from "lodash";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { isFinishedProduct } from "../../../../utils/finishedProductUtils";
import { ArticleExtended } from "../../../../utils/productArticleUtils";

interface CloneCommodityModalProps extends RouteComponentProps {
  originalCommodity: ArticleExtended;
  clonedCommodity: ArticleExtended;
  onHandleCreate: () => void;
}

interface CloneCommodityModalState {
  show: boolean;
}

class CloneCommodityModal extends PureComponent<CloneCommodityModalProps, CloneCommodityModalState> {
  constructor(props: CloneCommodityModalProps) {
    super(props);
    this.state = {
      show: false,
    };
  }

  handleShow = () => this.setState({ show: true });

  handleHide = () => this.setState({ show: false });

  render() {
    const { show } = this.state;
    const { onHandleCreate } = this.props;
    const different = !_.isEqual(this.props.originalCommodity, this.props.clonedCommodity);
    const finishedProduct = isFinishedProduct(this.props.originalCommodity);
    const productNameUpper = finishedProduct ? "Finished Product" : "Commodity";
    const productNameLower = finishedProduct ? "finished product" : "commodity";

    return (
      <>
        <button className="btn btn-outline btn-outline-light btn-sm float-right ml-2" onClick={this.handleShow}>
          Create {productNameUpper} from Clone
        </button>
        <Modal contentClassName={"bg-dark"} show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <CloseButton variant={"white"} onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body className="mx-5 py-0">
            <div className="pb-5">
              <h1 className="fw-bolder d-flex align-items-center text-white">Clone {productNameUpper}</h1>
              <br />
              <div className="row">
                {different ? (
                  <div className="text-muted fw-bold fs-6">
                    Changes were made to cloned {productNameLower}. New {productNameUpper} can be created from cloned
                    one.
                  </div>
                ) : (
                  <div className="text-muted fw-bold fs-6">
                    Cloned {productNameUpper} is the same as original {productNameLower}. Please change something.
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="mx-5">
            <button className="btn  btn-text-danger btn-sm " onClick={this.handleHide}>
              Cancel
            </button>
            {different && (
              <button className="btn btn-outline btn-outline-light btn-sm float-right ml-2" onClick={onHandleCreate}>
                Create {productNameUpper} from Clone
              </button>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default withRouter(CloneCommodityModal);
