import React from "react";
import { WarehouseCostDefinition } from "../../../../model/configuration/calculationConfiguration.types";
import CalculationCostObject from "./CalculationCostObject";

interface WarehouseCostViewProps {
  warehouseCost: WarehouseCostDefinition;
  onChangeWarehouseCost: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
}

const WarehouseCostView: React.FunctionComponent<WarehouseCostViewProps> = ({
  warehouseCost,
  onChangeWarehouseCost,
}) => {
  const { generalCost } = warehouseCost;
  return (
    <>
      <h3 className="mb-3 mt-7">Warehouse</h3>
      <div className="row">
        {generalCost.map((gC, index) => (
          <CalculationCostObject
            key={index.toString()}
            sizeClassName={"col-xl-4 " + (index > 2 && " mt-2")}
            cost={gC}
            onChangeCost={(e) => onChangeWarehouseCost(e, index)}
          />
        ))}
      </div>
    </>
  );
};

export default WarehouseCostView;
