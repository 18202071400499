import React, { useContext } from "react";
import { DataContextInternal } from "../../../../context/dataContext";
import CreateStorageOrder from "./CreateStorageOrder";

const StorageOrderWrapper: React.FunctionComponent = () => {
  const context = useContext(DataContextInternal);
  return <CreateStorageOrder context={context} />;
};

export default StorageOrderWrapper;
