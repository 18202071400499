import _ from "lodash";
import React, { PureComponent } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { getOrderNumber, isSupplierOrder, O_TRANSPORTTYPES_FORWARDINGORDER } from "../../../../utils/orderUtils";
import { resolveFilePath } from "../../../../utils/fileUtils";
import { toAbsoluteUrl } from "../../../../utils/baseUtils";
import { ForwardingOrder, FWO_STATES, FWO_TIMELINE } from "../../../../model/forwardingOrder.types";
import { DataContextInternal } from "../../../../context/dataContext";
import { CO_T_DOCUMENTREMOVED, CustomerOrder } from "../../../../model/customerOrder.types";
import { SO_T_DOCUMENTREMOVED, SupplierOrder } from "../../../../model/supplierOrder.types";
import SimpleConfirmationModal from "../../../common/SimpleConfirmationModal";
import {
  getForwardingOrderTimelineEntry,
  getFWOTransportTypeLabel,
  getOrdersFromFWO,
} from "../../../../utils/forwardingOrderUtils";
import { Action, CUSTOMERORDER, FORWARDINGORDER, SUPPLIERORDER, transaction } from "../../../../services/dbService";
import { getSupplierOrderTimelineEntry, SO_FORWARDINGORDER } from "../../../../utils/supplierOrderUtils";
import { CO_FORWARDINGORDER, getCustomerOrderTimelineEntry } from "../../../../utils/customerOrderUtils";
import { formatDateWithType } from "../../../../utils/logisticsUtils";

interface ForwardingOrderRowProps extends RouteComponentProps {
  context: React.ContextType<typeof DataContextInternal>;
  forwardingOrder: ForwardingOrder;
}

interface ForwardingOrderRowState {
  saving: boolean;
}

class ForwardingOrderRow extends PureComponent<ForwardingOrderRowProps, ForwardingOrderRowState> {
  constructor(props: ForwardingOrderRowProps) {
    super(props);
    this.state = { saving: false };
  }

  handleCancelOrder = async () => {
    const { context } = this.props;
    const forwardingOrder = _.cloneDeep(this.props.forwardingOrder);
    this.setState({ saving: true });
    try {
      const actions: Array<Action> = [
        {
          collection: FORWARDINGORDER,
          filter: { _id: forwardingOrder._id },
          update: {
            state: FWO_STATES.FWO_CANCELED,
          },
          push: {
            timeline: {
              $each: [getForwardingOrderTimelineEntry(FWO_TIMELINE.FWO_T_CANCELED)],
            },
          },
        },
      ];

      // remove the forwarding order files from supplier or customer orders
      const allOrders = getOrdersFromFWO(forwardingOrder, context);
      for (let i = 0; i < allOrders.length; i++) {
        const order = allOrders[i];
        const file = order?.files.find((f) => f.reference === forwardingOrder._id.toString());
        if (file && order) {
          const newFiles = order.files.filter((f) => f._id.toString() !== file._id.toString());
          actions.push({
            collection: isSupplierOrder(order) ? SUPPLIERORDER : CUSTOMERORDER,
            filter: { _id: order._id },
            update: {
              files: newFiles,
            },
            push: {
              timeline: {
                $each: [
                  isSupplierOrder(order)
                    ? getSupplierOrderTimelineEntry(SO_T_DOCUMENTREMOVED, { type: SO_FORWARDINGORDER })
                    : getCustomerOrderTimelineEntry(CO_T_DOCUMENTREMOVED, { type: CO_FORWARDINGORDER }),
                ],
              },
            },
          });
        }
      }
      const result = await transaction(actions);
      if (result) {
        toast.success(`Forwarding order canceled successfully`);
      } else toast.error(`Canceling forwarding order failed. Please try again later`);
    } catch (e) {
      toast.error(`Canceling forwarding order failed: ` + e);
    } finally {
      this.setState({ saving: false });
    }
  };

  getRelatedOrders = () => {
    const { forwardingOrder, context } = this.props;
    const relatedOrders: Array<SupplierOrder | CustomerOrder> = [];
    for (let i = 0; i < forwardingOrder.orderInformation.length; i++) {
      const id = forwardingOrder.orderInformation[i].orderId;
      let order: SupplierOrder | CustomerOrder | undefined = context.supplierOrder.find(
        (sO) => sO._id.toString() === id
      );
      if (!order) {
        order = context.customerOrder.find((cO) => cO._id.toString() === id);
      }
      if (order) relatedOrders.push(order);
    }
    return relatedOrders.map((rO) => {
      return (
        <div key={rO.orderNo}>
          <Link
            className="custom-link text-white"
            to={(isSupplierOrder(rO) ? "/supplierOrder/" : "/customerOrder/") + rO._id.toString()}
          >
            {getOrderNumber(rO)}
          </Link>
        </div>
      );
    });
  };

  /**
   * Constructs an email subject line based on the forwarding order details and opens a new email window with the constructed subject.
   */
  getMailTemplate = () => {
    const { forwardingOrder, context } = this.props;
    const orders = getOrdersFromFWO(forwardingOrder, context);
    const transportType = getFWOTransportTypeLabel(forwardingOrder.transportType) || "";
    const fwNumber = `FW-${forwardingOrder.forwardingOrderNo}`;
    const article = orders && orders.length > 0 ? orders[0].commodity.title.en : "";
    const incotermFrom = forwardingOrder.takeOver.startIncoterm;
    const cityFrom = forwardingOrder.takeOver.startingFrom;
    const destinationIncoterms = forwardingOrder.orderInformation.map((oI) => oI.destinationIncoterm).join(", ");
    const cityTo = forwardingOrder.orderInformation.map((oI) => oI.destinationTo).join(", ");
    const netWeight = forwardingOrder.orderInformation.map((oI) => oI.netWeight).join(", ");
    const subject = `${fwNumber} ${article} | Transporttype: ${transportType} | Incoterm: ${incotermFrom} ${cityFrom} - ${destinationIncoterms} ${cityTo} | Netweight (kg): ${netWeight}`;
    window.location.href = `mailto:?subject=${subject}`;
  };

  relatedOrderNos = this.getRelatedOrders();

  render() {
    const { forwardingOrder, history } = this.props;
    const { saving } = this.state;
    return (
      <tr>
        <td className="text-light align-middle">FW-{forwardingOrder.forwardingOrderNo}</td>
        <td className="text-light align-middle">{this.relatedOrderNos || "-"}</td>
        <td className="text-light align-middle">
          {O_TRANSPORTTYPES_FORWARDINGORDER.find((t) => t.value === forwardingOrder.transportType)?.label}
        </td>
        <td className="text-light align-middle">
          {forwardingOrder.state !== FWO_STATES.FWO_CANCELED
            ? forwardingOrder.orderInformation
                .map((oi) => formatDateWithType(oi.deliveryDate, oi.deliveryDateType))
                .join(", ")
            : "Canceled"}
        </td>
        <td className="text-light align-middle">{forwardingOrder.returnOrder ? "Yes" : "No"}</td>
        <td className="text-light align-middle">
          <a href={resolveFilePath(forwardingOrder.file)} target="_blank" rel="noopener noreferrer">
            <img src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} style={{ height: 24 }} alt="PDF" />
          </a>
        </td>
        <td className="text-light align-middle text-right">
          <Dropdown>
            <Dropdown.Toggle
              className="btn btn-sm btn-light btn-active-light"
              disabled={forwardingOrder.state === FWO_STATES.FWO_CANCELED}
            >
              Action
            </Dropdown.Toggle>
            <Dropdown.Menu className="custom-dropdown">
              <Dropdown.Item
                className={"py-2 text-white"}
                onClick={() => {
                  this.getMailTemplate();
                }}
              >
                Send Mail
              </Dropdown.Item>
              <Dropdown.Item
                className={"py-2 text-white"}
                onClick={() => history.push("/createForwardingOrder/" + forwardingOrder._id.toString())}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item>
                <SimpleConfirmationModal.SimpleConfirmationModalButton
                  onConfirm={this.handleCancelOrder}
                  confirmButtonText="Confirm"
                  cancelButtonText="Close"
                  buttonText="Cancel"
                  buttonClasses="btn btn-sm btn-outline py-2 text-danger pl-0"
                  modalTitle="Cancel Forwarding Order"
                  saving={saving}
                  modalDescription={
                    <span className="text-white">
                      Do you really want to cancel the forwarding order FW-{forwardingOrder.forwardingOrderNo}?
                    </span>
                  }
                />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  }
}

export default withRouter(ForwardingOrderRow);
