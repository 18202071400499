import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { SAMO_STATE, SampleOrderExtended } from "../../../../model/sampleOrder.types";
import OpenCommodityIcon from "../../../common/OpenCommodityIcon";
import { calculateArrivalInformation, getOrderNumber, getOrderStateDescriptions } from "../../../../utils/orderUtils";
import { formatDate } from "../../../../utils/baseUtils";
import { getUserName } from "../../../../utils/userUtils";
import { CustomerSampleOrderExtended } from "../../../../model/customer/customerSampleOrder.types";
import ReactStarsWrapper from "../../../common/ReactStarsWrapper";

interface SampleOrderSummaryProps {
  order: SampleOrderExtended | CustomerSampleOrderExtended;
}

class SampleOrderSummary extends PureComponent<SampleOrderSummaryProps> {
  render() {
    const { order } = this.props;
    const stateDescription = getOrderStateDescriptions(order);
    const arrival = calculateArrivalInformation(order);

    return (
      <div className="card mb-5 mb-xl-8 bg-white">
        <div className="card-body">
          <div className="d-flex flex-center flex-column py-5">
            <span className="fs-2 text-gray-800 fw-bolder mb-3">{getOrderNumber(order)}</span>
            <div className="fs-6 fw-bold text-muted ">
              {order.commodity.title.en}
              <OpenCommodityIcon article={order.commodity} />
            </div>
            <div className="fw-bold text-muted mb-3">{order.commodity.subtitle.en}</div>
            {order.state === SAMO_STATE.REQUESTED ? (
              <div className="mb-6 mt-3">
                <span className="badge fw-bolder badge-lg my-2 badge-warning">Requested</span>
              </div>
            ) : order.state === SAMO_STATE.CANCELED ? (
              <div className="mb-6 mt-3">
                <span className="badge fw-bolder badge-lg my-2 badge-danger">Canceled</span>
              </div>
            ) : order.state === SAMO_STATE.REJECTED ? (
              <div className="mb-6 mt-3">
                <span className="badge fw-bolder badge-lg my-2 badge-danger">Rejected</span>
              </div>
            ) : (
              <div className="mb-6 mt-3">
                <span
                  className="badge fw-bolder badge-lg my-2"
                  style={{ backgroundColor: arrival.late && arrival.target ? "#f15151" : "#50cc89ff" }}
                >
                  {arrival.target ? (arrival.late ? "Is late" : "In time") : "Delivered"}
                </span>
              </div>
            )}
          </div>
          <div className="d-flex flex-stack fs-4 py-3">
            <div className="fw-bolder">Order Details</div>
          </div>
          <div className="border-bottom-dark-gray" />
          <div className="pb-5 fs-6">
            <div className="fw-bolder mt-5">Amount</div>
            <div className="text-muted">
              {order.amount} {order.unit}
            </div>
            <div className="fw-bolder mt-5">Type</div>
            <div className="text-muted">Sample Order</div>
            <div className="fw-bolder mt-5">Status</div>
            <div className={order.state === SAMO_STATE.CANCELED ? "text-danger" : "text-warning"}>
              {stateDescription.title}
            </div>
            <div className="fw-bolder mt-5">{arrival.target ? "Target Week" : "Delivery Week"}</div>
            <div className="text-muted">
              Week {arrival.cw}-{arrival.year}
            </div>
            <div className="fw-bolder mt-5">Last Update</div>
            <div className="text-muted">
              {formatDate(order.timeline.length > 0 ? order.timeline[order.timeline.length - 1].date : order.createdAt)}
            </div>
            <div className="fw-bolder mt-5">Customer</div>
            <div>
              <Link className="text-muted custom-link" to={"/customer/" + order.company._id.toString()}>
                {order.company.name}
              </Link>
            </div>
            <div className="fw-bolder mt-5">Created by</div>
            <div className="text-muted">{getUserName(order.person)}</div>
            {order.sampleRating && (
              <>
                <div className="fw-bolder mt-5">Sample Rating ({formatDate(order.sampleRating.ratingDate)})</div>
                <ReactStarsWrapper value={order.sampleRating.rating} count={5} size={16} />
                <div className="text-muted">{order.sampleRating.ratingText}</div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SampleOrderSummary;
