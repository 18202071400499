import _ from "lodash";
import { CustomerOrderExtended, CustomerOrderShippingInformation } from "../../model/customerOrder.types";
import { formatDate, round } from "../baseUtils";
import { B_PACKAGE_DICT, B_PACKAGE_DICT_PLURAL } from "../batchUtils";
import { DI_UNITLOAD } from "../deliveryInformationUtils";
import { getOrderNumber } from "../orderUtils";
import { formatAddress } from "../addressUtils";
import { ORGANICCODE } from "./templateUtils";
import { formatArticleUnit, isAnyFinishedProduct } from "../productArticleUtils";

/**
 * Create a delivery note html string for pdf generation
 * @param order the customer order
 * @param shippingInformation the shipping information to be displayed
 * @param deliveryAddress html string of delivery address
 * @param draft optional, flag if a draft is being generated
 * @returns {string} a html string for pdf generation
 */
export function createDeliveryNoteHTML(
  order: CustomerOrderExtended,
  shippingInformation: CustomerOrderShippingInformation,
  deliveryAddress: string,
  draft: boolean
): string {
  const { company, commodity } = order;
  const address = company.address[0];
  return `<head>
  <link href="https://fonts.googleapis.com/css?family=Signika&display=swap" rel="stylesheet">
  <style>tr:nth-child(even) {
      background-color: white;
  }</style>
  <meta http-equiv="content-type" content="text/html; charset=utf-8">
</head>
<body style="font-family: Signika, sans-serif"><img src="https://rawbids.com/images/logo-dark.png" alt="Logo" width="180"
style="float:right" /><br /><br /><br /><br /><span
  style="font-size: 30px; font-weight: bold">Delivery Note ${draft ? "DRAFT" : getOrderNumber(order)}</span><br /><span
  style="font-size: 20px; font-weight: bold">${commodity.title.en}</span><br /><br /><br />
<table cellpadding="5" cellspacing="0" style="width: 100%">
  <tr style="width: 100%; vertical-align: top">
    <td style="vertical-align: top"><span style="font-size:16px;  width: 33%; vertical-align: top">Recipient:<br /><b>${
      company.name
    }</b><br />${formatAddress(address)}<br />VAT-ID: ${company.vat}</span>
      </td>
    <td style="vertical-align: top"><span style="font-size:16px; width: 33%; vertical-align: top">Issued by:<br /><b>RAWBIDS GmbH</b><br />Willy-Brandt-Str. 23, DE - 20457 Hamburg<br />Germany<br />VAT-ID: DE354508196</span>
    </td>
    <td style="text-align: right; vertical-align: top"><span style="font-size:16px; width: 33%; vertical-align: top"><b>Order number: ${
      draft ? "DRAFT" : getOrderNumber(order)
    }</b><br />Date: ${formatDate(new Date())}    ${
    order.customerReference && `<br/><span>Reference number: ${order.customerReference}</span>`
  }
    ${commodity.organic ? `<br/>${ORGANICCODE}` : `<br/>`}
    <br />Contact: info@rawbids.com</span>
      </td>
  </tr>
</table>
<br /><span style="font-size:22px; font-weight: bold;">Delivery Information:</span><br />
<table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px">
  <thead>
  <tr style="background-color:#333333; color: #ffffff">
    <th style="min-width: 150px; text-align: left"><b style="white-space:nowrap">Description</b></th>
    <th style="text-align: left"><b>Batch No.</b></th>
    <th style="text-align: left"><b>Expiration</b></th>
    <th style="text-align: left"><b style="white-space:nowrap;">Packages</b></th>
    <th style="text-align: left"><b style="white-space:nowrap;">Net Weight</b></th>
  </tr>
  </thead>
  <tbody>
  ${shippingInformation.shippingGroups
    .map((sg) => {
      const packaging =
        sg.packageAmount > 1 && isAnyFinishedProduct(commodity)
          ? (_.get(B_PACKAGE_DICT_PLURAL, sg.packageType) as string | undefined) || "Unknown"
          : (_.get(B_PACKAGE_DICT, sg.packageType) as string | undefined) || "Unknown";
      return `<tr>
    <td><b>${sg.description}</b></td>
    <td>${sg.lot} <br/> ${sg.supplierLot ? "(LOT: " + sg.supplierLot + ")" : ""}</td>
    <td>${formatDate(sg.expiry)}</td>
    <td>${
      isAnyFinishedProduct(commodity)
        ? `${sg.packageAmount} ${packaging} à ${sg.packageNetWeight}${formatArticleUnit(order.unit, order.commodity)}`
        : `${sg.packageAmount} x ${sg.packageNetWeight}${formatArticleUnit(order.unit, order.commodity)}/${packaging}`
    }</td>
        <td>${round(sg.packageAmount * sg.packageNetWeight, 2)}kg</td>
  </tr>`;
    })
    .join("")}
  <tr>
    <td colspan="3"></td>
    <td style="text-align: right">Total (net):</td>
    <td><b>${shippingInformation.shippingGroups.reduce(
      (a, b) => round(a + b.packageNetWeight * b.packageAmount, 2),
      0
    )}kg</b></td>
  </tr>
  </tbody>
</table>
<br/>
<br />
<table style="font-size:16px; width: 100%; display: block">
  <tbody>
  <tr>
    <td>
      <div>
        <div><b>${
          DI_UNITLOAD.find((ul) => ul.value === shippingInformation.unitLoad)?.label || shippingInformation.unitLoad
        }: </b>${shippingInformation.unitLoadAmount}</div>
      </div>
       <div>
        <div><b>Gross Weight: </b>${shippingInformation.grossWeight || ""} kg</div>
      </div>
      <div>
        <div><b>Ship Via: </b>${shippingInformation.shipVia}</div>
      </div>
      <div>
        <div><b>Est. Delivery Date: </b>${formatDate(shippingInformation.estimatedDeliveryDate)}</div>
      </div>
      <br />
      <div><b>Additional Information: </b>${shippingInformation.additionalInformation}</div>
    </td>
  </tr>
  </tbody>
</table>
<br /><br />
<span style="font-size:22px; font-weight: bold;">Delivery Address:</span><br /><br />
<div style="background-color: #fafafa; padding: 10px; width: 50%"><span style="font-size:25px;">${deliveryAddress}
</div>
<br />
<br />
<br />
<div> With kind regards and best wishes<br /><strong>Commodity
  Trading Systems GmbH</strong></div>
<br /><br /><br />
<b><i>This document is valid without signature.</i></b>
</body>`;
}
