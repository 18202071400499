import React, { FormEvent, PureComponent } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { toAbsoluteUrl } from "../../utils/baseUtils";
import authService from "../../services/authService";

interface PasswordResetProps extends RouteComponentProps {}

interface PasswordResetState {
  email: string;
  sending: boolean;
  success: boolean;
}

class PasswordResetRequest extends PureComponent<PasswordResetProps, PasswordResetState> {
  constructor(props: PasswordResetProps) {
    super(props);
    this.state = {
      // Note always init with empty string or value
      email: "",
      sending: false,
      success: false,
    };
  }

  handleResetPassword = async (e: FormEvent) => {
    // Disable default behavior
    e.preventDefault();
    this.setState({ sending: true });
    try {
      const { email } = this.state;
      if (await authService.userExistsAndConfirmed(email)) await authService.sendResetPasswordMail(email);
    } catch (ex) {
      console.error(ex);
    } finally {
      this.setState({ sending: false });
    }
    this.setState({ success: true, email: "" });
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ email: e.target.value });

  render() {
    if (authService.getUser()) return <Redirect to="/" />;
    const { email, success, sending } = this.state;
    return (
      <div
        className="d-flex flex-column"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/assets/media/misc/bg-1.jpg")})`,
          height: "100%",
          backgroundSize: "cover",
          justifyContent: "center",
        }}
      >
        <div className="d-flex flex-column flex-column flex-lg-row">
          <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
            <div className="d-flex flex-center flex-lg-start flex-column">
              <img alt="Logo" src={toAbsoluteUrl("/assets/media/logo.png")} className="mb-7 h-80px h-80px" />
              <h2 className="text-white fw-bold m-0">The biggest virtual marketplace for premium ingredients.</h2>
            </div>
          </div>
          <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20">
            <div
              className="bg-body d-flex flex-column align-items-stretch flex-center w-md-600px p-20 border-none"
              style={{ borderRadius: "1.5rem" }}
            >
              <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
                <form className="form w-100" onSubmit={success || sending ? undefined : this.handleResetPassword}>
                  <div className="text-center mb-11">
                    <h1 className="text-dark fw-bolder mb-3 fs-2x">Password Reset</h1>
                    {success ? (
                      <p className="text-center text-success">
                        An email with a password reset link was sent to the given address.
                      </p>
                    ) : (
                      <p className="text-dark">Please enter your email to request a password reset link:</p>
                    )}
                  </div>
                  <div className="fv-row mb-8">
                    <input
                      name="email"
                      id="login-email"
                      className="form-control form-control-lg form-control-solid"
                      type="email"
                      placeholder="Email"
                      autoComplete={"off"}
                      value={email}
                      required={true}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="d-grid mb-10">
                    <button
                      className={"btn btn-dark" + (success || sending ? " disabled" : "")}
                      type="submit"
                      disabled={success || sending}
                    >
                      {sending ? (
                        <span className="indicator-progress d-block">
                          Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      ) : (
                        <span className="indicator-label">Reset Password</span>
                      )}
                    </button>
                  </div>
                  <div className="text-gray-500 text-center fw-semibold ">
                    Not a Member yet?
                    <a href="https://rawbids.com/register" className="link-dark ml-2">
                      Register Now
                    </a>
                  </div>
                </form>
              </div>
              <div className="d-flex flex-stack px-lg-10">
                <div className="me-0">
                  <button className="btn btn-flex btn-link fs-base disabled">
                    <img
                      data-kt-element="current-lang-flag"
                      className="w-20px h-20px rounded me-3"
                      src={toAbsoluteUrl("assets/media/flags/united-states.svg")}
                      alt=""
                    />
                    <span data-kt-element="current-lang-name" className="me-1 link-dark">
                      English
                    </span>
                  </button>
                </div>
                <div className="d-flex text-primary fs-base gap-5">
                  <a
                    href="https://rawbids.com/files/terms_of_purchase.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-dark"
                  >
                    Terms of Purchase
                  </a>
                  <a
                    href="https://rawbids.com/files/terms_of_sale.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-dark"
                  >
                    Terms of Sale
                  </a>
                  <a href="mailto:info@rawbids.com" className="link-dark">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PasswordResetRequest;
