import React, { useContext, useState } from "react";
import { Accordion, AccordionContext, useAccordionButton } from "react-bootstrap";
import { CustomToggleProps } from "./CustomToggle";
import BaseListing from "./BaseListing";
import { B_PACKAGE_DICT, B_WAREHOUSE_OPTIONS, getBatchAmount, getStockPackageType } from "../../utils/batchUtils";
import { Batch } from "../../model/batch.types";
import Tooltip from "./Tooltip";
import { resolveFilePath } from "../../utils/fileUtils";
import { formatDate, toAbsoluteUrl } from "../../utils/baseUtils";
import { getDaysUntil } from "../../utils/dateUtils";
import OrderBatchStateDropdown from "./internal/OrderBatchStateDropdown";
import UpdateBatchStateModal from "../commodities/internal/modals/UpdateBatchStateModal";
import { formatArticleUnit } from "../../utils/productArticleUtils";

interface OrderBatchAccordionProps {
  batches: Array<Batch>;
}

const OrderBatchAccordion: React.FunctionComponent<OrderBatchAccordionProps> = ({ batches }) => {
  const headerDefinition = [
    { title: "Lot", style: { width: "30%" } },
    { title: "Amount", style: { width: "20%" } },
    { title: "Type", style: { width: "15%" } },
    { title: "Warehouse", style: { width: "20%" } },
    { title: "Location", style: { width: "15%" } },
  ];
  return (
    <Accordion defaultActiveKey={undefined} alwaysOpen={true}>
      {batches.map((batch) => (
        <React.Fragment key={batch._id.toString()}>
          <CustomContextToggle batch={batch} eventKey={batch._id.toString()} />
          <Accordion.Collapse eventKey={batch._id.toString()}>
            <BaseListing
              headerDefinition={headerDefinition}
              bodyContent={
                <>
                  {batch.packages.map((p) => (
                    <tr key={p._id.toString()} className="py-5 mb-1">
                      <td className="mt-2">
                        <span className="text-muted fw-bold">
                          RB{batch.identifier}-{p.number}
                        </span>
                      </td>
                      <td className="mt-2">
                        <span className="text-muted fw-bold">
                          {p.amountEach}
                          {formatArticleUnit(batch.unit, batch.commodity)}
                        </span>
                      </td>
                      <td className="mt-2">
                        <span className="text-muted fw-bold">{B_PACKAGE_DICT[p.packageType]}</span>
                      </td>
                      <td className="mt-2">
                        <span className="text-muted fw-bold">
                          {B_WAREHOUSE_OPTIONS.find((wo) => wo.value === p.warehouse)?.label ?? "Unknown"}
                        </span>
                      </td>
                      <td className="mt-2">
                        <span className="text-muted fw-bold">{p.warehouseLocation}</span>
                      </td>
                    </tr>
                  ))}
                </>
              }
            />
          </Accordion.Collapse>
        </React.Fragment>
      ))}
    </Accordion>
  );
};

interface CustomContextToggleProps extends Omit<CustomToggleProps, "children"> {
  batch: Batch;
}

export const CustomContextToggle: React.FunctionComponent<CustomContextToggleProps> = ({
  batch,
  eventKey,
  callback,
}) => {
  const [showBatchState, setShowBatchState] = useState<false | "release">(false);

  const accordionContext = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));
  const stockedDate = batch.stockedDate;
  const sinceStocked = stockedDate && Math.abs(+getDaysUntil(stockedDate));

  return (
    <div className="row" onClick={decoratedOnClick}>
      <div className="col-auto my-auto">
        <h5 className="mb-0 cursor-pointer">
          <i
            className={
              "custom-accordion-toggle fa fa-chevron-up mr-2 " +
              (accordionContext.activeEventKey &&
                (accordionContext.activeEventKey === batch._id.toString() ||
                  (Array.isArray(accordionContext.activeEventKey) &&
                    accordionContext.activeEventKey.includes(batch._id.toString()))) &&
                "show")
            }
          />
          Batch {`RB${batch.identifier}`}
        </h5>
      </div>
      <div className="col my-auto" onClick={(e) => e.stopPropagation()}>
        <OrderBatchStateDropdown batch={batch} />
      </div>
      <div className="col-2 my-auto">
        <Tooltip tooltipText={"Supplier CoA"}>
          <a
            href={resolveFilePath(batch.supplierCoA.path)}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            <img alt="supplierCoA" className="w-30px mr-2" src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} />
          </a>
        </Tooltip>
        {batch.ownCoA ? (
          <Tooltip tooltipText={"Rawbids CoA"}>
            <a
              href={resolveFilePath(batch.ownCoA.path)}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              <img alt="supplierCoA" className="w-30px mr-2" src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} />
            </a>
          </Tooltip>
        ) : (
          <Tooltip tooltipText={"Rawbids CoA missing"}>
            <img
              alt="Rawbids CoA"
              className="w-30px mr-2 image-gray"
              src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
              onClick={() => setShowBatchState("release")}
            />
          </Tooltip>
        )}
      </div>
      <div className="col-2 align-self-center">
        <div className="text-white fs-6 fw-bolder">
          {getBatchAmount(batch)}
          {formatArticleUnit(batch.unit, batch.commodity)}
        </div>
        <div className="text-muted fs-7">{getStockPackageType(batch)}</div>
      </div>
      <div className="col-2 align-self-center">
        {stockedDate && sinceStocked && (
          <>
            <div className="text-white fs-6 fw-bolder">{formatDate(stockedDate)}</div>
            <div className="text-muted fs-7">
              {sinceStocked < 1
                ? "Today"
                : Math.round(sinceStocked) === 1
                ? "Yesterday"
                : `${Math.round(sinceStocked)} days ago`}
            </div>
          </>
        )}
      </div>
      <UpdateBatchStateModal
        batchToHandle={showBatchState ? { batch, type: "release" } : undefined}
        onClose={() => setShowBatchState(false)}
      />
    </div>
  );
};

export default OrderBatchAccordion;
