import { BSON } from "realm-web";
import { DataContextType } from "../context/dataContext";
import { SN_TYPE, SystemNotification, SystemNotificationConditions } from "../model/systemNotification.types";
import { Commodity, CommoditySnapshot } from "../model/commodity.types";
import { CustomerCommodity } from "../model/customer/customerCommodity.types";
import { CO_TYPES } from "../model/customerOrder.types";
import { getCountryNameForCode } from "./baseUtils";
import { SelectOption } from "../components/common/CustomSelect";
import { callFunction, getDb, SYSTEMNOTIFICATION } from "../services/dbService";

const UPSERTSYSTEMNOTIFICATION = "upsertSystemNotification" as const;

interface RelevantSystemNotificationOptions {
  commodity?: Commodity | CustomerCommodity | CommoditySnapshot;
  transport?: CO_TYPES;
}

export const SYSTEM_NOTIFICATION_OPTIONS: Array<SelectOption> = [
  { value: SN_TYPE.SN_T_ORDERMASK, label: resolveTypeText(SN_TYPE.SN_T_ORDERMASK) },
];

export interface SystemNotificationConditionsInput {
  commodities?: Array<SelectOption>;
  origins?: Array<SelectOption>;
  transport?: Array<SelectOption>;
}

/**
 * Retrieves all relevant system notifications based upon the given type and options
 * @param type Type of system notifications that should be returned
 * @param context DataContext contains the system notifications
 * @param options Optional parameters to match the conditions of the system notification
 * @returns {Array<SystemNotification>} List of filtered relevant system notifications
 */
export function getRelevantSystemNotifications(
  type: SN_TYPE,
  context: DataContextType,
  options: RelevantSystemNotificationOptions
): Array<SystemNotification> {
  const { commodity, transport } = options;
  const now = new Date();
  return context.systemNotification.filter((sN) => {
    const { condition, type: snType, validUntil } = sN;
    let additionalCondition = true;
    if (condition) {
      if (commodity) {
        if (condition.commodities) additionalCondition = condition.commodities.includes(commodity._id.toString());
        if (!additionalCondition && condition.origins)
          additionalCondition = condition.origins.includes(commodity.country.code);
      }
      if (transport) {
        if (condition.transport) additionalCondition = condition.transport.includes(transport);
      }
    }
    return validUntil > now && snType === type && additionalCondition;
  });
}

/**
 * Resolves the text corresponding to the type of the system notification
 * @param type Type that should be resolved
 * @returns {string} Resolved text
 */
export function resolveTypeText(type: SN_TYPE): string {
  switch (type) {
    case SN_TYPE.SN_T_ORDERMASK:
      return "Order Mask Information";
  }
}

/**
 * Transforms the conditions of a system notification to a proper formatted list of strings.
 * @param conditions Conditions of the system notification
 * @param commodityCollection Commodities, needed for resolving them
 * @returns {Array<string>} Resolved system notification conditions
 */
export function resolveConditionsText(
  conditions: SystemNotificationConditions,
  commodityCollection: Array<Commodity>
): Array<string> {
  const { commodities, origins, transport } = conditions;
  const conditionsText = [];
  conditionsText.push(
    `Commodities: ${
      commodities
        ? commodities
            .map((c) => commodityCollection.find((cC) => cC._id.toString() === c)?.title.en || "Unknown")
            .join(",")
        : "All"
    }`
  );
  conditionsText.push(`Origins: ${origins ? origins.map((o) => getCountryNameForCode(o)).join(",") : "All"}`);
  conditionsText.push(`Transport: ${transport ? transport.map((t) => t).join(",") : "All"}`);
  return conditionsText;
}

/**
 * Inserts a system notification into the database.
 * @param systemNotification Object that should be inserted into the database
 * @returns {Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>>} Result of the insert
 */
export async function insertSystemNotification(
  systemNotification: SystemNotification
): Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>> {
  return callFunction(UPSERTSYSTEMNOTIFICATION, [systemNotification, true]);
}

/**
 * Updates a system notification inside the database.
 * @param systemNotification Partial object that contains the updates
 * @param id ID of the system notification
 * @returns {Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>>} Result of the update
 */
export async function updateSystemNotification(
  systemNotification: Partial<SystemNotification>,
  id: BSON.ObjectId
): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>> {
  systemNotification._id = id;
  return callFunction(UPSERTSYSTEMNOTIFICATION, [systemNotification, false]);
}

/**
 * Deletes the referenced system notification from the database.
 * @param _id ID of the system notification that should be deleted
 * @returns {Promise<Realm.Services.MongoDB.DeleteResult | undefined>}
 */
export async function deleteSystemNotification(
  _id: BSON.ObjectId
): Promise<Realm.Services.MongoDB.DeleteResult | undefined> {
  return getDb()?.collection(SYSTEMNOTIFICATION).deleteOne({ _id });
}
