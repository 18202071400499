import { pluralize } from "./baseUtils";

export const DAY_IN_MS = 1000 * 60 * 60 * 24;

/**
 * Get the days remaining until the current date is reached
 * @param date a date
 * @param round optional, if set the result is rounded
 * @returns {number} number of days until the date
 */
export function getDaysUntil(date: Date, round?: boolean): number {
  return getDaysBetween(date, new Date(), round);
}

/**
 * Get the days remaining until the current date is reached
 * @param date1 a date
 * @param date2 another date
 * @param round optional, if set the result is rounded
 * @returns {number} number of days until the date
 */
export function getDaysBetween(date1: Date, date2: Date, round?: boolean): number {
  const diffTime = date1.valueOf() - date2.valueOf();
  const res = diffTime / (1000 * 60 * 60 * 24);
  return round ? Math.round(res) : res;
}

/**
 * Get calendar week for a date
 * @param date a date
 * @returns {number} the calendar week for the date
 */
export function getCW(date: Date): number {
  const tdt = new Date(date.valueOf());
  const dayn = (date.getDay() + 6) % 7;
  tdt.setDate(tdt.getDate() - dayn + 3);
  const firstThursday = tdt.valueOf();
  tdt.setMonth(0, 1);
  if (tdt.getDay() !== 4) {
    tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
  }
  return 1 + Math.ceil((firstThursday - tdt.valueOf()) / 604800000);
}

/**
 * Formats a date as CW [CW-YYYY]
 * @param date the date to format
 * @param prefix optional, if given will be used instead of CW at the beginning
 * @returns {string} the formatted date
 */
export function getFullCWString(date: Date, prefix?: string): string {
  return `${prefix ?? "CW"} ${getCW(date)}-${date.getFullYear()}`;
}

/**
 * Get a string representing the difference between the given date and the current date.
 * @param date Optional, a date
 * @returns { string } Represents the time difference
 */
export function getTimeDiffString(date?: Date): string {
  if (!date) {
    return "-";
  }
  const date1 = date;
  const date2 = new Date();
  const diffTime = date1.getTime() - date2.getTime();

  const diffHours = diffTime / (1000 * 60 * 60);
  const diffDays = diffTime / (1000 * 60 * 60 * 24);

  if (diffTime / (1000 * 60) <= 1 && diffTime / (1000 * 60) > 0) {
    return "today";
  }
  if (diffHours <= 1 && diffHours > 0) {
    return "in " + pluralize(Math.ceil(diffTime / (1000 * 60)), "minute");
  }
  if (diffHours <= 24 && diffHours > 0) {
    return "in " + pluralize(Math.ceil(diffTime / (1000 * 60 * 60)), "hour");
  }
  if (diffHours >= -1 && diffHours <= 0) {
    return "since " + pluralize(Math.abs(Math.ceil(Math.ceil(diffTime / (1000 * 60)))), "minute");
  }
  if (diffHours >= -24 && diffHours <= 0) {
    return "since " + pluralize(Math.abs(Math.ceil(diffTime / (1000 * 60 * 60))), "hour");
  }
  if (diffDays > 0 && diffDays < 30) {
    return "in " + pluralize(Math.ceil(diffDays), "day");
  }
  if (diffDays >= 30 && diffDays <= 56) {
    return "in " + pluralize(Math.ceil(diffDays / 7), "week");
  }
  if (diffDays > 56) {
    return "in " + pluralize(Math.ceil(diffDays / 30), "month");
  }
  if (diffDays <= 0 && diffDays > -30) {
    return "since " + pluralize(Math.abs(Math.ceil(diffDays)), "day");
  }
  if (diffDays <= -30) {
    return "since " + pluralize(Math.abs(Math.ceil(diffDays / 30)), "month");
  }
  return "-";
}

/**
 * Get the weeks between two dates
 * @param date1 source date, e.g. today
 * @param date2 target date
 * @returns {number} rounded up count of weeks until date2
 */
export function getWeeksBetween(date1: Date, date2: Date): number {
  return Math.ceil((date2.getTime() - date1.getTime()) / (7 * 24 * 60 * 60 * 1000));
}

/**
 * Resolves which day of the year the given date is.
 * @param date Date which day of the year should be resolved
 * @returns { number } Day of the year (between 0 and 365)
 */
export function getDayOfYear(date: Date): number {
  return (
    (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) /
    24 /
    60 /
    60 /
    1000
  );
}

/**
 * Returns a date object representing the day of the year that was passed.
 * @param day Day of the year that should be resolved to a date
 * @returns {Date} Day of the year as date
 */
export function getDateFromDayOfYear(day: number): Date {
  const date = new Date(new Date().getFullYear(), 0, 1);
  // -1 since we initialize our date with the 1st day of the year
  date.setDate(date.getDate() + day - 1);
  return date;
}

/**
 * Returns the name of the month for the given month number in user localization.
 * @param month Number of the month in the same number format like in the Date type (0 = January)
 * @returns {string} Localized month name
 */
export function getMonthString(month: number): string {
  // Select 2nd day of the month to prevent any timezone friendly fire
  const date = new Date(1970, month, 2);
  return date.toLocaleString("default", { month: "long" });
}
