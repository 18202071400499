import React, { PureComponent } from "react";
import PropertyRow from "./PropertyRow";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import BaseListing from "../../common/BaseListing";
import { doFuseSearch, getComponentState } from "../../../utils/baseUtils";
import Search from "../../common/Search";
import { PROPERTYOPTIONSALLSELECT } from "../../../utils/propertyUtils";
import { paginate, PaginationState } from "../../common/Pagination";
import { DataContextInternal } from "../../../context/dataContext";
import { Property } from "../../../model/property.types";

interface PropertiesListingProps {
  context: React.ContextType<typeof DataContextInternal>;
}

interface PropertiesListingState extends PaginationState {
  type: SelectOption;
  search: string;
}

const COMPONENT_NAME = "PropertyListing";

class PropertyListing extends PureComponent<PropertiesListingProps, PropertiesListingState> {
  headerDefinition = [
    { title: "Name", style: { width: "25%" } },
    { title: "Description", style: { width: "40%" } },
    { title: "Type", style: { width: "10%" } },
    { title: "Status", style: { width: "10%" } },
    { title: "Action", className: "text-right", style: { width: "15%" } },
  ];

  constructor(props: PropertiesListingProps) {
    super(props);
    this.state = {
      search: "",
      type: PROPERTYOPTIONSALLSELECT[0],
      currentPage: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {
    const state = getComponentState(this.props.context, COMPONENT_NAME);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(COMPONENT_NAME, this.state);
  }

  handleChangeType = (e: SelectOption | undefined) => {
    this.setState({ type: e ?? PROPERTYOPTIONSALLSELECT[0], currentPage: 1 });
  };

  handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ search: e.target.value, currentPage: 1 });
  };

  /**
   * Filters the properties by name if a search query was entered.
   * @returns { Array<Property> } Filtered properties
   */
  getFilteredProperties = (): Array<Property> => {
    const { search, type } = this.state;
    let properties = this.props.context.property.slice();
    if (type.value !== "all") properties = properties.filter((p) => p.type === type.value);
    if (!search.trim()) return properties;
    return doFuseSearch(properties, search, ["name.en", "description.en", "type"]);
  };

  render() {
    const { currentPage, pageSize, search, type } = this.state;
    const filteredProperties = this.getFilteredProperties();
    const paginatedProperties = paginate(filteredProperties, currentPage, pageSize);

    return (
      <>
        <div className="d-flex align-items-center my-5">
          <div className="w-50">
            <Search
              onSearch={this.handleChangeSearch}
              placeholder="Search..."
              inputClasses="form-control custom-form-control"
              value={search}
            />
          </div>
          <div style={{ width: "20%" }} className="ml-auto">
            <CustomSelect
              options={PROPERTYOPTIONSALLSELECT}
              value={type}
              onChange={this.handleChangeType}
              isClearable={true}
            />
          </div>
        </div>
        <BaseListing
          headerDefinition={this.headerDefinition}
          documents={filteredProperties}
          noHover={true}
          bodyContent={
            <>
              {paginatedProperties.map((p) => (
                <PropertyRow key={p._id.toString()} property={p} />
              ))}
            </>
          }
          currentPage={currentPage}
          pageSize={pageSize}
          baseSize={25}
          onPageChange={(currentPage) => this.setState({ currentPage })}
          onPageSizeChange={(pageSize) => this.setState({ pageSize, currentPage: 1 })}
        />
        )
      </>
    );
  }
}

export default PropertyListing;
