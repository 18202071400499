import _ from "lodash";
import React, { PureComponent } from "react";
import {
  calculateCBMAndGrossWeight,
  ContainerInformation,
  getB2BFollowUpCost,
  isSeaFreightValues,
  recalculateContainerAllocation,
  recalculatePaletteDataOnChange,
  SeaFreightCalculationValues,
} from "../../../../utils/priceCalculationUtils";
import { Commodity, Duty } from "../../../../model/commodity.types";
import { Input } from "../../../common/Input";
import { EURO, USD } from "../../../../utils/currencyUtils";
import { pluralize } from "../../../../utils/baseUtils";
import { PaletteDataView } from "./PaletteDataView";
import CalculationCostObject from "./CalculationCostObject";
import { ContainerCost } from "../../../../model/seaport.types";
import { CONTAINER_LABEL } from "../../../../utils/seaportUtils";
import SeaFreightCustomsView from "./SeaFreightCustomsView";
import { DataContextInternal } from "../../../../context/dataContext";
import WarehouseCostView from "./WarehouseCostView";
import { SelectOption } from "../../../common/CustomSelect";
import { PackagingDimension } from "../../../../model/supplier.types";
import {
  generateValidPalettesForCommodity,
  packagingDimensionAsPaletteData,
} from "../../../../utils/packagingDimensionUtils";
import { T_SEAFREIGHT } from "../../../../model/customerOrder.types";

interface SeaFreightCalculationViewProps {
  totalAmount: number;
  isCalculator?: boolean;
  commodity?: Commodity;
  calculationValues: SeaFreightCalculationValues;
}

interface SeaFreightCalculationViewState {
  calculationValues: SeaFreightCalculationValues;
  palette?: SelectOption<PackagingDimension>;
}

class SeaFreightCalculationView extends PureComponent<SeaFreightCalculationViewProps, SeaFreightCalculationViewState> {
  static contextType = DataContextInternal;
  context!: React.ContextType<typeof DataContextInternal>;

  constructor(props: SeaFreightCalculationViewProps) {
    super(props);
    this.state = {
      calculationValues: _.cloneDeep(props.calculationValues),
    };
  }

  componentDidUpdate(prevProps: SeaFreightCalculationViewProps) {
    if (!_.isEqual(prevProps.calculationValues, this.props.calculationValues)) {
      this.setState({ calculationValues: _.cloneDeep(this.props.calculationValues) });
    }
  }

  handleChangeDuty = (e: React.ChangeEvent<HTMLInputElement>) => {
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    const name = e.target.name as keyof Duty;
    calculationValues.duty[name] = +e.target.value;
    this.setState({ calculationValues });
  };

  handleChangeRelevantLCLFollowupCost = (e: React.ChangeEvent<HTMLInputElement>) => {
    let calculationValues = _.cloneDeep(this.state.calculationValues);
    const followUpCost = calculationValues.otherCost.lcl.followUpCost;
    for (let i = 0; i < followUpCost.length; i++) {
      followUpCost[i].cost = +e.target.value;
    }
    calculationValues = recalculateContainerAllocation(calculationValues, this.context.currencies, undefined, true);
    this.setState({ calculationValues });
  };

  handleChangePaletteData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    recalculatePaletteDataOnChange(calculationValues, this.props.totalAmount, e);
    this.setState({
      // Recalculate container allocation for sea freight
      calculationValues: isSeaFreightValues(calculationValues)
        ? recalculateContainerAllocation(calculationValues, this.context.currencies, true)
        : calculationValues,
    });
  };

  handleChangeContainerValues = (e: React.ChangeEvent<HTMLInputElement>, container: string) => {
    let calculationValues = _.cloneDeep(this.state.calculationValues);
    const path = e.target.name;
    _.set(calculationValues, `containerValues.containers.${container}.${path}`, +e.target.value);
    if (path === "palettes")
      calculationValues = recalculateContainerAllocation(calculationValues, this.context.currencies, false, true);

    this.setState({ calculationValues });
  };

  handleChangeRestValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    const path = e.target.name;
    _.set(calculationValues, `containerValues.rest.${path}`, +e.target.value);
    this.setState({ calculationValues });
  };

  handleChangeSeaportCost = (e: React.ChangeEvent<HTMLInputElement>, container?: string) => {
    let calculationValues = _.cloneDeep(this.state.calculationValues);
    _.set(
      calculationValues,
      container ? `seaFreightCost.cost.containerCost.${container}` : "seaFreightCost.cost.cost",
      +e.target.value
    );
    calculationValues = recalculateContainerAllocation(calculationValues, this.context.currencies, undefined, true);
    this.setState({ calculationValues });
  };

  handleChangeNumValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    const name = e.target.name as keyof SeaFreightCalculationValues;
    const value = +e.target.value;
    _.set(calculationValues, name, ["customsFeeAgency"].includes(name) ? value / 100 : value);
    this.setState({ calculationValues });
  };

  handleChangeCost = (e: React.ChangeEvent<HTMLInputElement>, path: string, index: number) => {
    let calculationValues = _.cloneDeep(this.state.calculationValues);
    const fullPath = `otherCost.${path}[${index}].costPerUnit`;
    if (_.has(calculationValues, fullPath)) {
      _.set(calculationValues, fullPath, +e.target.value);
      calculationValues = recalculateContainerAllocation(calculationValues, this.context.currencies, undefined, true);
      this.setState({ calculationValues });
    }
  };

  handleChangeSeaFreightCost = (e: React.ChangeEvent<HTMLInputElement>, path: string, index: number, cbm?: boolean) => {
    let calculationValues = _.cloneDeep(this.state.calculationValues);
    const fullPath = `otherCost.${path}[${index}].${cbm ? "costPerCBM" : "costPerWeight"}`;
    if (_.has(calculationValues, fullPath)) {
      _.set(calculationValues, fullPath, +e.target.value);
      calculationValues = recalculateContainerAllocation(calculationValues, this.context.currencies, undefined, true);
      this.setState({ calculationValues });
    }
  };

  handleToggleStackable = () => {
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    calculationValues.paletteData.stackable = !calculationValues.paletteData.stackable;
    this.setState({
      // Recalculate container allocation for sea freight
      calculationValues: isSeaFreightValues(calculationValues)
        ? recalculateContainerAllocation(calculationValues, this.context.currencies, true)
        : calculationValues,
    });
  };

  handleChangeWarehouseGeneralCost = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    calculationValues.warehouseCost.generalCost[index].costPerUnit = +e.target.value;
    this.setState({ calculationValues });
  };

  handleChangePalette = (palette?: SelectOption<PackagingDimension>) => {
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    if (palette?.object) {
      const paletteData = palette.object;
      calculationValues.paletteData = packagingDimensionAsPaletteData(paletteData);
      const [cbm, weight, palettes] = calculateCBMAndGrossWeight(this.props.totalAmount, paletteData);
      calculationValues.baseValues = {
        cbm,
        weight,
        palettes,
      };
      calculationValues.b2bFollowUpCost = getB2BFollowUpCost(palettes);
    }
    this.setState({ palette, calculationValues });
  };

  /**
   * Used for parent component to get state values
   */
  getCalculationValues = () => {
    return this.state.calculationValues;
  };

  render() {
    const { totalAmount, isCalculator, commodity } = this.props;
    const { calculationValues, palette } = this.state;
    const {
      seaFreightCost,
      customsFlatRate,
      containerValues,
      customsFeeAgency,
      otherCost,
      b2bFollowUpCost,
      paletteData,
      duty,
      baseValues,
      warehouseCost,
      insurance,
      minimumAbsoluteMargin,
    } = calculationValues;
    const { fcl, lcl } = otherCost;
    const { rest } = containerValues;
    const containerCost = Object.entries(seaFreightCost.cost.containerCost) as Array<
      [key: keyof ContainerCost, cost: number]
    >;
    const containers = Object.entries(containerValues.containers) as Array<
      [key: keyof ContainerCost, value: ContainerInformation]
    >;
    let relevantLCLFollowupCostIndex = lcl.followUpCost.findIndex(
      (entry) => entry.from < rest.weight && rest.weight <= entry.to
    );
    if (relevantLCLFollowupCostIndex < 0) relevantLCLFollowupCostIndex = lcl.followUpCost.length - 1;
    const relevantLCLFollowupCost = lcl.followUpCost[relevantLCLFollowupCostIndex];
    return (
      <div>
        <h3 className="mb-3 mt-7">Transport</h3>
        <div className="row">
          <div className="col-xl-4">
            <label className="fs-5 fw-bold mb-2">Sea Freight Cost per Ton/CBM</label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type={"number"}
                min={0}
                name={"seaFreightCost"}
                value={seaFreightCost.cost.cost}
                onChange={this.handleChangeSeaportCost}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  {seaFreightCost.currency || USD}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 ">
            <label className="fs-5 fw-bold mb-2">
              B2B Follow-up Cost (based on {pluralize(baseValues.palettes, "palette")})
            </label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type={"number"}
                min={0}
                name={"b2bFollowUpCost"}
                value={b2bFollowUpCost}
                onChange={this.handleChangeNumValue}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  {EURO}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4" />
          {containerCost.map(([container, cost]) => (
            <div className="col-xl-4 mt-2" key={container}>
              <label className="fs-5 fw-bold mb-2">Sea Freight Cost per {CONTAINER_LABEL[container]} Container</label>
              <div className="input-group">
                <Input
                  className="form-control custom-form-control"
                  type={"number"}
                  min={0}
                  name={`containerCost${container}`}
                  value={cost}
                  onChange={(e) => this.handleChangeSeaportCost(e, container)}
                />
                <div className="input-group-append rounded-end bg-custom-light-gray">
                  <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                    {seaFreightCost.currency || USD}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <h3 className="mb-3 mt-7">FCL</h3>
        <div className="row">
          {fcl.additionalFreightCost.map((fC, index) => (
            <CalculationCostObject
              key={index.toString()}
              sizeClassName={"col-xl-4 " + (index > 2 && " mt-2")}
              cost={fC}
              onChangeCost={(e) => this.handleChangeCost(e, "fcl.additionalFreightCost", index)}
            />
          ))}
          {fcl.followUpCost.map((aC, index) => (
            <CalculationCostObject
              key={index.toString()}
              sizeClassName={"col-xl-4 " + (fcl.additionalFreightCost.length + index > 2 && " mt-2")}
              cost={aC}
              onChangeCost={(e) => this.handleChangeCost(e, "fcl.additionalCost", index)}
            />
          ))}
        </div>
        <h3 className="mb-3 mt-7">LCL</h3>
        <div className="row">
          {lcl.additionalFreightCost.map((fC, index) => (
            <React.Fragment key={index.toString()}>
              {fC.costPerCBM !== undefined && (
                <div className="col-xl-4 ">
                  <label className="fs-5 fw-bold mb-2">
                    {fC.description} per m³
                    {fC.minimum ? ` (Minimum: ${fC.minimum}${fC.currency})` : ""}
                  </label>
                  <div className="input-group">
                    <Input
                      className="form-control custom-form-control"
                      type={"number"}
                      min={0}
                      name={fC.description}
                      value={fC.costPerCBM}
                      onChange={(e) => this.handleChangeSeaFreightCost(e, "lcl.additionalFreightCost", index, true)}
                    />
                    <div className="input-group-append rounded-end bg-custom-light-gray">
                      <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                        {fC.currency}/m³
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-xl-4">
                <label className="fs-5 fw-bold mb-2">
                  {fC.description} per {fC.weightUnit}
                  {fC.minimum ? ` (Minimum: ${fC.minimum}${fC.currency})` : ""}
                </label>
                <div className="input-group">
                  <Input
                    className="form-control custom-form-control"
                    type={"number"}
                    min={0}
                    name={fC.description}
                    value={fC.costPerWeight}
                    onChange={(e) => this.handleChangeSeaFreightCost(e, "lcl.additionalFreightCost", index)}
                  />
                  <div className="input-group-append rounded-end bg-custom-light-gray">
                    <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                      {fC.currency}/{fC.weightUnit}
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
          <div className="col-xl-4 mt-2">
            <label className="fs-5 fw-bold mb-2">Follow-up Cost to Warehouse</label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type={"number"}
                min={0}
                name={"relevantLCLFollowupCost"}
                value={relevantLCLFollowupCost.cost}
                onChange={this.handleChangeRelevantLCLFollowupCost}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  {relevantLCLFollowupCost.currency || USD}
                </div>
              </div>
            </div>
          </div>
        </div>
        <SeaFreightCustomsView
          duty={duty}
          customsFlatRate={customsFlatRate}
          customsFeeAgency={customsFeeAgency}
          onChangeDuty={this.handleChangeDuty}
          onChangeNumValue={this.handleChangeNumValue}
        />
        <h3 className="mb-3 mt-7">Insurance</h3>
        <div className="row">
          <div className="col-xl-4">
            <label className="fs-5 fw-bold mb-2">Minimum Cost</label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type="number"
                name="insurance.min.value"
                value={insurance.min.value}
                onChange={this.handleChangeNumValue}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  {insurance.min.currency}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <label className="fs-5 fw-bold mb-2">Percentage</label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type="number"
                name="insurance.insuranceValue"
                value={insurance.insuranceValue}
                onChange={this.handleChangeNumValue}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  %
                </div>
              </div>
            </div>
          </div>
        </div>
        <WarehouseCostView
          warehouseCost={warehouseCost}
          onChangeWarehouseCost={this.handleChangeWarehouseGeneralCost}
        />
        {isCalculator && (
          <>
            <h3 className="mb-3 mt-7">Additional Settings</h3>
            <div className="row">
              <div className="col-xl-4">
                <label className="fs-5 fw-bold">Minimum Total Margin</label>
                <div className="input-group">
                  <Input
                    className="form-control custom-form-control"
                    type={"number"}
                    min={0}
                    name={"minimumAbsoluteMargin.value"}
                    value={minimumAbsoluteMargin.value}
                    onChange={this.handleChangeNumValue}
                  />
                  <div className="input-group-append rounded-end bg-custom-light-gray">
                    <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                      {minimumAbsoluteMargin.currency || EURO}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <PaletteDataView
          totalAmount={totalAmount}
          paletteData={paletteData}
          baseValues={baseValues}
          palette={palette}
          palettes={isCalculator ? generateValidPalettesForCommodity(commodity, T_SEAFREIGHT, this.context) : undefined}
          onChangePaletteData={this.handleChangePaletteData}
          onToggleStackable={this.handleToggleStackable}
          onChangePalette={isCalculator ? this.handleChangePalette : undefined}
        />
        <h3 className="mb-3 mt-7">Allocation of {baseValues.palettes} Palettes</h3>
        <div className="row">
          {containers.map(([key, value]) => (
            <div className="col-xl-3 " key={key}>
              <label className="fs-5 fw-bold mb-2">Amount {CONTAINER_LABEL[key]} Container</label>
              <div className="input-group">
                <Input
                  className="form-control custom-form-control"
                  type={"number"}
                  min={0}
                  name={"amount"}
                  value={value.amount}
                  onChange={(e) => this.handleChangeContainerValues(e, key)}
                />
                <div className="input-group-append rounded-end bg-custom-light-gray">
                  <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                    Container
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="col-xl-3 ">
            <label className="fs-5 fw-bold mb-2">Palettes LCL</label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type={"number"}
                min={0}
                name={"palettes"}
                value={containerValues.rest.palettes}
                onChange={this.handleChangeRestValues}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  Palettes
                </div>
              </div>
            </div>
          </div>
          {containers.map(([key, value]) => (
            <div className="col-xl-3 mt-2" key={key}>
              <label className="fs-5 fw-bold mb-2">Palettes per {CONTAINER_LABEL[key]} Container</label>
              <div className="input-group">
                <Input
                  className="form-control custom-form-control"
                  type={"number"}
                  min={0}
                  name={"palettes"}
                  value={value.palettes}
                  onChange={(e) => this.handleChangeContainerValues(e, key)}
                />
                <div className="input-group-append rounded-end bg-custom-light-gray">
                  <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                    Palettes
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="col-xl-3 mt-2">
            <label className="fs-5 fw-bold mb-2">CBM LCL</label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type={"number"}
                min={0}
                name={"cbm"}
                value={containerValues.rest.cbm}
                onChange={this.handleChangeRestValues}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  m³
                </div>
              </div>
            </div>
          </div>
          {containers.map(([key, value]) => (
            <div className="col-xl-3 mt-2" key={key}>
              <label className="fs-5 fw-bold mb-2">Total Weight {CONTAINER_LABEL[key]} Container</label>
              <div className="input-group">
                <Input
                  className={
                    "form-control custom-form-control " +
                    (value.weight > value.maxPayloadWeight + value.ownWeight && "custom-form-control-invalid")
                  }
                  type={"number"}
                  min={0}
                  name={"weight"}
                  value={value.weight}
                  onChange={(e) => this.handleChangeContainerValues(e, key)}
                />
                <div className="input-group-append rounded-end bg-custom-light-gray">
                  <div className={"form-control custom-form-control"} style={{ padding: ".375rem .75rem" }}>
                    kg
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="col-xl-3 mt-2">
            <label className="fs-5 fw-bold mb-2">Weight LCL</label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type={"number"}
                min={0}
                name={"weight"}
                value={containerValues.rest.weight}
                onChange={this.handleChangeRestValues}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  kg
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SeaFreightCalculationView;
