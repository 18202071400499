import _ from "lodash";
import validator from "validator";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import ErrorOverlayButton from "../../ErrorOverlayButton";
import { UserData } from "../../../../model/userData.types";
import { Input } from "../../Input";
import { updateUserData, UserUpdate } from "../../../../utils/userUtils";
import userService from "../../../../services/userService";

interface EditContactModalProps {
  contact: UserData;
}

interface EditContactModalState {
  show: boolean;
  saving: boolean;
  contactEdit: UserData;
}

class EditContactModal extends PureComponent<EditContactModalProps, EditContactModalState> {
  constructor(props: EditContactModalProps) {
    super(props);
    this.state = this.getDefaultState(false, props);
  }

  handleShow = () => this.setState(this.getDefaultState(true, this.props));
  handleHide = () => this.setState({ show: false });

  handleContactChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const contact = _.cloneDeep(this.state.contactEdit);
    _.set(contact, e.target.name, e.target.value);
    this.setState({ contactEdit: contact });
  };

  handleChangeItem = (fieldName: string, idx: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const contact = _.cloneDeep(this.state.contactEdit);
    //@ts-ignore
    contact[fieldName][idx] = { ...contact[fieldName][idx], [e.target.name]: e.target.value };
    this.setState({ contactEdit: contact });
  };

  handleAddItem = (fieldName: string) => {
    const contact = _.cloneDeep(this.state.contactEdit);
    //@ts-ignore
    contact[fieldName].push({ value: "", description: "" });
    this.setState({ contactEdit: contact });
  };

  handleDeleteItem = (idx: number, fieldName: string) => {
    const contact = _.cloneDeep(this.state.contactEdit);
    //@ts-ignore
    contact[fieldName].splice(idx, 1);
    this.setState({ contactEdit: contact });
  };

  handleSaveContacts = async () => {
    const { contact } = this.props;
    const { contactEdit } = this.state;
    if (!contactEdit) return;
    this.setState({ saving: true });
    try {
      const update: UserUpdate = { phones: contactEdit.phones, emails: contactEdit.emails };
      if (contactEdit.prename !== contact.prename) update.prename = contactEdit.prename;
      if (contactEdit.surname !== contact.surname) update.surname = contactEdit.surname;
      if (contactEdit.position !== contact.position) update.position = contactEdit.position;
      const result = await updateUserData(contact, update);
      if (result && result.modifiedCount > 0) {
        // Refresh user custom data since mongo handles it other than normal data
        userService.getUser()?.refreshCustomData();
        toast.success("User data updated successfully");
        this.setState({ show: false });
      } else {
        toast.error("Error updating user data. Please try again.");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  getDefaultState = (show: boolean, props: EditContactModalProps) => {
    return {
      show,
      saving: false,
      contactEdit: _.cloneDeep(props.contact),
    };
  };

  validateData = () => {
    const { contactEdit } = this.state;
    const errors: Array<string> = [];
    if (contactEdit.prename.trim().length < 2) errors.push(`Person prename too short`);
    if (contactEdit.surname.trim().length < 2) errors.push(`Person surname too short`);
    for (let i = 0; i < contactEdit.phones.length; i++) {
      if (!contactEdit.phones[i].value)
        errors.push("Phone number " + (i + 1) + " is empty. Please insert a number or remove the entry");
    }
    for (let i = 0; i < contactEdit.emails.length; i++) {
      if (!validator.isEmail(contactEdit.emails[i].value)) errors.push("Email " + (i + 1) + " is not a valid email");
    }
    return errors;
  };

  render() {
    const { show, saving, contactEdit } = this.state;
    const errors = this.validateData();
    return (
      <>
        <button className={"btn btn-sm px-2 py-1 btn-outline btn-outline-light float-right"} onClick={this.handleShow}>
          Edit
        </button>
        <Modal contentClassName={"bg-dark"} size={"lg"} show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="text-white">Edit Userdata</h1>
            </Modal.Title>
            <CloseButton variant={"white"} onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body className="mx-5 py-0">
            <div className="row mb-3">
              <div className="col-md-6 mt-3">
                <label className="required fs-5 fw-bold mb-2">Prename</label>
                <Input
                  type="text"
                  className="form-control custom-form-control"
                  name="prename"
                  autoComplete="off"
                  value={contactEdit.prename}
                  onChange={this.handleContactChange}
                />
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="required fs-5 fw-bold mb-2">Surname</label>
                <Input
                  type="text"
                  className="form-control custom-form-control"
                  name="surname"
                  autoComplete="off"
                  value={contactEdit.surname}
                  onChange={this.handleContactChange}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12">
                <label className="required fs-5 fw-bold mb-2">Position</label>
                <Input
                  type="text"
                  className="form-control custom-form-control"
                  name="position"
                  autoComplete="off"
                  value={contactEdit.position}
                  onChange={this.handleContactChange}
                />
              </div>
            </div>
            <div className="row mb-3">
              <label className="fs-5 fw-bold mb-2">Phone Numbers</label>
              {contactEdit.phones.map((phone, key) => {
                return (
                  <React.Fragment key={key}>
                    <div className="col-md-6 mb-3">
                      <Input
                        className="form-control custom-form-control"
                        type="text"
                        name="value"
                        value={phone.value}
                        onChange={(e) => this.handleChangeItem("phones", key, e)}
                        placeholder="Phone"
                      />
                    </div>
                    <div className="col-md-5 mb-3">
                      <Input
                        className="form-control custom-form-control"
                        type="text"
                        name="description"
                        value={phone.description}
                        onChange={(e) => this.handleChangeItem("phones", key, e)}
                        placeholder="Description"
                      />
                    </div>
                    <div className="col-1 mb-3 d-flex align-items-center justify-content-center">
                      <button className="btn btn-text btn-sm p-0" onClick={() => this.handleDeleteItem(key, "phones")}>
                        <i className="fa fa-times text-danger pr-0" />
                      </button>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
            <div className="row align-items-end justify-contend-end mb-3">
              <div className="col-1 ml-auto d-flex align-items-center justify-content-center">
                <button className="btn btn-text btn-sm p-0" onClick={() => this.handleAddItem("phones")}>
                  <i className="fa fa-plus pr-0" />
                </button>
              </div>
            </div>
            <div className="row mb-3">
              <label className="fs-5 fw-bold mb-2">Emails</label>
              {contactEdit.emails.map((mail, key) => {
                return (
                  <React.Fragment key={key}>
                    <div className="col-md-6 mb-3">
                      <Input
                        className="form-control custom-form-control"
                        type="text"
                        name="value"
                        value={mail.value}
                        onChange={(e) => this.handleChangeItem("emails", key, e)}
                        placeholder="Phone"
                      />
                    </div>
                    <div className="col-md-5 mb-3">
                      <Input
                        className="form-control custom-form-control"
                        type="text"
                        name="description"
                        value={mail.description}
                        onChange={(e) => this.handleChangeItem("emails", key, e)}
                        placeholder="Description"
                      />
                    </div>
                    <div className="col-1 mb-3 d-flex align-items-center justify-content-center">
                      <button
                        className={"btn btn-text btn-sm p-0" + (contactEdit.emails.length === 1 ? " disabled" : "")}
                        disabled={contactEdit.emails.length === 1}
                        onClick={
                          contactEdit.emails.length === 1 ? undefined : () => this.handleDeleteItem(key, "emails")
                        }
                      >
                        <i className="fa fa-times text-danger pr-0" />
                      </button>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
            <div className="row align-items-end justify-contend-end mb-3">
              <div className="col-1 ml-auto d-flex align-items-center justify-content-center">
                <button className="btn btn-text btn-sm p-0" onClick={() => this.handleAddItem("emails")}>
                  <i className="fa fa-plus pr-0" />
                </button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-text-white" onClick={this.handleHide}>
              Close
            </button>
            <ErrorOverlayButton
              errors={errors}
              className="btn btn-sm btn-outline btn-outline-light"
              buttonText="Save"
              saving={saving}
              onClick={this.handleSaveContacts}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
export default EditContactModal;
