import React from "react";
import { useAccordionButton } from "react-bootstrap";

export interface CustomToggleProps {
  children: React.ReactNode;
  eventKey: string;
  callback?: any;
}

export const CustomToggle: React.FunctionComponent<CustomToggleProps> = ({ children, eventKey, callback }) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));
  return <div onClick={decoratedOnClick}>{children}</div>;
};

export const TableRowToggle: React.FunctionComponent<CustomToggleProps> = ({ children, eventKey, callback }) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));
  return <tr onClick={decoratedOnClick}>{children}</tr>;
};
