import React from "react";
import { CustomerRequest } from "../../../model/customerRequest.types";
import { Input } from "../Input";
import { formatDate, toAbsoluteUrl } from "../../../utils/baseUtils";
import { CC_PERIOD_OPTIONS } from "../../../utils/customerContractUtils";
import { Textarea } from "../Textarea";
import { resolveFilePath } from "../../../utils/fileUtils";
import { formatAddress, isAddress } from "../../../utils/addressUtils";

interface CustomerRequestAdditionalInformationProps {
  request: CustomerRequest;
}

const CustomerRequestAdditionalInformation: React.FunctionComponent<CustomerRequestAdditionalInformationProps> = ({
  request,
}) => {
  const { requestData } = request;
  return (
    <>
      <div className="fw-bolder text-white fs-3 my-5">Additional Request Information</div>
      <div className="row mb-2">
        <span className="col-4 col-form-label fs-6 fw-bold">Requested On</span>
        <div className="col-8">
          <Input
            className={"form-control custom-form-control"}
            disabled={true}
            value={formatDate(request.requestedOn)}
          />
          <small className="mt-1">&nbsp;</small>
        </div>
      </div>
      {"period" in requestData ? (
        <div className="row mb-2">
          <span className="col-4 col-form-label fs-6 fw-bold">Valid For</span>
          <div className="col-8">
            <Input
              className={"form-control custom-form-control"}
              disabled={true}
              value={CC_PERIOD_OPTIONS.find((p) => p.value === requestData?.period)?.label || requestData?.period}
            />
            <small className="mt-1">&nbsp;</small>
          </div>
        </div>
      ) : "destination" in requestData ? (
        <div className="row mb-2">
          <span className="col-4 col-form-label fs-6 fw-bold">Destination</span>
          <div className="col-8">
            <Input
              className={"form-control custom-form-control"}
              disabled={true}
              value={
                requestData?.destination && typeof requestData.destination === "string"
                  ? requestData.destination
                  : requestData?.destination && isAddress(requestData.destination)
                  ? formatAddress(requestData.destination)
                  : "-"
              }
            />
            <small className="mt-1">&nbsp;</small>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="row mb-2">
        <span className="col-4 col-form-label fs-6 fw-bold">Message</span>
        <div className="col-8">
          <Textarea className={"form-control custom-form-control"} rows={2} disabled={true} value={request.message} />
          <small className="mt-1">&nbsp;</small>
        </div>
      </div>
      {request.attachments.length > 0 && (
        <div className="row mb-2">
          <span className="col-4 col-form-label fs-6 fw-bold">Attachments</span>
          <div className="col-8">
            {request.attachments.map((file, idx) => (
              <a
                key={file._id.toString()}
                className="text-white mr-3"
                href={resolveFilePath(file.path)}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => e.stopPropagation()}
              >
                <img
                  alt="attachment"
                  className="w-30px mr-2"
                  src={toAbsoluteUrl("/assets/media/svg/files/generic-file.svg")}
                />
                Attachment {idx + 1}
              </a>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerRequestAdditionalInformation;
