import React, { PureComponent } from "react";
import {
  DataContextAnonymousType,
  DataContextCustomerType,
  DataContextInternalType,
  isAnonymousContext,
} from "../../../context/dataContext";
import { I_CREDITNOTECUSTOMER, I_CREDITNOTESAMPLE, Invoice } from "../../../model/invoice.types";
import { formatCurrency, formatDate, toAbsoluteUrl } from "../../../utils/baseUtils";
import { resolveFilePath } from "../../../utils/fileUtils";
import BaseListing from "../../common/BaseListing";
import { EXTENDED_ORDER_TYPES, isSampleOrder } from "../../../utils/orderUtils";
import { isCustomerContract } from "../../../utils/customerContractUtils";
import { EXTENDED_CONTRACT_TYPES } from "../../../utils/contractUtils";
import { extendInvoice } from "../../../utils/invoiceUtils";

interface OrderCreditNotesProps {
  order: EXTENDED_ORDER_TYPES | EXTENDED_CONTRACT_TYPES;
  context: DataContextInternalType | DataContextCustomerType | DataContextAnonymousType;
}

interface OrderCreditNotesState {
  creditNotes: Array<Invoice>;
}

class OrderCreditNotes extends PureComponent<OrderCreditNotesProps, OrderCreditNotesState> {
  headerDefinition = [
    { title: "Credit Note", style: { width: "30%" } },
    { title: "Customer", style: { width: "30%" } },
    { title: "Date", style: { width: "20%" } },
    { title: "File", className: "text-right", style: { width: "20%" } },
  ];

  constructor(props: OrderCreditNotesProps) {
    super(props);
    this.state = { creditNotes: this.getRelatedCreditNotes() };
  }

  /**
   * Collect all credit notes related to the customer order.
   * @returns { Array<Invoice> } List of all related invoices
   */
  getRelatedCreditNotes = (): Array<Invoice> => {
    const { context, order } = this.props;
    if (isAnonymousContext(context)) return [];
    return context.invoice.filter(
      (i) =>
        i.relatedOrder &&
        (i.type === I_CREDITNOTECUSTOMER || (isSampleOrder(order) && i.type === I_CREDITNOTESAMPLE)) &&
        (i.relatedOrder === order._id.toString() ||
          (isCustomerContract(order) && order.calls.some((c) => c._id.toString() === i.relatedOrder)))
    );
  };

  render() {
    const { context } = this.props;
    const { creditNotes } = this.state;
    if (creditNotes.length === 0) return <></>;
    return (
      <div className="card bg-white mt-5">
        <div className="card-header border-0 mt-5">
          <div className="card-title flex-column">
            <h2 className="mb-1">Credit Notes</h2>
          </div>
        </div>
        <div className="card-body p-9 pt-0">
          <div className="table-responsive mt-5 pt-2">
            <BaseListing
              headerDefinition={this.headerDefinition}
              bodyContent={
                <>
                  {creditNotes.map((i) => {
                    const invoiceExtended = extendInvoice(i, context);
                    return (
                      <tr key={i._id.toString()}>
                        <td className="align-middle">
                          <div className="fw-bold text-white">C-{i.invoiceNumber}</div>
                          <div className="text-muted">{formatCurrency(i.total, i.currency)}</div>
                        </td>
                        <td className="align-middle">
                          <span className="fw-bold text-white">{invoiceExtended.company.name}</span>
                        </td>
                        <td className="align-middle">
                          <span className="fw-bold text-success">{formatDate(i.invoiceDate)}</span>
                        </td>
                        <td className="align-middle text-right">
                          <a href={resolveFilePath(i.file)} target="_blank" rel="noopener noreferrer">
                            <img
                              alt="pdf"
                              className="w-30px me-3"
                              src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                            />
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </>
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default OrderCreditNotes;
