import _ from "lodash";
import React, { PureComponent } from "react";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { DataContextInternal } from "../../../../context/dataContext";
import { getOrderNumber } from "../../../../utils/orderUtils";
import { toAbsoluteUrl } from "../../../../utils/baseUtils";
import { resolveFilePath } from "../../../../utils/fileUtils";
import { STO_STATES, STO_TIMELINE, StorageOrder } from "../../../../model/storageOrder.types";
import SimpleConfirmationModal from "../../../common/SimpleConfirmationModal";
import { getStorageOrderTimelineEntry, STO_STORAGEORDER } from "../../../../utils/storageOrderUtils";
import { getSupplierOrderTimelineEntry, SO_STORAGEORDER } from "../../../../utils/supplierOrderUtils";
import { SO_T_DOCUMENTREMOVED } from "../../../../model/supplierOrder.types";
import { Action, STORAGEORDER, SUPPLIERORDER, transaction } from "../../../../services/dbService";
import { formatDateWithType } from "../../../../utils/logisticsUtils";

interface StorageOrderRowProps extends RouteComponentProps {
  context: React.ContextType<typeof DataContextInternal>;
  storageOrder: StorageOrder;
}

export interface StorageOrderRowState {
  saving: boolean;
}

class StorageOrderRow extends PureComponent<StorageOrderRowProps, StorageOrderRowState> {
  constructor(props: StorageOrderRowProps) {
    super(props);
    this.state = { saving: false };
  }

  handleCancelOrder = async () => {
    const { context } = this.props;
    const storageOrder = _.cloneDeep(this.props.storageOrder);
    this.setState({ saving: true });
    try {
      const timeline = getStorageOrderTimelineEntry(STO_TIMELINE.STO_T_CANCELED);
      storageOrder.timeline ? storageOrder.timeline.push(timeline) : (storageOrder.timeline = [timeline]);
      const actions: Array<Action> = [
        {
          collection: STORAGEORDER,
          filter: { _id: storageOrder._id },
          update: {
            state: STO_STATES.STO_CANCELED,
            timeline: storageOrder.timeline,
          },
        },
      ];
      // remove the storage order file from supplier order
      const supplierOrder = context.supplierOrder.find(
        (so) => so._id.toString() === storageOrder.orderInformation.orderId
      );
      const file = supplierOrder?.files.find((f) => f.reference === storageOrder._id.toString());
      if (file && supplierOrder) {
        const newFiles = supplierOrder.files.filter((f) => f._id.toString() !== file._id.toString());
        actions.push({
          collection: SUPPLIERORDER,
          filter: { _id: supplierOrder._id },
          update: {
            files: newFiles,
          },
          push: {
            timeline: {
              $each: [getSupplierOrderTimelineEntry(SO_T_DOCUMENTREMOVED, { type: SO_STORAGEORDER })],
            },
          },
        });
      }
      const result = await transaction(actions);
      if (result) {
        toast.success(`Storage order canceled successfully`);
      } else toast.error(`Canceling storage order failed. Please try again later`);
    } catch (e) {
      toast.error(`Canceling storage order failed: ` + e);
    } finally {
      this.setState({ saving: false });
    }
  };

  render() {
    const { context, storageOrder, history } = this.props;
    const { saving } = this.state;
    const id = storageOrder.orderInformation.orderId;
    const order = context.supplierOrder.find((sO) => sO._id.toString() === id);
    const storageOrderFile = storageOrder.files?.find((d) => d.type === STO_STORAGEORDER);

    return (
      <tr>
        <td className="text-light align-middle">ST-{storageOrder.storageOrderNo}</td>
        <td className="text-light align-middle">
          {order ? (
            <Link className="custom-link text-white" to={"/supplierOrder/" + order._id.toString()}>
              {getOrderNumber(order)}
            </Link>
          ) : (
            "-"
          )}
        </td>
        <td className="text-light align-middle">
          {storageOrder.state !== STO_STATES.STO_CANCELED
            ? formatDateWithType(storageOrder.deliveryDate, storageOrder.deliveryDateType)
            : "Canceled"}
        </td>
        <td className="text-light align-middle">
          {storageOrderFile && (
            <a href={resolveFilePath(storageOrderFile?.path)} target="_blank" rel="noopener noreferrer">
              <img src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} style={{ height: 24 }} alt="PDF" />
            </a>
          )}
        </td>
        <td className="text-light align-middle text-right">
          <Dropdown>
            <Dropdown.Toggle
              className="btn btn-sm btn-light btn-active-light"
              disabled={storageOrder.state === STO_STATES.STO_CANCELED}
            >
              Action
            </Dropdown.Toggle>
            <Dropdown.Menu className="custom-dropdown">
              <Dropdown.Item
                className={"py-2 text-white"}
                onClick={() => history.push("/createStorageOrder/" + storageOrder._id.toString())}
              >
                Edit
              </Dropdown.Item>
              {storageOrder.state !== STO_STATES.STO_CANCELED && (
                <Dropdown.Item>
                  <SimpleConfirmationModal.SimpleConfirmationModalButton
                    onConfirm={this.handleCancelOrder}
                    confirmButtonText="Confirm"
                    cancelButtonText="Close"
                    buttonText="Cancel"
                    buttonClasses="btn btn-sm btn-outline py-2 text-danger pl-0"
                    modalTitle="Cancel Storage Order"
                    saving={saving}
                    modalDescription={
                      <div className="text-white">
                        Do you really want to cancel the storage order ST-{storageOrder.storageOrderNo}?
                      </div>
                    }
                  />
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  }
}

export default withRouter(StorageOrderRow);
