import React from "react";
import { SupplierOrder, SupplierOrderTimelineEntry } from "../../../model/supplierOrder.types";
import { mergeAndSortOrderTimelines } from "../../../utils/orderUtils";
import OrderHistoryEntry from "../../orders/common/OrderHistoryEntry";
import { DataContextInternal } from "../../../context/dataContext";

interface SupplierPageUpdatesProps {
  orders: Array<SupplierOrder>;
  context: React.ContextType<typeof DataContextInternal>;
}

const SupplierPageUpdates: React.FunctionComponent<SupplierPageUpdatesProps> = ({ orders, context }) => {
  const timeline = mergeAndSortOrderTimelines<SupplierOrder, SupplierOrderTimelineEntry>(orders);

  const recentlyUpdated = Array.from(
    new Set(timeline.filter((t) => t.recent).map((t) => t.order._id.toString()))
  ).length;

  return (
    <div className="card bg-white mt-5" style={{ maxHeight: "40vh" }}>
      <div className="card-header border-0 mt-6">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3 mb-1">Updates</span>
          <span className="text-muted fw-bold fs-7">{recentlyUpdated} Orders were updated recently</span>
        </h3>
      </div>
      <div className="card-body scroll-y pb-0 mb-4">
        {timeline.slice(0, 30).map((entry) => (
          <OrderHistoryEntry
            key={entry.timelineEntry._id.toString()}
            entry={entry}
            context={context}
            showOrder={true}
          />
        ))}
      </div>
    </div>
  );
};

export default SupplierPageUpdates;
