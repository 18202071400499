import React from "react";
import { ProgressBar } from "react-bootstrap";

interface PriceRangeWidgetProps {
  priceRange: { min: number; minCurrency: string; max: number; maxCurrency: string };
  overallPriceRange: { min: number; minCurrency: string; max: number; maxCurrency: string };
  formattedPriceRange: { min: string; max: string };
  outdatedPrices?: number;
}

const PriceRangeWidget: React.FunctionComponent<PriceRangeWidgetProps> = ({
  priceRange,
  overallPriceRange,
  outdatedPrices,
  formattedPriceRange,
}) => {
  return (
    <>
      <ProgressBar style={{ backgroundColor: "#232323", height: 5 }}>
        <ProgressBar
          now={priceRange.min}
          min={overallPriceRange.min}
          max={overallPriceRange.max}
          style={{ backgroundColor: "#232323" }}
        />
        <ProgressBar
          now={priceRange.max}
          min={overallPriceRange.min}
          max={overallPriceRange.max}
          style={{ backgroundColor: "#6d6d6d" }}
        />
      </ProgressBar>
      <div className="mt-2 fs-7 text-nowrap">{`${formattedPriceRange.min} - ${formattedPriceRange.max}`}</div>
      {outdatedPrices ? <small className="text-warning ">{outdatedPrices} outdated prices</small> : ""}
    </>
  );
};

export default PriceRangeWidget;
