import React from "react";
import { toAbsoluteUrl } from "../../utils/baseUtils";
import HoverPopover from "./HoverPopover";

interface CompanyCountryWidgetProps {
  wrapperClasses?: string;
  iconClasses?: string;
}

const SupplierEUWidget: React.FunctionComponent<CompanyCountryWidgetProps> = ({ wrapperClasses, iconClasses }) => {
  return (
    <HoverPopover
      popoverStyle={{ border: "none" }}
      content={
        <div className="px-4 py-3" style={{ background: "#3f3f3f" }}>
          <div className="text-white">This supplier is located within the EU</div>
        </div>
      }
    >
      <span className={wrapperClasses}>
        <img
          src={toAbsoluteUrl(`/assets/media/icons/countries/eu.png`)}
          style={{ height: "16px" }}
          className={iconClasses ?? "mb-1 mx-3"}
          alt="EU"
        />
      </span>
    </HoverPopover>
  );
};

export default SupplierEUWidget;
