import _ from "lodash";
import React from "react";
import { isSupplierTimelineEntry, SupplierTimelineEntry } from "../../../model/supplier.types";
import { formatCurrency, formatDate, formatUnit, getDocFromCollection } from "../../../utils/baseUtils";
import { CommodityTimelineEntry } from "../../../model/commodity.types";
import { DataContextInternalType } from "../../../context/dataContext";
import { FinishedProductTimelineEntry } from "../../../model/finishedProduct.types";
import { InternalArticle, InternalArticleExtended } from "../../../utils/productArticleUtils";

interface PriceHistoryEntryProps {
  entry: CommodityTimelineEntry | SupplierTimelineEntry | FinishedProductTimelineEntry;
  commodity: InternalArticleExtended | InternalArticle;
  context: DataContextInternalType;
}

export const PriceHistoryEntry: React.FunctionComponent<PriceHistoryEntryProps> = ({ entry, commodity, context }) => {
  let hasData = false;
  const preSuppliers = isSupplierTimelineEntry(entry) ? entry.pre : entry.pre?.suppliers;
  const postSuppliers = isSupplierTimelineEntry(entry) ? entry.post : entry.post?.suppliers;
  if (preSuppliers || postSuppliers) {
    const entryTooltip = (
      <div style={{ width: "38rem" }}>
        {preSuppliers?.map((s, i) => {
          const postSup = postSuppliers?.find((pS) => pS._id.toString() === s._id.toString());
          // If the supplier is missing something is fishy. If the entries are equal we can skip the entry.
          if (!s.supplier || _.isEqual(s, postSup)) return <></>;
          const postOnly = postSup?.prices?.filter(
            (pSp) => !s.prices?.some((p) => p._id.toString() === pSp._id.toString())
          );
          const totalChangeAmount = (s.prices?.length || 0) + (postOnly?.length || 0);
          return (
            <div className="row" key={s._id.toString()}>
              <div className="col-3" />
              <div className="col-9">
                <span className="fs-4 text-white">
                  {getDocFromCollection(context.supplier, s.supplier.toString())?.name}
                </span>
              </div>
              {s.prices?.map((p, idx) => {
                const postPrice = postSup?.prices?.find((pP) => pP._id.toString() === p._id.toString());
                // If prices are equals we can skip the entry.
                if (_.isEqual(p, postPrice)) return;
                hasData = true;
                const pre = [
                  formatCurrency(p.price, p.currency),
                  formatUnit(p.minOQ, commodity.unit),
                  formatDate(p.validUntil),
                ];
                const post = postPrice && [
                  formatCurrency(postPrice.price, postPrice.currency),
                  formatUnit(postPrice.minOQ, commodity.unit),
                  formatDate(postPrice.validUntil),
                ];
                return (
                  <React.Fragment key={p._id.toString()}>
                    <PriceChangeContent pre={pre} post={post} idx={idx} totalChangeAmount={totalChangeAmount} />
                  </React.Fragment>
                );
              })}
              {postOnly?.map((p, idx) => {
                hasData = true;
                const post = [
                  formatCurrency(p.price, p.currency),
                  formatUnit(p.minOQ, commodity.unit),
                  formatDate(p.validUntil),
                ];
                return (
                  <React.Fragment key={p._id.toString()}>
                    <PriceChangeContent
                      pre={undefined}
                      post={post}
                      idx={(s.prices?.length || 0) + idx}
                      totalChangeAmount={totalChangeAmount}
                    />
                  </React.Fragment>
                );
              })}
              {i !== (preSuppliers?.length || 0) - 1 && <div className="mt-2 mb-5" />}
            </div>
          );
        })}
        {!preSuppliers &&
          postSuppliers?.map((s, i) => {
            // If the supplier is missing something is fishy. If the entries are equal we can skip the entry.
            if (!s.supplier) return;
            return (
              <div className="row" key={s._id.toString()}>
                <div className="col-3" />
                <div className="col-9">
                  <span className="fs-4 text-white">
                    {getDocFromCollection(context.supplier, s.supplier.toString())?.name}
                  </span>
                </div>
                {s.prices?.map((p, idx) => {
                  // If prices are equals we can skip the entry.
                  hasData = true;
                  const post = [
                    formatCurrency(p.price, p.currency),
                    formatUnit(p.minOQ, commodity.unit),
                    formatDate(p.validUntil),
                  ];
                  return (
                    <React.Fragment key={p._id.toString()}>
                      <PriceChangeContent
                        pre={undefined}
                        post={post}
                        idx={idx}
                        totalChangeAmount={s.prices?.length || 0}
                      />
                    </React.Fragment>
                  );
                })}
                {i !== (postSuppliers?.length || 0) - 1 && <div className="mt-2 mb-5" />}
              </div>
            );
          })}
      </div>
    );
    // Only return data if there is something to show
    if (hasData) return entryTooltip;
  }
  return <span className="text-white">No specific data available</span>;
};

interface PriceChangeContentProps {
  pre: Array<string> | undefined;
  post: Array<string> | undefined;
  idx: number;
  totalChangeAmount: number;
}

const PriceChangeContent: React.FunctionComponent<PriceChangeContentProps> = ({
  pre,
  post,
  idx,
  totalChangeAmount,
}) => {
  return (
    <>
      <div className="col-3 text-left">
        <div className="text-white">Unit Price</div>
        <div className="text-white">MOQ</div>
        <div className="text-white">Valid Until</div>
      </div>
      <div className="col-3 text-right align-self-center">
        {pre ? (
          pre.map((pE, idx) => (
            <div key={idx} className={!post ? "text-danger" : post[idx] !== pE ? "text-warning" : "text-muted"}>
              {pE}
            </div>
          ))
        ) : (
          <div className="text-success">Added</div>
        )}
      </div>
      <div className="col-3 align-self-center">
        <i className="fa fa-arrow-right" />
      </div>
      <div className="col-3 text-left align-self-center">
        {post ? (
          post.map((pE, idx) => (
            <div key={idx} className={!pre ? "text-success" : pre[idx] !== pE ? "text-warning" : "text-muted"}>
              {pE}
            </div>
          ))
        ) : (
          <div key={idx} className="text-danger">
            Deleted
          </div>
        )}
      </div>
      {idx !== totalChangeAmount - 1 && <div className="border-bottom-dark-gray my-2" />}
    </>
  );
};
