import _ from "lodash";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { SelectOption } from "../../../../common/CustomSelect";
import { Notify } from "../../../../../model/notify.types";
import { getFormattedSOTerms, getOrderNumber } from "../../../../../utils/orderUtils";
import ErrorOverlayButton from "../../../../common/ErrorOverlayButton";
import {
  SO_T_DOCUMENTUPLOADED,
  SO_T_TERMSUPDATED,
  SupplierOrderExtended,
} from "../../../../../model/supplierOrder.types";
import { createPDF, PO_FOOTER_HTML } from "../../../../../utils/pdfUtils";
import { Action, SUPPLIERORDER, transaction } from "../../../../../services/dbService";
import userService from "../../../../../services/userService";
import { resolveFilePath } from "../../../../../utils/fileUtils";
import { DataContextInternalType } from "../../../../../context/dataContext";
import SupplierOrderTerms from "../../../../common/SupplierOrderTerms";
import { createSupplierOrderHTML } from "../../../../../utils/pdf/supplierOrderGenerationUtils";
import {
  getSupplierOrderTimelineEntry,
  getSupplierTermOptionFromSupplierOrder,
  SO_ORDERDOCUMENT,
  SupplierTermOptions,
} from "../../../../../utils/supplierOrderUtils";
import { T_EUSTOCK } from "../../../../../model/customerOrder.types";
import { formatAddress } from "../../../../../utils/addressUtils";
import { Incoterm } from "../../../../../utils/commodityUtils";

interface ChangeSupplierOrderTermsModalProps {
  generate: boolean;
  order: SupplierOrderExtended;
  context: DataContextInternalType;
}

interface ChangeSupplierOrderTermsModalState {
  show: boolean;
  saving: boolean;
  supplierTermOptions: SupplierTermOptions;
}

class ChangeSupplierOrderTermsModal extends PureComponent<
  ChangeSupplierOrderTermsModalProps,
  ChangeSupplierOrderTermsModalState
> {
  constructor(props: ChangeSupplierOrderTermsModalProps) {
    super(props);
    this.state = this.getDefaultState(props);
  }

  componentDidUpdate(prevProps: Readonly<ChangeSupplierOrderTermsModalProps>) {
    if (!_.isEqual(prevProps.order, this.props.order) || !_.isEqual(prevProps.context, this.props.context)) {
      this.setState(this.getDefaultState(this.props));
    }
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState({ show: false });

  handleChangeNotify = (e: SelectOption<Notify>) => {
    const { supplierTermOptions } = _.cloneDeep(this.state);
    if (!e.object) return; // Should never happen
    supplierTermOptions.notify = e.object;
    this.setState({ supplierTermOptions });
  };

  handleChangePaymentTerms = (e: SelectOption) => {
    const { supplierTermOptions } = _.cloneDeep(this.state);
    supplierTermOptions.paymentTerm = e;
    this.setState({ supplierTermOptions });
  };

  handleChangeCustomPaymentTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { supplierTermOptions } = _.cloneDeep(this.state);
    supplierTermOptions.customPaymentTerm = e.currentTarget.value;
    this.setState({ supplierTermOptions });
  };

  handleChangeCustomPaymentTermCondition = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { supplierTermOptions } = _.cloneDeep(this.state);
    supplierTermOptions.customPaymentTermCondition = e.currentTarget.value;
    this.setState({ supplierTermOptions });
  };

  handleChangeDeliveryTerms = (e: SelectOption) => {
    const { supplierTermOptions } = _.cloneDeep(this.state);
    supplierTermOptions.deliveryTerm = e;
    this.setState({ supplierTermOptions });
  };

  handleChangeTerms = async () => {
    const { order } = this.props;
    const { supplierTermOptions } = this.state;
    this.setState({ saving: true });
    try {
      const termsSO = getFormattedSOTerms(supplierTermOptions);
      const start =
        order.shipment.length > 0 && typeof order.shipment[0].shipping.startingPoint !== "string"
          ? order.shipment[0].shipping.startingPoint
          : order.purchaseOrderInformation?.startingSeaport ||
            order.purchaseOrderInformation?.startingAirport ||
            order.purchaseOrderInformation?.startingEUWarehouse;
      const delivery = `${termsSO.deliveryTerms}
          ${
            termsSO.deliveryTerms === Incoterm.DDP && order.transport === T_EUSTOCK
              ? ` - ${formatAddress(supplierTermOptions.notify.address, { withComma: true })}`
              : ""
          }`;
      const path = await createPDF(
        createSupplierOrderHTML(
          order,
          order.transport !== T_EUSTOCK || (termsSO.deliveryTerms !== Incoterm.DDP && order.transport === T_EUSTOCK)
            ? start
            : undefined,
          order.purchaseOrderInformation?.shippingInstruction ?? "",
          order.transport !== T_EUSTOCK || (termsSO.deliveryTerms === Incoterm.DDP && order.transport === T_EUSTOCK)
            ? supplierTermOptions.notify
            : undefined,
          delivery,
          termsSO.paymentTerms,
          termsSO.paymentTermConditions
        ),
        "PurchaseOrder-" + order.orderNo,
        order.supplier._id.toString(),
        {
          marginLeft: "2cm",
          marginBottom: "4.2cm",
          footerHtml: PO_FOOTER_HTML,
        }
      );
      const actions: Array<Action> = [];
      const action: Action = {
        collection: SUPPLIERORDER,
        filter: { _id: order._id },
        update: {
          terms: termsSO,
        },
      };
      if (!path) {
        console.error("Purchase order pdf could not be created. Please try again later");
        toast.error(`Purchase order pdf could not be created. Please try again later`);
      } else {
        const fileEntry = {
          _id: new BSON.ObjectId(),
          date: new Date(),
          path,
          type: SO_ORDERDOCUMENT,
          uploadedBy: userService.getUserId(),
        };
        action["push"] = {
          files: fileEntry,
          timeline: getSupplierOrderTimelineEntry(SO_T_TERMSUPDATED),
        };
        actions.push(action);
        actions.push({
          collection: SUPPLIERORDER,
          filter: { _id: order._id },
          push: {
            timeline: getSupplierOrderTimelineEntry(SO_T_DOCUMENTUPLOADED, { type: SO_ORDERDOCUMENT }),
          },
        });
        window.open(resolveFilePath(path));
      }
      const result = await transaction(actions);
      if (result) {
        toast.success(`Order updated successfully`);
        this.setState({ show: false });
      } else toast.error(`Order could not be updated. Please try again later`);
    } catch (e) {
      toast.error(`Order could not be updated: ${JSON.stringify(e) || ""}`);
    } finally {
      this.setState({ saving: false });
    }
  };

  getDefaultState = (props: ChangeSupplierOrderTermsModalProps): ChangeSupplierOrderTermsModalState => {
    const { order, context } = props;
    return {
      show: false,
      saving: false,
      supplierTermOptions: getSupplierTermOptionFromSupplierOrder(order, context.notify),
    };
  };

  validateData = () => {
    const { supplierTermOptions } = this.state;
    const errors = [];
    if (
      supplierTermOptions.paymentTerm.value === "custom" &&
      supplierTermOptions.customPaymentTerm.trim() === "" &&
      supplierTermOptions.customPaymentTermCondition.trim() === ""
    )
      errors.push("Select another payment term or enter custom values");
    return errors;
  };

  render() {
    const { generate, order, context } = this.props;
    const { show, saving, supplierTermOptions } = this.state;
    return (
      <>
        {generate ? (
          <button className="btn btn-sm btn-outline text-white p-0" onClick={this.handleShow}>
            Generate
          </button>
        ) : (
          <button className="btn btn-light float-right" onClick={this.handleShow}>
            Confirm
          </button>
        )}
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered size="lg">
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Purchase Order</h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <SupplierOrderTerms
              supplierTermOption={supplierTermOptions}
              notifies={context.notify}
              transportMethod={order.transport}
              onChangeNotify={this.handleChangeNotify}
              onChangePaymentTerms={this.handleChangePaymentTerms}
              onChangeCustomPaymentTerm={this.handleChangeCustomPaymentTerm}
              onChangeCustomPaymentTermCondition={this.handleChangeCustomPaymentTermCondition}
              onChangeDeliveryTerms={this.handleChangeDeliveryTerms}
            />
            {generate && (
              <div className="row text-white justify-content-center">
                Do you really want to generate a Purchase Order PDF for {getOrderNumber(order)}?
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-text text-muted" onClick={this.handleHide}>
              Close
            </button>
            <ErrorOverlayButton
              errors={this.validateData()}
              saving={saving}
              className="btn btn-light btn-sm "
              buttonText="Confirm"
              onClick={this.handleChangeTerms}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ChangeSupplierOrderTermsModal;
