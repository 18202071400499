import React, { PureComponent } from "react";
import VersionHistoryModal from "./modals/VersionHistoryModal";
import { VersionHistory } from "../../model/versionHistory.types";
import { ORGANICCODESHORT } from "../../utils/pdf/templateUtils";
import { toAbsoluteUrl } from "../../utils/baseUtils";

interface FooterProps {
  versionHistory: Array<VersionHistory>;
}

class Footer extends PureComponent<FooterProps> {
  render() {
    return (
      <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
        <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
          <div className="text-dark order-2 order-md-1">
            <span className="text-muted fw-bold me-1">{new Date().getFullYear()}©</span>
            <a
              href="https://www.rawbids.com"
              target="_blank"
              className="text-gray-800 text-hover-primary"
              rel="noopener noreferrer"
            >
              RAWBIDS GmbH
            </a>
            <span className="text-muted fw-bold organic-code ">{ORGANICCODESHORT}</span>
            <img className="organic-logo" alt="OrganicLogo" src={toAbsoluteUrl("/assets/media/organicLogo.jpg")} />
          </div>
          <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
            <li className="menu-item">
              <VersionHistoryModal versionHistory={this.props.versionHistory} />
            </li>
            <li className="menu-item">
              <a href="/termsOfService" target="_blank" className="menu-link px-2" rel="noopener noreferrer">
                Terms of Service
              </a>
            </li>
            <li className="menu-item">
              <a href="/privacy" target="_blank" className="menu-link px-2" rel="noopener noreferrer">
                Privacy
              </a>
            </li>
            <li className="menu-item">
              <a
                href="mailto:developer@commodity-trading-systems.com?subject=Bug Report"
                target="_blank"
                rel="noopener noreferrer"
                className="menu-link px-2"
              >
                Report Bug
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Footer;
