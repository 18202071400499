import React, { PureComponent } from "react";
import { Accordion } from "react-bootstrap";
import { doFuseSearch } from "../../../../utils/baseUtils";
import { SupplierExtended } from "../../../../model/supplier.types";
import { DataContextInternalType } from "../../../../context/dataContext";
import Search from "../../../common/Search";
import SupplierAndCommodityDocuments from "../../../common/SupplierAndCommodityDocuments";
import UploadDocumentModal, {
  T_COMMODITY,
  T_FINISHEDPRODUCT,
  T_GENERALARTICLE,
} from "../../../commodities/internal/modals/UploadDocumentModal";
import { CommodityExtended } from "../../../../model/commodity.types";
import { FinishedProductExtended } from "../../../../model/finishedProduct.types";
import { isFinishedProduct } from "../../../../utils/finishedProductUtils";

import { extendCommodity, extendFinishedProduct } from "../../../../utils/dataTransformationUtils";

interface SupplierPageDocumentsProps {
  supplier: SupplierExtended;
  context: DataContextInternalType;
}

interface SupplierPageDocumentsState {
  search: string;
}

class SupplierPageDocuments extends PureComponent<SupplierPageDocumentsProps, SupplierPageDocumentsState> {
  constructor(props: SupplierPageDocumentsProps) {
    super(props);
    this.state = {
      search: "",
    };
  }

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });

  getSupplierDocuments = () => {
    const { context, supplier } = this.props;
    const { commodity, finishedProduct } = context;
    const { search } = this.state;
    let articles: Array<CommodityExtended | FinishedProductExtended>;
    const commodities = commodity.filter((c) => c.suppliers.some((s) => s.supplier === supplier._id.toString()));
    articles = commodities.map((c) => extendCommodity(c, context));
    const finishedProducts = finishedProduct.filter((fp) =>
      fp.suppliers.some((s) => s.supplier === supplier._id.toString())
    );
    articles = articles.concat(finishedProducts.map((fp) => extendFinishedProduct(fp, context)));
    let supplierDocuments = articles.map((c) => {
      let documents = c.documents.filter((d) => d.supplier && d.supplier._id.toString() === supplier._id.toString());
      if (search.trim()) documents = doFuseSearch(documents, search, ["name", "type"]);
      return {
        article: c,
        documents: documents,
      };
    });
    let ownDocuments = supplier.documents ?? [];
    if (search.trim()) {
      supplierDocuments = doFuseSearch(supplierDocuments, search, [
        "article.title.en",
        "documents.name",
        "documents.type",
        "documents.customType",
      ]);
      ownDocuments = doFuseSearch(ownDocuments, search, ["name", "type", "customType"]);
    }

    return { articles, supplierDocuments, ownDocuments };
  };

  render() {
    const { supplier } = this.props;
    const { search } = this.state;
    const { articles, supplierDocuments, ownDocuments } = this.getSupplierDocuments();
    const totalDocuments = supplierDocuments.reduce((a, b) => a + b.documents.length, 0);
    return (
      <div className="card bg-white">
        <div className="card-header border-0 mt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">Documents</span>
            <span className="text-muted fw-bold fs-7">
              {totalDocuments} {totalDocuments === 1 ? "Document" : "Documents"}
            </span>
          </h3>
          <div className="card-toolbar">
            <Search onSearch={this.handleSearch} value={search} />
          </div>
        </div>
        <div className="card-body p-4 pt-4">
          <div className=" pt-0">
            <div className="card-body p-4 modal-content-scroll">
              <Accordion alwaysOpen={true} defaultActiveKey={articles.map((c) => c._id.toString()).concat("general")}>
                <SupplierAndCommodityDocuments
                  eventKey="general"
                  documents={ownDocuments}
                  type={T_GENERALARTICLE}
                  supplier={supplier}
                />
                {Object.values(supplierDocuments).map(({ article, documents }) => (
                  <SupplierAndCommodityDocuments
                    key={article._id.toString()}
                    eventKey={article._id.toString()}
                    type={isFinishedProduct(article) ? T_FINISHEDPRODUCT : T_COMMODITY}
                    article={article}
                    supplier={supplier}
                    documents={documents}
                    articles={articles}
                  />
                ))}
              </Accordion>
              <UploadDocumentModal
                supplier={supplier}
                type={T_GENERALARTICLE}
                articles={articles}
                buttonType={"wide"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SupplierPageDocuments;
