import React, { PureComponent } from "react";
import { Input } from "../../common/Input";
import { CommodityExtended } from "../../../model/commodity.types";
import { formatCurrency } from "../../../utils/baseUtils";
import { BASE_CURRENCY } from "../../../utils/currencyUtils";

interface CommoditySampleSizesProps {
  edit: boolean;
  commodity: CommodityExtended;
  onAddSampleSize: () => void;
  onEditSampleSize: (idx: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  onRemoveSampleSize: (idx: number) => void;
}

class CommoditySampleSizes extends PureComponent<CommoditySampleSizesProps> {
  render() {
    const { edit, commodity, onAddSampleSize, onEditSampleSize, onRemoveSampleSize } = this.props;
    if (!edit && (!commodity.sampleSize || commodity.sampleSize.length === 0)) {
      return <div className="text-center text-muted mb-5">No sample sizes provided yet</div>;
    }
    return (
      <table className="table table-no-padding text-white fw-bolder dataTable gy-1">
        <thead>
          <tr>
            <th className="w-25 border-0">Amount</th>
            <th className="w-25 border-0">Price</th>
            <th className="text-right w-50 border-0" colSpan={2}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {commodity.sampleSize &&
            commodity.sampleSize.map((s, idx) => (
              <tr key={idx}>
                <td>
                  {edit ? (
                    <div className="input-group">
                      <Input
                        className="form-control custom-form-control"
                        type="number"
                        name="amount"
                        value={s.amount}
                        min={0}
                        onChange={(e) => onEditSampleSize(idx, e)}
                      />
                      <div className="input-group-append rounded-end bg-custom-light-gray">
                        <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                          {commodity.unit}
                        </div>
                      </div>
                    </div>
                  ) : (
                    s.amount + "" + commodity.unit
                  )}
                </td>
                <td>
                  {edit ? (
                    <div className="input-group">
                      <Input
                        className="form-control custom-form-control"
                        type="number"
                        name="price"
                        value={s.price}
                        min={0}
                        onChange={(e) => onEditSampleSize(idx, e)}
                      />
                      <div className="input-group-append rounded-end bg-custom-light-gray">
                        <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                          {BASE_CURRENCY}
                        </div>
                      </div>
                    </div>
                  ) : (
                    formatCurrency(s.price, BASE_CURRENCY)
                  )}
                </td>
                <td className="text-right" colSpan={2}>
                  <button className="btn btn-sm float-right pr-0" onClick={() => onRemoveSampleSize(idx)}>
                    <i className="fa fa-trash text-danger p-0" />
                  </button>
                </td>
              </tr>
            ))}
          {edit && (
            <tr>
              <td colSpan={3} />
              <td className="text-right">
                <button className="btn btn-sm float-right pr-0" onClick={onAddSampleSize}>
                  <i className="fa fa-plus text-success p-0" />
                </button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }
}

export default CommoditySampleSizes;
