import React, { PureComponent, useContext } from "react";
import { BSON } from "realm-web";
import { CustomerOrder } from "../../model/customerOrder.types";
import { formatDate, getDocFromCollection } from "../../utils/baseUtils";
import { CustomerContract } from "../../model/customerContract.types";
import { isCustomerContract } from "../../utils/customerContractUtils";
import { getAmountForOrderOrContract, getIdentifierForOrderOrContract } from "../../utils/contractAndOrderUtils";
import HoverPopover from "../common/HoverPopover";
import { formatDateFromType } from "../../utils/customerOrderUtils";
import { isCustomerOrder, O_TRANSPORTTYPES_INTERNAL } from "../../utils/orderUtils";
import { DataContextInternal } from "../../context/dataContext";

interface CreateSupplierOrderDestinationProps {
  document: CustomerOrder | CustomerContract;
  selected: boolean;
  onSelect: (id: BSON.ObjectID | string) => void;
  onDeselect: (id: BSON.ObjectID | string) => void;
}

const CreateSupplierOrderDestination: React.FunctionComponent<CreateSupplierOrderDestinationProps> = ({
  document,
  selected,
  onSelect,
  onDeselect,
}) => {
  const context = useContext(DataContextInternal);

  const isCO = isCustomerOrder(document);
  const company = getDocFromCollection(context.company, document.company);
  return (
    <div
      className={
        "d-flex align-items-center rounded p-5 mb-7 cursor-pointer border-0 " + (selected ? " bg-success" : " bg-light")
      }
      onClick={selected ? () => onDeselect(document._id) : () => onSelect(document._id)}
    >
      <div className="w-100">
        <div className={"text-ellipsis fw-bolder fs-6" + (selected ? " text-white" : " text-gray-800")}>
          <HoverPopover
            popoverStyle={{ minWidth: "500px", border: "none" }}
            content={<CustomerOrderInformation document={document} />}
          >
            <i className="fa fa-info-circle text-white mr-1" />
          </HoverPopover>
          <span className="fw-boldest text-white mr-1">{getAmountForOrderOrContract(document)}</span>
          {company?.name}
          {isCustomerContract(document) && <span className="text-white float-right">Contract</span>}
        </div>
        <span className={"fw-bold d-block" + (selected ? " text-white" : " text-muted")}>
          {document.targetDate
            ? isCO
              ? formatDateFromType(document.targetDate, document.targetDateType)
              : formatDate(document.targetDate)
            : "-"}
          <small className="float-right">{getIdentifierForOrderOrContract(document)}</small>
        </span>
      </div>
    </div>
  );
};

interface CustomerOrderInformationProps {
  document: CustomerOrder | CustomerContract;
}

class CustomerOrderInformation extends PureComponent<CustomerOrderInformationProps> {
  render() {
    const { document } = this.props;
    const { noteCustomer, customerReference, targetDate, changedETA } = document;
    const isCO = isCustomerOrder(document);
    return (
      <div className="card overflow-auto" style={{ background: "#3f3f3f" }}>
        <div className="card-body">
          <div className="row text-white">
            <div className="col-12 fw-bolder">{getIdentifierForOrderOrContract(document)}</div>
            <div className="col-6">Customer Target Date</div>
            <div className="col-6">
              {targetDate
                ? isCO
                  ? formatDateFromType(document.targetDate, document.targetDateType)
                  : formatDate(targetDate)
                : "-"}
            </div>
            {changedETA && (
              <>
                <div className="col-6">Changed Target Date</div>
                <div className="col-6">
                  {isCO ? formatDateFromType(changedETA, document.changedETAType) : formatDate(changedETA)}
                </div>
              </>
            )}
            {isCO && (
              <>
                <div className="col-6">Transport Mode</div>
                <div className="col-6">
                  {O_TRANSPORTTYPES_INTERNAL.find((t) => t.value === document.transport)?.label ?? "Unknown"}
                </div>
              </>
            )}
            <div className="col-6">Customer Note</div>
            <div className="col-6">{noteCustomer.trim() ? noteCustomer : "-"}</div>
            <div className="col-6">Customer Reference</div>
            <div className="col-6">{customerReference.trim() ? customerReference : "-"}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateSupplierOrderDestination;
