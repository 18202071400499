import React from "react";
import { BSON } from "realm-web";
import CreateSupplierOrderDestination from "./CreateSupplierOrderDestination";
import { Input } from "../common/Input";
import { CustomerOrder } from "../../model/customerOrder.types";
import { CustomerContract } from "../../model/customerContract.types";
import { formatArticleUnit } from "../../utils/productArticleUtils";

interface CreateSupplierOrderDestinationsProps {
  order: CustomerOrder | CustomerContract;
  matchingOrders: Array<CustomerOrder | CustomerContract>;
  selectedOrders: Array<string>;
  warehouse: boolean;
  warehouseAmount: number;
  onSelectOrder: (id: BSON.ObjectID | string) => void;
  onDeselectOrder: (id: BSON.ObjectID | string) => void;
  onToggleWarehouseAmount: () => void;
  onChangeWarehouseAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CreateSupplierOrderDestinations: React.FunctionComponent<CreateSupplierOrderDestinationsProps> = ({
  order,
  matchingOrders,
  selectedOrders,
  warehouseAmount,
  warehouse,
  onDeselectOrder,
  onSelectOrder,
  onToggleWarehouseAmount,
  onChangeWarehouseAmount,
}) => {
  return (
    <div>
      <div className="fw-bolder text-white fs-3 my-5">Destinations</div>
      <div className="row">
        <div className="col-4">
          <CreateSupplierOrderDestination
            document={order}
            selected={selectedOrders.includes(order._id.toString())}
            onSelect={onSelectOrder}
            onDeselect={onDeselectOrder}
          />
        </div>
        {matchingOrders.map((mO) => (
          <div key={mO._id.toString()} className="col-4">
            <CreateSupplierOrderDestination
              document={mO}
              selected={selectedOrders.includes(mO._id.toString())}
              onSelect={onSelectOrder}
              onDeselect={onDeselectOrder}
            />
          </div>
        ))}
        <div className="col-4 d-flex align-items-stretch">
          <div
            className={
              "d-flex rounded p-5 mb-7 flex-grow-1 border-0 cursor-pointer" + (warehouse ? " bg-success" : " bg-light")
            }
            onClick={onToggleWarehouseAmount}
          >
            <div className="flex-grow-1">
              <span
                className={
                  "fw-bolder fs-6 flex-center flex-stack d-inline-flex " +
                  (warehouse ? " text-white" : " text-gray-800")
                }
              >
                Warehouse
              </span>
              <div className="input-group" style={{ maxHeight: "19px" }}>
                <Input
                  type="number"
                  min={0}
                  value={warehouseAmount}
                  className={"form-control custom-form-control " + (!warehouse && "bg-dark")}
                  style={{ maxWidth: "5rem", fontSize: "13px", maxHeight: "19.5px" }}
                  name="warehouseAmount"
                  onClick={(e) => e.stopPropagation()}
                  onBlur={onChangeWarehouseAmount}
                />
                <div className="input-group-append rounded-end">
                  <div
                    className={"form-control custom-form-control text-white py-0 pl-0 " + (!warehouse && "bg-dark")}
                    style={{ maxHeight: "19.5px", fontSize: "13px" }}
                  >
                    {formatArticleUnit(order.commodity.unit)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateSupplierOrderDestinations;
