import _ from "lodash";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { CloseButton, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CommoditySnapshot } from "../../../../model/commodity.types";
import { CO_T_UPDATEDCOMMODITYSNAPSHOT } from "../../../../model/customerOrder.types";
import { SO_T_UPDATEDCOMMODITYSNAPSHOT } from "../../../../model/supplierOrder.types";
import { INTERNAL_EXTENDED_ORDER_TYPES, isCustomerOrder, isSupplierOrder } from "../../../../utils/orderUtils";
import { getDocFromCollection } from "../../../../utils/baseUtils";
import { PropertyType } from "../../../../utils/propertyUtils";
import { C_COMMODITY_PROPERTIES, getArticleProperty, isPropertyType } from "../../../../utils/commodityUtils";
import { getCustomerOrderTimelineEntry, updateCustomerOrder } from "../../../../utils/customerOrderUtils";
import { getSupplierOrderTimelineEntry } from "../../../../utils/supplierOrderUtils";
import { DataContextInternalType } from "../../../../context/dataContext";
import { C_UPDATE_TABLE_DEFINITIONS, CommodityUpdateContent } from "../../../commodities/common/CommodityHelper";
import { Input } from "../../Input";
import { Action, CUSTOMERCONTRACT, CUSTOMERORDER, SUPPLIERORDER, transaction } from "../../../../services/dbService";
import { getIdentifierForOrderOrContract, getLinkForOrderOrContract } from "../../../../utils/contractAndOrderUtils";
import {
  CC_T_COMMODITYSNAPSHOTUPDATED,
  getCustomerContractTimelineEntry,
  isCustomerContract,
  updateCustomerContract,
} from "../../../../utils/customerContractUtils";
import { CustomerContractExtended } from "../../../../model/customerContract.types";
import { FP_FINISHEDPRODUCT_PROPERTIES, isFinishedProduct } from "../../../../utils/finishedProductUtils";
import { FinishedProductSnapshot } from "../../../../model/finishedProduct.types";
import {
  InternalArticleExtended,
  isAnyFinishedProduct,
  isCommoditySnapshot,
} from "../../../../utils/productArticleUtils";
import {
  FinishedProductUpdateContent,
  FP_UPDATE_TABLE_DEFINITIONS,
} from "../../../finishedProducts/common/FinishedProductHelper";
import { extendCommodity, extendFinishedProduct } from "../../../../utils/dataTransformationUtils";

interface UpdateCommoditySnapshotModalProps {
  order: INTERNAL_EXTENDED_ORDER_TYPES | CustomerContractExtended;
  context: DataContextInternalType;
}

interface UpdateCommoditySnapshotModalState {
  show: boolean;
  selectedProperties: Array<C_COMMODITY_PROPERTIES | FP_FINISHEDPRODUCT_PROPERTIES | PropertyType>;
  valuesToUpdate: Array<CommodityUpdateContent | FinishedProductUpdateContent>;
  step: number;
  order: INTERNAL_EXTENDED_ORDER_TYPES | CustomerContractExtended;
  referenceArticle: InternalArticleExtended | undefined;
  saving: boolean;
}

class UpdateCommoditySnapshotModal extends PureComponent<
  UpdateCommoditySnapshotModalProps,
  UpdateCommoditySnapshotModalState
> {
  constructor(props: UpdateCommoditySnapshotModalProps) {
    super(props);
    this.state = {
      show: false,
      selectedProperties: [],
      valuesToUpdate: [],
      step: 0,
      order: this.props.order,
      referenceArticle: this.getReferenceArticle(props),
      saving: false,
    };
  }

  handleShow = () => this.setState({ show: true, step: 0 });
  handleHide = () => this.setState({ show: false, valuesToUpdate: [], selectedProperties: [] });

  handleChecked = (property: C_COMMODITY_PROPERTIES | FP_FINISHEDPRODUCT_PROPERTIES | PropertyType) => {
    this.setState((prevState) => {
      const isSelected = prevState.selectedProperties.includes(property);
      if (isSelected) {
        return {
          selectedProperties: prevState.selectedProperties.filter((p) => p !== property),
        };
      } else {
        return {
          selectedProperties: [...prevState.selectedProperties, property],
        };
      }
    });
  };

  handleContinue = () => {
    const { step, selectedProperties, referenceArticle } = this.state;
    // get the commodityUpdateContent for each selected property to display them for confirmation
    const result =
      referenceArticle && isAnyFinishedProduct(referenceArticle)
        ? _.flatMapDeep(FP_UPDATE_TABLE_DEFINITIONS, (tableDefinition) => {
            return _.filter(tableDefinition.content, (commodityUpdateContent) => {
              return _.includes(selectedProperties, commodityUpdateContent.property);
            });
          })
        : _.flatMapDeep(C_UPDATE_TABLE_DEFINITIONS, (tableDefinition) => {
            return _.filter(tableDefinition.content, (commodityUpdateContent) => {
              return _.includes(selectedProperties, commodityUpdateContent.property);
            });
          });
    if (step < 1) this.setState({ step: step + 1, valuesToUpdate: result });
  };

  handleBack = () => {
    const { step } = this.state;
    if (step > 0) this.setState({ step: step - 1 });
  };

  handleSave = async () => {
    const { order, selectedProperties, referenceArticle } = this.state;
    if (referenceArticle) {
      try {
        this.setState({ saving: true });
        const updatedOrder = _.cloneDeep(order);
        selectedProperties.forEach((property) => {
          // for setting parts of article.properties
          if (isPropertyType(property)) {
            // for setting properties with the same type
            if (property === PropertyType.ALLERGEN || property === PropertyType.TAG) {
              const allAllergens = referenceArticle.properties.filter((p) => p.type === property);
              // start with an array where no allergens are present
              const otherProperties = updatedOrder.commodity.properties.filter((p) => p.type !== property);
              // add all allergens from finished product or commodity
              const updatedProperties = otherProperties.concat(allAllergens);
              // overwrite properties array in snapshot
              updatedOrder.commodity.properties = updatedProperties;
            } else {
              const index = updatedOrder.commodity.properties.findIndex((p) => p.type === property);
              const updateValue = referenceArticle.properties.find((p) => p.type === property);
              if (updateValue) {
                // replace property in commodity.properties if it already exists, otherwise add it
                updatedOrder.commodity.properties[index > -1 ? index : updatedOrder.commodity.properties.length] =
                  updateValue;
              }
            }
            // for setting article property not article.properties
          } else if (!isAnyFinishedProduct(referenceArticle)) {
            _.set(updatedOrder.commodity, property, referenceArticle[property as C_COMMODITY_PROPERTIES]);
          } else {
            _.set(updatedOrder.commodity, property, referenceArticle[property as FP_FINISHEDPRODUCT_PROPERTIES]);
          }
        });
        let res;
        const cOTimeline = getCustomerOrderTimelineEntry(CO_T_UPDATEDCOMMODITYSNAPSHOT);
        const sOTimeline = getSupplierOrderTimelineEntry(SO_T_UPDATEDCOMMODITYSNAPSHOT);
        const cCTimeline = getCustomerContractTimelineEntry(CC_T_COMMODITYSNAPSHOTUPDATED);
        if (isCustomerOrder(order)) {
          res = await updateCustomerOrder({ commodity: updatedOrder.commodity }, order._id, cOTimeline);
          if (res && res.res.modifiedCount) {
            toast.success("Customer order article snapshot updated successfully");
            this.handleHide();
          } else {
            toast.error("Error updating customer order article snapshot");
          }
        } else if (isCustomerContract(order)) {
          // TODO Add finished product to customer contracts RB-777
          res = await updateCustomerContract(
            { commodity: updatedOrder.commodity as CommoditySnapshot },
            order._id,
            cCTimeline
          );
          if (res && res.res.modifiedCount) {
            toast.success("Customer contract article snapshot updated successfully");
            this.handleHide();
          } else {
            toast.error("Error updating customer contract article snapshot");
          }
        } else if (isSupplierOrder(order)) {
          const actions: Array<Action> = [];
          actions.push({
            collection: SUPPLIERORDER,
            filter: { _id: order._id },
            update: {
              commodity: updatedOrder.commodity,
            },
            push: {
              timeline: sOTimeline,
            },
          });
          // if customerOrders are connected to supplierOrder, also update those
          order.customerOrders.map((cO) => {
            actions.push({
              collection: CUSTOMERORDER,
              filter: { _id: cO._id },
              update: {
                commodity: updatedOrder.commodity,
              },
              push: {
                timeline: cOTimeline,
              },
            });
          });
          // if customerContracts are connected to supplierOrder, also update those
          if (order.customerContracts) {
            order.customerContracts.map((cC) => {
              actions.push({
                collection: CUSTOMERCONTRACT,
                filter: { _id: cC._id },
                update: {
                  commodity: updatedOrder.commodity,
                },
                push: {
                  timeline: cCTimeline,
                },
              });
            });
          }
          res = await transaction(actions);
          if (res) {
            toast.success(
              actions.length === 1
                ? "Supplier order article snapshot updated successfully"
                : "Supplier order and related order/contract article snapshots updated successfully"
            );
            this.handleHide();
          } else {
            toast.error(
              actions.length === 1
                ? "Error updating supplier order article snapshot"
                : "Error updating supplier order and related customer order article snapshots"
            );
          }
        } else {
          toast.error("Order type not recognized");
        }
      } catch (e) {
        toast.error("Error updating article snapshot");
        console.error("Error updating article snapshot", e);
      } finally {
        this.setState({ saving: false });
      }
    }
  };

  getReferenceArticle(props: UpdateCommoditySnapshotModalProps): InternalArticleExtended | undefined {
    const { order, context } = props;
    if (isCommoditySnapshot(order.commodity)) {
      const com = getDocFromCollection(context.commodity, order.commodity._id);
      if (com) return extendCommodity(com, context);
    }
    const fp = getDocFromCollection(context.finishedProduct, order.commodity._id);
    if (fp) return extendFinishedProduct(fp, context);
  }

  render() {
    const { show, step, saving, valuesToUpdate, selectedProperties, referenceArticle } = this.state;
    const { order, context } = this.props;
    const comparisonTableContent = (
      referenceArticle && isAnyFinishedProduct(referenceArticle)
        ? FP_UPDATE_TABLE_DEFINITIONS
        : C_UPDATE_TABLE_DEFINITIONS
    ).map((item, idx) => (
      <ArticleUpdateTable
        key={idx}
        context={context}
        content={item.content}
        mainTitle={item.mainTitle}
        subtitle={item.subtitle}
        order={order}
        selectedProperties={selectedProperties}
        referenceArticle={referenceArticle}
        onChecked={this.handleChecked}
      />
    ));

    // if comparisonTableContent consists only of null, all properties in all tables were the same
    const hasTableContent = comparisonTableContent.some((item) => item !== null);
    const articleString = referenceArticle && isAnyFinishedProduct(referenceArticle) ? "Finished Product" : "Commodity";
    return (
      <>
        <button className="btn btn-light btn-sm mt-5" onClick={this.handleShow}>
          Update {articleString}
        </button>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered size="xl">
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="text-white">
                Update {articleString + " "}
                Values
              </h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            {step === 0 && referenceArticle ? (
              <>
                <div className="text-muted">
                  Only values differing from&nbsp;
                  <Link
                    className="text-white custom-link"
                    to={`/${
                      isAnyFinishedProduct(referenceArticle) ? "finishedProduct" : "commodity"
                    }/${referenceArticle._id.toString()}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {referenceArticle.title.en}
                  </Link>
                  &nbsp;will be shown.
                </div>
                <div className="border-bottom-dark-gray my-5" />
                {hasTableContent ? (
                  <>{comparisonTableContent}</>
                ) : (
                  <div className="text-muted my-3">
                    No difference between {articleString} and {articleString} Snapshot found.
                  </div>
                )}
              </>
            ) : step === 1 && referenceArticle ? (
              <>
                <div className="text-muted">
                  Are you sure you want to update the following values?{" "}
                  <span className="fw-boldest">This step can't be undone!</span>
                </div>
                {isSupplierOrder(order) && order.customerOrders.length > 0 && (
                  <div className="text-muted mt-2">
                    {order.customerOrders.length === 1
                      ? "The following customer order will also be updated:"
                      : "The following customer orders will also be updated:"}
                    <ul>
                      {order.customerOrders.map((cO) => (
                        <li key={cO._id.toString()} className="mt-2">
                          <Link
                            to={getLinkForOrderOrContract(cO)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-white custom-link"
                          >
                            {getIdentifierForOrderOrContract(cO)}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {isSupplierOrder(order) && order.customerContracts && order.customerContracts.length > 0 && (
                  <div className="text-muted mt-2">
                    {`The following customer ${
                      order.customerContracts.length === 1 ? "contract" : "contracts"
                    } will also be updated:`}
                    <ul>
                      {order.customerContracts.map((cC) => (
                        <li key={cC._id.toString()} className="mt-2">
                          <Link
                            to={getLinkForOrderOrContract(cC)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-white custom-link"
                          >
                            {getIdentifierForOrderOrContract(cC)}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="border-bottom-dark-gray my-5" />
                <ArticleUpdateTable
                  context={context}
                  content={valuesToUpdate}
                  mainTitle={""}
                  subtitle={""}
                  order={order}
                  selectedProperties={selectedProperties}
                  referenceArticle={referenceArticle}
                  onChecked={this.handleChecked}
                  hideUpdate={true}
                />
              </>
            ) : !referenceArticle ? (
              <div className="text-warning">No Reference {articleString} found!</div>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <button className={"btn btn-light btn-sm"} onClick={step === 0 ? this.handleHide : this.handleBack}>
              {step === 0 ? "Cancel" : "Back"}
            </button>
            <button
              disabled={!hasTableContent || selectedProperties.length === 0 || saving}
              className={
                "btn btn-light btn-sm " +
                ((!hasTableContent || selectedProperties.length === 0 || saving) && "disabled")
              }
              onClick={step === 0 ? this.handleContinue : this.handleSave}
            >
              {step === 0 ? "Continue" : "Update"}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

interface ArticleUpdateTableProps {
  context: DataContextInternalType;
  content: Array<CommodityUpdateContent | FinishedProductUpdateContent>;
  mainTitle: string;
  subtitle: string;
  hideUpdate?: boolean;
  order: INTERNAL_EXTENDED_ORDER_TYPES | CustomerContractExtended;
  selectedProperties: Array<C_COMMODITY_PROPERTIES | FP_FINISHEDPRODUCT_PROPERTIES | PropertyType>;
  referenceArticle: InternalArticleExtended | undefined;
  onChecked: (property: C_COMMODITY_PROPERTIES | FP_FINISHEDPRODUCT_PROPERTIES | PropertyType) => void;
}

export const ArticleUpdateTable: React.FunctionComponent<ArticleUpdateTableProps> = ({
  context,
  content,
  mainTitle,
  subtitle,
  hideUpdate,
  order,
  selectedProperties,
  referenceArticle,
  onChecked,
}) => {
  const tableRows = content.map((item, idx) => (
    <ArticleUpdateTableRow
      key={idx}
      context={context}
      content={item}
      hideUpdate={hideUpdate}
      order={order}
      selectedProperties={selectedProperties}
      referenceArticle={referenceArticle}
      onChecked={onChecked}
    />
  ));
  const hasRowContent = tableRows.some((item) => item !== null);

  return hasRowContent ? (
    <React.Fragment key={subtitle}>
      <div className="w-100 align-items-center">
        <div className="text-white fs-5 fw-bolder mb-3">
          {mainTitle} {subtitle && <span className="text-muted"> - {subtitle}</span>}
        </div>
      </div>
      <table className="table fw-bold align-middle gs-0 gy-1 ">
        <thead>
          <tr className="fw-bolder text-muted">
            <th className="border-bottom-0 col-2">Label</th>
            <th className="border-bottom-0 col-4">Snapshot value</th>
            <th className="border-bottom-0 col-4">Update value</th>
            {!hideUpdate && <th className="border-bottom-0 col-2">Update</th>}
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </table>
      <div className="border-bottom-dark-gray my-5" />
    </React.Fragment>
  ) : null;
};

interface ArticleUpdateTableRowProps {
  context: DataContextInternalType;
  content: CommodityUpdateContent | FinishedProductUpdateContent;
  hideUpdate?: boolean;
  order: INTERNAL_EXTENDED_ORDER_TYPES | CustomerContractExtended;
  selectedProperties: Array<C_COMMODITY_PROPERTIES | FP_FINISHEDPRODUCT_PROPERTIES | PropertyType>;
  referenceArticle: InternalArticleExtended | undefined;
  onChecked: (property: C_COMMODITY_PROPERTIES | FP_FINISHEDPRODUCT_PROPERTIES | PropertyType) => void;
}

export const ArticleUpdateTableRow: React.FunctionComponent<ArticleUpdateTableRowProps> = ({
  context,
  content,
  hideUpdate,
  order,
  selectedProperties,
  referenceArticle,
  onChecked,
}) => {
  const isSelected = selectedProperties.includes(content.property);
  const articleSnapshot = order.commodity;

  // commodity.properties has to be handled different
  const isProperty = isPropertyType(content.property);
  const isArrayProperty = content.property === PropertyType.ALLERGEN || content.property === PropertyType.TAG;
  const referenceProperty = isProperty
    ? isArrayProperty
      ? getArticleProperty(referenceArticle?.properties, content.property, true)
      : getArticleProperty(referenceArticle?.properties, content.property)
    : referenceArticle && isAnyFinishedProduct(referenceArticle)
    ? referenceArticle[content.property as FP_FINISHEDPRODUCT_PROPERTIES]
    : referenceArticle && referenceArticle[content.property as C_COMMODITY_PROPERTIES];
  const snapshotProperty = isProperty
    ? isArrayProperty
      ? getArticleProperty(articleSnapshot.properties, content.property, true)
      : getArticleProperty(articleSnapshot.properties, content.property)
    : !isFinishedProduct(articleSnapshot)
    ? (articleSnapshot as CommoditySnapshot)[content.property as C_COMMODITY_PROPERTIES]
    : (articleSnapshot as FinishedProductSnapshot)[content.property as FP_FINISHEDPRODUCT_PROPERTIES];

  return referenceArticle && !_.isEqual(referenceProperty, snapshotProperty) ? (
    <tr key={content.label}>
      <td className="text-white min-w-125px">{content.label}</td>
      <td className="text-muted" style={{ overflowWrap: "anywhere" }}>
        {isCommoditySnapshot(articleSnapshot)
          ? (content as CommodityUpdateContent).value(articleSnapshot, context)
          : (content as FinishedProductUpdateContent).value(articleSnapshot, context)}
      </td>
      <td className="text-muted" style={{ overflowWrap: "anywhere" }}>
        {!isAnyFinishedProduct(referenceArticle)
          ? (content as CommodityUpdateContent).value(referenceArticle, context)
          : (content as FinishedProductUpdateContent).value(referenceArticle, context)}
      </td>
      {!hideUpdate && (
        <td>
          <div className="form-check form-check-sm form-check-custom form-check-solid">
            <Input
              type="checkbox"
              className="form-check-input"
              checked={isSelected}
              onClick={() => onChecked(content.property)}
            />
          </div>
        </td>
      )}
    </tr>
  ) : null;
};

export default UpdateCommoditySnapshotModal;
