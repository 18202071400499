import React from "react";
import { Link } from "react-router-dom";

const HoverableLink: React.FunctionComponent<{ hovering: boolean; to: string; children: React.ReactNode }> = ({
  to,
  hovering,
  children,
}) => {
  if (!hovering) return <Link to={to}>{children}</Link>;
  return <span>{children}</span>;
};

export default HoverableLink;
