import React from "react";
import { SupplierOrderExtended } from "../../../model/supplierOrder.types";
import { CustomerOrderExtended } from "../../../model/customerOrder.types";
import { isSupplierOrder } from "../../../utils/orderUtils";
import { formatDateFromType, formatOrderDate } from "../../../utils/customerOrderUtils";
import { formatDate } from "../../../utils/baseUtils";
import { getFullCWString } from "../../../utils/dateUtils";

interface OrderDateOverviewProps {
  order: SupplierOrderExtended | CustomerOrderExtended;
}
const OrderDateOverview: React.FunctionComponent<OrderDateOverviewProps> = ({ order }) => {
  const isSO = isSupplierOrder(order);

  return (
    <>
      {isSO ? (
        <table className="table table-no-padding fw-bold gy-0 text-start text-nowrap">
          <tbody>
            <tr>
              <td className="text-white w-50">Original Target Date</td>
              <td className="text-muted">{getFullCWString(order.targetDate)}</td>
            </tr>
            {order.etd && (
              <tr>
                <td className="text-white w-50">ETD</td>
                <td className="text-muted">{getFullCWString(order.etd)}</td>
              </tr>
            )}
            {order.shipment.length > 0 && order.shipment[0].shipping.arrivedAtStartingPort && (
              <tr>
                <td className="text-white w-50">Arrived at starting port</td>
                <td className="text-muted">{getFullCWString(order.shipment[0].shipping.arrivedAtStartingPort)}</td>
              </tr>
            )}
            <tr>
              <td className="text-white w-50">ETA</td>
              <td className="text-muted">
                {order.changedETA ? getFullCWString(order.changedETA) : getFullCWString(order.targetDate)}
              </td>
            </tr>
            {order.shipment.length > 0 && order.shipment[0].shipping.eta && (
              <tr>
                <td className="text-white w-50">ETA (after shipping)</td>
                <td className="text-muted">{getFullCWString(order.shipment[0].shipping.eta)}</td>
              </tr>
            )}
            {order.shipment.length > 0 && order.shipment[0].shipping.shipped && (
              <tr>
                <td className="text-white w-50">Shipped</td>
                <td className="text-muted">{getFullCWString(order.shipment[0].shipping.shipped)}</td>
              </tr>
            )}
            {order.deliveryDate && (
              <tr>
                <td className="text-white w-50">Delivery Date</td>
                <td className="text-muted">{formatDate(order.deliveryDate)}</td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <table className="table table-no-padding fw-bold gy-0 text-start text-nowrap">
          <tbody>
            <tr>
              <td className="text-white w-50">Original Target Date</td>
              <td className="text-muted">{formatDateFromType(order.targetDate, order.targetDateType)}</td>
            </tr>
            <tr>
              <td className="text-white w-50">ETA</td>
              <td className="text-muted">{formatOrderDate(order)}</td>
            </tr>
            {order.shippingInformation?.estimatedDeliveryDate && (
              <tr>
                <td className="text-white w-50">Estimated Delivery Date</td>
                <td className="text-muted">{formatDate(order.shippingInformation?.estimatedDeliveryDate)}</td>
              </tr>
            )}
            {order.deliveryDate && (
              <tr>
                <td className="text-white w-50">Delivery Date</td>
                <td className="text-muted">{formatDate(order.deliveryDate)}</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </>
  );
};

export default OrderDateOverview;
