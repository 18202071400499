import { isAnyFinishedProduct } from "../productArticleUtils";
import { SUPPLIER_ORDER_TYPES } from "../orderUtils";

/**
 * Create a shipping mark html string for pdf generation
 * @param order the supplier order
 * @param batchNo the batch number for the label
 * @param netWeight the net weight of the batch
 * @param grossWeight the gross weight of the batch
 * @param manufacturingDate the date the batch was manufactured
 * @param expiry the expiration date of the batch
 * @param storageConditions the conditions the batch needs to be stored in
 * @param unit weight unit
 * @returns {string} a html string for pdf generation
 */
export function createShippingMark(
  order: SUPPLIER_ORDER_TYPES,
  batchNo: string,
  netWeight: string,
  grossWeight: string,
  manufacturingDate: Date,
  expiry: Date,
  storageConditions: string,
  unit: string
): string {
  const { commodity } = order;
  return `<head>
  <link href="https://fonts.googleapis.com/css?family=Signika&display=swap" rel="stylesheet">
  <style>tr:nth-child(even) {
      background-color: white;
  }</style>
  <meta http-equiv="content-type" content="text/html; charset=utf-8">
</head>
<body style="font-family: Signika, sans-serif">
<div><img src="https://rawbids.com/images/logo-dark.png" alt="Logo" width="500"
style="float:right"/></div>
<table style="font-size:16px; width: 100%; display: block">
  <tbody>
  <tr>
    <td>
      <div style="margin-top: 50px">
      <div style="float: right; text-align: center; background-color: #333333; border-radius: 10px; padding: 7px; margin-bottom: 5px" >
            <img alt="QR" style=" border-radius: 8px" src="https://v2018.api2pdf.com/zebra?apikey={{API_KEY}}&format=QR_CODE&value=https://${
              process.env.REACT_APP_BASE_URL || ""
            }/${
    isAnyFinishedProduct(commodity) ? "finishedProduct" : "commodity"
  }/${commodity._id.toString()}" width="200"/><br/><span style="font-size: 20px; text-align: center; color: white">REORDER</span>
        </div>
        <div style="font-size: 40px; line-height: 150%">Product: ${commodity.title.en}</div>
      </div>
      <div>
        <div style="font-size: 40px; line-height: 150%">Batch No.: ${batchNo}</div>
      </div>
      <div>
        <div style="font-size: 40px; line-height: 150%">Net weight: ${netWeight} ${unit}</div>
      </div>
      <div>
        <div style="font-size: 40px; line-height: 150%">Gross weight: ${grossWeight} ${unit}</div>
      </div>
      <div>
        <div style="font-size: 40px; line-height: 150%">Manufacturing Date: ${manufacturingDate.toLocaleDateString(
          "en-GB",
          { month: "short", day: "numeric", year: "numeric" }
        )}</div>
      </div>
      <div>
        <div style="font-size: 40px; line-height: 150%">Expiry Date: ${expiry.toLocaleDateString("en-GB", {
          month: "short",
          day: "numeric",
          year: "numeric",
        })}</div>
      </div>
      <div>
        <div style="font-size: 40px; line-height: 150%">Storage Conditions: ${storageConditions}</div>
      </div>
      <div>
        <div style="font-size: 40px; line-height: 150%">Made In ${commodity.country.name}</div>
      </div>
    </td>
  </tr>
  </tbody>
</table>
</body>`;
}
