import _ from "lodash";
import React, { PureComponent } from "react";
import CommodityEUStockEntry from "../../../commodities/common/CommodityEUStockEntry";
import { DataContextInternalType } from "../../../../context/dataContext";
import { SupplierExtended } from "../../../../model/supplier.types";
import { Commodity, SupplierEUStockPrices } from "../../../../model/commodity.types";
import AddEUStockModal from "../modals/AddEUStockModal";
import { getDefaultEUStock } from "../../../../utils/commodityUtils";
import { getDocFromCollection } from "../../../../utils/baseUtils";
import { FinishedProduct, SupplierEUStockPricesFinishedProduct } from "../../../../model/finishedProduct.types";
import { InternalArticleExtended } from "../../../../utils/productArticleUtils";
import { extendCommodity, extendFinishedProduct } from "../../../../utils/dataTransformationUtils";

interface SupplierPageEUStockProps {
  context: DataContextInternalType;
  supplier: SupplierExtended;
  finishedProduct: boolean;
}

interface SupplierPageEUStockState {
  articlesWithStock: Array<Commodity | FinishedProduct>;
}

class SupplierPageEUStock extends PureComponent<SupplierPageEUStockProps, SupplierPageEUStockState> {
  constructor(props: SupplierPageEUStockProps) {
    super(props);
    this.state = { articlesWithStock: this.getSupplierEUStocks(props) };
  }

  componentDidUpdate(prevProps: Readonly<SupplierPageEUStockProps>) {
    if (
      (!this.props.finishedProduct && !_.isEqual(prevProps.context.commodity, this.props.context.commodity)) ||
      !_.isEqual(prevProps.supplier, this.props.supplier)
    ) {
      this.setState({ articlesWithStock: this.getSupplierEUStocks(this.props) });
    } else if (
      (this.props.finishedProduct &&
        !_.isEqual(prevProps.context.finishedProduct, this.props.context.finishedProduct)) ||
      !_.isEqual(prevProps.supplier, this.props.supplier)
    ) {
      this.setState({ articlesWithStock: this.getSupplierEUStocks(this.props) });
    }
  }

  getSupplierEUStocks = (props: SupplierPageEUStockProps) => {
    return props.finishedProduct
      ? props.context.finishedProduct.filter((fp) =>
          fp.supplierEUStocks?.some((ses) => ses.supplier === props.supplier._id.toString())
        )
      : props.context.commodity.filter((c) =>
          c.supplierEUStocks?.some((ses) => ses.supplier === props.supplier._id.toString())
        );
  };

  handleResetStock = () => {
    this.setState({ articlesWithStock: this.getSupplierEUStocks(this.props) });
  };

  handleAddEUStock = (article: Commodity | FinishedProduct) => {
    const { supplier } = this.props;
    const articlesWithStock = _.cloneDeep(this.state.articlesWithStock);
    const defaultEUStock = getDefaultEUStock(supplier._id.toString());
    const comExisting = articlesWithStock.find((cws) => cws._id.toString() === article._id.toString());
    if (comExisting) {
      comExisting.supplierEUStocks?.push(defaultEUStock);
    } else {
      if (!article.supplierEUStocks) article.supplierEUStocks = [];
      article.supplierEUStocks.push(getDefaultEUStock(supplier._id.toString()));
      articlesWithStock.push(article);
    }
    this.setState({ articlesWithStock: articlesWithStock });
  };

  render() {
    const { context, supplier, finishedProduct } = this.props;
    const { articlesWithStock } = this.state;

    return (
      <div className="card bg-white">
        <div className="card-header border-0 mt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">
              EU Stock for {finishedProduct ? "Finished Products" : "Commodities"}
            </span>
            <span className="text-muted fw-bold fs-7">
              {articlesWithStock.reduce((sum, cws) => {
                return cws.supplierEUStocks?.some(
                  (supplierEUStockPrice) => supplierEUStockPrice.supplier === supplier._id.toString()
                )
                  ? sum + 1
                  : sum;
              }, 0)}{" "}
              Entries
            </span>
          </h3>
        </div>
        <div className="card-body p-4 pt-4">
          {articlesWithStock.map((cws) => (
            <SupplierPageEUStockCommodity
              key={cws._id.toString()}
              article={
                finishedProduct
                  ? extendFinishedProduct(getDocFromCollection(context.finishedProduct, cws._id)!, context)
                  : extendCommodity(getDocFromCollection(context.commodity, cws._id)!, context)
              }
              relevantStocks={cws.supplierEUStocks || []}
              supplier={supplier}
            />
          ))}
          <div className="pt-2">
            <AddEUStockModal
              supplier={supplier}
              onAddEUStock={this.handleAddEUStock}
              context={context}
              finishedProduct={finishedProduct}
            />
            <button type="button" className="btn btn-light btn-sm float-right mr-2" onClick={this.handleResetStock}>
              Reset
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default SupplierPageEUStock;

interface SupplierPageEUStockCommodityProps {
  article: InternalArticleExtended;
  relevantStocks: Array<SupplierEUStockPrices | SupplierEUStockPricesFinishedProduct>;
  supplier: SupplierExtended;
}

class SupplierPageEUStockCommodity extends PureComponent<SupplierPageEUStockCommodityProps> {
  render() {
    const { relevantStocks, article, supplier } = this.props;

    return relevantStocks
      .filter((rs) => rs.supplier === supplier._id.toString())
      .map((rs) => (
        <div key={article._id.toString() + "_" + supplier._id.toString()}>
          <CommodityEUStockEntry article={article} euStockPrice={rs} />
        </div>
      ));
  }
}
