import _ from "lodash";
import React, { useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import {
  CO_ORDEREDBYCUSTOMER,
  CO_PROCESSINGATWAREHOUSE,
  CO_T_DOCUMENTUPLOADED,
  CO_T_STOCKORDERCONFIRMED,
  CO_T_USESTOCK,
  CustomerOrderExtended,
  T_WAREHOUSE,
} from "../../../../../model/customerOrder.types";
import { getFormattedCOTerms, getOrderNumber } from "../../../../../utils/orderUtils";
import ErrorOverlayButton from "../../../../common/ErrorOverlayButton";
import {
  CO_ORDERCONFIRMATION,
  getCustomerTermOptionFromCustomerOrder,
  getCustomerOrderTimelineEntry,
} from "../../../../../utils/customerOrderUtils";
import CustomerTerms from "../../../../common/CustomerTerms";
import { SelectOption } from "../../../../common/CustomSelect";
import { CO_FOOTER_HTML, createPDF } from "../../../../../utils/pdfUtils";
import { createOrderConfirmationHTML } from "../../../../../utils/pdf/orderConfirmationGenerationUtils";
import { STANDARDTEXTSHORT } from "../../../../../utils/pdf/templateUtils";
import { Action, CUSTOMERORDER, transaction } from "../../../../../services/dbService";
import { resolveFilePath } from "../../../../../utils/fileUtils";
import { DataContextInternalType } from "../../../../../context/dataContext";
import userService from "../../../../../services/userService";

interface ConvertToWarehouseOrderModalProps {
  order: CustomerOrderExtended;
  context: DataContextInternalType;
}

const ConvertToWarehouseOrderModal: React.FunctionComponent<ConvertToWarehouseOrderModalProps> = ({
  order,
  context,
}) => {
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);

  const [customerOrderOption, setCustomerOrderOption] = useState(getCustomerTermOptionFromCustomerOrder(order));

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  const handleChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newCustomerOrderOption = _.cloneDeep(customerOrderOption);
    newCustomerOrderOption.note = e.currentTarget.value;
    setCustomerOrderOption(newCustomerOrderOption);
  };

  const handleChangePaymentTerms = (e: SelectOption) => {
    const newCustomerOrderOption = _.cloneDeep(customerOrderOption);
    newCustomerOrderOption.paymentTerm = e;
    setCustomerOrderOption(newCustomerOrderOption);
  };

  const handleChangeCustomPaymentTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCustomerOrderOption = _.cloneDeep(customerOrderOption);
    newCustomerOrderOption.customPaymentTerm = e.currentTarget.value;
    setCustomerOrderOption(newCustomerOrderOption);
  };

  const handleChangeCustomPaymentTermConditions = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCustomerOrderOption = _.cloneDeep(customerOrderOption);
    newCustomerOrderOption.customPaymentTermCondition = e.currentTarget.value;
    setCustomerOrderOption(newCustomerOrderOption);
  };

  const handleChangeDeliveryTerms = (e: SelectOption) => {
    const newCustomerOrderOption = _.cloneDeep(customerOrderOption);
    newCustomerOrderOption.deliveryTerm = e;
    setCustomerOrderOption(newCustomerOrderOption);
  };

  const handleChangeDeliveryCity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCustomerOrderOption = _.cloneDeep(customerOrderOption);
    newCustomerOrderOption.deliveryCity = e.currentTarget.value;
    setCustomerOrderOption(newCustomerOrderOption);
  };

  const handleConfirm = async () => {
    if (order.state !== CO_ORDEREDBYCUSTOMER) return;
    setSaving(true);
    try {
      const terms = getFormattedCOTerms(customerOrderOption);
      const action: Array<Action> = [];
      if (order.files.find((f) => f.type === CO_ORDERCONFIRMATION)) {
        action.push({
          collection: CUSTOMERORDER,
          filter: { _id: order._id },
          update: {
            state: CO_PROCESSINGATWAREHOUSE,
            transport: T_WAREHOUSE,
          },
          push: {
            timeline: {
              $each: [
                getCustomerOrderTimelineEntry(CO_T_USESTOCK),
                getCustomerOrderTimelineEntry(CO_T_STOCKORDERCONFIRMED),
              ],
            },
          },
        });
      } else {
        const path = await createPDF(
          createOrderConfirmationHTML(
            order,
            STANDARDTEXTSHORT,
            context.currencies,
            terms.deliveryTerms,
            terms.paymentTerms,
            terms.paymentTermConditions,
            terms.note,
            terms.deliveryCity
          ),
          "Order-Confirmation-" + order.orderNo,
          order.company._id.toString(),
          {
            marginLeft: "2cm",
            marginBottom: "4.2cm",
            footerHtml: CO_FOOTER_HTML,
          }
        );

        if (!path) {
          console.error("Order confirmation could not be created. Please try again later");
          return; // If PDF can not be generated the rest of the operations should not be performed
        } else {
          const fileEntry = {
            _id: new BSON.ObjectId(),
            date: new Date(),
            path,
            type: CO_ORDERCONFIRMATION,
            uploadedBy: userService.getUserId(),
          };
          // update state and terms in order
          action.push({
            collection: CUSTOMERORDER,
            filter: { _id: order._id },
            update: {
              state: CO_PROCESSINGATWAREHOUSE,
              transport: T_WAREHOUSE,
              terms: terms,
            },
            push: {
              files: fileEntry,
              timeline: {
                $each: [
                  getCustomerOrderTimelineEntry(CO_T_USESTOCK),
                  getCustomerOrderTimelineEntry(CO_T_STOCKORDERCONFIRMED),
                  getCustomerOrderTimelineEntry(CO_T_DOCUMENTUPLOADED, { type: CO_ORDERCONFIRMATION }),
                ],
              },
            },
          });
          window.open(resolveFilePath(path));
        }
      }

      const res = await transaction(action);
      if (res) {
        toast.success("Order successfully updated");
        setShow(false);
      } else {
        toast.error("Order could not be updated. Please try again later");
      }
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <button className="btn btn-outline btn-outline-warning ml-2" onClick={handleShow}>
        Use Stock
      </button>
      <Modal contentClassName="bg-dark" show={show} onHide={handleHide} size={"lg"} centered>
        <Modal.Header className="border-0 pb-0">
          <Modal.Title>
            <h1 className="fw-bolder d-flex align-items-center text-white">
              Fulfill Order {getOrderNumber(order)} with Stock
            </h1>
          </Modal.Title>
          <CloseButton variant="white" onClick={handleHide} />
        </Modal.Header>
        <Modal.Body>
          {order.files.find((f) => f.type === CO_ORDERCONFIRMATION) ? (
            ""
          ) : (
            <div>
              <CustomerTerms
                customerTerm={customerOrderOption}
                onChangePaymentTerms={handleChangePaymentTerms}
                onChangeCustomPaymentTerm={handleChangeCustomPaymentTerms}
                onChangeCustomPaymentTermCondition={handleChangeCustomPaymentTermConditions}
                onChangeDeliveryTerms={handleChangeDeliveryTerms}
                onChangeDeliveryCity={handleChangeDeliveryCity}
                onChangeComment={handleChangeNote}
              />
            </div>
          )}
          <div>
            <p className="text-white">
              Are you sure you want to fulfill order
              <em>{getOrderNumber(order)}</em> by existing or incoming stock? Please make sure enough stock is
              available.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline text-muted" onClick={handleHide}>
            Close
          </button>
          <ErrorOverlayButton
            errors={order.state !== CO_ORDEREDBYCUSTOMER ? ["Order cannot be converted"] : []}
            saving={saving}
            className="btn btn-sm btn-outline btn-outline-light"
            buttonText="Confirm"
            onClick={handleConfirm}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConvertToWarehouseOrderModal;
