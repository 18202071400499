import { SupplierOrderExtended } from "../model/supplierOrder.types";
import { CustomerOrderExtended, T_SEAFREIGHT } from "../model/customerOrder.types";
import { isCustomerOrder, isSupplierOrder } from "./orderUtils";
import { isBackToBackOrder } from "./forwardingOrderUtils";
import { getAddressString } from "./addressUtils";
import { getSupplierOrderStartString } from "./supplierOrderUtils";
import { StorageOrder } from "../model/storageOrder.types";
export const INBOUND = "inbound";
export const OUTBOUND = "outbound";

interface OrderGrouping<T> {
  groupedOrders: Array<Array<T>>;
  groupingConditions: string[];
}

export interface StorageOrderMap {
  [key: string]: StorageOrder;
}

/**
 * Splits an array of order groups based on a specified splitting function and returns a new array which contains the split order groups
 *
 * @template {SupplierOrder | CustomerOrder} T - The type of order, must be either SupplierOrder or CustomerOrder
 * @param {Array<Array<T>>} originalArray - The original array of order groups to be split.
 * @param {function(orders: <Array<T>): OrderGrouping<T>} splitFunction - The function to split the order groups.
 * @returns {Array<Array<T>>} The resulting array of split order groups.
 */
export const splitOrderGroup = <T extends SupplierOrderExtended | CustomerOrderExtended>(
  originalArray: Array<Array<T>>,
  splitFunction: (orders: Array<T>) => OrderGrouping<T>
): Array<Array<T>> => {
  const allGroupingConditions = new Set<string>(); // save only unique grouping conditions
  return originalArray
    .map((group) => {
      const splitResult = splitFunction(group);
      splitResult.groupingConditions.forEach((condition) => allGroupingConditions.add(condition)); // add grouping conditions from each group to set
      return splitResult.groupedOrders;
    })
    .flat();
};

/**
 * Splits an array of orders into groups based on transport conditions.
 * @template {SupplierOrder | CustomerOrder} T - The type of order, must be either SupplierOrder or CustomerOrder
 * @param {Array<T>} orders - An array of orders, where T extends SupplierOrder | CustomerOrder.
 * @returns {OrderGrouping<T>} An object containing the grouped orders and their types.
 *
 * The returned object has two properties:
 * - `groupedOrders`: An array of arrays, where each sub-array contains orders grouped by transport condition.
 * - `groupingConditions`: An array of strings representing the transport conditions used for grouping.
 */
export const splitGroupByTransportCondition = <T extends SupplierOrderExtended | CustomerOrderExtended>(
  orders: Array<T>
): OrderGrouping<T> => {
  // Pre-sort transport conditions for all orders so the key for multiple conditions will be the same
  const sortedOrders = orders.map((order) => ({
    ...order,
    commodity: {
      ...order.commodity,
      transportConditions:
        order.commodity.transportConditions?.sort((a, b) => {
          if (a.type < b.type) return -1;
          if (a.type > b.type) return 1;
          return a.property.localeCompare(b.property);
        }) || [],
    },
  }));
  const groupedOrders = sortedOrders.reduce((acc: Record<string, Array<T>>, order) => {
    const transportConditions = order.commodity.transportConditions;
    const defaultKey = "no-transport-conditions";
    const transportConditionKey =
      transportConditions.length === 0
        ? defaultKey // group orders with no transport condition together
        : transportConditions.map((tc) => `${tc.type}-${tc.property}`).join("|");
    // create record with key if it does not already exist
    if (!acc[transportConditionKey]) {
      acc[transportConditionKey] = [];
    }
    acc[transportConditionKey].push(order);
    return acc;
  }, {});
  return { groupedOrders: Object.values(groupedOrders), groupingConditions: Object.keys(groupedOrders) };
};

/**
 * Splits an array of orders into groups based on incoterm.
 * @template {SupplierOrder | CustomerOrder} T - The type of order, must be either SupplierOrder or CustomerOrder
 * @param {Array<T>} orders - An array of orders, where T extends SupplierOrder | CustomerOrder.
 * @returns {OrderGrouping<T>} An object containing the grouped orders and their types.
 *
 * The returned object has two properties:
 * - `groupedOrders`: An array of arrays, where each sub-array contains orders grouped by incoterm.
 * - `groupingConditions`: An array of strings representing the incoterms used for grouping.
 */
export const splitGroupByIncoterm = <T extends SupplierOrderExtended | CustomerOrderExtended>(
  orders: Array<T>
): OrderGrouping<T> => {
  const groupedOrders = new Map<string, Array<T>>();
  orders.forEach((order) => {
    const incotermKey = order.terms?.deliveryTerms || "no-delivery-term";
    if (!groupedOrders.has(incotermKey)) {
      // if key (incoterm) does not already exist, create new one with empty group
      groupedOrders.set(incotermKey, []);
    }
    groupedOrders.get(incotermKey)?.push(order);
  });
  const groupedOrdersArray = Array.from(groupedOrders.values());
  const groupingConditionsArray = Array.from(groupedOrders.keys());
  return { groupedOrders: groupedOrdersArray, groupingConditions: groupingConditionsArray };
};

/**
 * Splits an array of orders into groups based on destination.
 * @template {SupplierOrder | CustomerOrder} T - The type of order, must be either SupplierOrder or CustomerOrder
 * @param {Array<T>} orders - An array of orders, where T extends SupplierOrder | CustomerOrder.
 * @returns {OrderGrouping<T>} An object containing the grouped orders and their types.
 *
 * The returned object has two properties:
 * - `groupedOrders`: An array of arrays, where each sub-array contains orders grouped by destination.
 * - `groupingConditions`: An array of strings representing the destinations used for grouping.
 */
export const splitGroupByDestination = <T extends SupplierOrderExtended | CustomerOrderExtended>(
  orders: Array<T>
): OrderGrouping<T> => {
  const groupedOrders = new Map<string, Array<T>>();
  orders.forEach((order) => {
    let destinationKey = "default"; // only needed for initialization, will be replaced since order is always SO or CO
    if (isSupplierOrder(order)) {
      if (isBackToBackOrder(order)) {
        const firstCO = order.customerOrders[0];
        // SO which are B2B will use the destination address of the CO
        destinationKey = getAddressString(firstCO?.destination || "");
      } else {
        // SO which are not B2B will go to warehouse
        destinationKey = "glomm";
      }
    } else if (isCustomerOrder(order)) {
      // CO will use destination address
      destinationKey = getAddressString(order.destination);
    }
    if (!groupedOrders.has(destinationKey)) {
      // if key (destination) does not already exist, create new one with empty group
      groupedOrders.set(destinationKey, []);
    }
    groupedOrders.get(destinationKey)?.push(order);
  });
  const groupedOrdersArray = Array.from(groupedOrders.values());
  const groupingConditionsArray = Array.from(groupedOrders.keys());
  return { groupedOrders: groupedOrdersArray, groupingConditions: groupingConditionsArray };
};

/**
 * Splits an array of orders into groups based on starting point.
 * @template {SupplierOrder | CustomerOrder} T - The type of order, must be either SupplierOrder or CustomerOrder
 * @param {Array<T>} orders - An array of orders, where T extends SupplierOrder | CustomerOrder.
 * @returns {OrderGrouping<T>} An object containing the grouped orders and their types.
 *
 * The returned object has two properties:
 * - `groupedOrders`: An array of arrays, where each sub-array contains orders grouped by starting point.
 * - `groupingConditions`: An array of strings representing the starting points used for grouping.
 */
export const splitGroupByStartingPoint = <T extends SupplierOrderExtended | CustomerOrderExtended>(
  orders: Array<T>
): OrderGrouping<T> => {
  const groupedOrders = new Map<string, Array<T>>();
  orders.forEach((order) => {
    if (isSupplierOrder(order)) {
      const startingPointKey = getSupplierOrderStartString(order);
      if (!groupedOrders.has(startingPointKey)) {
        // if key (starting point) does not already exist, create new one with empty group
        groupedOrders.set(startingPointKey, []);
      }
      groupedOrders.get(startingPointKey)?.push(order);
    }
  });
  const groupedOrdersArray = Array.from(groupedOrders.values());
  const groupingConditionsArray = Array.from(groupedOrders.keys());

  return { groupedOrders: groupedOrdersArray, groupingConditions: groupingConditionsArray };
};

/**
 * Splits an array of orders into groups based on transport.
 * @template {SupplierOrder | CustomerOrder} T - The type of order, must be either SupplierOrder or CustomerOrder
 * @param {Array<T>} orders - An array of orders, where T extends SupplierOrder | CustomerOrder.
 * @returns {OrderGrouping<T>} An object containing the grouped orders and their types.
 *
 * The returned object has two properties:
 * - `groupedOrders`: An array of arrays, where each sub-array contains orders grouped by transport.
 * - `groupingConditions`: An array of strings representing the transport used for grouping.
 */
export const splitGroupByTransportMethod = <T extends SupplierOrderExtended | CustomerOrderExtended>(
  orders: Array<T>
): OrderGrouping<T> => {
  const groupedOrders = new Map<string, Array<T>>();
  orders.forEach((order) => {
    if (isSupplierOrder(order)) {
      const transportKey = order.transport;
      if (!groupedOrders.has(transportKey)) {
        // if key (transport method) does not already exist, create new one with empty group
        groupedOrders.set(transportKey, []);
      }
      groupedOrders.get(transportKey)?.push(order);
    }
  });
  const groupedOrdersArray = Array.from(groupedOrders.values());
  const groupingConditionsArray = Array.from(groupedOrders.keys());

  return { groupedOrders: groupedOrdersArray, groupingConditions: groupingConditionsArray };
};

/**
 * Splits an array of orders into groups based on ETD.
 * @template {SupplierOrder | CustomerOrder} T - The type of order, must be either SupplierOrder or CustomerOrder
 * @param {Array<T>} orders - An array of orders, where T extends SupplierOrder | CustomerOrder.
 * @returns {OrderGrouping<T>} An object containing the grouped orders and their types.
 *
 * The returned object has two properties:
 * - `groupedOrders`: An array of arrays, where each sub-array contains orders grouped by ETD.
 * - `groupingConditions`: An array of strings representing the grouping criteria; here [] since ETD varies too much.
 */
export const splitGroupByETD = (orders: Array<SupplierOrderExtended>): OrderGrouping<SupplierOrderExtended> => {
  const groupedOrders: Array<Array<SupplierOrderExtended>> = [];
  const allowedTimeDifference = orders[0].transport === T_SEAFREIGHT ? 14 : 7;

  // all orders without an etd will be grouped together since we have no information on how to group
  const ordersWithoutEtd = orders.filter((order) => order.etd === undefined);
  groupedOrders.push(ordersWithoutEtd);

  // sort orders with etd by date to make grouping easier
  const ordersWithEtd = orders.filter((order) => order.etd !== undefined);
  const sortedOrders = ordersWithEtd.sort((a, b) => {
    if (a.etd && b.etd) {
      return a.etd.getTime() - b.etd.getTime();
    }
    return 0; // should never happen because undefined etds are not included in ordersWithEtd
  });

  // group all orders together which are 14 (seafreight) or 7 (all other transport methods) days or less apart from the first order in the array
  let currentGroup: Array<SupplierOrderExtended> = [];
  let comparisonETD = sortedOrders.length > 0 ? (sortedOrders[0].etd as Date) : new Date();
  sortedOrders.forEach((order, index) => {
    // Add first order directly to current group
    if (index === 0) {
      currentGroup.push(order);
    } else {
      // Compare subsequent orders with the first order in the array
      const orderETD = order.etd as Date;
      if (orderETD.getTime() - comparisonETD.getTime() <= allowedTimeDifference * 24 * 60 * 60 * 1000) {
        // If the etd is within the allowed time difference to the first order in the group, add to the current group
        currentGroup.push(order);
      } else {
        // If the etd is outside the allowed time difference to the first order, start a new group and set the newly added order as reference etd
        groupedOrders.push(currentGroup);
        currentGroup = [order];
        comparisonETD = order.etd as Date;
      }
    }
  });
  // Add the last group if it's not empty
  if (currentGroup.length > 0) {
    groupedOrders.push(currentGroup);
  }

  // groupedOrders contain array with grouped orders, groupingConditions are empty since etds vary a lot
  return { groupedOrders: groupedOrders, groupingConditions: [] };
};

/**
 * Splits an array of orders into groups based on targetDate / changedETA.
 * @template {SupplierOrder | CustomerOrder} T - The type of order, must be either SupplierOrder or CustomerOrder
 * @param {Array<T>} orders - An array of orders, where T extends SupplierOrder | CustomerOrder.
 * @returns {OrderGrouping<T>} An object containing the grouped orders and their types.
 *
 * The returned object has two properties:
 * - `groupedOrders`: An array of arrays, where each sub-array contains orders grouped by targetDate / changedETA.
 * - `groupingConditions`: An array of strings representing the grouping criteria; here [] since targetDate / changedETA varies too much.
 */
export const splitGroupByTargetDate = <T extends SupplierOrderExtended | CustomerOrderExtended>(
  orders: Array<T>
): OrderGrouping<T> => {
  const groupedOrders: Array<Array<T>> = [];
  const allowedTimeDifference = orders[0].transport === T_SEAFREIGHT ? 14 : 7;

  // targetDate should always be defined, so no pregrouping here; sorting for better comparison
  const sortedOrders = orders.sort((a, b) => {
    return (a.changedETA ?? a.targetDate).getTime() - (b.changedETA ?? b.targetDate).getTime();
  });

  // group all orders together which are 14 (seafreight) or 7 (all other transport methods) days or less apart from the first order in the array
  let currentGroup: Array<T> = [];
  let comparisonDate = sortedOrders.length > 0 ? sortedOrders[0].changedETA ?? sortedOrders[0].targetDate : new Date();
  sortedOrders.forEach((order, index) => {
    // Add first order directly to current group
    if (index === 0) {
      currentGroup.push(order);
    } else {
      // Compare subsequent orders with the first order in the array
      const orderDate = order.changedETA ?? order.targetDate;
      if (orderDate.getTime() - comparisonDate.getTime() <= allowedTimeDifference * 24 * 60 * 60 * 1000) {
        // If the date is within the allowed time difference to the first order in the group, add to the current group
        currentGroup.push(order);
      } else {
        // If the date is outside the allowed time difference to the first order, start a new group and set the newly added order as reference date
        groupedOrders.push(currentGroup);
        currentGroup = [order];
        comparisonDate = order.changedETA ?? order.targetDate;
      }
    }
  });
  // Add the last group if it's not empty
  if (currentGroup.length > 0) {
    groupedOrders.push(currentGroup);
  }
  // groupedOrders contain array with grouped orders, groupingConditions are empty since targetDates/changedETAs vary a lot
  return { groupedOrders: groupedOrders, groupingConditions: [] };
};
