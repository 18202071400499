import _ from "lodash";
import React, { PureComponent } from "react";
import { DataContextInternalType } from "../../../context/dataContext";
import SCMDashboardOrderKanban from "./SCMDashboardOrderKanban";
import SCMDashboardFilter from "./SCMDashboardFilter";
import { INBOUND, OUTBOUND } from "../../../utils/SCMUtils";
import { SelectOption } from "../../common/CustomSelect";
import { ForwardingOrder, FWO_STATES } from "../../../model/forwardingOrder.types";
import { SupplierOrderExtended } from "../../../model/supplierOrder.types";
import { CustomerOrderExtended } from "../../../model/customerOrder.types";
import { getAvailableOrders } from "../../../utils/forwardingOrderUtils";
import { doFuseSearch } from "../../../utils/baseUtils";
import { isCustomerOrder, isSupplierOrder } from "../../../utils/orderUtils";
import { getSupplierOrderStartString } from "../../../utils/supplierOrderUtils";
import { formatAddress, getStandardWarehouse } from "../../../utils/addressUtils";

interface SCMDashboardProps {
  context: DataContextInternalType;
}

interface SCMDashboardState {
  selectedTab: typeof INBOUND | typeof OUTBOUND;
  showFilter: boolean;
  search: string;
  orderType?: SelectOption;
  transport?: SelectOption;
  startingPoint?: SelectOption;
  destination?: SelectOption;
  supplier?: SelectOption;
  forwarder?: SelectOption;
  etdFrom: Date | null;
  etdTo: Date | null;
  etaFrom: Date | null;
  etaTo: Date | null;
  forwardingOrders: Array<ForwardingOrder>;
  availableOrders: Array<CustomerOrderExtended | SupplierOrderExtended>;
}

class SCMDashboard extends PureComponent<SCMDashboardProps, SCMDashboardState> {
  constructor(props: SCMDashboardProps) {
    super(props);
    this.state = {
      selectedTab: INBOUND,
      showFilter: true,
      search: "",
      orderType: undefined,
      transport: undefined,
      startingPoint: undefined,
      destination: undefined,
      supplier: undefined,
      forwarder: undefined,
      etdFrom: null,
      etdTo: null,
      etaFrom: null,
      etaTo: null,
      forwardingOrders: [],
      availableOrders: [],
    };
  }

  componentDidMount() {
    this.getForwardingOrders();
    this.getAvailableOrders();
  }

  componentDidUpdate(prevProps: SCMDashboardProps) {
    const { context } = this.props;
    if (!_.isEqual(context.forwardingOrder, prevProps.context.forwardingOrder)) {
      this.getForwardingOrders();
    }
    // available orders won't be fetched here again, otherwise custom order grouping would be overwritten each time an order changes
    // content of orders inside already existing groups will be updated instead in SCMDashboardOrderKanban itself
  }

  handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ search: e.target.value });
  };

  handleSelectFilter = (fieldName: string, entry: SelectOption | undefined) => {
    //@ts-ignore
    this.setState({ [fieldName]: entry });
  };

  handleSelectDate = (entry: React.ChangeEvent<HTMLInputElement>) => {
    let date = null;
    if (entry.target.value) date = new Date(entry.target.value);
    //@ts-ignore
    this.setState({ [entry.target.name]: date });
  };

  handleToggleFilter = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };

  /**
   * Retrieves all forwarding orders from the context and saves them in the state
   */
  getForwardingOrders = () => {
    const { context } = this.props;
    const forwardingOrders = context.forwardingOrder.filter((fwo) => fwo.state !== FWO_STATES.FWO_CANCELED);
    this.setState({ forwardingOrders });
  };

  /**
   * Retrieves all available supplier and customer orders and saves them in the state
   */
  getAvailableOrders = () => {
    const { context } = this.props;
    const availableOrders = getAvailableOrders(context).map(
      (selectOption: SelectOption<CustomerOrderExtended | SupplierOrderExtended>) =>
        selectOption.object as CustomerOrderExtended | SupplierOrderExtended
    );
    this.setState({ availableOrders });
  };

  /**
   * Filters all forwardingOrders based on search and forwarder filter
   */
  getFilteredForwardingOrders = (): Array<ForwardingOrder> => {
    const { forwardingOrders, search, forwarder } = this.state;
    let filteredForwardingOrders = forwardingOrders.slice();
    if (search.trim()) {
      filteredForwardingOrders = doFuseSearch(filteredForwardingOrders, search, ["forwardingOrderNo"]);
    }
    if (forwarder) {
      filteredForwardingOrders = filteredForwardingOrders.filter((fwo) => fwo.forwarder === forwarder.value);
    }
    return filteredForwardingOrders;
  };

  /**
   * Filters all orders based on search and selected orderType, transport, startingPoint, destination, supplier, etd and eta filters
   */
  getFilteredOrders = (): Array<CustomerOrderExtended | SupplierOrderExtended> => {
    const { context } = this.props;
    const {
      availableOrders,
      search,
      orderType,
      transport,
      startingPoint,
      destination,
      supplier,
      etdFrom,
      etdTo,
      etaFrom,
      etaTo,
    } = this.state;
    let filteredOrders = availableOrders.slice();
    if (search.trim()) {
      filteredOrders = doFuseSearch(filteredOrders, search, ["orderNo", "commodity.title.en", "supplier.name"]);
    }
    if (orderType) {
      if (orderType.value === "customer") filteredOrders = filteredOrders.filter((o) => isCustomerOrder(o));
      else if (orderType.value === "supplier") filteredOrders = filteredOrders.filter((o) => isSupplierOrder(o));
    }
    if (transport) {
      filteredOrders = filteredOrders.filter((o) => o.transport === transport.value);
    }
    if (startingPoint) {
      filteredOrders = filteredOrders.filter((o) => {
        const startString = isSupplierOrder(o) ? getSupplierOrderStartString(o) : "-";
        return startString === startingPoint.value;
      });
    }
    if (destination) {
      const glomm = getStandardWarehouse(context);
      const glommAddress = glomm?.address ? formatAddress(glomm.address) : "-";
      filteredOrders = filteredOrders.filter((o) => {
        const destinationString = isSupplierOrder(o)
          ? glommAddress
          : typeof o.destination === "string"
          ? o.destination
          : formatAddress(o.destination);
        return destinationString === destination.value;
      });
    }
    if (supplier) {
      filteredOrders = filteredOrders.filter((o) => o.supplier && o.supplier.name === supplier.value);
    }
    if (etaFrom) {
      const etaFromNormalized = new Date(etaFrom.getFullYear(), etaFrom.getMonth(), etaFrom.getDate()); // set time to midnight for comparison
      filteredOrders = filteredOrders.filter((order) => {
        const eta = order.changedETA ?? order.targetDate;
        const etaNormalized = new Date(eta.getFullYear(), eta.getMonth(), eta.getDate()); // set time to midnight for comparison
        return etaNormalized >= etaFromNormalized;
      });
    }
    if (etaTo) {
      const etaToNormalized = new Date(etaTo.getFullYear(), etaTo.getMonth(), etaTo.getDate()); // set time to midnight for comparison
      filteredOrders = filteredOrders.filter((order) => {
        const eta = order.changedETA ?? order.targetDate;
        const etaNormalized = new Date(eta.getFullYear(), eta.getMonth(), eta.getDate()); // set time to midnight for comparison
        return etaNormalized <= etaToNormalized;
      });
    }
    if (etdFrom) {
      const etdFromNormalized = new Date(etdFrom.getFullYear(), etdFrom.getMonth(), etdFrom.getDate()); // set time to midnight for comparison
      filteredOrders = filteredOrders.filter((order) => {
        if (isSupplierOrder(order) && order.etd) {
          const etd = order.etd;
          const etdNormalized = new Date(etd.getFullYear(), etd.getMonth(), etd.getDate()); // set time to midnight for comparison
          return etdNormalized >= etdFromNormalized;
        }
        return false; // orders which are no SO or have no etd are not included
      });
    }
    if (etdTo) {
      const etdToNormalized = new Date(etdTo.getFullYear(), etdTo.getMonth(), etdTo.getDate()); // set time to midnight for comparison
      filteredOrders = filteredOrders.filter((order) => {
        if (isSupplierOrder(order) && order.etd) {
          const etd = order.etd;
          const etdNormalized = new Date(etd.getFullYear(), etd.getMonth(), etd.getDate()); // set time to midnight for comparison
          return etdNormalized <= etdToNormalized;
        }
        return false; // orders which are no SO or have no etd are not included
      });
    }
    return filteredOrders;
  };

  render() {
    const {
      selectedTab,
      showFilter,
      search,
      orderType,
      transport,
      startingPoint,
      destination,
      supplier,
      forwarder,
      etdFrom,
      etdTo,
      etaFrom,
      etaTo,
    } = this.state;
    const { context } = this.props;
    const filteredOrders = this.getFilteredOrders();
    const filteredForwardingOrders = this.getFilteredForwardingOrders();

    return (
      <div className="d-flex flex-column flex-column-fluid pt-15">
        <div className="container-fluid dashboard-container">
          <div className="row bg-white p-3">
            <div className="btn-group d-flex">
              <label
                className={"btn btn-light " + (selectedTab === INBOUND && "active")}
                onClick={() => this.setState({ selectedTab: INBOUND })}
              >
                Inbound (Sea, Air, etc.)
              </label>
              <label
                className={"btn btn-light " + (selectedTab === OUTBOUND && "active")}
                onClick={() => this.setState({ selectedTab: OUTBOUND })}
              >
                Outbound (road)
              </label>
            </div>
          </div>
          {showFilter ? (
            <SCMDashboardFilter
              selectedTab={selectedTab}
              context={context}
              availableOrders={filteredOrders}
              availableForwardingOrders={filteredForwardingOrders}
              search={search}
              orderType={orderType}
              transport={transport}
              startingPoint={startingPoint}
              destination={destination}
              supplier={supplier}
              forwarder={forwarder}
              etdFrom={etdFrom}
              etdTo={etdTo}
              etaFrom={etaFrom}
              etaTo={etaTo}
              onSearch={this.handleChangeSearch}
              onSelectFilter={(fieldName, e) => this.handleSelectFilter(fieldName, e)}
              onSelectDate={(e) => this.handleSelectDate(e)}
              onToggleFilter={this.handleToggleFilter}
            />
          ) : (
            <div className="row bg-white justify-content-end">
              <div className="col-1 justify-content-end">
                <button type="button" className="btn btn-light btn-sm mb-2 px-2 p-1" onClick={this.handleToggleFilter}>
                  Show Filter Options
                </button>
              </div>
            </div>
          )}
          <div className="row mt-5">
            <SCMDashboardOrderKanban
              selectedTab={selectedTab}
              context={context}
              forwardingOrders={filteredForwardingOrders}
              availableOrders={filteredOrders}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SCMDashboard;
