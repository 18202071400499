import { BSON } from "realm-web";
import { Commodity } from "../model/commodity.types";
import {
  CommodityOfferRequest,
  CommodityOfferRequestExtended,
  COR_ARTICLE_TYPE,
  COR_T_APPROVED,
  COR_T_CANCELED,
  COR_T_INREVIEW,
  COR_T_REJECTED,
  COR_TIMELINETYPE,
  CORState,
} from "../model/commodityOfferRequest.types";
import { callFunction } from "../services/dbService";
import userService from "../services/userService";
import { OrderTimeline } from "../model/commonTypes";
import { EXTENDED_ORDER_TYPES, ORDER_TYPES } from "./orderUtils";
import { CONTRACT_TYPES, EXTENDED_CONTRACT_TYPES } from "./contractUtils";
import { isAnyFinishedProduct, SupplierArticleExtended } from "./productArticleUtils";

export enum CORFiles {
  COA = "coa",
  SPECIFICATION = "supplierSpecification",
}

export const COR_FILTEROPTIONS = [
  { value: "open", label: "Open" },
  { value: "closed", label: "Closed" },
  { value: "all", label: "All" },
  { value: "requested", label: "Requested" },
  { value: "inReview", label: "In Review" },
  { value: "approved", label: "Approved" },
];

export const COR_SORTOPTIONS = [
  { value: "created", label: "Request Date" },
  { value: "commodity.title.en", label: "Commodity Name" },
  { value: "supplier.name", label: "Supplier" },
];

export const F_UPSERTCOMMODITYOFFERREQUEST = "upsertCommodityOfferRequest";

/**
 * Returns the default commodity offer request.
 * @param article Article that is requested to offer
 * @param supplier Optional, if set the supplier is also set
 * @returns {CommodityOfferRequest} Default commodity offer request
 */
export function getDefaultCommodityOfferRequest(
  article?: SupplierArticleExtended,
  supplier?: string
): CommodityOfferRequest {
  if (!supplier) supplier = userService.getCompany();
  const isFP = isAnyFinishedProduct(article);
  return {
    _id: new BSON.ObjectId(),
    article: {
      type: isFP ? COR_ARTICLE_TYPE.FINISHEDPRODUCT : COR_ARTICLE_TYPE.COMMODITY,
      id: article ? article._id.toString() : "",
    },
    created: new Date(),
    coa: null,
    note: "",
    specification: null,
    state: CORState.REQUESTED,
    supplier: supplier ?? "",
    timeline: [],
  };
}

/**
 * Generate a timeline entry for a commodity offer request with the given type and optional reason.
 * @param type Type of the entry
 * @param reason Optional, reason for rejecting
 * @returns {OrderTimeline} Timeline object
 */
export function getCORTimelineEntry(type: COR_TIMELINETYPE, reason?: string): OrderTimeline {
  return {
    _id: new BSON.ObjectId(),
    type,
    date: new Date(),
    person: userService.getUserId(),
    payload: reason ? { reason } : undefined,
  };
}

/**
 * Filters the given commodities to only contain the ones that are valid for offering. Commodities that are offered need
 * to be approved, enabled and contain a master specification.
 * @param commodities List of commodities who should be filtered
 * @returns {Array<Commodity>} List of valid commodities
 */
export function getValidCommodities(commodities: Array<Commodity>): Array<Commodity> {
  return commodities.filter((c) => c.approved && !c.disabled);
}

/**
 * Resolves the state of the commodity offer request.
 * @param cor Request whose state should be resolved
 * @returns {string} Resolved state
 */
export function resolveCommodityOfferRequestState(cor: CommodityOfferRequest | CommodityOfferRequestExtended): string {
  switch (cor.state) {
    case CORState.REQUESTED:
      return "Requested";
    case CORState.IN_REVIEW:
      return "In Review";
    case CORState.APPROVED:
      return "Approved";
    case CORState.CANCELED:
      return "Canceled";
    case CORState.REJECTED:
      return "Rejected";
  }
}

/**
 * Calls upsertCommodityOfferRequest in the backend.
 * @param commodityOfferRequest Request that should be upserted
 * @param insert Indicates whether the request is inserted or updated
 * @returns {Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>  | false>} Result of the call
 */
async function callUpsertCommodityOfferRequest(
  commodityOfferRequest: Partial<CommodityOfferRequest>,
  insert: boolean
): Promise<
  Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false
> {
  return callFunction(F_UPSERTCOMMODITYOFFERREQUEST, [commodityOfferRequest, insert]);
}

/**
 * Inserts the commodity offer request into the database.
 * @param commodityOfferRequest Request that should be inserted
 * @returns {Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false>} Result of the insert
 */
export async function insertCommodityOfferRequest(
  commodityOfferRequest: CommodityOfferRequest
): Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false> {
  return (await callUpsertCommodityOfferRequest(commodityOfferRequest, true)) as
    | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>
    | false;
}

/**
 * Updates a commodity offer request in the database.
 * @param commodityOfferRequest Request that should be updated
 * @param commodityOfferRequestId ID of the request
 * @returns {Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false>} Result of the update
 */
export async function updateCommodityOfferRequest(
  commodityOfferRequest: Partial<CommodityOfferRequest>,
  commodityOfferRequestId?: BSON.ObjectId
): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> {
  if (commodityOfferRequestId) commodityOfferRequest._id = commodityOfferRequestId;
  return (await callUpsertCommodityOfferRequest(commodityOfferRequest, false)) as
    | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>
    | false;
}

/**
 * Checks if the given order is a commodity offer request or not.
 * @param cor Order that should be checked
 * @returns {boolean} Indicates whether the order is a commodity offer request or not
 */
export function isCommodityOfferRequest(
  cor: ORDER_TYPES | EXTENDED_ORDER_TYPES | CommodityOfferRequest | CONTRACT_TYPES | EXTENDED_CONTRACT_TYPES
): cor is CommodityOfferRequest {
  return "coa" in cor && "specification" in cor;
}

/**
 * Get the progress of the commodity offer request and the variant of the progress bar.
 * @param cor Commodity offer requests whose progress should be resolved
 * @returns {[percent: number, variant: string]} Tuple that contains the progress and its variant
 */
export function getCommodityOfferRequestProgress(
  cor: CommodityOfferRequest | CommodityOfferRequestExtended
): [percent: number, variant: string] {
  switch (cor.state) {
    case CORState.REQUESTED:
      return [0, "warning"];
    case CORState.CANCELED:
    case CORState.REJECTED:
      return [100, "danger"];
    case CORState.IN_REVIEW:
      return [50, "warning"];
    case CORState.APPROVED:
      return [100, "success"];
    default:
      return [0, "danger"];
  }
}

/**
 * Resolves when the commodity offer request entered its state.
 * @param cor Commodity offer request whose latest state update should be resolved
 * @returns {Date | undefined} Date of the latest state update, if not found undefined is returned
 */
export function resolveLatestStateUpdateDate(
  cor: CommodityOfferRequest | CommodityOfferRequestExtended
): Date | undefined {
  switch (cor.state) {
    case CORState.REQUESTED:
      return cor.created;
    case CORState.CANCELED:
      return cor.timeline.find((t) => t.type === COR_T_CANCELED)?.date;
    case CORState.REJECTED:
      return cor.timeline.find((t) => t.type === COR_T_REJECTED)?.date;
    case CORState.IN_REVIEW:
      return cor.timeline.find((t) => t.type === COR_T_INREVIEW)?.date;
    case CORState.APPROVED:
      return cor.timeline.find((t) => t.type === COR_T_APPROVED)?.date;
    default:
      return cor.created;
  }
}
