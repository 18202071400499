import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { Textarea } from "../../../../common/Textarea";
import ErrorOverlayButton from "../../../../common/ErrorOverlayButton";
import { CO_T_DESTINATIONCHANGED, CustomerOrderExtended } from "../../../../../model/customerOrder.types";
import { getOrderNumber } from "../../../../../utils/orderUtils";
import { getCustomerOrderTimelineEntry } from "../../../../../utils/customerOrderUtils";
import { CUSTOMERORDER, transaction } from "../../../../../services/dbService";
import userService from "../../../../../services/userService";
import { getDefaultSlackChannel, NotificationType, sendMessage } from "../../../../../services/slackService";
import { AddressSelectOption, formatAddress, isAddress } from "../../../../../utils/addressUtils";
import CustomSelect from "../../../../common/CustomSelect";

interface ChangeDestinationAddressModalProps {
  order: CustomerOrderExtended;
}

const ChangeDestinationAddressModal: React.FunctionComponent<ChangeDestinationAddressModalProps> = ({ order }) => {
  const [{ show, saving, destination, note }, setModalState] = useState({
    show: false,
    saving: false,
    destination: isAddress(order.destination)
      ? { value: order.destination._id.toString(), label: formatAddress(order.destination), address: order.destination }
      : { value: "", label: order.destination, address: order.destination },
    note: "",
  });

  const addressSelections = useMemo(
    () =>
      order.company.address.map((a) => {
        return { value: a._id.toString(), label: formatAddress(a), address: a };
      }),
    [order.company.address]
  );

  const handleShow = useCallback(() => {
    setModalState((prevState) => {
      return { ...prevState, show: true };
    });
  }, []);

  const handleHide = useCallback(() => {
    setModalState((prevState) => {
      return { ...prevState, show: false };
    });
  }, []);

  const handleChangeDestination = useCallback((e: AddressSelectOption) => {
    setModalState((prevState) => {
      return { ...prevState, destination: e };
    });
  }, []);

  const handleChangeNote = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setModalState((prevState) => {
      return { ...prevState, note: e.currentTarget.value };
    });
  }, []);

  const handleSaveDestination = useCallback(async () => {
    if (typeof destination.address !== "string") {
      try {
        setModalState((prevState) => {
          return { ...prevState, saving: true };
        });
        const timelineEntryText = `${note} - Changed from ${
          isAddress(order.destination) ? formatAddress(order.destination, { withComma: true }) : order.destination
        } to ${formatAddress(destination.address, { withComma: true })}`;
        const timelineEntry = getCustomerOrderTimelineEntry(CO_T_DESTINATIONCHANGED, { reason: timelineEntryText });
        const action = {
          collection: CUSTOMERORDER,
          filter: { _id: order._id },
          update: {
            destination: destination.address,
          },
          push: { timeline: timelineEntry },
        };

        const res = await transaction([action]);
        if (res) {
          toast.success("Destination address successfully changed");
          const user = userService.getUserData();

          await sendMessage(
            getDefaultSlackChannel(false, NotificationType.DELIVERIES),
            `Destination address of customer order <https://${
              process.env.REACT_APP_BASE_URL || ""
            }/customerOrder/${order._id.toString()}|*${getOrderNumber(order)}*> was changed from ${
              isAddress(order.destination) ? formatAddress(order.destination, { withComma: true }) : order.destination
            } to ${formatAddress(destination.address, { withComma: true })} by ${user.prename} ${
              user.surname
            }. Reason: ${note}`
          );
          setModalState((prevState) => {
            return { ...prevState, show: false };
          });
        } else {
          toast.error("Error changing destination address");
        }
      } finally {
        setModalState((prevState) => {
          return { ...prevState, saving: false, note: "" };
        });
      }
    }
  }, [note, destination, order]);

  const validateData = useCallback(() => {
    const errors = [];
    if (note.trim() === "") errors.push("Please add a note");
    // happens only if order.destination is a string so it should not be possible to save it like this
    if (typeof destination.address === "string") errors.push("You did not change the address string");
    else if (_.isEqual(destination.address, order.destination)) errors.push("You did not change the address");
    return errors;
  }, [note, destination, order]);

  return (
    <>
      <button className="btn btn-icon btn-sm p-0  h-auto w-auto align-middle mb-1 ml-1" onClick={handleShow}>
        <i className="fa fa-edit text-gray-300 text-hover-white p-0" />
      </button>
      <Modal contentClassName="bg-dark" show={show} onHide={handleHide} size={"lg"} centered>
        <Modal.Header className="border-0 pb-0">
          <Modal.Title>
            <h1 className="fw-bolder d-flex align-items-center text-white">Change Customer Destination Address</h1>
          </Modal.Title>
          <CloseButton variant="white" onClick={handleHide} />
        </Modal.Header>
        <Modal.Body>
          <div className="mb-2">
            <label className="fs-5 fw-bold mb-2">New Destination Address</label>
            <CustomSelect value={destination} options={addressSelections} onChange={handleChangeDestination} />
          </div>
          <div className="mb-2">
            <label className="fs-5 fw-bold mb-2">Note</label>
            <Textarea
              className="fs-6 form-control custom-form-control"
              placeholder="Add a reason why Destination Address is changed"
              value={note}
              onChange={handleChangeNote}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline btn-outline-light float-right" onClick={handleHide}>
            Close
          </button>
          <ErrorOverlayButton
            errors={validateData()}
            saving={saving}
            className={"btn btn-sm btn-outline btn-outline-light"}
            buttonText={"Save"}
            onClick={handleSaveDestination}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChangeDestinationAddressModal;
