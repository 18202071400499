import { BSON } from "realm-web";
import userService from "./userService";

// dev
const DEFAULT_SLACK_DEV_CHANNEL = "#dev-internal-test";

// production
const DEFAULT_SLACK_CHANNEL = "#notifications-general";
const DEFAULT_SLACK_ORDER_CHANNEL = "#notifications-orders";
const DEFAULT_SLACK_DELIVERIES_CHANNEL = "#notifications-deliveries";
const DEFAULT_SLACK_PRICE_CHANNEL = "#notifications-prices";
const DEFAULT_SLACK_REGISTRATION_CHANNEL = "#notifications-registrations";
const DEFAULT_SLACK_REQUEST_CHANNEL = "#notifications-requests";
const DEFAULT_SLACK_SCM_CHANNEL = "#notifications-scm";
const DEFAULT_SLACK_BATCH_HANDLING_CHANNEL = "#notifications-batch-handling";
const DEFAULT_SLACK_ANONYMOUS_CHANNEL = "#notifications-anonymous";

// production error
const DEFAULT_SLACK_ERROR_CHANNEL = "#rawbids-error-channel";

export enum NotificationType {
  DEFAULT,
  ORDER,
  PRICE,
  REGISTRATION,
  REQUEST,
  ONBOARDING,
  SCM,
  DELIVERIES,
  BATCH_HANDLING,
  ANONYMOUS,
}

export function getDefaultSlackChannel(error?: boolean, notificationType?: NotificationType) {
  let destinationChannel = DEFAULT_SLACK_DEV_CHANNEL;
  const type = notificationType ? notificationType : NotificationType.DEFAULT;
  if (
    process.env.NODE_ENV === "production" &&
    !process.env.REACT_APP_BASE_URL?.startsWith("demo") &&
    !process.env.REACT_APP_NAME?.startsWith("ark")
  ) {
    if (error) {
      destinationChannel = DEFAULT_SLACK_ERROR_CHANNEL;
    } else {
      switch (type) {
        case NotificationType.DEFAULT:
          destinationChannel = DEFAULT_SLACK_CHANNEL;
          break;
        case NotificationType.ORDER:
          destinationChannel = DEFAULT_SLACK_ORDER_CHANNEL;
          break;
        case NotificationType.DELIVERIES:
          destinationChannel = DEFAULT_SLACK_DELIVERIES_CHANNEL;
          break;
        case NotificationType.PRICE:
          destinationChannel = DEFAULT_SLACK_PRICE_CHANNEL;
          break;
        case NotificationType.REGISTRATION:
          destinationChannel = DEFAULT_SLACK_REGISTRATION_CHANNEL;
          break;
        case NotificationType.REQUEST:
          destinationChannel = DEFAULT_SLACK_REQUEST_CHANNEL;
          break;
        case NotificationType.ONBOARDING:
          destinationChannel = DEFAULT_SLACK_REGISTRATION_CHANNEL;
          break;
        case NotificationType.SCM:
          destinationChannel = DEFAULT_SLACK_SCM_CHANNEL;
          break;
        case NotificationType.BATCH_HANDLING:
          destinationChannel = DEFAULT_SLACK_BATCH_HANDLING_CHANNEL;
          break;
        case NotificationType.ANONYMOUS:
          destinationChannel = DEFAULT_SLACK_ANONYMOUS_CHANNEL;
      }
    }
  }

  return destinationChannel;
}

/**
 * Calls the given function with the given args in backend.
 * @param target ObjectId of a user to notify or slack channel name
 * @param message the message to send
 * @param isNotificationRole whether the target is a notificationRole
 * @returns {Promise<boolean>} Result of backend function
 * */
export async function sendMessage(target: BSON.ObjectId | string, message: string, isNotificationRole?: boolean) {
  return userService.getUser()?.callFunction<boolean>("sendSlackMessage", [target, message, isNotificationRole]);
}
