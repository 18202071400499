import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { Input } from "../../../common/Input";
import { Service } from "../../../../model/service.types";
import { insertService, updateService } from "../../../../utils/serviceUtils";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import { Textarea } from "../../../common/Textarea";

interface CreateServiceModalProps {
  service?: Service;
  additionalButtonClasses?: string;
}

interface CreateServiceModalState {
  step: number;
  show: boolean;
  saving: boolean;
  title: string;
  description: string;
  price: string;
  duration: string;
}

class CreateServiceModal extends PureComponent<CreateServiceModalProps, CreateServiceModalState> {
  constructor(props: CreateServiceModalProps) {
    super(props);
    this.state = this.getDefaultState(false);
  }

  handleShow = () => this.setState(this.getDefaultState(true));
  handleHide = () => this.setState({ show: false });
  handleNext = () => this.setState({ step: 1 });
  handleBack = () => this.setState({ step: 0 });

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  /**
   * Handles the creation / edit of the service
   */
  handleCreateService = async () => {
    const { service } = this.props;
    const { title, description, duration, price } = this.state;
    this.setState({ saving: true });
    const serv = {
      _id: service ? service._id : new BSON.ObjectId(),
      serviceNo: service ? service.serviceNo : "-1",
      title: { en: title },
      description: { en: description },
      price: Number(price),
      duration: Number(duration),
      disabled: false,
    };
    let result;
    // If a service was passed as props we are in edit case
    if (service) {
      result = await updateService(serv);
    } else {
      result = await insertService(serv);
    }
    if (result) {
      toast.success("Service " + (service ? "edited" : "inserted") + " successfully");
      this.setState({ show: false, saving: false });
    } else {
      toast.error("Error " + (service ? "editing" : "inserting") + " service");
    }
  };

  /**
   * Returns the default state of the component.
   * @param show: Indicates which values should be used for show
   * @returns { CreateServiceModalState } Default state with given show value
   */
  getDefaultState = (show: boolean) => {
    const { service } = this.props;
    return {
      show,
      step: 0,
      saving: false,
      title: service ? service.title.en : "",
      description: service ? service.description.en : "",
      duration: service ? service.duration : "0",
      price: service ? service.price : "0",
    } as CreateServiceModalState;
  };

  /**
   * Check the input for errors. No changes at all are also seen as error.
   * @returns { Array<string> } List of errors
   */
  checkForErrors = () => {
    const { service } = this.props;
    const { title, description, duration, price } = this.state;
    const errors = [];
    if (title.trim().length < 3) errors.push("Title of the service is too short.");
    if (!errors.length) {
      if (
        service &&
        service.title.en === title &&
        service.description.en === description &&
        service.duration.toString() === duration.toString() &&
        service.price.toString() === price.toString()
      ) {
        errors.push("Service was not changed.");
      }
    }
    return errors;
  };

  render() {
    const { additionalButtonClasses, service } = this.props;
    const { description, duration, title, price, saving, show, step } = this.state;
    const onSummary = step === 1;
    const errors = this.checkForErrors();

    return (
      <>
        <button
          className={"btn btn-outline btn-outline-light " + (additionalButtonClasses ?? "")}
          onClick={this.handleShow}
        >
          {service ? "Edit" : "New Service"}
        </button>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">{service ? "Edit" : "New"} service</h1>
            </Modal.Title>
            <CloseButton variant={"white"} onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-3">
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="required fs-5 fw-bold mb-2">Title</label>
                <Input
                  type="text"
                  className={"form-control custom-form-control " + (onSummary ? "disabled" : "")}
                  name="title"
                  value={title}
                  disabled={onSummary}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="required fs-5 fw-bold mb-2">Description</label>
                <Textarea
                  className={"form-control custom-form-control " + (onSummary ? "disabled" : "")}
                  rows={2}
                  name="description"
                  value={description}
                  disabled={onSummary}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="required fs-5 fw-bold mb-2">Duration (in full days)</label>
                <Input
                  type="number"
                  className={"form-control custom-form-control " + (onSummary ? "disabled" : "")}
                  name="duration"
                  value={duration}
                  disabled={onSummary}
                  integerOnly={true}
                  min={0}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="required fs-5 fw-bold mb-2">Price (in €)</label>
                <Input
                  type={"number"}
                  className={"form-control custom-form-control " + (onSummary ? "disabled" : "")}
                  name="price"
                  value={price}
                  disabled={onSummary}
                  min={0}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            {onSummary && (
              <div className="row">
                <h2 className="fw-bolder text-white text-center">Please check that the data above is correct!</h2>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-sm btn-outline btn-text-white"
              onClick={step === 0 ? this.handleHide : this.handleBack}
            >
              {step === 0 ? "Close" : "Back"}
            </button>
            <ErrorOverlayButton
              errors={errors}
              className={"btn btn-sm btn-outline btn-outline-light " + (errors.length > 0 || saving ? "disabled" : "")}
              buttonText={saving ? "Saving..." : step === 0 ? "Next" : service ? "Confirm" : "Create"}
              onClick={step === 0 ? this.handleNext : this.handleCreateService}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreateServiceModal;
