import _ from "lodash";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { CloseButton, Modal } from "react-bootstrap";
import { BSON } from "realm-web";
import { Input } from "../../../../common/Input";
import { Textarea } from "../../../../common/Textarea";
import ErrorOverlayButton from "../../../../common/ErrorOverlayButton";
import { SO_T_DOCUMENTUPLOADED, SupplierOrderExtended } from "../../../../../model/supplierOrder.types";
import { createPDF, FOOTER_WITH_FINANCIAL_HTML } from "../../../../../utils/pdfUtils";
import { Action, SUPPLIERORDER, transaction } from "../../../../../services/dbService";
import { getSupplierOrderTimelineEntry, SO_CUSTOMSCLEARANCE } from "../../../../../utils/supplierOrderUtils";
import { resolveFilePath } from "../../../../../utils/fileUtils";
import { createCustomsClearanceInstruction } from "../../../../../utils/pdf/customsClearanceInstructionsGenerationUtils";
import { T_AIRFREIGHT, T_EUSTOCK, T_SEAFREIGHT } from "../../../../../model/customerOrder.types";
import { getSeaportName } from "../../../../../utils/seaportUtils";
import { getAirportName } from "../../../../../utils/airportUtils";
import userService from "../../../../../services/userService";
import { SupplierSupplierOrderExtended } from "../../../../../model/supplier/supplierSupplierOrder.types";

interface CreateCustomsClearanceInstructionsModalProps {
  order: SupplierOrderExtended | SupplierSupplierOrderExtended;
}

interface CreateCustomsClearanceInstructionsModalState {
  show: boolean;
  saving: boolean;
  country: string;
  btiRef: string;
  customsValue: string;
  startingPoint: string;
  hsCode: string;
}

class CreateCustomsClearanceInstructionsModal extends PureComponent<
  CreateCustomsClearanceInstructionsModalProps,
  CreateCustomsClearanceInstructionsModalState
> {
  constructor(props: CreateCustomsClearanceInstructionsModalProps) {
    super(props);
    this.state = this.getDefaultState(props);
  }

  componentDidUpdate(prevProps: Readonly<CreateCustomsClearanceInstructionsModalProps>) {
    const { order: prevOrder } = prevProps;
    const { order } = this.props;
    if (!_.isEqual(prevOrder, order)) {
      this.setState(this.getDefaultState(this.props));
    }
  }

  handleShow = () => this.setState({ show: true });

  handleHide = () => this.setState(this.getDefaultState(this.props));

  handleChangeCountry = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ country: e.currentTarget.value });
  };

  handleChangeBTIRef = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ btiRef: e.currentTarget.value });
  };

  handleChangeStartingPoint = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ startingPoint: e.currentTarget.value });
  };

  handleChangeHSCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ hsCode: e.currentTarget.value });
  };

  handleChangeCustomsValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ customsValue: e.currentTarget.value });
  };

  handleConfirm = async () => {
    const { country, btiRef, customsValue, startingPoint, hsCode } = this.state;
    const { order } = this.props;
    this.setState({ saving: true });
    try {
      const path = await createPDF(
        createCustomsClearanceInstruction(order, country, customsValue, hsCode, btiRef, startingPoint),
        "Customs-Clearance-Instruction-RB-" + order.orderNo,
        order._id.toString(),
        {
          marginLeft: "2cm",
          marginBottom: "4.2cm",
          footerHtml: FOOTER_WITH_FINANCIAL_HTML,
        }
      );
      const action: Array<Action> = [];
      if (!path) {
        console.error("Customs Clearance Instruction could not be created. Please try again later");
        return;
      } else {
        const fileEntry = {
          _id: new BSON.ObjectId(),
          date: new Date(),
          path,
          type: SO_CUSTOMSCLEARANCE,
          uploadedBy: userService.getUserId(),
        };
        // update state and terms in order
        action.push({
          collection: SUPPLIERORDER,
          filter: { _id: order._id },
          push: {
            files: fileEntry,
            timeline: {
              $each: [getSupplierOrderTimelineEntry(SO_T_DOCUMENTUPLOADED, { type: SO_CUSTOMSCLEARANCE })],
            },
          },
        });
        window.open(resolveFilePath(path));
      }
      const result = await transaction(action);
      if (result) {
        toast.success(`Supplier order updated successfully`);
        this.setState({ show: false });
      } else toast.error(`Supplier order could not be updated. Please try again later`);
    } catch (e) {
      toast.error("An error has occurred on updating the order: " + e);
    } finally {
      this.setState({ saving: false });
    }
  };

  getDefaultState = (props: CreateCustomsClearanceInstructionsModalProps) => {
    const { order } = props;
    return {
      show: false,
      saving: false,
      btiRef: order.commodity.btiRefNo || "",
      customsValue: "per commercial invoice",
      country: order.commodity.country.name,
      startingPoint:
        order.transport === T_SEAFREIGHT && order.purchaseOrderInformation?.startingSeaport
          ? `${getSeaportName(order.purchaseOrderInformation.startingSeaport)}`
          : order.transport === T_AIRFREIGHT && order.purchaseOrderInformation?.startingAirport
          ? `${getAirportName(order.purchaseOrderInformation.startingAirport)}`
          : order.transport === T_EUSTOCK &&
            order.shipment[0] &&
            order.shipment[0].shipping &&
            order.shipment[0].shipping.startingPoint &&
            typeof order.shipment[0].shipping.startingPoint === "string"
          ? order.shipment[0].shipping.startingPoint
          : "",
      hsCode: order.commodity.hsCode,
    };
  };

  validateData = () => {
    const { country, customsValue, hsCode } = this.state;
    const errors = [];
    if (country.trim() === "") errors.push("Enter the country of origin");
    if (customsValue.trim() === "") errors.push("Enter the customs value");
    if (hsCode.trim() === "") errors.push("Enter HS Code");
    return errors;
  };

  render() {
    const { order } = this.props;
    const { show, saving, btiRef, customsValue, country, startingPoint, hsCode } = this.state;
    return (
      <>
        <button className="btn btn-sm btn-outline text-white p-0" onClick={this.handleShow}>
          Generate
        </button>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Customs Clearance Instruction</h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="fw-bolder text-white fs-5 my-2">HS Code</div>
            <Input
              className="form-control custom-form-control"
              value={hsCode}
              placeholder="Insert HS Code"
              onChange={this.handleChangeHSCode}
            />
            <div className="fw-bolder text-white fs-5 my-2">Country of origin</div>
            <Input
              className="form-control custom-form-control"
              value={country}
              placeholder="Insert Country of Origin"
              onChange={this.handleChangeCountry}
            />
            <div className="fw-bolder text-white fs-5 my-2">BTI Reference no.</div>
            <Input
              className="form-control custom-form-control"
              value={btiRef}
              placeholder="Insert BTI Reference no"
              onChange={this.handleChangeBTIRef}
            />
            {order.transport === T_EUSTOCK && (
              <>
                <div className="fw-bolder text-white fs-5 my-2">
                  Starting Point Incoterms (needs to be inserted due to EU stock)
                </div>
                <Input
                  className="form-control custom-form-control"
                  value={startingPoint}
                  placeholder="Insert Starting Point for Incoterms"
                  onChange={this.handleChangeStartingPoint}
                />
              </>
            )}
            <div className="fw-bolder text-white fs-5 my-2">Customs Value</div>
            <Textarea
              className="form-control custom-form-control"
              value={customsValue}
              placeholder="Insert Customs Value"
              onChange={this.handleChangeCustomsValue}
            />
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-text text-muted" onClick={this.handleHide}>
              Close
            </button>
            <ErrorOverlayButton
              errors={this.validateData()}
              saving={saving}
              className="btn btn-light btn-sm "
              buttonText="Confirm"
              onClick={this.handleConfirm}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreateCustomsClearanceInstructionsModal;
