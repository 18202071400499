import React from "react";
import { CALC_AIRFREIGHT, CALC_SEAFREIGHT } from "../../../../model/supplierOrder.types";
import { Input } from "../../../common/Input";

export type GraduationReference =
  | {
      type: "default";
      tab: typeof CALC_AIRFREIGHT | typeof CALC_SEAFREIGHT;
    }
  | {
      type: "property" | "article";
      tab: typeof CALC_AIRFREIGHT | typeof CALC_SEAFREIGHT;
      id: string;
    };

interface GraduationCardProps {
  graduations: Array<number>;
  title?: string;
  description?: string;
  isFullyDeletable?: true;
  onDeleteGraduation: (idx: number, reference?: GraduationReference) => void;
  onAddGraduation: (reference?: GraduationReference) => void;
  onChangeGraduation: (e: React.ChangeEvent<HTMLInputElement>, idx: number, reference?: GraduationReference) => void;
  reference: GraduationReference;
  children?: JSX.Element | Array<JSX.Element>;
}

const GraduationCard: React.FunctionComponent<GraduationCardProps> = ({
  graduations,
  title,
  description,
  isFullyDeletable,
  onDeleteGraduation,
  onAddGraduation,
  onChangeGraduation,
  reference,
  children,
}) => {
  return (
    <div className="bg-light2 rounded p-5 mb-7">
      <h3 className="mb-3 ml-2">{title}</h3>
      <h4 className="text-muted ml-2">{description}</h4>
      <div className="table-responsive mt-5">
        <table className="gy-1">
          <thead>
            <tr className="text-gray-400 fw-bolder fs-7 text-uppercase gs-0 border-bottom-dark-gray">
              <th style={{ width: "95%" }} />
              <th style={{ width: "5%" }} />
            </tr>
          </thead>
          <tbody>
            {graduations.map((value, idx) => {
              return (
                <tr
                  key={(reference.type === "default" ? reference.tab + "-" + reference.tab : reference.id) + "-" + idx}
                >
                  <td className="p-1">
                    <div className="input-group">
                      <Input
                        type={"number"}
                        value={value}
                        className="form-control custom-form-control py-0 bg-dark"
                        name={"graduationValue"}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => onChangeGraduation(e, idx, reference)}
                      />
                      <div className="input-group-append rounded-end pl-1">
                        <div className="input-group-text form-control custom-form-control bg-dark">kg</div>
                      </div>
                    </div>
                  </td>
                  <td>
                    {(isFullyDeletable || graduations.length > 1) && (
                      <button className="btn btn-text-muted btn-sm" onClick={() => onDeleteGraduation(idx, reference)}>
                        <i className="fa fa-minus text-danger" />
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
            <tr>
              <td />
              <td>
                <button className="btn btn-text-muted btn-sm" onClick={() => onAddGraduation(reference)}>
                  <i className="fa fa-plus text-success" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        {children}
      </div>
    </div>
  );
};

export default GraduationCard;
