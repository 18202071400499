import { BSON } from "realm-web";
import { OrderTimeline } from "./commonTypes";
import { Supplier } from "./supplier.types";
import { Commodity } from "./commodity.types";
import { FinishedProduct } from "./finishedProduct.types";

export enum CORState {
  REQUESTED = "corRequested",
  IN_REVIEW = "corInReview",
  APPROVED = "corApproved",
  REJECTED = "corRejected",
  CANCELED = "corCanceled",
}

// Timeline
export const COR_T_CREATED = "corTCreated";
export const COR_T_NOTE = "corTNote";
export const COR_T_INREVIEW = "corTInReview";
export const COR_T_REJECTED = "corTRejected";
export const COR_T_APPROVED = "corTApproved";
export const COR_T_CANCELED = "corTCanceled";

export type COR_TIMELINETYPE =
  | typeof COR_T_CREATED
  | typeof COR_T_NOTE
  | typeof COR_T_INREVIEW
  | typeof COR_T_REJECTED
  | typeof COR_T_APPROVED
  | typeof COR_T_CANCELED;

export enum COR_ARTICLE_TYPE {
  COMMODITY = "commodity",
  FINISHEDPRODUCT = "finishedProduct",
}

export interface CommodityOfferRequest {
  _id: BSON.ObjectId;
  article: { type: COR_ARTICLE_TYPE; id: string };
  supplier: string;
  created: Date;
  coa: null | UploadedOfferFile;
  specification: null | UploadedOfferFile;
  state: CORState;
  note: string;
  timeline: Array<OrderTimeline>;
}

export interface UploadedOfferFile {
  _id: BSON.ObjectId;
  name: string;
  path: string;
  type: "supplierSpecification" | "coa";
  date: Date;
  size: number;
}

export type CORE_ARTICLE =
  | { type: COR_ARTICLE_TYPE.COMMODITY; document: Commodity }
  | { type: COR_ARTICLE_TYPE.FINISHEDPRODUCT; document: FinishedProduct };

export interface CommodityOfferRequestExtended extends Omit<CommodityOfferRequest, "article" | "supplier"> {
  article: CORE_ARTICLE;
  supplier: Supplier;
}
