import _ from "lodash";
import React, { PureComponent } from "react";
import ImageUploader from "react-images-upload";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import validator from "validator";
import { Image } from "react-bootstrap";
import { Input } from "../common/Input";
import { toAbsoluteUrl } from "../../utils/baseUtils";
import { DataContextType } from "../../context/dataContext";
import userService from "../../services/userService";
import { updateUserData, UserUpdate } from "../../utils/userUtils";
import ErrorOverlayButton from "../common/ErrorOverlayButton";
import { resolveFilePath, uploadFile, uploadPublicFile } from "../../utils/fileUtils";

interface UserProfileProps {
  context: DataContextType;
}

interface UserProfileState {
  prename: string;
  surname: string;
  position: string;
  image: string;
  phones: Array<{ value: string; description: string }>;
  emails: Array<{ value: string; description: string }>;
  saving: boolean;
}

class UserProfile extends PureComponent<UserProfileProps, UserProfileState> {
  // Login mail is static and can not be changed
  loginMail: string;

  constructor(props: UserProfileProps) {
    super(props);
    this.loginMail = userService.getUser()?.profile.email ?? "Error reading login mail";
    this.state = this.getDefaultState();
  }

  /**
   * Handle profile image Upload, public only for internal
   * @param files the selected file(s)
   */
  handleUpload = async (files: File[]) => {
    // Only one file is allowed, upload in mediahub
    if (files.length === 1) {
      const file = files.pop();
      if (!file) return;
      // Set saving flag so that buttons are disabled until actions were performed
      this.setState({ saving: true });
      // File name consisting of the userdata id, current timestamp and the given local file name
      const userId = userService.getUserId();
      const fileName = `${userId}_${Date.now()}_${file.name}`;
      const type = userService.getUserType();
      let resp: string | boolean;
      try {
        // Only for type internal public file upload
        if (type === "internal") {
          resp = uploadPublicFile(file, fileName);
        } else {
          resp = uploadFile(file, fileName, undefined, false);
        }
        if (!resp) {
          toast.error("File upload failed. Please try again later.");
          return;
        }
        const imgUrl = resolveFilePath(resp.toString(), type === "internal");

        // Update picture on server
        const user = userService.getUserData();
        const update: UserUpdate = { image: imgUrl };
        const result = await updateUserData(user, update);
        if (result && result.modifiedCount > 0) {
          // Refresh user custom data since mongo handles it other than normal data
          userService.getUser()?.refreshCustomData();
          toast.success("Profile image updated successfully");
          this.setState({ image: imgUrl });
        } else {
          toast.error("Error updating profile image. Please try again.");
        }
      } catch (ex) {
        toast.error("File upload failed. Please try again later.");
        console.error(ex);
      } finally {
        // Unset saving flag so that buttons can be clicked again
        this.setState({ saving: false });
      }
    }
  };

  /**
   * Handles the change of a plain string value.
   * @param e Event that triggered the change
   */
  handleChangeStringValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  /**
   * Handles changing the phone number at the referenced index.
   * @param idx Index of the phone number that was changed
   * @param e Event that triggered the change
   */
  handleChangePhone = (idx: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const phones = _.cloneDeep(this.state.phones);
    _.set(phones[idx], e.target.name, e.target.value);
    this.setState({ phones });
  };

  /**
   * Handles deleting a phone number from the phones array.
   * @param idx Index of the phone number that should be deleted
   */
  handleClickDeletePhone = (idx: number) => {
    const phones = _.cloneDeep(this.state.phones);
    phones.splice(idx, 1);
    this.setState({ phones });
  };

  /**
   * Handles adding a new, empty phone number
   */
  handleClickAddPhone = () => {
    const phones = _.cloneDeep(this.state.phones);
    phones.push({ value: "", description: "" });
    this.setState({ phones });
  };

  /**
   * Handles changing the mail address at the referenced index.
   * @param idx Index of the mail that was changed
   * @param e Event that triggered the change
   */
  handleChangeMail = (idx: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const emails = _.cloneDeep(this.state.emails);
    _.set(emails[idx], e.target.name, e.target.value);
    this.setState({ emails });
  };

  /**
   * Handles deleting a mail from the emails array.
   * @param idx Index of the mail that should be deleted
   */
  handleClickDeleteMail = (idx: number) => {
    const emails = _.cloneDeep(this.state.emails);
    emails.splice(idx, 1);
    this.setState({ emails });
  };

  /**
   * Handles added a new, empty mail address.
   */
  handleClickAddMail = () => {
    const emails = _.cloneDeep(this.state.emails);
    emails.push({ value: "", description: "" });
    this.setState({ emails });
  };

  /**
   * Handles resetting the displayed data to the values that are currently stored inside the database.
   */
  handleClickReset = () => this.setState(this.getDefaultState());

  /**
   * Handles resetting the displayed profile image to the default image.
   */
  handleClickResetImg = async () => {
    // Set saving flag so that buttons are disabled until actions were performed
    this.setState({ saving: true });
    const imgUrl = toAbsoluteUrl("assets/media/avatars/blank.png");
    try {
      // Reset picture on server
      const user = userService.getUserData();
      const update: UserUpdate = { image: imgUrl };
      const result = await updateUserData(user, update);
      if (result && result.modifiedCount > 0) {
        // Refresh user custom data since mongo handles it other than normal data
        userService.getUser()?.refreshCustomData();
        toast.success("Profile image reset successfully");
        this.setState({ image: imgUrl });
      } else {
        toast.error("Error resetting profile image. Please try again.");
      }
    } catch (ex) {
      toast.error("Resetting profile image failed. Please try again later.");
      console.error(ex);
    } finally {
      // Unset saving flag so that buttons can be clicked again
      this.setState({ saving: false });
    }
  };

  /**
   * Handles saving all changes - except for profile images - that were made to the user data.
   */
  handleClickSave = async () => {
    const { prename, surname, position, phones, emails } = this.state;
    // Set saving flag so that buttons are disabled until actions were performed
    this.setState({ saving: true });
    try {
      const user = userService.getUserData();
      // Phones and mails are always part of the update since the check for changes would be unnecessary complex
      const update: UserUpdate = { phones, emails };
      // Plain string values are only updated when they really changed
      if (prename !== user.prename) update.prename = prename;
      if (surname !== user.surname) update.surname = surname;
      if (position !== user.position) update.position = position;
      const result = await updateUserData(user, update);
      if (result && result.modifiedCount > 0) {
        // Refresh user custom data since mongo handles it other than normal data
        userService.getUser()?.refreshCustomData();
        toast.success("User data updated successfully");
      } else {
        toast.error("Error updating user data. Please try again.");
      }
    } finally {
      // Unset saving flag so that buttons can be clicked again
      this.setState({ saving: false });
    }
  };

  /**
   * Generates the default state for this component
   * @returns { UserProfileState } Default state
   */
  getDefaultState = (): UserProfileState => {
    const user = userService.getUserData();
    return {
      prename: user.prename,
      surname: user.surname,
      position: user.position,
      image: user.image,
      phones: _.cloneDeep(user.phones),
      emails: _.cloneDeep(user.emails),
      saving: false,
    };
  };

  /**
   * Checks the input values for errors.
   * @returns { Array<string> } All validation errors that occurred
   */
  checkForErrors = (): Array<string> => {
    const { prename, surname, phones, emails } = this.state;
    const errors = [];
    if (!prename) errors.push("Please insert a prename!");
    if (!surname) errors.push("Please insert a surname!");
    for (let i = 0; i < phones.length; i++) {
      if (!phones[i].value)
        errors.push("Phone number " + (i + 1) + " is empty. Please insert a number or remove the entry");
    }
    for (let i = 0; i < emails.length; i++) {
      if (!validator.isEmail(emails[i].value)) errors.push("Email " + (i + 1) + " is not a valid email");
    }
    return errors;
  };

  render() {
    const { prename, surname, position, phones, emails, saving } = this.state;
    const image = this.state.image !== "" ? this.state.image : toAbsoluteUrl("/assets/media/avatars/blank_dark.png");

    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="card bg-white">
              <div className="card-body p-9">
                <h3 className="card-title align-items-start flex-column mb-15">
                  <span className="card-label fw-bolder mb-3 fs-3rem">General Settings</span>
                </h3>
                <div className="row mb-6">
                  <div className="col-4 fw-bold fs-6">Image</div>
                  <div className="col-8 fw-bold fs-6">
                    <div className="text-center">
                      <Image src={image} className="mw-125px mb-3 rounded" />
                      <button
                        type="button"
                        className="badge badge-circle reset-image-button-relative border-0 reset-image-button-danger"
                        aria-label="Close"
                        onClick={this.handleClickResetImg}
                      >
                        <i className="fas fa-times" />
                      </button>
                    </div>
                    <ImageUploader
                      buttonClassName={saving ? "disabled" : ""}
                      withIcon={false}
                      withLabel={false}
                      buttonText={"Upload Profile Image"}
                      imgExtension={["jpeg", ".jpg", ".png"]}
                      accept=".png, .jpg, .jpeg"
                      maxFileSize={10 * 1024 * 1024}
                      singleImage
                      errorClass="px-3"
                      fileSizeError={"is too big. Max. 10MB"}
                      fileTypeError={"is not the right file type. Choose .jpg or .png"}
                      onChange={saving ? undefined : this.handleUpload}
                    />
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-4 col-form-label required fw-bold fs-6">Name</label>
                  <div className="col-4 ">
                    <Input
                      className="form-control custom-form-control"
                      type="text"
                      name="prename"
                      value={prename}
                      onChange={this.handleChangeStringValue}
                    />
                  </div>
                  <div className="col-4 ">
                    <Input
                      className="form-control custom-form-control"
                      type="text"
                      name="surname"
                      value={surname}
                      onChange={this.handleChangeStringValue}
                    />
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-4 col-form-label fw-bold fs-6">Position</label>
                  <div className="col-8 ">
                    <Input
                      className="form-control custom-form-control"
                      type="text"
                      name="position"
                      value={position}
                      onChange={this.handleChangeStringValue}
                    />
                  </div>
                </div>
                {phones.map((entry, key) => {
                  return (
                    <div className="row mb-6" key={entry.value + key}>
                      <label className="col-4 col-form-label fw-bold fs-6">
                        {key === 0 && <span>Phone numbers</span>}
                      </label>
                      <div className="col-4 ">
                        <Input
                          className="form-control custom-form-control"
                          type="text"
                          name="value"
                          value={entry.value}
                          onChange={(e) => this.handleChangePhone(key, e)}
                          placeholder="Phone"
                        />
                      </div>
                      <div className="col-3 ">
                        <Input
                          className="form-control custom-form-control"
                          type="text"
                          name="description"
                          value={entry.description}
                          onChange={(e) => this.handleChangePhone(key, e)}
                          placeholder="Description"
                        />
                      </div>
                      <div className="col-1 text-right">
                        <button className="btn btn-text btn-sm" onClick={() => this.handleClickDeletePhone(key)}>
                          <i className="fa fa-times text-danger pr-0" />
                        </button>
                      </div>
                    </div>
                  );
                })}
                <div className="row mb-6">
                  {phones.length === 0 ? (
                    <>
                      <label className="col-4 col-form-label fw-bold fs-6">
                        <span>Phone numbers</span>
                      </label>
                      <div className="col-7" />
                    </>
                  ) : (
                    <div className="col-11" />
                  )}
                  <div className="col-1 text-right">
                    <button className="btn btn-text btn-sm" onClick={this.handleClickAddPhone}>
                      <i className="fa fa-plus pr-0" />
                    </button>
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-4 col-form-label fw-bold fs-6">
                    <span className="required">Mail addresses</span>
                  </label>
                  <div className="col-4 ">
                    <Input
                      className="form-control custom-form-control"
                      type="text"
                      name="mail"
                      value={this.loginMail}
                      disabled
                    />
                  </div>
                  <div className="col-4 ">
                    <Input
                      className="form-control custom-form-control"
                      type="text"
                      value="Login Mail Address"
                      disabled
                    />
                  </div>
                </div>
                {emails.map((entry, key) => {
                  return (
                    <div className="row mb-6" key={entry.value + key}>
                      <div className="col-4" />
                      <div className="col-4 ">
                        <Input
                          className="form-control custom-form-control"
                          type="text"
                          name="value"
                          value={entry.value}
                          onChange={(e) => this.handleChangeMail(key, e)}
                          placeholder="Email"
                        />
                      </div>
                      <div className="col-3 ">
                        <Input
                          className="form-control custom-form-control"
                          type="text"
                          name="description"
                          value={entry.description}
                          onChange={(e) => this.handleChangeMail(key, e)}
                          placeholder="Description"
                        />
                      </div>
                      <div className="col-1 text-right">
                        <button
                          className={"btn btn-text btn-sm" + (emails.length === 1 ? " disabled" : "")}
                          disabled={emails.length === 1}
                          onClick={emails.length === 1 ? undefined : () => this.handleClickDeleteMail(key)}
                        >
                          <i className="fa fa-times text-danger pr-0" />
                        </button>
                      </div>
                    </div>
                  );
                })}
                <div className="row">
                  <div className="col-11" />
                  <div className="col-1 text-right">
                    <button className="btn btn-text btn-sm" onClick={this.handleClickAddMail}>
                      <i className="fa fa-plus pr-0" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-footer border-0 text-right">
                <Link to="/changePassword" id="changePassword" className="text-muted text-hover-white float-left">
                  Change Password
                </Link>
                <button
                  className={"btn btn-sm btn-outline btn-outline-light mr-2" + (saving ? " disabled" : "")}
                  onClick={saving ? undefined : this.handleClickReset}
                >
                  Reset
                </button>
                <ErrorOverlayButton
                  errors={this.checkForErrors()}
                  className={"btn btn-sm btn-outline btn-outline-light" + (saving ? " disabled" : "")}
                  buttonText="Save"
                  onClick={saving ? undefined : this.handleClickSave}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserProfile;
