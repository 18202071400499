import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { CustomerContractExtended } from "../../../../model/customerContract.types";
import { CustomerOrder } from "../../../../model/customerOrder.types";
import { formatCurrency, formatDate, toAbsoluteUrl } from "../../../../utils/baseUtils";
import { getCW } from "../../../../utils/dateUtils";
import { CC_CONTRACTCONFIRMATION } from "../../../../utils/customerContractUtils";
import { resolveFilePath } from "../../../../utils/fileUtils";
import { getOrderNumber, getOrderStateDescriptions } from "../../../../utils/orderUtils";
import { formatArticleUnit } from "../../../../utils/productArticleUtils";

interface CustomerContractOverviewProps {
  contract: CustomerContractExtended;
  calls?: Array<CustomerOrder>;
}

class CustomerContractOverview extends PureComponent<CustomerContractOverviewProps> {
  render() {
    const { contract, calls } = this.props;
    const { totalAmount, restAmount, minimumCallQuantity } = contract.contractInformation;
    const { totalPrice, currency, purchasePriceCommodities, discount } = contract.priceInformation;
    const discountAmount = totalPrice - totalPrice * (1 - (discount || 0) / 100);
    const confirmationCustomer = contract.files.find((f) => f.type === CC_CONTRACTCONFIRMATION);

    return (
      <div className="card bg-white">
        <div className="card-header border-0 mt-5">
          <div className="card-title flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">Contract Information</span>
          </div>
        </div>
        <div className="card-body p-9 py-0">
          <div className="table-responsive mt-1 pt-3 bg-light2">
            <table className="table fw-bold gy-1 ">
              <tbody>
                <tr>
                  <td className="text-white w-50">Total Amount</td>
                  <td className="text-muted">
                    {totalAmount} {formatArticleUnit(contract.commodity.unit)}
                  </td>
                </tr>
                <tr>
                  <td className="text-white w-50">Amount Left</td>
                  <td className="text-muted">
                    {restAmount} {formatArticleUnit(contract.commodity.unit)}
                  </td>
                </tr>
                <tr>
                  <td className="text-white w-50">Minimum Call-Off Quantity</td>
                  <td className="text-muted">
                    {minimumCallQuantity} {formatArticleUnit(contract.commodity.unit)}
                  </td>
                </tr>
                <tr>
                  <td className="text-white w-50">Validity Period</td>
                  <td className="text-muted">
                    {formatDate(contract.validityPeriod.start)} to {formatDate(contract.validityPeriod.end)}
                  </td>
                </tr>
                <tr>
                  <td className="text-white w-50">Available From</td>
                  <td className="text-muted">
                    {contract.arrivalDate
                      ? `since CW ${getCW(contract.arrivalDate)}-${contract.arrivalDate.getFullYear()}`
                      : contract.targetDate
                      ? `around CW ${getCW(contract.targetDate)}-${contract.targetDate.getFullYear()}`
                      : "Unknown"}
                  </td>
                </tr>
                <tr>
                  <td className="text-white w-50">Confirmation</td>
                  <td className="text-muted">
                    {confirmationCustomer ? (
                      <a href={resolveFilePath(confirmationCustomer.path)} target="_blank" rel="noopener noreferrer">
                        <img alt="pdf" className="w-25px me-3" src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} />
                      </a>
                    ) : (
                      "Missing"
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="card-header border-0 mt-5">
          <div className="card-title flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">Calculation</span>
          </div>
        </div>
        <div className="card-body p-9 py-0">
          <div className="table-responsive mt-1 pt-3 bg-light2">
            <table className="table fw-bold gy-1 ">
              <tbody>
                <tr>
                  <td className="text-white w-50">Commodities</td>
                  <td className="text-muted">{formatCurrency(purchasePriceCommodities, currency)}</td>
                </tr>
                <tr>
                  <td className="text-white w-50">Purchase Price per {contract.commodity.unit}</td>
                  <td className="text-muted">
                    {formatCurrency(purchasePriceCommodities / contract.contractInformation.totalAmount, currency)}
                  </td>
                </tr>
                <tr>
                  <td className="text-white w-50">Price per {contract.commodity.unit}</td>
                  <td className="text-muted">
                    {formatCurrency(totalPrice / contract.contractInformation.totalAmount, currency)}
                  </td>
                </tr>
                {discount && discount !== 0 && (
                  <tr>
                    <td className="text-white w-50">Discount</td>
                    <td className="text-muted">{discount}%</td>
                  </tr>
                )}
                <tr>
                  <td className="text-white w-50">Total</td>
                  <td className="text-muted">{formatCurrency(totalPrice - discountAmount, currency)}</td>
                </tr>
                <tr>
                  <td className="text-white w-50">Total Margin</td>
                  <td className="text-muted">
                    {formatCurrency(totalPrice - purchasePriceCommodities - discountAmount, currency)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="card-header border-0 mt-5">
          <div className="card-title flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">Call-Offs</span>
          </div>
        </div>
        <div className="card-body p-9 pt-0">
          <div className="table-responsive mt-1 pt-3 bg-light2">
            <table className="table fw-bold gy-2 ">
              <tbody>
                {calls && calls.length > 0 ? (
                  calls.map((c) => (
                    <tr key={c._id.toString()}>
                      <td className="text-white w-25">
                        <Link className="text-white custom-link" to={`/customerOrder/${c._id.toString()}`}>
                          Order {getOrderNumber(c)}
                        </Link>
                      </td>
                      <td className="text-white w-25">
                        {c.amount} {formatArticleUnit(c.unit)}
                      </td>
                      <td className="text-white w-25">{getOrderStateDescriptions(c).title}</td>
                      <td className="text-muted text-right w-25 ">{formatDate(c.createdAt)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-white text-center pb-5 pt-7 text-muted">No calls created yet</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerContractOverview;
