import React, { useContext } from "react";
import { DataContextInternal } from "../../context/dataContext";
import WarehouseListing from "./WarehouseListing";

interface WarehouseListingWrapperProps {}

const WarehouseListingWrapper: React.FunctionComponent<WarehouseListingWrapperProps> = (props) => {
  const context = useContext(DataContextInternal);

  return <WarehouseListing {...props} context={context} />;
};

export default WarehouseListingWrapper;
