import React, { useContext } from "react";
import { DataContextAnonymous, DataContextCustomer } from "../../../context/dataContext";
import NewsArticlePage from "./NewsArticlePage";
import userService from "../../../services/userService";
import { ANONYMOUS, CUSTOMER } from "../../../utils/userUtils";

const NewsArticlePageWrapper: React.FunctionComponent = () => {
  let context;
  if (userService.getUserType() === CUSTOMER) {
    context = useContext(DataContextCustomer);
  } else if (userService.getUserType() === ANONYMOUS) {
    context = useContext(DataContextAnonymous);
  }
  if (!context) return <></>;
  return <NewsArticlePage context={context} />;
};

export default NewsArticlePageWrapper;
