import React, { Component } from "react";
import { BSON } from "realm-web";
import { SelectOption } from "../../common/CustomSelect";
import { Input } from "../../common/Input";
import { formatCurrency } from "../../../utils/baseUtils";
import { Position } from "../../../model/invoice.types";
import { I_P_DUNNING, I_P_PAYMENT } from "../../../utils/invoiceUtils";

interface InvoicePositionProps {
  currency: SelectOption;
  position: Position;
  reverseCharge: boolean;
  nonEU: boolean;
  onRemovePosition: (id: BSON.ObjectId) => void;
  onPositionChange: (id: BSON.ObjectId, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

class InvoicePosition extends Component<InvoicePositionProps> {
  render() {
    const { currency, position, reverseCharge, nonEU, onRemovePosition, onPositionChange } = this.props;
    // Automatically generated positions should be fixed (beside the description)
    const locked = [I_P_PAYMENT, I_P_DUNNING].includes(position.unit);

    return (
      <tr className="border-bottom-dark" id={position._id.toString()}>
        <td>
          <Input
            type="text"
            className="form-control  custom-form-control mb-2"
            name="title"
            placeholder="Position Title"
            value={position.title}
            disabled={locked}
            onChange={(e) => onPositionChange(position._id, e)}
          />
          <textarea
            rows={2}
            className="form-control custom-form-control text-muted"
            name="description"
            placeholder="Description"
            value={position.description}
            onChange={(e) => onPositionChange(position._id, e)}
          />
        </td>
        <td className="ps-0">
          <Input
            className="form-control custom-form-control"
            type="number"
            min={1}
            name="quantity"
            value={position.quantity}
            disabled={locked}
            onChange={(e) => onPositionChange(position._id, e)}
          />
        </td>
        <td className="ps-0">
          <Input
            className="form-control custom-form-control"
            type="text"
            name="unit"
            value={position.unit}
            disabled={locked}
            onChange={(e) => onPositionChange(position._id, e)}
          />
        </td>
        <td className="ps-0">
          <Input
            type="number"
            className="form-control custom-form-control"
            name="price"
            value={position.price}
            disabled={locked}
            allowNegative={true}
            onChange={(e) => onPositionChange(position._id, e)}
          />
        </td>
        {!reverseCharge && !nonEU ? (
          <td className="ps-0">
            <Input
              type="number"
              className="form-control custom-form-control"
              name="vatPercentage"
              value={position.vatPercentage}
              disabled={locked}
              onChange={(e) => onPositionChange(position._id, e)}
            />
          </td>
        ) : (
          <td className="ps-0 pt-7 text-white text-nowrap">
            <span className="text-white">0</span>
          </td>
        )}
        <td className="ps-0">
          <Input
            type="number"
            className="form-control custom-form-control"
            name="discount"
            value={position.discount}
            disabled={locked}
            onChange={(e) => onPositionChange(position._id, e)}
          />
        </td>
        <td className="ps-0 pt-7 text-end text-white text-nowrap">
          <span className="text-white"> {formatCurrency(position.total, currency.value)}</span>
        </td>
        <td className="ps-0 pt-5 text-end">
          {!locked && (
            <button
              type="button"
              className="btn btn-sm btn-icon btn-active-color-primary"
              onClick={() => onRemovePosition(position._id)}
            >
              <span className="svg-icon svg-icon-3 text-danger">X</span>
            </button>
          )}
        </td>
      </tr>
    );
  }
}

export default InvoicePosition;
