import _ from "lodash";
import React, { PureComponent, useEffect, useState } from "react";
import { DataContextInternalType } from "../../../../context/dataContext";
import { Batch } from "../../../../model/batch.types";
import Search from "../../../common/Search";
import BaseListing, { BaseListingHeaderDefinition } from "../../../common/BaseListing";
import { paginate, PaginationState } from "../../../common/Pagination";
import CreateBatchModal from "../modals/CreateBatchModal";
import {
  B_STATE_OPTIONS,
  B_LISTING_VIEWS,
  getPreparedStockForView,
  sortStock,
  ShipmentBatch,
  getFilteredStock,
  extendBatch,
  isShipmentBatch,
  extendShipmentBatch,
} from "../../../../utils/batchUtils";
import { C_PACKAGE_OPTIONS } from "../../../../utils/commodityUtils";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";
import { BatchEntry } from "../../../batch/BatchEntry";
import EditBatchModal from "../modals/EditBatchModal";
import { SortColumn } from "../../../../utils/filterUtils";
import { SupplierOrder } from "../../../../model/supplierOrder.types";
import UpdateBatchStateModal from "../modals/UpdateBatchStateModal";
import { CommodityStatistics } from "../../../../model/statistics/commodityStatistics.types";
import { formatDateTime } from "../../../../utils/baseUtils";
import Tooltip from "../../../common/Tooltip";
import {
  formatArticleUnit,
  getArticleStatistics,
  InternalArticleExtended,
} from "../../../../utils/productArticleUtils";

interface CommodityPageStockProps {
  article: InternalArticleExtended;
  context: DataContextInternalType;
}

interface CommodityPageStockState extends PaginationState {
  search: string;
  packageType?: SelectOption;
  state?: SelectOption;
  currentStock: Array<Batch | ShipmentBatch>;
  commoditySupplierOrders: Array<SupplierOrder>;
  batchToEdit?: Batch;
  batchToHandle?: { batch: Batch; type: "release" | "block" };
  preparedStock: Array<Batch | ShipmentBatch>;
  sortColumn?: SortColumn;
}

class CommodityPageStock extends PureComponent<CommodityPageStockProps, CommodityPageStockState> {
  constructor(props: CommodityPageStockProps) {
    super(props);
    this.state = {
      search: "",
      currentStock: this.getCurrentStock(props),
      commoditySupplierOrders: this.getSupplierOrders(props),
      currentPage: 1,
      pageSize: 25,
      preparedStock: [],
    };
  }

  componentDidMount() {
    this.setState({ preparedStock: this.getStock() });
  }

  componentDidUpdate(prevProps: Readonly<CommodityPageStockProps>, prevState: Readonly<CommodityPageStockState>) {
    if (
      prevState.search !== this.state.search ||
      prevState.pageSize !== this.state.pageSize ||
      prevState.packageType !== this.state.packageType ||
      prevState.state !== this.state.state ||
      prevState.currentPage !== this.state.currentPage
    )
      return;
    const currentStock = this.getCurrentStock(this.props);
    if (
      prevProps.article._id.toString() !== this.props.article._id.toString() ||
      !_.isEqual(currentStock, this.state.currentStock)
    ) {
      this.setState({ currentStock }, () => {
        const stock = this.getStock();
        const newState: Pick<CommodityPageStockState, "preparedStock" | "batchToEdit" | "batchToHandle"> = {
          preparedStock: stock,
        };
        if (this.state.batchToEdit)
          newState.batchToEdit = stock.find(
            (b) => b._id.toString() === this.state.batchToEdit?._id.toString()
          ) as Batch;
        if (this.state.batchToHandle)
          newState.batchToHandle = {
            batch: stock.find((b) => b._id.toString() === this.state.batchToHandle?.batch._id.toString()) as Batch,
            type: this.state.batchToHandle.type,
          };
        this.setState(newState);
      });
    }
  }

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value, currentPage: 1 });
  handlePageChange = (page: number) => this.setState({ currentPage: page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleEditBatch = (batch: Batch) => this.setState({ batchToEdit: batch });
  handleBatchState = (batch: Batch, type: "release" | "block") => this.setState({ batchToHandle: { batch, type } });
  handleCloseModal = () => {
    this.setState({ batchToEdit: undefined });
  };
  handleCloseStateModal = () => {
    this.setState({ batchToHandle: undefined });
  };

  handleSort = (column: string) => {
    const { sortColumn } = this.state;
    let newOrder: "asc" | "desc" = "desc";
    if (sortColumn && column === sortColumn.column) newOrder = sortColumn.order === "asc" ? "desc" : "asc";
    this.setState({
      sortColumn: { column, order: newOrder },
    });
  };

  getCurrentStock = (props: CommodityPageStockProps) => {
    return props.context.batch.filter((b) => b.commodity._id.toString() === props.article._id.toString());
  };

  getSupplierOrders = (props: CommodityPageStockProps) =>
    props.context.supplierOrder.filter((s) => s.commodity._id.toString() === props.article._id.toString());

  getStock = () => {
    const { currentStock, commoditySupplierOrders } = this.state;
    return getPreparedStockForView(currentStock, B_LISTING_VIEWS[1], commoditySupplierOrders);
  };

  getFilteredStock = () => {
    const { search, packageType, state, preparedStock } = this.state;
    return getFilteredStock(preparedStock, search, packageType, state);
  };

  render() {
    const { article, context } = this.props;
    const { search, pageSize, currentPage, state, packageType, batchToEdit, batchToHandle, sortColumn } = this.state;
    const headerDefinition: Array<BaseListingHeaderDefinition> = [
      { title: "Supplier", style: { width: "20%" }, className: "pr-1", sortColumn: "supplier" },
      { title: "Amount", className: "pr-1", sortColumn: "amount" },
      { title: "Status", className: "pr-1", sortColumn: "status" },
      { title: "Batch No.", className: "pr-1", sortColumn: "batch" },
      { title: "Stocked", className: "pr-1", sortColumn: "stocked" },
      { title: "CoA", className: "pr-1" },
      { title: "Actions", className: "text-right" },
    ];
    const filteredStock = sortStock(this.getFilteredStock(), sortColumn);
    const paginatedStock = paginate(filteredStock, currentPage, pageSize);

    return (
      <>
        <EditBatchModal batch={batchToEdit} onClose={this.handleCloseModal} context={context} />
        <UpdateBatchStateModal batchToHandle={batchToHandle} onClose={this.handleCloseStateModal} />
        <div className="card bg-white">
          <div className="card-header border-0 mt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder fs-3 ">Warehouse</span>
            </h3>
          </div>
          <div className="card-body">
            <div className="card mb-10 bg-white border-none">
              <div className="row g-8 mb-5">
                <ArticlePageStockStats article={article} />
              </div>
              <div className="row g-8">
                <div className="col-md-3 mt-4">
                  <CustomSelect
                    options={B_STATE_OPTIONS}
                    matchFormControl={true}
                    placeholder={"All States"}
                    value={state}
                    isClearable={true}
                    onChange={(e: SelectOption | undefined) => this.setState({ state: e })}
                  />
                </div>
                <div className="col-md-3 mt-4">
                  <CustomSelect
                    options={C_PACKAGE_OPTIONS}
                    matchFormControl={true}
                    value={packageType}
                    placeholder={"All Packaging"}
                    isClearable={true}
                    onChange={(e: SelectOption | undefined) => this.setState({ packageType: e })}
                  />
                </div>
                <div className="col-md-6 mt-4">
                  <Search onSearch={this.handleSearch} value={search} />
                </div>
              </div>
            </div>
            <BaseListing
              documents={filteredStock}
              headerDefinition={headerDefinition}
              wrapperStyle={{ minHeight: "55vh" }}
              bodyContent={
                <>
                  {paginatedStock.length === 0 ? (
                    <tr>
                      <td className="text-center" colSpan={10}>
                        No batches found
                      </td>
                    </tr>
                  ) : (
                    paginatedStock.map((batch) => (
                      <BatchEntry
                        key={batch._id.toString()}
                        mergedDateColumn={true}
                        batch={
                          isShipmentBatch(batch) ? extendShipmentBatch(batch, context) : extendBatch(batch, context)
                        }
                        onEditBatch={() => this.handleEditBatch(batch as Batch)}
                        onBatchState={(type: "release" | "block") => this.handleBatchState(batch as Batch, type)}
                      />
                    ))
                  )}
                </>
              }
              currentPage={currentPage}
              pageSize={pageSize}
              baseSize={25}
              sortColumn={sortColumn}
              noHover={true}
              onPageChange={this.handlePageChange}
              onPageSizeChange={this.handlePageSizeChange}
              onSort={this.handleSort}
            />
            <CreateBatchModal article={article} context={context} />
          </div>
        </div>
      </>
    );
  }
}

interface ArticlePageStockStatsProps {
  article: InternalArticleExtended;
}

const ArticlePageStockStats: React.FunctionComponent<ArticlePageStockStatsProps> = ({ article }) => {
  const [statistics, setStatistics] = useState<Partial<CommodityStatistics> | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getArticleStatistics(article._id, ["stockData", "flowData"]).then((res) => {
      setStatistics(res);
      setLoading(false);
    });
  }, [article]);

  return (
    <div className="card border-0 bg-custom-medium-gray w-100 p-5 mt-0 mb-10">
      <div className="row">
        <div className="col-6 col-md-3">
          <div className="text-white fs-6 text-center mb-2">Total</div>
          <Tooltip
            show={!statistics?.stockData ? false : undefined}
            tooltipText={
              <div className="text-white text-left">
                Last Update: {statistics?.stockData ? formatDateTime(statistics.stockData.lastUpdate) : "-"}
              </div>
            }
          >
            <div className="badge badge-lg bg-color-10 d-block fs-6">
              {loading
                ? "Loading..."
                : (statistics?.stockData?.onStock || 0) + formatArticleUnit(article.unit, article)}
            </div>
          </Tooltip>
        </div>
        <div className="col-6 col-md-3">
          <div className="text-white fs-6 text-center mb-2">Free</div>
          <Tooltip
            show={!statistics?.stockData ? false : undefined}
            tooltipText={
              <div className="text-white text-left">
                Last Update: {statistics?.stockData ? formatDateTime(statistics.stockData.lastUpdate) : "-"}
              </div>
            }
          >
            <div className="badge badge-lg bg-color-10 d-block fs-6">
              {loading
                ? "Loading..."
                : (statistics?.stockData ? statistics.stockData.onStock - statistics.stockData.reservedStock : 0) +
                  formatArticleUnit(article.unit, article)}
            </div>
          </Tooltip>
        </div>
        <div className="col-6 col-md-3">
          <div className="text-white fs-6 text-center mb-2">Inflow</div>
          <Tooltip
            show={!statistics?.flowData ? false : undefined}
            tooltipText={
              <div className="text-white text-left">
                Last Update: {statistics?.flowData ? formatDateTime(statistics.flowData.lastUpdate) : "-"}
              </div>
            }
          >
            <div className="badge badge-lg bg-color-10 d-block fs-6">
              {loading ? "Loading..." : (statistics?.flowData?.inFlow || 0) + formatArticleUnit(article.unit, article)}
            </div>
          </Tooltip>
        </div>
        <div className="col-6 col-md-3">
          <div className="text-white fs-6 text-center mb-2">Outflow</div>
          <Tooltip
            show={!statistics?.flowData ? false : undefined}
            tooltipText={
              <div className="text-white text-left">
                Last Update: {statistics?.flowData ? formatDateTime(statistics.flowData.lastUpdate) : "-"}
              </div>
            }
          >
            <div className="badge badge-lg bg-color-10 d-block fs-6">
              {loading ? "Loading..." : (statistics?.flowData?.outFlow || 0) + formatArticleUnit(article.unit, article)}
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default CommodityPageStock;
