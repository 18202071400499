import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Cookies, withCookies } from "react-cookie";
import auth, { DEFAULT_SESSION_KEY_NAME } from "../../services/authService";

interface LogoutProps extends RouteComponentProps {
  cookies: Cookies;
}

class Logout extends Component<LogoutProps> {
  async componentDidMount() {
    await auth.removeApiKey(this.props.cookies, DEFAULT_SESSION_KEY_NAME);
    await auth.logout();
    // clear all keys in local storage
    localStorage.clear();
    window.location.href = "/login?logout=true";
  }

  render() {
    return null;
  }
}

export default withCookies(Logout);
