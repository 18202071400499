import _ from "lodash";
import React, { PureComponent, useMemo } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { DataContextInternalType } from "../../../context/dataContext";
import { paginate, PaginationState } from "../../common/Pagination";
import { SelectOption } from "../../common/CustomSelect";
import { SORTORDEROPTIONS } from "../../../utils/filterUtils";
import { doFuseSearch, getComponentState, getDocFromCollection } from "../../../utils/baseUtils";
import { F_SORTOPTIONS } from "../../../utils/forwarderUtils";
import BaseListing from "../../common/BaseListing";
import ForwarderListingFilter from "./ForwarderListingFilter";
import { Forwarder } from "../../../model/forwarder.types";
import ContactPersonWidget from "../../common/ContactPersonWidget";
import CreateForwarderModal from "./modals/CreateForwarderModal";
import CountryWidget from "../../common/CountryWidget";

interface ForwarderListingProps {
  context: DataContextInternalType;
}

interface ForwarderListingState extends PaginationState {
  search: string;
  sortBy: SelectOption;
  sortOrder: SelectOption;
}

const COMPONENT_NAME = "ForwarderListing";

class ForwarderListing extends PureComponent<ForwarderListingProps, ForwarderListingState> {
  constructor(props: ForwarderListingProps) {
    super(props);
    this.state = {
      pageSize: 25,
      currentPage: 1,
      search: "",
      sortBy: F_SORTOPTIONS[0],
      sortOrder: SORTORDEROPTIONS[0],
    };
  }

  componentDidMount() {
    const state = getComponentState(this.props.context, COMPONENT_NAME);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(COMPONENT_NAME, this.state);
  }

  handlePageChange = (page: number) => this.setState({ currentPage: page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    // Special handling for sortBy when searching
    if (!val.trim())
      this.setState({
        search: "",
        currentPage: 1,
        sortBy: F_SORTOPTIONS[0],
      });
    else
      this.setState({
        search: val,
        currentPage: 1,
        sortBy: { value: "", label: "Best Match" },
        sortOrder: SORTORDEROPTIONS[0],
      });
  };

  handleChangeSelect = (name: string, entry: SelectOption | undefined) => {
    //@ts-ignore
    if (["sortBy", "sortOrder"].includes(name)) this.setState({ [name]: entry });
    //@ts-ignore
    this.setState({ [name]: entry, currentPage: 1 });
  };

  /**
   * Filters forwarders based on search and sorting selection
   * @returns {Array<Forwarder>} filtered forwarders
   */
  getFilteredForwarders = (): Array<Forwarder> => {
    const { context } = this.props;
    const { forwarder } = context;
    const { search, sortBy, sortOrder } = this.state;
    let filteredForwarders = _.cloneDeep(forwarder);
    if (search.trim()) filteredForwarders = doFuseSearch(filteredForwarders, search, ["name"]);
    if (!sortBy.value && search.trim()) {
      if (sortOrder.value === "desc") return filteredForwarders.reverse();
      return filteredForwarders;
    }
    return _.orderBy(filteredForwarders, sortBy.value, sortOrder.value as "asc" | "desc");
  };

  render() {
    const { context } = this.props;
    const { currentPage, pageSize, search, sortBy, sortOrder } = this.state;
    const filteredForwarders = this.getFilteredForwarders();
    const headerDefinition = [{ title: "Forwarder" }, { title: "Delivery Countries" }];
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="card bg-white h-100">
              <div className="card-body">
                <h3 className="card-title align-items-start flex-column mb-15">
                  <span className="card-label fw-bolder mb-3 fs-3rem">Forwarders</span>
                  <CreateForwarderModal />
                </h3>
                <ForwarderListingFilter
                  search={search}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                  onSearch={this.handleSearch}
                  onSortByChange={(e) => this.handleChangeSelect("sortBy", e)}
                  onSortOrderChange={(e) => this.handleChangeSelect("sortOrder", e)}
                />
                <BaseListing
                  headerDefinition={headerDefinition}
                  documents={filteredForwarders}
                  bodyContent={
                    <>
                      {filteredForwarders.length > 0 ? (
                        paginate(filteredForwarders, currentPage, pageSize).map((f) => (
                          <ForwarderListingRowRouter key={f._id.toString()} forwarder={f} context={context} />
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7} className="text-center">
                            <span>No Forwarders found</span>
                          </td>
                        </tr>
                      )}
                    </>
                  }
                  currentPage={currentPage}
                  pageSize={pageSize}
                  baseSize={25}
                  onPageChange={this.handlePageChange}
                  onPageSizeChange={this.handlePageSizeChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

interface ForwarderListingRowProps extends RouteComponentProps {
  forwarder: Forwarder;
  context: DataContextInternalType;
}

const ForwarderListingRow: React.FunctionComponent<ForwarderListingRowProps> = ({ forwarder, context, history }) => {
  const forwardForwarder = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    history.push(`/forwarder/${forwarder._id.toString()}`);
  };

  const primaryPerson = useMemo(
    () => getDocFromCollection(context.userData, forwarder.primaryPerson)!,
    [forwarder.primaryPerson, context.userData]
  );

  return (
    <tr className="cursor-pointer-row" onClick={forwardForwarder}>
      <td>
        <Link
          onClick={(e) => e.stopPropagation()}
          className="text-white fs-5 mb-1 custom-link"
          to={`/forwarder/${forwarder._id.toString()}`}
        >
          {forwarder.disabled && <span className="text-danger mr-2">[DISABLED]</span>}
          {forwarder.name}
        </Link>
        <ContactPersonWidget person={primaryPerson} spanClasses="text-muted fw-bold d-block" />
      </td>
      <td className="align-middle w-50 text-center">
        <div className="d-flex flex-wrap">
          {forwarder.countries.length > 0
            ? forwarder.countries.map((c) => {
                return <CountryWidget wrapperClasses={"px-4"} key={c} countryCode={c} />;
              })
            : "-"}
        </div>
      </td>
    </tr>
  );
};

const ForwarderListingRowRouter = withRouter(ForwarderListingRow);

export default ForwarderListing;
