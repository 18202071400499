import { formatCurrency, getDocFromCollection } from "../baseUtils";
import { CustomerOrder, CustomerOrderExtended } from "../../model/customerOrder.types";
import { CustomerCustomerOrder, CustomerCustomerOrderExtended } from "../../model/customer/customerCustomerOrder.types";
import { convertCurrency, CUSTOMER_BASE_CURRENCY } from "../currencyUtils";
import { getOrderNumber } from "../orderUtils";
import { formatAddress } from "../addressUtils";
import { ORGANICCODE } from "./templateUtils";
import { formatDateFromType } from "../customerOrderUtils";
import { DataContextAnonymousType, DataContextCustomerType, DataContextInternalType } from "../../context/dataContext";
import { Company } from "../../model/company.types";

/**
 * Creates the request confirmation HTML after customer order is created
 * @param order Order that is requested
 * @param notes Notes below positions
 * @param context Data context - needed for currencies and resolving data
 * @returns { string } a html string that can be used to create the PDF
 */
export function createRequestConfirmationHTML(
  order: CustomerOrder | CustomerOrderExtended | CustomerCustomerOrder | CustomerCustomerOrderExtended,
  notes: string,
  context: DataContextInternalType | DataContextCustomerType | DataContextAnonymousType
): string {
  const { currencies, company, supplier } = context;
  const customer =
    typeof order.company === "string" ? (getDocFromCollection(company, order.company) as Company) : order.company;
  const supplierDoc = order.supplier
    ? typeof order.supplier === "string"
      ? getDocFromCollection(supplier, order.supplier)
      : order.supplier
    : undefined;
  const address = customer.address[0];
  return `<head>
    <!-- Header -->
        <link href='https://fonts.googleapis.com/css?family=Signika&display=swap' rel='stylesheet'>
        <style>tr:nth-child(even) {background-color: white;}</style><meta http-equiv='content-type' content='text/html; charset=utf-8'>
    </head>
    <body style='font-family: Signika, sans-serif'>
        <img src='https://rawbids.com/images/logo-dark.png' alt="Logo" width='180' style='float:right'/>
        <br/><br/><br/><br/><span style='font-size: 30px; font-weight: bold'>Request Confirmation ${getOrderNumber(
          order,
          true
        )}
        </span><br/><span style='font-size: 20px; font-weight: bold'>${order.commodity.title.en}</span><br/><br/><br/>
    <table cellpadding='5' cellspacing='0' style='width: 100%'>
        <tbody>
            <tr style='width: 100%; vertical-align: top'>
                <td style='vertical-align: top'><span style='font-size:16px;  width: 33%; vertical-align: top'>Recipient:<br/><b>${
                  customer.name
                }</b><br/>${formatAddress(address).replace(/\n/g, "<br/>")}<br/>${
    customer.vat ? "VAT-ID: " + customer.vat : ""
  }</span>
                </td>
                <td style='vertical-align: top'><span style='font-size:16px; width: 33%; vertical-align: top'>Issued by:<br/><b>RAWBIDS GmbH</b><br/>Willy-Brandt-Str. 23, 20457 Hamburg<br/>Germany<br/>VAT-ID: DE354508196</span></td>
                <td style='text-align: right; vertical-align: top'><span style='font-size:16px; width: 33%; vertical-align: top'><b>Order number: ${
                  order.orderNo
                }</b>
                ${order.commodity.organic ? `<br/>${ORGANICCODE}` : ""}
                <br/>Order date: ${new Date().toLocaleDateString()}
                ${order.customerReference && `<br/><span>Reference number: ${order.customerReference}</span>`}
                <br/>ETA: ${formatDateFromType(
                  order.targetDate,
                  order.targetDateType
                )}<br/><br/>Contact: info@rawbids.com</span>
                </td>
            </tr>
        </tbody>
    </table><br/>
    <table style='font-size:16px; width: 100%; display: block; margin-top:20px; margin-bottom:20px'>
        <tbody>
            <tr>
                <td>Dear Sir or Madam, <br/><br/>We hereby confirm to you, we received your order request ${getOrderNumber(
                  order
                )} as follows:</td>
            </tr>
        </tbody>
    </table>
    <table cellpadding='5' cellspacing='0' style='font-size:15px; width:100%; background-color:#fafafa; margin-top:20px' >
        <thead>
            <tr style='background-color:#333333; color: #ffffff'>
                <th><b>Pos.</b></th>
                <th style='min-width: 250px'><b style='white-space:nowrap'>Description</b></th>
                <th><b>Amount</b></th>
                <th><b style='white-space:nowrap;'>Unit</b></th>
                <th><b style='white-space:nowrap;'>Per unit</b></th>
                <th><b style='white-space:nowrap;'>Discount</b></th>
                <th><b style='white-space:nowrap; float: right'>Total</b></th>
            </tr>
        </thead>
        <tbody>
        <!--  Commodity-->
            <tr>
                <td>&nbsp;&nbsp;${1}</td>
                <td style='width: 45%'><b>${order.commodity.title.en}</b><br/>${order.commodity.subtitle.en}<br/>${
    supplierDoc ? supplierDoc.name : ""
  }</td>
                <td>&nbsp;&nbsp;${order.amount}</td>
                <td>&nbsp;&nbsp;${order.unit}</td>
                <td>&nbsp;&nbsp;${formatCurrency(order.priceCommodities / order.amount, order.currency)}</td>
                <td>&nbsp;&nbsp;${order.discount ? order.discount + "%" : ""}</td>
                <td style='float: right; text-align: right'>${formatCurrency(
                  order.priceCommodities * (1 - (order.discount || 0) / 100),
                  order.currency
                )}</td>
            </tr>
${order.services
  .map((s, i) => {
    return `<tr><td>&nbsp;&nbsp;${i + 2}</td>
                <td style='width: 45%'><b>${s.service.title.en}</b><br/>${s.service.description.en}</td>
                <td>&nbsp;&nbsp;1</td>
                <td>&nbsp;&nbsp;Service</td>
                <td>&nbsp;&nbsp;${formatCurrency(
                  convertCurrency(s.service.price, CUSTOMER_BASE_CURRENCY, order.currency, currencies),
                  order.currency
                )}</td>
                <td></td>
                <td style='float: right; text-align: right'>${formatCurrency(
                  convertCurrency(s.service.price, CUSTOMER_BASE_CURRENCY, order.currency, currencies),
                  order.currency
                )}</td>
             </tr>`;
  })
  .join("")}
    </tbody>
</table><br/>
    <table style='width: 50%; float: right; display: block'>
      <tbody>
        <tr>
            <td>Total</td>
            <td style='float: right; text-align: right'><span style='float: right; text-align: right'><b style='font-size: 20px'>${formatCurrency(
              order.totalPrice * (1 - (order.discount || 0) / 100),
              order.currency
            )}</b></span></td>
        </tr>
      </tbody>
    </table><br/><br/><br/><br/><br/><br/>    
    <table style='font-size:16px; width: 100%; display: block'>
        <tbody>
            <tr>
                <td><span style='font-size:16px; font-weight: bold;'> Requested target date: </span>${formatDateFromType(
                  order.targetDate,
                  order.targetDateType
                )}</td>
            </tr>
        </tbody>
    </table><br/><br/><br/>
    <table style='font-size:16px; width: 100%; display: block'>
        <tbody>
            <tr>
                <td><span style='font-size:22px; font-weight: bold;'>Notes:</span><br/><br/>${notes}</td>
            </tr>
        </tbody>
    </table><br/><br/><br/><br/>
  </body>`;
}
