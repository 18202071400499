import { History, Location } from "history";

/**
 * Get and remove given queryString from the search query
 * @param location the location property
 * @param history the history property
 * @param queryString the query string to search for
 * @returns {string | null} the found query content or null
 */
export function getAndRemoveQuery(location: Location, history: History, queryString: string): string | null {
  const query = new URLSearchParams(location.search);
  if (!query.has(queryString)) return null;
  const res = query.get(queryString);
  query.delete(queryString);
  history.replace({ search: query.toString() });
  return res;
}
