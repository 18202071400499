import _ from "lodash";
import { Link } from "react-router-dom";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import { CALC_AIRFREIGHT, CALC_SEAFREIGHT } from "../../../../model/supplierOrder.types";
import { DataContextInternalType } from "../../../../context/dataContext";
import { PropertyType } from "../../../../utils/propertyUtils";
import { Property } from "../../../../model/property.types";
import { CONFIG } from "../../../../utils/configurationUtils";
import CategoryGraduationConfiguration from "./CategoryGraduationConfiguration";
import { GraduationConfiguration } from "../../../../model/configuration/graduationConfiguration.types";
import { GraduationReference } from "./GraduationCard";
import { Action, CONFIGURATION, transaction } from "../../../../services/dbService";
import userService from "../../../../services/userService";
import AddCategoryForGraduationModal from "./AddCategoryForGraduationModal";

type tabType = typeof CALC_AIRFREIGHT | typeof CALC_SEAFREIGHT;

export interface ConfigurationRow {
  id: string;
  relatedProperty: Property;
  graduation: Array<number>;
}

interface CategoryGraduation {
  type: typeof CALC_SEAFREIGHT | typeof CALC_AIRFREIGHT;
  property: string;
  graduation: Array<number>;
}

interface GraduationSettingsProps {
  context: DataContextInternalType;
}

interface GraduationSettingsState {
  tab: tabType;
  saving: boolean;
  show: boolean;
  seaFreightRows: Array<ConfigurationRow>;
  airFreightRows: Array<ConfigurationRow>;
  seaFreightGraduation: Array<number>;
  airFreightGraduation: Array<number>;
}
const GraduationSettings: React.FunctionComponent<GraduationSettingsProps> = ({ context }) => {
  const { property, configuration } = context;

  // find the related config
  const graduationConfiguration = useMemo(() => {
    return configuration.find((config) => config.key === CONFIG.GRADUATION) as GraduationConfiguration;
  }, [configuration]);

  // extract a category set collection from the property collection
  const categories = property
    .filter((p) => p.type === PropertyType.CATEGORY)
    .sort((a, b) => a.name.en.localeCompare(b.name.en));

  const [{ tab, saving, show, seaFreightRows, airFreightRows, seaFreightGraduation, airFreightGraduation }, setState] =
    useState<GraduationSettingsState>({
      tab: CALC_SEAFREIGHT,
      show: false,
      saving: false,
      seaFreightRows: [],
      airFreightRows: [],
      seaFreightGraduation: [],
      airFreightGraduation: [],
    });

  useEffect(() => {
    const seaFreightRows: Array<ConfigurationRow> = resolveGraduationRows(CALC_SEAFREIGHT);
    const airFreightRows: Array<ConfigurationRow> = resolveGraduationRows(CALC_AIRFREIGHT);
    const seaFreightGraduation = _.cloneDeep(
      graduationConfiguration.values.defaultGraduation.sea.sort((a, b) => a - b)
    );
    const airFreightGraduation = _.cloneDeep(
      graduationConfiguration.values.defaultGraduation.air.sort((a, b) => a - b)
    );

    setState((prevState) => {
      return {
        ...prevState,
        seaFreightRows: seaFreightRows,
        airFreightRows: airFreightRows,
        seaFreightGraduation: seaFreightGraduation,
        airFreightGraduation: airFreightGraduation,
      };
    });
  }, [graduationConfiguration]);

  const handleChangeTab = useCallback((tab: typeof CALC_AIRFREIGHT | typeof CALC_SEAFREIGHT) => {
    setState((prevState) => {
      return {
        ...prevState,
        tab: tab,
      };
    });
  }, []);

  const handleChangeGraduation = useCallback(
    (e: ChangeEvent<HTMLInputElement>, idx: number, reference?: GraduationReference) => {
      if (!e.target.value) return;
      if (!reference) return;
      const seaFreightGraduationCopy = _.cloneDeep(seaFreightGraduation);
      const airFreightGraduationCopy = _.cloneDeep(airFreightGraduation);
      const seaFreightRowsCopy = _.cloneDeep(seaFreightRows);
      const airFreightRowsCopy = _.cloneDeep(airFreightRows);

      switch (reference.type) {
        case "default":
          if (reference.tab === "seaFreight") {
            seaFreightGraduationCopy[idx] = Number(e.target.value);
          } else {
            airFreightGraduationCopy[idx] = Number(e.target.value);
          }
          break;
        case "property":
          if (tab === CALC_SEAFREIGHT) {
            const correspondingIndex = seaFreightRows.findIndex((row) => row.id === reference.id);
            const newRows = seaFreightRows[correspondingIndex].graduation;
            newRows[idx] = Number(e.target.value);
            seaFreightRowsCopy[correspondingIndex].graduation = newRows;
          } else {
            const correspondingIndex = airFreightRows.findIndex((row) => row.id === reference.id);
            const newRows = airFreightRows[correspondingIndex].graduation;
            newRows[idx] = Number(e.target.value);
            airFreightRowsCopy[correspondingIndex].graduation = newRows;
          }
          break;
        default:
          return;
      }

      setState((prevState) => {
        return {
          ...prevState,
          changed: true,
          seaFreightGraduation: seaFreightGraduationCopy,
          airFreightGraduation: airFreightGraduationCopy,
          seaFreightRows: seaFreightRowsCopy,
          airFreightRows: airFreightRowsCopy,
        };
      });
    },
    [seaFreightRows, airFreightRows, seaFreightGraduation, airFreightGraduation, tab]
  );

  const handleAddGraduation = useCallback(
    (reference?: GraduationReference) => {
      if (!reference) return;
      const seaFreightGraduationCopy = _.cloneDeep(seaFreightGraduation);
      const airFreightGraduationCopy = _.cloneDeep(airFreightGraduation);
      const seaFreightRowsCopy = _.cloneDeep(seaFreightRows);
      const airFreightRowsCopy = _.cloneDeep(airFreightRows);

      switch (reference.type) {
        case "default":
          if (reference.tab === "seaFreight") {
            seaFreightGraduationCopy.push(0);
          } else {
            airFreightGraduationCopy.push(0);
          }
          break;
        case "property":
          if (tab === CALC_SEAFREIGHT) {
            const correspondingIndex = seaFreightRows.findIndex((row) => row.id === reference.id);
            const newRows = seaFreightRows[correspondingIndex].graduation;
            newRows.push(0);
            seaFreightRowsCopy[correspondingIndex].graduation = newRows;
          } else {
            const correspondingIndex = airFreightRows.findIndex((row) => row.id === reference.id);
            const newRows = airFreightRows[correspondingIndex].graduation;
            newRows.push(0);
            airFreightRowsCopy[correspondingIndex].graduation = newRows;
          }
          break;
        default:
          return;
      }

      setState((prevState) => {
        return {
          ...prevState,
          changed: true,
          seaFreightGraduation: seaFreightGraduationCopy,
          airFreightGraduation: airFreightGraduationCopy,
          seaFreightRows: seaFreightRowsCopy,
          airFreightRows: airFreightRowsCopy,
        };
      });
    },
    [seaFreightRows, airFreightRows, seaFreightGraduation, airFreightGraduation, tab]
  );

  const handleDeleteGraduationEntry = useCallback(
    (idx: number, reference?: GraduationReference) => {
      if (!reference) return;
      const seaFreightGraduationCopy = _.cloneDeep(seaFreightGraduation);
      const airFreightGraduationCopy = _.cloneDeep(airFreightGraduation);
      const seaFreightRowsCopy = _.cloneDeep(seaFreightRows);
      const airFreightRowsCopy = _.cloneDeep(airFreightRows);

      switch (reference.type) {
        case "default":
          if (reference.tab === "seaFreight") {
            seaFreightGraduationCopy.splice(idx, 1);
          } else {
            airFreightGraduationCopy.splice(idx, 1);
          }
          break;
        case "property":
          if (tab === CALC_SEAFREIGHT) {
            const correspondingIndex = seaFreightRows.findIndex((row) => row.id === reference.id);
            const newRows = seaFreightRows[correspondingIndex].graduation;
            newRows.splice(idx, 1);
            seaFreightRowsCopy[correspondingIndex].graduation = newRows;
          } else {
            const correspondingIndex = airFreightRows.findIndex((row) => row.id === reference.id);
            const newRows = airFreightRows[correspondingIndex].graduation;
            newRows.splice(idx, 1);
            airFreightRowsCopy[correspondingIndex].graduation = newRows;
          }
          break;
        default:
          return;
      }

      setState((prevState) => {
        return {
          ...prevState,
          changed: true,
          seaFreightGraduation: seaFreightGraduationCopy,
          airFreightGraduation: airFreightGraduationCopy,
          seaFreightRows: seaFreightRowsCopy,
          airFreightRows: airFreightRowsCopy,
        };
      });
    },
    [seaFreightRows, airFreightRows, seaFreightGraduation, airFreightGraduation, tab]
  );

  const handleDeleteGraduation = useCallback(
    async (reference?: GraduationReference) => {
      if (!reference) return;

      setState((prevState) => {
        return {
          ...prevState,
          saving: true,
        };
      });

      try {
        // create working objects
        const seaFreightRowsCopy: Array<CategoryGraduation> = _.cloneDeep(seaFreightRows).map((row) => {
          return {
            type: CALC_SEAFREIGHT,
            property: row.id,
            graduation: row.graduation,
          };
        });
        const airFreightRowsCopy: Array<CategoryGraduation> = _.cloneDeep(airFreightRows).map((row) => {
          return {
            type: CALC_AIRFREIGHT,
            property: row.id,
            graduation: row.graduation,
          };
        });
        const categoryGraduation: Array<CategoryGraduation> = seaFreightRowsCopy.concat(airFreightRowsCopy);
        const categoryGraduationBefore: Array<CategoryGraduation> = _.cloneDeep(categoryGraduation);

        switch (reference.type) {
          case "property":
            if (tab === CALC_SEAFREIGHT) {
              const correspondingIndex = categoryGraduation.findIndex(
                (row) => row.property === reference.id && row.type === CALC_SEAFREIGHT
              );
              categoryGraduation.splice(correspondingIndex, 1);
            } else {
              const correspondingIndex = categoryGraduation.findIndex(
                (row) => row.property === reference.id && row.type === CALC_AIRFREIGHT
              );
              categoryGraduation.splice(correspondingIndex, 1);
            }
            break;
          default:
            return;
        }

        const actions: Array<Action> = [];
        const pre = { categoryGraduationBefore };
        const post = { categoryGraduation };
        const timelineEntry = {
          _id: new BSON.ObjectId(),
          date: new Date(),
          person: userService.getUserId(),
          pre,
          post,
        };

        actions.push({
          collection: CONFIGURATION,
          filter: { key: CONFIG.GRADUATION },
          update: {
            lastUpdate: new Date(),
            "values.categoryGraduations": categoryGraduation,
          },
          push: {
            timeline: timelineEntry,
          },
        });

        const result = await transaction(actions);
        if (result) {
          toast.success("Changes have been successfully applied.");
        } else {
          toast.error("Changes could not be applied. Please try again later.");
        }
      } catch (e) {
        toast.error("An error occurred: " + e);
      } finally {
        setState((prevState) => {
          return {
            ...prevState,
            saving: false,
          };
        });
      }
    },
    [seaFreightRows, airFreightRows, tab]
  );

  const handleSaveChanges = async (reference: GraduationReference) => {
    setState((prevState) => {
      return {
        ...prevState,
        saving: true,
      };
    });

    // create working objects
    const seaFreightRowsCopy: Array<CategoryGraduation> = _.cloneDeep(seaFreightRows).map((row) => {
      return {
        type: CALC_SEAFREIGHT,
        property: row.id,
        graduation: row.graduation,
      };
    });
    const airFreightRowsCopy: Array<CategoryGraduation> = _.cloneDeep(airFreightRows).map((row) => {
      return {
        type: CALC_AIRFREIGHT,
        property: row.id,
        graduation: row.graduation,
      };
    });
    let categoryGraduation: Array<CategoryGraduation> = seaFreightRowsCopy.concat(airFreightRowsCopy);
    const categoryGraduationBefore: Array<CategoryGraduation> = _.cloneDeep(categoryGraduation);

    try {
      let pre = {};
      let post = {};
      const actions: Array<Action> = [];
      const timelineEntry = {
        _id: new BSON.ObjectId(),
        date: new Date(),
        person: userService.getUserId(),
        pre,
        post,
      };

      switch (reference.type) {
        case "default":
          if (tab === CALC_SEAFREIGHT) {
            pre = { defaultSea: graduationConfiguration.values.defaultGraduation.sea };
            post = { defaultSea: seaFreightGraduation };
            timelineEntry.pre = pre;
            timelineEntry.post = post;
            actions.push({
              collection: CONFIGURATION,
              filter: { key: CONFIG.GRADUATION },
              update: { lastUpdate: new Date(), "values.defaultGraduation.sea": seaFreightGraduation },
              push: {
                timeline: timelineEntry,
              },
            });
          } else {
            pre = { defaultAir: graduationConfiguration.values.defaultGraduation.air };
            post = { defaultAir: airFreightGraduation };
            timelineEntry.pre = pre;
            timelineEntry.post = post;
            actions.push({
              collection: CONFIGURATION,
              filter: { key: CONFIG.GRADUATION },
              update: { lastUpdate: new Date(), "values.defaultGraduation.air": airFreightGraduation },
              push: {
                timeline: timelineEntry,
              },
            });
          }
          break;
        case "property":
          categoryGraduation = categoryGraduation.filter((grad) => grad.graduation.length !== 0);
          pre = { categoryGraduationBefore };
          post = { categoryGraduation };
          actions.push({
            collection: CONFIGURATION,
            filter: { key: CONFIG.GRADUATION },
            update: {
              lastUpdate: new Date(),
              "values.categoryGraduations": categoryGraduation,
            },
            push: {
              timeline: timelineEntry,
            },
          });
          break;
        default:
          return;
      }

      const result = await transaction(actions);
      if (result) {
        toast.success("Changes have been successfully applied.");
      } else {
        toast.error("Changes could not be applied. Please try again later.");
      }
    } catch (e) {
      toast.error("An error occurred: " + e);
    } finally {
      setState((prevState) => {
        return {
          ...prevState,
          saving: false,
        };
      });
    }
  };

  const handleShow = useCallback(() => {
    setState((prevState) => {
      return {
        ...prevState,
        show: true,
      };
    });
  }, []);

  const handleHide = useCallback(() => {
    setState((prevState) => {
      return {
        ...prevState,
        show: false,
      };
    });
  }, []);

  const handleAddCategories = useCallback(
    async (selectedCategories: Array<string>, graduationSea: Array<number>, graduationAir: Array<number>) => {
      try {
        const seaFreightRowsCopy: Array<CategoryGraduation> = _.cloneDeep(seaFreightRows).map((row) => {
          return {
            type: CALC_SEAFREIGHT,
            property: row.id,
            graduation: row.graduation,
          };
        });
        const airFreightRowsCopy: Array<CategoryGraduation> = _.cloneDeep(airFreightRows).map((row) => {
          return {
            type: CALC_AIRFREIGHT,
            property: row.id,
            graduation: row.graduation,
          };
        });
        const categoryGraduation: Array<CategoryGraduation> = seaFreightRowsCopy.concat(airFreightRowsCopy);
        const categoryGraduationBefore: Array<CategoryGraduation> = _.cloneDeep(categoryGraduation);
        const actions: Array<Action> = [];

        selectedCategories.forEach((categoryId) => {
          const relatedCategory = categories.find((cg) => cg._id.toString() === categoryId);
          if (relatedCategory) {
            // avoiding duplications
            if (!seaFreightRowsCopy.find((row) => row.property === categoryId) && graduationSea.length !== 0) {
              const categoryGraduationToAdd: CategoryGraduation = {
                type: CALC_SEAFREIGHT,
                property: relatedCategory._id.toString(),
                graduation: graduationSea,
              };
              categoryGraduation.push(categoryGraduationToAdd);
            }

            // avoiding duplications
            if (!airFreightRowsCopy.find((row) => row.property === categoryId) && graduationAir.length !== 0) {
              const categoryGraduationToAdd: CategoryGraduation = {
                type: CALC_AIRFREIGHT,
                property: relatedCategory._id.toString(),
                graduation: graduationAir,
              };
              categoryGraduation.push(categoryGraduationToAdd);
            }
          }
        });
        const pre = { categoryGraduationBefore };
        const post = { categoryGraduation };
        const timelineEntry = {
          _id: new BSON.ObjectId(),
          date: new Date(),
          person: userService.getUserId(),
          pre,
          post,
        };

        actions.push({
          collection: CONFIGURATION,
          filter: { key: CONFIG.GRADUATION },
          update: {
            lastUpdate: new Date(),
            "values.categoryGraduations": categoryGraduation,
          },
          push: {
            timeline: timelineEntry,
          },
        });
        const result = await transaction(actions);
        if (result) {
          toast.success("Graduations successfully added!");
        } else {
          toast.error("Graduations could not be added. Please try again later.");
        }
      } catch (e) {
        toast.error("An error occurred: " + e);
      } finally {
        setState((prevState) => {
          return {
            ...prevState,
            show: false,
          };
        });
      }
    },
    [seaFreightRows, airFreightRows, categories]
  );

  /**
   * Helper Function. Resolve existing configurations. Generating objects to express configuration rows by categories.
   * @param type the corresponding transport type to resolve
   * @returns {Array<ConfigurationRow>} Information set of the corresponding rows
   */
  const resolveGraduationRows = useCallback(
    (type: typeof CALC_SEAFREIGHT | typeof CALC_AIRFREIGHT) => {
      const result: Array<ConfigurationRow> = [];
      categories.forEach((category) => {
        // looking for existing configurations
        const relatedConfiguration = graduationConfiguration.values.categoryGraduations.find(
          (cg) => cg.property === category._id.toString() && cg.type === type
        );

        if (relatedConfiguration && relatedConfiguration.graduation.length !== 0) {
          // case: pre-existing configuration -> load values
          result.push({
            id: category._id.toString(),
            relatedProperty: category,
            graduation: _.cloneDeep(relatedConfiguration.graduation.sort((a, b) => a - b)),
          });
        }
      });
      return result;
    },
    [categories, graduationConfiguration]
  );

  const seaFreightRowIds = useMemo(() => {
    return seaFreightRows.map((row) => row.id);
  }, [seaFreightRows]);
  const airFreightRowIds = useMemo(() => {
    return airFreightRows.map((row) => row.id);
  }, [airFreightRows]);
  const filteredCategories = useMemo(() => {
    return categories.filter(
      (category) =>
        !seaFreightRowIds.includes(category._id.toString()) || !airFreightRowIds.includes(category._id.toString())
    );
  }, [seaFreightRows, airFreightRows]);

  return (
    <div className="content d-flex flex-column flex-column-fluid">
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl">
          <div className="card bg-white min-h-100">
            <div className="card-body">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder fs-3rem">Graduation Settings</span>
                <div className="float-right">
                  <Link to="/settings">
                    <button className="btn btn-text text-white">Back</button>
                  </Link>
                  <AddCategoryForGraduationModal
                    show={show}
                    onClose={handleHide}
                    onShow={handleShow}
                    onAddCategories={handleAddCategories}
                    categories={filteredCategories}
                  />
                </div>
              </h3>
              <div className="d-flex flex-column">
                <div className="btn-group btn-group-md rounded-0 mt-10 mb-5">
                  <button
                    className={
                      "btn btn-outline btn-outline-light btn-sm w-50 rounded-0 " + (tab === CALC_SEAFREIGHT && "active")
                    }
                    onClick={() => handleChangeTab(CALC_SEAFREIGHT)}
                  >
                    Sea Freight
                  </button>
                  <button
                    className={
                      "btn btn-outline btn-outline-light btn-sm w-50 rounded-0 " + (tab === CALC_AIRFREIGHT && "active")
                    }
                    onClick={() => handleChangeTab(CALC_AIRFREIGHT)}
                  >
                    Air Freight
                  </button>
                </div>
              </div>
              {tab === CALC_SEAFREIGHT && (
                <CategoryGraduationConfiguration
                  rows={seaFreightRows}
                  defaultGraduation={seaFreightGraduation}
                  tab={CALC_SEAFREIGHT}
                  onChangeGraduation={handleChangeGraduation}
                  onAddGraduation={handleAddGraduation}
                  onDeleteGraduationEntry={handleDeleteGraduationEntry}
                  onDeleteGraduation={handleDeleteGraduation}
                  onSaveChanges={handleSaveChanges}
                  saving={saving}
                />
              )}
              {tab === CALC_AIRFREIGHT && (
                <CategoryGraduationConfiguration
                  rows={airFreightRows}
                  defaultGraduation={airFreightGraduation}
                  tab={CALC_AIRFREIGHT}
                  onChangeGraduation={handleChangeGraduation}
                  onAddGraduation={handleAddGraduation}
                  onDeleteGraduationEntry={handleDeleteGraduationEntry}
                  onDeleteGraduation={handleDeleteGraduation}
                  onSaveChanges={handleSaveChanges}
                  saving={saving}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraduationSettings;
