import _ from "lodash";
import React, { PureComponent } from "react";
import {
  CalculationBaseValues,
  getB2BFollowUpCost,
  isLCLSeaFreightValues,
  LCL_CHARGES,
  recalculatePaletteDataOnChange,
  LCLSeaFreightCalculationValues,
} from "../../../../utils/priceCalculationUtils";
import { Duty } from "../../../../model/commodity.types";
import { Input } from "../../../common/Input";
import { PaletteDataView } from "./PaletteDataView";
import { EURO, USD } from "../../../../utils/currencyUtils";
import SeaFreightCustomsView from "./SeaFreightCustomsView";
import WarehouseCostView from "./WarehouseCostView";

interface SeaFreightCalculationViewProps {
  totalAmount: number;
  calculationValues: LCLSeaFreightCalculationValues;
}

interface SeaFreightCalculationViewState {
  useTotals: boolean;
  calculationValues: LCLSeaFreightCalculationValues;
}

class LCLSeaFreightCalculationView extends PureComponent<
  SeaFreightCalculationViewProps,
  SeaFreightCalculationViewState
> {
  constructor(props: SeaFreightCalculationViewProps) {
    super(props);
    this.state = {
      useTotals: false,
      calculationValues: _.cloneDeep(props.calculationValues),
    };
  }

  componentDidUpdate(prevProps: SeaFreightCalculationViewProps) {
    if (!_.isEqual(prevProps.calculationValues, this.props.calculationValues)) {
      this.setState({ calculationValues: _.cloneDeep(this.props.calculationValues) });
    }
  }

  handleToggleTotals = () => this.setState({ useTotals: !this.state.useTotals });

  handleChangeDuty = (e: React.ChangeEvent<HTMLInputElement>) => {
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    const name = e.target.name as keyof Duty;
    calculationValues.duty[name] = +e.target.value;
    this.setState({ calculationValues });
  };

  handleChangeLCLChargeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    if (!isLCLSeaFreightValues(calculationValues)) return;
    const name = e.target.name as keyof typeof LCL_CHARGES;
    calculationValues.lclCharges[name] = +e.target.value;
    this.setState({ calculationValues });
  };

  handleChangePaletteData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    recalculatePaletteDataOnChange(calculationValues, this.props.totalAmount, e);
    this.setState({ calculationValues });
  };

  handleChangeNumValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    const name = e.target.name as keyof LCLSeaFreightCalculationValues;
    const value = +e.target.value;
    // @ts-ignore
    calculationValues[name] = name === "customsFeeAgency" ? value / 100 : value;
    this.setState({ calculationValues });
  };

  handleChangeBaseValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { calculationValues: originalCalculationValues } = this.props;
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    const name = e.target.name as keyof CalculationBaseValues;
    const value = +e.target.value;
    calculationValues.baseValues[name] = value;
    // Recalculate b2b cost if they weren't changed by the user
    if (name === "palettes" && calculationValues.b2bFollowUpCost === originalCalculationValues.b2bFollowUpCost)
      calculationValues.b2bFollowUpCost = getB2BFollowUpCost(value);
    this.setState({ calculationValues });
  };

  handleChangeWarehouseGeneralCost = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    calculationValues.warehouseCost.generalCost[index].costPerUnit = +e.target.value;
    this.setState({ calculationValues });
  };

  /**
   * Used for parent component to get state values
   */
  getCalculationValues = () => {
    return this.state.calculationValues;
  };

  render() {
    const { totalAmount } = this.props;
    const { calculationValues, useTotals } = this.state;
    const {
      lclCharges,
      dockage,
      customsFeeAgency,
      customsFlatRate,
      seaFreightCost,
      b2bFollowUpCost,
      paletteData,
      duty,
      baseValues,
      warehouseCost,
    } = calculationValues;

    return (
      <div>
        <h3 className="mb-3 mt-7">Transport</h3>
        <div className="row">
          <div className="col-xl-4">
            <label className="fs-5 fw-bold mb-2">Sea Freight Cost per Ton/CBM</label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type={"number"}
                min={0}
                name={"seaFreightCost"}
                value={seaFreightCost}
                onChange={this.handleChangeNumValue}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  {USD}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <label className="fs-5 fw-bold mb-2">B2B Follow-up Cost (based on palettes)</label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type={"number"}
                min={0}
                name={"b2bFollowUpCost"}
                value={b2bFollowUpCost}
                onChange={this.handleChangeNumValue}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  {EURO}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <label className="fs-5 fw-bold mb-2">Dockage per Ton</label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type={"number"}
                min={0}
                name={"dockage"}
                value={dockage}
                onChange={this.handleChangeNumValue}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  {EURO}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 mt-2">
            <label className="fs-5 fw-bold mb-2">LCL Charge per m³</label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type={"number"}
                min={0}
                name={"cbm"}
                value={lclCharges.cbm}
                onChange={this.handleChangeLCLChargeValue}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  {EURO}/m³
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 mt-2">
            <label className="fs-5 fw-bold mb-2">LCL Charge per ton </label>
            <div className="input-group">
              <Input
                className="form-control custom-form-control"
                type={"number"}
                min={0}
                name={"weight"}
                value={lclCharges.weight}
                onChange={this.handleChangeLCLChargeValue}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  {EURO}/t
                </div>
              </div>
            </div>
          </div>
        </div>
        <SeaFreightCustomsView
          duty={duty}
          customsFlatRate={customsFlatRate}
          customsFeeAgency={customsFeeAgency}
          onChangeDuty={this.handleChangeDuty}
          onChangeNumValue={this.handleChangeNumValue}
        />
        <WarehouseCostView
          warehouseCost={warehouseCost}
          onChangeWarehouseCost={this.handleChangeWarehouseGeneralCost}
        />
        <PaletteDataView
          totalAmount={totalAmount}
          paletteData={paletteData}
          baseValues={baseValues}
          useTotals={useTotals}
          onChangePaletteData={this.handleChangePaletteData}
          onChangeBaseValue={this.handleChangeBaseValue}
          onToggleTotals={this.handleToggleTotals}
        />
      </div>
    );
  }
}

export default LCLSeaFreightCalculationView;
