import React from "react";
import { CostObject } from "../../../../../model/configuration/calculationConfiguration.types";
import { Input } from "../../../../common/Input";
import Tooltip from "../../../../common/Tooltip";

interface CostObjectListProps {
  cost: Array<CostObject>;
  objectPath: string;
  onChangeValues: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CostObjectList: React.FunctionComponent<CostObjectListProps> = ({ cost, onChangeValues, objectPath }) => {
  let size = 3;
  if (cost.some((c) => c.freeDays !== undefined)) size++;

  return (
    <div className="row">
      <div className={"col-auto "} style={{ width: `${100 / size}%` }}>
        <label className="fs-5 fw-bold mb-2">Description</label>
      </div>
      <div className={"col-auto "} style={{ width: `${100 / size}%` }}>
        <label className="fs-5 fw-bold mb-2">Cost Per Unit</label>
      </div>
      <div className={"col-auto "} style={{ width: `${100 / size}%` }}>
        <label className="fs-5 fw-bold mb-2">Minimum Cost</label>
      </div>
      {size === 4 && (
        <div className={"col-auto "} style={{ width: `${100 / size}%` }}>
          <label className="fs-5 fw-bold mb-2">
            Free Days
            <Tooltip
              tooltipText={
                <span className="text-white">Amount of days free of charge for cost related to cost per day</span>
              }
            >
              <span className="ml-2">
                <i className="fa fa-info-circle text-white" />
              </span>
            </Tooltip>
          </label>
        </div>
      )}
      {cost.map((costObject, index) => (
        <CostObjectConfiguration
          key={costObject.description}
          costObject={costObject}
          objectPath={objectPath}
          index={index}
          size={size}
          onChangeValues={onChangeValues}
        />
      ))}
    </div>
  );
};

interface CostObjectConfigurationProps {
  costObject: CostObject;
  onChangeValues: (e: React.ChangeEvent<HTMLInputElement>) => void;
  objectPath: string;
  size: number;
  index: number;
}

const CostObjectConfiguration: React.FunctionComponent<CostObjectConfigurationProps> = ({
  costObject,
  onChangeValues,
  objectPath,
  index,
  size,
}) => {
  return (
    <>
      <div className={"col-auto " + (index > 0 && "mt-2")} style={{ width: `${100 / size}%` }}>
        <Input
          className="form-control custom-form-control"
          type={"text"}
          min={0}
          name={`${objectPath}.${index}.description`}
          value={costObject.description}
          onChange={onChangeValues}
        />
      </div>
      <div className={"col-auto " + (index > 0 && "mt-2")} style={{ width: `${100 / size}%` }}>
        <div className="input-group">
          <Input
            className="form-control custom-form-control"
            type={"number"}
            min={0}
            name={`${objectPath}.${index}.costPerUnit`}
            value={costObject.costPerUnit}
            onChange={onChangeValues}
          />
          <div className="input-group-append rounded-end bg-custom-light-gray">
            <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
              {costObject.currency}/{costObject.unit}
            </div>
          </div>
        </div>
      </div>
      <div className={"col-auto " + (index > 0 && "mt-2")} style={{ width: `${100 / size}%` }}>
        <div className="input-group">
          <Input
            className={"form-control custom-form-control "}
            type={"number"}
            min={0}
            name={`${objectPath}.${index}.minimum`}
            value={costObject.minimum || 0}
            onChange={onChangeValues}
          />
          <div className="input-group-append rounded-end bg-custom-light-gray">
            <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
              {costObject.currency}
            </div>
          </div>
        </div>
      </div>
      {size === 4 && (
        <div className={"col-auto " + (index > 0 && "mt-2")} style={{ width: `${100 / size}%` }}>
          {costObject.unit === "day/100kg" && (
            <div className="input-group">
              <Input
                className={"form-control custom-form-control " + (costObject.freeDays === undefined && "disabled")}
                type={"number"}
                min={0}
                name={`${objectPath}.${index}.freeDays`}
                value={costObject.freeDays || 0}
                onChange={onChangeValues}
              />
              <div className="input-group-append rounded-end bg-custom-light-gray">
                <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                  days
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
