import React, { PureComponent } from "react";
import { D_MASTERSPECIFICATION } from "../../../utils/commodityUtils";
import { formatDate, toAbsoluteUrl } from "../../../utils/baseUtils";
import { resolveFilePath } from "../../../utils/fileUtils";
import { COMMODITY } from "../../../services/dbService";
import { SupplierMasterSpecificationType } from "../common/CustomTypes";

interface MasterSpecificationProps {
  article: SupplierMasterSpecificationType;
}

class MasterSpecification extends PureComponent<MasterSpecificationProps> {
  render() {
    const { article } = this.props;
    let masterSpec;
    if (article.type === COMMODITY) {
      masterSpec = article.commodity.documents.find((d) => d.type === D_MASTERSPECIFICATION);
    } else {
      masterSpec = article.finishedProduct.documents.find((d) => d.type === D_MASTERSPECIFICATION);
    }

    return (
      <div className="py-0">
        <div className="d-flex py-6 align-items-center w-100">
          <div className="me-3">
            <div className="text-white fs-5 fw-bolder">Master Specification</div>
            <div className="text-muted">Specification determining target values</div>
          </div>
        </div>
        <div className="table-responsive mt-5 pt-2 bg-light2">
          <table className="table align-middle gs-0 gy-1 ">
            <thead className="border-bottom-dark-gray">
              <tr className="fw-bolder text-muted">
                <th>Type</th>
                <th>File</th>
                <th>Valid til</th>
              </tr>
            </thead>
            <tbody>
              {masterSpec ? (
                <tr>
                  <td className="align-middle">
                    <a href={resolveFilePath(masterSpec.path)} target="_blank" rel="noopener noreferrer">
                      <span className="text-white fw-bolder">Master Specification</span>
                    </a>
                  </td>
                  <td className="align-middle">
                    <a href={resolveFilePath(masterSpec.path)} target="_blank" rel="noopener noreferrer">
                      <img alt="pdf" className="w-30px mr-2" src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} />
                      <span className="text-white fw-bold align-middle">
                        {masterSpec.name}
                        {masterSpec.size && (
                          <small className="text-muted ml-2">{Math.round(masterSpec.size / 1024)} KB</small>
                        )}
                      </span>
                    </a>
                  </td>
                  <td className="align-middle">
                    {masterSpec.validUntil ? (
                      <span className={masterSpec.validUntil > new Date() ? "text-success" : "text-danger"}>
                        {formatDate(masterSpec.validUntil)}
                      </span>
                    ) : (
                      <span className="text-white">-</span>
                    )}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td className="align-middle text-center py-5 cursor-default" colSpan={4}>
                    <span className="text-white fw-bolder">No master specification available yet</span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default MasterSpecification;
