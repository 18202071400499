import React from "react";
import { UserData } from "../../model/userData.types";
import { getUserName } from "../../utils/userUtils";

interface ContactPersonWidgetProps {
  person?: UserData;
  spanClasses: string;
}

const ContactPersonWidget: React.FunctionComponent<ContactPersonWidgetProps> = ({ person, spanClasses }) => {
  return (
    <span className={spanClasses}>
      <i className="fas fa-user mr-1" />
      {getUserName(person)}
    </span>
  );
};

export default ContactPersonWidget;
