import React, { Component } from "react";
import { getRawbidsVersion, updateRawbidsVersion } from "../../utils/generalUtils";
import { INTERNAL } from "../../utils/userUtils";

interface VersionCheckProps {
  view: string;
}

interface VersionCheckState {
  show: boolean;
  versionMajor: number;
  versionMinor: number;
  versionPatch: number;
}

class VersionCheck extends Component<VersionCheckProps, VersionCheckState> {
  versionFrontendMajor: number;
  versionFrontendMinor: number;
  versionFrontendPatch: number;
  pollingTimer: NodeJS.Timeout | null;
  constructor(props: VersionCheckProps) {
    super(props);
    const [versionFrontendMajor, versionFrontendMinor, versionFrontendPatch] =
      process.env.REACT_APP_VERSION!.split(".");
    this.versionFrontendMajor = parseInt(versionFrontendMajor);
    this.versionFrontendMinor = parseInt(versionFrontendMinor);
    this.versionFrontendPatch = parseInt(versionFrontendPatch);
    this.pollingTimer = null;
    this.state = { show: false, versionMajor: 0, versionMinor: 0, versionPatch: 0 };
  }

  async componentDidMount() {
    if (process.env.NODE_ENV === "production") {
      await this.pollVersion();
      this.pollingTimer = setInterval(async () => {
        await this.pollVersion();
        await this.updateBackendVersion();
      }, 60000);
    }
  }

  componentWillUnmount() {
    if (this.pollingTimer) {
      clearInterval(this.pollingTimer);
      this.pollingTimer = null;
    }
  }

  /**
   * Retrieve the current version number of Rawbids from backend.
   */
  pollVersion = async () => {
    const versionBackend = await getRawbidsVersion();
    try {
      if (versionBackend) {
        const [versionBackendMajor, versionBackendMinor, versionBackendPatch] = versionBackend
          .toString()
          .split(".")
          .map((v: string) => parseInt(v));
        let show = false;
        if (versionBackendMajor > this.versionFrontendMajor) {
          show = true;
        } else if (
          versionBackendMajor === this.versionFrontendMajor &&
          versionBackendMinor > this.versionFrontendMinor
        ) {
          show = true;
        } else if (
          versionBackendMinor === this.versionFrontendMinor &&
          versionBackendPatch > this.versionFrontendPatch
        ) {
          show = true;
        }
        this.setState({
          versionMajor: versionBackendMajor,
          versionMinor: versionBackendMinor,
          versionPatch: versionBackendPatch,
          show,
        });
      }
    } catch (e) {
      console.error("Polling version failed due to:", e);
    }
  };

  /**
   * Updates the backend version of the application if the frontend version is higher than the backend version.
   */
  updateBackendVersion = async () => {
    const { versionMajor, versionMinor, versionPatch } = this.state;
    let updateNeeded = false;
    if (versionMajor < this.versionFrontendMajor) {
      updateNeeded = true;
    } else if (versionMajor === this.versionFrontendMajor && versionMinor < this.versionFrontendMinor) {
      updateNeeded = true;
    } else if (versionMinor === this.versionFrontendMinor && versionPatch < this.versionFrontendPatch) {
      updateNeeded = true;
    }
    if (updateNeeded) {
      const rawbidsVersion = [this.versionFrontendMajor, this.versionFrontendMinor, this.versionFrontendPatch].join(
        "."
      );
      await updateRawbidsVersion(rawbidsVersion); // We don't want to do something with the result
    }
  };

  render() {
    const { view } = this.props;
    const { show, versionMajor, versionMinor, versionPatch } = this.state;

    // On major update a reload is enforced since nothing can be guaranteed anymore
    if (process.env.NODE_ENV === "production" && this.versionFrontendMajor < versionMajor) {
      window.location.reload();
    }

    return (
      show && (
        <div
          className="w-100 position-fixed"
          style={{
            background: "white",
            zIndex: 999,
            top: 0,
            left: 0,
            minHeight: "100px",
            backgroundColor: "#f5bb4f",
          }}
        >
          <div className="row m-2">
            <div className="col-12 text-center mt-3">
              <div className="text-dark h5">
                <b>Rawbids v{[versionMajor, versionMinor, versionPatch].join(".")} is available.</b> <br />
                Please{" "}
                {view === INTERNAL &&
                  this.versionFrontendPatch !== versionPatch &&
                  this.versionFrontendMinor === versionMinor &&
                  "save your work and "}
                reload the application!
              </div>
            </div>
            <div className="col-12 text-center">
              <div className="btn btn-clean text-dark m-2" onClick={() => this.setState({ show: false })}>
                Dismiss
              </div>
              <div className="btn m-2 text-white" style={{ background: "black" }} onClick={window.location.reload}>
                Reload page
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}

export default VersionCheck;
