import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { DEFAULT_PALETTE } from "../../../../utils/priceCalculationUtils";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import { Input } from "../../../common/Input";
import {
  calculateCBM,
  insertPackagingDimension,
  PD_TRANSPORT_TYPE_OPTIONS,
} from "../../../../utils/packagingDimensionUtils";
import { PackagingDimension, SupplierExtended } from "../../../../model/supplier.types";
import { SupplierSupplierExtended } from "../../../../model/supplier/supplierSupplier.types";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";

interface CreatePackagingDimensionModalProps {
  supplier: SupplierExtended | SupplierSupplierExtended;
}

interface CreatePackagingDimensionModalState {
  description: string;
  length: number;
  width: number;
  height: number;
  netWeight: number;
  grossWeight: number;
  stackable: boolean;
  transportTypes: Array<SelectOption>;
  step: number;
  show: boolean;
  saving: boolean;
}

class CreatePackagingDimensionModal extends PureComponent<
  CreatePackagingDimensionModalProps,
  CreatePackagingDimensionModalState
> {
  constructor(props: CreatePackagingDimensionModalProps) {
    super(props);
    this.state = this.getDefaultState(false);
  }

  handleShow = () => this.setState(this.getDefaultState(true));
  handleHide = () => this.setState({ show: false });
  handleNext = () => this.setState({ step: 1 });
  handleBack = () => this.setState({ step: 0 });

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;
    // @ts-ignore
    this.setState({ [name]: type === "number" ? Number(value) : value });
  };

  handleChangeStackable = (e: SelectOption) => {
    if (e.value === "yes") this.setState({ stackable: true });
    else this.setState({ stackable: false });
  };

  handleSelectTransportTyp = (types: Array<SelectOption>) => {
    this.setState({ transportTypes: types });
  };

  handleSavePackagingDimension = async () => {
    const { supplier } = this.props;
    const { description, length, width, height, netWeight, grossWeight, transportTypes, stackable } = this.state;
    this.setState({ saving: true });
    try {
      const packagingDimension: PackagingDimension = {
        _id: new BSON.ObjectId(),
        description,
        length,
        width,
        height,
        cbm: calculateCBM(length, width, height),
        netWeight,
        grossWeight,
        commodities: [],
        finishedProducts: [],
        stackable,
        transportTypes: transportTypes.map((t) => t.value),
      };
      const res = await insertPackagingDimension(packagingDimension, supplier._id);
      if (res) {
        toast.success("Palette data successfully inserted");
        this.handleHide();
      } else {
        toast.error("Error inserting palette data");
      }
    } catch (e) {
      console.error("Error inserting palette data:", e);
      toast.error("Error inserting palette data");
    } finally {
      this.setState({ saving: false });
    }
  };

  getDefaultState = (show: boolean): CreatePackagingDimensionModalState => {
    return {
      show,
      step: 0,
      description: "",
      length: DEFAULT_PALETTE.length,
      width: DEFAULT_PALETTE.width,
      height: DEFAULT_PALETTE.height,
      netWeight: DEFAULT_PALETTE.netWeight,
      grossWeight: DEFAULT_PALETTE.grossWeight,
      saving: false,
      stackable: false,
      transportTypes: PD_TRANSPORT_TYPE_OPTIONS,
    };
  };

  validateData = () => {
    const { length, width, height, netWeight, grossWeight } = this.state;
    const errors: Array<string> = [];
    if (length <= 0) errors.push("Length has to be greater than zero.");
    if (width <= 0) errors.push("Width has to be greater than zero.");
    if (height <= 0) errors.push("Height has to be greater than zero.");
    if (netWeight <= 0) errors.push("Net weight has to be greater than zero.");
    if (grossWeight <= 0) errors.push("Gross weight has to be greater than zero.");
    return errors;
  };

  render() {
    const {
      description,
      length,
      width,
      height,
      netWeight,
      grossWeight,
      stackable,
      transportTypes,
      step,
      show,
      saving,
    } = this.state;
    const onSummary = step === 1;
    const errors = this.validateData();

    return (
      <>
        <button className="btn w-100 fw-bolder btn-light" onClick={this.handleShow}>
          New Palette Data
        </button>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered size="lg">
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">New Palette Data</h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-3">
              <div className="col-12 mt-5">
                <label className="fs-5 fw-bold mb-2">Description</label>
                <input
                  type="text"
                  className={"form-control custom-form-control " + (onSummary ? "disabled" : "")}
                  name="description"
                  value={description}
                  disabled={onSummary}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-12 col-lg-6 mt-5">
                <label className="required fs-5 fw-bold mb-2">Length</label>
                <div className="input-group">
                  <Input
                    type="number"
                    className={"form-control custom-form-control " + (onSummary ? "disabled" : "")}
                    name="length"
                    value={length}
                    disabled={onSummary}
                    min={0}
                    onChange={this.handleChange}
                  />
                  <div className="input-group-append rounded-end bg-custom-light-gray">
                    <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                      cm
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 mt-5">
                <label className="required fs-5 fw-bold mb-2">Width</label>
                <div className="input-group">
                  <Input
                    type="number"
                    className={"form-control custom-form-control " + (onSummary ? "disabled" : "")}
                    name="width"
                    value={width}
                    disabled={onSummary}
                    min={0}
                    onChange={this.handleChange}
                  />
                  <div className="input-group-append rounded-end bg-custom-light-gray">
                    <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                      cm
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 mt-5">
                <label className="required fs-5 fw-bold mb-2">Total Height</label>
                <div className="input-group">
                  <Input
                    type="number"
                    className={"form-control custom-form-control " + (onSummary ? "disabled" : "")}
                    name="height"
                    value={height}
                    disabled={onSummary}
                    min={0}
                    onChange={this.handleChange}
                  />
                  <div className="input-group-append rounded-end bg-custom-light-gray">
                    <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                      cm
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 mt-5">
                <label className="required fs-5 fw-bold mb-2">CBM (Calculated)</label>
                <div className="input-group">
                  <input
                    type="text" // So that the number toggle is not shown
                    className="form-control custom-form-control disabled"
                    value={calculateCBM(length, width, height)}
                    disabled={true}
                  />
                  <div className="input-group-append rounded-end bg-custom-light-gray">
                    <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                      m³
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 mt-5">
                <label className="required fs-5 fw-bold mb-2">Stackable</label>
                <CustomSelect
                  options={[
                    { value: "yes", label: "Yes" },
                    { value: "no", label: "No" },
                  ]}
                  disabled={onSummary}
                  value={stackable ? { value: "yes", label: "Yes" } : { value: "no", label: "No" }}
                  matchFormControl={true}
                  onChange={this.handleChangeStackable}
                />
              </div>
              <div className="col-12 col-lg-6 mt-5">
                <label className="required fs-5 fw-bold mb-2">Used For</label>
                <CustomSelect
                  isMulti={true}
                  disabled={onSummary}
                  options={PD_TRANSPORT_TYPE_OPTIONS}
                  matchFormControl={true}
                  value={transportTypes}
                  onChange={this.handleSelectTransportTyp}
                />
              </div>
              <div className="col-12 col-lg-6 mt-5">
                <label className="required fs-5 fw-bold mb-2">Ingredient Amount per Palette</label>
                <div className="input-group">
                  <Input
                    type="number"
                    className={"form-control custom-form-control " + (onSummary ? "disabled" : "")}
                    name="netWeight"
                    value={netWeight}
                    disabled={onSummary}
                    min={0}
                    onChange={this.handleChange}
                  />
                  <div className="input-group-append rounded-end bg-custom-light-gray">
                    <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                      kg
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 mt-5">
                <label className="required fs-5 fw-bold mb-2">Total Palette Weight</label>
                <div className="input-group">
                  <Input
                    type="number"
                    className={"form-control custom-form-control " + (onSummary ? "disabled" : "")}
                    name="grossWeight"
                    value={grossWeight}
                    disabled={onSummary}
                    min={0}
                    onChange={this.handleChange}
                  />
                  <div className="input-group-append rounded-end bg-custom-light-gray">
                    <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                      kg
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {onSummary && (
              <div className="row">
                <h2 className="fw-bolder text-white text-center">Please check that the data above is correct!</h2>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-sm btn-outline btn-text-white"
              onClick={step === 0 ? this.handleHide : this.handleBack}
            >
              {step === 0 ? "Close" : "Back"}
            </button>
            <ErrorOverlayButton
              errors={errors}
              className="btn btn-sm btn-outline btn-outline-light"
              buttonText={saving ? "Saving..." : step === 0 ? "Next" : "Create"}
              onClick={step === 0 ? this.handleNext : this.handleSavePackagingDimension}
              saving={saving}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreatePackagingDimensionModal;
