import React, { useCallback, useMemo, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { Input } from "../../../common/Input";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import { General } from "../../../../model/general.types";
import { getDeliveryTimeDisplayText } from "../../../../utils/settingsUtils";
import { updateGeneral } from "../../../../utils/generalUtils";

interface EditDeliveryTimeSettingModalProps {
  setting: General;
}

const EditDeliveryTimeSettingModal: React.FC<EditDeliveryTimeSettingModalProps> = ({ setting }) => {
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const [value, setValue] = useState(Number(setting.value));

  const handleShow = () => {
    setShow(true);
    setValue(Number(setting.value));
  };
  const handleHide = () => setShow(false);
  const handleChangeValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setValue(Number(e.target.value)),
    []
  );

  const handleSaveChange = useCallback(async () => {
    setSaving(true);
    try {
      const res = await updateGeneral({ ...setting, value, lastUpdate: new Date() });
      if (res && res.modifiedCount > 0) {
        toast.success("Delivery time setting successfully updated");
        handleHide();
      } else {
        toast.error("Error updating delivery time setting");
      }
    } catch (e) {
      console.error("Error updating delivery time setting:", e);
    }
    setSaving(false);
  }, [value, setting]);

  const errors = useMemo(() => {
    const errors: Array<string> = [];
    if (value <= 0) errors.push("Value must be positive");
    if (!Number.isInteger(value)) errors.push("Value must not be a floating point number");
    return errors;
  }, [value]);

  const { key } = setting;

  return (
    <>
      <button className="btn btn-sm btn-outline btn-outline-light" onClick={handleShow}>
        Edit
      </button>
      <Modal contentClassName="bg-dark" show={show} onHide={handleHide} centered>
        <Modal.Header className="border-0 pb-0">
          <Modal.Title>
            <h1 className="fw-bolder d-flex align-items-center text-white">Edit Delivery Time Configuration</h1>
          </Modal.Title>
          <CloseButton variant="white" onClick={handleHide} />
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-3">
            <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
              <label className="fs-5 fw-bold mb-2">Key</label>
              <Input
                type="text"
                className="form-control custom-form-control"
                value={getDeliveryTimeDisplayText(key)}
                disabled={true}
              />
            </div>
            <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
              <label className="required fs-5 fw-bold mb-2">Value</label>
              <div className="input-group">
                <Input
                  type="number"
                  className="form-control custom-form-control"
                  name="value"
                  integerOnly={true}
                  value={value}
                  disabled={saving}
                  onChange={handleChangeValue}
                />
                <div className="input-group-append rounded-end bg-custom-light-gray">
                  <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                    days
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline btn-text-danger" onClick={handleHide}>
            Cancel
          </button>
          <ErrorOverlayButton
            errors={errors}
            saving={saving}
            className="btn btn-sm btn-outline btn-outline-light"
            buttonText={saving ? "Saving..." : "Confirm"}
            onClick={handleSaveChange}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditDeliveryTimeSettingModal;
