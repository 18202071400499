import _ from "lodash";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { DataContextAnonymousType, DataContextCustomerType } from "../../../context/dataContext";
import NewsPreview from "../../common/NewsPreview";
import { getNews } from "../../../utils/articleUtils";
import Search from "../../common/Search";
import { doFuseSearch, pluralize } from "../../../utils/baseUtils";

interface CustomerDashboardNewsProps {
  context: DataContextCustomerType | DataContextAnonymousType;
}

interface CustomerDashboardNewsState {
  newsAmount: number;
  search: string;
}

class CustomerDashboardNews extends PureComponent<CustomerDashboardNewsProps, CustomerDashboardNewsState> {
  constructor(props: CustomerDashboardNewsProps) {
    super(props);
    this.state = { newsAmount: 0, search: "" };
  }

  componentDidMount() {
    this.setState({ newsAmount: this.calculateLatestNewsAmount() });
  }

  componentDidUpdate(prevProps: Readonly<CustomerDashboardNewsProps>) {
    if (_.isEqual(prevProps.context.news, this.props.context.news)) {
      this.setState({ newsAmount: this.calculateLatestNewsAmount() });
    }
  }

  handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });

  calculateLatestNewsAmount = () => {
    const { context } = this.props;
    const actualDateSubThirtyDays = new Date();
    actualDateSubThirtyDays.setDate(actualDateSubThirtyDays.getDate() - 30);
    return getNews(context.news).filter((n) => n.creationTime >= actualDateSubThirtyDays).length;
  };

  filterNews = () => {
    const { context } = this.props;
    const { search } = this.state;
    let news = getNews(context.news);
    if (search.trim()) {
      news = doFuseSearch(news, search, ["title", "tags"]);
    }
    return news;
  };

  render() {
    const { context } = this.props;
    const { search, newsAmount } = this.state;
    const news = this.filterNews();

    return (
      <div className="card h-100 bg-white">
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">News</span>
            <span className="text-muted fw-bold fs-7">{pluralize(newsAmount, "Article")} within 30 days</span>
          </h3>
          <div className="card-toolbar" style={{ maxWidth: "50%" }}>
            <Search value={search} onSearch={this.handleChangeSearch} />
          </div>
        </div>
        <div className="card-body" style={{ overflowY: "auto" }}>
          {news.length > 0 ? (
            news
              .sort((a, b) => {
                return b.creationTime.getTime() - a.creationTime.getTime();
              })
              .slice(0, 5)
              .map((article) => (
                <Link key={article._id.toString()} to={"/article/" + article._id.toString()}>
                  <NewsPreview article={article} context={context} size="sm" hover={true} noTitleLink={true} />
                </Link>
              ))
          ) : (
            <div className="p-7 text-center text-muted h6">No News found.</div>
          )}
        </div>
        <div className="card-footer border-top-0 pt-4">
          <Link to="/news">
            <button type="button" className="btn btn-light w-100">
              Show More
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

export default CustomerDashboardNews;
