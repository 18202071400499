import React, { useContext } from "react";
import CreateForwardingOrder from "./CreateForwardingOrder";
import { DataContextInternal } from "../../../../context/dataContext";

const ForwardingOrderWrapper: React.FunctionComponent = () => {
  const context = useContext(DataContextInternal);
  return <CreateForwardingOrder context={context} />;
};

export default ForwardingOrderWrapper;
