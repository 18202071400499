import { SelectedSpecificationUserData } from "../userUtils";
import { resolveFilePath } from "../fileUtils";
import { CoAInput, Header } from "../../components/batch/CreateRawbidsCoA";
import { Batch } from "../../model/batch.types";
import { isAnyFinishedProduct, isCommoditySnapshot } from "../productArticleUtils";

export function createCoAHTML(
  batch: Batch,
  headerInfoOne: Array<Header>,
  headerInfoTwo: Array<{ item: string; input: Date }>,
  country: Header,
  allergyAndAdditional: Array<CoAInput>,
  storageConditions: string,
  furtherRemarks: Array<CoAInput>,
  furtherRemarksText: string,
  originator: SelectedSpecificationUserData,
  physicalProperties?: Array<CoAInput>,
  meshSize?: CoAInput,
  purity?: CoAInput,
  heavyMetals?: Array<CoAInput>,
  microbiologicalAnalysis?: Array<CoAInput>,
  microbiologicalAnalysisSelectables?: Array<CoAInput>,
  carcinogenicSubstances?: Array<CoAInput>
) {
  const documentInformationRows: Array<[key: string, value: string | Array<string>]> = [
    ["Document Type", "Certificate of Analysis"],
    ["Doc. Identifier", `300${batch.identifier}00`],
    ["Version", "1.0"],
    batch.commodity.organic ? ["Certified Organic Code", "DE-ÖKO-006"] : ["", ""],
    ["Release", `${originator.prename} ${originator.surname} [${originator.date.toISOString().split("T")[0]}]`],
  ];

  const confirmationInformationRows: Array<[key: string, value: string]> = [
    [
      "Confirmation of BSE / TSE Status<br><span style='font-weight: 200'>This is to certify that this product complies with all relevant current UK and EU Legislative requirements in regard to Transmissible Spongiform Encephalopathies (TSE) and Bovine Spongiform Encephalopathy (BSE) for human food, and so is free of TSE/BSE.</span>",
      "",
    ],
    [
      "Confirmation of GMO Status<br><span style='font-weight: 200'>This is to certify that this product is not manufactured from GM raw materials and is therefore not subject to labelling under current regulations.</span>",
      "",
    ],
    [
      "Confirmation of Non-Irradiation Status<br><span style='font-weight: 200'>This is to certify that this product, whole or in part, has not been subjected to Ionising Radiation as per European Directives.</span>",
      "",
    ],
    [
      "Confirmation of Nandrolone Status<br><span style='font-weight: 200'>This is to certify that this product, whole or in part, has not come into contact with Nandrolone or any of its precursors in any way.</span>",
      "",
    ],
    [
      "Confirmation of IOC Product Status<br><span style='font-weight: 200'>This is to certify that this product, whole or in part, has not come into contact with any product/s, which is banned by the IOC (International Olympics Committee) and or WADA.</span>",
      "",
    ],
    [
      "Confirmation of Animal Testing Status<br><span style='font-weight: 200'>This is to certify that all the products sold by RAWBIDS have not been tested on animals in any part of its manufacture in accordance with current regulations.</span>",
      "",
    ],
    [
      "Confirmation of Pesticides Status<br><span style='font-weight: 200'>This is to certify that the above-mentioned product complies with the EU max residue limits (MRLs) on pesticides.</span>",
      "",
    ],
    [
      "Confirmation of Nanoparticles Status<br><span style='font-weight: 200'>This is to certify that unless otherwise stated, the above-mentioned product is free of nanoparticles. Commission Recommendation, defines as follows: \"'Nanomaterial' means a natural, incidental or manufactured material containing particles, in an unbound state or as an aggregate or as an agglomerate and where, for 50 % or more of the particles in the number size distribution, one or more external dimensions is in the size range 1 nm - 100 nm\".</span>",
      "",
    ],
    [
      "Packaging Status<br><span style='font-weight: 200'>We hereby certify that the packaging used in the above-mentioned material conforms to EU regulations and subsequent amendments on food grade packaging</span>",
      "",
    ],
    [
      "Confirmation of PAH status<br><span style='font-weight: 200'>This is to certify that the above-mentioned product complies with the max PAH limits set by the EU regulations</span>",
      "",
    ],
    [
      isCommoditySnapshot(batch.commodity) && batch.commodity.regulatoryData.en
        ? batch.commodity.regulatoryData.en
        : "",
      "",
    ],
    [
      isCommoditySnapshot(batch.commodity) && batch.commodity.possibleCrossContamination.en
        ? batch.commodity.possibleCrossContamination.en
        : "",
      "",
    ],
  ];

  return `<html lang="en">
<head>
  <link href="https://fonts.googleapis.com/css?family=Arimo" rel="stylesheet">
  <style>tr:nth-child(even) {background-color: white;}</style>
  <meta charset="UTF-8"/>
  <title>Certificate of Analysis ${headerInfoOne[0].input}</title>
</head>
<body style="font-family: Arimo, sans-serif">
    <img alt="Rawbids Logo" src="https://rawbids.com/images/logo-dark.png" width="180" style="float:right"/>
    <br/><br/><br/><br/>
    <table style="width: 100%"><tr>
    <td>
    <span style="font-size: 28px; font-weight: 900">Certificate of Analysis</span><br/><br/>
    <span style="font-size: 20px; font-weight: bold">${batch.commodity.title.en}</span>
    <br/>
    <span style="font-size: 18px; font-weight: bold; font-style: italic">${batch.commodity.subtitle.en}</span>
    </td>
   <td style="float:right">
    <div style="float: right; text-align: center; background-color: #333333; border-radius: 10px; padding: 5px; margin-bottom: 5px">
    <img alt="QR" style=" border-radius: 8px" src="https://v2018.api2pdf.com/zebra?apikey={{API_KEY}}&format=QR_CODE&value=https://${
      process.env.REACT_APP_BASE_URL || ""
    }/commodity/${batch.commodity._id.toString()}"/><br/><span style="font-size: 12px; text-align: center; color: white">Open digital<br/>Certificate of Analysis</span></div>
    </td></tr>
    </table>
    </table>
    <table cellpadding="5" cellspacing="0" style="font-size:15px; width:50%; background-color:#fafafa; justify-self: right;" >
      ${createHeader("Document Information")}
      <tbody>
        ${documentInformationRows.reduce((resHtml, def) => resHtml + createRow(def), "")}
      </tbody>
    </table>
   <table style="font-size:15px; width:100%; margin-top:10px">
   <tr><td style="width: 45%"> 
   <table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa;" >
      ${createHeader("Product & Batch Information")}
      <tbody>
        ${headerInfoOne.reduce(
          (resHtml, def) =>
            resHtml +
            (def.input
              ? `<tr><td style="text-align: left;"><b>${def.item}</b></td><td style="text-align: right;"><span>${def.input}</span></td></tr>`
              : `<tr><td style="text-align: left;"><b>${def.item}</b></td><td style="text-align: right;"><span>-</span></td></tr>`),
          ""
        )}
         ${headerInfoTwo.reduce(
           (resHtml, def) =>
             resHtml +
             (def.input
               ? `<tr><td style="text-align: left;"><b>${def.item}</b></td><td style="text-align: right;"><span>${
                   def.input.toISOString().split("T")[0]
                 }</span></td></tr>`
               : `<tr><td style="text-align: left;"><b>${def.item}</b></td><td style="text-align: right;"><span>-</span></td></tr>`),
           ""
         )}
         <tr>
            <td style="text-align: left;"><b>${country.item}</b></td>
            <td style="text-align: right;">
              <span>${country.input ? country.input : "-"}</span>
            </td>
         </tr>
      </tbody>
    </table>
    <br/></td>
    </tr>
   ${
     physicalProperties
       ? meshSize &&
         `<table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px" >
      ${createHeader("Physical Properties")}
      <tbody>
        ${createSubHeader()}
        ${physicalProperties.reduce((resHtml, def) => resHtml + createCoARow(def), "")}
        ${createCoARow(meshSize)}
      </tbody>
    </table><br/>`
       : ""
   }
    ${
      purity
        ? `<table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px" >
      ${createHeader("General Analysis")}
      <tbody>
        ${createSubHeader()}
        ${createCoARow(purity)}
      </tbody>
    </table>
    <br/>`
        : ""
    }
    ${
      heavyMetals
        ? `     <table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px" >
          ${createHeader("Heavy Metals Analysis")}
          <tbody>
            ${createSubHeader()}
            ${heavyMetals.reduce((resHtml, def) => resHtml + createCoARow(def), "")}
          </tbody>
        </table>
        <br/>`
        : ""
    }
    ${
      microbiologicalAnalysis && microbiologicalAnalysisSelectables
        ? `<table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px" >
      ${createHeader("Microbiological Analysis")}
      <tbody>
        ${createSubHeader()}
        ${microbiologicalAnalysis.reduce((resHtml, def) => resHtml + createCoARow(def), "")}
        ${microbiologicalAnalysisSelectables.reduce((resHtml, def) => resHtml + createCoARow(def), "")}
      </tbody>
    </table>
    <br/>`
        : ""
    }
    <table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px" >
      ${createHeader("Allergy & Additional Certifications")}
      <tbody>
        ${createSubHeader({ itemCol: "Item", specCol: "Yes / No", resultCol: "Specified" })}
        ${allergyAndAdditional.reduce((resHtml, def) => resHtml + createCoARow(def), "")}
      </tbody>
    </table>
    <br/>
 ${
   carcinogenicSubstances
     ? `<table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px" >
      ${createHeader("Carcinogenic Substances")}
      <tbody>
        ${createSubHeader()}
        ${carcinogenicSubstances.reduce((resHtml, def) => resHtml + createCoARow(def), "")}
      </tbody>
    </table>
        <br/>`
     : ""
 }
    <table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px" >
      ${createHeader("Storage Conditions & Packing")}
      <tbody>
      <tr><td>${storageConditions ? storageConditions : "-"}</td></tr>
      </tbody>
    </table>
     <br/>
     ${isAnyFinishedProduct(batch.commodity) ? `<p style="page-break-after: auto;">&nbsp;</p>` : ""}
    <table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px" >
      ${createHeader("Confirmations")}
      <tbody>
        ${confirmationInformationRows.reduce((resHtml, def) => resHtml + createRow(def), "")}
      </tbody>
    </table>
    <br/>
    <table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px" >
      ${createHeader("Further Remarks")}
      <tbody>
        ${furtherRemarks.length > 0 ? createSubHeader() : furtherRemarksText ? "" : `<tr><td> - </td></tr>`}
        ${furtherRemarks.reduce((resHtml, def) => resHtml + createCoARow(def), "")}
        ${furtherRemarks.length > 0 ? `<tr><td><br></td></tr>` : ""}
        <tr><td>${furtherRemarksText}</td></tr>
      </tbody>
    </table>
    <br/>
    <div class="row" style="font-size: 14px; font-weight: bold; float: right">For and on Behalf of RAWBIDS GmbH.</div>
    <br/>
    ${
      originator && originator.signature
        ? `<div >
      <table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px" >
        ${createHeader("Document Approval")}
        <tbody>
          <tr>
            <td>
              <table>
                <tbody>
                  <tr>
                    <td style="width: 50%; text-align: right">
                      <div>Originator</div>
                      <div><b>${originator.prename} ${originator.surname}</b></div>
                      <div><i>${originator.position}</i></div>
                      <div>Released ${originator.date.toISOString().split("T")[0]}</div>
                      <div>E-mail: ${originator.emails[0].value}</div>
                    </td>
                    <td style="width: 50%;">
                      <img style="max-width: 150px; padding-left: 20px" src=${resolveFilePath(
                        originator.signature.path,
                        originator.signature.isPublic
                      )} alt="Signature"/>
                    </td>
                  </tr>
                </tbody>
              </table>
           </td>
          </tr>
        </tbody>
      </table>
    </div>`
        : ""
    }
    <br/>
    <hr><span>End of document.</span>
    <br/>
</body>
</html>
`;
}

const createHeader = (title: string) =>
  `<thead><tr style="background-color:#333333; color: #ffffff; text-align: left" ><th colSpan="3"><b>${title}</b></th></tr></thead>`;

const createSubHeader = (header?: { itemCol: string; specCol: string; resultCol: string }) => {
  return header
    ? `<tr><td style="text-align: left;"><b>${header.itemCol}</b></td><td style="text-align: left;"><b>${header.specCol}</b></td><td style="text-align: right;"><b>${header.resultCol}</b></td></tr>`
    : `<tr><td style="text-align: left;"><b>Item</b></td><td style="text-align: left;"><b>Specification</b></td><td style="text-align: right;"><b>Result</b></td></tr>`;
};

const createRow = (row: [key: string, values: string | Array<string>]) => {
  return (
    row[0] &&
    `<tr><td style="text-align: left;"><b>${row[0]}</b></td><td style="text-align: right;"><span>${
      Array.isArray(row[1]) ? row[1].join("<br/>") : row[1]
    }</span></td></tr>`
  );
};

const createCoARow = (row: CoAInput) => {
  if (row.result.trim() === "") {
    return (
      row.itemName &&
      `<tr><td style="text-align: left; width: 33%;"><b>${row.itemName}</b></td><td style="text-align: left; width: 33%;"><span>${row.spec}</span></td><td style="text-align: right; width: 33%;"><span>-</span></td></tr>`
    );
  } else {
    return (
      row.itemName &&
      `<tr><td style="text-align: left; width: 33%;"><b>${row.itemName}</b></td><td style="text-align: left; width: 33%;"><span>${row.spec}</span></td><td style="text-align: right; width: 33%;"><span>${row.result}</span></td></tr>`
    );
  }
};
