import React from "react";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import { O_SORTOPTIONS } from "../../../utils/orderUtils";
import { CR_AGEOPTIONS, CR_SORTOPTIONS, CR_STATEOPTIONS, CR_TYPEOPTIONS } from "../../../utils/customerRequestUtils";
import { SortOrder } from "../../../utils/filterUtils";
import SortIcon from "../../common/SortIcon";

interface CustomerRequestFilterProps {
  search: string;
  status?: SelectOption;
  age?: SelectOption;
  type?: SelectOption;
  sortColumn: SelectOption;
  sortOrder: SortOrder;
  onChangeSelect: (option: SelectOption | undefined, name: string) => void;
  onToggleSortOrder: () => void;
}

const CustomerRequestFilter: React.FunctionComponent<CustomerRequestFilterProps> = ({
  search,
  status,
  age,
  type,
  sortOrder,
  sortColumn,
  onChangeSelect,
  onToggleSortOrder,
}) => {
  const sortOptions = O_SORTOPTIONS.slice();
  if (search.trim()) sortOptions.unshift({ value: "", label: "Best Match" });
  return (
    <div className="row g-8">
      <div className="col-lg-3">
        <label className="fs-6 form-label fw-bolder text-dark">Status</label>
        <CustomSelect
          options={CR_STATEOPTIONS}
          matchFormControl={true}
          placeholder={"All States"}
          isClearable={true}
          onChange={(e: SelectOption) => onChangeSelect(e, "status")}
          value={status}
        />
      </div>
      <div className="col-lg-3">
        <label className="fs-6 form-label fw-bolder text-dark">Active</label>
        <CustomSelect
          options={CR_AGEOPTIONS}
          matchFormControl={true}
          placeholder={"All Requests"}
          isClearable={true}
          onChange={(e: SelectOption) => onChangeSelect(e, "age")}
          value={age}
        />
      </div>
      <div className="col-lg-3">
        <label className="fs-6 form-label fw-bolder text-dark">Type</label>
        <CustomSelect
          options={CR_TYPEOPTIONS}
          matchFormControl={true}
          placeholder={"All Requests"}
          isClearable={true}
          onChange={(e: SelectOption) => onChangeSelect(e, "type")}
          value={type}
        />
      </div>
      <div className="col-lg-3">
        <label className="fs-6 form-label fw-bolder text-dark">Sort</label>
        <label className=" form-label text-muted float-right">
          <SortIcon sortOrder={sortOrder} onToggleSortOrder={onToggleSortOrder} />
        </label>
        <CustomSelect
          options={CR_SORTOPTIONS}
          matchFormControl={true}
          onChange={(e: SelectOption) => onChangeSelect(e, "sortColumn")}
          value={sortColumn}
        />
      </div>
    </div>
  );
};

export default CustomerRequestFilter;
