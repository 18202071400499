import _ from "lodash";
import React, { PureComponent } from "react";
import { Nav } from "react-bootstrap";
import { CustomerCustomerOrderExtended } from "../../../../../model/customer/customerCustomerOrder.types";
import { DataContextAnonymousType, DataContextCustomerType } from "../../../../../context/dataContext";
import { getOrderStateRanking, getTabThatRequiresAttention } from "../../../../../utils/customerOrderUtils";
import OrderDetails from "../../../common/OrderDetails";
import { CO_CANCELED, CO_REQUESTEDBYCUSTOMER } from "../../../../../model/customerOrder.types";
import { formatCurrency } from "../../../../../utils/baseUtils";
import { BASE_CURRENCY } from "../../../../../utils/currencyUtils";
import Tooltip from "../../../../common/Tooltip";
import OrderUpdates from "../../../../common/customer/OrderUpdates";
import ActiveOrders from "../../../../common/customer/ActiveOrders";

interface OrderPageOrderDetailsProps {
  order: CustomerCustomerOrderExtended;
  context: DataContextCustomerType | DataContextAnonymousType;
}

class OrderPageOrderDetails extends PureComponent<OrderPageOrderDetailsProps> {
  isDone = (tab: string) => {
    const { order } = this.props;
    const stateRank = getOrderStateRanking(order);
    if (stateRank === -1) return false;
    switch (tab) {
      case "ordered":
        return stateRank > 1;
      case "shipping":
        return stateRank > 4;
      case "processing":
      case "closed":
        return stateRank > 6;
    }
  };

  getStepDescription = (step: string, isCurrent: boolean) => {
    switch (step) {
      case "ordered":
        return `Order ${isCurrent ? "is" : "was"} placed and prepared by our suppliers`;
      case "shipping":
        return `Commodities ${isCurrent ? "are" : "were"} shipped by the supplier`;
      case "processing":
        return `Commodities ${isCurrent ? "are" : "were"} processed at our warehouse`;
      case "closed":
        return "Order is done";
      default:
        return "";
    }
  };

  render() {
    const { order, context } = this.props;
    const progress = ["ordered", "shipping", "processing", "closed"];
    const currentProgress = getTabThatRequiresAttention(order);
    return (
      <>
        <div className="card card-bottom-radius-none bg-white">
          <div className="d-flex flex-column px-9 py-4 bg-light2 border-bottom-0">
            <div className="d-flex align-items-center justify-content-between mb-1 w-100">
              <div className="d-flex flex-column">
                <span className="fs-1 text-gray-800 fw-bolder">Order RB-{order.orderNo}</span>
                <div className="fs-4 text-muted">{order.commodity.title.en}</div>
              </div>
            </div>
          </div>
          <ul
            className="nav-group nav-group-fluid p-0 order-nav-group"
            style={{ borderRadius: 0, backgroundColor: "# !important" }}
          >
            <li className="btn btn-sm fw-bolder custom-form-control p-0 ">
              <Nav.Link
                id={"createOrder"}
                active={false}
                className={
                  "py-2 text-white " +
                  (order.state === CO_REQUESTEDBYCUSTOMER
                    ? "progress-background-requested"
                    : order.state === CO_CANCELED
                    ? "progress-background-canceled"
                    : "progress-background")
                }
                style={{ cursor: "initial" }}
              >
                {order.request ? (
                  <Tooltip
                    defaultShow={order.state === CO_REQUESTEDBYCUSTOMER}
                    tooltipText={
                      <span className="text-white">
                        You requested {order.amount}
                        {order.commodity.unit} of <em>{order.commodity.title.en}</em> for{" "}
                        {formatCurrency(order.priceCommodities / order.amount, BASE_CURRENCY)}/{order.commodity.unit}.
                        This is not yet confirmed. You will receive updates and a confirmation if we are able to match
                        your desired price.
                      </span>
                    }
                  >
                    <span>Requested</span>
                  </Tooltip>
                ) : (
                  "Create Order"
                )}
              </Nav.Link>
            </li>
            {progress.map((t) => {
              const done = this.isDone(t);
              const isCurrent = t === currentProgress;
              const disabled = !done && !isCurrent;
              return (
                <li key={t + "_link"} className="btn btn-sm fw-bolder custom-form-control p-0">
                  <Tooltip
                    show={disabled ? false : undefined}
                    delay={{ show: 250, hide: 0 }}
                    tooltipText={<span className="text-white">{this.getStepDescription(t, isCurrent)}</span>}
                  >
                    <Nav.Link
                      id={t}
                      active={isCurrent}
                      className={
                        order.state === CO_CANCELED
                          ? "text-white py-2 progress-background-canceled"
                          : "text-white py-2 " +
                            (disabled ? "text-muted " : "") +
                            (done ? "progress-background " : "") +
                            (isCurrent ? " progress-btn" : "")
                      }
                      style={{ cursor: "initial" }}
                    >
                      <span>{_.upperFirst(t)}</span>
                    </Nav.Link>
                  </Tooltip>
                </li>
              );
            })}
          </ul>
          <div className="card-body p-9 pt-4">
            <OrderDetails order={order} context={context} />
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-6">
            <OrderUpdates context={context} order={order} />
          </div>
          <div className="col-6">
            <ActiveOrders context={context} small={true} />
          </div>
        </div>
      </>
    );
  }
}

export default OrderPageOrderDetails;
