import React, { PureComponent } from "react";
import { CO_CANCELED, CustomerOrderExtended } from "../../../../../../model/customerOrder.types";
import { getProcessingTasksChecklist } from "../../../../../../utils/customerOrderUtils";

interface WorkflowProcessingTasksCardProps {
  order: CustomerOrderExtended;
}

class WorkflowProcessingTasksCard extends PureComponent<WorkflowProcessingTasksCardProps> {
  render() {
    const { order } = this.props;
    const processingTasks = getProcessingTasksChecklist(order);
    const allDone = processingTasks.every((pT) => pT.done);

    return (
      <div className="opacity-100-hover" style={{ opacity: allDone || order.state === CO_CANCELED ? 0.3 : 1 }}>
        <div className="fw-bolder text-white fs-3 my-5">
          Processing Tasks{" "}
          {allDone ? (
            <i className="h2 fas fa-check-circle text-success" />
          ) : order.state !== CO_CANCELED ? (
            <span className="text-warning">[Current Task]</span>
          ) : null}
        </div>
        <div className="row py-2">
          <div className="bg-light2 rounded p-5 mb-7">
            {processingTasks.map((pT) => (
              <div key={pT.task.value} className="form-check my-3">
                <input className="form-check-input" checked={pT.done} type="checkbox" disabled={true} />
                <label className="text-white my-0 pl-1">{pT.task.label}</label>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default WorkflowProcessingTasksCard;
