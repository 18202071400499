import countryList from "i18n-iso-countries";
import _ from "lodash";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { SN_TYPE, SystemNotification, SystemNotificationConditions } from "../../../model/systemNotification.types";
import { Commodity } from "../../../model/commodity.types";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { Textarea } from "../../common/Textarea";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import {
  insertSystemNotification,
  SYSTEM_NOTIFICATION_OPTIONS,
  SystemNotificationConditionsInput,
  updateSystemNotification,
} from "../../../utils/systemNotificationUtils";
import DateInput from "../../common/DateInput";
import { isArticleInactive } from "../../../utils/commodityUtils";
import { INTERNAL } from "../../../utils/userUtils";
import { getCountryNameForCode } from "../../../utils/baseUtils";
import { getOrderTypeName, O_ORDERMETHODS } from "../../../utils/orderUtils";
import { CO_TYPES } from "../../../model/customerOrder.types";

interface CreateSystemNotificationModalProps {
  systemNotification?: SystemNotification;
  commodities: Array<Commodity>;
}

interface CreateSystemNotificationModalState {
  show: boolean;
  message: string;
  type: SelectOption;
  validUntil: Date | null;
  condition: SystemNotificationConditionsInput;
  saving: boolean;
}

class CreateSystemNotificationModal extends PureComponent<
  CreateSystemNotificationModalProps,
  CreateSystemNotificationModalState
> {
  constructor(props: CreateSystemNotificationModalProps) {
    super(props);
    this.state = this.getDefaultState(false, props);
  }

  handleShow = () => this.setState(this.getDefaultState(true, this.props));
  handleHide = () => this.setState({ show: false });

  handleChangeMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => this.setState({ message: e.target.value });

  handleChangeType = (type: SelectOption) => this.setState({ type });

  handleChangeValidUntil = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ validUntil: e.target.valueAsDate });

  handleChangeCommodities = (e: Array<SelectOption>) => {
    const condition = _.cloneDeep(this.state.condition);
    condition.commodities = e;
    this.setState({ condition });
  };

  handleChangeOrigins = (e: Array<SelectOption>) => {
    const condition = _.cloneDeep(this.state.condition);
    condition.origins = e;
    this.setState({ condition });
  };

  handleChangeTransport = (e: Array<SelectOption>) => {
    const condition = _.cloneDeep(this.state.condition);
    condition.transport = e;
    this.setState({ condition });
  };

  handleSave = async () => {
    const { systemNotification } = this.props;
    const { message, type, validUntil, condition } = this.state;
    if (!validUntil) return;
    this.setState({ saving: true });
    try {
      const typePrepared = type.value as SN_TYPE;
      const conditionPrepared: SystemNotificationConditions = {};
      if (condition.commodities) conditionPrepared.commodities = condition.commodities.map((c) => c.value);
      if (condition.origins) conditionPrepared.origins = condition.origins.map((o) => o.value);
      if (condition.transport) conditionPrepared.transport = condition.transport.map((t) => t.value) as Array<CO_TYPES>;
      let res;
      if (systemNotification) {
        const update: Partial<SystemNotification> = {};
        if (message !== systemNotification.message) update.message = message;
        if (typePrepared !== systemNotification.type) update.type = typePrepared;
        if (!_.isEqual(conditionPrepared, systemNotification.condition)) update.condition = conditionPrepared;
        if (validUntil !== systemNotification.validUntil) update.validUntil = validUntil;
        res = await updateSystemNotification(update, systemNotification._id);
      } else {
        const sN: SystemNotification = {
          _id: new BSON.ObjectId(),
          message,
          type: typePrepared,
          validUntil,
          condition: conditionPrepared,
        };
        res = await insertSystemNotification(sN);
      }
      if (res && (("insertedId" in res && res.insertedId) || ("modifiedCount" in res && res.modifiedCount > 0))) {
        toast.success(`System Notification ${systemNotification ? "updated" : "inserted"} successfully`);
        this.handleHide();
      } else {
        toast.error(`Error ${systemNotification ? "updating" : "inserting"} System Notification`);
      }
    } catch (e) {
      console.error(e);
      toast.error(`Error ${systemNotification ? "updating" : "inserting"} System Notification`);
    } finally {
      this.setState({ saving: false });
    }
  };

  getDefaultState = (show: boolean, props: CreateSystemNotificationModalProps): CreateSystemNotificationModalState => {
    const { systemNotification, commodities } = props;
    const type = systemNotification?.type
      ? SYSTEM_NOTIFICATION_OPTIONS.find((sNO) => sNO.label === systemNotification.type)
      : undefined;
    const condition = systemNotification?.condition;
    const conditionState: SystemNotificationConditionsInput = {};
    if (condition) {
      if (condition.commodities) {
        conditionState.commodities = condition.commodities.map((c) => {
          return { value: c, label: commodities.find((com) => com._id.toString() === c)?.title.en || "Unknown" };
        });
      }
      if (condition.origins) {
        conditionState.origins = condition.origins.map((o) => {
          return { value: o, label: getCountryNameForCode(o) };
        });
      }
      if (condition.transport) {
        conditionState.transport = condition.transport.map((t) => {
          return { value: t, label: getOrderTypeName(t) };
        });
      }
    }
    return {
      show,
      message: systemNotification?.message ?? "",
      type: type ?? SYSTEM_NOTIFICATION_OPTIONS[0],
      validUntil: systemNotification?.validUntil ?? new Date(),
      condition: conditionState,
      saving: false,
    };
  };

  /**
   * Transforms the list of commodities to select options.
   * @returns {Array<SelectOption>} Commodities as select option
   */
  getCommodityOptions = (): Array<SelectOption> => {
    const { commodities } = this.props;
    return commodities
      .filter((c) => !isArticleInactive(c, INTERNAL))
      .map((cf) => {
        return { value: cf._id.toString(), label: cf.title.en };
      });
  };

  validateData = () => {
    const { message, validUntil } = this.state;
    const errors: Array<string> = [];
    if (message.trim().length < 10) errors.push("Please enter a message that is at least 10 characters long");
    if (!validUntil) errors.push("Please select a valid until date");
    return errors;
  };

  render() {
    const { systemNotification } = this.props;
    const { message, type, validUntil, condition, show, saving } = this.state;
    const errors = this.validateData();

    return (
      <>
        <button className="btn btn-sm btn-outline btn-outline-light" onClick={this.handleShow}>
          {systemNotification ? "Edit" : "Add New"}
        </button>
        <Modal show={show} size="lg" contentClassName={"bg-dark"} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">
                {systemNotification ? "Edit" : "Add"} System Notification
              </h1>
            </Modal.Title>
            <CloseButton variant="white" disabled={saving} onClick={saving ? undefined : this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-5 ">
              <div className="col-12 mt-5">
                <label className="required fs-5 fw-bold mb-2">Message</label>
                <Textarea
                  className="form-control custom-form-control"
                  placeholder="Message"
                  value={message}
                  onBlur={this.handleChangeMessage}
                />
              </div>
              <div className="col-12 col-md-6 mt-5">
                <label className="required fs-5 fw-bold mb-2">Type</label>
                <CustomSelect
                  options={SYSTEM_NOTIFICATION_OPTIONS}
                  value={type}
                  matchFormControl={true}
                  onChange={this.handleChangeType}
                />
              </div>
              <div className="col-12 col-md-6 mt-5">
                <label className="required fs-5 fw-bold mb-2">Valid Until</label>
                <DateInput
                  classes="form-control custom-form-control"
                  value={validUntil}
                  onBlur={this.handleChangeValidUntil}
                  name="validUntil"
                  min={new Date()}
                />
              </div>
              <div className="col-12 mt-10">
                <h3>Conditions for the message - if none is selected message is shown independent on that type</h3>
              </div>
              <div className="col-12  mt-5">
                <label className="fs-5 fw-bold mb-2">Commodities</label>
                <CustomSelect
                  options={this.getCommodityOptions()}
                  value={condition.commodities}
                  isClearable={true}
                  isMulti={true}
                  placeholder="All Commodities"
                  onChange={this.handleChangeCommodities}
                />
              </div>
              <div className="col-12 col-md-6 mt-5">
                <label className="fs-5 fw-bold mb-2">Origins</label>
                <CustomSelect
                  options={Object.values(countryList.getNames("en", { select: "alias" })).map((item: string) => {
                    return {
                      value: countryList.getAlpha2Code(item, "en"),
                      label: item,
                    };
                  })}
                  value={condition.origins}
                  isClearable={true}
                  isMulti={true}
                  placeholder="All Origins"
                  onChange={this.handleChangeOrigins}
                />
              </div>
              <div className="col-12 col-md-6 mt-5">
                <label className="fs-5 fw-bold mb-2">Order Methods</label>
                <CustomSelect
                  options={O_ORDERMETHODS}
                  value={condition.transport}
                  isClearable={true}
                  isMulti={true}
                  placeholder="All Order Methods"
                  onChange={this.handleChangeTransport}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              disabled={saving}
              className={"btn btn-light btn-sm " + (saving && "disabled")}
              onClick={saving ? undefined : this.handleHide}
            >
              Close
            </button>
            <ErrorOverlayButton
              errors={errors}
              className="btn btn-light btn-sm "
              saving={saving}
              buttonText="Save"
              onClick={this.handleSave}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreateSystemNotificationModal;
