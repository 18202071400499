import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { DataContextInternal } from "../../../../context/dataContext";
import { CustomerContract } from "../../../../model/customerContract.types";
import { getContractNumber } from "../../../../utils/customerContractUtils";
import { getAmountForOrderOrContract, getLinkForOrderOrContract } from "../../../../utils/contractAndOrderUtils";

interface CustomerOrderContractProps {
  context: React.ContextType<typeof DataContextInternal>;
  contract?: CustomerContract;
}

interface CustomerOrderContractState {}

class CustomerOrderContract extends PureComponent<CustomerOrderContractProps, CustomerOrderContractState> {
  render() {
    const { contract } = this.props;
    return (
      <>
        {contract && (
          <div>
            <div className="card mb-5 mb-xl-8 bg-white">
              <div className="card-body">
                <div className="d-flex flex-stack fs-4 py-3">
                  <div className="fw-bolder rotate collapsible">Contract</div>
                </div>
                <div className="border-bottom-dark-gray" />
                <div className="pb-5 fs-6">
                  <div className="fw-bolder mt-5">
                    <Link className="text-white custom-link" to={getLinkForOrderOrContract(contract)}>
                      {getContractNumber(contract)}
                    </Link>
                  </div>
                  <div className="text-muted">{getAmountForOrderOrContract(contract)}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default CustomerOrderContract;
