import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";

/**
 * Get html representation for remarks
 * @param content a WYSIWYG editor content/state
 * @returns {string} html representation of remarks
 */
export function getEditorStateAsHTML(content: EditorState): string {
  let contentString: string = draftToHtml(convertToRaw(content.getCurrentContent()));
  contentString = contentString.replace(/<p/g, "<span");
  contentString = contentString.replace(/p>/g, "span><br>");
  return contentString;
}

/**
 * Convert a string to an EditorState
 * @param content string, the text to convert
 * @returns {EditorState} the content as Editor State for a WYSIWYG editor
 */
export function getStringAsEditorState(content: string): EditorState {
  const blocksFromHTML = convertFromHTML(content);
  const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
  return EditorState.createWithContent(state);
}

/**
 * Get the string properly formatted as html, exchanging \n with <br/>
 * @param content the string to be converted
 * @returns {string} the string as html
 */
export function getFormattedHTML(content: string): string {
  return content.replaceAll("\n", "<br/>");
}
