import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { CO_ARCHIVED, CO_CANCELED, CO_STATES, CustomerOrderExtended } from "../../../../../model/customerOrder.types";
import { formatCurrency } from "../../../../../utils/baseUtils";
import { resolveFilePath, shortenAlias } from "../../../../../utils/fileUtils";
import { convertCurrency, CUSTOMER_BASE_CURRENCY } from "../../../../../utils/currencyUtils";
import { DataContextInternalType } from "../../../../../context/dataContext";
import ErrorOverlayButton from "../../../../common/ErrorOverlayButton";
import SimpleConfirmationModal from "../../../../common/SimpleConfirmationModal";
import { bookOrderService } from "../../../../../utils/customerOrderUtils";
import { Service } from "../../../../../model/service.types";

interface CustomerOrderServicesProps {
  order: CustomerOrderExtended;
  context: DataContextInternalType;
}

interface CustomerOrderServicesState {
  saving: boolean;
}

class CustomerOrderServices extends PureComponent<CustomerOrderServicesProps, CustomerOrderServicesState> {
  constructor(props: CustomerOrderServicesProps) {
    super(props);
    this.state = { saving: false };
  }

  handleAddService = async (service: Service) => {
    const { order, context } = this.props;
    this.setState({ saving: true });
    const price = convertCurrency(service.price, CUSTOMER_BASE_CURRENCY, order.currency, context.currencies);
    try {
      const res = await bookOrderService(order, service, price);
      if (res && res.modifiedCount > 0) {
        toast.success("Service successfully booked");
      } else {
        toast.error("Service could not be booked");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  render() {
    const { order, context } = this.props;
    const { saving } = this.state;
    const availableServices = context.service.filter(
      (s) => !order.services.some((oS) => s._id.toString() === oS.service._id.toString())
    );
    const disabled = ([CO_ARCHIVED, CO_CANCELED] as Array<CO_STATES>).includes(order.state);
    return (
      <div className="card bg-white">
        <div className="card-header border-0 mt-5">
          <div className="card-title flex-column">
            <h2 className="mb-1">Services</h2>
          </div>
        </div>
        <div className="card-body p-9 pt-0">
          {order.services.map((s) => (
            <div key={s.service._id.toString()} className="row">
              <div className="bg-light2 rounded p-5 mb-7">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 me-2">
                    <span className="fw-bolder text-gray-800 fs-6 flex-center flex-stack">
                      RBS-{s.service.serviceNo} {s.service.title.en}
                    </span>
                    <div style={{ maxHeight: "60px", overflowY: "auto" }}>
                      <span className="text-muted fw-bold d-block">{s.service.description.en}</span>
                    </div>
                  </div>
                  <div className="text-right" style={{ minWidth: "150px" }}>
                    <div className="fw-bold fs-6 text-gray-400">{s.service.duration} day(s) duration</div>
                    <span className="text-muted mb-1">{formatCurrency(s.service.price, CUSTOMER_BASE_CURRENCY)}</span>
                  </div>
                </div>
                <div className="mt-5">
                  <div className="fw-bolder text-white fs-5 mt-4">Service Documents</div>
                  <div>
                    {s.files.length > 0 ? (
                      s.files.map((d) => (
                        <a
                          key={d._id.toString()}
                          className="text-white fw-bold ml-3 custom-link"
                          href={resolveFilePath(d.path)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {shortenAlias(d.path)}
                        </a>
                      ))
                    ) : (
                      <span className="text-muted fw-bold ml-3">No files available</span>
                    )}
                  </div>
                </div>
                <div className="float-right">
                  {s.performed && (
                    <span className="text-white">
                      <i className="icon-lg fas fa-check-circle text-success mr-1" />
                      Done
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
          {availableServices.map((service) => (
            <div key={service._id.toString()} className="row">
              <div className="bg-light2 rounded p-5 mb-7">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 me-2">
                    <span className="fw-bolder text-gray-800 fs-6 flex-center flex-stack">
                      RBS-{service.serviceNo} {service.title.en}
                    </span>
                    <div style={{ maxHeight: "60px", overflowY: "auto" }}>
                      <span className="text-muted fw-bold d-block">{service.description.en}</span>
                    </div>
                  </div>
                  <div className="d-flex" style={{ minWidth: "230px" }}>
                    <div className="text-right mx-5">
                      <div className="fw-bold fs-6 text-gray-400">{service.duration} day(s) duration</div>
                      <span className="text-muted mb-1">{formatCurrency(service.price, CUSTOMER_BASE_CURRENCY)}</span>
                    </div>
                    {disabled ? (
                      <ErrorOverlayButton
                        errors={["Order is already being prepared or was canceled"]}
                        className={"btn btn-warning btn-sm mt-1"}
                        buttonText={"Book Now"}
                        onClick={() => true}
                      />
                    ) : (
                      <SimpleConfirmationModal.SimpleConfirmationModalButton
                        size="md"
                        saving={saving}
                        modalTitle="Book Service"
                        onConfirm={() => this.handleAddService(service)}
                        confirmButtonText="Book"
                        buttonText="Book Now"
                        buttonClasses={"btn btn-warning btn-sm mt-1 " + (saving && "disabled")}
                        cancelButtonText="Cancel"
                        modalDescription={
                          <span className="text-white">
                            Do you really want to book the service <em>{service.title.en}</em> for{" "}
                            <b>
                              {formatCurrency(
                                convertCurrency(
                                  service.price,
                                  CUSTOMER_BASE_CURRENCY,
                                  order.currency,
                                  context.currencies
                                ),
                                order.currency
                              )}
                            </b>
                          </span>
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default CustomerOrderServices;
