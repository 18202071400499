import React from "react";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";
import { SortOption, SORTORDEROPTIONS } from "../../../../utils/filterUtils";
import { CR_AGEOPTIONS, CR_SORTOPTIONS } from "../../../../utils/customerRequestUtils";

interface CustomerRequestFilterProps {
  filterBy: SelectOption;
  sortBy: SelectOption;
  sortOrder: SortOption;
  onChangeFilterBy: (filterBy: SelectOption) => void;
  onChangeSortBy: (sortBy: SelectOption) => void;
  onChangeSortOrder: (sortOrder: SortOption) => void;
}

const CustomerRequestFilter: React.FC<CustomerRequestFilterProps> = ({
  filterBy,
  sortBy,
  sortOrder,
  onChangeFilterBy,
  onChangeSortBy,
  onChangeSortOrder,
}) => {
  return (
    <div className="row mt-6">
      <div className="col-12 col-md-6 col-lg-3">
        <label className="fs-6 form-label fw-bolder text-dark">Age</label>
        <CustomSelect options={CR_AGEOPTIONS} onChange={onChangeFilterBy} value={filterBy} />
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <label className="fs-6 form-label fw-bolder text-dark">Sort by</label>
        <CustomSelect options={CR_SORTOPTIONS} onChange={onChangeSortBy} value={sortBy} />
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <label className="fs-6 form-label fw-bolder text-dark">Sort order</label>
        <CustomSelect options={SORTORDEROPTIONS} onChange={onChangeSortOrder} value={sortOrder} />
      </div>
    </div>
  );
};

export default CustomerRequestFilter;
