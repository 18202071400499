import React, { useContext } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DataContextAnonymous, DataContextCustomer } from "../../../context/dataContext";
import OrdersListing from "./OrdersListing";
import userService from "../../../services/userService";
import { ANONYMOUS, CUSTOMER } from "../../../utils/userUtils";

interface OrdersWrapperProps extends RouteComponentProps {
  orderType: "customer" | "sample";
}

const OrdersWrapper: React.FunctionComponent<OrdersWrapperProps> = (props) => {
  let context;
  if (userService.getUserType() === CUSTOMER) {
    context = useContext(DataContextCustomer);
  } else if (userService.getUserType() === ANONYMOUS) {
    context = useContext(DataContextAnonymous);
  }
  if (!context) return <></>;
  return <OrdersListing context={context} {...props} />;
};

export default withRouter(OrdersWrapper);
