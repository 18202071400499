import * as Realm from "realm-web";
import { Cookies } from "react-cookie";
import { BSON } from "realm-web";
import { callFunction, CHECKTOKENVALIDITY, DELETEUSER } from "./dbService";
import { UserData } from "../model/userData.types";

const application = process.env.REACT_APP_NAME;
const app: Realm.App = new Realm.App({ id: application ?? "" });
export const LOCAL_API_KEY_NAME = "localApiKeyName";
export const DEFAULT_SESSION_KEY_NAME =
  process.env.NODE_ENV !== "production" || process.env.REACT_APP_BASE_URL?.startsWith("demo")
    ? "sessionKeyDev"
    : "sessionKey";

async function login(email: string, password: string) {
  const credentials = Realm.Credentials.emailPassword(email, password);
  await app.logIn(credentials);
}

async function loginWithCredentials(credentials: Realm.Credentials<SimpleObject>) {
  if (app.currentUser?.isLoggedIn) await app.currentUser.logOut();
  await app.logIn(credentials);
}

function getUser() {
  return app.currentUser;
}

function isLoggedIn() {
  return app.currentUser?.isLoggedIn;
}

async function logout() {
  await app.currentUser?.logOut();
}

/**
 * Send password reset mail by calling a password reset function
 * @param email email of the user
 */
async function sendResetPasswordMail(email: string) {
  await app.emailPasswordAuth.callResetPasswordFunction(email, "12345678", [true]);
}

/**
 * Change password by calling the password reset function with the new password
 * @param email email of the user
 * @param password new password
 */
async function changePassword(email: string, password: string) {
  await app.emailPasswordAuth.callResetPasswordFunction(email, password, [false]);
}

/**
 * Check if a user exists and is confirmed
 * @param email
 * @returns true if user already confirmed, false if user does not exist or isn't confirmed
 */
async function userExistsAndConfirmed(email: string) {
  try {
    await app.emailPasswordAuth.resendConfirmationEmail(email);
    return false;
  } catch (e: any) {
    return e.errorCode === "UserAlreadyConfirmed";
  }
}

/**
 * Change password according to user input
 * @param token mongo identifier for user
 * @param tokenId mongo identifier for user
 * @param newPassword new Password set by user.
 */
async function resetPassword(token: string, tokenId: string, newPassword: string) {
  await app.emailPasswordAuth.resetPassword(token, tokenId, newPassword);
}

/**
 * Check if credentials are valid
 * @param email email of the user to check
 * @param password password of the user
 * @returns {Promise<AuthResponse>} result of authentication
 */
async function checkCredentials(email: string, password: string) {
  const credentials = Realm.Credentials.emailPassword(email, password);
  return await app.authenticator.authenticate(credentials);
}

/**
 * Create an api key for the logged in user
 * @param cookies a cookies object
 * @param key name of the api key and cookie
 * @returns {Promise<boolean>} true if api key was created and set as cookie, else false
 */
async function createAndSetApiKey(cookies: Cookies, key: string): Promise<boolean> {
  const keys = await app.currentUser?.apiKeys?.fetchAll();
  const oldApiKeyName = localStorage.getItem(LOCAL_API_KEY_NAME);

  if (keys && (oldApiKeyName || keys.length > 15)) {
    try {
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        // Remove old api key or oldest api key if many exist to avoid limit of 20
        if (key.name === oldApiKeyName || (keys.length > 15 && i < 1)) {
          await app.currentUser?.apiKeys.delete(key._id);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  const apiKeyName = key + new Realm.BSON.ObjectId().toString();
  const apiKey = await app.currentUser?.apiKeys.create(apiKeyName);
  if (apiKey) {
    localStorage.setItem(LOCAL_API_KEY_NAME, apiKeyName);
    cookies.set(key, apiKey.key, {
      secure: process.env.NODE_ENV === "production",
      path: "/",
      sameSite: process.env.NODE_ENV === "production" ? "none" : undefined,
      domain: process.env.REACT_APP_SESSION_COOKIE_DOMAIN || undefined,
    });
    return true;
  }
  return false;
}

/**
 * Remove an api key
 * @param cookies a cookies object
 * @param key name of the api key and cookie
 */
async function removeApiKey(cookies: Cookies, key: string) {
  const apiKeyName = localStorage.getItem(LOCAL_API_KEY_NAME);
  if (!apiKeyName) {
    console.info("No local api key found");
    return;
  }

  try {
    const keys = await app.currentUser?.apiKeys?.fetchAll();
    if (keys)
      for (let i = 0; i < keys.length; i++) {
        const aKey = keys[i];
        if (aKey.name === apiKeyName) await app.currentUser?.apiKeys.delete(aKey._id);
      }
  } catch (e) {
    console.error(e);
  } finally {
    cookies.remove(key);
  }
}

/**
 * Calls the setUserAndApiKeyValidity function in backend.
 * @param user User that should get app access
 * @param userAlreadyExists If set an already existing userData object is updated
 * @returns { Promise<{ result: boolean; message?: string }> } Result of the call
 */
export async function callSetUserAndApiKeyValidity(
  user: UserData,
  userAlreadyExists?: boolean
): Promise<{ result: boolean; message?: string }> {
  return callFunction("setUserAndApiKeyValidity", [user, userAlreadyExists]);
}

/**
 * Registers a new app user at the backend.
 * @param email Mail that the user is logging in with
 * @returns { { id: string, token: string: user: User<FunctionsFactoryType, CustomDataType> } | null } If successful the user information, null if not
 */
export async function registerUser(email: string) {
  const pw = new BSON.ObjectId().toString();
  const credentials = Realm.Credentials.emailPassword(email, pw);
  let user;
  try {
    // Register user
    await app.emailPasswordAuth.registerUser(email, pw);
    // Try to log in user
    user = await app.logIn(credentials);
    const apiKey = await user.apiKeys.create("firstLogin");
    await user.logOut();
    return { id: user.id, token: apiKey, user };
  } catch (err) {
    console.error("Failed to authenticate", err);
    return null;
  }
}

/**
 * Deletes an app user from the backend
 * @param userId ID of the user that should be deleted
 * @returns { Promise<boolean> } Result of the operation
 */
export async function deleteUser(userId: string): Promise<boolean> {
  return callFunction(DELETEUSER, [userId]);
}

/**
 * Call the check token validity function in backend.
 * @param mail Email whose token should be checked
 * @returns { Promise<boolean> } Indicating validity
 */
export async function callCheckTokenValidity(mail: string): Promise<boolean> {
  return callFunction(CHECKTOKENVALIDITY, [mail]);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  checkCredentials,
  login,
  loginWithCredentials,
  getUser,
  logout,
  resetPassword,
  sendResetPasswordMail,
  isLoggedIn,
  userExistsAndConfirmed,
  changePassword,
  createAndSetApiKey,
  removeApiKey,
};
