import { CONTRACT_TYPES, EXTENDED_CONTRACT_TYPES } from "./contractUtils";
import {
  EXTENDED_ORDER_TYPES,
  getOrderNumber,
  isCustomerOrder,
  isSampleOrder,
  isSupplierOrder,
  ORDER_TYPES,
} from "./orderUtils";
import { formatCurrency } from "./baseUtils";
import { getContractNumber, isAnyCustomerContract } from "./customerContractUtils";
import userService from "../services/userService";
import { INTERNAL } from "./userUtils";
import { formatArticleUnit } from "./productArticleUtils";

/**
 * Check if passed document is an order or contract
 * @param document any order or contract document
 * @returns { boolean } Returns true if the document is an order
 */
export function isAnyOrder(
  document: ORDER_TYPES | EXTENDED_ORDER_TYPES | CONTRACT_TYPES | EXTENDED_CONTRACT_TYPES
): document is ORDER_TYPES | EXTENDED_ORDER_TYPES {
  return "orderNo" in document;
}

/**
 * Check if passed document is an order or contract
 * @param document any order or contract document
 * @returns { boolean } Returns true if the document is a contract
 */
export function isAnyContract(
  document: ORDER_TYPES | EXTENDED_ORDER_TYPES | CONTRACT_TYPES | EXTENDED_CONTRACT_TYPES
): document is CONTRACT_TYPES | EXTENDED_CONTRACT_TYPES {
  return "contractNo" in document;
}

/**
 * Get amount for any order or contract
 * @param document any order or contract
 * @returns {string} formatted amount for order or contract
 */
export function getAmountForOrderOrContract(
  document: ORDER_TYPES | EXTENDED_ORDER_TYPES | CONTRACT_TYPES | EXTENDED_CONTRACT_TYPES
): string {
  if (isAnyOrder(document)) return `${document.amount}${formatArticleUnit(document.unit, document.commodity)}`;
  return `${document.contractInformation.totalAmount}${formatArticleUnit(document.commodity.unit)}`;
}

/**
 * Get total price for any order or contract
 * @param document any order or contract
 * @returns {string} formatted total price for order or contract
 */
export function getTotalPriceForOrderOrContract(
  document: ORDER_TYPES | EXTENDED_ORDER_TYPES | CONTRACT_TYPES | EXTENDED_CONTRACT_TYPES
): string {
  if (isAnyOrder(document)) return formatCurrency(document.totalPrice, document.currency);
  return formatCurrency(document.priceInformation.totalPrice, document.priceInformation.currency);
}
/**
 * Get total price for any order or contract
 * @param document any order or contract
 * @returns {string} formatted commodity price for order or contract
 */
export function getCommodityPriceForOrderOrContract(
  document: ORDER_TYPES | EXTENDED_ORDER_TYPES | CONTRACT_TYPES | EXTENDED_CONTRACT_TYPES
): string {
  if (isSampleOrder(document)) return formatCurrency(document.totalPrice, document.currency);
  if (isAnyOrder(document)) return formatCurrency(document.priceCommodities, document.currency);
  return formatCurrency(document.priceInformation.totalPrice, document.priceInformation.currency);
}

/**
 * Get total price for any order or contract
 * @param document any order or contract
 * @returns {string} identifier for order or contract
 */
export function getIdentifierForOrderOrContract(document: ORDER_TYPES | CONTRACT_TYPES): string {
  if (isAnyOrder(document)) return getOrderNumber(document);
  return getContractNumber(document);
}

/**
 * Get the link for any order or contract
 * @param document any order or contract
 * @returns {string} link for order or contract
 */
export const getLinkForOrderOrContract = (
  document: ORDER_TYPES | EXTENDED_ORDER_TYPES | CONTRACT_TYPES | EXTENDED_CONTRACT_TYPES
): string => {
  const view = userService.getUserType();
  if (isSupplierOrder(document) && view === INTERNAL) {
    return `/supplierOrder/${document._id.toString()}`;
  } else if (isCustomerOrder(document) && view === INTERNAL) {
    return `/customerOrder/${document._id.toString()}`;
  } else if (isAnyCustomerContract(document) && view === INTERNAL) {
    return `/customerContract/${document._id.toString()}`;
  } else if (isSampleOrder(document)) {
    return `/sampleOrder/${document._id.toString()}`;
  } else if (isAnyCustomerContract(document)) {
    return `/contract/${document._id.toString()}`;
  } else {
    return `/order/${document._id.toString()}`;
  }
};
