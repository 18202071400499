import { BSON } from "realm-web";
import { UserData, UserFile } from "../model/userData.types";
import { callFunction } from "../services/dbService";
import { getDocFromCollection } from "./baseUtils";

export interface UserUpdate extends Partial<UserData> {}

export interface SelectedSpecificationUserData extends UserData {
  signature?: UserFile;
  date: Date;
}

export interface UserSnapshot extends Pick<UserData, "_id" | "prename" | "surname" | "image"> {}

export const CUSTOMER = "customer";
export const INTERNAL = "internal";
export const SUPPLIER = "supplier";
export const ANONYMOUS = "anonymous";

// Roles
export const ADMIN = "Admin";
export const SCM = "SCM"; // Supply Chain Management
export const BOM = "BOM"; // Bord of Management

// notificationRoles
export enum NotificationRoles {
  QM = "QM",
  ACCOUNTING = "Accounting",
}

// Backend functions related to users
export const UPSERTUSERDATA = "upsertUserData";

// User file types
export const F_SIGNATURE = "signature";

export const REQUIRED_ROLES_CALCULATION_CONFIGURATION = ["SCM", "Admin"];
export const REQUIRED_ROLES_RESPONSIBLE_CONFIGURATION = ["Admin"];

/**
 * Updates the data of the referenced user with the given update via backend function.
 * @param user User that should be updated
 * @param update Update that should be performed
 * @returns { Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>> } Result of the update
 */
export async function updateUserData(
  user: UserData,
  update: UserUpdate
): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>> {
  return await callFunction<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>>(UPSERTUSERDATA, [
    { _id: new BSON.ObjectId(user._id.toString()), ...update },
    false,
  ]);
}

/**
 * Get a default userdata object
 * @param prename optional prename
 * @param surname optional surname
 * @param mail optional email address
 * @param companyId optional company id
 * @param type optional userdata type
 * @param phone optional phone number
 * @param position optional position
 * @returns {UserData} empty user
 */
export const getDefaultUser = (
  prename?: string,
  surname?: string,
  mail?: string,
  companyId?: BSON.ObjectId | string,
  type?: "supplier" | "customer" | "internal" | "forwarder",
  phone?: string,
  position?: string
): UserData => {
  return {
    _id: new BSON.ObjectId(),
    prename: prename || "",
    surname: surname || "",
    position: position || "",
    company: companyId ? companyId.toString() : "",
    type: type ? type : "customer",
    emails: mail ? [{ value: mail, description: "Primary email address" }] : [],
    phones: phone ? [{ value: phone, description: "Primary phone number" }] : [],
    roles: [],
    image: "",
    notifications: { language: "en", email: mail || "", settings: [] },
    onboardingDone: type === "internal",
    userId: "",
  } as UserData;
};

/**
 * Get the full name of a user
 * @param user userdata object
 * @returns {string} full name of the user
 */
export const getUserName = (user?: Pick<UserData, "prename" | "surname">): string => {
  return user ? `${user.prename} ${user.surname}` : "Unknown";
};

/**
 * Get the name for a user
 * @param id id of the user
 * @param user list of all users
 * @returns {string} Name of the user
 */
export const searchUserName = (id: string | BSON.ObjectId, user: Array<UserData>): string => {
  const userDoc = getDocFromCollection(user, id);
  if (userDoc) return `${userDoc.prename} ${userDoc.surname}`;
  return "Unknown User";
};
