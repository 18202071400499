import React, { PureComponent } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Input } from "../../common/Input";
import {
  I_CREDITNOTECUSTOMER,
  I_CUSTOMERINVOICE,
  I_STATE,
  I_SUPPLIERINVOICE,
  Invoice,
} from "../../../model/invoice.types";
import { formatCurrency, formatDate, getDocFromCollection, toAbsoluteUrl } from "../../../utils/baseUtils";
import { getDaysUntil } from "../../../utils/dateUtils";
import { resolveFilePath } from "../../../utils/fileUtils";
import { I_REMINDERDESCRIPTION } from "../../../utils/invoiceUtils";
import PayInvoiceModal from "./modals/PayInvoiceModal";
import { getOrderNumber } from "../../../utils/orderUtils";
import { isCommoditySnapshot } from "../../../utils/productArticleUtils";
import { DataContextInternalType } from "../../../context/dataContext";

interface InvoiceRowProps extends RouteComponentProps {
  onClickCheckbox?: (_id: string) => void;
  checked?: boolean;
  invoice: Invoice;
  compact?: boolean;
  isCreditNote?: boolean;
  context: DataContextInternalType;
}

interface InvoiceRowState {
  generating: boolean;
}

class InvoiceRow extends PureComponent<InvoiceRowProps, InvoiceRowState> {
  constructor(props: InvoiceRowProps) {
    super(props);
    this.state = { generating: false };
  }

  render() {
    const { invoice, isCreditNote, checked, compact, onClickCheckbox, history, context } = this.props;
    const due = new Date(invoice.invoiceDate);
    due.setDate(due.getDate() + invoice.paymentTarget);
    const untilPayment = Math.ceil(getDaysUntil(due));
    const inAdvance = invoice.paymentTarget === -1;

    const dueDate = new Date(invoice.invoiceDate);
    dueDate.setDate(dueDate.getDate() + invoice.paymentTarget);
    const cantRemind = invoice.reminders.length >= 3 || inAdvance || getDaysUntil(dueDate) > 0;
    const relatedOrder = invoice.relatedOrder
      ? invoice.type === I_SUPPLIERINVOICE
        ? getDocFromCollection(context.supplierOrder, invoice.relatedOrder)
        : [I_CUSTOMERINVOICE, I_CREDITNOTECUSTOMER].includes(invoice.type)
        ? getDocFromCollection(context.customerOrder, invoice.relatedOrder)
        : getDocFromCollection(context.sampleOrder, invoice.relatedOrder)
      : undefined;

    return (
      <>
        <tr>
          {checked !== undefined && onClickCheckbox && (
            <td className="align-middle">
              <div className="form-check form-check-sm form-check-custom form-check-solid">
                <Input
                  type="checkbox"
                  className="form-check-input"
                  checked={checked}
                  onClick={() => onClickCheckbox(invoice._id.toString())}
                />
              </div>
            </td>
          )}
          <td className="text-light align-middle">
            {isCreditNote ? "C" : `INV-${inAdvance ? "A" : ""}`}
            {invoice.invoiceNumber}
          </td>
          <td className="text-light align-middle">{formatCurrency(invoice.total, invoice.currency)}</td>
          {!compact && (
            <td className="text-light align-middle">
              <Link className="custom-link text-white" to={"/customer/" + invoice.company._id.toString()}>
                <span className="d-inline-block text-ellipsis" style={{ maxWidth: "165px" }}>
                  {invoice.company.name}
                </span>
              </Link>
            </td>
          )}
          <td className="text-light align-middle">
            {relatedOrder && (
              <>
                <Link className="custom-link text-white" to={"/customerOrder/" + relatedOrder._id.toString()}>
                  {getOrderNumber(relatedOrder)}
                </Link>
                <br />
                <Link
                  className="custom-link text-white"
                  to={
                    `/${isCommoditySnapshot(relatedOrder.commodity) ? "commodity" : "finishedProduct"}/` +
                    relatedOrder.commodity._id.toString()
                  }
                >
                  <span className="fs-8 d-inline-block text-muted text-ellipsis" style={{ maxWidth: "100px" }}>
                    {relatedOrder?.commodity.title.en}
                  </span>
                </Link>
              </>
            )}
          </td>
          {!compact && <td className="text-muted align-middle">{formatDate(invoice.invoiceDate)}</td>}
          {!compact && !isCreditNote && (
            <td className="text-muted align-middle">{inAdvance ? "In Advance" : invoice.paymentTarget + " days"}</td>
          )}
          {!isCreditNote && (
            <td
              className={
                "align-middle " +
                (inAdvance || [I_STATE.PAID, I_STATE.CANCELED].includes(invoice.state)
                  ? "text-muted "
                  : untilPayment > 0
                  ? "text-success"
                  : "text-danger")
              }
            >
              {inAdvance || [I_STATE.PAID, I_STATE.CANCELED].includes(invoice.state) ? "-" : untilPayment + " day(s)"}
            </td>
          )}
          <td className="align-middle">
            <a href={resolveFilePath(invoice.file)} target="_blank" rel="noopener noreferrer">
              <img src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} style={{ height: 24 }} alt="PDF" />
            </a>
          </td>
          {!isCreditNote && (
            <td className="text-right align-middle">
              {invoice.state === I_STATE.CANCELED ? (
                <label className="text-white">
                  <i className="fa fa-times text-danger pr-1" />
                  Canceled
                </label>
              ) : invoice.state === I_STATE.PAID ? (
                <label className="text-white">
                  <i className="fa fa-check text-success pr-1" />
                  Paid
                </label>
              ) : (
                <Dropdown>
                  <Dropdown.Toggle className="btn btn-sm btn-light btn-active-light">Action</Dropdown.Toggle>
                  <Dropdown.Menu className="custom-dropdown">
                    <Dropdown.Item
                      className={"py-2 " + (cantRemind ? "text-muted disabled" : "text-white")}
                      onClick={cantRemind ? undefined : () => history.push("/createReminder/" + invoice._id.toString())}
                      disabled={cantRemind}
                    >
                      Remind
                    </Dropdown.Item>
                    <Dropdown.Item className="text-white py-2">
                      <PayInvoiceModal invoice={invoice} noButton={true} context={context} />
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="text-white py-2"
                      onClick={() => history.push("/createCancelation/" + invoice._id.toString())}
                    >
                      Cancel
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </td>
          )}
        </tr>
        {!isCreditNote && (
          <>
            {invoice.reminders.map((r) => {
              const due = new Date(r.date);
              due.setDate(due.getDate() + 14);
              const remaining = Math.ceil(getDaysUntil(due));
              return (
                <tr key={r._id.toString()}>
                  <td />
                  {!compact && <td />}
                  <td className="text-light align-middle">
                    {r.dunningFee ? formatCurrency(r.dunningFee, invoice.currency) : ""}
                  </td>
                  <td className="text-warning align-middle">
                    {I_REMINDERDESCRIPTION.find((rd) => rd.value === r.type)?.label}
                  </td>
                  {!compact && <td />}
                  {!compact && <td className="text-muted align-middle">{formatDate(r.date)}</td>}
                  {!compact && <td className="text-muted align-middle">14 days</td>}
                  <td className={"align-middle " + (remaining >= 0 ? "text-success" : "text-danger")}>
                    {remaining} day(s)
                  </td>
                  <td className="align-middle">
                    <a href={resolveFilePath(r.reminderFile)} target="_blank" rel="noopener noreferrer">
                      <img src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} style={{ height: 24 }} alt="PDF" />
                    </a>
                  </td>
                  <td />
                </tr>
              );
            })}
            {invoice.payments.map((p) => (
              <tr key={p._id.toString()}>
                <td />
                {!compact && <td />}
                <td className="text-light align-middle">{formatCurrency(p.amount, invoice.currency)}</td>
                <td className="text-white align-middle">Payment</td>
                {!compact && <td />}
                {!compact && <td className="text-muted align-middle">{formatDate(p.date)}</td>}
                <td colSpan={compact ? 1 : 2} />
                <td>
                  <img
                    src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                    style={{ height: 24 }}
                    className="image-gray"
                    alt="PDF"
                  />
                </td>
                <td />
              </tr>
            ))}
          </>
        )}
        {invoice.cancelation && (
          <tr key={invoice.cancelation._id.toString()}>
            <td />
            {!compact && <td />}
            <td />
            <td className="text-danger align-middle">Cancelation</td>
            {!compact && <td />}
            {!compact && <td className="text-muted align-middle">{formatDate(invoice.cancelation.date)}</td>}
            <td colSpan={compact ? 1 : 2} />
            <td className="align-middle">
              <a href={resolveFilePath(invoice.cancelation.cancelationFile)} target="_blank" rel="noopener noreferrer">
                <img src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} style={{ height: 24 }} alt="PDF" />
              </a>
            </td>
            <td />
          </tr>
        )}
      </>
    );
  }
}

export default withRouter(InvoiceRow);
