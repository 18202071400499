import React, { PureComponent } from "react";
import { D_MASTERSPECIFICATION } from "../../../utils/commodityUtils";
import { formatDate, toAbsoluteUrl } from "../../../utils/baseUtils";
import { resolveFilePath } from "../../../utils/fileUtils";
import { DataContextInternalType } from "../../../context/dataContext";
import UploadSignedSpecificationModal from "./modals/UploadSignedSpecificationModal";
import CreateMasterSpecificationModal from "./modals/CreateMasterSpecificationModal";
import { COMMODITY } from "../../../services/dbService";
import { MasterSpecificationType } from "../common/CustomTypes";

interface MasterSpecificationProps {
  article: MasterSpecificationType;
  context: DataContextInternalType;
}

class MasterSpecification extends PureComponent<MasterSpecificationProps> {
  render() {
    const { article, context } = this.props;
    let masterSpec;
    if (article.type === COMMODITY) {
      masterSpec = article.commodity.documents.find((d) => d.type === D_MASTERSPECIFICATION);
    } else {
      masterSpec = article.finishedProduct.documents.find((d) => d.type === D_MASTERSPECIFICATION);
    }

    return (
      <div className="py-0">
        <div className="d-flex py-6 align-items-center w-100">
          <div className="me-3">
            <div className="text-white fs-5 fw-bolder">Master Specification</div>
            <div className="text-muted">Specification determining target values</div>
          </div>
        </div>
        <div className="table-responsive mt-5 pt-2 bg-light2">
          <table className="table align-middle gs-0 gy-1 ">
            <thead className="border-bottom-dark-gray">
              <tr className="fw-bolder text-muted">
                <th>File</th>
                <th>Version</th>
                <th>Created</th>
                <th>Status</th>
                <th className="text-right">Action</th>
              </tr>
            </thead>
            <tbody>
              {masterSpec ? (
                <tr>
                  <td className="align-middle">
                    <a href={resolveFilePath(masterSpec.path)} target="_blank" rel="noopener noreferrer">
                      <img alt="pdf" className="w-30px mr-2" src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} />
                      <span className="text-white fw-bold align-middle">
                        {masterSpec.name}
                        {masterSpec.size && (
                          <small className="text-muted ml-2">{Math.round(masterSpec.size / 1024)} KB</small>
                        )}
                      </span>
                    </a>
                  </td>
                  <td className="align-middle">
                    <span className="text-white">{masterSpec && masterSpec.version ? masterSpec.version : "N/A"}</span>
                  </td>
                  <td className="align-middle">
                    <span className="text-white">{masterSpec.date ? formatDate(masterSpec.date) : "-"}</span>
                  </td>
                  <td className="align-middle">
                    {masterSpec.signedBy ? (
                      <span className={"text-success"}>Signed</span>
                    ) : (
                      <UploadSignedSpecificationModal article={article} specification={masterSpec} />
                    )}
                  </td>
                  <td className="align-middle text-right">
                    <CreateMasterSpecificationModal
                      article={article}
                      masterSpecification={masterSpec}
                      buttonClasses="fs-7 btn btn-text text-warning pr-0"
                      buttonText="Create New"
                      context={context}
                    />
                  </td>
                </tr>
              ) : (
                <tr>
                  <td className="align-middle text-center py-5 cursor-default" colSpan={10}>
                    <div className="text-white fw-bolder text-muted">No master specification available yet</div>
                    <CreateMasterSpecificationModal article={article} context={context} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default MasterSpecification;
