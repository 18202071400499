import _ from "lodash";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import { Input } from "../../../common/Input";
import { VersionHistory, VersionHistoryType } from "../../../../model/versionHistory.types";
import DateInput from "../../../common/DateInput";
import { Textarea } from "../../../common/Textarea";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";
import { insertVersionHistory, TypeOptions, updateVersionHistory } from "../../../../utils/versionHistoryUtils";

interface AddNewVersionModalProps {
  versionHistory?: VersionHistory;
}

interface AddNewVersionModalState {
  versionHistoryInput: VersionHistory;
  saving: boolean;
  show: boolean;
}

class AddNewVersionModal extends PureComponent<AddNewVersionModalProps, AddNewVersionModalState> {
  constructor(props: AddNewVersionModalProps) {
    super(props);
    this.state = this.getDefaultState(false);
  }

  handleShow = () => this.setState(this.getDefaultState(true));
  handleHide = () => this.setState({ show: false });

  handleResetData = () => {
    const versionHistoryInput = _.cloneDeep(this.props.versionHistory);
    if (versionHistoryInput) this.setState({ versionHistoryInput });
  };

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const versionHistoryInput = _.cloneDeep(this.state.versionHistoryInput);
    if (e.target.name === "versionNumber") versionHistoryInput.version = e.target.value;
    this.setState({ versionHistoryInput });
  };

  handleBlurDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const versionHistoryInput = _.cloneDeep(this.state.versionHistoryInput);
    const value = e.target.value;
    versionHistoryInput.date = new Date(value);
    this.setState({ versionHistoryInput });
  };

  handleChangeType = (e: SelectOption, idx: number) => {
    const versionHistoryInput = _.cloneDeep(this.state.versionHistoryInput);
    versionHistoryInput.content[idx].type = e.value as VersionHistoryType;
    this.setState({ versionHistoryInput });
  };

  handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>, idx: number) => {
    const versionHistoryInput = _.cloneDeep(this.state.versionHistoryInput);
    // @ts-ignore
    versionHistoryInput.content[idx][e.target.name] = e.target.value;
    this.setState({ versionHistoryInput });
  };

  handleToggleVisibility = (type: "customer" | "supplier", idx: number) => {
    const versionHistoryInput = _.cloneDeep(this.state.versionHistoryInput);
    if (type === "customer") {
      versionHistoryInput.content[idx].forCustomersVisible = !versionHistoryInput.content[idx].forCustomersVisible;
    } else if (type === "supplier") {
      versionHistoryInput.content[idx].forSuppliersVisible = !versionHistoryInput.content[idx].forSuppliersVisible;
    }
    this.setState({ versionHistoryInput });
  };

  handleRemoveContentEntry = (idx: number) => {
    const versionHistoryInput = _.cloneDeep(this.state.versionHistoryInput);
    versionHistoryInput.content.splice(idx, 1);
    this.setState({ versionHistoryInput });
  };

  handleAddContentEntry = () => {
    const versionHistoryInput = _.cloneDeep(this.state.versionHistoryInput);
    versionHistoryInput.content.push({
      type:
        versionHistoryInput.content.length > 0
          ? versionHistoryInput.content[versionHistoryInput.content.length - 1].type
          : VersionHistoryType.FEATURE,
      content: "",
      reason: "",
      forSuppliersVisible: true,
      forCustomersVisible: true,
    });
    this.setState({ versionHistoryInput });
  };

  handleCreateVersionHistory = async () => {
    const { versionHistoryInput } = this.state;
    this.setState({ saving: true });
    try {
      if (this.props.versionHistory) {
        const res = await updateVersionHistory(versionHistoryInput);
        if (res && res.modifiedCount > 0) {
          toast.success("Version history successfully updated");
        } else {
          toast.error("Error updating version history");
        }
      } else {
        const res = await insertVersionHistory(versionHistoryInput);
        if (res && res.insertedId) {
          toast.success("Version history successfully inserted");
        } else {
          toast.error("Error inserting version history");
        }
      }
    } catch (e) {
      console.error("ERROR UPSERTING VERSION HISTORY", e);
    } finally {
      this.setState(this.getDefaultState(false));
    }
  };

  getDefaultState = (show: boolean) => {
    const versionHistoryInput = _.cloneDeep(this.props.versionHistory) ?? {
      _id: new BSON.ObjectId(),
      version: "",
      date: new Date(),
      content: [],
    };
    return { show, saving: false, versionHistoryInput };
  };

  validateData = () => {
    const { versionHistoryInput } = this.state;
    const errors = [];
    if (!versionHistoryInput.version) errors.push("Version number has to be provided");
    else if (!/^[0-9]*\.[0-9]*\.[0-9]*$/.test(versionHistoryInput.version))
      errors.push("Version has to be provided in the following schema: X.Y.Z");
    if (!versionHistoryInput.date) errors.push("Date of the version has to be provided");
    return errors;
  };

  render() {
    const { versionHistory } = this.props;
    const { versionHistoryInput, saving, show } = this.state;
    const errors = this.validateData();
    return (
      <>
        <button className="btn btn-sm btn-outline btn-outline-light" onClick={this.handleShow}>
          {versionHistory ? "Edit" : "Add Version Notes"}
        </button>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered size="xl">
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">
                {versionHistory ? "Edit" : "Add"} version notes
              </h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body className="overflow-auto" style={{ maxHeight: "80vh" }}>
            <div className="row mb-3">
              <div className="col-md-6 fv-row fv-plugins-icon-container mt-5">
                <label className="required fs-5 fw-bold mb-2">Version Number</label>
                <Input
                  type="text"
                  className="form-control custom-form-control"
                  name="versionNumber"
                  value={versionHistoryInput.version}
                  disabled={!!versionHistory}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="col-md-6 fv-row fv-plugins-icon-container mt-5">
                <label className="required fs-5 fw-bold mb-2">Date</label>
                <DateInput
                  classes="form-control custom-form-control"
                  value={versionHistoryInput.date}
                  onBlur={this.handleBlurDate}
                  name="versionDate"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-3 text-white">Type</div>
              <div className="col-3 text-white">Content</div>
              <div className="col-3 text-white">Reason</div>
              <div className="col-2 text-white">Visibility</div>
              <div className="col-1" />
              {versionHistoryInput.content.map((f, idx) => (
                <React.Fragment key={idx}>
                  <div className="col-3 my-4">
                    <CustomSelect
                      options={TypeOptions}
                      onChange={(e: SelectOption) => this.handleChangeType(e, idx)}
                      value={TypeOptions.find((t) => t.value === f.type) ?? TypeOptions[0]}
                    />
                  </div>
                  <div className="col-3 my-4">
                    <Textarea
                      className="form-control custom-form-control"
                      name="content"
                      value={f.content}
                      onChange={(e) => this.handleTextChange(e, idx)}
                    />
                  </div>
                  <div className="col-3 my-4">
                    <Textarea
                      className="form-control custom-form-control"
                      name="reason"
                      value={f.reason}
                      onChange={(e) => this.handleTextChange(e, idx)}
                    />
                  </div>
                  <div className="col-2 my-4">
                    <label className="form-check form-check-sm form-check-custom form-check-solid">
                      <input
                        className="form-check-input mr-5"
                        name="customer"
                        type="checkbox"
                        checked={f.forCustomersVisible}
                        onChange={() => this.handleToggleVisibility("customer", idx)}
                      />
                      <span className="ml-5 form-check-label text-white">Customer</span>
                    </label>
                    <label className="mt-5 form-check form-check-sm form-check-custom form-check-solid">
                      <input
                        className="form-check-input mr-5"
                        name="supplier"
                        type="checkbox"
                        checked={f.forSuppliersVisible}
                        onChange={() => this.handleToggleVisibility("supplier", idx)}
                      />
                      <span className="ml-5 form-check-label text-white">Supplier</span>
                    </label>
                  </div>
                  <div className="col-1 my-4">
                    <button className="btn btn-sm float-right" onClick={() => this.handleRemoveContentEntry(idx)}>
                      <i className="fa fa-trash text-danger p-0" />
                    </button>
                  </div>
                </React.Fragment>
              ))}
              <div className="col-12">
                <button className="btn btn-sm float-right" onClick={() => this.handleAddContentEntry()}>
                  <i className="fa fa-plus text-success p-0" />
                </button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {versionHistory && (
              <button className="btn btn-sm btn-outline btn-text-secondary" onClick={this.handleResetData}>
                Reset
              </button>
            )}
            <button className="btn btn-sm btn-outline btn-text-danger" onClick={this.handleHide}>
              Cancel
            </button>
            <ErrorOverlayButton
              errors={errors}
              className="btn btn-sm btn-outline btn-outline-light"
              saving={saving}
              buttonText={saving ? "Saving..." : versionHistory ? "Confirm" : "Add"}
              onClick={this.handleCreateVersionHistory}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default AddNewVersionModal;
