import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface ErrorOverlayButtonProps {
  errors?: Array<string>;
  warnings?: Array<string>;
  saving?: boolean;
  disabled?: boolean;
  className: string;
  buttonText?: string | JSX.Element;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  children?: React.ReactNode;
}

const ErrorOverlayButton: React.FunctionComponent<ErrorOverlayButtonProps> = ({
  errors,
  warnings,
  saving,
  disabled,
  className,
  buttonText,
  children,
  onClick,
}) => {
  const hasErrors = errors && errors.length > 0;
  return (
    <OverlayTrigger
      show={(!hasErrors && (!warnings || warnings.length === 0)) || saving || disabled ? false : undefined}
      overlay={
        <Tooltip className={hasErrors ? "custom-tooltip-danger" : "custom-tooltip-warning"} id="errorDescription">
          <ul className="m-0 p-0">
            {hasErrors
              ? errors.map((e, key) => {
                  return (
                    <ul className="text-left pl-0" key={key}>
                      <span className="text-white">
                        <b>{e}</b>
                      </span>
                      <br />
                    </ul>
                  );
                })
              : warnings && warnings.length > 0
              ? warnings.map((e, key) => {
                  return (
                    <ul className="text-left pl-0" key={key}>
                      <span className="text-black">
                        <b>{e}</b>
                      </span>
                      <br />
                    </ul>
                  );
                })
              : ""}
          </ul>
        </Tooltip>
      }
    >
      <button
        type="button"
        className={className + (hasErrors || saving || disabled ? " disabled" : "")}
        onClick={hasErrors || saving || disabled ? undefined : onClick}
      >
        {children ?? buttonText}
      </button>
    </OverlayTrigger>
  );
};

export default ErrorOverlayButton;
