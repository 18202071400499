import { BSON } from "realm-web";
import { callFunction } from "../services/dbService";
import {
  CustomerRequest,
  CustomerRequestState,
  CustomerRequestTimelineTypes,
  CustomerRequestType,
  RequestTimelineEntry,
} from "../model/customerRequest.types";
import userService from "../services/userService";
import { ContractPeriods } from "./customerContractUtils";

export const CR_TYPEOPTIONS = [
  { value: CustomerRequestType.STANDARDREQUEST, label: "Order Requests" },
  { value: CustomerRequestType.CONTRACTREQUEST, label: "Contract Requests" },
];

export const CR_STATEOPTIONS = [
  { value: CustomerRequestState.OPEN, label: "Requested" },
  { value: CustomerRequestState.CLOSED, label: "Processed" },
  { value: CustomerRequestState.REJECTED, label: "Rejected" },
  { value: CustomerRequestState.CANCELED, label: "Canceled" },
];

export enum CustomerRequestAge {
  ALL = "all",
  LESS_THAN_48H = "lessThan48h",
  LESS_THAN_1_WEEK = "lessThan1Week",
  OLD = "old",
}

export const CR_AGEOPTIONS = [
  { value: CustomerRequestAge.ALL, label: "All" },
  { value: CustomerRequestAge.LESS_THAN_48H, label: "Less than 48 Hours" },
  { value: CustomerRequestAge.LESS_THAN_1_WEEK, label: "Less than 1 Week" },
  { value: CustomerRequestAge.OLD, label: "Old Requests" },
];

export enum CustomerRequestSort {
  REQUEST_NO = "requestNo",
  REQUESTED_ON = "requestedOn",
  STATE = "state",
  AMOUNT = "amount",
  COMMODITY = "commodity.title.en",
}

export const CR_SORTOPTIONS = [
  { value: CustomerRequestSort.REQUEST_NO, label: "Request Number" },
  { value: CustomerRequestSort.REQUESTED_ON, label: "Time Active" },
  { value: CustomerRequestSort.STATE, label: "Status" },
  { value: CustomerRequestSort.AMOUNT, label: "Volume" },
  { value: CustomerRequestSort.COMMODITY, label: "Article" },
];

export const CR_STATEDESCRIPTION = {
  [CustomerRequestState.OPEN]: "Requested",
  [CustomerRequestState.CLOSED]: "Processed",
  [CustomerRequestState.REJECTED]: "Rejected",
  [CustomerRequestState.CANCELED]: "Canceled",
};

// Functions
const UPSERT_CUSTOMER_REQUEST = "upsertCustomerRequest";

/**
 * Inserts the customer request
 * @param customerRequest new customer request document
 * @returns { Promise<{ res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>, requestNumber: string } | false> } Result of the insert
 */
export async function insertCustomerRequest(
  customerRequest: Partial<CustomerRequest>
): Promise<{ res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; requestNumber: string } | false> {
  return (await upsertCustomerRequest(customerRequest, true)) as {
    res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>;
    requestNumber: string;
  };
}
/**
 * Updates the customer request
 * @param customerRequest (partial) customer request document
 * @param customerRequestId ID of the customer request
 * @param updateTimelineEntry optional, timeline entry in case of an update
 * @returns { Promise<{ res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>, requestNumber: string } | false> } Result of the update
 */
export async function updateCustomerRequest(
  customerRequest: Partial<CustomerRequest>,
  customerRequestId?: BSON.ObjectId,
  updateTimelineEntry?: object
): Promise<{ res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>; requestNumber: string } | false> {
  if (customerRequestId) customerRequest._id = customerRequestId;
  return (await upsertCustomerRequest(customerRequest, false, updateTimelineEntry)) as {
    res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>;
    requestNumber: string;
  };
}

/**
 * Insert or update a customer request
 * @param customerRequest (partial) customer request document
 * @param insert flag indicating if it is an insert or update
 * @param updateTimelineEntry optional, timeline entry in case of an update
 * @returns { Promise<{ res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>, requestNumber: string } | false> } Result of the upsert
 */
async function upsertCustomerRequest(
  customerRequest: Partial<CustomerRequest>,
  insert: boolean,
  updateTimelineEntry?: object
): Promise<
  | {
      res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>;
      requestNumber: string;
    }
  | false
> {
  return callFunction(UPSERT_CUSTOMER_REQUEST, [customerRequest, insert, updateTimelineEntry]);
}

/**
 * Generates a timeline entry for a customer request
 * @param type Type of the entry
 * @param payload Optional payload of the entry
 * @returns { RequestTimelineEntry } Timeline entry object
 */
export function getCustomerRequestTimelineEntry(
  type: CustomerRequestTimelineTypes,
  payload?: object
): RequestTimelineEntry {
  return {
    _id: new BSON.ObjectId(),
    date: new Date(),
    type,
    person: userService.getUserId(),
    payload: payload ?? null,
  };
}

/**
 * Get the validity period for a given period and start date
 * @param startDate the start date
 * @param period the period from a request
 * @returns {{start: Date, end: Date}} validity period with start and end date
 */
export function getValidityPeriodFromRequest(startDate: Date, period: ContractPeriods): { start: Date; end: Date } {
  let months = 0;
  switch (period) {
    case ContractPeriods.OneMonth:
      months = 1;
      break;
    case ContractPeriods.TwoMonths:
      months = 2;
      break;
    case ContractPeriods.SixMonths:
      months = 6;
      break;
    case ContractPeriods.OneYear:
      months = 12;
      break;
    case ContractPeriods.TwoYears:
      months = 24;
      break;
  }
  return { start: startDate, end: new Date(new Date(startDate).setMonth(startDate.getMonth() + months)) };
}

/**
 * Retrieve the request number of the customer request in a well formatted string.
 * @param request Customer Request whose request number should be retrieved
 * @returns {string} Well formatted request number
 */
export function getRequestNumber(request: CustomerRequest): string {
  return `REQ-${request.requestNo}`;
}
