import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContextInternal } from "../../../context/dataContext";
import CustomerPage from "./CustomerPage";

interface CustomerWrapperProps extends RouteComponentProps<CustomerParams> {}

interface CustomerParams {
  id: string;
}

const CustomerWrapper: React.FunctionComponent<CustomerWrapperProps> = (props) => {
  const context = useContext(DataContextInternal);
  return <CustomerPage context={context} {...props} />;
};
export default CustomerWrapper;
