import _ from "lodash";
import { Accordion, CloseButton, Modal, ProgressBar } from "react-bootstrap";
import React, { PureComponent, useState } from "react";
import { Link } from "react-router-dom";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import { SupplierPricesExtended } from "../../../../model/commodity.types";
import {
  getBestPrice,
  getArticlePricesCount,
  getCommoditySupplierDiff,
  getCommodityTimelineEntry,
  getDefaultPrice,
  getMinOQ,
  getPriceRange,
  getSupplierPriceRange,
  T_PRICESUPDATED,
  T_PRICEUPDATED,
  UPDATECOMMODITYSUPPLIERPRICES,
  transformSuppliersForDiff,
} from "../../../../utils/commodityUtils";
import Search from "../../../common/Search";
import { Supplier, SupplierExtended } from "../../../../model/supplier.types";
import { DataContextInternalType } from "../../../../context/dataContext";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";
import { doFuseSearch } from "../../../../utils/baseUtils";
import { callFunction, transaction } from "../../../../services/dbService";
import { CustomToggle } from "../../../common/CustomToggle";
import CommodityPriceTable from "../../../common/CommodityPriceTable";
import ContactPersonWidget from "../../../common/ContactPersonWidget";
import CommoditySupplierSettingsModal from "../../../common/CommoditySupplierSettingsModal";
import { Currencies, EURO } from "../../../../utils/currencyUtils";
import CommodityEUStock from "../CommodityEUStock";
import SupplierEUWidget from "../../../common/SupplierEUWidget";
import { CommoditySupplierSettingsUpdate } from "../../../common/CustomTypes";
import { getCommoditySupplierSettingsUpdateActions } from "../../../../utils/commoditySupplierUtils";
import { SupplierPricesFinishedProductExtended } from "../../../../model/finishedProduct.types";
import { getFinishedProductTimelineEntry, isFinishedProduct } from "../../../../utils/finishedProductUtils";
import { formatArticleUnit, InternalArticleExtended } from "../../../../utils/productArticleUtils";
import ArticleGraduation from "../ArticleGraduation";
import { extendSupplier } from "../../../../utils/dataTransformationUtils";

interface CommodityPagePricesProps {
  article: InternalArticleExtended;
  context: DataContextInternalType;
}

interface CommodityPagePricesState {
  search: string;
  suppliers: Array<SupplierPricesExtended | SupplierPricesFinishedProductExtended>;
}

class CommodityPagePrices extends PureComponent<CommodityPagePricesProps, CommodityPagePricesState> {
  constructor(props: CommodityPagePricesProps) {
    super(props);
    this.state = { search: "", suppliers: _.cloneDeep(props.article.suppliers) };
  }

  componentDidUpdate = (prevProps: Readonly<CommodityPagePricesProps>) => {
    if (!_.isEqual(prevProps.article.suppliers, this.props.article.suppliers)) {
      this.setState({ suppliers: _.cloneDeep(this.props.article.suppliers) });
    }
  };

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });

  handleNewPrice = (entryId: BSON.ObjectId | string) => {
    const suppliers = _.cloneDeep(this.state.suppliers);
    const entry = suppliers.find((s) => s._id.toString() === entryId.toString());
    if (entry)
      entry.prices.push(
        getDefaultPrice(
          entry.prices[entry.prices.length - 1],
          entry.leadTime || entry.supplier.transport.preparationTime,
          entry.supplier.euSupplier ? EURO : undefined
        )
      );
    this.setState({ suppliers });
  };

  handleRemovePrice = (entryId: BSON.ObjectId | string, priceId: BSON.ObjectId | string) => {
    const suppliers = _.cloneDeep(this.state.suppliers);
    const entry = suppliers.find((s) => s._id.toString() === entryId.toString());
    if (entry) entry.prices = entry.prices.filter((p) => p._id.toString() !== priceId.toString());
    this.setState({ suppliers });
  };

  handleUpdatePrice = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    entryId: BSON.ObjectId | string,
    priceId: BSON.ObjectId | string
  ) => {
    const suppliers = _.cloneDeep(this.state.suppliers);
    const entry = suppliers.find((s) => s._id.toString() === entryId.toString());
    if (!entry) return;
    const price = entry.prices.find((p) => p._id.toString() === priceId.toString());
    if (price) {
      _.set(price, e.target.name, e.target.type === "number" ? +e.target.value : e.target.value);
      price.date = new Date();
    }
    this.setState({ suppliers });
  };

  handleDisableSupplier = (entryId: BSON.ObjectId | string) => {
    const suppliers = _.cloneDeep(this.state.suppliers);
    const entry = suppliers.find((s) => s._id.toString() === entryId.toString());
    if (!entry) return;
    entry.disabled = !entry.disabled;
    this.setState({ suppliers }, () => this.handleSaveSupplier(entryId));
  };

  handleRenewPrices = (entryId: BSON.ObjectId | string, date: Date, priceId?: BSON.ObjectId | string) => {
    const suppliers = _.cloneDeep(this.state.suppliers);
    const entry = suppliers.find((s) => s._id.toString() === entryId.toString());
    if (!entry) return;
    if (priceId) {
      const price = entry.prices.find((p) => p._id.toString() === priceId.toString());
      if (!price) return;
      const val = new Date(date);
      if (isNaN(val.getTime())) return;
      _.set(price, "validUntil", val);
      price.date = new Date();
    } else {
      for (let i = 0; i < entry.prices.length; i++) {
        const val = new Date(date);
        if (isNaN(val.getTime())) return;
        _.set(entry.prices[i], "validUntil", val);
        entry.prices[i].date = new Date();
      }
    }
    this.setState({ suppliers }, () => this.handleSaveSupplier(entryId));
  };

  handleSetLeadTime = (entryId: BSON.ObjectId | string, value: string) => {
    const suppliers = _.cloneDeep(this.state.suppliers);
    const entry = suppliers.find((s) => s._id.toString() === entryId.toString());
    if (!entry) return;
    const val = parseFloat(value);
    entry.leadTime = val; // Set lead time globally for offered commodity
    entry.prices = entry.prices.map((price) => ({ ...price, leadTime: val })); // Overwrite individual lead times if global lead time is set
    this.setState({ suppliers }, () => this.handleSaveSupplier(entryId));
  };

  handleAddSupplier = (supplier: Supplier) => {
    const suppliers = _.cloneDeep(this.state.suppliers);
    suppliers.push({
      _id: new BSON.ObjectId(),
      supplier: extendSupplier(supplier, this.props.context),
      contingent: Infinity,
      prices: [],
    });
    this.setState({ suppliers });
  };

  handleSaveSettings = async (
    commodityId: BSON.ObjectId | string,
    supplierId: BSON.ObjectId | string,
    settings: CommoditySupplierSettingsUpdate,
    finishedProduct: boolean
  ) => {
    const actions = getCommoditySupplierSettingsUpdateActions(commodityId, supplierId, settings, finishedProduct);
    if (actions.length > 0) {
      const res = await transaction(actions);
      if (res) {
        toast.success("Update successful");
      } else {
        toast.error("Data couldn't be updated");
      }
    }
  };

  handleSaveSupplier = async (entryId: BSON.ObjectId | string) => {
    const { article } = this.props;
    const { suppliers } = this.state;
    const finishedProduct = isFinishedProduct(article);
    const supplier = suppliers.find((s) => s._id.toString() === entryId.toString());
    const oldSupplier: SupplierPricesExtended | SupplierPricesFinishedProductExtended | undefined =
      article.suppliers.find((s) => s._id.toString() === entryId.toString());
    if (!supplier) return;
    const timelineEntry = finishedProduct
      ? getFinishedProductTimelineEntry(T_PRICESUPDATED, undefined, supplier.supplier._id.toString())
      : getCommodityTimelineEntry(T_PRICEUPDATED, undefined, supplier.supplier._id.toString());
    supplier.prices.sort((p1, p2) => p1.minOQ - p2.minOQ);
    // Add diff to timeline entry
    if (oldSupplier) {
      // Changed
      const [differentSuppliersPre, differentSuppliersPost] = getCommoditySupplierDiff(
        transformSuppliersForDiff([oldSupplier]),
        transformSuppliersForDiff([supplier])
      );
      if (differentSuppliersPre.length > 0) timelineEntry.pre = { suppliers: differentSuppliersPre };
      if (differentSuppliersPost.length > 0) timelineEntry.post = { suppliers: differentSuppliersPost };
    } else {
      // New supplier
      timelineEntry.post = {
        suppliers: [{ ...supplier, supplier: supplier.supplier ? supplier.supplier._id : undefined }],
      };
    }
    const result: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false = await callFunction(
      UPDATECOMMODITYSUPPLIERPRICES,
      [article._id.toString(), { id: entryId, supplier }, !oldSupplier, timelineEntry, finishedProduct]
    );
    if (result && result.modifiedCount > 0) {
      toast.success(`${finishedProduct ? "Finished Product" : "Commodity"} prices updated successfully`);
    } else {
      toast.error(`Error updating ${finishedProduct ? "finished product" : "commodity"} prices`);
    }
  };

  handleSaveAll = async () => {
    const { article } = this.props;
    const { suppliers } = this.state;
    const finishedProduct = isFinishedProduct(article);
    const timelineEntry = finishedProduct
      ? getFinishedProductTimelineEntry(T_PRICESUPDATED)
      : getCommodityTimelineEntry(T_PRICESUPDATED);
    // Diff
    const [differentSuppliersPre, differentSuppliersPost] = getCommoditySupplierDiff(
      transformSuppliersForDiff(article.suppliers),
      transformSuppliersForDiff(suppliers)
    );
    if (differentSuppliersPre.length > 0) timelineEntry.pre = { suppliers: differentSuppliersPre };
    if (differentSuppliersPost.length > 0) timelineEntry.post = { suppliers: differentSuppliersPost };

    const result: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false = await callFunction(
      UPDATECOMMODITYSUPPLIERPRICES,
      [article._id.toString(), suppliers, undefined, timelineEntry, finishedProduct]
    );
    if (result && result.modifiedCount > 0) {
      toast.success(`${finishedProduct ? "Finished Product" : "Commodity"} prices updated successfully`);
    } else {
      toast.error(`Error updating ${finishedProduct ? "finished product" : "commodity"} prices`);
    }
  };

  render() {
    const { article, context } = this.props;
    const { search, suppliers } = this.state;
    const articlePriceRange = getPriceRange(article, context.currencies);
    const filteredSuppliers = search.trim() ? doFuseSearch(suppliers, search, ["supplier.name"]) : suppliers;
    const finishedProduct = isFinishedProduct(article);
    return (
      <>
        <div className="card bg-white">
          <div className="card-header border-0 mt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder fs-3 mb-1">
                {finishedProduct ? "Finished product" : "Commodity"} Prices
              </span>
              <span className="text-muted fw-bold fs-7">
                {article.suppliers.length} Suppliers and {getArticlePricesCount(article)} Prices
              </span>
            </h3>
            <div className="card-toolbar">
              <Search value={search} onSearch={this.handleSearch} />
            </div>
          </div>
          <div className="card-body p-4 pt-8">
            <Accordion alwaysOpen={true} defaultActiveKey={filteredSuppliers.map((s) => s._id.toString())}>
              {_.orderBy(filteredSuppliers, (s) => s.disabled || s.supplier.disabled || s.prices.length === 0, [
                "asc",
              ]).map((s) => (
                <CommodityPageSupplier
                  key={s._id.toString()}
                  article={article}
                  eventKey={s._id.toString()}
                  supplierPrice={s}
                  articlePriceRange={articlePriceRange}
                  onNewPrice={this.handleNewPrice}
                  onRemovePrice={this.handleRemovePrice}
                  onUpdatePrice={this.handleUpdatePrice}
                  onSaveSupplier={this.handleSaveSupplier}
                  onRenewPrices={this.handleRenewPrices}
                  onSetLeadTime={this.handleSetLeadTime}
                  onSaveSettings={this.handleSaveSettings}
                  onDisableSupplier={this.handleDisableSupplier}
                  currencies={context.currencies}
                />
              ))}
            </Accordion>
            <button
              className={"btn btn-sm btn-success float-right ml-2 " + (article.disabled && "disabled")}
              disabled={article.disabled}
              onClick={article.disabled ? undefined : this.handleSaveAll}
            >
              Save All
            </button>
            <AddSupplierModal
              commodity={article}
              context={context}
              usedSuppliers={suppliers.map((s) => s.supplier)}
              onAddSupplier={this.handleAddSupplier}
            />
          </div>
        </div>
        <CommodityEUStock context={context} commodity={article} />
        <ArticleGraduation article={article} />
      </>
    );
  }
}

interface CommodityPageSupplierProps {
  eventKey: string;
  article: InternalArticleExtended;
  supplierPrice: SupplierPricesExtended;
  articlePriceRange: { min: number; minCurrency: string; max: number; maxCurrency: string } | null;
  onNewPrice: (entryId: BSON.ObjectId | string) => void;
  onRemovePrice: (entryId: BSON.ObjectId | string, priceId: BSON.ObjectId | string) => void;
  onUpdatePrice: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    entryId: BSON.ObjectId | string,
    priceId: BSON.ObjectId | string
  ) => void;
  onSaveSupplier: (entryId: BSON.ObjectId | string) => void;
  onRenewPrices: (entryId: BSON.ObjectId | string, date: Date, priceId?: BSON.ObjectId | string) => void;
  onSetLeadTime: (entryId: BSON.ObjectId | string, value: string) => void;
  onSaveSettings: (
    commodityId: BSON.ObjectId | string,
    supplierId: BSON.ObjectId | string,
    settings: CommoditySupplierSettingsUpdate,
    finishedProduct: boolean
  ) => Promise<void>;
  onDisableSupplier: (entryId: BSON.ObjectId | string) => void;
  currencies: Currencies;
}

interface CommodityPageSupplierState {}

class CommodityPageSupplier extends PureComponent<CommodityPageSupplierProps, CommodityPageSupplierState> {
  render() {
    const {
      eventKey,
      article,
      supplierPrice,
      articlePriceRange,
      currencies,
      onNewPrice,
      onRemovePrice,
      onUpdatePrice,
      onSaveSupplier,
      onRenewPrices,
      onSetLeadTime,
      onSaveSettings,
      onDisableSupplier,
    } = this.props;
    const { supplier, prices, contingent } = supplierPrice;
    const minOQ = getMinOQ(prices);
    const bestPrice = getBestPrice(prices);
    const supplierPriceRange = getSupplierPriceRange(prices, currencies);
    const disabled = supplierPrice.disabled || supplier.disabled || prices.length === 0;
    const now = new Date();
    const validPrices = prices.filter((p) => p.validUntil >= now && p.price > 0).length;
    const invalidPrices = prices.filter((p) => p.validUntil < now || p.price <= 0).length;
    return (
      <div className="bg-light2 bg-light2-hover rounded p-5 mb-7">
        <CustomToggle eventKey={eventKey}>
          <div className="d-flex align-items-center ">
            <div className="flex-grow-1 me-2">
              <Link
                to={`/supplier/${supplier._id.toString()}`}
                onClick={(e) => e.stopPropagation()}
                className={"fw-bolder text-gray-800 fs-6 custom-link " + (disabled && "text-muted")}
              >
                {supplier.disabled ? (
                  <span className="text-danger mr-2">[DISABLED]</span>
                ) : supplierPrice.disabled ? (
                  <span className="text-warning mr-2">[DEACTIVATED]</span>
                ) : prices.length === 0 ? (
                  <span className="text-warning mr-2">[NO PRICES]</span>
                ) : (
                  ""
                )}
                {supplier.name}
              </Link>
              {supplier.euSupplier && <SupplierEUWidget />}
              <ContactPersonWidget person={supplier.primaryPerson} spanClasses={"text-muted fw-bold d-block"} />
            </div>
            <div style={{ textAlign: "right", marginRight: 25 }}>
              <span className="mb-1 fs-6 fw-bold text-nowrap">
                {minOQ >= 0 ? `${minOQ}${formatArticleUnit(article.unit)}` : "N/A"}
              </span>
              <div className="text-muted text-nowrap">MOQ</div>
            </div>
            <div style={{ textAlign: "right", marginRight: 25 }}>
              <span className="mb-1 fs-6 fw-bold text-nowrap">
                {!contingent
                  ? "-"
                  : contingent !== Infinity
                  ? `${contingent} ${formatArticleUnit(article.unit) || ""}`
                  : "unlimited"}
              </span>
              <div className="text-muted text-nowrap">Contingent</div>
            </div>
            <div style={{ textAlign: "right", marginRight: 25 }}>
              <span className="mb-1 fs-6 fw-bold text-nowrap">{bestPrice ? bestPrice : "N/A"}</span>
              <div className="text-muted text-nowrap">Best Price</div>
            </div>
            <div style={{ textAlign: "right", marginRight: 25 }}>
              <span className={"mb-1 fs-6 fw-bold text-nowrap"}>{prices.length} Prices</span>
              <div className="text-nowrap fs-8">
                <span className={validPrices > 0 ? "text-success" : "text-muted"}>{validPrices} valid</span>{" "}
                <span className={invalidPrices > 0 ? "text-danger" : "text-muted"}>{invalidPrices} invalid</span>
              </div>
            </div>
            <div style={{ textAlign: "right" }}>
              <div className="text-muted text-nowrap">Price Level</div>
              <ProgressBar style={{ backgroundColor: "rgb(74,74,74)", height: 5 }}>
                <ProgressBar
                  now={supplierPriceRange.min}
                  min={articlePriceRange?.min || 0}
                  max={articlePriceRange?.max || Infinity}
                  style={{ backgroundColor: "red" }}
                />
                <ProgressBar
                  now={supplierPriceRange.max}
                  min={articlePriceRange?.min || 0}
                  max={articlePriceRange?.max || Infinity}
                  style={{ backgroundColor: "rgb(74,74,74)" }}
                />
              </ProgressBar>
            </div>
            <div onClick={(e) => e.stopPropagation()}>
              <CommoditySupplierSettingsModal
                article={article}
                supplierPrice={supplierPrice}
                onSaveSettings={onSaveSettings}
              />
            </div>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={eventKey}>
          <CommodityPriceTable
            commodity={article}
            disabled={article.disabled || supplier.disabled}
            isNew={!article.suppliers.some((s) => s.supplier._id.toString() === supplier._id.toString())}
            supplierDisabled={!!supplierPrice.disabled}
            supplier={supplierPrice.supplier}
            eventKey={eventKey}
            prices={prices}
            onNewPrice={onNewPrice}
            onRemovePrice={onRemovePrice}
            onUpdatePrice={onUpdatePrice}
            onSave={onSaveSupplier}
            onRenewPrices={onRenewPrices}
            onSetLeadTime={onSetLeadTime}
            onDisable={onDisableSupplier}
          />
        </Accordion.Collapse>
      </div>
    );
  }
}

interface AddSupplierModalProps {
  commodity: InternalArticleExtended;
  usedSuppliers: Array<SupplierExtended>;
  context: DataContextInternalType;
  onAddSupplier: (supplier: Supplier) => void;
}

const AddSupplierModal: React.FunctionComponent<AddSupplierModalProps> = ({
  commodity,
  context,
  usedSuppliers,
  onAddSupplier,
}) => {
  const [show, setShow] = useState(false);
  const [supplier, setSupplier] = useState<Supplier | undefined>(undefined);
  const availableSuppliers = context.supplier.filter(
    (s) => !s.disabled && !usedSuppliers.some((us) => us._id.toString() === s._id.toString())
  );
  const handleShow = () => {
    setShow(true);
    setSupplier(undefined);
  };
  const handleHide = () => setShow(false);
  const handleSupplierChange = (event: SelectOption<Supplier>) => setSupplier(event.object);
  return (
    <>
      <button
        type="button"
        className={"btn btn-light btn-sm float-right " + (commodity.disabled && "disabled")}
        disabled={commodity.disabled}
        onClick={commodity.disabled ? undefined : handleShow}
      >
        Add Supplier
      </button>
      <Modal contentClassName="bg-dark" show={show} onHide={handleHide} centered>
        <Modal.Header className="border-0 pb-0">
          <Modal.Title>
            <h1 className="fw-bolder d-flex align-items-center text-white">Add Supplier</h1>
          </Modal.Title>
          <CloseButton variant={"white"} onClick={handleHide} />
        </Modal.Header>
        <Modal.Body>
          <div className="pb-5">
            <div className="text-muted fw-bold fs-6">Add a new supplier to deliver this commodity</div>
          </div>
          <div>
            <div className="row mt-3">
              <div className="col-md-4 my-auto">
                <label className="fs-5 fw-bold my-auto">Supplier</label>
              </div>
              <div className="col-md-8">
                <CustomSelect
                  options={availableSuppliers.map((as) => {
                    return { value: as._id.toString(), label: as.name, object: as };
                  })}
                  value={
                    supplier ? { value: supplier._id.toString(), label: supplier.name, object: supplier } : undefined
                  }
                  onChange={handleSupplierChange}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline btn-text-danger" onClick={handleHide}>
            Cancel
          </button>
          <button
            className={"btn btn-sm btn-outline btn-outline-light " + (!supplier && "disabled")}
            disabled={!supplier}
            onClick={() => {
              if (supplier) onAddSupplier(supplier);
              handleHide();
            }}
          >
            Add Supplier
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CommodityPagePrices;
