import _ from "lodash";
import React, { PureComponent } from "react";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import { RouteComponentProps } from "react-router-dom";
import { DataContextInternalType } from "../../context/dataContext";
import {
  CO_ORDEREDBYCUSTOMER,
  CO_T_ORDERED,
  CO_TRANSPORT,
  CO_TRANSPORT_FOR_STOCK,
  CustomerOrder,
  T_AIRFREIGHT,
  T_EUSTOCK,
  T_RAILFREIGHT,
  T_ROADFREIGHT,
  T_SEAFREIGHT,
} from "../../model/customerOrder.types";
import {
  getFormattedCOTerms,
  getFormattedSOTerms,
  getNoteObject,
  getSupplierOrderTargetDate,
  isCustomerOrder,
} from "../../utils/orderUtils";
import { callPushToArray, getDocFromCollection } from "../../utils/baseUtils";
import { SelectOption } from "../common/CustomSelect";
import { Commodity } from "../../model/commodity.types";
import {
  getBestSupplierAndPrice,
  getBetterPriceOrderQuantity,
  getCalculationDetails,
} from "../../utils/orderCalculationUtils";
import CreateSupplierOrderDestinations from "./CreateSupplierOrderDestinations";
import CreateSupplierOrderSettings from "./CreateSupplierOrderSettings";
import CreateSupplierOrderCalculation from "./CreateSupplierOrderCalculation";
import {
  Action,
  COMMODITY,
  CUSTOMERCONTRACT,
  CUSTOMERORDER,
  SUPPLIERORDER,
  transaction,
  UpdateAction,
} from "../../services/dbService";
import userService from "../../services/userService";
import {
  createSupplierOrder,
  getSupplierOrderFile,
  getSupplierOrderTimelineEntry,
  getSupplierTermOptionFromSupplier,
  SelectedSupplier,
  SO_DELIVERYTERMS,
  SO_ORDERDOCUMENT,
  SupplierTermOptions,
  updateSupplierOrder,
} from "../../utils/supplierOrderUtils";
import {
  PurchaseOrderInformation,
  SO_REQUESTED,
  SO_T_CREATED,
  SO_T_DOCUMENTUPLOADED,
  SO_T_EDITED,
  SupplierOrder,
  SupplierOrderExtended,
} from "../../model/supplierOrder.types";
import ErrorOverlayButton from "../common/ErrorOverlayButton";
import { CO_FOOTER_HTML, createPDF, PO_FOOTER_HTML } from "../../utils/pdfUtils";
import { resolveFilePath } from "../../utils/fileUtils";
import {
  createSupplierOrderHTML,
  shippingInstructionsAir,
  shippingInstructionsEUStock,
  shippingInstructionsSea,
} from "../../utils/pdf/supplierOrderGenerationUtils";
import { getCommodityTimelineEntry, Incoterm, T_ORDEREDFROMSUPPLIER } from "../../utils/commodityUtils";
import {
  CNY,
  convertCurrency,
  Currencies,
  DEFAULT_EU_SUPPLIER_CURRENCY_OPTION,
  DEFAULT_SO_CURRENCY_OPTION,
  getRelevantCurrencyExchangeRates,
  USD,
} from "../../utils/currencyUtils";
import {
  CO_ORDERCONFIRMATION,
  FALLBACKSUPPLIERPREPARATIONTIME,
  getCustomerOrderTimelineEntry,
  getCustomerTermOptionFromCustomerOrder,
} from "../../utils/customerOrderUtils";
import { Seaport } from "../../model/seaport.types";
import {
  AirFreightCalculationValues,
  AirFreightPriceCalculation,
  calculateAirFreightPrice,
  calculateEUStockPrice,
  calculateSeaFreightPrice,
  EUStockCalculationValues,
  EUStockPriceCalculation,
  getAirFreightCalculationValuesForSupplierOrder,
  getDefaultAirFreightCalculationsValues,
  getDefaultEUStockCalculationsValues,
  getDefaultSeaFreightCalculationsValues,
  getEUStockCalculationValuesForSupplierOrder,
  getSeaFreightCalculationValuesForSupplierOrder,
  getSupplierOrderCalculation,
  isAirFreightValues,
  isEUStockValues,
  isSeaFreightValues,
  SeaFreightCalculationValues,
  SeaFreightPriceCalculation,
} from "../../utils/priceCalculationUtils";
import { Airport } from "../../model/airport.types";
import { Notify } from "../../model/notify.types";
import { I_PAYMENTTARGETS } from "../../utils/invoiceUtils";
import SimpleSplashScreen from "../common/SimpleSplashScreen";
import { createOrderConfirmationHTML } from "../../utils/pdf/orderConfirmationGenerationUtils";
import { STANDARDTEXTSHORT } from "../../utils/pdf/templateUtils";
import CustomerTerms, { CustomerTermOptions } from "../common/CustomerTerms";
import { CC_STATE, CustomerContract } from "../../model/customerContract.types";
import { CC_T_ORDERED, getCustomerContractTimelineEntry } from "../../utils/customerContractUtils";
import { getIdentifierForOrderOrContract } from "../../utils/contractAndOrderUtils";
import { getCW } from "../../utils/dateUtils";
import { Address } from "../../model/commonTypes";
import { AddressSelectOption, formatAddress } from "../../utils/addressUtils";
import { getPaletteForCommodity } from "../../utils/supplierUtils";
import {
  getPackagingDimensionString,
  getStandardPackagingDimension,
  packagingDimensionAsPaletteData,
} from "../../utils/packagingDimensionUtils";
import { PackagingDimension } from "../../model/supplier.types";
import { ARTICLETYPES, getArticleSnapshot } from "../../utils/productArticleUtils";
import { FinishedProduct } from "../../model/finishedProduct.types";
import { isFinishedProduct } from "../../utils/finishedProductUtils";
import {
  extendCommodity,
  extendCustomerOrder,
  extendFinishedProduct,
  reduceSupplierOrder,
} from "../../utils/dataTransformationUtils";

interface CreateSupplierOrderParams {
  id: string;
  type?: string;
}

interface CreateSupplierOrderProps extends RouteComponentProps<CreateSupplierOrderParams> {
  forStock?: boolean;
  existingSupplierOrder?: SupplierOrder;
  context: DataContextInternalType;
}

interface CreateSupplierOrderState {
  saving: "draft" | "order" | false;
  order?: CustomerOrder;
  contract?: CustomerContract;
  matchingOrders: Array<CustomerOrder | CustomerContract>;
  selectedOrders: Array<string>;
  warehouse: boolean;
  warehouseAmount: number;
  supplier: SelectedSupplier | null;
  supplierPalette: SelectOption<PackagingDimension>;
  noteInternal: string;
  targetDate: Date;
  etd: Date;
  transportMethod: CO_TRANSPORT_FOR_STOCK | CO_TRANSPORT;
  totalAmount: number;
  article?: Commodity | FinishedProduct;
  startingSeaport?: Seaport;
  startingAirport?: Airport;
  startingEUWarehouse?: Address; // Address of the EU warehouse as address
  shippingInstruction: string;
  currency: SelectOption;
  supplierTermOptions: SupplierTermOptions;
  calculation: SeaFreightPriceCalculation | AirFreightPriceCalculation | EUStockPriceCalculation | null;
  calculationValues: SeaFreightCalculationValues | AirFreightCalculationValues | EUStockCalculationValues | undefined;
  customerOrderOptions?: Array<CustomerTermOptions>;
  loading: boolean;
  followUpCostEUStock: number;
  preferredSupplierInvalid: boolean;
}

class CreateSupplierOrder extends PureComponent<CreateSupplierOrderProps, CreateSupplierOrderState> {
  exchangeRates: Currencies;
  _isMounted = false;
  constructor(props: CreateSupplierOrderProps) {
    super(props);
    this.exchangeRates = _.cloneDeep(this.props.context.currencies);
    this.state = this.getDefaultState(props, undefined, undefined, true);
  }

  componentDidMount = async () => {
    const { forStock, existingSupplierOrder } = this.props;
    const { order, contract, supplier, article, totalAmount, etd } = this.state;
    this._isMounted = true;
    const amount = (forStock ? totalAmount : order?.amount || contract?.contractInformation.totalAmount) || 0;
    const calculationValues =
      order && order.transport === T_EUSTOCK
        ? await getDefaultEUStockCalculationsValues(
            amount,
            isFinishedProduct(article) ? article.weightPerUnit : undefined
          )
        : order && order.transport === T_AIRFREIGHT
        ? await getDefaultAirFreightCalculationsValues(
            article,
            amount,
            isFinishedProduct(article) ? article.weightPerUnit : undefined
          )
        : await getDefaultSeaFreightCalculationsValues(
            this.props.context,
            article,
            amount,
            isFinishedProduct(article) ? article.weightPerUnit : undefined
          ); // default if no order exists
    const calculation = await this.getCalculation(calculationValues, order, contract, article, supplier);
    if (existingSupplierOrder) {
      const {
        targetDate,
        currency,
        customerOrders,
        etd,
        transport,
        customerContracts,
        warehouseAmount,
        purchaseOrderInformation,
        calculationDetails,
        noteInternal,
        amount,
      } = existingSupplierOrder;
      if (this._isMounted) {
        this.setState({
          targetDate,
          calculation: calculation ?? null,
          calculationValues,
          loading: false,
          currency: { value: currency, label: currency },
          etd: etd ?? this.getDefaultETD(supplier),
          transportMethod: transport,
          selectedOrders: customerOrders.map((cO) => cO).concat(customerContracts?.map((cC) => cC) || []),
          warehouseAmount,
          warehouse: warehouseAmount > 0,
          startingEUWarehouse: purchaseOrderInformation?.startingEUWarehouse,
          startingSeaport: purchaseOrderInformation?.startingSeaport,
          startingAirport: purchaseOrderInformation?.startingAirport,
          followUpCostEUStock:
            calculationDetails && "totalFollowUpCost" in calculationDetails.details.finalValues
              ? calculationDetails?.details.finalValues.totalFollowUpCost
              : 0,
          noteInternal: noteInternal.length > 0 ? noteInternal[0].note : "",
          totalAmount: amount,
        });
      }
    } else {
      const targetDate = await getSupplierOrderTargetDate(
        order?.transport,
        supplier?.supplier,
        supplier?.price,
        article,
        etd
      );
      if (this._isMounted) {
        if (calculation) this.setState({ targetDate, calculation, calculationValues, loading: false });
        else this.setState({ targetDate, calculationValues, loading: false });
      }
    }
  };

  componentDidUpdate = async (
    prevProps: Readonly<CreateSupplierOrderProps>,
    prevState: Readonly<CreateSupplierOrderState>
  ) => {
    const { match, context, forStock } = this.props;
    const { order, contract, supplier, followUpCostEUStock, transportMethod } = this.state;
    const document = order || contract;
    if (match.params.id || (!match.params.id && prevProps.match.params.id)) {
      let commodityNew;
      if (forStock) {
        commodityNew = getDocFromCollection(context.commodity, match.params.id);
      }
      let contractNew = undefined;
      let orderNew = undefined;
      if (match.params.type) contractNew = getDocFromCollection(context.customerContract, match.params.id);
      else orderNew = this.getCustomerOrder(this.props);
      if (
        !_.isEqual(orderNew, this.state.order) ||
        !_.isEqual(contractNew, this.state.contract) ||
        (commodityNew && !_.isEqual(commodityNew, this.state.article))
      ) {
        const newState = await this.getDefaultStateAsync(this.props, orderNew, contractNew);
        if (this._isMounted) this.setState({ ...newState });
        return;
      } else if (
        _.isEqual(this.state, prevState) &&
        (!_.isEqual(context.customerOrder, prevProps.context.customerOrder) ||
          !_.isEqual(context.customerContract, prevProps.context.customerContract))
      ) {
        const matchingOrders = this.getMatchingOrdersAndContract(document, context);
        // Remove selected orders that may not exist anymore
        const selectedOrders = this.state.selectedOrders.filter((sO) =>
          matchingOrders.some((mO) => mO._id.toString() === sO)
        );
        if (!_.isEqual(matchingOrders, prevState.matchingOrders) && this._isMounted) {
          this.setState({ matchingOrders, selectedOrders }, () =>
            this.recalculate(undefined, true, transportMethod === T_EUSTOCK ? followUpCostEUStock : undefined)
          );
        }
      } else {
        const commodityNew = document && getDocFromCollection(this.props.context.commodity, document.commodity._id);
        if (commodityNew && !_.isEqual(commodityNew, this.state.article)) {
          const suppliers =
            supplier && commodityNew.suppliers.some((s) => s.supplier === supplier.supplier._id.toString())
              ? [supplier.supplier._id.toString()]
              : commodityNew.suppliers.map((s) => s.supplier);
          if (this._isMounted)
            this.setState({ article: commodityNew }, () =>
              this.recalculate(suppliers, false, transportMethod === T_EUSTOCK ? followUpCostEUStock : undefined)
            );
        }
      }
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  handleSetCalculationValues = (
    calculationValues: SeaFreightCalculationValues | AirFreightCalculationValues | EUStockCalculationValues
  ) => {
    const { supplier, followUpCostEUStock, transportMethod } = this.state;
    const suppliers = supplier ? [supplier.supplier._id.toString()] : undefined;
    this.setState({ calculationValues }, () =>
      this.recalculate(suppliers, false, transportMethod === T_EUSTOCK ? followUpCostEUStock : undefined)
    );
  };

  handleChangeSupplier = (e: SelectOption) => {
    const id = e.value;
    const { forStock, context } = this.props;
    const {
      order,
      calculationValues,
      contract,
      article,
      followUpCostEUStock,
      transportMethod,
      currency: currencyState,
    } = this.state;
    if (!(order || contract || forStock) || !article) return;
    const supplier = context.supplier.find((s) => s._id.toString() === id);
    const palette = supplier
      ? getPaletteForCommodity(supplier, article?._id.toString(), order ? order.transport : T_SEAFREIGHT)
      : getStandardPackagingDimension();
    // Should always be there - just for sanity
    if (calculationValues) {
      calculationValues.paletteData = packagingDimensionAsPaletteData(palette);
      this.setState({ calculationValues }, () =>
        this.recalculate([id], true, transportMethod === T_EUSTOCK ? followUpCostEUStock : undefined)
      );
    } else {
      this.recalculate([id], false, transportMethod === T_EUSTOCK ? followUpCostEUStock : undefined);
    }
    if (supplier) {
      const supplierTermOptions = getSupplierTermOptionFromSupplier(supplier, context.notify);
      let currency = currencyState;
      if (supplier.euSupplier) {
        currency = DEFAULT_EU_SUPPLIER_CURRENCY_OPTION;
      }
      this.setState({
        supplierTermOptions,
        supplierPalette: {
          value: palette._id.toString(),
          label: getPackagingDimensionString(palette),
          object: palette,
        },
        currency,
      });
    }
  };

  handleChangeSeaport = (e: SelectOption) => {
    if (!this.state.calculationValues) return;
    const { seaport } = this.props.context;
    const { supplier, supplierTermOptions } = this.state;
    const suppliers = supplier ? [supplier.supplier._id.toString()] : undefined;
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    if (!isSeaFreightValues(calculationValues)) return;
    const startingSeaport = seaport.find((s) => s._id.toString() === e.value);
    if (
      !startingSeaport ||
      (supplierTermOptions.deliveryTerm.value !== Incoterm.CIF &&
        (!startingSeaport.cost || !startingSeaport.containerCost))
    )
      return;
    const containerCost = startingSeaport.containerCost || {
      "20": 0,
      "40": 0,
      "40HC": 0,
    };
    calculationValues.seaFreightCost = {
      cost: { containerCost, cost: startingSeaport.cost || 0 },
      currency: startingSeaport.currency || USD,
    };
    this.setState({ startingSeaport, calculationValues }, () => this.recalculate(suppliers, true));
  };

  handleChangeAirport = (e: SelectOption) => {
    if (!this.state.calculationValues) return;
    const { airport } = this.props.context;
    const { supplier } = this.state;
    const suppliers = supplier ? [supplier.supplier._id.toString()] : undefined;
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    if (!isAirFreightValues(calculationValues)) return;
    const startingAirport = airport.find((a) => a._id.toString() === e.value);
    if (!startingAirport) return;
    calculationValues.airFreightCost = { cost: startingAirport.cost || 0, currency: startingAirport.currency || CNY };
    this.setState({ startingAirport, calculationValues }, () => this.recalculate(suppliers));
  };

  handleChangeEUWarehouse = (e: AddressSelectOption) => {
    this.setState({ startingEUWarehouse: e.address });
  };

  handleChangeTargetDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = new Date(e.target.value);
    if (isNaN(val.getTime())) return;
    this.setState({ targetDate: val });
  };

  handleChangeETD = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { supplier, article, transportMethod } = this.state;
    const val = new Date(e.target.value);
    if (isNaN(val.getTime())) return;
    const targetDate = await getSupplierOrderTargetDate(
      transportMethod,
      supplier?.supplier,
      supplier?.price,
      article,
      val
    );
    this.setState({ etd: val, targetDate });
  };

  handleChangeShippingInstruction = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ shippingInstruction: e.target.value });
  };

  handleSelectDocument = (id: BSON.ObjectID | string) => {
    const { context } = this.props;
    const { matchingOrders, selectedOrders, followUpCostEUStock, transportMethod } = this.state;
    let customerOrderOptions = _.cloneDeep(this.state.customerOrderOptions);
    const cO =
      matchingOrders.find((mO) => mO._id.toString() === id.toString()) ||
      (id.toString() === this.state.order?._id.toString() && this.state.order);
    if (cO && isCustomerOrder(cO)) {
      const newOption = getCustomerTermOptionFromCustomerOrder(extendCustomerOrder(cO, context));
      if (customerOrderOptions) {
        customerOrderOptions.push(newOption);
      } else {
        customerOrderOptions = [newOption];
      }
    }
    const preferredSupplier = this.getPreferredSupplier();
    this.setState({ selectedOrders: selectedOrders.concat(id.toString()), customerOrderOptions }, () =>
      this.recalculate(
        preferredSupplier ? [preferredSupplier] : undefined,
        true,
        transportMethod === T_EUSTOCK ? followUpCostEUStock : undefined
      )
    );
  };
  handleDeselectDocument = (id: BSON.ObjectID | string) => {
    const { selectedOrders, customerOrderOptions, transportMethod, followUpCostEUStock } = this.state;
    const preferredSupplier = this.getPreferredSupplier();
    this.setState(
      {
        selectedOrders: selectedOrders.filter((sO) => sO !== id.toString()),
        customerOrderOptions: customerOrderOptions?.filter((coo) => id.toString() !== coo.id),
      },
      () =>
        this.recalculate(
          preferredSupplier ? [preferredSupplier] : undefined,
          true,
          transportMethod === T_EUSTOCK ? followUpCostEUStock : undefined
        )
    );
  };

  handleChangeTotalAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { transportMethod, followUpCostEUStock } = this.state;
    if (this.props.forStock)
      this.setState({ totalAmount: e.target.valueAsNumber, warehouseAmount: e.target.valueAsNumber }, () =>
        this.recalculate(undefined, true, transportMethod === T_EUSTOCK ? followUpCostEUStock : undefined)
      );
    else
      this.setState({ totalAmount: e.target.valueAsNumber }, () =>
        this.recalculate(undefined, true, transportMethod === T_EUSTOCK ? followUpCostEUStock : undefined)
      );
  };

  handleChangeTransportMethod = (e: SelectOption) => {
    const instructions =
      e.value === T_AIRFREIGHT
        ? shippingInstructionsAir
        : e.value === T_EUSTOCK
        ? shippingInstructionsEUStock
        : shippingInstructionsSea;
    this.setState(
      {
        transportMethod: e.value as CO_TRANSPORT_FOR_STOCK,
        startingAirport: undefined,
        startingSeaport: undefined,
        shippingInstruction: instructions,
        followUpCostEUStock: 0,
      },
      () =>
        this.recalculate(
          this.state.supplier ? [this.state.supplier.supplier._id.toString()] : undefined,
          true,
          e.value === T_EUSTOCK ? this.state.followUpCostEUStock : undefined
        )
    );
  };

  handleChangeFollowUpCostEUStock = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { transportMethod } = this.state;
    if (transportMethod === T_EUSTOCK) {
      const followUpCostEUStock = +e.currentTarget.value;
      this.recalculate(undefined, true, followUpCostEUStock);
      this.setState({ followUpCostEUStock });
    }
  };

  handleChangeNotify = (e: SelectOption<Notify>) => {
    const { supplierTermOptions } = _.cloneDeep(this.state);
    if (!e.object) return; // Should never happen
    supplierTermOptions.notify = e.object;
    this.setState({ supplierTermOptions });
  };

  handleChangePaymentTerms = (e: SelectOption) => {
    const { supplierTermOptions } = _.cloneDeep(this.state);
    supplierTermOptions.paymentTerm = e;
    this.setState({ supplierTermOptions });
  };

  handleChangeCustomPaymentTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { supplierTermOptions } = _.cloneDeep(this.state);
    supplierTermOptions.customPaymentTerm = e.currentTarget.value;
    this.setState({ supplierTermOptions });
  };

  handleChangeCustomPaymentTermCondition = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { supplierTermOptions } = _.cloneDeep(this.state);
    supplierTermOptions.customPaymentTermCondition = e.currentTarget.value;
    this.setState({ supplierTermOptions });
  };

  handleChangeDeliveryTerms = (e: SelectOption) => {
    const { supplierTermOptions } = _.cloneDeep(this.state);
    supplierTermOptions.deliveryTerm = e;
    // Since the list of allowed air/seaport might switch upon changing the incoterm we need to reset them
    this.setState({ supplierTermOptions, startingAirport: undefined, startingSeaport: undefined });
  };

  handleChangeCurrency = (currency: SelectOption) => {
    const { transportMethod, followUpCostEUStock } = this.state;
    this.setState({ currency }, () =>
      this.recalculate(undefined, false, transportMethod === T_EUSTOCK ? followUpCostEUStock : undefined)
    );
  };

  handleChangeCustomerNote = (e: React.ChangeEvent<HTMLTextAreaElement>, selectedOrderId: string) => {
    const customerOrderOptions = _.cloneDeep(this.state.customerOrderOptions);
    const selectedOrderOption = customerOrderOptions?.find((coo) => coo.id === selectedOrderId);
    if (selectedOrderOption) {
      selectedOrderOption.note = e.currentTarget.value;
      this.setState({ customerOrderOptions });
    }
  };

  handleChangeCustomerPaymentTerms = (e: SelectOption, selectedOrderId: string) => {
    const customerOrderOptions = _.cloneDeep(this.state.customerOrderOptions);
    const selectedOrderOption = customerOrderOptions?.find((coo) => coo.id === selectedOrderId);
    if (selectedOrderOption) {
      selectedOrderOption.paymentTerm = e;
      this.setState({ customerOrderOptions });
    }
  };

  handleChangeCustomerCustomPaymentTerms = (e: React.ChangeEvent<HTMLInputElement>, selectedOrderId: string) => {
    const customerOrderOptions = _.cloneDeep(this.state.customerOrderOptions);
    const selectedOrderOption = customerOrderOptions?.find((coo) => coo.id === selectedOrderId);
    if (selectedOrderOption) {
      selectedOrderOption.customPaymentTerm = e.currentTarget.value;
      this.setState({ customerOrderOptions });
    }
  };

  handleChangeCustomerCustomPaymentTermConditions = (
    e: React.ChangeEvent<HTMLInputElement>,
    selectedOrderId: string
  ) => {
    const customerOrderOptions = _.cloneDeep(this.state.customerOrderOptions);
    const selectedOrderOption = customerOrderOptions?.find((coo) => coo.id === selectedOrderId);
    if (selectedOrderOption) {
      selectedOrderOption.customPaymentTermCondition = e.currentTarget.value;
      this.setState({ customerOrderOptions });
    }
  };

  handleChangeCustomerDeliveryTerms = (e: SelectOption, selectedOrderId: string) => {
    const customerOrderOptions = _.cloneDeep(this.state.customerOrderOptions);
    const selectedOrderOption = customerOrderOptions?.find((coo) => coo.id === selectedOrderId);
    if (selectedOrderOption) {
      selectedOrderOption.deliveryTerm = e;
      this.setState({ customerOrderOptions });
    }
  };

  handleChangeCustomerDeliveryCity = (e: React.ChangeEvent<HTMLInputElement>, selectedOrderId: string) => {
    const customerOrderOptions = _.cloneDeep(this.state.customerOrderOptions);
    const selectedOrderOption = customerOrderOptions?.find((coo) => coo.id === selectedOrderId);
    if (selectedOrderOption) {
      selectedOrderOption.deliveryCity = e.currentTarget.value;
      this.setState({ customerOrderOptions });
    }
  };

  handleToggleWarehouseAmount = () => {
    const { transportMethod, followUpCostEUStock } = this.state;
    this.setState({ warehouse: !this.state.warehouse }, () =>
      this.recalculate(undefined, true, transportMethod === T_EUSTOCK ? followUpCostEUStock : undefined)
    );
  };

  handleChangeWarehouseAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { transportMethod, followUpCostEUStock } = this.state;
    this.setState({ warehouseAmount: +e.target.value }, () =>
      this.recalculate(undefined, true, transportMethod === T_EUSTOCK ? followUpCostEUStock : undefined)
    );
  };

  handleCreateDraft = async () => {
    const {
      transportMethod,
      startingSeaport,
      startingAirport,
      startingEUWarehouse,
      shippingInstruction,
      supplierTermOptions,
      noteInternal,
    } = this.state;
    const supplierOrder = this.getSupplierOrder();
    if (!supplierOrder || !(startingSeaport || startingAirport || startingEUWarehouse)) return;
    this.setState({ saving: "draft" });
    try {
      const start = startingSeaport || startingAirport || startingEUWarehouse;
      const terms = getFormattedSOTerms(supplierTermOptions);
      const delivery = `${terms.deliveryTerms}
          ${
            terms.deliveryTerms === Incoterm.DDP && transportMethod === T_EUSTOCK
              ? ` - ${formatAddress(supplierTermOptions.notify.address, { withComma: true })}`
              : ""
          }`;
      const path = await createPDF(
        createSupplierOrderHTML(
          supplierOrder,
          transportMethod !== T_EUSTOCK || (terms.deliveryTerms !== Incoterm.DDP && transportMethod === T_EUSTOCK)
            ? start
            : undefined,
          shippingInstruction,
          transportMethod !== T_EUSTOCK || (terms.deliveryTerms === Incoterm.DDP && transportMethod === T_EUSTOCK)
            ? supplierTermOptions.notify
            : undefined,
          delivery,
          terms.paymentTerms,
          terms.paymentTermConditions,
          true,
          noteInternal
        ),
        "DRAFT-Order",
        supplierOrder.supplier._id.toString(),
        {
          marginLeft: "2cm",
          marginBottom: "4.2cm",
          footerHtml: PO_FOOTER_HTML,
        }
      );
      if (path) {
        window.open(resolveFilePath(path));
      } else {
        toast.error("Error creating order PDF preview");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  handleCreateOrder = async () => {
    const { existingSupplierOrder, context } = this.props;
    const {
      startingSeaport,
      startingAirport,
      startingEUWarehouse,
      transportMethod,
      shippingInstruction,
      supplierTermOptions,
      noteInternal,
      customerOrderOptions,
    } = this.state;
    const supplierOrder = this.getSupplierOrder();
    if (!supplierOrder || !(startingSeaport || startingAirport || startingEUWarehouse)) return;
    const customerOrders = supplierOrder.customerOrders;
    this.setState({ saving: "order" });
    const paths = [];
    try {
      // create supplier order
      supplierOrder.timeline = [
        {
          _id: new BSON.ObjectId(),
          date: new Date(),
          type: SO_T_CREATED,
          person: userService.getUserId(),
          payload: null,
        },
      ];
      const termsSO = getFormattedSOTerms(supplierTermOptions);
      const cOTimelineEntry = getCustomerOrderTimelineEntry(CO_T_ORDERED);
      const cCTimelineEntry = getCustomerContractTimelineEntry(CC_T_ORDERED);
      let result;
      if (existingSupplierOrder) {
        const actions: Array<UpdateAction> = [];
        const update = _.omit(supplierOrder, ["_id", "createdAt", "orderNo", "timeline", "files", "state", "person"]);
        actions.push({
          collection: SUPPLIERORDER,
          filter: { _id: existingSupplierOrder._id },
          update: {
            ...update,
            customerOrders: supplierOrder.customerOrders.map((cO) => cO._id.toString()),
            supplier: supplierOrder.supplier._id.toString(),
          },
          push: { timeline: getSupplierOrderTimelineEntry(SO_T_EDITED) },
        });
        if (!_.isEqual(existingSupplierOrder.customerOrders, customerOrders)) {
          for (let i = 0; i < customerOrders.length; i++) {
            const cO = customerOrders[i];
            const exCO = existingSupplierOrder.customerOrders.find((ecO) => ecO === cO._id.toString());
            if (exCO) {
              const exCOObj = getDocFromCollection(context.customerOrder, exCO);
              // If CO was already part of SO it still could have updated terms
              if (!_.isEqual(cO.terms, exCOObj?.terms)) {
                actions.push({ collection: CUSTOMERORDER, filter: { _id: cO._id }, update: { terms: cO.terms } });
              }
              // CO was added to the SO
            } else {
              actions.push({
                collection: SUPPLIERORDER,
                filter: { _id: existingSupplierOrder._id },
                push: { customerOrders: cO._id.toString() },
              });
            }
          }
          for (let i = 0; i < existingSupplierOrder.customerOrders.length; i++) {
            const exCO = existingSupplierOrder.customerOrders[i];
            const cO = customerOrders.find((scO) => scO._id.toString() === exCO);
            // Equality case was already covered above
            if (!cO) {
              // CO was removed - so has to be pulled from SO
              actions.push({
                collection: SUPPLIERORDER,
                filter: { _id: existingSupplierOrder._id },
                pull: { customerOrder: exCO },
              });
            }
          }
        }
        if (!_.isEqual(existingSupplierOrder.customerContracts, supplierOrder.customerContracts)) {
          if (supplierOrder.customerContracts) {
            for (let i = 0; i < supplierOrder.customerContracts.length; i++) {
              const cC = supplierOrder.customerContracts[i];
              const exCC = existingSupplierOrder.customerContracts?.find((ecC) => ecC === cC._id.toString());
              if (exCC) {
                const exCCObj = getDocFromCollection(context.customerContract, exCC);
                // If CC was already part of SO it still could have updated terms
                if (!_.isEqual(cC.terms, exCCObj?.terms)) {
                  actions.push({ collection: CUSTOMERCONTRACT, filter: { _id: cC._id }, update: { terms: cC.terms } });
                }
                // CC was added to the SO
              } else {
                actions.push({
                  collection: SUPPLIERORDER,
                  filter: { _id: existingSupplierOrder._id },
                  push: { customerOrders: cC._id.toString() },
                });
              }
            }
          }
          if (existingSupplierOrder.customerContracts) {
            for (let i = 0; i < existingSupplierOrder.customerContracts.length; i++) {
              const exCC = existingSupplierOrder.customerContracts[i];
              const cC = supplierOrder.customerContracts?.find((scC) => scC._id.toString() === exCC);
              // Equality case was already covered above
              if (!cC) {
                // CC was removed - so has to be pulled from SO
                actions.push({
                  collection: SUPPLIERORDER,
                  filter: { _id: existingSupplierOrder._id },
                  pull: { customerContracts: exCC },
                });
              }
            }
          }
        }
        result = await transaction(actions);
      } else {
        result = await createSupplierOrder(reduceSupplierOrder(supplierOrder), cOTimelineEntry, cCTimelineEntry);
      }
      if ((typeof result === "boolean" && result) || (result && result.res.insertedId && result.orderNo)) {
        let id, orderNo;
        if (typeof result === "boolean") {
          if (!existingSupplierOrder) return; // Should never happen
          id = existingSupplierOrder._id;
          orderNo = existingSupplierOrder.orderNo.toString();
        } else {
          id = result.res.insertedId;
          orderNo = result.orderNo;
        }
        supplierOrder.orderNo = orderNo;
        const start = startingSeaport || startingAirport || startingEUWarehouse;
        const delivery = `${termsSO.deliveryTerms}
          ${
            termsSO.deliveryTerms === Incoterm.DDP && transportMethod === T_EUSTOCK
              ? ` - ${formatAddress(supplierTermOptions.notify.address, { withComma: true })}`
              : ""
          }`;
        const path = await createPDF(
          createSupplierOrderHTML(
            supplierOrder,
            transportMethod !== T_EUSTOCK || (termsSO.deliveryTerms !== Incoterm.DDP && transportMethod === T_EUSTOCK)
              ? start
              : undefined,
            shippingInstruction,
            transportMethod !== T_EUSTOCK || (termsSO.deliveryTerms === Incoterm.DDP && transportMethod === T_EUSTOCK)
              ? supplierTermOptions.notify
              : undefined,
            delivery,
            termsSO.paymentTerms,
            termsSO.paymentTermConditions,
            false,
            noteInternal
          ),
          "PurchaseOrder-" + orderNo,
          supplierOrder.supplier._id.toString(),
          {
            marginLeft: "2cm",
            marginBottom: "4.2cm",
            footerHtml: PO_FOOTER_HTML,
          }
        );
        if (!path) throw Error("PDF could not be generated");
        else paths.push(path);
        const sOTimeline = getSupplierOrderTimelineEntry(SO_T_DOCUMENTUPLOADED, { type: SO_ORDERDOCUMENT });
        const res = await updateSupplierOrder(
          { files: [getSupplierOrderFile(path, SO_ORDERDOCUMENT)] },
          id,
          sOTimeline
        );
        if (res && res.modifiedCount) {
          toast.success(`Order ${existingSupplierOrder ? "updated" : "created"} successfully`);

          const actions = [];
          // create customer order confirmations to related customer orders
          if (customerOrders) {
            for (let i = 0; i < customerOrders.length; i++) {
              if (customerOrderOptions) {
                const customerOrderOption = customerOrderOptions.find(
                  (coo) => coo.id === customerOrders[i]._id.toString()
                )!;
                const termsCO = getFormattedCOTerms(customerOrderOption);
                const path = await createPDF(
                  createOrderConfirmationHTML(
                    extendCustomerOrder(customerOrders[i], context),
                    STANDARDTEXTSHORT,
                    this.props.context.currencies,
                    termsCO.deliveryTerms,
                    termsCO.paymentTerms,
                    termsCO.paymentTermConditions,
                    termsCO.note,
                    termsCO.deliveryCity
                  ),
                  "Order-Confirmation-" + customerOrders[i].orderNo,
                  customerOrders[i].company,
                  {
                    marginLeft: "2cm",
                    marginBottom: "4.2cm",
                    footerHtml: CO_FOOTER_HTML,
                  }
                );
                const action: Action = {
                  collection: CUSTOMERORDER,
                  filter: { _id: customerOrders[i]._id },
                  update: {
                    transport: transportMethod !== T_EUSTOCK ? transportMethod : customerOrders[i].transport,
                    terms: termsCO,
                    supplier: supplierOrder.supplier._id.toString(),
                  },
                };
                if (!path) {
                  console.error("Order confirmation could not be created. Please try again later");
                } else {
                  const fileEntry = {
                    _id: new BSON.ObjectId(),
                    date: new Date(),
                    path,
                    type: CO_ORDERCONFIRMATION,
                    uploadedBy: userService.getUserId(),
                  };
                  action["push"] = {
                    files: fileEntry,
                  };
                }
                actions.push(action);
                if (path) {
                  paths.push(path);
                }
              }
            }
          }
          const resultCustomOrders = await transaction(actions);
          if (resultCustomOrders) {
            toast.success(`Customer order confirmation created successfully`);
          } else toast.error(`Customer order confirmation could not be created. Please try again later`);

          const commodityTimelineEntry = getCommodityTimelineEntry(T_ORDEREDFROMSUPPLIER, undefined, id.toString());
          await callPushToArray(COMMODITY, supplierOrder.commodity._id.toString(), "timeline", commodityTimelineEntry);
          // open PDFs, not working in loop if pop-ups blocked by browser
          paths.forEach((p) => {
            window.open(resolveFilePath(p), p);
          });
          this.props.history.push("/supplierOrder/" + id);
        } else {
          toast.error("Error creating order PDF");
        }
      } else {
        toast.error(`Error ${existingSupplierOrder ? "updating" : "creating"} order`);
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  /**
   * Recalculate numbers that need to be recalculated
   * @param suppliers optional, list of supplier ids that should only be included
   * @param recalculateValues optional, flag indicating if a full recalculation should be done
   * @param followUpCostEUStock number, optional transport cost from supplier to our warehouse
   */
  recalculate = async (suppliers?: Array<string>, recalculateValues?: boolean, followUpCostEUStock?: number) => {
    if (!this.state.calculationValues) return;
    const { forStock, context } = this.props;
    const { order, contract, startingAirport, startingSeaport, article, transportMethod, etd } = this.state;
    if ((!forStock && !(order || contract)) || !article) return;
    const totalAmount = this.getTotalAmount();
    const bestPrice = getBestSupplierAndPrice(
      article,
      totalAmount,
      order?.unit || contract?.commodity.unit || (article.unit as "kg" | "ltr" | "1000 pcs"),
      transportMethod,
      context,
      suppliers
    );
    if (!bestPrice) {
      toast.error("No suitable price found for current selection");
      this.setState({
        supplier: null,
        calculation: null,
      });
      return;
    }
    const betterPrice = getBetterPriceOrderQuantity(
      article,
      bestPrice.price,
      order?.transport || transportMethod,
      context
    );
    const supplier = { ...bestPrice, betterPrice };
    // #TODO RB-666: Add proper story for calculation implementation
    const calculationValues = recalculateValues
      ? transportMethod === T_EUSTOCK
        ? await getEUStockCalculationValuesForSupplierOrder(
            this.state.calculationValues.paletteData,
            totalAmount,
            isFinishedProduct(article) ? article.weightPerUnit : undefined
          )
        : transportMethod === T_AIRFREIGHT
        ? await getAirFreightCalculationValuesForSupplierOrder(
            article,
            this.state.calculationValues.paletteData,
            totalAmount,
            startingAirport
          )
        : await getSeaFreightCalculationValuesForSupplierOrder(
            article,
            this.state.calculationValues.paletteData,
            totalAmount,
            this.exchangeRates,
            startingSeaport
          )
      : this.state.calculationValues;

    const calculation = await this.getCalculationForSupplier(
      calculationValues,
      supplier,
      transportMethod === T_EUSTOCK ? followUpCostEUStock : undefined
    );
    if (!calculation) return;
    const targetDate = await getSupplierOrderTargetDate(
      transportMethod,
      supplier?.supplier,
      supplier?.price,
      article,
      etd
    );
    this.setState({
      supplier,
      calculation,
      calculationValues,
      targetDate,
    });
  };

  getSupplierOrder = (): SupplierOrderExtended | undefined => {
    const { forStock, context } = this.props;
    const {
      order,
      contract,
      article,
      supplier,
      selectedOrders,
      matchingOrders,
      calculation,
      calculationValues,
      warehouseAmount,
      warehouse,
      noteInternal,
      targetDate,
      etd,
      startingSeaport,
      startingAirport,
      startingEUWarehouse,
      shippingInstruction,
      transportMethod,
      supplierTermOptions,
      currency,
    } = this.state;
    if ((!forStock && !(order || contract)) || !article || !supplier || !calculation) return;
    const customerOrders = [];
    const customerContracts = [];
    if (order && selectedOrders.includes(order._id.toString())) customerOrders.push(order);
    if (contract && selectedOrders.includes(contract._id.toString())) customerContracts.push(contract);
    matchingOrders.forEach((o) => {
      if (selectedOrders.includes(o._id.toString())) {
        if (isCustomerOrder(o)) customerOrders.push(o);
        else customerContracts.push(o);
      }
    });
    const { totalTurnover, transportCost, customsCost, commodityCost, totalMargin, totalPrice, warehouseCost } =
      getSupplierOrderCalculation(this.getTotalTurnover(), calculation);
    // Get all relevant currencies without duplicates EUR/USD will always be required
    const currencies = customerOrders
      .map((o) => o.currency)
      .concat(customerContracts.map((c) => c.priceInformation.currency));
    const purchaseOrderInformation: PurchaseOrderInformation = { shippingInstruction };
    if (startingAirport && transportMethod === T_AIRFREIGHT)
      purchaseOrderInformation["startingAirport"] = startingAirport;
    else if (startingSeaport && transportMethod === T_SEAFREIGHT)
      purchaseOrderInformation["startingSeaport"] = startingSeaport;
    else if (startingEUWarehouse && transportMethod === T_EUSTOCK)
      purchaseOrderInformation["startingEUWarehouse"] = startingEUWarehouse;

    return {
      _id: new BSON.ObjectId(),
      orderNo: "-1",
      createdAt: new Date(),
      targetDate,
      etd,
      state: SO_REQUESTED,
      supplier: supplier.supplier,
      noteInternal: noteInternal.trim() ? [getNoteObject(noteInternal.trim())] : [],
      noteSupplier: "",
      person: userService.getUserData(),
      commodity: getArticleSnapshot(
        isFinishedProduct(article) ? extendFinishedProduct(article, context) : extendCommodity(article, context),
        supplier.supplier._id,
        supplier.price
      ),
      amount: this.getTotalAmount(),
      unit: order?.unit || contract?.commodity.unit || (article.unit as "kg" | "ltr" | "1000 pcs"),
      warehouseAmount: warehouse ? warehouseAmount : 0,
      totalTurnover,
      priceCommodities: commodityCost,
      priceTransport: transportCost,
      priceCustoms: customsCost || 0,
      totalWarehouse: warehouseCost || 0,
      totalBuffer: 0,
      totalPrice,
      totalMargin,
      currency: currency.value,
      exchangeRates: getRelevantCurrencyExchangeRates(this.exchangeRates, currencies),
      customerOrders,
      customerContracts,
      timeline: [],
      files: [],
      transport: transportMethod,
      shipment: [],
      purchaseOrderInformation,
      calculationDetails: getCalculationDetails(calculation, calculationValues),
      terms: getFormattedSOTerms(supplierTermOptions),
    };
  };

  getDefaultETD = (supplier?: SelectedSupplier | null) => {
    return supplier?.supplier.transport.preparationTime
      ? new Date(new Date().getTime() + supplier?.supplier.transport.preparationTime * 24 * 60 * 60 * 1000)
      : new Date(new Date().setDate(new Date().getDate() + FALLBACKSUPPLIERPREPARATIONTIME)); // default supplier preparation time
  };

  /**
   * Get the default state asynchronously
   * @param props the components properties
   * @param cOrder optional, a customer order
   * @param cContract optional, a customer contract
   * @param loading optional, loading flag value
   * @returns {CreateSupplierOrderState} a component state object
   */
  getDefaultState = (
    props: CreateSupplierOrderProps,
    cOrder?: CustomerOrder,
    cContract?: CustomerContract,
    loading?: boolean
  ): CreateSupplierOrderState => {
    const { context, forStock, match, existingSupplierOrder } = props;
    const { id, type } = match.params;
    const order = cOrder ?? !type ? this.getCustomerOrder(props) : undefined;
    const contract =
      cContract ?? type === "contract" ? getDocFromCollection(props.context.customerContract, id) : undefined;
    const matchingOrders = this.getMatchingOrdersAndContract(type === "contract" ? contract : order, context);
    const commodity: Commodity | FinishedProduct | undefined = existingSupplierOrder
      ? existingSupplierOrder.commodity.articleType === ARTICLETYPES.COMMODITY
        ? getDocFromCollection(context.commodity, existingSupplierOrder.commodity._id)
        : getDocFromCollection(context.finishedProduct, existingSupplierOrder.commodity._id)
      : forStock
      ? context.commodity.some((c) => c._id.toString() === id)
        ? getDocFromCollection(context.commodity, id)
        : getDocFromCollection(context.finishedProduct, id)
      : order
      ? order.commodity.articleType === ARTICLETYPES.COMMODITY
        ? getDocFromCollection(context.commodity, order.commodity._id)
        : getDocFromCollection(context.finishedProduct, order.commodity._id)
      : contract
      ? contract.commodity.articleType === ARTICLETYPES.COMMODITY
        ? getDocFromCollection(context.commodity, contract.commodity._id)
        : getDocFromCollection(context.finishedProduct, contract.commodity._id)
      : undefined;
    const fallbackAmount = 1000;
    const preferredSupplier = order?.supplier || contract?.supplier;
    const bestPrice = commodity
      ? getBestSupplierAndPrice(
          commodity,
          order?.amount || contract?.contractInformation.totalAmount || fallbackAmount,
          order?.unit || contract?.commodity.unit || (commodity.unit as "kg" | "ltr" | "1000 pcs"),
          order?.transport || T_SEAFREIGHT,
          context,
          preferredSupplier ? [preferredSupplier] : undefined
        )
      : null;
    const betterPrice =
      commodity && bestPrice
        ? getBetterPriceOrderQuantity(commodity, bestPrice.price, order?.transport || T_SEAFREIGHT, context)
        : null;
    const supplier = bestPrice ? { ...bestPrice, betterPrice } : null;
    if (!supplier) toast.error("Preferred supplier of customer got not valid price for the current configuration.");
    const preferredSupplierInvalid = !supplier;
    const etd = this.getDefaultETD(supplier);
    const paymentTerm =
      supplier?.supplier.paymentTerms && supplier?.supplier.paymentTerms.paymentTarget !== ""
        ? {
            value: String(supplier.supplier.paymentTerms.paymentTarget),
            label: supplier.supplier.paymentTerms.paymentTarget + " days",
          }
        : I_PAYMENTTARGETS[3];
    const paymentTermCondition = supplier?.supplier.paymentTerms?.paymentTargetConditions
      ? supplier.supplier.paymentTerms.paymentTargetConditions
      : "";
    let supplierTermOptions: SupplierTermOptions = {
      paymentTerm: paymentTerm,
      customPaymentTerm: "",
      customPaymentTermCondition: paymentTermCondition,
      notify: context.notify[0],
      deliveryTerm: SO_DELIVERYTERMS[0],
    };
    if (supplier?.supplier) {
      supplierTermOptions = getSupplierTermOptionFromSupplier(supplier?.supplier, context.notify);
    }

    const palette = supplier
      ? getPaletteForCommodity(supplier?.supplier, commodity?._id.toString(), order ? order.transport : T_SEAFREIGHT)
      : getStandardPackagingDimension();

    return {
      saving: false,
      loading: !!loading,
      contract,
      order,
      matchingOrders,
      article: commodity,
      supplier: supplier,
      supplierPalette: {
        value: palette._id.toString(),
        label: getPackagingDimensionString(palette),
        object: palette,
      },
      selectedOrders: existingSupplierOrder
        ? existingSupplierOrder.customerOrders
            .map((cO) => cO)
            .concat(existingSupplierOrder.customerContracts?.map((cC) => cC) || [])
        : order
        ? [order._id.toString()]
        : contract
        ? [contract._id.toString()]
        : [],
      warehouse: existingSupplierOrder ? existingSupplierOrder.warehouseAmount > 0 : !!props.forStock,
      warehouseAmount: existingSupplierOrder?.warehouseAmount ?? (order ? 0 : forStock ? fallbackAmount : 0),
      noteInternal:
        existingSupplierOrder && existingSupplierOrder?.noteInternal.length > 0
          ? existingSupplierOrder.noteInternal[0].note
          : "",
      targetDate: existingSupplierOrder?.targetDate ?? new Date(),
      etd: existingSupplierOrder?.etd ?? etd,
      startingSeaport: existingSupplierOrder?.purchaseOrderInformation?.startingSeaport,
      startingAirport: existingSupplierOrder?.purchaseOrderInformation?.startingAirport,
      startingEUWarehouse: existingSupplierOrder?.purchaseOrderInformation?.startingEUWarehouse,
      calculation: null,
      shippingInstruction: order
        ? order.transport === T_AIRFREIGHT
          ? shippingInstructionsAir
          : order.transport === T_EUSTOCK
          ? shippingInstructionsEUStock
          : shippingInstructionsSea
        : shippingInstructionsSea,
      calculationValues: undefined,
      transportMethod: existingSupplierOrder?.transport ?? (order ? order.transport : T_SEAFREIGHT),
      totalAmount:
        existingSupplierOrder?.amount ?? (forStock ? fallbackAmount : order || contract ? 0 : fallbackAmount),
      supplierTermOptions: supplierTermOptions,
      customerOrderOptions:
        existingSupplierOrder?.customerOrders.map((cO) =>
          getCustomerTermOptionFromCustomerOrder(
            extendCustomerOrder(getDocFromCollection(context.customerOrder, cO)!, context)
          )
        ) ?? (order ? [getCustomerTermOptionFromCustomerOrder(extendCustomerOrder(order, context))] : []),
      followUpCostEUStock:
        existingSupplierOrder?.calculationDetails &&
        "totalFollowUpCost" in existingSupplierOrder.calculationDetails.details.finalValues
          ? existingSupplierOrder.calculationDetails?.details.finalValues.totalFollowUpCost
          : 0,
      currency: existingSupplierOrder
        ? { value: existingSupplierOrder.currency, label: existingSupplierOrder.currency }
        : DEFAULT_SO_CURRENCY_OPTION,
      preferredSupplierInvalid,
    };
  };

  /**
   * Get the default state asynchronously populated with calculated target date and calculation
   * @param props the components properties
   * @param cOrder optional, a customer order
   * @param cContract optional, a customer contract
   * @returns {Promise<CreateSupplierOrderState>} a component state object
   */
  getDefaultStateAsync = async (
    props: CreateSupplierOrderProps,
    cOrder?: CustomerOrder,
    cContract?: CustomerContract
  ): Promise<CreateSupplierOrderState> => {
    const state = this.getDefaultState(props, cOrder, cContract);
    state.targetDate = await getSupplierOrderTargetDate(
      state.order ? state.order.transport : state.transportMethod,
      state.supplier?.supplier,
      state.supplier?.price,
      state.article
    );
    const calculation = await this.getCalculationForState(state);
    if (calculation) state.calculation = calculation;
    return state;
  };

  /**
   * Get a calculation object for a state
   * @param calculationValues object containing all values relevant for calculation
   * @param selectedSupplier optional, a selected supplier with price
   * @param followUpCostEUStock number, optional, the transport cost from supplier to our warehouse for EU Stock
   * @returns {Promise<SeaFreightPriceCalculation | AirFreightPriceCalculation | EUStockPriceCalculation | undefined>} calculation object or undefined
   */
  getCalculationForSupplier = async (
    calculationValues: SeaFreightCalculationValues | AirFreightCalculationValues | EUStockCalculationValues,
    selectedSupplier?: SelectedSupplier,
    followUpCostEUStock?: number
  ): Promise<SeaFreightPriceCalculation | AirFreightPriceCalculation | EUStockPriceCalculation | undefined> => {
    const { article, order, contract } = this.state;
    const supplier = selectedSupplier ?? this.state.supplier;
    return this.getCalculation(calculationValues, order, contract, article, supplier, followUpCostEUStock);
  };

  /**
   * Get a calculation object for a state
   * @param state optional, a component state
   * @returns {Promise<SeaFreightPriceCalculation | AirFreightPriceCalculation | EUStockPriceCalculation | undefined>} calculation object or undefined
   */
  getCalculationForState = async (
    state?: CreateSupplierOrderState
  ): Promise<SeaFreightPriceCalculation | AirFreightPriceCalculation | EUStockPriceCalculation | undefined> => {
    const { article, supplier, order, calculationValues, contract, followUpCostEUStock } = state ?? this.state;
    return this.getCalculation(calculationValues, order, contract, article, supplier, followUpCostEUStock);
  };

  /**
   * Get a calculation object
   * @param calculationValues object containing all values relevant for calculation
   * @param order optional, customer order
   * @param contract optional, customer contract
   * @param article optional, commodity or finished product document
   * @param supplier optional, selected supplier with price
   * @param followUpCostEUStock number, optional, transport costs from supplier to our warehouse for EU stock
   * @returns {Promise<SeaFreightPriceCalculation | AirFreightPriceCalculation | EUStockPriceCalculation | undefined>} calculation object or undefined
   */
  getCalculation = async (
    calculationValues: SeaFreightCalculationValues | AirFreightCalculationValues | EUStockCalculationValues | undefined,
    order?: CustomerOrder,
    contract?: CustomerContract,
    article?: Commodity | FinishedProduct,
    supplier?: SelectedSupplier | null,
    followUpCostEUStock?: number
  ): Promise<SeaFreightPriceCalculation | AirFreightPriceCalculation | EUStockPriceCalculation | undefined> => {
    const { forStock } = this.props;
    const document = order || contract;
    if ((!forStock && !document) || !article || !supplier || !calculationValues) return;
    const { warehouse, warehouseAmount, currency } = this.state;
    if (isSeaFreightValues(calculationValues))
      return await calculateSeaFreightPrice(
        document ? this.getTotalAmount() : this.state.totalAmount,
        warehouse ? warehouseAmount : 0,
        supplier.price,
        calculationValues,
        this.exchangeRates,
        currency.value
      );
    else if (isAirFreightValues(calculationValues)) {
      return await calculateAirFreightPrice(
        document ? this.getTotalAmount() : this.state.totalAmount,
        warehouse ? warehouseAmount : 0,
        supplier.price,
        calculationValues,
        this.exchangeRates,
        currency.value
      );
    } else if (isEUStockValues(calculationValues) && followUpCostEUStock !== undefined) {
      return await calculateEUStockPrice(
        document ? this.getTotalAmount() : this.state.totalAmount,
        warehouse ? warehouseAmount : 0,
        supplier.price,
        calculationValues,
        this.exchangeRates,
        followUpCostEUStock,
        currency.value
      );
    }
  };

  getPreferredSupplier = () => {
    const { order, contract, matchingOrders, selectedOrders } = this.state;
    return (
      order?.supplier ||
      contract?.supplier ||
      matchingOrders.find((mO) => mO.supplier && selectedOrders.includes(mO._id.toString()))?.supplier
    );
  };

  getCustomerOrder = (props: CreateSupplierOrderProps) => {
    if (props.match.params.id) return getDocFromCollection(props.context.customerOrder, props.match.params.id);
    return undefined;
  };

  getMatchingOrdersAndContract = (
    document: CustomerOrder | CustomerContract | undefined,
    context: DataContextInternalType
  ) => {
    const { existingSupplierOrder } = this.props;
    const { customerContract, customerOrder, supplierOrder } = context;
    let filteredContracts: Array<CustomerContract> = [];
    let filteredOrders: Array<CustomerOrder> = [];
    if (document) {
      const commodityId = document.commodity._id.toString();
      const isOrder = isCustomerOrder(document);
      const unit = isOrder ? document.unit : document.commodity.unit;
      filteredContracts = customerContract.filter(
        (cC) =>
          cC._id.toString() !== document._id.toString() &&
          cC.commodity._id.toString() === commodityId &&
          cC.commodity.unit === unit &&
          cC.state === CC_STATE.OPEN &&
          ((!document.supplier && !cC.supplier) || document.supplier === cC.supplier) &&
          !supplierOrder.some((sO) => sO.customerContracts?.some((cC2) => cC._id.toString() === cC2))
      );
      filteredOrders = customerOrder.filter(
        (cO) =>
          cO._id.toString() !== document._id.toString() &&
          cO.commodity._id.toString() === commodityId &&
          cO.commodity.unit === unit &&
          cO.state === CO_ORDEREDBYCUSTOMER &&
          (!isOrder || document.transport === cO.transport) &&
          ((!document.supplier && !cO.supplier) || document.supplier === cO.supplier) &&
          !supplierOrder.some((sO) => sO.customerOrders.some((cO2) => cO._id.toString() === cO2))
      );
    }
    if (existingSupplierOrder) {
      for (let i = 0; i < existingSupplierOrder.customerOrders.length; i++) {
        const cO = existingSupplierOrder.customerOrders[i];
        if (!filteredOrders.find((fo) => fo._id.toString() === cO) && cO !== document?._id.toString()) {
          const cOObj = getDocFromCollection(context.customerOrder, cO);
          if (cOObj) filteredOrders.push(cOObj);
        }
      }
      if (existingSupplierOrder.customerContracts) {
        for (let i = 0; i < existingSupplierOrder.customerContracts.length; i++) {
          const cC = existingSupplierOrder.customerContracts[i];
          if (!filteredContracts.find((fc) => fc._id.toString() === cC) && cC !== document?._id.toString()) {
            const cCObj = getDocFromCollection(context.customerContract, cC);
            if (cCObj) filteredContracts.push(cCObj);
          }
        }
      }
    }
    return (filteredOrders as Array<CustomerOrder | CustomerContract>).concat(filteredContracts);
  };

  getTotalAmount = () => {
    const { forStock } = this.props;
    const { order, matchingOrders, warehouse, warehouseAmount, selectedOrders, totalAmount, contract } = this.state;
    if (forStock) return totalAmount;
    if (!order && !contract) return 0;
    let amount = 0;
    if (order && selectedOrders.includes(order._id.toString())) amount += order.amount;
    if (contract && selectedOrders.includes(contract._id.toString()))
      amount += contract.contractInformation.totalAmount;
    amount += matchingOrders.reduce(
      (a, b) =>
        a +
        (selectedOrders.includes(b._id.toString())
          ? isCustomerOrder(b)
            ? b.amount
            : b.contractInformation.totalAmount
          : 0),
      0
    );

    if (warehouse) amount += warehouseAmount;
    return amount;
  };

  getTotalTurnover = () => {
    const { order, contract, matchingOrders, selectedOrders, currency } = this.state;
    if (!order && !contract) return 0;
    let turnover = 0;
    if (order && selectedOrders.includes(order._id.toString()))
      turnover += convertCurrency(
        order.totalPrice * (1 - (order.discount || 0) / 100),
        order.currency,
        currency.value,
        this.exchangeRates
      );
    if (contract && selectedOrders.includes(contract._id.toString()))
      turnover += convertCurrency(
        contract.priceInformation.totalPrice * (1 - (contract.priceInformation.discount || 0) / 100),
        contract.priceInformation.currency,
        currency.value,
        this.exchangeRates
      );
    turnover += matchingOrders.reduce(
      (a, b) =>
        a +
        (selectedOrders.includes(b._id.toString())
          ? isCustomerOrder(b)
            ? convertCurrency(
                b.totalPrice * (1 - (b.discount || 0) / 100),
                b.currency,
                currency.value,
                this.exchangeRates
              )
            : convertCurrency(
                b.priceInformation.totalPrice * (1 - (b.priceInformation.discount || 0) / 100),
                b.priceInformation.currency,
                currency.value,
                this.exchangeRates
              )
          : 0),
      0
    );
    return turnover;
  };

  validateData = () => {
    const { forStock } = this.props;
    const {
      order,
      contract,
      article,
      targetDate,
      supplier,
      startingSeaport,
      startingAirport,
      startingEUWarehouse,
      transportMethod,
      selectedOrders,
      matchingOrders,
    } = this.state;
    const errors = [];
    const warnings = [];
    const document = order || contract;
    if (!forStock && !document) errors.push("No order or contract selected");
    if (!article) errors.push("No article selected");
    if (article && (article.disabled || !article.approved)) errors.push("Article is disabled. Cannot create order");
    if (!supplier) errors.push("No valid price and supplier selected");
    if (transportMethod === T_SEAFREIGHT && !startingSeaport) errors.push("Select starting seaport");
    if (transportMethod === T_AIRFREIGHT && !startingAirport) errors.push("Select starting airport");
    if (
      (transportMethod === T_EUSTOCK || transportMethod === T_ROADFREIGHT || transportMethod === T_RAILFREIGHT) &&
      !startingEUWarehouse
    )
      errors.push("Select starting address");
    if (this.getTotalAmount() <= 0) errors.push("Invalid amount");
    if (!forStock && selectedOrders.length > 0) {
      const documents = matchingOrders.filter((doc) => selectedOrders.includes(doc._id.toString()));
      if (contract && selectedOrders.includes(contract._id.toString())) documents.push(contract);
      if (order && selectedOrders.includes(order._id.toString())) documents.push(order);
      const conflictingOrders: Array<string> = [];
      documents.forEach((doc) => {
        if (doc.targetDate && doc.targetDate < targetDate)
          conflictingOrders.push(
            `${getIdentifierForOrderOrContract(doc)} (CW ${getCW(doc.targetDate)}-${doc.targetDate.getFullYear()})`
          );
      });
      if (conflictingOrders.length > 0)
        warnings.push(
          `Target weeks of ${conflictingOrders.join(", ")} conflict with selected ETA CW ${getCW(
            targetDate
          )}-${targetDate.getFullYear()}`
        );
    }
    return [errors, warnings];
  };

  render() {
    const { context, forStock, existingSupplierOrder } = this.props;
    const {
      saving,
      order,
      contract,
      article,
      matchingOrders,
      selectedOrders,
      warehouse,
      warehouseAmount,
      supplier,
      supplierPalette,
      noteInternal,
      targetDate,
      etd,
      startingAirport,
      startingSeaport,
      startingEUWarehouse,
      shippingInstruction,
      calculation: calculationDetails,
      calculationValues,
      transportMethod,
      supplierTermOptions,
      loading,
      customerOrderOptions,
      followUpCostEUStock,
      currency,
      preferredSupplierInvalid,
    } = this.state;

    if (loading) return <SimpleSplashScreen description={"Loading order information..."} />;
    if (!calculationValues) return null;
    if (!order && !contract && !forStock) return null;
    if (!article) return null;
    const totalAmount = this.getTotalAmount();
    const [errors, warnings] = this.validateData();
    const preferredSupplier = this.getPreferredSupplier();
    // necessary for layout
    const cORows = [];
    if (customerOrderOptions) {
      for (let i = 0; i < customerOrderOptions.length; i = i + 2) {
        if (customerOrderOptions[i + 1]) {
          cORows.push(
            <div className="row mb-2">
              <div className="col-6">
                <CustomerTerms
                  customerTerm={customerOrderOptions[i]}
                  onChangePaymentTerms={this.handleChangeCustomerPaymentTerms}
                  onChangeCustomPaymentTerm={this.handleChangeCustomerCustomPaymentTerms}
                  onChangeCustomPaymentTermCondition={this.handleChangeCustomerCustomPaymentTermConditions}
                  onChangeDeliveryTerms={this.handleChangeCustomerDeliveryTerms}
                  onChangeDeliveryCity={this.handleChangeCustomerDeliveryCity}
                  onChangeComment={this.handleChangeCustomerNote}
                />
              </div>
              <div className="col-6">
                <CustomerTerms
                  customerTerm={customerOrderOptions[i + 1]}
                  onChangePaymentTerms={this.handleChangeCustomerPaymentTerms}
                  onChangeCustomPaymentTerm={this.handleChangeCustomerCustomPaymentTerms}
                  onChangeCustomPaymentTermCondition={this.handleChangeCustomerCustomPaymentTermConditions}
                  onChangeDeliveryTerms={this.handleChangeCustomerDeliveryTerms}
                  onChangeDeliveryCity={this.handleChangeCustomerDeliveryCity}
                  onChangeComment={this.handleChangeCustomerNote}
                />
              </div>
            </div>
          );
        } else {
          cORows.push(
            <div className="row mb-2">
              <div className="col-6">
                <CustomerTerms
                  customerTerm={customerOrderOptions[i]}
                  onChangePaymentTerms={this.handleChangeCustomerPaymentTerms}
                  onChangeCustomPaymentTerm={this.handleChangeCustomerCustomPaymentTerms}
                  onChangeCustomPaymentTermCondition={this.handleChangeCustomerCustomPaymentTermConditions}
                  onChangeDeliveryTerms={this.handleChangeCustomerDeliveryTerms}
                  onChangeDeliveryCity={this.handleChangeCustomerDeliveryCity}
                  onChangeComment={this.handleChangeCustomerNote}
                />
              </div>
            </div>
          );
        }
      }
    }
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="container-xxl">
          <div className="ms-lg-15">
            <div className="card bg-white">
              <div className="card-header mt-6 border-none">
                <div className="card-title flex-column">
                  <h2 className="mb-1">Create Order{forStock && <span> for Stock</span>}</h2>
                </div>
              </div>
              <div className="card-body p-9 pt-0">
                <div className=" pt-0">
                  <div className="py-0">
                    <span className="text-white">
                      <div className="nav-group nav-group-fluid custom-form-control">
                        <button
                          className={
                            "btn btn-sm btn-color-muted fw-bolder px-4 custom-form-control custom-form-control-active"
                          }
                        >
                          Create Order
                        </button>
                        {["Ordered", "Shipping", "Processing", "Closed"].map((b) => (
                          <button
                            key={b}
                            disabled={true}
                            className={"btn btn-sm btn-color-muted fw-bolder px-4 custom-form-control disabled"}
                          >
                            {b}
                          </button>
                        ))}
                      </div>
                    </span>
                  </div>
                  {!forStock && (order || contract) && (
                    <>
                      <div className="border-bottom-dark-gray pt-5" />
                      <CreateSupplierOrderDestinations
                        order={(order || contract)!} // If we got here there is an order or contract
                        matchingOrders={matchingOrders}
                        selectedOrders={selectedOrders}
                        warehouse={warehouse}
                        warehouseAmount={warehouseAmount}
                        onSelectOrder={this.handleSelectDocument}
                        onDeselectOrder={this.handleDeselectDocument}
                        onToggleWarehouseAmount={this.handleToggleWarehouseAmount}
                        onChangeWarehouseAmount={this.handleChangeWarehouseAmount}
                      />
                      {cORows.length > 0 && <div className="border-bottom-dark-gray pt-5" />}
                      {cORows.map((row, idx) => {
                        return <React.Fragment key={idx}>{row}</React.Fragment>;
                      })}
                    </>
                  )}
                  <div className="border-bottom-dark-gray pt-5" />
                  <CreateSupplierOrderSettings
                    context={context}
                    article={article}
                    supplier={supplier}
                    supplierPalette={supplierPalette}
                    supplierTermOptions={supplierTermOptions}
                    targetDate={targetDate}
                    etd={etd}
                    totalAmount={totalAmount}
                    seaport={startingSeaport}
                    airport={startingAirport}
                    euWarehouse={startingEUWarehouse}
                    shippingInstruction={shippingInstruction}
                    forStock={forStock}
                    transportMethod={transportMethod}
                    preferredSupplier={preferredSupplierInvalid ? undefined : preferredSupplier}
                    followUpCostEUStock={followUpCostEUStock ? followUpCostEUStock : 0}
                    currency={currency}
                    existingSupplierOrder={existingSupplierOrder}
                    onChangeSeaport={this.handleChangeSeaport}
                    onChangeAirport={this.handleChangeAirport}
                    onChangeEUWarehouse={this.handleChangeEUWarehouse}
                    onChangeTransportMethod={this.handleChangeTransportMethod}
                    onChangeTotalAmount={this.handleChangeTotalAmount}
                    onChangeSupplier={this.handleChangeSupplier}
                    onChangeTargetDate={this.handleChangeTargetDate}
                    onChangeShippingInstruction={this.handleChangeShippingInstruction}
                    onChangeNotify={this.handleChangeNotify}
                    onChangePaymentTerms={this.handleChangePaymentTerms}
                    onChangeCustomPaymentTerm={this.handleChangeCustomPaymentTerm}
                    onChangeCustomPaymentTermCondition={this.handleChangeCustomPaymentTermCondition}
                    onChangeDeliveryTerms={this.handleChangeDeliveryTerms}
                    onChangeFollowUpCostEUStock={this.handleChangeFollowUpCostEUStock}
                    onChangeETD={this.handleChangeETD}
                    onChangeCurrency={this.handleChangeCurrency}
                  />
                  <div className="border-bottom-dark-gray pt-5" />
                  <div className="row">
                    <div className="col-6">
                      <div className="fw-bolder text-white fs-3 my-5">Additional Information</div>
                      <div className="row">
                        <div className="col-12">
                          <textarea
                            className="form-control custom-form-control"
                            rows={5}
                            value={noteInternal}
                            placeholder={"Additional Notes..."}
                            onChange={(e) => this.setState({ noteInternal: e.target.value })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <CreateSupplierOrderCalculation
                        article={article}
                        totalAmount={totalAmount}
                        totalTurnover={this.getTotalTurnover()}
                        selectedSupplier={supplier}
                        calculationDetails={calculationDetails}
                        calculationValues={calculationValues}
                        currency={currency.value}
                        onSetCalculationValues={this.handleSetCalculationValues}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer pt-0 border-0">
                <div className="border-bottom-dark-gray pt-5" />
                <div className="pt-3">
                  <div className="d-flex pt-3 align-items-center w-100">
                    <ErrorOverlayButton
                      errors={errors}
                      className="btn btn-link btn-sm btn-text-white ml-auto"
                      saving={!!saving}
                      buttonText={saving === "draft" ? "Generating" : "Preview"}
                      onClick={this.handleCreateDraft}
                    />
                    <ErrorOverlayButton
                      errors={errors}
                      warnings={warnings}
                      className={"btn btn-outline btn-outline-white btn-sm ml-4"}
                      buttonText={
                        existingSupplierOrder ? "Edit Order" : saving === "order" ? "Creating Order" : "Create Order"
                      }
                      saving={!!saving}
                      onClick={this.handleCreateOrder}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateSupplierOrder;
