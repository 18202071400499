import _ from "lodash";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { DataContextAnonymousType, DataContextCustomerType, isAnonymousContext } from "../../../context/dataContext";
import { paginate, PaginationState } from "../../common/Pagination";
import Search from "../../common/Search";
import BaseListing from "../../common/BaseListing";
import { doFuseSearch, getComponentState } from "../../../utils/baseUtils";
import CustomerRequestRow from "./CustomerRequestRow";
import { SelectOption } from "../../common/CustomSelect";
import { SortOrder } from "../../../utils/filterUtils";
import CustomerRequestFilter from "./CustomerRequestFilter";
import { CustomerRequest, CustomerRequestState } from "../../../model/customerRequest.types";
import { CR_SORTOPTIONS } from "../../../utils/customerRequestUtils";
import ListPlaceholder from "../../common/ListPlaceholder";
import CreateCustomerAccountModal from "../../userData/anonymous/CreateCustomerAccountModal";

interface CustomerRequestListingProps {
  context: DataContextCustomerType | DataContextAnonymousType;
}

interface CustomerRequestListingState extends PaginationState {
  search: string;
  status?: SelectOption;
  type?: SelectOption;
  age?: SelectOption;
  sortColumn: SelectOption;
  sortOrder: SortOrder;
}

const COMPONENT_NAME = "CustomerRequestsListing";

class CustomerRequestListing extends PureComponent<CustomerRequestListingProps, CustomerRequestListingState> {
  constructor(props: CustomerRequestListingProps) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 20,
      search: "",
      sortColumn: CR_SORTOPTIONS[0],
      sortOrder: SortOrder.DESC,
    };
  }

  componentDidMount() {
    const state = getComponentState(this.props.context, COMPONENT_NAME);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(COMPONENT_NAME, this.state);
  }

  handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    // Special handling for sortBy when searching
    if (!val.trim())
      this.setState({
        search: e.target.value,
        currentPage: 1,
        sortColumn: CR_SORTOPTIONS[0],
      });
    else
      this.setState({
        search: e.target.value,
        currentPage: 1,
        sortColumn: { value: "", label: "Best Match" },
        sortOrder: SortOrder.DESC,
      });
  };

  handleChangePageSize = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleChangeCurrentPage = (currentPage: number) => this.setState({ currentPage });
  handleChangeSelect = (option: SelectOption | undefined, name: string) =>
    // @ts-ignore
    this.setState({ [name]: option, currentPage: 1 });
  handleToggleSortOrder = () => {
    const { sortOrder } = this.state;
    this.setState({ sortOrder: sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC, currentPage: 1 });
  };

  handleClickNewRequestAnonymous = () => {
    (this.props.context.refMap["CreateCustomerAccountModal"] as CreateCustomerAccountModal).handleShow();
  };

  getFilteredRequests = () => {
    const { customerRequest } = this.props.context;
    const { search, sortOrder, sortColumn, age, type, status } = this.state;

    let requestFiltered: Array<CustomerRequest> = customerRequest;
    if (search.trim())
      requestFiltered = doFuseSearch(requestFiltered, search, [
        "requestNo",
        "commodity.title.en",
        "customerReference",
        "message",
        "type",
        "state",
      ]);
    if (status) requestFiltered = requestFiltered.filter((r) => r.state === status.value);
    if (age) {
      const now = new Date();
      if (age.value === "lessThan48h") {
        const comparisonDate = new Date(new Date().setHours(now.getHours() - 48));
        requestFiltered = requestFiltered.filter((r) => r.requestedOn > comparisonDate);
      } else if (age.value === "lessThan1Week") {
        const comparisonDate = new Date(new Date().setDate(now.getDate() - 7));
        requestFiltered = requestFiltered.filter((r) => r.requestedOn > comparisonDate);
      } else if (age.value === "old") {
        const comparisonDate = new Date(new Date().setDate(now.getDate() - 7));
        requestFiltered = requestFiltered.filter((r) => r.requestedOn <= comparisonDate);
      } else {
        console.error("Invalid age value:", age.value);
      }
    }
    if (type) requestFiltered = requestFiltered.filter((r) => r.type === type.value);

    if (!sortColumn.value && search.trim()) {
      if (sortOrder === SortOrder.DESC) return requestFiltered;
      return requestFiltered;
    }

    // Inactive requests always at the end
    const active: Array<CustomerRequest> = [];
    const inactive: Array<CustomerRequest> = [];
    requestFiltered.forEach((r) => (r.state === CustomerRequestState.OPEN ? active.push(r) : inactive.push(r)));
    requestFiltered = _.orderBy(active, sortColumn.value, sortOrder).concat(
      _.orderBy(inactive, sortColumn.value, sortOrder)
    );

    return requestFiltered;
  };

  render() {
    const { context } = this.props;
    const { pageSize, currentPage, search, sortColumn, sortOrder, status, age, type } = this.state;

    const requests = this.getFilteredRequests();
    const paginatedRequests = paginate(requests, currentPage, pageSize);
    const requestHeaderDefinition = [
      { title: "Request" },
      { title: "Type" },
      { title: "Quantity" },
      { title: "Article", style: { width: "30%" } },
      { title: "Message", style: { width: "30%" } },
      { title: "Status" },
      { title: "" },
    ];

    const isAV = isAnonymousContext(context);

    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="card bg-white min-h-100">
              <div className="card-body">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder mb-3 fs-3rem">Requests</span>
                  {isAV ? (
                    <button
                      className="btn btn-light btn-text float-right"
                      onClick={this.handleClickNewRequestAnonymous}
                    >
                      New Request
                    </button>
                  ) : (
                    <Link to="/articles">
                      <button className="btn btn-light btn-text float-right">New Request</button>
                    </Link>
                  )}
                  <button className="btn btn-light btn-text float-right disabled mr-3" disabled={true}>
                    Export
                  </button>
                </h3>
                <p className="mb-15">
                  <span className="mb-3 fs-5 text-muted">
                    Showing {paginatedRequests.length} of {requests.length} matching requests
                  </span>
                </p>
                <div className="card mb-10 bg-white border-none bg-custom-medium-gray p-5 ">
                  <div className="row g-8 mb-4">
                    <div className="col-lg-6 col-8">
                      <label className="fs-6 form-label fw-bolder text-dark">Search Query</label>
                      <Search placeholder="Search requests..." onSearch={this.handleChangeSearch} value={search} />
                    </div>
                  </div>
                  <CustomerRequestFilter
                    search={search}
                    sortColumn={sortColumn}
                    sortOrder={sortOrder}
                    age={age}
                    type={type}
                    status={status}
                    onChangeSelect={this.handleChangeSelect}
                    onToggleSortOrder={this.handleToggleSortOrder}
                  />
                </div>
                <BaseListing
                  headerDefinition={requestHeaderDefinition}
                  bodyContent={
                    <>
                      {requests.length > 0 ? (
                        paginatedRequests.map((cO) => (
                          <CustomerRequestRow key={cO._id.toString()} request={cO} {...this.props} />
                        ))
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={8}>
                            <ListPlaceholder type={"request"} />
                          </td>
                        </tr>
                      )}
                    </>
                  }
                  baseSize={20}
                  noHover={true}
                  documents={requests}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  onPageChange={this.handleChangeCurrentPage}
                  onPageSizeChange={this.handleChangePageSize}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerRequestListing;
