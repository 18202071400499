import _ from "lodash";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import { UploadedFile, UploadedFileExtended } from "../../../../model/commodity.types";
import { DataContextInternalType } from "../../../../context/dataContext";
import { F_SIGNATURE, getUserName, INTERNAL, SelectedSpecificationUserData } from "../../../../utils/userUtils";
import { callPushToArray, getDocFromCollection } from "../../../../utils/baseUtils";
import userService from "../../../../services/userService";
import { SelectOption } from "../../../common/CustomSelect";
import { resolveFilePath, uploadPublicFile } from "../../../../utils/fileUtils";
import { UserFile } from "../../../../model/userData.types";
import { Action, COMMODITY, transaction, USERDATA } from "../../../../services/dbService";
import { createPDF } from "../../../../utils/pdfUtils";
import { createMasterSpecificationHTML } from "../../../../utils/pdf/masterSpecificationGenerationUtils";
import {
  D_MASTERSPECIFICATION,
  getCommodityTimelineEntry,
  T_COMMODITYCREATED,
  T_COMMODITYEDITED,
  T_MASTERSPECCREATED,
} from "../../../../utils/commodityUtils";
import DocumentApprovalUser from "../../../common/internal/DocumentApprovalUser";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import { T_FINISHEDPRODUCTCREATED, T_FINISHEDPRODUCTEDITED } from "../../../../utils/finishedProductUtils";
import { MasterSpecificationType } from "../../common/CustomTypes";

interface CreateMasterSpecificationModalProps {
  article: MasterSpecificationType;
  masterSpecification?: UploadedFileExtended;
  buttonClasses?: string;
  buttonText?: string;
  context: DataContextInternalType;
}

interface CreateMasterSpecificationModalState {
  show: boolean;
  generating: boolean;
  originator?: SelectedSpecificationUserData;
  approver?: SelectedSpecificationUserData;
}

class CreateMasterSpecificationModal extends PureComponent<
  CreateMasterSpecificationModalProps,
  CreateMasterSpecificationModalState
> {
  constructor(props: CreateMasterSpecificationModalProps) {
    super(props);
    const approver = getDocFromCollection(props.context.userData, userService.getUserId());
    this.state = {
      show: false,
      generating: false,
      originator: _.cloneDeep(this.getDefaultOriginator(props)),
      approver: approver
        ? _.cloneDeep({ ...approver, signature: approver.files?.find((f) => f.type === F_SIGNATURE), date: new Date() })
        : undefined,
    };
  }

  componentDidUpdate(prevProps: CreateMasterSpecificationModalProps) {
    const { context, masterSpecification } = this.props;
    const { originator, approver } = this.state;
    if (!_.isEqual(prevProps.masterSpecification, masterSpecification)) {
      this.setState({ originator: _.cloneDeep(this.getDefaultOriginator(this.props)) });
    } else {
      const originatorOld = originator && getDocFromCollection(prevProps.context.userData, originator._id);
      const approverOld = approver && getDocFromCollection(prevProps.context.userData, approver._id);
      const originatorNew = originator && getDocFromCollection(context.userData, originator._id);
      const approverNew = approver && getDocFromCollection(context.userData, approver._id);
      if (!_.isEqual(originatorOld, originatorNew) || !_.isEqual(approverOld, approverNew)) {
        const newState: Pick<CreateMasterSpecificationModalState, "approver" | "originator"> = {};
        if (approverOld && approver)
          newState.approver = approverNew
            ? { ...approverNew, position: approverOld.position, date: approver.date }
            : undefined;
        if (originatorOld && originator)
          newState.originator = originatorNew
            ? { ...originatorNew, position: originatorOld.position, date: originator.date }
            : undefined;
        this.setState(newState);
      }
    }
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => !this.state.generating && this.setState({ show: false });

  handleSelectUser = (type: "approver" | "originator", e: SelectOption<SelectedSpecificationUserData>) => {
    const userData = _.cloneDeep(e.object);
    if (!userData) return; // Should not happen
    userData.signature = userData?.files?.find((f) => f.type === F_SIGNATURE);
    userData.date = new Date();
    // @ts-ignore
    this.setState({ [type]: userData });
  };

  handleChangePosition = (type: "approver" | "originator", e: React.ChangeEvent<HTMLInputElement>) => {
    const userData = { ..._.get(this.state, type) };
    userData.position = e.target.value;
    // @ts-ignore
    this.setState({ [type]: userData });
  };

  handleChangeDate = (type: "approver" | "originator", e: React.ChangeEvent<HTMLInputElement>) => {
    const userData = { ..._.get(this.state, type) };
    const targetDate = new Date(e.target.value);
    if (!targetDate) return;
    userData.date = targetDate;
    // @ts-ignore
    this.setState({ [type]: userData });
  };

  handleUploadSignature = async (type: "approver" | "originator", e: React.ChangeEvent<HTMLInputElement>) => {
    const userData = { ..._.get(this.state, type) };
    const file = e.target.files ? e.target.files[0] : null;
    if (!file || !userData) return;
    const alias = uploadPublicFile(file, `Signature-${userData.prename || ""}${userData.surname || ""}`);
    if (!alias) {
      toast.error("File upload failed.");
      return;
    }
    const userFile: UserFile = {
      _id: new BSON.ObjectId(),
      path: alias,
      type: F_SIGNATURE,
      date: new Date(),
      uploadedBy: userService.getUserId(),
      isPublic: true,
    };
    const action: Action = {
      collection: USERDATA,
      filter: { _id: userData._id },
      push: { files: userFile },
    };
    const result = await transaction([action]);
    if (result) {
      toast.success("Signature uploaded successfully");
      userData.signature = userFile;
      // @ts-ignore
      this.setState({ [type]: userData });
    } else {
      toast.error("Error uploading signature image");
    }
  };

  handleRemoveSignature = async (type: "approver" | "originator") => {
    const userData = { ..._.get(this.state, type) };
    const action: Action = {
      collection: USERDATA,
      filter: { _id: userData._id },
      pull: { files: userData.signature },
    };
    const result = await transaction([action]);
    if (result) {
      toast.success("Signature deleted successfully");
      userData.signature = undefined;
      // @ts-ignore
      this.setState({ [type]: userData });
    } else {
      toast.error("Error deleting signature");
    }
  };

  handleCreateMasterSpecification = async () => {
    const { article, masterSpecification } = this.props;
    const { originator, approver } = this.state;
    if (!originator || !approver) return;
    this.setState({ generating: true });
    try {
      const version = masterSpecification && masterSpecification.version ? masterSpecification.version + 1 : 1;
      const articleNo = article.type === COMMODITY ? article.commodity.articleNo : article.finishedProduct.articleNo;
      const path = await createPDF(
        createMasterSpecificationHTML(article, originator, approver, version),
        `MasterSpecification-${articleNo}`,
        undefined,
        {
          marginLeft: "2cm",
          marginBottom: "4.2cm",
          footerHtml:
            process.env.NODE_ENV === "development"
              ? "https://demohub.rawbids.com/storage/specificationFooter.html" // Only for dev since localhost is not accessible. Update file via 'scp specificationFooter.html mediahub-rawbids-demo:~/work/storage/specificationFooter.html'
              : process.env.REACT_APP_BASE_URL + "/specificationFooter.html", // load specification footer from mediahub
          footerSpacing: 5,
        }
      );
      if (path) {
        const file: UploadedFile = {
          _id: new BSON.ObjectId(),
          name: "Master Specification",
          path,
          type: D_MASTERSPECIFICATION,
          date: new Date(),
          originator: originator._id.toString(),
          approver: approver._id.toString(),
          approvalDate: new Date(),
          signedBy: null,
          version,
        };
        const timelineEntry = getCommodityTimelineEntry(T_MASTERSPECCREATED, D_MASTERSPECIFICATION, path);
        let res;
        const objectId = article.type === COMMODITY ? article.commodity._id : article.finishedProduct._id;
        if (masterSpecification) {
          const action: Action = {
            collection: article.type,
            filter: { _id: objectId },
            update: { "documents.$[filter]": file }, // replace existing master spec
            arrayFilters: [{ "filter._id": masterSpecification._id }],
            push: { timeline: timelineEntry },
          };
          res = await transaction([action]);
        } else {
          res = await callPushToArray(article.type, objectId, ["documents", "timeline"], [file, timelineEntry]);
        }
        if (res && (!(typeof res === "object" && "modifiedCount" in res) || res.modifiedCount)) {
          toast.success("Master specification successfully generated");
          this.setState({ show: false });
        } else toast.error("Error adding master specification.");
        window.open(resolveFilePath(path));
      } else {
        toast.error("Error creating master specification. Please try again later.");
      }
    } finally {
      this.setState({ generating: false });
    }
  };

  /**
   * Get default originator from components properties
   * @param props the components properties, including commodity and context
   * @returns {SelectedSpecificationUserData} user data extended with signature and date of last edit
   */
  getDefaultOriginator = (props: CreateMasterSpecificationModalProps) => {
    const { article, context } = props;
    const lastTimelineEntry =
      article.type === COMMODITY
        ? article.commodity.timeline
            .slice()
            .reverse()
            .find((t) => [T_COMMODITYCREATED, T_COMMODITYEDITED].includes(t.type))
        : article.finishedProduct.timeline
            .slice()
            .reverse()
            .find((t) => [T_FINISHEDPRODUCTCREATED, T_FINISHEDPRODUCTEDITED].includes(t.type));
    if (!lastTimelineEntry) return undefined;
    const user = getDocFromCollection(context.userData, lastTimelineEntry.person);
    if (!user) return undefined;
    return { ...user, signature: user.files?.find((f) => f.type === F_SIGNATURE), date: lastTimelineEntry.date };
  };

  validateData = () => {
    const { originator, approver } = this.state;
    const errors: Array<string> = [];
    if (originator) {
      if (!originator.signature) errors.push("Signature of originator missing");
    } else errors.push("No originator selected. Please select a user as originator/creator of the commodity");
    if (approver) {
      if (!approver.signature) errors.push("Signature of approver missing");
    } else errors.push("No approver selected. Please select a user that approved the commodity");
    if (originator && approver && originator._id.toString() === approver._id.toString())
      errors.push(
        "Originator and approver cannot be the same person. Please select a different originator or approver"
      );
    return errors;
  };

  render() {
    const { context, buttonClasses, buttonText } = this.props;
    const { show, generating, originator, approver } = this.state;
    const internalUsers = context.userData
      .filter((uD) => uD.type === INTERNAL)
      .map((uD) => {
        return { value: uD._id.toString(), label: getUserName(uD), object: uD as SelectedSpecificationUserData };
      });
    const errors = this.validateData();
    return (
      <>
        <button
          type="button"
          className={buttonClasses ?? "btn btn-outline btn-outline-light btn-sm mt-5 "}
          onClick={this.handleShow}
        >
          {buttonText ?? "Create Master Specification"}
        </button>
        <Modal contentClassName="bg-dark" show={show} size={"lg"} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Create Master Specification</h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="mt-5">
              <div className="row">
                <div className="col-xl-6">
                  <div className="text-white text-center h6">Originator</div>
                  <DocumentApprovalUser
                    type="originator"
                    userData={originator}
                    internalUsers={internalUsers}
                    onSelectUser={this.handleSelectUser}
                    onChangePosition={this.handleChangePosition}
                    onChangeDate={this.handleChangeDate}
                    onUploadSignature={this.handleUploadSignature}
                    onRemoveSignature={this.handleRemoveSignature}
                  />
                </div>
                <div className="col-xl-6">
                  <div className="text-white text-center h6">Approver</div>
                  <DocumentApprovalUser
                    type="approver"
                    userData={approver}
                    internalUsers={internalUsers}
                    onSelectUser={this.handleSelectUser}
                    onChangePosition={this.handleChangePosition}
                    onChangeDate={this.handleChangeDate}
                    onUploadSignature={this.handleUploadSignature}
                    onRemoveSignature={this.handleRemoveSignature}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-outline btn-outline-light" onClick={this.handleHide}>
              Close
            </button>
            <ErrorOverlayButton
              errors={errors}
              saving={generating}
              className="btn btn-sm btn-outline btn-outline-light"
              buttonText="Create Specification"
              onClick={this.handleCreateMasterSpecification}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreateMasterSpecificationModal;
