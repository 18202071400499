import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { resolveContextType } from "../../../context/dataContext";
import CommodityListing from "./CommodityListing";

interface CommodityListingParams {
  id: string;
}

interface CommodityListingWrapperProps extends RouteComponentProps<CommodityListingParams> {}

const CommodityListingWrapper: React.FunctionComponent<CommodityListingWrapperProps> = (props) => {
  const contextType = resolveContextType();
  if (!contextType) return <></>;
  // @ts-ignore Context will be needed late for the supplier / customer view to display their company
  const context = useContext(contextType);

  return <CommodityListing {...props} context={context} category={props.match.params.id} />;
};

export default CommodityListingWrapper;
