import React from "react";
import { Input } from "../Input";
import CustomSelect, { SelectOption } from "../CustomSelect";
import { Company } from "../../../model/company.types";
import { round } from "../../../utils/baseUtils";
import { DataContextInternalType } from "../../../context/dataContext";
import { Commodity } from "../../../model/commodity.types";
import { Supplier } from "../../../model/supplier.types";
import SupplierEUWidget from "../SupplierEUWidget";
import { FinishedProduct } from "../../../model/finishedProduct.types";
import { ArticleExtended, formatArticleUnit } from "../../../utils/productArticleUtils";

interface CreateCustomerOrderContractGeneralSettingsProps {
  totalAmount: number;
  pricePerUnit: number;
  discount: number;
  purchasePrice: number;
  customer?: Company;
  selectedArticle?: ArticleExtended;
  preferredSupplier?: Supplier;
  context: DataContextInternalType;
  onSelectCustomer: (e: SelectOption<Company>) => void;
  onSelectArticle: (e: SelectOption<Commodity | FinishedProduct>) => void;
  onSelectSupplier: (e: SelectOption<Supplier>) => void;
  onChangeValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CreateCustomerOrderContractGeneralSettings: React.FunctionComponent<
  CreateCustomerOrderContractGeneralSettingsProps
> = ({
  totalAmount,
  pricePerUnit,
  discount,
  purchasePrice,
  customer,
  selectedArticle,
  preferredSupplier,
  context,
  onSelectArticle,
  onSelectCustomer,
  onSelectSupplier,
  onChangeValue,
}) => {
  const { commodity, company, finishedProduct } = context;
  let articleSelections: Array<SelectOption<Commodity | FinishedProduct>> = commodity
    .filter((c) => !c.disabled && c.approved)
    .map((c) => {
      return {
        label: `${c.title.en} - ${c.subtitle.en}`,
        value: c._id.toString(),
        object: c,
      };
    });
  articleSelections = articleSelections.concat(
    finishedProduct
      .filter((fp) => !fp.disabled && fp.approved)
      .map((fp) => {
        return {
          label: `${fp.title.en} - ${fp.subtitle.en}`,
          value: fp._id.toString(),
          object: fp,
        };
      })
  );
  return (
    <>
      <div className="fw-bolder text-white fs-3 my-5">General Settings</div>
      <div className="row mb-2">
        <span className="col-4 col-form-label fs-6 fw-bold">Identifier</span>
        <div className="col-8">
          <Input
            className="form-control custom-form-control disabled"
            disabled={true}
            type="text"
            placeholder={"Auto Generated..."}
          />
          <small className="text-success mt-1">&nbsp;</small>
        </div>
      </div>
      <div className="row mb-2">
        <span className="col-4 col-form-label fs-6 fw-bold">Customer</span>
        <div className="col-8">
          <div>
            <CustomSelect
              options={company
                .filter((c) => !c.disabled)
                .map((c: Company) => {
                  return { value: c._id.toString(), label: c.name, object: c };
                })}
              value={
                customer
                  ? {
                      value: customer._id.toString(),
                      label: customer.name,
                    }
                  : null
              }
              placeholder={"Select Customer"}
              matchFormControl={true}
              onChange={onSelectCustomer}
            />
          </div>
          <small className="mt-1">&nbsp;</small>
        </div>
      </div>
      <div className="row mb-2">
        <span className="col-4 col-form-label fs-6 fw-bold">Article</span>
        <div className="col-8">
          <div>
            <CustomSelect
              options={articleSelections}
              onChange={onSelectArticle}
              matchFormControl={true}
              placeholder={"Select Article"}
              value={
                selectedArticle ? { value: selectedArticle._id.toString(), label: selectedArticle.title.en } : null
              }
            />
          </div>
          <small className="mt-1">&nbsp;</small>
        </div>
      </div>
      <div className="row mb-2">
        <span className="col-4 col-form-label fs-6 fw-bold">
          Preferred Supplier{preferredSupplier?.euSupplier && <SupplierEUWidget />}
        </span>
        <div className="col-8">
          <div>
            <CustomSelect
              options={selectedArticle?.suppliers.map((s) => {
                return {
                  label: s.supplier.name,
                  value: s.supplier._id.toString(),
                  object: s.supplier,
                };
              })}
              onChange={onSelectSupplier}
              matchFormControl={true}
              isClearable={true}
              disabled={!selectedArticle}
              placeholder={!selectedArticle ? "Select Article First" : "Select Preferred Supplier"}
              value={
                preferredSupplier ? { value: preferredSupplier._id.toString(), label: preferredSupplier.name } : null
              }
            />
          </div>
          <small className="mt-1">&nbsp;</small>
        </div>
      </div>
      <div className="row mb-2">
        <span className="col-4 col-form-label fs-6 fw-bold">Total Amount</span>
        <div className="col-8">
          <div className="input-group">
            <Input
              className="form-control custom-form-control"
              type="number"
              integerOnly={true}
              value={totalAmount}
              name="totalAmount"
              onBlur={onChangeValue}
            />
            <div className="input-group-append">
              <span className="input-group-text form-control custom-form-control">
                {selectedArticle ? formatArticleUnit(selectedArticle.unit) : "kg"}
              </span>
            </div>
          </div>
          <small className="text-success mt-1">&nbsp;</small>
        </div>
      </div>
      <div className="row mb-2">
        <span className="col-4 col-form-label fs-6 fw-bold">Price Per Unit</span>
        <div className="col-8">
          <div className="input-group">
            <Input
              className="form-control custom-form-control"
              type="number"
              value={pricePerUnit}
              name="pricePerUnit"
              onBlur={onChangeValue}
            />
            <div className="input-group-append">
              <span className="input-group-text form-control custom-form-control">
                €/{selectedArticle?.unit ?? "kg"}
              </span>
            </div>
          </div>
          <small className="text-success mt-1">&nbsp;</small>
        </div>
      </div>
      <div className="row mb-2">
        <span className="col-4 col-form-label fs-6 fw-bold">Discount</span>
        <div className="col-8">
          <div className="input-group">
            <Input
              className="form-control custom-form-control"
              type="number"
              value={discount}
              name="discount"
              onBlur={onChangeValue}
            />
            <div className="input-group-append">
              <span className="input-group-text form-control custom-form-control">%</span>
            </div>
          </div>
          <small className="text-success mt-1">&nbsp;</small>
        </div>
      </div>
      <div className="row mb-2">
        <span className="col-4 col-form-label fs-6 fw-bold">Purchase Price Per Unit</span>
        <div className="col-8">
          <div className="input-group">
            <Input
              className="form-control custom-form-control"
              type="number"
              value={purchasePrice}
              name="purchasePrice"
              onBlur={onChangeValue}
            />
            <div className="input-group-append ">
              <span className="input-group-text form-control custom-form-control">
                €/{selectedArticle?.unit ?? "kg"}
              </span>
            </div>
          </div>
          <small className="text-success mt-1">&nbsp;</small>
        </div>
      </div>
      <div className="row mb-2">
        <span className="col-4 col-form-label fs-6 fw-bold">Margin Per Unit</span>
        <div className="col-8">
          <div className="input-group">
            <Input
              className="form-control custom-form-control disabled"
              disabled={true}
              type="number"
              value={round(pricePerUnit * (1 - discount / 100) - purchasePrice, 2)}
            />
            <div className="input-group-append disabled" style={{ opacity: "0.7" }}>
              <span className="input-group-text form-control custom-form-control">
                €/{selectedArticle?.unit ?? "kg"}
              </span>
            </div>
          </div>
          <small className="text-success mt-1">&nbsp;</small>
        </div>
      </div>
    </>
  );
};

export default CreateCustomerOrderContractGeneralSettings;
